import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useState } from "react";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import ProfileButtons from "../utils/ProfileButtons";
import { FaRedo, FaRegFileExcel } from "react-icons/fa";
import swal from "sweetalert";

const CompleteButton = ({ calibration, setCalibration }) => {
  const { takeAction, loading } = useApi();

  const [startedAt] = useState(dayjs().format("HH:mm"));

  const generateWorksheet = () => {
    return takeAction("store", `calibrations/${calibration.uuid}/worksheet`)
      .then(({ data }) => {
        toast.success(`${calibration.name} updated!`);
        setCalibration(data.data);
      })
      .catch(errorSwal);
  };

  const markComplete = () => {
    takeAction("update", `equipment-calibration-status/${calibration.uuid}`, {
      completed_at: calibration.completed_at
        ? null
        : dayjs().format("YYYY-MM-DD HH:mm:ss"),
      tracking_time: {
        scheduled_start_time: startedAt,
        scheduled_finish_time: dayjs().format("HH:mm"),
      },
    })
      .then(({ data }) => {
        toast.success(`${calibration.name} updated!`);
        setCalibration(data.data);
      })
      .catch(errorSwal);
  };

  const buttons = [
    {
      text: (
        <>
          {" "}
          {isSubmitting(
            loading,
            <>
              {!calibration.completed_at && (
                <i className="icon ion-checkmark tx-success" />
              )}
              {calibration.completed_at ? "Unm" : "M"}ark Calibration Complete
            </>,
            "Marking...",
          )}
        </>
      ),
      onClick: markComplete,
      disabled: loading,
    },
  ];

  if (calibration.worksheet_document) {
    buttons.pop();

    buttons.push({
      text: (
        <>
          <FaRegFileExcel className="text-success tx-18 me-2" />
          Open In Excel
        </>
      ),
      onClick: () => {
        window.open(
          calibration.worksheet_document.external_links[0].url,
          "_blank",
        );
      },
    });

    buttons.push({
      text: isSubmitting(
        loading,
        <>
          <FaRedo className="tx-18 me-2" /> Regenerate Calibration Sheet
        </>,
        "Regenerating...",
      ),
      disabled: loading,
      onClick: () => {
        swal({
          title: "Regenerate Calibration Sheet",
          text: "Regenerating the calibration sheet will overwrite any existing worksheets. Are you sure you want to regenerate the calibration sheet?",
          icon: "warning",
          buttons: {
            cancel: {
              text: "Cancel",
              value: null,
              closeModal: true,
              visible: true,
            },
            delete: {
              text: "Regenerate",
              value: true,
              closeModal: true,
              className: "btn btn-danger align-baseline",
            },
          },
          dangerMode: true,
        }).then((result) => {
          if (result) {
            generateWorksheet();
          }
        });
      },
    });
  }

  if (
    calibration.calibration_type.document_id &&
    !calibration.worksheet_document
  ) {
    buttons.push({
      text: "Generate Calibration Sheet",
      onClick: generateWorksheet,
    });
  }

  return <ProfileButtons buttons={buttons} />;
};

export default CompleteButton;
