import React from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { connect } from "react-redux";
import {
  handleDayClick,
  handleDayMouseEnter,
} from "../../actions/projectOverviewDateRangeActions";
import { primary } from "../utils/Colours";

const ProjectOverviewDateRange = ({
  show,
  from,
  to,
  enteredTo,
  handleDayClick,
  handleDayMouseEnter,
  className,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className={`card ${className}`}>
      <style>{modifiersStyles}</style>
      <DayPicker
        className="Range"
        onDayClick={(day) => handleDayClick(day, from, to)}
        selected={[from, { from, to: enteredTo }]}
        onDayMouseEnter={(day) => handleDayMouseEnter(day, from, to)}
      />
    </div>
  );
};

const modifiersStyles = `.DayPicker-Day--selected {
  color: white !important;
  background-color: ${primary} !important;
  border-radius:0  !important;
}`;

const mapStateToProps = (state) => ({
  from: state.projectOverviewDateRange.from,
  to: state.projectOverviewDateRange.to,
  enteredTo: state.projectOverviewDateRange.enteredTo,
});

export default connect(mapStateToProps, {
  handleDayClick,
  handleDayMouseEnter,
})(ProjectOverviewDateRange);
