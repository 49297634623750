import HeaderPage from "../header/HeaderPage";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import ClientSignUpForm from "./ClientSignUpForm";

const Add = () => {
  const setting = useOrganisationSetting("client_sign_up");

  return (
    <>
      <HeaderPage
        titlePage="Add new Client"
        crumbs={[
          { link: "clients", name: "Clients" },
          { name: "Add Client", active: true },
        ]}
      />
      <ClientSignUpForm
        form="AddClient"
        initialValues={{
          has_account: true,
          abn_holder: true,
          request_account: true,
        }}
        setting={setting}
      />
    </>
  );
};

export default Add;
