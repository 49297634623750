import React from "react";
import Edit from "./Edit";
import NotFound from "../404";

const ShowPage = (props) => {
  const { docket, match } = props;
  const { page } = match.params;

  const initialValues = {
    ...docket,
    jobs: docket.jobs.map((job) => ({
      label: job.label,
      value: job.id,
    })),
  };

  switch (page) {
    case "details":
      return <Edit initialValues={initialValues} {...props} />;
    case "signature":
      return <SignatureImage {...props} />;
    default:
      return <NotFound />;
  }
};

const SignatureImage = ({ docket }) => (
  <>
    <img className="w-100" src={docket.signature} alt="signature" />
    <label>Signatory:</label>
    <p>{docket.signatory}</p>
  </>
);

export default ShowPage;
