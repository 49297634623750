import React from "react";
import { useAuth } from "../../context/auth-context";
import SetUpMfa from "../user/SetUpMfa";
import { useHistory, useLocation } from "react-router-dom";
import { useRedirectOnLogin } from "./hooks/useRedirectOnLogin";
import { AuthUser } from "../user/userTypes";

const TwoFactorRequired = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirect = useRedirectOnLogin();

  return (
    <div className="signin-wrapper">
      <div>
        <SetUpMfa
          twoFactorRequiredOrganisation={urlParams.get("organisation") ?? ""}
          onSuccess={(user: AuthUser) => redirect(user)}
        />
      </div>
    </div>
  );
};

export default TwoFactorRequired;
