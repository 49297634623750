import { reduxForm } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import Fields from "./Fields";

const Form = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <Fields />
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};
const form = reduxForm({ form: "EditBankAccountDetails" });

export default form(Form);
