import React, { useEffect } from "react";
import PriceBandsFields from "./PriceBandsFields";

const PriceBands = (props : any) => {
  const { fields, initialValues } = props;
  useEffect(() => {
    if (!initialValues) {
      fields.push({});
    }
  }, []);

  return (
    <>
      <div className="col-12">
        <p className="tx-inverse tx-semibold">Add Price Bands</p>
      </div>

      {fields.map((field : any, index : any) => {
        return (
          <PriceBandsFields
            key={index}
            {...props}
            field={field}
            index={index}
          />
        );
      })}

      <div className="col-12 d-flex">
        <button
          type="button"
          className="ms-auto btn btn-link"
          onClick={() => fields.push({})}
        >
          Add Price Bands
        </button>
      </div>
    </>
  );
};

export default PriceBands;
