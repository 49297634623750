import React from "react";
import { Field, reduxForm } from "redux-form";
import TimeUnitType, { timeUnitTypes } from "../../enums/TimeUnitType";
import { date, money } from "../form/formatters";
import DateTime from "../utils/dateTime";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const Form = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            validate={required}
            required
            component={SelectInputAsync}
            name="account_id"
            label="Account"
            url="/chart-of-accounts"
            formatData={(data) => {
              return data.data.map((account) => ({
                label: `${account.code} - ${account.name}`,
                value: account.id,
              }));
            }}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            validate={required}
            required
            component={SelectInputAsync}
            name="branch_id"
            label="Branch"
            formatData={(data) => {
              return data.data;
            }}
            url="/user-dashboards"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            validate={required}
            required
            component={DateTime}
            name="start"
            label="Start Date"
            {...date}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field component={DateTime} name="end" label="End Date" {...date} />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            validate={required}
            required
            component={RenderField}
            name="amount"
            label="Amount"
            {...money}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            validate={required}
            required
            component={SelectInputAsync}
            name="time_frame"
            label="Time Frame"
            options={filteredTimeUnits}
          />
        </div>
        <div className="col-lg-12 form-group">
          <Field
            component={renderToggleInput}
            name="rolls_over"
            label="Rolls Over"
          />
        </div>
        <div className="col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const filteredTimeUnits = timeUnitTypes.filter((type) => {
  return [TimeUnitType.Years, TimeUnitType.Months].indexOf(type.value) !== -1;
});

export default reduxForm()(Form);
