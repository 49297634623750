export const AUTHENTICATED = "authenticated_user";
export const UNAUTHENTICATED = "unauthenticated_user";
export const AUTHENTICATION_ERROR = "authentication_error";

export const FETCH_PROJECT_TIMELINE = "FETCH_PROJECT_TIMELINE";
export const FETCH_PROJECT_OVERVIEW = "FETCH_PROJECT_OVERVIEW";

export const FETCH_INVITE = "FETCH_INVITE";
export const FETCH_INVITES = "FETCH_INVITES";
export const NEW_INVITE = "NEW_INVITE";
export const DELETE_INVITE = "DELETE_INVITE";

export const NEW_USER = "NEW_USER";
export const FETCH_USERS = "FETCH_USERS";
export const UPDATE_USER = "UPDATE_USER";

export const FETCH_ORGANISATIONS = "FETCH_ORGANISATIONS";
export const NEW_ORGANISATION = "NEW_ORGANISATION";
export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";

export const FETCH_ORGANISATION_SETTINGS = "FETCH_ORGANISATION_SETTINGS";
export const UPDATE_ORGANISATION_SETTINGS = "UPDATE_ORGANISATION_SETTINGS";
export const UPDATE_ORGANISATION_APPROVALS = "UPDATE_ORGANISATION_APPROVALS";
export const NEW_ORGANISATION_SETTINGS = "NEW_ORGANISATION_SETTINGS";

export const FETCH_NOTICEBOARDS = "FETCH_NOTICEBOARDS";
export const NEW_NOTICEBOARDS = "NEW_NOTICEBOARDS";
export const UPDATE_NOTICEBOARDS = "UPDATE_NOTICEBOARDS";
export const DELETE_NOTICEBOARDS = "DELETE_NOTICEBOARDS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const VERIFY_TOKEN = "VERIFY_TOKEN";

export const FETCH_ORGANISATION_ROLES = "FETCH_ORGANISATION_ROLES";
export const NEW_ORGANISATION_ROLE = "NEW_ORGANISATION_ROLE";
export const UPDATE_ORGANISATION_ROLE = "UPDATE_ORGANISATION_ROLE";
export const DELETE_ORGANISATION_ROLE = "DELETE_ORGANISATION_ROLE";

export const FETCH_STAFF_ROLES = "FETCH_STAFF_ROLES";
export const NEW_STAFF_ROLE = "NEW_STAFF_ROLE";
export const UPDATE_STAFF_ROLE = "UPDATE_STAFF_ROLE";
export const DELETE_STAFF_ROLE = "DELETE_STAFF_ROLE";

export const FETCH_STAFF_ROLE_TYPES = "FETCH_STAFF_ROLE_TYPES";
export const NEW_STAFF_ROLE_TYPE = "NEW_STAFF_ROLE_TYPE";
export const UPDATE_STAFF_ROLE_TYPE = "UPDATE_STAFF_ROLE_TYPE";
export const DELETE_STAFF_ROLE_TYPE = "DELETE_STAFF_ROLE_TYPE";

export const FETCH_TEST_REQUESTS = "FETCH_TEST_REQUESTS";
export const UPDATE_TEST_REQUEST = "UPDATE_TEST_REQUEST";
export const DELETE_TEST_REQUEST = "DELETE_TEST_REQUEST";

export const FETCH_SAMPLES = "FETCH_SAMPLES";
export const NEW_SAMPLE = "NEW_SAMPLE";
export const UPDATE_SAMPLE = "UPDATE_SAMPLE";

export const FETCH_SAMPLE_TESTS = "FETCH_SAMPLE_TESTS";
export const NEW_SAMPLE_TEST = "NEW_SAMPLE_TEST";
export const UPDATE_SAMPLE_TEST = "UPDATE_SAMPLE_TEST";
export const DELETE_SAMPLE_TEST = "DELETE_SAMPLE_TEST";
export const FETCH_SAMPLE_TESTS_METHODS = "FETCH_SAMPLE_TESTS_METHODS";
export const FETCH_USER_ORGANISATIONS = "FETCH_USER_ORGANISATIONS";

export const FETCH_TESTS = "FETCH_TESTS";
export const FETCH_ALL_TESTS = "FETCH_ALL_TESTS";
export const NEW_TEST = "NEW_TEST";
export const DELETE_TEST = "DELETE_TEST";
export const UPDATE_TEST = "UPDATE_TEST";
export const UPDATE_SELECTED_TESTS = "UPDATE_SELECTED_TESTS";
export const REMOVE_SELECTED_TESTS = "REMOVE_SELECTED_TESTS";
export const TOGGLE_ALL_SELECTED_TESTS = "TOGGLE_ALL_SELECTED_TESTS";

export const GET_NEW_FILES = "GET_NEW_FILES";
export const GET_STANDARD_FILE_PATH = "GET_STANDARD_FILE_PATH";

export const TOGGLE_FOLDER_DROPDOWN = "TOGGLE_FOLDER_DROPDOWN";

export const FETCH_CALIBRATIONS = "FETCH_CALIBRATIONS";
export const NEW_CALIBRATION = "NEW_CALIBRATION";
export const UPDATE_CALIBRATION = "UPDATE_CALIBRATION";
export const DELETE_CALIBRATION = "DELETE_CALIBRATION";
export const FETCH_UPCOMING_CALIBRATIONS = "FETCH_UPCOMING_CALIBRATIONS";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const FETCH_NOTIFICATION_SETTINGS = "FETCH_NOTIFICATION_SETTINGS";

export const DELETE_DELIVERABLE_PRICING = "DELETE_DELIVERABLE_PRICING";

export const TOGGLE_CHANGE_REPORT_MODAL = "TOGGLE_CHANGE_REPORT_MODAL";

export const TOGGLE_SELECTED_SAMPLE = "TOGGLE_SELECTED_SAMPLE";

export const TOGGLE_SELECTED_TEST_SAMPLE = "TOGGLE_SELECTED_TEST_SAMPLE";
export const TOGGLE_ALL_SELECTED_SAMPLES = "TOGGLE_ALL_SELECTED_SAMPLES";
export const REMOVE_SELECTED_SAMPLES = "REMOVE_SELECTED_SAMPLES";

export const ADD_SELECTED_TEST_SAMPLE = "ADD_SELECTED_TEST_SAMPLE";
export const REMOVE_SELECTED_TEST_SAMPLE = "REMOVE_SELECTED_TEST_SAMPLE";

export const FETCH_CONTRACTORS = "FETCH_CONTRACTORS";
export const FETCH_CONTRACTOR_REQUESTS = "FETCH_CONTRACTOR_REQUESTS";
export const UPDATE_CONTRACTOR_REQUEST = "UPDATE_CONTRACTOR_REQUEST";

export const NEW_DOCKET = "NEW_DOCKET";
export const DELETE_DOCKET = "DELETE_DOCKET";
export const UPDATE_DOCKET = "UPDATE_DOCKET";
export const FETCH_DOCKETS = "FETCH_DOCKETS";
export const FETCH_DOCKET_PREVIEW = "FETCH_DOCKET_PREVIEW";
export const UPDATE_SIGNATURE = "UPDATE_SIGNATURE";
export const GET_JOBS_FOR_DOCKET = "GET_JOBS_FOR_DOCKET";
export const GET_PROJECTS_FOR_DOCKET = "GET_PROJECTS_FOR_DOCKET";
export const GET_DOCKET_ITEMS = "GET_DOCKET_ITEMS";

export const ADD_DOCKET_BILLABLE_ITEMS = "ADD_DOCKET_BILLABLE_ITEMS";
export const ADD_DOCKET_UNITS = "ADD_DOCKET_UNITS";

export const NEW_STAFF_SCHEDULE = "NEW_STAFF_SCHEDULE";
export const UPDATE_STAFF_SCHEDULE = "UPDATE_STAFF_SCHEDULE";
export const DELETE_STAFF_SCHEDULE = "DELETE_STAFF_SCHEDULE";

export const FETCH_TIMESHEET_APPROVAL_USERS = "FETCH_TIMESHEET_APPROVAL_USERS,";
export const NEW_TIMESHEET_APPROVAL_USER = "NEW_TIMESHEET_APPROVAL_USER,";
export const UPDATE_TIMESHEET_APPROVAL_USER = "UPDATE_TIMESHEET_APPROVAL_USER,";
export const DELETE_TIMESHEET_APPROVAL_USER = "DELETE_TIMESHEET_APPROVAL_USER";

export const FETCH_TIMESHEETS = "FETCH_TIMESHEETS";
export const NEW_TIMESHEET = "NEW_TIMESHEET";
export const UPDATE_TIMESHEET = "UPDATE_TIMESHEET";
export const DELETE_TIMESHEET = "DELETE_TIMESHEET";
export const MODIFY_TIMESHEET_DATE = "MODIFY_TIMESHEET_DATE";
export const CLEAR_TIMESHEET = "CLEAR_TIMESHEET";

export const NEW_PRODUCTIVITY = "NEW_PRODUCTIVITY";
export const UPDATE_PRODUCTIVITY = "UPDATE_PRODUCTIVITY";
export const DELETE_PRODUCTIVITY = "DELETE_PRODUCTIVITY";
export const FETCH_PRODUCTIVITY = "FETCH_PRODUCTIVITY";
export const CLEAR_PRODUCTIVITY = "CLEAR_PRODUCTIVITY";
export const ADD_PRODUCTIVITY = "ADD_PRODUCTIVITY";

export const FETCH_TO_DOS = "FETCH_TO_DOS";

export const FETCH_PAYS = "FETCH_PAYS";
export const UPDATE_PAY = "UPDATE_PAY";

export const DELETE_CPIR = "DELETE_CPIR";

export const FETCH_SKILL_CATEGORYS = "FETCH_SKILL_CATEGORYS";
export const NEW_SKILL_CATEGORY = "NEW_SKILL_CATEGORY";
export const UPDATE_SKILL_CATEGORY = "UPDATE_SKILL_CATEGORY";
export const DELETE_SKILL_CATEGORY = "DELETE_SKILL_CATEGORY";

export const CANCEL_EMPLOYMENT = "CANCEL_EMPLOYMENT";

export const UPDATE_PAY_CORRECTION = "UPDATE_PAY_CORRECTION";

export const FETCH_PAY_TIMESHEETS = "FETCH_PAY_TIMESHEETS";
export const CLEAR_PAY_TIMESHEETS = "CLEAR_PAY_TIMESHEETS";

export const FETCH_PAY_RUN_TIMESHEETS = "FETCH_PAY_RUN_TIMESHEETS";

export const FETCH_CALIBRATION_UNITS = "FETCH_CALIBRATION_UNITS";
export const NEW_CALIBRATION_UNIT = "NEW_CALIBRATION_UNIT";
export const UPDATE_CALIBRATION_UNIT = "UPDATE_CALIBRATION_UNIT";
export const DELETE_CALIBRATION_UNIT = "DELETE_CALIBRATION_UNIT";

export const SELECT_CALIBRATION_UNIT = "SELECT_CALIBRATION_UNIT";

export const FETCH_CALIBRATION_TRACKINGS = "FETCH_CALIBRATION_TRACKINGS";
export const NEW_CALIBRATION_TRACKING = "NEW_CALIBRATION_TRACKING";
export const UPDATE_CALIBRATION_TRACKING = "UPDATE_CALIBRATION_TRACKING";
export const DELETE_CALIBRATION_TRACKING = "DELETE_CALIBRATION_TRACKING";

export const UPDATE_PROJECT_OVERVIEW_DATE_RANGE =
  "UPDATE_PROJECT_OVERVIEW_DATE_RANGE";

export const FETCH_NUCLEAR_DENSOMETERS = "FETCH_NUCLEAR_DENSOMETERS";
export const UPDATE_NUCLEAR_DENSOMETER = "UPDATE_NUCLEAR_DENSOMETER";

export const FETCH_OVERSIZE_FIELD_MOISTURES = " FETCH_OVERSIZE_FIELD_MOISTURES";

export const FETCH_MOISTURE_CONTAINERS = "FETCH_MOISTURE_CONTAINERS";
export const NEW_MOISTURE_CONTAINER = "NEW_MOISTURE_CONTAINER";
export const UPDATE_MOISTURE_CONTAINER = "UPDATE_MOISTURE_CONTAINER";
export const DELETE_MOISTURE_CONTAINER = "DELETE_MOISTURE_CONTAINER";

export const FETCH_COMPACTIONS = "FETCH_COMPACTIONS";
export const UPDATE_COMPACTION = "UPDATE_COMPACTION";

export const FETCH_PROJECT_CONTACTS = "FETCH_PROJECT_CONTACTS";
export const NEW_PROJECT_CONTACT = "NEW_PROJECT_CONTACT";
export const UPDATE_PROJECT_CONTACT = "UPDATE_PROJECT_CONTACT";
export const DELETE_PROJECT_CONTACT = "DELETE_PROJECT_CONTACT";

export const ADD_PRE_SIGNED_URL = "ADD_PRE_SIGNED_URL";

export const SET_STANDARD_JOB_TYPES = "SET_STANDARD_JOB_TYPES";

export const SET_LOCAL_STORAGE = "SET_LOCAL_STORAGE";
