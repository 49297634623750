import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, reduxForm, submit } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

const DeleteModal = (props) => {
  const { toggle, modal, handleSubmit, dispatch, title, form } = props;

  return (
    <Modal
      backdrop="static"
      className="wd-md-850"
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Delete {title}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <Field
            component={RenderField}
            name="reason"
            label="Reason"
            textarea
            required
            validate={required}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          save="Delete"
          saving="Deleting..."
          onClick={() => dispatch(submit(form))}
          {...props}
        />
      </ModalFooter>
    </Modal>
  );
};

DeleteModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
};

const form = reduxForm();

export default form(DeleteModal);
