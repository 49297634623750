import { useEffect, useState } from "react";
import DocsModal from "../utils/DocsModal";
import { IFilter } from "../hooks/useFilter";
import usePendingApprovals from "./hooks/usePendingApprovals";
import PaginatedList from "../pagination/PaginatedList";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import InformationAlert from "../utils/InformationAlert";
import ApprovalList from "../home/ApprovalList";
import { useQueryClient } from "react-query";
import useSort, { ISorter } from "../hooks/useSort";
import SortDropdown from "../utils/SortDropdown";
export type Filters = IFilter<
  "status" | "approvable_type",
  boolean | number | string
>;

interface Option {
  label: string;
  value: number;
  selected?: boolean;
}

const useFilters = (): Filters[] => {
  const { userId } = useParams<{ userId: string }>();
  const { takeAction, loading }: IUseApi = useApi();
  const [approvableType, setApprovableType] = useState<Option[]>([]);

  useEffect(() => {
    takeAction("index", `/users/${userId}/approvable-types`).then(
      ({ data }) => {
        setApprovableType(Object.values(data.data));
      },
    );
  }, []);

  return [
    {
      label: "Status",
      name: "status",
      options: [
        {
          label: "Approved",
          value: 1,
        },
        {
          label: "Not Approved",
          value: 0,
          selected: true,
        },
      ],
    },
    {
      label: "Approvable Type",
      name: "approvable_type",
      options: approvableType,
    },
  ];
};

const approvalsSorters: ISorter<"due_date">[] = [
  {
    label: "Due Date",
    name: "due_date",
    sortBy: "desc",
  },
];

export const PendingApprovals = ({ user }: any) => {
  const { userId } = useParams<{ userId: string }>();
  const filters = useFilters();
  const [approvalData, setApprovalData] = useState<any[]>([]);
  const queryClient = useQueryClient();

  const {
    sorters,
    toggleSorter,
    stringified: sortString,
  } = useSort(approvalsSorters);

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/miscellaneous/approvals/" />
      <div className="mb-4">
        <InformationAlert
          title="Filters now available"
          body={
            <>
              You can now view your previous approvals. to user, select the
              'Approved' status in the filters to view.
              <br />
              You can also filter by the 'Approval Type'
            </>
          }
        />
      </div>

      <PaginatedList
        indexHook={usePendingApprovals}
        originalFilters={filters}
        indexHookArguments={[userId]}
        // @ts-ignore
        extraButtons={({ _, sorting, setSorting }) => {
          return (
            <Sorters
              setSorting={setSorting}
              sorters={sorters}
              toggleSorter={toggleSorter}
            />
          );
        }}
        // @ts-ignore
        list={({ data, pages, pageParams }: { data?: any[]; pages: any[] }) => {
          if (data) {
            setApprovalData(data);
          }

          return (
            <div>
              <ApprovalList
                approvals={approvalData ?? []}
                setApprovals={setApprovalData}
                refreshData={() =>
                  queryClient.invalidateQueries("pending-approvals")
                }
              />
            </div>
          );
        }}
      />
    </>
  );
};

const Sorters = ({
  sorters,
  toggleSorter,
  setSorting,
}: {
  sorters: ISorter<"due_date">[];
  toggleSorter: (sortedName: "due_date") => void;
  setSorting?: Function;
}) => {
  useEffect(() => {
    setSorting?.(
      sorters.map((s) => ({
        id: s.name,
        desc: s.sortBy === "desc",
      })),
    );
  }, [sorters]);

  return <SortDropdown sorters={sorters} toggleSorter={toggleSorter} />;
};
