import {
  FETCH_PROJECT_TIMELINE,
  FETCH_PROJECT_OVERVIEW,
} from "../actions/types";

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  let project;
  let newJobs;

  if (state.items.uuid) {
    project = Object.assign({}, state.items);
  }

  switch (action.type) {
    case FETCH_PROJECT_TIMELINE:
      return {
        ...state,
        timeline: action.payload,
      };
    case FETCH_PROJECT_OVERVIEW:
      project.overview = action.payload;
      return {
        ...state,
        items: project,
      };
    default:
      return state;
  }
}
