import { EditorState } from "draft-js";
import { useRef, useState } from "react";
import { ResizableBox } from "react-resizable";
import { MediaProps } from "reactstrap";
import ResizableMedia from "./ResizableMedia";

const EmbeddedImage = (props: MediaProps) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));

  const { src } = entity.getData();

  return (
    <ResizableMedia {...props}>
      <img className="h-100 w-100" src={src}/>
    </ResizableMedia>
  );
};

export default EmbeddedImage;
