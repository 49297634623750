import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { primary } from "../utils/Colours";

const AssignButton = (props) => {
  const { user, updating, onClick } = props;
  const loading = updating.includes(user.uuid);

  return (
    <>
      {loading ? (
        <>
          <ClipLoader size={15} color={primary} />
          Updating...
        </>
      ) : (
        <a className="btn btn-link p-0" onClick={onClick}>
          Assign
        </a>
      )}
    </>
  );
};

export default AssignButton;
