import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field, reduxForm, submit, reset } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import renderField from "../utils/renderField";
import required from "../utils/required";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import axios from "../api/api";

const AddModal = (props) => {
  const { toggle, modal, handleSubmit, dispatch } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} className="wd-md-850">
      <ModalHeader toggle={toggle}>Add New Industry</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 form-group">
              <Field
                component={renderField}
                validate={required}
                name="name"
                required
                label="Name"
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton onClick={() => dispatch(submit("Industry"))} {...props} />
      </ModalFooter>
    </Modal>
  );
};

const submitForm = (values, dispatch, props) => {
  return axios
    .post(`/industries`, values)
    .then(({ data }) => {
      toast.success("Industry added!");
      dispatch(reset("Industry"));
      props.setIndustries([...props.industries, data.data]);
    })
    .catch((err) => {
      return errorSwal(err);
    });
};

const form = reduxForm({ form: "Industry", onSubmit: submitForm });

export default form(AddModal);
