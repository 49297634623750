import React from "react";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import usePerformanceReviewTemplates from "./hooks/usePerformanceReviewTemplates";
import { PerformanceReviewTemplate } from "./perfomanceReviewTypes";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";

const PerformanceReviewTemplateList = () => {
  return (
    <>
      <HeaderPage
        titlePage="Performance Review Templates"
        crumbs={[
          {
            link: "performance-reviews",
            name: "Performance Review Templates",
            active: true,
          },
        ]}
      />
      <PaginatedList
        indexHook={usePerformanceReviewTemplates}
        addLink="/performance-review-templates/add"
        listName="performanceReviewTemplates"
        originalFilters={[]}
        list={({ data }: { data?: PerformanceReviewTemplate[] }) => {
          return (
            <div className="col-12">
              <PerformanceReviewTemplateTable data={data} />
            </div>
          );
        }}
      />
    </>
  );
};

const PerformanceReviewTemplateTable = ({
  data = [],
}: {
  data?: PerformanceReviewTemplate[];
}) => {
  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => {
        const template = info.row.original;

        return (
          <Link to={`/performance-review-templates/${template.uuid}/details`}>
            {template.name}
          </Link>
        );
      },
    },
  ];

  return <ReactTable disableSearch columns={columns} data={data} />;
};

export default PerformanceReviewTemplateList;
