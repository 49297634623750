import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getProjectInvoices = ({ pageParam = 1 }, search?: string, uuid?: string, status?:string) => {
  if (!pageParam || !uuid) {
    return;
  }

  const statusParam = status ? `&status=${status}` : '';

  return api
    .get(`projects/${uuid}/invoices/?page=${pageParam}&filter[search]=${search}${statusParam}`)
    .then(({ data }) => data);
};

export default function useProjectInvoices(search?: string, uuid?: string, status?: string) {
  return useInfiniteQuery(
    ["projectInvoices", { search, uuid, status }],
    (pageParam) => getProjectInvoices(pageParam, search, uuid, status),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
