import React, { useState } from "react";
import { toast } from "react-toastify";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import history from "../utils/History";
import FormOrganisationRole from "./Form";
import axios from "../api/api";

const EditOrganisationRole = (props) => {
  const { initialValues, setRole } = props;

  const [deleting, setDeleting] = useState(false);

  const onSubmit = (values) => {
    return axios
      .put(`/organisation-roles/${initialValues.uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.name} updated successfully`);
        setRole(data.data.organisationRole);
      })
      .catch(errorSwal);
  };

  const deleteRole = (values) => {
    deleteSwal(initialValues.name)
      .then(() => {
        setDeleting(true);
        return axios.delete(`/organisation-roles/${initialValues.uuid}`);
      })
      .then(() => {
        history.push(`/organisation-roles`);
        toast.success(`${initialValues.name} deleted successfully`);
      })
      .catch(errorSwal)
      .then(() => setDeleting(false));
  };

  return (
    <FormOrganisationRole
      {...props}
      deleting={deleting}
      onDelete={deleteRole}
      onSubmit={onSubmit}
    />
  );
};

export default EditOrganisationRole;
