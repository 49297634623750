import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import getBgColor from "../utils/getBgColor";
import ProgressBar from "../utils/ProgressBar";

const UpcomingCalibrationsWidget = ({ equipment }) => {
  const { nextDueCalibrations } = equipment;

  if (nextDueCalibrations.length === 0) return null;

  return (
    <div className="card mg-t-20">
      <div className="card-body">
        <h6 className="slim-card-title mg-b-20">Upcoming Calibrations</h6>
        {nextDueCalibrations.map((calibration) => (
          <CalibrationDetail key={calibration.uuid} calibration={calibration} />
        ))}
      </div>
    </div>
  );
};

export default UpcomingCalibrationsWidget;

const CalibrationDetail = ({ calibration }) => {
  return (
    <div className="mb-4">
      <label className="mg-b-0 tx-inverse tx-bold">
        <Link to={calibration.link}>
          {calibration.calibration_type.procedures}
        </Link>
      </label>
      {calibration.is_initial ? (
        <div className="tx-danger">Initial Calibration Required</div>
      ) : (
        <div>
          <NextCalibrationDue calibration={calibration} />
          <ProgressBar
            percent={calibration.percent}
            color={getBgColor(calibration.percent)}
          />
          <div>{calibration.calibration_type.interval}</div>
        </div>
      )}
    </div>
  );
};

const NextCalibrationDue = ({ calibration }) => (
  <label className="mb-2">
    Due{" "}
    {calibration.calibration_type.frequency_units === null ? (
      `at ${calibration.calibration_type.frequency} ${calibration.calibration_type.frequency_unit_name}`
    ) : (
      <>on the {dayjs(calibration.date).format("DD/MM/YYYY")}</>
    )}
  </label>
);
