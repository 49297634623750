import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { FormSubmitHandler, reset } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { ClientContact } from "../clients/ClientContacts";
import ContactModal from "../clients/ContactModal";
import useModal from "../hooks/useModal";
import { IProject } from "../projects/projectTypes";
import errorSwal from "../utils/errorSwal";

interface AddClientContactToProjectProps {
  project: IProject;
  contacts: any[];
  setContacts: (contacts: { value: number; label: string }[]) => void;
  setClientContacts: (contacts: ClientContact[]) => void;
  clientContacts: ClientContact[];
}

const AddClientContactToProject = ({
  project,
  contacts,
  setContacts,
  clientContacts,
  setClientContacts,
}: AddClientContactToProjectProps) => {
  const { toggle, modal } = useModal();
  const { takeAction }: IUseApi<{}, { data: any }> = useApi();

  const onSubmit: FormSubmitHandler<ClientContact> = (values, dispatch) => {
    return takeAction(
      "store",
      `organisation-clients/${project.client.uuid}/contacts`,
      values,
    )
      .then(({ data }) => {
        setClientContacts([...clientContacts, data.data]);

        return takeAction("store", "project-contacts", {
          project_id: project.id,
          contact_id: data.data.id,
        });
      })

      .then(({ data }) => {
        setContacts([...contacts, data.data]);
        toast.success("Contact Added");
        toggle();
        dispatch(reset("ProjectContacts"));
      })
      .catch(errorSwal);
  };

  return (
    <div className="p-3">
      {contacts.length === 0 ? (
        <p className="mb-0">
          No contacts found in {project.client.display_name}.
        </p>
      ) : null}

      <p className="mb-0">
        <Button onClick={toggle} color="link" className="p-0">
          Click here
        </Button>{" "}
        to add a contact to {project.client.display_name}.
      </p>
      <ContactModal
        toggle={toggle}
        modal={modal}
        title="Add new Contact"
        form="AddClientContact"
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default AddClientContactToProject;
