import React from "react";
import { Button } from "reactstrap";
import useApi from "../../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../../utils/errorSwal";
import isSubmitting from "../../utils/submitting";

const SyncClients = () => {
  const { takeAction, loading } = useApi();
  const syncClients = () => {
    return takeAction("store", "the-testing-app-clients")
      .then(() =>
        toast.success(
          "Client syncing has begun, if you have a lot of client, this may take a few minutes.",
        ),
      )
      .catch(errorSwal);
  };

  return (
    <div>
      <Button onClick={syncClients} disabled={loading}>
        {isSubmitting(loading, "Sync all Clients", "Syncing...")}
      </Button>
    </div>
  );
};

export default SyncClients;
