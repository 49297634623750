import React, { useState, useEffect } from "react";
import axios from "../api/api";
import JobListJob from "./JobListJob";
import DashboardEditDropdown from "../jobs/DashboardEditDropdown";

const JobList = ({ jobs }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [position, setPosition] = useState({});
  const [job, setJob] = useState({});
  const [users, setUsers] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const contextMenu = (e, selectedJob) => {
    e.preventDefault();
    const yAxisAdjustment =
      window.screen.height - e.screenY < window.screen.height / 2 ? 325 : 0;
    toggle();
    setPosition({ x: e.clientX, y: e.clientY - yAxisAdjustment });
    setJob(selectedJob);
  };

  const toggle = () => setDropDownOpen(!dropDownOpen);

  useEffect(() => {
    if (dropDownOpen) {
      axios.get(`/users/staff`).then(({ data }) => setUsers(data));
    }
  }, [dropDownOpen]);

  useEffect(() => {
    setFilteredJobs(jobs);
  }, [jobs]);
  return (
    <>
      <DashboardEditDropdown
        toggle={toggle}
        dropDownOpen={dropDownOpen}
        position={position}
        job={job}
        users={users}
        jobs={filteredJobs}
        setJobs={setFilteredJobs}
      />
      {filteredJobs.map((job, index) => (
        <JobListJob
          job={job}
          key={job.uuid}
          index={index}
          jobCount={filteredJobs.length}
          contextMenu={contextMenu}
        />
      ))}
    </>
  );
};

export default JobList;
