import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import InvoiceStatusBadge from "../jobs/InvoiceStatusBadge";

const Job = ({ job, jobs, index }) => {
  const isLast = jobs.length === index + 1;
  return (
    <div
      className={`d-flex align-items-end ${
        !isLast ? "pb-3 mb-3 border-bottom" : ""
      }`}
    >
      <div>
        <h6>
          <Link to={job.link}>{job.full_name}</Link>
        </h6>
        <p className="mb-1">
          <a href={`mailto:${job.manager.email}`}>{job.manager.name}</a>
        </p>
        <div className="d-flex space-x-2">
          <Badge className="rounded-pill shadow" color={job.status_badge.color}>
            {job.status_badge.label}
          </Badge>
          <InvoiceStatusBadge status={job.invoice_status} />
          {job.is_overdue && (
            <Badge className="rounded-pill shadow" color="danger">
              Overdue
            </Badge>
          )}
        </div>
      </div>
      <div className="ms-auto">
        <p className="mb-0 fw-bolder text-dark" style={{ fontSize: "16px" }}>
          {dayjs(job.scheduled_finish_date).format("DD/MM/YYYY")}
        </p>
        <small>Date Due</small>
      </div>
    </div>
  );
};

export default Job;
