import { useEffect, useRef } from "react";

const useObserver = (
  callback?: Function,
  options: IntersectionObserverInit = {
    root: null,
    rootMargin: "30px 0px 0px 0px",
    threshold: 0,
  },
) => {
  const loader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries: any[]) => {
      const target = entries[0];

      if (target.isIntersecting && callback) {
        callback();
      }
    }, options);

    if (loader?.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loader, callback, options]);

  return loader;
};

export default useObserver;
