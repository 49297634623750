import React from "react";
import { Field } from "redux-form";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required, { minLength } from "../utils/required";
import AddressFields from "../addresses/AddressFields";
import dateField from "../utils/dateTime";

const passwordLength = minLength(6);

const UserFields = (props) => {
  const { isOrganisation, individualClient } = props;

  const width = isOrganisation || individualClient ? "4" : "6";

  return (
    <>
      {isOrganisation && (
        <>
          <AddressFields postal {...props} />
          <div className="col-12">
            <label className="section-title mt-3">User Details</label>
            <hr />
          </div>
        </>
      )}

      <div className="col-12 form-group">
        <Field
          validate={required}
          required
          type="email"
          component={renderField}
          name="email"
          label="Email"
        />
        <small className="d-block">You'll use this email to login.</small>
      </div>

      <div className="col-6 form-group">
        <Field
          validate={[required, passwordLength]}
          required
          type="password"
          component={renderField}
          name="password"
          label="Password"
          autoComplete="new-password"
        />
      </div>
      <div className="col-6 form-group">
        <Field
          validate={[required, passwordLength]}
          required
          type="password"
          component={renderField}
          name="password_confirmation"
          label="Confirm Password"
        />
      </div>
      <div className={`col-lg-${width} form-group`}>
        <Field
          validate={required}
          required
          type="text"
          component={renderField}
          name="first_name"
          label="First Name"
          autoComplete="off"
        />
      </div>
      <div className={`col-lg-${width} form-group`}>
        <Field
          validate={required}
          required
          type="text"
          component={renderField}
          name="last_name"
          label="Last Name"
        />
      </div>
      <div className={`col-lg-${width} form-group`}>
        <Field
          validate={required}
          required
          type="text"
          component={renderField}
          name="phone"
          label="Phone Number"
          autoComplete="off"
        />
      </div>
      {!isOrganisation && !individualClient && (
        <div className="col-lg-6 form-group">
          <Field
            validate={required}
            required
            type="date"
            component={dateField}
            timeFormat={false}
            name="dob"
            label="Date of Birth"
          />
        </div>
      )}

      <hr />
      {!isOrganisation && !individualClient && (
        <AddressFields postal {...props} />
      )}
      <div className="col-12 form-group">
        <Field
          validate={required}
          required
          component={renderToggleInput}
          label="Terms of Service"
          name="accepted"
        />
        <small className="d-block">
          You have read and agree to The BOSS App's{" "}
          <a
            href="https://thebossapp.com.au/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms of service
          </a>
          .
        </small>
      </div>
    </>
  );
};

export default UserFields;
