import { useState } from "react";
import { SkillCategory } from "../skillCategories/SkillCategoriesList";
import StaffMatrixSkillCategory from "./StaffMatrixSkillCategory";
import { SelectedSkill } from "./SkillStaffMatrix";
import SearchInput from "../search/SearchInput";

const SkillCategories = ({
  skillCategories,
  selectedSkills,
  setSelectedSkills,
}: {
  skillCategories: SkillCategory[];
  selectedSkills: SelectedSkill[];
  setSelectedSkills: (selectedSkills: SelectedSkill[]) => void;
}) => {
  const [search, setSearch] = useState<string>("");

  const filteredSkillCategories = skillCategories
    .filter((skillCategory) => {
      return (
        skillCategory.name.toLowerCase().includes(search.toLowerCase()) ||
        skillCategory.competency_skills?.filter((skill) => {
          return skill.skill.toLowerCase().includes(search.toLowerCase());
        })?.length > 0
      );
    })
    .map((skillCategory) => {
      return {
        ...skillCategory,
        competency_skills: skillCategory.competency_skills?.filter((skill) => {
          return skill.skill.toLowerCase().includes(search.toLowerCase());
        }),
      };
    });

  return (
    <div
      className="custom-scrollbar"
      style={{ maxHeight: "100vh", overflow: "auto" }}
    >
      <div className="mb-4">
        <h6 className="slim-card-title mb-0">Skill Categories</h6>
        <p className="mb-0 ms-auto">{selectedSkills.length} selected </p>
      </div>
      <SearchInput
        wrapperclass="mb-4"
        setValue={(s: string) => {
          setSelectedSkills([]);
          setSearch(s);
        }}
      />
      <div className="space-y-3">
        {filteredSkillCategories.map((skillCategory) => {
          return (
            <StaffMatrixSkillCategory
              selectedSkills={selectedSkills}
              skillCategory={skillCategory}
              setSelectedSkills={setSelectedSkills}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SkillCategories;
