import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { useAuth } from "../../context/auth-context";
import { ReactComponent as Phone } from "../../svgs/phone.svg";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SubmitButton from "../utils/SubmitButton";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import isSubmitting from "../utils/submitting";
import { AuthUser } from "./userTypes";
import CopyToClipboard from "react-copy-to-clipboard";
import { AiFillCopy } from "react-icons/ai";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";

interface QrCode {
  svg: string;
  url: string;
}

interface QrCodeProps {
  qrCode?: QrCode;
  setQrCode: (qrCode: QrCode) => void;
}

const SetUpMfa = ({
  twoFactorRequiredOrganisation,
  onSuccess,
}: {
  twoFactorRequiredOrganisation?: string;
  onSuccess?: (user: AuthUser) => void;
}) => {
  const { user, setUser } = useAuth();

  const [qrCode, setQrCode] = useState<QrCode>();

  const { takeAction }: IUseApi<{}, { data: AuthUser }> = useApi();
  const { takeAction: setUpMfa, loading }: IUseApi<{}, QrCode> = useApi();

  const onSubmit: FormSubmitHandler<{ code: number }, QrCodeProps> = (
    values,
  ) => {
    return takeAction(
      "store",
      "user/confirmed-two-factor-authentication",
      values,
    )
      .then(({ data }) => {
        setUser(data.data);

        toast.success(
          "Two factor authentication enabled. Next time you login, you will be prompted to enter your code.",
        );

        if (onSuccess) {
          onSuccess(data.data);
        }
      })
      .catch(formError);
  };

  const enableMfa = () => {
    setUpMfa("store", "user/unauthenticated-two-factor-authentication")
      .then(() => setUpMfa("show", "/user/unauthenticated-two-factor-qr-code"))
      .then(({ data }) => {
        setQrCode(data);
      })
      .catch(errorSwal);
  };

  if (user?.has_mfa) {
    return null;
  }

  return (
    <>
      {twoFactorRequiredOrganisation ? (
        <>
          <h5 className="text-center text-dark mb-4">
            {twoFactorRequiredOrganisation} has set up a requirement for two
            factor authentication.
          </h5>
          <p className="mb-2 text-center">
            Enabling two factor authentication adds an extra layer of security,
            requiring you to verify your identity using a password and a unique
            code from your smartphone.
          </p>
          <p className=" text-center">
            Please set up two factor authentication to continue.
          </p>
        </>
      ) : (
        <>
          <h5 className="text-center text-dark mb-4">
            Enhance Your Security with Two Factor Authentication (2FA)
          </h5>
          <p className="mb-2 text-center">
            By enabling 2FA, you'll add an extra layer of security that requires
            you to verify your identity using multiple methods, such as a
            password and a unique code from your smartphone.
          </p>
          <p className=" text-center">
            This greatly reduces the risk of unauthorized access, giving you
            peace of mind knowing your information is safer.
          </p>
        </>
      )}
      <div className="bg-white p-4 rounded-lg border mb-5">
        <AddMfa loading={loading} enableMfa={enableMfa} qrCode={qrCode} />
        <ConfirmMfaForm
          onSubmit={onSubmit}
          qrCode={qrCode}
          setQrCode={setQrCode}
        />
      </div>
    </>
  );
};

const AddMfa = ({
  enableMfa,
  qrCode,
  loading,
}: {
  enableMfa: React.MouseEventHandler<HTMLButtonElement>;
  qrCode?: QrCode;
  loading: boolean;
}) => {
  const { user } = useAuth();
  if (qrCode || user?.two_factor_secret) {
    return null;
  }

  return (
    <div className="d-flex">
      <Phone height="50%" width="40%" />
      <div className="ms-4 d-flex flex-column justify-content-between w-100">
        <div>
          <p className="text-dark fw-bolder mb-3">Download App</p>
          <p className="mb-1">
            Download your favourite authenticator app from the App Store or get
            one ready if you already have one installed.
          </p>
          <p>
            If you don't have an authenticator app, we recommend Microsoft
            Authenticator. Go to your app store and search to download.
          </p>
        </div>
        <div className="d-flex">
          <a
            href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
              width="100"
              alt="Download on the App Store"
            />
          </a>
          <a
            className="ms-2"
            href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/google-play-badge.png"
              width="100"
              alt="Get it on Google Play"
            />
          </a>
          <Button
            onClick={enableMfa}
            disabled={loading}
            className="ms-auto"
            size="sm"
            color="primary"
          >
            {isSubmitting(loading, "Next", "Loading...")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const ConfirmMfa = (
  props: InjectedFormProps<{ code: number }, QrCodeProps> & QrCodeProps,
) => {
  const { qrCode, handleSubmit, setQrCode } = props;
  const { user } = useAuth();
  const regex = /secret=([^&]+)/;

  const match = qrCode?.url.match(regex);
  const secret = match?.[1];

  const { takeAction } = useApi();

  useEffect(() => {
    if (!qrCode && user?.two_factor_secret) {
      takeAction("show", "/user/unauthenticated-two-factor-qr-code").then(
        ({ data }: { data: QrCode }) => {
          setQrCode(data);
        },
      );
    }
  }, [qrCode]);

  if (!qrCode) {
    return null;
  }

  return (
    <>
      <div className="text-center">
        <p className="mb-4">
          <a
            className="text-dark fw-bolder"
            target="_blank"
            href="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/2-factor-authentication-9645/2-factor-authentication-for-users-9659/"
          >
            Click here
          </a>{" "}
          for detailed instructions on how to set up MFA.
        </p>
        <p className="text-dark fw-bolder mb-1">Scan QR Code</p>
        <p className="mb-0">
          Scan the QR code{" "}
          <span className="text-dark fw-bolder">
            with your authenticator app
          </span>{" "}
          to add your account.
        </p>
        <p className="mb-4">
          Please note that if you do not scan with the authenticator app, MFA
          will not work.
        </p>
        <QRCode value={qrCode.url} />
        <p className="mt-4 mb-1">
          Alternatively you can enter the following code manually{" "}
        </p>
        <p className="text-primary">
          <CopyToClipboard
            text={secret ?? ""}
            options={{ format: "text/plain" }}
            onCopy={() => toast.success(`Code copied.`)}
          >
            <span className="pointer-hover">
              {secret}
              <AiFillCopy />
            </span>
          </CopyToClipboard>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormErrorAlert error={props.error} />
          <div className="col-12 form-group">
            <Field
              component={RenderField}
              name="code"
              type="text"
              label="Code"
            />
          </div>
          <div className="col-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const form = reduxForm<{ code: number }, QrCodeProps>({
  form: "ConfirmMfa",
});

const ConfirmMfaForm = form(ConfirmMfa);

export default SetUpMfa;
