import { Field } from "redux-form";
import dateField from "../../utils/dateTime";
import renderField from "../../utils/renderField";

const GeneralFields = ({ disabled }) => (
  <>
    <div className="form-group col-6">
      <Field
        label="Reference Number"
        name="general.reference_number"
        component={renderField}
        extraProps={{ disabled: disabled }}
      />
    </div>
    <div className="form-group col-6">
      <Field
        label="Type"
        name="general.type"
        extraProps={{ disabled: disabled }}
        component={renderField}
      />
    </div>
    <div className="form-group col-6">
      <Field
        label="Scheduled At"
        name="general.scheduled_at"
        component={dateField}
        extraProps={{ disabled: disabled }}
      />
    </div>
    <div className="form-group col-6">
      <Field
        label="Scheduled Finish"
        name="general.scheduled_finish"
        component={dateField}
        extraProps={{ disabled: disabled }}
      />
    </div>
    <div className="form-group col-6">
      <Field
        label="Price"
        name="general.price"
        type="number"
        component={renderField}
        extraProps={{ disabled: disabled }}
      />
    </div>
  </>
);

export default GeneralFields;
