import React from "react";
import Sentiment from "../../enums/Sentiment";
import { Badge } from "reactstrap";

const getColor = (sentiment) => {
  switch (sentiment) {
    case Sentiment.Positive:
      return "success";
    case Sentiment.Negative:
      return "danger";
    case Sentiment.Neutral:
      return "primary";
    default:
      return "primary";
  }
};

const SentimentBadge = ({ feedback }) => {
  return (
    <Badge color={getColor(feedback.sentiment)}>
      {feedback.sentiment_description}
    </Badge>
  );
};

export default SentimentBadge;
