import _ from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Toggle from "react-toggle";
import CustomScaleLoader from "../utils/scaleLoader";
import StaffList from "./StaffList";
import SkillList from "./SkillList";

const StaffSkillList = (props) => {
  const {
    staff,
    employed,
    setEmployed,
    loadingStaff,
    activeStaffSkillFilters,
    setActiveStaffSkillFilters,
  } = props;
  
  const { uuid } = useParams();
  const [search, setSearch] = useState("");
  const isStaffTab = !uuid || uuid !== "skill";
  const onSearch = (e) => {
    setActiveStaffSkillFilters({
      skillType: [],
      skillLevel: [],
    });
    setSearch(e.target.value);
  };
  const filteredStaff = staff.filter((member) =>
    member.name.toLowerCase().includes(search.toLowerCase()),
  );

  const filteredAndOrderedStaff = _.sortBy(filteredStaff, [
    "first_name",
    "last_name",
  ]);

  return (
    <>
      <div className="row">
        <div className="col-lg-8">
          <label className="form-control-label d-block tx-inverse tx-semibold">
            Search {isStaffTab ? "Staff" : "Skill"}
          </label>
          <input
            type="text"
            onChange={(e) => onSearch(e)}
            value={search}
            className="form-control mb-3"
            disabled={loadingStaff}
          />
        </div>
        <div className="col-lg-4">
          <label className="form-control-label d-block tx-inverse tx-semibold">
            Current Staff
          </label>
          <Toggle
            onChange={() => {
              setEmployed(!employed);
            }}
            checked={employed}
          />
        </div>
      </div>
      <div className="card card-people-list p-0">
        <div className="slim-card-title p-3">
          {isStaffTab ? "Staff" : "Skills"}
        </div>
        {isStaffTab ? (
          loadingStaff ? (
            <CustomScaleLoader>
              <p>Fetching Users...</p>
            </CustomScaleLoader>
          ) : (
            <StaffList
              {...props}
              filteredAndOrderedStaff={filteredAndOrderedStaff}
            />
          )
        ) : (
          <SkillList
            {...props}
            activeStaffSkillFilters={activeStaffSkillFilters}
            setActiveStaffSkillFilters={setActiveStaffSkillFilters}
            search={search}
          />
        )}
      </div>
    </>
  );
};

export default StaffSkillList;
