import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import Avatar from "../utils/Avatar";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import useDockets from "./hooks/useDockets";
import { Docket } from "./docketTypes";
import dayjs from "dayjs";
import { Template, ProjectJob } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const DocketsList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/dockets/" />
      <HeaderPage
        titlePage="Dockets"
        pageDescription={
          <>
            Dockets are used to track and manage work completed at a site,
            allowing for confirmation that the work meets the standards of the
            contract, while also serving as a reference for future work. Dockets
            can be attached to deliverables and jobs across multiple projects.
          </>
        }
        relatedLinks={[
          { name: "Templates", link: "/templates", model: Template },
          { name: "Jobs", link: "/jobs", model: ProjectJob },
        ]}
        crumbs={[{ link: "dockets", name: "Dockets", active: true }]}
      />
      <div className="mb-3 d-flex">
        <Link to="dockets/add" className="btn btn-outline-primary">
          Add New Docket
        </Link>
      </div>
      <PaginatedList
        listName="docketsList"
        indexHook={useDockets}
        originalFilters={[]}
        itemCard={({ item }: { item: Docket }) => {
          return (
            <PaginatedCard
              key={item.id}
              header={<DocketHeader docket={item} />}
              info={info(item)}
            />
          );
        }}
      />
    </>
  );
};

const DocketHeader = ({ docket }: { docket: Docket }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={`Docker Name ${docket.name}`} colour={"info"} />
        </div>

        <div className="ms-1" style={{ maxWidth: "85%" }}>
          <p
            id={`docket_number_${docket.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={`dockets/${docket.uuid}/details`}>
              Docket Name: {docket.name}
            </Link>
          </p>
          <UncontrolledTooltip
            placement="top-start"
            target={`docket_number_${docket.uuid}`}
          >
            Docket Name: {docket.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

const info = (docket: Docket): PaginatedInformation[] => {
  return [
    {
      name: "Client",
      value: docket.client.name,
      link: `/clients/${docket.client.uuid}/details`,
    },
    {
      name: "Project",
      value: docket.project.name,
      link: `/projects/${docket.project.uuid}/details`,
    },
    {
      name: "Date",
      value: dayjs(docket.date).format("DD/MM/YYYY"),
    },
    {
      name: "Staff Member",
      value: docket.staff_member.name,
      link: (
        <a href={`mailto:${docket.staff_member.email}`}>
          {docket.staff_member.name}
        </a>
      ),
    },
  ];
};

export default DocketsList;
