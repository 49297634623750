import React from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import formError from "../utils/formError";
import Form from "./BillableItemForm";

const AddBillableItem = () => {
  const { takeAction } = useApi();
  const history = useHistory();

  const queryClient = useQueryClient();

  const onSubmit = (values) => {
    return takeAction("store", "billable-items", values)
      .then(() => {
        toast.success(`${values.name} added successfully`);
        history.push("/billable-items");

        queryClient.invalidateQueries("billable-items");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Billable Item"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "billable-items", name: "Billable Items" },
          {
            link: "",
            name: "New Billable Item",
            active: true,
          },
        ]}
      />

      <Form onSubmit={onSubmit} form="AddBillableItem" />
    </>
  );
};

export default AddBillableItem;
