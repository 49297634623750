import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import formError from "../utils/formError";
import CustomScaleLoader from "../utils/scaleLoader";
import ShowTestSuitePage from "./ShowTestSuitePage";
import { TestSuite, TestSuitePages } from "./testSuiteTypes";

const TestSuiteProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: testSuite,
    setData: setTestSuite,
    loading,
  }: IUseApi<TestSuite> = useApi(`test-suites/${uuid}`, null, true);

  const { takeAction }: IUseApi<{}, { data: TestSuite }> = useApi();

  const onSubmit: FormSubmitHandler<TestSuite> = (values) => {
    return takeAction("update", `test-suites/${uuid}`, values)
      .then(({ data }) => {
        setTestSuite(data.data);
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Test Suites",
            link: "/test-suites",
          },
          {
            name: testSuite?.name ?? "-",
            link: "/test-suites",
            active: true,
          },
        ]}
        titlePage={testSuite ? testSuite.name : "Test Suite"}
      />

      {loading ? (
        <CustomScaleLoader>Fetching Test Suite...</CustomScaleLoader>
      ) : (
        <Profile<TestSuitePages>
          content={
            testSuite ? (
              <ShowTestSuitePage
                testSuite={testSuite}
                setTestSuite={setTestSuite}
                onSubmit={onSubmit}
              />
            ) : (
              <div>Fetching Test Suite...</div>
            )
          }
          header={
            <DashboardHeader>
              <DashboardH3>{testSuite?.name}</DashboardH3>
            </DashboardHeader>
          }
          tabs={[
            {
              label: "Details",
              link: `/test-suites/${uuid}/details`,
              page: "details",
            },
            {
              label: "Statuses",
              link: `/test-suites/${uuid}/statuses`,
              page: "statuses",
            },
            {
              label: "Template",
              link: `/test-suites/${uuid}/template`,
              page: "template",
            },
          ]}
        />
      )}
    </>
  );
};

export default TestSuiteProfile;
