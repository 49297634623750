import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { AiOutlineShareAlt, AiOutlineDownload } from "react-icons/ai";
import useModal from "../hooks/useModal";
import isSubmitting from "../utils/submitting";
import { useCreateZip } from "../../context/createZip-context";
import ManageFileSharingModal from "./ManageFileSharingModal";

const DownloadFolderButton = ({ query, documents, highlightedDocuments }) => {
  const { folderUuid } = useParams();
  const { creatingZip, createZipEvent } = useCreateZip();
  const { modal: shareModal, toggle: toggleShare } = useModal();

  return (
    <>
      {documents?.length > 0 && (
        <div className="d-flex">
          <Button onClick={toggleShare} color="link" className="p-0 me-3">
            <AiOutlineShareAlt className="tx-18 mb-3px" />
            Manage File Shares
          </Button>
          {shareModal && (
            <ManageFileSharingModal
              modal={shareModal}
              toggle={toggleShare}
              doc={{}}
              query={query}
              documents={documents}
              highlightedDocuments={highlightedDocuments}
            />
          )}
          <Button
            onClick={() =>
              createZipEvent(`zipped-folders`, {
                ...query,
                document_ids: highlightedDocuments.map(({ id }) => id),
                parent_uuid: folderUuid,
              })
            }
            disabled={creatingZip}
            color="link"
            className="p-0"
          >
            {isSubmitting(
              creatingZip,
              <>
                <AiOutlineDownload className="tx-16" /> Download{" "}
                {highlightedDocuments.length > 0 ? "Selected" : "All"} Files
              </>,
              "Zipping...",
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default DownloadFolderButton;
