import React from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import SampleVariableForm from "./SampleVariableForm";
import { TestSuiteProps } from "./testSuiteTypes";
import { toast } from "react-toastify";

const StandardVariables = (props: TestSuiteProps) => {
  const { testSuite } = props;

  const { takeAction }: IUseApi = useApi();

  const standardFields = testSuite.template.fields.filter(
    (field) => !field.incrementable_id,
  );

  const onSubmit = (values: any) => {
    return takeAction(
      "update",
      `/test-suites/${testSuite.template.uuid}/fields`,
      values,
    )
      .then(() => {
        toast.success("Updated");
      })
      .catch(formError);
  };

  return (
    <SampleVariableForm
      onSubmit={onSubmit}
      form="SampleStandardsVariables"
      variables={testSuite.template.template.variables}
      initialValues={{
        fields: standardFields,
      }}
      {...props}
    />
  );
};

export default StandardVariables;
