import React from "react";
import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import TestSuiteForm from "./TestSuiteForm";
import TestSuiteStatuses from "./TestSuiteStatuses";
import TestSuiteTemplate from "./TestSuiteTemplate";
import { TestSuite, TestSuitePages, TestSuiteProps } from "./testSuiteTypes";

const ShowTestSuitePage = (
  props: TestSuiteProps & { onSubmit: FormSubmitHandler<TestSuite> },
) => {
  const { testSuite, onSubmit, setTestSuite } = props;

  const { page } = useParams<{ page: TestSuitePages }>();

  switch (page) {
    case "details":
      return (
        <TestSuiteForm
          initialValues={testSuite}
          onSubmit={onSubmit}
          form="UpdateTestSuite"
        />
      );
    case "statuses":
      return <TestSuiteStatuses />;
    case "template":
      return (
        <TestSuiteTemplate setTestSuite={setTestSuite} testSuite={testSuite} />
      );
    default:
      return null;
  }
};

export default ShowTestSuitePage;
