import * as d3 from "d3";
import { OrgChart } from "d3-org-chart";
import { useLayoutEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import useModal from "../hooks/useModal";
import AddChildModal from "./AddChildModal";
import ChartControl from "./ChartControl";
import RemoveParentModal from "./RemoveParentModal";

export const OrgChartComponent = (props, ref) => {
  const d3Container = useRef(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const { modal, toggle } = useModal();
  const { modal: deleteModal, toggle: toggleDelete } = useModal();

  const toggleAdd = (position) => {
    setSelectedPosition(position);
    toggle();
  };

  const handleToggleDelete = (position) => {
    setSelectedPosition(position);
    toggleDelete();
  };

  let chart = null;

  useLayoutEffect(() => {
    if (props.data.length > 0 && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }
      var index = 0;
      var compact = 0;
      chart
        .container(d3Container.current)
        .data(props.data)
        .rootMargin(100)

        .nodeWidth((d) => 200)

        .nodeHeight((d) => 98)

        .childrenMargin((d) => 80)

        .neightbourMargin((n1, n2) => 90)

        .compactMarginBetween((d) => 75)

        .compactMarginPair((d) => 80)

        .siblingsMargin((d) => 90)

        .linkUpdate(function (d) {
          d3.select(this)

            .attr("stroke", (d) =>
              d.data._upToTheRootHighlighted ? "#152785" : "lightgray",
            )

            .attr("stroke-width", (d) =>
              d.data._upToTheRootHighlighted ? 5 : 1.5,
            )

            .attr("stroke-dasharray", "4,4");

          if (d.data._upToTheRootHighlighted) {
            d3.select(this).raise();
          }
        })
        .nodeContent(function (d) {
          return `<div id="chart_tree_${d.data.id}"></div>`;
        })
        .render()
        .expandAll()
        .buttonContent(function ({ node, state }) {
          reRenderAll();
          const icons = {
            left: (d) =>
              d
                ? `<div style="margin-top:-10px;line-height:1.2;font-size:25px;height:22px">‹</div>`
                : `<div style="margin-top:-10px;font-size:25px;height:23px">›</div>`,
            bottom: (d) =>
              d
                ? `<div style="margin-top:-20px;font-size:25px">ˬ</div>`
                : `<div style="margin-top:0px;line-height:1.2;height:11px;font-size:25px">ˆ</div>`,
            right: (d) =>
              d
                ? `<div style="margin-top:-10px;font-size:25px;height:23px">›</div>`
                : `<div style="margin-top:-10px;line-height:1.2;font-size:25px;height:22px">‹</div>`,
            top: (d) =>
              d
                ? `<div style="margin-top:0px;line-height:1.2;height:11px;font-size:25px">ˆ</div>`
                : `<div style="margin-top:-20px;font-size:25px">ˬ</div>`,
          };
          return `<div style="border-radius:3px;padding:3px;font-size:10px;margin:auto auto;background-color:lightgray"> ${icons[
            state.layout
          ](node.children)}  </div>`;
        });

      reRenderAll();

      function handleSwap() {
        chart
          .layout(["right", "bottom", "left", "top"][index++ % 4])
          .render()
          .fit();
      }

      function reRenderAll() {
        const container = document.getElementById("chart_tree_top_menu");
        const root = createRoot(container);
        root.render(
          <ChartControl
            chart={chart}
            swap={handleSwap}
            index={index}
            compact={compact}
          />,
        );

        props.data.forEach((d) => {
          if (document.getElementById(`chart_tree_${d.id}`)) {
            const container = document.getElementById(`chart_tree_${d.id}`);
            const root = createRoot(container);
            root.render(
              <ChartControl
                chart={chart}
                swap={handleSwap}
                index={index}
                compact={compact}
              />,
            );
          }
        });
      }
    }
  }, [props.data, d3Container.current, modal, deleteModal]);

  return (
    <div>
      {selectedPosition && (
        <AddChildModal
          toggle={toggle}
          modal={modal}
          position={selectedPosition}
          addChild={props.addChild}
        />
      )}
      {selectedPosition && (
        <RemoveParentModal
          toggle={toggleDelete}
          modal={deleteModal}
          position={selectedPosition}
          removeChild={props.removeChild}
        />
      )}
      <div ref={d3Container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "1px",
          }}
          id="chart_tree_top_menu"
        ></div>
      </div>
    </div>
  );
};
