import { useState } from "react";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import querystring from "query-string";
import ListAddModal from "./ListAddModal";

const CommentIcon = (props : any) => {
  const { commentableType, commentableId } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const {
    data: comments,
    setData: setComments,
    loading,
    response,
  } = useApi(
    commentableType && commentableId
      ? `comments?${querystring.stringify({
          commentable_type: commentableType,
          commentable_id: commentableId,
          paginate: 0
        })}`
      : "",
    [],
    true,
  );

  if (loading) return null;

  return (
    <>
      <button onClick={toggle} className="btn btn-link p-0" style={{ color: "white" }} type="button">
        <i className="fa fa-comments white"></i>
      </button>
      
      <ListAddModal
          {...props}
          toggle={toggle}
          modal={modal}
          comments={comments}
          setComments={setComments}
          response={response}
          />
    </>
  );
};

export default CommentIcon;
