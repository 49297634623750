import { Button } from "reactstrap";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import PaginatedList from "../pagination/PaginatedList";
import UserCreditCardModal from "./UserCreditCardModal";
import useUserCreditCards from "./hooks/useUserCreditCards";
import { UserCreditCard } from "./userCreditCardTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import { SiMastercard, SiVisa } from "react-icons/si";
import { FaEllipsisV } from "react-icons/fa";
import TextButton from "../utils/TextButton";
import { useQueryClient } from "react-query";
import { useAuth } from "../../context/auth-context";
import CommentIcon from "../comments/CommentIcon";
import dayjs from "dayjs";
import classNames from "classnames";

const UserCreditCards = () => {
  const { toggle, modal } = useModal();
  const { user } = useAuth();
  const { takeAction } = useApi();

  const queryClient = useQueryClient();

  const onSubmit = (values: UserCreditCard) => {
    return takeAction("store", "user-credit-cards", values)
      .then(() => {
        toast.success("User Credit Card Added");
        toggle();
        queryClient.invalidateQueries("user-credit-cards");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage titlePage="User Credit Cards" crumbs={[]} />
      <PaginatedList
        indexHook={useUserCreditCards}
        listName="userCreditCardsList"
        originalFilters={[]}
        itemCard={({ item }: { item: UserCreditCard }) => (
          <CreditCard
            card={item}
            canUpdate={user?.hasAccessTo(
              "App\\Models\\UserCreditCard",
              "update",
            )}
          />
        )}
        extraButtons={() =>
          user?.hasAccessTo("App\\Models\\UserCreditCard", "create") ? (
            <Button
              color="primary"
              className="ms-3"
              size="sm"
              onClick={() => toggle()}
            >
              Add New
            </Button>
          ) : null
        }
      />
      <UserCreditCardModal onSubmit={onSubmit} toggle={toggle} modal={modal} />
    </>
  );
};

export const CreditCard = ({
  card,
  canUpdate = true,
  wrapperClass = "col-lg-4",
}: {
  card: UserCreditCard;
  canUpdate?: boolean;
  wrapperClass?: string;
}) => {
  const { toggle, modal } = useModal();

  const { takeAction } = useApi();

  const queryClient = useQueryClient();

  const onSubmit = (values: UserCreditCard) => {
    return takeAction("update", `user-credit-cards/${card.uuid}`, values)
      .then(() => {
        toast.success("User Credit Card Added");
        toggle();
        queryClient.invalidateQueries("user-credit-cards");
      })
      .catch(formError);
  };

  return (
    <>
      <div className={classNames("mb-3", wrapperClass)}>
        <div
          className={`p-3 border shadow-lg text-white position-relative ${
            card.deleted_at ? "temple-gradient-deleted" : "temple-gradient"
          }`}
          style={{
            borderRadius: "1.3rem",
          }}
        >
          <div
            className="d-flex text-white mb-5 align-items-center"
            style={{ height: "0.85cm" }}
          >
            <img alt="" src="/img/card-chip.png" style={{ height: "30px" }} />
            <div className="ms-auto">
              {card.card_brand === "Visa" ? (
                <SiVisa className="tx-40 text-white" />
              ) : (
                <SiMastercard className="tx-40 text-white" />
              )}
            </div>
            {canUpdate && (
              <div className="ms-3">
                <TextButton onClick={toggle}>
                  <FaEllipsisV className="text-white" />
                </TextButton>
              </div>
            )}
          </div>
          <div className="d-flex form-group tx-26">
            **** **** **** {card.card_last_four}
          </div>
          {card.expiry_date && (
            <div className="d-flex align-items-center">
              <label className="ms-auto mg-lg-r-10 mg-lg-t-8">
                Expiry Date
              </label>
              <span>{dayjs(`${card.expiry_date}-01`).format("MM/YYYY")}</span>
            </div>
          )}
          <label className="mb-0">Card Holder Name</label>
          <p className="mb-0 text-white text-uppercase">
            {card.user.full_name}
          </p>

          <div className="d-flex align-items-center">
            <div className="ms-auto">
              <CommentIcon
                commentableType="App\Models\UserCreditCard"
                commentableId={card.id}
              />
            </div>
          </div>
        </div>
      </div>
      {canUpdate && (
        <UserCreditCardModal
          onSubmit={onSubmit}
          toggle={toggle}
          modal={modal}
          initialValues={card}
          title={`Edit ${card.user.full_name}'s Credit Card`}
        />
      )}
    </>
  );
};

export default UserCreditCards;
