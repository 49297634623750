import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";

import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import NumberField from "../utils/NumberField";
import SubmitButton from "../utils/SubmitButton";
import RequiredCompetencies from "../positions/RequiredCompetencies";

const FormOrganisationRole = (props) => {
  const { handleSubmit, onDelete, initialValues } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            name="name"
            type="text"
            component={renderField}
            label="Name"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="division_id"
            url="/organisationdivisions/divisions"
            label="Division"
            component={SelectInputAsync}
            required
            validate={required}
          />
        </div>
        <div className="col-lg-12">
          <NumberField {...props} />
        </div>
        {props.showCompetencies && (
          <FieldArray
            component={RequiredCompetencies}
            name="required_competencies"
            {...props}
          />
        )}
        {initialValues?.uuid && (
          <div className="form-group col-lg-6">
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={onDelete}
            >
              Delete
            </button>
          </div>
        )}
        <div
          className={`form-group ${
            initialValues?.uuid ? "col-lg-6" : "col-lg-12"
          }`}
        >
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "OrganisationRole" });

export default form(FormOrganisationRole);
