import {
  FETCH_PRODUCTIVITY,
  NEW_PRODUCTIVITY,
  UPDATE_PRODUCTIVITY,
  DELETE_PRODUCTIVITY,
  CLEAR_PRODUCTIVITY,
  ADD_PRODUCTIVITY,
} from "../actions/types";

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  const { items } = state;
  let newItems;
  switch (action.type) {
    case FETCH_PRODUCTIVITY:
      const manuallyAddedItems = state.items.filter(
        (item) => !item.pulled_from_schedule,
      );

      const formattedPayload = action.payload.map((event) => ({
        id: event.uuid,
        title: event.title,
        start: new Date(event.start_time),
        end: new Date(event.finish_time),
        comments: event.comments,
        job_id: event.job_id,
        project: event.job.project_id,
        is_job: event.is_job,
        pulled_from_schedule: true,
        break_type: event.break_type,
      }));

      return {
        ...state,
        items: [...manuallyAddedItems, ...formattedPayload],
      };
    case NEW_PRODUCTIVITY:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case UPDATE_PRODUCTIVITY:
      newItems = items.map((event) =>
        event.id === action.values.uuid ? action.payload : event,
      );

      return { ...state, items: newItems };
    case DELETE_PRODUCTIVITY:
      newItems = items.filter((event) => event.id !== action.payload);
      return {
        ...state,
        items: newItems,
      };
    case CLEAR_PRODUCTIVITY:
      return {
        ...state,
        items: [],
      };
    case ADD_PRODUCTIVITY:
      return {
        ...state,
        items: action.payload.map((event) => ({
          id: event.uuid,
          title: event.title,
          start: new Date(event.start_time),
          end: new Date(event.finish_time),
          comments: event.comments,
          job_id: event.job_id,
          project: event.project_id,
          job_name: event.job_name,
          project_name: event.project_name,
          job_link: event.job_link,
          internal: event.internal,
          break_type: event.break_type,
        })),
      };
    default:
      return state;
  }
}
