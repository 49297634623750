import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getEquipmentTypes = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `equipment-types?page=${pageParam}&filter[search]=${search}&paginate=20`,
    )
    .then(({ data }) => data);
};

export default function useEquipmentTypes(search?: string) {
  return useInfiniteQuery(
    ["equipmentType", { search }],
    (pageParam) => getEquipmentTypes(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
