import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormModal from "../utils/FormModal";
import formError from "../utils/formError";
import RenderField from "../utils/renderField";

interface PasswordModalProps {
  toggle: () => void;
  modal: boolean;
  onSuccess: () => void;
  form?: string;
}

const PasswordModal = (props: PasswordModalProps) => {
  const { modal, toggle, onSuccess, form = "PASSWORD_MODAL" } = props;

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<{ password: string }> = (values) => {
    return takeAction("store", "user/password-check", values)
      .then(() => {
        onSuccess();
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      title="Password Required"
      modal={modal}
      toggle={toggle}
      form={form}
      onSubmit={onSubmit}
    >
      <div className="col-12 form-group">
        <Field
          component={RenderField}
          type="password"
          name="password"
          label="Password"
        />
      </div>
    </FormModal>
  );
};

export default PasswordModal;
