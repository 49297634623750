import { useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import JobStatus from "../../enums/JobStatus";
import useApi from "../api/useApi";
import CommentList from "../comments/CommentList";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { statusDetails } from "../projectManagers/ProjectManagerJob";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import SupportComment from "./SupportComment";
import { SupportRequest } from "./supportRequestTypes";

const SupportRequestProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading, setData } = useApi(
    `support-requests/${uuid}`,
    null,
    true,
  );

  const supportRequest = data as SupportRequest;
  const status = statusDetails(
    supportRequest ?? {
      status: JobStatus.Pending,
    },
  );

  if (loading) {
    return <Spinner loading={true} />;
  }

  return (
    <>
      <HeaderPage
        crumbs={[
          { link: "/support-requests", name: "Support Requests" },
          {
            link: `/support-requests/${uuid}`,
            name: supportRequest.name,
            active: true,
          },
        ]}
        titlePage={supportRequest.name}
      />

      <Profile
        header={
          <DashboardHeader>
            <div className="d-flex align-items-start">
              <DashboardH3>{supportRequest.name}</DashboardH3>
              <Badge
                className="rounded-pill shadow ms-auto"
                color={status.color}
              >
                {supportRequest.string_status}
              </Badge>
            </div>
            <p className="mb-0">{supportRequest.project.name}</p>
          </DashboardHeader>
        }
        content={
          <>
            <SupportComment
              form="SupportComment"
              supportRequest={supportRequest}
              setData={setData}
            />
            <div className="bg-white border rounded-lg shadow p-4">
              <h6 className="slim-card-title mb-4">Comments</h6>
              {supportRequest.comments.length === 0 ? (
                <p className="mb-0">
                  No comments yet. Be the first to comment.
                </p>
              ) : (
                <CommentList comments={supportRequest.comments} />
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default SupportRequestProfile;
