import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getRecurringPayments = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(`recurring-payments?page=${pageParam}&filter[search]=${search}`)
    .then(({ data }) => data);
};

export default function useRecurringPurchases(search?: string) {
  return useInfiniteQuery(
    ["recurring-payments", { search }],
    (pageParam) => getRecurringPayments(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
