import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getBranchCpirs = (
  { pageParam = 1 },
  search: string,
  subject_id?: number,
  subject_type?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `cpirs?page=${pageParam}&filter[search]=${search}&filter[subject_type]=${subject_type}&filter[subject_id]=${subject_id}`,
    )
    .then(({ data }) => data);
};

export default function useBranchCpirs(
  search: string,
  subject_id?: number,
  subject_type?: string,
) {
  return useInfiniteQuery(
    ["branch-cpirs", { search, subject_id, subject_type }],
    (pageParam) => getBranchCpirs(pageParam, search, subject_id, subject_type),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
