import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import TextButton from "../utils/TextButton";
import AddCustomJobTemplateModal from "./AddCustomJobTemplateModal";
import { CustomJobTemplate, CustomJobState } from "./customJobTypes";
import EditCustomJobTemplate from "./EditCustomJobTemplate";

const CustomJobJobTemplate = (props: CustomJobState) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { toggle, modal } = useModal();

  const [selectedTemplate, setSelectedTemplate] =
    useState<CustomJobTemplate | null>();

  const { data, loading, setData }: IUseApi<CustomJobTemplate[]> = useApi(
    `/custom-jobs/${uuid}/templates`,
    [],
    true,
  );

  const { takeAction, loading: deleting }: IUseApi = useApi();
  const templates = data as CustomJobTemplate[];

  const deleteTemplate = (template: CustomJobTemplate) => {
    return deleteSwal(template.name)
      .then(() =>
        takeAction("destroy", `custom-job-templates/${template.uuid}`),
      )
      .then(() => {
        setData(templates.filter((t) => t.uuid !== template.uuid));
        setSelectedTemplate(null);
      })
      .catch(errorSwal);
  };

  if (loading) return <Spinner loading />;

  return (
    <>
      <div className="d-flex mb-3 align-items-center">
        <Button size="sm" outline color="primary" onClick={toggle}>
          Add New Template
        </Button>
        {selectedTemplate && (
          <div className="d-flex ms-auto space-x-2">
            <DeleteButton
              deleting={deleting}
              deleteFunction={() => deleteTemplate(selectedTemplate)}
            />
            <TextButton
              disabled={deleting}
              onClick={() => setSelectedTemplate(null)}
            >
              <GrClose />
            </TextButton>
          </div>
        )}
      </div>
      {selectedTemplate ? (
        <>
          <EditCustomJobTemplate
            form="EDIT_CUSTOM_JOB_TEMPLATE"
            initialValues={selectedTemplate}
            templates={templates}
            setTemplates={setData}
            setSelectedTemplate={setSelectedTemplate}
          />
        </>
      ) : (
        <div className="space-y-5 mt-3">
          {data?.map((template) => (
            <button
              type="button"
              className="p-3 bg-white border w-100 text-start text-secondary"
              onClick={() => setSelectedTemplate(template)}
              key={template.id}
            >
              {template.name}
            </button>
          ))}
        </div>
      )}
      <AddCustomJobTemplateModal
        templates={templates}
        setTemplates={setData}
        toggle={toggle}
        modal={modal}
      />
    </>
  );
};

export default CustomJobJobTemplate;
