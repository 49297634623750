import React from "react";
import { Badge } from "reactstrap";
import { money } from "../form/formatters";

const PayableBadges = ({
  overdue,
  unpaid,
}: {
  overdue: number;
  unpaid: number;
}) => {
  return (
    <div className="d-flex">
      <div className="ms-auto align-self-end d-flex mt-3">
        {overdue ? (
          <Badge color="danger" className="rounded-pill shadow">
            {money.format(overdue)} overdue.
          </Badge>
        ) : null}
        {unpaid ? (
          <Badge color="info" className="ms-3 rounded-pill shadow">
            {money.format(unpaid)} unpaid.
          </Badge>
        ) : null}
      </div>
    </div>
  );
};

export default PayableBadges;
