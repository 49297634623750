import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { DataResponse, IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import Empty from "../utils/Empty";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import isSubmitting from "../utils/submitting";
import TextButton from "../utils/TextButton";
import SubjectItemEditor from "./SubjectItemEditor";
import SubjectItemSidebar from "./SubjectItemSidebar";
import { ISubjectItem } from "./subjectTypes";

const SubjectItems = (props: any) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();

  const {
    data: items,
    setData: setItems,
    loading,
  }: IUseApi<ISubjectItem[]> = useApi(`subjects/${uuid}/items`);

  const { takeAction, loading: adding }: IUseApi = useApi();

  const addItem = () => {
    return takeAction("store", `subjects/${uuid}/items`)
      .then(({ data }: DataResponse<ISubjectItem>) => {
        setItems([...(items ?? []), data.data]);
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <CustomScaleLoader>Fetching Items...</CustomScaleLoader>;
  }

  if (items?.length === 0) {
    return (
      <div className="mt-5 skinny-page">
        <Empty top={false}>
          <div className="text-center mt-4">
            <p className="fw-bolder text-dark mb-2">No subject items added.</p>
            <p className="mb-1">
              Subject items are the content within your subject that tells
              employees how to undertake certain tasks.
            </p>
            {user?.hasAccessTo("App\\Models\\SubjectItem", "create") &&
            !props.subject?.is_read_only ? (
              <p className="mb-0">
                To get started{" "}
                <TextButton disabled={adding} onClick={addItem}>
                  {isSubmitting(adding, "click here.", "adding...")}
                </TextButton>
              </p>
            ) : (
              <p className="mb-0">
                Ask an administrator to add subject items to get started!
              </p>
            )}
          </div>
        </Empty>
      </div>
    );
  }

  return (
    <>
      <div className="position-relative skinny-page">
        <div className="space-y-7">
          {items?.map((item) => {
            return (
              <SubjectItemEditor
                key={item?.uuid}
                item={item}
                items={items}
                setItems={setItems}
              />
            );
          })}
        </div>
        <SubjectItemSidebar items={items} setItems={setItems} />
      </div>
      {user?.hasAccessTo("App\\Models\\SubjectItem", "create") &&
        !props.subject?.is_read_only && (
          <div className="d-flex">
            <Button
              color="primary"
              className="mt-3 ms-auto"
              onClick={addItem}
              disabled={adding}
            >
              {isSubmitting(adding, "Add New Item", "Adding...")}
            </Button>
          </div>
        )}
    </>
  );
};

export default SubjectItems;
