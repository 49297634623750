import {
  FETCH_NOTICEBOARDS,
  UPDATE_NOTICEBOARDS,
  NEW_NOTICEBOARDS,
  DELETE_NOTICEBOARDS
} from "../actions/types";

const initialState = {
  items: [],
  item: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTICEBOARDS:
      return {
        ...state,
        items: action.payload
      };
    case UPDATE_NOTICEBOARDS:
      return {
        ...state,
        items: action.payload
      };
    case NEW_NOTICEBOARDS:
      return {
        ...state,
        items: [action.payload, ...state.items]
      };
    case DELETE_NOTICEBOARDS:
      return {
        ...state,
        items: state.items.filter(item => item.notice.uuid !== action.payload)
      };
    default:
      return state;
  }
}
