import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import isSubmitting from "../utils/submitting";
import { RouteParams } from "./projectForm";

const DeleteButton = () => {
  const { uuid } = useParams<RouteParams>();
  const history = useHistory();
  const { takeAction, loading } = useApi();

  const deleteCustomJob = () => {
    deleteSwal("Project Form")
      .then(() => takeAction("destroy", `project-forms/${uuid}`))
      .then(() => {
        history.push("/project-forms");
        toast.success("Project Form Deleted");
      })
      .catch(errorSwal);
  };

  return (
    <Button
      onClick={deleteCustomJob}
      outline
      color="danger"
      block
      className="mt-3"
      disabled={loading}
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </Button>
  );
};

export default DeleteButton;
