import { Link } from "react-router-dom";
import PaginatedCard from "../pagination/PaginatedCard";
import { TestSuiteTest } from "./testSuiteTypes";

const TestSuiteTestItem = ({ test }: { test: TestSuiteTest }) => {
  return (
    <PaginatedCard
      colSize="6"
      info={test.samples.map((sample) => {
        return {
          name: sample.number,
          value: "",
        };
      })}
      header={
        <div>
          <Link to={test.link} className="mb-0 tx-inverse fw-bolder">
            {test.test_suite.name}
          </Link>
          <p className="text-secondary tx-12 mb-2">
            {test?.current_status?.name}
          </p>
        </div>
      }
    />
  );
};
export default TestSuiteTestItem;
