import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import AddModal from "./AddModal";
import HeaderPage from "../header/HeaderPage";
import ReactTable from "../table/ReactTable";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import errorSwal from "../utils/errorSwal";
import useModal from "../hooks/useModal";
import { ChartOfAccount } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const Index = () => {
  const { data: categories, setData, takeAction, loading } = useApi(
    "tracking-categories",
    [],
  );

  const { toggle, modal } = useModal();

  const onSubmit = (values, dispatch) => {
    return takeAction("store", "tracking-categories", values)
      .then(({ data }) => {
        setData([...categories, data.data]);
        dispatch(reset("AddTrackingCategory"));
        toast.success("Category Added");
        toggle();
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: info => info.getValue(),
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/tracking-categories/" />
      <HeaderPage
        titlePage="Tracking Categories"
        pageDescription={
          <>
            A tracking category is a classification system that groups similar
            transactions, expenses, or assets together for more effective
            management and analysis, such as by department, project, cost
            center, location, depreciation method, or asset type.
          </>
        }
        relatedLinks={[
          {
            name: "Chart of Accounts",
            link: "/chart-of-accounts",
            model: ChartOfAccount,
          },
        ]}
        crumbs={[
          {
            link: "/organisations/settings/links",
            name: "Organisation",
          },
          {
            link: "/tracking-categories",
            name: "Tracking Categories",
            active: true,
          },
        ]}
      />
      <Button className="mb-3" color="primary" outline onClick={toggle}>
        Add New Tracking Category
      </Button>
      <ReactTable loading={loading} data={categories} columns={columns} />
      <AddModal
        toggle={toggle}
        modal={modal}
        form="AddTrackingCategory"
        onSubmit={onSubmit}
        title="Add New Tracking Category"
      />
    </>
  );
};

export default Index;
