import React from "react";
import { Link } from "react-router-dom";
import { money, percentage } from "../form/formatters";
import ReactTable from "../table/ReactTable";

const BudgetList = ({ budgets, fetchingBudgets }) => {
  const columns = [
    {
      accessorKey: "date_range",
      header: "Date Range",
      cell: (info) => (
        <Link to={`/budgets/${info.row.original.uuid}`}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: (info) => money.format(info.getValue()),
    },
    {
      accessorKey: "amount_spent",
      header: "Amount Spent",
      cell: (info) => money.format(info.getValue()),
    },
    {
      accessorKey: "percentage_spent",
      header: "Percentage Spent",
      cell: (info) => percentage.format(info.getValue()),
    },
  ];

  return (
    <ReactTable data={budgets} columns={columns} loading={fetchingBudgets} />
  );
};

export default BudgetList;
