import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import errorSwal from "../utils/errorSwal";

const useUploadLogo = (organisation, setOrganisation) => {
  const { takeAction, loading } = useApi();
  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();
  const { user } = useAuth();

  const addLogo = (file) => {
    return upload(
      file,
      `organisations/${user.active_organisation.uuid}/logos`,
      (file) => {
        return takeAction("update", "organisation/logo", {
          file,
        })
          .then(({ data }) => {
            setUploadPercent(0);
            setOrganisation({
              ...organisation,
              logo: data.data,
            });
            toast.success("Logo Updated");
          })
          .catch(errorSwal);
      },
    );
  };

  return {
    addLogo,
    loading,
    uploadPercent,
  };
};

export default useUploadLogo;
