import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getProjectManagerJobs = (
  { pageParam = 1 },
  projectUuid: string,
  search?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `project-manager-dashboard/${projectUuid}/jobs?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useProjectManagerJobs(
  projectUuid: string,
  viewJobs: boolean,
  search?: string,
) {
  return useInfiniteQuery(
    [
      "project-manager-jobs",
      {
        search,
        projectUuid,
      },
    ],
    (pageParam) => getProjectManagerJobs(pageParam, projectUuid, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
      refetchOnWindowFocus: false,
      enabled: viewJobs,
    },
  );
}
