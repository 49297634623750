import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getAuditLogs = ({ pageParam = 1 }, search?: string, subjectType?:string, uuid?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `activity-logs?page=${pageParam}&filter[search]=${search}&subject_type=${encodeURIComponent(subjectType ?? "")}&uuid=${uuid}`,
    )
    .then(({ data }) => data);
};

export default function useAuditLogs(search?: string, subjectType?: string, uuid?: string) {
  return useInfiniteQuery(
    ["audit-logs", { search, subjectType, uuid }],
    (pageParam) => getAuditLogs(pageParam, search, subjectType, uuid),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}