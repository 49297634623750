import { Link, useHistory, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import ApprovalComments from "../approvals/ApprovalComments";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import ProfilePage from "../profile";
import formatDate from "../utils/formatDate";
import ApprovalStatus from "../user/ApprovalStatus";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import Form from "./Form";

const Profile = () => {
  const { uuid } = useParams();

  const { takeAction, loading: deleting } = useApi();

  const { user } = useAuth();

  const history = useHistory();

  const {
    data: competency,
    setData: setCompetency,
    loading,
  } = useApi(`staff-competencies/${uuid}`, null, true, "/staff-matrix");

  if (loading || !competency) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            link: `/staff-matrix/users/${competency.user.uuid}`,
            name: "Staff Matrix",
          },
          {
            link: "/",
            name: `${competency?.user?.name} - ${competency?.skill_level?.full_name}`,
            active: true,
          },
        ]}
        titlePage={`${competency?.user?.name} - ${competency?.skill_level?.full_name}`}
      />
      <ProfilePage
        header={
          <DashboardHeader>
            <DashboardH3>
              {competency?.user?.name} - {competency?.skill_level?.full_name}
            </DashboardH3>
            {competency.job && (
              <DashboardSubTitle>
                View job <Link to={competency.job.link}>here</Link>.
              </DashboardSubTitle>
            )}
          </DashboardHeader>
        }
        content={
          <>
            <CardRow close={false} competency={competency} />

            <Form
              initialValues={competency}
              competency={competency}
              setCompetency={setCompetency}
            />
          </>
        }
        sideBar={
          <>
            <ApprovalButton
              approvalAction={competency?.approval_action}
              wrapperClass="w-100"
              canTransfer
            />
            <div className="d-block my-3">
              <ApprovalStatus
                status={competency.approval_status}
                className="w-100 py-2"
              />
            </div>
            {user.is_admin && (
              <Button
                color="danger"
                className="mb-3"
                block
                outline
                disabled={deleting}
                onClick={() =>
                  takeAction("destroy", `/staff-competencies/${uuid}`)
                    .then(() => history.push("/staff-matrix"))
                    .catch(errorSwal)
                }
              >
                Delete
              </Button>
            )}
            <ApprovalComments
              modelType="App\Models\StaffCompetency"
              modelId={competency?.id}
              className="mt-3"
            />

            <div className="mt-3">
              <SideList
                commentableType="App\Models\StaffCompetency"
                commentableId={competency.id}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default Profile;

export const CardRow = ({ competency, close = true }) => {
  return (
    <div className="row mb-3">
      <div className={`col-${close ? "10" : "12"}`}>
        <div className="bg-white border p-4">
          <div className="d-flex">
            <p className="mb-0">
              Level:{" "}
              <span className="text-dark">
                {competency.skill_level && competency.skill_level.level}
              </span>
              <br />
              Skill:{" "}
              <span className="text-dark">
                {competency.skill_level && competency.skill_level.name}
              </span>
              <br />
              Date:
              <span className="text-dark fw-bold fs-5">
                {formatDate(competency.added_on)}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
