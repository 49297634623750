import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import errorSwal from "../components/utils/errorSwal";
import useApi from "../components/api/useApi";

export const CreateZipContext = createContext<{
  createZipEvent: (url: string, data: any) => void;
}>({
  createZipEvent: (url, data) => {},
});

const CreateZipProvider = (props: any) => {
  const [creatingZip, setCreatingZip] = useState(false);
  const [zipUrl, setZipUrl] = useState();
  const { takeAction } = useApi();

  const waitMs = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

  function createZipEvent(url: string, data: any) {
    setCreatingZip(true);
    takeAction("store", `${url}`, {
      ...data,
    })
      .then(({ data }: { data: { zipUuid: string } }) => {
        return data?.zipUuid;
      })
      .then((zipUuid: string) => {
        if (zipUuid) {
          (async () => {
            for (let i = 0; i <= 300; i++) {
              takeAction("show", `${url}/status?zipUuid=${zipUuid}`)
                .then(({ data }: { data: any }) => {
                  if (data.path) {
                    setCreatingZip(false);
                    setZipUrl(data.path);
                    i = 300;
                    toast.success(
                      "Zip file created. Please download within 15 minutes.",
                    );
                  }
                })
                .catch((e: any) => {
                  setCreatingZip(false);
                  errorSwal(e);
                  toast.warning("Unable to create zip file. Please try again.");
                });
              await waitMs(3000);
            }
          })();
        }
      })
      .catch((e: any) => {
        setCreatingZip(false);
        errorSwal(e);
        toast.warning("Unable to create zip file. Please try again.");
      });
  }

  if (zipUrl) {
    (async () => {
      await waitMs(899000);
      setZipUrl(undefined);
    })();
  }

  return (
    <CreateZipContext.Provider
      value={{
        creatingZip,
        zipUrl,
        createZipEvent,
        setZipUrl,
      }}
      {...props}
    />
  );
};

const useCreateZip = () => {
  const context = useContext(CreateZipContext);
  if (context === undefined) {
    throw new Error("useCreateZip must be used within a CreateZipProvider");
  }
  return context;
};

export { CreateZipProvider, useCreateZip };
