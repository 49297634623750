import React from "react";
import dayjs from "dayjs";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { ProjectStatus } from "../projects/projectTypes";
import { IProjectManagerProject } from "./projectManagerTypes";

interface TimeLeftInProjectProps {
  project: IProjectManagerProject;
}

const TimeLeftInProject = ({ project }: TimeLeftInProjectProps) => {
  const daysLeft = dayjs(project.scheduled_finish_date).diff(dayjs(), "days");

  const scheduled = `Schedule Finish Date: ${dayjs(
    project.scheduled_finish_date,
  ).format("DD/MM/YYYY")}`;

  if (project.status === ProjectStatus.CLOSED) {
    return (
      <>
        <Badge id={`time_left_${project.uuid}`} color="success">
          <AiOutlineClockCircle className="me-1" />
          Closed
        </Badge>
        <UncontrolledTooltip
          placement="top"
          target={`time_left_${project.uuid}`}
        >
          {scheduled}
        </UncontrolledTooltip>
      </>
    );
  }

  if (daysLeft <= 0) {
    return (
      <>
        <Badge id={`time_left_${project.uuid}`} color="danger">
          <AiOutlineClockCircle className="me-1" />
          Overdue{" "}
        </Badge>
        <UncontrolledTooltip
          placement="top"
          target={`time_left_${project.uuid}`}
        >
          {scheduled}
        </UncontrolledTooltip>
      </>
    );
  }

  if (daysLeft >= 7) {
    return (
      <>
        <Badge id={`time_left_${project.uuid}`} color="secondary">
          <AiOutlineClockCircle className="me-1" />
          {daysLeft} days left
        </Badge>
        <UncontrolledTooltip
          placement="top"
          target={`time_left_${project.uuid}`}
        >
          {scheduled}
        </UncontrolledTooltip>
      </>
    );
  }

  if (daysLeft < 7) {
    return (
      <>
        <Badge id={`time_left_${project.uuid}`} color="warning">
          <AiOutlineClockCircle className="me-1" />
          {daysLeft} day{daysLeft === 1 ? "" : "s"} left
        </Badge>
        <UncontrolledTooltip
          placement="top"
          target={`time_left_${project.uuid}`}
        >
          {scheduled}
        </UncontrolledTooltip>
      </>
    );
  }
  return null;
};

export default TimeLeftInProject;
