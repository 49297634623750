import { EquipmentStatus } from "./../equipment/equipmentTypes";
import { UrlValues } from "./CustomFieldFilter";

interface Option {
  label: string;
  value: any;
}
export interface FilterType {
  name: string;
  label: string;
  options?: Option[];
  url?: string;
  formatData?: (data: any[]) => Option[];
  signOptions?: boolean;
}

export const filters = (value: UrlValues): FilterType[] => {
  switch (value) {
    case "/custom-fields/equipment":
      return [
        {
          label: "Type",
          name: "type_id",
          url: "/custom-fields/equipment-types",
          signOptions: true,
          formatData: (data) => {
            return data.map((d) => {
              return {
                label: d.name,
                value: d.id,
              };
            });
          },
        },
        {
          label: "Group",
          name: "type.group",
          url: "/custom-fields/equipment-type-groups",
          signOptions: true,
          formatData: (data) => {
            return data.map((d) => {
              return {
                label: d.name,
                value: d.id,
              };
            });
          },
        },
        {
          label: "Status",
          name: "withdrawn",
          signOptions: true,
          options: [
            {
              label: "In Service",
              value: EquipmentStatus.InService,
            },
            {
              label: "Out of Service",
              value: EquipmentStatus.OutOfService,
            },
            {
              label: "Withdrawn",
              value: EquipmentStatus.Withdrawn,
            },
            {
              label: "Hired Out",
              value: EquipmentStatus.HiredOut,
            },
            {
              label: "Hiring",
              value: EquipmentStatus.Hiring,
            },
            {
              label: "Loaned Out",
              value: EquipmentStatus.LoanedOut,
            },
            {
              label: "Loaned In",
              value: EquipmentStatus.LoanedIn,
            },
          ],
        },
        {
          label: "Calibration Status",
          name: "in_calibration",
          options: [
            {
              label: "In Calibration",
              value: true,
            },
            {
              label: "Out of Calibration",
              value: false,
            },
          ],
        },
      ];
    case "/custom-fields/users":
      return [
        {
          label: "Employed",
          name: "employed",
          options: [
            {
              label: "Currently Employed",
              value: true,
            },
            {
              label: "Not Currently Employed",
              value: false,
            },
          ],
        },
        {
          label: "Skill",
          name: "skill",
          url: "/skill-levels",
          formatData: (data) => {
            return data.map((d) => {
              return {
                label: d.full_name,
                value: d.id,
              };
            });
          },
        },
      ];
    case "/custom-fields/suppliers":
      return [
        {
          label: "Subcontractor",
          name: "is_subcontractor",
          options: [
            {
              label: "Is Subcontractor",
              value: true,
            },
            {
              label: "Is Not Subcontractor",
              value: false,
            },
          ],
        },
      ];
    default:
      return [];
  }
};
