import { Job } from "./jobTypes";

const SharedJobProfile = ({ job }: { job: Job }) => {
  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-md-6">
          <h6 className="text-dark">Branch</h6>
          <p>{job.branch.name || "Not specified"}</p>
        </div>
        <div className="col-12">
          <h6 className="text-dark">Description</h6>
          <p>{job.description}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-6">
          <h6 className="text-dark">Job Manager</h6>
          <p>{job.manager.name}</p>
        </div>
        <div className="col-md-6">
          <h6 className="text-dark">Critical Path</h6>
          <p>{job.critical_path ? "Yes" : "No"}</p>
        </div>
        <div className="col-md-6">
          <h6 className="text-dark">Job Type</h6>
          <p>{job.job_type.name}</p>
        </div>
        <div className="col-md-6">
          <h6 className="text-dark">Organisation</h6>
          <p>{job.organisation.name}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <h6 className="text-dark">Start Date</h6>
          <p>{job.scheduled_start_date}</p>
        </div>
        <div className="col-md-6">
          <h6 className="text-dark">Finish Date</h6>
          <p>{job.scheduled_finish_date}</p>
        </div>
        <div className="col-md-6">
          <h6 className="text-dark">Estimated Hours</h6>
          <p>{job.estimated_hours}</p>
        </div>
        <div className="col-md-6">
          <h6 className="text-dark">Estimated Value</h6>
          <p>${job.estimated_value}</p>
        </div>
      </div>
    </div>
  );
};

export default SharedJobProfile;
