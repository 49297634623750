import { useParams } from "react-router-dom";
import NotFound from "../../404";
import { TestSuiteTestPages, TestSuiteTestProps } from "../testSuiteTypes";
import TestSuiteTests from "./TestSuiteTests";

const ShowTestSuiteTestPage = (props: TestSuiteTestProps) => {
  const { page } = useParams<{ page: TestSuiteTestPages }>();

  switch (page) {
    case "details":
      return <div>Details</div>;
    case "tests":
      return <TestSuiteTests {...props} />;
    default:
      return <NotFound />;
  }
};

export default ShowTestSuiteTestPage;
