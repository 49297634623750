import React from "react";
import ReactTable from "../table/ReactTable";
import SVGContainer from "../utils/SVGContainer";
import { ReactComponent as Empty } from "../../svgs/empty.svg";

const List = ({ laboratories, loading }) => {
  const columns = [
    {
      accessorKey: "branch.name",
      header: "Branch",
    },
    {
      accessorKey: "accreditation_number",
      header: "Accreditation Number",
    },
    {
      accessorKey: "site_number",
      header: "Site Number",
    },
    {
      accessorKey: "annex",
      header: "Is Annex",
      cell: (info) => (info.getValue() === 0 ? "No" : "Yes"),
    },
  ];

  if (laboratories.length === 0 && !loading) {
    return (
      <SVGContainer width="25%" SVG={Empty}>
        <p>No Labs found, click above to get adding!</p>
      </SVGContainer>
    );
  }

  return <ReactTable data={laboratories} columns={columns} loading={loading} />;
};

export default List;
