import dayjs from "dayjs";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { EmploymentDetail } from "../employmentDetails/employmentDetailTypes";
import { money } from "../form/formatters";
import BottomLogo from "../utils/BottomLogo";
import CustomScaleLoader from "../utils/scaleLoader";
import { useEffect, useMemo, useState } from "react";
import useFilter from "../hooks/useFilter";
import FilterDropdown from "../utils/FilterDropdown";
import SVGContainer from "../utils/SVGContainer";
import { ReactComponent as NoData } from "../../svgs/no_data.svg";
import SearchInput from "../search/SearchInput";

const UsersStaffRoles = ({ user }: { user: { id: number } }) => {
  const { userId } = useParams<{ userId: string }>();
  const { user: authUser } = useAuth();

  const defaultFilters = useMemo(() => {
    return [
      {
        label: "Status",
        name: "is_finished",
        options: [
          {
            label: "Active",
            value: 1,
            // selected: true,
          },
          {
            label: "Finished",
            value: 0,
          },
        ],
      },
    ];
  }, []);

  const [staffRoles, setStaffRoles] = useState<EmploymentDetail[]>([]);

  const { filters, toggleFilter, filterCount, stringified } =
    useFilter(defaultFilters);

  const { data, loading, setUrl }: IUseApi<EmploymentDetail[]> = useApi(
    `users/${userId}/staff-roles?${stringified}`,
    [],
    true,
  );

  useEffect(() => {
    setUrl(`users/${userId}/staff-roles?${stringified}`);
  }, [stringified]);

  const payUnits: string[] = ["", "per Hour", "per Annum"];

  const searchHandler = (search: string) => {
    if (data?.length !== 0) {
      const filteredData = data?.filter((item) => {
        const filter =
          item.dropdown_name?.toLocaleLowerCase().includes(search) ||
          item.award_abbreviation.toLocaleLowerCase().includes(search) ||
          item.remuneration.toString().includes(search) ||
          dayjs(item.start_date).format("DD/MM/YYYY")?.includes(search) ||
          dayjs(item.finish_date).format("DD/MM/YYYY")?.includes(search);
        return filter;
      });
      setStaffRoles(filteredData || []);
    }
  };

  useEffect(() => {
    if (data) {
      setStaffRoles(data);
    }
  }, [data]);

  if (loading) {
    return (
      <CustomScaleLoader>Fetching Employment Details...</CustomScaleLoader>
    );
  }

  return (
    <>
      {authUser?.is_admin && (
        <div className="mb-3">
          <Link
            className="btn btn-outline-primary mb-4"
            to={`/employment-details/add?user_id=${user?.id}`}
          >
            Add Employment Detail
          </Link>
          <div className="d-flex justify-content-between align-items-center">
            <SearchInput
              setValue={searchHandler}
              wrapperclass="search-box shadow-sm d-flex flex-grow-1"
            />
            <FilterDropdown
              filters={filters}
              toggleFilter={toggleFilter}
              filterCount={filterCount}
            />
          </div>
        </div>
      )}
      <div className="space-y-3">
        {staffRoles?.length !== 0 ? (
          staffRoles?.map((staffRole) => {
            return (
              <div className="bg-white border shadow-sm position-relative hover-no-shadow">
                <Link to={authUser?.is_admin ? staffRole.link : "#"}>
                  <div className="p-3">
                    <p className="mb-3 fw-bolder tx-inverse tx-16">
                      {staffRole.role_branch}
                    </p>
                    <p className="mb-1 text-secondary">
                      {staffRole.employment_basis} -{" "}
                      <span className="tx-inverse">
                        {money.format(staffRole.remuneration)}{" "}
                      </span>
                      {payUnits[staffRole.units]}
                    </p>
                    <p className="mb-0 text-secondary">
                      {staffRole.award_abbreviation} Award
                    </p>
                    <div className="d-flex space-x-1 align-items-center">
                      {staffRole.start_date && (
                        <p className="mb-1 text-secondary">
                          Starting on the{" "}
                          <span className="tx-inverse fw-bolder">
                            {dayjs(staffRole.start_date).format("DD/MM/YYYY")}
                          </span>
                        </p>
                      )}
                      {staffRole.finish_date && (
                        <p className="mb-1 text-secondary">
                          - Ending on the{" "}
                          <span className="tx-inverse fw-bolder">
                            {dayjs(staffRole.finish_date).format("DD/MM/YYYY")}
                          </span>
                        </p>
                      )}
                      <div className="ms-auto">
                        <div
                          className={`bg-${
                            staffRole.is_active
                              ? "success"
                              : staffRole.finish_date
                              ? "secondary"
                              : "info"
                          } shadow rounded-pill text-white tx-10 text-center px-2`}
                        >
                          {staffRole.is_active
                            ? "Active"
                            : staffRole.finish_date
                            ? "Finished"
                            : `Begins on ${dayjs(staffRole.start_date).format(
                                "DD/MM/YYYY",
                              )}`}
                        </div>
                      </div>
                    </div>
                    <BottomLogo />
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <div className="mt-4">
            <SVGContainer SVG={NoData} width="20%" top={false}>
              <p className="mt-5 mb-0 tx-inverse tx-medium">No data found</p>
            </SVGContainer>
          </div>
        )}
      </div>
    </>
  );
};

export default UsersStaffRoles;
