import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { money } from "../form/formatters";
import PaginatedList from "../pagination/PaginatedList";
import ResourcePurchaseList from "../purchases/ResourcePurchaseList";
import useProjectPurchases from "../purchases/hooks/useProjectPurchases";
import ReactTable from "../table/ReactTable";
import FormModal from "../utils/FormModal";
import FormDeliverable from "./DeliverableForm";
import useJobProductivity from "./hooks/useJobProductivity";
import useApi from "../api/useApi";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { arrayPush } from "redux-form";

const DeliverablesModal = (props) => {
  const { modal, toggle, jobId, job, arrayPush } = props;

  const { user } = useAuth();

  const { takeAction } = useApi();

  const { data: pricingItems } = useApi(`pricing-items`, []);

  const { data: units } = useApi(`billable-item-units`, []);

  return (
    <FormModal
      title="Add New Deliverable"
      form="DeliverableDetails"
      onSubmit={props.onSubmit}
      className="wd-md-1000 w-95 mx-wd-1400"
      toggle={toggle}
      modal={modal}
      initialValues={props.initialValues}
    >
      {({ change }) => (
        <>
          <div className="col-lg-6 space-y-3">
            <div style={{ maxHeight: "48vh", overflowY: "auto" }}>
              <label className="section-title mt-3">Productivity</label>
              <hr className="w-100" />
              <PaginatedList
                indexHook={useJobProductivity}
                indexHookArguments={[jobId]}
                list={({ data }) => {
                  return (
                    <div className="col-12">
                      <ProductivityTable
                        productivity={data}
                        arrayPush={arrayPush}
                        units={units}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div style={{ maxHeight: "48vh", overflowY: "auto" }}>
              <label className="section-title mt-3">Purchases</label>
              <hr className="w-100" />
              <PaginatedList
                indexHook={useProjectPurchases}
                indexHookArguments={[job.project.uuid]}
                showFilters={false}
                originalFilters={[
                  {
                    label: "only show purchase",
                    name: "only_show_purchases",
                    options: [
                      {
                        label: "true",
                        value: true,
                        selected: true,
                      },
                    ],
                  },
                ]}
                list={({ data }) => {
                  return (
                    <div style={{ overflowY: "auto" }} className="col-12">
                      <ResourcePurchaseList
                        purchases={data ?? []}
                        project={props.job.project}
                        hideActions={true}
                        extraColumns={[
                          {
                            accessorKey: "deliverables",
                            header: "Deliverables",
                            cell: (info) => {
                              const deliverables = info.getValue();

                              return (
                                <div className="d-flex space-x-2">
                                  {deliverables?.map((deliverable) => {
                                    return (
                                      <Link
                                        to={`/deliverables/${deliverable.uuid}/details`}
                                      >
                                        {deliverable.name}
                                      </Link>
                                    );
                                  })}
                                </div>
                              );
                            },
                          },
                          {
                            accessorKey: "add_deliverable",
                            header: "Add Deliverable",
                            cell: (info) => {
                              const purchase = info.row.original;
                              return (
                                <Button
                                  onClick={() => {
                                    takeAction(
                                      "show",
                                      purchase.is_purchase_order
                                        ? `/purchase-orders/${purchase.uuid}`
                                        : `/credit-card-receipts/${purchase.uuid}`,
                                    ).then(({ data }) => {
                                      getPricingInformation(
                                        data.data,
                                        pricingItems,
                                        units,
                                        arrayPush,
                                      );
                                    });
                                  }}
                                  outline
                                  color="primary"
                                  size="sm"
                                >
                                  Add
                                </Button>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <label className={`section-title mt-3`}>
                  Deliverable Details
                </label>
                {user.is_position_admin && (
                  <div className="d-flex align-items-center space-x-5">
                    <div>
                      <p className="mb-0  text-dark">
                        {money.format(job.estimated_value)}
                      </p>
                      <small>Estimated Value</small>
                    </div>
                    <p className="mb-0">/</p>
                    <div>
                      <p className="mb-0  text-dark">
                        {money.format(job.completed_value)}
                      </p>
                      <small>Actual Value</small>
                    </div>
                  </div>
                )}
              </div>
              <FormDeliverable
                file
                name
                jobId={jobId}
                change={change}
                pricingItems={pricingItems}
                units={units}
                {...props}
              />
            </div>
          </div>
        </>
      )}
    </FormModal>
  );
};

const getPricingInformation = (purchase, pricingItems, units, arrayPush) => {
  const testMethods = pricingItems.filter(
    (item) => item.type === "App\\Models\\TestMethod",
  );

  if (purchase.inter_entity_invoice) {
    purchase.inter_entity_invoice.line_items.forEach((lineItem) => {
      //Need to get the first word of the lineItem.description and check if that matches the first word of the pricing items that have a type of "App\\Models\\TestMethod"
      const testMethodType = testMethods.find(
        (testMethod) =>
          testMethod.label.split(" ")[0] === lineItem.description.split(" ")[0],
      );

      if (testMethodType) {
        arrayPush("DeliverableDetails", "pricing", {
          line_item_id: testMethodType.value,
          line_item_type: "App\\Models\\TestMethod",
          billable_item_units_id: units.find((unit) => unit.name === "Each")
            ?.id,
          price: lineItem.price,
          quantity: lineItem.quantity,
          purchase_id: purchase.id,
          description: `${lineItem.description} (${purchase.name})`,
        });

        return;
      }

      arrayPush("DeliverableDetails", "pricing", {
        price: lineItem.price,
        quantity: lineItem.quantity,
        purchase_id: purchase.id,
        description: `${lineItem.description} (${purchase.name})`,
      });
    });

    return;
  }

  if (purchase.purchase_invoice?.purchase_items.length > 0) {
    purchase.purchase_invoice.purchase_items.forEach((lineItem) => {
      arrayPush("DeliverableDetails", "pricing", {
        price: lineItem.markup_price,
        quantity: lineItem.quantity,
        purchase_id: purchase.id,
        description: `${lineItem.description} (${purchase.name})`,
      });
    });

    return;
  }

  purchase.purchase_items.forEach((lineItem) => {
    arrayPush("DeliverableDetails", "pricing", {
      price: lineItem.markup_price,
      quantity: lineItem.quantity,
      purchase_id: purchase.id,
      description: `${lineItem.description} (${purchase.name})`,
    });
  });
};

const ProductivityTable = ({ productivity, arrayPush, units }) => {
  const { user } = useAuth();

  const columns = [
    {
      accessorKey: "user.name",
      header: "Staff Member",
    },
    {
      accessorKey: "start_time",
      header: "Date",
      cell: (info) => {
        return new Date(info.getValue()).toLocaleDateString();
      },
    },
    {
      accessorKey: "comments",
      header: "Comments",
    },
    {
      accessorKey: "time_in_hours",
      header: "Hours",
    },
  ];

  if (user.is_position_admin) {
    columns.push({
      accessorKey: "charge_out_rate",
      header: "Charge Out Rate",
      cell: (info) => money.format(info.getValue()),
    });

    columns.push({
      accessorKey: "charge_out_amount",
      header: "Charge Out Amount",
      cell: (info) => {
        return money.format(info.getValue());
      },
    });

    columns.push({
      accessorKey: "deliverables",
      header: "Deliverables",
      cell: (info) => {
        if (!info.getValue()) {
          return;
        }

        return info.getValue().map((deliverable) => {
          return (
            <Link to={`/deliverables/${deliverable.uuid}/details`}>
              {deliverable.name}
            </Link>
          );
        });
      },
    });

    columns.push({
      accessorKey: "add",
      header: "Add",
      cell: (info) => {
        const productivity = info.row.original;

        return (
          <Button
            color="primary"
            size="sm"
            outline
            onClick={() => {
              const pricing = {
                productivity_id: productivity.id,
                price: productivity.charge_out_rate ?? 0,
                quantity: productivity.time_in_hours,
                line_item_type: "App\\Models\\BillableItem",
                billable_item_units_id: units.find((unit) =>
                  unit.name.toLowerCase().includes("hour"),
                )?.id,
                description: `${
                  productivity.user.name
                }'s productivity on ${new Date(
                  productivity.start_time,
                ).toLocaleDateString()}`,
              };

              arrayPush("DeliverableDetails", "pricing", pricing);
            }}
          >
            Add
          </Button>
        );
      },
    });
  }

  return <ReactTable disableSearch columns={columns} data={productivity} />;
};

export default connect(null, { arrayPush })(DeliverablesModal);
