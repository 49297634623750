import React from "react";
import DetailsTable from "./DetailsTable";
import { Link } from "react-router-dom";
import SpreadPrice from "../utils/SpreadPrice";

const EstimatedAmountBilledTable = (props) => {
  const columns = [
    {
      accessorKey: "user",
      header: "User",
      cell: (info) => (
        <a href={`mailto:${info.row.original.user_email}`}>{info.getValue()}</a>
      ),
    },
    {
      accessorKey: "job_full_name",
      header: "Job",
      cell: (info) => (
        <Link to={info.row.original.job_link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "time_in_hours",
      header: "Hours",
    },
    {
      accessorKey: "chargeout_amount",
      header: "Charge Out",
      cell: (info) => <SpreadPrice price={info.getValue()} />,
    },
  ];

  return (
    <DetailsTable
      url="productivity-dashboard"
      {...props}
      title="Estimate Amount Billed"
      columns={columns}
    />
  );
};

export default EstimatedAmountBilledTable;
