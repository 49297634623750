import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Badge } from "reactstrap";
import useApi from "../api/useApi";

import HeaderPage from "../header/HeaderPage";
import { primary } from "../utils/Colours";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import AssignButton from "./assignButton";
import hasGroup from "./hasGroup";
import GroupForm from "./GroupForm";

const GroupUserList = () => {
  const [updating, setUpdating] = useState([]);
  const [group, setGroup] = useState();
  const [groupUsers, setGroupUsers] = useState([]);
  const { takeAction, loading } = useApi();
  const { takeAction: takeActionQuietly } = useApi();
  const { uuid: groupUuid } = useParams();

  const updateUser = (user) => {
    setUpdating([...updating, user.uuid]);
    const userInGroup = hasGroup(group.uuid, user.groups);

    // If we are removing a group leader, remove them as leader too
    if (userInGroup && isGroupLeader(user, group)) {
      setGroupLeader(null, group);
    }

    const data = {
      uuid: group.uuid,
      userId: user.uuid,
      group: userInGroup,
    };

    takeActionQuietly("update", `groups/users/${data.uuid}`, data).then(
      ({ data }) => {
        setGroupUsers((groupUsers) => {
          const updatedUser = data.data;
          return groupUsers.map((user) => {
            return user.uuid !== updatedUser.uuid ? user : updatedUser;
          });
        });
        setUpdating((updating) =>
          updating.filter((uuid) => uuid !== user.uuid),
        );
      },
    );
  };

  const isGroupLeader = (user) => {
    return user.id == group.group_leader_id;
  };

  const setGroupLeader = (user) => {
    if (user) {
      setUpdating([...updating, user.uuid]);
    }
    const newGroup = { group_leader_id: user ? user.id : null };

    takeActionQuietly("update", `groups/${group.uuid}`, newGroup)
      .then(({ data }) => {
        setGroup(data.data);
        if (user) {
          setUpdating((updating) =>
            updating.filter((uuid) => uuid !== user.uuid),
          );
        }
      })
      .catch(errorSwal);
  };

  useEffect(() => {
    takeAction("show", `/groups/${groupUuid}`)
      .then(({ data }) => setGroup(data.data))
      .catch(errorSwal);

    takeAction("index", `/groups/${groupUuid}/users`)
      .then(({ data }) => setGroupUsers(data.data))
      .catch(errorSwal);
  }, []);

  if (!group) return <Spinner loading />;

  return (
    <div>
      <HeaderPage
        titlePage={group.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "groups", name: "Groups" },
          { link: "", name: `${group?.name ?? "-"} Users`, active: true },
        ]}
      />
      <GroupForm
        group={group}
        form="GROUP"
        initialValues={group}
        setGroup={setGroup}
      />
      <p>
        Click the user below to add or remove them from <i>{group.name}</i>
      </p>
      <p className={!group.group_leader_id && "tx-danger"}>
        Groups should always have an assigned group leader, or some features
        will be disabled.
      </p>
      <div>
        <table className="table table-hover">
          <thead className="thead-default">
            <tr>
              <th>User</th>
              <th className="text-center">Joined</th>
              <th className="text-center">Group leader</th>
            </tr>
          </thead>
          <tbody>
            {groupUsers.map((user) => (
              <>
                {updating.includes(user.uuid) ? (
                  <tr>
                    <td colSpan="3">
                      <ClipLoader size={15} color={primary} /> Updating...
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      className="pointer-hover"
                      onClick={() => updateUser(user)}
                      scope="row"
                    >
                      {user.name}
                    </td>
                    <td className="text-center">
                      {hasGroup(groupUuid, user.groups) ? "Yes" : "No"}
                    </td>
                    <td className="text-center">
                      {isGroupLeader(user) ? (
                        <Badge color="success">Assigned</Badge>
                      ) : hasGroup(groupUuid, user.groups) ? (
                        <AssignButton
                          user={user}
                          updating={updating}
                          onClick={() => setGroupLeader(user)}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupUserList;
