import { Button } from "reactstrap";
import useModal from "../hooks/useModal";
import PaginatedList from "../pagination/PaginatedList";
import { tenderFilters } from "../tenders/TendersList";
import { Tender } from "../tenders/tenderTypes";
import AddBranchTenderModal from "./AddBranchTenderModal";
import BranchTenderTable from "./BranchTenderTable";
import { Branch } from "./branchTypes";
import useBranchTenders from "./hooks/useBranchTenders";
import { useParams } from "react-router-dom";
import { RiAddFill } from "react-icons/ri";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { IUseApi } from "../api/apiTypes";
import { FiDownload } from "react-icons/fi";
import useUserGroup from "../hooks/useUserGroup";
import { useAuth } from "../../context/auth-context";

const BranchTenders = ({ branch }: { branch: Branch }) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();

  const { takeAction, loading }: IUseApi<{}, { data: string }> = useApi();

  const userIsPartOfBusinessDevelopment = useUserGroup("Business Development");

  const canSeeDownloadButton =
    userIsPartOfBusinessDevelopment || user?.is_admin;

  const downloadTenders = (searchQuery: string) => {
    return takeAction("show", `tenders/csv${searchQuery}`)
      .then(({ data }) => {
        window.location.href = data.data;
      })
      .catch(errorSwal);
  };

  const { toggle, modal } = useModal();

  return (
    <>
      <PaginatedList
        listName="branchTendersList"
        originalFilters={tenderFilters}
        indexHook={useBranchTenders}
        extraButtons={({ searchQuery }) => (
          <>
            {canSeeDownloadButton && (
              <Button
                onClick={() =>
                  downloadTenders(
                    `?filter[search]=${searchQuery}&filter[branch.uuid]=${uuid}`,
                  )
                }
                disabled={loading}
                color="link"
              >
                {loading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <FiDownload className="tx-16" />
                )}
              </Button>
            )}
            <Button color="link" onClick={toggle}>
              <RiAddFill aria-label="Add New Resource" className="tx-24 ms-1" />{" "}
              Add Tender
            </Button>
          </>
        )}
        indexHookArguments={[uuid]}
        list={({ data }: { data?: Tender[] }) => (
          <div className="col-12">
            {data && data.length > 0 && (
              <BranchTenderTable tenders={data ?? []} />
            )}
          </div>
        )}
      />
      <AddBranchTenderModal toggle={toggle} modal={modal} branch={branch} />
    </>
  );
};

export default BranchTenders;
