import React from "react";
import { Alert as BootstrapAlert, Button } from "reactstrap";
import useApi from "../api/useApi";

import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { useAuth } from "../../context/auth-context";

const SyncIntegrationEstimation = ({ contact, setContact }) => {
  const { takeAction, loading } = useApi();
  const { user } = useAuth();

  const syncIntegration = () => {
    return takeAction("update", `xero/contacts/${contact.uuid}`, {
      xero_id: contact.integration_estimation[0].ContactID,
    })
      .then(({ data }) => {
        setContact(data.data);
        toast.success("Contact Updated");
      })
      .catch(errorSwal);
  };

  if (
    !contact.integration_estimation ||
    contact.integration_estimation.length === 0 ||
    !user.is_admin
  ) {
    return null;
  }

  if (!contact.form_filled_at) {
    return null;
  }

  return (
    <BootstrapAlert color="primary">
      <p className="mb-0">
        {contact.name} isn't yet synced with your accounting integration
      </p>
      <p className="mb-0">
        We found a contact with the same name in the integration{" "}
        <Button
          disabled={loading}
          onClick={syncIntegration}
          color="link"
          className="px-0"
          size="sm"
        >
          {isSubmitting(loading, "Click Here  to sync.", "Syncing...")}
        </Button>{" "}
      </p>
    </BootstrapAlert>
  );
};

export default SyncIntegrationEstimation;
