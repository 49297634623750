import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import StandardDocumentModal from "../standardDocuments/standardDocumentModal";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import isSubmitting from "../utils/submitting";
import InvoiceButton from "./InvoiceButton";
import markJobCompleteSwal from "./markJobCompleteSwal";
import RevisionModal from "./RevisionModal";

const isRevision = ({ deliverable }) => {
  return (
    deliverable.sent.length > 0 &&
    new Date(deliverable.sent[0].created_at) >
      new Date(deliverable.current_revision.created_at)
  );
};

const FileButton = (props) => {
  const { revisionToggle, uploadToggle } = props;
  const revision = isRevision(props);
  return (
    <li className="nav-item">
      <button
        type="button"
        onClick={revision ? revisionToggle : uploadToggle}
        className="nav-link w-100 pointer-hover link-hover"
      >
        <i
          className={`icon ion-ios-cloud-upload tx-${
            revision ? "danger" : "info"
          }`}
        />{" "}
        Upload {revision ? "Revision" : "New Deliverable"}
      </button>
    </li>
  );
};

const ProfileButtons = (props) => {
  const { deliverable, setDeliverable } = props;
  const { takeAction, loading, response } = useApi();
  const { user } = useAuth();
  const { uuid } = useParams();
  const { toggle: revisionToggle, modal: revisionModal } = useModal();
  const { toggle: uploadToggle, modal: uploadModal } = useModal();
  const [sending, setSending] = useState(false);

  const bucket = `organisations/${user.active_organisation.uuid}/deliverables/${uuid}`;

  const whenSubmitted = () => {
    takeAction("show", `deliverables/${uuid}`)
      .then(({ data }) => setDeliverable(data.data))
      .catch(errorSwal);
  };

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      {loading && !response && <Spinner loading />}
      {deliverable?.current_revision?.document && (
        <li className="nav-item">
          <button
            type="button"
            disabled={loading}
            onClick={() =>
              sendDeliverable({ ...props, uuid, takeAction, setSending })
            }
            className="nav-link w-100 pointer-hover link-hover"
          >
            <i className="icon ion-ios-redo tx-purple" />{" "}
            {isSubmitting(sending, "Send", "Sending...")}
          </button>
        </li>
      )}
      <FileButton
        {...props}
        revisionToggle={revisionToggle}
        uploadToggle={uploadToggle}
      />
      <InvoiceButton deliverable={deliverable} />
      <RevisionModal
        {...props}
        toggle={revisionToggle}
        modal={revisionModal}
        deliverable={deliverable}
        bucket={bucket}
        setDeliverable={setDeliverable}
      />
      <StandardDocumentModal
        {...props}
        modal={uploadModal}
        toggle={uploadToggle}
        documentable_type="App\Models\DeliverableRevision"
        documentable_id={deliverable.current_revision.uuid}
        whenSubmitted={whenSubmitted}
        bucket={bucket}
      />
    </ul>
  );
};

const sendDeliverable = (props) => {
  const { deliverable, setDeliverable, uuid, takeAction, setSending } = props;

  const deliverableRecipients = deliverable?.recipients
    ?.map((recipient) => recipient.label)
    .join(", ");

  const alertMessage =
    !deliverable.recipients || deliverable.recipients.length === 0
      ? "There are no recipients set for this deliverable, still mark as sent?"
      : `Send deliverable to ${deliverableRecipients}?`;

  swal({
    title: "Send Deliverable",
    text: alertMessage,
    icon: "warning",
    buttons: true,
  })
    .then((action) => {
      if (action) {
        setSending(true);
        return takeAction("store", `deliverables/${uuid}/sent`);
      }
      return Promise.reject("dont_continue");
    })
    .then(({ data }) => {
      toast.success("Deliverable Sent Successfully!");
      setDeliverable(data.data);
      setSending(false);
      if (
        deliverable.job.status != "3" &&
        deliverable.job.offer_mark_completed
      ) {
        return markJobCompleteSwal();
      }
      return Promise.reject("dont_continue");
    })
    .then(() => {
      takeAction("update", `/project-jobs/status/${deliverable.job.uuid}`, {
        status: "3",
      });
    })
    .then(() => {
      toast.success("Job marked complete.");
    })
    .catch((err) => {
      setSending(false);
      if (err === "dont_continue") {
        return;
      }
      return errorSwal(err);
    });
};

export default ProfileButtons;
