import * as _ from "lodash";
import {
  FETCH_TESTS,
  FETCH_ALL_TESTS,
  NEW_TEST,
  DELETE_TEST,
  UPDATE_TEST,
  UPDATE_SELECTED_TESTS,
  REMOVE_SELECTED_TESTS,
  TOGGLE_ALL_SELECTED_TESTS,
  TOGGLE_CHANGE_REPORT_MODAL,
  TOGGLE_SELECTED_SAMPLE,
  TOGGLE_SELECTED_TEST_SAMPLE,
  TOGGLE_ALL_SELECTED_SAMPLES,
  REMOVE_SELECTED_SAMPLES
} from "../actions/types";
import hasAllSelected from "../components/utils/testHasAllSamplesSelected";

const initialState = {
  items: [],
  selectedTests: {},
  allSelected: false,
  changeReportModal: false,
  selectedSamples: {},
  allSamplesSelected: false
};

export default function(state = initialState, action) {
  const selectedTests = Object.assign({}, state.selectedTests);
  const { items } = state;
  const selectedSamples = Object.assign({}, state.selectedSamples);
  const allSelectedSamples = hasAllSelected(selectedSamples, action.payload);

  let { allSelected } = state;

  switch (action.type) {
    case FETCH_TESTS:
      return {
        ...state,
        items: action.payload
      };
    case FETCH_ALL_TESTS:
      return {
        ...state,
        allTests: action.payload
      };
    case UPDATE_TEST:
      return {
        ...state,
        allTests: state.allTests.map(test =>
          action.payload.uuid === test.uuid ? action.payload : test
        )
      };
    case NEW_TEST:
      return {
        ...state,
        items: {
          data: items.data.map(sample => {
            const newSample = action.payload.filter(
              samp => sample.id === samp.id
            );
            return newSample.length > 0 ? newSample[0] : sample;
          })
        }
      };
    case DELETE_TEST:
      return {
        ...state,
        items: {
          data: items.data.filter(
            sample => !action.payload.map(samp => samp.id).includes(sample.id)
          )
        }
      };
    case UPDATE_SELECTED_TESTS:
      if (selectedTests[action.payload]) {
        delete selectedTests[action.payload];
      } else {
        selectedTests[action.payload] = true;
      }

      if (Object.values(selectedTests).length === 0) {
        allSelected = false;
      }
      return {
        ...state,
        selectedTests,
        allSelected
      };
    case REMOVE_SELECTED_TESTS:
      return {
        ...state,
        selectedTests: []
      };
    case TOGGLE_ALL_SELECTED_TESTS:
      action.payload.forEach(item => {
        if (!state.allSelected) {
          selectedTests[item] = true;
        } else {
          delete selectedTests[item];
        }
      });

      return {
        ...state,
        selectedTests,
        allSelected: !state.allSelected
      };
    case TOGGLE_CHANGE_REPORT_MODAL:
      return {
        ...state,
        changeReportModal: !state.changeReportModal
      };
    case TOGGLE_SELECTED_SAMPLE:
      if (selectedSamples[action.payload.id]) {
        delete selectedSamples[action.payload.id];
      } else {
        selectedSamples[action.payload.id] = [];
        action.payload.tests.map(test =>
          selectedSamples[action.payload.id].push(test.id)
        );
      }

      return {
        ...state,
        selectedSamples
      };
    case REMOVE_SELECTED_SAMPLES:
      return {
        ...state,
        selectedSamples: {}
      };
    case TOGGLE_SELECTED_TEST_SAMPLE:
      _.forEach(selectedSamples, (val, key) => {
        if (!val.includes(action.payload) && !allSelectedSamples) {
          val.push(action.payload);
        } else if (!allSelectedSamples) {
          return;
        } else {
          val.splice(val.indexOf(action.payload), 1);
        }
        selectedSamples[key] = val;
      });
      return {
        ...state,
        selectedSamples
      };
    case TOGGLE_ALL_SELECTED_SAMPLES:
      _.forEach(items.data, val => {
        if (action.payload && !selectedSamples[val.id]) {
          selectedSamples[val.id] = [];
          items.data
            .find(sample => sample.id === val.id)
            .tests.map(test => selectedSamples[val.id].push(test.id));
        } else if (!action.payload) {
          delete selectedSamples[val.id];
        }
      });
      return {
        ...state,
        allSamplesSelected: action.payload,
        selectedSamples
      };
    default:
      return state;
  }
}
