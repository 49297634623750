import React from "react";
import { useParams } from "react-router";
import { ButtonDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import { ResourceGroup } from "../resourceGroups/resourceGroupTypes";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

interface AddJobFolderProps {
  project: any;
  group?: ResourceGroup;
  setGroup?: (group: ResourceGroup) => void;
}

const AddJobFolder: React.FC<
  AddJobFolderProps & InjectedFormProps<{}, AddJobFolderProps>
> = (props: any) => {
  const { handleSubmit, project, group, setGroup } = props;
  const { jobFolderUuid } = useParams<{ jobFolderUuid?: string }>();

  const { takeAction }: IUseApi<any, { data: ResourceGroup }> = useApi();

  const { modal: dropdownOpen, toggle } = useModal();

  const onSubmit: FormSubmitHandler<{
    name: string;
    parent_type?: string;
    parent_id?: number;
    resource_type?: string;
  }> = (values) => {
    values = jobFolderUuid
      ? {
          name: values.name,
          parent_type: "App\\Models\\ResourceGroup",
          parent_id: group?.id,
          resource_type: "App\\Models\\ResourceGroup",
        }
      : {
          name: values.name,
          parent_type: "App\\Models\\Project",
          parent_id: project?.id,
          resource_type: "App\\Models\\ProjectJob",
        };

    return takeAction("store", `resource-groups`, values)
      .then(({ data }) => {
        toast.success(`${values.name} added.`);
        setGroup(data.data);
        toggle();
        props?.reset?.();
      })
      .catch(errorSwal);
  };

  return (
    <ButtonDropdown className={`w-100`} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="light" caret>
        <i className="fa fa-plus pe-2"></i>Add Folder
      </DropdownToggle>
      <DropdownMenu>
        <div className="pd-30 pd-sm-40 wd-sm-350">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12 form-group">
                <Field component={RenderField} name="name" label="Name" />
              </div>
              <div className="col-12">
                <SubmitButton {...props} />
              </div>
            </div>
          </form>
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const form = reduxForm<{}, AddJobFolderProps>({ form: "AddJobFolder" });

export default form(AddJobFolder);
