import { useMemo } from "react";
import { InfiniteData } from "react-query";

const flattenInfinitePages = <T>(
  data: InfiniteData<any> | undefined,
): T[] | undefined => {
  return useMemo(() => data?.pages?.map((page) => page.data)?.flat(), [data]);
};

export default flattenInfinitePages;
