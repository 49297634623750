const CPIR_TYPES = [
  {
    value: 5,
    label: "Incident",
  },
  {
    value: 6,
    label: "Near Miss",
  },
  {
    value: 7,
    label: "Observation",
  },
];
export default CPIR_TYPES;
