import React from "react";
import dayjs from "dayjs";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";

const HoldsPopover = ({ holdableType, holdableId }) => {
  const { data: holds, loading } = useApi(
    `holds?holdable_type=${encodeURIComponent(
      holdableType,
    )}&holdable_id=${holdableId}`,
    [],
  );

  return (
    <UncontrolledPopover placement="bottom" target="holds">
      <PopoverHeader>Holds</PopoverHeader>
      <PopoverBody>
        {loading ? (
          <CustomScaleLoader>Fetching Holds...</CustomScaleLoader>
        ) : (
          <div className="row">
            {holds.map((hold) => {
              return (
                <React.Fragment key={hold.uuid}>
                  <div
                    className={`px-2 col-md-6 ${
                      !hold.deleted_at ? "mb-4" : "mb-2"
                    }`}
                  >
                    <p className="fw-bolder tx-inverse mb-0">Created at</p>
                    <p className="tx-inverse mb-0">
                      {dayjs(hold.created_at).format("DD/MM/YYYY hh:mm")}
                    </p>
                  </div>
                  <div
                    className={`px-2 col-md-6 ${
                      !hold.deleted_at ? "mb-3" : "mb-1"
                    }`}
                  >
                    <p className="fw-bolder tx-inverse mb-0">Reason</p>
                    <p className="mb-0">{hold.reason}</p>
                  </div>
                  {hold.deleted_at && (
                    <>
                      <div className="px-2 mb-1 col-6">
                        <p className="fw-bolder tx-inverse mb-0">Removed at</p>
                        <p className="tx-inverse mb-0">
                          {dayjs(hold.deleted_at).format("DD/MM/YYYY hh:mm")}
                        </p>
                      </div>
                      {hold.removed_by?.name && (
                        <div className="px-2 mb-1 col-6">
                          <p className="fw-bolder tx-inverse mb-0">
                            Removed by
                          </p>
                          <p className="tx-inverse mb-0">
                            {hold.removed_by?.name}
                          </p>
                        </div>
                      )}
                      <div className="px-2 mb-5 col-12">
                        <p className="fw-bolder tx-inverse mb-0">
                          Removal Reason
                        </p>
                        <p className="tx-inverse mb-0">{hold.removed_reason}</p>
                      </div>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default HoldsPopover;
