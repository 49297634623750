import { useParams } from "react-router-dom";
import { Field } from "redux-form";

import renderField, { money } from "../../utils/renderField";
import renderToggleInput from "../../utils/renderToggleInput";
import required from "../../utils/required";
import SelectInputAsync from "../../utils/SelectInputAsync";
import * as JobFields from "./jobFields";

const FormjobType = (props) => {
  const { projectUuid } = useParams();

  return (
    <>
      <div className="col-lg-12">
        <div className="form-group">
          <Field
            name="name"
            type="text"
            component={renderField}
            autocomplete="false"
            required
            label="Job Name"
          />
        </div>
      </div>

      <JobFields.Branch className="col-lg-4" />

      <JobFields.JobManager className="col-lg-4" />

      <div className="form-group col-lg-4">
        <Field
          name="users"
          label="Staff"
          multiple="true"
          component={SelectInputAsync}
          url="/users/staff"
        />
        <small>
          Select Staff from Your Organisation that will be working on this job
        </small>
      </div>
      <div className="col-lg-12">
        <div className="form-group">
          <Field
            name="description"
            label="Notes"
            textarea
            rows={3}
            cols={64}
            component={renderField}
          />
        </div>
      </div>
      <div className="col-12 mb-3">
        <hr />
      </div>
      <div className="col-lg-3">
        <div className="form-group">
          <Field
            name="scheduled_start_date"
            type="date"
            validate={required}
            component={renderField}
            required
            label="Scheduled Start Date"
            extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
          />
        </div>
      </div>

      <div className="col-lg-3">
        <div className="form-group">
          <Field
            name="scheduled_start_time"
            type="time"
            component={renderField}
            label="Scheduled Start Time"
          />
        </div>
      </div>

      <div className="col-lg-3">
        <div className="form-group">
          <Field
            name="scheduled_finish_date"
            type="date"
            validate={required}
            component={renderField}
            required
            label="Scheduled Finish Date"
            extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
          />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="form-group">
          <Field
            name="scheduled_finish_time"
            type="time"
            component={renderField}
            label="Scheduled Finish Time"
          />
        </div>
      </div>

      <div className="col-lg-3">
        <div className="form-group">
          <Field
            name="estimated_value"
            {...money}
            component={renderField}
            label="Estimated Value ($)"
          />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="form-group">
          <Field
            name="estimated_hours"
            type="number"
            component={renderField}
            label="Estimated Time (Hours)"
          />
        </div>
      </div>

      <JobFields.Dependencies
        projectUuid={projectUuid ?? props.projectUuid}
        className="col-lg-3"
      />

      <div className="col-lg-3">
        <div className="form-group">
          <Field
            name="critical_path"
            label="Critical Path"
            component={renderToggleInput}
          />
        </div>
      </div>
    </>
  );
};

export default FormjobType;
