import React from "react";
import { Field } from "redux-form";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";

const EditShareFilesModel = (props) => {
  const { toggle, refreshData, selectedFileShare, setSelectedFileShare } =
    props;
  const { takeAction } = useApi();

  const updateFileSharing = (values) => {
    return takeAction("update", `shared-files/${selectedFileShare.uuid}`, {
      expires_at: values?.expires_at,
    })
      .then(() => {
        toast.success(`File sharing successfully updated.`);
        toggle();
        refreshData();
        setSelectedFileShare();
      })
      .catch(formError);
  };

  return (
    <FormModal
      {...props}
      onSubmit={updateFileSharing}
      initialValues={{
        email: selectedFileShare?.email,
        expires_at: selectedFileShare?.expires_at,
      }}
    >
      <div className="col-lg-6 form-group">
        <Field
          required
          validate={required}
          component={RenderField}
          name="email"
          label="Send Share Link to Email"
          extraProps={{ disabled: true }}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="expires_at"
          type="date"
          label="Share Expiry Date"
        />
      </div>
    </FormModal>
  );
};

export default EditShareFilesModel;
