import FieldType from "../../enums/FieldType";
import useUploadMultipleToS3 from "./useUploadMultipleToS3";

const useUploadCustomFields = (bucket) => {
  const { upload: uploadMultiple, setBucket } = useUploadMultipleToS3(bucket);

  const upload = (values) => {
    const filteredCurrentDocuments = {};

    if (!values.custom_fields) {
      return Promise.resolve(values);
    }

    let documentValues = [];

    return Promise.all(getValues(values.custom_fields))
      .then((values) => {
        values.forEach((v) => {
          filteredCurrentDocuments[v.uuid] = v;
        });

        documentValues = values
          .filter(({ value }) => {
            return value instanceof File;
          })
          .map((value) => {
            return {
              ...filteredCurrentDocuments[value.uuid],
              uuid: value.uuid,
            };
          });

        const documents = documentValues.map(({ value }) => value);

        return uploadMultiple(documents);
      })
      .then((uploadedDocuments) => {
        const replaced = replaceFieldsWithUploaded(
          uploadedDocuments,
          documentValues,
        );

        const customFields = {
          ...filteredCurrentDocuments,
          ...replaced,
        };

        return {
          ...values,
          custom_fields: customFields,
        };
      });
  };

  const replaceFieldsWithUploaded = (uploaded, fields) => {
    const val = {};

    uploaded.forEach((document, index) => {
      val[fields[index].uuid] = {
        ...fields[index],
        value: document,
      };
    });
    return val;
  };

  const getValues = (customFields) => {
    return Object.entries(customFields).map(([uuid, value]) => {
      //Check to see if there is currently a file uploaded
      if (!value?.value?.uuid) {
        return value.type == FieldType.FieldArray
          ? Promise.all(
              value?.value?.map((v) => upload({ custom_fields: v })) ?? [],
            ).then((d) => {
              return {
                ...value,
                value: d.map(({ custom_fields }) => custom_fields),
              };
            })
          : Promise.resolve(value);
      }

      return Promise.resolve({
        ...value,
        value: {
          path: value?.value?.path,
          name: value?.value?.name,
          file_size: value?.value?.file_size,
          mime_type: value?.value?.mime_type,
        },
      });
    });
  };

  return {
    upload,
    setBucket,
  };
};

export default useUploadCustomFields;
