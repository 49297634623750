import React from "react";
import FileCard from "../utils/FileCard";
import SVGContainer from "../utils/SVGContainer";
import { ReactComponent as Empty } from "../../svgs/empty.svg";
import AddFileButton from "./AddFileButton";

const FilePage = props => {
  const { role } = props;
  return (
    <>
      <AddFileButton {...props} />
      {role.file.link ? (
        <FileCard link={role.file.link} />
      ) : (
        <SVGContainer SVG={Empty}>
          <p className="mg-y-20">No file added, click above to add!</p>
        </SVGContainer>
      )}
    </>
  );
};

export default FilePage;
