import ProfileForm from "../customFields/ProfileForm";
import InformationAlert from "../utils/InformationAlert";

const SupplierTypeFields = ({
  supplierType,
  setData,
}: {
  supplierType: any;
  setData: Function;
}) => {
  if (!supplierType) {
    return null;
  }

  return (
    <>
      <p className="text-dark">
        Build a form here that suppliers will be required to fill in when they
        are asked to register.
      </p>
      <div className="mb-3">
        <InformationAlert
          type="info"
          closable
          body={`If you have any select fields in this form that are pulled from your database, the supplier filling this form in will be able to see this information. \n\nPlease use caution when using this feature.`}
          title="Sensitive data may be shown"
        />
      </div>
      <ProfileForm
        initialValues={supplierType}
        modelId={supplierType.id}
        modelType="App\Models\SupplierType"
        onSuccess={(data: { data: any[] }) =>
          setData({
            ...supplierType,
            custom_fields: data.data,
          })
        }
      />
    </>
  );
};

export default SupplierTypeFields;
