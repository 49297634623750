import React, { useState, useEffect } from "react";
import { reduxForm } from "redux-form";
import { toast } from "react-toastify";
// BO$$
import Spinner from "../utils/Spinner";
import errorSwal from "../utils/errorSwal";
import UserFields from "./UserFields";
import isSubmitting from "../utils/submitting";
import dayjs from "dayjs";
import axios from "../api/api";
import { useAuth } from "../../context/auth-context";

const RegisterUser = (props) => {
  const { match, history, handleSubmit, change } = props;
  const { setUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    axios
      .get(`invited-users/${match.params.uuid}`)
      .then(({ data }) => {
        change("first_name", data.data.first_name);
        change("last_name", data.data.last_name);
        change("email", data.data.email);
        return setLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to find invitation");
        history.push("/login");
        return;
      });
  }, []);

  const onSubmit = (values) => {
    setSubmitting(true);

    let data = { ...values };

    if (values.dob) {
      data.dob = dayjs(values.dob).format("YYYY-MM-DD");
    }

    return axios
      .put(`invited-users/${match.params.uuid}`, data)
      .then(({ data }) => {
        setUser(data.data);
        history.push("/");
        history.go();
      })
      .catch((err) => {
        errorSwal(err);
        setSubmitting(false);
      });
  };

  if (loading) return <Spinner loading />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <UserFields {...props} />
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-primary w-100 btn-signin"
            disabled={submitting}
          >
            {isSubmitting(submitting, "Sign Up", "Signing Up...")}
          </button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "Register" });

export default form(RegisterUser);
