import useModal from "../hooks/useModal";
import { TemplateDocument } from "../templateDocuments/templateDocumentTypes";
import ProfileButtons from "../utils/ProfileButtons";
import ProfileSingleFile from "../utils/ProfileSingleFile";
import { TestSuiteProps } from "./testSuiteTypes";
import UploadNewTemplateModal from "./UploadNewTemplateModal";

const UploadTestSuiteTemplate = (props: TestSuiteProps) => {
  const { testSuite, setTestSuite } = props;

  const { toggle, modal } = useModal();

  const buttons = testSuite.template
    ? [
        {
          text: (
            <>
              {" "}
              <i className="icon ion-plus tx-primary" /> Upload new template
            </>
          ),

          onClick: toggle,
        },
        {
          text: (
            <>
              <i className="icon icon ion-archive tx-secondary" /> Download File
            </>
          ),
          onClick: () => {
            if (testSuite.template.link) {
              window.location.href = testSuite.template.link;
            }
          },
        },
      ]
    : [];

  return (
    <>
      <ProfileButtons buttons={buttons} />

      <ProfileSingleFile
        hideButton={testSuite.template}
        documentable_id={testSuite.uuid}
        documentable_type="App\Models\TestSuite"
        whenSubmitted={({ data }: { data: { data: TemplateDocument } }) => {
          setTestSuite({
            ...testSuite,
            template: data.data,
          });
        }}
        {...testSuite?.template}
      />
      <UploadNewTemplateModal {...props} toggle={toggle} modal={modal} />
    </>
  );
};

export default UploadTestSuiteTemplate;
