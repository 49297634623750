import PropTypes from "prop-types";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Progress } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { ReactComponent as Empty } from "../../svgs/empty.svg";
import useUploadStandardDocument from "../hooks/useUploadStandardDocument";
import AddFileButton from "./AddFileButton";
import FileCard from "./FileCard";
import SVGContainer from "./SVGContainer";

const ProfileSingleFile = (props) => {
  const { documentable_id, documentable_type, whenSubmitted } = props;
  const { user } = useAuth();

  const { uploadDocument, uploadPercent, loading } =
    useUploadStandardDocument();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const bucket = `organisations/${user.active_organisation.uuid}/${props.bucket}`;

    const data = {
      documentable_type,
      documentable_id,
    };

    return uploadDocument(file, bucket, data).then((res) => {
      if (whenSubmitted) {
        whenSubmitted(res);
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    multiple: false,
    disabled: props.disableUpload || loading,
    preventDropOnDocument: false,
  });

  return (
    <>
      {!loading && <AddFileButton {...props} />}

      {props.link ? (
        <FileCard {...props} />
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {loading && (
            <div className="mt-3">
              <Progress
                value={uploadPercent}
                animated
                className="progress-bar-sm"
              />
              <p>
                {uploadPercent < 100 ? (
                  `Upload ${uploadPercent}% complete.`
                ) : (
                  <>
                    <i className="icon ion-checkmark tx-success" /> File
                    uploaded, saving details.
                  </>
                )}
              </p>
            </div>
          )}
          <SVGContainer SVG={Empty}>
            <p className={`mg-y-20 ${isDragReject ? "tx-danger" : ""}`}>
              {isDragReject
                ? "Can't Accept drop..."
                : `No file added, click above, ${
                    !props.disableUpload ? "or drag file onto image" : ""
                  }to add!`}
            </p>
          </SVGContainer>
        </div>
      )}
    </>
  );
};

AddFileButton.propTypes = {
  link: PropTypes.string,
};

export default ProfileSingleFile;
