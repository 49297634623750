import { useState } from "react";
import { useAuth } from "../../context/auth-context";
import { NewTimesheetPage } from "./Timesheets";
import HeaderPage from "../header/HeaderPage";
import { TimesheetProvider } from "../../context/timesheet-context";
import { modifyDate } from "../../actions/timesheetActions";
import { connect } from "react-redux";
import TimesheetDayPicker from "./TimesheetDayPicker";
import dayjs from "dayjs";
import { ReactComponent as Time } from "../../svgs/time.svg";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import { AuthUser } from "../user/userTypes";
import errorSwal from "../utils/errorSwal";
const ForceTimesheets = ({
  modifyDate,
  selectedDay,
}: {
  modifyDate: Function;
  selectedDay: string;
}) => {
  const { user, setUser } = useAuth();

  const [showProductivity, setShowProductivity] = useState(false);

  const { takeAction } = useApi();

  const handleDayClick = (day: any, { selected }: any) => {
    return selected ? undefined : modifyDate(user, day);
  };

  return (
    <>
      <HeaderPage titlePage="Add Timesheet" crumbs={[]} />
      <TimesheetProvider>
        <div className="row z-10">
          <div className="col-lg-8">
            <div
              className="bg-teal money p-3 rounded-lg shadow-sm mb-5 d-flex position-relative"
              style={{ color: "#e8fff4" }}
            >
              <div>
                <p className="mb-3 tx-18">Timesheet Required</p>
                <p className="mb-2 tx-12">
                  {user?.active_organisation.display_name} requires you to
                  submit timesheets after{" "}
                  {user?.active_organisation.timesheet_required_time} days.
                </p>
                {user?.timesheet_required === "warning" && (
                  <>
                    <p className="tx-12 mb-1">
                      You may continue by clicking below one time.
                    </p>
                    <p className="tx-12 mb-0">
                      The next time you log in will be required to submit a
                      timesheet.
                    </p>
                    <Button
                      onClick={() => {
                        takeAction("store", `timesheets/force`)
                          .then(({ data }: { data: { data: AuthUser } }) => {
                            setUser(data.data);
                          })
                          .catch(errorSwal);
                      }}
                      color="warning"
                      className="mt-3"
                      size="sm"
                    >
                      continue
                    </Button>
                  </>
                )}
              </div>
              <div
                className="ms-auto pe-3 d-none d-sm-block position-absolute r-0"
                style={{
                  bottom: "-23px",
                }}
              >
                <Time width="120px" height="120px" className="drop-shadow" />
              </div>
            </div>
            {!showProductivity && (
              <p className="text-dark">
                {dayjs(selectedDay).format("dddd, MMMM D, YYYY")}
              </p>
            )}
            <NewTimesheetPage
              showProductivity={showProductivity}
              setShowProductivity={setShowProductivity}
              userId={user?.uuid}
              userUuid={user?.uuid}
              initialValues={{
                id: user?.id,
              }}
              addTimesheet
            />
          </div>
          <div className="col-lg-4 space-y-5 d-none d-lg-block">
            <div className="bg-white d-flex align-items-center justify-content-center rounded-lg border shadow-sm">
              <TimesheetDayPicker user={user} handleDayClick={handleDayClick} />
            </div>
          </div>
        </div>
      </TimesheetProvider>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  selectedDay: state.timesheets.selectedDay,
});

export default connect(mapStateToProps, {
  modifyDate,
})(ForceTimesheets);
