import _ from "lodash";
import { Link } from "react-router-dom";
import QUICK_LINKS from "../../data/quick_links";
import useApi from "../api/useApi";

const QuickLinks = () => {
  const { data } = useApi("organisations-standard-job-types", []);

  const organisationStandardJobTypes = data?.map(
    ({ job_type_type }) => job_type_type,
  );

  return (
    <div className="bg-white px-4 shadow-sm rounded-lg border">
      <div className="row">
        {_.sortBy(QUICK_LINKS, "heading").map((type, index) => (
          <div className="d-flex my-4 col-lg-6" key={index}>
            <div className="d-flex flex-grow-1">
              <div>
                <i className={`${type.icon} lh-0`} />
              </div>
              <div className="media-body mg-l-15 mg-t-4">
                <h6 className="tx-14 tx-gray-700">{type.heading}</h6>
                {_.sortBy(type.links, "text").map(
                  ({ link, text, jobType }) =>
                    (organisationStandardJobTypes.includes(jobType) ||
                      !jobType) && (
                      <Link to={link} className="d-block" key={link}>
                        {text}
                      </Link>
                    ),
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuickLinks;
