import _ from "lodash";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { Field, FormSubmitHandler, InjectedFormProps } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import { SelectOption } from "../utils/utilTypes";
import {
  PermissionGroup,
  PermissionGroupSubject,
  SubjectTypes,
} from "./permissionGroupTypes";
import filterOption from "../../utils/filterOption";
import required from "../utils/required";

interface AddPermissionGroupSubjectModalProps {
  subjects: PermissionGroupSubject[];
  setSubjects: (subjects: PermissionGroupSubject[]) => void;
  toggle: () => void;
  modal: boolean;
  permissionGroup: PermissionGroup;
  options: SelectOption[];
  subjectTitle: "User" | "Position";
  subjectType: SubjectTypes;
}

const AddPermissionGroupSubjectModal = ({
  permissionGroup,
  subjects,
  setSubjects,
  toggle,
  modal,
  subjectTitle,
  options,
  subjectType,
}: AddPermissionGroupSubjectModalProps) => {
  const { takeAction }: IUseApi<{}, { data: PermissionGroupSubject[] }> =
    useApi();

  const onSubmit: FormSubmitHandler<{
    subject_ids: any[];
  }> = (values) => {
    return takeAction(
      "store",
      `/permission-groups/${permissionGroup.uuid}/subjects`,
      {
        subject_ids: values.subject_ids.map((v) => v.value),
        subject_type: subjectType,
      },
    )
      .then(({ data }) => {
        const newSubjects = _.uniqBy([...subjects, ...data.data], "uuid");

        setSubjects(newSubjects);
        toast.success("Permission Group Subject Added");
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      form="PermissionGroupSubject"
      toggle={toggle}
      modal={modal}
      title={`Add ${subjectTitle}s`}
      onSubmit={onSubmit}
    >
      {({ change }: InjectedFormProps) => {
        return (
          <div className="col-lg-12 form-group">
            <div className="d-flex align-items-center mb-2">
              <label className="tx-inverse tx-semibold mb-0">
                {subjectTitle}s
              </label>
              <div className="d-flex ms-auto align-items-center">
                <Button
                  size="sm"
                  outline
                  onClick={() => change("subject_ids", options)}
                >
                  Add All
                </Button>
              </div>
            </div>
            <Field
              component={SelectInput}
              name="subject_ids"
              isMulti
              required
              validate={required}
              options={options}
              filterOption={filterOption}
            />
          </div>
        );
      }}
    </FormModal>
  );
};

export default AddPermissionGroupSubjectModal;
