import "chart.js/auto";
import React, { useEffect } from "react";
import useApi from "../api/useApi";
import { Range } from "../hooks/useProductivityRange";
import { rangeQuery } from "./ManagementCPIRs";
import { Group } from "./managementDashboardTypes";
import querystring from "query-string";
import LoadingOverlay from "../utils/LoadingOverlay";
import { Line } from "react-chartjs-2";
import * as chartjs from "chart.js";
import * as d3 from "d3";
import dayjs from "dayjs";
import _ from "lodash";
import colors from "../../data/colors";
import moneyOptions from "../charts/moneyOptions";

interface TotalInvoicedResponse {
  project_data: {
    scheduled_start_date: string;
    scheduled_finish_date: string;
    estimated_value_per_month: number;
  };
  invoiced: {
    [key: string]: {
      created_at: string;
      total_cost: number;
    }[];
  };
}

const TotalInvoicedLine = ({
  group,
  range,
}: {
  group?: Group;
  range: Range;
}) => {
  const {
    data: totalInvoiced,
    setUrl,
    loading,
  }: {
    data: TotalInvoicedResponse;
    setUrl: (url: string) => any;
    loading: boolean;
  } = useApi();

  useEffect(() => {
    if (group) {
      const query: string = querystring.stringify({
        model: group.type,
        id: group.id,
      });

      setUrl(`total-invoiced?${query}${rangeQuery(range)}`);
    }
  }, [group, range]);

  if (!totalInvoiced) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-lg-12 my-3">
        <div className="position-relative">
          <LoadingOverlay loading={loading} />
          <div className="bg-white p-3 border">
            <p className="slim-card-title">Invoice Overview</p>
            <Line data={invoicedData(totalInvoiced)} options={moneyOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

const invoicedData = (
  totalInvoiced: TotalInvoicedResponse,
): chartjs.ChartData<"line"> => {
  const range = d3.timeMonth.range(
    dayjs(totalInvoiced.project_data.scheduled_start_date)
      .subtract(1, "month")
      .toDate(),
    dayjs(totalInvoiced.project_data.scheduled_finish_date).toDate(),
  );

  return {
    labels: range.map((date) => dayjs(date).format("MMM YYYY")),
    datasets: [
      {
        label: "Amount Invoiced",
        data: range.map((date) => {
          return (
            _.sumBy(
              totalInvoiced.invoiced[dayjs(date).format("YYYY-MM")],
              "total_cost",
            ) ?? 0
          );
        }),
        backgroundColor: colors.success.light,
        borderColor: colors.success.border,
        borderWidth: 1,
      },
      {
        label: "Estimated Amount Invoiced",
        data: range.map(() => {
          return totalInvoiced.project_data.estimated_value_per_month;
        }),
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: colors.info.border,
        borderWidth: 1,
      },
    ],
  };
};

export default TotalInvoicedLine;
