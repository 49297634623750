import { FETCH_PAYS, UPDATE_PAY } from "../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  const { items } = state;
  switch (action.type) {
    case FETCH_PAYS:
      return {
        ...state,
        items: action.payload
      };
    case UPDATE_PAY:
      const newItems = items.map(item =>
        action.payload.uuid === item.uuid ? action.payload : item
      );
      return {
        ...state,
        items: newItems
      };
    default:
      return state;
  }
}
