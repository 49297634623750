import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { checkMobileAndTablet } from "../utils/deviceDetection";
import errorSwal from "../utils/errorSwal";
import LoadingOverlay from "../utils/LoadingOverlay";
import DraggableJob from "./DraggableJob";

const JobOrder = ({ dashboardData }: { dashboardData: any }) => {
  const { takeAction, loading }: IUseApi = useApi();
  const [jobs, setJobs] = useState<any[]>([]);

  useEffect(() => {
    setJobs(dashboardData);
  }, [dashboardData]);

  const nonPrioritisedJobs = jobs?.filter((job) => job.priority === null);

  const prioritisedJobs = _.orderBy(
    jobs?.filter((job) => job.priority !== null),
    "priority",
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (
      result.destination.droppableId === "non-priority" &&
      result.source.droppableId === "non-priority"
    ) {
      return;
    }

    const droppedJob = jobs.find(({ uuid }) => result.draggableId === uuid);
    const oldJobs = jobs;

    if (result.destination.droppableId === "priority") {
      if (droppedJob.priority === result.destination?.index) {
        return;
      }
      const newPrioritised = prioritisedJobs.filter(
        ({ uuid }) => uuid !== result.draggableId,
      );

      newPrioritised.splice(result.destination.index, 0, {
        ...droppedJob,
        priority: result.destination.index,
      });

      const prioritised = newPrioritised.map((job, index) => {
        return {
          ...job,
          priority: index,
        };
      });

      const newJobs = _.uniqBy([...prioritised, ...nonPrioritisedJobs], "uuid");

      setJobs(newJobs);

      takeAction("update", "job-priorities", {
        jobs: prioritised,
      })
        .then(({ data }: { data: { data: any[] } }) => {
          setJobs(
            newJobs.map((job) => {
              const freshJob = data.data.find(
                (fresh) => fresh.uuid === job.uuid,
              );
              return freshJob ?? job;
            }),
          );
        })
        .catch((err) => {
          errorSwal(err);
          setJobs(oldJobs);
        });

      return;
    }

    const newNonPriority = jobs.map((job) => {
      if (job.uuid === result.draggableId) {
        return {
          ...job,
          priority: null,
        };
      }
      return job;
    });

    setJobs(newNonPriority);

    takeAction("destroy", `job-priorities/${result.draggableId}`)
      .then(({ data }) => {
        setJobs(
          newNonPriority.map((j) =>
            j.uuid === data.data.uuid ? data.data : j,
          ),
        );
      })
      .catch((err) => {
        errorSwal(err);
        setJobs(oldJobs);
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="row">
        {checkMobileAndTablet() ? (
          <DroppableJobs
            jobs={jobs}
            allJobs={jobs}
            title="Your Jobs"
            droppableId="your-jobs"
            loading={loading}
            setJobs={setJobs}
          />
        ) : (
          <>
            <DroppableJobs
              jobs={prioritisedJobs}
              allJobs={jobs}
              title="Priority"
              droppableId="priority"
              loading={loading}
              setJobs={setJobs}
            />
            <DroppableJobs
              jobs={nonPrioritisedJobs}
              allJobs={jobs}
              title="Non-Priority"
              droppableId="non-priority"
              loading={loading}
              setJobs={setJobs}
            />
          </>
        )}
      </div>
    </DragDropContext>
  );
};

interface DroppableJobsProps {
  droppableId: "priority" | "non-priority" | "your-jobs";
  title: "Priority" | "Non-Priority" | "Your Jobs";
  jobs: any[];
  loading: boolean;
  setJobs: Function;
  allJobs: any[];
}

const DroppableJobs = ({
  droppableId,
  jobs,
  title,
  loading,
  setJobs,
  allJobs,
}: DroppableJobsProps) => {
  const amountOfHoursLeft = _.sumBy(jobs, "estimated_hours_left");

  return (
    <div className="col-lg-6 h-100 mb-3 mb-lg-0">
      <Droppable
        isDropDisabled={checkMobileAndTablet()}
        droppableId={droppableId}
      >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
          return (
            <div className="position-relative h-100">
              <LoadingOverlay loading={loading} />
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="bg-white p-3 border"
              >
                <p className="slim-card-title mb-1">{title}</p>
                <p className="mb-0">
                  <span className="tx-inverse">{amountOfHoursLeft}</span> hours
                  of work remaining for{" "}
                  <span className="tx-inverse">{jobs?.length}</span> jobs
                </p>
                <div className="mt-4 space-y-3">
                  {jobs.map((job: any, index: number) => {
                    return (
                      <div key={job.uuid}>
                        <Draggable
                          isDragDisabled={checkMobileAndTablet()}
                          draggableId={job.uuid}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot,
                          ) => {
                            return (
                              <DraggableJob
                                provided={provided}
                                snapshot={snapshot}
                                job={job}
                                setJobs={setJobs}
                                jobs={allJobs}
                              />
                            );
                          }}
                        </Draggable>
                      </div>
                    );
                  })}
                </div>
                {provided.placeholder}
              </div>
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};

const searchValues: { column: string; format?: (job: any) => string }[] = [
  {
    column: "name",
  },
  {
    column: "number",
  },
  {
    column: "project.full_name",
  },
  {
    column: "scheduled_finish_date",
    format: (job) => dayjs(job.scheduled_finish_date).format("DD/MM/YYYY"),
  },
];

export default JobOrder;
