import { useQuery } from "react-query";
import api from "../../api/api";

const getTimesheetDates = (id) => {
  return api.get(`timesheets/dates?userId=${id}`).then(({ data }) => data.data);
};

export default function useTimesheetDates(id) {
  return useQuery(["timesheet-dates", id], () => getTimesheetDates(id));
}
