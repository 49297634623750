const DATE_OPTIONS = [
    {
      label: 'In the Last 7 Days',
      value: 1,
    },
    {
      label: 'In the Last 30 Days',
      value: 2,
    },
    {
      label: 'Custom Dates',
      value: 3,
    },
    {
      label: 'Any',
      value: 4,
    },
  ];
  
  export default DATE_OPTIONS;