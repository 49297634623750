import { useState } from "react";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import isSubmitting from "../utils/submitting";
import errorSwal from "../utils/errorSwal";
import swal from "sweetalert";

const LoginConsent = () => {
  const params = new URLSearchParams(window.location.search);

  const clientType = params.get("client_type");

  const { takeAction }: IUseApi<{}, { data: { token: string } }> = useApi();

  const [tokenName, setTokenName] = useState(clientType ?? "");

  const [submitting, setSubmitting] = useState(false);

  const denyRequest = () => {
    swal({
      title: "Deny?",
      text: `Deny request to connect with ${clientType}?`,
      icon: "warning",
      buttons: {
        cancel: {
          text: "Continue with Request",
          value: null,
          closeModal: true,
          visible: true,
        },
        delete: {
          text: "Deny Request",
          value: true,
          closeModal: true,
          className: "btn btn-danger align-baseline",
        },
      },
    }).then((remove) => {
      if (remove) {
        window.location.href = `${params.get(
          "redirect_uri",
        )}?error=access_denied`;
      }
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    takeAction("store", "users/tokens", {
      name: tokenName,
    })
      .then(({ data }) => {
        swal(`${params.get("redirect_uri")}?token=${data.data.token}`);
        window.location.href = `${params.get("redirect_uri")}?token=${
          data.data.token
        }`;
      })
      .then(() => setSubmitting(false))
      .catch((err) => {
        setSubmitting(false);
        errorSwal(err);
      });
  };

  return (
    <div
      style={{
        height: "100vh",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="bg-white border rounded p-5 text-center">
        <img
          style={{
            width: "100px",
          }}
          src="/img/logo.png"
          alt="theBOSSapp"
        />
        <p className="mt-4 text-dark mb-0">
          {clientType} would like to connect to your theBOSSapp profile
        </p>
        <p className="mt-3">{clientType} will have access to your data.</p>

        <form onSubmit={onSubmit}>
          <div className="form-group text-start">
            <label
              htmlFor="tokenName"
              className="form-control-label tx-inverse tx-semibold"
            >
              Token Name
            </label>
            <input
              type="text"
              className="form-control"
              id="tokenName"
              value={tokenName}
              onChange={(e) => setTokenName(e.target.value)}
            />
            <small className="d-block">
              Set your token name here to make it easier to identify if you'd
              like to revoke access later.
            </small>
          </div>
          <Button disabled={submitting} type="submit" block color="primary">
            {isSubmitting(submitting, "Allow Access", "Generating Token...")}
          </Button>
          <Button
            onClick={denyRequest}
            block
            color="danger"
            className="mt-3"
            outline
          >
            Deny
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginConsent;
