import { useState } from "react";
import { initialize } from "redux-form";
import ReactTable from "../table/ReactTable";
import ModalForm from "./ModalForm";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { Button } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";

const SkillTable = ({ skills, setSkills, category, loading }) => {
  const [modal, setModal] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState("");

  const { takeAction } = useApi();

  const toggle = (skill) => {
    setSelectedSkill(selectedSkill ? null : skill);
    setModal(!modal);
  };

  const deleteSkill = (skill) => {
    return deleteSwal(skill.skill)
      .then(() => takeAction("destroy", `competency-skills/${skill.uuid}`))
      .then(() => {
        setSkills(skills.filter((s) => s.uuid !== skill.uuid));
        toast.success(`${skill.skill} delete`);
      })
      .catch(errorSwal);
  };

  const onSubmit = (values, dispatch) => {
    return takeAction("update", `competency-skills/${values.uuid}`, {
      ...values,
      skill_category_id: category.id,
      skill_levels: values.skill_levels.map((level, i) => {
        return {
          ...level,
          level: i + 1,
        };
      }),
    })
      .then(({ data }) => {
        setSkills(
          skills.map((skill) =>
            skill.uuid === data.data.uuid ? data.data : skill,
          ),
        );
        toast.success("Skill updated successfully");
        dispatch(initialize("CompetencySkills", data.data));
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "skill",
      header: "Skill",
      cell: (info) => {
        return (
          <button
            className="btn btn-link py-0"
            onClick={() => toggle(info.row.original)}
          >
            {info.row.original.skill}
          </button>
        );
      },
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => (
        <Button
          color="danger"
          size="sm"
          outline
          onClick={() => deleteSkill(info.row.original)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <ReactTable columns={columns} data={skills} loading={loading} />
      {selectedSkill && (
        <ModalForm
          modal={modal}
          onSubmit={onSubmit}
          toggle={toggle}
          initialValues={selectedSkill}
          form="UPDATE_COMPETENCY_SKILLS"
          title={`Update ${selectedSkill.skill}`}
        />
      )}
    </>
  );
};

export default SkillTable;
