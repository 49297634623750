import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Field } from "redux-form";
import swal from "sweetalert";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import SelectInputAsync from "../utils/SelectInputAsync";

const TransferTasksModal = (props) => {
  const { userId: uuid } = useParams();
  const [users, setUsers] = useState([]);

  const {
    takeAction,
    data: tasks,
    setData: setTasks,
  } = useApi(`users/${uuid}/incomplete-tasks`, []);
  const { toggle } = props;

  useEffect(() => {
    takeAction("index", "users/staff").then(({ data }) => setUsers(data));
  }, []);

  const onSubmit = (values) => {
    return takeAction("update", `users/${uuid}/incomplete-tasks`, values)
      .then(({ data }) => {
        swal({
          title: "Job Dispatched",
          text: "We have just dispatched a job to update these tasks. This can take a while so we'll email you when it's complete.",
          icon: "success",
        });

        setTasks(data.data);

        toggle();
      })
      .catch(errorSwal);
  };

  const tasksToUpdate = tasks.filter(({ count }) => count > 0);

  const initialValues = {
    tasks: tasksToUpdate.map(({ uuid, type }) => ({
      uuid,
      type,
      manager_id: props.user.id,
    })),
  };

  return (
    <FormModal
      {...props}
      onSubmit={onSubmit}
      form="IncompleteTasks"
      title="Transfer Tasks"
      initialValues={initialValues}
    >
      {({ change }) => {
        return tasksToUpdate.map((task, index) => {
          if (task.count === 0) {
            return null;
          }

          return (
            <React.Fragment key={task.type}>
              <div className="form-group col-12">
                <Field
                  component={SelectInputAsync}
                  options={users}
                  name={`tasks[${index}].manager_id`}
                  label={`${task.type} (${task.type === "group-leader" ? task.details.title : task.count})`}
                />
              </div>
              <div className="form-group col-12">
                <Field
                  component="input"
                  type="hidden"
                  name={`tasks[${index}].type`}
                />
              </div>
            </React.Fragment>
          );
        });
      }}
    </FormModal>
  );
};

export default TransferTasksModal;
