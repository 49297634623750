import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Form from "./EquipmentCalibrationTypeFields";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { reset } from "redux-form";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";

const AddModal = (props) => {
  const { toggle, modal, equipmentType, setEquipmentType } = props;
  const { uuid } = useParams();

  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    return takeAction(
      "store",
      `equipment-types/${uuid}/calibration-types`,
      values,
    )
      .then(({ data }) => {
        toggle();
        toast.success("Calibration Added");
        dispatch(reset("AddNewCalibration"));
        setEquipmentType({
          ...equipmentType,
          calibration_types: [...equipmentType.calibration_types, data.data],
        });
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      form="AddNewCalibration"
      onSubmit={onSubmit}
      title="Add New Calibration"
      initialValues={{ approval_required: true }}
    >
      <Form onSubmit={onSubmit} form="AddNewCalibration" />
    </FormModal>
  );
};

export default AddModal;
