import { useState } from "react";
import dayjs from "dayjs";
import ReactTable from "../table/ReactTable";
import DocumentModal from "../documents/DocumentModal";

const Deliverables = (props) => {
  const [selectedDeliverable, setSelectedDeliverable] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => {
        return (
          <button
            className="btn btn-link p-0"
            type="button"
            onClick={() => changeDeliverable(info.row.original)}
          >
            {info.getValue()}
          </button>
        );
      },
    },
    {
      accessorKey: "sent_at",
      header: "Date",
      cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "download",
      header: "Download",
      cell: (info) => {
        const deliverable = info.row.original;
        return (
          <a href={deliverable.document.link}>{deliverable.document.name}</a>
        );
      },
    },
  ];

  const changeDeliverable = (deliverable) => {
    setSelectedDeliverable(deliverable);
    toggle();
  };

  return (
    <>
      <ReactTable columns={columns} data={props.project.deliverables} />
      <DocumentModal
        toggle={toggle}
        modal={modal}
        link={
          selectedDeliverable.document
            ? selectedDeliverable.document.inline_link
            : null
        }
        title={selectedDeliverable.name}
      />
    </>
  );
};
export default Deliverables;
