import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";

const List = (props) => {
  const { takeAction } = useApi();
  const {
    loading,
    recurringJobs,
    setRecurringJobs,
    setSelectedRecurringJob,
    toggle,
  } = props;

  const deleteRecurring = (recurring) => {
    deleteSwal(recurring.name)
      .then(() => {
        return takeAction("destroy", `recurring-jobs/${recurring.uuid}`);
      })
      .then(() => {
        setRecurringJobs(
          recurringJobs.filter((j) => j.uuid !== recurring.uuid),
        );
        toast.success(`${recurring.name} deleted.`);
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => {
        return (
          <Link
            type="button"
            className="btn btn-link p-0"
            to={`/recurring-jobs/${info.row.original.uuid}/details`}
          >
            {info.getValue()}
          </Link>
        );
      },
    },
    {
      accessorKey: "job_manager.name",
      header: "Manager",
    },
    {
      accessorKey: "copy",
      header: "Copy",
      cell: (info) => {
        return (
          <Button
            size="sm"
            color="primary"
            outline
            onClick={() => {
              setSelectedRecurringJob(info.row.original);
              toggle();
            }}
          >
            Copy
          </Button>
        );
      },
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => {
        return (
          <button
            onClick={() => deleteRecurring(info.row.original)}
            type="button"
            className="btn btn-sm btn-outline-danger"
          >
            Delete
          </button>
        );
      },
    },
  ];

  return (
    <ReactTable columns={columns} data={recurringJobs} loading={loading} />
  );
};

export default List;
