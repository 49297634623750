import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import getInvoiceDetails from "../invoices/getInvoiceDetails";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";
import SentBadge from "./SentBadge";

const DeliverableTable = ({
  deliverables,
  showJob,
  useSelected,
  setSelectedDeliverables = () => {},
}) => {
  const { user } = useAuth();

  const isPositionAdmin = user.is_position_admin;

  const columns = [
    {
      accessorKey: "name",
      header: "Deliverable Name",
      cell: (info) => {
        const deliverable = info.row.original;

        return <Link to={deliverable.link}>{deliverable.name}</Link>;
      },
    },
    {
      accessorKey: "total_cost",
      header: "Deliverable Value",
      cell: (info) => <SpreadPrice price={info.getValue()} />,
    },
    {
      accessorKey: "sent",
      header: "Deliverable Status",
      cell: (info) => (
        <SentBadge className="rounded-pill shadow" sent={info.getValue()} />
      ),
    },
    {
      accessorKey: "invoiced_at",
      header: "Invoice Status",
      cell: (info) => {
        const invoicedAt = getInvoiceDetails(info.row.original.invoice);
        return (
          <Badge className="rounded-pill shadow" color={invoicedAt.color}>
            {invoicedAt.link}
          </Badge>
        );
      },
    },
    {
      accessorKey: "current_revision.document",
      header: "Download",
      cell: (info) => {
        const document = info.getValue();

        if (!document) {
          return "-";
        }

        return <a href={document.link}>{document.name}</a>;
      },
    },
  ];

  if (showJob) {
    columns.unshift({
      accessorKey: "job.name",
      header: "Job",
      cell: (info) => {
        const deliverable = info.row.original;
        return <Link to={deliverable.job.link}>{deliverable.job.name}</Link>;
      },
    });
  }

  const filteredColumns = isPositionAdmin
    ? columns
    : columns.filter(({ accessorKey }) => accessorKey !== "total_cost");

  if (!useSelected) {
    // Bulk-send deliverables not enabled in this view so remove checkboxes
    return (
      <ReactTable disableSearch columns={filteredColumns} data={deliverables} />
    );
  }

  return (
    <ReactTable
      disableSearch
      enableMultiSelect
      columns={filteredColumns}
      data={deliverables}
      setSelectedRows={setSelectedDeliverables}
    />
  );
};

export default DeliverableTable;
