import React from "react";
import { Field } from "redux-form";
import renderField from "../utils/renderField";
import required from "../utils/required";

const Fields = ({ prefix, validate = true }) => {
  return (
    <>
      <div className="col-lg-4 form-group">
        <Field
          type="text"
          required={validate}
          validate={validate ? required : null}
          component={renderField}
          name={`${prefix ? `${prefix}.` : ""}name`}
          label="Account Name"
        />
      </div>
      <div className="col-lg-3 form-group">
        <Field
          type="text"
          required={validate}
          validate={validate ? required : null}
          component={renderField}
          name={`${prefix ? `${prefix}.` : ""}bsb`}
          label="BSB"
        />
      </div>
      <div className="col-lg-5 form-group">
        <Field
          type="text"
          required={validate}
          validate={validate ? required : null}
          component={renderField}
          name={`${prefix ? `${prefix}.` : ""}number`}
          label="Account Number"
        />
      </div>
    </>
  );
};

export default Fields;
