import {
  AtomicBlockUtils,
  DraftHandleValue,
  EditorState,
  Modifier,
  SelectionState,
} from "draft-js";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useAuth } from "../../../context/auth-context";
import { DataResponse, IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import useUploadMultipleToS3 from "../../hooks/useUploadMultipleToS3";
import { ISubjectItem } from "../subjectTypes";

const useHandleDroppedFiles = (
  item: ISubjectItem,
  editorState: EditorState,
  setEditorState: (state: EditorState) => void,
) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { takeAction }: IUseApi = useApi();

  const { user } = useAuth();

  const { upload } = useUploadMultipleToS3(
    `organisations/${user?.active_organisation.uuid}/subjects/${uuid}/media`,
  );

  const handle = (
    selection: SelectionState,
    files: Array<Blob>,
  ): DraftHandleValue => {
    if (files.length > 1) {
      swal({
        icon: "warning",
        title: "Too many Images",
        text: "Please only upload one image at a time.",
      });

      return "not-handled";
    }
    const allFilesImages = files.every((file) => {
      return file.type.substring(0, 5) === "image";
    });

    if (!allFilesImages) {
      swal({
        icon: "warning",
        title: "Incorrect File Types",
        text: "Only images can be put in knowledge base.",
      });

      return "not-handled";
    }

    upload(files).then((documents) => {
      return takeAction("store", `subject-items/${item.uuid}/media`, {
        documents,
      }).then(({ data }: DataResponse<{ link: string }[]>) => {
        const contentState = editorState.getCurrentContent();
        let newEditorState = editorState;

        const document = data.data[0];
        const img = new Image();
        img.onload = () => {
          const contentStateWithEntity = contentState.createEntity(
            "IMAGE",
            "MUTABLE",
            {
              src: document.link,
              width: img.width,
              height: img.height,
            },
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

          const contentStateWithImage = Modifier.applyEntity(
            contentStateWithEntity,
            selection,
            entityKey,
          );

          newEditorState = AtomicBlockUtils.insertAtomicBlock(
            EditorState.set(editorState, {
              currentContent: contentStateWithImage,
            }),
            entityKey,
            " ",
          );

          setEditorState(newEditorState);
        };
        img.src = document.link;
      });
    });

    return "handled";
  };

  return handle;
};

export default useHandleDroppedFiles;
