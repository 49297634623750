const HEADER_LINKS = [
  // {
  //   id: "home",
  //   icon: "fa fa-home",
  //   heading: "Home",
  //   link: "/home",
  //   value: "Home",
  // },
  {
    id: "dashboards",
    heading: "Dashboards",
    icon: "fa fa-line-chart",
    links: [
      { link: "/home/management", value: "Management (BETA)", beta: true },
      { link: "/home/dashboard", value: "Overall", beta: true },
      { link: "/branch-jobs", value: "Branch" },
      { link: "/project-manager", value: "Project Manager" },
      { link: "/job-manager", value: "Job Manager" },
      { link: "/job-overview-dashboard", value: "Job Overview" },
      { link: "/schedule", value: "Schedule" },
      { link: "/noticeboard", value: "Noticeboard" },
    ],
  },
  {
    id: "registers",
    heading: "Registers",
    icon: "fa fa-list-ul",
    nav_type: "mega",
    links: [],
  },
  {
    id: "documents",
    heading: "Resources",
    icon: "fa fa fa-info",
    links: [
      {
        link: "/documents",
        value: "Controlled Documents",
      },
      {
        link: "/topics",
        value: "Knowledge Base",
      },
    ],
  },
  {
    id: "organisation",
    admin: true,
    icon: "fa fa-building-o",
    heading: "Organisation",
    link: "/organisations/settings/links",
    value: "Organisation",
  },
];

export default HEADER_LINKS;
