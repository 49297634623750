import React from "react";
import { Field, reduxForm } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

const Edit = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="name"
            label="Name"
            required
            validate={required}
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "EditSupplierType" });

export default form(Edit);
