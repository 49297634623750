import React from "react";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { EmploymentAward } from "./types";

const EmploymentAwardForm = (props: InjectedFormProps<EmploymentAward>) => {
  const { handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="name"
            label="Name"
            validate={required}
            required
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="abbreviation"
            label="Abbreviation"
            validate={required}
            required
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </Form>
  );
};

const form = reduxForm<EmploymentAward>({});

export default form(EmploymentAwardForm);
