import { WrappedFieldArrayProps } from "redux-form";
import useApi from "../api/useApi";
import FormHeader from "../utils/FormHeader";
import { Button } from "reactstrap";
import RegisterFields from "./RegisterFields";

const RegisterWrapper = (props: WrappedFieldArrayProps & { form: string }) => {
  const { fields, form } = props;

  const { data } = useApi("registers", []);

  return (
    <>
      <FormHeader>Registers</FormHeader>

      {fields.map((field, index) => {
        return (
          <RegisterFields
            form={form}
            registers={data.map((d: any) => {
              return {
                label: d.name,
                value: d.id,
              };
            })}
            key={field}
            field={field}
            index={index}
            fields={fields}
          />
        );
      })}

      <div className="col-12">
        <Button onClick={() => fields.push({})}>Add Register</Button>
      </div>
    </>
  );
};

export default RegisterWrapper;
