import React from "react";
import { useParams } from "react-router-dom";
import { InjectedFormProps, reduxForm } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import RecurringJobFields from "./RecurringJobFields";
import { RecurringJob } from "./recurringJobTypes";
import { toast } from "react-toastify";
import SubmitButton from "../utils/SubmitButton";

interface EditRecurringJobProps {
  setRecurringJob: (job: RecurringJob) => void;
}

const EditRecurringJob = (
  props: EditRecurringJobProps & InjectedFormProps<any, EditRecurringJobProps>,
) => {
  const { handleSubmit, change, setRecurringJob, form, initialValues } = props;
  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction("update", `recurring-jobs/${uuid}`, values)
      .then(({ data }: { data: { data: RecurringJob } }) => {
        toast.success("Recurring Job Updated");
        setRecurringJob(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <RecurringJobFields
          project={initialValues?.project}
          change={change}
          form={form}
          initialized={props.initialized}
        />
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<{}, EditRecurringJobProps>({
  form: "EditRecurringJob",
});

export default form(EditRecurringJob);
