import React from "react";
import { Sample, Test } from "./testRequestTypes";
import ProfileButtons from "../utils/ProfileButtons";
import useModal from "../hooks/useModal";
import ToggleTestModal from "./ToggleTestModal";
import { Table } from "@tanstack/react-table";

const TestActions = ({
  tests,
  selectedTests,
  setSelectedTests,
  samples,
  setSamples,
  tableInstance,
  fetchTests,
}: {
  tests: Test[];
  selectedTests: Test[];
  setSelectedTests: (tests: Test[]) => void;
  samples: Sample[];
  setSamples: (samples: Sample[]) => void;
  tableInstance?: Table<any>;
  fetchTests: Function;
}) => {
  const allSelected = tests.length === selectedTests.length;
  const { modal, setModal } = useModal();

  const toggle = () => {
    if (!modal) {
      setModal(true);
      return;
    }

    fetchTests();
    setModal(false);
  };

  const buttons = [
    {
      text: (
        <>
          <i className="icon ion-compose tx-primary" /> Create/Edit Tests
        </>
      ),
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => toggle(),
    },
  ];

  if (tests.length > 0) {
    buttons.push({
      text: allSelected ? (
        <>
          <i className="icon ion-close tx-danger" />
          Deselect All
        </>
      ) : (
        <>
          <i className="icon ion-checkmark tx-success" /> Select All
        </>
      ),
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        setSelectedTests(allSelected ? [] : tests);
        tableInstance?.toggleAllRowsSelected(!allSelected);
      },
    });
  }

  return (
    <>
      <ProfileButtons buttons={buttons} />
      <ToggleTestModal
        setSamples={setSamples}
        modal={modal}
        toggle={toggle}
        samples={samples}
      />
    </>
  );
};

export default TestActions;
