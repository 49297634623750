import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import dayjs from "dayjs";

const ProfileBar = (props) => {
  const { payRun, setPayRun, className } = props;
  const { takeAction, loading } = useApi();

  const { uuid } = useParams();

  const toggleCompletedAt = () => {
    const data = {
      completed_at: payRun.completed_at ? null : dayjs(),
    };

    return takeAction("update", `pay-runs/${uuid}`, data)
      .then(({ data }) => {
        setPayRun(data.data);
        toast.success("Pay Run Updated");
      })
      .catch(errorSwal);
  };

  return (
    <ul className={`nav nav-activity-profile ${className}`}>
      <li className="nav-item">
        <button
          type="button"
          disabled={loading}
          onClick={toggleCompletedAt}
          className="nav-link w-100 pointer-hover link-hover"
        >
          {isSubmitting(
            loading,
            `Mark ${payRun.completed_at ? "in" : ""}complete`,
            "Updating...",
          )}
        </button>
      </li>
    </ul>
  );
};

export default ProfileBar;
