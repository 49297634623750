import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";

const PurchaseTable = ({ data }) => {
  if (!data) return null;

  if (data?.length === 0) return null;

  const columns = [
    {
      accessorKey: "name",
      header: "Purchase Number",
      cell: (info) => {
        const purchase = info.row.original;
        return (
          <>
            <Link to={purchase.link}>{purchase.name}</Link>
            {purchase.status && (
              <>
                <br />

                <Badge
                  className="mt-1 shadow rounded-pill"
                  color={purchase.status.color}
                >
                  {purchase.status.text}
                </Badge>
              </>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "realised_total_cost",
      header: "Purchase Price",
      cell: (info) => {
        const purchase = info.row.original;

        if (!purchase.realised_total_cost && purchase.imported_cost) {
          return <SpreadPrice price={purchase.imported_cost} />;
        }

        return <SpreadPrice price={info.getValue()} />;
      },
    },
    {
      accessorKey: "tax_type",
      header: "Amounts Are",
    },
    {
      accessorKey: "supplier.name",
      header: "Supplier",
      cell: (info) => {
        const purchase = info.row.original;
        if (purchase.supplier.uuid) {
          return (
            <Link to={`/suppliers/${purchase.supplier.uuid}/purchases`}>
              {info.getValue()}
            </Link>
          );
        }
        return info.getValue() || "-";
      },
    },
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info) => {
        const purchase = info.row.original;

        if (!purchase.project) return "-";

        return (
          <Link to={`/projects/${purchase.project.uuid}/purchases`}>
            {purchase.project.name}
          </Link>
        );
      },
    },
  ];

  return <ReactTable disableSearch columns={columns} data={data} />;
};

export default PurchaseTable;
