const openXmlExcelMimeTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx: Standard Excel workbook without macros
  "application/vnd.ms-excel.sheet.macroenabled.12", // .xlsm: Excel workbook with macros
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template", // .xltx: Excel template without macros
  "application/vnd.ms-excel.template.macroenabled.12", // .xltm: Excel template with macros
  "application/vnd.ms-excel.addin.macroenabled.12", // .xlam: Excel add-in with macros
  "application/vnd.ms-excel.sheet.binary.macroenabled.12", // .xlsb: Binary Excel file with macros (also considered part of Open XML due to its structure and compatibility)
];

export default openXmlExcelMimeTypes;
