import React, { useMemo } from "react";
import useFilter from "../hooks/useFilter";
import useObserver from "../hooks/useObserver";
import usePagination from "../hooks/usePagination";
import { Range } from "../hooks/useProductivityRange";
import useSort, { ISorter } from "../hooks/useSort";
import {
  projectFilters,
  ProjectFilterType,
} from "../projectManagers/ProjectManagerDashboard";
import ProjectManagerFilters from "../projectManagers/ProjectManagerFilters";
import ProjectManagerProject from "../projectManagers/ProjectManagerProject";
import { IProjectManagerProject } from "../projectManagers/projectManagerTypes";
import flattenInfinitePages from "../utils/flattenInfinitePages";
import CustomScaleLoader from "../utils/scaleLoader";
import SortDropdown from "../utils/SortDropdown";
import useProjectOverview from "./hooks/useProjectOverview";
import { rangeQuery } from "./ManagementCPIRs";
import { Group } from "./managementDashboardTypes";
import TotalInvoicedLine from "./TotalInvoicedLine";
interface ProjectOverviewProps {
  selectedGroup?: Group;
  range: Range;
}
const ProjectOverview = ({ selectedGroup, range }: ProjectOverviewProps) => {
  const { searchQuery, onSearch } = usePagination();

  const {
    sorters,
    toggleSorter,
    stringified: sortString,
  } = useSort(projectSorters);

  const projectFiltersWithoutPeriod = useMemo(
    () => projectFilters.filter((filter) => filter.name !== "period"),
    [projectFilters],
  );

  const { stringified, filters, toggleFilter, filterCount } = useFilter<
    ProjectFilterType,
    number | [string, string]
  >(projectFiltersWithoutPeriod);

  const { data, isFetchingNextPage, fetchNextPage, isFetching } =
    useProjectOverview(
      selectedGroup,
      rangeQuery(range)
        ? searchQuery + stringified + rangeQuery(range) + sortString
        : "",
    );

  const intersection = useObserver(
    () => selectedGroup && !isFetchingNextPage && fetchNextPage(),
  );

  const projects = flattenInfinitePages<IProjectManagerProject>(data);
  const meta = data?.pages?.[0].meta;

  return (
    <>
      <p>You have {meta?.total} projects.</p>

      <TotalInvoicedLine group={selectedGroup} range={range} />

      <div className="d-flex align-items-center  mb-3">
        <div className="search-box shadow-sm flex-grow-1 d-flex">
          <input
            autoComplete="off"
            placeholder="Search..."
            className="form-control w-100"
            type="search"
            style={{ zIndex: 1 }}
            onChange={onSearch}
          />
          <button className="btn btn-primary ms-auto" type="button">
            <i
              className={`fa fa-${isFetching ? "spinner fa-spin" : "search"}`}
            />
          </button>
        </div>
        <ProjectManagerFilters
          filterCount={filterCount}
          filters={filters}
          toggleFilter={toggleFilter}
        />
        <SortDropdown sorters={sorters} toggleSorter={toggleSorter} />
      </div>
      <div className="row">
        {projects?.map((project) => {
          return (
            <ProjectManagerProject
              key={project.uuid}
              project={project}
              filters={filters}
            />
          );
        })}
      </div>
      <div ref={intersection} />
      {isFetchingNextPage && (
        <CustomScaleLoader>Fetching More Projects...</CustomScaleLoader>
      )}
    </>
  );
};

const projectSorters: ISorter<
  "name" | "created_at" | "realised_value" | "organisation_clients.name"
>[] = [
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Created",
    name: "created_at",
    sortBy: "desc",
  },
  {
    label: "Realised Value",
    name: "realised_value",
  },
  {
    label: "Client Name",
    name: "organisation_clients.name",
  },
];

export default ProjectOverview;
