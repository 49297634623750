import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import ProfileButtons from "../utils/ProfileButtons";
import errorSwal from "../utils/errorSwal";
import { Invoice, LineItemStyle } from "./invoiceTypes";
import isSubmitting from "../utils/submitting";
import { toast } from "react-toastify";

interface ToggleAggregateByButtonProps {
  invoice: Invoice;
  setInvoice: (invoice: Invoice) => void;
}

const ToggleAggregateByButton = (props: ToggleAggregateByButtonProps) => {
  const { invoice, setInvoice } = props;
  const { takeAction, loading }: IUseApi<{}, { data: Invoice }> = useApi();

  const { uuid } = useParams<{ uuid: string }>();

  const toggle = () => {
    takeAction("update", `invoices/${uuid}/aggregate`)
      .then(({ data }) => {
        toast.success("Aggregate Method Changed");
        setInvoice(data.data);
      })
      .catch(errorSwal);
  };

  const buttons = [
    {
      disabled: loading,
      onClick: toggle,
      text: isSubmitting(
        loading,
        <>
          {" "}
          Aggregate Line Items by{" "}
          {invoice.line_item_style === LineItemStyle.Deliverables
            ? "Jobs"
            : "Deliverables"}
        </>,
        "Switching...",
      ),
    },
  ];

  return <ProfileButtons buttons={buttons} />;
};

export default ToggleAggregateByButton;
