import { FormSubmitHandler } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import formError from "../utils/formError";
import RegisterItemForm, { RegisterItemFormProps } from "./RegisterItemForm";
import { RegisterItem, RegisterItemProps } from "./registerItemTypes";
import { toast } from "react-toastify";

const EditRegisterItem = (props: RegisterItemProps) => {
  const { item, setItem } = props;

  const { user } = useAuth();

  const { takeAction }: IUseApi<{}, { data: RegisterItem }> = useApi();

  const { upload } = useUploadCustomFields(
    `${user?.active_organisation.uuid}/register-items/${item.uuid}`,
  );

  const onSubmit: FormSubmitHandler<{}, RegisterItemFormProps> = (values) => {
    return upload(values).then((values) =>
      takeAction("update", `/register-items/${item.uuid}`, values)
        .then(({ data }) => {
          setItem(data.data);
          toast.success(`${item.register.name} updated`);
        })
        .catch(formError),
    );
  };

  return (
    <RegisterItemForm
      registerItem={item}
      initialValues={item}
      form="EditRegisterItem"
      onSubmit={onSubmit}
    />
  );
};

export default EditRegisterItem;
