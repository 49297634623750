import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { DataResponse, IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { SubjectChildrenProps } from "./Subject";
import { ISubject } from "./subjectTypes";

const EditHeader = ({ subject, setSubject }: SubjectChildrenProps) => {
  const [name, setName] = useState(subject?.name ?? "");
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();

  const { takeAction }: IUseApi = useApi();
  const onBlur = () => {
    takeAction("update", `subjects/${uuid}`, { name })
      .then(({ data }: DataResponse<ISubject>) => {
        setSubject(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <div className="manager-header">
      <div className="d-flex py-4">
        <h6 className="slim-pagetitle flex-fill">
          {user?.hasAccessTo("App\\Models\\Subject", "update") ? (
            <input
              type="text"
              className="bg-transparent slim-pagetitle no-focus"
              placeholder="Set Title Here..."
              style={{
                border: "none",
                boxShadow: "none",
                width: "100%",
              }}
              onBlur={onBlur}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          ) : (
            name
          )}
        </h6>
        <ol className="breadcrumb slim-breadcrumb ms-auto me-2">
          <li className="breadcrumb-item">
            <Link to="/home">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/topics">Topics</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/topics/${subject?.topic?.uuid}/subjects`}>
              {subject?.topic?.name}
            </Link>
          </li>
          <li className="breadcrumb-item">{subject?.name}</li>
        </ol>
      </div>
    </div>
  );
};

export default EditHeader;
