import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { ShowPage } from "./ShowPage";
import Tabs from "./Tabs";

const Profile = (props) => {
  const { uuid } = useParams();

  const { data: project, loading, response } = useApi(
    `suppliers/projects/${uuid}`,
  );

  if (loading || !response) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={project.name}
        crumbs={[{ link: "/projects", name: "Projects" }]}
      />
      <div className="row">
        <div className="col-lg-8">
          <DashboardHeader>
            <DashboardH3>{project.name}</DashboardH3>
            <DashboardSubTitle>
              <div>Project Manager: {project.manager.name}</div>
              <div>
                Phone:{" "}
                <a href={`mailto:${project.manager.email}`}>
                  {project.manager.email}
                </a>
              </div>
              <div>
                Email:{" "}
                <a href={`tel:${project.manager.phone}`}>
                  {project.manager.phone}
                </a>
              </div>
            </DashboardSubTitle>
          </DashboardHeader>
          <ShowPage {...props} project={project} />
        </div>
        <div className="col-lg-4">
          <Tabs />
        </div>
      </div>
    </>
  );
};

export default Profile;
