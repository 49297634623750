import React from "react";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import SVGContainer from "./components/utils/SVGContainer";
import { ReactComponent as Error } from "./svgs/error.svg";

const ErrorFallback = () => {
  const history = useHistory();

  return (
    <div className="mg-t-20 d-flex justify-content-center align-items-center">
      <SVGContainer
        wrapperClass="w-100"
        width="35%"
        height="30%"
        SVG={Error}
        top={false}
      >
        <div className="text-center">
          <p className="mg-y-10  tx-18 tx-inverse font-semibold">
            Something went wrong.
          </p>
          {/* <p className="mg-y-10">Our developers have been notified.</p> */}
          <Button outline onClick={history.goBack}>
            Go back
          </Button>
        </div>
      </SVGContainer>
    </div>
  );
};

export default ErrorFallback;
