import { useQuery } from "react-query";
import api from "../../api/api";

export default function useProjectCalendarJobs(
  uuid: string,
  dateRange: { start: string; end: string },
) {
  return useQuery("project-calendar-jobs", () =>
    api
      .get(
        `projects/${uuid}/jobs?dateRange[]=${dateRange.start}&dateRange[]=${dateRange.end}`,
      )
      .then(({ data }) => data),
  );
}
