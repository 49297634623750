import React from "react";

const PromoModal = props => {
  const { toggle, modal } = props;

  return (
    <div
      className="position-fixed vw-100 vh-100 t-0 r-0 l-0 b-0"
      style={{
        backgroundColor: modal ? "#000000d4" : "#49000000",
        zIndex: modal ? 3 : -1,
        transition: "0.5s"
      }}
    >
      <div className="p-5 m-auto w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="position-fixed t-10 r-10">
          <button
            type="button"
            className="close tx-white"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggle}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <video
          controls
          style={{ transform: `scaleY(${modal ? 1 : 0})`, transition: "0.5s" }}
        >
          <source
            src="https://boss-prod.s3.ap-southeast-2.amazonaws.com/theBOSSapp_promo.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default PromoModal;
