import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { deleteStaffRoleType } from "../../actions/staffRoleTypesActions";
import SelectInput from "../form/SelectInput";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import required from "../utils/required";

const TypeFields = ({
  index,
  member,
  fields,
  initialValues,
  roles,
  deleteStaffRoleType,
}) => {
  const removeVal = () => {
    if (
      initialValues &&
      initialValues.role_types &&
      initialValues.role_types[index]
    ) {
      const roleType = initialValues.role_types[index];
      return deleteSwal()
        .then(() => {
          return deleteStaffRoleType(roleType.uuid);
        })
        .then(() => {
          fields.remove(index);
        })
        .catch(errorSwal);
    }
    fields.remove(index);
  };
  return (
    <>
      <div className="form-group col-lg-9">
        <Field
          name={`${member}.type_id`}
          component={SelectInput}
          options={roles}
          label={`Role Type ${index + 1}`}
          required
          validate={required}
        />
      </div>
      <div className="form-group col-lg-3 d-flex align-items-end">
        <button
          className="btn form-control btn-outline-danger"
          type="button"
          onClick={removeVal}
        >
          Delete
        </button>
      </div>
    </>
  );
};

export default connect(null, { deleteStaffRoleType })(TypeFields);
