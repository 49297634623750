import {
  Field,
  FieldArray,
  InjectedFormProps,
  WrappedFieldArrayProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { AccountsPayable } from "./types";
import SelectInput from "../form/SelectInput";
import { Button } from "reactstrap";
import FormHeader from "../utils/FormHeader";
import { connect } from "react-redux";
import isSubmitting from "../utils/submitting";
import FormErrorAlert from "../form/FormErrorAlert";

const InboundRecurringPurchaseForm = (
  props: InjectedFormProps<any, { recurringPurchase: AccountsPayable }> & {
    recurringPurchase: AccountsPayable;
  },
) => {
  const { handleSubmit, change } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-12 form-group">
          <Field
            name="date"
            component={RenderField}
            label="Date"
            required
            validate={required}
            type="date"
          />
        </div>
        <div className="col-12 form-group" id="invoice_number">
          <Field
            name="number"
            component={RenderField}
            label="Supplier Invoice Number"
            required
            validate={required}
          />
        </div>
        <FieldArray
          component={ConnectedPaymentFieldArray}
          {...props}
          name="payment"
          change={change}
        />
        <div className="col-12">
          <Button
            disabled={props.submitting}
            color="primary"
            outline={!props.valid}
            type="submit"
            block
          >
            {isSubmitting(props.submitting, "Save", "Saving...")}
          </Button>
        </div>
      </div>
    </form>
  );
};

const PaymentFieldArray = ({
  fields,
  recurringPurchase,
  items,
  change,
}: WrappedFieldArrayProps & {
  recurringPurchase: AccountsPayable;
  items: any[];
  change: Function;
}) => {
  const total = items?.reduce((acc, item) => {
    if (!item.quantity || !item.price) return acc;

    return acc + item.quantity * item.price;
  }, 0);

  return (
    <>
      <FormHeader>
        Items (${total ?? 0} - ${recurringPurchase.includes_gst_description})
      </FormHeader>
      {fields.map((field, index) => {
        return (
          <>
            <div className="col-12 form-group">
              <Field
                component={SelectInput}
                options={
                  recurringPurchase.prices?.map((price) => ({
                    label: `${price.description} (${price.branch})`,
                    value: price.id,
                    ...price,
                  })) ?? []
                }
                name={`${field}.item`}
                label="Item"
                changeValue={(value: { price: number }) => {
                  change(`${field}.price`, value.price);
                }}
                required
                validate={required}
              />
            </div>
            <div className="col-8 form-group">
              <Field
                component={RenderField}
                name={`${field}.quantity`}
                label="Quantity"
                required
                validate={required}
              />
            </div>
            <div className="col-4 form-group align-self-end">
              <Button
                color="danger"
                onClick={() => fields.remove(index)}
                outline
                block
              >
                Delete
              </Button>
            </div>
          </>
        );
      })}
      <div className="col-12 form-group">
        <Button onClick={() => fields.push({})}>Add</Button>
      </div>
    </>
  );
};

const form = reduxForm<
  any,
  {
    recurringPurchase: AccountsPayable;
  }
>({
  enableReinitialize: true,
});

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("inbound_recurring_purchase");

  return {
    items: selector(state, "payment"),
  };
};

const ConnectedPaymentFieldArray = connect(mapStateToProps)(PaymentFieldArray);

export default form(InboundRecurringPurchaseForm);
