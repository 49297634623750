import { AuthUser } from "./../../user/userTypes";
import { useHistory } from "react-router-dom";

export const useRedirectOnLogin = () => {
  const history = useHistory();

  const redirect = (user: AuthUser) => {
    const returnPath = localStorage.getItem("return_path");

    if (returnPath) {
      history.push(returnPath);
      localStorage.removeItem("return_path");
      return;
    }

    history.push(
      user.multiple_organisations ? "/organisations/select" : "home",
    );
  };

  return redirect;
};
