import { Editor, EditorState, convertFromRaw } from "draft-js";
import { useState } from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormModal from "../utils/FormModal";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { myBlockRenderer } from "../subjects/SubjectItemEditor";

interface ISubjectItemReminder {
  uuid: string;
  name: string;
  content: string;
  subject: {
    name: string;
  };
}

const SubjectItemReminder = () => {
  const { data }: IUseApi = useApi("subject-items-to-read", []);

  const { takeAction }: IUseApi = useApi();

  const [currentItem, setCurrentItem] = useState(0);

  const onSubmit = () => {
    if (currentItem >= subjectItemReminders.length) {
      return;
    }

    return takeAction(
      "store",
      `subject-items/${subjectItemReminders[currentItem].uuid}/read`,
    )
      .then(() => {
        toast.success("Item marked as read");
        setCurrentItem(currentItem + 1);
      })
      .catch(errorSwal);
  };

  const subjectItemReminders = data as ISubjectItemReminder[];

  if (
    subjectItemReminders.length === 0 ||
    currentItem >= subjectItemReminders.length
  ) {
    return null;
  }

  const currentSubjectItem = subjectItemReminders[currentItem];

  const editorState = EditorState.createWithContent(
    convertFromRaw(
      JSON.parse(
        currentSubjectItem.content ??
          JSON.stringify({ blocks: [], entityMap: {} }),
      ),
    ),
  );

  return (
    <FormModal
      title="You Have Items in the Knowledge Base you Must Read"
      modal={true}
      form="SUBJECT_ITEM_REMINDERS"
      save="I have read and understood this item"
      onSubmit={onSubmit}
    >
      <div className="d-flex">
        <h6 className="slim-card-title">{currentSubjectItem.name}</h6>
        <p className="mb-0 ms-auto">
          {currentItem + 1} of {subjectItemReminders.length}
        </p>
      </div>
      <div className="text-gray">
        <Editor
          editorState={editorState}
          onChange={() => {}}
          readOnly={true}
          blockRendererFn={(contentBlock) =>
            myBlockRenderer(contentBlock, editorState)
          }
          spellCheck
        />
      </div>
    </FormModal>
  );
};

export default SubjectItemReminder;
