import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { connect } from "react-redux";
import _ from "lodash";
import {
  updateNotifications,
  updateNotification,
} from "../../actions/notificationsActions";

class NotificationsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationHovered: "",
    };
  }

  hover = (notificationHovered) => this.setState({ notificationHovered });

  render() {
    const { props } = this;
    const { notificationHovered } = this.state;
    const {
      notifications,
      updateNotifications,
      amount,
      showRead,
      updateNotification,
    } = props;
    const orderedNotifications = _.orderBy(
      notifications,
      [(notification) => !notification.read_at, "created_at"],
      ["desc", "desc"],
    );

    return (
      <Fragment>
        {notifications && notifications.length ? (
          _.take(orderedNotifications, amount || notifications.length).map(
            (notification) => {
              if (!showRead && notification.read_at) return null;
              return (
                <div
                  className="d-flex border-bottom dropdown-link"
                  key={notification.id}
                >
                  <div
                    className={`
                    bg-gray-200
                    ${!notification.read_at ? "bg-info" : ""}
                    ${
                      notificationHovered === notification.id
                        ? "wd-10 bg-gray-400"
                        : "wd-5"
                    } border-bottom pointer-hover`}
                    onClick={() => updateNotification(notification.id)}
                    onMouseEnter={() => this.hover(notification.id)}
                    onMouseLeave={() => this.hover("")}
                  />
                  <Link
                    to={`${notification.data.link}`}
                    onClick={() => updateNotifications([notification.id])}
                    className="w-100"
                    key={notification.id}
                  >
                    <div
                      className={`d-flex w-100 ps-0 pt-1 ${
                        !notification.read_at ? "bg-light" : ""
                      } align-items-center`}
                    >
                      <svg
                        height="45"
                        width="45"
                        className={`${
                          notificationHovered === notification.id
                            ? "mg-l-10"
                            : "mg-l-15"
                        }`}
                      >
                        <circle cx="20" cy="20" r="20" fill="#c5c5c5" />
                        <text
                          fill="#ffffff"
                          fontSize="16"
                          textAnchor="middle"
                          x="20"
                          y="25"
                        >
                          {notification.data.user}
                        </text>
                      </svg>
                      <div className="media-body">
                        <p className="mg-b-0">
                          {notification.data.description}
                        </p>
                        <span>
                          {dayjs(notification.created_at).format(
                            "MMMM DD, YYYY hh:mm a",
                          )}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            },
          )
        ) : (
          <Link className="dropdown-link" to="/notifications/show">
            <div className="flex-grow-1">
              <div className="media-body">
                <p className="mg-b-0">No new notifications</p>
              </div>
            </div>
          </Link>
        )}
      </Fragment>
    );
  }
}

export default connect(null, { updateNotifications, updateNotification })(
  NotificationsDetails,
);
