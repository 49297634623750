import React, { useState, useEffect } from "react";
import FormModal from "../../utils/FormModal";
import { Field } from "redux-form";
import SelectInputAsync from "../../utils/SelectInputAsync";
import useApi from "../../api/useApi";
import { useAuth } from "../../../context/auth-context";
import Fields from "../../purchases/Fields";
import errorSwal from "../../utils/errorSwal";
import { PurchaseOrder } from "../../purchaseOrders/purchaseOrderTypes";
import { InjectedFormProps } from "redux-form";
import { PurchaseItem } from "../../purchases/purchaseTypes";

const AddToExistingPurchaseOrderModal = (props: any) => {
    const [uuid, setUuid] = useState<string>('');
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder | null>(null);
    const [updatedPurchaseOrder, setUpdatedPurchaseOrder] = useState<PurchaseOrder | null>(null);
    const { takeAction } = useApi();

    useEffect(() => {
        setUuid('');
        setUpdatedPurchaseOrder(null);
      }, [props.modal]);

    const handleInputChange = (uuid: any) => {
        setUuid(uuid);
    };

    useEffect(() => {
        if (uuid) {
          takeAction("index", `purchase-orders/${uuid}`)
            .then(({ data } : {data : any}) => {
              setPurchaseOrder(data.data);
            })
            .catch((error: any) => {
              errorSwal(error);
            });
        } else {
          setPurchaseOrder(null);
        }
      }, [uuid]);
    
    const updatePurchaseItems = (existingItems: PurchaseItem[], newItems: PurchaseItem[]) => {
        return existingItems.map((item) => {
            const matchingMethod = newItems.find((newItem) => newItem.method === item.method);

            if (matchingMethod) {
                item.tests = [...matchingMethod.tests, ...item.tests];
                item.quantity += matchingMethod.quantity;
                item.description += `, ${matchingMethod.description}`;
            }

            return item;
        });
    };
    
    const mergeNewMethods = (existingItems: PurchaseItem[], newItems: PurchaseItem[]) => {
        const newMethods = newItems.filter((newItem) => !existingItems.some((item) => item.method === newItem.method));

        return newMethods.map((newMethod) => ({
            method: newMethod.method,
            quantity: newMethod.quantity,
            tests: newMethod.tests,
            description: generateDescription(newMethod.method, newMethod.description),
        }));
    };
    
    useEffect(() => {
        if (purchaseOrder) {
            if (purchaseOrder.purchase_items.length === 0) {
                const updatedPurchaseItems = props.purchaseItems.map((item: PurchaseItem) => ({
                    ...item,
                    description: generateDescription(item.method, item.description),
                }));
                setUpdatedPurchaseOrder({ ...purchaseOrder, purchase_items: updatedPurchaseItems });
            } else {
                const updatedItems = updatePurchaseItems(purchaseOrder.purchase_items, props.purchaseItems);
                const newMethods = mergeNewMethods(purchaseOrder.purchase_items, props.purchaseItems);
                const mergedItems = [...updatedItems, ...newMethods];
                const updatedPurchaseOrder = { ...purchaseOrder, purchase_items: mergedItems };
                console.log(updatedPurchaseOrder);
                setUpdatedPurchaseOrder(updatedPurchaseOrder);
            }
        }
    }, [purchaseOrder]);

    const generateDescription = (method: string | undefined, description: string | undefined) => {
        return `${method} for samples ${description}`;
    }

    return (
        <FormModal {...props} initialValues={updatedPurchaseOrder}>
          {(formProps: InjectedFormProps) => (
            <>
              <div className="col-12 form-group">
                <Field
                  label="Select Purchase Order"
                  component={SelectInputAsync}
                  name="uuid"
                  isSearchable={false}
                  onChange={handleInputChange}
                  url={`purchases?filter[test_request_id]=${props.test_request_id}&filter[not_sent]=1&filter[is_archived]=0`}
                  formatData={({ data }: { data: { name: string; uuid: string }[] }) =>
                    data.map((d) => ({ label: d.name, value: d.uuid }))
                  }
                />
              </div>
    
              {purchaseOrder && (
                <>
                  <Fields
                    purchaseOrder
                    {...props}
                    setItem={setPurchaseOrder}
                    item={purchaseOrder}
                    {...formProps}
                  />
                </>
              )}
            </>
          )}
        </FormModal>
    );
};

export default AddToExistingPurchaseOrderModal;
