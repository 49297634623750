import React, { useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formatMoney from "../utils/formatMoney";
import ProfitAndLossForm from "./ProfitAndLossForm";

const ProfitAndLoss = () => {
  const { uuid } = useParams();

  const { takeAction } = useApi();

  const [profitLoss, setProfitLoss] = useState(null);

  const onSubmit = (values) => {
    return takeAction(
      "store",
      `organisation-branches/${uuid}/profit-loss`,
      values,
    ).then(({ data }) => setProfitLoss(data.data));
  };

  return (
    <>
      <HeaderPage titlePage="Profit Loss" crumbs={[]} />
      <ProfitAndLossForm
        onSubmit={onSubmit}
        initialValues={{
          from: dayjs().startOf("month").format("YYYY-MM-DD"),
          to: dayjs().endOf("month").format("YYYY-MM-DD"),
          periods: 5,
          time_frame: "MONTH",
          payments_only: false,
        }}
      />
      {profitLoss && (
        <div className="bg-white p-4 border">
          <div className="mb-4">
            <p className="tx-inverse fw-bolder mb-0 tx-20">
              {profitLoss.ReportTitles[0]}
            </p>
            <p className="mb-0">{profitLoss.ReportTitles[1]}</p>
            <p className="mb-0">{profitLoss.ReportTitles[2]}</p>
          </div>

          <hr />

          {profitLoss.Rows.map(function (row) {
            if (row.RowType === "Header") {
              return null;
            }

            return (
              <div className="mb-3">
                {row.Title && (
                  <p className="tx-inverse fw-bolder tx-18 mb-0">
                    {row.Title}
                    <hr className="mt-0" />
                  </p>
                )}
                {row.Rows.map(function (innerRow, index) {
                  if (innerRow.Cells[1].Value == "0.00") {
                    return null;
                  }

                  const isSummary = innerRow.RowType === "SummaryRow";

                  const isNet = !row.Title;

                  const isBold = isSummary || isNet;

                  return (
                    <div>
                      <div className={`d-flex  ${!isNet ? "px-3" : "pe-3"}`}>
                        <p
                          className={`mb-0 ${
                            isBold ? "fw-bolder tx-inverse" : ""
                          }`}
                        >
                          {innerRow.Cells[0].Value}
                        </p>

                        <p
                          className={`mb-0 ms-auto ${
                            isBold ? "tx-inverse fw-bolder" : ""
                          }`}
                        >
                          {format(innerRow.Cells[1].Value)}
                        </p>
                      </div>
                      {index + 1 !== row.Rows.length && <hr />}
                    </div>
                  );
                })}
                <hr />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const format = (value) => {
  if (isNaN(value)) return value;

  return value < 0 ? `(${formatMoney(Math.abs(value))})` : formatMoney(value);
};

export default ProfitAndLoss;
