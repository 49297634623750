import React from "react";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";
import { ProjectForm, RouteParams } from "./projectForm";

const Edit = (props : any) => {
  const { takeAction } = useApi();
  const { uuid } = useParams<RouteParams>();
  const { setProjectForm, projectForm } = props;

  const onSubmit = (values : any) => {
    return takeAction("update", `project-forms/${uuid}`, values)
      .then(({ data } : { data:any }) => {
        setProjectForm(data.data);
        toast.success(`${values.name} updated`);
      })
      .catch(errorSwal);
  };

  return (
    <Form
      onSubmit={onSubmit}
      form="EditProjectForm"
      initialValues={projectForm}
    />
  );
};

export default Edit;
