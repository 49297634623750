import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import { AiOutlineCheck } from "react-icons/ai";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const StaffRoleReminder = () => {
  const { takeAction, data, setData, loading } = useApi(
    "staff-role-reminders",
    [],
  );

  const complete = () => {
    return takeAction("destroy", "staff-role-reminders")
      .then(() => {
        toast.success("Complete");
        setData([]);
      })
      .catch(errorSwal);
  };

  const cancel = (staffRole) => {
    return takeAction("store", `staff-role-reminders/${staffRole.uuid}`)
      .then(() => {
        toast.success("Email Sent to HR to cancel.");
        setData(
          data.map((d) =>
            d.uuid === staffRole.uuid
              ? {
                  ...d,
                  canceled: true,
                }
              : d,
          ),
        );
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Employee's",
    },
    {
      accessorKey: "update",
      header: "Cancel?",
      cell: (info) => {
        const staffRole = info.row.original;
        if (staffRole.canceled) {
          return <AiOutlineCheck className="tx-success" />;
        }
        return (
          <Button size="sm" outline onClick={() => cancel(staffRole)}>
            Cancel
          </Button>
        );
      },
    },
  ];

  return (
    <Modal isOpen={data.length > 0} backdrop="static">
      <ModalHeader>Current Employee's in your Branch</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <p className="mb-0">
            The following Employees had no timesheets put in last month.
          </p>
          <p className="mb-0">
            Please let us know if you want to cancel their position within your
            Branch.
          </p>
          <p className="mb-0">
            If no action is required, please click "Complete".
          </p>
        </div>
        <ReactTable
          disableSearch
          columns={columns}
          data={data}
          loading={loading}
        />
      </ModalBody>
      <ModalFooter>
        <Button outline color="secondary" onClick={() => setData([])}>
          Remind Me Later
        </Button>
        <Button color="primary" onClick={complete}>
          Complete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StaffRoleReminder;
