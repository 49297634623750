import React from "react";
import TypeFields from "./TypeFields";
import FormHeader from "../utils/FormHeader";
import { Button } from "reactstrap";

const StaffRoleType = ({ fields, initialValues, roles }) => {
  return (
    <>
      <FormHeader>Role Types</FormHeader>
      {fields.map((member, index) => (
        <TypeFields
          roles={roles}
          member={member}
          key={index}
          fields={fields}
          index={index}
          initialValues={initialValues}
        />
      ))}
      <div className="form-group col-12 d-flex">
        <Button
          outline
          color="primary"
          className="ms-auto"
          onClick={(e) => {
            e.preventDefault();
            fields.push({});
          }}
        >
          Add Role Type
        </Button>
      </div>
    </>
  );
};

export default StaffRoleType;
