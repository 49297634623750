import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import FormErrorAlert from "../form/FormErrorAlert";
import NumberField from "../utils/NumberField";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

const BillableItemForm = (props) => {
  const { insurable, handleSubmit, deleteButton, error } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="col-lg-12 form-group">
          <Field
            label="Name"
            name="name"
            type="text"
            component={renderField}
            validate={required}
            required
          />
        </div>
        <div className="col-lg-6 form-group align-items-center">
          <Field
            label="Requires Insurance"
            name="insurable"
            component={renderToggleInput}
          />
        </div>
        {insurable && (
          <>
            <div className="col-lg-6 form-group">
              <Field
                component={renderField}
                name="insurable_percentage"
                label="Insurable Percentage"
                required
                type="number"
                validate={required}
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                component={renderField}
                name="insurable_percentage_reason"
                label="Insurable Percentage Reasoning"
                required
                validate={required}
              />
            </div>
          </>
        )}
        <NumberField
          {...props}
          label="Number"
          toggleClassName="col-lg-12"
          fieldClassName="col-lg-12"
        />
        <div className="form-group col-12 d-flex">
          {deleteButton}
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    insurable: selector(state, "insurable"),
  };
};

const form = reduxForm();

export default connect(mapStateToProps, {})(form(BillableItemForm));
