import PropTypes from "prop-types";
import React from "react";

const DocLink = ({ to }) => {
  return (
    <a
      href={`https://docs.thebossapp.com.au/${to}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-info-circle tx-inverse" />
    </a>
  );
};

DocLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default DocLink;
