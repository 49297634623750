import InformationAlert from "../utils/InformationAlert";

const FormErrorAlert = ({ error }: { error?: string }) => {
  if (!error) {
    return null;
  }

  return (
    <div style={{ top: "1.5rem" }} className="mb-3 position-sticky z-10">
      <InformationAlert
        type="danger"
        closable
        body={error}
        title="Error when Submitting Form"
      />
    </div>
  );
};

export default FormErrorAlert;
