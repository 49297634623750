import React from "react";

const ProgressBar = ({ percent, color }) => {
  return (
    <div className="progress">
      <div
        className={`progress-bar bg-${color} progress-bar-xs`}
        style={{
          width: `${percent}%`
        }}
        role="progressbar"
        aria-valuenow={percent}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
};

export default ProgressBar;
