import { FaCheck } from "react-icons/fa";
import StyledCheck from "../../form/StyledCheck";
import { LicenseData } from "./UserMicrosoftDetails";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import SubmitButton from "../../utils/SubmitButton";
import FormHeader from "../../utils/FormHeader";
import useApi from "../../api/useApi";
import { useParams } from "react-router-dom";
import formError from "../../utils/formError";
import FormErrorAlert from "../../form/FormErrorAlert";
import { toast } from "react-toastify";

interface UserLicenseProps {
  licenceData: LicenseData;
  refreshData: Function;
}

type FormValues = {
  licences: string[];
};

type UserLicenseFormProps = InjectedFormProps<FormValues, UserLicenseProps> &
  UserLicenseProps;

const UserLicenseForm = (props: UserLicenseFormProps) => {
  const { handleSubmit, licenceData, error, refreshData } = props;

  const { userId } = useParams<{ userId: string }>();

  const { takeAction } = useApi();

  const onSubmit: FormSubmitHandler<FormValues, UserLicenseProps> = (
    values,
  ) => {
    return takeAction("update", `microsoft/licences/users/${userId}`, {
      licences: values.licences,
      current_licences: licenceData?.user_licences.map((l) => l.skuId),
    })
      .then(() => {
        toast.success("Licences updated");
        refreshData();
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={error} />
        <FormHeader>Licenses</FormHeader>
        <Field
          name="licences"
          component={StyledCheck}
          options={licenceData.organisation_licences.map((licence) => ({
            value: licence.skuId,
            label: licence.displayName,
            skuPartNumber: licence.skuPartNumber,
          }))}
          button={({
            label,
            onClick,
            active,
            skuPartNumber,
          }: {
            onClick: () => void;
            active: boolean;
            value: string;
            label: string;
            skuPartNumber: string;
          }) => {
            return (
              <div className="col-lg-6 form-group">
                <button
                  type="button"
                  onClick={onClick}
                  className={`${
                    active
                      ? "border-success text-success shadow-inner"
                      : "shadow-sm"
                  } p-3 border rounded w-100 d-flex position-relative bg-white tn-300`}
                >
                  <div className="text-start w-100">
                    <div>
                      <p className="mb-0" style={{ maxWidth: "80%" }}>
                        {label}
                      </p>
                      <small
                        className={active ? "text-success" : "text-secondary"}
                      >
                        {skuPartNumber}
                      </small>
                    </div>
                  </div>
                  <FaCheck
                    className={`ms-auto tx-18 text-success tn-300 ${
                      active ? "opacity-1" : "opacity-0"
                    }`}
                  />
                </button>
              </div>
            );
          }}
        />
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<FormValues, UserLicenseProps>({
  form: "MicrosoftLicences",
});

export default form(UserLicenseForm);
