import React from "react";
import { FormSubmitHandler, InjectedFormProps, reduxForm } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";
import CustomJobTemplateFields from "./CustomJobTemplateFields";
import { CustomJobTemplate } from "./customJobTypes";
import { toast } from "react-toastify";

interface TemplateState {
  setTemplates: (templates: CustomJobTemplate[]) => void;
  templates: CustomJobTemplate[];
  setSelectedTemplate: Function;
}

const EditCustomJobTemplate = (
  props: InjectedFormProps<CustomJobTemplate, TemplateState> & TemplateState,
) => {
  const { handleSubmit, change, setTemplates, templates, setSelectedTemplate } =
    props;

  const { takeAction }: IUseApi<{}, { data: CustomJobTemplate }> = useApi();

  const onSubmit: FormSubmitHandler<CustomJobTemplate, TemplateState> = (
    values,
  ) => {
    return takeAction("update", `custom-job-templates/${values.uuid}`, values)
      .then(({ data }) => {
        setTemplates(
          templates.map((template) =>
            template.uuid === data.data.uuid ? data.data : template,
          ),
        );
        toast.success("Template updated successfully");
        setSelectedTemplate(null);
      })
      .catch(errorSwal);
  };

  return (
    <form
      className="bg-white p-3 shadow-sm rounded-lg border"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="row">
        <CustomJobTemplateFields change={change} />
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};
const form = reduxForm<CustomJobTemplate, TemplateState>({});

export default form(EditCustomJobTemplate);
