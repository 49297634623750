import React from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import AccountRequestFields from "./AccountRequestFields";
import { AccountRequest } from "./accountTypes";

interface EditAccountRequestProps {
  toggle: Function;
  modal: boolean;
  accountRequest: AccountRequest;
  onUpdated: (accountRequest: AccountRequest) => void;
}

const EditAccountRequest = (props: EditAccountRequestProps) => {
  const { takeAction } = useApi();
  const { accountRequest, onUpdated, toggle } = props;

  const onSubmit = (values: any) => {
    return takeAction(
      "update",
      `account-requests/${accountRequest.uuid}`,
      values,
    )
      .then(({ data }: { data: { data: AccountRequest } }) => {
        toast.success(`Account Request Updated`);
        onUpdated(data.data);
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      title="Edit Account Request"
      {...props}
      form="EditAccountRequest"
      initialValues={accountRequest}
      onSubmit={onSubmit}
    >
      <AccountRequestFields form="EditAccountRequest" />
    </FormModal>
  );
};

export default EditAccountRequest;
