import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { remove } from "../utils/arrayUtils";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { SubjectItemEditorProps } from "./SubjectItemEditor";

const DeleteSubjectItemButton = (props: SubjectItemEditorProps) => {
  const { item, items, setItems } = props;

  const { user } = useAuth();

  const { takeAction, loading }: IUseApi = useApi();

  const onDelete = () => {
    deleteSwal(item?.name)
      .then(() => takeAction("destroy", `subject-items/${item?.uuid}`))
      .then(() => setItems(remove("uuid", items, item)))
      .catch(errorSwal);
  };

  if (!user?.hasAccessTo("App\\Models\\SubjectItem", "delete")) {
    return null;
  }

  return (
    <button
      onClick={onDelete}
      type="button"
      className="btn text-danger shadow-none py-0 ms-auto"
      disabled={loading}
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </button>
  );
};

export default DeleteSubjectItemButton;
