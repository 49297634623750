import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PaginatedList from "../pagination/PaginatedList";
import useProfileViews from "./hooks/useProfileViews";
import ProfileViewLogTable from "./ProfileViewLogTable";

const ProfileViewLogModal = (props) => {
  const { toggle, modal, viewable, viewable_type } = props;
  const viewable_id = viewable.id;
  return (
    <Modal isOpen={modal} toggle={toggle} className="wd-md-1000 mx-wd-800">
      <ModalHeader toggle={toggle}>{viewable.name} Profile Views</ModalHeader>
      <ModalBody>
        <PaginatedList
          indexHook={useProfileViews}
          indexHookArguments={[viewable_id, viewable_type]}
          originalFilters={[]}
          list={({ data }) => (
            <div className="col-12">
              <ProfileViewLogTable data={data} />
            </div>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <button onClick={toggle}>Close</button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileViewLogModal;
