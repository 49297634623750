import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import {
  Field,
  FieldArray,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
  WrappedFieldArrayProps,
} from "redux-form";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import SelectInput from "../form/SelectInput";
import deleteSwal from "../utils/deleteSwal";
import Empty from "../utils/Empty";
import errorSwal from "../utils/errorSwal";
import LoadingOverlay from "../utils/LoadingOverlay";
import RenderField from "../utils/renderField";
import CustomScaleLoader from "../utils/scaleLoader";
import SubmitButton from "../utils/SubmitButton";
import TextButton from "../utils/TextButton";
import { ProjectAllowance, ProjectAllowanceEnum } from "./projectTypes";
import DocsModal from "../utils/DocsModal";
import { useAuth } from "../../context/auth-context";
import { IntegrationType } from "../integrations/integrationTypes";

const ProjectAllowances = (
  props: InjectedFormProps<{ allowances: ProjectAllowance[] }>,
) => {
  const { handleSubmit, initialize } = props;

  const { number } = useParams<{ number: string }>();

  const {
    data: allowances,
    setData: setAllowances,
    loading,
  }: IUseApi<ProjectAllowance[]> = useApi(
    `/projects/${number}/allowances`,
    [],
    true,
  );

  const { takeAction }: IUseApi = useApi();

  useEffect(() => {
    if (allowances) {
      initialize({ allowances });
    }
  }, [allowances]);

  const onSubmit: FormSubmitHandler<{
    allowances: ProjectAllowance[];
  }> = (values) => {
    return takeAction(
      "update",
      `/projects/${number}/allowances`,
      values.allowances,
    )
      .then(({ data }) => {
        initialize({ allowances: data.data });
        toast.success("Allowances Updates");
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <CustomScaleLoader>Fetching Allowances...</CustomScaleLoader>;
  }

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/allowances-9181/" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row position-relative">
          <FieldArray
            component={ProjectAllowancesFields}
            name="allowances"
            initialize={initialize}
          />
          <div className="col-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const ProjectAllowancesFields = (
  props: WrappedFieldArrayProps & { initialize: Function },
) => {
  const { fields, initialize } = props;

  const { data }: IUseApiWithData<{ name: string; id: number }[]> = useApi(
    "payroll-integrations/pay-categories",
    [],
  );

  const { user } = useAuth();

  const { takeAction, loading }: IUseApi = useApi();

  return (
    <>
      <LoadingOverlay loading={loading} />
      {fields?.length === 0 ? (
        <div className="col-12">
          <Empty width="50%" top={false}>
            <p className="mt-5">
              No Allowances added,{" "}
              <TextButton onClick={() => fields.push({})}>
                click here
              </TextButton>{" "}
              to start adding
            </p>
          </Empty>
        </div>
      ) : (
        <>
          {fields.map((field, index) => {
            const value = fields.get(index);

            return (
              <>
                <div className="col-lg-3 form-group">
                  <Field
                    component={RenderField}
                    name={`${field}.name`}
                    label="Name"
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <Field
                    component={SelectInput}
                    options={[
                      {
                        label: "Hours",
                        value: ProjectAllowanceEnum.Hours,
                      },
                      {
                        label: "Per Day",
                        value: ProjectAllowanceEnum.Day,
                      },
                      {
                        label: "Per Kilometre",
                        value: ProjectAllowanceEnum.Kilometre,
                      },
                    ]}
                    name={`${field}.unit`}
                    label="Unit"
                  />
                </div>
                <div className="col-lg-2 form-group">
                  <Field
                    component={RenderField}
                    name={`${field}.amount`}
                    label="Amount"
                    {...money}
                  />
                </div>
                <div className="col-lg-2 form-group">
                  <Field
                    component={RenderField}
                    name={`${field}.from`}
                    label="From"
                    information="Fill this in if the staff get this penalty rate after they've done a certain amount of hours"
                  />
                </div>
                {user?.active_organisation.integration_types.includes(
                  IntegrationType.Payroll,
                ) && (
                  <div className="col-lg-12 form-group">
                    <Field
                      component={SelectInput}
                      name={`${field}.payroll_category`}
                      label="Payroll Category"
                      options={data.map((d) => ({
                        label: d.name,
                        value: d.id,
                      }))}
                    />
                  </div>
                )}
                <div className="col-lg-2 form-group d-flex">
                  <Button
                    outline
                    color="danger"
                    className="align-self-end"
                    onClick={() => {
                      if (value?.uuid) {
                        return deleteSwal(value.name)
                          .then(() =>
                            takeAction(
                              "destroy",
                              `project-allowances/${value?.uuid}`,
                            ),
                          )
                          .then(({ data }) => {
                            initialize({ allowances: data.data });
                          })
                          .catch(errorSwal);
                      }

                      fields.remove(index);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </>
            );
          })}
          <div className="col-12">
            <Button outline onClick={() => fields.push({})}>
              Add Allowance
            </Button>
          </div>
        </>
      )}
    </>
  );
};

const form = reduxForm<{ allowances: ProjectAllowance[] }>({
  form: "ProjectAllowances",
});

export default form(ProjectAllowances);
