import React from "react";

class Icon extends React.Component {
  render() {
    const icons = this.props.data;
    const className = this.props.className;
    if (!icons) {
      return null;
    }

    return (
      <p className={className ?? "contact-social"}>
        {Object.entries(icons).map((key) => {
          switch (key[0]) {
            case "phone":
              return key[1] ? (
                <a key={key} href={`tel:${key[1]}`}>
                  <i className="icon ion-ios-telephone-outline tx-30 lh-0 mg-r-5" />
                </a>
              ) : (
                ""
              );
            case "email":
              return key[1] ? (
                <a key={key} href={`mailto:${key[1]}`}>
                  <i className="icon ion-ios-email-outline tx-30 lh-0 mg-r-5" />
                </a>
              ) : (
                ""
              );
            case "address":
              return key[1] ? (
                <a
                  key={key}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://maps.google.com/?q=${key[1]}`}
                >
                  <i className="icon ion-ios-location-outline tx-30 lh-0" />
                </a>
              ) : (
                ""
              );
            default:
              return "";
          }
        })}
      </p>
    );
  }
}

export default Icon;
