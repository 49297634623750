import React from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { CustomField } from "../customFields/customFieldTypes";
import ProfileForm from "../customFields/ProfileForm";
import HeaderPage from "../header/HeaderPage";
import CustomScaleLoader from "../utils/scaleLoader";

const BranchCustomFields = () => {
  const { loading, data, setData }: IUseApi = useApi(
    `/custom-fields?model=${encodeURIComponent(
      "App\\Models\\OrganisationBranch",
    )}`,
    [],
    true,
  );

  const fields = data as CustomField[];

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Branches",
            link: "/branches",
          },
          {
            name: "Branch Custom Fields",
            link: "/",
            active: true,
          },
        ]}
        titlePage="Branch Custom Fields"
      />
      {loading ? (
        <CustomScaleLoader>Fetching Fields...</CustomScaleLoader>
      ) : (
        <ProfileForm
          initialValues={{
            custom_fields: fields,
          }}
          modelType="App\Models\OrganisationBranch"
          onSuccess={(data: { data: CustomField[] }) => {
            setData(data.data);
          }}
        />
      )}
    </>
  );
};

export default BranchCustomFields;
