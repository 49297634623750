import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DT from "react-datetime";
// @ts-ignore
import "react-datetime/css/react-datetime.css";

const DateTime = DT.default ? DT.default : DT;
dayjs.extend(customParseFormat);

const dateField = ({
  input,
  label,
  required,
  meta: { touched, error },
  timeFormat = true,
  // dateFormat = "DD/MM/YYYY", // changing to always use DD/MM/YYYY
  extraProps,
  disabled,
}) => {
  return (
    <>
      <label className="form-control-label tx-inverse tx-semibold">
        {label}
        {required ? <span className="tx-danger"> *</span> : ""}
      </label>

      <DateTime
        timeFormat={timeFormat}
        dateFormat="DD/MM/YYYY"
        className={touched && error && "parsley-error"}
        {...input}
        inputProps={{ ...extraProps, disabled }}
      />

      {touched && error && (
        <span className={error ? "parsley-errors-list" : ""}>{error}</span>
      )}
    </>
  );
};

export default dateField;

export const formatting = {
  normalize: (value) => {
    if (value?._isValid || dayjs(value, "DD/MM/YYYY").isValid()) {
      return dayjs(value).format("YYYY-MM-DD");
    }
    return null;
  },
  format: (value) => {
    if (value && dayjs(value, "YYYY-MM-DD").isValid()) {
      return dayjs(value).format("DD/MM/YYYY");
    }
  },
  timeFormat: false,
};
