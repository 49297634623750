import { useState } from "react";
import { Field, reduxForm } from "redux-form";
import CustomForm from "../customFields/CustomForm";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import DateTime, { formatting } from "../utils/dateTime";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import uploadToS3 from "../upload/uploadToS3";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import Documents from "./Documents";
import SelectInput from "../form/SelectInput";
import CustomScaleLoader from "../utils/scaleLoader";
import changeCompetencyDateSwal from "./changeCompetencyDateSwal";
import { useAuth } from "../../context/auth-context";
import { Button } from "reactstrap";

const Form = (props) => {
  const { initialValues, handleSubmit, competency, setCompetency, change } =
    props;

  const { user } = useAuth();

  const { uuid } = useParams();
  const [customForm, setCustomForm] = useState(initialValues.custom_form);

  const { takeAction: loadCustomForm, loading } = useApi();

  const { takeAction: checkDueDate } = useApi();

  const { takeAction } = useApi();

  const { upload: uploadCustomFields } = useUploadCustomFields(
    `${user.active_organisation.uuid}/staff-competencies/${uuid}`,
  );

  const onSubmit = (values) => {
    return uploadCustomFields(values)
      .then((data) => {
        values = data;

        return uploadToS3(
          values.documents,
          `organisations/${user.active_organisation.uuid}/staff-competencies`,
        );
      })
      .then((documents) => {
        const data = {
          ...values,
          documents,
        };

        return takeAction("update", `staff-competencies/${values.uuid}`, data);
      })
      .then(({ data }) => {
        toast.success("Competency Updated Successfully");
        setCompetency(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <div className="row">
        <div className="form-group col-lg-6">
          <Field
            component={DateTime}
            label="Date Completed"
            name="added_on"
            type="date"
            validate={required}
            required
            {...formatting}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            component={SelectInput}
            label="Skill Level"
            name="skill_level_id"
            url={`/react-select-data?select=*&model=${encodeURIComponent(
              "App\\Models\\SkillLevel",
            )}&value=id&label=name&where[competency_skill_id]=${
              competency.skill_level.competency_id
            }`}
            validate={required}
            isDisabled={competency.completed_at}
            changeValue={({ value }) => {
              loadCustomForm(
                "index",
                `staff-competency-custom-form/${value}`,
              ).then(({ data }) => setCustomForm(data.data));
              checkDueDate("store", `staff-competency-check/${uuid}`, {
                skill_level_id: value,
              }).then(({ data }) => {
                if (data?.data) {
                  changeCompetencyDateSwal(data.data, change);
                }
              });
            }}
          />
          {competency.completed_at && (
            <small>
              Unable to edit skill level for completed competencies.
            </small>
          )}
        </div>
        <div className="form-group col-lg-12">
          <Field component={RenderField} label="Comments" name="comments" />
        </div>
        {competency.documents.length > 0 || customForm?.length === 0 ? (
          <Documents
            setCompetency={setCompetency}
            competency={competency}
            {...props}
          />
        ) : (
          <>
            <FormHeader>Competency Details</FormHeader>
            {loading && (
              <CustomScaleLoader>Fetching Custom Form...</CustomScaleLoader>
            )}
            <CustomForm customForm={customForm} {...props} />
          </>
        )}
        <div className="col-lg-6 form-group mt-3">
          <Button
            color="secondary"
            outline
            disabled={props.submitting}
            onClick={handleSubmit((values) =>
              onSubmit({
                ...values,
                continue: true,
              }),
            )}
          >
            Save and Continue Later
          </Button>
        </div>
        <div className="col-lg-6 form-group mt-3">
          <SubmitButton save="Submit" {...props} disabled={!props.valid} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "CompetencyProfile" });

export default form(Form);
