import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getJobs = (param: any, search?: string) => {
  const { pageParam = "initial" } = param;

  if (!pageParam) {
    return;
  }

  return api
    .get(
      `project-jobs?filter[search]=${search}&cursor=${
        pageParam === "initial" ? "" : pageParam
      }`,
    )
    .then(({ data }) => data);
};

export default function useJobs(search?: string) {
  return useInfiniteQuery(
    ["jobs", { search }],
    (pageParam) => getJobs(pageParam, search),
    {
      getNextPageParam: (page) => {
        if (!page?.meta?.next_cursor) {
          return;
        }
        return page?.meta?.next_cursor;
      },
    },
  );
}
