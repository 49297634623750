const calculateTotal = (
  items?: { quantity?: number; price?: number }[],
): number => {
  if (!items) {
    return 0;
  }

  return items.reduce((carry, item) => {
    if (item.price && item.quantity) {
      carry += item.price * item.quantity;
    }

    return carry;
  }, 0);
};

export default calculateTotal;
