import React from "react";
import formatDate from "../../utils/formatDate";

const Label = ({ sample }) => {
  const locations = [1, 2, 3, 4];

  const locationString = locations
    .map((number) => {
      return sample[`location_${number}`];
    })
    .filter((location) => location && location !== "-")
    .join(", ");

  return (
    <div className="col-6 mb-1">
      <div className="bg-white p-3 border h-100">
        <div className="row">
          <p className="mb-3 col-3">Client:</p>
          <p className="mb-3 col-3 tx-inverse tx-semibold">
            {sample.client.name}
          </p>
          <p className="mb-3 col-3">Branch:</p>
          <p className="tx-inverse col-3   tx-semibold mb-0">
            {sample.branch.name}
          </p>
          <p className="mb-3 col-3">Project:</p>
          <p className="mb-3 tx-inverse tx-center  col-9 tx-semibold">
            {sample.project.name}
          </p>
          <p className="mb-3 col-3">Lot:</p>
          <p className="mb-3 tx-inverse tx-center col-9 tx-semibold">
            {sample.lot_number}
          </p>
          <p className="mb-3 col-3">Location:</p>
          <p className="mb-3 tx-inverse tx-center col-9 tx-semibold">
            {locationString ?? "-"}
          </p>
          <p className="mb-3 col-3">Sample:</p>
          <p className="mb-3 tx-inverse tx-center col-9 tx-semibold">
            {sample.number}
          </p>
          <p className="mb-3 col-3">Description:</p>
          <p className="mb-3 tx-inverse tx-center col-9 tx-semibold">
            {sample.material_colour} {sample.description}
          </p>
          <p className="mb-3 col-3">Material Source:</p>
          <p className="mb-3 tx-inverse tx-center col-9 tx-semibold">
            {sample.material_source}
          </p>
          <p className="mb-3 col-3">Sample Date:</p>
          <p className="mb-3 tx-inverse tx-center col-9 tx-semibold">
            {formatDate(sample.sampled_at)}
          </p>
          <p className="mb-3 col-3">Bags:</p>
          <p className="mb-3 tx-inverse tx-center col-9 tx-semibold">
            <div className="d-flex justify-content-around">
              <p>___</p>
              <p>Of</p>
              <p>___</p>
            </div>
          </p>
          <p className="col-3">Tests Required:</p>
          <p className="tx-inverse tx-center col-9 tx-semibold">
            {sample.tests.map(({ title }) => title).join(", ")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Label;
