import { InjectedFormProps, reduxForm } from "redux-form";
import AddTenderFields from "./AddTenderFields";

const AddTenderForm = (
  props: InjectedFormProps<any, { showProject?: boolean }> & {
    showProject?: boolean;
  },
) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <AddTenderFields {...props} />
      </div>
    </form>
  );
};

const form = reduxForm<any, { showProject?: boolean }>({});

export default form(AddTenderForm);
