import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { connect } from "react-redux";
import useTimesheetDates from "./hooks/useTimesheetDates";

const TimesheetDayPicker = (props) => {
  const { selectedDay, handleDayClick, user } = props;
  const { data: timesheets } = useTimesheetDates(user.id);

  const modifiers = {
    timesheetDays: timesheets
      ? timesheets.map((timesheet) => new Date(timesheet.date))
      : [],
  };

  return (
    <DayPicker
      selected={selectedDay}
      onDayClick={handleDayClick}
      modifiers={modifiers}
      modifiersClassNames={{
        timesheetDays: "timesheetDays",
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedDay: state.timesheets.selectedDay,
});

export default connect(mapStateToProps, {})(TimesheetDayPicker);
