import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import LeaveForm from "./LeaveForm";
import Spinner from "../utils/Spinner";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";

const Request = (props) => {
  const { takeAction } = useApi();
  const { id } = useParams();

  const { user: authUser } = useAuth();

  const [user, setUser] = useState();

  const { takeAction: getUser } = useApi();

  const { upload, setBucket } = useUploadMultipleToS3();

  useState(() => {
    getUser("show", `users/${id}`).then(({ data }) => {
      setUser(data.data.user);
    });
  }, []);

  useEffect(() => {
    if (user?.uuid) {
      setBucket(
        `organisations/${authUser.active_organisation.uuid}/leave/${user?.uuid}`,
      );
    }
  }, [user]);

  const onSubmit = (values) => {
    return upload(values.file ? [values.file] : [])
      .then((files) => {
        const data = {
          ...values,
          document: files[0] ?? null,
        };

        return takeAction("store", "requested-leave", {
          ...data,
          user_id: id,
        });
      })
      .then(({ data }) => {
        toast.success("Leave Requested");

        props.history.push(`/requested-leave/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  if (!user) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage="Request Leave"
        crumbs={[
          {
            name: "Profile",
            link: `/users/${user?.uuid}/timesheets`,
          },
          {
            name: "Request Leave",
            link: `/`,
            active: true,
          },
        ]}
      />

      <LeaveForm
        initialValues={{ full_day: true }}
        form="RequestLeave"
        onSubmit={onSubmit}
        user={user}
      />
    </>
  );
};

export default Request;
