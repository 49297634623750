import axios from "../api/api";
import {
  DELETE_MOISTURE_CONTAINER,
  FETCH_MOISTURE_CONTAINERS,
  NEW_MOISTURE_CONTAINER,
  UPDATE_MOISTURE_CONTAINER,
} from "../actions/types";

export const fetchMoistureContainers =
  (id = "") =>
  (dispatch) => {
    return axios
      .get(`/moisture-containers/${id}`)
      .then((response) => response.data.data)
      .then((moistureContainers) =>
        dispatch({
          type: FETCH_MOISTURE_CONTAINERS,
          payload: moistureContainers,
        }),
      );
  };

export const createMoistureContainer = (postData) => (dispatch) => {
  return axios
    .post(`/moisture-containers`, postData)
    .then(({ data }) =>
      dispatch({
        type: NEW_MOISTURE_CONTAINER,
        payload: data.data,
      }),
    );
};

export const updateMoistureContainer = (postData) => (dispatch) => {
  return axios
    .put(`/moisture-containers/${postData.uuid}`, postData)
    .then(({ data }) =>
      dispatch({
        type: UPDATE_MOISTURE_CONTAINER,
        payload: data.data,
      }),
    );
};

export const deleteMoistureContainer = (uuid) => (dispatch) => {
  return axios.delete(`/moisture-containers/${uuid}`).then(() =>
    dispatch({
      type: DELETE_MOISTURE_CONTAINER,
      payload: uuid,
    }),
  );
};
