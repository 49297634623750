import _ from "lodash";
import React from "react";
import { BootstrapColour } from "../utils/utilTypes";
import ProjectCard from "./ProjectCard";

export interface IJob {
  name: string;
  full_name: string;
  uuid: string;
  job_number: string;
  id: string;
  status: string;
  manager: {
    name: string;
    email: string;
  };
  is_overdue: boolean;
  link: string;
  status_badge: {
    color: BootstrapColour;
    label: string;
  };
  scheduled_finish_date: string;
  scheduled_start_date: string;
  invoice_status: string;
  project: {
    name: string;
    link: string;
    id: number;
  };
}

const ProjectList = ({ jobs }: { jobs: IJob[] }) => {
  const grouped = _.groupBy(jobs, "project.id") as {
    [key: string]: IJob[];
  };

  return (
    <div className="w-full">
      {Object.values(grouped).map((values) => {
        const project = {
          ...values[0]?.project,
          jobs: values,
        };
        return <ProjectCard key={project.id} project={project} />;
      })}
    </div>
  );
};

export default ProjectList;
