import { BiImport } from "react-icons/bi";
import { Button } from "reactstrap";
import swal from "sweetalert";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";

const ImportLeaveReasonButton = ({
  refreshData,
}: {
  refreshData: Function;
}) => {
  const { takeAction } = useApi();

  const onClick = () => {
    swal({
      title: "Are you sure?",
      text: "This will import all non-synced leave reasons from Employment Hero. \n\nPlease only do this once you've synced all of your current leave reasons below.",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          closeModal: true,
          visible: true,
        },
        import: {
          text: "Import",
          value: true,
          closeModal: true,
          className: "btn btn-primary align-baseline",
        },
      },
      dangerMode: true,
    }).then((willImport) => {
      if (willImport) {
        takeAction("store", `integrations/employment-hero/leavecategories`)
          .then(() => {
            refreshData();

            toast.success("Leave Reasons Imported");
          })
          .catch(errorSwal);
      }
    });
  };

  return (
    <div className="col-12 mb-3">
      <Button onClick={onClick} color="primary" outline>
        <BiImport /> Import Leave Reasons from Employment Hero
      </Button>
    </div>
  );
};

export default ImportLeaveReasonButton;
