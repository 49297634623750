import PRICING_TYPES from "../../data/pricing_types";
import useApi from "../api/useApi";

const usePricingTypes = () => {
  const { data } = useApi("organisations-standard-job-types", []);

  if (
    data?.find(
      (jobType: any) => jobType?.job_type_type === "App\\Models\\TestRequest",
    )
  ) {
    return PRICING_TYPES;
  }

  return [
    { value: "App\\Models\\BillableItem", label: "Billable Item" },
    {
      value: "App\\Models\\User",
      label: "User",
    },
  ];
};

export default usePricingTypes;
