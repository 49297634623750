import PropTypes from "prop-types";
import _ from "lodash";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import Empty from "../standardDocuments/Empty";
import errorSwal from "../utils/errorSwal";
import Item from "./Item";
import PreviewModal from "./PreviewModal";
import UploadOverlay from "./UploadOverlay";
import UploadProgress from "./UploadProgress";
import axios from "../api/api";

const List = (props) => {
  const { modal, toggle } = useModal();
  const [revisionActive, setRevisionActive] = useState(false);
  const [templateDocument, setTemplateDocument] = useState(null);
  const { folder, setFolder, onSuccess } = props;
  const { takeAction, loading, setLoading } = useApi();
  const { user } = useAuth();

  const { files, upload, setFiles } =
    useUploadMultipleToS3("documents/standard");

  const naturallyOrdered = folder.children
    .slice()
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true, base: true }),
    );

  const orderedDocuments = _.orderBy(
    naturallyOrdered,
    [(doc) => doc.is_folder],
    ["desc"],
  );

  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(orderedDocuments);

  const onDrop = (acceptedFiles) => {
    setLoading(true);
    return upload(acceptedFiles)
      .then((documents) =>
        takeAction("store", "/documents", {
          documents,
          folder_id: folder.id,
        }),
      )
      .then(({ data }) => {
        toast.success("Documents Uploaded");
        setFiles([]);
        setFolder(data.data);
      });
  };
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      noClick: folder?.children?.length > 0,
      disabled:
        (!user.is_admin &&
          !user.hasAccessTo("App\\Models\\Document", "create_add_version")) ||
        loading ||
        revisionActive ||
        user.active_organisation.id !== folder.organisation_id,
      preventDropOnDocument: false,
    });

  async function setFilledTemplate(document) {
    const format =
      document?.mime_type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ? "Word"
        : "xlsx";
    try {
      const response = await axios.post(`templates/${document.uuid}/download`, {
        model: "App\\Models\\Document",
        id: document.id,
        format: format,
      });
      setTemplateDocument({
        ...document,
        link: response?.data?.data,
      });
    } catch (error) {
      setTemplateDocument(null);
      errorSwal(error);
    }
  }

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {!folder?.id || folder?.children?.length === 0 ? (
          <Empty
            folder={folder}
            isDragActive={isDragActive}
            isDragAccept={isDragAccept}
          />
        ) : (
          <UploadOverlay isDragActive={isDragActive} />
        )}
        <div className="file-list">
          {orderedDocuments.map((document) => (
            <Item
              {...props}
              key={document.uuid}
              document={document}
              setSelectedDocument={setSelectedDocument}
              changeDocument={changeDocument}
              setFilledTemplate={setFilledTemplate}
              togglePreview={toggle}
              revisionActive={revisionActive}
              setRevisionActive={setRevisionActive}
              onSuccess={onSuccess}
            />
          ))}
        </div>
      </div>

      <UploadProgress files={files} />
      {(!selectedDocument?.is_templatable || templateDocument) && (
        <PreviewModal
          toggle={toggle}
          modal={modal}
          onClosed={() => setTemplateDocument(null)}
          document={templateDocument ? templateDocument : selectedDocument}
          changeDocument={changeDocument}
        />
      )}
    </>
  );
};

List.propTypes = {
  folder: PropTypes.shape({
    children: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    is_folder: PropTypes.bool,
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  response: PropTypes.object.isRequired,
};

export default List;
