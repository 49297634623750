export enum InvoiceStatus {
  AwaitingInvoice,
  Invoiced,
  Paid,
  InvoiceNotRequired,
}

export enum JobStatus {
  Pending,
  InProgress,
  OnHold,
  Completed,
  Cancelled,
  Invoiced,
  InvoiceRequested,
}

export type Job = {
  id: number;
  uuid: string;
  name: string;
  full_name: string;
  actual_finish_date: string | null;
  actual_start_date: string | null;
  branch_id: number;
  branch: {
    name: string | null;
    uuid: string | null;
  };
  job_number: number;
  job_type_id: number;
  job_type_type: string;
  prefix: string;
  is_overdue: boolean;
  number: string;
  project: {
    uuid: string;
    link: string;
    name: string;
    full_name: string;
    number: string;
    state: string;
    postcode: string;
    suburb: string;
    line_1: string;
    line_2: string | null;
    id: number;
    deliverable_recipients: any | null;
    internal: boolean;
    formatted_address: string;
    allowance_count: number;
  };
  client: {
    name: string;
    link: string;
    uuid: string;
  };
  invoices: any[];
  dependencies: any[];
  dependants: any[];
  project_id: number;
  project_manager: number;
  scheduled_start_time: string | null;
  scheduled_finish_time: string | null;
  scheduled_start_date: string;
  scheduled_finish_date: string;
  status: number;
  estimated_hours: number;
  allUsers: Array<{
    first_name: string;
    last_name: string;
    email: string;
    id: number;
    uuid: string;
  }>;
  is_standard: boolean;
  standard_job_type: {
    link: string;
    type: string;
  };
  job_type: {
    name: string;
    link: string;
    show_tabs: boolean;
  };
  description: string;
  critical_path: boolean;
  job_dependency: any | null;
  users: any[];
  estimated_value: number;
  total_hours_added: number;
  status_badge: {
    color: string;
    label: string;
  };
  link: string;
  manager: {
    id: number;
    name: string;
    email: string;
  };
  completed_value: number;
  docket: any | null;
  comment_count: number | null;
  cancelled_reason: string | null;
  priority: any | null;
  hours_before_beginning: number | null;
  expected_finish_date: string;
  estimated_hours_left: number;
  invoice_status: number;
  invoiceless_job_request: any | null;
  tender: any | null;
  can_only_edit_status: boolean;
  understandings: any[];
  user_must_sign: any[];
  deliverables_count: number;
  organisation: {
    name: string;
    id: number;
  };
};
export interface IndexJob {
  id: number;
  description: string;
  uuid: string;
  name: string;
  number: string;
  link: string;
  created_at: string;
  project: {
    name: string;
    link: string;
  };
  branch: {
    name: string;
  };
  type: {
    name: string;
  };
  manager: {
    name: string;
  };
  scheduled_start_date: string;
  scheduled_finish_date: string;
  actual_start_date?: string;
  actual_finish_date?: string;
  status: JobStatus;
  is_overdue: boolean;
  estimated_value?: number;
  completed_value?: number;
  users: {
    label: string;
    value: number;
  }[];
}

export interface InvoicelessJobRequest {
  uuid: string;
  accepted_at?: string;
  user_id: number;
  job_id: number;
}

export interface ExternalJobRequest {
  id: number;
  uuid: string;
  name?: string;
  email: string;
  job_id: number;
  filled_at?: string;
}
