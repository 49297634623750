import React from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import TestSuiteTestItem from "../testSuites/TestSuiteTestItem";
import { TestSuiteTest } from "../testSuites/testSuiteTypes";
import AddTestSuiteTestModal from "./AddTestSuiteTestModal";

const TestRequestTestSuites = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { toggle, modal } = useModal();

  const { data: testSuites, setData: setTestSuites }: IUseApi<TestSuiteTest[]> =
    useApi(`test-requests/${uuid}/test-suites`, []);

  return (
    <>
      <Button size="sm" onClick={toggle} outline color="primary">
        Add Test Suite
      </Button>
      <AddTestSuiteTestModal
        tests={testSuites ?? []}
        setTests={setTestSuites}
        toggle={toggle}
        modal={modal}
      />
      <div className="row mt-3">
        {testSuites?.map((test) => {
          return <TestSuiteTestItem test={test} key={test.uuid} />;
        })}
      </div>
    </>
  );
};

export default TestRequestTestSuites;
