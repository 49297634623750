import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import formatDate from "../utils/formatDate";
import ApprovalStatus from "./ApprovalStatus";

const ExpenseClaims = () => {
  const { userId } = useParams();

  const { loading, data } = useApi(`users/${userId}/expense-claims`, []);

  const columns = [
    {
      accessorKey: "description",
      header: "Description",
      cell: (info) => {
        const claim = info.row.original;
        return (
          <>
            <Link className="d-block" to={claim.link}>
              {info.getValue()}
            </Link>
            <ApprovalStatus status={claim.approval_status} />
          </>
        );
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      cell: (info) => formatDate(info.getValue()),
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: (info) => `$${info.getValue().toFixed(2)}`,
    },
  ];

  return (
    <>
      <Link className="btn btn-outline-primary mb-3" to="/expense-claims/add">
        Add Expense Claim
      </Link>
      <ReactTable data={data} columns={columns} loading={loading} />
    </>
  );
};

export default ExpenseClaims;
