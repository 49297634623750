import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { reduxForm, submit } from "redux-form";
import FormErrorAlert from "../form/FormErrorAlert";
import ReactTable from "../table/ReactTable";
import { useMemo, useState } from "react";
import SubmitButton from "../utils/SubmitButton";
import axios from "../api/api";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import { remove } from "../utils/arrayUtils";

const DeleteItemByTestModal = (props: any) => {
  const {
    toggle,
    modal,
    vals,
    deleteSelected
  } = props;
  const [selectedTests, setSelectedTests] = useState([]);
  const purchaseItemUuid = vals.uuid;
  const [deleting, setDeleting] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "sample_number_id",
        header: "Sample Number",
      },
    ];
  }, [vals.tests]);

  return (
    <Modal
      className="wd-md-1000 mx-wd-800 w-95"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
          <h1> Select Item To Delete </h1>
      </ModalHeader>
      <ModalBody>
        <ReactTable
            columns={columns}
            data={vals.tests}
            enableMultiSelect
            setSelectedRows={setSelectedTests}
          />
      </ModalBody>
      <ModalFooter>
        <SubmitButton save="Delete" onClick={() => deleteSelected(selectedTests, purchaseItemUuid, selectedTests.length === vals.tests.length, vals)} /> 
      </ModalFooter>
    </Modal>
  );
};

export default DeleteItemByTestModal;
