import dayjs from "dayjs";
import _ from "lodash";
import { Badge } from "reactstrap";

const SentBadge = ({ sent, className }) => {
  const details = sentDetails(sent);

  return (
    <Badge className={className} color={details.color}>
      {details.text}
    </Badge>
  );
};

export const sentDetails = (sent) => {
  if (sent.length === 0) {
    return {
      color: "warning",
      text: "Not Sent",
    };
  }

  const lastSent = _.max(sent.map((s) => s.created_at));

  return {
    color: "info",
    text: `Last sent at ${dayjs(lastSent).format("DD/MM/YYYY")}`,
  };
};

export default SentBadge;
