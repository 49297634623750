import React, { useState } from "react";
import { Field } from "redux-form";
import { initialize } from "redux-form";
import renderField from "../utils/renderField";
import SKILL_LEVEL_UNITS from "../../data/skill_level_units";
import SelectInputAsync from "../utils/SelectInputAsync";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import isSubmitting from "../utils/submitting";
import errorSwal from "../utils/errorSwal";
import required from "../utils/required";
import { Link } from "react-router-dom";
import renderToggleInput from "../utils/renderToggleInput";
import axios from "../api/api";
import PRICE_BANDS_STATUS from "../../data/price_bands_status";

const PriceBandsFields = ({
    index,
    fields,
    field,
    initialValues,
    dispatch,
} : 
{
    index : any,
    fields : any,
    field : any,
    initialValues : any,
    dispatch : any
}) => {
  const [deleting, setDeleting] = useState(false);
  
  const deleteLevel = () => {
    setDeleting(true);
    if (!initialValues || !initialValues[fields.name][index]?.uuid) {
      fields.remove(index);
      setDeleting(false);
      return;
    }
  };

  return (
    <>
      <div className="col-12">
        <div className="d-flex w-100 align-items-center">
          <label className="section-title mt-3">Price Band {index + 1}</label>
        </div>
        <hr />
      </div>

      <div className="row">
        <div className="col-4 form-group">
          <Field
            validate={required}
            required
            component={renderField}
            name={`${field}.lower_limit`}
            label="Lower Limit of Estimated Value"
          />
        </div>
        <div className="form-group col-4">
          <Field
            validate={required}
            required
            component={renderField}
            name={`${field}.upper_limit`}
            label="Upper Limit of Estimated Value"
          />
        </div>

        <div className="form-group col-3">
          <Field
            component={SelectInputAsync}
            label="Required"
            name={`${field}.status`}
            options={PRICE_BANDS_STATUS}
            validate={required}
            required
          />
        </div>
      
        <div className="form-group col-1"
          style={{
            display: 'flex',
            alignItems: 'end',
          }}>
          <button
            onClick={deleteLevel}
            className="btn ms-auto btn-outline-danger"
            type="button"
            disabled={deleting}
          >
            {isSubmitting(deleting, "Delete", "Deleting...")}
          </button>
        </div>
      </div>
    </>
  );
};

export default PriceBandsFields;
