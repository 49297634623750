import { toast } from "react-toastify";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import formError from "../utils/formError";
import SubmitButton from "../utils/SubmitButton";
import SelectInput from "../form/SelectInput";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

export interface DetailForm {
  extra_info: {
    user_email_template: string;
    user_email_template_domain: string;
  };
}

interface DetailsProps {
  template?: string;
  domain?: string;
}

const Details = (
  props: InjectedFormProps<DetailForm, DetailsProps> & DetailsProps,
) => {
  const { handleSubmit } = props;

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi = useApi();

  const { data }: IUseApiWithData<{ name: string; id: number }[]> = useApi(
    "payroll-integrations/pay-categories",
    [],
  );

  const onSubmit: FormSubmitHandler<DetailForm> = (values) => {
    return takeAction("update", `payroll-integrations/${uuid}`, values)
      .then(() => {
        toast.success("Details updated.");
      })
      .catch(formError);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormErrorAlert error={props.error} />
        <div className="col-lg-12 form-group">
          <Field
            component={SelectInput}
            label="Ordinary Hours Pay Category"
            options={data.map((d) => ({
              label: d.name,
              value: d.id,
            }))}
            name="extra_info.default_pay_category"
          />
        </div>
        <div className="col-lg-12 form-group">
          <Field
            component={SelectInput}
            label="Public Holiday Pay Category"
            options={data.map((d) => ({
              label: d.name,
              value: d.id,
            }))}
            name="extra_info.public_holiday_pay_category"
          />
        </div>
        <div className="col-lg-12 form-group">
          <Field
            component={SelectInput}
            label="Overtime Pay Category"
            options={data.map((d) => ({
              label: d.name,
              value: d.id,
            }))}
            name="extra_info.overtime_pay_category"
          />
        </div>
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </form>
    </>
  );
};

const form = reduxForm<DetailForm, DetailsProps>({
  form: "EmploymentHeroDetails",
});

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("EmploymentHeroDetails");

  return {
    template: selector(state, "extra_info.user_email_template"),
    domain: selector(state, "extra_info.user_email_template_domain"),
  };
};

export default connect(mapStateToProps)(form(Details));
