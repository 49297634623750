import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, formValueSelector } from "redux-form";
import AddMultiple from "../form/AddMultiple";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import { SimilarSupplier } from "./supplierTypes";

interface SupplierNameFieldProps {
  setUrl: (url: string) => void;
  setData: (data: any[]) => void;
  url: string;
  similarSuppliers: SimilarSupplier[];
}

const SupplierNameField = ({
  setUrl,
  setData,
  url,
  similarSuppliers,
}: SupplierNameFieldProps) => {
  return (
    <>
      <Field
        component={RenderField}
        name="name"
        validate={required}
        /*
              ... No idea???
            // @ts-ignore */
        label="Name"
        required
        onBlur={(e, val) => {
          if (!val) {
            return;
          }
          const newUrl = `check-contacts?name=${encodeURIComponent(
            val,
          )}&type=Supplier`;
          if (url !== newUrl) {
            setData([]);
          }
          setUrl(newUrl);
        }}
      />
      {similarSuppliers.length > 0 ? (
        <>
          <small>The following supplier/s with a similar name were found</small>
          {similarSuppliers.map((supplier) => {
            return (
              <small className="d-block" key={supplier.id}>
                <Link to={supplier.link}>{supplier.name}</Link>
              </small>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default SupplierNameField;
