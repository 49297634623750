import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CompressPath from "./CompressPath";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import useModal from "../hooks/useModal";
import dayjs from "dayjs";
import useApi from "../api/useApi";
import { useEffect } from "react";
import CustomScaleLoader from "./scaleLoader";

const HeaderFooter = ({ extraButtons = null, model }) => {
  const { modal, toggle } = useModal();

  const { data, setUrl, loading } = useApi();

  useEffect(() => {
    if (modal && !data) {
      setUrl(
        `meta-data?model=${encodeURIComponent(model.type)}&id=${model.id}}`,
      );
    }
  }, [modal]);

  return (
    <div className="card-footer">
      <a
        href="javascript:void(0)"
        id="current-link"
        className="card-profile-direct"
      >
        {<CompressPath />}
      </a>
      <div>
        {extraButtons}
        {model ? (
          <>
            <button onClick={toggle}>See Meta Data</button>
            <Modal
              className="wd-md-1000 mx-wd-800 w-95"
              isOpen={modal}
              toggle={toggle}
            >
              <ModalHeader toggle={toggle}>Meta Data</ModalHeader>
              <ModalBody>
                {loading ? (
                  <CustomScaleLoader>Loading...</CustomScaleLoader>
                ) : (
                  <>
                    <p>
                      Created By{" "}
                      <span className="text-dark fw-bolder">
                        {data?.created_by}
                      </span>
                      , at{" "}
                      <span className="text-dark">
                        {dayjs(data?.created_at).format("DD/MM/YYYY hh:mm a")}
                      </span>
                    </p>
                    <p>
                      Last Updated By{" "}
                      <span className="fw-bolder text-dark">
                        {data?.updated_by}
                      </span>
                      , at{" "}
                      <span className="text-dark">
                        {" "}
                        {dayjs(data?.updated_at).format("DD/MM/YYYY hh:mm a")}
                      </span>
                    </p>
                    <CopyToClipboard
                      options={{ format: "text/plain" }}
                      text={window.location.href}
                      onCopy={() => toast.success("Link Copied!")}
                    >
                      <Button color="link" className="px-0">
                        Copy Link
                      </Button>
                    </CopyToClipboard>
                  </>
                )}
              </ModalBody>
            </Modal>
          </>
        ) : (
          <CopyToClipboard
            options={{ format: "text/plain" }}
            text={window.location.href}
            onCopy={() => toast.success("Link Copied!")}
          >
            <a href="javascript:void(0)">Copy Link</a>
          </CopyToClipboard>
        )}
      </div>
    </div>
  );
};
export default HeaderFooter;
