import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import Profile from "../profile";
import { DashboardHeader, DashboardH3 } from "../utils/DashboardHeader";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import ShowPage from "./ShowPage";

const SupplierTypeProfile = () => {
  const { uuid } = useParams();

  const {
    takeAction,
    data: supplierType,
    loading,
    response,
    setData,
  } = useApi(`supplier-types/${uuid}`);

  const onSubmit = (values) => {
    return takeAction("update", `supplier-types/${uuid}`, values)
      .then(({ data }) => {
        setData(data.data);
        toast.success(`${values.name} updated.`);
      })
      .catch(errorSwal);
  };

  if (loading && !response) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={supplierType?.name}
        crumbs={[
          { link: "/supplier-types", name: "Supplier Types" },
          { link: "", name: supplierType?.name, active: true },
        ]}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{supplierType?.name}</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowPage
            onSubmit={onSubmit}
            initialValues={supplierType}
            supplierType={supplierType}
            setData={setData}
          />
        }
        tabs={[
          {
            link: `/supplier-types/${uuid}/details`,
            page: "details",
            label: "Details",
            icon: "",
          },
          {
            link: `/supplier-types/${uuid}/insurances`,
            page: "insurances",
            label: "Insurances",
            icon: "",
          },
          {
            link: `/supplier-types/${uuid}/form`,
            page: "form",
            label: "Form",
            icon: "",
          },
        ]}
      />
    </>
  );
};

export default SupplierTypeProfile;
