import { useEffect } from "react";
import {
  ChangeAction,
  Field,
  FieldArray,
  FieldArrayFieldsProps,
  initialize,
  WrappedFieldArrayProps,
} from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import { SelectOption } from "../utils/utilTypes";
import { RecurringJobDeliverablesProps } from "./RecurringJobDeliverables";
import {
  IRecurringJobDeliverablePricing,
  RecurringJob,
} from "./recurringJobTypes";

const RecurringJobDeliverablePricing = ({
  initialValues,
  field,
  index,
  change,
  setRecurringJob,
  dispatch,
}: {
  initialValues: RecurringJob;
  field: string;
  index: number;
  change?: ChangeAction;
} & RecurringJobDeliverablesProps) => {
  //Typescript wigs if i put this directly in the name??
  const fieldName = `${field}.pricing`;

  return (
    <div className="form-group col-12">
      <div className="slim-card-title mb-3">
        Add pricing for deliverable {index + 1}
      </div>
      <FieldArray
        component={Pricing}
        name={fieldName}
        project={initialValues.project}
        change={change}
        setRecurringJob={setRecurringJob}
        dispatch={dispatch}
      />
    </div>
  );
};

const Pricing = (
  props: WrappedFieldArrayProps<IRecurringJobDeliverablePricing> &
    RecurringJobDeliverablesProps & {
      project: { uuid: string };
      change?: ChangeAction;
    },
) => {
  const { fields, project, change, setRecurringJob, dispatch } = props;

  const { data: pricingItems }: IUseApi<(SelectOption & { type: string })[]> =
    useApi("pricing-items", []);
  const { data: units }: IUseApi<SelectOption[]> = useApi(
    `react-select-data?model=${encodeURIComponent(
      "App\\Models\\BillableItemUnit",
    )}&select=id,name&label=name&value=id&orderyBy[name]=ASC`,
  );

  return (
    <>
      {fields.map((field, index) => {
        return (
          <Fields
            key={index}
            field={field}
            pricingItems={pricingItems}
            units={units}
            fields={fields}
            index={index}
            project={project}
            change={change}
            setRecurringJob={setRecurringJob}
            dispatch={dispatch}
          />
        );
      })}
      <div className="row">
        <div className="col-lg-12 d-flex form-group">
          <button
            type="button"
            className="btn ms-auto btn-link"
            onClick={() => fields.push({})}
          >
            Add Pricing
          </button>
        </div>
      </div>
    </>
  );
};

interface FieldsProps {
  field: string;
  pricingItems?: (SelectOption & { type: string })[];
  units?: SelectOption[];
  fields: FieldArrayFieldsProps<IRecurringJobDeliverablePricing>;
  index: number;
  project: { uuid: string };
  change?: ChangeAction;
}

const Fields = ({
  field,
  pricingItems,
  units,
  fields,
  index,
  project,
  change,
  dispatch,
  setRecurringJob,
}: FieldsProps & RecurringJobDeliverablesProps) => {
  const price = fields.get(index);
  const { user } = useAuth();

  const pricingUrl =
    price.line_item_type &&
    price.line_item_id &&
    price.billable_item_units_id &&
    `project-pricing/${project.uuid}?item_type=${encodeURIComponent(
      price.line_item_type ?? "",
    )}&item_id=${price?.line_item_id}&units_id=${price.billable_item_units_id}`;

  const { setUrl, response }: IUseApi = useApi();

  const { takeAction }: IUseApi = useApi();

  useEffect(() => {
    if (pricingUrl) {
      setUrl(pricingUrl);
    }
  }, [pricingUrl]);

  useEffect(() => {
    if (response?.data?.price && change) {
      change(`${field}.price`, response?.data?.price);
    }
  }, [response]);

  const selectedItems = pricingItems?.filter(
    (item) => item.type === price?.line_item_type ?? "",
  );

  const deletePrice = () => {
    if (!price.uuid) {
      fields.remove(index);
    }

    return deleteSwal()
      .then(() =>
        takeAction(
          "destroy",
          `recurring-job-deliverable-pricing/${price.uuid}`,
        ),
      )
      .then(({ data }: { data: { data: RecurringJob } }) => {
        setRecurringJob(data.data);
        dispatch && dispatch(initialize("RecurringJobDeliverables", data.data));
      })
      .catch(errorSwal);
  };

  return (
    <div className="row border-bottom mb-3">
      <div className="col-lg-4 form-group">
        <Field
          validate={required}
          required
          component={SelectInput}
          name={`${field}.line_item_type`}
          url="/pricing-types"
          label="Item Type"
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          validate={required}
          required
          label="Units"
          name={`${field}.billable_item_units_id`}
          options={units}
          component={SelectInputAsync}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          validate={required}
          required
          label="Type"
          name={`${field}.line_item_id`}
          options={selectedItems}
          component={SelectInputAsync}
          disabled={!price?.billable_item_units_id || !price?.line_item_type}
        />
      </div>
      {user?.is_position_admin && (
        <div className="col-lg-4 form-group">
          <Field
            validate={required}
            required
            label="Price"
            name={`${field}.price`}
            component={RenderField}
            type="number"
            extraProps={{ step: "0.001" }}
          />
        </div>
      )}
      <div className="col-lg-4 form-group">
        <Field
          validate={required}
          required
          label="Quantity"
          name={`${field}.quantity`}
          component={RenderField}
          type="number"
          extraProps={{ step: "0.001" }}
        />
      </div>
      <div className="col-lg-4 form-group d-flex ms-auto align-items-end">
        <button
          onClick={deletePrice}
          className="btn w-100 btn-outline-danger"
          type="button"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default RecurringJobDeliverablePricing;
