import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUpcomingCalibrations } from "../../actions/calibrationsActions";
import HeaderPage from "../header/HeaderPage";
import CustomScaleLoader from "../utils/scaleLoader";
import Spinner from "../utils/Spinner";
import CalibrationsDueTable from "./CalibrationsDueTable";

export class CalibrationsDue extends Component {
  constructor(props) {
    super(props);
    const {
      location: { search },
    } = this.props;

    const params = new URLSearchParams(search);
    const period = params.get("period");

    this.state = {
      period,
      loading: false,
    };
  }

  componentDidMount() {
    const { fetchUpcomingCalibrations } = this.props;
    const { period } = this.state;
    fetchUpcomingCalibrations(period);
  }

  searchCalibrations = () => {
    const { fetchUpcomingCalibrations } = this.props;

    const { period } = this.state;
    this.setState({
      loading: true,
    });
    window.history.pushState(
      { period },
      null,
      `upcomingcalibrations?period=${period}`,
    );
    fetchUpcomingCalibrations(period, (res) => {
      this.setState({
        loading: false,
      });
    });
  };

  render() {
    const { upcomingCalibrations } = this.props;
    const { period, loading } = this.state;

    if (!upcomingCalibrations) return <Spinner loading />;

    return (
      <React.Fragment>
        <HeaderPage
          titlePage={`Calibrations due next ${period}`}
          crumbs={[
            { link: "/equipment", name: "Equipment" },
            { link: "/", name: "Calibrations Due", active: true },
          ]}
        />
        <div className="input-group mg-b-10">
          <input
            type="text"
            disabled={loading}
            name="period"
            placeholder="Search for Calibrations Due (e.g. week, 10 days or 6 months)"
            className="form-control"
            onChange={(e) => this.setState({ period: e.target.value })}
          />
          <span className="input-group-btn">
            <button
              disabled={loading}
              className="btn bd bd-l-0 bg-white tx-gray-600"
              onClick={this.searchCalibrations}
              type="button"
            >
              <i className="fa fa-search mg-r-10" />{" "}
              {loading ? "Searching..." : "Search"}
            </button>
          </span>
        </div>
        {loading ? (
          <CustomScaleLoader>
            <p>Loading Calibrations...</p>
          </CustomScaleLoader>
        ) : (
          <CalibrationsDueTable {...this.props} period={period} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  upcomingCalibrations: state.calibrations.upcomingCalibrations,
});

export default connect(mapStateToProps, { fetchUpcomingCalibrations })(
  CalibrationsDue,
);
