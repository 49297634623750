import React, { useState, useEffect } from "react";
import { Field, FieldArray, InjectedFormProps, reduxForm } from "redux-form";
import FormErrorAlert from "../form/FormErrorAlert";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import HazardReportAnswer from "./HazardReportAnswer";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { SafetyInitiative } from "./safetyInitiative";
import Empty from "../utils/Empty";
import { HazardReport, Answers } from "./types";

const HazardReportForm = (props: InjectedFormProps<HazardReport>) => {
  const { handleSubmit, error, change, form } = props;
  const [answerIsSet, setAnswerIsSet] = useState(false);
  const { takeAction } = useApi();

  const handleSafetyInitiativeChange = async (safetyInitiativeUUid: any) => {
    takeAction("show", `safety-initiatives/${safetyInitiativeUUid}/questions`)
    .then(({ data } : any) => {
      setAnswerIsSet(data.data ? true : false);

      const initialAnswers = data.data.map((question: any) => ({
        question_id: question.id,
        name: question.name
      }));
      change("answers", initialAnswers);
    })
    .catch(errorSwal);
  };

  useEffect(() => {
    if (form != "AddHazardReport") {
      const answers = props.initialValues.answers !== undefined ? true : false;
      setAnswerIsSet(answers);
    }
  }, [form]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="col-12 form-group">
          {form === "AddHazardReport" && (
            <Field
              label="Safety Initiative"
              name="safety_initiative_uuid"
              component={SelectInputAsync}
              url="/safety-initiatives"
              formatData={(data : any) => {
                return data.data.map((safetyInitiative : SafetyInitiative) => ({
                  label: safetyInitiative.name,
                  value: safetyInitiative.uuid,
                  disabled: safetyInitiative.disabled
                }));
              }}
              validate={required}
              onChange={(event: any, newValue: any) => handleSafetyInitiativeChange(newValue)}
              required
            />
          )}
        </div>
        
        {answerIsSet && (
          <>
            <FormHeader>Questions</FormHeader>
            <FieldArray {...props} component={HazardReportAnswer} name="answers" />
            <div className="form-group col-12">
              <SubmitButton {...props} />
            </div>
          </>
        )}
      </div>
    </form>
  );
};

const form = reduxForm<HazardReport>({});

export default form(HazardReportForm);