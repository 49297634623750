import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import isSubmitting from "../utils/submitting";

const DeleteButton = ({ archived, refreshData }) => {
  const { uuid } = useParams();
  const { takeAction, loading } = useApi();

  const deleteCustomJob = () => {
    swal({
      title: archived ? "Unarchive" : "Archive",
      text: archived
        ? "Unarchive this custom job?"
        : "Archive this custom job?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          closeModal: true,
          visible: true,
        },
        delete: {
          text: archived ? "Unarchive" : "Archive",
          value: true,
          closeModal: true,
          className: "btn btn-danger align-baseline",
        },
      },
    }).then((remove) => {
      if (remove) {
        return takeAction("destroy", `custom-jobs/${uuid}`)
          .then(() => {
            toast.success(`Custom Job ${archived ? "unarchived" : "archived"}`);
            refreshData();
          })
          .catch(errorSwal);
      }
    });
  };

  return (
    <Button
      onClick={deleteCustomJob}
      outline
      color="danger"
      block
      className="mt-3"
      disabled={loading}
    >
      {isSubmitting(
        loading,
        archived ? "Unarchive" : "Archive",
        archived ? "Unarchiving..." : "Archiving...",
      )}
    </Button>
  );
};

export default DeleteButton;
