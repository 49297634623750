import { useEffect, useState } from "react";
import { Progress, Spinner, UncontrolledTooltip } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";
import DateRangePicker from "./DateRangePicker";
import { dateRangeQuery, lastQuarterRange } from "./ProductivityCards";

const ProjectProductivity = ({ userId, width, wrapperClass = "" }) => {
  const [range, setRange] = useState(lastQuarterRange);
  const { data, setUrl, loading } = useApi();
  const { user } = useAuth();

  useEffect(() => {
    const dateRange = dateRangeQuery(range);
    if (!dateRange) {
      return;
    }
    const url = `productivity-overview/${
      userId ?? user.uuid
    }/projects?${dateRange}`;

    setUrl(url);
  }, [range, userId]);

  const percent = (numerator, denominator) => {
    if (!denominator || !numerator) {
      return 0;
    }

    return ((numerator / denominator) * 100).toFixed(0);
  };

  return (
    <div className={`position-relative col-lg-${width ?? "4"} ${wrapperClass}`}>
      <div className="card card-sales">
        {loading && (
          <div className="z-index-10 t-0 l-0 h-100 w-100 d-flex align-items-center justify-content-center position-absolute bg-white-4">
            <Spinner color="primary" />
          </div>
        )}
        <div className="d-flex">
          <div className="mb-3">
            <h6 className="slim-card-title tx-info mb-0">
              Project Manager Report
            </h6>
            <small>A quick summary of jobs from projects you manage.</small>
          </div>

          <DateRangePicker range={range} setRange={setRange} />
        </div>
        <div className="row">
          <div className="col">
            <label className="tx-12 mb-0">
              Estimated Hours
              <FieldInformationPopOver
                id="estimated_hours"
                information="The sum of estimated hours from jobs inside your projects."
              />
            </label>

            <p>{data?.estimated_hours?.toFixed(2)}</p>
          </div>
          <div className="col">
            <label className="tx-12 mb-0">
              Actual Hours
              <FieldInformationPopOver
                id="actual_hours"
                information="The sum of productivity hours added to jobs inside your projects. (Includes unapproved timesheets)"
              />
            </label>
            <p>{data?.actual_hours?.toFixed(2)}</p>
          </div>
        </div>
        <Progress
          id="hours-estimate"
          className="mb-2"
          barClassName="bg-info"
          value={percent(data?.estimated_hours, data?.actual_hours)}
        >
          {percent(data?.estimated_hours, data?.actual_hours)}%
        </Progress>
        <UncontrolledTooltip placement="top" target="hours-estimate">
          <small>Estimate Hours / Actual Hours</small>
        </UncontrolledTooltip>
        <div className="row mt-3">
          <div className="col">
            <label className="tx-12 mb-0">
              Estimated
              <FieldInformationPopOver
                id="estimated"
                information="The estimated value of projects based on amount of productivity put towards them and the charge out rate for that productivity. (Includes unapproved timesheets)"
              />
            </label>
            <p>{money.format(data?.estimated_invoices?.toFixed(2))}</p>
          </div>
          <div className="col">
            <label className="tx-12 mb-0">
              Invoiced
              <FieldInformationPopOver
                id="invoiced"
                information="The actual amount invoiced."
              />
            </label>
            <p>{money.format(data?.invoiced?.toFixed(2))}</p>
          </div>
          <div className="col">
            <label className="tx-12 mb-0">
              WIP
              <FieldInformationPopOver
                id="wip"
                information="Work in Progress (total amount of un-invoiced deliverables and estimated value of jobs)."
              />
            </label>
            <p>{money.format(data?.wip?.toFixed(2))}</p>
          </div>
        </div>
        <Progress
          id="invoiced-estimate"
          className="mb-2"
          barClassName="bg-info"
          value={percent(data?.invoiced + data?.wip, data?.estimated_invoices)}
        >
          {percent(data?.invoiced + data?.wip, data?.estimated_invoices)}%
        </Progress>
        <UncontrolledTooltip placement="top" target="invoiced-estimate">
          <small>(Total Amount Invoiced + Total WIP) / Estimated Amount</small>
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

export default ProjectProductivity;
