import axios from "../api/api";
import {
  DELETE_CALIBRATION_UNIT,
  FETCH_CALIBRATION_UNITS,
  NEW_CALIBRATION_UNIT,
  SELECT_CALIBRATION_UNIT,
  UPDATE_CALIBRATION_UNIT,
} from "../actions/types";

export const fetchCalibrationUnits =
  (id = "") =>
  (dispatch) => {
    return axios
      .get(`/calibration-units/${id}`)
      .then((response) => response.data.data)
      .then((calibrationUnits) =>
        dispatch({
          type: FETCH_CALIBRATION_UNITS,
          payload: calibrationUnits,
        }),
      );
  };

export const createCalibrationUnit = (postData) => (dispatch) => {
  return axios.post(`/calibration-units`, postData).then(({ data }) =>
    dispatch({
      type: NEW_CALIBRATION_UNIT,
      payload: data.data,
    }),
  );
};

export const updateCalibrationUnit = (postData) => (dispatch) => {
  return axios
    .put(`/calibration-units/${postData.uuid}`, postData)
    .then(({ data }) =>
      dispatch({
        type: UPDATE_CALIBRATION_UNIT,
        payload: data.data,
      }),
    );
};

export const deleteCalibrationUnit = (uuid) => (dispatch) => {
  return axios.delete(`/calibration-units/${uuid}`).then(({ data }) =>
    dispatch({
      type: DELETE_CALIBRATION_UNIT,
      payload: uuid,
    }),
  );
};

export const selectCalibrationUnit = (unit) => (dispatch) => {
  return dispatch({
    type: SELECT_CALIBRATION_UNIT,
    payload: unit,
  });
};
