import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { TestDashboardFilter, TestDashboardFilterKeys } from "./TestDashboard";
import Select from "react-select";
import { FilterOption } from "../hooks/useFilter";
interface TestDashboardFilterModalProps {
  toggle: React.MouseEventHandler;
  modal: boolean;
  filters: TestDashboardFilter[];
  toggleFilter: (filterName: TestDashboardFilterKeys, optionValue: any) => void;
  toggleMulti: (
    filterName: TestDashboardFilterKeys,
    optionValue: readonly any[],
  ) => void;
}

type SelectOption = FilterOption<number | boolean, TestDashboardFilterKeys>;

const TestDashboardFilterModal = (props: TestDashboardFilterModalProps) => {
  const { modal, toggle, filters, toggleFilter, toggleMulti } = props;

  return (
    <Modal
      className="wd-md-1000 mx-wd-800"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Filter Tests</ModalHeader>
      <ModalBody>
        <div className="row">
          {filters.map((filter) => {
            return (
              <div key={filter.name} className="col-lg-4 form-group">
                <label className="form-control-label tx-inverse tx-semibold">
                  {filter.label}
                </label>
                <Select<SelectOption, boolean>
                  options={filter.options}
                  isClearable
                  className="shadow-sm"
                  isMulti={filter.multiple}
                  value={filter.options.filter((option) => option.selected)}
                  onChange={(value, { action }) => {
                    if (action === "clear") {
                      toggleFilter(
                        filter.name,
                        filter.options.find((f) => f.selected)?.value,
                      );
                      return;
                    }

                    if (!value) {
                      return;
                    }

                    if ("value" in value) {
                      toggleFilter(filter.name, value.value);
                      return;
                    }

                    toggleMulti(filter.name, value);
                  }}
                />
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default TestDashboardFilterModal;
