import React from "react";
import { Badge } from "reactstrap";
import getStatus from "./getStatus";

const StatusBadge = ({ purchase }) => {
  const status = getStatus(purchase);
  return (
    <Badge className="rounded-pill shadow" color={status.color}>
      {status.text}
    </Badge>
  );
};

export default StatusBadge;
