import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  getFormInitialValues,
  reduxForm,
} from "redux-form";
import renderField from "../utils/renderField";
import required from "../utils/required";

import axios from "../api/api";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import { EquipmentStatus } from "./equipmentTypes";
import SelectInput from "../form/SelectInput";
import { useAuth } from "../../context/auth-context";
import EQUIPMENT_CONDITIONS from "../../data/equipment_condition";
import CustomForm from "../customFields/CustomForm";
import useApi from "../api/useApi";

const EquipmentForm = (props) => {
  const [typeOptions, setTypeOptions] = useState([]);
  const { initialValues, change, handleSubmit, projectUuid } = props;
  const { user } = useAuth();

  const { data: project, setUrl: setProjectUrl } = useApi();

  const { data: recurringPayments, setUrl: setRecurringPaymentUrl } = useApi(
    "",
    [],
  );

  useEffect(() => {
    if (
      project &&
      project?.client?.client_entity_type == "App\\Models\\Organisation"
    ) {
      setRecurringPaymentUrl(
        `clients/${project.client.uuid}/recurring-payments`,
      );
    }
  }, [project]);

  useEffect(() => {
    if (projectUuid) {
      setProjectUrl(`/projects/${projectUuid}`);
    }
  }, [projectUuid]);

  useEffect(() => {
    if (initialValues && initialValues.type_group) {
      axios
        .get(`/equipment/types/${initialValues.type_group}`)
        .then((response) => setTypeOptions(response.data));
    }
  }, [initialValues]);

  const groupChange = (_, value) => {
    change("type_id", "");
    setTypeOptions([]);
    return axios
      .get(`/equipment/types/${value}`)
      .then((response) => setTypeOptions(response.data));
  };

  const branchDirty =
    props.branch_id_initial !== undefined &&
    props.branch_id_value !== props.branch_id_initial;

  const subLocationDirty =
    props.sub_location_initial !== undefined &&
    props.sub_location_value !== props.sub_location_initial;

  const withdrawnDirty =
    props.withdrawn_initial !== undefined &&
    props.withdrawn_value !== props.withdrawn_initial;

  const isHiring = [
    EquipmentStatus.Hiring,
    EquipmentStatus.OnHired,
    EquipmentStatus.OffHired,
  ].includes(props.withdrawn_value);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-10">
        {user?.is_admin && (
          <>
            <div className="form-group col-lg-6">
              <Field component={renderField} name="prefix" label="Prefix" />
              <small>
                Leave prefix and number blank to have number automatically
                created from your organisation settings
              </small>
            </div>
            <div className="form-group col-lg-6">
              <Field
                component={renderField}
                type="number"
                name="number"
                label="Number"
              />
            </div>
          </>
        )}

        <div className="form-group col-lg-6">
          <Field
            validate={required}
            required
            name="type_group"
            component={SelectInputAsync}
            label="Group"
            onChange={groupChange}
            url="/equipment/groups"
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={required}
            required
            name="type_id"
            component={SelectInputAsync}
            label="Type"
            options={typeOptions}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="project_id"
            label="Project"
            component={SelectInput}
            url="/custom-fields/projects"
            formatData={(projects) =>
              projects.map((project) => ({
                label: project.name,
                value: project.id,
                uuid: project.uuid,
              }))
            }
            changeValue={(value) => {
              props.change("project_uuid", value?.uuid);
              props.change("recurring_payment_id", null);
            }}
            information="The equipment manager will be the job manager of all the equipment's calibrations."
          />
        </div>
        {recurringPayments.length > 0 && (
          <div className="form-group col-lg-6">
            <Field
              component={SelectInput}
              name="recurring_payment_id"
              label="External Contract Agreement"
              options={recurringPayments.map((r) => ({
                label: r.name,
                value: r.id,
              }))}
            />
          </div>
        )}
        <div className="form-group col-lg-6">
          <Field
            name="manager_id"
            label="Manager"
            component={SelectInput}
            url="/users?filter[employed]=true"
            formatData={(users) =>
              users.map((user) => ({
                label: user.full_name,
                value: user.id,
              }))
            }
            information="The equipment manager will be the job manager of all the equipment's calibrations."
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="branch_owner_id"
            label="Purchased By"
            component={SelectInputAsync}
            url="/organisationbranches/branches"
            hideDisabled={true}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={required}
            required
            name="make"
            type="text"
            label="Make"
            component={renderField}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={required}
            required
            name="model"
            type="text"
            label="Model"
            component={renderField}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={isHiring ? null : required}
            required={!isHiring}
            name="serial_number"
            type="text"
            label="Serial Number"
            component={renderField}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={required}
            required
            name="condition"
            type="text"
            label="Condition"
            component={SelectInputAsync}
            options={EQUIPMENT_CONDITIONS}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={required}
            required
            name="branch_id"
            label="Branch"
            component={SelectInputAsync}
            url="/organisationbranches/branches"
            hideDisabled={true}
          />
          {branchDirty && (
            <div className="mt-3">
              <Field
                validate={required}
                required
                name="edit_reasons.branch_id"
                label="Branch Change Reason"
                component={renderField}
              />
            </div>
          )}
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="sub_location"
            type="text"
            label="Sub Location"
            component={renderField}
          />
          {subLocationDirty && (
            <div className="mt-3">
              <Field
                validate={required}
                required
                name="edit_reasons.sub_location"
                label="Location Change Reason"
                component={renderField}
              />
            </div>
          )}
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="call_sign"
            type="text"
            label="Call Sign"
            component={renderField}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={required}
            required
            name="date_received"
            type="date"
            label="Date Received"
            component={renderField}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            label="Status"
            component={SelectInputAsync}
            url="/equipment-status-types"
            name="withdrawn"
            validate={required}
            required
          />
          {withdrawnDirty && (
            <div className="mt-3">
              <Field
                validate={required}
                required
                name="edit_reasons.withdrawn"
                label="Status Change Reason"
                component={renderField}
              />
            </div>
          )}
        </div>
        {isHiring && (
          <>
            <div className="form-group col-lg-6">
              <Field
                component={SelectInput}
                url="/suppliers"
                name="hired_from_id"
                label="Hired From"
                formatData={(data) =>
                  data.map((supplier) => ({
                    label: supplier.display_name,
                    value: supplier.id,
                  }))
                }
              />
            </div>

            <div className="form-group col-lg-6">
              <Field
                component={renderField}
                name="date_off_hired"
                label="Date Off Hired"
                type="date"
              />
            </div>
          </>
        )}
        <CustomForm
          customForm={initialValues?.custom_form ?? props.customFields}
          {...props}
          shouldError={true}
        />
        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  const initial = getFormInitialValues(form);

  return {
    branch_id_value: selector(state, "branch_id"),
    branch_id_initial: initial(state)?.branch_id,
    sub_location_value: selector(state, "sub_location"),
    sub_location_initial: initial(state)?.sub_location,
    withdrawn_value: selector(state, "withdrawn"),
    withdrawn_initial: initial(state)?.withdrawn,
    projectUuid: selector(state, "project_uuid"),
  };
};

const validate = (values) => {
  const errors = {};
  if (values.number && values.number > 2147483647) {
    errors.number = "Number must be less than or equal to 2,147,483,647";
  }

  return errors;
};

const form = reduxForm({ validate });

export default connect(mapStateToProps, {})(form(EquipmentForm));
