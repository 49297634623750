import React from "react";
import { Button } from "reactstrap";
import useApi from "../../api/useApi";
import CustomScaleLoader from "../../utils/scaleLoader";
import UserForm from "./UserForm";

const SyncUsers = () => {
  const { data, takeAction, loading } = useApi(
    "the-testing-app-users",
    [],
    true,
  );

  if (loading) {
    return <CustomScaleLoader>Fetching Users...</CustomScaleLoader>;
  }

  return (
    <>
      <small className="d-block tx-inverse" style={{ marginTop: "-2rem" }}>
        In order to sync users, the users must be signed up to theTESTINGapp.
      </small>
      <small className="d-block tx-inverse">
        If you are unable to see a user, please ensure that they've accepted
        their invitation.
      </small>
      <small className="d-block tx-inverse">
        You can see a full list of users that have signed up by{" "}
        <a href="https://thetestingapp.com.au/organisation">clicking here</a>{" "}
        and moving to the 'Users' tab.
      </small>
      <hr />
      <UserForm data={data} />
    </>
  );
};

export default SyncUsers;
