import React from "react";
import { Button } from "reactstrap";
import useModal from "../hooks/useModal";
import Empty from "../utils/Empty";
import DeleteTopicPosition from "./DeleteTopicPosition";
import { EditTopicProps } from "./EditTopic";
import TopicPositionModal from "./TopicPositionModal";

const TopicPositions = (props: EditTopicProps) => {
  const { topic } = props;

  const { modal, toggle } = useModal();

  return (
    <>
      <div>
        <div className="d-flex mt-3 align-items-center">
          <label className="section-title my-0">Positions</label>
          <Button
            size="sm"
            color="primary"
            className="ms-auto"
            onClick={toggle}
          >
            Add New Position
          </Button>
        </div>
        <hr className="w-100" />
      </div>
      {topic?.positions.length === 0 ? (
        <Empty top={false}>
          <div className="mt-5 text-center">
            <p className="fw-bolder text-dark mb-2">
              No Positions Added
            </p>
            <p className="mb-2">
              Adding positions to topics ensures that the staff who need to know
              this information will be up to date.
            </p>
            <p>
              To get started adding positions to {topic.name}, click 'Add New
              Position' above.
            </p>
          </div>
        </Empty>
      ) : (
        <div className="space-y-3">
          {topic?.positions?.map((position) => {
            return (
              <div className="bg-white p-3 border rounded-lg d-flex">
                <p className="mb-0 text-dark me-auto">{position.name}</p>
                <DeleteTopicPosition
                  setTopic={props.setTopic}
                  position={position}
                />
              </div>
            );
          })}
        </div>
      )}
      <TopicPositionModal modal={modal} toggle={toggle} {...props} />
    </>
  );
};

export default TopicPositions;
