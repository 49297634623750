import React from "react";

const PositionList = ({ pay }) => {
  if (!pay) return null;

  return (
    <div className="row mg-b-20">
      <div className="col-md-12">
        <div className="list-group">
          {pay.user.roles.map((role) => (
            <div
              key={role.uuid}
              className="list-group-item pd-y-20 link-hover tx-inverse"
            >
              <div className="d-flex align-items-center">
                <div className="d-flex mg-r-10 wd-50">
                  <i className="fa fa-briefcase tx-primary tx-40" />
                </div>
                <div className="media-body">
                  <h6 className="mb-0">{role.position}</h6>
                  <p className="mb-0">
                    Remuneration: $
                    {role.remuneration
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$& ")}
                  </p>
                  <p className="mb-0">Hourly Rate: ${role.hourly_rate}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PositionList;
