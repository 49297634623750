import axios from "../api/api";

export const store = (values) => {
  return axios.post(`/organisationdivisions`, values);
};

export const update = (values, uuid) => {
  if (!uuid) {
    throw new Error("Unable to update division, division not found.");
  }

  return axios.put(`/organisationdivisions/${uuid}`, values);
};

export const destroy = (uuid) => {
  if (!uuid) {
    throw new Error("Unable to delete division, division not found.");
  }

  return axios.delete(`/organisationdivisions/${uuid}`);
};
