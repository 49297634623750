import React from "react";
import NoMatch from "../../404";
import NuclearDensometer from "../../nuclearDensometers/NuclearDensometer";
import OversizeFieldMoisture from "../../oversizeFieldMoistures/OversizeFieldMoisture";
import Compaction from "../../compactions/Compaction";

const TestIndex = props => {
  const {
    match: {
      params: { testMethod }
    }
  } = props;
  switch (testMethod) {
    case "nuclear-densometor":
      return <NuclearDensometer {...props} />;
    case "oversize-field-moisture":
      return <OversizeFieldMoisture {...props} />;
    case "compaction":
      return <Compaction {...props} />;
    default:
      return <NoMatch />;
  }
};

export default TestIndex;
