import axios from "../api/api";
import {
  DELETE_STAFF_ROLE_TYPE,
  FETCH_STAFF_ROLE_TYPES,
  NEW_STAFF_ROLE_TYPE,
  UPDATE_STAFF_ROLE_TYPE,
} from "./types";

export const fetchStaffRoleTypes =
  (id = "") =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(`staffroletypes/${id}`)
        .then((response) => response.data.data)
        .then((staffRoleTypes) =>
          dispatch({
            type: FETCH_STAFF_ROLE_TYPES,
            payload: staffRoleTypes,
          }),
        )
        .then(({ payload }) => {
          resolve(payload);
        })
        .catch((error) => reject(error));
    });

export const createStaffRoleType = (postData) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .post(`staffroletypes/`, postData)
      .then((staffRoleType) =>
        dispatch({
          type: NEW_STAFF_ROLE_TYPE,
          payload: staffRoleType,
        }),
      )
      .then((response) => {
        const {
          data: { errors },
          data,
        } = response.payload;
        if (errors) {
          reject(errors);
        }
        resolve(data);
      })
      .catch((error) => reject(error));
  });

export const updateStaffRoleType = (postData) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`staffroletypes/${postData.uuid}`, postData)
      .then((staffRoleType) =>
        dispatch({
          type: UPDATE_STAFF_ROLE_TYPE,
          payload: staffRoleType,
        }),
      )
      .then((response) => {
        const {
          data: { errors },
          data,
        } = response.payload;
        if (errors) {
          reject(errors);
        }
        resolve(data);
      })
      .catch((error) => reject(error));
  });

export const deleteStaffRoleType = (uuid) => (dispatch) => {
  return axios.delete(`staffroletypes/${uuid}`).then((staffRoleType) =>
    dispatch({
      type: DELETE_STAFF_ROLE_TYPE,
      payload: staffRoleType,
    }),
  );
};
