import {
  FETCH_INVITE,
  FETCH_INVITES,
  NEW_INVITE,
  DELETE_INVITE
} from "../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  const items = Object.assign([], state.items);
  switch (action.type) {
    case FETCH_INVITES:
      return {
        ...state,
        items: action.payload
      };
    case FETCH_INVITE:
      return {
        ...state,
        item: action.payload
      };
    case NEW_INVITE:
      items.push(action.payload);
      return {
        ...state,
        items
      };
    case DELETE_INVITE:
      return {
        ...state,
        items: items.filter(item => item.uuid !== action.payload)
      };
    default:
      return state;
  }
}
