import React from "react";
import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import UserSchedule from "./UserSchedule";

const Index = (props) => {
  const { user } = useAuth();

  return (
    <>
      <HeaderPage
        titlePage="Your Schedule"
        crumbs={[
          {
            link: "/",
            name: "Schedule",
            active: true,
          },
        ]}
      />
      <UserSchedule userId={user.uuid} {...props} />
    </>
  );
};

export default Index;
