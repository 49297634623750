import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Toggle from "react-toggle";
import { Button } from "reactstrap";
import swal from "sweetalert";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import ReactTable from "../table/ReactTable";
import { findAndReplace } from "../utils/arrayUtils";
import errorSwal from "../utils/errorSwal";
import { PublicHoliday } from "./publicHolidayTypes";
import { PenaltyRate, Position } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const PublicHolidays = () => {
  const [onlyFuture, setOnlyFuture] = useState(true);

  const {
    data,
    setData: setHolidays,
    loading,
    takeAction,
    setUrl,
  }: IUseApi = useApi("public-holidays?filter[in_future]=true", [], true);

  const holidays = data as PublicHoliday[];

  useEffect(() => {
    setUrl(`public-holidays${onlyFuture ? "?filter[in_future]=true" : ""}`);
  }, [onlyFuture]);

  const createTimesheets = (holiday: PublicHoliday) => {
    swal({
      icon: "warning",
      title: "Create Timesheets",
      text: `Are you sure you want to create timesheets for ${
        holiday.name
      } on ${dayjs(holiday.date).format("DD/MM/YYYY")}?`,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          closeModal: true,
          visible: true,
          className: "btn btn-secondary align-baseline",
        },
        delete: {
          text: "Continue",
          value: true,
          closeModal: true,
          className: "btn btn-primary align-baseline",
        },
      },
    }).then((create) => {
      if (create) {
        return takeAction("store", `public-holidays/${holiday.uuid}/timesheets`)
          .then(({ data }) => {
            toast.success(
              "Job Dispatched, you will receive an email when all timesheets are created.",
            );
            setHolidays(findAndReplace("uuid", holidays, data.data));
          })
          .catch(errorSwal);
      }
    });
  };

  const columnHelper = createColumnHelper<PublicHoliday>();

  const columnDefs = [
    columnHelper.accessor("name", {
      cell: (info) => {
        return (
          <Link to={`/public-holidays/${info.row.original.uuid}`}>
            {info.getValue()}
          </Link>
        );
      },
      header: "Name",
    }),
    columnHelper.accessor("date", {
      cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY"),
      header: "Date",
    }),
    columnHelper.accessor("timesheets_created_at", {
      cell: (info) => (info.getValue() ? "Yes" : "No"),
      header: "Timesheets Created",
    }),
    columnHelper.display({
      id: "create_timesheets",
      header: "Create Timesheets",
      cell: (info) => {
        return (
          <Button
            color="primary"
            size="sm"
            onClick={() => createTimesheets(info.row.original)}
          >
            Create
          </Button>
        );
      },
    }),
  ];

  const columns = useMemo<ColumnDef<PublicHoliday, any>[]>(
    () => columnDefs,
    [holidays],
  );

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/timesheet-tracking/public-holidays/" />
      <HeaderPage
        titlePage="Public Holidays"
        pageDescription={
          <>
            Manage public holidays for an organization, enabling the creation
            and assignment of public holidays to specific tracking sites for
            streamlined management of holiday schedules.
          </>
        }
        relatedLinks={[
          {
            name: "Penalty Rates",
            link: "/penalty-rates",
            model: PenaltyRate,
          },
          {
            name: "Positions",
            link: "/positions",
            model: Position,
          },
        ]}
        crumbs={[
          {
            link: "/organisations/settings/links",
            name: "Organisation",
          },
          {
            link: "",
            name: "Public Holidays",
            active: true,
          },
        ]}
      />
      <ReactTable
        data={holidays}
        columns={columns}
        loading={loading}
        extraButtons={
          <>
            <Link className="align-self-center ms-3" to="/public-holidays/add">
              <AiOutlinePlus /> Add New
            </Link>
            <div className="align-self-center ms-3 d-flex align-items-center">
              <Toggle
                id="show-previous-public-holidays"
                checked={!!onlyFuture}
                onChange={(e) => {
                  setOnlyFuture(e.target.value === "false" ? true : false);
                }}
              />
              <label
                htmlFor="show-previous-public-holidays"
                className="text-dark ms-1 mb-0"
              >
                {onlyFuture ? "Hiding" : "Showing"} previous
              </label>
            </div>
          </>
        }
      />
    </>
  );
};

export default PublicHolidays;
