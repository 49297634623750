import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import InformationAlert from "../utils/InformationAlert";
import renderField from "../utils/renderField";
import Spinner from "../utils/Spinner";

const ResetPassword = (props: InjectedFormProps) => {
  const { reset, handleSubmit, submitSucceeded, submitting, error } = props;

  const [verificationError, setVerificationError] = useState("");

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction: verify, loading }: IUseApi = useApi();

  const { takeAction }: IUseApi = useApi();

  useEffect(() => {
    verify("store", "auth/verify", { token: uuid }).catch((err) =>
      setVerificationError(err?.response?.data?.message),
    );
  }, []);

  const onSubmit = (values: any) => {
    values.token = uuid;

    return takeAction("store", "auth/reset", values)
      .then(reset)
      .catch(formError);
  };

  return (
    <div className="signin-wrapper">
      {loading && <Spinner loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="signin-box signup">
          <h2 className="slim-logo">
            <a href="/">theBOSSapp</a>
          </h2>

          {submitSucceeded ? (
            <p className="mg-t-40 mg-b-0">
              Please <Link to="/login">Sign In</Link>.
            </p>
          ) : (
            <h3 className="signin-title-primary">Reset password!</h3>
          )}

          {(error || verificationError) && (
            <div className="mb-3">
              <InformationAlert
                type="danger"
                closable
                body={error ?? verificationError}
                title={
                  verificationError
                    ? "Verification Error"
                    : "Error when Submitting Form"
                }
              />
            </div>
          )}

          {!submitSucceeded && (
            <div>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder="Password"
                  component={renderField}
                />
              </div>
              <div className="form-group mg-b-50">
                <Field
                  className="form-control"
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  component={renderField}
                />
              </div>

              <button
                className="btn btn-primary w-100 btn-signin"
                disabled={submitting}
              >
                Change password
              </button>
              <p className="mg-t-40 mg-b-0">
                Already have an account? <Link to="/login">Sign In</Link>
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const register = reduxForm({ form: "Register", touchOnBlur: false });

export default register(ResetPassword);
