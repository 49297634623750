import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import FormErrorAlert from "../form/FormErrorAlert";
import { Field } from "redux-form";
import DateTime, { formatting } from "../utils/dateTime";
import required from "../utils/required";

const ExportTimesheetFormModal = (props) => {
  const { toggle, modal, user } = props;
  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    values.user_id = user.id;
    
    return takeAction(
      "store",
      `timesheet-exports`,
      values,
    )
      .then(({ data }) => {
        toggle();
        
        const downloadLink = document.createElement('a');
        downloadLink.href = data.temporaryUrl;
        downloadLink.download = 'downloaded_file.csv';
        downloadLink.style.display = 'none';

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);

        toast.success("The timesheet has been successfully exported. The download should start any moment now.");
      })
      .catch(formError);
  };

  return (
      <FormModal
        onSubmit={onSubmit}
        title="Export Timesheet to CSV"
        modal={modal}
        toggle={toggle}
        form="ExportTimesheetForm"
        save="Export"
        saving="Exporting"
      >
        <div className="row">
          <FormErrorAlert error={props.error} />
          <div className="form-group col-lg-12">
            <Field
              component={DateTime}
              name="start_date"
              label="Start Date"
              {...formatting}
              validate={required}
              required
            />
          </div>
          <div className="form-group col-lg-12">
            <Field
              component={DateTime}
              name="end_date"
              label="End Date"
              {...formatting}
              validate={required}
              required
            />
          </div>
        </div>
      </FormModal>
  );
};

export default ExportTimesheetFormModal;
