import React from "react";
import renderField from "../utils/renderField";
import { reduxForm, Field } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import required from "../utils/required";

const Form = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            required
            validate={required}
            component={renderField}
            name="name"
            label="Name"
          />
        </div>
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "Qualifications" });

export default form(Form);
