import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import ShowNotificationPage from "./ShowNotificationPage";

const NotificationsDashboard = (props) => {
  const { toggle: toggleShowRead, modal: showRead } = useModal();
  const { page } = useParams();

  return (
    <div>
      <HeaderPage
        titlePage="Notifications"
        crumbs={[{ link: "notifications/show", name: "Notifications" }]}
      />

      <div className="row row-sm">
        <div className="col-lg-8">
          <ShowNotificationPage {...props} showRead={showRead} />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <TabList>
            <Tab
              link="/notifications/show"
              active={page === "show"}
              icon="fa fa-bell-o"
            >
              Notifications
            </Tab>
            <Tab
              link="/notifications/settings"
              active={page === "settings"}
              icon="fa fa-cog"
            >
              Email Settings
            </Tab>
          </TabList>
          {page === "show" && (
            <button
              type="button"
              className="mg-t-20 btn w-100 btn-outline-secondary"
              onClick={toggleShowRead}
            >
              {showRead ? "Hide Read" : "Show Read"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications.items,
});

export default connect(mapStateToProps, {})(NotificationsDashboard);
