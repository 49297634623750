import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjsLocalizer from "../utils/dayjsLocalizer";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Calendar } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import { useParams } from "react-router-dom";
import useBranchLeave from "./hooks/useBranchLeave";

dayjs.locale("en");
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const localizer = dayjsLocalizer(dayjs);

const BranchLeaveCalendar = ({ branch }) => {
  const { uuid } = useParams();
  const [leaveRange, setLeaveRange] = useState({
    start: dayjs().startOf("month").format("YYYY-MM-DD"),
    end: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const {
    data: branchLeaves,
    status: branchLeavesStatus,
    error: branchLeavesError,
    isLoading: branchLeavesLoading,
    refetch: branchLeavesRefetch,
  } = useBranchLeave(branch.uuid, leaveRange);

  useEffect(() => {
    branchLeavesRefetch();
  }, [uuid, leaveRange]);

  if (branchLeavesStatus === "error") {
    errorSwal(branchLeavesError);
  }

  if (branchLeavesLoading) {
    return <CustomScaleLoader>Fetching Leaves...</CustomScaleLoader>;
  }

  const formattedEvents =
    branchLeavesLoading || branchLeavesError
      ? []
      : branchLeaves.data.map((leave) => {
          const approval = leave.approved ? "APPROVED - " : "PENDING - ";
          return {
            ...leave,
            title: approval
              .concat(leave.user.full_name)
              .concat(` - ${leave.leave_reason}`),
            start: dayjs(leave.start).toDate(),
            end: dayjs(leave.end).toDate(),
          };
        });

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex">
          <label className="section-title mt-3">
            Staff Leave calendar for {branch.name}
          </label>
        </div>
        <div className="col-12">
          <hr />
        </div>
      </div>
      {branchLeavesStatus === "error" ? (
        <span className="text-warning">
          Unable to fetch branch leaves. Please try again.
        </span>
      ) : (
        <Calendar
          defaultDate={leaveRange.start}
          date={leaveRange.start}
          localizer={localizer}
          events={formattedEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "800px" }}
          views={["month", "week", "day"]}
          className="p-3 bg-white border"
          onNavigate={(date, view) => {
            setLeaveRange({
              start: dayjs(date).startOf(view).format("YYYY-MM-DD"),
              end: dayjs(date).endOf(view).format("YYYY-MM-DD"),
            });
          }}
          onView={(view) => {
            setLeaveRange({
              start: dayjs(leaveRange.start).startOf(view).format("YYYY-MM-DD"),
              end: dayjs(leaveRange.start).endOf(view).format("YYYY-MM-DD"),
            });
          }}
          eventPropGetter={(event) => {
            return {
              className: `bg-${event.status_badge.color}`,
            };
          }}
        />
      )}
    </>
  );
};

export default BranchLeaveCalendar;
