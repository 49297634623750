import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import Avatar from "../utils/Avatar";
import { BiCheck, BiCheckCircle, BiX } from "react-icons/bi";
import UserGoals from "../user/UserGoals";
import EmployeeAnswers from "./EmployeeAnswers";
import ManagerAnswers from "./ManagerAnswers";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import PerformanceReviewOverview from "./PerformanceReviewOverview";
import Spinner from "../utils/Spinner";
import FormHeader from "../utils/FormHeader";
import CheckInAnswers from "./CheckInAnswers";
import colors from "../../data/colors";
import CustomScaleLoader from "../utils/scaleLoader";
import ApprovalButton from "../approvals/ApprovalButton";
import { ApprovalActionType } from "../approvals/approvalTypes";
import BottomLogo from "../utils/BottomLogo";
import InformationAlert from "../utils/InformationAlert";

const PerformanceReviewProfile = () => {
  const { uuid, page } = useParams<{ uuid: string; page: string }>();

  const {
    data,
    setData: setPerformanceReview,
    loading,
    setUrl,
  } = useApi(`performance-reviews/${uuid}`, null, true);

  useEffect(() => {
    setUrl(`performance-reviews/${uuid}`);
  }, [uuid]);

  return (
    <>
      <Header performanceReview={data} />
      <div className="row">
        <div className="col-12 my-5">
          <Steps performanceReview={data} />
        </div>
        <div className="col-lg-9">
          {loading ? (
            <CustomScaleLoader>
              Fetching Performance Review...
            </CustomScaleLoader>
          ) : (
            <>
              <ShowPage
                performanceReview={data}
                setPerformanceReview={setPerformanceReview}
              />
            </>
          )}
        </div>
        <div className="col-lg-3">
          <ApprovalButton
            approvalOptions={[
              {
                label: "Approve",
                value: ApprovalActionType.Approve,
              },
              {
                label: "Redo",
                value: ApprovalActionType.Redo,
              },
            ]}
            approvalAction={data?.approval_action}
          />
          <div className="space-y-3">
            {data?.siblings.map((sibling: any) => {
              const isPrimary = sibling.number === 1;

              const isSelected = sibling.uuid === uuid;

              return (
                <div>
                  <Link
                    to={`/performance-reviews/${sibling.uuid}/${
                      !isPrimary ? "check-in" : "employee-assessment"
                    }`}
                  >
                    <div
                      className={`p-3 tn-300 rounded-lg shadow-sm border ${
                        isSelected ? "money-gradient" : "bg-white"
                      }`}
                    >
                      <p
                        style={{
                          color: colors.info.main,
                        }}
                        className={`mb-0 ${
                          isSelected ? "text-white" : "text-dark"
                        }`}
                      >
                        {isPrimary ? "Primary" : "Check In"}{" "}
                        {isPrimary ? "" : sibling.number - 1}
                      </p>
                      <p
                        className={`mb-0 ${
                          isSelected ? "text-white" : "text-muted"
                        }`}
                      >
                        {dayjs(sibling.date).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const ShowPage = ({
  performanceReview,
  setPerformanceReview,
}: {
  performanceReview: any;
  setPerformanceReview: Function;
}) => {
  const { page } = useParams<{ page: string }>();

  if (!performanceReview) return null;

  switch (page) {
    case "employee-assessment":
      return (
        <EmployeeAnswers
          performanceReview={performanceReview}
          setPerformanceReview={setPerformanceReview}
        />
      );
    case "manager-assessment":
      return (
        <ManagerAnswers
          performanceReview={performanceReview}
          setPerformanceReview={setPerformanceReview}
        />
      );
    case "overview":
      return (
        <PerformanceReviewOverview
          performanceReview={performanceReview}
          setPerformanceReview={setPerformanceReview}
        />
      );
    case "check-in":
      return (
        <CheckInAnswers
          performanceReview={performanceReview}
          setPerformanceReview={setPerformanceReview}
        />
      );
    case "goals":
      return (
        <>
          <p>
            These goals will pull through to your quarterly check-ins and annual{" "}
            '{performanceReview.round.template}''.
          </p>
          <UserGoals
            initialValues={{
              start_date: dayjs(performanceReview.siblings[0].date).format(
                "YYYY-MM-DD",
              ),
              to_be_completed_by: dayjs(performanceReview.siblings[0].date)
                .add(1, "year")
                .format("YYYY-MM-DD"),
            }}
            queryString={`?filter[date_between]=${dayjs(
              performanceReview.siblings[0].date,
            ).format("YYYY-MM-DD")},${dayjs(performanceReview.siblings[0].date)
              .add(1, "year")
              .format("YYYY-MM-DD")}`}
            uuid={performanceReview?.user.uuid}
          />
          <div className="mt-3">
            <CompleteGoalButton
              performanceReview={performanceReview}
              setPerformanceReview={setPerformanceReview}
            />
          </div>
        </>
      );
    default:
      return null;
  }
};

const Steps = ({ performanceReview }: { performanceReview?: any }) => {
  const { uuid, page } = useParams<{ uuid: string; page: string }>();

  //Steps, 1. Employee Assessment, 2. Manager Assessment, 3. Together 4. Goal setting

  let steps =
    performanceReview?.number === 1
      ? [
          {
            page: "employee-assessment",
            title: "Employee Assessment",
            icon: performanceReview?.employee_answers.completed_at ? (
              <BiCheckCircle className="tx-30 tx-success" />
            ) : (
              <BiX className="tx-30 tx-danger" />
            ),
          },
          {
            page: "manager-assessment",
            title: "Manager Assessment",
            icon: performanceReview?.manager_answers?.completed_at ? (
              <BiCheckCircle className="tx-30 tx-success" />
            ) : (
              <BiX className="tx-30 tx-danger" />
            ),
          },
          {
            page: "overview",
            title: "Overview",
            icon: performanceReview?.acknowledged_at ? (
              <BiCheckCircle className="tx-30 tx-success" />
            ) : (
              <BiX className="tx-30 tx-danger" />
            ),
          },
          {
            page: "goals",
            title: "Goal Setting",
            icon: performanceReview?.goals_set_at ? (
              <BiCheckCircle className="tx-30 tx-success" />
            ) : (
              <BiX className="tx-30 tx-danger" />
            ),
          },
        ]
      : [
          {
            page: "check-in",
            title: "Check In",
            icon: performanceReview?.check_in_answers.completed_at ? (
              <BiCheckCircle className="tx-30 tx-success" />
            ) : (
              <BiX className="tx-30 tx-danger" />
            ),
          },
          {
            page: "goals",
            title: "Goal Setting",
            icon: performanceReview?.goals_set_at ? (
              <BiCheckCircle className="tx-30 tx-success" />
            ) : (
              <BiX className="tx-30 tx-danger" />
            ),
          },
        ];

  if (!performanceReview?.manager_answers) {
    steps = steps.filter(
      (step) => step.page !== "manager-assessment" && step.page !== "overview",
    );
  }

  return (
    <div className="row">
      {steps.map((step, index) => (
        <div className="col-lg-3 col-md-6" key={step.page}>
          <Link
            to={`/performance-reviews/${uuid}/${step.page}`}
            className="w-100"
          >
            <div
              className={`text-center tn-300 p-3 position-relative ${
                page === step.page ? "bg-white money rounded-lg" : ""
              }`}
            >
              {step.icon}
              <div>
                <p className="mb-0 text-muted mt-3">{step.title}</p>
              </div>

              <div
                className={`tn-500 ${
                  page === step.page ? "opacity-40" : "opacity-0"
                }`}
              >
                <BottomLogo size={20} left={130} bottom={10} />
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

const Header = ({ performanceReview }: { performanceReview: any }) => {
  const firstTwo = performanceReview?.user?.name?.split(" ").slice(0, 2) ?? [];
  const initials = firstTwo.map((word: string) => word[0]).join("");

  return (
    <div className="w-100 d-flex justify-content-center position-relative">
      <div className="position-absolute top-0 left-0 w-100 h-100  money-gradient polygon-clip" />

      <div className="z-10">
        <div className="mt-5 d-flex justify-content-center">
          <div
            className={`bg-grad-info shadow rounded-circle tx-white d-flex align-items-center justify-content-center tx-24`}
            style={{ width: 100, height: 100 }}
          >
            {initials}
          </div>
        </div>
        <div className="text-center mt-4">
          <p className="mb-0 tx-inverse mb-0 tx-20 ">
            {performanceReview?.user.name}
          </p>
          <p className="mt-1 mb-0">{performanceReview?.template.name}</p>
          <small className="mt-1 mb-0">
            {performanceReview?.number === 1 ? "Primary" : "Check In"}{" "}
            {performanceReview?.number === 1
              ? ""
              : performanceReview?.number - 1}
          </small>
        </div>
      </div>
    </div>
  );
};

const CompleteGoalButton = ({
  performanceReview,
  setPerformanceReview,
}: {
  performanceReview: any;
  setPerformanceReview: Function;
}) => {
  const { takeAction, loading } = useApi();

  if (performanceReview.goals_set_at) return null;

  return (
    <Button
      className={`${
        performanceReview.goals_set_at
          ? "bg-danger text-white"
          : "bg-white text-muted"
      }  w-100 mb-3 border`}
      onClick={() => {
        takeAction(
          performanceReview.goals_set_at ? "destroy" : "update",
          `/performance-reviews/${performanceReview.uuid}/goals`,
        )
          .then(({ data }: { data: any }) => {
            toast.success("Goals Updated");
            setPerformanceReview(data.data);
          })
          .catch(errorSwal);
      }}
    >
      Submit Goals.
    </Button>
  );
};

export default PerformanceReviewProfile;
