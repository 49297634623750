import React from "react";
import { useParams } from "react-router";
import { useAuth } from "../../context/auth-context";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import Edit from "./Edit";

const ShowPage = ({ cpir, setCpir }) => {
  const { page, uuid } = useParams();
  const { user } = useAuth();

  switch (page) {
    case "details":
      return <Edit initialValues={cpir} setCpir={setCpir} />;
    case "documents":
      return (
        <NewProfileDocuments
          model="App\Models\CPIR"
          documentableId={uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/cpirs/${uuid}`}
          homeUrl={`cpirs/${uuid}/documents`}
        />
      );
    default:
      return null;
  }
};

export default ShowPage;
