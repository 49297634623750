import React from "react";

const initials = (userName: string) => {
  const matches = userName.match(/\b(\w)/g);
  return matches?.join("");
};

interface InitialAvatarProps {
  userName: string;
  size?: number;
  fontSize?: number;
  id?: string;
  className?: string;
}

const InitialAvatar = ({
  userName,
  size = 40,
  fontSize = 16,
  id = undefined,
  className = "mg-r-5",
}: InitialAvatarProps) => {
  const halfSize = size / 2;

  return (
    <svg className={className} height={size} width={size} id={id}>
      <circle cx={halfSize} cy={halfSize} r={halfSize} fill="#c5c5c5" />
      <text
        fill="#ffffff"
        fontSize={fontSize}
        textAnchor="middle"
        x={halfSize}
        y={halfSize + 5}
      >
        {initials(userName)}
      </text>
    </svg>
  );
};

export default InitialAvatar;
