import React from "react";
import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { TemplateDocumentFieldFormProps } from "./TemplateDocumentFieldForm";
import { TemplateDocumentField } from "./templateDocumentTypes";

const TemplateDocumentFields = (
  props: TemplateDocumentFieldFormProps & {
    valid: boolean;
    submitting: boolean;
    submitButton?: JSX.Element;
  } & WrappedFieldArrayProps<TemplateDocumentField>,
) => {
  const { fields, sheets, variables, submitButton } = props;

  return (
    <>
      <FormHeader>Variable Fields</FormHeader>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={index}>
            <div className="col-lg-2 form-group">
              <Field
                component={RenderField}
                label="Cell"
                name={`${field}.cell`}
                required
                validate={required}
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                options={sheets.map((sheet) => ({
                  label: sheet,
                  value: sheet,
                }))}
                label="Sheet"
                name={`${field}.sheet`}
                required
                validate={required}
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                options={variables.map((variable) => ({
                  label: variable.name,
                  value: variable.template_variable,
                }))}
                label="Variable"
                name={`${field}.variable`}
                required
                validate={required}
              />
            </div>
            <div className="col-lg-2 form-group d-flex align-items-end">
              <Button
                color="danger"
                block
                outline
                onClick={() => fields.remove(index)}
              >
                Delete
              </Button>
            </div>
          </React.Fragment>
        );
      })}
      <div className="col-12 mt-3 d-flex">
        <Button
          color="primary"
          outline
          onClick={() =>
            fields.push({ sheet: sheets.length === 1 ? sheets[0] : undefined })
          }
        >
          Add Variable
        </Button>
        {submitButton ?? (
          <SubmitButton
            className="ms-auto"
            valid={props.valid}
            submitting={props.submitting ?? false}
          />
        )}
      </div>
    </>
  );
};

export default TemplateDocumentFields;
