import { MentionData, MentionPluginTheme } from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { ReactElement, MouseEvent } from "react";
import "draft-js/dist/Draft.css";

interface EntryComponentProps {
  className?: string;
  onMouseDown(event: MouseEvent): void;
  onMouseUp(event: MouseEvent): void;
  onMouseEnter(event: MouseEvent): void;
  role: string;
  id: string;
  'aria-selected'?: boolean | 'false' | 'true';
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
}

const Entry = (props: EntryComponentProps): ReactElement => {
  const {
    mention,
    theme,
    searchValue,
    isFocused,
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>
            {mention.name}
            {mention.type && <p className="mb-0">{mention.type}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entry;
