import { ChangeAction } from "redux-form";
import FormModal from "../utils/FormModal";
import RecurringJobFields from "./RecurringJobFields";

export enum TimeUnit {
  Initial,
  Days,
  Weeks,
  Months,
  Years,
}

interface RecurringJobFormModalProps {
  toggle: Function;
  modal: boolean;
  recurringFrequencyUnits: TimeUnit;
  onSubmit: Function;
  project: any;
  initialValues: any;
}

const RecurringJobFormModal = (props: RecurringJobFormModalProps) => {
  const { toggle, modal, onSubmit, project, initialValues } = props;

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Recurring Jobs"
      onSubmit={onSubmit}
      form="RecurringJobs"
      initialValues={initialValues}
    >
      {({ change }: { change: ChangeAction }) => {
        return (
          <RecurringJobFields
            form="RecurringJobs"
            project={project}
            change={change}
          />
        );
      }}
    </FormModal>
  );
};

export default RecurringJobFormModal;
