import { useLocation } from "react-router-dom";
import useApi from "../api/useApi";
import { useEffect, useRef, useState } from "react";
import {
  getDocument,
  GlobalWorkerOptions,
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
} from "pdfjs-dist";
import { POField } from "./purchaseOrderTypes";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.js";
import colors from "../../data/colors";
import AnalysedInvoiceInformation from "./AnalysedInvoiceInformation";
import { Button } from "reactstrap";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { BsDownload } from "react-icons/bs";

const AnalysedInvoice = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const path = query.get("path");
  const job_id = query.get("job_id");
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [pdf, setPdf] = useState<PDFDocumentProxy>();
  const [page, setPage] = useState(1);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [loadingTask, setLoadingTask] = useState<PDFDocumentLoadingTask>();

  const {
    data: analysedDocument,
    takeAction,
    setData: setAnalysedDocument,
  } = useApi("", {
    po_fields: [],
  });

  const { data: documentLink, setUrl } = useApi();

  useEffect(() => {
    GlobalWorkerOptions.workerSrc = pdfjsWorker;

    if (path) {
      setUrl(`path-url?path=${encodeURIComponent(path)}`);
    }

    checkAnalysis(job_id as string).then((data) => setAnalysedDocument(data));
  }, []);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (!e.metaKey && !e.ctrlKey) {
        return;
      }
      e.preventDefault();
      e.deltaY > 0 ? setScale(scale - 0.15) : setScale(scale + 0.15);
    };

    // Adding the event listener
    const div = divRef.current;
    if (!div) {
      return;
    }

    div.addEventListener("wheel", handleWheel, { passive: false });

    // Cleanup
    return () => {
      div.removeEventListener("wheel", handleWheel);
    };
  }, [scale]);

  useEffect(() => {
    if (!analysedDocument?.po_fields) {
      return;
    }

    const entry = analysedDocument?.po_fields[0];

    if (!entry) {
      return;
    }

    setPage(entry.PageNumber);
  }, [analysedDocument]);

  const checkAnalysis = (jobId: string) => {
    return new Promise<{ po_fields: POField }>((resolve, reject) => {
      const check = () => {
        takeAction("show", `analyse-purchase-orders/${jobId}`)
          .then(({ data }: { data: any }) => {
            if (data.data) {
              setLoading(false);
              resolve(data.data);
            } else {
              setTimeout(check, 1000);
            }
          })
          .catch(reject);
      };
      check();
    });
  };

  useEffect(() => {
    if (!documentLink) {
      return;
    }
    setLoadingTask(getDocument(documentLink));
  }, [documentLink]);

  const renderPdf = () => {
    // Initialize PDF.js
    if (!documentLink) {
      return;
    }

    if (analysedDocument?.po_fields?.length === 0) {
      return;
    }

    if (!loadingTask) {
      return;
    }

    loadingTask.promise.then((pdf) => {
      // Render the first page

      const entry = analysedDocument?.po_fields[page - 1];

      setPdf(pdf);
      pdf.getPage(page).then((page) => {
        const pdfCanvas = document.getElementById(
          "pdfCanvas",
        ) as HTMLCanvasElement;

        const context = pdfCanvas.getContext("2d") as CanvasRenderingContext2D;

        const viewport = page.getViewport({ scale: scale });
        pdfCanvas.height = viewport.height;
        pdfCanvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        page.render(renderContext).promise.then(() => {
          // PDF page rendered, now draw rectangles
          const drawingCanvas = document.getElementById(
            "drawingCanvas",
          ) as HTMLCanvasElement;
          const ctx = drawingCanvas.getContext(
            "2d",
          ) as CanvasRenderingContext2D;

          drawingCanvas.width = viewport.width;
          drawingCanvas.height = viewport.height;

          const labelBox = entry.LabelDetection.Geometry.BoundingBox;
          const valueBox = entry.ValueDetection.Geometry.BoundingBox;

          const rectanglePoints = [
            {
              x: labelBox.Left * viewport.width,
              y: labelBox.Top * viewport.height,
              width: labelBox.Width * viewport.width,
              height: labelBox.Height * viewport.height,
            },
            {
              x: valueBox.Left * viewport.width,
              y: valueBox.Top * viewport.height,
              width: valueBox.Width * viewport.width,
              height: valueBox.Height * viewport.height,
            },
          ];

          rectanglePoints.forEach((point) => {
            const padding = 5;

            ctx.rect(
              point.x - padding,
              point.y - padding,
              point.width + 2 * padding,
              point.height + 2 * padding,
            );

            ctx.strokeStyle = colors.info.border; // setting color to red

            ctx.lineWidth = 2; // setting line width
            ctx.stroke();
          });
        });
      });
    });
  };

  useEffect(() => {
    renderPdf();
  }, [analysedDocument, documentLink, scale, page, loadingTask]);

  return (
    <div className="d-flex flex-column align-items-center mt-5">
      <AnalysedInvoiceInformation
        setPage={setPage}
        analysedData={analysedDocument}
      />
      <div className="w-50 mt-5">
        <div className="d-flex align-items-end mb-3">
          <small className="mb-0">Hold ctrl/command + scroll to resize</small>
          <a
            href={documentLink}
            target="_blank"
            rel="noopener noreferrer"
            className="ms-auto"
          >
            <BsDownload className="tx-18 text-secondary" />
          </a>
        </div>
        <div
          ref={divRef}
          style={{ position: "relative", overflow: "auto", maxHeight: "100vh" }}
        >
          <canvas id="pdfCanvas"></canvas>
          <canvas
            id="drawingCanvas"
            style={{ position: "absolute", top: 0, left: 0 }}
          ></canvas>
        </div>
        <div className="mt-3 w-full d-flex justify-content-center align-items-center">
          <Button
            disabled={page === 1}
            color="link"
            size="sm"
            onClick={() => setPage(page - 1)}
          >
            <MdOutlineArrowBackIos className="tx-18" />
          </Button>
          <Button
            disabled={page === pdf?.numPages}
            color="link"
            size="sm"
            className="me-3"
            onClick={() => setPage(page + 1)}
          >
            <MdOutlineArrowForwardIos className="tx-18" />
          </Button>
          <div className="space-x-2 d-flex">
            <p className="mb-0">{page}</p>
            <p className="mb-0">/</p>
            <p className="mb-0">{pdf?.numPages}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysedInvoice;
