import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import buttonGroup from "../../utils/buttonGroup";
import renderField from "../../utils/renderField";
import removeComments from "./removeComments";

import PREPERATION_TYPES from "../../../data/preperation_types";
import SATISFACTION from "../../../data/site_inspection_satisfaction";
import AddCommentsField from "./addCommentsField";

const PreperationInspected = (props) => (
  <form onSubmit={props.handleSubmit}>
    <div className="row">
      <div className="form-group col-12">
        <h5 className="text-dark">Preparation Inspected</h5>
      </div>
      {PREPERATION_TYPES.map((type) => (
        <div className="form-group col-md-6">
          <Field
            name={`preperation.${type.name}`}
            label={type.label}
            component={buttonGroup}
            options={SATISFACTION}
            groupClass="w-100"
            buttonClass="w-100"
            onChange={() =>
              removeComments(type.name, props.dispatch, "preperation")
            }
          />
          {props[type.name] === "C" && (
            <div className="form-group mg-t-10">
              <Field
                placeholder="Comments..."
                name={`preperation.comments.${type.name}`}
                component={renderField}
              />
            </div>
          )}
          <AddCommentsField
            fieldInformation={type}
            fieldsToAdd={["backhoe_piers"]}
            namePrefix="preperation"
          />
        </div>
      ))}
    </div>
    <div className="row mg-y-20">
      <div className="col-6">
        <button
          className="btn w-100 bg-gray-400 tx-gray-800"
          type="button"
          onClick={props.previousPage}
        >
          <i className="icon ion-reply" /> Weather
        </button>
      </div>
      <div className="col-6">
        <button className="btn w-100 btn-primary" type="submit">
          Reinforcement <i className="icon ion-forward" />
        </button>
      </div>
    </div>
  </form>
);

const form = reduxForm({
  destroyOnUnmount: false,
  form: "addResidentialSiteInspection",
});

const selector = formValueSelector("addResidentialSiteInspection");

const mapStateToProps = (state) => {
  const toReturn = {};
  PREPERATION_TYPES.forEach((type) => {
    toReturn[type.name] = selector(state, `preperation.${type.name}`);
  });
  return toReturn;
};

export default connect(mapStateToProps, {})(form(PreperationInspected));
