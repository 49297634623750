import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import api from "../../api/api";
import { TestRequestIndex } from "../testRequestTypes";

const geTestRequestWorksheets = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(`test-request-worksheets?page=${pageParam}&filter[search]=${search}`)
    .then(({ data }) => data);
};

export default function useTestRequestWorksheets(
  search?: string,
): UseInfiniteQueryResult<{
  data: TestRequestIndex[];
}> {
  return useInfiniteQuery(
    ["test-request-worksheets", { search }],
    (pageParam) => geTestRequestWorksheets(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
