import { Link } from "react-router-dom";
import { DashboardH3, DashboardSubTitle } from "../utils/DashboardHeader";
import formatDate from "../utils/formatDate";
import riskDetails from "./risk";
import HeaderFooter from "../utils/HeaderFooter";

const ProfileHeader = ({ cpir }) => {
  const risk = riskDetails(cpir?.risk_score);

  const progressWidth = (cpir?.risk_score / 25) * 100;

  return (
    <div className="card card-profile mg-b-20 shadow-sm rounded-lg">
      <div className="card-body wrap-all">
        <div className="flex-grow-1">
          <div className="media-body">
            <DashboardH3>
              CPIR/{cpir?.number}
              {cpir?.title && ` - ${cpir?.title}`}
            </DashboardH3>
            <DashboardSubTitle>
              Job: <Link to={cpir?.job?.link}>{cpir?.job?.name}</Link>{" "}
            </DashboardSubTitle>
            <DashboardSubTitle>
              Raised by:{" "}
              <a href={`mailto:${cpir?.raised_by?.email}`}>
                {cpir?.raised_by?.name}
              </a>
            </DashboardSubTitle>
            <DashboardSubTitle>
              To be completed by:{" "}
              <a href={`mailto:${cpir?.raised_to?.email}`}>
                {cpir?.raised_to.name}
              </a>
            </DashboardSubTitle>
            <DashboardSubTitle>
              Raised to:{" "}
              <a href={`mailto:${cpir?.raised_to?.email}`}>
                {cpir?.raised_to?.subject}
              </a>
            </DashboardSubTitle>
            <p className="mg-b-10">
              <span
                className={`badge bg-${risk.colour}`}
              >{`${risk.name} Risk`}</span>
            </p>
            <p className="mg-b-0">Added on {formatDate(cpir?.created_at)}</p>
            <p className="mg-b-0">
              {!cpir?.job.actual_finish_date
                ? `Close out by ${formatDate(cpir?.job?.scheduled_finish_date)}`
                : `Closed on ${formatDate(cpir?.job?.actual_finish_date)}`}
            </p>
          </div>
        </div>
      </div>
      <HeaderFooter />

      <div className="card-footer">
        <div
          style={{ width: `${progressWidth}%` }}
          className={`progress-bar progress-bar-xs bg-${risk.colour}`}
          role="progressbar"
          aria-valuenow={progressWidth}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    </div>
  );
};

export default ProfileHeader;
