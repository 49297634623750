import React from "react";
import { InjectedFormProps } from "redux-form";
import FormDeliverable from "../deliverables/DeliverableForm";
import FormModal from "../utils/FormModal";
import { CustomJobJob } from "./jobs/customJobTypes";

interface GenerateDeliverableModalProps {
  job: CustomJobJob;
  modal: boolean;
  toggle: Function;
  onSubmit: Function;
}

const GenerateDeliverableModal = ({
  job,
  modal,
  toggle,
  onSubmit,
}: GenerateDeliverableModalProps) => {
  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      form="GenerateDeliverable"
      onSubmit={onSubmit}
      title="Generate Deliverable"
      initialValues={{ pricing: [{}] }}
    >
      {({ change }: InjectedFormProps) => (
        <FormDeliverable name job={job} change={change} />
      )}
    </FormModal>
  );
};

export default GenerateDeliverableModal;
