import React from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";
import FormHeader from "../utils/FormHeader";
import SelectInput from "../form/SelectInput";
import { BranchIndex } from "../branches/branchTypes";
import RenderField from "../utils/renderField";
import { UserIndex } from "../user/userTypes";
import { IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import filterOption from "../../utils/filterOption";

const EmailBranches = ({
  fields,
  branches,
}: WrappedFieldArrayProps & { branches: BranchIndex[] }) => {
  const { data: users }: IUseApiWithData<UserIndex[]> = useApi(
    "users?filter[employed]=true",
    [],
  );

  return (
    <>
      <FormHeader>Branches To Email</FormHeader>
      {fields.map((branch, index) => {
        return (
          <React.Fragment key={index}>
            <div className="form-group col-lg-6">
              <Field
                component={SelectInput}
                options={branches.map((b) => ({
                  label: (
                    <>
                      <p className="mb-0 text-dark">{b.name}</p>
                      <p className="mb-0 tx-12 text-muted">{b.number}</p>
                    </>
                  ),
                  value: b.uuid,
                  text: b.display_name,
                }))}
                isMulti
                filterOption={filterOption}
                name={`${branch}.branches`}
                label="Branches"
              />
            </div>
            <div className="form-group col-lg-6">
              <Field
                component={SelectInput}
                name={`${branch}.recipients`}
                label="Send To"
                options={users.map((u) => ({
                  label: (
                    <>
                      <p className="mb-0 text-dark">{u.full_name}</p>
                      <p className="mb-0 text-muted">
                        {u.secondary_email ?? u.email}
                      </p>
                    </>
                  ),
                  value: u.id,
                  text: u.full_name,
                }))}
                isMulti
                filterOption={filterOption}
              />
            </div>

            <div className="form-group col-lg-12">
              <Field
                component={RenderField}
                textarea
                name={`${branch}.email`}
                label="Email"
              />
            </div>
          </React.Fragment>
        );
      })}
      <div className="col-lg-12 form-group">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            fields.push({ email: "Hi,\n\nPlease see attached report." })
          }
        >
          Add Branch
        </button>
      </div>
    </>
  );
};

export default EmailBranches;
