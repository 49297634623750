import React from "react";
import { useParams } from "react-router-dom";
import { ProfileTab } from "../tabs/tabTypes";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";

interface ProfileProps<Pages> {
  header: JSX.Element;
  content: JSX.Element;
  tabs?: ProfileTab<Pages>[];
  sideBar?: JSX.Element | null;
}

const Profile = <Pages extends unknown>(props: ProfileProps<Pages>) => {
  const { header, content, tabs, sideBar } = props;
  const { page } = useParams<{ page: string }>();

  return (
    <div className="row">
      <div className="col-lg-8">
        {header}
        {content}
      </div>
      <div className="col-lg-4">
        {tabs && (
          <TabList>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  link={tab.link}
                  icon={tab.icon}
                  active={page === tab.page}
                >
                  {tab.label}
                </Tab>
              );
            })}
          </TabList>
        )}
        {sideBar}
      </div>
    </div>
  );
};

export default Profile;
