import React from "react";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import { useParams } from "react-router-dom";

const ProfileTabs = () => {
  const { page, uuid } = useParams();

  return (
    <TabList>
      <Tab
        link={`/skill-categories/${uuid}/details`}
        active={page === "details"}
      >
        Details
      </Tab>
      <Tab link={`/skill-categories/${uuid}/skills`} active={page === "skills"}>
        Skills
      </Tab>
    </TabList>
  );
};

export default ProfileTabs;
