import React from "react";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const ProfileHeader = ({ docket }) => {
  return (
    <DashboardHeader>
      <DashboardH3>Docket Number: {docket.name}</DashboardH3>
      <Link to={`/${docket.project_link}`}>{docket.project_name}</Link>
      <DashboardSubTitle>
        Created at: {dayjs(docket.created_at).format("DD/MM/YYYY HH:MM")}
      </DashboardSubTitle>
      <DashboardSubTitle>
        Technician:{" "}
        <a href={`mailto:${docket.technician.email}`}>
          {docket.technician.first_name} {docket.technician.last_name}
        </a>
      </DashboardSubTitle>
      <DashboardSubTitle>
        <span
          className={`badge ${docket.invoiced_at ? "bg-info" : "bg-warning"}`}
        >
          {docket.invoiced_at
            ? `Invoiced at: ${dayjs(docket.invoiced_at).format(
                "DD/MM/YYYY HH:mm",
              )}`
            : "Not Invoiced"}
        </span>
      </DashboardSubTitle>
    </DashboardHeader>
  );
};

export default ProfileHeader;
