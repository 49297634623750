import { useAuth } from "../../context/auth-context";
import HeaderPage, { HeaderPageProps } from "./HeaderPage";

const HeaderPageAdminFilter = (props: HeaderPageProps) => {
  const { user } = useAuth();

  const filteredCrumbs = user?.is_admin
    ? [...orgLink, ...props.crumbs]
    : props.crumbs;

  return (
    <HeaderPage
      titlePage={props.titlePage}
      pageDescription={props.pageDescription}
      relatedLinks={props.relatedLinks}
      crumbs={filteredCrumbs}
    />
  );
};

const orgLink = [
  {
    link: "/organisations/settings/links",
    name: "Organisation",
  },
];

export default HeaderPageAdminFilter;
