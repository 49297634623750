import { PayRun } from "./payRunTypes";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";

interface PayRunBranchesProps {
  payRun: PayRun;
  setPayRun: (payRun: PayRun) => void;
}

interface FormValues {
  branches: {
    label: string;
    value: number;
  }[];
}

const PayRunBranches = (
  props: PayRunBranchesProps &
    InjectedFormProps<FormValues, PayRunBranchesProps>,
) => {
  const { handleSubmit, payRun, setPayRun, error } = props;

  const { takeAction }: IUseApi<{}, { data: PayRun }> = useApi();

  const onSubmit: FormSubmitHandler<FormValues, PayRunBranchesProps> = (
    values,
  ) => {
    return takeAction("update", `pay-runs/${payRun.uuid}/branches`, {
      branches: values.branches.map((b) => b.value),
    })
      .then(({ data }) => {
        toast.success("Branches updated successfully");
        setPayRun(data.data);
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="form-group col-lg-12">
          <Field
            component={SelectInput}
            isMulti
            name="branches"
            label="Branches"
            url="organisation-branches"
            formatData={(data: any[]) =>
              data.map((d) => ({
                label: d.name,
                value: d.id,
              }))
            }
          />
          <small>Leave blank to include timesheets across all branches.</small>
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<FormValues, PayRunBranchesProps>({
  form: "payRunBranches",
});

export default form(PayRunBranches);
