import { connect } from "react-redux";
import { Field } from "redux-form";

import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import SelectInputAsync from "../utils/SelectInputAsync";

const getComponent = (type) => {
  switch (type) {
    case "select":
      return SelectInputAsync;
    case "boolean":
      return renderToggleInput;
    default:
      return renderField;
  }
};

const SettingsFields = ({ fields, initialValues, jobTypes }) => {
  return (
    <>
      {fields.map((field, index) => {
        const setting = initialValues.settings[index];
        if (
          setting.job_type &&
          !jobTypes.find((jobTypeType) => jobTypeType === setting.job_type)
        ) {
          return;
        }

        //Hot trash
        const isPrefix =
          setting.meta_key.substring(setting.meta_key.length - 6) ===
            "prefix" && setting.meta_key !== "job_prefix";

        const isBool = setting.form_details.type === "boolean";

        return (
          <>
            <div
              className={`col-lg-${
                setting.form_details.width ?? (isPrefix || isBool ? "6" : "12")
              } form-group`}
              key={index}
            >
              <Field
                component={getComponent(setting.form_details.type)}
                {...setting.form_details}
                name={`${field}.${setting.meta_key}`}
              />

              {setting.form_details.notes && (
                <p className="mb-0">
                  <small>{setting.form_details.notes}</small>
                </p>
              )}
            </div>
            {isPrefix && (
              <div className="col-lg-6 form-group">
                <Field
                  component={renderField}
                  name={`${field}.number_starts_at`}
                  label="Starts At"
                />
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => ({
  jobTypes: state.standardJobTypes.items,
});

export default connect(mapStateToProps, {})(SettingsFields);
