import React from "react";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { PenaltyRate } from "./penaltyRateTypes";
import ShowPenaltyRatePage from "./ShowPenaltyRatePage";

const PenaltyRateProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data,
    setData: setPenaltyRate,
    loading,
  }: IUseApi<PenaltyRate> = useApi(`/penalty-rates/${uuid}`, {}, true);

  const penaltyRate = data as PenaltyRate;

  const tabs: ProfileTab<"details" | "documents">[] = [
    {
      link: `/penalty-rates/${uuid}/details`,
      page: "details",
      label: "Details",
    },
    {
      link: `/penalty-rates/${uuid}/documents`,
      page: "documents",
      label: "Documents",
    },
  ];

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage="penalty-rates"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "penalty-rates", name: "Penalty Rates" },
          {
            link: "",
            name: penaltyRate?.name ?? penaltyRate.title,
            active: true,
          },
        ]}
      />
      <Profile
        tabs={tabs}
        content={
          <ShowPenaltyRatePage
            penaltyRate={penaltyRate}
            setPenaltyRate={setPenaltyRate}
          />
        }
        header={
          <DashboardHeader>
            <DashboardH3>{penaltyRate?.name ?? penaltyRate.title}</DashboardH3>
          </DashboardHeader>
        }
      />
    </>
  );
};

export default PenaltyRateProfile;
