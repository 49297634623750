import NotFound from "../404";
import { useParams } from "react-router-dom";
import Edit from "./Edit";
import EquipmentCalibrationTypes from "../equipmentCalibrationTypes/EquipmentCalibrationTypes";
import { useAuth } from "../../context/auth-context";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import ProfileForm from "../customFields/ProfileForm";

const ShowPage = (props) => {
  const { page, uuid } = useParams();
  const { user } = useAuth();

  const { ticketType, setTicketType } = props;

  switch (page) {
    case "details":
      return <Edit {...props} />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
