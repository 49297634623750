import dayjs from "dayjs";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import { destroyForm } from "../../actions/utilActions";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import ApprovalComments from "../approvals/ApprovalComments";
import SideList from "../comments/SideList";
import { formatNumber } from "../dashboard/OverviewCard";
import HeaderPage from "../header/HeaderPage";
import SyncIntegrationEstimation from "../purchases/SyncIntegrationEstimation";
import EditDate from "../recurringPaymentPurchases/EditDate";
import ApprovalStatus from "../user/ApprovalStatus";
import CostWidget from "../utils/CostWidget";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import InfoCard from "../utils/InfoCard";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import ArchiveButton from "./ArchiveButton";
import Buttons from "./Buttons";
import PurchaseItemApprovals from "./PurchaseItemApprovals";
import ShowPage from "./ShowPage";
import useUserGroup from "../hooks/useUserGroup";
import { IntegrationType } from "../integrations/integrationTypes";
import { GroupType } from "../user/userTypes";

const Profile = (props) => {
  const { uuid, page } = useParams();
  const { user } = useAuth();

  const {
    data: purchaseOrder,
    setData: setPurchaseOrder,
    loading,
    setUrl,
  } = useApi(`purchase-orders/${uuid}`, null, true, "/home");

  const isAccounting = useUserGroup("Accounting");

  useEffect(() => {
    return () => {
      props.destroyForm("EditPurchaseOrder");
    };
  }, []);

  useEffect(() => {
    setUrl(`purchase-orders/${uuid}`);
  }, [uuid]);

  if (loading) return <Spinner loading />;

  const valueInfo = [
    {
      title: "Purchase Order",
      label: "Value",
      value: `$${formatNumber(purchaseOrder.total_cost)} (Amount Excludes GST)`,
    },
  ];

  if (purchaseOrder.purchase_invoice.uuid) {
    valueInfo.push({
      title: "Purchase Invoice",
      label: "Value",
      value: `$${formatNumber(
        purchaseOrder.purchase_invoice.total_cost,
      )} (Amount Excludes GST)`,
    });
  }

  const crumbs = [{ link: "", name: purchaseOrder.name, active: true }];

  if (purchaseOrder.project?.name) {
    crumbs.unshift({
      link: `projects/${purchaseOrder.project.uuid}/purchases`,
      name: `${purchaseOrder.project.name} Purchases`,
    });
  }

  return (
    <>
      <HeaderPage titlePage={purchaseOrder.name} crumbs={crumbs} />
      <div className="row row-sm">
        <div className="col-lg-8">
          <DashboardHeader>
            <DashboardH3>
              {purchaseOrder.name}{" "}
              <PurchaseItemApprovals purchase={purchaseOrder} />
            </DashboardH3>

            <DashboardSubTitle>
              Supplier:{" "}
              <Link to={`/suppliers/${purchaseOrder.supplier.uuid}/details`}>
                {purchaseOrder.supplier.name}
              </Link>
            </DashboardSubTitle>
            <DashboardSubTitle>
              Project:{" "}
              {purchaseOrder.project?.name ? (
                <Link to={`/projects/${purchaseOrder.project.uuid}/purchases`}>
                  {purchaseOrder.project.name}
                </Link>
              ) : (
                "Unable to Find."
              )}
            </DashboardSubTitle>
            <DashboardSubTitle>
              Purchaser Name:{" "}
              <a href={`mailto:${purchaseOrder.user.email}`}>
                {purchaseOrder.user.name}
              </a>
            </DashboardSubTitle>
            <EditDate purchase={purchaseOrder} setPurchase={setPurchaseOrder} />
            <DashboardSubTitle>
              Created at:{" "}
              {dayjs(purchaseOrder.created_at).format("DD/MM/YYYY HH:mm")}
            </DashboardSubTitle>
            <DashboardSubTitle className="mg-t-10">
              {purchaseOrder.includes_gst_description}
            </DashboardSubTitle>
            <DashboardSubTitle>
              {purchaseOrder.purchase_invoice?.file?.link && (
                <div>
                  Last Invoice Uploaded By:{" "}
                  <a
                    href={`mailto:${purchaseOrder.purchase_invoice?.invoice_document.created_by?.email}`}
                  >
                    {
                      purchaseOrder.purchase_invoice?.invoice_document
                        .created_by?.name
                    }
                  </a>
                </div>
              )}
            </DashboardSubTitle>
            <DashboardSubTitle className="mg-t-10">
              <Badge
                className="mt-1 shadow rounded-pill"
                color={purchaseOrder.status.color}
              >
                {purchaseOrder.status.text}
              </Badge>
            </DashboardSubTitle>
            {purchaseOrder.archived_at && (
              <DashboardSubTitle>
                <Badge color="warning">
                  Archived at{" "}
                  {dayjs(purchaseOrder.archived_at).format("DD/MM/YYYY")}
                </Badge>
              </DashboardSubTitle>
            )}
          </DashboardHeader>
          <div className="d-block d-lg-none my-3">
            <ApprovalStatus
              status={purchaseOrder.approval_status}
              className="w-100 py-2"
            />
          </div>
          <Buttons
            {...props}
            purchaseOrder={purchaseOrder}
            setPurchaseOrder={setPurchaseOrder}
          />
          <div className="my-3">
            <ApprovalMessage purchaseOrder={purchaseOrder} />
          </div>
          <div className="d-lg-none mb-3">
            <CostWidget
              price={purchaseOrder.total_cost}
              name={purchaseOrder.name}
            />
            {purchaseOrder.purchase_invoice.uuid && (
              <CostWidget
                price={purchaseOrder.purchase_invoice.total_cost}
                name={`${purchaseOrder.name} Purchase Invoice`}
              />
            )}
          </div>

          <SyncIntegrationEstimation
            purchase={purchaseOrder}
            setPurchase={setPurchaseOrder}
          />

          <ShowPage
            setPurchaseOrder={setPurchaseOrder}
            purchaseOrder={purchaseOrder}
            {...props}
            isAccounting={isAccounting}
          />
        </div>

        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <TabList>
            <Tab
              link={`/purchase-orders/${uuid}/details`}
              active={page === "details"}
              icon="icon ion-clipboard text-capitalize"
            >
              Details
            </Tab>
            <Tab
              link={`/purchase-orders/${uuid}/quote`}
              active={page === "quote"}
              icon="icon ion-clipboard text-capitalize"
            >
              Quote
            </Tab>
            {purchaseOrder.sent_at &&
              (isAccounting ||
                purchaseOrder?.purchase_invoice?.approval_action?.user_id ==
                  user.id) && (
                <Tab
                  link={`/purchase-orders/${uuid}/purchase-invoice`}
                  active={page === "purchase-invoice"}
                  icon="icon ion-clipboard text-capitalize"
                >
                  Purchase Invoice
                </Tab>
              )}
            {purchaseOrder.sent_at &&
              purchaseOrder.purchase_invoice &&
              purchaseOrder.purchase_invoice.file.link && (
                <Tab
                  link={`/purchase-orders/${uuid}/invoice-file`}
                  active={page === "invoice-file"}
                  icon="icon ion-clipboard text-capitalize"
                >
                  Invoice File
                </Tab>
              )}
            {(user?.groups.includes(GroupType.Accounting) || user?.is_admin) &&
              user?.active_organisation.integration_types.includes(
                IntegrationType.Accounting,
              ) && (
                <Tab
                  link={`/purchase-orders/${uuid}/pre-payments`}
                  active={page === "pre-payments"}
                  icon="icon ion-clipboard text-capitalize"
                >
                  Pre Payments
                </Tab>
              )}
            {user.active_organisation.integration_types.includes(
              IntegrationType.Accounting,
            ) && (
              <Tab
                link={`/purchase-orders/${uuid}/loan`}
                active={page === "loan"}
                icon="icon ion-clipboard text-capitalize"
              >
                Loan
              </Tab>
            )}
          </TabList>

          <div className="d-none d-lg-block mt-3">
            <ApprovalStatus
              status={purchaseOrder.approval_status}
              className="w-100 py-2"
            />
          </div>
          {purchaseOrder.received_at && (
            <div className="d-none mt-3 d-lg-block">
              <PurchaseApprovalButton
                purchaseOrder={purchaseOrder}
                {...props}
              />
            </div>
          )}
          <ApprovalComments
            modelType="App\Models\Purchase"
            modelId={purchaseOrder.id}
          />
          <div className="d-none mt-3 d-lg-block">
            <InfoCard info={valueInfo} />
          </div>
          <ArchiveButton purchase={purchaseOrder} />
          <div className="mt-3">
            <SideList
              commentableType="App\Models\Purchase"
              commentableId={purchaseOrder.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ApprovalMessage = ({ purchaseOrder }) => {
  const approvalAction = purchaseOrder.purchase_invoice.approval_action;
  const { user } = useAuth();
  if (
    !approvalAction ||
    approvalAction.user_id !== user.id ||
    purchaseOrder.received_at
  )
    return null;
  return (
    <InformationAlert
      type="warning"
      body="Please Mark Purchase as Received before Approving Invoice"
      title="Action Required"
    />
  );
};

const PurchaseApprovalButton = (props) => {
  const { purchaseOrder } = props;

  const shouldValidate =
    purchaseOrder.total_cost - purchaseOrder.purchase_invoice.total_cost !== 0;
  return (
    <ApprovalButton
      {...props}
      approvalAction={purchaseOrder.purchase_invoice.approval_action}
      validateComments={shouldValidate}
    />
  );
};

export default connect(null, { destroyForm })(Profile);
