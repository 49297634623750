import React, { useRef, useEffect, TextareaHTMLAttributes } from "react";

interface AutoExpandingTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

const AutoExpandingTextArea: React.FC<AutoExpandingTextAreaProps> = (props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "inherit";
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [props.value]); // Recalculate height when the value changes

  return <textarea ref={textAreaRef} {...props} />;
};

export default AutoExpandingTextArea;
