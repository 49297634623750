import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getDocuments = (
  { pageParam = 1 },
  queryString: string,
  uuid?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(`documents/standard/${uuid ?? ""}?page=${pageParam}&${queryString}`)
    .then(({ data }) => data);
};

export default function useStandardDocuments(
  queryString: string,
  uuid?: string,
) {
  return useInfiniteQuery(
    ["standard-documents", { uuid, queryString }],
    (pageParam) => getDocuments(pageParam, queryString, uuid),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
