export default [
  { value: 'GW' },
  { value: 'GP' },
  { value: 'GC' },
  { value: 'GM' },
  { value: 'SW' },
  { value: 'SP' },
  { value: 'SM' },
  { value: 'SC' },
  { value: 'ML' },
  { value: '(CL, CI)' },
  { value: 'OL' },
  { value: 'MH' },
  { value: 'CH' },
  { value: 'OH' },
  { value: 'Pt' }
];