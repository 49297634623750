import { useParams } from "react-router-dom";
import { Integration } from "../integrations/integrationTypes";
import { WrappedOpenAIDetails } from "./OpenAIProfile";
import OpenAiAssistants from "./OpenAiAssistants";

type OpenAIPages = "details" | "assistants";

const ShowOpenAIPage = ({
  integration,
}: {
  integration: Integration<{ extra_info: { default_model: string } }>;
}) => {
  const { page } = useParams<{ page: OpenAIPages }>();

  switch (page) {
    case "details":
      return (
        <WrappedOpenAIDetails
          form="OpenAIDetails"
          integration={integration}
          /** @ts-ignore */
          initialValues={
            /** @ts-ignore */
            integration.extra_info?.default_model ? integration : {}
          }
        />
      );
    case "assistants":
      return <OpenAiAssistants integration={integration} />;
  }

  return <div>ShowOpenAIPage</div>;
};
export default ShowOpenAIPage;
