import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import UploadOverlay from "./UploadOverlay";
import UploadProgress from "./UploadProgress";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import { toast } from "react-toastify";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const CreateModal = (props) => {
  const { toggle, modal, title, folder, setFolder } = props;
  const { user } = useAuth();
  const { takeAction, loading, setLoading } = useApi();
  const { files, upload, setFiles } =
    useUploadMultipleToS3("documents/standard");

  const onDrop = (acceptedFiles) => {
    setLoading(true);
    return upload(acceptedFiles)
      .then((documents) =>
        takeAction("store", "/documents", {
          documents,
          folder_id: folder.id,
        }),
      )
      .then(({ data }) => {
        toast.success("Documents Uploaded");
        setFiles([]);
      });
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    disabled:
      !user.is_admin ||
      loading ||
      user.active_organisation.id !== folder.organisation_id,
    preventDropOnDocument: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <Modal
      backdrop="static"
      className="wd-md-850"
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Add files to {title}</ModalHeader>
      <ModalBody>
        <div className="my-10">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
            {!folder?.id || folder?.children?.length === 0 ? (
              <Empty
                folder={folder}
                isDragActive={isDragActive}
                isDragAccept={isDragAccept}
              />
            ) : (
              <UploadOverlay isDragActive={isDragActive} />
            )}
          </div>

          <UploadProgress files={files} />
        </div>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

CreateModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  setFolder: PropTypes.func.isRequired,
  folder: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_folder: PropTypes.bool,
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
};

export default CreateModal;
