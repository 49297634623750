import { AxiosError } from "axios";
import { SubmissionError } from "redux-form";

const formError = (err: AxiosError) => {
  if (err.response?.status === 422) {
    throw new SubmissionError({
      ...err.response.data.errors,
      _error: err.response.data.message,
    });
  }

  if (err.response?.status) {
    throw new SubmissionError({
      _error: err.response.data.message,
    });
  }
};

export default formError;
