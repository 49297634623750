import axios from "../api/api";
import { DELETE_CPIR } from "../actions/types";

export const deleteCPIR = (uuid) => (dispatch) => {
  return axios.delete(`/cpirs/${uuid}`).then(({ data }) =>
    dispatch({
      type: DELETE_CPIR,
      payload: data.data,
    }),
  );
};
