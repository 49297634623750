import React from "react";
import { Link } from "react-router-dom";
import { Badge, Progress, UncontrolledTooltip } from "reactstrap";
import Avatar from "../utils/Avatar";
import getBgColor from "../utils/getBgColor";
import { CPIR } from "./managementTypes";

const ManagementCPIR = ({ cpir }: { cpir: CPIR }) => {
  //

  return (
    <div className="bg-white border">
      <div className="p-3 bg-gray-100 border-bottom d-flex align-items-center">
        <Avatar
          name={cpir.user.name}
          colour={getBgColor((cpir?.risk_score / 12) * 100)}
        />
        <div className="w-75">
          <p
            id={`cpir_title_${cpir.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={cpir.link}>{cpir.title}</Link>
          </p>
          <p className="mb-0">CPIR/{cpir.number}</p>
        </div>
        <div className="ms-auto">
          {cpir.closed_at ? (
            <Badge color="secondary">Closed</Badge>
          ) : (
            <Badge color="warning">Open</Badge>
          )}
        </div>
      </div>
      <div className="p-3">
        <p className="mb-2">
          {cpir?.category?.name} CPIR raised to{" "}
          <a href={`mailto:${cpir.raised_to.email}`}>{cpir.raised_to.name}</a>
        </p>
        <Badge color={getBgColor((cpir?.risk_score / 12) * 100)}>
          {cpir.risk_level} Risk
        </Badge>
      </div>

      <Progress
        id={`progress_${cpir.uuid}`}
        value={(cpir?.risk_score / 12) * 100}
        color={getBgColor((cpir?.risk_score / 12) * 100)}
        className="progress-bar-xs"
        striped
        animated
      />
      <UncontrolledTooltip placement="top" target={`progress_${cpir.uuid}`}>
        {cpir.risk_level} Risk
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="top" target={`cpir_title_${cpir.uuid}`}>
        {cpir.title}
      </UncontrolledTooltip>
    </div>
  );
};

export default ManagementCPIR;
