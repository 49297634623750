import { useQuery } from "react-query";
import api from "../../api/api";

interface ProjectEquipment {
  uuid: string;
  name: string;
  disabled: boolean;
}

const getProjectEquipment = (): Promise<ProjectEquipment[]> => {
  return api.get("projects/equipment").then(({ data }) => data.data);
};

const useProjectEquipment = () => {
  return useQuery("project-equipment", getProjectEquipment);
};

export default useProjectEquipment;
