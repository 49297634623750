const required = (value) =>
  value === null || value === "" || value === undefined
    ? "Required"
    : undefined;

export default required;

export const exactLength = (length) => (value) =>
  value && value.length !== length
    ? `Must be exactly ${length} characters`
    : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
