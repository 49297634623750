import ProjectProjectFormsTable from "./Table";
import { Link, useParams } from "react-router-dom";
import ProjectProjectFormsForm from "./Form";
import swal from "sweetalert";
import useApi from "../../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../../utils/errorSwal";
import NotFound from "../../404";

const ProjectFormsTabsList = props => {
  const { project, setProject } = props;

  return (
    <>
      <ProjectFormTabs
        setProject={setProject}
        project={project}
      />
    </>
  );
};

const ProjectFormTabs = ({ setProject, project }) => {
  const { number, folderUuid: projectFormDetailPage } = useParams();

  return (
    <>
      <div className="nav-statistics-wrapper">
        <nav style={{ overflowX: "auto" }} className="nav flex-nowrap">
          <Link
            to={`/projects/${number}/project-forms`}
            className={`nav-link mb-3 ${!projectFormDetailPage ? "active" : ""}`}
          >
            Jobs
          </Link>
          <Link
            to={`/projects/${number}/project-forms/form`}
            className={`nav-link mb-3 ${
              projectFormDetailPage === "form" ? "active" : ""
            }`}
          >
            Details
          </Link>
        </nav>
      </div>
      <ProjectFormDetailsTab
        project={project}
        setProject={setProject}
      />
    </>
  );
};

const ProjectFormDetailsTab = ({ setProject, project }) => {
  const { folderUuid : projectFormDetailPage } = useParams();

  const { takeAction } = useApi();
  const { number } = useParams();

  const onSubmit = (values) => {
    return swal({
      icon: "info",
      title: "Update Project Forms?",
      text: `Updating this will remove the existing pending jobs associated with the removed project forms. Would you like to proceed?`,
      buttons: {
        cancel: {
          text: "No",
          value: null,
          closeModal: true,
          visible: true,
        },
        delete: {
          text: "Yes",
          value: true,
          closeModal: true,
          className: "btn btn-danger align-baseline",
        },
      },
    }).then((value) => {
      if (value) {
        return takeAction("update", `projects/${number}/projectProjectForm`, {
          project_forms: values.project_forms,
          project_type_id: values.project_type_id,
          organisation_division_id: values.organisation_division_id,
        })
          .then(({ data }) => {
            toast.success(`Project Forms Updated!`);
            setProject(data.data);
          })
          .catch(errorSwal);
      }
    });
  }

  switch (projectFormDetailPage) {
    case undefined:
      return (
        <ProjectProjectFormsTable />
      );
    case "form":
      return (
        <ProjectProjectFormsForm
          onSubmit={onSubmit}
          estimatedProjectValue= {project.estimated_value}
          initialValues={{
            project_type_id: project.project_type_id,
            organisation_divisions: project.organisation_divisions,
            project_form_toggle_fields: project.project_form_toggle_fields
          }}
          />
      );
    default:
      return <NotFound />;
  }
};

export default ProjectFormsTabsList;
