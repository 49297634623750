import React from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import FormOrganisationRole from "./Form";

const AddOrganisationRole = (props) => {
  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("store", "organisation-roles", values)
      .then(({ data }) => {
        toast.success("Organisation role created successfully!");
        props.history.push(
          `/organisation-roles/${data.data.organisationRole.uuid}/details`,
        );
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Role"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "organisation-roles", name: "Roles" },
          {
            link: "organisation-roles/add",
            name: "New Role",
            active: true,
          },
        ]}
      />
      <FormOrganisationRole {...props} onSubmit={onSubmit} showCompetencies />
    </>
  );
};

export default AddOrganisationRole;
