import ReactLinkify from "react-linkify";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";

const ReadyOnly = (props) => {
  const { required, label, text, input, information } = props;

  return (
    <>
      {label && (
        <label className="form-control-label d-block tx-inverse tx-semibold">
          {label}
          {required ? <span className="tx-danger"> *</span> : ""}
          <FieldInformationPopOver information={information} input={input} />
        </label>
      )}
      {text && (
        <div
          className="border-0 bg-transparent shadow-none white-space"
          style={{ height: "auto" }}
        >
          <ReactLinkify
            properties={{ target: "_blank", rel: "noopener noreferrer" }}
          >
            {text}
          </ReactLinkify>
        </div>
      )}
      {input?.value && (
        <div
          className="border-0 bg-transparent shadow-none white-space"
          style={{ height: "auto" }}
        >
          <ReactLinkify
            properties={{ target: "_blank", rel: "noopener noreferrer" }}
          >
            {input.value}
          </ReactLinkify>
        </div>
      )}
    </>
  );
};

export default ReadyOnly;
