import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getProjectJobs = (
  { pageParam = "initial" },
  search: string,
  number?: string,
) => {
  if (!pageParam) {
    return;
  }

  const jobType = encodeURIComponent("App\Models\ProjectForm");

  return api
    .get(
      `projects/${number}/projectFormJobs?cursor=${
        pageParam === "initial" ? "" : pageParam
      }&filter[search]=${search}&paginate=15`,
    )
    .then(({ data }) => data);
};

export default function useProjectJobs(search: string, number?: string) {
  return useInfiniteQuery(
    ["project-jobs", { search, number }],
    (pageParam) => getProjectJobs(pageParam, search, number),
    {
      getNextPageParam: (page) => {
        if (!page?.meta?.next_cursor) {
          return;
        }
        return page?.meta?.next_cursor;
      },
    },
  );
}
