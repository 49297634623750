import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import formError from "../../utils/formError";
import Form from "./Form";

const AddJobType = () => {
  const { takeAction } = useApi();

  const history = useHistory();

  const queryClient = useQueryClient();

  const onSubmit = (values) => {
    return takeAction("store", "jobtypes", values)
      .then(() => {
        queryClient.invalidateQueries();
        toast.success("Job type created successfully!");
        history.push("/jobtypes");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Job Type"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "jobtypes", name: "Job Types" },
          { link: "jobtypes/add", name: "New Job Type", active: true },
        ]}
      />
      <Form onSubmit={onSubmit} />
    </>
  );
};

export default AddJobType;
