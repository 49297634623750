import dayjs from "dayjs";
import { createBrowserHistory } from "history";
import React, { createContext, useEffect, useState } from "react";
import { FormSubmitHandler } from "redux-form";
import {
  getUser,
  ILoginValues,
  login as authLogin,
  logout as authLogout,
  register,
} from "../api/auth";
import userHasAccessTo from "../components/accessControlList/userHasAccessTo";
import { useWindowEvent } from "../components/hooks/useWindowEvent";
import { AuthUser } from "../components/user/userTypes";
import Spinner from "../components/utils/Spinner";

export const AuthContext = createContext<{
  user?: AuthUser;
  login: FormSubmitHandler<ILoginValues>;
  logout: () => void;
  register: () => void;
  setUser: (user: AuthUser) => void;
  sessionExpiry: number;
  refresh: Function;
  onLineStatus: boolean;
  checkUser: () => void;
}>({
  login: () => {},
  logout: () => {},
  register: () => {},
  setUser: () => {},
  sessionExpiry: dayjs().add(2, "hours").unix(),
  refresh: () => {},
  onLineStatus: true,
  checkUser: () => {},
});

const history = createBrowserHistory();

const AuthProvider = (props: any) => {
  const [user, setUser] = useState<AuthUser>();
  const [loading, setLoading] = useState<boolean>(true);
  const [onLineStatus, setOnLineStatus] = useState(true);

  const [sessionExpiry, setSessionExpiry] = useState(
    dayjs().add(2, "hours").unix(),
  );

  useEffect(() => {
    const checker = setInterval(() => {
      if (onLineStatus) {
        return;
      }

      checkUser();
    }, 2500);

    return () => clearInterval(checker);
  }, [onLineStatus]);

  const login: FormSubmitHandler<ILoginValues> = (values: any) =>
    authLogin(values).then((authUser) => {
      if ("two_factor" in authUser || "two_factor_required" in authUser) {
        return authUser;
      }

      setUser({
        ...authUser,
        hasAccessTo: (model: string, method: string) => {
          return userHasAccessTo(authUser, model, method);
        },
      });

      return authUser;
    });

  const checkUser = () => {
    return getUser()
      .then((authUser) => {
        setOnLineStatus(true);
        setUser({
          ...authUser,
          hasAccessTo: (model: string, method: string) => {
            return userHasAccessTo(authUser, model, method);
          },
        });
        setLoading(false);

        return authUser;
      })

      .catch((err: any) => {
        setLoading(false);

        if (err.message === "Network Error") {
          setOnLineStatus(false);
          return;
        }

        setUser(undefined);
      });
  };

  const refresh = (): Promise<"refreshed" | "checked"> => {
    setSessionExpiry(dayjs().add(2, "hours").unix());

    return Promise.resolve("checked");
  };

  useWindowEvent(
    "click",
    () => {
      refresh();
    },
    [user, sessionExpiry],
  );

  useWindowEvent(
    "focus",
    () => {
      checkUser().then(refresh);
    },
    [user, sessionExpiry],
  );

  useEffect(() => {
    checkUser();
  }, []);

  const logout = () => {
    authLogout().then(() => {
      setUser(undefined);
      history.push("/login");
      history.goForward();
    });
  };

  if (loading)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Spinner loading />
      </div>
    );

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        register,
        setUser: (user) => {
          setUser(
            user
              ? {
                  ...user,
                  hasAccessTo: (model: string, method: string) => {
                    return userHasAccessTo(user, model, method);
                  },
                }
              : undefined,
          );
        },
        sessionExpiry,
        refresh,
        onLineStatus,
        checkUser,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
