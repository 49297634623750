import React from "react";
import SpreadPrice from "../utils/SpreadPrice";

export const formatNumber = (number) => {
  if (number == 0 || !number) return "-";
  number = Math.round((number + Number.EPSILON) * 100) / 100;
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return parseFloat(number).toFixed(2);
};

export const getPrice = (number) => {
  const num = formatNumber(number);
  if (num === "-") return num;
  return `$${num}`;
};

const OverviewCard = ({ dashboard, showWages = true }) => {
  return (
    <div className="card card-dash-one mg-t-20">
      <div className="row no-gutters">
        <div className={`col-lg-${showWages ? "4" : "6"}`}>
          <i className="icon ion-ios-analytics-outline" />
          <div className="dash-content w-100">
            <label className="tx-primary w-100 d-flex">
              <span className="ms-auto">Invoiced</span>
            </label>
            <h2>
              <SpreadPrice price={dashboard.total.total_invoiced} />
            </h2>
          </div>
        </div>
        <div className={`col-lg-${showWages ? "4" : "6"}`}>
          <i className="icon ion-ios-pie-outline" />
          <div className="dash-content w-100">
            <label className="tx-success w-100 d-flex">
              <span className="ms-auto">WIP</span>
            </label>
            <h2>
              <SpreadPrice price={dashboard.total.wip} />
            </h2>
          </div>
        </div>
        {showWages && (
          <div className="col-lg-4">
            <i className="icon ion-ios-world-outline" />
            <div className="dash-content w-100">
              <label className="tx-danger w-100 d-flex">
                <span className="ms-auto">Wages</span>
              </label>
              <h2>
                <SpreadPrice price={dashboard.total.total_wages} />
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewCard;
