const density_specs = [
  {
    value: "-",
    label: "-",
  },
  {
    value: "60%",
    label: "60%",
  },
  {
    value: "65%",
    label: "65%",
  },
  {
    value: "70%",
    label: "70%",
  },
  {
    value: "90%",
    label: "90%",
  },
  {
    value: "92% - 95%",
    label: "92% - 95%",
  },
  {
    value: "93%",
    label: "93%",
  },
  {
    value: "95%",
    label: "95%",
  },
  {
    value: "96%",
    label: "96%",
  },
  {
    value: "97%",
    label: "97%",
  },
  {
    value: "98%",
    label: "98%",
  },
  {
    value: "100%",
    label: "100%",
  },
  {
    value: "102%",
    label: "102%",
  },
  {
    value: "60% - 80%",
    label: "60% - 80%",
  },
  {
    value: ">92%",
    label: ">92%",
  },
  {
    value: "99%",
    label: "99%",
  },
];

export default density_specs;
