import { useHistory } from "react-router-dom";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import ProfileButtons from "../utils/ProfileButtons";
import isSubmitting from "../utils/submitting";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import useModal from "../hooks/useModal";
import { Field } from "redux-form";
import { toast } from "react-toastify";
import PaginatedSelectInput from "../form/PaginatedSelectInput";
import { useAuth } from "../../context/auth-context";

const Add = ({ project, invoiceUrl = "", creditNoteUrl = "" }) => {
  const { takeAction, loading } = useApi();

  const { toggle, modal } = useModal();
  const history = useHistory();

  const buttons = [
    {
      text: (
        <>
          <i className="icon ion-plus tx-primary" />{" "}
          {isSubmitting(loading, "Add Invoice", "Adding...")}
        </>
      ),
      onClick: () => addInvoice(invoiceUrl || "invoices"),
      disabled: loading,
    },

    {
      text: (
        <>
          <i className="icon ion-plus tx-primary" />{" "}
          {isSubmitting(loading, "Add Credit Note", "Adding...")}
        </>
      ),
      onClick: () => toggle(),
      disabled: loading,
    },
  ];

  const addInvoice = (style = "invoices") => {
    return takeAction("store", `projects/${project.uuid}/${style}`)
      .then(({ data }) => {
        toast.success("Invoice added successfully");
        history.push(data.data.link);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <ProfileButtons buttons={buttons} />
      <CreditNoteModal project={project} toggle={toggle} modal={modal} />
    </>
  );
};

const CreditNoteModal = ({ project, toggle, modal }) => {
  const { takeAction, loading } = useApi();

  const { user } = useAuth();

  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", `projects/${project.uuid}/credit-notes`, values)
      .then(({ data }) => {
        history.push(data.data.link);
      })
      .catch(errorSwal);
  };

  const interEntityFilter =
    project.organisation.id != user.active_organisation.id
      ? `filter[inter_entity]=true`
      : "filter[client_id]=${project.client.id}";

  return (
    <FormModal
      title="Add Credit Note"
      form="AddCreditNote"
      onSubmit={onSubmit}
      modal={modal}
      toggle={toggle}
    >
      <div className="col-12 form-group">
        <Field
          component={PaginatedSelectInput}
          name="allocated_invoice_id"
          label="Invoice to Allocate Credit Note To"
          validate={required}
          required
          url={`projects/${project.uuid}/invoices?&filter[is_credit_note]=false&${interEntityFilter}`}
          formatData={(data) =>
            data.map((d) => ({ label: d.name, value: d.id }))
          }
        />
      </div>
    </FormModal>
  );
};

export default Add;
