import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import isSubmitting from "../utils/submitting";
import generatePayRunCSV from "./generatePayRunCSV";

const ProfileButton = () => {
  const [generatingCSV, setGeneratingCSV] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [number, setNumber] = React.useState("");
  const { uuid } = useParams();
  const { user } = useAuth();

  const downloadXero = (number) => {
    setGeneratingCSV(true);
    generatePayRunCSV(uuid, number).then(() => {
      setIsOpen(false);
      setGeneratingCSV(false);
      setNumber("");
    });
  };

  if (!user.active_organisation.beta) {
    return null;
  }

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      <li className="nav-item">
        <div className={`dropdown ${isOpen ? "show" : ""}`}>
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="pointer-hover w-100 nav-link"
            disabled={generatingCSV}
          >
            <Fragment>
              <i className="icon ion-ios-cloud-download tx-info" />
              Download CSV
            </Fragment>
          </button>
          <DropDown
            isOpen={isOpen}
            download={downloadXero}
            generatingCSV={generatingCSV}
            number={number}
            setNumber={setNumber}
          />
        </div>
      </li>
    </ul>
  );
};

const DropDown = ({ isOpen, download, generatingCSV, number, setNumber }) => {
  return (
    <div
      className={`dropdown-menu pd-30 pd-sm-40 wd-sm-350 ${
        isOpen ? "show" : ""
      }`}
    >
      <h6 className="tx-gray-800 tx-uppercase tx-bold">
        Current Highest Invoice Number
      </h6>

      <div className="form-group">
        <input
          type="number"
          className="form-control pd-y-12"
          placeholder="Number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
      </div>

      <button
        onClick={() => download(number)}
        className="btn btn-primary w-100 pd-y-10 mg-b-30"
        disabled={generatingCSV}
      >
        {isSubmitting(generatingCSV, "Download", "Generating...")}
      </button>
    </div>
  );
};

export default ProfileButton;
