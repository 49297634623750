import React from "react";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import { PurchaseOrder } from "./purchaseOrderTypes";

const PurchaseItemApprovals = ({ purchase }: { purchase: PurchaseOrder }) => {
  const unapprovedItems = purchase.purchase_items.filter((item) => {
    return item.over_budget_approval_action;
  });

  if (unapprovedItems.length === 0) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        className="bg-transparent border-0 p-0"
        id="over_budget_items"
      >
        <i className="fa tx-16 fa-info-circle tx-inverse" />
      </button>
      <UncontrolledPopover target="over_budget_items">
        <PopoverHeader>Unapproved Items Over Budget</PopoverHeader>
        <PopoverBody>
          <div className="space-y-2">
            {unapprovedItems.map((item) => {
              return <p className="mb-0">{item.description}</p>;
            })}
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default PurchaseItemApprovals;
