import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { useUserTimesheet } from "../../context/timesheet-context";
import useApi from "../api/useApi";
import { EmploymentDetail } from "../employmentDetails/employmentDetailTypes";
import { RoleType } from "../home/OverallProductivityOverview";
import ProductivityOverview from "../home/ProductivityOverview";
import {
  amountOfHours,
  calculateProgressColor,
  getHours,
  productivityStatus,
} from "./functions/timesheetFunctions";
import {
  BreakType,
  ProductivityEvent,
  ProductivityOverviewType,
  ProductivityStatus,
} from "./timesheetTypes";

interface CurrentTimesheetProductivityOverviewProps {
  events: ProductivityEvent[];
  addTimesheet: boolean;
  startTime?: string;
  finishTime?: string;
  nightShift?: boolean;
  unpaidBreak?: number;
  roleId?: number;
  userUuid: string;
  form: string;
}

const CurrentTimesheetProductivityOverview = ({
  addTimesheet = true,
  startTime,
  finishTime,
  nightShift,
  unpaidBreak,
  roleId,
  userUuid,
}: CurrentTimesheetProductivityOverviewProps) => {
  const { data: roles } = useApi(
    `current-user-staff-roles/${userUuid}`,
    [],
    true,
  );

  const { productivity } = useUserTimesheet();

  const currentRole: RoleType = roles?.find((staffRole: EmploymentDetail) => {
    return staffRole.id === roleId;
  })?.staffRole;

  const productivityOverviewData: ProductivityOverviewType = {
    timesheets: getHours(startTime, finishTime, nightShift, unpaidBreak),
    billable_productivity: amountOfHours(
      productivity.filter((event) => event?.job_id && !event.internal),
    ),
    non_billable_productivity: amountOfHours(
      productivity.filter((event) => event?.job_id && event.internal),
    ),
    break_productivity: amountOfHours(
      productivity.filter((event) =>
        // @ts-ignore
        [BreakType.Paid, BreakType.Unpaid].includes(event?.break_type),
      ),
    ),
    total_productivity: amountOfHours(productivity),
  };

  if (!addTimesheet) {
    return null;
  }

  return (
    <ProductivityOverview
      {...productivityOverviewData}
      width={12}
      hoursAddedText="Hours Added."
      billableColor={(percent) => {
        const status = productivityStatus(percent, currentRole);
        return calculateProgressColor(status);
      }}
      extraInfo={(infoProps: any) => {
        return (
          <ExtraInfo
            status={productivityStatus(
              infoProps.billablePercentage,
              currentRole,
            )}
            role={currentRole}
          />
        );
      }}
    />
  );
};

export const ExtraInfo = ({
  status,
  role,
}: {
  status: ProductivityStatus;
  role: RoleType;
}) => {
  if (!role?.id) {
    return null;
  }

  return (
    <>
      {status === ProductivityStatus.ABOVE && (
        <p className="tx-danger mb-0">
          The Billable percentage is over maximum productivity
        </p>
      )}
      {status === ProductivityStatus.BELOW && (
        <p className="tx-danger mb-0">
          The Billable percentage is under required productivity
        </p>
      )}
      {role.position?.maximum_productivity &&
        role.position?.minimum_productivity && (
          <small className="mb-2">
            Target Billable Productivity between{" "}
            {role.position.minimum_productivity}% and{" "}
            {role.position.maximum_productivity}%.
          </small>
        )}
    </>
  );
};

const mapStateToProps = (
  state: any,
  props: CurrentTimesheetProductivityOverviewProps,
) => {
  const selector = formValueSelector(props.form);

  return {
    startTime: selector(state, "start_time"),
    finishTime: selector(state, "finish_time"),
    nightShift: selector(state, "night_shift"),
    unpaidBreak: selector(state, "unpaid_break"),
    roleId: selector(state, "role_id"),
  };
};

export default connect(
  mapStateToProps,
  {},
)(CurrentTimesheetProductivityOverview);
