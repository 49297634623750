import axios from "../api/api";
import {
  DELETE_NOTICEBOARDS,
  FETCH_NOTICEBOARDS,
  NEW_NOTICEBOARDS,
  UPDATE_NOTICEBOARDS,
} from "./types";

export const fetchNoticeboards =
  (id = "") =>
  (dispatch) => {
    return axios
      .get(`/noticeboards/${id}`)
      .then((response) => response.data.data)
      .then((noticeboards) =>
        dispatch({
          type: FETCH_NOTICEBOARDS,
          payload: noticeboards,
        }),
      );
  };

export const updateNoticeboard = (postData) => (dispatch) => {
  return axios
    .put(`/noticeboards/${postData.id}`, postData)
    .then((noticeboard) =>
      dispatch({
        type: UPDATE_NOTICEBOARDS,
        payload: noticeboard,
      }),
    );
};

export const createNoticeboard = (postData) => (dispatch) => {
  return axios.post(`/noticeboards/`, postData).then(({ data }) =>
    dispatch({
      type: NEW_NOTICEBOARDS,
      payload: data.data,
    }),
  );
};

export const deleteNoticeboard = (uuid) => (dispatch) => {
  return axios.delete(`/noticeboards/${uuid}`).then((noticeboard) =>
    dispatch({
      type: DELETE_NOTICEBOARDS,
      payload: uuid,
    }),
  );
};
