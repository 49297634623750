import React, { useEffect, useState } from "react";
import useApi from "../api/useApi";
import CpirTable from "../CPIRs/CpirTable";
import PaginatedList from "../pagination/PaginatedList";
import useBranchCpirs from "./hooks/useBranchCpirs";
import { baseCpirFilters } from "../managementDashboard/ManagementCPIRs";

const BranchCpirs = ({ branch }) => {
  const subject_type = "App\\Models\\OrganisationBranch";
  const subject_id = branch.id;

  const { data: cpirCategories, loading } = useApi("cpir-categories", []);

  const [cpirFilters, setCpirFilters] = useState(baseCpirFilters);

  useEffect(() => {
    setCpirFilters([
      ...baseCpirFilters,
      {
        label: "Category",
        name: "cpir_categories.name",
        options: cpirCategories.map((category) => {
          return {
            label: category.name,
            value: category.name,
          };
        }),
      },
    ]);
  }, [cpirCategories]);

  return (
    <PaginatedList
      listName="branchCpirsList"
      indexHook={useBranchCpirs}
      indexHookArguments={[subject_id, subject_type]}
      originalFilters={cpirFilters}
      list={({ data }) => (
        <div className="col-12">
          <CpirTable cpirs={data} loading={loading} />
        </div>
      )}
    />
  );
};

export default BranchCpirs;
