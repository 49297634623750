import Avatar from "../utils/Avatar";
import { AiFillEye } from "react-icons/ai";
import { UncontrolledTooltip } from "reactstrap";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import ProfileViewLogModal from "./ProfileViewLogModal";

const ProfileViewsList = (props) => {
  const { viewable, viewable_type } = props;
  const viewable_id = viewable.id;

  const { modal: profileViewLogModal, toggle: toggleProfileViewLogVersion } =
    useModal();

  const { loading, data: profileViews } = useApi(
    `profiles/views?viewable_type=${encodeURIComponent(
      viewable_type,
    )}&viewable_id=${viewable_id}&profile=true`,
    "",
    true,
  );

  if (loading) {
    return null;
  }

  const colors = ["secondary", "success", "warning", "info"];

  return (
    <>
      <div className="mb-2 d-flex justify-content-end">
        {profileViews && (
          <>
            {profileViews?.map((view, index) => {
              return (
                <div key={index} id={`profile_view_${view.user.id}`}>
                  <Avatar
                    name={view.user.full_name}
                    colour={colors[index % 4] ?? "info"}
                    rounded="-circle"
                    size="30px"
                    tooltip={false}
                  />
                  <UncontrolledTooltip target={`profile_view_${view.user.id}`}>
                    <div className="text-center">
                      {view.user.full_name}
                      <br />
                      Viewed {view.count} times
                    </div>
                  </UncontrolledTooltip>
                </div>
              );
            })}
            <button
              onClick={toggleProfileViewLogVersion}
              className="btn btn-sm m-0 p-0 rounded-circle border border-2 p-1"
              id="profile-view-more"
            >
              <AiFillEye style={{ fontSize: "20px" }} />
            </button>
            <UncontrolledTooltip target="profile-view-more">
              View All
            </UncontrolledTooltip>
          </>
        )}
      </div>
      <ProfileViewLogModal
        modal={profileViewLogModal}
        toggle={toggleProfileViewLogVersion}
        viewable={viewable}
        viewable_type={viewable_type}
      />
    </>
  );
};

export default ProfileViewsList;
