import React from "react";
import { toast } from "react-toastify";
import Toggle from "react-toggle";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";

const RequiresRenewalToggle = ({
  competency,
  setStaffCompetencies,
  staffCompetencies,
}) => {
  const { takeAction, loading } = useApi();
  const { user } = useAuth();

  const toggle = (e) => {
    e.preventDefault();

    takeAction("update", `/staff-competencies/${competency.uuid}/required`, {
      requires_renewal: !competency.requires_renewal,
    })
      .then(({ data }) => {
        setStaffCompetencies(
          staffCompetencies.map((c) =>
            c.uuid === data.data.uuid ? data.data : c,
          ),
        );
        toast.success("Competency updated");
      })
      .catch(errorSwal);
  };

  if (!user.is_admin || (!competency.renew_on && competency.requires_renewal)) {
    return null;
  }

  return (
    <div className="ms-auto text-end">
      <Toggle
        onClick={toggle}
        checked={competency.requires_renewal}
        id={`competency-${competency.uuid}`}
        disabled={competency.deleted_at}
      />
      <label
        htmlFor={`competency-${competency.uuid}`}
        className="d-block text-secondary tx-12 mb-0"
      >
        Requires Renewal {loading && "(Updating...)"}
      </label>
    </div>
  );
};
export default RequiresRenewalToggle;
