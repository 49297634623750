import React from "react";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import PenaltyRateForm from "./PenaltyRateForm";
import { PenaltyRate } from "./penaltyRateTypes";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import errorSwal from "../utils/errorSwal";
import { ProjectAllowanceEnum } from "../projects/projectTypes";

const AddPenaltyRate = () => {
  const { takeAction }: IUseApi<{}, PenaltyRate> = useApi();

  const history = useHistory();

  const onSubmit: FormSubmitHandler<PenaltyRate> = (values) => {
    return takeAction("store", `/penalty-rates`, values)
      .then(() => {
        toast.success("Penalty Rate Added");
        history.push("/penalty-rates");
      })
      .catch((err) => {
        errorSwal(err);

        return formError(err);
      });
  };

  return (
    <>
      <HeaderPage
        titlePage="Add Penalty Rate"
        crumbs={[
          {
            link: "organisations/settings/links",
            name: "Organisation",
          },
          { link: "/penalty-rates", name: "Penalty Rates" },
          {
            link: "/penalty-rates/add",
            name: "Add Penalty Rate",
            active: true,
          },
        ]}
      />
      <PenaltyRateForm
        form="AddPenaltyRate"
        onSubmit={onSubmit}
        initialValues={{
          hours: [{ rate: 1 }],
          allowances: [{ name: "", amount: 0, unit: ProjectAllowanceEnum.Day }],
        }}
      />
    </>
  );
};

export default AddPenaltyRate;
