import { Button } from "reactstrap";
import { FieldArrayFieldsProps, WrappedFieldArrayProps } from "redux-form";
import EquipmentTypeChildrenFields from "./EquipmentTypeChildrenFields";
import useApi from "../api/useApi";
import isSubmitting from "../utils/submitting";

const EquipmentTypeFieldWrapper = (
  props: WrappedFieldArrayProps & {
    form: string;
    equipmentTypes: any[];
    index: number;
    parentFields: FieldArrayFieldsProps<any>;
  },
) => {
  const { fields, form, equipmentTypes, index, parentFields } = props;

  const { takeAction, loading } = useApi();

  const parentValue = parentFields.get(index);

  const removeParent = () => {
    if (parentValue.uuid) {
      return takeAction(
        "destroy",
        `test-worksheet-equipment-types/${parentValue.uuid}`,
      ).then(() => {
        parentFields.remove(index);
      });
    }

    parentFields.remove(index);
  };

  return (
    <>
      <p className="fw-bolder text-dark">
        Equipment types for sheet {index + 1}
      </p>
      {fields.map((field, index) => {
        return (
          <>
            <EquipmentTypeChildrenFields
              form={form}
              equipmentTypes={equipmentTypes}
              key={field}
              field={field}
              index={index}
              fields={fields}
            />
          </>
        );
      })}

      <div className="col-2">
        <Button
          color="link"
          className="text-muted"
          onClick={() => fields.push({})}
        >
          Add Equipment Type
        </Button>
      </div>
      <div className="col-lg-2 d-flex align-self-end form-group">
        <Button
          color="danger"
          disabled={loading}
          outline
          block
          onClick={removeParent}
        >
          {isSubmitting(loading, `Remove Sheet ${index + 1}`, "Removing...")}
        </Button>
      </div>
    </>
  );
};

export default EquipmentTypeFieldWrapper;
