import React from "react";
import _ from "lodash";
import { Badge } from "reactstrap";
import ManagerLeftTabs from "../utils/ManagerLeftTabs";

const OverdueTab = ({ jobs, filters, toggleOverdue }) => {
  if (jobs.length === 0) {
    return null;
  }

  const count = _.countBy(jobs, "is_overdue").true;

  const items = [
    {
      label: "Overdue",
      count,
      countDisplay: () => (
        <Badge className="shadow" color="danger">
          {count}
        </Badge>
      ),
      onClick: toggleOverdue,
      className: filters.overdue ? "active" : "",
    },
  ];

  return <ManagerLeftTabs wrapperClass="mt-3" label="Overdue" items={items} />;
};

export default OverdueTab;
