import React from "react";
import { Link } from "react-router-dom";

import {
  DashboardHeader,
  DashboardH3,
  DashboardSubTitle
} from "../utils/DashboardHeader";

const ProfileHeader = ({ role }) => {
  return (
    <DashboardHeader>
      <DashboardH3>{role.name}</DashboardH3>
      {role.division && (
        <DashboardSubTitle>
          <Link to={`/divisions/${role.division.uuid}`}>
            {role.division.name}
          </Link>
        </DashboardSubTitle>
      )}
    </DashboardHeader>
  );
};

export default ProfileHeader;
