import React, { useMemo } from "react";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { HiClipboardCopy, HiOutlineMail } from "react-icons/hi";
import { MdAdd, MdOutlineContentCopy, MdPlusOne } from "react-icons/md";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
const NewUserInvitedList = () => {
  const { data: users } = useApi("users?filter[no_password]=true", [], true);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessor: "full_name",
        cell: (info: any) => {
          const user = info.row.original;
          return (
            <div>
              <p className="text-dark mb-0">{user.full_name}</p>
              <CopyToClipboard
                text={user.email}
                options={{ format: "text/plain" }}
                onCopy={() => toast.success("Email copied to clipboard")}
              >
                <p className="mb-0 pointer-hover">
                  {user.email} <MdOutlineContentCopy />
                </p>
              </CopyToClipboard>
              <small className="text-muted">
                {user.primary_position?.name}
              </small>
            </div>
          );
        },
      },
      {
        header: "Actions",
        accessor: "actions",
        cell: (info: any) => {
          const user = info.row.original;
          const { takeAction, loading } = useApi();

          return (
            <div>
              <button
                className="btn btn-outline-primary btn-sm"
                disabled={loading}
                onClick={() =>
                  takeAction("store", `users/${user.uuid}/resend-password-link`)
                    .then(() => toast.success("Password link sent"))
                    .catch(errorSwal)
                }
              >
                {isSubmitting(loading, "Resend Password Link", "Resending...")}
              </button>
            </div>
          );
        },
      },
    ],
    [users],
  );

  return (
    <>
      <HeaderPage titlePage="Users Not Signed In" crumbs={[]} />
      <ReactTable
        extraButtons={
          <Link className="ms-3" to="/users/invite/add">
            <MdAdd className="tx-18" /> Add New User
          </Link>
        }
        columns={columns}
        data={users}
      />
    </>
  );
};

export default NewUserInvitedList;
