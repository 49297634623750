import { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";

const SelectTenant = (props) => {
  const { handleSubmit } = props;
  const history = useHistory();

  const url = `${window.location.pathname}${window.location.search}`;
  const [tenants, setTenants] = useState([]);

  const { takeAction } = useApi();

  useEffect(() => {
    takeAction("store", url)
      .then(({ data }) => setTenants(data.data))
      .catch(errorSwal);
  }, []);

  const submit = (values) => {
    return takeAction("store", "xero-tenant", values).then(() => {
      toast.success("Organisation Added");
      history.push("/xero-integration/client-contacts");
    });
  };

  return (
    <>
      Select Xero Organisation to use:
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className="col-12 form-group">
            <Field
              component={SelectInputAsync}
              name="xero_tenant_id"
              label="Organisation"
              options={tenants}
            />
          </div>
          <div className="col-12 form-group">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const form = reduxForm({ form: "XeroOrganisation" });

export default form(SelectTenant);
