const INVOICE_TERMS = [
  {
    label: "COD",
    value: "0",
  },
  {
    label: "Days From Invoiced",
    value: "1",
  },
  {
    label: "Days From End of Month",
    value: "2",
  },
];

export default INVOICE_TERMS;
