import { Badge } from "reactstrap";
import { getStatus } from "../tenders/TendersList";
import ReactTable from "../table/ReactTable";
import { Tender } from "../tenders/tenderTypes";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { money } from "../form/formatters";

const BranchTenderTable = ({ tenders }: { tenders: Tender[] }) => {
  const columns = [
    {
      id: "name",
      header: "Name",
      dataIndex: "job.name",
      cell: (info: any) => {
        const tender = info.row.original as Tender;

        return (
          <Link to={tender.link} className="mb-0">
            {tender.job.name}
          </Link>
        );
      },
    },
    {
      id: "client",
      header: "Client",
      dataIndex: "client",
      cell: (info: any) => {
        const tender = info.row.original;
        if (tender.client_name) {
          return tender.client_name;
        }

        return tender.client.name;
      },
    },
    {
      id: "type",
      header: "Type",
      dataIndex: "type.name",
      cell: (info: any) => {
        return info.row.original.type.name;
      },
    },
    {
      id: "total_price",
      header: "Total Price",
      dataIndex: "total_price",
      cell: (info: any) => {
        const tender = info.row.original as Tender;
        if (tender.total_price === null) {
          return "-";
        }

        return money.format(tender.total_price);
      },
    },
    {
      id: "submitted_on",
      header: "Submitted On",
      dataIndex: "submitted_on",
      cell: (info: any) => {
        const tender = info.row.original as Tender;

        return tender.submitted_on
          ? dayjs(tender.submitted_on).format("DD/MM/YYYY")
          : "-";
      },
    },
    {
      id: "due_date",
      header: "Due Date",
      dataIndex: "due_date",
      cell: (info: any) => {
        const tender = info.row.original as Tender;

        return dayjs(tender.job.scheduled_finish_date).format("DD/MM/YYYY");
      },
    },

    {
      id: "status",
      header: "Status",
      dataIndex: "status",
      cell: (info: any) => {
        const tender = info.row.original;

        const status = getStatus(tender);

        return (
          <Badge className="rounded-pill shadow" color={status.colour}>
            {status.status}
          </Badge>
        );
      },
    },
  ];

  /** @ts-ignore */
  return <ReactTable disableSearch columns={columns} data={tenders} />;
};

export default BranchTenderTable;
