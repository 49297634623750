import dayjs from "dayjs";
import api from "../components/api/api";
import {
  FETCH_PROJECT_OVERVIEW,
  UPDATE_PROJECT_OVERVIEW_DATE_RANGE,
} from "./types";

export const isSelectingFirstDay = (from, to, day) => {
  const isBeforeFirstDay = from && dayjs(day).isBefore(from);
  const isRangeSelected = from && to;
  return !from || isBeforeFirstDay || isRangeSelected;
};

export const getInitialState = () => {
  return {
    from: null,
    to: null,
    enteredTo: null, // Keep track of the last day for mouseEnter.
  };
};

export const setDayRange = (period, projectUuid) => (dispatch) => {
  const formattedPeriod = formatPeriod(period);
  localStorage.setItem("project_overview_period", period);

  dispatch({
    type: UPDATE_PROJECT_OVERVIEW_DATE_RANGE,
    payload: {
      ...formattedPeriod,
      enteredTo: formattedPeriod.to,
      loading: true,
    },
  });
  const queryString = getQueryString(formattedPeriod.from, formattedPeriod.to);
  return api
    .get(`projects/overview/${projectUuid}${queryString}`)
    .then(({ data }) => data.data)
    .then((overview) =>
      dispatch({
        type: FETCH_PROJECT_OVERVIEW,
        payload: overview,
      }),
    )
    .then(() =>
      dispatch({
        type: UPDATE_PROJECT_OVERVIEW_DATE_RANGE,
        payload: {
          loading: false,
        },
      }),
    );
};

const getQueryString = (start, end) => {
  const startDate = dayjs(start).format("YYYY-MM-DD");
  const endDate = dayjs(end).format("YYYY-MM-DD");

  return `?dateRange[]=${startDate}&dateRange[]=${endDate}`;
};

const formatPeriod = (period) => ({
  from: dayjs().startOf(period).toDate(),
  to: dayjs().endOf(period).toDate(),
});

export const handleDayClick = (day, from, to) => (dispatch) => {
  if (from && to && day >= from && day <= to) {
    return dispatch({
      type: UPDATE_PROJECT_OVERVIEW_DATE_RANGE,
      payload: getInitialState(),
    });
  }
  if (isSelectingFirstDay(from, to, day)) {
    return dispatch({
      type: UPDATE_PROJECT_OVERVIEW_DATE_RANGE,
      payload: {
        from: day,
        to: null,
        enteredTo: null,
      },
    });
  }

  dispatch({
    type: UPDATE_PROJECT_OVERVIEW_DATE_RANGE,
    payload: {
      to: day,
      enteredTo: day,
      loading: true,
    },
  });
};

export const handleDayMouseEnter = (day, from, to) => (dispatch) => {
  if (!isSelectingFirstDay(from, to, day)) {
    dispatch({
      type: UPDATE_PROJECT_OVERVIEW_DATE_RANGE,
      payload: {
        enteredTo: day,
      },
    });
  }
};
