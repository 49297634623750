const PRICE_BANDS_STATUS = [
  {
    label: "Not Applicable",
    value: "0",
  },
  {
    label: "Required",
    value: "1",
  },
  {
    label: "Optional",
    value: "2",
  },
];

export default PRICE_BANDS_STATUS;
