import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import DeleteLeaveRequestButton from "./DeleteLeaveRequestButton";
import ApprovalComments from "../approvals/ApprovalComments";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { IRequestedLeave } from "./leaveTypes";
import ShowRequestedLeavePage from "./ShowRequestedLeavePage";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import LeaveTimesheets from "./LeaveTimesheets";
import ApprovalStatus from "../user/ApprovalStatus";
import dayjs from "dayjs";

const RequestedLeaveProfile = () => {
  const { user } = useAuth();

  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading, setData } = useApi(
    `requested-leave/${uuid}`,
    {},
    true,
    "/home",
  );

  const requestedLeave = data as IRequestedLeave;

  const [approvers, setApprovers] = useState<
    Array<{ label: string; value: number }> | []
  >([]);

  const { takeAction, response } = useApi();

  useEffect(() => {
    takeAction(
      "index",
      `timesheet-approval-users/users?branch_id=${user?.default_branch_id}`,
    ).then(
      ({
        data,
      }: {
        data: { data: Array<{ label: string; value: number }> };
      }) => {
        setApprovers(data.data);
      },
    );
  }, []);

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={`${requestedLeave.user.name}'s requested leave for ${requestedLeave.period_string}`}
        crumbs={[]}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>
              {requestedLeave.user.name}'s requested leave for{" "}
              {requestedLeave.period_string}
            </DashboardH3>
            <p className="mb-0">
              Created on the{" "}
              <span className="text-dark">
                {dayjs(requestedLeave.created_at).format("DD/MM/YYYY")}
              </span>{" "}
              at
              <span className="text-dark">
                {" "}
                {dayjs(requestedLeave.created_at).format("hh:mm a")}
              </span>
            </p>
          </DashboardHeader>
        }
        content={
          <ShowRequestedLeavePage
            requestedLeave={requestedLeave}
            setRequestedLeave={setData}
          />
        }
        tabs={[
          {
            label: "Details",
            link: `/requested-leave/${uuid}/details`,
            page: "details",
            icon: "fa-pencil",
          },
          {
            label: "Documents",
            link: `/requested-leave/${uuid}/documents`,
            page: "documents",
            icon: "fa-folder-open-o",
          },
        ]}
        sideBar={
          <>
            <ApprovalButton
              wrapperClass="mg-t-20 w-100"
              approvalAction={requestedLeave.approval_action}
              canTransfer
              transferUsers={approvers}
            />
            <ApprovalStatus
              status={requestedLeave.approval_status}
              className="w-100 py-2 mt-3"
            />
            <DeleteLeaveRequestButton requestedLeave={requestedLeave} />
            <ApprovalComments
              modelType="App\Models\RequestedLeave"
              modelId={requestedLeave.id}
              className="mt-3"
            />
            <LeaveTimesheets requestedLeave={requestedLeave} />
          </>
        }
      />
    </>
  );
};

export default RequestedLeaveProfile;
