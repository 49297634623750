import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import INVOICE_TERMS from "../../data/invoice_terms";
import { InvoiceTerms } from "../clients/clientTypes";
import RenderField, { money } from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const AccountRequestFields = ({
  namePrefix = "",
  isRequired = false,
  invoiceTerm,
  invoiceTerms = INVOICE_TERMS,
}: {
  namePrefix?: string;
  isRequired?: boolean;
  invoiceTerm: string;
  form: string;
  invoiceTerms?: any[];
}) => {
  return (
    <>
      <div
        className={`col-lg-${
          parseInt(invoiceTerm) === InvoiceTerms.COD ? "12" : "4"
        } form-group`}
      >
        <Field
          component={SelectInputAsync}
          label="Invoice Terms"
          name={`${namePrefix ? `${namePrefix}.` : ""}invoice_terms`}
          options={invoiceTerms}
          validate={isRequired ? required : null}
          required={isRequired}
        />
      </div>
      {parseInt(invoiceTerm) !== InvoiceTerms.COD ? (
        <>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              name={`${namePrefix ? `${namePrefix}.` : ""}invoice_terms_amount`}
              label="Days"
              type="number"
              validate={isRequired ? required : null}
              required={isRequired}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              name={`${namePrefix ? `${namePrefix}.` : ""}account_limit`}
              label="Account Limit"
              {...money}
              validate={isRequired ? required : null}
              required={isRequired}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (
  state: any,
  { form, namePrefix }: { form: string; namePrefix?: string },
) => {
  const selector = formValueSelector(form);

  return {
    invoiceTerm: selector(
      state,
      `${namePrefix ? `${namePrefix}.` : ""}invoice_terms`,
    ),
  };
};

export default connect(mapStateToProps, {})(AccountRequestFields);
