import {
  FETCH_TIMESHEETS,
  NEW_TIMESHEET,
  UPDATE_TIMESHEET,
  DELETE_TIMESHEET,
  MODIFY_TIMESHEET_DATE,
} from "../actions/types";

const initialState = {
  items: [],
  addTimesheet: false,
  selectedDay: new Date(),
  productivityEvents: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TIMESHEETS:
      return { ...state, items: action.payload };
    case NEW_TIMESHEET:
      return { ...state, items: [...state.items, action.payload] };
    case UPDATE_TIMESHEET:
      return { ...state, items: action.payload };
    case DELETE_TIMESHEET:
      return { ...state, items: action.payload };
    case MODIFY_TIMESHEET_DATE:
      return { ...state, selectedDay: action.payload };
    default:
      return state;
  }
}
