import RenderField from "../utils/renderField";

const CustomTimeInput = (props: any) => {
  const supportsTime = checkInput("time");

  return (
    <RenderField
      {...props}
      type={supportsTime ? "time" : null}
      textarea={false}
    />
  );
};

export function checkInput(type: any) {
  var input = document.createElement("input");
  input.setAttribute("type", type);
  return input.type == type;
}

export default CustomTimeInput;
