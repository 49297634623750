import axiosWithoutAuthenticationHeaders from "axios";
import api from "../api/api";

const uploadToS3 = (input, bucket) => {
  if (!input) return Promise.resolve([]);

  const files = formatInput(input);

  const uploadedFiles = files.map((file) => uploadFile(file, bucket));
  return Promise.all(uploadedFiles);
};

const formatInput = (input) => {
  let files = input;
  if (!Array.isArray(input)) {
    files = [input];
  }

  return files;
};

const uploadFile = (file, bucket) => {
  let path = "";

  if (!(file instanceof File)) {
    return Promise.resolve(file);
  }

  return api
    .post(`pre-signed-urls`, {
      bucket,
      name: file.name,
    })
    .then(({ data }) => {
      path = data.data.path;
      return axiosWithoutAuthenticationHeaders.put(data.data.url, file, {
        "Content-Type": "multipart/form-data",
      });
    })
    .then(() => {
      return {
        path,
        name: file.name,
        file_size: file.size,
        mime_type: file.type,
      };
    });
};

export default uploadToS3;
