import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import { formValueSelector } from "redux-form";
import SideList from "../comments/SideList";
import { formatNumber } from "../dashboard/OverviewCard";
import HeaderPage from "../header/HeaderPage";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import errorSwal from "../utils/errorSwal";
import InfoCard from "../utils/InfoCard";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import ArchiveButton from "./ArchiveButton";
import ShowPage from "./ShowPage";

import axios from "../api/api";
import useUserGroup from "../hooks/useUserGroup";

const calculateTotalPrice = (pricing, values) => {
  if (!pricing || !pricing.every((p) => p)) return 0;
  return pricing.reduce((carry, item) => {
    if (!item.item || !item.quantity) return carry;
    const value = values.find((val) => val.id == item.item);

    const totalValue = parseFloat(
      Math.round((value.price * item.quantity + Number.EPSILON) * 100) / 100,
    ).toFixed(2);
    carry = parseFloat(carry) + parseFloat(totalValue);
    return carry;
  }, 0);
};

const Profile = (props) => {
  const { pricing } = props;

  const { uuid, page } = useParams();
  const [loading, setLoading] = useState(true);
  const [recurringPurchase, setRecurringPurchase] = useState({});
  const isAccounting = useUserGroup("Accounting");

  useEffect(() => {
    axios
      .get(`recurring-payments/${uuid}`)
      .then(({ data }) => setRecurringPurchase(data.data))
      .catch(errorSwal)
      .then(() => setLoading(false));
  }, []);

  if (loading) return <Spinner loading />;

  const totalPrice = calculateTotalPrice(pricing, recurringPurchase.prices);

  return (
    <>
      <HeaderPage
        titlePage={recurringPurchase.name}
        crumbs={[
          { link: "/contract-agreements", name: "Contract Agreements" },
          { link: "/", name: recurringPurchase.name, active: true },
        ]}
      />
      <div className="row">
        <div className="col-lg-8">
          <DashboardHeader>
            <div className="d-flex">
              <DashboardH3>{recurringPurchase.name}</DashboardH3>
              {recurringPurchase.archived_at && (
                <div className="ms-auto">
                  <Badge color="primary" className="rounded-pill shadow">
                    Archived{" "}
                    {dayjs(recurringPurchase.archived_at).format("DD/MM/YYYY")}
                  </Badge>
                </div>
              )}
            </div>
            <DashboardSubTitle>
              Supplier:{" "}
              <Link
                to={`/suppliers/${recurringPurchase.supplier.uuid}/details`}
              >
                {recurringPurchase.supplier.name}
              </Link>
            </DashboardSubTitle>
            <DashboardSubTitle>
              Project:{" "}
              <Link
                to={`/projects/${recurringPurchase.project.uuid}/purchases`}
              >
                {recurringPurchase.project.name}
              </Link>
            </DashboardSubTitle>
            <DashboardSubTitle>
              Purchaser Name:{" "}
              <a href={`mailto:${recurringPurchase.user.email}`}>
                {recurringPurchase.user.name}
              </a>
            </DashboardSubTitle>
            {recurringPurchase.description && (
              <DashboardSubTitle>
                <p className="mt-2 mb-2 tx-inverse">
                  Agreement Description: {recurringPurchase.description}
                </p>
              </DashboardSubTitle>
            )}

            <DashboardSubTitle>
              {recurringPurchase.includes_gst_description}
            </DashboardSubTitle>
            {recurringPurchase.expected_next_payment && (
              <Badge className="rounded-pill shadow mt-2" color="info">
                Next payment expected on{" "}
                {dayjs(recurringPurchase.expected_next_payment).format(
                  "DD/MM/YYYY",
                )}
              </Badge>
            )}
          </DashboardHeader>
          <div className="d-lg-none mb-3">
            <ArchiveButton
              recurringPurchase={recurringPurchase}
              setRecurringPurchase={setRecurringPurchase}
            />
          </div>
          <ShowPage
            {...props}
            recurringPurchase={recurringPurchase}
            setRecurringPurchase={setRecurringPurchase}
            totalPrice={totalPrice}
            isAccounting={isAccounting}
          />
        </div>
        <div className="col-lg-4">
          {" "}
          <TabList>
            <Tab
              link={`/recurring-purchases/${uuid}/details`}
              active={page === "details"}
              icon="icon ion-clipboard text-capitalize"
            >
              Details
            </Tab>
            <Tab
              link={`/recurring-purchases/${uuid}/quote`}
              active={page === "quote"}
              icon="icon ion-clipboard text-capitalize"
            >
              Quote
            </Tab>
            <Tab
              link={`/recurring-purchases/${uuid}/purchases`}
              active={page === "purchases"}
              icon="icon ion-clipboard text-capitalize"
            >
              Purchases
            </Tab>
            {isAccounting && !recurringPurchase.archived_at && (
              <Tab
                link={`/recurring-purchases/${uuid}/add-purchase`}
                active={page === "add-purchase"}
                icon="icon ion-clipboard text-capitalize"
              >
                Add Purchase
              </Tab>
            )}
          </TabList>
          <div className="d-lg-block d-none my-3">
            <ArchiveButton
              recurringPurchase={recurringPurchase}
              setRecurringPurchase={setRecurringPurchase}
            />
          </div>
          {page === "add-purchase" && (
            <InfoCard
              wrapperClass="d-none d-lg-block"
              info={[
                {
                  title: "Total Cost",
                  label: "Value",
                  value: `$${formatNumber(totalPrice)}`,
                },
              ]}
            />
          )}
          <div className="mt-3">
            <SideList
              commentableType="App\Models\RecurringPayment"
              commentableId={recurringPurchase.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const selector = formValueSelector("RecurringPayment");

const mapStateToProps = (state) => ({
  pricing: selector(state, "payment"),
});

export default connect(mapStateToProps, {})(Profile);
