import React from "react";
import { geocodeByAddress } from "react-places-autocomplete";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import { Field, formValueSelector } from "redux-form";
import Autocomplete from "../utils/Autocomplete";
import formatGoogleAddress, {
  checkAddress,
} from "../utils/formatGoogleAddress";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import AUSTRALIAN_STATES from "../../data/australia_states";

const fields = ["line_1", "line_2", "suburb", "postcode", "state"];

const AddressFields = (props) => {
  const {
    postal,
    streetTitle,
    change,
    postalTitle,
    hideStreetTitle = false,
    validate = true,
  } = props;

  const toggleChange = (e) => {
    const checked = e.target.checked;

    fields.forEach((field) => {
      change(`postal_${field}`, checked ? props[field] : "");
    });
  };

  const handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const formattedResults = formatGoogleAddress(results);
      change(
        "line_1",
        `${checkAddress(formattedResults, "street_number")} ${checkAddress(
          formattedResults,
          "street_name",
        )}`,
      );
      change(
        "line_2",
        formattedResults.subpremise
          ? `${formattedResults.subpremise.long_name}`
          : "",
      );
      change("suburb", checkAddress(formattedResults, "suburb"));

      change(
        "postcode",
        checkAddress(formattedResults, "post_code", "short_name"),
      );

      change("state", checkAddress(formattedResults, "state", "short_name"));

      change("latitude", formattedResults.latitude);
      change("longitude", formattedResults.longitude);
    });
  };

  return (
    <>
      {!hideStreetTitle && (
        <div className="col-12">
          <label className="section-title mt-3">
            {streetTitle || "Street Address"}
          </label>
          <hr />
        </div>
      )}
      <div className="form-group col-lg-8">
        {window.google ? (
          <Field
            handleSelect={handleSelect}
            component={Autocomplete}
            name="line_1"
            label="Line 1"
            required={validate}
            validate={validate ? required : null}
          />
        ) : (
          <Field
            component={renderField}
            name="line_1"
            label="Line 1"
            required={validate}
            validate={validate ? required : null}
          />
        )}
      </div>
      <div className="form-group col-lg-4">
        <Field component={renderField} name="line_2" label="Line 2" />
      </div>
      <div className="form-group col-lg-6">
        <Field
          component={renderField}
          name="suburb"
          label="Suburb"
          required={validate}
          validate={validate ? required : null}
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderField}
          name="postcode"
          label="Postcode"
          required={validate}
          validate={validate ? required : null}
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={SelectInputAsync}
          name="state"
          label="State"
          options={AUSTRALIAN_STATES}
          required={validate}
          validate={validate ? required : null}
        />
      </div>
      {postal && (
        <>
          <div className="col-12">
            <label className="section-title">
              {postalTitle || "Postal Address"}
            </label>
            <hr />
          </div>

          <div className="col-12 form-group">
            <label className="d-block form-control-label tx-inverse tx-semibold">
              Same as above?
            </label>
            <Toggle onChange={toggleChange} />
          </div>

          <div className="form-group col-lg-8">
            <Field
              component={renderField}
              name="postal_line_1"
              label="Line 1"
              required={validate}
              validate={validate ? required : null}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              component={renderField}
              name="postal_line_2"
              label="Line 2"
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              component={renderField}
              name="postal_suburb"
              label="Suburb"
              required={validate}
              validate={validate ? required : null}
            />
          </div>
          <div className="form-group col-lg-3">
            <Field
              component={renderField}
              name="postal_postcode"
              label="Postcode"
              required={validate}
              validate={validate ? required : null}
            />
          </div>
          <div className="form-group col-lg-3">
            <Field
              component={SelectInputAsync}
              name="postal_state"
              label="State"
              options={AUSTRALIAN_STATES}
              required={validate}
              validate={validate ? required : null}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    line_1: selector(state, "line_1"),
    line_2: selector(state, "line_2"),
    suburb: selector(state, "suburb"),
    postcode: selector(state, "postcode"),
    state: selector(state, "state"),
  };
};

export default connect(mapStateToProps, {})(AddressFields);
