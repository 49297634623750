import { useHistory, useParams } from "react-router-dom";
import Skill from "./Skill";
import SkillModal from "./SkillModal";
import Empty from "../utils/Empty";

const StaffSkills = (props) => {
  const { staffCompetencies, activeFilters } = props;
  const { competency, uuid } = useParams();
  const history = useHistory();

  return (
    <div className="mg-t-20">
      <div className="space-y-5">
        {staffCompetencies.length === 0 && (
          <div className="mt-5">
            <Empty width="25%" height="50%">
              {activeFilters.length > 0 ? (
                <div className="d-block text-center">
                  <p className="tx-inverse tx-16 fw-bolder mb-2">
                    No Results found for the following filters:
                  </p>
                  <div className="mb-3">
                    {activeFilters.map((filter, index) => {
                      return (
                        <p key={index} className="mb-0">
                          <span className="tx-inverse">
                            {filter.header_label}
                          </span>{" "}
                          - {filter.label}
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <p className="tx-inverse fw-bolder">No Results found</p>
              )}
            </Empty>
          </div>
        )}

        {staffCompetencies.map((comp, index) => (
          <Skill
            key={comp.uuid}
            competency={comp}
            {...props}
            index={index}
            staffCompetencies={staffCompetencies}
          />
        ))}
      </div>
    
      <SkillModal
        {...props}
        modal={competency}
        toggle={() => history.push(`/staff-matrix/users/${uuid}`)}
        competency={staffCompetencies.find((c) => c.uuid === competency)}
      />
    </div>
  );
};

export default StaffSkills;
