import React from "react";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import { ITakeAction } from "../api/apiTypes";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import { AccountRequest } from "./accountTypes";

interface DenyRequestModalProps {
  toggle: Function;
  modal: boolean;
  takeAction: ITakeAction;
  accountRequest: AccountRequest;
  onCompleted: Function;
}

const DenyRequestModal = (props: DenyRequestModalProps) => {
  const { toggle, modal, takeAction, accountRequest, onCompleted } = props;

  const onSubmit = ({ denied_reason }: { denied_reason: string }) => {
    return takeAction(
      "store",
      `complete-account-requests/${accountRequest.uuid}`,
      {
        accepted: false,
        denied_reason,
      },
    )
      .then(() => {
        toggle();
        onCompleted();
        toast.success(`Account request updated successful.`);
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      title="Deny Account Request"
      onSubmit={onSubmit}
      toggle={toggle}
      modal={modal}
      save="Deny"
      saving="Denying..."
      form="DenyAccountRequest"
    >
      <div className="col-12 form-group">
        <Field
          name="denied_reason"
          label="Reason for denial"
          component={RenderField}
          required
          validate={required}
        />
      </div>
    </FormModal>
  );
};

export default DenyRequestModal;
