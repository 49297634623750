import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";

const Add = () => {
  const { takeAction } = useApi();

  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", "trackingsites", values)
      .then(({ data }) => {
        toast.success("Tracking site created successfully!");
        history.push(`/trackingsites/${data.data.trackingsite.uuid}`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Tracking Site"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "trackingsites", name: "Tracking Sites" },
          {
            link: "trackingsites/add",
            name: "New Tracking Site",
            active: true,
          },
        ]}
      />
      <Form form="AddTrackingSite" onSubmit={onSubmit} />
    </>
  );
};

export default Add;
