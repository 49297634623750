import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NoMatch from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import EditMachineDayRate from "./EditMachineDayRate";

const ShowMachineDayRatePage = (props: {
  machineDayRate: any;
  setMachineDayRate: Function;
}) => {
  const { page, uuid } = useParams<{ uuid: string; page: string }>();
  const { user } = useAuth();

  switch (page) {
    case "details":
      return <EditMachineDayRate {...props} />;
    case "documents":
      return (
        <NewProfileDocuments
          model="App\Models\MachineDayRate"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/machineDayRates/${uuid}`}
          homeUrl={`machine-day-rates/${uuid}/documents`}
        />
      );
    default:
      return <NoMatch />;
  }
};

export default ShowMachineDayRatePage;
