import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

interface CreateBudgetButtonProps {
  budgets: any[];
  setBudgets: Function;
}

const CreateBudgetButton = ({
  budgets,
  setBudgets,
}: CreateBudgetButtonProps) => {
  const { takeAction, loading }: IUseApi = useApi();

  const { uuid } = useParams<{ uuid: string }>();

  const createBudget = () => {
    return takeAction("store", `budget-templates/${uuid}/budget`)
      .then(({ data }) => {
        toast.success("Budget created.");
        setBudgets([...budgets, data.data]);
      })
      .catch(errorSwal);
  };

  return (
    <Button
      block
      color="secondary"
      outline
      className="mt-3"
      onClick={createBudget}
      disabled={loading}
    >
      {isSubmitting(loading, "Create Budget", "Creating Budget...")}
    </Button>
  );
};

export default CreateBudgetButton;
