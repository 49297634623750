import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import ProfileViewsList from "../profileViews/ProfileViewsList";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import isSubmitting from "../utils/submitting";
import IncompleteJobList from "./IncompleteJobList";
import ProfileButton from "./ProfileButton";
import ProfileHeader from "./ProfileHeader";
import ShowPage from "./ShowPage";
import SyncIntegrationEstimation from "./SyncIntegrationEstimation";
import Tabs from "./Tabs";
import ToggleAggregateByButton from "./ToggleAggregateByButton";

const Profile = (props) => {
  const { uuid } = useParams();
  const { user } = useAuth();

  const history = useHistory();

  const { takeAction, loading: deleting } = useApi();

  const {
    data: uninvoicedDeliverables,
    setUrl: setDeliverableUrl,
    setData,
  } = useApi("", [], true);

  const deleteInvoice = () => {
    return deleteSwal(invoice.name)
      .then(() => takeAction("destroy", `invoices/${uuid}`))
      .then(() => {
        toast.success("Invoice deleted");
        history.push(`/projects/${invoice.project.uuid}/invoices`);
      })
      .catch(errorSwal);
  };

  useEffect(() => {
    setUrl(`invoices/${uuid}`);
  }, [uuid]);

  const {
    data: invoice,
    setData: setInvoice,
    setUrl,
  } = useApi(`invoices/${uuid}`, null, true, history.goBack);

  useEffect(() => {
    if (invoice) {
      setDeliverableUrl(
        `projects/${invoice.project.uuid}/uninvoiced-deliverables`,
      );
    }
  }, [invoice]);

  if (!invoice) return <Spinner loading />;

  const allLineItemsHavePrice = invoice?.line_items?.every(
    (item) => item.price !== 0,
  );

  const invoiceLink = user.is_admin
    ? { link: "invoices", name: "Invoices" }
    : { link: "projects", name: "Projects" };

  return (
    <>
      <HeaderPage
        titlePage={invoice.name}
        crumbs={[
          invoiceLink,
          {
            link: `projects/${invoice.project.uuid}/invoices`,
            name: invoice.project.name,
          },
          { link: "", name: invoice.name, active: true },
        ]}
      />
      <div className="row row-sm justify-content-end">
        <div className="col-lg-4 m-1">
          <ProfileViewsList
            viewable={invoice}
            viewable_type={"App\\Models\\Invoice"}
          />
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-lg-8">
          <ProfileHeader {...props} invoice={invoice} setInvoice={setInvoice} />
          {!allLineItemsHavePrice && (
            <InformationAlert
              type="danger"
              title="Prices Missing"
              body="Deliverables marked as red have line items missing pricing."
            />
          )}
          <ToggleAggregateByButton invoice={invoice} setInvoice={setInvoice} />
          <ProfileButton {...props} invoice={invoice} setInvoice={setInvoice} />

          {invoice?.estimated_integrations?.map((estimated) => {
            return (
              <SyncIntegrationEstimation
                estimated={estimated}
                invoice={invoice}
                setInvoice={setInvoice}
              />
            );
          })}

          <ShowPage
            {...props}
            invoice={invoice}
            setInvoice={setInvoice}
            uninvoicedDeliverables={uninvoicedDeliverables}
          />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <Tabs invoice={invoice} />
          {invoice.user_can_delete && (
            <Button
              block
              outline
              color="danger"
              className="my-3"
              onClick={deleteInvoice}
              disabled={deleting}
            >
              {isSubmitting(deleting, "Delete", "Deleting...")}
            </Button>
          )}
          <div className="mt-3">
            <SideList
              commentableType="App\Models\Invoice"
              commentableId={invoice.id}
            />
          </div>
          <IncompleteJobList
            deliverables={uninvoicedDeliverables}
            invoice={invoice}
            setDeliverables={setData}
          />
        </div>
      </div>
    </>
  );
};

export default Profile;
