import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import RightModal from "./RightModal";
import CustomScaleLoader from "./scaleLoader";
import { useEffect } from "react";
import { ReactComponent as Error } from "../../svgs/injured.svg";
import SVGContainer from "./SVGContainer";

const DocsModal = ({ url }: { url: string }) => {
  const { data, loading, setUrl } = useApi(
    `documentation-pages?docs_url=${encodeURIComponent(url)}`,
    null,
    true,
  );

  useEffect(() => {
    setUrl(`documentation-pages?docs_url=${encodeURIComponent(url)}`);
  }, [url]);

  const { toggle, modal } = useModal();

  return (
    <>
      <div
        style={{
          margin: "1rem",
          right: "0",
          bottom: "140px",
        }}
        className="position-fixed z-10 d-none d-lg-block"
      >
        <Button
          style={{
            height: "56px",
            width: "56px",
          }}
          onClick={toggle}
          color="primary"
          className="rounded-circle shadow"
        >
          <i className="icon ion-help tx-20"></i>
        </Button>
      </div>
      <RightModal modal={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody className="overflow-auto d-flex align-items-center">
          <DocsModalBody url={url} data={data} loading={loading} />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-outline-secondary bd-0"
            onClick={toggle}
          >
            Close
          </button>
        </ModalFooter>
      </RightModal>
    </>
  );
};

const DocsModalBody = ({
  loading,
  data,
  url,
}: {
  loading: boolean;
  data: any;
  url: string;
}) => {
  if (loading) {
    return (
      <div className="w-100">
        <CustomScaleLoader>Fetching Docs...</CustomScaleLoader>
      </div>
    );
  }

  if (!data) {
    return (
      <SVGContainer
        wrapperClass="w-100 h-100"
        width="35%"
        height="30%"
        SVG={Error}
        top={false}
      >
        <div className="text-center mt-4">
          <h6 className="mb-2 text-dark tx-18">
            Unable to fetch Data from our documentation website
          </h6>
          <p>
            To go directly to our documentation website{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.thebossapp.com.au/"
            >
              click here
            </a>
          </p>
        </div>
      </SVGContainer>
    );
  }

  return (
    <div>
      <p>
        To view on our docs website{" "}
        <a target="_blank" rel="noopener noreferrer" href={url}>
          click here
        </a>
      </p>
      <div className="p-3" dangerouslySetInnerHTML={{ __html: data.html }} />
    </div>
  );
};

export default DocsModal;
