import React from "react";
import JobList from "../utils/JobList";

const DependenciesWidget = ({ jobs, title }) => {
  return (
    <>
      {jobs && jobs.length > 0 && (
        <div className="card mt-3">
          <div className="card-body">
            <label className="slim-card-title">{title}</label>
            <JobList jobs={jobs} />
          </div>
        </div>
      )}
    </>
  );
};

export default DependenciesWidget;
