import React from "react";
import { DataResponse, IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { Topic, TopicPosition } from "./topicType";

const DeleteTopicPosition = (props: {
  position: TopicPosition;
  setTopic: (topic: Topic) => void;
}) => {
  const { position, setTopic } = props;
  const { takeAction, loading }: IUseApi = useApi();

  const deleteFunction = () => {
    return deleteSwal(position.name).then(() => {
      return takeAction("destroy", `position-topics/${position.pivot_uuid}`)
        .then(({ data }: DataResponse<Topic>) => {
          setTopic(data.data);
        })
        .catch(errorSwal);
    });
  };

  return (
    <button
      onClick={deleteFunction}
      disabled={loading}
      type="button"
      className="btn btn-outline-danger py-0"
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </button>
  );
};

export default DeleteTopicPosition;
