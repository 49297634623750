import React from "react";
import { Field } from "redux-form";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";
import dayjs from "dayjs";

const AddShareFilesModel = (props) => {
  const { query, toggle, refreshData } = props;
  const { takeAction } = useApi();

  const requestFileSharing = (values) => {
    values = {
      ...query,
      ...values,
    };
    return takeAction("store", `shared-files`, values)
      .then(() => {
        toast.success(`Files successfully shared.`);
        toggle();
        refreshData();
      })
      .catch(formError);
  };

  return (
    <FormModal
      {...props}
      onSubmit={requestFileSharing}
      initialValues={{
        expires_at: dayjs().add(90, "days").format("YYYY-MM-DD"),
      }}
    >
      <div className="col-lg-6 form-group">
        <Field
          required
          validate={required}
          component={RenderField}
          name="email"
          label="Send Share Link to Email"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="expires_at"
          type="date"
          label="Share Expiry Date"
        />
      </div>
    </FormModal>
  );
};

export default AddShareFilesModel;
