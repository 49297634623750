import React from "react";
import Tabs from "../tabs/Tabs";
import ClientInvoiceOverview from "./ClientInvoiceOverview";
import ClientItemOverview from "./ClientItemOverview";

const Overview = () => {
  const tabs = [
    {
      title: "Invoiced",
      component: <ClientInvoiceOverview />,
    },
    {
      title: "Items",
      component: <ClientItemOverview />,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default Overview;
