import useApi from "../api/useApi";
import FormHeader from "../utils/FormHeader";
import XeroIntegration from "../xero/XeroIntegration";
import MicrosoftIntegration from "./microsoft//MicrosoftIntegration";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import {
  Integration,
  IntegrationPlatform,
  IntegrationType,
} from "./integrationTypes";
import { TMicrosoftIntegration } from "../microsoft/microsoftTypes";
import { IXeroIntegration } from "../xero/xeroTypes";
import CustomScaleLoader from "../utils/scaleLoader";
import { IXeroPayrollIntegration } from "./xeroPayroll/xeroPayrollTypes";
import EmploymentHeroIntegration from "./EmploymentHero/EmploymentHeroIntegration";
import { Link, useHistory } from "react-router-dom";
import errorSwal from "../utils/errorSwal";

const Integrations = () => {
  const { data, takeAction, loading }: IUseApiWithData<Integration[]> = useApi(
    "integrations",
    [],
    true,
  );

  const xeroIntegration = data.find(
    (integration) =>
      integration.name === IntegrationPlatform.Xero &&
      integration.type_description === IntegrationType.Accounting,
  ) as IXeroIntegration | undefined;

  const microsoftIntegration = data.find(
    (integration) => integration.name === IntegrationPlatform.Microsoft,
  ) as TMicrosoftIntegration | undefined;

  const xeroPayroll = data.find(
    ({ name, type_description }) =>
      name === IntegrationPlatform.Xero &&
      type_description === IntegrationType.Payroll,
  ) as IXeroPayrollIntegration | undefined;

  const employmentHero = data.find(
    ({ name, type_description }) =>
      name === IntegrationPlatform.EmploymentHero &&
      type_description === IntegrationType.Payroll,
  ) as IXeroPayrollIntegration | undefined;

  const openAi = data.find(
    ({ name, type_description }) =>
      name === IntegrationPlatform.OpenAI &&
      type_description === IntegrationType.AI,
  );

  if (loading) {
    return <CustomScaleLoader>Fetching Integrations...</CustomScaleLoader>;
  }

  return (
    <>
      <div className="row">
        <FormHeader>Accounting Integrations</FormHeader>
        <XeroIntegration isIntegrated={xeroIntegration} />
      </div>

      <div className="row mt-5">
        <FormHeader>Office Suite Integrations</FormHeader>
      </div>
      <MicrosoftIntegration isIntegrated={microsoftIntegration} />
      <div className="row mt-5">
        <FormHeader>Payroll Integrations</FormHeader>
      </div>
      {/* <div>Coming Soon</div> */}
      {/* <XeroPayroll isIntegrated={xeroPayroll} /> */}
      <EmploymentHeroIntegration isIntegrated={employmentHero} />
      <div className="row mt-5">
        <FormHeader>AI Integrations</FormHeader>
      </div>
      <OpenAIIntegration isIntegrated={openAi} />
    </>
  );
};

const OpenAIIntegration = ({ isIntegrated }: { isIntegrated?: any }) => {
  const { takeAction }: IUseApi<{}, { data: { uuid: string } }> = useApi();

  const history = useHistory();

  const addIntegration = () => {
    return takeAction("store", "integrations", {
      type: 6,
      provider: 1,
      access_token: [{}],
    })
      .then(({ data }) => {
        history.push(`/integrations/open-ai/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <div className="col-lg-4">
      {isIntegrated ? (
        <>
          <Link to={`/integrations/open-ai/${isIntegrated.uuid}/details`}>
            <div className="d-flex justify-content-center align-items-center bg-white p-4 border rounded-lg">
              <img
                style={{
                  height: "60px",
                }}
                src="/img/openai-logomark.png"
                alt="Open AI"
              />
            </div>
          </Link>
        </>
      ) : (
        <button onClick={addIntegration} className="border border-0 p-0 w-100">
          <div className="d-flex justify-content-center align-items-center bg-white p-4 border rounded-lg">
            <img
              style={{
                height: "60px",
              }}
              src="/img/openai-logomark.png"
              alt="Open AI"
            />
          </div>
        </button>
      )}
    </div>
  );
};

export default Integrations;
