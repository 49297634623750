import PropTypes from "prop-types";
import {
  AiOutlineCloudDownload,
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFileWord,
} from "react-icons/ai";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import useApi from "../../api/useApi";
import useModal from "../../hooks/useModal";
import errorSwal from "../../utils/errorSwal";
import isSubmitting from "../../utils/submitting";
import Emails from "./Emails";
import GenerateDeliverable from "./GenerateDeliverable";
import CreateJobFromTemplate from "./CreateJobFromTemplate";
import { useState } from "react";

const DownloadDropdown = (props) => {
  const [loading, setLoading] = useState(false);
  const { takeAction } = useApi();

  const { documents, emails, job } = props;

  const hideBar =
    (!documents || documents?.length === 0) &&
    (!emails || emails?.length === 0) &&
    job?.custom_job_templates?.length === 0;

  if (hideBar) return null;

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      {documents && documents?.length !== 0 && (
        <li className="nav-item">
          <UncontrolledDropdown type="button">
            <DropdownToggle caret className="w-100 nav-link">
              {isSubmitting(
                loading,
                <>
                  <AiOutlineCloudDownload className="tx-indigo tx-20 me-1" />
                  Download
                </>,
                "Generating...",
              )}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Choose Download</DropdownItem>
              <DropdownItem>
                <MenuItem
                  {...props}
                  takeAction={takeAction}
                  setLoading={setLoading}
                />
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <MenuItem
                  word
                  {...props}
                  takeAction={takeAction}
                  setLoading={setLoading}
                />
              </DropdownItem>
              <ExcelItem {...props} takeAction={takeAction} />
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      )}
      <Emails emails={emails} {...props} />
      <GenerateDeliverable job={props.job} documents={documents} />
      <CreateJobFromTemplate job={props.job} />
    </ul>
  );
};

const MenuItem = ({ word, model, takeAction, documents, setLoading }) => {
  const { toggle, modal, setModal } = useModal();

  const checkForUrl = (cacheKey) => {
    takeAction("show", `templates/check/${cacheKey}`).then(({ data }) => {
      if (data.data) {
        window.location.href = data.data;
        setLoading(false);
        return;
      }

      setTimeout(() => checkForUrl(cacheKey), 1000);
    });
  };

  const download = (document) => {
    setLoading(true);
    return takeAction("store", `templates/${document.uuid}/download`, {
      format: word ? "Word" : "PDF",
      ...model,
    })
      .then(({ data }) => {
        checkForUrl(data.data);
      })
      .catch((err) => {
        setLoading(false);
        errorSwal(err);
      });
  };

  return (
    <Dropdown
      toggle={toggle}
      isOpen={modal}
      onMouseEnter={() => setModal(true)}
      onMouseLeave={() => setModal(false)}
      onClick={(e) => e.stopPropagation()}
      caret
      direction="right"
      className="pb-0"
    >
      <DropdownToggle color="white" className="w-100 py-1 shadow-none">
        {word ? (
          <>
            <AiOutlineFileWord className="tx-primary tx-22 me-2" />
            Word
          </>
        ) : (
          <>
            <AiOutlineFilePdf className="tx-danger tx-22 me-2" />
            PDF
          </>
        )}
      </DropdownToggle>
      <DropdownMenu>
        {documents
          ?.filter((document) => {
            const split = document.name.split(".");

            return ["docx", "doc"].includes(split[split.length - 1]);
          })
          .map((document) => {
            return (
              <DropdownItem
                key={document.uuid}
                onClick={() => download(document)}
              >
                {document.name}
              </DropdownItem>
            );
          })}
      </DropdownMenu>
    </Dropdown>
  );
};

const ExcelItem = ({ model, takeAction, documents }) => {
  const { toggle, modal, setModal } = useModal();

  if (!documents) {
    return null;
  }

  const download = (document) => {
    return takeAction("store", `templates/${document.uuid}/download`, {
      format: "xlsx",
      ...model,
    })
      .then(({ data }) => {
        window.location.href = data.data;
      })
      .catch(errorSwal);
  };

  const excelDocs = documents?.filter((document) => {
    const split = document.name.split(".");

    return split[split.length - 1] === "xlsx";
  });

  if (!excelDocs || excelDocs.length === 0) {
    return null;
  }

  return (
    <DropdownItem>
      <Dropdown
        toggle={toggle}
        isOpen={modal}
        onMouseEnter={() => setModal(true)}
        onMouseLeave={() => setModal(false)}
        caret
        onClick={(e) => e.stopPropagation()}
        direction="right"
      >
        <DropdownToggle color="white" className="w-100 py-1 shadow-none">
          <AiOutlineFileExcel className="tx-success tx-22 me-2" />
          Excel
        </DropdownToggle>
        <DropdownMenu>
          {excelDocs.map((document) => {
            return (
              <DropdownItem
                key={document.uuid}
                onClick={() => download(document)}
              >
                {document.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </DropdownItem>
  );
};

DownloadDropdown.propTypes = {
  model: PropTypes.objectOf({
    model: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  documents: PropTypes.array,
};

export default DownloadDropdown;
