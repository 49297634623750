import React, { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { reset } from "redux-form";
import Form from "./Form";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import errorSwal from "../utils/errorSwal";

const List = (props) => {
  const { loading, industries, setIndustries } = props;
  const { takeAction } = useApi();
  const [selectedIndustry, setSelectedIndustry] = useState();
  const { modal, toggle } = useModal();

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => {
        return (
          <Button
            color="link"
            className="p-0"
            onClick={() => {
              setSelectedIndustry(info.row.original);
              toggle();
            }}
          >
            {info.getValue()}
          </Button>
        );
      },
    },
  ];

  const editIndustry = (values, dispatch, { form }) => {
    return takeAction("update", `industries/${values.uuid}`, values)
      .then(({ data }) => {
        toast.success("Industry updated successfully");
        setIndustries(
          industries.map((i) => (i.uuid === data.data.uuid ? data.data : i)),
        );
        dispatch(reset(form));
        toggle();
        setSelectedIndustry();
      })
      .catch(errorSwal);
  };

  const sortedIndustries = _.orderBy(industries, [(d) => d.name.toLowerCase()]);

  return (
    <>
      <ReactTable columns={columns} data={sortedIndustries} loading={loading} />
      <Form
        initialValues={selectedIndustry}
        onSubmit={editIndustry}
        form="EditIndustry"
        title={`Edit ${selectedIndustry?.name}`}
        modal={modal}
        toggle={toggle}
      />
    </>
  );
};

export default List;
