import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import { DueBadge } from "./Profile";

const CalibrationTable = (props) => {
  const { calibrations, equipment, loading } = props;

  const columns = [
    {
      accessorKey: "calibration_type.procedures",
      header: "Calibration",
      cell: (info) => (
        <Link
          to={{
            pathname: info.row.original.link,
            state: {
              equipment,
            },
          }}
        >
          {info.getValue()}
        </Link>
      ),
    },
    {
      accessorKey: "date",
      header: "Due",
      cell: (info) => {
        const calibration_type = info.row.original.calibration_type;
        if (calibration_type.unit_frequency_units) {
          return `At  ${calibration_type.frequency} ${calibration_type.frequency_unit_name}`;
        }

        return dayjs(info.getValue()).format("DD/MM/YYYY");
      },
    },
    {
      accessorKey: "completed",
      header: "Completed",
      cell: (info) => <DueBadge calibration={info.row.original} />,
    },
  ];

  return (
    <ReactTable
      data={calibrations}
      columns={columns}
      loading={loading}
      disableSearch
    />
  );
};

export default CalibrationTable;
