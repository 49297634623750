import { useQueryClient } from "react-query";
import useApi from "../api/useApi";
import PaginatedList from "../pagination/PaginatedList";
import useUsersMfa from "../timesheets/hooks/useUsersMfa";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import ReactTable from "../table/ReactTable";
import deleteSwal from "../utils/deleteSwal";
import HeaderPage from "../header/HeaderPage";
import useUserGroup from "../hooks/useUserGroup";

interface UserMfa {
  uuid: string;
  two_factor_confirmed_at: string;
  name: string;
  email: string;
}

const UserMfaList = () => {
  const isIt = useUserGroup("I.T.");

  return (
    <>
      <HeaderPage
        titlePage="MFA"
        crumbs={[]}
        pageDescription="This page allows members of the I.T. group manage MFA for users."
      />
      {isIt ? (
        <PaginatedList
          listName="userMfaList"
          indexHook={useUsersMfa}
          list={({ data }: { data?: UserMfa[] }) => {
            return (
              <div className="col-12">
                {data && data.length > 0 && <UserMfaTable users={data} />}
              </div>
            );
          }}
          originalFilters={[]}
        />
      ) : (
        <div className="alert alert-danger">
          You are not authorized to view this page.
        </div>
      )}
    </>
  );
};

const UserMfaTable = ({ users }: { users: UserMfa[] }) => {
  const queryClient = useQueryClient();

  const { takeAction, loading } = useApi();
  const deleteMfa = (user: UserMfa) => {
    return deleteSwal(`MFA from ${user.name}`)
      .then(() =>
        takeAction("destroy", `user/${user.uuid}/two-factor-authentication`),
      )
      .then(() => {
        queryClient.invalidateQueries("multi-factor-authentications");
        toast.success("MFA deleted successfully");
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      id: "delete",
      header: "Delete",
      cell: (info: any) => {
        return (
          <div>
            <Button
              onClick={() => {
                deleteMfa(info.row.original);
              }}
              color="danger"
              outline
              size="sm"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return <ReactTable disableSearch data={users} columns={columns} />;
};

export default UserMfaList;
