import React, { useState } from "react";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import ContactForm from "./ContactForm";
import { FaCheck } from "react-icons/fa";
import { RiArrowGoBackFill, RiArrowGoForwardFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const ClientContacts = () => {
  const { loading, data, setData } = useApi(`xero/clients/contacts`, []);

  const [contact, setContact] = useState(0);

  const moveForward = () => {
    if (contact + 1 === data?.organisation?.length) {
      return;
    }
    setContact(contact + 1);
  };

  const moveBackward = () => {
    if (contact === 0) {
      return;
    }
    setContact(contact - 1);
  };

  return (
    <>
      <Spinner loading={loading} />
      <HeaderPage titlePage="Step 1. Add Clients to Xero" crumbs={[]} />
      <p>
        To start syncing your data to Xero, we need to ensure all your clients
        and suppliers are matched correctly in Xero.
      </p>

      <p>
        {contact + 1} of {data?.organisation?.length}
      </p>
      <div className="bg-white border px-5 py-4 mb-3 position-relative">
        {data?.organisation?.[contact]?.integration && (
          <div
            className="position-absolute"
            style={{
              top: "0.5rem",
              right: "0.5rem",
            }}
          >
            <p className="tx-success mb-0">
              <FaCheck className="tx-16" /> Integration Successfull
            </p>
          </div>
        )}
        <ContactForm
          form={"Client"}
          client={data?.organisation?.[contact]}
          contacts={data?.organisation}
          xeroContacts={data.xero}
          setData={setData}
          moveForward={moveForward}
        />
        <div className="d-flex">
          <Button outline disabled={contact === 0} onClick={moveBackward}>
            <RiArrowGoBackFill />
          </Button>
          <Button
            className="ms-auto"
            outline
            disabled={contact + 1 === data?.organisation?.length}
            onClick={moveForward}
          >
            <RiArrowGoForwardFill />
          </Button>
        </div>
      </div>
      <Link className="btn btn-primary" to="/organisations/settings/links">
        Complete.
      </Link>
    </>
  );
};

export default ClientContacts;
