import React from "react";
import { useHistory } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import DocketTypeForm from "./DocketTypeForm";
import { DocketType } from "./docketTypeTypes";
import { toast } from "react-toastify";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";

const AddDocketType = () => {
  const { takeAction }: IUseApi = useApi();

  const history = useHistory();

  const onSubmit: FormSubmitHandler<DocketType> = (values) => {
    return takeAction("store", "docket-types", {
      ...values,
      custom_fields: values.custom_fields.map((field, index) => ({
        ...field,
        order: index,
      })),
    })
      .then(({ data }) => {
        history.push(`/docket-types/${data.data.uuid}/details`);
        toast.success("Docket type created successfully");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Add New Docket Type"
        crumbs={[
          { name: "Docket Types", link: "/docket-types" },
          { name: "Add Docket Type", link: "", active: true },
        ]}
      />
      <DocketTypeForm
        form="AddDocketType"
        showFields={true}
        onSubmit={onSubmit}
        initialValues={{ custom_fields: [{}] }}
      />
    </>
  );
};

export default AddDocketType;
