import { useAuth } from "../../context/auth-context";
import DetailField from "../clients/DetailField";
import OrganisationDetails from "../clients/organisationDetails";
import ProfileForm from "./ProfileForm";

const Details = ({ supplier, setSupplier }) => {
  const { user } = useAuth();

  return (
    <>
      {!user.is_admin && (
        <div className="row">
          <>
            {supplier.abn_holder && (
              <OrganisationDetails organisation={supplier} />
            )}
            <DetailField
              label="Contact Email"
              value={supplier.contact_email}
              link={
                <a href={`mailto:${supplier.contact_email}`}>
                  {supplier.contact_email}
                </a>
              }
            />
            <DetailField
              label="Contact Phone"
              value={supplier.contact_telephone}
              link={
                <a href={`tel:${supplier.contact_telephone}`}>
                  {supplier.contact_telephone}
                </a>
              }
            />
            <DetailField label="Supplier Type" value={supplier.type.name} />
            <DetailField
              label="Street Address"
              value={
                supplier.address !== "Not Provided" ? supplier.address : null
              }
              link={
                supplier.address !== "Not Provided" ? (
                  <a
                    href={`http://maps.google.com/maps?q=${supplier.address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {supplier.address}
                  </a>
                ) : null
              }
            />
            <DetailField
              label="Postal Address"
              value={
                supplier.postal_address !== "Not Provided"
                  ? supplier.postal_address
                  : null
              }
              link={
                supplier.postal_address !== "Not Provided" ? (
                  <a
                    href={`http://maps.google.com/maps?q=${supplier.postal_address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {supplier.postal_address}
                  </a>
                ) : null
              }
            />
          </>
        </div>
      )}
      <ProfileForm
        supplier={supplier}
        initialValues={supplier}
        setSupplier={setSupplier}
      />
    </>
  );
};

export default Details;
