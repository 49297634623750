import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const UserMustSign = ({
  toSign,
}: {
  toSign: { name: string; uuid: string }[];
}) => {
  const [show, setShow] = useState(true);

  if (!toSign || toSign?.length === 0) {
    return null;
  }

  if (!show) {
    return false;
  }

  return (
    <div className="z-10 bg-info position-fixed top-0 left-0 w-100 d-flex align-content-center justify-content-center p-3">
      <div className="position-relative w-100 d-flex justify-content-center">
        {toSign.map((item) => {
          return (
            <p className="text-white mb-0">
              Please Sign Into{" "}
              <Link
                className="text-white text-decoration-underline"
                to={`/custom-jobs/jobs/${item.uuid}/details`}
              >
                {item.name}
              </Link>
            </p>
          );
        })}
        <Button
          color="link"
          className="position-absolute top-0 text-white p-0"
          style={{ right: 0 }}
          onClick={() => setShow(false)}
        >
          x
        </Button>
      </div>
    </div>
  );
};

export default UserMustSign;
