import React from "react";
import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { UserIndex } from "../user/userTypes";
import { CustomFieldNotification } from "./customFieldTypes";

const CustomFieldNotifications = (
  props: WrappedFieldArrayProps<CustomFieldNotification>,
) => {
  const { fields } = props;
  const { data: users } = useApi("users?filter[employed]=true", []);
  const { data: groups } = useApi("groups", []);

  return (
    <>
      {fields.map((field, index) => {
        const value = fields.get(index);

        return (
          <>
            <FormHeader>Notification {index + 1}</FormHeader>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                required
                validate={required}
                options={[
                  {
                    label: "Group",
                    value: "App\\Models\\Group",
                  },
                  {
                    label: "User",
                    value: "App\\Models\\User",
                  },
                ]}
                name={`${field}.notify_type`}
                label="Notify Group or Person?"
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                required
                validate={required}
                label="Who needs to be notified"
                options={
                  value.notify_type === "App\\Models\\Group"
                    ? formatGroups(groups)
                    : formatUsers(users)
                }
                name={`${field}.to_notify`}
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                required
                validate={required}
                label="Condition"
                options={[
                  {
                    label: "Is",
                    value: "==",
                  },
                  {
                    label: "Is Not",
                    value: "!=",
                  },
                ]}
                name={`${field}.condition`}
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                required={value.condition === "=="}
                validate={value.condition === "==" ? required : null}
                component={RenderField}
                name={`${field}.value`}
                label="Value"
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                component={RenderField}
                required
                validate={required}
                name={`${field}.email`}
                label="Email to be sent"
                information="Use the ${value} variable to include the value of the field in the email. You can also use the ${user} variable to get the user who filled the field."
              />
            </div>
            <div className="col-lg-12 form-group">
              <Button
                color="danger"
                outline
                onClick={() => fields.remove(index)}
              >
                Delete Notification {index + 1}
              </Button>
            </div>
          </>
        );
      })}
      <div className="col-lg-12 form-group">
        <Button
          outline
          onClick={() =>
            fields.push({
              notify_type: "App\\Models\\User",
              condition: "==",
              email: "",
            })
          }
        >
          Add New Notification
        </Button>
      </div>
    </>
  );
};

const formatUsers = (users: UserIndex[]) => {
  return users.map((user) => {
    return {
      label: user.full_name,
      value: user.id,
    };
  });
};

const formatGroups = (groups: { id: number; name: string }[]) => {
  return groups.map((group) => {
    return {
      label: group.name,
      value: group.id,
    };
  });
};
export default CustomFieldNotifications;
