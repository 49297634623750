import { useParams } from "react-router-dom";
import { Field, FormSubmitHandler } from "redux-form";
import swal from "sweetalert";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import FileInput from "../upload/FileInput";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import renderToggleInput from "../utils/renderToggleInput";
import { TestSuite, TestSuiteProps } from "./testSuiteTypes";
import { toast } from "react-toastify";

interface FormValues {
  file: File;
  keep_variables: boolean;
}

interface UploadedFile {
  path: string;
  name: string;
  file_size: number;
  mime_type: string;
}

interface UploadNewTemplateModalProps {
  toggle: Function;
  modal: boolean;
}

const UploadNewTemplateModal = ({
  toggle,
  modal,
  setTestSuite,
}: UploadNewTemplateModalProps & TestSuiteProps) => {
  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();
  const { takeAction }: IUseApi<{}, { data: TestSuite }> = useApi();
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();

  const onSubmit: FormSubmitHandler<FormValues> = (values) => {
    if (!values.keep_variables) {
      return swal({
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancel",
            value: null,
            closeModal: true,
            visible: true,
          },
          remove: {
            text: "Continue",
            value: true,
            closeModal: true,
            className: "btn btn-primary align-baseline",
          },
        },
        title: "Don't keep variables?",
        text: "By not keeping variables, you will lose all variable data you currently have. Only uncheck this option if you are uploading a completely new template.",
      }).then((val?: boolean) => {
        if (val) {
          return submitForm(values);
        }
      });
    }

    return submitForm(values);
  };

  const submitForm = (values: FormValues) => {
    return upload(
      values.file,
      `organisations/${user?.active_organisation.uuid}/test-suite-templates`,
      (file: UploadedFile) => {
        return takeAction("update", `/test-suites/${uuid}/templates`, {
          ...file,
          keep_variables: values.keep_variables,
        })
          .then(({ data }) => {
            setTestSuite(data.data);
            setUploadPercent(0);
            toggle();
            toast.success("New Template Added Successfully");
          })
          .catch(errorSwal);
      },
    );
  };

  return (
    <FormModal
      form="UploadNewTemplate"
      modal={modal}
      toggle={toggle}
      title="Upload new template"
      initialValues={{
        keep_variables: true,
      }}
      onSubmit={onSubmit}
    >
      <div className="col-12 form-group">
        <Field
          percent={uploadPercent}
          component={FileInput}
          name="file"
          label="New Template"
        />
      </div>
      <div className="col-12 form-group">
        <Field
          component={renderToggleInput}
          name="keep_variables"
          label="Keep Existing Variables"
        />
        <small className="d-block">
          Please note, if you keep existing variables, you may need to change
          the sheets of existing variables if the sheet names have changed.
        </small>
      </div>
    </FormModal>
  );
};

export default UploadNewTemplateModal;
