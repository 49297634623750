import React from "react";
import { Field } from "redux-form";

import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";

const ActionFields = ({
  corrective_action_required,
  preventative_action_required,
  verify_actions,
}) => {
  return (
    <>
      <div className="col-lg-4 form-group align-items-center">
        <Field
          label="Is a Corrective Action Required? (Yes/No)"
          name="corrective_action_required"
          component={renderToggleInput}
        />
      </div>
      <div className="col-lg-4 form-group align-items-center">
        <Field
          label="Is a Preventative Action Required? (Yes/No)"
          name="preventative_action_required"
          component={renderToggleInput}
        />
      </div>
      <div className="col-lg-12 form-group align-items-center">
        <Field
          required
          validate={required}
          name="root_cause"
          label="Root Cause"
          textarea
          component={renderField}
        />
      </div>
      {corrective_action_required && (
        <div className="col-lg-12 form-group align-items-center">
          <Field
            required
            validate={required}
            name="corrective_action"
            label="Corrective Action"
            textarea
            component={renderField}
          />
        </div>
      )}
      {preventative_action_required && (
        <div className="col-lg-12 form-group align-items-center">
          <Field
            required
            validate={required}
            name="preventative_action"
            label="Preventative Action"
            textarea
            component={renderField}
          />
        </div>
      )}
      <div className="col-lg-4 form-group align-items-center">
        <Field
          label="Do you need to verify the actions taken are appropriate? (Yes/No)"
          name="verify_actions"
          component={renderToggleInput}
        />
      </div>
      {verify_actions && (
        <div className="col-lg-12 form-group align-items-center">
          <Field
            required
            validate={required}
            name="how_actions_verified"
            label="How Were Actions Verified?"
            textarea
            component={renderField}
          />
        </div>
      )}
    </>
  );
};

export default ActionFields;
