import { useParams } from "react-router";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { ClientFeedback as IClientFeedback } from "../clientFeedback/clientFeedbackTypes";
import useModal from "../hooks/useModal";
import CustomScaleLoader from "../utils/scaleLoader";
import RequestProjectFeedbackModal from "./RequestProjectFeedbackModal";
import ShowProjectFeedback from "./ShowProjectFeedback";

const ProjectFeedback = ({ project }: { project: any }) => {
  const { toggle, modal } = useModal();

  const { number } = useParams<{ number: string }>();

  const { data, setData, loading }: IUseApi<IClientFeedback[]> = useApi(
    `projects/${number}/client-feedback`,
    [],
  );

  const feedback = data as IClientFeedback[];

  if (loading) {
    return <CustomScaleLoader>Fetching feedback...</CustomScaleLoader>;
  }

  return (
    <>
      <ShowRequestFeedback project={project} toggle={toggle} />
      <div className="space-y-3">
        {feedback.map((feedback) => {
          return <ShowProjectFeedback key={feedback.id} feedback={feedback} />;
        })}
      </div>
      <RequestProjectFeedbackModal
        setFeedback={setData}
        feedback={feedback}
        project={project}
        toggle={toggle}
        modal={modal}
      />
    </>
  );
};

const ShowRequestFeedback = ({
  project,
  toggle,
}: {
  project: any;
  toggle: () => void;
}) => {
  const { data: clientFeedbackTemplates, loading } = useApi(
    "client-feedback-templates",
    [],
    true,
  );

  if (loading) {
    return null;
  }

  if (clientFeedbackTemplates.length === 0) {
    return <p>Please Add Client Feedback Templates to Request Feedback</p>;
  }

  if (project.contacts.length === 0) {
    return <p>Please Add Contacts to Request Feedback</p>;
  }

  return (
    <Button color="link" className="p-0 mb-3" onClick={toggle}>
      Request Feedback
    </Button>
  );
};

export default ProjectFeedback;
