import { Link } from "react-router-dom";
import BottomLogo from "../utils/BottomLogo";

export interface PaginatedInformation {
  name: string;
  value?: string | JSX.Element;
  link?: string | JSX.Element;
}

const PaginatedCard = ({
  header,
  info,
  bottomContent,
  colSize = "4",
}: {
  header: JSX.Element;
  info: PaginatedInformation[];
  bottomContent?: JSX.Element;
  colSize?: string;
}) => {
  return (
    <div className={`col-lg-${colSize} col-12 py-2`}>
      <div className="bg-white p-3 border shadow-sm rounded-lg position-relative paginated-card tn-1000">
        <div style={{ zIndex: 100 }} className="position-relative">
          <div className="d-flex pb-4">{header}</div>
          <div className="mt-4">
            <div className="space-y-3">
              {info.map((information, index) => {
                return (
                  <div
                    className="d-flex pb-3"
                    style={{
                      borderBottom:
                        index !== info.length - 1 ? "1px dotted #dee2e6 " : "",
                    }}
                    key={information.name}
                  >
                    <p className="mb-0">{information.name} </p>
                    <p
                      className="mb-0 ms-auto text-truncate"
                      style={{ maxWidth: "70%" }}
                    >
                      {information.link ? (
                        typeof information.link === "string" ? (
                          <Link
                            className=" text-secondary"
                            to={information.link}
                          >
                            {information.value}
                          </Link>
                        ) : (
                          information.link
                        )
                      ) : (
                        information.value ?? "-"
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          {bottomContent}
        </div>
        <BottomLogo />
      </div>
    </div>
  );
};

export default PaginatedCard;
