import React from "react";
import { DashboardSubTitle } from "../utils/DashboardHeader";
import { Badge, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { useState } from "react";

const MissingInsurances = ({ supplier }) => {
  const [popOver, setPopOver] = useState(false);
  const toggle = () => setPopOver(!popOver);

  if (!supplier.missing_insurances || supplier.missing_insurances.length === 0)
    return null;

  return (
    <>
      <DashboardSubTitle>
        <Badge
          color="danger"
          id="InsuranceMissing"
          onMouseEnter={() => {
            setPopOver(true);
          }}
          onMouseLeave={() => setPopOver(false)}
        >
          {supplier.missing_insurances.length} Insurances Missing
        </Badge>
      </DashboardSubTitle>
      <Popover
        placement="top"
        isOpen={popOver}
        target="InsuranceMissing"
        toggle={toggle}
      >
        <PopoverHeader>Insurances Missing</PopoverHeader>
        <PopoverBody>
          {supplier.missing_insurances.map((insurance) => {
            return (
              <p key={insurance.uuid} className="mb-0">
                {insurance.name}
              </p>
            );
          })}
        </PopoverBody>
      </Popover>
    </>
  );
};

export default MissingInsurances;
