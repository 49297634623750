import React, { useState, useEffect } from "react";
import { Field, initialize } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";

const RequiredCompetencyFields = (props) => {
  const {
    competency,
    skillCategories,
    fields,
    index,
    initialValues,
    change,
    position,
    setPosition,
    dispatch,
  } = props;
  const { takeAction } = useApi();
  const [competencySkills, setCompetencySkills] = useState([]);
  const [skillLevels, setSkillLevels] = useState([]);

  const requiredCompetency =
    initialValues &&
    initialValues.required_competencies &&
    initialValues.required_competencies[index];

  useEffect(() => {
    if (requiredCompetency) {
      fetchSkills(requiredCompetency.skill_level.skill_category_uuid).then(
        (data) => {
          const skill = data.find(
            (d) => d.id == requiredCompetency.skill_level.competency_skill_id,
          );
          onChangeSkill(skill);
        },
      );
    }
  }, [initialValues]);

  const fetchSkills = (uuid) => {
    return takeAction(
      "index",
      `skill-categories/${uuid}/competency-skills`,
    ).then(({ data }) => {
      setCompetencySkills(
        data.data.map((d) => ({
          ...d,
          value: d.id,
          label: d.skill,
        })),
      );
      return data.data;
    });
  };

  const onChangeSkill = (skill) => {
    setSkillLevels(
      skill.skill_levels.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    );
  };

  const remove = () => {
    if (requiredCompetency) {
      return deleteSwal(requiredCompetency.skill_level.name)
        .then(() => {
          return takeAction(
            "destroy",
            `required-competencies/${requiredCompetency.uuid}`,
          );
        })
        .then(({ data }) => {
          const newPosition = {
            ...position,
            required_competencies: position.required_competencies.filter(
              (c) => c.uuid !== data.data.uuid,
            ),
          };
          setPosition(newPosition);
          fields.remove(index);
          dispatch(initialize("Position", newPosition));
        })
        .catch((err) => console.log(err));
    }
    fields.remove(index);
  };

  return (
    <>
      <div className="form-group col-lg-4">
        <Field
          name={`${competency}.skill_level.skill_category_id`}
          component={SelectInputAsync}
          options={skillCategories}
          label="Type"
          validate={required}
          onChangeValue={(category) => {
            change(`${competency}.skill_level.competency_skill_id`, "");
            return fetchSkills(category.uuid);
          }}
          required
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name={`${competency}.skill_level.competency_skill_id`}
          component={SelectInputAsync}
          options={competencySkills}
          label="Skill"
          validate={required}
          onChangeValue={(skill) => {
            change(`${competency}.skill_level_id`, "");
            return onChangeSkill(skill);
          }}
          required
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name={`${competency}.skill_level_id`}
          component={SelectInputAsync}
          options={skillLevels}
          label="Level"
          validate={required}
          required
        />
      </div>
      <div className="form-group col-lg-12 d-flex">
        <button
          type="button"
          onClick={remove}
          className="btn btn-outline-danger ms-auto"
        >
          Delete
        </button>
      </div>
    </>
  );
};

export default RequiredCompetencyFields;
