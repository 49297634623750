import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import ApprovalModal from "../approvals/ApprovalModal";
import useModal from "../hooks/useModal";
import TransferModal from "./TransferModal";
import formError from "../utils/formError";

const ApprovalButton = (props) => {
  const { modal, toggle, setModal } = useModal();
  const { user } = useAuth();

  const { modal: transferModal, toggle: toggleTransfer } = useModal();

  const {
    approvalAction,
    wrapperClass = "mg-t-20 form-group w-100",
    buttonClass = "btn btn-outline-info ",
    onSubmitted,
    form = "ApprovalModal",
    canTransfer = false,
    onSuccess,
    transferUsers,
  } = props;

  const { takeAction } = useApi();

  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction(
      "update",
      `approval-actions/${approvalAction.uuid}`,
      values,
    )
      .then(() => {
        toast.success("Approval Updated");
        setModal(false);
        onSubmitted
          ? onSubmitted()
          : history.push(`/users/${user.uuid}/pending-approvals`);
      })
      .catch(formError);
  };

  if (!approvalAction || approvalAction?.action_type !== "Approve") {
    return null;
  }

  if (approvalAction.user_id !== user.id && !user.is_admin) {
    return (
      <div className={wrapperClass}>
        <p className="mb-0 text-secondary">
          Awaiting Approval by{" "}
          <a
            className="text-secondary"
            href={`mailto:${approvalAction.user.email}`}
          >
            {approvalAction.user.name}
          </a>
        </p>
      </div>
    );
  }

  return (
    <>
      <div className={classNames("btn-group", wrapperClass)}>
        <Button
          type="button"
          outline
          color="info"
          className={buttonClass}
          onClick={toggle}
          block
        >
          Take Approval Action
        </Button>
        {canTransfer && (
          <Button color="info" onClick={toggleTransfer} outline block>
            Transfer Approval
          </Button>
        )}
      </div>
      <ApprovalModal
        {...props}
        form={form}
        toggle={toggle}
        modal={modal}
        onSubmit={onSubmit}
      />
      <TransferModal
        modal={transferModal}
        toggle={toggleTransfer}
        approvalAction={approvalAction}
        onSuccess={onSuccess}
        transferUsers={transferUsers}
      />
    </>
  );
};

export default ApprovalButton;
