import { CustomFieldValue, FieldAttributes } from "./customFieldTypes";
import EquipmentFieldInformation from "./EquipmentFieldInformation";

const FieldInformation = (props: {
  formValues: { custom_fields: CustomFieldValue };
  field_attributes: FieldAttributes;
  uuid: string;
  currentValue: any;
  showInformation: boolean;
}) => {
  const { showInformation = true } = props;

  if (!showInformation) {
    return null;
  }

  switch (props.field_attributes?.url) {
    case "/custom-fields/equipment":
      return <EquipmentFieldInformation {...props} />;
    default:
      return null;
  }
};

export default FieldInformation;
