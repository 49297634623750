import { Field, InjectedFormProps, WrappedFieldArrayProps } from "redux-form";
import {
  ProjectAllowance,
  ProjectAllowanceEnum,
} from "../projects/projectTypes";
import renderToggleInput from "../utils/renderToggleInput";
import RenderField from "../utils/renderField";
import Autocomplete from "../utils/Autocomplete";
import { useEffect } from "react";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { money } from "../form/formatters";
import FormHeader from "../utils/FormHeader";
import required from "../utils/required";

type AllowanceFormItem = ProjectAllowance & {
  include: boolean;
  from: string;
  to: string;
  from_id?: string;
  to_id?: string;
  duration?: string;
  notes?: string;
};

export const AllowanceFields = ({
  fields,
  change,
}: WrappedFieldArrayProps<AllowanceFormItem> & {
  change: InjectedFormProps["change"];
}) => {
  return (
    <div className="space-y-3 px-4">
      {fields.map((field, index) => {
        const allowance = fields.get(index);

        return (
          <Allowance
            key={index}
            index={index}
            field={field}
            allowance={allowance}
            change={change}
          />
        );
      })}
    </div>
  );
};

export const Allowance = ({
  index,
  field,
  allowance,
  change,
}: {
  index: number;
  field: string;
  allowance: AllowanceFormItem;
  change: InjectedFormProps["change"];
}) => {
  const {
    takeAction,
  }: IUseApi<
    {},
    {
      data: {
        distance: number;
        duration: number;
      };
    }
  > = useApi();

  useEffect(() => {
    if (allowance.from_id && allowance.to_id) {
      takeAction("store", "calculated-distance", {
        origin: allowance.from,
        destination: allowance.to,
      }).then(({ data }) => {
        change(`${field}.quantity`, (data.data.distance / 1000).toFixed(2));
        change(`${field}.duration`, data.data.duration);
      });
    }
  }, [allowance.from_id, allowance.to_id]);

  return (
    <div
      className="row bg-white p-3 rounded-lg shadow-sm border position-relative"
      key={index}
    >
      <div className="col-lg-12 d-flex align-items-center">
        <div>
          <p className="text-dark mb-0 fw-bolder">{allowance.name}</p>
          <small>
            {money.format(allowance.amount)} per {allowance.unit_description}
          </small>
        </div>
        <div className="d-flex align-items-center space-x-3 ms-auto">
          <Field
            name={`${field}.include`}
            className="toggle-sm"
            icons={false}
            component={renderToggleInput}
          />
          <label htmlFor={`${field}.include`} className="mb-0">
            Include
          </label>
        </div>
      </div>

      <div className="col-12 form-group mt-3">
        <Field
          name={`${field}.notes`}
          label="Notes"
          component={RenderField}
          textarea
        />
      </div>
      {allowance.unit == ProjectAllowanceEnum.Kilometre && (
        <>
          <div className="col-lg-12 form-group mt-3">
            <FormHeader>Distance</FormHeader>
          </div>

          <div className="col-lg-4">
            <Field
              name={`${field}.from`}
              component={Autocomplete}
              label="From"
              className="form-control"
              handleSelect={(value: string, placeId: string) => {
                change(`${field}.from`, value);
                change(`${field}.from_id`, placeId);
                change(
                  `${field}.notes`,
                  `${allowance.notes ?? ""} Travelled from ${value}`,
                );
              }}
            />
          </div>
          <div className="col-lg-4">
            <Field
              name={`${field}.to`}
              label="To"
              component={Autocomplete}
              className="form-control"
              handleSelect={(value: string, placeId: string) => {
                change(`${field}.to`, value);
                change(`${field}.to_id`, placeId);
                change(
                  `${field}.notes`,
                  `${allowance.notes ?? ""}\n Travelled to ${value}`,
                );
              }}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              name={`${field}.quantity`}
              label="Number of Kilometres"
              component={RenderField}
              className="form-control"
              validate={allowance.include ? required : null}
              required={allowance.include}
            />
            {allowance.duration && (
              <small className="d-block">{allowance.duration}</small>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AllowanceFields;
