import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const DeleteExpenseClaim = ({
  expenseClaim,
}: {
  expenseClaim: { uuid: string };
}) => {
  const { user } = useAuth();

  const { takeAction, loading }: IUseApi = useApi();

  const history = useHistory();

  const deleteExpenseClaim = () => {
    return deleteSwal()
      .then(() => takeAction("destroy", `expense-claims/${expenseClaim.uuid}`))
      .then(() => {
        history.push(`/`);
        toast.success("Expense Claim Deleted");
      })
      .catch(errorSwal);
  };

  if (!user?.is_admin) {
    return null;
  }

  return (
    <Button
      disabled={loading}
      color="danger"
      onClick={deleteExpenseClaim}
      outline
      block
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </Button>
  );
};

export default DeleteExpenseClaim;
