import { toast } from "react-toastify";
import { Field, initialize } from "redux-form";
import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import ClipLoader from "react-spinners/ClipLoader";
import required from "../utils/required";
import { primary } from "../utils/Colours";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import RenderField from "../utils/renderField";

const ItemFields = ({
  member,
  index,
  fields,
  branches,
  accountCodes,
  initialValues,
  loading,
  recurringPurchase,
  setRecurringPurchase,
}) => {
  const { takeAction, loading: deleting } = useApi();

  const deleteItem = () => {
    const val = initialValues?.prices && initialValues.prices[index];

    if (val) {
      return deleteSwal(val.description)
        .then(() => {
          return takeAction("destroy", `recurring-payment-prices/${val.uuid}`);
        })
        .then(({ data }) => {
          toast.success("Item deleted!");
          fields.remove(index);
          const newRecurringPurchase = {
            ...recurringPurchase,
            prices: recurringPurchase.prices.filter(
              (price) => price.uuid !== val.uuid,
            ),
          };

          initialize("RecurringPurchasePrices", newRecurringPurchase);

          setRecurringPurchase(newRecurringPurchase);
        })
        .catch((err) => {
          errorSwal(err);
        });
    }

    return fields.remove(index);
  };

  const field = fields.get(index);

  if (deleting)
    return (
      <div className="mb-3 d-flex align-items-center">
        <ClipLoader color={primary} /> Deleting...
      </div>
    );

  return (
    <>
      <div className={`col-12 ${index !== 0 ? "mt-5" : ""}`}>
        {/* <p className="mb-3 fw-bolder tx-inverse tx-14">
          Item {index + 1}
        </p> */}
      </div>
      <div className="col-lg-4 form-group">
        <Field
          label="Description"
          component={renderField}
          name={`${member}.description`}
          required
          validate={required}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          label="Price"
          component={renderField}
          name={`${member}.price`}
          required
          validate={required}
          {...money}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          label="Account Code"
          component={SelectInputAsync}
          isLoading={loading}
          options={accountCodes}
          name={`${member}.chart_of_accounts_id`}
          required
          validate={required}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          label="Branch"
          component={SelectInputAsync}
          isLoading={loading}
          options={branches}
          name={`${member}.branch_id`}
          required
          validate={required}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          component={RenderField}
          name={`${member}.quantity`}
          label="Estimated Quantity"
          type="number"
          validate={required}
          required
        />
      </div>
      <div className="col-lg-4 form-group d-flex align-items-end ms-auto">
        <button
          type="button"
          className="btn btn-outline-danger w-100"
          onClick={deleteItem}
        >
          Delete
        </button>
      </div>
    </>
  );
};

export default ItemFields;
