import React from "react";
import { useParams } from "react-router";
import { CpirTypeProfilePages } from "./CpirTypeProfile";
import NotFound from "../404";
import ProfileForm from "../customFields/ProfileForm";
import { CPIRType } from "./cpirTypeTypes";
import CpirTypeForm, { CpirTypeFormData } from "./CpirTypeForm";
import { FormSubmitHandler } from "redux-form";

interface ShowCpirTypePageProps {
  type: CPIRType;
  setType: (type: CPIRType) => void;
  onSubmitTypeForm: FormSubmitHandler<CpirTypeFormData>;
}

const ShowCpirTypePage = (props: ShowCpirTypePageProps) => {
  const { type, setType, onSubmitTypeForm } = props;

  const { page } = useParams<{ page: CpirTypeProfilePages }>();

  switch (page) {
    case "details":
      return (
        <CpirTypeForm
          showCustomFields={false}
          form="EditCpirType"
          initialValues={type}
          onSubmit={onSubmitTypeForm}
        />
      );
    case "fields":
      return (
        <ProfileForm
          initialValues={type}
          {...props}
          modelId={type.id}
          modelType="App\Models\CpirType"
          onSuccess={(data: { data: any[] }) =>
            setType({
              ...type,
              custom_fields: data.data,
            })
          }
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowCpirTypePage;
