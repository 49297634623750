import React from "react";
import { Alert as BootstrapAlert, Button } from "reactstrap";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const SyncIntegrationEstimation = ({ purchase, setPurchase }) => {
  const { takeAction, loading } = useApi();

  const syncIntegration = () => {
    return takeAction("store", `xero/purchases/${purchase.uuid}`, {
      xero_id: purchase.estimated_integration[0].InvoiceID,
    })
      .then(({ data }) => {
        setPurchase(data.data);
        toast.success("Purchase Synced");
      })
      .catch(errorSwal);
  };

  if (
    !purchase.estimated_integration ||
    purchase.estimated_integration?.length === 0
  )
    return null;

  return (
    <BootstrapAlert color="primary" className="">
      <p className="mb-0">
        {purchase.name} isn't yet synced with your accounting integration
      </p>
      <p className="mb-0">
        We found an purchase with the same name in the integration{" "}
        <Button
          disabled={loading}
          onClick={syncIntegration}
          color="link"
          className="px-0"
          size="sm"
        >
          {isSubmitting(loading, "Click Here  to sync.", "Syncing...")}
        </Button>{" "}
      </p>
    </BootstrapAlert>
  );
};

export default SyncIntegrationEstimation;
