import React from "react";
import { Field } from "redux-form";
import CPIR_TYPES from "../../data/cpir_types";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import ActionFields from "./ActionFields";
import CpirParentForm from "./CpirParentForm";

const OldForm = (props: any) => {
  return (
    <CpirParentForm
      typeField={(props: any) => {
        return (
          <Field
            required
            validate={required}
            name="type"
            label="Type"
            options={CPIR_TYPES}
            component={SelectInput}
          />
        );
      }}
      {...props}
    >
      {(props: any) => {
        const { initialValues, further_action_required, closed_out } = props;

        if (!initialValues) {
          return null;
        }

        return (
          <>
            <div className="col-lg-12 form-group">
              <Field
                required={closed_out}
                validate={closed_out ? required : null}
                name="investigation"
                label="Investigation - What happened to make this occur?"
                textarea
                component={RenderField}
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                label="Is further Action Required? (Yes/No)"
                name="further_action_required"
                component={renderToggleInput}
              />
            </div>

            {further_action_required && <ActionFields {...props} />}
          </>
        );
      }}
    </CpirParentForm>
  );
};

export default OldForm;
