import React from "react";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";
import HeaderPage from "../header/HeaderPage";
import axios from "../api/api";

const Add = (props) => {
  const { history } = props;

  const onSubmit = (values) => {
    return axios
      .post(`/qualifications`, values)
      .then(() => {
        history.push("/qualifications");
        toast.success("Qualification created");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add New Qualification"
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisations" },
          {
            link: "/qualifications",
            name: "Licenses/Registrations/Qualifications",
          },
          {
            link: "",
            name: "Add New License/Registration/Qualification",
            active: true,
          },
        ]}
      />
      <Form onSubmit={onSubmit} />
    </>
  );
};

export default Add;
