import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  getFormValues,
  reduxForm,
} from "redux-form";
import useApi from "../api/useApi";
import RenderField from "../utils/renderField";
import { WebhookKey } from "../webhooks/webhookTypes";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";
import FormHeader from "../utils/FormHeader";
import { Button } from "reactstrap";
import { connect } from "react-redux";

interface MicrosoftWebhooksValues {
  [WebhookKey.OnMicrosoftDelete]: {
    value: string;
    test: {
      user_principal_name: string;
      to_forward_to_email: string;
    };
  };
  [WebhookKey.OnMicrosoftReinstate]: {
    value: string;
    test: {
      user_principal_name: string;
      to_forward_to_email: string;
    };
  };
}

interface MicrosoftWebhooksProps {
  formValues: MicrosoftWebhooksValues;
}

interface WebhookUrl {
  key: WebhookKey;
  uuid: string;
  variables: {
    label: string;
    name: string;
  }[];
}

const MicrosoftWebhooks = (
  props: InjectedFormProps<MicrosoftWebhooksValues, MicrosoftWebhooksProps> &
    MicrosoftWebhooksProps,
) => {
  const { handleSubmit, formValues } = props;

  const { data, setData }: IUseApiWithData<WebhookUrl[]> = useApi(
    "webhook-urls?filter[key]=on_microsoft_delete,on_microsoft_reinstate",
    [],
  );

  const { takeAction, loading }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<
    MicrosoftWebhooksValues,
    MicrosoftWebhooksProps
  > = (values) => {
    return takeAction("update", "microsoft/webhook-urls", values)
      .then(({ data }) => {
        setData(data.data);
        toast.success("Webhook URL updated successfully");
      })
      .catch(formError);
  };

  const testWebhook = (key: WebhookKey) => {
    const webhook = data.find((d) => d.key === key);

    return takeAction(
      "store",
      `webhook-urls/${webhook?.uuid}/test`,
      formValues[key].test,
    )
      .then(({ data }) => {
        toast.success("Webhook Tested Successfully");
      })
      .catch(formError);
  };

  const deleteWebhookUrl = data.find(
    (d) => d.key === WebhookKey.OnMicrosoftDelete,
  );

  const reinstateWebhookUrl = data.find(
    (d) => d.key === WebhookKey.OnMicrosoftReinstate,
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name={`${WebhookKey.OnMicrosoftDelete}.value`}
            label="User Removed from Microsoft URL"
          />
          {deleteWebhookUrl && (
            <small className="d-block">
              Webhook Url created, not shown for security reasons
            </small>
          )}
        </div>
        {deleteWebhookUrl && (
          <>
            <div className="mb-4 mt-3">
              <p className="text-dark">Test User Cancelled Webhook</p>
            </div>
            <div className="col-lg-5 form-group">
              <Field
                component={RenderField}
                name={`${WebhookKey.OnMicrosoftDelete}.test.user_principal_name`}
                label="User Principal Name"
              />
            </div>
            <div className="col-lg-5 form-group">
              <Field
                component={RenderField}
                name={`${WebhookKey.OnMicrosoftDelete}.test.to_forward_to_email`}
                label="To Forward To"
              />
            </div>
            <div className="col-lg-2 form-group d-flex align-items-end">
              <Button
                color="primary"
                block
                outline
                onClick={(e) => testWebhook(WebhookKey.OnMicrosoftDelete)}
              >
                Test
              </Button>
            </div>
          </>
        )}
        <FormHeader>Reinstated</FormHeader>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name={`${WebhookKey.OnMicrosoftReinstate}.value`}
            label="User Reinstated from Microsoft URL"
          />
          {reinstateWebhookUrl && (
            <small className="d-block">
              Webhook Url created, not shown for security reasons
            </small>
          )}
        </div>
        {reinstateWebhookUrl && (
          <>
            <FormHeader>Test Webhook</FormHeader>
            <div className="col-lg-5 form-group">
              <Field
                component={RenderField}
                name={`${WebhookKey.OnMicrosoftReinstate}.test.user_principal_name`}
                label="User Principal Name"
              />
            </div>
            <div className="col-lg-2 form-group d-flex align-items-end">
              <Button
                color="primary"
                block
                outline
                onClick={(e) => testWebhook(WebhookKey.OnMicrosoftReinstate)}
              >
                Test
              </Button>
            </div>
          </>
        )}
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<MicrosoftWebhooksValues, MicrosoftWebhooksProps>({
  form: "MicrosoftWebhooks",
});

const mapStateToProps = (state: any) => {
  return {
    formValues: getFormValues("MicrosoftWebhooks")(state),
  };
};

/** @ts-ignore */
export default connect(mapStateToProps, {})(form(MicrosoftWebhooks));
