import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import fileDownload from "js-file-download";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button, UncontrolledTooltip } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { Equipment, EquipmentStatus } from "./equipmentTypes";
import useEquipment from "./hooks/useEquipment";
import { EquipmentType, CalibrationUnit } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";
import Tabs from "../tabs/Tabs";
import ReactTable from "../table/ReactTable";
import useModal from "../hooks/useModal";
import AddEquipmentInvoiceModal from "./AddEquipmentInvoiceModal";
import { useQueryClient } from "react-query";

dayjs.extend(localizedFormat);

const EquipmentList = () => {
  const { data } = useApi("organisation-branches", []);
  const { user } = useAuth();

  const [baseFilters, setBaseFilters] = useState(equipmentFilters);

  useEffect(() => {
    setBaseFilters([
      ...equipmentFilters,
      {
        label: "Branch",
        name: "branch_id",
        options: data.map((branch: { name: string; id: number }) => {
          return {
            label: branch.name,
            value: branch.id,
          };
        }),
      },
    ]);
  }, [data]);

  const tabs = [
    {
      title: "Cards",
      component: (
        <PaginatedList
          listName="equipmentList"
          indexHook={useEquipment}
          originalFilters={baseFilters}
          itemCard={({ item }: { item: Equipment }) => (
            <PaginatedCard
              info={info(item)}
              header={<EquipmentHeader equipment={item} />}
              bottomContent={<EquipmentFooter equipment={item} />}
            />
          )}
        />
      ),
    },
    {
      title: "Table",
      component: (
        <PaginatedList
          listName="equipmentList"
          indexHook={useEquipment}
          originalFilters={baseFilters}
          list={({ data }: { data?: Equipment[] }) => (
            <div>
              <EquipmentTable equipment={data ?? []} />
            </div>
          )}
        />
      ),
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/equipment-asset-management/equipment/" />
      <HeaderPage
        titlePage="Equipment"
        pageDescription={
          <>
            Track individual pieces of equipment in your organization. You can
            add new equipment, view existing equipment, and manage their
            calibrations and assignments.
          </>
        }
        relatedLinks={[
          {
            name: "Equipment Types",
            link: "/equipment-types",
            model: EquipmentType,
          },
          {
            name: "Calibrations",
            link: "/calibration-units",
            model: CalibrationUnit,
          },
        ]}
        crumbs={[{ link: "equipment", name: "Equipment", active: true }]}
      />
      <div className="mb-3 d-flex space-x-3">
        {user?.hasAccessTo("App\\Models\\Equipment", "create") && (
          <Link
            to="/equipment/add"
            className="btn btn-outline-primary  p-1 px-2"
          >
            Add new Equipment
          </Link>
        )}
        {user?.hasAccessTo("App\\Models\\Equipment", "create") && (
          <Link
            to="/equipment/import"
            className="btn btn-outline-primary  p-1 px-2"
          >
            Import Equipment
          </Link>
        )}
        {user?.is_admin ? (
          <Link
            to="/equipment/custom-fields"
            className="btn btn-outline-primary p-1 px-2"
          >
            Equipment Custom Fields
          </Link>
        ) : null}
        <DownloadData />
      </div>
      <Tabs
        tabs={tabs}
        startingTab={parseInt(
          localStorage.getItem("equipment_list_tab") ?? "0",
        )}
        onSelect={(i) => {
          localStorage.setItem("equipment_list_tab", `${i}`);
        }}
      />
    </>
  );
};

const EquipmentTable = ({ equipment }: { equipment: Equipment[] }) => {
  const queryClient = useQueryClient();

  const columns = useMemo(() => {
    const columns = [
      {
        accessorKey: "name",
        header: "Name",
        cell: (info: any) => {
          const equipment = info.row.original as Equipment;

          return (
            <div className="d-flex">
              <Avatar
                name={equipment.type.type}
                colour={
                  equipment.withdrawn == EquipmentStatus.OutOfService
                    ? "warning"
                    : equipment.is_in_calibration
                    ? "success"
                    : "danger"
                }
              />
              <div className="ms-1">
                <Link to={`equipment/${equipment.uuid}/details`}>
                  {equipment.name}
                </Link>
                <p className="mb-0">{equipment.branch.name}</p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "type.type",
        header: "Type",
      },
      {
        accessorKey: "make",
        header: "Make",
      },
    ];

    if (equipment.find((e) => e.next_invoice_due)) {
      columns.push({
        accessorKey: "next_invoice_due",
        header: "Next Invoice Due",
        /** @ts-ignore */
        cell: (info: any) => {
          const equipment = info.row.original as Equipment;

          if (!equipment.next_invoice_due) return null;

          return dayjs(equipment.next_invoice_due).format("DD/MM/YYYY");
        },
      });

      columns.push({
        accessorKey: "actions",
        header: "Actions",
        /** @ts-ignore */
        cell: (info: any) => {
          const equipment = info.row.original as Equipment;

          const { toggle, modal } = useModal();

          if (!equipment.recurring_payment?.id) {
            return;
          }

          return (
            <>
              <Button onClick={toggle} size="sm" outline color="primary">
                Invoice
              </Button>
              <AddEquipmentInvoiceModal
                modal={modal}
                toggle={toggle}
                equipment={equipment}
                onSuccess={() => {
                  toggle();
                  queryClient.invalidateQueries("equipment");
                }}
                initialValues={{
                  name: equipment.default_invoice_name ?? equipment.name,
                  prices: equipment.prices,
                }}
              />
            </>
          );
        },
      });
    }

    return columns;
  }, [equipment, queryClient]);

  return <ReactTable disableSearch={true} data={equipment} columns={columns} />;
};

const info = (equipment: Equipment): PaginatedInformation[] => {
  const values = [
    {
      name: "Type",
      value: equipment.type.type,
    },
    {
      name: "Make",
      value: equipment.make,
    },
    {
      name: "Model",
      value: equipment.model,
    },
    {
      name: "Serial Number",
      value: equipment.serial_number,
    },
    {
      name: "Status",
      value: EquipmentStatus[equipment.withdrawn],
    },
    {
      name: "Branch",
      value: equipment.branch.name,
    },
    {
      name: "Group",
      value: equipment.group.name,
    },
    {
      name: "Call Sign",
      value: equipment.call_sign,
    },
    {
      name: "Sub Location",
      value: equipment.sub_location,
    },
    {
      name: "Project",
      value: equipment.project?.name ?? "-",
      link: equipment.project?.link ?? "",
    },
  ];

  if (equipment.next_invoice_due) {
    values.push({
      name: "Next Invoice Due",
      value: dayjs(equipment.next_invoice_due).format("DD/MM/YYYY"),
    });
  }

  return values;
};

const EquipmentHeader = ({ equipment }: { equipment: Equipment }) => {
  const tooltipId = useMemo(
    () => `equipment_title_${equipment.uuid}`,
    [equipment.uuid],
  );

  if (!tooltipId) {
    return null;
  }

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar
            name={equipment.type.type}
            colour={
              equipment.withdrawn == EquipmentStatus.OutOfService
                ? "warning"
                : equipment.is_in_calibration
                ? "success"
                : "danger"
            }
          />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`equipment/${equipment.uuid}/details`}>
              {equipment.name}
            </Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {equipment.name}
          </UncontrolledTooltip>
          <p className="mb-0">{equipment.branch.name}</p>
        </div>
      </div>
    </div>
  );
};

const EquipmentFooter = ({ equipment }: { equipment: Equipment }) => {
  return (
    <div className="d-flex">
      <div
        className={`ms-auto shadow rounded-pill text-white tx-10 text-center px-2 ${
          equipment.withdrawn == EquipmentStatus.OutOfService
            ? "bg-warning"
            : equipment.is_in_calibration
            ? "bg-success"
            : "bg-danger"
        }`}
      >
        {equipment.withdrawn == EquipmentStatus.OutOfService
          ? "Out of Service"
          : equipment.is_in_calibration
          ? "In Calibration"
          : "Out of Calibration"}
      </div>
    </div>
  );
};

const equipmentFilters: EquipmentFilters[] = [
  {
    label: "Status",
    name: "withdrawn",
    multiple: true,
    options: [
      {
        label: "In Service",
        value: EquipmentStatus.InService,
      },
      {
        label: "Out of Service",
        value: EquipmentStatus.OutOfService,
      },
      {
        label: "Withdrawn",
        value: EquipmentStatus.Withdrawn,
      },
      {
        label: "Hired Out",
        value: EquipmentStatus.HiredOut,
      },
      {
        label: "Hiring",
        value: EquipmentStatus.Hiring,
      },
      {
        label: "Loaned Out",
        value: EquipmentStatus.LoanedOut,
      },
      {
        label: "Loaned In",
        value: EquipmentStatus.LoanedIn,
      },
      {
        label: "On Hired",
        value: EquipmentStatus.OnHired,
      },
      {
        label: "Off Hired",
        value: EquipmentStatus.OffHired,
      },
    ],
  },
  {
    label: "Calibration Status",
    name: "in_calibration",
    options: [
      {
        label: "In Calibration",
        value: true,
      },
      {
        label: "Out of Calibration",
        value: false,
      },
    ],
  },
  {
    label: "Invoice Due",
    name: "invoice_due_between",
    options: [
      {
        label: "This Month",
        value: `${dayjs().startOf("month").format("YYYY-MM-DD")},${dayjs()
          .endOf("month")
          .format("YYYY-MM-DD")}`,
      },
      {
        label: "This Week",
        value: `${dayjs().startOf("week").format("YYYY-MM-DD")},${dayjs()
          .endOf("week")
          .format("YYYY-MM-DD")}`,
      },
      {
        label: "Next Week",
        value: `${dayjs()
          .startOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD")},${dayjs()
          .endOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD")}`,
      },
    ],
  },
];

const DownloadData = () => {
  const { loading, takeAction }: IUseApi = useApi();

  const downloadData = () => {
    return takeAction("store", "equipment-exports")
      .then((res) =>
        fileDownload(
          res.data,
          `Equipment ${dayjs().format("DD-MM-YYYY, LT")}.csv`,
        ),
      )
      .catch(errorSwal);
  };
  return (
    <button
      type="button"
      disabled={loading}
      className="btn btn-link  p-1 px-2"
      onClick={downloadData}
    >
      {isSubmitting(
        loading,
        <>
          <i className="fa fa-download tx-primary tx-16" /> Download CSV
        </>,
        "Generating...",
      )}
    </button>
  );
};

type EquipmentFilters = IFilter<
  "withdrawn" | "in_calibration" | "branch_id" | "invoice_due_between",
  EquipmentStatus | boolean | string
>;

export default EquipmentList;
