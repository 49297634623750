import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { Field, FormSubmitHandler, InjectedFormProps } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import { TestSuite, TestSuiteTest } from "../testSuites/testSuiteTypes";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";

interface TestRequestSample {
  id: number;
  number: string;
}

interface TestSuiteTestForm {
  test_suite_id: number;
  samples: {
    label: string;
    value: number;
  }[];
}

const FORM_NAME = "AddTestSuite";

const AddTestSuiteTestModal = ({
  toggle,
  modal,
  setTests,
  tests,
}: {
  toggle: Function;
  modal: boolean;
  tests: TestSuiteTest[];
  setTests: (tests: TestSuiteTest[]) => void;
}) => {
  const { takeAction }: IUseApi<{}, { data: TestSuiteTest }> = useApi();
  const { uuid } = useParams<{ uuid: string }>();
  const { data }: IUseApi<TestRequestSample[]> = useApi(
    `test-requests/${uuid}/samples`,
    [],
  );

  const onSubmit: FormSubmitHandler<TestSuiteTestForm> = (values, _, props) => {
    const data = {
      ...values,
      samples: values.samples.map(({ value }) => value),
    };

    return takeAction("store", `test-requests/${uuid}/test-suite-tests`, data)
      .then(({ data }) => {
        toast.success("Test Added");
        setTests([...tests, data.data]);
        toggle();
        props?.reset?.();
      })
      .catch(formError);
  };

  return (
    <FormModal
      form={FORM_NAME}
      toggle={toggle}
      modal={modal}
      onSubmit={onSubmit}
      title="Add New Test Suite"
    >
      {({ change }: InjectedFormProps) => {
        const addAllSamples = () => {
          change("samples", formatSamples(data ?? []));
        };

        return (
          <>
            <div className="col-12 form-group">
              <Field
                component={SelectInput}
                url="/test-suites"
                name="test_suite_id"
                label="Test Suite"
                formatData={(data: TestSuite[]) => {
                  return data.map((suite) => {
                    return {
                      label: suite.name,
                      value: suite.id,
                    };
                  });
                }}
              />
            </div>
            <div className="col-12 form-group">
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: "-10px" }}
              >
                <label className="form-control-label mb-0 tx-inverse tx-semibold">
                  Samples
                </label>
                <Button
                  onClick={addAllSamples}
                  className="ms-auto"
                  color="primary"
                  outline
                  size="sm"
                >
                  Add All
                </Button>
              </div>
              <Field
                component={SelectInput}
                options={formatSamples(data ?? [])}
                isMulti={true}
                name="samples"
              />
            </div>
          </>
        );
      }}
    </FormModal>
  );
};

const formatSamples = (samples: TestRequestSample[]) => {
  return samples?.map((sample) => {
    return {
      label: sample.number,
      value: sample.id,
    };
  });
};

export default AddTestSuiteTestModal;
