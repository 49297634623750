import React from "react";
import { IFilter } from "../hooks/useFilter";
import { JobStatus } from "../jobs/jobTypes";
import { JobFilterType } from "./ProjectManagerJobs";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { AiOutlineCheck } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import { InvoiceStatus } from "../invoices/invoiceTypes";
const JobManagerFilters = ({
  filters,
  toggleFilter,
  filterCount,
}: {
  filters: IFilter<
    JobFilterType,
    JobStatus | boolean | InvoiceStatus | string
  >[];
  toggleFilter: (filterName: JobFilterType, optionValue: any) => void;
  filterCount: number;
}) => {
  return (
    <UncontrolledDropdown className="ms-3">
      <DropdownToggle color="link" caret className="p-1 text-secondary">
        <FaFilter className="tx-16" />
        <Badge color="white" className="p-1">
          {filterCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu>
        {filters.map((filter) => {
          return (
            <React.Fragment key={filter.name}>
              <DropdownItem header>{filter.label}</DropdownItem>
              {filter.options?.map((option, index) => {
                return (
                  <DropdownItem
                    key={index}
                    onClick={() => toggleFilter(filter.name, option.value)}
                  >
                    {option.label}{" "}
                    {option.selected ? (
                      <AiOutlineCheck className="ms-1 tx-success" />
                    ) : null}
                  </DropdownItem>
                );
              })}
            </React.Fragment>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default JobManagerFilters;
