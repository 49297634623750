import React from "react";
import FormHeader from "../utils/FormHeader";
import CustomFormOverview from "../customFields/CustomFormOverview";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { IUseApi } from "../api/apiTypes";
import dayjs from "dayjs";
import isSubmitting from "../utils/submitting";

const PerformanceReviewOverview = ({
  performanceReview,
  setPerformanceReview,
}: {
  performanceReview: any;
  setPerformanceReview: Function;
}) => {
  const { takeAction, loading }: IUseApi<{}, { data: any }> = useApi();

  const completePerformanceReview = () => {
    return takeAction(
      "update",
      `performance-reviews/${performanceReview.uuid}`,
      {
        acknowledged_at: dayjs().utc().format("YYYY-MM-DD HH:mm:ss"),
      },
    )
      .then(({ data }) => {
        toast.success("Performance Review Completed");
        setPerformanceReview(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <FormHeader>Employee Assesment</FormHeader>
      <CustomFormOverview
        customForm={performanceReview?.employee_answers.custom_fields}
      />
      <FormHeader className="mt-5">Manager Assesment</FormHeader>
      <CustomFormOverview
        customForm={performanceReview?.manager_answers.custom_fields}
      />
      <Button
        color="primary"
        disabled={loading}
        className="mt-3"
        onClick={completePerformanceReview}
      >
        {isSubmitting(loading, "Complete", "Completing...")}
      </Button>
    </>
  );
};

export default PerformanceReviewOverview;
