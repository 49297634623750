import React from "react";

const FormHeader = ({
  children,
  className = "col-12",
  labelClass = "",
  extra = <></>,
}) => {
  return (
    <div className={className}>
      <label className={`section-title mt-3 ${labelClass}`}>{children}</label>
      {extra}
      <hr className="w-100" />
    </div>
  );
};

export default FormHeader;
