import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

const DocumentBreadCrumbs = ({
  path,
  url,
  highlightedDocuments,
  setHighlightedDocuments,
}) => {
  const { takeAction } = useApi();

  const queryClient = useQueryClient();

  const { folderUuid } = useParams();

  const onDrop = (url, id) => {
    document
      .getElementById(id)
      .classList.remove("bg-gray-400", "rounded", "p-1");

    if (!folderUuid) {
      return;
    }

    const cantBeMoved = highlightedDocuments.filter(
      (document) =>
        document.default_folder_type !== null ||
        document.folder_default_folder_type !== null,
    );

    if (cantBeMoved.length > 0) {
      const names = cantBeMoved.map(({ name }) => name).join(", ");

      toast.warning(
        `${names} ${
          cantBeMoved.length === 1 ? "is" : "can't"
        } unable to be moved as ${
          cantBeMoved.length === 1 ? "it is" : "they are"
        } either default folders or children of default folders.`,
      );

      return;
    }

    takeAction("store", url, {
      documents: highlightedDocuments.map(({ id }) => id),
    })
      .then(() => {
        queryClient.invalidateQueries("standard-documents");
        toast.success("Documents Moved");
        setHighlightedDocuments([]);
      })
      .catch(errorSwal);
  };

  return (
    <div>
      <ol className="breadcrumb slim-breadcrumb">
        <li className="breadcrumb-item">
          <Link
            {...styleDrag("home-documents-url")}
            id="home-documents-url"
            onDrop={() =>
              onDrop(`dropped-home-documents`, "home-documents-url")
            }
            to={`/${url}`}
          >
            Home
          </Link>
        </li>
        {path?.length > 0 &&
          path.map((crumb, index) => (
            <li key={crumb.uuid} className="breadcrumb-item">
              <Crumb
                index={index}
                path={path}
                crumb={crumb}
                url={url}
                onDrop={onDrop}
              />
            </li>
          ))}
      </ol>
    </div>
  );
};

const Crumb = ({ index, path, crumb, url, onDrop }) => {
  const to = `/${url}/${crumb.uuid}`;

  if (index + 1 === path.length) {
    return crumb.name;
  }

  return (
    <Link
      {...styleDrag(to)}
      id={to}
      onDrop={() => onDrop(`dropped-documents/${crumb.uuid}`, to)}
      to={to}
    >
      {crumb.name}
    </Link>
  );
};

const styleDrag = (id) => ({
  onDragOver: () => {
    document.getElementById(id).classList.add("bg-gray-400", "rounded", "p-1");
  },
  onDragLeave: () => {
    document
      .getElementById(id)
      .classList.remove("bg-gray-400", "rounded", "p-1");
  },
});
export default DocumentBreadCrumbs;
