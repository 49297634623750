import { Field } from "redux-form";
import RenderMonaco from "../form/RenderMonaco";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import { AggregateOutputType } from "./customJobTypes";

const CustomJobAggregateFields = ({ field }: { field: string }) => {
  return (
    <>
      <div className="col-lg-12 form-group">
        <Field
          name={`${field}.name`}
          component={RenderField}
          label="Name"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          name={`${field}.in_progress`}
          component={renderToggleInput}
          label="In Progress"
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          name={`${field}.code`}
          component={RenderMonaco}
          label="Code"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          name={`${field}.output_type`}
          component={SelectInput}
          label="Output Type"
          options={[
            {
              label: "CSV",
              value: AggregateOutputType.CSV,
            },
          ]}
        />
      </div>
    </>
  );
};

export default CustomJobAggregateFields;
