import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import isSubmitting from "../utils/submitting";
import { RegisterItem, RegisterItemPages } from "./registerItemTypes";
import ShowRegisterItemPage from "./ShowRegisterItemPage";

const RegisterItemProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const queryClient = useQueryClient();
  const { takeAction, loading: deleting }: IUseApi = useApi();
  const history = useHistory();

  const { data, loading, setData }: IUseApi = useApi(
    `/register-items/${uuid}`,
    {},
    true,
  );
  const item = data as RegisterItem;

  const deleteItem = () => {
    return deleteSwal(item.name)
      .then(() => takeAction("destroy", `register-items/${item.uuid}`))
      .then(() => {
        queryClient.invalidateQueries(`register-items`);
        history.push(`/register-list/${item.register.uuid}`);
        toast.success("Item deleted");
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={`${item.name}`}
        crumbs={[
          {
            name: item?.register?.name,
            link: `/register-list/${item.register.uuid}`,
          },
          {
            name: item.name,
            link: "",
            active: true,
          },
        ]}
      />
      <Profile<RegisterItemPages>
        header={
          <DashboardHeader
            extraButtons={
              <button
                onClick={deleteItem}
                type="button"
                disabled={deleting}
                className="tx-primary d-lg-none"
              >
                {isSubmitting(deleting, "Delete", "Deleting...")}
              </button>
            }
          >
            <DashboardH3>{item.name}</DashboardH3>
          </DashboardHeader>
        }
        tabs={[
          {
            label: "Details",
            link: `/register-items/${uuid}/details`,
            page: "details",
            icon: "fa-pencil",
          },
          {
            label: "Documents",
            link: `/register-items/${uuid}/documents`,
            page: "documents",
            icon: "fa-folder-open-o",
          },
        ]}
        content={<ShowRegisterItemPage item={item} setItem={setData} />}
        sideBar={
          <Button
            className="mt-3 d-none d-lg-block"
            onClick={deleteItem}
            block
            outline
            color="danger"
            disabled={deleting}
          >
            {isSubmitting(deleting, "Delete", "Deleting...")}
          </Button>
        }
      />
    </>
  );
};

export default RegisterItemProfile;
