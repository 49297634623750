import { useParams } from "react-router-dom";
import Edit from "./Edit";
import NotFound from "../404";
import ProfileForm from "../customFields/ProfileForm";
import EquipmentCalibrationWorksheet from "./EquipmentCalibrationWorksheet";

const ShowPage = (props) => {
  const { page } = useParams();

  const { calibrationType, setCalibrationType } = props;

  switch (page) {
    case "details":
      return (
        <Edit
          {...props}
          initialValues={{
            ...calibrationType,
            unit_type: calibrationType.unit_frequency_units ? 1 : 0,
            is_tailored_calibration_job_deadlines:
              calibrationType.job_creation_units ||
              calibrationType.job_creation_length
                ? 1
                : 0,
          }}
        />
      );
    case "fields":
      return (
        <ProfileForm
          initialValues={calibrationType}
          {...props}
          modelId={calibrationType.id}
          modelType="App\Models\EquipmentCalibrationType"
          onSuccess={(data) =>
            setCalibrationType({
              ...calibrationType,
              custom_fields: data.data,
            })
          }
        />
      );
    case "worksheet":
      return (
        <EquipmentCalibrationWorksheet
          calibrationType={calibrationType}
          initialValues={calibrationType}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
