import React, { useState } from "react";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import deleteSwal from "../utils/deleteSwal";
import { reset } from "redux-form";

const useLeaveReasonsApi = () => {
  const { takeAction, loading } = useApi();
  const [reasons, setReasons] = useState([]);
  const url = "leave-reasons";
  const history = useHistory();

  const index = () => {
    takeAction("index", url)
      .then(({ data }) => {
        setReasons(data.data);
      })
      .catch((err) => errorSwal(err));
  };

  const store = (values, dispatch, props) => {
    return takeAction("store", url, values)
      .then(({ data }) => {
        setReasons([...reasons, data.data]);
        toast.success(`${values.reason} added`);
        history.push(`/${url}`);
        dispatch(reset(props.form));
      })
      .catch(errorSwal);
  };

  const update = (values) => {
    return takeAction("update", `${url}/${values.uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.reason} updated`);
        history.push(`/${url}`);
        setReasons(
          reasons.map((reason) =>
            reason.uuid === data.data.uuid ? data.data : reason,
          ),
        );
      })
      .catch(errorSwal);
  };

  const destroy = (reason) => {
    deleteSwal(reason.reason)
      .then(() => {
        takeAction("destroy", `${url}/${reason.uuid}`).then(() => {
          setReasons(reasons.filter((r) => r.uuid !== reason.uuid));
          toast.success(`${reason.reason} deleted`);
        });
      })
      .catch(errorSwal);
  };

  return {
    destroy,
    update,
    store,
    index,
    reasons,
    loading,
  };
};

export default useLeaveReasonsApi;
