import React from "react";
import { Field } from "redux-form";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";

const ArchiveModal = (props) => {
  return (
    <FormModal {...props}>
      <div className="form-group col-12">
        <Field component={RenderField} name="archive_reason" label="Reason" />
      </div>
    </FormModal>
  );
};

export default ArchiveModal;
