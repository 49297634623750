import React from "react";
import { Field } from "redux-form";
import AddressFields from "../addresses/AddressFields";
import dateField, { formatting } from "../utils/dateTime";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const TemplateCopyModal = (props) => {
  return (
    <FormModal {...props}>
      {(formProps) => {
        return (
          <>
            <div className="col-12 form-group">
              <Field
                component={RenderField}
                name="name"
                label="Name"
                required
                validate={required}
              />
            </div>
            {props.initialValues.client_id && (
              <div className="col-lg-6 form-group">
                <Field
                  component={SelectInputAsync}
                  name="client_id"
                  url="/organisations/clients"
                  label="Client"
                  required
                  validate={required}
                />
              </div>
            )}

            <div className="form-group col-lg-6">
              <Field
                name="project_manager"
                component={SelectInputAsync}
                url="/users/projectmanagers"
                required
                label="Project Manager"
                validate={required}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={dateField}
                {...formatting}
                name="scheduled_start_date"
                label="Scheduled Start Date"
                validate={required}
                required
              />
              <small className="d-block">
                N.B. the created project will have the same length as this
                project.
              </small>
            </div>

            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="copy_documents"
                label="Copy Files"
              />
              <small className="d-block">
                If checked, will copy all project and job files and file
                structure.
              </small>
            </div>

            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="copy_jobs"
                label="Copy Jobs"
              />
            </div>
            <AddressFields {...props} {...formProps} />
          </>
        );
      }}
    </FormModal>
  );
};

export default TemplateCopyModal;
