import axios from "../api/api";
import { DELETE_DELIVERABLE_PRICING } from "./types";

export const deletePricing = (uuid) => (dispatch) => {
  return axios
    .delete(`/deliverables/pricing/${uuid}`)
    .then((res) => res.data.data)
    .then((deliverable) =>
      dispatch({
        type: DELETE_DELIVERABLE_PRICING,
        payload: deliverable,
      }),
    );
};
