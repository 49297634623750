import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getInboundAccountsPayable = ({ pageParam = "" }, search?: string) => {
  return api
    .get(
      `inbound-accounts-payable-accounts/messages?nextLink=${encodeURIComponent(
        pageParam,
      )}&search=${encodeURIComponent(search ?? "")}`,
    )
    .then(({ data }) => data);
};

export default function useInboundAccountsPayable(search?: string) {
  return useInfiniteQuery(
    ["inbound-accounts-payable", { search }],
    (pageParam) => getInboundAccountsPayable(pageParam, search),
    {
      getNextPageParam: (data) => {
        if (!data.meta.next_link) {
          return;
        }

        return data.meta.next_link;
      },
    },
  );
}
