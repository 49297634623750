import { UPDATE_PROJECT_OVERVIEW_DATE_RANGE } from "../actions/types";
import dayjs from "dayjs";
const initialPeriod = () => {
  const period = localStorage.getItem("project_overview_period") || "month";
  return {
    from: dayjs()
      .startOf(period)
      .toDate(),
    to: dayjs()
      .endOf(period)
      .toDate(),
    enteredTo: dayjs()
      .endOf(period)
      .toDate()
  };
};

const initialState = {
  ...initialPeriod(),
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROJECT_OVERVIEW_DATE_RANGE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
