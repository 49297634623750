import React from "react";
import ReactTable from "../table/ReactTable";

const PurchaseTable = ({ data }) => {
  const columns = [
    {
      accessorKey: "description",
      header: "Item",
    },
    {
      accessorKey: "price",
      header: "Price",
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return "-";
        }
        return `$${value.toFixed(2)}`;
      },
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
    },
  ];

  return <ReactTable disableSearch data={data} columns={columns} />;
};

export default PurchaseTable;
