import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import formError from "../utils/formError";
import FormMachineDayRate from "./form";

const AddMachineDayRate = () => {
  const { projectUuid } = useParams<{ projectUuid: string }>();
  const history = useHistory();
  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction(
      "store",
      `projects/${projectUuid}/machine-day-rates`,
      values,
    )
      .then(({ data }: { data: any }) => {
        toast.success(`Machine day rate added successfully`);
        history.push(`/machine-day-rates/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Machine Day Rate"
        crumbs={[{ link: "", name: "New Machine Day Rate", active: true }]}
      />

      <FormMachineDayRate onSubmit={onSubmit} form="AddMachineDayRate" />
    </>
  );
};

export default AddMachineDayRate;
