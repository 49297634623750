import dayjs from "dayjs";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmissionError } from "redux-form";
import { useUserTimesheet } from "../../context/timesheet-context";
import formError from "../utils/formError";
import { amountOfHours } from "./functions/timesheetFunctions";
import useCreateTimesheet from "./hooks/useCreateTimesheet";
import TimesheetForm from "./TimesheetForm";
import { useAuth } from "../../context/auth-context";

const AddTimesheet = (props) => {
  const { mutateAsync } = useCreateTimesheet();
  const { selectedDay, setShowProductivity } = props;
  const { productivity, setProductivity } = useUserTimesheet();
  const { checkUser } = useAuth();
  const { userId } = useParams();
  const history = useHistory();

  const submit = (values, _, props) => {
    if (amountOfHours(productivity) > 24) {
      throw new SubmissionError({
        _error:
          "You are unable to submit a timesheet with over 24 hours of productivity.\nRemove some productivity hours before continuing.",
      });
    }

    const data = {
      ...values,
      user_id: props.userId,
      date: dayjs(selectedDay).format("YYYY-MM-DD"),
    };

    return mutateAsync(data)
      .then(() => {
        setProductivity([]);
        props?.reset(props.form);

        setShowProductivity(false);

        checkUser();

        history.push(
          props.leaveReason
            ? `/users/${userId || props.userUuid}/requested-leave`
            : `/users/${userId || props.userUuid}/timesheets`,
        );
      })
      .catch(formError);
  };

  return (
    <TimesheetForm
      userId={props.userId}
      onSubmit={(values, dispatch, props) =>
        props.showProductivity || props.leaveReason
          ? submit(values, dispatch, props)
          : props.setShowProductivity(true)
      }
      save={props.leaveReason ? "Save" : "Fill Productivity"}
      {...props}
      requiresApproval
      initialValues={{
        ...props?.user?.organisation_user?.settings,
        date: selectedDay,
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedDay: state.timesheets.selectedDay,
});

export default connect(mapStateToProps)(AddTimesheet);
