export const Standard_Price_List = "App\\Models\\Pricing";
export const Position = "App\\Models\\OrganisationPosition";
export const Qualification = "App\\Models\\Qualification";
export const SkillCategory = "App\\Models\\SkillCategory";
export const CPIR = "App\\Models\\CPIR";
export const Pricing = "App\\Models\\Pricing";
export const BillableItem = "App\\Models\\BillableItem";
export const BillableItemUnit = "App\\Models\\BillableItemUnit";
export const LeaveReason = "App\\Models\\LeaveReason";
export const ProjectJob = "App\\Models\\ProjectJob";
export const Template = "App\\Models\\Template";
export const EquipmentType = "App\\Models\\EquipmentType";
export const Invoice = "App\\Models\\Invoice";
export const Supplier = "App\\Models\\Supplier";
export const Client = "App\\Models\\Client";
export const Project = "App\\Models\\Project";
export const TrackingSite = "App\\Models\\TrackingSite";
export const OrganisationBranch = "App\\Models\\OrganisationBranch";
export const OrganisationRole = "App\\Models\\OrganisationRole";
export const User = "App\\Models\\User";
export const CalibrationUnit = "App\\Models\\CalibrationUnit";
export const Equipment = "App\\Models\\Equipment";
export const EquipmentTypeGroup = "App\\Models\\EquipmentTypeGroup";
export const Document = "App\\Models\\Document";
export const Tender = "App\\Models\\Tender";
export const CustomJob = "App\\Models\\CustomJob";
export const Docket = "App\\Models\\Docket";
export const PenaltyRate = "App\\Models\\PenaltyRate";
export const PublicHoliday = "App\\Models\\PublicHoliday";
export const Purchase = "App\\Models\\Purchase";
export const Industry = "App\\Models\\Industry";
export const BudgetTemplate = "App\\Models\\BudgetTemplate";
export const ChartOfAccount = "App\\Models\\ChartOfAccount";
export const OrganisationDivision = "App\\Models\\OrganisationDivision";
export const InviteUser = "App\\Models\\InviteUser";
