import React, { useEffect, useState } from "react";
import useApi from "../api/useApi";
import RequiredCompetencyFields from "./RequiredCompetencyFields";

const RequiredCompetencies = props => {
  const { fields } = props;
  const { takeAction, loading } = useApi();
  const [skillCategories, setSkillCategories] = useState([]);

  useEffect(() => {
    takeAction("index", "skill-categories").then(({ data }) => {
      const formatted = data.data.map((skillCategory ) => {
        return {
          ...skillCategory,
          label: skillCategory.name,
          value: skillCategory.id
        };
      });
      setSkillCategories(formatted);
    });
  }, []);

  return (
    <>
      <div className="col-12 mt-3">
        <p className="tx-16 tx-semibold tx-inverse mb-0">
          Required Competencies
        </p>{" "}
        <hr />
      </div>
      {fields.map((competency, index) => (
        <RequiredCompetencyFields
          key={index}
          competency={competency}
          skillCategories={skillCategories}
          fields={fields}
          index={index}
          {...props}
        />
      ))}
      <div className="col-12 form-group d-flex">
        <button
          type="button"
          className="btn btn-link ms-auto px-0"
          onClick={() => fields.push()}
        >
          Add Required Competency
        </button>
      </div>
    </>
  );
};

export default RequiredCompetencies;
