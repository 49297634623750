import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import AccountRequestFields from "../accounts/AccountRequestFields";
import AddressFields from "../addresses/AddressFields";
import useApi from "../api/useApi";
import AddMultiple from "../form/AddMultiple";
import FormErrorAlert from "../form/FormErrorAlert";
import AbnField from "../utils/AbnField";
import errorSwal from "../utils/errorSwal";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { InvoiceTerms } from "./clientTypes";

const ClientDetailForm = (props) => {
  const { handleSubmit, client, change, requestAccount } = props;
  const { uuid } = useParams();

  const { takeAction } = useApi();

  useEffect(() => {
    if (!requestAccount) {
      change("account_request", null);
    }
  }, [requestAccount]);

  return (
    <form onSubmit={handleSubmit}>
      <FormErrorAlert error={props.error} />
      <div className="row">
        <div className="col-12">
          <label className="section-title">Details</label>
          <hr />
        </div>
        {client?.abn_holder ? (
          <>
            <div className="col-lg-3 form-group">
              <AbnField
                {...props}
                onSuccess={(abn) => {
                  takeAction(
                    "store",
                    `/organisation-clients/${uuid}/validation`,
                    {
                      abn,
                    },
                  ).catch((err) => {
                    if (err?.response?.status === 422) {
                      errorSwal(err);
                      change("abn", "");
                      change("name", "");
                    }
                  });
                }}
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field component={RenderField} name="acn" label="ACN" />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name="name"
                label="Name"
                disabled
                readOnly
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name="trading_name"
                label="Trading Name"
              />
            </div>

            <div className="col-lg-6 form-group">
              <Field
                component={AddMultiple}
                name="accounts_email"
                label="Accounts Email/s"
                validate={required}
                required
              />
            </div>
            <div className="col-12 form-group">
              <Field
                component={RenderField}
                textarea
                name="description"
                label="Description"
              />
            </div>
          </>
        ) : (
          <div className="col-lg-6 form-group">
            <Field component={RenderField} name="name" label="Name" />
          </div>
        )}
        <div className="col-lg-6 form-group">
          <Field
            component={AddMultiple}
            name="contact_email"
            label="Contact Email/s"
            validate={required}
            onAdd={(currentValues, email) => {
              if (!client?.abn_holder) {
                takeAction(
                  "store",
                  `/organisation-clients/${uuid}/validation`,
                  {
                    contact_email: email,
                  },
                ).catch((err) => {
                  if (err?.response?.status === 422) {
                    errorSwal(err);

                    change("contact_email", currentValues);
                  }
                });
              }
            }}
            required
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="contact_telephone"
            label="Contact Phone"
            validate={required}
            required
          />
        </div>

        <AddressFields {...props} postal />

        <div className="col-lg-12 form-group">
          <Field
            component={renderToggleInput}
            name="request_account"
            label="Request Credit Account"
          />
          <small className="d-block">
            Should a credit account not be requested, invoicing terms will be
            'cash on delivery'. This means that work carried out will not be
            released until the invoice is paid.
          </small>
        </div>
        {requestAccount && (
          <>
            <FormHeader>Account Request</FormHeader>
            <AccountRequestFields
              namePrefix="account_request"
              isRequired={true}
              form="ClientDetails"
              invoiceTerms={[
                {
                  label: "Days From Invoiced",
                  value: InvoiceTerms.DaysFromInvoiced,
                },
                {
                  label: "Days From End of Month",
                  value: InvoiceTerms.DaysFromEndOfMonth,
                },
              ]}
            />
            {/* <FormHeader
              extra={
                <p>
                  In order to have an account, we require 3 trade references.
                </p>
              }
            >
              Trade References
            </FormHeader>
            {[0, 1, 2].map((i) => {
              return <Reference index={i} key={i} />;
            })} */}
          </>
        )}
      </div>
      <SubmitButton {...props} className="w-100" />
    </form>
  );
};

const Reference = ({ index }) => {
  return (
    <>
      <div className="col-lg-4 form-group">
        <Field
          name={`account_request.references[${index}].name`}
          component={RenderField}
          label="Name"
          validate={required}
          required
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          name={`account_request.references[${index}].email`}
          component={RenderField}
          label="Email"
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          name={`account_request.references[${index}].phone`}
          component={RenderField}
          label="Phone"
        />
      </div>
    </>
  );
};

const form = reduxForm({ form: "ClientDetails", enableReinitialize: true });

const selector = formValueSelector("ClientDetails");

const mapStateToProps = (state) => {
  return {
    contact_email: selector(state, "contact_email"),
    requestAccount: selector(state, "request_account"),
  };
};

export default connect(mapStateToProps, {})(form(ClientDetailForm));
