import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm, reset } from "redux-form";
//BO$$ COMPONENTS
import renderField from "../utils/renderField";

const StandardFolder = (props) => {
  const { submitting, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="form-group col-md-12">
          <Field
            name="name"
            type="text"
            component={renderField}
            required
            label="Name"
          />
        </div>
        <div className="form-group col-md-12 mg-b-10">
          <button
            className="btn btn-primary bd-0 form-control"
            disabled={submitting}
          >
            {submitting ? "Loading..." : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "addFolder" });

export default form(StandardFolder);
