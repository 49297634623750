import React from "react";
import { useHistory } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { UploadedDocument } from "../documents/documentTypes";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import useS3Uploader from "../hooks/useS3Uploader";
import formError from "../utils/formError";
import BrandingThemeForm, { BrandingThemeFormProps } from "./BrandingThemeForm";
import { BrandingTheme } from "./brandingThemeTypes";
import { toast } from "react-toastify";

const AddBrandingTheme = () => {
  const { upload, uploadPercent } = useS3Uploader();

  const { user } = useAuth();
  const history = useHistory();
  const { takeAction }: IUseApi<{}, { data: BrandingTheme }> = useApi();

  const onSubmit: FormSubmitHandler<BrandingTheme, BrandingThemeFormProps> = (
    values,
  ) => {
    return upload(
      values.logo,
      `organisations/${user?.active_organisation.uuid}/branding-themes`,
      (logo?: UploadedDocument) => {
        return takeAction("store", `branding-themes`, {
          ...values,
          logo,
        })
          .then(({ data }) => {
            toast.success(`${values.name} created successfully.`);
            history.push(`/branding-themes/${data.data.uuid}/details`);
          })
          .catch(formError);
      },
    );
  };

  return (
    <>
      <HeaderPageAdminFilter
        crumbs={[
          {
            name: "Branding Themes",
            link: "/branding-themes",
          },
          {
            name: "Add Branding Theme",
            link: "/branding-themes/add",
            active: true,
          },
        ]}
        titlePage="Add Branding Theme"
      />
      <BrandingThemeForm
        onSubmit={onSubmit}
        form="AddBrandingTheme"
        percent={uploadPercent}
        showFile={true}
      />
    </>
  );
};

export default AddBrandingTheme;
