import React from "react";
import { FieldArray, reduxForm } from "redux-form";
import PointForm from "./PointForm";

const CompactionPoints = (props) => {
  return (
    <>
      <div className="row mt-4">
        <div className="col-12">
          <h6 className="tx-inverse">Points</h6>
          <hr />
        </div>
      </div>
      <form>
        <FieldArray {...props} component={PointForm} name="points" />
      </form>
    </>
  );
};

const form = reduxForm({ form: "CompactionPoints", enableReinitialize: true });

export default form(CompactionPoints);
