import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import EditPenaltyRate from "./EditPenaltyRate";
import { PenaltyRate } from "./penaltyRateTypes";

export interface ShowPenaltyRatePageProps {
  penaltyRate: PenaltyRate;
  setPenaltyRate: (rate: PenaltyRate) => void;
}

const ShowPenaltyRatePage = (props: ShowPenaltyRatePageProps) => {
  const { page, uuid } = useParams<{ page: string; uuid: string }>();

  const { user } = useAuth();

  switch (page) {
    case "details":
      return <EditPenaltyRate {...props} />;

    case "documents":
      return (
        <NewProfileDocuments
          model="App\Models\PenaltyRate"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/penalty-rates/${uuid}`}
          homeUrl={`penalty-rates/${uuid}/documents`}
        />
      );

    default:
      return <NotFound />;
  }
};

export default ShowPenaltyRatePage;
