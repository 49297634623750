import {
  Field,
  FieldArray,
  FormSubmitHandler,
  formValueSelector,
  InjectedFormProps,
} from "redux-form";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import { connect } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { Button } from "reactstrap";
import { FaPlus } from "react-icons/fa";
import { Job } from "./jobTypes";
import useModal from "../hooks/useModal";
import { RenderRadioButton } from "../approvals/ApprovalModal";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { useAuth } from "../../context/auth-context";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import required from "../utils/required";
import AllowanceFields from "../timesheets/AllowanceFields";

const QuickAddProductivityModal = ({
  hours,
  job,
  from,
  nearest,
}: {
  hours?: number;
  job: Job;
  from: "from_now" | "to_now";
  nearest: number;
}) => {
  const { toggle, modal } = useModal();

  const { takeAction }: IUseApi = useApi();
  const [allowances, setAllowances] = useState([]);

  const { user } = useAuth();

  const time = {
    start_time: getStartTime(from, hours)?.format("HH:mm"),
    finish_time: getFinishTime(from, hours)?.format("HH:mm"),
  };

  useEffect(() => {
    takeAction("index", `projects/${job.project.uuid}/allowances`).then(
      ({ data }) => {
        setAllowances(
          data.data.map((d: any) => ({
            ...d,
            include: true,
            project_allowance_id: d.id,
          })),
        );
      },
    );
  }, [job]);

  const onSubmit: FormSubmitHandler<{
    hours: number;
    from: string;
    comments: string;
    allowances: Array<{ project_allowance_id: number; include: boolean }>;
  }> = (values, dispatch, props) => {
    return takeAction("store", `staff-schedules`, {
      start_time: getStartTime(from, hours)?.format("YYYY-MM-DD HH:mm"),
      finish_time: getFinishTime(from, hours)?.format("YYYY-MM-DD HH:mm"),
      job_id: job.id,
      user_uuid: user?.uuid,
      comments: values?.comments,
      allowances: values?.allowances,
    }).then(() => {
      toggle();
      props?.reset?.();
      toast.success("Productivity added successfully");
    });
  };

  return (
    <>
      <Button size="sm" color="primary" onClick={toggle}>
        <FaPlus className="me-1 tx-10" /> Quick add Productivity
      </Button>
      <FormModal
        onSubmit={onSubmit}
        title="Quick Add Productivity"
        form="QuickAddProductivity"
        toggle={toggle}
        modal={modal}
        initialValues={{ from: "to_now", allowances }}
      >
        {({ change }: InjectedFormProps) => {
          return (
            <>
              <div className="col-lg-12 form-group">
                <Field
                  component={RenderField}
                  name="hours"
                  type="number"
                  required
                  validate={required}
                  label="Hours"
                />
              </div>
              <div className="col-lg-12 form-group mt-3">
                <Field
                  component={RenderRadioButton}
                  name="from"
                  options={[
                    {
                      label: "To Now",
                      value: "to_now",
                    },
                    {
                      label: "From Now",
                      value: "from_now",
                    },
                  ]}
                />
              </div>

              <div className="col-lg-12 form-group ">
                <Field
                  component={RenderField}
                  name="comments"
                  label="Comments"
                  textarea
                />
              </div>
              <div className="col-lg-12 form-group">
                {hours ? (
                  <div>
                    <p className="mb-0">
                      From <span className="text-dark">{time.start_time}</span>{" "}
                      to <span className="text-dark">{time.finish_time}</span>
                    </p>
                  </div>
                ) : null}
              </div>
              <FieldArray
                name="allowances"
                component={AllowanceFields}
                change={change}
              />
            </>
          );
        }}
      </FormModal>
    </>
  );
};

const getQuarterHourStart = (date: Dayjs) => {
  const minutes = date.minute();
  const quarterHour = Math.round(minutes / 15) * 15;
  return date.startOf("hour").add(quarterHour, "minute");
};

const getStartTime = (from: "from_now" | "to_now", hours?: number) => {
  if (!hours) return null;

  if (from == "from_now") {
    return getQuarterHourStart(dayjs());
  }

  return getQuarterHourStart(dayjs().add(-hours, "hour"));
};

const getFinishTime = (from: "from_now" | "to_now", hours?: number) => {
  if (!hours) return null;

  if (from == "from_now") {
    return getQuarterHourStart(dayjs().add(hours, "hour"));
  }

  return getQuarterHourStart(dayjs());
};

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("QuickAddProductivity");

  return {
    hours: selector(state, "hours"),
    from: selector(state, "from"),
  };
};

export default connect(mapStateToProps)(QuickAddProductivityModal);
