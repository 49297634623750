import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { ClientFeedbackTemplateIndex } from "./clientFeedbackTypes";
import useClientFeedbackTemplates from "./hooks/useClientFeedbackTemplates";
import { Client } from "../../enums/Model";

const ClientFeedbackTemplatesList = () => {
  return (
    <>
      <HeaderPage
        titlePage="Client Feedback Types"
        pageDescription={
          <>
            Create custom “client feedback types” that include specific
            questions relevant to the type of feedback being gathered.
          </>
        }
        relatedLinks={[{ name: "Clients", link: "/clients", model: Client }]}
        crumbs={[]}
      />
      <div className="mb-3">
        <Link
          to="/client-feedback-types/add"
          className="btn btn-outline-primary"
        >
          Add new client feedback type
        </Link>
      </div>
      <PaginatedList
        listName="clientFeedbackTemplatesList"
        indexHook={useClientFeedbackTemplates}
        originalFilters={[]}
        itemCard={({ item }: { item: ClientFeedbackTemplateIndex }) => (
          <PaginatedCard
            key={item.id}
            info={[]}
            header={<Header template={item} />}
          />
        )}
      />
    </>
  );
};

const Header = ({ template }: { template: ClientFeedbackTemplateIndex }) => {
  const tooltipId = useMemo(
    () => `template_title_${template.uuid}`,
    [template.uuid],
  );

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={template.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/client-feedback-types/${template.uuid}/details`}>
              {template.name}
            </Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {template.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default ClientFeedbackTemplatesList;
