import React from "react";
import { toast } from "react-toastify";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import renderToggleInput from "../utils/renderToggleInput";
import SubmitButton from "../utils/SubmitButton";
import { UserDetailTabsProps } from "./UserDetailTabs";

interface UserSettingsValues {
  load_work_day: boolean;
  auto_fill_purchase_branch: boolean;
  allow_user_to_not_log_emails?: boolean;
}

const UserSettings: React.FC<
  InjectedFormProps<UserSettingsValues, UserDetailTabsProps> &
    UserDetailTabsProps
> = (props) => {
  const { takeAction }: IUseApi = useApi();
  const { handleSubmit, setUser, user } = props;
  const { user: authUser } = useAuth();

  const onSubmit = (values: UserSettingsValues) => {
    return takeAction("update", "settings", {
      setter_id: user?.id,
      setter_type: "App\\Models\\User",
      settings: values,
    })
      .then(({ data }) => {
        setUser({
          ...props.user,
          settings: data.data.settings,
        });
        toast.success("Settings Updated");
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            component={renderToggleInput}
            name="load_work_day"
            label="Load Work Day into Timesheet"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={renderToggleInput}
            name="auto_fill_purchase_branch"
            label="Auto Fill Branch when Creating Purchases"
          />
        </div>
        {authUser?.is_admin && (
          <div className="col-lg-6 form-group">
            <Field
              component={renderToggleInput}
              information="When working with our outlook add in, turning this off will allow the user to not be required to log emails."
              name="allow_user_to_not_log_emails"
              label="Allow user to not log emails"
            />
          </div>
        )}

        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<UserSettingsValues, UserDetailTabsProps>({
  form: "UserSettings",
  enableReinitialize: true,
});

export default form(UserSettings);
