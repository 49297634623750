import dayjs from "dayjs";
import _ from "lodash";
import { useParams } from "react-router";
import { Button } from "reactstrap";
import { Field } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { ClientFeedback } from "../clientFeedback/clientFeedbackTypes";
import SelectInput from "../form/SelectInput";
import FormModal from "../utils/FormModal";
import errorSwal from "../utils/errorSwal";
import required from "../utils/required";
import { ProjectContact } from "./projectTypes";

interface RequestProjectFeedbackModalProps {
  toggle: Function;
  modal: boolean;
  feedback: ClientFeedback[];
  setFeedback: (data: ClientFeedback[]) => void;
  project: any;
}

const RequestProjectFeedbackModal = (
  props: RequestProjectFeedbackModalProps,
) => {
  const { toggle, modal, setFeedback, feedback, project } = props;

  const { takeAction }: IUseApi<{}, { data: ClientFeedback[] }> = useApi();

  const { number } = useParams<{ number: string }>();

  const { data: clientFeedbackTemplates } = useApi(
    "client-feedback-templates",
    [],
  );

  const onSubmit = (values: any) => {
    return takeAction("store", `projects/${number}/client-feedback`, values)
      .then(({ data }) => {
        setFeedback([...feedback, ...data.data]);
        toggle();
      })
      .catch(errorSwal);
  };

  if (clientFeedbackTemplates.length === 0) {
    return null;
  }

  if (project?.contacts?.length === 0) {
    return null;
  }

  return (
    <FormModal
      modal={modal}
      toggle={toggle}
      form="RequestProjectFeedback"
      onSubmit={onSubmit}
      title="Request Project Feedback"
      extraFooterButtons={
        <Button outline onClick={() => toggle()}>
          Skip Feedback
        </Button>
      }
    >
      <div className="col-12 form-group">
        <Field
          component={SelectInput}
          name="template_id"
          label="Feedback Type"
          required
          validate={required}
          options={clientFeedbackTemplates.map((d: any) => ({
            label: d.name,
            value: d.id,
          }))}
        />
      </div>
      <div className="col-12 form-group">
        <Field
          component={SelectInput}
          name="sent_to"
          label="Request Feedback From"
          required
          validate={required}
          isMulti
          options={project?.contacts?.map((contact: ProjectContact) => {
            const incompleteFeedback = contact.feedback.filter(
              (feedback) => !feedback.completed_at,
            );

            const disabled = !contact.email || incompleteFeedback.length > 0;

            let extraInfo = "";

            if (disabled && !contact.email) {
              extraInfo = " (No Email Found)";
            }

            if (disabled && incompleteFeedback.length > 0) {
              extraInfo = " (Has Incomplete Feedback)";
            }

            const lastFeedback = _.last(contact.feedback);

            if (lastFeedback && lastFeedback) {
              extraInfo += ` last requested on ${dayjs(
                lastFeedback.created_at,
              ).format("DD/MM/YYYY")}`;
            }

            return {
              label: `${contact.name}${extraInfo}`,
              value: contact.contact_id,
              disabled,
              email: contact.email,
            };
          })}
        />
      </div>
    </FormModal>
  );
};

export default RequestProjectFeedbackModal;
