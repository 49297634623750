import React from "react";
import { money } from "../form/formatters";

const PurchaseItemDetails = ({ purchaseItem }) => {
  return (
    <div className="bg-white p-4 border">
      <div className="row">
        <div className="col-lg-6 form-group">
          <p className="mb-0 tx-inverse tx-semibold">Description</p>
          <p className="mb-0">{purchaseItem?.description}</p>
        </div>
        <div className="col-lg-6 form-group">
          <p className="mb-0 tx-inverse tx-semibold">Total Cost</p>
          <p className="mb-0">{money.format(purchaseItem?.cost)}</p>
        </div>
        <div className="col-lg-6 form-group">
          <p className="mb-0 tx-inverse tx-semibold">Price</p>
          <p className="mb-0">{money.format(purchaseItem?.price)}</p>
        </div>
        <div className="col-lg-6 form-group">
          <p className="mb-0 tx-inverse tx-semibold">Quantity</p>
          <p className="mb-0">{purchaseItem?.quantity}</p>
        </div>
        <div className="col-lg-6 form-group">
          <p className="mb-0 tx-inverse tx-semibold">Budget</p>
          {purchaseItem?.budget?.description_array?.map((d, i) => {
            return (
              <p className="mb-0" key={i}>
                {d}
              </p>
            );
          })}
        </div>

        <div className="col-lg-6 form-group">
          <p className="mb-0 tx-inverse tx-semibold">Current Budget Spend</p>
          <p className="mb-0">
            {money.format(purchaseItem?.budget?.total_cost)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PurchaseItemDetails;
