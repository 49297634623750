import { useParams } from "react-router-dom";
import NotFound from "../404";
import useApi from "../api/useApi";
import { ProjectForm, ProjectFormState } from "./projectForm";
import Edit from "./Edit";
import { ProjectFormPages } from "./Profile";
import { CustomField } from "../customFields/customFieldTypes";
import ProfileForm from "../customFields/ProfileForm";
import { fieldTypes } from "../../enums/FieldType";
import PriceBandsList from "./PriceBandsList";

const ShowPage = (props: ProjectFormState) => {
  const { page, uuid } = useParams<{ page: ProjectFormPages; uuid: string }>();
  const { takeAction } = useApi();

  const { projectForm, setProjectForm } = props;

  switch (page) {
    case "details":
      return (
        <Edit
          initialValues={projectForm}
          {...props}
        />
      );
    case "fields":
      return (
        <ProfileForm
          initialValues={projectForm}
          {...props}
          modelId={projectForm.id}
          modelType="App\Models\ProjectForm"
          onSuccess={(data: { data: CustomField[] }) =>
            setProjectForm({
              ...projectForm,
              custom_fields: data.data,
            })
          }
          onApproval={() => {
            takeAction("show", `project-forms/${uuid}`).then(
              ({ data }: { data: { data: ProjectForm } }) => {
                setProjectForm(data.data);
              },
            );
          }}
          fieldTypes={[
            ...fieldTypes,
            {
              label: "Prompt",
              value: 16,
            },
          ]}
        />
      );
    case "price-bands":
      return (
        <PriceBandsList />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
