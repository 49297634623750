import CustomScaleLoader from "./scaleLoader";

const LoadingOverlay = ({ loading }: { loading: boolean }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className="position-absolute w-100 h-100 z-1000 d-flex align-items-center justify-content-center bg-black-3 rounded-lg top-0 left-0">
      <div>
        <CustomScaleLoader>Loading...</CustomScaleLoader>
      </div>
    </div>
  );
};

export default LoadingOverlay;
