import React from "react";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { Link } from "react-router-dom";
import { BsDownload } from "react-icons/bs";

const DocumentTable = ({ user, template, deleteTemplateDocument }) => {
  const isPositionAdmin = user.is_position_admin;

  const { takeAction } = useApi();

  const deleteDocument = (document) => {
    return deleteSwal(document.name)
      .then(() => takeAction("destroy", `documents/standard/${document.uuid}`))
      .then(() => {
        toast.success(`${document.name} deleted`);
        deleteTemplateDocument(document);
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => {
        return (
          <Link to={`/template-documents/${info.row.original.uuid}/details`}>
            {info.getValue()}
          </Link>
        );
      },
    },
    {
      accessorKey: "download",
      header: "download",
      cell: (info) => {
        return (
          <a
            className="py-0 btn btn-outline-primary"
            href={info.row.original.link}
          >
            <BsDownload />
          </a>
        );
      },
    },
  ];

  if (isPositionAdmin) {
    columns.push({
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => {
        return (
          <DeleteButton
            deleteFunction={() => deleteDocument(info.row.original)}
          >
            Delete
          </DeleteButton>
        );
      },
    });
  }

  return <ReactTable columns={columns} data={template?.documents ?? []} />;
};

export default DocumentTable;
