import React from "react";

const List = ({ data }) => {
  if (!data) {
    return null;
  }

  return Object.entries(data).map(([title, value]) => (
    <p className="contact-item" key={title}>
      <span>{title}:</span>
      <span className="text-truncate text-wrap">{value}</span>
    </p>
  ));
};

export default List;
