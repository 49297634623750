import { FormSubmitHandler, getFormValues, initialize } from "redux-form";
import LoanForm, { LoanFormProps } from "./LoanForm";
import { Loan, Purchase } from "./purchaseTypes";
import LoanSummary from "./LoanSummary";
import { connect } from "react-redux";
import useApi from "../api/useApi";
import { ApiCalls, IUseApi } from "../api/apiTypes";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import ProfileButtons from "../utils/ProfileButtons";
import { FaMoneyBillWave } from "react-icons/fa";
import { useState } from "react";

const Loans = ({
  purchase,
  setPurchase,
  loan,
}: {
  purchase: Purchase;
  loan: Loan;
  setPurchase: (purchase: Purchase) => void;
}) => {
  const { takeAction }: IUseApi<{}, { data: Loan }> = useApi();

  const [currentLoan, setCurrentLoan] = useState(
    purchase.loans.length > 0 ? purchase.loans.length - 1 : 0,
  );

  const onSubmit: FormSubmitHandler<Loan, LoanFormProps> = (
    values,
    dispatch,
  ) => {
    const params: [ApiCalls, string, Loan] = values.uuid
      ? [
          "update",
          `loans/${values.uuid}`,
          {
            ...values,
            loanable_type: "App\\Models\\Purchase",
            loanable_id: purchase.id,
          },
        ]
      : [
          "store",
          "loans",
          {
            ...values,
            loanable_type: "App\\Models\\Purchase",
            loanable_id: purchase.id,
          },
        ];

    return takeAction(...params)
      .then(({ data }) => {
        setPurchase({
          ...purchase,
          loans: purchase.loans.map((loan, index) =>
            index == currentLoan ? data.data : loan,
          ),
        });

        dispatch(initialize("LoanForm", data.data));

        toast.success("Loan updated successfully");
      })
      .catch(formError);
  };

  return (
    <div>
      <ProfileButtons
        buttons={
          purchase.loans.length > 0 &&
          purchase.loans[purchase.loans.length - 1]?.uuid
            ? [
                {
                  text: (
                    <>
                      <FaMoneyBillWave className="text-success tx-20 me-2" />
                      Add Refinance
                    </>
                  ),
                  onClick: () => {
                    setPurchase({
                      ...purchase,
                      loans: [
                        ...purchase.loans,
                        //@ts-ignore
                        {
                          contact_id: purchase.loans[currentLoan]?.contact_id,
                          amount: 0,
                          loan_interest_rate: 0,
                          payments: [],
                        } as Loan,
                      ],
                    });

                    setCurrentLoan(purchase.loans.length);
                  },
                },
              ]
            : []
        }
      />
      <div className={`nav-statistics-wrapper`}>
        <nav className="nav flex-nowrap" style={{ overflowX: "auto" }}>
          {purchase.loans.map((loan, index) => {
            return (
              <button
                key={index}
                type="button"
                onClick={() => {
                  setCurrentLoan(index);
                }}
                className={`btn btn-link nav-link mb-3 ${
                  currentLoan == index ? "active" : ""
                }`}
              >
                {index == 0 ? "Initial Loan" : `Refinance ${index}`}
              </button>
            );
          })}
        </nav>
      </div>
      <LoanForm
        form="LoanForm"
        onSubmit={onSubmit}
        purchase={purchase}
        initialValues={purchase.loans[currentLoan]}
        enableReinitialize
      />
      {loan && (
        <LoanSummary loan={loan} initialSchedule={loan.payments ?? []} />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const loan = getFormValues("LoanForm")(state) as Loan;

  return {
    loan,
  };
};

export default connect(mapStateToProps)(Loans);
