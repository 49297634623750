import dayjs from "dayjs";

const getStatus = (purchase) => {
  let color = "warning";
  let text = "Not ready to be billed";

  if (purchase.archived_at && purchase.recurring_payment) {
    return {
      color: "secondary",
      text: `Contract Agreement (archived)`,
    };
  }
  if (purchase.archived_at) {
    return {
      color: "secondary",
      text: `Archived`,
    };
  }

  if (purchase.paid_at) {
    return {
      color: "success",
      text: "Paid",
    };
  }

  if (purchase.recurring_payment) {
    return {
      color: "primary",
      text: "Contract Agreement",
    };
  }

  if (purchase.billed_at) {
    color = "primary";
    text = ` Billed on ${dayjs(purchase.billed_at).format("DD/MM/YYYY")}`;
    return {
      color,
      text,
    };
  }
  if (purchase.ready_to_be_billed) {
    color = "info";
    text = "Ready to be billed";
  }

  return {
    color,
    text,
  };
};

export default getStatus;
