import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { RiArrowGoBackFill, RiArrowGoForwardFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import AccountForm from "./AccountForm";

const Accounts = () => {
  const [currentAccount, setCurrentAccount] = useState(0);

  const {
    data: accounts,
    setData: setAccounts,
    takeAction,
    loading,
  } = useApi(`xero/accounts`, []);

  const account = accounts?.organisation?.[currentAccount];

  const moveForward = () => {
    if (currentAccount + 1 === accounts?.organisation?.length) {
      return;
    }
    setCurrentAccount(currentAccount + 1);
  };

  const moveBackward = () => {
    if (currentAccount === 0) {
      return;
    }
    setCurrentAccount(currentAccount - 1);
  };

  const estimatedXero = accounts?.xero?.find((xeroAccount) => {
    return xeroAccount.Code === account?.code;
  });

  const onSubmit = (values) => {
    return takeAction("store", `xero/accounts/${account.uuid}`, values)
      .then(({ data }) => {
        setAccounts({
          ...accounts,
          organisation: accounts.organisation.map((a) =>
            a.uuid === data.data.uuid ? data.data : a,
          ),
        });
        toast.success(`${account?.code} - ${account?.name} synced`);

        moveForward();
      })
      .catch(errorSwal);
  };

  return (
    <>
      <Spinner loading={loading} />
      <HeaderPage titlePage="Step 1. Add Accounts to Xero" crumbs={[]} />
      <p>
        To start syncing your data to Xero, we have to first make sure all of
        your accounts in theBOSSapp are matched up to there respective accounts
        in Xero.
      </p>
      <div className="bg-white p-5 border position-relative">
        {account?.integration && (
          <div
            className="position-absolute"
            style={{
              top: "0.5rem",
              right: "0.5rem",
            }}
          >
            <p className="tx-success mb-0">
              <FaCheck className="tx-16" /> Integration Successfull
            </p>
          </div>
        )}
        <AccountForm
          form="Account"
          initialValues={{
            xero_id: estimatedXero?.AccountID,
          }}
          xeroAccounts={accounts?.xero}
          account={account}
          onSubmit={onSubmit}
        />
        <div className="d-flex">
          <Button
            outline
            disabled={currentAccount === 0}
            onClick={moveBackward}
          >
            <RiArrowGoBackFill />
          </Button>
          <Button
            className="ms-auto"
            outline
            disabled={currentAccount + 1 === accounts?.organisation?.length}
            onClick={moveForward}
          >
            <RiArrowGoForwardFill />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Accounts;
