import { CardElement } from "@stripe/react-stripe-js";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import isSubmitting from "../utils/submitting";

const CardModal = (props) => {
  const { toggle, modal, submit, submitting } = props;
  return (
    <Modal
      style={{ maxWidth: "1000px" }}
      className="wd-md-800"
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Update Credit Card</ModalHeader>
      <ModalBody>
        {" "}
        <div className="row">
          <div className="form-group col-12">
            <CardElement className="form-control" />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button disabled={submitting} color="primary" onClick={submit}>
          {isSubmitting(submitting)}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default CardModal;
