import { useQuery } from "react-query";

import api from "../../api/api";
import { ClientContact } from "../ClientContacts";

const getClientContacts = (uuid: string): Promise<ClientContact[]> => {
  return api.get(`clients/${uuid}/contacts`).then(({ data }) => data.data);
};

export default function useClientContacts(uuid: string) {
  return useQuery(["clients", uuid], () => getClientContacts(uuid));
}
