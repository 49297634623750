import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import { FaFilter } from "react-icons/fa";
import {
  Badge,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import SearchInput from "../search/SearchInput";
import BottomLogo from "../utils/BottomLogo";
import FormHeader from "../utils/FormHeader";
import LoadingOverlay from "../utils/LoadingOverlay";
import { TimesheetUser } from "./PayRunForm";
import PayRunOverviewUser from "./PayRunOverviewUser";

const PayRunOverview = ({
  timesheetUsers,
  loading,
  startDate,
  finishDate,
}: {
  startDate?: string;
  finishDate?: string;
  timesheetUsers: TimesheetUser[];
  loading: boolean;
}) => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<string>();

  const timesheets = timesheetUsers.map((user) => user.timesheets)?.flat();

  const filteredUsers =
    !search && !filter
      ? timesheetUsers
      : timesheetUsers
          .filter((user) => {
            return search
              ? user.user.name.toLowerCase().includes(search.toLowerCase())
              : true;
          })
          .filter((user) => {
            return filter ? user.timesheets.length < parseInt(filter) : true;
          });

  if (timesheetUsers.length === 0) {
    return null;
  }

  return (
    <div className="row position-relative">
      <LoadingOverlay loading={loading} />
      <FormHeader>
        Pay Run Overview, From{" "}
        {`${dayjs(startDate).format("DD/MM/YYYY")} To ${dayjs(
          finishDate,
        ).format("DD/MM/YYYY")}`}
      </FormHeader>
      <div className="col-lg-4">
        <div className="position-sticky" style={{ top: "1rem" }}>
          <div className="bg-royal text-white p-3 shadow-sm rounded-lg position-relative">
            <div className="d-flex align-items-center">
              <BiTimeFive className="tx-28 mb-2" />
              <div className="ms-auto">
                <p className="tx-12 mb-0">
                  {`${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(
                    finishDate,
                  ).format("DD/MM/YYYY")}`}
                </p>
              </div>
            </div>
            <p className="mb-0 fw-bolder tx-22">
              {timesheets.length} Timesheets
            </p>
            <p className="mb-0 mt-3">From {timesheetUsers.length} staff</p>
            <p className="mb-0 ">
              For a total of {_.sumBy(timesheets, "total_hours").toFixed(2)}{" "}
              hours
            </p>
            <p className="mb-0 mt-3">Click save to get started</p>
            <BottomLogo />
          </div>
        </div>
      </div>
      <div className="col-lg-8 space-y-5">
        <div className="d-flex align-items-center">
          <SearchInput wrapperclass="flex-grow-1" setValue={setSearch} />
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              caret
              className="p-1"
              aria-label="Filters"
            >
              <FaFilter className="tx-16" />
              <Badge color="" className="p-1 text-black">
                {filter}
              </Badge>
            </DropdownToggle>
            <DropdownMenu style={{ width: "250px" }}>
              <div className="form-group mb-0 p-3">
                <label
                  htmlFor="amount-filter"
                  className="form-control-label tx-inverse tx-semibold"
                >
                  Maximum Timesheets
                </label>
                <input
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  id="amount-filter"
                  className="form-control"
                  type="number"
                />
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        {filteredUsers.map((user) => {
          return <PayRunOverviewUser user={user} />;
        })}
      </div>
    </div>
  );
};

export default PayRunOverview;
