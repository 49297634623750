import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import formError from "../../utils/formError";
import Spinner from "../../utils/Spinner";
import Form from "./Form";
import { Button } from "reactstrap";
import errorSwal from "../../utils/errorSwal";
import deleteSwal from "../../utils/deleteSwal";

const EditJobType = () => {
  const { uuid } = useParams();
  const {
    data: jobType,
    setData: setJobType,
    loading,
  } = useApi(`jobtypes/${uuid}`, {}, true);

  const { takeAction } = useApi();

  const history = useHistory();

  const deleteJobType = () => {
    return deleteSwal()
      .then(() => takeAction("destroy", `/jobtypes/${uuid}`))
      .then(() => {
        toast.success("Job Type deleted successfully");
        history.push("/jobtypes");
      })
      .catch(errorSwal);
  };

  const onSubmit = (values) => {
    return takeAction("update", `/jobtypes/${uuid}`, values)
      .then(({ data }) => {
        setJobType(data.data);
        toast.success(`${data.data.name} updated successfully`);
      })
      .catch(formError);
  };

  if (loading) return <Spinner loading={loading} />;

  return (
    <>
      <HeaderPage
        titlePage={jobType.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "jobtypes", name: "Job Types" },
          {
            link: `/`,
            name: jobType.name,
            active: true,
          },
        ]}
      />
      <Form onSubmit={onSubmit} initialValues={jobType} />
      <div className="d-flex">
        <Button
          onClick={deleteJobType}
          outline
          className="ms-auto"
          color="danger"
        >
          Delete
        </Button>
      </div>
    </>
  );
};

export default EditJobType;
