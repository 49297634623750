import React, { useState } from "react";

const useSelectedDocuments = documents => {
  const [selectedDocument, setSelectedDocument] = useState("");

  const changeDocument = direction => {
    if (!selectedDocument) {
      return;
    }

    let currentPosition = documents.indexOf(selectedDocument);

    setSelectedDocument(getDocument(currentPosition, documents, direction));
  };

  return {
    selectedDocument,
    setSelectedDocument,
    changeDocument
  };
};

export default useSelectedDocuments;

const getDocument = (
  currentPosition,
  documents,
  direction,
  initialPosition = currentPosition
) => {
  const newPosition =
    direction === "forward" ? currentPosition + 1 : currentPosition - 1;

  if (!documents[newPosition]) {
    return documents[initialPosition];
  }

  if (documents[newPosition].is_folder) {
    return getDocument(newPosition, documents, direction, initialPosition);
  }

  return documents[newPosition];
};
