import ReactTable from "../table/ReactTable";
import { ActiveDashboard } from "../timesheets/ProfileCards";
import ApprovalList from "./ApprovalList";
import JobOrder from "./JobOrder";
import tableColumns from "./tableColumns";

export interface HomeDataProps {
  activeDashboard: ActiveDashboard;
  setDashboardData: (data: any) => void;
  dashboardData: any;
  refreshData: Function;
}

const HomeData = ({
  activeDashboard,
  dashboardData,
  setDashboardData,
  refreshData,
}: HomeDataProps) => {
  if (activeDashboard.key === "to-approve") {
    return (
      <ApprovalList
        approvals={dashboardData}
        setApprovals={setDashboardData}
        refreshData={refreshData}
      />
    );
  }

  if (
    activeDashboard.description !== "Jobs" ||
    activeDashboard.key === "overdue-jobs"
  ) {
    return (
      <ReactTable
        data={dashboardData}
        disableSearch
        wrapperClasses="table-responsive card pd-sm-x-0-force"
        columns={tableColumns[activeDashboard.description]}
      />
    );
  }

  return <JobOrder dashboardData={dashboardData} />
};

export default HomeData;
