import React, { useEffect } from "react";
import { Field, reduxForm, reset } from "redux-form";
import useApi from "../api/useApi";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import MentionField from "../form/MentionField";
import { draftField } from "../form/formatters";

const CommentForm = (props) => {
  const { handleSubmit, setComments, comments, notice } = props;

  const { takeAction } = useApi();
  const queryData = useQueryClient();

  const onSubmit = (values, dispatch) => {
    return takeAction("store", "comments", {
      commentable_id: notice.id,
      commentable_type: "App\\Models\\Noticeboard",
      comment: values.comment,
    }).then(({ data }) => {
      setComments([data.data, ...comments]);
      toast.success("Comment Added");
      dispatch(reset("NoticeComment"));
      queryData.invalidateQueries("notices");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="my-3 px-4">
      <Field
        component={MentionField}
        {...draftField}
        validate={required}
        name="comment"
        fieldClass="no-focus border-0 p-2 comment-bg mb-2"
        placeholder="Write Comment Here..."
      />
      <SubmitButton {...props} />
    </form>
  );
};

const form = reduxForm();
export default form(CommentForm);
