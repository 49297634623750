import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateDocket } from "../../actions/docketsActions";
import DocketForm from "./DocketForm";
import { reduxForm } from "redux-form";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import getJobQueryString from "./getJobQueryString";
import axios from "../api/api";

const Edit = (props) => {
  const { initialValues, updateDocket, handleSubmit } = props;
  const [jobs, setJobs] = useState([]);
  const [items, setItems] = useState(initialValues.items);
  const [loadingItems, setLoadingItems] = useState(false);

  useEffect(() => {
    axios
      .get(
        `/docket-jobs?project_id=${initialValues.project_id}&docket_id=${initialValues.id}`,
      )
      .then(({ data }) => setJobs(data.data));
  }, []);

  const submit = (values) => {
    return updateDocket(values)
      .then(() => toast.success("Docket Updated"))
      .catch(errorSwal);
  };

  const getItems = (jobs) => {
    if (jobs.length === 0) return setItems([]);
    const queryString = getJobQueryString(jobs);
    setLoadingItems(true);
    return axios.get(`/docket-items${queryString}`).then(({ data }) => {
      setLoadingItems(false);
      return setItems(data.data);
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <DocketForm
        loadingItems={loadingItems}
        items={items}
        getItems={getItems}
        jobs={jobs}
        {...props}
      />
    </form>
  );
};

const form = reduxForm({ form: "Docket" });

export default connect(null, { updateDocket })(form(Edit));
