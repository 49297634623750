import React from "react";
import Toggle from "react-toggle";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";

const TestStatus = ({ compaction }) => {
  const { takeAction, loading } = useApi();

  const toggleCompleted = ({ target }) => {
    takeAction("update", `/completed-compactions/${compaction.uuid}`, {
      completed_at: target.checked,
    })
      .then(() => toast.success("Compaction Updated"))
      .catch(errorSwal);
  };

  return (
    <div className="row mt-5">
      <div className="col-12">
        <h6 className="tx-inverse">Test Status</h6>
        <hr />
      </div>
      <div className="col-12 form-group ">
        <div>
          <label
            for="toggle-completed"
            className="form-control-label tx-inverse tx-semibold d-block"
          >
            Test Completed
          </label>
          <Toggle
            id="toggle-completed"
            defaultChecked={compaction.completed_at}
            disabled={loading}
            onChange={toggleCompleted}
          />
        </div>
      </div>
    </div>
  );
};

export default TestStatus;
