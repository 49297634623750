import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import formError from "../utils/formError";
import RegisterForm, { RegisterFormProps } from "./RegisterForm";
import { Register } from "./registerTypes";

const AddRegister = () => {
  const { takeAction }: IUseApi<{}, { data: Register }> = useApi();

  const history = useHistory();

  const onSubmit: FormSubmitHandler<Register, RegisterFormProps> = (values) => {
    const data = {
      ...values,
      custom_fields: values.custom_fields.map((field, index) => ({
        ...field,
        order: index,
      })),
    };

    return takeAction("store", `/registers`, data)
      .then(({ data }) => {
        toast.success("Register Added");

        history.push(`/registers/${data.data.uuid}/details`);
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPageAdminFilter titlePage="Add Register" crumbs={[]} />
      <RegisterForm
        form="AddRegister"
        initialValues={{
          custom_fields: [],
        }}
        creating={true}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default AddRegister;
