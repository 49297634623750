import { connect } from "react-redux";
import { Field, formValueSelector, InjectedFormProps } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const MoistureFields = (
  props: InjectedFormProps & {
    branchId: number;
  },
) => {
  return (
    <>
      <div className="col-lg-6 form-group">
        <Field
          label="Tin"
          component={RenderField}
          name="container_id"
          validate={required}
          required
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          label="Branch"
          component={SelectInputAsync}
          name="branch_id"
          url="/organisationbranches/branches"
          validate={required}
          required
          hideDisabled={true}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    branchId: selector(state, "branch_id"),
  };
};

export default connect(mapStateToProps, {})(MoistureFields);
