import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import swal from "sweetalert";
import TimeZones from "../../data/timezone";
import BankDetailFields from "../bankAccountDetails/Fields";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import required, { maxLength } from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import axios from "../api/api";

const length = maxLength(20);

const OrganisationFields = (props) => {
  const { abn, change, invite, loading } = props;

  const handleABN = () => {
    if (!abn) return;

    return axios
      .post(`util/checkabn`, {
        abn,
      })
      .then(({ data: { data: res } }) => {
        if (res.Message) {
          swal({ icon: "warning", text: res.Message });
          change("trading_name", "");
          change("name", "");
          change("abn", "");
          return;
        }
        change("trading_name", res.BusinessName[0]);
        change("name", res.EntityName);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <div className="col-12 form-group">
        <Field
          validate={required}
          component={renderField}
          type="text"
          onBlur={handleABN}
          name="abn"
          label="ABN"
          autoComplete="off"
          required
        />
        {loading && <small>Searching ABN Database...</small>}
      </div>

      <div className="col-lg-6 form-group">
        <Field
          validate={required}
          type="text"
          component={renderField}
          extraProps={{ disabled: true }}
          name="name"
          id="name"
          label="Company Name"
          autoComplete="off"
          required
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          type="text"
          component={renderField}
          name="trading_name"
          id="trading_name"
          label="Trading Name"
        />
        <small>If Different to Company Name</small>
      </div>
      <div className="form-group col-lg-6">
        <Field
          validate={required}
          required
          name="contact_email"
          type="email"
          component={renderField}
          label="Primary Contact Email"
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          validate={[required, length]}
          required
          name="contact_telephone"
          type="text"
          component={renderField}
          label="Primary Contact Phone"
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          validate={required}
          required
          name="accounts_email"
          type="email"
          component={renderField}
          label="Accounts Email"
        />
      </div>
      <div className="form-group col-lg-6">
        <Field name="acn" type="text" component={renderField} label="ACN" />
      </div>
      <div className="form-group col-12">
        <Field
          component={SelectInputAsync}
          name="timezone"
          options={TimeZones}
          validate={required}
          required
          label="Timezone"
        />
      </div>
      <div className="form-group col-lg-12">
        <Field
          name="description"
          type="text"
          textarea
          component={renderField}
          label="Description"
        />
        <small>A quick description of what your company does.</small>
      </div>
      {invite && invite.type === "Supplier" && (
        <>
          <div className="col-12">
            <label className="section-title mt-3">Bank Details</label>
            <p>
              Because you have been invited to be a supplier, you must fill in
              your bank details.
            </p>
            <hr />
          </div>
          <BankDetailFields prefix="account" />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  abn: selector(state, "abn"),
});

const selector = formValueSelector("RegisterOrganisation");
export default connect(mapStateToProps, {})(OrganisationFields);
