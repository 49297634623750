import { useQueryClient } from "react-query";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { Equipment } from "../equipment/equipmentTypes";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import SubmitButton from "../utils/SubmitButton";
import useProjectEquipment from "./hooks/useProjectEquipment";
import { IProjectEquipment } from "./ProjectEquipment";

export interface ProjectEquipmentProps {
  equipment: IProjectEquipment[];
  setEquipment: (equipment: IProjectEquipment[]) => void;
  project: {
    id: number;
  };
}

const ProjectEquipmentForm = (
  props: InjectedFormProps<{ uuid: number }, ProjectEquipmentProps> &
    ProjectEquipmentProps,
) => {
  const queryClient = useQueryClient();
  const { handleSubmit, equipment, setEquipment, project } = props;
  const { takeAction }: IUseApi<{}, { data: IProjectEquipment }> = useApi();
  const { data } = useProjectEquipment();

  const onSubmit: FormSubmitHandler<{ uuid: number }, ProjectEquipmentProps> = (
    { uuid },
    _,
    form,
  ) => {
    return takeAction("store", `equipment/${uuid}/projects`, {
      project_id: project.id,
    })
      .then(({ data }) => {
        setEquipment([...equipment, data.data]);
        toast.success("Equipment added.");
        form?.reset?.();
        queryClient.invalidateQueries(["project-equipment"]);
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row align-items-end">
        <FormHeader>Add Equipment to Project</FormHeader>
        <div className="col-lg-10">
          <Field
            component={SelectInput}
            name="uuid"
            options={
              data?.map((d) => {
                return { label: d.name, value: d.uuid, disabled: d.disabled };
              }) ?? []
            }
          />
        </div>
        <div className="col-lg-2">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<{ uuid: number }, ProjectEquipmentProps>({
  form: "ProjectEquipmentForm",
});

export default form(ProjectEquipmentForm);
