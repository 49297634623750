import axios from "../api/api";
import { UPDATE_PAY } from "../actions/types";

export const updatePay = (postData) => (dispatch) => {
  return axios.put(`/pays/${postData.uuid}`, postData).then(({ data }) =>
    dispatch({
      type: UPDATE_PAY,
      payload: data.data,
    }),
  );
};
