import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import Fields from "./EquipmentCalibrationTypeFields";
import { reduxForm } from "redux-form";
import SubmitButton from "../utils/SubmitButton";

const Edit = (props) => {
  const { setCalibrationType, handleSubmit } = props;

  const { uuid } = useParams();

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `equipment-calibration-types/${uuid}`, values)
      .then(({ data }) => {
        setCalibrationType(data.data);
        toast.success("Updated successfully");
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <Fields {...props} />
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "EquipmentCalibrationType" });

export default form(Edit);
