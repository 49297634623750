import React from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import isSubmitting from "../utils/submitting";

const ResendSupplierInsuranceButton = ({
  supplier,
  setSupplier,
}: {
  supplier: { uuid: string };
  setSupplier: Function;
}) => {
  const { takeAction, loading }: IUseApi = useApi();

  const resendSupplierInsuranceEmail = () => {
    return takeAction(
      "store",
      `suppliers/${supplier.uuid}/resend-insurances`,
    ).then(({ data }) => {
      setSupplier(data.data);
      toast.success("Supplier notified");
    });
  };

  return (
    <button
      disabled={loading}
      type="button"
      onClick={resendSupplierInsuranceEmail}
    >
      {isSubmitting(loading, "Resend Insurance Upload Link", "Sending...")}
    </button>
  );
};

export default ResendSupplierInsuranceButton;
