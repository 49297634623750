import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { ProductivityRange } from "../timesheets/timesheetTypes";
import ComplianceMetrics from "./ComplianceMetrics";
import ProjectProductivity from "./ProjectProductivity";

dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrAfter);

interface ProductivityCardInterface {
  userId: string;
  overviewWidth: number;
  projectWidth: number;
  jobLengthWidth: number;
  projectClass?: string;
}

const ProductivityCards = ({
  userId,
  overviewWidth,
  projectWidth,
  jobLengthWidth,
  projectClass,
}: ProductivityCardInterface) => {
  return (
    <div className="row">
      {/* <OverallProductivityOverview userId={userId} width={overviewWidth} /> */}
      <ComplianceMetrics userId={userId} width={jobLengthWidth} />
      <ProjectProductivity
        wrapperClass={projectClass}
        userId={userId}
        width={projectWidth}
      />
    </div>
  );
};

export const lastQuarterRange: ProductivityRange = [
  dayjs().startOf("Q"),
  dayjs().endOf("Q"),
];

export const dateRangeQuery = (range: ProductivityRange): string => {
  return range[0] && range[1]
    ? `dateRange[]=${range[0].format(
        "YYYY-MM-DD",
      )}&dateRange[]=${range[1].format("YYYY-MM-DD")}`
    : "";
};
export default ProductivityCards;
