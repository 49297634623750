import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import useModal from "../../hooks/useModal";
import { ExternalJobRequest } from "../../jobs/jobTypes";
import DeleteButton from "../../utils/DeleteButton";
import deleteSwal from "../../utils/deleteSwal";
import Empty from "../../utils/Empty";
import errorSwal from "../../utils/errorSwal";
import CustomScaleLoader from "../../utils/scaleLoader";
import TextButton from "../../utils/TextButton";
import ExternalRequestModal from "./ExternalRequestModal";

const ExternalRequests = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { toggle, modal } = useModal();

  const { takeAction, loading: deleting }: IUseApi = useApi();

  const { takeAction: resend, loading: resending }: IUseApi = useApi();

  const { data, loading, setData } = useApi(
    `jobs/${uuid}/external-requests`,
    [],
    true,
  );

  const requests = data as ExternalJobRequest[];

  const resendRequest = (request: ExternalJobRequest) => {
    return resend("store", `/external-job-requests/${request.uuid}/resend`)
      .then(() => {
        toast.success("Request resent");
      })
      .catch(errorSwal);
  };

  const deleteRequest = (request: ExternalJobRequest) => {
    return deleteSwal(request.name)
      .then(() =>
        takeAction("destroy", `external-job-requests/${request.uuid}`),
      )
      .then(() => {
        setData(requests.filter((r) => r.uuid !== request.uuid));
        toast.success("Deleted");
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <CustomScaleLoader>Fetching Requests...</CustomScaleLoader>;
  }

  return (
    <>
      <Button outline color="primary" onClick={toggle}>
        Send Job Externally
      </Button>
      <ExternalRequestModal
        setRequests={setData}
        requests={requests}
        toggle={toggle}
        modal={modal}
      />
      {requests.length === 0 ? (
        <div className="mt-5">
          <Empty top={false}>
            <div className="text-center">
              <p className="mt-4 mb-0 text-dark fw-bolder">
                No External Job Requests Sent
              </p>
              <p>
                You can send this job to external users by{" "}
                <TextButton onClick={toggle}>clicking here</TextButton> or
                above.
              </p>
            </div>
          </Empty>
        </div>
      ) : (
        <div className="bg-white p-3 shadow rounded-lg mt-3">
          <h6 className="slim-card-title mb-4 ">Sent To</h6>
          <div className="space-y-3">
            {requests.map((request) => {
              return (
                <div className="d-flex">
                  <div className="me-3">
                    <p className="mb-0 text-dark">{request.email}</p>
                    <p className="mb-0">{request.name}</p>
                    {request.filled_at ? (
                      <p className="mb-0">
                        Last filled on{" "}
                        <span className="text-dark">
                          {dayjs(request.filled_at).format("DD/MM/YYYY")}
                        </span>{" "}
                        at{" "}
                        <span className="text-dark">
                          {dayjs(request.filled_at).format("hh:mm a")}
                        </span>
                      </p>
                    ) : null}
                  </div>
                  <div className="ms-auto space-x-2">
                    <Button
                      className="py-0"
                      outline
                      onClick={() => resendRequest(request)}
                      disabled={resending}
                    >
                      Resend
                    </Button>
                    <DeleteButton
                      deleteFunction={() => deleteRequest(request)}
                      deleting={deleting}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ExternalRequests;
