import React from "react";
import Label from "./Label";

class Labels extends React.Component {
  //Has to be a class component to print......
  render() {
    return (
      <div className="row">
        {this.props.samples?.map((sample) => {
          return <Label sample={sample} />;
        })}
      </div>
    );
  }
}

export default Labels;
