import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { isSubmitting, isValid, reset, submit } from "redux-form";
import useApi from "../api/useApi";
import SubmitButton from "../utils/SubmitButton";
import Form from "./Form";
import errorSwal from "../utils/errorSwal";

const form = "TimesheetApprovalUser";

const FormModal = (props) => {
  const { toggle, modal, users, setUsers, dispatch } = props;
  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    return takeAction("store", "timesheet-approval-users", values)
      .then(({ data }) => {
        setUsers([...users, data.data]);
        dispatch(reset(form));
        toast.success(`User added successfully`);
      })
      .catch(errorSwal);
  };

  return (
    <Modal className="wd-md-800" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add New Timesheet Approver</ModalHeader>
      <ModalBody>
        <Form form={form} onSubmit={onSubmit} />
      </ModalBody>
      <ModalFooter>
        <SubmitButton {...props} onClick={() => dispatch(submit(form))} />
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    valid: isValid(form)(state),
    submitting: isSubmitting(form)(state),
  };
};

export default connect(mapStateToProps)(FormModal);
