import dayjs from "dayjs";
import { useAuth } from "../../context/auth-context";

const color = (status) => {
  switch (true) {
    case status === "Approved":
      return "bg-primary";
    case status === "To be Approved":
      return "bg-info";
    case status === "To be Redone":
      return "bg-warning";
    case status === "Deletion Rejected":
      return "primary text-warning";
    default:
      return "bg-danger";
  }
};

const ApprovalStatus = ({ status, className = "" }) => {
  const { user } = useAuth();

  if (!status) {
    return null;
  }

  return (
    <div
      className={`badge ${color(
        status.status,
      )} rounded-pill shadow ${className}`}
    >
      <p className="mb-0 tx-10">
        {status.status} by {status.by} (
        {dayjs(status.at).format("DD/MM/YYYY HH:mm")})
      </p>
      {/* {user.active_organisation.id === } */}
    </div>
  );
};

export default ApprovalStatus;
