import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { FormSubmitHandler, InjectedFormProps, reduxForm } from "redux-form";
import NotFound from "../../404";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import { CustomField } from "../../customFields/customFieldTypes";
import CustomForm from "../../customFields/CustomForm";
import FormErrorAlert from "../../form/FormErrorAlert";
import useUploadCustomFields from "../../hooks/useUploadCustomFields";
import formError from "../../utils/formError";
import Spinner from "../../utils/Spinner";
import SubmitButton from "../../utils/SubmitButton";
import errorSwal from "../../utils/errorSwal";
interface ExternalCustomJob {
  organisation: {
    name: string;
    uuid: string;
  };
  custom_fields: CustomField[];
}

const ExternalJobForm = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const urlParams = new URLSearchParams(window.location.search);

  const { upload, setBucket } = useUploadCustomFields();

  const {
    data: job,
    loading,
    response,
  }: IUseApi<ExternalCustomJob> = useApi(
    `external-custom-jobs/${uuid}?email=${urlParams.get("email")}`,
    {},
    true,
  );

  useEffect(() => {
    setBucket(`${job?.organisation?.uuid}/custom-jobs/${uuid}`);
  }, [job]);

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<
    ExternalCustomJob,
    { job: any; urlParams: URLSearchParams }
  > = (values) => {
    return upload(values)
      .then((values) =>
        takeAction(
          "update",
          `external-custom-jobs/${uuid}?email=${urlParams.get("email")}`,
          values,
        ),
      )
      .then(() => {
        toast.success("Information saved, thanks!");
      })
      .catch(formError);
  };

  if (loading) {
    return <Spinner loading />;
  }

  if (response?.status === 404) {
    return <NotFound />;
  }

  return (
    <div className="signin-wrapper">
      <div className="signin-box wd-md-1000 mx-wd-800 rounded-lg shadow">
        <h2 className="slim-logo d-flex justify-content-center">
          <a
            href="https://thebossapp.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/boss-app.png"
              alt="The BOSS App Logo"
              style={{ height: "75px" }}
            />
          </a>
        </h2>
        <p className="text-center mb-4">
          Please fill the following information for {job?.organisation?.name}.
        </p>
        <ReduxForm
          onSubmit={onSubmit}
          initialValues={job}
          job={job}
          urlParams={urlParams}
        />
      </div>
    </div>
  );
};

const Form = (
  props: InjectedFormProps<
    ExternalCustomJob,
    { job: any; urlParams: URLSearchParams }
  > & {
    job: any;
    urlParams: URLSearchParams;
  },
) => {
  const { handleSubmit, error, job, urlParams } = props;
  const {
    takeAction,
    loading,
  }: IUseApi<
    {},
    {
      data: {
        content: {
          text: {
            value: string;
            annotations: any[];
          };
        };
        thread_id?: string;
      };
    }
  > = useApi();

  const onPrompt = (
    fieldUuid: string,
    prompt: string,
    name: string,
    change: Function,
    attachments: any[],
    setAnnotations: Function,
  ) => {
    return takeAction("store", `custom-fields/${fieldUuid}/prompts`, {
      prompts: [
        {
          role: "user",
          content: prompt,
          attachments: attachments.map((a) => ({
            file_id: a.id,
            tools: [
              {
                type: "file_search",
              },
            ],
          })),
        },
      ],
      modelType: "App\\Models\\ProjectJob",
      modelId: job?.id,
    })
      .then(({ data }) => {
        toast.success("Prompt added");

        change(name, data.data.content.text.value);

        if (setAnnotations && data.data.content.text.annotations.length > 0) {
          setAnnotations(data.data.content.text.annotations);
        }

        //Replace the last .value of the 'name' to .thread_id
        const nameArray = name.split(".");

        nameArray[nameArray.length - 1] = "thread_id";

        change(nameArray.join("."), data.data.thread_id);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />
        <CustomForm
          customForm={job.custom_form
            .filter((field: any) => !field.field_attributes.hide_externally)
            .map((field: any) => {
              return {
                ...field,
                field_attributes: {
                  ...field.field_attributes,
                  disabled: field.field_attributes.disabled_externally,
                },
              };
            })}
          {...props}
          onPrompt={onPrompt}
          generatingPrompt={loading}
          shouldError={true}
          templates={job?.template?.documents ?? []}
          parentUuid={job.uuid}
          fieldUrlAppends={`&job_id=${job.id}&email=${urlParams.get("email")}`}
          showInformation={false}
        />
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<
  ExternalCustomJob,
  { job: any; urlParams: URLSearchParams }
>({
  form: "ExternalCustomJob",
});

const ReduxForm = form(Form);

export default ExternalJobForm;
