import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import useEmploymentAwards from "./hooks/useEmploymentAwards";
import { EmploymentAward } from "./types";

const EmploymentAwardsList = () => {
  return (
    <>
      <HeaderPage
        titlePage="Employment Awards"
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          {
            link: "employment-awards",
            name: "Employment Awards",
            active: true,
          },
        ]}
      />
      <PaginatedList
        listName="employmentAwardsList"
        indexHook={useEmploymentAwards}
        originalFilters={[]}
        addLink="/employment-awards/add"
        itemCard={({ item }: { item: EmploymentAward }) => (
          <PaginatedCard
            key={item.id}
            info={info(item)}
            header={<Header award={item} />}
          />
        )}
      />
    </>
  );
};

const Header = ({ award }: { award: EmploymentAward }) => {
  const tooltipId = useMemo(() => `award_title_${award.uuid}`, [award.uuid]);

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={award.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={tooltipId}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={`/employment-awards/${award.uuid}/details`}>
              {award.name}
            </Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {award.name}
          </UncontrolledTooltip>
          <p className="mb-0">{award.abbreviation}</p>
        </div>
      </div>
    </div>
  );
};

const info = (award: EmploymentAward): PaginatedInformation[] => {
  return [
    {
      name: "Abbreviation",
      value: award.abbreviation,
    },
  ];
};

export default EmploymentAwardsList;
