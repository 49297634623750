import PropTypes from "prop-types";

const documentProps = {
  document: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_folder: PropTypes.bool,
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    link: PropTypes.string,
  }).isRequired,
};

export default documentProps;
