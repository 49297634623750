import React from "react";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import RequestedProjectFeedback from "./RequestedProjectFeedback";

const ClientFeedback = ({ uuid }) => {
  const { data: projects, loading } = useApi(`clients/${uuid}/feedback`, []);

  if (loading) {
    return <CustomScaleLoader> Fetching Project Feedback...</CustomScaleLoader>;
  }
  return (
    <>
      {projects.length > 0 ? (
        projects.map((project) => {
          return (
            <RequestedProjectFeedback
              key={project.project_uuid}
              project={project}
            />
          );
        })
      ) : (
        <div className="text-center">No Feedback</div>
      )}
    </>
  );
};

export default ClientFeedback;
