import React from "react";
import swal from "sweetalert";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { useHistory } from "react-router-dom";

const DeleteButton = ({ payRun }) => {
  const { takeAction, loading } = useApi();
  const history = useHistory();

  const hasPayComplete = payRun.pays.find((p) => p.completed_at);

  const deletePayRun = () => {
    if (hasPayComplete) {
      return swal(
        "Unable to delete pay run.",
        "Unable to delete pay runs with complete pays, please mark all pays as incomplete before continuing.",
        "warning",
      );
    }

    return deleteSwal("Pay Run").then(() => {
      takeAction("destroy", `pay-runs/${payRun.uuid}`)
        .then(() => {
          history.push("/pay-runs");
          toast.success("Pay run deleted");
        })
        .catch(errorSwal);
    });
  };

  return (
    <button
      onClick={deletePayRun}
      type="button"
      className="btn w-100 btn-outline-danger mt-3"
      disabled={loading}
    >
      {isSubmitting(loading, "Delete Pay Run", "Deleting...")}
    </button>
  );
};

export default DeleteButton;
