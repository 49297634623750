import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import PricingCards from "./PricingCards";

const Subscription = (props) => {
  const [loading, setLoading] = useState(true);

  const { user } = useAuth();

  const [organisationSubscriptionStatus, setOrganisationSubscriptionStatus] =
    useState({});

  useEffect(() => {
    axios
      .get(`subscriptions/${user.active_organisation.uuid}`)
      .then(({ data }) => {
        setOrganisationSubscriptionStatus(data.data);
        setLoading(false);
      })
      .catch(errorSwal);
  }, []);

  return (
    <div className="card mg-t-20">
      <div className="card-body">
        {loading ? (
          <CustomScaleLoader>Fetching Plan Details</CustomScaleLoader>
        ) : (
          <>
            <h6 className="slim-card-title">Plan Details</h6>
            <p>Update your Organisation's plan.</p>
            <div className="row">
              <PricingCards
                organisationSubscriptionStatus={organisationSubscriptionStatus}
                setOrganisationSubscriptionStatus={
                  setOrganisationSubscriptionStatus
                }
                {...props}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Subscription;
