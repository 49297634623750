import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormSubmitHandler, reset } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import SearchInput from "../search/SearchInput";
import Empty from "../utils/Empty";
import formError from "../utils/formError";
import CustomScaleLoader from "../utils/scaleLoader";
import SupplierContactCard from "./SupplierContactCard";
import ContactModal from "./ContactModal";

export interface ISupplierContact {
  name: string;
  email?: string;
  phone?: string;
  description?: string;
  uuid?: string;
  id: number;
  estimated_user?: {
    id: number;
  };
  user?: {
    name: string;
    email: string;
  };
  projects_count: number;
}

export interface ContactStateProps {
  contact: ISupplierContact;
  contacts: ISupplierContact[];
  setContacts: (contacts: ISupplierContact[]) => void;
}

const SupplierContacts = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { toggle, modal } = useModal();

  const [search, setSearch] = useState("");

  const {
    takeAction,
    loading,
    data,
    setData: setContacts,
    response,
  }: IUseApi<ISupplierContact[], { data: ISupplierContact }> = useApi(
    `suppliers/${uuid}/contacts`,
    [],
  );

  const addContact: FormSubmitHandler<ISupplierContact> = (
    values,
    dispatch,
  ) => {
    return takeAction("store", `suppliers/${uuid}/contacts`, values)
      .then(({ data }) => {
        setContacts([...contacts, data.data]);
        toast.success(`${values.name} added.`);
        dispatch(reset("AddISupplierContact"));
        toggle();
      })
      .catch(formError);
  };

  let contacts = data as ISupplierContact[];

  const lcSearch = search.toLowerCase();

  const filteredContacts = contacts.filter((contact) => {
    return (
      contact.name.toLowerCase().includes(lcSearch) ||
      contact?.email?.toLowerCase().includes(lcSearch) ||
      contact?.phone?.toLowerCase().includes(lcSearch) ||
      contact?.description?.toLowerCase().includes(lcSearch)
    );
  });

  if (loading && !response) {
    return <CustomScaleLoader>Fetching Contacts...</CustomScaleLoader>;
  }

  return (
    <>
      <ul className="nav nav-activity-profile mg-y-20">
        <li className="nav-item">
          <button
            type="button"
            onClick={toggle}
            className="pointer-hover w-100 nav-link"
          >
            <>Add Contact</>
          </button>
        </li>
      </ul>
      {contacts.length === 0 ? (
        <div className="mt-5">
          <Empty top={false}>
            <div className="mt-4 text-center">
              <p className="tx-inverse fw-bolder mb-1">No contacts found</p>
              <p className="mb-0">
                Add client contacts above to make it easy for your team to
                contact the client.
              </p>
              <p className="mb-0">
                You can also add these contacts to projects assigned to this
                client.
              </p>
              <p className="mb-0">This helps with project communication.</p>
              <p>
                <a
                  target="_blank"
                  href="https://docs.thebossapp.com.au/docs/thebossapp-docs/clients/client-contacts"
                  rel="noreferrer noopener"
                >
                  Click here
                </a>{" "}
                for more information on client contacts
              </p>
            </div>
          </Empty>
        </div>
      ) : (
        <>
          <SearchInput setValue={setSearch} wrapperclass="mb-3" />

          <div className="row">
            {filteredContacts.map((contact, index) => {
              return (
                <SupplierContactCard
                  setContacts={setContacts}
                  contacts={contacts}
                  key={index}
                  contact={contact}
                />
              );
            })}
          </div>
        </>
      )}
      <ContactModal
        onSubmit={addContact}
        title="Add new Contact"
        toggle={toggle}
        modal={modal}
        form="AddISupplierContact"
      />
    </>
  );
};

export default SupplierContacts;
