import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getAssistants = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  let url = `open-ai/assistants`;

  if (pageParam !== 1) {
    url += `?after=${pageParam}`;
  }

  return api.get(url).then(({ data }) => data);
};

export default function useAssistants(search?: string) {
  return useInfiniteQuery(
    ["openai-assistants", { search }],
    (pageParam) => getAssistants(pageParam, search),
    {
      getNextPageParam: (data) => {
        if (!data.has_more) {
          return false;
        }

        return data.last_id;
      },
    },
  );
}
