import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import ProfileButtons from "../utils/ProfileButtons";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import TemplateType from "../../enums/TemplateType";
import useModal from "../hooks/useModal";
import GeneratePdfModal from "./GeneratePdfModal";

const GeneratePdf = ({ docket }) => {
  const { takeAction, loading } = useApi();
  const { uuid } = useParams();
  const { data: template } = useApi(
    `template-types?type=${TemplateType.Docket}`,
  );
  const { toggle, modal } = useModal();

  const generatePdf = () => {
    return takeAction("store", `/dockets/${uuid}/pdfs`)
      .then(({ data }) => {
        window.location.replace(data.data.link);
        toast.success("Docket PDF generated, downloading now!");
      })
      .catch(errorSwal);
  };

  const buttons = [
    {
      text: isSubmitting(
        loading,
        <>
          <i className="icon icon ion-archive tx-primary" /> Generate PDF
        </>,
        "Generating...",
      ),
      onClick:
        template?.template?.documents?.length <= 1 || docket.docket_type_id
          ? generatePdf
          : toggle,
    },
  ];

  return (
    <>
      <ProfileButtons buttons={buttons} />
      <GeneratePdfModal
        modal={modal}
        toggle={toggle}
        template={template?.template}
      />
    </>
  );
};

export default GeneratePdf;
