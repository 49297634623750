import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div className="section-wrapper">
        <blockquote className="blockquote bd-l bd-5 pd-l-20">
          {this.props.children}
        </blockquote>
      </div>
    );
  }
}

export default NotFound;
