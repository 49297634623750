import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";

const RemoveParentModal = (props: any) => {
  const { position, modal, toggle, removeChild } = props;

  return (
    <Modal
      className="wd-sm-600 text-danger"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
        Remove {position.division.name} - {position.name} from this chart?
      </ModalHeader>
      <ModalFooter>
        <button
          onClick={() =>
            removeChild({
              ...position,
              parent_id: null,
            })
          }
          className="btn-btn-sm btn-danger"
        >
          Remove
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveParentModal;
