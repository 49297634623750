import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import StatusReportWidget, {
  IStatusProgress,
  IStatusReport,
} from "../utils/widgets/StatusReportWidget";
import { IProject } from "./projectTypes";

const InfoCard = ({ project }: { project: IProject }) => {
  const { number } = useParams<any>();

  const { data } = useApi(`projects/${number}/productivity`);

  const info: IStatusReport[] = [
    {
      title: "Project Value",
      description: "Estimated/Actual:",
      value: `${money.format(
        project.estimated_value.toFixed(2),
      )} / ${money.format(project.realised_value.toFixed(2))}`,
    },
  ];

  const projectProgress: IStatusProgress = {
    min: 0,
    max: 100,
    current: project.percentage_completed,
  };

  if (data) {
    info.push({
      title: "Workload Overview (hours)",
      description: "Estimated/Actual:",
      value: `${data.estimated_hours.toFixed(2)} /
      ${data.productivity_hours.toFixed(2)}`,
    });

    info.push({
      title: "Billed Amount Overview",
      description: "Estimated/Actual:",
      value: `${money.format(data.estimated_billed.toFixed(2))} /
      ${money.format(project.realised_value.toFixed(2))}`,
    });
  }

  return (
    <StatusReportWidget
      title="Project Status"
      reports={info}
      progress={projectProgress}
    />
  );
};

export default InfoCard;
