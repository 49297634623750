import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, FieldArray, formValueSelector } from "redux-form";
import { useAuth } from "../../context/auth-context";
import AddressFields from "../addresses/AddressFields";
import useApi from "../api/useApi";
import CustomForm from "../customFields/CustomForm";
import { money, percentage } from "../form/formatters";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import RenderField, { default as renderField } from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import requireDashes from "../utils/requireDashes";
import CustomScaleLoader from "../utils/scaleLoader";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import ProjectFormFields from "./projectForms/Fields";
import filterOption from "../../utils/filterOption";
import Toggle from "react-toggle";
import InformationAlert from "../utils/InformationAlert";
import JobsFromTemplate from "./JobsFromTemplate";
import dayjs from "dayjs";
import CreatableSelectInput from "../form/CreatableSelectInput";

export const fieldsToRemoveIfTemplate = [
  "client_id",
  "project_manager",
  "scheduled_start_date",
  "scheduled_finish_date",
  "state",
  "suburb",
  "postcode",
  "line_1",
  "line_2",
  "purchase_order",
  "industry_id",
  "branding_theme_id",
  "organisation_divisions",
  "project_type_id",
  "project_forms",
];

const ProjectForm = (props) => {
  const { user } = useAuth();

  const {
    internal,
    initialValues,
    estimated_value,
    adding,
    isTemplate,
    copyJobs,
    startDate,
  } = props;

  const [noScheduledFinishDate, setNoScheduledFinishDate] = useState(
    initialValues.uuid && !initialValues.scheduled_finish_date,
  );

  const [warning, setWarning] = useState({});

  const { data: templateJobs, setUrl: setTemplateJobsUrl } = useApi();

  useEffect(() => {
    if (templateJobs && copyJobs) {
      const mapped = templateJobs.map((job) => {
        const daysBetween = dayjs(job.scheduled_finish_date).diff(
          dayjs(job.scheduled_start_date),
          "day",
        );

        const newScheduledFinishDate = dayjs(startDate)
          .add(daysBetween, "day")
          .format("YYYY-MM-DD");

        return {
          ...job,
          scheduled_start_date: startDate,
          scheduled_finish_date: newScheduledFinishDate,
          selected_job: true,
        };
      });

      props.change("template_jobs", mapped);

      return;
    }
    props.change("template_jobs", []);
  }, [templateJobs, copyJobs]);

  const { data: brandingThemes } = useApi("branding-themes", []);

  const [requiredInformation, setRequiredInformation] = useState(null);
  const [estimatedProjectValue, setEstimatedProjectValue] = useState(null);

  const { takeAction, loading } = useApi();

  const isPositionAdmin = user.is_position_admin;

  const { data: orgStandardJobTypes } = useApi(
    "organisations-standard-job-types",
    [],
  );
  const [hasJobTypeProjectForm, setHasJobTypeProjectForm] = useState(false);

  useEffect(() => {
    if (orgStandardJobTypes) {
      setHasJobTypeProjectForm(
        !!orgStandardJobTypes.find(
          (jobType) => jobType.job_type_type === "App\\Models\\ProjectForm",
        ),
      );
    }
  }, [orgStandardJobTypes]);

  const [isNewRecord, setIsNewRecord] = useState(true);
  useEffect(() => {
    if (initialValues.id) {
      setIsNewRecord(false);
    }
  }, [initialValues]);

  const updateInformation = (estimatedValue) => {
    setEstimatedProjectValue(estimatedValue);
    takeAction(
      "show",
      `estimated-value-project-information?estimated_value=${estimatedValue}${
        initialValues?.uuid ? `&project_uuid=${initialValues.uuid}` : ""
      }`,
    ).then(({ data }) => {
      setRequiredInformation(data.data);
    });
  };

  useEffect(() => {
    if (initialValues?.estimated_value) {
      updateInformation(initialValues.estimated_value);
      setEstimatedProjectValue(initialValues.estimated_value);
    }
  }, []);

  const warnName = () => {
    if (warning.name) {
      return (
        <small>
          The project{" "}
          <Link to={`/${warning.name.link}`}>{warning.name.name}</Link> has a
          similar name
        </small>
      );
    }

    return undefined;
  };

  const checkProjectName = (e, value) => {
    if (!value) return;

    return takeAction("store", "projects/validation", {
      validate: "name",
      value,
    }).then(({ data }) => {
      setWarning({ ...warning, ...data.data });
    });
  };

  return (
    <div className="row">
      {user?.hasAccessTo("App\\Models\\Project", "edit_number") && (
        <>
          <div className="form-group col-lg-6">
            <Field component={renderField} name="prefix" label="Prefix" />
            <small>
              Leave prefix and number blank to have number automatically created
              from your organisation settings
            </small>
          </div>
          <div className="form-group col-lg-6">
            <Field
              component={renderField}
              name="numeral_number"
              label="Project Number"
            />
          </div>
        </>
      )}
      {!props?.initialValues?.uuid && (
        <>
          <div className="col-lg-12 form-group">
            <Field
              component={SelectInput}
              url="/projects?filter[is_template]=true"
              name="template_id"
              label="Template"
              filterOption={filterOption}
              changeValue={(value) => {
                if (value) {
                  props.change("is_confidential", value.is_confidential);

                  props.change("internal", value.internal);

                  setTemplateJobsUrl(
                    `projects/${value.uuid}/jobs?sort=project_jobs.job_number`,
                  );

                  return;
                }

                props.change("template_jobs", []);
              }}
              formatData={(data) =>
                data.map((d) => ({
                  label: (
                    <div>
                      <p className="text-dark mb-0">{d.name}</p>
                      <p className="mb-0 text-secondary">{d.number}</p>
                    </div>
                  ),
                  value: d.id,
                  text: `${d.name} ${d.number}`,
                  internal: d.internal,
                  is_confidential: d.is_confidential,
                  uuid: d.uuid,
                }))
              }
            />
            <small>
              Select a template if you would like to copy jobs and documents
              from another project.
            </small>
          </div>
          {props.templateId && (
            <>
              <div className="col-lg-6 form-group">
                <Field
                  component={renderToggleInput}
                  name="copy_jobs"
                  label="Copy Jobs"
                />
              </div>
              <div className="col-lg-6 form-group">
                <Field
                  component={renderToggleInput}
                  name="copy_documents"
                  label="Copy Documents"
                />
              </div>
            </>
          )}
        </>
      )}
      <div className="form-group col-lg-12">
        <Field
          name="name"
          type="text"
          component={renderField}
          required
          label="Project Name"
          validate={required}
          onBlur={checkProjectName}
        />
        {warnName()}
      </div>
      <div className="form-group col-lg-2">
        <Field
          name="internal"
          component={renderToggleInput}
          label="Internal"
          link={
            <a
              href="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/internal-and-confidential-projects/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-info-circle tx-inverse" />
            </a>
          }
        />
      </div>
      <div className="col-lg-2 form-group align-items-center">
        <Field
          name="is_confidential"
          component={renderToggleInput}
          label="Confidential"
          link={
            <a
              href="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/internal-and-confidential-projects/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-info-circle tx-inverse" />
            </a>
          }
        />
      </div>
      {!initialValues?.uuid && (
        <div className="col-lg-4 form-group align-items-center">
          <Field
            name="is_template"
            component={renderToggleInput}
            label="Project is a Template"
            information="Toggle this on if you'd like to have this project be available as a template for future projects."
          />
        </div>
      )}
      {isTemplate && !initialValues?.uuid && (
        <div className="col-12 form-group">
          <InformationAlert
            type="warning"
            title="Unable to Change Template Projects"
            body="Please be aware, when you mark a project as a 'template' project, you won't be able to change it to a normal project."
          />
        </div>
      )}
      {!internal && !isTemplate && (
        <>
          <div className="form-group col-lg-4">
            <Field
              name="client_id"
              component={SelectInputAsync}
              url={`/organisations/clients?${
                initialValues?.organisation_id &&
                initialValues.organisation_id !== user.active_organisation.id
                  ? `organisation_id=${initialValues.organisation_id}`
                  : ""
              }`}
              required
              label="Client"
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field name="care_of" component={RenderField} label="Care Of" />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name="industry_id"
              component={SelectInput}
              required
              label="Industry"
              validate={required}
              url="/industries"
              formatData={(data) =>
                data.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))
              }
            />
          </div>
          {brandingThemes.length > 0 ? (
            <div className="form-group col-12">
              <Field
                component={SelectInput}
                options={brandingThemes.map((theme) => ({
                  label: theme.name,
                  value: theme.id,
                }))}
                name="branding_theme_id"
                label="Branding Theme"
              />
              <small>If left blank will use organisation branding</small>
            </div>
          ) : null}
        </>
      )}
      {!isTemplate && (
        <>
          <div className="form-group col-lg-4">
            <Field
              name="project_manager"
              component={SelectInputAsync}
              url="/users/projectmanagers"
              required
              label="Project Manager"
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name="team_members"
              component={SelectInputAsync}
              url="/users/staff"
              label="Team Members"
              multiple
            />
          </div>

          {!internal && (
            <>
              <div className="form-group col-lg-4">
                <Field
                  name="purchase_order"
                  component={renderField}
                  label="Purchase Order"
                  required
                  validate={requireDashes}
                />
              </div>
              {(isPositionAdmin || !initialValues?.uuid) && (
                <>
                  <div className="form-group col-lg-4">
                    <Field
                      name="estimated_value"
                      required
                      validate={required}
                      component={renderField}
                      label="Budget/Contract Value"
                      onBlur={() => updateInformation(estimated_value)}
                      {...money}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <Field
                      name="markup_percent"
                      component={renderField}
                      label="Markup"
                      {...percentage}
                    />
                  </div>
                </>
              )}

              {user.active_organisation.id === 1599 &&
                isNewRecord &&
                hasJobTypeProjectForm && (
                  <ProjectFormFields
                    {...props}
                    estimatedProjectValue={estimatedProjectValue}
                  />
                )}
            </>
          )}
        </>
      )}
      <div className="form-group col-lg-12">
        <Field
          name="description"
          type="textarea"
          textarea
          rows={3}
          cols={64}
          component={renderField}
          label="Notes"
          required
          validate={requireDashes}
        />
      </div>
      <div className="form-group col-lg-12">
        <Field
          name="tags"
          component={CreatableSelectInput}
          label="Tags"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isMulti={true}
        />
      </div>
      {!isTemplate && (
        <>
          <div className={`form-group col-lg-6`}>
            <Field
              name="scheduled_start_date"
              type="date"
              component={renderField}
              label="Start Date of Work"
              required
              validate={required}
              extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
            />
          </div>

          <div className={`form-group col-lg-6`}>
            <div className="d-flex align-items-center mb-2">
              <label className="tx-inverse tx-semibold mb-0">
                Scheduled Finish Date
              </label>
              {!noScheduledFinishDate ? (
                <span className="tx-danger"> *</span>
              ) : (
                ""
              )}
              <div className="d-flex ms-auto align-items-center">
                <Toggle
                  checked={noScheduledFinishDate}
                  onChange={() => {
                    setNoScheduledFinishDate(!noScheduledFinishDate);
                    if (!noScheduledFinishDate) {
                      props.change("scheduled_finish_date", null);
                    }
                  }}
                  className="toggle-sm"
                  icons={false}
                />
                <span className="tx-sm ms-1">No Scheduled Finish Date</span>
              </div>
            </div>
            <Field
              name="scheduled_finish_date"
              type="date"
              component={renderField}
              validate={noScheduledFinishDate ? null : required}
              extraProps={{
                min: "1900-01-01",
                max: "2999-12-31",
                disabled: noScheduledFinishDate,
              }}
            />
          </div>

          {!adding && (
            <div className="form-group col-lg-12">
              <Field
                name="status"
                component={SelectInputAsync}
                required
                options={[
                  { label: "Open", value: "0" },
                  { label: "Closed", value: "1" },
                ]}
                label="Status"
                validate={required}
              />
            </div>
          )}

          {!internal && (
            <AddressFields streetAddress="Project Street Address" {...props} />
          )}

          {loading && (
            <div className="col-12">
              <CustomScaleLoader>
                Fetching Extra Information Required...
              </CustomScaleLoader>
            </div>
          )}
          {requiredInformation && (
            <>
              <FormHeader>Extra Information Required</FormHeader>
              <CustomForm
                customForm={requiredInformation?.custom_fields}
                {...props}
              />
            </>
          )}
        </>
      )}
      <FieldArray
        name="template_jobs"
        component={JobsFromTemplate}
        change={props.change}
        startDate={startDate}
        form={props.form}
      />
      <div className="form-layout-footer form-group col-lg-12">
        <SubmitButton {...props} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    internal: selector(state, "internal"),
    estimated_value: selector(state, "estimated_value"),
    templateId: selector(state, "template_id"),
    isTemplate: selector(state, "is_template"),
    copyJobs: selector(state, "copy_jobs"),
    startDate: selector(state, "scheduled_start_date"),
  };
};

export default connect(mapStateToProps, {})(ProjectForm);
