import useModal from "../hooks/useModal";
import AddModal from "./AddModal";
import { PricesProps, PriceStateProps } from "./priceTypes";

const AddModalButton = (props: PriceStateProps & PricesProps) => {
  const { modal, toggle } = useModal();

  return (
    <>
      <button
        type="button"
        onClick={toggle}
        className="btn w-100 btn-outline-primary"
      >
        Add Price
      </button>
      <AddModal {...props} modal={modal} toggle={toggle} />
    </>
  );
};

export default AddModalButton;
