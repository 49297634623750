import dayjs from "dayjs";
import React from "react";
import { Button } from "reactstrap";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { date } from "../form/formatters";
import useModal from "../hooks/useModal";
import dateField from "../utils/dateTime";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import { toast } from "react-toastify";
import useUserGroup from "../hooks/useUserGroup";

interface Purchase {
  uuid: string;
  date: string;
  name: string;
}

interface EditDateProps {
  purchase: Purchase;
  setPurchase: (purchase: Purchase) => void;
}

const EditDate = ({ purchase, setPurchase }: EditDateProps) => {
  const { toggle, modal } = useModal();
  const isAccounting = useUserGroup("Accounting");

  return (
    <div className="d-flex align-items-center">
      <p className="mb-0 tx-inverse">
        Invoice Date:{" "}
        <span className="fw-bolder">
          {dayjs(purchase.date).format("DD/MM/YYYY")}
        </span>
      </p>
      {isAccounting && (
        <>
          <Button
            className="ms-auto"
            outline
            color="info"
            size="sm"
            onClick={toggle}
          >
            Edit
          </Button>
          <EditDateModal
            toggle={toggle}
            modal={modal}
            purchase={purchase}
            setPurchase={setPurchase}
          />
        </>
      )}
    </div>
  );
};

interface EditDateModalProps extends EditDateProps {
  modal: boolean;
  toggle: Function;
}

const EditDateModal = ({
  modal,
  toggle,
  purchase,
  setPurchase,
}: EditDateModalProps) => {
  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<Purchase> = (values) => {
    return takeAction("update", `purchases/${purchase.uuid}/date`, values)
      .then(({ data }) => {
        toggle();
        setPurchase(data.data);
        toast.success(`${purchase.name} date updated.`);
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      form="EditCL"
      initialValues={purchase}
      title={`Edit ${purchase.name} Date`}
      onSubmit={onSubmit}
      modal={modal}
      toggle={toggle}
    >
      <div className="col-12 form-group">
        <Field component={dateField} name="date" {...date} label="Date" />
      </div>
    </FormModal>
  );
};

export default EditDate;
