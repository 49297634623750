import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NoMatch from "../404";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import EditRecurringJob from "./EditRecurringJob";
import RecurringJobDeliverables from "./RecurringJobDeliverables";
import { RecurringJobProfilePages } from "./RecurringJobProfile";
import { RecurringJob } from "./recurringJobTypes";

const ShowRecurringJobPage = ({
  recurringJob,
  setRecurringJob,
}: {
  recurringJob?: RecurringJob;
  setRecurringJob: (job: RecurringJob) => void;
}) => {
  const { page } = useParams<{ page: RecurringJobProfilePages }>();
  const { user } = useAuth();

  switch (page) {
    case "details":
      return (
        <EditRecurringJob
          initialValues={recurringJob}
          setRecurringJob={setRecurringJob}
        />
      );
    case "documents":
      return (
        <ProfileDocuments
          {...recurringJob}
          model="App\Models\RecurringJob"
          documentableId={recurringJob?.uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/recurring-jobs/${recurringJob?.uuid}`}
          homeUrl={`recurring-jobs/${recurringJob?.uuid}/documents`}
        />
      );
    case "deliverables":
      return (
        <RecurringJobDeliverables
          initialValues={recurringJob}
          setRecurringJob={setRecurringJob}
        />
      );
    default:
      return <NoMatch />;
  }
};

export default ShowRecurringJobPage;
