import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const SortDropdown = ({ sort, toggleSort }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} setActiveFromChild={false}>
      <DropdownToggle color="link" className="py-0">
        <i className="fa fa-sort text-secondary" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Sort Items</DropdownItem>
        <DropdownItem
          toggle={false}
          className="d-flex"
          onClick={() => toggleSort("scheduled_start_date")}
        >
          Start Date <SortingIcon name="scheduled_start_date" sort={sort} />
        </DropdownItem>
        <DropdownItem
          toggle={false}
          className="d-flex"
          onClick={() => toggleSort("scheduled_finish_date")}
        >
          Date Due <SortingIcon name="scheduled_finish_date" sort={sort} />
        </DropdownItem>
        <DropdownItem
          toggle={false}
          className="d-flex"
          onClick={() => toggleSort("name")}
        >
          Job Name <SortingIcon name="name" sort={sort} />
        </DropdownItem>
        <DropdownItem
          toggle={false}
          className="d-flex"
          onClick={() => toggleSort("job_number")}
        >
          Number <SortingIcon name="job_number" sort={sort} />
        </DropdownItem>
        <DropdownItem
          toggle={false}
          className="d-flex"
          onClick={() => toggleSort("status")}
        >
          Status <SortingIcon name="status" sort={sort} />
        </DropdownItem>
        <DropdownItem
          toggle={false}
          className="d-flex"
          onClick={() => toggleSort("manager")}
        >
          Job Manager <SortingIcon name="manager" sort={sort} />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const SortingIcon = ({ name, sort }) => {
  if (name !== sort.key) {
    return null;
  }

  if (sort.order) {
    return <i className="fa fa-sort-alpha-asc text-secondary ms-auto" />;
  }

  return <i className="fa fa-sort-alpha-desc text-secondary ms-auto" />;
};

export default SortDropdown;
