import React from "react";
import { Badge } from "reactstrap";
import { BootstrapColour } from "../utils/utilTypes";
import { InvoiceStatus } from "./jobTypes";

const InvoiceStatusBadge = ({ status }: { status?: InvoiceStatus }) => {
  if (status === undefined) {
    return null;
  }
  const info = getStatus(status);

  return (
    <Badge className="rounded-pill shadow" color={info.color}>
      {info.name}
    </Badge>
  );
};

export const getStatus = (
  status: InvoiceStatus | null,
): {
  name: string;
  color: BootstrapColour;
} => {
  switch (status) {
    case InvoiceStatus.AwaitingInvoice:
      return {
        name: "Awaiting Invoice",
        color: "primary",
      };
    case InvoiceStatus.Invoiced:
      return {
        name: "Invoiced",
        color: "info",
      };
    case InvoiceStatus.Paid:
      return {
        name: "Paid",
        color: "success",
      };

    case InvoiceStatus.InvoiceNotRequired:
      return {
        name: "Invoice not Required",
        color: "secondary",
      };
    default:
      return {
        name: "Internal Project",
        color: "info",
      };
  }
};

export default InvoiceStatusBadge;
