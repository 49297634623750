import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getSkillCategories = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `skill-categories?page=${pageParam}&filter[search]=${search}&paginate=9`,
    )
    .then(({ data }) => data);
};

export default function useSkillCategories(search?: string) {
  return useInfiniteQuery(
    ["skill-categories", { search }],
    (pageParam) => getSkillCategories(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
