export interface Integration<ExtraInfo = {}> {
  uuid: string;
  id: number;
  type_description: "Accounting" | "OfficeSuite" | "Payroll" | "Budget" | "AI";
  name:
    | "Xero"
    | "Microsoft"
    | "Xero Payroll"
    | "Employment Hero"
    | "Modano"
    | "OpenAI";
  provider_platform:
    | "Xero"
    | "Microsoft"
    | "Employment Hero"
    | "Modano"
    | "OpenAI";
  extra_info: ExtraInfo;
}

export enum IntegrationType {
  Accounting = "Accounting",
  OfficeSuite = "Office Suite",
  Payroll = "Payroll",
  Budget = "Budget",
  AI = "AI",
}

export enum IntegrationPlatform {
  Xero = "Xero",
  Microsoft = "Microsoft",
  EmploymentHero = "Employment Hero",
  OpenAI = "OpenAI",
}
