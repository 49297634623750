import {
  FETCH_PROJECT_CONTACTS,
  NEW_PROJECT_CONTACT,
  DELETE_PROJECT_CONTACT
} from "../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_CONTACTS:
      return {
        ...state,
        items: action.payload
      };
    case NEW_PROJECT_CONTACT:
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    case DELETE_PROJECT_CONTACT:
      return {
        ...state,
        items: state.items.filter(item => item.uuid !== action.payload)
      };
    default:
      return state;
  }
}
