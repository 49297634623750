import { Progress, Spinner } from "reactstrap";

const UploadProgress = ({ uploadPercent, loading }) => {
  if (!loading) return null;

  return (
    <div className="mt-3">
      <div key={uploadPercent} className="d-flex items-center w-100 mb-2">
        <Spinner type="grow" color="primary" className="me-3" />
        <div className="w-100">
          <p className="mb-0">Uploading Receipts...</p>
          <Progress
            className="progress-bar-sm"
            striped
            animated
            value={uploadPercent || 0}
            color="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default UploadProgress;
