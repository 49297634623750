import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, formValueSelector, reduxForm, submit } from "redux-form";
import { createNoticeboard } from "../../actions/noticeboardsActions";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import SelectInput from "../form/SelectInput";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import FileList from "../utils/FileList";
import UploadProgress from "../documents/UploadProgress";

const NoticeboardModal = (props) => {
  const {
    toggle,
    modal,
    handleSubmit,
    dispatch,
    noticeableType,
    change,
    files,
    setFiles,
    loading,
    percent,
  } = props;

  const { data } = useApi("/user-associations", []);
  const { data: childrenOrganisations } = useApi("organisations/children", []);

  const filterOptions = [
    { label: "Tracking Site", value: "App\\Models\\TrackingSite" },
    { label: "Division", value: "App\\Models\\OrganisationDivision" },
    { label: "Branch", value: "App\\Models\\OrganisationBranch" },
  ];

  return (
    <Modal className="wd-md-850" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add New Notice</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <FormErrorAlert error={props.error} />
            <div className="form-group col-12">
              <Field
                type="text"
                name="name"
                label="Title"
                component={renderField}
                validate={required}
                required
              />
            </div>
            <div className="form-group col-12">
              <Field
                className="form-control"
                textarea
                component={renderField}
                validate={required}
                required
                name="notice"
                label="What's on your mind?"
              />
            </div>
            {childrenOrganisations.length > 0 && (
              <div className="form-group col-12">
                <div className="d-flex">
                  <label className="form-control-label tx-inverse tx-semibold">
                    Include Organisations
                  </label>
                  <div className="ms-auto">
                    <Button
                      color="link"
                      onClick={() => {
                        change("noticeable_id", null);
                        change("noticeable_type", null);
                        change("child_organisations", childrenOrganisations);
                      }}
                    >
                      Select All
                    </Button>
                  </div>
                </div>
                <Field
                  component={SelectInput}
                  options={childrenOrganisations}
                  name="child_organisations"
                  isMulti
                  onChange={() => {
                    change("noticeable_id", null);
                    change("noticeable_type", null);
                  }}
                />
              </div>
            )}
            <div className="col-lg-5">
              <Field
                label="Post to"
                name="noticeable_type"
                component={SelectInput}
                options={filterOptions}
                onChange={() => {
                  change("noticeable_id", null);
                  change("child_organisations", null);
                }}
              />
            </div>
            <div className="col-lg-7">
              <Field
                component={SelectInput}
                options={data
                  .filter((d) => d.class === noticeableType)
                  .map((data) => ({ label: data.name, value: data.id }))}
                name="noticeable_id"
                label="Label"
              />
            </div>
            <div className="form-group col-12">
              <small>
                Leave blank to post to all users in your organisation.
              </small>
            </div>
            <div className="form-group col-12">
              <FileList
                {...props}
                files={files}
                setFiles={setFiles}
                change={change}
                percent={0}
              />
              {loading && <UploadProgress files={files} />}
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          onClick={() => dispatch(submit("AddNotice"))}
          {...props}
        />
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm({ form: "AddNotice" });

const mapStateToProps = (state) => {
  const selector = formValueSelector("AddNotice");

  return {
    noticeableType: selector(state, "noticeable_type"),
  };
};

export default connect(mapStateToProps, { createNoticeboard })(
  form(NoticeboardModal),
);
