import { FormSubmitHandler } from "redux-form";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import TestWorksheetForm, {
  TestWorksheetFormValues,
} from "./TestWorksheetForm";
import { IUseApi } from "../api/apiTypes";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import formError from "../utils/formError";

const AddTestWorksheet = () => {
  const { takeAction }: IUseApi<{}, { data: { uuid: string } }> = useApi();

  const history = useHistory();

  const onSubmit: FormSubmitHandler<TestWorksheetFormValues> = (values) => {
    return takeAction("store", "test-worksheets", {
      ...values,
      test_method_ids: values.test_method_ids?.map((d) => d.value),
      equipment_type_ids:
        values.equipment_types?.map((equipmentType) => {
          return {
            ...equipmentType,
            custom_field_ids: equipmentType.custom_field_ids?.map(
              (d) => d.value,
            ),
          };
        }) ?? [],
    })
      .then(({ data }) => {
        history.push(`/test-worksheets/${data.data.uuid}`);
        toast.success("Test Worksheet Created Successfully");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage titlePage="Add Test Worksheet" crumbs={[]} />

      <TestWorksheetForm form="ADD_TEST_WORKSHEET" onSubmit={onSubmit} />
    </>
  );
};

export default AddTestWorksheet;
