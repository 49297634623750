import React from "react";
import { Link } from "react-router-dom";
import Badges from "./Badges";
import Icon from "./Icon";
import List from "./List";
import Status from "./Status";
const SearchItem = ({ className = "", item }) => {
  return (
    <div className={`py-2 col-sm-6 ${className}`}>
      <div
        className="card-contact h-100"
        style={{ marginBottom: 30, minHeight: 105 }}
      >
        <div>
          <h5 className="mg-t-10 mg-b-5">
            {item.search.link && (
              <Link to={`${item.search.link}`}>{item.search.name}</Link>
            )}
          </h5>
          {item.search.description && <p>{item.search.description}</p>}
          <Status data={String(item.search.status)} />
          <Badges badges={item.search.badges} />
          <Icon data={item.search.icon} />
          <List data={item.search.list} />
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
