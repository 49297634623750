import _ from "lodash";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { JobStatus } from "../jobs/jobTypes";
import errorSwal from "../utils/errorSwal";
import { Invoice } from "./invoiceTypes";

interface UninvoicedDeliverableJob {
  name: string;
  status: JobStatus;
  link: string;
  uuid: string;
}

interface UninvoicedDeliverable {
  id: number;
  name: string;
  job: UninvoicedDeliverableJob;
}

const IncompleteJobList = ({
  deliverables,
  setDeliverables,
  invoice,
}: {
  deliverables: UninvoicedDeliverable[];
  setDeliverables: (deliverables: UninvoicedDeliverable[]) => void;
  invoice: Invoice;
}) => {
  const { takeAction, loading }: IUseApi = useApi();

  const markComplete = (job: UninvoicedDeliverableJob) => {
    return takeAction("update", `project-jobs/status/${job.uuid}`, {
      status: JobStatus.Completed,
    })
      .then(() => {
        toast.success(`${job.name} marked complete`);
        setDeliverables(
          deliverables.map((deliverable) =>
            deliverable.job.uuid !== job.uuid
              ? deliverable
              : {
                  ...deliverable,
                  job: {
                    ...deliverable.job,
                    status: JobStatus.Completed,
                  },
                },
          ),
        );
      })
      .catch(errorSwal);
  };

  const groupedDeliverables = _.groupBy(deliverables, "job.uuid") as {
    [key: string]: UninvoicedDeliverable[];
  };

  const jobs = Object.values(groupedDeliverables)
    .map((jobs) => jobs[0].job)
    .filter((job) => job.status !== JobStatus.Completed);

  if (invoice.client?.is_cod || jobs.length === 0) {
    return null;
  }

  return (
    <div className="mt-3">
      <div className="bg-white border p-3 rounded-lg">
        <h6 className="tx-normal tx-gray-800 fw-bolder tx-uppercase tx-spacing-1 mb-4">
          Incomplete Jobs
        </h6>
        <div className="space-y-3">
          {jobs.map((job) => {
            return (
              <div key={job.uuid} className="mb-0 d-flex align-items-center">
                <Link
                  style={{
                    maxWidth: "60%",
                  }}
                  className="text-truncate"
                  to={job.link}
                >
                  {job.name}
                </Link>
                <div className="ms-auto">
                  <Button
                    outline
                    size="sm"
                    className="w-100"
                    disabled={loading}
                    onClick={() => markComplete(job)}
                  >
                    Mark Complete
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IncompleteJobList;
