import React from "react";
import { useAuth } from "../../context/auth-context";
import FooterLink from "../footer/FooterLink";
import MobileFooter from "../footer/MobileFooter";
import { NavWithoutSub } from "./Header";
import HeaderLogo from "./HeaderLogo";
import MobileLogo from "./MobileLogo";
import UserMenu from "./UserMenu";

const BasicUserNavigation = () => {
  const { user } = useAuth();

  const headers = [
    {
      link: "/jobs",
      icon: "fa fa-building-o",
      heading: "Jobs",
    },
    {
      link: `/users/${user?.uuid}/timesheets`,
      icon: "fa fa-clock-o",
      heading: "Timesheets",
    },
  ];

  return (
    <>
      <div className="slim-header ht-60 ht-lg-75">
        <div className="container">
          <HeaderLogo />

          <MobileLogo />
          <UserMenu />
        </div>
      </div>
      <MobileFooter>
        <FooterLink link="/jobs" icon="fa-building-o" active={false}>
          Jobs
        </FooterLink>
        <FooterLink
          link={`/users/${user?.uuid}/timesheets`}
          icon="fa-clock-o"
          active={false}
        >
          Timesheets
        </FooterLink>
      </MobileFooter>
      <div className="slim-navbar d-none d-lg-block tn-1000">
        <div className="container">
          <ul className="nav shadow-sm">
            {headers.map((header) => {
              return <NavWithoutSub key={header.link} header={header} />;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default BasicUserNavigation;
