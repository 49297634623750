import React from "react";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import swal from "sweetalert";
import errorSwal from "../utils/errorSwal";
import required from "../utils/required";
import signaturePad from "../utils/signaturePad";
import SubmitButton from "../utils/SubmitButton";
import axios from "../api/api";
import UploadSignatureButton from "./UploadSignatureButton";

const UserSignature = (props) => {
  const { handleSubmit, initialValues, setUser } = props;

  const submit = (values) => {
    return swal({
      buttons: ["Cancel", true],
      icon: "warning",
      text: "Once you have saved your signature, you will not be able to change it.",
      title: "Save Signature?",
    }).then((res) => {
      if (res) {
        return axios
          .put(`/user-signatures/${initialValues.uuid}`, values)
          .then(({ data }) => {
            setUser(data.data.user);
            toast.success("Signature Added Successfully");
          })
          .catch(errorSwal);
      }
    });
  };

  if (initialValues.signature || initialValues?.signature_file?.link)
    return (
      <>
        <ul className="nav nav-activity-profile mg-t-20">
          <li className="nav-item">
            <a
              href={
                initialValues?.signature_file?.link ?? initialValues.signature
              }
              download={`${initialValues.first_name}_${initialValues.last_name}_digital_signature.svg`}
              type="button"
              className="nav-link"
            >
              <i className="icon ion-ios-cloud-download tx-info" /> Download
              Signature
            </a>
          </li>
          {!initialValues?.signature_file?.link && (
            <UploadSignatureButton setUser={setUser} />
          )}
        </ul>
        <small className="tx=inverse">
          Download your signature to digitally sign documents.
        </small>
        <img
          src={initialValues?.signature_file?.link ?? initialValues.signature}
          className="img-fluid"
          alt=""
        />
      </>
    );

  return (
    <>
      <ul className="nav nav-activity-profile mg-y-20">
        <UploadSignatureButton setUser={setUser} />
      </ul>
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className="col-12 form-group">
            <Field
              label="Add your signature below"
              component={signaturePad}
              name="signature"
              validate={required}
            />
          </div>
          <div className="col-12 form-group">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const form = reduxForm({ form: "addUserSignature" });

export default form(UserSignature);
