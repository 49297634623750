import { WrappedFieldProps } from "redux-form";

type StyledRadioProps = WrappedFieldProps & {
  options: any[];
  button: (props: any) => JSX.Element;
  input: { value: any; onChange: Function };
  label: string;
};

const StyledRadio = (props: StyledRadioProps) => {
  const {
    options,
    button,
    input: { value, onChange },
    label,
  } = props;

  return (
    <>
      {label}

      {options.map((option) => {
        return button({
          ...option,
          onClick: () => onChange(option.value),
          active: value === option.value,
        });
      })}
    </>
  );
};

export default StyledRadio;
