import isSubmitting from "../utils/submitting";

const findAnnotation = (href: string, value: any) => {
  return value?.content?.text?.annotations?.find(
    (annotation: any) => annotation.text === href,
  );
};

export const LinkRenderer = ({
  href,
  children,
  takeAction,
  loading,
  value,
}: any) => {
  const annotation = findAnnotation(href, value);

  if (annotation) {
    //Get the file name of the path
    const name = annotation.text.split("/").pop();

    return (
      <button
        onClick={() => {
          takeAction("store", `open-ai/files/${annotation.file_path.file_id}`, {
            name,
          }).then(({ data }: { data: any }) => window.open(data.data));
        }}
        className="btn btn-link p-0"
        type="button"
        disabled={loading}
      >
        {isSubmitting(loading, children, "Downloading...")}
      </button>
    );
  }

  return <a href={href}>{children}</a>;
};

export default LinkRenderer;
