import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";

const Edit = (props) => {
  const { user } = useAuth();
  const { organisation, setOrganisation } = props;

  const submit = (values) => {
    return axios
      .put(`bank-account-details/${user.active_organisation.uuid}`, values)
      .then(({ data }) => {
        setOrganisation(data.data.organisation);
        toast.success("Bank account details updated.");
      })
      .catch(errorSwal);
  };
  return (
    <Form onSubmit={submit} initialValues={organisation.account_details} />
  );
};

export default Edit;
