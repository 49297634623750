import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Form from "./Form";
import errorSwal from "../utils/errorSwal";
import { useHistory } from "react-router-dom";

const Add = () => {
  const { takeAction } = useApi();
  const history = useHistory();
  
  const onSubmit = (values : any) => {
    return takeAction("store", "project-types", values)
      .then(() => {
        history.push("/project-types");
        toast.success(`${values.name} added.`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Add New Project Type"
        crumbs={[
          { name: "Project Types", link: "/project-types" },
          { name: "Add Project Type", link: "", active: true },
        ]}
      />
      <Form onSubmit={onSubmit} />
    </>
  );
};

export default Add;
