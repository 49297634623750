import { FiCheck } from "react-icons/fi";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import { Button } from "reactstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { LeaveReasonToSync } from "./SyncLeaveReasons";
import errorSwal from "../utils/errorSwal";

interface EmploymentHeroLeaveCategoryCardProps {
  leaveReason: {
    name: string;
    uuid: string;
    external_integration_id?: number;
  };
  leaveReasons: LeaveReasonToSync;
  setLeaveReasons: (leaveReasons: LeaveReasonToSync) => void;
  refreshData: Function;
}

const EmploymentHeroLeaveCategoryCard = ({
  leaveReason,
  leaveReasons,
  setLeaveReasons,
  refreshData,
}: EmploymentHeroLeaveCategoryCardProps) => {
  const { takeAction, loading }: IUseApi = useApi();

  const estimated =
    !leaveReason?.external_integration_id &&
    leaveReasons.employment_hero_leave_reasons.find(
      (u) => u.name == leaveReason.name,
    );

  const [selected, setSelected] = useState(
    estimated
      ? {
          value: estimated.id,
          label: estimated.name,
        }
      : null,
  );

  // const filterLeaveReasons = () => {

  //   return leaveReasons.filter((u) => u.uuid !== leaveReason.uuid);
  // };

  const createLeaveReason = () => {
    return takeAction(
      "store",
      `integrations/employment-hero/leavecategories/sync/${leaveReason.uuid}`,
    )
      .then(() => {
        toast.success(`${leaveReason.name} synced to Employment Hero`);
        refreshData();
      })
      .catch(errorSwal);
  };

  const syncLeaveReason = () => {
    if (!selected) return toast.error("Please select a leave category");

    return takeAction(
      "update",
      `integrations/employment-hero/leavecategories/sync/${leaveReason.uuid}`,
      {
        employment_hero_id: selected?.value,
      },
    )
      .then(() => {
        toast.success(`${leaveReason.name} synced to Employment Hero`);
        setLeaveReasons({
          ...leaveReasons,
          boss_leave_reasons: leaveReasons.boss_leave_reasons.map((u) =>
            u.uuid !== leaveReason.uuid
              ? u
              : {
                  ...u,
                  external_integration_id: selected?.value,
                },
          ),
        });
      })
      .catch(errorSwal);
  };

  const syncedTo =
    leaveReason.external_integration_id &&
    leaveReasons.employment_hero_leave_reasons.find(
      (u) => u.id == leaveReason.external_integration_id,
    )?.name;

  return (
    <div className="mb-3 col-lg-6">
      <div className="bg-white border rounded-lg">
        <div className="p-3">
          <div className="mb-1 d-flex">
            <p className="mb-0 tx-inverse fw-bolder">{leaveReason.name}</p>
            <p className="mb-0 ms-auto">
              {leaveReason?.external_integration_id ? (
                <FiCheck className="tx-success" />
              ) : (
                <AiOutlineClose className="tx-danger" />
              )}
            </p>
          </div>
          {leaveReason?.external_integration_id ? (
            <p className="mb-0">Synced to {syncedTo}</p>
          ) : null}
        </div>
        {leaveReason?.external_integration_id ? null : (
          <div
            className={`p-3 border-top border-0 tx-inverse bg-light rounded-bottom w-100 justify-content-between align-items-center`}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <Select
                value={selected}
                options={leaveReasons.employment_hero_leave_reasons.map(
                  (reason) => ({
                    value: reason.id,
                    label: reason.name,
                  }),
                )}
                onChange={setSelected}
              />
            </div>
            <div className="d-flex space-x-3 align-items-center mt-3">
              <div>
                <Button
                  onClick={syncLeaveReason}
                  size="sm"
                  color="primary"
                  outline
                  disabled={loading}
                  type="button"
                >
                  Sync
                </Button>
              </div>
              <div>
                <Button
                  onClick={createLeaveReason}
                  size="sm"
                  color="primary"
                  outline
                  type="button"
                  disabled={loading}
                >
                  Create in Employment Hero
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmploymentHeroLeaveCategoryCard;
