import { Field } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { CalibrationType } from "../equipmentCalibrationTypes/calibrationTypeTypes";
import SelectInput from "../form/SelectInput";
import { CustomField } from "./customFieldTypes";

export interface CustomValueFieldProps {
  filters: { key: string; value: any }[];
  field: string;
  value?: CustomField;
}

const EquipmentCustomValueFields = ({
  filters,
  field,
  value,
}: CustomValueFieldProps) => {
  const typeFilters = filters
    ?.filter(({ key }) => key === "type_id")
    .map((filter) => filter.value)
    .join(",");

  const { data }: IUseApi<CalibrationType[]> = useApi(
    `/equipment-calibration-types?filter[equipment_type_id]=${typeFilters}`,
    [],
  );

  const calibrationTypeOptions = data?.map((d) => {
    return {
      label: d.procedures,
      value: d.id,
    };
  });

  const customValueType = value?.field_attributes?.custom_value_type;

  const equipmentCalibrationType = data?.find((d) => d.id === customValueType);

  return (
    <>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInput}
          name={`${field}.field_attributes.custom_value_type`}
          label="Calibration Type"
          options={calibrationTypeOptions}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInput}
          name={`${field}.field_attributes.custom_value`}
          label="Custom Value"
          options={equipmentCalibrationType?.custom_fields.map((field) => {
            return {
              label: field.field_attributes?.label,
              value: field.id,
            };
          })}
        />
      </div>
    </>
  );
};

export default EquipmentCustomValueFields;
