import { useEffect } from "react";
import { Field } from "redux-form";
import SelectInput from "../form/SelectInput";
import { CustomField, FieldType } from "./customFieldTypes";
import { valueFilters } from "./valueFilters";

const CustomFieldValue = ({
  customFields,
  field,
  value,
  newOrder,
  change,
}: {
  field: string;
  customFields: CustomField[];
  value?: CustomField;
  newOrder?: [number, number];
  change: (field: string, value: any) => void;
}) => {
  useEffect(() => {
    if (!newOrder) {
      return;
    }

    const fieldValue = value?.field_attributes?.custom_value_field;

    if (fieldValue === undefined) {
      return;
    }
    if (newOrder[0] > newOrder[1]) {
      if (!(fieldValue >= newOrder[1] && fieldValue <= newOrder[0])) {
        //
      } else if (fieldValue === newOrder[0]) {
        change(`${field}.field_attributes.custom_value_field`, newOrder[1]);
      } else {
        change(`${field}.field_attributes.custom_value_field`, fieldValue + 1);
      }

      return;
    }

    if (!(fieldValue >= newOrder[0] && fieldValue <= newOrder[1])) {
      //
    } else if (fieldValue === newOrder[0]) {
      change(`${field}.field_attributes.custom_value_field`, newOrder[1]);
    } else {
      change(`${field}.field_attributes.custom_value_field`, fieldValue - 1);
    }
  }, [newOrder]);

  const availableFields = customFields
    .map((field, i) => {
      return {
        ...field,
        order: i,
      };
    })
    .filter((field) => {
      return (
        field?.type === FieldType.Select &&
        field?.field_attributes?.options_selection === 1
      );
    })
    .map((field, i) => {
      return {
        label: field.field_attributes?.label ?? null,
        value: field.order,
        ...field,
      };
    });

  const selectedField = availableFields?.find(
    (field) => field.order === value?.field_attributes?.custom_value_field,
  );

  const valueType = valueFilters[selectedField?.field_attributes?.url ?? ""];

  //TODO Clean up the input if the option becomes unavailable
  return (
    <>
      <div className="col-12 form-group">
        <Field
          label="Field"
          component={SelectInput}
          options={availableFields}
          name={`${field}.field_attributes.custom_value_field`}
        />
      </div>
      {valueType?.passes(selectedField) &&
        valueType?.fields({
          filters: selectedField?.field_attributes?.filters ?? [],
          field,
          value,
        })}
    </>
  );
};

export default CustomFieldValue;
