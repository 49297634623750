import { Field, reduxForm } from "redux-form";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import SelectInput from "../form/SelectInput";
import { EmployedUser } from "../user/userTypes";
import RenderField from "../utils/renderField";
import PaginatedSelectInput from "../form/PaginatedSelectInput";

const FormTicketType = (props: any) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <Field
            name="name"
            type="text"
            validate={required}
            required
            component={renderField}
            label="Name"
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            name="branch_id"
            url="/custom-fields/branches"
            formatData={(data: any[]) =>
              data.map((d) => ({
                label: d.name,
                value: d.id,
              }))
            }
            component={SelectInput}
            label="Branch"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={PaginatedSelectInput}
            url="/groups?"
            validate={required}
            required
            name="group_id"
            label="Group"
            formatData={(data: { name: string; id: number }[]) => {
              return data.map((d) => {
                return {
                  label: d.name,
                  value: d.id,
                };
              });
            }}
          />
          <small>Select a group to help filter ticket types.</small>
        </div>

        <div className="col-lg-6 form-group">
          <Field
            url="custom-fields/projects"
            component={SelectInput}
            name="project_id"
            label="Project"
            validate={required}
            required
            formatData={(data: { name: string; id: number }[]) => {
              return data.map((d) => {
                return {
                  label: d.name,
                  value: d.id,
                };
              });
            }}
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            url="custom-jobs"
            component={SelectInput}
            name="custom_job_id"
            label="Custom Job"
            validate={required}
            required
            formatData={(data: { name: string; id: number }[]) => {
              return data.map((d) => {
                return {
                  label: d.name,
                  value: d.id,
                };
              });
            }}
          />
        </div>

        <div className="form-group col-lg-6">
          <Field
            name="job_manager_id"
            validate={required}
            component={SelectInput}
            url="/employed-users"
            required
            label="Job Manager"
            formatData={(data: EmployedUser[]) => {
              return data.map((user) => {
                return {
                  label: user.name,
                  value: user.id,
                };
              });
            }}
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="estimated_hours"
            label="Estimated Hours"
            validate={required}
          />
        </div>

        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};
const form = reduxForm({ form: "TicketType" });

export default form(FormTicketType);
