import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import HeaderPage from "../header/HeaderPage";
import FormMoistureContainer from "./form";
import errorSwal from "../utils/errorSwal";
import Table from "./Table";

class MoistureContainers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedContainer: {},
    };
  }

  selectContainer = (selectedContainer) => {
    if (selectedContainer.uuid === this.state.selectedContainer.uuid)
      return this.setState({ selectedContainer: {} });

    this.setState({ selectedContainer });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Fragment>
        <HeaderPage
          titlePage="Moisture Containers"
          crumbs={[{ link: "", name: "New Moisture Container", active: true }]}
        />

        <FormMoistureContainer
          initialValues={this.state.selectedContainer}
          {...this.props}
        />
        <Table
          {...this.props}
          selectedContainer={this.state.selectedContainer}
          selectContainer={this.selectContainer}
        />
      </Fragment>
    );
  }
}

export default MoistureContainers;
