import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import useSkillCategories from "./hooks/useSkillCategories";
import DocsModal from "../utils/DocsModal";

export interface SkillCategory {
  name: string;
  uuid: string;
  id: string;
  approver_id: string;
  skills_count: number;
  competency_skills: {
    uuid: string;
    skill: string;
  }[];
}

const SkillCategoriesList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/hr-staff-training/skill-categories/" />
      <HeaderPage
        titlePage="Skill Categories"
        pageDescription={
          <>
            Manage and track the skills and qualifications of employees,
            ensuring they have the necessary skills to perform their job duties,
            and skills are organized under skill categories.
          </>
        }
        relatedLinks={[
          {
            name: "Staff Matrix",
            link: "/staff-matrix",
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "", name: "Skill Categories", active: true },
        ]}
      />
      <PaginatedList
        listName="skillCategoriesList"
        indexHook={useSkillCategories}
        originalFilters={[]}
        addLink="/skill-categories/add"
        itemCard={({ item }: { item: SkillCategory }) => (
          <PaginatedCard
            key={item.uuid}
            info={[
              {
                name: "Skill Count",
                value: item.skills_count.toString(),
              },
            ]}
            header={<SkillCategoryHeader item={item} />}
          />
        )}
      />
    </>
  );
};

const SkillCategoryHeader = ({ item }: { item: SkillCategory }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={item.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/skill-categories/${item.uuid}/details`}>
              {item.name}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SkillCategoriesList;
