import axiosWithoutAuthenticationHeaders from "axios";
import { useState } from "react";
import useApi from "../api/useApi";

const useS3Uploader = () => {
  const { takeAction } = useApi();

  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const upload = (file, bucket, callback) => {
    if (!file) {
      return callback ? callback() : Promise.resolve();
    }

    let path = "";
    setLoading(true);
    return takeAction("store", "/pre-signed-urls", {
      name: file.name,
      bucket,
    })
      .then(({ data }) => {
        path = data.data.path;
        return axiosWithoutAuthenticationHeaders.put(data.data.url, file, {
          "Content-Type": "multipart/form-data",
          onUploadProgress: ({ loaded, total }) =>
            setUploadPercent(Math.round((loaded * 100) / total)),
        });
      })
      .then(() => {
        if (callback) {
          return callback({
            path,
            name: file.name,
            file_size: file.size,
            mime_type: file.type,
          });
        }

        return {
          path,
          name: file.name,
          file_size: file.size,
          mime_type: file.type,
        };
      })
      .then((data) => {
        setLoading(false);
        return data;
      });
  };

  return {
    upload,
    uploadPercent,
    setUploadPercent,
    loading,
  };
};

export default useS3Uploader;
