import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";

export type Features =
  | "EntityFeedback"
  | "CopyCustomField"
  | "ConfidentialTenders";

const useFeatureUsage = (
  feature: Features,
): {
  showNotification: boolean;
  updateFeatureUsage: (cacheForever: boolean) => Promise<void>;
} => {
  const { data, takeAction, setData } = useApi(
    `users-feature-check?featureType=${feature}`,
  );

  const updateFeatureUsage = (cacheForever: boolean) => {
    return takeAction("store", `users-feature-check?featureType=${feature}`, {
      forever: cacheForever,
    })
      .then(() => {
        setData({ show_feature: false });
      })
      .catch(errorSwal);
  };

  return {
    showNotification: data?.show_feature ?? false,
    updateFeatureUsage,
  };
};

export default useFeatureUsage;
