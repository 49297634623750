import { useEffect, useState } from "react";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import ProfileButtons from "../utils/ProfileButtons";
import CopyJobModal from "./CopyJobModal";

const ProjectProfileButtons = ({ toggle, selectedJob }) => {
  const { toggle: toggleClone, modal } = useModal();

  const { takeAction, loading } = useApi();

  const [currentJob, setCurrentJob] = useState({});

  useEffect(() => {
    if (selectedJob) {
      takeAction("show", `project-jobs/${selectedJob.uuid}`).then(({ data }) =>
        setCurrentJob(data.data),
      );
    }
  }, [selectedJob]);

  const buttons = [
    {
      onClick: () => toggle(),
      text: (
        <>
          <i className="icon ion-compose tx-primary" /> Create a New Job
        </>
      ),
    },
  ];

  if (selectedJob) {
    buttons.push({
      onClick: toggleClone,
      text: (
        <>
          <i className="fa fa-clone tx-18 me-2 tx-primary" /> Copy Selected Job
        </>
      ),
      disabled: loading,
    });
  }

  return (
    <>
      <ProfileButtons buttons={buttons} />
      <CopyJobModal toggle={toggleClone} modal={modal} job={currentJob} />
    </>
  );
};

export default ProjectProfileButtons;
