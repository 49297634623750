import { Field } from "redux-form";
import useClientContacts from "../clients/hooks/useClientContacts";
import CreatableSelectInput from "../form/CreatableSelectInput";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import renderToggleInput from "../utils/renderToggleInput";
import useApi from "../api/useApi";
import RenderField from "../utils/renderField";

const CancelFormModal = (props : any) => {
  const { toggle, modal, onSubmit } = props;

  return (
    <FormModal
      title="Cancel Support Ticket"
      onSubmit={onSubmit}
      toggle={toggle}
      modal={modal}
      save="Submit"
      saving="Submit..."
      form="CancelSupportTicket"
    >
      <div className="col-12 form-group">
        <Field
          name="cancelled_reason"
          label="Reason for cancelation"
          component={RenderField}
          textarea
        />
      </div>
    </FormModal>
  );
};

export default CancelFormModal;
