import { Badge } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";

export interface OrganisationUserOrganisation {
  uuid: string;
  organisation: {
    display_name: string;
    id: number;
  };
  is_primary_organisation: boolean;
}

const UsersOrganisations = ({
  organisationUsers,
  refreshData,
}: {
  organisationUsers: OrganisationUserOrganisation[];
  refreshData: Function;
}) => {
  const { takeAction, loading: updating }: IUseApi = useApi();

  const makePrimary = (orgUser: OrganisationUserOrganisation) => {
    return takeAction("update", `organisation-users/${orgUser.uuid}`, {
      is_primary_organisation: true,
    })
      .then(() => {
        toast.success("Primary Organisation Updated");
        refreshData();
      })
      .catch(errorSwal);
  };

  return (
    <div className="space-y-3">
      {organisationUsers.map((organisationUser) => {
        return (
          <div className="bg-white border shadow-sm position-relative p-3 w-100 text-start text-dark d-flex align-items-center">
            {organisationUser.organisation.display_name}
            {organisationUser.is_primary_organisation ? (
              <Badge color="success" className="rounded-pill shadow-sm ms-auto">
                Primary
              </Badge>
            ) : (
              <button
                type="button"
                disabled={updating}
                onClick={() => makePrimary(organisationUser)}
                className="btn btn-outline-primary rounded-pill btn-sm ms-auto"
              >
                Make Primary
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default UsersOrganisations;
