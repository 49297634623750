import { useState } from "react";
import { Field } from "redux-form";
import { initialize } from "redux-form";
import renderField from "../utils/renderField";
import SKILL_LEVEL_UNITS from "../../data/skill_level_units";
import SelectInputAsync from "../utils/SelectInputAsync";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import isSubmitting from "../utils/submitting";
import errorSwal from "../utils/errorSwal";
import required from "../utils/required";
import { Link } from "react-router-dom";
import renderToggleInput from "../utils/renderToggleInput";
import axios from "../api/api";

const SkillLevelFields = ({
  index,
  fields,
  field,
  initialValues,
  dispatch,
}) => {
  const skillLevel = initialValues && initialValues.skill_levels[index];

  const [deleting, setDeleting] = useState(false);
  const [isInitial, setIsInitial] = useState(
    skillLevel && skillLevel.units == 0 ? true : false,
  );

  const deleteLevel = () => {
    if (!initialValues || !initialValues[fields.name][index]?.uuid) {
      fields.remove(index);
      return;
    }
    const skill = initialValues[fields.name][index];
    deleteSwal(skill.name)
      .then(() => {
        setDeleting(true);
        return axios.delete(`/skill-levels/${skill.uuid}`);
      })
      .then(({ data }) => {
        toast.success(`${skill.name} deleted successfully`);
        dispatch(initialize("CompetencySkills", data.data));
      })
      .catch(errorSwal)
      .then(() => setDeleting(false));
  };

  const changeUnits = (e, val) => {
    setIsInitial(val == 0 ? true : false);
  };

  const level = fields.get(index);

  return (
    <>
      <div className="col-12">
        <div className="d-flex w-100 align-items-center">
          <label className="section-title m-0">Skill Level {index + 1}</label>
          {level.uuid && (
            <Link
              className="ms-auto mb-0"
              to={`/skill-levels/${level.uuid}/details`}
            >
              View Profile
            </Link>
          )}
        </div>
        <hr />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          validate={required}
          required
          component={renderField}
          name={`${field}.name`}
          label="Name"
        />
      </div>
      <div className={`form-group col-${isInitial ? "12" : "6"}`}>
        <Field
          validate={required}
          required
          label="Units"
          component={SelectInputAsync}
          name={`${field}.units`}
          options={SKILL_LEVEL_UNITS}
          order={false}
          onChange={changeUnits}
        />
        <small>How often does this level need to be renewed?</small>
      </div>
      {!isInitial && (
        <div className="form-group col-6">
          <Field
            validate={required}
            required
            label="Amount"
            component={renderField}
            name={`${field}.amount`}
            type="number"
          />
        </div>
      )}

      <div className="form-group col-lg-12">
        <Field
          name={`${field}.notify_hr`}
          label="Notify HR on Completion?"
          component={renderToggleInput}
        />
      </div>
      <div className="form-group col-12 mg-t-20">
        <Field
          validate={required}
          required
          label="Remarks"
          component={renderField}
          name={`${field}.remarks`}
          textarea
        />
      </div>
      <div className="form-group col-12 d-flex">
        <button
          onClick={deleteLevel}
          className="btn ms-auto btn-outline-danger"
          type="button"
          disabled={deleting}
        >
          {isSubmitting(deleting, "Delete", "Deleting...")}
        </button>
      </div>
    </>
  );
};

export default SkillLevelFields;
