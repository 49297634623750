import React from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";

import Spinner from "../utils/Spinner";
import isSubmitting from "../utils/submitting";
import Form from "./BillableItemForm";

const EditBillableItem = () => {
  const { uuid } = useParams();
  const {
    data: billableItem,
    setData: setBillableItem,
    loading,
  } = useApi(`billable-items/${uuid}`, {}, true);

  const history = useHistory();

  const { takeAction } = useApi();
  const queryClient = useQueryClient();

  const { takeAction: deleteAction, loading: deleting } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `billable-items/${uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.name} updated successfully`);
        setBillableItem(data.data);
      })
      .catch(errorSwal);
  };

  const deleteItem = () => {
    return deleteSwal(billableItem?.name)
      .then(() => deleteAction("destroy", `billable-items/${uuid}`))
      .then(() => {
        queryClient.invalidateQueries("billable-items");
        toast.success("Item deleted");
        history.push("/billable-items");
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={billableItem?.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "billable-items", name: "Billable Items" },
          {
            link: "",
            name: billableItem?.name,
            active: true,
          },
        ]}
      />

      <Form
        onSubmit={onSubmit}
        initialValues={billableItem}
        form="EditBillableItem"
        deleteButton={
          <Button
            onClick={deleteItem}
            color="danger"
            outline
            disabled={deleting}
          >
            {isSubmitting(deleting, "Delete", "Deleting...")}
          </Button>
        }
      />
    </>
  );
};

export default EditBillableItem;
