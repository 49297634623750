import { change } from "redux-form";

const removeComments = (name, dispatch, type) => {
  if (name !== "C") {
    dispatch(
      change("addResidentialSiteInspection", `${type}.comments.${name}`, ""),
    );
  }
};

export default removeComments;
