import React from "react";
import { useParams } from "react-router-dom";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";

const Tabs = ({ invoice }) => {
  const { uuid, page } = useParams();
  return (
    <TabList>
      <Tab
        link={`/invoices/${uuid}/deliverables`}
        icon="fa fa-gift"
        active={page === "deliverables"}
      >
        Deliverables
      </Tab>
      <Tab
        link={`/invoices/${uuid}/documents`}
        icon="fa fa-folder-open-o"
        active={page === "documents"}
      >
        Documents
      </Tab>

      {invoice.deliverables.length > 0 && (
        <Tab
          link={`/invoices/${uuid}/overview`}
          icon="fa fa-gift"
          active={page === "overview"}
        >
          Overview
        </Tab>
      )}

      {invoice.sent_invoices.length > 0 && (
        <Tab
          link={`/invoices/${uuid}/sent`}
          icon="fa fa-gift"
          active={page === "sent"}
        >
          Sent
        </Tab>
      )}
    </TabList>
  );
};

export default Tabs;
