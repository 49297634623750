import React from "react";
import { reduxForm, Field } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import renderField from "../utils/renderField";
import isSubmitting from "../utils/submitting";
import errorSwal from "../utils/errorSwal";
import JOB_STATUS from "../../data/job_status";
import required from "../utils/required";
import axios from "../api/api";

const filterJobs = (jobs, data) => {
  return jobs.map((job) => (job.uuid !== data.uuid ? job : data));
};

const DashboardEditForm = ({
  handleSubmit,
  submitting,
  users,
  initialValues,
  jobs,
  setJobs,
  toggle,
  valid,
}) => {
  const filteredStatus =
    initialValues && initialValues.status <= 4
      ? JOB_STATUS.filter((status) => {
          return status.value <= 4;
        })
      : JOB_STATUS;

  const submit = (values) => {
    return axios
      .put(`/user-jobs/${initialValues.uuid}`, values)
      .then(({ data }) => {
        const filtered = filterJobs(jobs, data.data);
        setJobs(filtered);
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="form-group col-lg-6">
          <Field
            component={SelectInputAsync}
            options={users}
            name="project_manager"
            label="Job Manager"
            validate={required}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            component={renderField}
            type="date"
            name="scheduled_finish_date"
            label="Scheduled Finish Date"
            validate={required}
            extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            component={renderField}
            type="number"
            name="estimated_hours"
            label="Estimated Hours"
            extraProps={{ step: "0.01" }}
            validate={required}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            component={SelectInputAsync}
            options={filteredStatus}
            name="status"
            label="Status"
            validate={required}
          />
        </div>

        <div className="form-group col-lg-12">
          <button
            disabled={submitting}
            type="submit"
            className={`btn btn${
              !valid ? "-outline" : ""
            }-primary d-flex ms-auto`}
          >
            {isSubmitting(submitting)}
          </button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "DashboardJobEdit", enableReinitialize: true });

export default form(DashboardEditForm);
