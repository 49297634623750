import React from "react";
import dayjs from "dayjs";
import { useParams } from "react-router";
import HeaderPage from "../header/HeaderPage";
import useFilter, { IFilter } from "../hooks/useFilter";
import useObserver from "../hooks/useObserver";
import usePagination from "../hooks/usePagination";
import CustomScaleLoader from "../utils/scaleLoader";
import useProjectManager from "./hooks/useProjectManager";
import ProjectManagerFilters from "./ProjectManagerFilters";
import ProjectManagerProject from "./ProjectManagerProject";
import { IProjectManagerProject } from "./projectManagerTypes";
import DocsModal from "../utils/DocsModal";

export type ProjectFilterType = "status" | "internal" | "period";

export type ProjectFilters = IFilter<
  ProjectFilterType,
  number | [string, string]
>[];

const ProjectManagerDashboard = () => {
  const { searchQuery, onSearch } = usePagination();

  const { managerUuid }: { managerUuid: string | undefined } = useParams();

  const { stringified, filters, toggleFilter, filterCount } = useFilter<
    ProjectFilterType,
    number | [string, string]
  >(projectFilters);

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useProjectManager(managerUuid, searchQuery + stringified);

  const intersection = useObserver(
    () => !isFetchingNextPage && fetchNextPage(),
  );

  const projects: IProjectManagerProject[] | undefined = data?.pages
    .map(page => page.data)
    .flat();

  const currentMeta = data?.pages?.[data?.pages?.length - 1]?.meta;
  const total = currentMeta?.total ?? 0;

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/" />
      <HeaderPage
        titlePage="Project Manager Dashboard"
        crumbs={[
          {
            link: "/project-manager",
            name: "Project Manager Dashboard",
            active: true,
          },
        ]}
      />
      <p>You have {total} projects.</p>
      <div className="d-flex align-items-center  mb-3">
        <div className="search-box shadow-sm flex-grow-1 d-flex">
          <input
            autoComplete="off"
            placeholder="Search..."
            className="form-control w-100"
            type="search"
            style={{ zIndex: 1 }}
            onChange={onSearch}
          />
          <button className="btn btn-primary ms-auto" type="button">
            <i
              className={`fa fa-${isFetching ? "spinner fa-spin" : "search"}`}
            />
          </button>
        </div>
        <ProjectManagerFilters
          filterCount={filterCount}
          filters={filters}
          toggleFilter={toggleFilter}
        />
      </div>
      <div className="row">
        {projects?.map(project => {
          return (
            <ProjectManagerProject
              key={project.uuid}
              project={project}
              filters={filters}
            />
          );
        })}
      </div>
      {currentMeta?.current_page < currentMeta?.last_page && (
        <div ref={intersection} />
      )}
      {isFetchingNextPage && (
        <CustomScaleLoader>Fetching More Projects...</CustomScaleLoader>
      )}
    </>
  );
};

export const projectFilters: IFilter<
  ProjectFilterType,
  number | [string, string]
>[] = [
  {
    name: "internal",
    label: "Type",
    options: [
      {
        label: "Internal",
        value: 1,
        selected: false,
      },
      {
        label: "External",
        value: 0,
        selected: false,
      },
    ],
  },
  {
    name: "status",
    label: "Status",
    options: [
      {
        label: "Open",
        value: 0,
        selected: true,
      },
      {
        label: "Closed",
        value: 1,
        selected: false,
      },
    ],
  },
  {
    name: "period",
    label: "Period (from today)",
    options: [
      {
        label: "Fortnight",
        value: [
          dayjs()
            .subtract(2, "weeks")
            .format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
      },
      {
        label: "Month",
        value: [
          dayjs()
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
      },
      {
        label: "Quarter",
        value: [
          dayjs()
            .subtract(3, "months")
            .format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
      },
    ],
  },
];

export default ProjectManagerDashboard;
