import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import { getStaffRoleUrl } from "../deductions/Form";
import SelectInput from "../form/SelectInput";
import FileInput from "../upload/FileInput";
import DateTime, { formatting } from "../utils/dateTime";
import RenderField, { money } from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

const Form = (props) => {
  const { handleSubmit, uploadPercent, initialValues } = props;
  const [approvers, setApprovers] = useState([]);
  const { uuid } = useParams();
  const { takeAction, loading } = useApi();
  const { user } = useAuth();

  const getApprovers = ({ branch_id }) => {
    takeAction(
      "index",
      `timesheet-approval-users/users?branch_id=${branch_id}`,
    ).then(({ data }) => setApprovers(data.data));
  };

  useEffect(() => {
    if (
      initialValues?.approval_action?.action_type === "Redo" ||
      initialValues?.approval_status?.status === "Approved"
    ) {
      getApprovers({ branch_id: initialValues.branch_id });
    }
  }, []);

  const isDisabled =
    initialValues && initialValues?.user?.id !== user.id && !user.is_admin;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-4 form-group">
          <Field
            component={DateTime}
            name="date"
            label="Date"
            {...formatting}
            validate={required}
            required
            extraProps={{
              disabled: isDisabled,
            }}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            url={`/${getStaffRoleUrl(user.id)}`}
            name="role_id"
            label="Role"
            changeValue={getApprovers}
            validate={required}
            required
            extraProps={{
              disabled: isDisabled,
            }}
          />
        </div>
        {(!uuid ||
          initialValues?.approval_action?.action_type === "Redo" ||
          initialValues?.approval_status?.status === "Approved") && (
          <div className="col-lg-4 form-group">
            <Field
              isLoading={loading}
              component={SelectInput}
              options={approvers}
              name="to_approve"
              label="To Approve"
              validate={required}
              required
              extraProps={{
                disabled: isDisabled,
              }}
            />
          </div>
        )}
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="amount"
            label="Amount"
            {...money}
            validate={required}
            required
            extraProps={{
              disabled: isDisabled,
            }}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="account_id"
            label="Account"
            getOptionValues={(options, onChange) => {
              if (options.length === 1 && !initialValues?.uuid) {
                onChange(options[0].value);
              }
            }}
            validate={required}
            required
            url="/chart-of-accounts?expenseClaims=1"
            formatData={(data) =>
              data.map((code) => ({
                label: `${code.code} - ${code.name}`,
                value: code.id,
              }))
            }
            extraProps={{
              disabled: isDisabled,
            }}
            noResults={
              <small>
                No accounts found marked as 'Expense Claims'. Please ask an
                administrator to mark appropriate accounts as 'Expense Claims'.
              </small>
            }
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            url="/custom-fields/projects"
            name="project_id"
            label="Project"
            formatData={(data) =>
              data.map((d) => ({
                label: d.name,
                value: d.id,
              }))
            }
          />
        </div>
        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            name="description"
            label="Description"
            textarea
            validate={required}
            required
            extraProps={{
              disabled: isDisabled,
            }}
          />
        </div>
        {!initialValues?.uuid && (
          <div className="col-lg-12 form-group">
            <Field
              component={FileInput}
              name="file"
              label="Receipt"
              validate={required}
              required
              percent={uploadPercent}
            />
          </div>
        )}

        {!isDisabled && (
          <div className="col-12 form-group">
            <SubmitButton {...props} />
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm();

export default form(Form);
