import React from "react";
import { useParams } from "react-router-dom";
import NotFound from "../404";
import ProfileForm from "../customFields/ProfileForm";
import Form from "./Form";

const ShowPage = (props) => {
  const { page } = useParams();

  const { level, setLevel } = props;

  switch (page) {
    case "details":
      return <Form {...props} form="UpdateSkillLevel" initialValues={level} />;
    case "fields":
      return (
        <ProfileForm
          initialValues={level}
          {...props}
          modelId={level.id}
          modelType="App\Models\SkillLevel"
          onSuccess={(data) =>
            setLevel({
              ...level,
              custom_fields: data.data,
            })
          }
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
