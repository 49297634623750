import React from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { reset } from "redux-form";
import useApi from "../api/useApi.js";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import Form from "./Form.jsx";

const AddButton = (props) => {
  const { categories, setCategories } = props;
  const { toggle, modal } = useModal();

  const { takeAction, loading } = useApi();

  const onSubmit = (values, dispatch, { form }) => {
    return takeAction("store", `cpir-categories`, values)
      .then(({ data }) => {
        toast.success("CPIR Category created successfully");
        setCategories([...categories, data.data]);
        toggle();
        dispatch(reset(form));
      })
      .catch(errorSwal);
  };

  return (
    <>
      <Button outline color="primary" className="mb-3" onClick={toggle}>
        Add a new CPIR category
      </Button>
      <Form
        modal={modal}
        toggle={toggle}
        loading={loading}
        onSubmit={onSubmit}
        title="Add new CPIR Category"
        form="AddCPIRCategory"
      />
    </>
  );
};

export default AddButton;
