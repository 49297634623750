import React from "react";
import { Field } from "redux-form";
import buttonGroup from "../utils/buttonGroup";
import RenderField from "../utils/renderField";
import { CustomField, FieldType } from "./customFieldTypes";

const TextFieldOptions = ({
  value,
  field,
}: {
  value: CustomField;
  field: string;
}) => {
  if (value?.type != FieldType.Text) {
    return null;
  }

  return (
    <>
      <div className="col-lg-6 form-group">
        <Field
          label="Text Type"
          component={buttonGroup}
          name={`${field}.field_attributes.type`}
          options={[
            {
              label: "Text",
              value: "text",
            },
            {
              label: "Number",
              value: "number",
            },
          ]}
        />
      </div>
      {value?.field_attributes?.type === "number" && (
        <div className="col-lg-6 form-group">
          <Field
            label="Step"
            component={RenderField}
            type="number"
            name={`${field}.field_attributes.step`}
          />
        </div>
      )}
    </>
  );
};

export default TextFieldOptions;
