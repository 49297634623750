import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Spinner from "../utils/Spinner";
import {
  fetchDockets,
  updateDocket,
  deleteDocket,
} from "../../actions/docketsActions";

import HeaderPage from "../header/HeaderPage";

import JobList from "../utils/JobList";
import ShowPage from "./ShowPage";
import ProfileHeader from "./ProfileHeader";
import GeneratePdf from "./GeneratePdf";
import ProfileTabs from "./ProfileTabs";

const Profile = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { fetchDockets, match } = props;
    fetchDockets(match.params.uuid).then(() => setLoading(false));
  }, []);

  const { docket } = props;
  const docketName = `Docket Number: ${docket.name}`;

  if (loading) return <Spinner loading={loading} />;

  return (
    <>
      <HeaderPage
        titlePage={docketName}
        crumbs={[
          { link: "dockets", name: "Dockets" },
          {
            link: `dockets/${docket.uuid}`,
            name: docketName,
            active: true,
          },
        ]}
      />
      <div className="row row-sm">
        <div className="col-lg-8">
          <ProfileHeader {...props} />
          <GeneratePdf {...props} />
          <ShowPage {...props} />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <ProfileTabs {...props} />
          <div className="card mg-t-20">
            <div className="card-body">
              <h4 className="card-profile-name mg-b-10">
                Jobs associated to docket
              </h4>
              <JobList jobs={docket.jobs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  docket: state.dockets.items,
});

export default connect(mapStateToProps, {
  fetchDockets,
  updateDocket,
  deleteDocket,
})(Profile);
