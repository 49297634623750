import ProfileForm from "./ProfileForm";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import ProfileDeliverables from "../deliverables/ProfileDeliverables";
import NotFound from "../404";
import { useAuth } from "../../context/auth-context";
import { AdministrationLevels } from "../user/userTypes";
import OverviewProductivity from "../projects/OverviewProductivity";

const ShowPage = (props) => {
  const { page, jobUuid, number, job } = props;
  const { user } = useAuth();

  const isBasic =
    user.position_administration_level == AdministrationLevels.Basic &&
    !user.is_admin;

  switch (page) {
    case "details":
      return <ProfileForm {...props} initialValues={job} />;
    case "documents":
      return (
        <ProfileDocuments
          {...props}
          model="App\Models\ProjectJob"
          documentableId={jobUuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/jobs/${jobUuid}`}
          homeUrl={`jobs/${jobUuid}/documents`}
        />
      );
    case "deliverables":
      return job.client && !isBasic ? (
        <ProfileDeliverables
          {...props}
          model="App\Models\ProjectJob"
          id={jobUuid}
          jobId={jobUuid}
          projectUuid={job.project.uuid}
        />
      ) : (
        <NotFound />
      );
    case "productivity":
      return (
        <OverviewProductivity
          modelId={job.id}
          modelType="App\Models\ProjectJob"
          projectId={job.project.id}
          allDates={true}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
