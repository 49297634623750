import { reduxForm, Field } from "redux-form";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import CustomFields from "../customFields/CustomFields";
import SubmitButton from "../utils/SubmitButton";
import { money } from "../form/formatters";

const Form = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormHeader>Project Information</FormHeader>
        <div className="form-group col-lg-6">
          <Field
            component={RenderField}
            name="lower_limit"
            label="Lower Limit of Estimated Value"
            {...money}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            component={RenderField}
            name="upper_limit"
            label="Upper Limit of Estimated Value"
            {...money}
          />
        </div>
        <FormHeader>Information Required</FormHeader>
      </div>
      <CustomFields {...props} />
      <div className="row">
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm();

export default form(Form);
