import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Spinner from "../utils/Spinner";

const render = (status: Status) => {
  if (status === Status.FAILURE) return <div>Unable to load Google Maps</div>;

  return <Spinner loading />;
};

const MapWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Wrapper apiKey="AIzaSyBJdHEjOZfcfgYYF190-XvLY94f087wydQ" render={render}>
      {children}
    </Wrapper>
  );
};

export default MapWrapper;
