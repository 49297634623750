import { useInfiniteQuery } from "react-query";
import api from "../api/api";

const getJobManagerJobs = ({ pageParam = 1 }, search) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(`job-manager?page=${pageParam}&filter[search]=${search}`)
    .then(({ data }) => data);
};

export default function useJobManagerJobs(filters) {
  return useInfiniteQuery(
    ["job-manager-jobs", { filters }],
    (pageParam) => getJobManagerJobs(pageParam, filters),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
