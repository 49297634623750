import { EquipmentType } from "./EquipmentTypes";
import React from "react";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import isSubmitting from "../utils/submitting";

const DeleteEquipmentType = ({
  equipmentType,
}: {
  equipmentType: EquipmentType;
}) => {
  const { takeAction, loading } = useApi();

  const history = useHistory();

  const deleteType = () => {
    return deleteSwal(equipmentType.type)
      .then(() =>
        takeAction("destroy", `equipment-types/${equipmentType.uuid}`),
      )
      .then(() => {
        toast.success("Equipment Type Deleted");
        history.push("/equipment-types");
      })
      .catch(errorSwal);
  };

  if (equipmentType.equipment_count !== 0) {
    return null;
  }

  return (
    <Button
      disabled={loading}
      block
      className="mt-3"
      outline
      color="danger"
      onClick={deleteType}
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </Button>
  );
};

export default DeleteEquipmentType;
