import React from "react";
import { useHistory } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import { TestSuite } from "./testSuiteTypes";
import { toast } from "react-toastify";
import TestSuiteForm from "./TestSuiteForm";

const AddTestSuite = () => {
  const { takeAction }: IUseApi<{}, { data: TestSuite }> = useApi();
  const history = useHistory();

  const onSubmit: FormSubmitHandler<TestSuite> = (values) => {
    return takeAction("store", `test-suites`, values)
      .then(({ data }) => {
        history.push(`/test-suites/${data.data.uuid}/details`);
        toast.success("Test Suite Added");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Test Suites",
            link: "/test-suites",
          },
          {
            name: "Add New Test Suite",
            link: "/test-suites/add",
            active: true,
          },
        ]}
        titlePage="Add New Test Suite"
      />
      <TestSuiteForm onSubmit={onSubmit} form="AddTestSuite" />
    </>
  );
};

export default AddTestSuite;
