import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import DownloadDropdown from "../customJobs/jobs/DownloadDropdown";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { cleanLink } from "../utils/cleanLink";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import ConfidentialTenderNotification from "./ConfidentialTenderNotification";
import Contacts from "./Contacts";
import ShowTenderPage, { TenderPages } from "./ShowTenderPage";
import TenderSuccessButtons from "./TenderSuccessButtons";

const TenderProfile = () => {
  const { uuid, page } = useParams<{ uuid: string; page: TenderPages }>();

  const {
    data: tender,
    setData: setTender,
    loading,
  } = useApi(`tenders/${uuid}`, {}, true, "/jobs");

  const tabs: ProfileTab<TenderPages>[] = [
    {
      label: "Details",
      link: `/tenders/${uuid}/details`,
      page: "details",
    },
    {
      label: "Quote",
      link: `/tenders/${uuid}/quote`,
      page: "quote",
    },
    {
      label: "Documents",
      link: `/tenders/${uuid}/documents`,
      page: "documents",
    },
    {
      label: "Pricing",
      link: `/tenders/${uuid}/pricing`,
      page: "pricing",
    },
    {
      label: "Emails",
      link: `/tenders/${uuid}/emails`,
      page: "emails",
    },
  ];

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={tender.name}
        crumbs={[
          { link: tender?.project?.link, name: tender?.project?.name },
          { link: tender?.job?.link, name: "Job" },
          { link: "", name: tender.name, active: true },
        ]}
      />
      <Profile
        header={
          <DashboardHeader
            model={{
              type: "App\\Models\\Tender",
              id: tender.id,
            }}
          >
            <DashboardH3>{tender.name}</DashboardH3>
            <p className="mb-1 tx-12">{tender.title}</p>
            <DashboardSubTitle>
              Tender Due{" "}
              {dayjs(tender.job.scheduled_finish_date).format("DD/MM/YYYY")}
            </DashboardSubTitle>
            <DashboardSubTitle>
              {tender?.client?.name && (
                <Link to={cleanLink(`clients/${tender.client.uuid}/details`)}>
                  Client: {tender.client?.name}
                </Link>
              )}
            </DashboardSubTitle>
            <DashboardSubTitle>
              <Badge
                className="rounded-pill shadow mt-3"
                color={`${tender.submitted_on ? "info" : "warning"}`}
              >
                {tender.submitted_on ? (
                  <>
                    Submitted on{" "}
                    {dayjs(tender.submitted_on).format("DD/MM/YYYY")}
                  </>
                ) : (
                  "Not Submitted"
                )}
              </Badge>
            </DashboardSubTitle>
            <DashboardSubTitle>
              {tender.successful !== null && (
                <Badge
                  className="rounded-pill shadow"
                  color={tender.successful ? "success" : "danger"}
                >
                  {tender.successful
                    ? "Tender Successful"
                    : "Tender Unsuccessful"}
                </Badge>
              )}
            </DashboardSubTitle>
            {tender.work && (
              <DashboardSubTitle>
                <Link to={cleanLink(tender.work.link)}>{tender.work.name}</Link>
              </DashboardSubTitle>
            )}
          </DashboardHeader>
        }
        content={
          <>
            <ConfidentialTenderNotification />
            <TenderSuccessButtons tender={tender} setTender={setTender} />
            <DownloadDropdown
              documents={tender?.type?.template?.documents}
              model={{ model: "App\\Models\\Tender", id: tender.id }}
            />
            <ShowTenderPage tender={tender} setTender={setTender} />
          </>
        }
        tabs={tabs}
        sideBar={
          <>
            <div className="mt-3">
              <Contacts tender={tender} />
            </div>
            <div className="mt-3">
              <SideList
                commentableType="App\Models\ProjectJob"
                commentableId={tender.job.id}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default TenderProfile;
