import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, Form, reduxForm, submit } from "redux-form";
import FileInput from "../upload/FileInput";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import useS3Uploader from "../hooks/useS3Uploader";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { useParams } from "react-router-dom";

const RevisionModal = (props) => {
  const {
    handleSubmit,
    toggle,
    modal,
    deliverable,
    dispatch,
    setDeliverable,
    bucket,
  } = props;
  const { upload, uploadPercent } = useS3Uploader();
  const { takeAction } = useApi();
  const { uuid } = useParams();

  const uploadRevision = (values) => {
    return upload(values.revision_file, bucket, (documents) => {
      const data = {
        reason: values.revision_reason,
        documents: [documents],
        deliverableId: uuid,
      };
      return takeAction("store", "deliverables/revisions", data)
        .then(({ data }) => {
          toast.success("Revision uploaded successfully");
          setDeliverable(data.data);
          toggle();
        })
        .catch(errorSwal);
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Upload revision for {deliverable.name}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(uploadRevision)}>
          <div className="row">
            <div className="col-md-12 form-group">
              <Field
                name="revision_file"
                validate={required}
                component={FileInput}
                required
                label="File"
                percent={uploadPercent}
              />
            </div>
            <div className="col-md-12 form-group">
              <Field
                name="revision_reason"
                textarea
                validate={required}
                component={renderField}
                required
                label="Reason"
              />
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          save="Upload"
          saving={`Uploading Revision ${deliverable.revision_number + 1}`}
          onClick={() => dispatch(submit("revisionModal"))}
          {...props}
        />
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm({
  form: "revisionModal",
});

export default form(RevisionModal);
