import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";

const Edit = (props) => {
  const { setBudgetTemplate } = props;
  const { uuid } = useParams();
  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `budget-templates/${uuid}`, values)
      .then(({ data }) => {
        setBudgetTemplate(data.data);
        toast.success("Template Updated Successfully");
      })
      .catch(errorSwal);
  };

  return <Form form="EditBudgetTemplate" onSubmit={onSubmit} {...props} />;
};

export default Edit;
