import React from "react";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import GenerateFromButton from "../dockets/generateFromButton";
import HeaderPage from "../header/HeaderPage";
import * as header from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import ShowMachineDayRatePage from "./ShowMachineDayRatePage";

const Profile = () => {
  const { uuid, page } = useParams<{ uuid: string; page: string }>();

  const {
    data: machineDayRate,
    setData: setMachineDayRate,
    loading,
  } = useApi(`machine-day-rates/${uuid}`, null, true);

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={`${machineDayRate.job.name} - ${page}`}
        crumbs={[
          {
            link: "/",
            name: "Machine Day Rate",
            active: true,
          },
        ]}
      />

      <div className="row row-sm">
        <div className="col-lg-8">
          <header.DashboardHeader>
            <header.DashboardH3>{machineDayRate.job.name}</header.DashboardH3>
            <header.DashboardSubTitle>
              <Link to={`/${machineDayRate.job.project.link}`}>
                {machineDayRate.job.project.name}
              </Link>
            </header.DashboardSubTitle>
            <header.DashboardSubTitle>
              Operator:{" "}
              <a href={`mailto:${machineDayRate.operator.email}`}>
                {machineDayRate.operator.name}
              </a>
            </header.DashboardSubTitle>
            <header.DashboardSubTitle>
              Job:{" "}
              <Link to={machineDayRate.job.link}>
                {machineDayRate.job.name}
              </Link>
            </header.DashboardSubTitle>
          </header.DashboardHeader>
          <div className="my-3 d-block d-lg-none">
            <GenerateFromButton initialValues={machineDayRate.job} />
          </div>
          <ShowMachineDayRatePage
            machineDayRate={machineDayRate}
            setMachineDayRate={setMachineDayRate}
          />
        </div>

        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <TabList>
            <Tab
              link={`/machine-day-rates/${uuid}/details`}
              active={page === "details"}
              icon="fa fa-file-text-o text-capitalize"
            >
              Details
            </Tab>
            <Tab
              link={`/machine-day-rates/${uuid}/documents`}
              active={page === "documents"}
              icon="fa fa-folder-open-o text-capitalize"
            >
              Documents
            </Tab>
          </TabList>
          <div className="mt-3 d-none d-lg-block">
            <GenerateFromButton initialValues={machineDayRate.job} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
