import React from "react";
import { Field } from "redux-form";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import required from "../utils/required";

const Form = (props) => {
  return (
    <FormModal {...props}>
      <div className="col-lg-12 form-group">
        <Field
          name="name"
          validate={required}
          type="text"
          label="Industry name"
          component={RenderField}
          required
        />
      </div>
    </FormModal>
  );
};

export default Form;
