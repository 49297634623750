import { Link, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import useApi from "../api/useApi";
import ApprovalStatus from "../user/ApprovalStatus";
import formatDate from "../utils/formatDate";
import CustomScaleLoader from "../utils/scaleLoader";

const SkillHistory = (props) => {
  const { competency, competencyUuid } = props;

  const { uuid } = useParams();

  const { data: competencySkillHistory, loading } = useApi(
    `users/${uuid}/staff-competencies/${competencyUuid}/competency-skill-history`,
    [],
  );

  if (loading) {
    return (
      <CustomScaleLoader>
        <p>Fetching History...</p>
      </CustomScaleLoader>
    );
  }

  return (
    <HistoryList
      competencySkillHistory={competencySkillHistory}
      competency={competency}
    />
  );
};

const HistoryList = (props) => {
  const { competencySkillHistory, competency } = props;

  return (
    <div className="row mg-b-20">
      <div className="col-md-12">
        <p className="slim-card-title">
          {competency?.skill_level?.name} History
        </p>
        {competencySkillHistory.map((competency) => (
          <Link
            key={competency.uuid}
            to={`/staff-competencies/${competency.uuid}`}
          >
            <div
              className="bg-white border pd-20 pointer-hover link-hover tx-inverse mb-3"
              key={competency.uuid}
            >
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h6 className="mb-0">{competency.skill_level.name}</h6>
                  {competency.comments && (
                    <p className="mb-0 text-secondary">{competency.comments}</p>
                  )}
                  <p className="mb-0 text-secondary">
                    {formatDate(competency.added_on)}
                  </p>
                </div>
                <CompetencyStatusBadge competency={competency} />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

const CompetencyStatusBadge = ({ competency }) => {
  if (competency.completed_at) {
    return (
      <Badge className="rounded-pill shadow" color="success">
        Completed
      </Badge>
    );
  }

  if (!competency.has_job) {
    return (
      <Badge className="rounded-pill shadow" color="warning">
        Upcoming
      </Badge>
    );
  }

  if (competency.approval_status?.status === "To be Approved") {
    return (
      <ApprovalStatus
        status={competency.approval_status}
        className="rounded-pill shadow"
      />
    );
  }

  return (
    <Badge className="rounded-pill shadow" color="info">
      In Progress
    </Badge>
  );
};

export default SkillHistory;
