import React, { useState } from "react";
import isSubmitting from "../utils/submitting";
import dayjs from "dayjs";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import axios from "../api/api";

const MarkAsBilledButton = ({ payment, setPayment, isAccounting }) => {
  const [submitting, setSubmitting] = useState(false);

  const markAsBilled = () => {
    setSubmitting(true);
    axios
      .post(`/billed-purchases`, { uuids: [payment.uuid] })
      .then(() => {
        setPayment({
          ...payment,
          billed_at: dayjs().format("YYYY-MM-DD"),
        });
        toast.success("Marked as billed.");
      })
      .catch(errorSwal)
      .then(() => setSubmitting(false));
  };

  if (!isAccounting || payment.billed_at || payment.approval_action) {
    return null;
  }

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      <li className="nav-item">
        <button
          type="button"
          disabled={submitting}
          onClick={markAsBilled}
          className="pointer-hover w-100 nav-link"
        >
          {isSubmitting(
            submitting,
            <>
              <i className="icon ion-checkmark tx-primary tx-16" /> Mark as
              Billed
            </>,
            "Marking...",
          )}
        </button>
      </li>
    </ul>
  );
};

export default MarkAsBilledButton;
