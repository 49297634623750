import dayjs from "dayjs";
import { Link } from "react-router-dom";

const getInvoiceDetails = (invoice) => {
  let text = "No Invoice Created";
  let color = "info";
  let link = "No Invoice Created";
  if (!invoice) {
    return {
      text,
      color,
      link,
    };
  }

  if (invoice.paid_at) {
    text = `Paid on ${formatDate(invoice.paid_at)}`;
    link = (
      <Link className="text-white" to={invoice.link}>
        {invoice.name} Paid
      </Link>
    );
    color = "success";
    return {
      text,
      link,
      color,
    };
  }

  if (invoice.completed_at) {
    text = `Invoiced on ${formatDate(invoice.date ?? invoice.completed_at)}`;
    link = (
      <Link className="text-white" to={invoice.link}>
        {invoice.name} Completed
      </Link>
    );
    color = "success";
    return {
      text,
      link,
      color,
    };
  }

  if (invoice.requested_at) {
    text = `Invoice Requested on ${formatDate(invoice.requested_at)}`;
    link = (
      <Link className="text-white" to={invoice.link}>
        {invoice.name} Requested
      </Link>
    );
    color = "warning";
    return {
      text,
      link,
      color,
    };
  }
  text = "Draft Invoice";
  link = "Draft Invoice";
  color = "info";
  return {
    text,
    link,
    color,
  };
};
const formatDate = (date) => dayjs(date).format("DD/MM/YYYY");

export default getInvoiceDetails;
