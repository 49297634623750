import { Field, reduxForm } from "redux-form";
import {
  NEARBY_VEGETATION,
  SITE_DRAINAGE,
} from "../../../data/weather_conditions";
import buttonGroup from "../../utils/buttonGroup";
import WeatherButtons from "./weatherButtons";

const WeatherConditions = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <div className="col-12">
          <h5 className="text-dark">Weather Conditions</h5>
        </div>
        <WeatherButtons name="day_of_inspection" label="Day of Inspection" />
        <WeatherButtons
          name="days_prior_to_inspection"
          label="Days Prior to Inspection"
        />
        <div className="col-12">
          <label className="mg-b-0 mg-t-10">Site Drainage</label>
        </div>
        <div className="col-12">
          <Field
            component={buttonGroup}
            options={SITE_DRAINAGE}
            name="weather.site_drainage"
            groupClass="w-100"
            buttonClass="w-100"
          />
        </div>
        <div className="col-12">
          <label className="mg-b-0 mg-t-10">Nearby Vegetation</label>
        </div>
        <div className="col-12">
          <Field
            component={buttonGroup}
            options={NEARBY_VEGETATION}
            name="weather.nearby_vegetation"
            groupClass="w-100"
            buttonClass="w-100"
          />
        </div>
      </div>
      <div className="row mg-y-20">
        <div className="col-6">
          <button
            className="btn w-100 bg-gray-400 tx-gray-800"
            type="button"
            onClick={props.previousPage}
          >
            <i className="icon ion-reply" /> General
          </button>
        </div>
        <div className="col-6">
          <button className="btn w-100 btn-primary" type="submit">
            Preparation Inspected <i className="icon ion-forward" />
          </button>
        </div>
      </div>
    </form>
  );
};
const form = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: "addResidentialSiteInspection",
});

export default form(WeatherConditions);
