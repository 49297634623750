import React, { Component } from "react";
import { connect } from "react-redux";

import Spinner from "../utils/Spinner";
import { fetchOversizeFieldMoistures } from "../../actions/oversizeFieldMoistureActions";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import MoistureForm from "../moistures/MoistureForm";
import OversizeForm from "./OversizeForm";

class OversizeFieldMoisture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    const { fetchOversizeFieldMoistures, match } = this.props;
    return fetchOversizeFieldMoistures(match.params.test)
      .catch(() => errorSwal())
      .then(() => this.setState({ loading: false }));
  };
  render() {
    const { handleSubmit, oversizeFieldMoisture } = this.props;
    const { loading } = this.state;
    if (loading) return <Spinner loading={loading} />;

    const moistureInitialValues = {
      testable_id: oversizeFieldMoisture.field_moisture.id,
      testable_type: "App\\Models\\FieldMoisture",
      ...oversizeFieldMoisture.field_moisture.moisture,
    };
    return (
      <div>
        <HeaderPage
          titlePage={oversizeFieldMoisture.name}
          crumbs={[
            { link: "", name: oversizeFieldMoisture.name, active: true },
          ]}
        />

        <div className="row">
          <div className="col-md-6">
            <OversizeForm
              initialValues={oversizeFieldMoisture.oversize}
              {...this.props}
            />
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <h6 className="tx-inverse">Field Moisture Details</h6>
                <hr />
              </div>
            </div>
            <MoistureForm
              initialValues={moistureInitialValues}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  oversizeFieldMoisture:
    state.oversizeFieldMoistures.items && state.oversizeFieldMoistures.items,
});

export default connect(mapStateToProps, {
  fetchOversizeFieldMoistures,
})(OversizeFieldMoisture);
