import { Table } from "@tanstack/react-table";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const ToggleColumns = ({ table }: { table: Table<any> }) => {
  return (
    <UncontrolledDropdown direction="up">
      <DropdownToggle size="sm" outline caret>
        Toggle Columns
      </DropdownToggle>
      <DropdownMenu>
        <div className="border-bottom px-3 py-1">
          <label className="mb-1">
            <input
              {...{
                type: "checkbox",
                checked: table.getIsAllColumnsVisible(),
                onChange: table.getToggleAllColumnsVisibilityHandler(),
              }}
            />{" "}
            Toggle All
          </label>
        </div>
        <div className="px-3 py-2">
          {table.getAllColumns().map((column) => {
            return (
              <div key={column.id}>
                <label>
                  <input
                    {...{
                      type: "checkbox",
                      checked: column.getIsVisible(),
                      onChange: column.getToggleVisibilityHandler(),
                    }}
                  />{" "}
                  {typeof column.columnDef.header === "function"
                    ? column.id
                    : column.columnDef.header ?? ""}
                </label>
              </div>
            );
          })}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ToggleColumns;
