import React from "react";
import FeatureNotification from "../utils/FeatureNotification";

const ConfidentialTenderNotification = () => {
  return (
    <FeatureNotification featureName="ConfidentialTenders">
      <h5 className="tx-info">Confidential Tenders Now Available</h5>
      <p className="mb-0">
        You are now able to mark a tender as 'confidential'
      </p>
      <p>
        When a tender is confidential, only admins, people part of the tender
        and people in the 'Business Development' group are able to see them.
      </p>
      <p>
        Toggle the 'Confidential' switch below to mark this tender as
        confidential.
      </p>
    </FeatureNotification>
  );
};

export default ConfidentialTenderNotification;
