import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { reduxForm, Field, submit } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import renderToggleInput from "../utils/renderToggleInput";
import axios from "../api/api";

const StopWorkModal = (props) => {
  const { toggle, modal, handleSubmit, dispatch } = props;
  return (
    <Modal
      className="wd-md-850"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader>Was Work Stopped?</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-12">
              <Field
                name="work_stopped"
                label="No/Yes"
                component={renderToggleInput}
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          save="Submit"
          onClick={() => dispatch(submit("StopWorkIncident"))}
          {...props}
        />
      </ModalFooter>
    </Modal>
  );
};

const submitForm = (values, _, props) => {
  props.toggle();
  if (values.work_stopped) {
    axios.post(`/work-has-stopped-email`);
  }
};

const form = reduxForm({ form: "StopWorkIncident", onSubmit: submitForm });

export default form(StopWorkModal);
