import React from "react";
import Search from "../search/Search";

const MobileSearch = () => (
  <div className="slim-navbar d-lg-none pd-t-10 pd-b-10">
    <div className="container">
      <Search />
    </div>
  </div>
);

export default MobileSearch;
