import { useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { BiCloudUpload } from "react-icons/bi";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Progress } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import errorSwal from "../utils/errorSwal";
import InformationAlert from "../utils/InformationAlert";

const EquipmentUpload = () => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();

  const { takeAction }: IUseApi<{}, { data: any }> = useApi();

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setRejectedFiles(fileRejections);

    setLoading(true);
    return upload(
      acceptedFiles[0],
      `tmp/organisations/equipment-upload`,
      (file: any) => {
        return takeAction("store", "/equipment/import", file)
          .then(({ data }) => {
            toast.success("Equipment Uploaded");
            setUploadPercent(0);
            setLoading(false);
            history.push("/equipment");
          })
          .catch((err) => {
            setLoading(false);
            errorSwal(err);
          });
      },
    );
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      multiple: false,
      onDrop,
      disabled: loading,
    });

  return (
    <div className="border-0 bg-white shadow-sm rounded-lg  position-relative">
      <div className="p-3 border-bottom">
        <p className="fw-bolder text-dark mb-0">Upload Excel</p>
      </div>
      <div className="p-3">
        <div
          {...getRootProps()}
          className={`tn-300 rounded-lg ${
            isDragActive ? "bg-gray-100" : ""
          } d-flex align-items-center w-100 h-100 justify-content-center`}
          style={{ minHeight: "7rem", border: "1px dashed #dee2e6" }}
        >
          <input {...getInputProps()} />
          {loading ? (
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          ) : (
            <div>
              <div className="text-center">
                <BiCloudUpload className="tx-24 text-dark" />
              </div>
              <p className="mb-0">Upload Equipment List</p>
            </div>
          )}
        </div>
        {uploadPercent > 0 && uploadPercent < 100 && (
          <div className="mt-3">
            <Progress
              className="progress-bar-sm"
              striped
              animated
              value={uploadPercent}
              color="primary"
            />
          </div>
        )}
        {rejectedFiles.length > 0 && (
          <div className="mt-3">
            <InformationAlert
              type="warning"
              title="Files Rejected"
              body={`The following files were rejected: \n\n${rejectedFiles
                .map((file) => file.file.name)
                .join("\n\n")}.\n\nPlease upload a CSV or excel file.`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EquipmentUpload;
