import { Loan, Payment } from "./purchaseTypes";
import { money } from "../form/formatters";
import ReactTable from "../table/ReactTable";
import { Chart } from "react-chartjs-2";
import { useEffect, useState } from "react";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import dayjs from "dayjs";
import _ from "lodash";

interface LoanSummaryProps {
  loan: Loan;
  initialSchedule?: Payment[];
}

const LoanSummary = ({ loan, initialSchedule }: LoanSummaryProps) => {
  const { takeAction }: IUseApi<{}, { data: Payment[] }> = useApi();

  const [schedule, setSchedule] = useState<Payment[]>([]);

  useEffect(() => {
    setSchedule(initialSchedule ?? []);
  }, [loan?.uuid]);

  const loanAmount =
    typeof loan.amount === "string" ? parseFloat(loan.amount) : loan.amount;

  const fees =
    loan.fees?.map((fee) => ({
      ...fee,
      amount:
        typeof fee.amount === "string" ? parseFloat(fee.amount) : fee.amount,
    })) ?? [];

  const totalLoanAmount = loanAmount + _.sumBy(fees, "amount");

  useEffect(() => {
    const requiredFields: (keyof Loan)[] = [
      "amount",
      "loan_start_date",
      "loan_interest_rate",
      "loan_period_years",
    ];

    if (!_.every(requiredFields, (field) => loan[field])) {
      return;
    }

    takeAction("store", "amortization-schedule", loan).then(({ data }) =>
      setSchedule(data.data),
    );
  }, [
    //TODO figure out why it's changing on blur
    loan.amount,
    loan.loan_interest_rate,
    loan.loan_period_years,
    loan.loan_start_date,
    loan.loan_balloon_amount,
    loan.loan_from_date,
    JSON.stringify(loan.fees?.map((f) => f.amount) ?? ""),
  ]);

  const totalInterest = schedule.reduce(
    (acc, item) => acc + item.interest_paid,
    0,
  );

  const totalPaid = schedule.reduce((acc, item) => acc + item.payment, 0);

  return (
    <div className="mt-5">
      <div className="row">
        <div className="col-lg-3">
          <div className="bg-white p-3 rounded-lg shadow-sm border space-y-5">
            <div>
              <p className="mb-0 text-dark">
                {money.format(schedule[0]?.payment.toFixed(2))}{" "}
              </p>
              <p className="mb-0">Monthly Payment</p>
            </div>

            <div>
              <p className="mb-0 text-dark">{schedule.length}</p>
              <p className="mb-0">Payments</p>
            </div>

            <div>
              <p className="mb-0 text-dark">
                {money.format(totalInterest.toFixed(2))}
              </p>
              <p className="mb-0">Total Interest</p>
            </div>

            <div>
              <p className="mb-0 text-dark">
                {money.format(totalPaid.toFixed(2))}
              </p>
              <p className="mb-0">Total Cost of Loan</p>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <LoanChart schedule={schedule} />
        </div>
      </div>
      <SummaryTable schedule={schedule} loan={loan} />
    </div>
  );
};

const LoanChart = ({ schedule }: { schedule: Payment[] }) => {
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Loan Amortization Schedule",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (item: any): string => {
            if (!item.formattedValue) {
              return "$ 0";
            }

            return money.format(item.formattedValue) ?? "";
          },
        },
      },
    },
    responsive: true,
    scales: {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      },
    },
  };

  const interest: number[] = [];
  let totalInterest = 0;

  schedule.forEach((item) => {
    totalInterest += item.interest_paid;
    interest.push(totalInterest);
  });

  const principal: number[] = [];
  let totalPrincipal = 0;

  schedule.forEach((item) => {
    totalPrincipal += item.principal_paid;
    principal.push(totalPrincipal);
  });

  const data = {
    labels: schedule.map((item) => item.date),
    datasets: [
      {
        type: "line" as const,
        label: "Loan Balance",
        data: schedule.map((item) => item.beginning_balance),
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderColor: "rgba(0, 0, 0, 1)",
        borderWidth: 1,
        stack: "amount",
      },
      {
        type: "bar" as const,
        label: "Principal Paid",
        data: principal,
        backgroundColor: "rgba(0, 170, 79, 0.2)",
        borderColor: "rgba(0, 170, 79, 1)",
        borderWidth: 1,
      },
      {
        type: "bar" as const,
        label: "Interest Paid",
        data: interest,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  if (schedule.length === 0) {
    return null;
  }

  return (
    <div className="bg-white p-3 rounded-lg shadow-sm border">
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

const SummaryTable = ({
  schedule,
  loan,
}: LoanSummaryProps & { schedule: Payment[] }) => {
  const columns = [
    {
      accessorKey: "payment_number",
    },
    {
      accessorKey: "date",
      cell: (info: any) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "beginning_balance",
      cell: (info: any) => money.format(info.getValue().toFixed(2)),
    },
    {
      accessorKey: "payment",
      cell: (info: any) => money.format(info.getValue().toFixed(2)),
    },
    {
      accessorKey: "principal_paid",
      cell: (info: any) => money.format(info.getValue().toFixed(2)),
    },
    {
      accessorKey: "interest_paid",
      cell: (info: any) => money.format(info.getValue().toFixed(2)),
    },
  ];

  return (
    <div className="mt-5">
      <ReactTable columns={columns} data={schedule} disableSearch />
    </div>
  );
};

export default LoanSummary;
