import { FiCopy } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import TemplateCopyModal from "./TemplateCopyModal";
import { Button, UncontrolledTooltip } from "reactstrap";

const TemplateCopyButton = ({ project }) => {
  const { number } = useParams();
  const { toggle, modal } = useModal();
  const history = useHistory();

  const { takeAction } = useApi();

  const buttons = [
    {
      text: (
        <>
          <FiCopy className="tx-16 me-1 tx-primary" />
          Use Project as Template
        </>
      ),
      onClick: toggle,
    },
  ];

  const onSubmit = (values) => {
    return takeAction("store", `projects/${number}/templates`, values)
      .then(({ data }) => {
        toast.success("Project Created");
        history.push(`/${data.data.link}`);
        history.go();
      })
      .catch(errorSwal);
  };

  if (project.is_template) return null;

  return (
    <>
      <Button onClick={toggle} className="p-0" id="copy-project" color="link">
        <FiCopy className="tx-16 me-1 text-secondary" />
      </Button>
      <UncontrolledTooltip target="copy-project">
        Use Project as Template
      </UncontrolledTooltip>
      <TemplateCopyModal
        form="CopyFromTemplate"
        toggle={toggle}
        modal={modal}
        onSubmit={onSubmit}
        title={`Use ${project.full_name} as Template`}
        initialValues={{
          client_id: project.client_id,
          copy_documents: true,
          copy_jobs: true,
          project_manager: project.project_manager,
        }}
      />
    </>
  );
};

export default TemplateCopyButton;
