import React from "react";
import colors from "../../data/colors";
import { ReactComponent as AccountImage } from "../../svgs/account.svg";
import { InvoiceTerms } from "../clients/clientTypes";
import { money } from "../form/formatters";
import useModal from "../hooks/useModal";
import { Account, AccountRequest } from "./accountTypes";
import CreateAccountRequest from "./CreateAccountRequest";

const AccountCard = ({
  account,
  currentRequest,
  requestable,
  onCreated,
}: {
  account: Account;
  currentRequest?: AccountRequest;
  requestable: {
    type: string;
    id: number;
  };
  onCreated: (accountRequest: AccountRequest) => void;
}) => {
  const { toggle, modal } = useModal();

  return (
    <div
      className="subtle-info-grad shadow rounded-lg d-flex align-items-end position-relative"
      style={{ border: `1px solid ${colors.info.border}` }}
    >
      <div>
        <div className="p-3">
          <h6
            className="slim-card-title"
            style={{
              color: colors.info.main,
            }}
          >
            Account Info
          </h6>
          {!currentRequest ? (
            <p style={{ color: colors.info.main }}>
              <button
                type="button"
                className="bg-transparent border-0 p-0 fw-bolder"
                style={{ color: colors.info.main }}
                onClick={toggle}
              >
                Click Here
              </button>{" "}
              to request an update to account.
            </p>
          ) : null}
        </div>
        <div className="p-3">
          <p className="mb-2" style={{ color: colors.info.main }}>
            Account limit of{" "}
            <span className="fw-bolder">
              {money.format(account.account_limit)}
            </span>{" "}
          </p>
          <p className="mb-0" style={{ color: colors.info.main }}>
            Due to be paid{" "}
            <span className="fw-bolder">
              {account.invoice_terms === InvoiceTerms.COD
                ? null
                : account.invoice_terms_amount}
              {account.invoice_terms === 0
                ? "COD"
                : InvoiceTerms[account.invoice_terms]
                    ?.replace(/([A-Z])/g, " $1")
                    ?.trim()
                    ?.toLowerCase()}
            </span>
            .
          </p>
        </div>
      </div>
      <div
        className="ms-auto pe-3 d-none d-sm-block position-absolute r-0"
        style={{
          bottom: "-30px",
        }}
      >
        <AccountImage width="150px" height="150px" className="drop-shadow" />
      </div>
      <CreateAccountRequest
        toggle={toggle}
        modal={modal}
        requestable={requestable}
        onCreated={onCreated}
      />
    </div>
  );
};

export default AccountCard;
