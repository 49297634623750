import React from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormTicketType from "./Form";
import { useParams } from "react-router-dom";

const EditTicketType = (props : any) => {
  const { ticketType, setTicketType } = props;
  const { uuid } = useParams<{ uuid: string }>();
  const { takeAction } = useApi();

  const onSubmit = (values : any) => {
    return takeAction("update", `ticket-types/${uuid}`, values)
      .then(({ data } : {data : any}) => {
        toast.success(`${data.data.name} updated successfully`);
      })
      .catch(errorSwal);
  };

  return (
    <FormTicketType {...props} onSubmit={onSubmit} initialValues={ticketType} />
  );
};

export default EditTicketType;
