const JobStatus = {
  Pending: 0,
  InProgress: 1,
  OnHold: 2,
  Completed: 3,
  Cancelled: 4,
  Invoiced: 5,
  InvoiceRequested: 6,
};

export default JobStatus;
