import React from "react";
import { FiUpload, FiDollarSign } from "react-icons/fi";
import { useAuth } from "../../context/auth-context";
import useModal from "../hooks/useModal";
import ProfileButtons from "../utils/ProfileButtons";
import DocumentTable from "../templates/DocumentTable";
import VariableList from "../templates/VariableList";
import StandardDocumentModal from "../standardDocuments/standardDocumentModal";
import AddTemplateDocumentModal from "./AddTemplateDocumentModal";

const CustomJobDocumentTemplate = (props: any) => {
  const template = props.customJob?.template;
  const { toggle, modal } = useModal();
  const { toggle: addTemplateToggle, modal: AddTemplateModal } = useModal();
  const { user } = useAuth();

  const deleteTemplateDocument = (document: any) => {
    props.setCustomJob({
      ...props.customJob,
      template: {
        ...template,
        documents: template.documents.filter(
          (d: any) => d.uuid !== document.uuid,
        ),
      },
    });
  };

  const variablesComponent =  <div className="m-3"><VariableList variables={template?.variables ?? props.customJob.variables} /></div>;

  return (
    <>
      <ProfileButtons
        buttons={[
          {
            text: (
              <>
                <FiUpload className="tx-indigo tx-18 me-2" />
                Upload Template File
              </>
            ),
            onClick: template ? toggle : addTemplateToggle,
          }
        ]}
      />

      <DocumentTable
        user={user}
        template={template}
        deleteTemplateDocument={deleteTemplateDocument}
      />
      {template ? (
        <StandardDocumentModal
          modal={modal}
          toggle={toggle}
          documentable_type="App\Models\Template"
          bucket={`organisations/${user?.active_organisation.uuid}/templates/${template.uuid}`}
          documentable_id={template.uuid}
          whenSubmitted={({ data }: { data: any }) => {
            toggle();
            props.setCustomJob({
              ...props.customJob,
              template: {
                ...template,
                documents: [...template.documents, data.data],
              },
            });
          }}
          extraComponent={variablesComponent}
        />) : (
        <AddTemplateDocumentModal
          modal={AddTemplateModal}
          toggle={addTemplateToggle}
          customJobValues={{
            template_type: 4,
            templatable_id: props.customJob.id,
            templatable_type: "App\\Models\\CustomJob",
          }}
          whenSubmitted={({ data }: { data: any }) => {
            addTemplateToggle();
            props.setCustomJob({
              ...props.customJob,
              template: {
                ...template,
                documents: data.template.documents,
              },
            });
          }}
          extraComponent={variablesComponent}
        />

      )}
    </>
  );
};

export default CustomJobDocumentTemplate;
