import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ProjectFilters, ProjectFilterType } from "./ProjectManagerDashboard";

const ProjectManagerFilters = ({
  filters,
  toggleFilter,
  filterCount,
}: {
  filters: ProjectFilters;
  toggleFilter: (filterName: ProjectFilterType, optionValue: any) => void;
  filterCount: number;
}) => {
  return (
    <UncontrolledDropdown className="ms-3">
      <DropdownToggle color="link" caret className="p-1">
        <FaFilter className="tx-16" />
        <Badge color="" className="p-1" style={{ color: "black" }}>
          {filterCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu>
        {filters.map((filter) => {
          return (
            <React.Fragment key={filter.name}>
              <DropdownItem header>{filter.label}</DropdownItem>
              {filter.options?.map((option, index) => {
                return (
                  <DropdownItem
                    onClick={() => toggleFilter(filter.name, option.value)}
                    key={index}
                  >
                    {option.label}{" "}
                    {option.selected ? (
                      <AiOutlineCheck className="ms-1 tx-success" />
                    ) : null}
                  </DropdownItem>
                );
              })}
            </React.Fragment>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ProjectManagerFilters;
