import {
  FETCH_ORGANISATIONS, UPDATE_ORGANISATION, NEW_ORGANISATION, FETCH_USER_ORGANISATIONS,
  UPDATE_ORGANISATION_SETTINGS,
} from '../actions/types';

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORGANISATIONS:
      return {
        ...state,
        items: action.payload,
      };
    case FETCH_USER_ORGANISATIONS:
      return {
        ...state,
        items: action.payload,
      };
    case UPDATE_ORGANISATION:
      return {
        ...state,
        items: action.payload.data.data,
      };
    case UPDATE_ORGANISATION_SETTINGS:
      return {
        ...state,
        items: {
          organisation: [action.payload.data.data],
        },
      };
    case NEW_ORGANISATION:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
}
