import { WrappedFieldArrayProps } from "redux-form";
import EquipmentTypeFields from "./EquipmentTypeFields";
import FormHeader from "../utils/FormHeader";
import { Button } from "reactstrap";
import useApi from "../api/useApi";

const EquipmentTypeWrapper = (
  props: WrappedFieldArrayProps & { form: string; worksheets: string[] },
) => {
  const { fields, form, worksheets } = props;

  const { data } = useApi("custom-fields/equipment-types", []);

  return (
    <>
      <FormHeader>Equipment Sheets</FormHeader>
      {fields.map((field, index) => {
        return (
          <EquipmentTypeFields
            form={form}
            equipmentTypes={data.map((d: any) => {
              return {
                label: d.name,
                value: d.id,
              };
            })}
            key={field}
            field={field}
            index={index}
            fields={fields}
            worksheets={worksheets}
          />
        );
      })}

      <div className="col-12 form-group">
        <Button
          outline
          onClick={() =>
            fields.push({
              equipment_types: [{}],
            })
          }
        >
          Add Equipment Sheet
        </Button>
      </div>
    </>
  );
};

export default EquipmentTypeWrapper;
