import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getUsers = ({ pageParam = 1 }, search?: string, uuid?: string) => {
  if (!pageParam) {
    return;
  }
  return api
    .get(
      `user-pending-approval-actions/${uuid}?page=${pageParam}&filter[search]=${search}&paginate=9`,
    )
    .then(({ data }) => data);
};

export default function usePendingApprovals(search?: string, uuid?: string) {
  return useInfiniteQuery(
    ["pending-approvals", { search, uuid }],
    (pageParam) => getUsers(pageParam, search, uuid),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        return meta.current_page + 1;
      },
    },
  );
}
