import { Link } from "react-router-dom";
import { POField } from "./purchaseOrderTypes";
import TextButton from "../utils/TextButton";

const AnalysedInvoiceInformation = ({
  analysedData,
  setPage,
}: {
  analysedData: {
    po_fields: POField[];
    possible_purchase_orders: {
      uuid: string;
      name: string;
    }[];
  };
  setPage: (page: number) => void;
}) => {
  if (!analysedData.po_fields) {
    return <div>Loading</div>;
  }

  return (
    <div className="text-center">
      <div className="space-y-3">
        {analysedData.po_fields.map((field) => {
          return (
            <div>
              <h5 className="text-dark">Analysed Invoice</h5>
              <p className="mb-0">
                Estimated P.O. Number{" "}
                <span className="text-dark fw-bolder">
                  {field.ValueDetection.Text}
                </span>
                , on{" "}
                <TextButton onClick={() => setPage(field.PageNumber)}>
                  page {field.PageNumber}
                </TextButton>
              </p>
              <p className="mb-0">
                Under label:{" "}
                <span className="text-dark fw-bolder">
                  {field.LabelDetection.Text}
                </span>
              </p>
            </div>
          );
        })}
      </div>
      {analysedData?.possible_purchase_orders?.length === 0 && (
        <h5 className="text-dark mt-3">No Possible Purchase Orders Found</h5>
      )}
      {analysedData?.possible_purchase_orders?.length > 0 && (
        <div className="mt-3">
          <h5 className="text-dark">Possible Purchase Orders</h5>
          {analysedData?.possible_purchase_orders.map((possibility) => {
            return (
              <Link to={`/purchase-orders/${possibility.uuid}/details`}>
                {possibility.name}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );

  return <div>AnalysedInvoiceInformation</div>;
};

export default AnalysedInvoiceInformation;
