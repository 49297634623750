import React, { MouseEventHandler } from "react";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ITakeAction, IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { InvoiceTerms } from "../clients/clientTypes";
import { money } from "../form/formatters";
import useModal from "../hooks/useModal";
import Avatar from "../utils/Avatar";
import BottomLogo from "../utils/BottomLogo";
import confirm from "../utils/confirm";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import AcceptRequestModal from "./AcceptRequestModal";
import { AccountReference, AccountRequest } from "./accountTypes";
import DenyRequestModal from "./DenyRequestModel";
import EditAccountRequest from "./EditAccountRequest";

const AccountRequestCard = ({
  accountRequest,
  onUpdated,
  onCompleted,
}: {
  accountRequest: AccountRequest;
  onUpdated?: (accountRequest: AccountRequest) => void;
  onCompleted?: () => void;
}) => {
  const { modal, toggle } = useModal();
  const { takeAction, loading } = useApi();
  return (
    <>
      <Spinner loading={loading} />
      <div className="mt-3 bg-white position-relative shadow rounded-lg hover-logo ">
        <div className="p-3 d-flex align-items-start position-relative z-10">
          <div>
            <h6 className="slim-card-title mb-0">
              {accountRequest.accepted !== null
                ? `${accountRequest.accepted_string} Request`
                : "Current Account Request"}{" "}
            </h6>
            <p className="mb-0 mt-2 tx-12">
              Requested by{" "}
              {accountRequest.requested_by ? (
                <a href={`mailto:${accountRequest.requested_by.email}`}>
                  {accountRequest.requested_by.name}
                </a>
              ) : (
                "Client"
              )}
            </p>
          </div>
          {onCompleted && (
            <ActionDropdown
              toggle={toggle}
              accountRequest={accountRequest}
              onCompleted={onCompleted}
              takeAction={takeAction}
            />
          )}
        </div>
        <div className="p-3 z-10 position-relative">
          {accountRequest.account_limit !== null && (
            <p className="mb-2">
              Account limit of{" "}
              <span className="tx-inverse">
                {money.format(accountRequest.account_limit)}
              </span>{" "}
            </p>
          )}
          <p className="mb-2">
            Due to be paid{" "}
            <span className="tx-inverse">
              {accountRequest.invoice_terms_amount}{" "}
              {InvoiceTerms[accountRequest.invoice_terms]
                .replace(/([A-Z])/g, " $1")
                .trim()
                .toLowerCase()}
            </span>
            .
          </p>
          {accountRequest.accepted_string && (
            <>
              <p className="mb-2">
                {accountRequest.accepted_string} by{" "}
                <a href={`mailto:${accountRequest?.accepted_by?.email}`}>
                  {accountRequest?.accepted_by?.name}
                </a>
              </p>
              {accountRequest.denied_remarks ? (
                <p className="mb-0">
                  The application was denied for following reason:{" "}
                  <span className="tx-inverse">
                    {accountRequest.denied_remarks}
                  </span>
                </p>
              ) : (
                ""
              )}
            </>
          )}
          <ReferenceList references={accountRequest.references} />
        </div>
        <BottomLogo />
        {onUpdated && (
          <EditAccountRequest
            toggle={toggle}
            modal={modal}
            accountRequest={accountRequest}
            onUpdated={onUpdated}
          />
        )}
      </div>
    </>
  );
};

const ActionDropdown = ({
  toggle,
  accountRequest,
  onCompleted,
  takeAction,
}: {
  toggle: MouseEventHandler;
  accountRequest: AccountRequest;
  onCompleted: () => void;
  takeAction: ITakeAction;
}) => {
  const { toggle: toggleAccepted, modal } = useModal();
  const { toggle: toggleDenied, modal: deniedModal } = useModal();
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: unpaidInvoices,
  }: IUseApi<{ name: string; link: string; uuid: string }[]> = useApi(
    `clients/${uuid}/unpaid-invoices`,
    [],
  );

  const complete = (accepted: boolean, denied_reason?: string) => {
    return confirm({
      text: `Are you sure you want to ${
        accepted ? "accept" : "deny"
      } this account request?`,
      title: `${accepted ? "Accept" : "Deny"} account request?`,
      onConfirm: () => {
        return takeAction(
          "store",
          `complete-account-requests/${accountRequest.uuid}`,
          {
            accepted,
            denied_reason,
          },
        )
          .then(() => {
            onCompleted();
            toast.success(`Account request updated successful.`);
          })
          .catch(errorSwal);
      },
    });
  };

  return (
    <>
      <UncontrolledDropdown
        className="ms-auto position-relative"
        direction="up"
      >
        <DropdownToggle color="link" className="p-1">
          <FiMoreHorizontal className="tx-18 text-secondary" />
        </DropdownToggle>
        <DropdownMenu className="z-20">
          <DropdownItem className="px-2" header>
            Edit
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center px-2 text-secondary"
            onClick={toggle}
          >
            <AiOutlineEdit className="me-3 tx-16" /> Edit
          </DropdownItem>
          <DropdownItem className="px-2" header>
            Complete
          </DropdownItem>
          <DropdownItem
            onClick={
              unpaidInvoices && unpaidInvoices.length > 0
                ? toggleAccepted
                : () => complete(true)
            }
            className="d-flex align-items-center px-2 text-secondary"
          >
            <AiOutlineCheck className="me-3 tx-16 text-success fw-bold" />{" "}
            Accept
          </DropdownItem>
          <DropdownItem
            onClick={toggleDenied}
            className="d-flex align-items-center px-2 text-secondary"
          >
            <AiOutlineClose className="me-3 tx-16 text-danger fw-bold" /> Reject
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <DenyRequestModal
        toggle={toggleDenied}
        modal={deniedModal}
        onCompleted={onCompleted}
        takeAction={takeAction}
        accountRequest={accountRequest}
      />
      <AcceptRequestModal
        toggle={toggleAccepted}
        modal={modal}
        unpaidInvoices={unpaidInvoices}
        initialValues={{
          update_expected_payment_dates: false,
        }}
        takeAction={takeAction}
        accountRequest={accountRequest}
        onCompleted={onCompleted}
      />
    </>
  );
};

const ReferenceList = ({ references }: { references: AccountReference[] }) => {
  if (references.length === 0) {
    return null;
  }

  return (
    <>
      <div className="border-bottom mt-4">
        <p className="mb-0 fw-bolder tx-inverse pb-1">References:</p>
      </div>

      <div className="mt-3 space-y-7">
        {references.map((reference) => {
          return (
            <div
              key={reference.uuid}
              className="d-flex align-items-center space-x-2"
            >
              <Avatar name={reference.name} colour="info" rounded="-circle" />
              <div>
                <p className="mb-0 tx-inverse">{reference.name}</p>
                <p className="mb-0">
                  <a className="text-secondary" href={reference.email}>
                    {reference.email}
                  </a>
                </p>
              </div>
              <p className="ms-auto mb-0">
                <a className="text-dark" href={`tel:${reference.phone}`}>
                  {reference.phone}
                </a>
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default AccountRequestCard;
