import { Link, useParams } from "react-router-dom";
import { TaskListProps } from "./TaskList";
import { TodoTask as ITodoTask } from "./todoTypes";
import dayjs from "dayjs";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import errorSwal from "../utils/errorSwal";
import { BiCalendar, BiNote } from "react-icons/bi";

const TodoTask = ({
  task,
  taskList,
  setTaskList,
}: { task: ITodoTask } & TaskListProps) => {
  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi<{}, { data: ITodoTask }> = useApi();

  const isOverdue = dayjs(task.due_date).isBefore(dayjs(), "day");
  return (
    <Link
      to={task.uuid ? `/to-dos/${uuid}/tasks/${task.uuid}` : `/to-dos/${uuid}`}
      className="d-flex align-items-center bg-white px-3 py-2 rounded-lg border"
    >
      <input
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          e.stopPropagation();

          const oldTaskList = taskList;

          setTaskList({
            ...taskList,
            tasks: taskList.tasks?.map((taskItem) => {
              if (taskItem.uuid === task.uuid) {
                return {
                  ...taskItem,
                  completed_at: e.target.checked
                    ? dayjs().format("YYYY-MM-DD")
                    : undefined,
                };
              }

              return taskItem;
            }),
          });

          return takeAction("update", `to-do-tasks/${task.uuid}`, {
            ...task,
            completed_at: e.target.checked
              ? dayjs().format("YYYY-MM-DD")
              : null,
          })
            .then(({ data }) => {
              setTaskList({
                ...oldTaskList,
                tasks: oldTaskList.tasks?.map((taskItem) => {
                  return taskItem.uuid === data.data.uuid
                    ? data.data
                    : taskItem;
                }),
              });
            })
            .catch((err) => {
              setTaskList(oldTaskList);
              errorSwal(err);
            });
        }}
        checked={!!task.completed_at}
        type="checkbox"
        className="me-3 rounded-circle form-check-input tx-16"
      />
      <div>
        <p
          style={{ color: "#5c5c5c" }}
          className={`mb-0 ${
            task.completed_at ? "text-decoration-line-through" : ""
          }`}
        >
          {task.title}
        </p>

        <div className="d-flex space-x-3 align-items-center">
          {task.completed_at ? (
            <p className="mb-0 text-muted tx-12">
              Completed {dayjs(task.completed_at).format("MMM DD, YYYY")}
            </p>
          ) : (
            task.due_date && (
              <p
                className={`mb-0 tx-12 ${
                  isOverdue ? "text-danger" : "text-muted"
                }`}
              >
                <BiCalendar /> {dayjs(task.due_date).format("MMM DD, YYYY")}
              </p>
            )
          )}
          {task.description ? (
            <p className="mb-0 text-muted tx-12">
              <BiNote /> Notes
            </p>
          ) : null}
        </div>
      </div>
    </Link>
  );
};

export default TodoTask;
