import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import FileInput from "../upload/FileInput";
import uploadToS3 from "../upload/uploadToS3";
import errorSwal from "../utils/errorSwal";
import history from "../utils/History";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import VariableList from "./VariableList";

const Add = (props) => {
  const [selectedTemplate, setSelectedTemplate] = useState();

  const { user } = useAuth();

  const { data: templateTypes, takeAction } = useApi(
    "template-type-options",
    [],
  );

  const submit = (values) => {
    return uploadToS3(
      values.file,
      `organisations/${user.active_organisation.uuid}/templates`,
    )
      .then((documents) =>
        takeAction("store", "templates", {
          documents,
          ...selectedTemplate,
        }),
      )
      .then(({ data }) => {
        toast.success("Template Added Successfully");
        history.push(`/templates/${data.data.template.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="New Template"
        crumbs={[
          { link: "/templates", name: "Templates" },
          { link: "/", name: "New Template", active: true },
        ]}
      />
      <div className="row">
        <div className="col-lg-8">
          <form onSubmit={props.handleSubmit(submit)}>
            <div className="row">
              <div className="col-lg-12 form-group">
                <Field
                  component={SelectInputAsync}
                  name="template"
                  label="Template Type"
                  options={templateTypes}
                  required
                  validate={required}
                  onChangeValue={setSelectedTemplate}
                />
              </div>
              <div className="col-lg-12 form-group">
                <Field
                  component={FileInput}
                  name="file"
                  label="File"
                  required
                  validate={required}
                />
              </div>
              <div className="col-12 form-group">
                <SubmitButton {...props} />
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-4">
          {selectedTemplate && (
            <VariableList variables={selectedTemplate?.variables} />
          )}
        </div>
      </div>
    </>
  );
};

const form = reduxForm({ form: "AddTemplate" });

const selector = formValueSelector("AddTemplate");

const mapStateToProps = (state) => ({
  file: selector(state, "file"),
});

export default connect(mapStateToProps, {})(form(Add));
