import React from "react";
import { Field } from "redux-form";
import FormModal from "../utils/FormModal";
import SelectInputAsync from "../utils/SelectInputAsync";

const AddModal = (props) => {
  return (
    <FormModal {...props}>
      <div className="form-group col-12">
        <Field
          component={SelectInputAsync}
          url="/tracking-category-types"
          name="type"
          label="Type"
        />
      </div>
    </FormModal>
  );
};

export default AddModal;
