import React from "react";
import OrganisationList from "./OrganisationList.jsx";

const SelectOrganisation = () => (
  <div className="mg-t-20">
    <label className="section-title">Select Organisation</label>
    <p className="mb-4 mg-sm-b-40">
      Please select the organisation you would like to visit
    </p>
    <div className="row">
      <div className="col-md-12">
        <OrganisationList />
      </div>
    </div>
  </div>
);

export default SelectOrganisation;
