import React from "react";
import { Field } from "redux-form";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import required from "../utils/required";
import CustomScaleLoader from "../utils/scaleLoader";

const Items = (props) => {
  const { initialValues, fields, equipment } = props;
  const { takeAction, loading } = useApi();

  const { data: billableItemUnits } = useApi(
    `/react-select-data?model=${encodeURIComponent(
      "App\\Models\\BillableItemUnit",
    )}&select=id,name&label=name&value=id&orderyBy[name]=ASC`,
    [],
  );

  const { data: billableItems } = useApi(`billable-items`, []);

  const remove = (index) => {
    if (!initialValues || !initialValues.items[index]) {
      return fields.remove(index);
    }

    const selectedItem = initialValues.items[index];

    return deleteSwal("Are you sure you want to delete this item?")
      .then(() =>
        takeAction("destroy", `machine-day-rate-items/${selectedItem.uuid}`),
      )
      .then(() => fields.remove(index))
      .catch(errorSwal);
  };

  if (loading) {
    return <CustomScaleLoader color="red">Deleting Item...</CustomScaleLoader>;
  }

  return (
    <div>
      {fields.length > 0 && (
        <div>
          <hr />
          <h4>Daysheet Items</h4>
        </div>
      )}

      {fields.map((item, index) => (
        <div key={index} className="row">
          <div className="form-group col-lg-12">
            <label>Item {index + 1}</label>
          </div>
          <div className="form-group col-lg-4">
            <Field
              name={`${item}.equipment_id`}
              component={SelectInput}
              options={equipment}
              label="Equipment ID"
              validate={required}
              required
            />
            {equipment?.length === 0 ? (
              <small>Please select branch to view equipment</small>
            ) : null}
          </div>

          <div className="form-group col-lg-4">
            <Field
              name={`${item}.smu_start`}
              component={renderField}
              label="SMU Start"
              type="number"
              validate={required}
              required
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name={`${item}.smu_finish`}
              component={renderField}
              type="text"
              label="SMU Finish"
              validate={required}
              required
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name={`${item}.activity`}
              component={SelectInput}
              options={billableItems.map((billableItem) => ({
                label: billableItem.name,
                value: billableItem.id,
              }))}
              label="Activity"
              validate={required}
              required
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name={`${item}.quantity_units`}
              component={SelectInput}
              options={billableItemUnits}
              label="Quantity Units"
              validate={required}
              required
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name={`${item}.quantity`}
              component={renderField}
              label="Quantity"
              validate={required}
              required
            />
          </div>

          <div className="form-group col-lg-6">
            <button
              type="button"
              onClick={() => remove(index)}
              className="btn btn-outline-danger"
            >
              Remove
            </button>
          </div>
        </div>
      ))}
      <div className="form-group">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => fields.push()}
        >
          Add Item
        </button>
      </div>
    </div>
  );
};

export default Items;
