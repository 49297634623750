import { FETCH_TO_DOS } from "../actions/types";

const initialState = {
  items: [],
  selectedBranch: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TO_DOS:
      return {
        ...state,
        items: action.payload,
        selectedBranch: action.branch || {}
      };
    default:
      return state;
  }
}
