import React from "react";
import { Progress, UncontrolledTooltip } from "reactstrap";
import { money } from "../form/formatters";
import { percent } from "../home/ProductivityOverview";
import { IProjectManagerJob } from "./projectManagerTypes";

const ProjectManagerJobProgress = ({ job }: { job: IProjectManagerJob }) => {
  return (
    <div>
      <div className="mb-1">
        <p className="mb-2 ms-auto tx-12 text-justify">
          <span className="tx-inverse">
            <span
              className="fw-bolder"
              id={`estimated_hours_${job.uuid}`}
            >
              Estimated Hours
            </span>{" "}
            vs{" "}
            <span
              className="fw-bolder"
              id={`actual_hours_${job.uuid}`}
            >
              Actual Hours
            </span>
          </span>{" "}
          | {percent(job.estimated_hours, job.actual_hours)}%
        </p>
        <Progress
          value={percent(job.estimated_hours, job.actual_hours)}
          barClassName="progress-bar-xs"
          color="purple"
        />
      </div>
      <div className="mb-1">
        <p className="mb-2 ms-auto tx-12  text-justify">
          <span className="tx-inverse">
            <span className="fw-bolder" id={`invoiced_${job.uuid}`}>
              Total Amount Invoiced
            </span>{" "}
            +{" "}
            <span id={`wip_${job.uuid}`} className="fw-bolder">
              Total WIP
            </span>{" "}
            vs{" "}
            <span
              id={`estimated_invoiced_${job.uuid}`}
              className="fw-bolder"
            >
              Estimated Amount Invoiced
            </span>
          </span>{" "}
          | {percent(job.invoiced + job.wip, job.estimated_invoices)}%
        </p>
        <Progress
          value={percent(job.invoiced + job.wip, job.estimated_invoices)}
          barClassName="progress-bar-xs"
          color="info"
        />
      </div>
      <UncontrolledTooltip target={`estimated_hours_${job.uuid}`}>
        {job.estimated_hours} Estimated Hours
      </UncontrolledTooltip>
      <UncontrolledTooltip target={`actual_hours_${job.uuid}`}>
        {job.actual_hours} Actual Hours
      </UncontrolledTooltip>
      <UncontrolledTooltip target={`invoiced_${job.uuid}`}>
        {money.format(job.invoiced)}
      </UncontrolledTooltip>
      <UncontrolledTooltip target={`wip_${job.uuid}`}>
        {money.format(job.wip)}
      </UncontrolledTooltip>
      <UncontrolledTooltip target={`estimated_invoiced_${job.uuid}`}>
        {money.format(job.estimated_invoices)}
      </UncontrolledTooltip>
    </div>
  );
};

export default ProjectManagerJobProgress;
