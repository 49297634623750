import { Link, useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import AddTenderForm from "../tenders/AddTenderForm";
import { ConvertToWorkFormProps } from "./ConvertToJobModal";
import { TodoTask } from "./todoTypes";
import { toast } from "react-toastify";
import formError from "../utils/formError";

const ConvertToTenderForm = ({
  initialValues,
  setData,
  toggle,
}: { initialValues: any } & ConvertToWorkFormProps) => {
  const { taskUuid } = useParams<{ taskUuid: string }>();

  const { takeAction }: IUseApi<{}, { data: TodoTask }> = useApi();

  const onSubmit = (values: any) => {
    return takeAction("store", `to-do-tasks/${taskUuid}/convert/tender`, values)
      .then(({ data }) => {
        setData(data.data);
        toggle();
        toast.success(
          <div className="d-flex">
            <p className="mb-0">Todo Converted</p>
            <Link to={data.data.work?.link ?? ""}>View Tender</Link>
          </div>,
        );
      })
      .catch(formError);
  };

  return (
    <AddTenderForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      form="CONVERT_TO_TENDER"
      showProject={true}
    />
  );
};

export default ConvertToTenderForm;
