import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import Avatar from "../utils/Avatar";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import useTicketType from "./hooks/useTicketTypes";
import { TicketType } from "./ticketType";

const TicketTypesList = () => {
  return (
    <>
      <HeaderPage
        titlePage="Ticket Types"
        pageDescription={
          <>
            Ticket types simplify ticket management and allows
            organizations to track tickets, services, and replacement
            values, reducing the risk of loss or damage due to inadequate
            maintenance.
          </>
        }
        relatedLinks={[
          {
            name: "Ticket",
            link: "/ticket-type"
          }
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "", name: "Ticket Types", active: true },
        ]}
      />
      <Link
        className="btn btn-sm btn-outline-primary mb-2"
        to="ticket-types/add"
      >
        Add New Ticket Type
      </Link>
      <PaginatedList
        indexHook={useTicketType}
        indexHookArguments={[]}
        originalFilters={[]}
        itemCard={({ item }: { item: TicketType }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<TicketHeader ticketType={item} />}
          />
        )}
      />
    </>
  );
};

export default TicketTypesList;

const TicketHeader = ({
  ticketType,
}: {
  ticketType: TicketType;
}) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={ticketType.name} colour={"info"} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={`equipment_type_title${ticketType.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={ticketType.link}>{ticketType.name}</Link>
          </p>
          <UncontrolledTooltip
            placement="top-start"
            target={`equipment_type_title${ticketType.uuid}`}
          >
            {ticketType.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

const info = (ticketType: TicketType): PaginatedInformation[] => {
  return [
    {
      name: "Project",
      value: ticketType.project?.name ?? "-",
      link: ticketType.project?.link ?? "",
    },
    {
      name: "Custom Job",
      value: ticketType.custom_job?.name ?? "-",
      link: ticketType.custom_job?.link ?? "",
    },
    {
      name: "Job Manager",
      value: ticketType.job_manager?.name ?? "-",
      link: ticketType.job_manager?.link ?? "",
    }
  ];
};
