import React from "react";
import { useParams } from "react-router-dom";
import {
  BrandingThemeProfilePages,
  BrandingThemeProfileProps,
} from "./brandingThemeTypes";
import NotFound from "../404";
import EditBrandingTheme from "./EditBrandingTheme";

const ShowBrandingThemeProfilePage = (props: BrandingThemeProfileProps) => {
  const { page } = useParams<{ page: BrandingThemeProfilePages }>();

  switch (page) {
    case "details":
      return <EditBrandingTheme {...props} />;
    default:
      return <NotFound />;
  }
};

export default ShowBrandingThemeProfilePage;
