import { Link } from "react-router-dom";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import { Badge } from "reactstrap";
import formatDate from "../utils/formatDate";

const SupportTicket = ({ user }: { user: { id: number; uuid: string } }) => {
  const { data, loading } = useApi(
    `support-tickets?user_id=${user.id}`,
    [],
    true,
  );

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => {
        const projectJob = info.row.original;
        return (
          <div className="d-block">
            <Link
              className="text-dark mb-0"
              to={`/support-tickets/${projectJob.uuid}`}
            >
              {projectJob.name}
            </Link>
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      cell: (info: any) => formatDate(info.getValue()),
    },
    {
      accessorKey: "status_badge",
      header: "Status",
      cell: (info: any) => {
        const status = info.getValue();

        return (
          <Badge className="rounded-pill shadow" color={status.color}>
            {status.label}
          </Badge>
        );
      },
    },
  ];

  return <ReactTable columns={columns} data={data ?? []} loading={loading} />;
};

export default SupportTicket;
