import React from "react";
import useApi from "../api/useApi";
import { IUseApiWithData } from "../api/apiTypes";
import { IXeroContactGroup } from "./xeroTypes";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";

interface FormData {
  default_contact_group_id: string;
}

const ContactGroups = (props: InjectedFormProps<FormData>) => {
  const { handleSubmit } = props;

  const { data, takeAction }: IUseApiWithData<IXeroContactGroup[]> = useApi(
    "xero/contact-groups",
    [],
  );

  const onSubmit: FormSubmitHandler<FormData> = (values) => {
    return takeAction("update", "xero/default-contact-groups", values)
      .then(() => {
        toast.success("Default contact group saved.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <p>
        Set the contact group that you want to default for members on your
        group.
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-12 form-group">
            <Field
              required
              validate={required}
              label="Default Group contact group"
              component={SelectInput}
              name="extra_info.default_contact_group_id"
              options={data.map((group) => ({
                label: group.name,
                value: group.id,
              }))}
            />
          </div>
          <div className="form-group col-lg-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </div>
  );
};

const form = reduxForm<FormData>({ form: "ContactGroups" });

export default form(ContactGroups);
