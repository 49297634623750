import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/auth-context";
import NotFound from "../../404";
import ProfileDeliverables from "../../deliverables/ProfileDeliverables";
import GenerateTimesheetFromJob from "../../jobs/GenerateTimesheetFromJob";
import NewProfileDocuments from "../../standardDocuments/ProfileDocuments";
import ExternalRequests from "./ExternalRequests";
import Form from "./Form";
import Understandings from "./Understandings";

const ShowPage = (props) => {
  const { job } = props;

  const { page, uuid } = useParams();
  const isInternal = job?.project?.internal;
  const { user } = useAuth();

  const showTimesheet = job?.scheduled_start_time && job?.scheduled_finish_time;

  switch (page) {
    case "details":
      return (
        <Form
          initialValues={props.job}
          job={props.job}
          onSubmit={props.onSubmit}
          form="EditCustomJobJob"
          onPrompt={props.onPrompt}
          generatingPrompt={props.generatingPrompt}
        />
      );
    case "documents":
      return (
        <NewProfileDocuments
          {...props}
          model="App\Models\ProjectJob"
          documentableId={uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/custom-jobs/${uuid}`}
          homeUrl={`custom-jobs/jobs/${uuid}/documents`}
        />
      );
    case "deliverables":
      return isInternal ? (
        <NotFound />
      ) : (
        <ProfileDeliverables
          model="App\Models\ProjectJob"
          {...props}
          form="deliverableDetails"
          id={uuid}
          jobId={uuid}
          projectUuid={props.job.project.uuid}
        />
      );

    case "understandings":
      return props?.job?.must_be_understood ? (
        <Understandings job={props?.job} setJob={props.setJob} />
      ) : null;
    case "timesheet":
      return showTimesheet ? (
        <GenerateTimesheetFromJob
          initialValues={{
            date: dayjs(job.scheduled_finish_date).format("YYYY-MM-DD"),
            start_time: job.scheduled_start_time,
            finish_time: job.scheduled_finish_time,
          }}
          job={job}
        />
      ) : null;
    case "external-requests":
      return <ExternalRequests />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
