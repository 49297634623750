import { useEffect } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import CustomFields from "../customFields/CustomFields";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import selectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { AutoCreateCustomJobType } from "./customJobTypes";

const Form = (props) => {
  const {
    handleSubmit,
    showFields = true,
    requiresApproval,
    autoCreateJob,
    jobType,
    change,
    initialized,
    disableFormEdit = false,
  } = props;

  useEffect(() => {
    if (!requiresApproval && initialized) {
      change("approvers", []);
    }
    if (!autoCreateJob && initialized) {
      change("job_type", null);
      change("branch_id", null);
    }
    if (
      jobType &&
      jobType !== AutoCreateCustomJobType.recurringJob &&
      initialized
    ) {
      change("length", null);
      change("length_units", null);
      change("recurring_frequency", null);
      change("recurring_frequency_units", null);
    }
  }, [requiresApproval, autoCreateJob, jobType]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            required
            validate={required}
            name="name"
            label="Name"
            extraProps={{ disabled: disableFormEdit }}
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            component={renderToggleInput}
            name="requires_approval"
            label="Requires Approval"
            disabled={disableFormEdit}
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            component={renderToggleInput}
            name="must_be_understood"
            label="Must be Understood"
            disabled={disableFormEdit}
            information="Toggle this on if you require all members of the job to view and mark the job as 'understood'."
          />
        </div>
        <div className="col-lg-12 form-group">
          <Field
            component={renderToggleInput}
            name="auto_create_job"
            label="Automatically Create for Every New Project"
            disabled={disableFormEdit}
            information="Toggle this on if you require an automatically created custom job for every new project."
          />
        </div>
        {autoCreateJob && (
          <>
            <div className="col-lg-6 form-group">
              <Field
                component={SelectInput}
                required
                validate={required}
                name="job_type"
                label="Auto-create Job Type"
                options={[
                  {
                    value: "singleJob",
                    label: "Single Job",
                  },
                  {
                    value: "recurringJob",
                    label: "Recurring Job",
                  },
                ]}
                isDisabled={disableFormEdit}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={selectInputAsync}
                name="branch_id"
                label="Branch"
                url="/organisationbranches/branches"
                required
                validate={required}
                hideDisabled={true}
              />
            </div>
            {jobType === AutoCreateCustomJobType.recurringJob && (
              <>
                <div className="col-lg-6 form-group">
                  <Field
                    name="length"
                    component={RenderField}
                    label="Length Of Job"
                    required
                    validate={required}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    name="length_units"
                    component={selectInputAsync}
                    label="Length of Job Units"
                    url="/enums?enum=TimeUnitType"
                    formatData={formatData}
                    required
                    validate={required}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    name="recurring_frequency_units"
                    component={selectInputAsync}
                    label="Units"
                    url="/enums?enum=TimeUnitType"
                    formatData={formatData}
                    required
                    validate={required}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    name="recurring_frequency"
                    component={RenderField}
                    label="Frequency"
                    required
                    validate={required}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
      {requiresApproval && (
        <div className="col-12 form-group">
          <Field
            component={SelectInput}
            isMulti
            url="employed-users?filter[employed_by_group]=true"
            name="approvers"
            label="Approvers"
            formatData={(data) => {
              return data.map((user) => ({
                value: user.id,
                label: user.name,
              }));
            }}
            isDisabled={disableFormEdit}
          />
        </div>
      )}

      {showFields && <CustomFields {...props} />}
      <div className="row">
        <div className="col-12">
          <SubmitButton {...props} disabled={disableFormEdit} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({});

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    requiresApproval: selector(state, "requires_approval"),
    autoCreateJob: selector(state, "auto_create_job"),
    jobType: selector(state, "job_type"),
  };
};

const formatData = (data) => {
  return data.data
    .filter((d) => d.label !== "Initial")
    .map((d) => {
      return {
        ...d,
      };
    });
};

export default connect(mapStateToProps, {})(form(Form));
