import React, { useState } from "react";

const ChangeStep = (props) => {
  const [stepSize, setStepSize] = useState(props.stepSize);

  return (
    <div
      className={`dropdown-menu pd-30 pd-sm-40 wd-sm-350 ${
        props.rightClick.x ? "show" : ""
      }`}
      style={{
        position: "fixed",
        left: props.rightClick.x,
        top: props.rightClick.y,
      }}
    >
      <h6 className="tx-gray-800 tx-uppercase tx-bold">Change Step</h6>
      <p className="tx-gray-600 mg-b-30">
        Change how many minutes are dragged.
      </p>

      <div className="form-group">
        <input
          value={stepSize}
          onChange={(e) => setStepSize(e.target.value)}
          onBlur={props.changeStep}
          type="number"
          step="5"
          className="form-control pd-y-12"
        />
      </div>
    </div>
  );
};

export default ChangeStep;
