import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { Link, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import AddTestSuiteStatusModal from "./AddTestSuiteStatusModal";
import { TestSuiteStatus } from "./testSuiteTypes";
import { toast } from "react-toastify";
import LoadingOverlay from "../utils/LoadingOverlay";

//See https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another/5306832#5306832
function array_move<T>(
  arr: (T | undefined)[],
  old_index: number,
  new_index: number,
) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

const TestSuiteStatuses = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { toggle, modal } = useModal();

  const {
    takeAction,
    loading: updating,
  }: IUseApi<{}, { data: TestSuiteStatus[] }> = useApi();

  const {
    data: statuses,
    setData: setStatuses,
    loading,
  }: IUseApi<TestSuiteStatus[], { data: TestSuiteStatus[] }> = useApi(
    `test-suites/${uuid}/statuses`,
    [],
    true,
  );

  const onDrop = (dropResult: DropResult, provided: ResponderProvided) => {
    const { destination, source } = dropResult;

    if (!destination) {
      return;
    }
    const oldOrder = statuses;

    const newOrder: TestSuiteStatus[] = array_move<TestSuiteStatus>(
      statuses ?? [],
      source.index,
      destination.index,
    ).map((val, index) => {
      const status = val as TestSuiteStatus;

      return {
        ...status,
        order: index,
      };
    });

    setStatuses(newOrder);

    return takeAction("update", `test-suites/${uuid}/status-order`, {
      statuses: newOrder,
    })
      .then(({ data }) => {
        setStatuses(data.data);
        toast.success("Status order updated");
      })
      .catch((err) => {
        errorSwal(err);
        setStatuses(oldOrder ?? []);
      });
  };

  if (loading) {
    return <CustomScaleLoader>Fetching Statuses...</CustomScaleLoader>;
  }

  return (
    <>
      <Button
        color="primary"
        size="sm"
        className="mt-1"
        outline
        onClick={toggle}
      >
        Add New
      </Button>
      <AddTestSuiteStatusModal
        statuses={statuses ?? []}
        setStatuses={setStatuses}
        toggle={toggle}
        modal={modal}
      />
      <div className="position-relative">
        <LoadingOverlay loading={updating} />
        <DragDropContext onDragEnd={onDrop}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-5 mt-3"
                >
                  {statuses?.map((status, i) => {
                    return (
                      <Draggable
                        key={status.uuid}
                        draggableId={status.id.toString()}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-white p-3 border"
                          >
                            <Link
                              key={status.uuid}
                              to={`/test-suite-statuses/${status.uuid}/details`}
                            >
                              <p className="mb-0">{status.name}</p>
                            </Link>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default TestSuiteStatuses;
