import { FieldArray } from "redux-form";
import PricingFieldArray from "./PricingFieldArray";
import PricingForm from "./PricingForm";

const DeliverablePricing = (props) => {
  const { initialValues, isInvoiced } = props;

  const isOldSystem = initialValues.pricing.find((price) => price.item);

  return (
    <div className="form-group">
      <div className="slim-card-title mb-3">Add new price below</div>
      <FieldArray
        component={isOldSystem ? PricingForm : PricingFieldArray}
        name="pricing"
        projectUuid={initialValues.project.uuid}
        {...props}
        isInvoiced={isInvoiced}
      />
    </div>
  );
};

export default DeliverablePricing;
