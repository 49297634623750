import React from "react";
import { toast } from "react-toastify";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import dateField, { formatting } from "../utils/dateTime";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import { InvoiceState } from "./invoiceTypes";

interface EditInvoiceDateProps extends InvoiceState {
  modal: boolean;
  toggle: Function;
}

const EditInvoiceDate = ({
  modal,
  invoice,
  toggle,
  setInvoice,
}: EditInvoiceDateProps) => {
  const { takeAction }: IUseApi<any, { data: { date: string } }> = useApi();

  const onSubmit: FormSubmitHandler<{ date: string }> = (values) => {
    return takeAction("update", `invoice-dates/${invoice.uuid}`, values)
      .then(({ data }) => {
        setInvoice({
          ...invoice,
          date: data.data.date,
        });
        toggle();
        toast.success(`${invoice.name} date updated.`);
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      initialValues={invoice}
      form="EditInvoiceDate"
      modal={modal}
      toggle={toggle}
      title={`Edit date for ${invoice.name}`}
      onSubmit={onSubmit}
    >
      <div className="col-12 form-group">
        <Field component={dateField} name="date" {...formatting} label="Date" />
      </div>
    </FormModal>
  );
};

export default EditInvoiceDate;
