import { useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import Tabs from "../tabs/Tabs";
import { Tab } from "../tabs/tabTypes";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import ProjectEquipmentCalibrations, {
  ProjectEquipmentCalibration,
} from "./ProjectEquipmentCalibrations";
import ProjectEquipmentForm from "./ProjectEquipmentForm";
import ProjectEquipmentHistory from "./ProjectEquipmentHistory";
import ProjectEquipmentRates from "./ProjectEquipmentRates";
import DocsModal from "../utils/DocsModal";

export interface IProjectEquipment {
  uuid: string;
  name: string;
  call_sign: string;
  calibrations: ProjectEquipmentCalibration[];
  id: number;
  rate: number;
}

const ProjectEquipment = ({
  project,
}: {
  project: { id: number; full_name: string };
}) => {
  const { number } = useParams<{ number: string }>();
  const queryClient = useQueryClient();
  const {
    data,
    setData: setEquipment,
    loading,
  }: IUseApi<IProjectEquipment[]> = useApi(
    `/projects/${number}/equipment`,
    [],
    true,
  );

  const { takeAction }: IUseApi = useApi();

  const deleteEquipment = (deleted: IProjectEquipment) => {
    return deleteSwal(
      null,
      `Are you sure you want to remove ${deleted.name} from this project?`,
    )
      .then(() => takeAction("destroy", `equipment/${deleted.uuid}/projects`))
      .then(() => {
        setEquipment(equipment.filter((e) => e.uuid !== deleted.uuid));
        toast.success("Equipment removed from project");
        queryClient.invalidateQueries(["project-equipment"]);
      })
      .catch(errorSwal);
  };

  const equipment = data as IProjectEquipment[];

  const columns = [
    {
      accessorKey: "name",
      header: "Equipment",
      cell: (info: any) => {
        return (
          <Link to={`/equipment/${info.row.original.uuid}/details`}>
            {info.getValue()}
          </Link>
        );
      },
    },
    {
      accessorKey: "call_sign",
      header: "Call Sign",
    },
    {
      accessorKey: "remove",
      header: "Remove",
      cell: (info: any) => (
        <DeleteButton
          deleteFunction={() => deleteEquipment(info.row.original)}
        />
      ),
    },
  ];

  const tabs: Tab[] = [
    {
      title: "Equipment",
      component: (
        <>
          <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/equipment-asset-management/equipment/" />
          <ProjectEquipmentForm
            equipment={equipment}
            setEquipment={setEquipment}
            project={project}
          />

          <div className="mt-5">
            <ReactTable data={equipment} columns={columns} loading={loading} />
          </div>
        </>
      ),
    },
    {
      title: "Rates",
      component: <ProjectEquipmentRates equipment={data ?? []} />,
    },
    {
      title: "Equipment History",
      component: (
        <div className="mt-5">
          <ProjectEquipmentHistory />
        </div>
      ),
    },
    {
      title: "Calibrations",
      component: (
        <ProjectEquipmentCalibrations project={project} equipment={equipment} />
      ),
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default ProjectEquipment;
