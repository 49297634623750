import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "react-query";
import LogoutModal from "./components/auth/LogoutModal";
import BasicUserRoutes from "./components/BasicUserRoutes";
import Footer from "./components/footer/Footer";
import Navigation from "./components/header/Navigation";
import Main from "./components/Main";
import { AdministrationLevels } from "./components/user/userTypes";
import SetHead from "./components/utils/SetHead";
import { useAuth } from "./context/auth-context";
import { CreateZipProvider } from "./context/createZip-context";
import "./scss/boss.scss";

const queryClient = new QueryClient();

const AuthenticatedApp = (props) => {
  const { location } = props;
  const { user, onLineStatus } = useAuth();

  const routesWithoutShell = ["/two-factor-reminder", "/login/consent"];

  const isBasic =
    user?.position_administration_level === AdministrationLevels.Basic &&
    !user.is_admin;

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (!import.meta.env.DEV) {
      Sentry.setContext("organisation", {
        name: user?.active_organisation?.display_name,
      });

      Sentry.setUser({
        id: user?.id?.toString(),
        username: user?.name,
      });

      ReactGA.set({ userId: user?.id });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {!onLineStatus ? (
        <div
          className="bg-danger text-center tx-white p-3 position-fixed w-100"
          style={{ zIndex: 10000 }}
        >
          <p className="mb-0">You are currently offline.</p>
          <p className="mb-0">Please reconnect before continuing.</p>
        </div>
      ) : null}
      <CreateZipProvider>
        {routesWithoutShell.includes(location.pathname) ? (
          isBasic ? (
            <BasicUserRoutes />
          ) : (
            <Main />
          )
        ) : (
          <div className="App">
            <SetHead />
            <Navigation {...props} />

            <div className="slim-mainpanel pd-b-20 mg-b-50">
              <div className="container mg-b-20" id="app-container">
                {isBasic ? <BasicUserRoutes /> : <Main />}
              </div>
            </div>
            <Footer />
          </div>
        )}
        <LogoutModal />
      </CreateZipProvider>
    </QueryClientProvider>
  );
};

export default AuthenticatedApp;
