import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { reduxForm, Field, reset } from "redux-form";

import {
  createMoistureContainer,
  updateMoistureContainer,
} from "../../actions/moistureContainerActions";
import renderField from "../utils/renderField";
import isSubmitting from "../utils/submitting";

import errorSwal from "../utils/errorSwal";

class FormMoistureContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  onSubmit = (values) => {
    return this.createOrUpdate(values)
      .then((res) => {
        toast.success(`${res.payload.name} added successfully`);
        this.props.dispatch(reset("MoistureContainers"));
      })
      .catch((err) => {
        errorSwal(err);
      });
  };
  createOrUpdate = (values) => {
    const { createMoistureContainer, updateMoistureContainer } = this.props;

    if (values.uuid) return updateMoistureContainer(values);
    return createMoistureContainer(values);
  };
  render() {
    const { submitting, initialValues, handleSubmit } = this.props;
    const { deleting } = this.state;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="row">
          <div className="col-lg-9 form-group align-items-center">
            <Field
              name="name"
              label="Name"
              type="text"
              component={renderField}
            />
          </div>

          <div className="form-group col-lg-3 d-flex align-items-end">
            <button
              className="btn btn-primary bd-0 form-control"
              disabled={deleting || submitting}
            >
              {isSubmitting(submitting)}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const form = reduxForm({
  form: "MoistureContainers",
  enableReinitialize: true,
});
export default connect(null, {
  createMoistureContainer,
  updateMoistureContainer,
})(form(FormMoistureContainer));
