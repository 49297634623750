import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import NotFound from "../404";
import { CustomField } from "../customFields/customFieldTypes";
import ProfileForm from "../customFields/ProfileForm";
import { IDocument } from "../documents/documentTypes";
import ProfileSingleFile from "../utils/ProfileSingleFile";
import DocketTypeForm from "./DocketTypeForm";
import { DocketTypeProfilePages, DocketTypeProps } from "./DocketTypeProfile";
import { DocketType } from "./docketTypeTypes";

const ShowDocketTypePage = (
  props: DocketTypeProps & { onSubmit: FormSubmitHandler<DocketType> },
) => {
  const { docketType, onSubmit, setDocketType } = props;

  const type = docketType as DocketType;

  const { page } = useParams<{ page: DocketTypeProfilePages }>();

  const whenSubmitted = ({ data }: { data: { data: IDocument } }) => {
    setDocketType({
      ...type,
      template: data.data,
    });
  };

  switch (page) {
    case "details":
      return (
        <DocketTypeForm
          form="UpdateDocketType"
          onSubmit={onSubmit}
          initialValues={docketType}
        />
      );
    case "fields":
      return (
        <ProfileForm
          initialValues={docketType}
          modelId={docketType?.id ?? 1}
          modelType="App\Models\DocketType"
          onSuccess={(data: { data: CustomField[] }) =>
            setDocketType({
              ...type,
              custom_fields: data.data,
            })
          }
        />
      );
    case "template":
      return (
        <ProfileSingleFile
          link={docketType?.template?.inline_link}
          mime_type={docketType?.template?.mime_type}
          downloadLink={docketType?.template?.link}
          whenSubmitted={whenSubmitted}
          documentable_id={docketType?.uuid}
          documentable_type="App\Models\DocketType"
          bucket={`docket-types/${docketType?.uuid}`}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowDocketTypePage;
