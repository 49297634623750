import { CustomField } from "../customFields/customFieldTypes";
import { StandardDocument } from "../standardDocuments/standardDocumentTypes";

export enum EquipmentStatus {
  InService,
  OutOfService,
  Withdrawn,
  LoanedOut,
  LoanedIn,
  HiredOut,
  Hiring,
  OnHired,
  OffHired,
}

export interface Equipment {
  nextDueCalibrations?: any[];
  branch_id: number;
  call_sign: string;
  make: string;
  condition: 0 | 1;
  date_received: string;
  model: string;
  serial_number: string;
  uuid: string;
  withdrawn: EquipmentStatus;
  type_type: string;
  sub_location: string;
  id: number;
  type: {
    type: string;
    has_unit_frequency: boolean;
    link: string;
    calibration_types: CalibrationType[];
  };
  calibration_managers: {
    uuid: string;
    user_id: number;
    user: {
      name: string;
      email: string;
    };
    equipment_id: number;
    equipment_calibration_type_id: number;
  }[];
  branch: {
    name: string;
  };
  group: {
    name: string;
    fixed_asset_account_id: string;
    depreciation_expense_account_id: string;
    accumulated_depreciation_account_id: string;
    depreciation_method: string;
    averaging_method: string;
    depreciation_rate: string;
    effective_life_years: string;
    depreciation_calculation_method: string;
    tax_depreciation_method: string;
    tax_averaging_method: string;
    tax_depreciation_rate: string;
    tax_effective_life_years: string;
    tax_depreciation_calculation_method: string;
  };
  name: string;
  type_id: number;
  type_group: string;
  manager_id: number;
  branch_owner_id: number;
  is_in_calibration: boolean;
  project: {
    name?: string;
    link?: string;
  };
  recurring_payment_start_date?: string;
  recurring_payment_end_date?: string;
  recurring_payment_frequency?: number;
  recurring_payment_frequency_units?: number;
  recurring_payment_next_date?: string;
  prices: any[];
  next_invoice_due?: string;
  default_invoice_name?: string;
  recurring_payment?: {
    id: number;
    name: string;
    prices: any[];
  };
}

export interface StoodDownEquipment {
  uuid: string;
  date: string;
  reason?: string;
}

export type CalibrationType = {
  uuid: string;
  id: number;
  equipment_type_id: number;
  equipment_type_type: string;
  frequency: number;
  frequency_units: string;
  unit_frequency_units: string;
  interval: number;
  procedures: string;
  source: string;
  type: string;
  frequency_unit_name: string;
  equipment_type: {
    uuid: string;
    type: string;
  };
  custom_fields: CustomField[];
  approval_required: boolean;
  critically_overdue_amount: number;
  critically_overdue_units: string;
  job_creation_length: number;
  job_creation_units: string;
  document_id: number;
  document: StandardDocument;
  worksheet_equipment_types: any[];
};
