import React from "react";
import { Button } from "reactstrap";
import { WrappedFieldArrayProps } from "redux-form";
import FormHeader from "../utils/FormHeader";
import CustomJobAggregateFields from "./CustomJobAggregateFields";

const CustomJobAggregateFieldArray = ({ fields }: WrappedFieldArrayProps) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={index}>
            <FormHeader>Aggregate {index + 1}</FormHeader>
            <CustomJobAggregateFields field={field} />
          </React.Fragment>
        );
      })}
      <div className="col-lg-12">
        <Button color="primary" outline onClick={() => fields.push({})}>
          Add New
        </Button>
      </div>
    </>
  );
};

export default CustomJobAggregateFieldArray;
