import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import api from "../../api/api";
import errorSwal from "../../utils/errorSwal";
import { AuthUser } from "../userTypes";

const getAuthUser = () => {
  return api
    .get(`auth/me`)
    .then(({ data }: AxiosResponse<{ data: AuthUser }>) => data.data)
    .catch(errorSwal);
};
export default function useAuthUser() {
  return useQuery<void | AuthUser>("auth/me", getAuthUser);
}
