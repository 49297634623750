import React from "react";
import { Button } from "reactstrap";
import { WrappedFieldArrayProps } from "redux-form";
import FormHeader from "../utils/FormHeader";
import CustomForm from "./CustomForm";

const CustomFieldArray = (
  props: WrappedFieldArrayProps & {
    change: Function;
    customForm: any;
    label: string;
  },
) => {
  const { fields, label } = props;

  return (
    <>
      <div className="col-lg-12 row form-group">
        {fields.map((field, index) => {
          return (
            <React.Fragment key={index}>
              <FormHeader
                labelClass="mb-0"
                className="col-12 d-flex flex-wrap align-items-center"
                extra={
                  <Button
                    size="sm"
                    color="danger"
                    outline
                    onClick={() => fields.remove(index)}
                    className="ms-auto p-1 align-self-end"
                  >
                    Delete
                  </Button>
                }
              >
                {label} {index + 1}
              </FormHeader>
              <CustomForm name={field} {...props} />
            </React.Fragment>
          );
        })}
      </div>
      <div className="form-group col-12">
        <Button onClick={() => fields.push({})}>Add New {label}</Button>
      </div>
    </>
  );
};

export default CustomFieldArray;
