import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import CustomScaleLoader from "../utils/scaleLoader";
import CustomJobAggregateForm from "./CustomJobAggregateForm";
import { CustomJobAggregate } from "./customJobTypes";

const CustomJobAggregates = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<{ aggregates: CustomJobAggregate[] }> = (
    values,
  ) => {
    return takeAction("update", `custom-jobs/${uuid}/aggregates`, values)
      .then(() => {
        toast.success("Updated");
      })
      .catch(formError);
  };

  const { data, loading }: IUseApi<CustomJobAggregate[]> = useApi(
    `/custom-jobs/${uuid}/aggregates`,
    [],
    true,
  );

  const aggregates = data as CustomJobAggregate[];

  if (loading) {
    return <CustomScaleLoader>Fetching Aggregates...</CustomScaleLoader>;
  }

  return (
    <CustomJobAggregateForm
      initialValues={{
        aggregates:
          aggregates?.length > 0
            ? aggregates
            : [
                {
                  name: "",
                  code: "",
                  in_progress: true,
                },
              ],
      }}
      onSubmit={onSubmit}
      form="CustomJobAggregates"
    />
  );
};

export default CustomJobAggregates;
