import _ from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ReactTable from "../table/ReactTable";
import Form from "./Form";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";

const ProjectTypes = () => {
  const {
    data: projectTypes,
    loading,
    setData,
    takeAction,
  } = useApi("project-types", []);

  const { toggle, modal } = useModal();

  const onSubmit = (values: any, dispatch: any) => {
    return takeAction("store", "project-types", values)
      .then(({ data }: { data: any }) => {
        setData([...projectTypes, data.data]);
        toggle();
        dispatch(reset("AddProjectType"));
        toast.success(`${values.name} added.`);
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => (
        <Link to={`project-types/${info.row.original.uuid}/details`}>
          {info.getValue()}
        </Link>
      ),
    },
  ];

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Project Types"
        pageDescription={
          <>
            The Project Types feature lets organisation's categorise projects so
            that specific project forms can be applied within the Quality
            Assurance system.
          </>
        }
        crumbs={[
          { name: "Project Types", link: "/project-types", active: true },
        ]}
      />
      <div className="d-flex mb-3">
        <button
          type="button"
          onClick={toggle}
          className="btn btn-outline-primary"
        >
          Add New Project Type
        </button>
      </div>
      <ReactTable
        columns={columns}
        data={_.sortBy(projectTypes, "name")}
        loading={loading}
      />
      <Form
        onSubmit={onSubmit}
        title="Add new Project Type"
        form="AddProjectType"
        toggle={toggle}
        modal={modal}
      />
    </>
  );
};

export default ProjectTypes;
