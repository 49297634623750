import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { Position, Qualification, SkillCategory } from "../../enums/Model";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import SkillCard from "./SkillCard";
import StaffSkillList from "./StaffSkillList";

interface StaffMember {
  id: number;
  uuid: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  secondary_email?: string;
  phone?: string;
  work_phone?: string;
}

const UserStaffMatrix = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const [selectedUser, setSelectedUser] = useState<StaffMember>();

  const [employed, setEmployed] = useState(true);

  const { loading, data, setUrl } = useApi("", []);

  const { loading: loadingCompetencies, takeAction: fetchCompetencies } =
    useApi();

  const [staffCompetencies, setStaffCompetencies] = useState([]);

  const [activeStaffSkillFilters, setActiveStaffSkillFilters] = useState({
    skillType: [],
    skillLevel: [],
  });

  const staff = data as StaffMember[];

  useEffect(() => {
    if (!uuid || staff?.length === 0) {
      setUrl(`staff-matrix?filter[employed]=${employed}`);
    }
  }, [uuid, employed]);

  useEffect(() => {
    selectUser();
  }, [uuid, staff]);

  const selectUser = () => {
    if (uuid && staff.length !== 0) {
      const selected = staff.find((s) => s.uuid === uuid);

      if (!selected) {
        return swal(
          "User Not Found",
          "Unable to find user, please ensure that they have the correct employment status",
          "warning",
        );
      }

      setSelectedUser(selected);

      return fetchCompetencies("index", `user/${selected.uuid}/competencies`)
        .then(({ data }: { data: any }) => {
          setStaffCompetencies(data.data);
        })
        .catch(errorSwal);
    }

    setSelectedUser(undefined);
  };

  return (
    <>
      <div className="col-lg-8">
        {loadingCompetencies ? (
          <CustomScaleLoader>
            <p>Loading Competencies...</p>
          </CustomScaleLoader>
        ) : (
          <SkillCard
            selectedUser={selectedUser}
            staffCompetencies={staffCompetencies}
            setStaffCompetencies={setStaffCompetencies}
          />
        )}
      </div>
      <div className="col-lg-4">
        <StaffSkillList
          staff={staff}
          employed={employed}
          setEmployed={setEmployed}
          loadingStaff={loading}
          activeStaffSkillFilters={activeStaffSkillFilters}
          setActiveStaffSkillFilters={setActiveStaffSkillFilters}
        />
      </div>
    </>
  );
};

export default UserStaffMatrix;
