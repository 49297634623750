import _ from "lodash";
import React from "react";
import { AiOutlineMail, AiOutlinePlus } from "react-icons/ai";
import Toggle from "react-toggle";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import useModal from "../../hooks/useModal";
import Spinner from "../../utils/Spinner";
import InvoiceReminderModal from "./InvoiceReminderModal";
import { InvoiceReminder } from "./invoiceReminderTypes";
import { Invoice } from "../../../enums/Model";

const InvoiceReminders = () => {
  const { toggle, modal } = useModal();

  const { takeAction }: IUseApi<{}, { data?: InvoiceReminder }> = useApi();
  const {
    data: invoiceReminder,
    loading,
    setData,
  }: IUseApi<InvoiceReminder | undefined> = useApi(
    "invoice-reminder",
    {},
    true,
  );

  const onChange = () => {
    return storeOrDelete().then(({ data }) => {
      return setData(data.data ?? undefined);
    });
  };

  const storeOrDelete = () => {
    if (invoiceReminder) {
      return takeAction("destroy", `invoice-reminders`);
    }

    return takeAction("store", `invoice-reminders`);
  };

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage="Invoice Reminders"
        pageDescription={
          <>
            Configure invoice reminders to automatically notify clients of
            overdue invoices after a specified number of days.
          </>
        }
        relatedLinks={[{ name: "Invoices", link: "/invoices", model: Invoice }]}
        crumbs={[]}
      />
      <label className="form-control-label d-block tx-inverse tx-semibold">
        Send Invoice Reminders
      </label>
      <Toggle checked={!!invoiceReminder} onChange={onChange} icons={true} />

      {invoiceReminder && (
        <>
          <div className="mt-3 row">
            {_.sortBy(invoiceReminder.items, "length").map((item) => {
              return (
                <div className="col-lg-3 mb-3">
                  <div className="p-4 border rounded-lg bg-white text-dark text-center">
                    <AiOutlineMail className="tx-24 mb-1" />
                    <p className="mb-0 text-dark">{item.length} days overdue</p>
                  </div>
                </div>
              );
            })}
            <div className="col-lg-3 mb-3">
              <button
                type="button"
                className="p-4 border rounded-lg bg-white text-dark w-100 h-100"
                onClick={toggle}
              >
                <AiOutlineMail className="tx-24 mb-1" />
                <p className="mb-0">
                  <AiOutlinePlus />
                  Add Reminder
                </p>
              </button>
            </div>
          </div>
          <InvoiceReminderModal
            invoiceReminder={invoiceReminder}
            setInvoiceReminder={setData}
            modal={modal}
            toggle={toggle}
          />
        </>
      )}
    </>
  );
};

export default InvoiceReminders;
