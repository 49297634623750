import dayjs from "dayjs";
import * as _ from "lodash";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { deleteCalibrationTracking } from "../../actions/calibrationTrackingActions";
import useApi from "../api/useApi";
import { primary } from "../utils/Colours";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";

const CalibrationTrackingTable = (props) => {
  const { takeAction } = useApi();
  const {
    refreshEquipment,
    selectItem,
    calibrationTracking,
    setCalibrationTracking,
  } = props;

  const [deleting, setDeleting] = useState([]);

  const deleteTracking = (e, log) => {
    e.stopPropagation();

    selectItem({});

    return deleteSwal()
      .then(() => {
        const newDeleting = deleting;
        newDeleting.push(log.uuid);
        return takeAction("destroy", `calibration-tracking/${log.uuid}`);
      })
      .then(() => {
        setCalibrationTracking(
          calibrationTracking.filter((t) => t.uuid !== log.uuid),
        );
        return refreshEquipment();
      })
      .then(() => {
        toast.success("Tracking item deleted");
        const newDeleting = deleting.filter((del) => del !== log.uuid);
        setDeleting(newDeleting);
      })
      .catch(errorSwal);
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Units</th>
            <th>Date</th>
            <th>Tracker</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {_.orderBy(calibrationTracking, ["date"], ["desc"]).map((log) => (
            <TrackingRow
              key={log.uuid}
              log={log}
              {...props}
              deleteTracking={deleteTracking}
              deleting={deleting}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TrackingRow = ({
  log,
  selectItem,
  selectedItem,
  deleteTracking,
  deleting,
}) => (
  <tr
    className={`pointer-hover link-hover ${
      selectedItem?.uuid === log.uuid ? "table-secondary" : ""
    }`}
    onClick={() => {
      !log.tracker
        ? selectItem(log)
        : toast.warning(
            "This item is tracked from elsewhere, please click the link to edit.",
          );
    }}
  >
    {deleting.includes(log.uuid) ? (
      <td scope="row" colSpan="5">
        <ClipLoader loading color={primary} size={15} /> Deleting...
      </td>
    ) : (
      <Fragment>
        <td scope="row">{log.amount}</td>
        <td>{log.units}</td>
        <td>{dayjs(log.date).format("DD/MM/YYYY")}</td>
        <td>
          <TrackingLink tracker={log.tracker} />
        </td>
        <td>
          <DeleteData log={log} deleteTracking={deleteTracking} />
        </td>
      </Fragment>
    )}
  </tr>
);

const DeleteData = ({ log, deleteTracking }) => {
  if (log.tracker) return null;
  return (
    <i
      onClick={(e) => deleteTracking(e, log)}
      className="tx-22 pointer-hover tx-danger icon ion-trash-a"
    />
  );
};

const TrackingLink = ({ tracker }) => {
  if (!tracker) return null;
  return <Link to={tracker.link}>{tracker.name}</Link>;
};

export default CalibrationTrackingTable;
