import React from "react";
import PropTypes from "prop-types";
import SpreadPrice from "../utils/SpreadPrice";
import CardDash from "./CardDash";

const DashboardCards = ({ dashboard, setSelectedView, selectedView }) => {
  const totalOutgoings = dashboard.timesheets + dashboard.purchases_billed;

  const totalIncome = dashboard.total_invoiced;

  const overallTotal = totalIncome - totalOutgoings;

  const income = [
    {
      label: "WIP",
      value: (
        <SpreadPrice
          wrapperClass={selectedView === "wip" ? "tx-gray-300" : ""}
          price={dashboard.wip}
        />
      ),
      color: "success",
      onClick: () => setSelectedView("wip"),
      view: "wip",
    },
    {
      label: "Invoiced",
      value: (
        <SpreadPrice
          wrapperClass={selectedView === "invoiced" ? "tx-gray-300" : ""}
          price={dashboard.total_invoiced}
        />
      ),
      color: "success",
      onClick: () => setSelectedView("invoiced"),
      view: "invoiced",
    },
  ];

  const outgoings = [
    {
      label: "Purchases Made",
      value: (
        <SpreadPrice
          wrapperClass={selectedView === "purchases_made" ? "tx-gray-300" : ""}
          price={dashboard.upcoming_purchases}
        />
      ),
      color: "danger",
      onClick: () => setSelectedView("purchases_made"),
      view: "purchases_made",
    },
    {
      label: "Purchases Billed",
      value: (
        <SpreadPrice
          wrapperClass={
            selectedView === "purchases_billed" ? "tx-gray-300" : ""
          }
          price={dashboard.purchases_billed}
        />
      ),
      color: "danger",
      onClick: () => setSelectedView("purchases_billed"),
      view: "purchases_billed",
    },
    {
      label: "Wages",
      value: (
        <SpreadPrice
          wrapperClass={selectedView === "wages" ? "tx-gray-300" : ""}
          price={dashboard.timesheets}
        />
      ),
      color: "danger",
      onClick: () => setSelectedView("wages"),
      view: "wages",
    },
  ];

  const totals = [
    {
      label: "Total Income",
      value: <SpreadPrice price={totalIncome} />,
      color: "success",
    },
    {
      label: "Total Expenses",
      value: <SpreadPrice price={totalOutgoings} />,
      color: "danger",
    },
  ];

  const profitPercentage = isFinite(overallTotal / totalIncome)
    ? ((overallTotal / totalIncome) * 100).toFixed(1)
    : "-";

  const profitDiv = (
    <div className="d-flex">
      <span>%</span>
      <span className="ms-auto">{profitPercentage}</span>
    </div>
  );

  const net = [
    {
      label: "Net Income ($)",
      value: <SpreadPrice price={overallTotal} />,
      color: overallTotal > 0 ? "success" : "danger",
    },
    {
      label: "Net Income (%)",
      value: profitDiv,
      color: overallTotal > 0 ? "success" : "danger",
    },
  ];

  return (
    <>
      <CardDash items={income} selectedView={selectedView} />
      <CardDash
        outerClass="mt-3"
        items={outgoings}
        selectedView={selectedView}
      />
      <CardDash outerClass="mt-3" items={totals} selectedView={selectedView} />
      <CardDash outerClass="mt-3" items={net} selectedView={selectedView} />
    </>
  );
};

DashboardCards.propTypes = {
  setSelectedView: PropTypes.func.isRequired,
  selectedView: PropTypes.string.isRequired,
  dashboard: PropTypes.object.isRequired,
};

export default DashboardCards;
