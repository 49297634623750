import { FieldArray, InjectedFormProps, reduxForm } from "redux-form";
import FormErrorAlert from "../form/FormErrorAlert";
import SubmitButton from "../utils/SubmitButton";
import CustomJobAggregateFieldArray from "./CustomJobAggregateFieldArray";
import { CustomJobAggregate } from "./customJobTypes";

const CustomJobAggregateForm = (
  props: InjectedFormProps<{ aggregates: CustomJobAggregate[] }>,
) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <FieldArray
          component={CustomJobAggregateFieldArray}
          name="aggregates"
        />
        <div className="col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};
const form = reduxForm<{ aggregates: CustomJobAggregate[] }>({});

export default form(CustomJobAggregateForm);
