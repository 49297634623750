import dayjs from "dayjs";
import { Fragment } from "react";

const statuses = [
  "Current",
  "19mm Sieved",
  "Prepped",
  "Tested",
  "Completed",
  "Void",
  "Delivered",
  "Sampled",
  "Compaction Complete",
  "Ready to be Placed",
  "Penetrated",
  "ATT Prep",
  "Moistures",
  "Void",
  "Placed",
  "Wash Fines",
  "PSD Fines",
  "Change Name",
];

const TestHistoryList = ({ history }) => {
  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead className="thead-inverse">
          <tr>
            <th>User</th>
            <th>Action</th>
            <th>Change</th>
            <th>Approved By</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {history.map((item) => (
            <tr key={item.id}>
              <td scope="row">
                <a href={`mailto:${item.updated_by.email}`}>
                  {item.updated_by.name}
                </a>
              </td>
              <td>{item.description}</td>
              <td>
                <ShowChanges item={item} />
              </td>
              <td>
                {item.supervisor ? (
                  <a href={item.supervisor.email}>{item.supervisor.name}</a>
                ) : (
                  "Approval not Required"
                )}
              </td>
              <td>{dayjs(item.created_at).format("DD/MM/YYYY, LT")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ShowChanges = ({ item }) => (
  <Fragment>
    {item.description === "Updated" ? (
      <Fragment>
        From:{" "}
        {item.properties.old.status ? (
          <b>{statuses[item.properties.old.status - 1]}</b>
        ) : (
          "Empty"
        )}{" "}
        to:{" "}
        {item.properties.attributes.status ? (
          <b>{statuses[item.properties.attributes.status - 1]}</b>
        ) : (
          "Empty"
        )}
      </Fragment>
    ) : (
      <Fragment>No Change</Fragment>
    )}
  </Fragment>
);

export default TestHistoryList;
