import React from "react";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import NotFound from "../404";
import useApi from "../api/useApi";
import EquipmentUpload from "./EquipmentUpload";
import isSubmitting from "../utils/submitting";

const EquipmentUploadPage = () => {
  const { user } = useAuth();

  const { takeAction, loading } = useApi();

  if (!user?.hasAccessTo("App\\Models\\Equipment", "create")) {
    return <NotFound />;
  }

  return (
    <>
      <HeaderPage
        crumbs={[{ name: "Equipment", link: "/equipment" }]}
        titlePage="Import Equipment"
      />
      <h6 className="text-dark mb-4">Import you equipment list</h6>
      <p className="mb-0">Step 1.</p>
      <p>
        Download the excel sheet, this sheet will include all of your
        organisations equipment types and branches.
      </p>
      <Button
        className="mb-5"
        size="sm"
        outline
        onClick={() => {
          return takeAction("store", "equipment/import-template").then(
            ({ data }: { data: any }) => {
              window.location.href = data.data;
            },
          );
        }}
        disabled={loading}
      >
        {isSubmitting(loading, "Download", "Genrating..")}
      </Button>
      <p className="mb-0">Step 2.</p>
      <p>Fill in the downloaded excel sheet and upload here.</p>
      <EquipmentUpload />
    </>
  );
};

export default EquipmentUploadPage;
