import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { reduxForm, reset } from "redux-form";
import {
  createCalibrationUnit,
  updateCalibrationUnit,
} from "../../actions/calibrationUnitActions";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import FormCalibrationUnit from "./form";
import CalibrationUnitsTable from "./list";
import { EquipmentType } from "../../enums/Model";

class addCalibrationUnit extends Component {
  onSubmit = (values) => {
    const { dispatch } = this.props;
    return this.createOrUpdate(values)
      .then((res) => {
        dispatch(reset("addCalibrationUnit"));
        toast.success(`${res.payload.name} added successfully`);
      })
      .catch((err) => {
        errorSwal(err);
      });
  };

  createOrUpdate = (values) => {
    const { initialValues, updateCalibrationUnit, createCalibrationUnit } =
      this.props;
    if (initialValues.name) return updateCalibrationUnit(values);
    return createCalibrationUnit(values);
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <HeaderPage
          titlePage="Calibration Units"
          pageDescription={
            <>
              The calibration feature allows for custom units of time or
              measurement to track when equipment requires servicing, such as
              adding "kms" to a car to monitor when it needs maintenance.
            </>
          }
          relatedLinks={[
            {
              name: "Equipment Types",
              link: "/equipment-types",
              model: EquipmentType,
            },
          ]}
          crumbs={[
            { link: "organisations/settings/links", name: "Organisation" },
            { link: "", name: "Calibration Units", active: true },
          ]}
        />

        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-layout">
            <FormCalibrationUnit {...this.props} />
          </div>
        </form>
        <CalibrationUnitsTable {...this.props} selectUnit={this.selectUnit} />
      </div>
    );
  }
}

const form = reduxForm({
  form: "addCalibrationUnit",
  enableReinitialize: true,
});

const mapStateToProps = (state) => ({
  initialValues: state.calibrationUnits.selected,
});

export default connect(mapStateToProps, {
  createCalibrationUnit,
  updateCalibrationUnit,
})(form(addCalibrationUnit));
