import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { ReactComponent as Completed } from "../../svgs/completed.svg";
import SVGContainer from "../utils/SVGContainer";

const CalibrationsDueTable = ({ upcomingCalibrations, period }) => {
  return (
    <Fragment>
      {upcomingCalibrations.length > 0 ? (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Equipment ID</th>
                <th>Calibration Due</th>
                <th>Procedures</th>
              </tr>
            </thead>
            <tbody>
              {upcomingCalibrations.map((calibration) => (
                <tr>
                  <td>
                    <Link
                      to={`/equipment/${calibration.equipment.uuid}/details`}
                    >
                      {calibration.equipment.name}
                    </Link>
                  </td>
                  <td>
                    {dayjs(calibration.due_date.date).format("DD/MM/YYYY") ||
                      "No calibrations undertaken"}
                  </td>
                  <td>{calibration.procedures}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <SVGContainer SVG={Completed} className="mg-t-20" height={"300px"}>
          <h3 className="tx-inverse mg-b-20">
            No calibrations due in the next {period}.
          </h3>
        </SVGContainer>
      )}
    </Fragment>
  );
};

export default CalibrationsDueTable;
