import { useState } from "react";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Badge, Button } from "reactstrap";
import { updatePay } from "../../actions/payActions";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import PayModal from "./PayModal";
import { money } from "../form/formatters";
import _ from "lodash";
import { useAuth } from "../../context/auth-context";

const PayTable = (props) => {
  const { pays, setPayRun, payRun } = props;
  const [selectedPay, setSelectedPay] = useState();

  const { user: authUser } = useAuth();
  const { takeAction } = useApi();
  const toggle = () => (selectedPay ? setSelectedPay() : null);

  const updatePay = (pay, values) => {
    return takeAction("update", `pays/${pay.uuid}`, values)
      .then(({ data }) => {
        const updatedPayRun = {
          ...payRun,
          pays: payRun.pays.map((p) =>
            p.uuid !== data.data.uuid ? p : data.data,
          ),
        };

        setPayRun(updatedPayRun);
        toast.success(
          `Pay marked as ${data.data.completed_at ? "" : "in"}complete!`,
        );
      })
      .catch(errorSwal);
  };

  const deletePay = (pay) => {
    return deleteSwal()
      .then(() => takeAction("destroy", `pays/${pay.uuid}`))
      .then(() => {
        setPayRun({
          ...payRun,
          pays: payRun.pays.filter((p) => pay.uuid !== p.uuid),
        });
        toast.success(`Pay deleted`);
      })
      .catch(errorSwal);
  };

  let columns = [
    {
      accessorKey: "user.name",
      header: "User",
      cell: (info) => {
        const user = info.row.original.user;

        return (
          <>
            <Button
              color="link"
              className="p-0"
              onClick={() => setSelectedPay(info.row.original)}
            >
              {info.getValue()}
            </Button>
            {user.employment_ended && (
              <div className="d-block">
                <Badge color="info" className="rounded-pill shadow">
                  Employment Ended{" "}
                  {dayjs(user.employment_ended).format("DD/MM/YYYY")}
                </Badge>
              </div>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "total_hours",
      header: "Total Hours",
      cell: (info) => info.getValue().toFixed(2),
    },
    {
      accessorKey: "estimated_amount",
      header: "Estimated Amount",
      cell: (info) => money.format(info.getValue().toFixed(2)),
      footer: `Total: ${money.format(
        _.sumBy(pays, "estimated_amount").toFixed(2),
      )}`,
    },
    {
      accessorKey: "completed_at",
      header: "Completed",
      cell: (info) => {
        const pay = info.row.original;
        return (
          <i
            className={`icon pointer-hover ${
              info.getValue()
                ? "ion-checkmark tx-success"
                : "ion-close tx-danger"
            }`}
            onClick={() =>
              updatePay(pay, {
                completed_at: pay.completed_at
                  ? null
                  : dayjs().format("YYYY-MM-DD HH:mm:ss"),
              })
            }
          />
        );
      },
    },
  ];

  if (!payRun.completed_at) {
    columns.push({
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => (
        <DeleteButton deleteFunction={() => deletePay(info.row.original)} />
      ),
    });
  }

  if (!authUser.is_admin) {
    columns = columns.filter(
      (c) => !["completed_at", "delete"].includes(c.accessorKey),
    );
  }

  return (
    <>
      <ReactTable columns={columns} data={pays} />

      <PayModal
        toggle={toggle}
        {...props}
        modal={selectedPay}
        pay={selectedPay}
        setSelectedPay={setSelectedPay}
        updatePay={updatePay}
      />
    </>
  );
};
export default connect(null, { updatePay })(PayTable);
