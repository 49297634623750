import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import useModal from "../hooks/useModal";
import AddEquipmentInvoiceModal from "./AddEquipmentInvoiceModal";
import { Equipment } from "./equipmentTypes";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import ProfileButtons from "../utils/ProfileButtons";
import { FiPlus } from "react-icons/fi";

const EquipmentInvoices = ({ equipment }: { equipment: Equipment }) => {
  const { modal, toggle } = useModal();

  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading } = useApi(`equipment/${uuid}/invoices`, [], true);

  const columns = useMemo(() => {
    return [
      {
        header: "Invoice",
        accessorKey: "name",
        cell: (info: any) => {
          return <Link to={info.row.original.link}>{info.getValue()}</Link>;
        },
      },
    ];
  }, [data]);

  const button = [
    {
      text: (
        <>
          <FiPlus className="me-1 tx-20" /> Add Invoice
        </>
      ),
      onClick: toggle,
    },
  ];

  //Only show the call sign if it exists
  const defaultName = equipment.default_invoice_name;

  return (
    <>
      <ProfileButtons buttons={button} />

      <AddEquipmentInvoiceModal
        initialValues={{
          name: getDefaultName(equipment),
          prices: equipment.prices,
        }}
        modal={modal}
        toggle={toggle}
        equipment={equipment}
      />
      <ReactTable columns={columns} data={data} loading={loading} />
    </>
  );
};

const getDefaultName = (equipment: Equipment) => {
  if (equipment.default_invoice_name) {
    return equipment.default_invoice_name;
  }

  if (equipment.call_sign) {
    return `${equipment.name} - ${equipment?.call_sign} - ${equipment.recurring_payment?.name}`;
  }

  return `${equipment.name} - ${equipment.recurring_payment?.name}`;
};

export default EquipmentInvoices;
