import {
  Button,
  Modal,
  ModalBody,
  Popover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import { TodoTask } from "./todoTypes";
import { useState } from "react";
import ShowConvertPage from "./ShowConvertPage";
import ConvertTypeButtons from "./ConvertTypeButtons";
import { BsArrowLeft } from "react-icons/bs";

interface ConvertToJobModalProps {
  toggle: () => void;
  modal: boolean;
  setData: (data: TodoTask) => void;
  todo?: TodoTask;
}

export interface ConvertToWorkFormProps {
  toggle: () => void;
  setData: (data: TodoTask) => void;
}

export type ConvertType = "job" | "project" | "tender";

const ConvertToJobModal = (props: ConvertToJobModalProps) => {
  const { toggle, modal, setData, todo } = props;

  const [convertType, setConvertType] = useState<ConvertType>();
  const [showForm, setShowForm] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Modal
      style={{ maxWidth: "1000px" }}
      className="wd-md-1000 w-95"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalBody className="p-5">
        <div className="d-flex align-items-start">
          <div>
            <h5 className="tx-gray-800 tx-normal mg-b-5">Convert</h5>
            <p>Convert your 'to do' to a project, job or tender.</p>
          </div>
          <div className="ms-auto d-flex space-x-3">
            <Button
              size="sm"
              color="link"
              outline
              id="back_to_convert_type"
              onClick={() => setShowPopover(true)}
              className={`shadow-none ${showForm ? "d-block" : "d-none"}`}
            >
              <BsArrowLeft /> Back
            </Button>
            {showForm && (
              <>
                <Popover
                  isOpen={showPopover}
                  toggle={() => setShowPopover(!showPopover)}
                  target="back_to_convert_type"
                >
                  <PopoverHeader>Go Back?</PopoverHeader>
                  <PopoverBody>
                    <p className="">
                      Going back will clear all the data you have entered.
                    </p>
                    <div className="space-x-3 d-flex">
                      <Button
                        onClick={() => {
                          setShowPopover(false);
                          setShowForm(false);
                        }}
                        className="p-0 tx-12 text-danger"
                        color="link"
                      >
                        Go Back
                      </Button>
                      <Button
                        onClick={() => setShowPopover(false)}
                        className="p-0 tx-12 ms-auto"
                        color="link"
                      >
                        Cancel
                      </Button>
                    </div>
                  </PopoverBody>
                </Popover>
              </>
            )}
            <button
              type="button"
              className="p-0 border-0 bg-transparent tx-24 text-muted"
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggle}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        {showForm ? (
          <ShowConvertPage
            toggle={toggle}
            setData={setData}
            convertType={convertType}
            todo={todo}
          />
        ) : (
          <ConvertTypeButtons
            convertType={convertType}
            setConvertType={setConvertType}
            setShowForm={setShowForm}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ConvertToJobModal;
