import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import FormModal from "../../utils/FormModal";
import { TestSuiteTest, TestSuiteTestProps } from "../testSuiteTypes";

import { toast } from "react-toastify";
import SelectInput from "../../form/SelectInput";
import formError from "../../utils/formError";

const ApproverModal = ({
  test,
  toggle,
  modal,
  setTest,
}: TestSuiteTestProps & { toggle: Function; modal: boolean }) => {
  const { takeAction }: IUseApi<{}, { data: TestSuiteTest }> = useApi();

  const onSubmit: FormSubmitHandler<{ approver: number }> = (values) => {
    return takeAction(
      "store",
      `test-suite-tests/${test.uuid}/approvals`,
      values,
    )
      .then(({ data }) => {
        toast.success("Approval Added");
        toggle();
        setTest(data.data);
      })
      .catch(formError);
  };

  return (
    <FormModal
      onSubmit={onSubmit}
      title="Add Approver"
      toggle={toggle}
      modal={modal}
      form="AddTestSuiteApprover"
    >
      <div className="form-group col-12">
        <Field
          component={SelectInput}
          label="Approver"
          name="approver"
          url={`/competent-staff?skill_type=TestSuite&skill_id=${test.test_suite_id}`}
          formatData={(data: { name: string; id: number; email: string }[]) => {
            return data.map((d) => {
              return {
                label: d.name,
                value: d.id,
              };
            });
          }}
        />
      </div>
    </FormModal>
  );
};

export default ApproverModal;
