import { Route, Switch } from "react-router-dom";
import NoMatch from "./404";
import BasicUserProjectProfile from "./basicUser/BasicUserProjectProfile";
import CustomJobJobProfile from "./customJobs/jobs/Profile";
import AddExpenseClaim from "./expenseClaims/Add";
import ExpenseClaimProfile from "./expenseClaims/Profile";
import Home from "./home";
import JobProfile from "./jobs/Profile";
import JobsList from "./jobs/register/JobsList";
import RequestLeave from "./leave/Request";
import RequestedLeaveProfile from "./leave/RequestedLeaveProfile";
import TimesheetProfile from "./timesheets/TimesheetProfile";
import UserProfile from "./user/Profile";
import CalibrationProfile from "./calibrations/Profile";
import SelectOrganisation from "./organisation/SelectOrganisation";
import AddDocket from "./dockets/Add";
import DocketProfile from "./dockets/Profile";
import { useAuth } from "../context/auth-context";
import DeliverableProfile from "./deliverables/Profile";
import StaffCompetencyProfile from "./staffCompetencies/Profile";

const BasicUserRoutes = () => {
  const { user } = useAuth();

  return (
    <main>
      <Switch>
        <Route exact path="/jobs" component={JobsList} />
        <Route path="/" exact component={Home} />
        <Route exact path="/users/:id/leave/request" component={RequestLeave} />
        <Route
          path="/users/:userId/:page/:folderUuid?"
          component={UserProfile}
        />
        <Route
          path="/calibrations/:uuid/:page"
          component={CalibrationProfile}
        />
        <Route
          exact
          path="/requested-leave/:uuid/:page/:folderUuid?"
          component={RequestedLeaveProfile}
        />
        <Route exact path="/leave/request" component={RequestLeave} />
        <Route exact path="/expense-claims/add" component={AddExpenseClaim} />

        <Route
          exact
          path="/expense-claims/:uuid/:page/:folderUuid?"
          component={ExpenseClaimProfile}
        />
        <Route
          exact
          path="/custom-jobs/jobs/:uuid/:page/:folderUuid?"
          component={CustomJobJobProfile}
        />
        <Route
          path="/projects/:number/jobs/:jobUuid/:page/:folderUuid?"
          component={JobProfile}
        />

        <Route
          exact
          path="/organisations/select"
          component={SelectOrganisation}
        />
        <Route
          path="/timesheets/:uuid/:page/:folderUuid?"
          component={TimesheetProfile}
        />
        <Route path="/home" exact component={Home} />
        <Route
          path="/projects/:uuid/:page"
          component={BasicUserProjectProfile}
        />

        <Route exact path="/dockets/add" component={AddDocket} />

        <Route exact path="/dockets/:uuid/:page" component={DocketProfile} />
        <Route
          exact
          path="/deliverables/:uuid/:page"
          component={DeliverableProfile}
        />

        <Route
          path="/staff-competencies/:uuid"
          component={StaffCompetencyProfile}
        />

        {/* 404 - Not Found */}
        <Route component={NoMatch} />
      </Switch>
    </main>
  );
};

export default BasicUserRoutes;
