import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { formValueSelector, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";
import OrgFillFields from "./OrgFillFields";
import SupplierFillFields from "./SupplierFillFields";

const Add = (props) => {
  const { handleSubmit, type_id, change } = props;
  const setting = useOrganisationSetting("supplier_sign_up");
  const { loading, data, takeAction } = useApi("supplier-types", []);
  const { data: similarSuppliers, setUrl, setData, url } = useApi(null, []);
  const history = useHistory();
  const supplierType = data.find((d) => d.id === type_id);

  const submit = (values) => {
    return takeAction("store", "suppliers", values)
      .then(({ data }) => {
        toast.success(`${values.name} added.`);
        history.push(`/suppliers/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Supplier"
        crumbs={[
          { link: "suppliers", name: "Suppliers" },
          { link: "suppliers", name: "New Supplier", active: true },
        ]}
      />
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <ShowFields
            setting={setting}
            loading={loading}
            similarSuppliers={similarSuppliers}
            setUrl={setUrl}
            setData={setData}
            url={url}
            change={change}
            data={data}
          />

          <div className="col-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
      {type_id && supplierType?.insurances?.length > 0 && (
        <div className="row">
          <div className="col-12">
            <label className="section-title">Required Insurances</label>
            <hr />
          </div>
          {supplierType?.insurances.map((insurance) => {
            if (
              insurance?.approval_status?.status === "Approved" ||
              !insurance.approval_status
            ) {
              return (
                <div key={insurance.id} className="col-lg-4">
                  <div className="bg-white p-3 d-flex justify-content-center align-items-center border">
                    <p className="fw-bolder text-dark mb-1">{insurance.name}</p>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </>
  );
};

const ShowFields = (props) => {
  const { setting } = props;

  if (!setting) {
    return null;
  }

  if (setting.meta_value) {
    return <SupplierFillFields {...props} />;
  }

  return <OrgFillFields {...props} />;
};

const form = reduxForm({ form: "AddSupplier" });

const selector = formValueSelector("AddSupplier");

const mapStateToProps = (state) => {
  return {
    type_id: selector(state, "type_id"),
    initialValues: {
      abn_holder: true,
    },
  };
};

export default connect(mapStateToProps, {})(form(Add));
