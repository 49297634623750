import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import Empty from "../utils/Empty";
import CustomScaleLoader from "../utils/scaleLoader";
import TimeLine, { TimelineItem } from "../utils/TimeLine";

interface CheckIn {
  uuid: string;
  id: number;
  check_point: {
    name: string;
    link: string;
  };
  check_in_time: string;
  check_in_latitude: number;
  check_in_longitude: number;
  check_out_latitude?: number;
  check_out_longitude?: number;
  check_out_time?: string;
}

const CheckIns = ({
  timesheet,
}: {
  timesheet: { user: { uuid: string }; date: string };
}) => {
  const { data, loading }: IUseApi = useApi(
    `users/${timesheet.user.uuid}/check-ins?filter[check_in_time]=${timesheet.date}`,
    [],
    true,
  );

  const checkIns = data as CheckIn[];

  const formatted: TimelineItem[] = checkIns.map((checkIn) => {
    return {
      uuid: checkIn.uuid,
      created_at: checkIn.check_in_time,
      title: (
        <Link className="tx-inverse" to={`/${checkIn.check_point.link}`}>
          {checkIn.check_point.name}
        </Link>
      ),
      content: checkIn.check_out_time ? (
        <>
          Checked out at{" "}
          <span className="tx-inverse">
            {dayjs(checkIn.check_out_time).format("MMMM, DD hh:mm a")}
          </span>
        </>
      ) : (
        ""
      ),
    };
  });
  if (loading) {
    return <CustomScaleLoader>Fetching Check Ins...</CustomScaleLoader>;
  }
  if (formatted?.length === 0) {
    return (
      <div className="mt-5">
        <Empty top={false}>
          <p className="mt-4 tx-inverse tx-semibold">
            No check-ins found for {dayjs(timesheet.date).format("DD/MM/YYYY")}
          </p>
        </Empty>
      </div>
    );
  }

  return <TimeLine title="" items={formatted} />;
};

export default CheckIns;
