export const organisationVariables = [
  {
    description:
      "The Company's name, if the company has a trading as name, it will be that.",
    name: "Company Name",
    template_variable: "${company-name}",
  },
  {
    description: "Your Company's ABN",
    name: "ABN",
    template_variable: "${abn}",
  },
  {
    description: "Street Address",
    name: "Your Company's Street Address",
    template_variable: "${street-address}",
  },
  {
    description: "Suburb",
    name: "Your Company's Suburb",
    template_variable: "${suburb}",
  },
  {
    description: "State",
    name: "Your Company's State",
    template_variable: "${state}",
  },
  {
    description: "Postcode",
    name: "Your Company's Postcode",
    template_variable: "${postcode}",
  },
  {
    description: "The date the document was generated",
    name: "Generated At Date",
    template_variable: "${generated-at-date}",
  },
  {
    description: "The time the document was generated",
    name: "Generated At Time",
    template_variable: "${generated-at-time}",
  },
  {
    description: "The timezone your organisation has set",
    name: "Timezone",
    template_variable: "${timezone}",
  },
  {
    description: "User logged in that generated template name.",
    name: "Generated By Name",
    template_variable: "${generated-by-name}",
  },
  {
    description: "User logged in that generated template phone",
    name: "Generated By Email",
    template_variable: "${generated-by-email}",
  },
  {
    description: "",
    name: "Generated By Phone",
    template_variable: "${generated-by-phone}",
  },
];
