import React, { useState } from "react";
import List from "./List";
import HeaderPage from "../header/HeaderPage";
import AddModal from "./AddModal";
import useApi from "../api/useApi";
import { Tender, Project } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const Industries = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { loading, data: industries, setData: setIndustries } = useApi(
    "industries",
    [],
    true,
  );

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/industries/" />
      <HeaderPage
        titlePage="Industries"
        pageDescription={
          <>
            Categorise and track projects based on the industries they serve,
            view total work done for each industry, and assign industries to
            projects.
          </>
        }
        relatedLinks={[
          { name: "Tenders", link: "/tenders", model: Tender },
          { name: "Projects", link: "/projects", model: Project },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "", name: "Industries", active: true },
        ]}
      />
      <button
        type="button"
        onClick={toggle}
        className="btn btn-outline-primary mb-2"
      >
        Add Industry
      </button>
      <List
        industries={industries}
        setIndustries={setIndustries}
        loading={loading}
      />
      <AddModal
        industries={industries}
        setIndustries={setIndustries}
        toggle={toggle}
        modal={modal}
      />
    </>
  );
};

export default Industries;
