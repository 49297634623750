import { Group } from "./../managementDashboardTypes";
import { useInfiniteQuery } from "react-query";
import api from "../../api/api";
import querystring from "query-string";

const getManagementCpirs = (
  { pageParam = 1 },
  group?: Group,
  search?: string,
) => {
  if (!pageParam) {
    return;
  }

  if (!search) {
    return;
  }

  const query: string = group
    ? querystring.stringify({
        model: group.type,
        id: group.id,
      })
    : "";

  return api
    .get(`cpir-management?page=${pageParam}&filter[search]=${search}&${query}`)
    .then(({ data }) => data);
};

export default function useManagementCpirs(group?: Group, search?: string) {
  return useInfiniteQuery(
    ["management-cpirs", { search, group }],
    (pageParam) => getManagementCpirs(pageParam, group, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
      enabled: !!group,
    },
  );
}
