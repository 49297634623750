import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, formValueSelector } from "redux-form";
import checkAbn from "./checkAbn";
import RenderField from "./renderField";
import required from "./required";

const AbnField = (props) => {
  const { change, abn, validate = true } = props;
  const [fetching, setFetching] = useState(false);

  const updateAbn = () => {
    if (!abn) return;

    setFetching(true);

    checkAbn(abn)
      .then((details) => {
        if (details.EntityName) {
          change("name", details.EntityName);
          setFetching(false);
          return;
        }
        throw new Error("No ABN");
      })
      .then(() => {
        if (props.onSuccess) {
          return props.onSuccess(abn);
        }
      })
      .catch((err) => {
        change("abn", "");
        change("name", "");
        toast.error("Unable to find ABN, Please search Again");
        setFetching(false);
      });
  };

  return (
    <>
      <Field
        component={RenderField}
        name="abn"
        label="ABN"
        validate={validate ? required : null}
        required={validate}
        onBlur={updateAbn}
      />
      {fetching && <small>Checking ABN...</small>}
    </>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    abn: selector(state, "abn"),
  };
};

export default connect(mapStateToProps, {})(AbnField);
