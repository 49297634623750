import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useState } from "react";
import { AiOutlineFilePdf, AiOutlineFileWord } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { IProjectEquipment } from "./ProjectEquipment";
import SendEquipmentCalibrationsModal from "../equipment/SendEquipmentCalibrationsModal";

dayjs.extend(isBetween);

export interface ProjectEquipmentCalibration {
  name: string;
  link: string;
  date: string;
  id: number;
  has_template: boolean;
}

const ProjectEquipmentCalibrations = ({
  equipment,
  project,
}: {
  equipment: IProjectEquipment[];
  project: {
    id: number;
    full_name: string;
  };
}) => {
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const { takeAction, loading }: IUseApi = useApi();
  const { modal, toggle } = useModal();

  const [selectedCalibrations, setSelectedCalibrations] = useState<
    ProjectEquipmentCalibration[]
  >([]);

  const downloadCalibrations = (format: "Word" | "PDF") => {
    return takeAction("store", `download-merged-template`, {
      format,
      model_ids: selectedCalibrations.map(({ id }) => id),
      model: "App\\Models\\EquipmentCalibration",
      file_name: project.full_name,
    })
      .then(({ data }) => {
        window.location.href = data.data;
      })
      .catch(errorSwal);
  };

  const SendCalibrations = () => {
    if (selectedCalibrations.length < 1) {
      return null;
    }
    return (
      <Button
        outline
        size="sm"
        className="btn m-1"
        onClick={toggle}
        disabled={modal && loading}
      >
        <i className="fa fa-paper-plane tx-18 me-2 tx-primary" />{" "}
        {isSubmitting(
          modal && loading,
          `Send ${selectedCalibrations.length} Calibrations`,
          "Sending...",
        )}
      </Button>
    );
  };

  const calibrations = equipment.map((e) => e.calibrations).flat();

  const columns = [
    {
      accessorKey: "name",
      header: "Calibration",
      cell: (info: any) => {
        return <Link to={info.row.original.link}>{info.getValue()}</Link>;
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      cell: (info: any) => {
        return dayjs(info.getValue()).format("DD/MM/YYYY");
      },
    },
  ];

  const filtered =
    startDate && endDate
      ? calibrations.filter((c) => {
          return (
            dayjs(c.date).isSameOrAfter(startDate) &&
            dayjs(c.date).isSameOrBefore(endDate)
          );
        })
      : calibrations;

  return (
    <>
      <SendEquipmentCalibrationsModal
        modal={modal}
        toggle={toggle}
        project={project}
        selectedCalibrations={selectedCalibrations}
        title={"Send Equipment Callibrations"}
        className={"wd-md-500 mx-wd-500"}
        form="SendEquipmentCallibrations"
        save="Send"
        saving="Sending..."
      />
      <div className="row">
        <div className="col-lg-4 form-group">
          <label className="form-control-label tx-inverse tx-semibold">
            From
          </label>
          <input
            className="form-control"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-lg-4 form-group">
          <label className="form-control-label tx-inverse tx-semibold">
            To
          </label>
          <input
            className="form-control"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        {selectedCalibrations.length !== 0 ? (
          <div className="col-lg-4 form-group d-flex align-items-end">
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                block
                outline
                color="primary"
                className="w-100"
                disabled={!modal && loading}
              >
                {isSubmitting(
                  !modal && loading,
                  `Download ${selectedCalibrations.length} Calibrations`,
                  "Generating...",
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  className="py-2"
                  onClick={() => downloadCalibrations("Word")}
                >
                  <AiOutlineFileWord className="tx-primary tx-22 me-2" />
                  Word
                </DropdownItem>
                <DropdownItem
                  className="py-2"
                  onClick={() => downloadCalibrations("PDF")}
                >
                  <AiOutlineFilePdf className="tx-danger tx-22 me-2" />
                  PDF
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ) : null}
      </div>
      <ReactTable
        enableMultiSelect
        data={filtered}
        columns={columns}
        setSelectedRows={setSelectedCalibrations}
        extraButtons={<SendCalibrations />}
      />
    </>
  );
};

export default ProjectEquipmentCalibrations;
