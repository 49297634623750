import React, { useEffect, useState } from "react";
import SignatureCanvas from "./signature";

const SignaturePad = (props) => {
  const {
    input: { onChange, value, onBlur },
    meta: { touched, error },
    label,
    clearOnResize,
    required,
  } = props;

  const [sigPad, setSigPad] = useState();

  useEffect(() => {
    if (sigPad && value) {
      sigPad.fromDataURL(value);
    }
  }, [sigPad]);

  const clear = () => {
    sigPad.clear();

    onChange("");
  };

  return (
    <>
      {label && (
        <label className="form-control-label tx-inverse tx-semibold">
          {label}
          {required ? <span className="tx-danger"> *</span> : ""}
        </label>
      )}
      <button
        className="btn btn-outline-danger mb-2 w-100"
        type="button"
        onClick={clear}
      >
        Clear Pad
      </button>
      <>
        <SignatureCanvas
          ref={setSigPad}
          backgroundColor="#DEE2E6"
          canvasProps={{ style: { width: "100%", height: "300px" } }}
          onEnd={() => {
            onBlur(sigPad.toDataURL("image/svg+xml"));
          }}
          clearOnResize={clearOnResize || false}
        />
        {touched && error && (
          <span className={error ? "parsley-errors-list" : ""}>{error}</span>
        )}
      </>
    </>
  );
};

export default SignaturePad;
