import "chart.js/auto";
import _ from "lodash";
import dayjs from "dayjs";
import queryString from "query-string";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { ReactComponent as Analytics } from "../../svgs/analytics.svg";
import CustomScaleLoader from "../utils/scaleLoader";
import SpreadPrice from "../utils/SpreadPrice";
import SVGContainer from "../utils/SVGContainer";
import ProductivityList from "./ProductivityList";
import useApi from "../api/useApi";

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const formatProductivity = (productivity) => {
  return {
    labels: productivity.map((prod, key) => prod.user),
    datasets: [
      {
        label: "Productivity Hours",
        data: productivity.map((prod) => prod.time_in_hours),
        backgroundColor: "rgba(0, 170, 79, 0.2)",
        borderColor: "rgba(0, 170, 79, 1)",
        borderWidth: 1,
      },
    ],
  };
};

const groupByUsers = (productivity) => {
  const grouped = _.chain(productivity)
    .groupBy("user_id")
    .map((group) => {
      return {
        user: group[0].user,
        time_in_hours: _.sumBy(group, "time_in_hours"),
      };
    })
    .value();
  return grouped;
};

const OverviewProductivity = ({
  modelId,
  modelType,
  to,
  from,
  allDates,
  projectId,
}) => {
  const query = {
    startDate: !allDates ? `${dayjs(from).format("YYYY-MM-DD")}` : null,
    endDate: !allDates ? `${dayjs(to).format("YYYY-MM-DD")}` : null,
    model: modelType,
    modelId,
    withJsonResouceWrapping: "1",
  };

  const {
    data: productivity,
    refreshData,
    loading,
    setUrl,
    setData,
  } = useApi(
    `/productivity-dashboard?${queryString.stringify(query)}`,
    [],
    true,
  );

  useEffect(() => {
    if (to && from && !allDates) {
      setData([]);
      setUrl(`/productivity-dashboard?${queryString.stringify(query)}`);
    }
  }, [to, from, allDates]);

  useEffect(() => {
    setData([]);
    setUrl(`/productivity-dashboard?${queryString.stringify(query)}`);
  }, [allDates]);

  if (loading && productivity.length === 0) {
    return <CustomScaleLoader>Fetching Productivity...</CustomScaleLoader>;
  }

  if (productivity.length === 0) {
    return (
      <div className="h-100 w-100">
        <SVGContainer SVG={Analytics}>
          <p className="mb-3 text-center">
            No Productivity Hours logged for this project.
            <br />
          </p>
        </SVGContainer>
      </div>
    );
  }

  const totalChargeoutAmount = _.sumBy(productivity, "chargeout_amount");
  return (
    <div className="row mg-b-30 w-100">
      <div className="col-md-12 mg-t-20 mg-md-t-0">
        <Bar
          options={options}
          data={formatProductivity(groupByUsers(productivity))}
        />
      </div>
      <div className="col-12">
        <div className="card card-dash-one mg-t-20 w-100">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <i className="icon ion-ios-pie-outline" />
              <div className="dash-content w-100">
                <label className="tx-secondary w-100 d-flex">
                  <span className="ms-auto">Hours</span>
                </label>
                <h2 className="text-end">
                  {_.sumBy(productivity, "time_in_hours").toFixed(1)}
                </h2>
              </div>
            </div>

            <div className="col-lg-6">
              <i className="icon ion-ios-analytics-outline" />
              <div className="dash-content w-100">
                <label className="tx-success w-100 d-flex">
                  <span className="ms-auto text-end">Estimated Chargeout</span>
                </label>
                <h2>
                  <SpreadPrice price={totalChargeoutAmount} />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        <ProductivityList
          productivity={productivity}
          modelId={modelId}
          projectId={projectId ?? modelId}
          refreshData={refreshData}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default OverviewProductivity;
