import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormMachineDayRate from "./form";

const EditMachineDayRate = ({
  machineDayRate,
  setMachineDayRate,
}: {
  machineDayRate: any;
  setMachineDayRate: Function;
}) => {
  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction("update", `machine-day-rates/${uuid}`, values)
      .then(({ data }) => {
        toast.success(`Updated Successfully`);
        setMachineDayRate(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <FormMachineDayRate
      initialValues={machineDayRate}
      onSubmit={onSubmit}
      form="EditMachineDayRate"
    />
  );
};

export default EditMachineDayRate;
