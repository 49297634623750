import React from "react";
import { Link, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";

const Projects = (props) => {
  const { uuid } = useParams();

  const { data: supplierProjects, loading } = useApi(
    `suppliers/${uuid}/projects`,
    [],
  );

  const columns = [
    {
      accessorKey: "name",
      header: "Project",
      cell: (info) => (
        <Link to={`/supplier-projects/${info.row.original.id}/deliverables`}>
          {info.getValue()}
        </Link>
      ),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => {
        const status = info.getValue();
        return <Badge color={status.color}>{status.label}</Badge>;
      },
    },
  ];

  return (
    <ReactTable columns={columns} data={supplierProjects} loading={loading} />
  );
};

export default Projects;
