import { useHistory, useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { RecurringJob } from "./recurringJobTypes";
import ShowRecurringJobPage from "./ShowRecurringJobPage";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { Button } from "reactstrap";

export type RecurringJobProfilePages = "details" | "documents" | "deliverables";

const RecurringJobProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const history = useHistory();

  const { data, setData, loading, takeAction }: IUseApi<RecurringJob> = useApi(
    `recurring-jobs/${uuid}`,
    null,
    true,
  );

  const deleteRecurringJob = () => {
    deleteSwal(data?.name)
      .then(() => takeAction("destroy", `recurring-jobs/${uuid}`))
      .then(() => {
        history.push(`/projects/${data?.project.uuid}/jobs`);
        toast.success("Recurring Job deleted successfully");
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <Spinner loading={true} />;
  }

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Projects",
            link: "/projects",
          },
          {
            name: data?.project.name ?? "-",
            link: `/projects/${data?.project.uuid}/jobs`,
          },
          {
            name: data?.name ?? "-",
            link: ``,
            active: true,
          },
        ]}
        titlePage={data?.name ?? "Recurring Job"}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{data?.name}</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowRecurringJobPage recurringJob={data} setRecurringJob={setData} />
        }
        tabs={tabs(data)}
        sideBar={
          <Button
            className="mt-3"
            block
            color="danger"
            outline
            onClick={deleteRecurringJob}
          >
            Delete
          </Button>
        }
      />
    </>
  );
};

const tabs = (
  recurringJob?: RecurringJob,
): ProfileTab<RecurringJobProfilePages>[] => {
  return [
    {
      label: "Details",
      link: `/recurring-jobs/${recurringJob?.uuid}/details`,
      page: "details",
    },
    {
      label: "Documents",
      link: `/recurring-jobs/${recurringJob?.uuid}/documents`,
      page: "documents",
    },
    {
      label: "Deliverables",
      link: `/recurring-jobs/${recurringJob?.uuid}/deliverables`,
      page: "deliverables",
    },
  ];
};

export default RecurringJobProfile;
