import React from "react";

const AverageDaysToPay = ({
  resource,
}: {
  resource: { average_days_to_pay?: number; name: string };
}) => {
  if (resource.average_days_to_pay == null) {
    return null;
  }

  return (
    <p>
      {resource.name} averages{" "}
      <span className="tx-inverse">
        {resource.average_days_to_pay.toFixed(2)} days
      </span>{" "}
      to pay invoices.
    </p>
  );
};

export default AverageDaysToPay;
