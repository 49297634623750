import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import CustomForm from "../customFields/CustomForm";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import BottomCompleteButton from "./BottomCompleteButton";

const Edit = (props) => {
  const { takeAction } = useApi();
  const { user } = useAuth();
  const { uuid } = useParams();

  const isComplete = props.calibration.completed_at;

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/calibrations/${uuid}/custom-values`,
  );

  const submit = (values) => {
    return upload(values)
      .then((data) => {
        return takeAction(
          "update",
          `equipment-calibrations/${values.uuid}`,
          data,
        );
      })
      .then(({ data }) => {
        toast.success("Calibration Updated");
        props.setCalibration(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <div className="row">
        <div className="form-group col-lg-12">
          <Field
            validate={required}
            required
            label="Date"
            component={renderField}
            name="date"
            type="date"
          />
        </div>
        <CustomForm customForm={props.initialValues.custom_form} {...props} />
        <div className="form-group col-12 d-flex space-x-3">
          <SubmitButton
            className={`${isComplete ? "ms-auto" : "me-auto"}`}
            {...props}
          />
          {!isComplete && (
            <BottomCompleteButton {...props} baseSubmit={submit} />
          )}
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "Calibration" });

export default form(Edit);
