import "chart.js/auto";
import dayjs from "dayjs";
import React from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router";
import useApi from "../api/useApi";
import { barMoneyOptions } from "../charts/moneyOptions";
import CustomScaleLoader from "../utils/scaleLoader";

const ClientInvoiceOverview = () => {
  const { uuid } = useParams();

  const { data, loading } = useApi(
    `clients/${uuid}/invoice-overview`,
    [],
    true,
  );

  if (loading) {
    return <CustomScaleLoader>Fetching overview..</CustomScaleLoader>;
  }

  return <Bar options={barMoneyOptions} data={formatted(data)} />;
};

const formatted = (invoiceOverview) => {
  return {
    labels: invoiceOverview.map((overview, key) =>
      dayjs(overview.month).format("MMM YYYY"),
    ),
    datasets: [
      {
        label: "Invoice Amount",

        data: invoiceOverview.map((overview) =>
          parseFloat(overview.invoice_amount.toFixed(2)),
        ),
        backgroundColor: "rgba(0, 170, 79, 0.2)",
        borderColor: "rgba(0, 170, 79, 1)",
        borderWidth: 1,
      },
    ],
  };
};

export default ClientInvoiceOverview;
