import React from "react";
import SubmitButton from "../utils/SubmitButton";
import { Modal, ModalHeader, ModalFooter } from "reactstrap";

const NoticeDeleteModal = (props) => {
  const { noticeName, modal, toggle, onSubmit } = props;

  return (
    <Modal
      size="sm"
      isOpen={modal}
      className="modal-dialog modal-lg wd-md-600"
      toggle={toggle}
      data-keyboard="false"
    >
      <ModalHeader toggle={toggle} className="text-danger">
        Do you really want to delete this notice?
      </ModalHeader>
      <ModalFooter>
        <h5>{noticeName}</h5>
        <SubmitButton
          {...props}
          className="btn-danger"
          save="Delete"
          saving="Deleting"
          onClick={onSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default NoticeDeleteModal;
