import React from "react";
import { useParams } from "react-router-dom";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import { SelectOption } from "../utils/utilTypes";
import CustomJobTemplateFields from "./CustomJobTemplateFields";
import { CustomJobTemplate } from "./customJobTypes";

interface AddCustomJobTemplateModalProps {
  toggle: Function;
  modal: boolean;
  setTemplates: (templates: CustomJobTemplate[]) => void;
  templates: CustomJobTemplate[];
}

const AddCustomJobTemplateModal = (props: AddCustomJobTemplateModalProps) => {
  const { toggle, modal, setTemplates, templates } = props;
  const { takeAction }: IUseApi<{}, { data: CustomJobTemplate }> = useApi();
  const { uuid } = useParams<{ uuid: string }>();

  const onSubmit: FormSubmitHandler<CustomJobTemplate> = (values) => {
    return takeAction("store", `/custom-jobs/${uuid}/templates`, values)
      .then(({ data }) => {
        setTemplates([...templates, data.data]);
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      form="ADD_CUSTOM_JOB_TEMPLATE"
      onSubmit={onSubmit}
      title="Add new custom job template"
    >
      {({ change }: { change: (field: string, value: any) => void }) => (
        <CustomJobTemplateFields change={change} />
      )}
    </FormModal>
  );
};

export default AddCustomJobTemplateModal;
