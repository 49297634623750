import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import ShowPage from "./ShowPage";

const TypeProfile = () => {
  const { uuid } = useParams();

  const {
    data: type,
    setData: setType,
    loading,
  } = useApi(`tender-types/${uuid}`, null, true);

  const tabs = [
    {
      link: `/tender-types/${uuid}/details`,
      icon: "",
      page: "details",
      label: "Details",
    },
    {
      link: `/tender-types/${uuid}/fields`,
      icon: "",
      page: "fields",
      label: "Fields",
    },
  ];

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={type?.name}
        crumbs={[
          {
            link: "/organisations/settings/links",
            name: "Organisation",
          },
          {
            link: "/tender-types",
            name: "Tender Types",
          },
          {
            link: "/",
            name: type?.name,
            active: true,
          },
        ]}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{type?.name}</DashboardH3>
          </DashboardHeader>
        }
        tabs={tabs}
        content={<ShowPage type={type} setType={setType} />}
      />
    </>
  );
};

export default TypeProfile;
