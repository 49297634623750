import { useInfiniteQuery } from "react-query";
import api from "../../api/api";
import { useParams } from "react-router-dom";

const getBranchInterEntityInvoices = (
  { pageParam = 1 },
  uuid: string,
  search?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `organisation-branches/${uuid}/inter-entity-invoices?page=${pageParam}&filter[search]=${search}&paginate=9`,
    )
    .then(({ data }) => data);
};

export default function useBranchInterEntityInvoices(search?: string) {
  const { uuid } = useParams<{ uuid: string }>();

  return useInfiniteQuery(
    ["branch-inter-entity-invoices", { uuid, search }],
    (pageParam) => getBranchInterEntityInvoices(pageParam, uuid, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
