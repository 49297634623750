import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { Register, RegisterPages } from "./registerTypes";
import ShowRegisterPage from "./ShowRegisterPage";

const RegisterProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, setData, loading }: IUseApi = useApi(
    `/registers/${uuid}`,
    {},
    true,
  );

  const register = data as Register;

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPageAdminFilter titlePage={register.name} crumbs={[]} />

      <Profile<RegisterPages>
        content={<ShowRegisterPage register={register} setRegister={setData} />}
        header={
          <DashboardHeader>
            <DashboardH3>{register.name}</DashboardH3>
          </DashboardHeader>
        }
        tabs={[
          {
            label: "Details",
            link: `/registers/${uuid}/details`,
            page: "details",
            icon: "fa-pencil",
          },
          {
            label: "Fields",
            link: `/registers/${uuid}/fields`,
            page: "fields",
            icon: "fa-file-text-o",
          },
        ]}
      />
    </>
  );
};

export default RegisterProfile;
