import { useParams } from "react-router-dom";
import {
  InboundAccountsPayable,
  InboundAccountsPayablePages,
  SelectedPayableProps,
} from "./types";
import InboundAccountsPayableDetails from "./InboundAccountsPayableDetails";
import CustomScaleLoader from "../utils/scaleLoader";

const ShowInboundAccountsPayablePage = ({
  payable,
  selectedPayable,
  setSelectedPayable,
  selectedDocument,
  setSelectedDocument,
}: {
  payable: InboundAccountsPayable;
} & SelectedPayableProps) => {
  const { page } = useParams<{
    page: InboundAccountsPayablePages;
    uuid: string;
  }>();

  if (!payable) {
    return null;
  }

  switch (page) {
    case "details":
      return !payable.documents_analysed ? (
        <CustomScaleLoader>Analysing Documents...</CustomScaleLoader>
      ) : (
        <InboundAccountsPayableDetails
          payable={payable}
          selectedPayable={selectedPayable}
          setSelectedPayable={setSelectedPayable}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
        />
      );
    case "email":
      return null;
    default:
      return null;
  }
};

export default ShowInboundAccountsPayablePage;
