import { FETCH_COMPACTIONS, UPDATE_COMPACTION } from "../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPACTIONS:
      return {
        ...state,
        items: action.payload
      };
    case UPDATE_COMPACTION:
      return {
        ...state,
        items: action.payload
      };
    default:
      return state;
  }
}
