import dayjs from "dayjs";

const filterProjects = (projects, filters) => {
  return projects.reduce((carry, project) => {
    if (
      filters.search &&
      project.name.toLowerCase().includes(filters.search.toLocaleLowerCase())
    ) {
      carry.push({
        ...project,
        jobs: project.jobs.filter(
          (job) =>
            filterStatus(job, filters.statuses) &&
            filterOverdue(job, filters.overdue) &&
            filterDueDate(job, filters.dueDateLimit, filters.dueDateLimitDays),
        ),
      });
      return carry;
    }

    const fil = filteredJobs(project.jobs, filters);
    if (fil.length > 0) {
      carry.push({
        ...project,
        jobs: fil,
      });
    }

    return carry;
  }, []);
};

const filteredJobs = (jobs, filters) => {
  return jobs.filter(
    (job) =>
      filterStatus(job, filters.statuses) &&
      filterSearch(job, filters.search) &&
      filterOverdue(job, filters.overdue) &&
      filterDueDate(job, filters.dueDateLimit, filters.dueDateLimitDays) &&
      filterInvoiceStatus(job, filters.invoiceStatuses),
  );
};

const filterOverdue = (job, overdue) => {
  if (!overdue) {
    return true;
  }

  return job.is_overdue;
};

const filterStatus = (job, statuses) => {
  if (statuses.length === 0) {
    return true;
  }
  return statuses.includes(job.status);
};

const filterInvoiceStatus = (job, statuses) => {
  if (statuses.length === 0) {
    return true;
  }
  return statuses.includes(job.invoice_status);
};

const filterSearch = (job, search) => {
  if (!search) return true;

  const searchLc = search.toLowerCase();

  return (
    job.full_name.toLowerCase().includes(searchLc) ||
    job.status_badge.label.toLowerCase().includes(searchLc) ||
    job.manager.name.toLowerCase().includes(searchLc) ||
    dayjs(job.scheduled_finish_date).format("DD/MM/YYYY").includes(searchLc)
  );
};

const filterDueDate = (job, filterActive, dueDateLimitDays) => {
  if (!filterActive) return true;
  const upperLim = dayjs().add(dueDateLimitDays, "days");
  return dayjs(job.scheduled_finish_date).isBefore(upperLim);
};

export default filterProjects;
