import { Link, useParams } from "react-router-dom";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import ApprovalButton from "../../approvals/ApprovalButton";
import { ApprovalOptions } from "../../approvals/approvalTypes";
import HeaderPage from "../../header/HeaderPage";
import Profile from "../../profile";
import ProfileViewsList from "../../profileViews/ProfileViewsList";
import { DashboardH3, DashboardHeader } from "../../utils/DashboardHeader";
import CustomScaleLoader from "../../utils/scaleLoader";
import { TestSuiteTest, TestSuiteTestPages } from "../testSuiteTypes";
import ShowTestSuiteTestPage from "./ShowTestSuiteTestPage";

const TestSuiteTestProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading, setData, refreshData }: IUseApi<TestSuiteTest> =
    useApi(`test-suite-tests/${uuid}`, null, true);

  const test = data as TestSuiteTest;

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Samples",
            link: "/samples",
          },
          {
            name: test?.test_request?.name,
            link: test?.test_request?.link,
          },
          {
            name: test?.test_suite?.name,
            link: "/",
            active: true,
          },
        ]}
        titlePage={data?.test_suite?.name ?? "-"}
      />

      {loading ? (
        <CustomScaleLoader>Fetching Test....</CustomScaleLoader>
      ) : (
        <>
          <div className="row row-sm justify-content-end">
            <div className="col-lg-4 m-1">
              <ProfileViewsList
                viewable={test}
                viewable_type="App\\Models\\TestSuiteTest"
              />
            </div>
          </div>
          <Profile<TestSuiteTestPages>
            content={<ShowTestSuiteTestPage test={test} setTest={setData} />}
            header={
              <DashboardHeader>
                <DashboardH3>{test.test_suite.name}</DashboardH3>
                <p className="mb-0">
                  {!test.current_status
                    ? "Not Started"
                    : test.current_status.name}
                </p>
                {test.deliverable ? (
                  <Link to={test.deliverable.link}>
                    {test.deliverable.name}
                  </Link>
                ) : null}
              </DashboardHeader>
            }
            tabs={[
              {
                label: "Tests",
                link: `/test-suite-tests/${uuid}/tests`,
                page: "tests",
              },
            ]}
            sideBar={
              <ApprovalButton
                onSuccess={refreshData}
                approvalAction={test.approval_action}
                approvalOptions={[
                  { label: "Approve", value: ApprovalOptions.Approve },
                  { label: "Redo", value: ApprovalOptions.Redo },
                ]}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default TestSuiteTestProfile;
