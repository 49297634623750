import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import ApprovalStatus from "../user/ApprovalStatus";
import ApprovalButton from "../approvals/ApprovalButton";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import errorSwal from "../utils/errorSwal";
import formatDate from "../utils/formatDate";
import Spinner from "../utils/Spinner";
import ShowPage from "./ShowPage";
import { useAuth } from "../../context/auth-context";
import { Button } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";

const DeductionProfile = () => {
  const { uuid } = useParams();

  const history = useHistory();

  const {
    loading,
    data: deduction,
    setData: setDeduction,
  } = useApi(`deductions/${uuid}`, null, true);

  const { takeAction } = useApi();

  const deleteDeduction = () => {
    return deleteSwal()
      .then(() => takeAction("destroy", `deductions/${uuid}`))
      .then(() => {
        toast.success("Deduction Deleted");
        history.push("/deductions");
      })
      .catch(errorSwal);
  };

  const { user } = useAuth();

  const onSubmit = (values) => {
    return takeAction("update", `deductions/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Deduction Updated.");
        setDeduction(data.data);
      })
      .catch(errorSwal);
  };

  const tabs = [
    {
      link: `/deductions/${uuid}/details`,
      page: "details",
      label: "Details",
    },
    {
      link: `/deductions/${uuid}/documents`,
      page: "documents",
      label: "Documents",
    },
  ];

  const title = `${deduction?.user?.name} - ${deduction?.description}`;

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage crumbs={[]} titlePage={title} />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{title}</DashboardH3>
          </DashboardHeader>
        }
        tabs={tabs}
        content={<ShowPage onSubmit={onSubmit} deduction={deduction} />}
        sideBar={
          <>
            <ApprovalButton approvalAction={deduction.approval_action} />
            <ApprovalStatus
              className="w-100 mt-3"
              status={deduction.approval_status}
            />
            {user.is_admin && (
              <Button
                color="danger"
                className="mt-3"
                block
                outline
                onClick={deleteDeduction}
              >
                Delete Deduction
              </Button>
            )}
          </>
        }
      />
    </>
  );
};

export default DeductionProfile;
