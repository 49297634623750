import React from "react";
import InformationAlert from "../utils/InformationAlert";
import CustomScaleLoader from "../utils/scaleLoader";

const DocketTable = ({ items, notes, loadingItems }) => {
  if (loadingItems)
    return <CustomScaleLoader>Fetching Docket Items...</CustomScaleLoader>;

  if (items?.length === 0)
    return (
      <InformationAlert
        title="No Docket Items Found"
        body="No items found for the selected jobs, please ensure this is correct."
        type="warning"
        closable={false}
      />
    );

  return (
    <div className="table-responsive card">
      <table className="table">
        <thead className="thead-default">
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Units</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, key) => (
            <tr key={key}>
              <td scope="row">{item.item}</td>
              <td>{item.quantity}</td>
              <td>{item.units}</td>
            </tr>
          ))}
          {notes && (
            <tr>
              <td colSpan="3" rowSpan="4" className="valign-middle">
                <div className="invoice-notes">
                  <label className="section-label-sm tx-gray-500">Notes</label>
                  <p>
                    {notes.split("\n").map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          {item}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DocketTable;
