import useApi from "../../api/useApi";
import { IProject } from "../../projects/projectTypes";
import Add from "../Add";
import ReactTable from "../../table/ReactTable";
import { Link } from "react-router-dom";

const InterEntityInvoices = ({ project }: { project: IProject }) => {
  const { data } = useApi(
    `projects/${project.uuid}/invoices?inter_entity=true`,
    [],
  );

  return (
    <>
      <Add
        invoiceUrl="inter-entity-invoices"
        creditNoteUrl="inter-entity-credit-notes"
        project={project}
      />

      <InvoiceTable data={data} />
    </>
  );
};

const InvoiceTable = ({ data }: { data: any }) => {
  const columns = [
    {
      accessorKey: "name",
      header: "Invoice Number",
      cell: (info: any) => {
        const invoice = info.row.original;

        return <Link to={invoice.link}>{invoice.name}</Link>;
      },
    },
  ];

  return <ReactTable data={data} columns={columns} />;
};

export default InterEntityInvoices;
