import useApi from "../api/useApi";
import { Link } from "react-router-dom";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ReactTable from "../table/ReactTable";
import { useAuth } from "../../context/auth-context";

const SafetyInitiatives = () => {
  const { data, loading } = useApi("safety-initiatives", []);

  const { user } = useAuth();

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => {
        const safetyInitiative = info.row.original;
        return (
          <>
            <Link to={`/safety-initiatives/${safetyInitiative.uuid}/details`}>
              {info.getValue()}
            </Link>
            <br />
          </>
        );
      },
    },
  ];

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Safety Initiatives"
        crumbs={[
          { name: "Safety Initiatives", link: "/safety-initiatives", active: true },
        ]}
      />
      <Link to="/safety-initiatives/add" className="mb-3 btn btn-outline-primary">
        Add New
      </Link>

      <ReactTable columns={columns} data={data} loading={loading} />
    </>
  );
};

export default SafetyInitiatives;
