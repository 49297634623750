import { Link } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import ReactTable from "../table/ReactTable";
import useBranchInterEntityInvoices from "./hooks/useBranchInterEntityInvoices";

const BranchInterEntityInvoices = () => {
  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => {
        return <Link to={info.row.original.link}>{info.getValue()}</Link>;
      },
    },
  ];

  return (
    <PaginatedList
      indexHook={useBranchInterEntityInvoices}
      listName="allowanceTypes"
      originalFilters={[]}
      list={({ data }) => {
        const allowanceTypes = data as any[];

        return (
          <div className="col-12">
            <ReactTable columns={columns} data={allowanceTypes} disableSearch />
          </div>
        );
      }}
    />
  );
};

export default BranchInterEntityInvoices;
