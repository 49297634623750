import React from "react";
import useFilter from "../hooks/useFilter";
import useObserver from "../hooks/useObserver";
import usePagination from "../hooks/usePagination";
import flattenInfinitePages from "../utils/flattenInfinitePages";
import ManagerLeftTabs from "../utils/ManagerLeftTabs";
import CustomScaleLoader from "../utils/scaleLoader";

const PaginatedList = ({
  originalFilters,
  indexHook,
  indexHookArguments = [],
  list,
}) => {
  const { searchQuery, onSearch } = usePagination();

  const { stringified, filters, toggleFilter } = useFilter(originalFilters);

  const { data, isFetchingNextPage, fetchNextPage, isFetching } = indexHook(
    searchQuery + stringified,
    ...indexHookArguments,
  );

  const intersection = useObserver(fetchNextPage);

  const flattenedData = flattenInfinitePages(data);

  return (
    <>
      <div className="row">
        <div className="col-lg-8">
          <div className="d-flex align-items-center mb-3">
            <div className="search-box shadow-sm flex-grow-1 d-flex">
              <input
                autoComplete="off"
                placeholder="Search..."
                className="form-control w-100"
                type="search"
                style={{ zIndex: 1 }}
                onChange={onSearch}
              />
              <button className="btn btn-primary ms-auto" type="button">
                <i
                  className={`fa fa-${
                    isFetching ? "spinner fa-spin" : "search"
                  }`}
                />
              </button>
            </div>
          </div>
          {!data && isFetching && (
            <div className="mt-5">
              <CustomScaleLoader>Fetching Jobs...</CustomScaleLoader>
            </div>
          )}
          {list({ data: flattenedData })}
          <div id="intersection" ref={intersection} />
          {isFetchingNextPage && (
            <CustomScaleLoader>Fetching More Data...</CustomScaleLoader>
          )}
        </div>
        <div className="col-lg-4 space-y-5">
          {filters.map((filter) => {
            const items = filter.options.map((option) => {
              return {
                label: option.label,
                onClick: (e) => {
                  e.preventDefault();
                  toggleFilter(filter.name, option.value);
                },
                className: `pointer-hover ${option.selected ? "active" : ""} `,
              };
            });
            return (
              <ManagerLeftTabs
                key={filter.name}
                items={items}
                label={filter.label}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PaginatedList;
