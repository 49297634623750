import React from "react";
import { useAuth } from "../../context/auth-context";
import dayjs from "dayjs";
import { ReactComponent as Security } from "../../svgs/security.svg";
import { Link } from "react-router-dom";

const TwoFactorReminder = () => {
  const { user } = useAuth();

  return (
    <div className="signin-wrapper">
      <div className="bg-white p-5 rounded-lg border ">
        <h2 className="w-100 d-flex justify-content-center mb-5">
          <Security height={100} />
        </h2>

        <div className="text-center">
          <h5 className="text-dark">Two Factor Required</h5>
          <p className="">
            {user?.two_factor_required_at?.display_name} has set up a
            requirement for two factor authentication.
          </p>
          <p className="mb-2">
            This <span className="fw-bolder text-dark">must</span> be set up
            before{" "}
            <span className="fst-italic">
              {dayjs(
                user?.two_factor_required_at?.two_factor_enabled_at,
              ).format("DD/MM/YYYY")}
            </span>{" "}
            otherwise you will not be able to login without setting it up.
          </p>

          <p className="mb-0">Click below to set up now.</p>

          <div className="d-flex justify-content-between mt-5">
            <Link className="btn btn-outline-primary" to="/">
              Skip for now
            </Link>
            <Link className="btn btn-primary" to={`/users/${user?.uuid}/mfa`}>
              Set Up Two Factor Authentication
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorReminder;
