import { toast } from "react-toastify";
import { FormSubmitHandler, InjectedFormProps } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import Fields from "./Fields";
import { Price, PricesProps, PriceStateProps } from "./priceTypes";

type AddModalProps = PriceStateProps &
  PricesProps & {
    toggle: () => void;
    modal: boolean;
    onUpdate?: () => void;
  };

const AddModal = (props: AddModalProps) => {
  const { toggle, modal, onUpdate, setPrices, prices } = props;

  const { takeAction }: IUseApi<{}, { data: Price }> = useApi();

  const onSubmit: FormSubmitHandler<Price> = (values) => {
    const data = {
      ...values,
      chargeable_type: props.chargeableType,
      chargeable_id: props.chargeableId,
    };

    return takeAction("store", "pricing", data)
      .then(({ data }) => {
        toast.success("Price added successfully");
        toggle();
        setPrices([...prices, data.data]);
        return onUpdate?.();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      title="Add New Price"
      onSubmit={onSubmit}
      toggle={toggle}
      modal={modal}
      form="Prices"
    >
      {({ change }: InjectedFormProps) => {
        return <Fields form="Prices" {...props} change={change} />;
      }}
    </FormModal>
  );
};

export default AddModal;
