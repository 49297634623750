import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as PiggyBank } from "../../svgs/piggy_bank.svg";
import NotFound from "../404";
import HeaderPage from "../header/HeaderPage";
import ProjectOverviewDateRange from "../projects/ProjectOverviewDateRange";
import fetchDashboard from "../utils/fetchDashboard";
import CustomScaleLoader from "../utils/scaleLoader";
import SVGContainer from "../utils/SVGContainer";
import DashboardCards from "./DashboardCards";
import DashboardViews from "./DashboardViews";
import SelectDashboard from "./SelectDashboard";
import { JobStatus } from "../jobs/jobTypes";
import useFilter from "../hooks/useFilter";
import NamedFilterDropdownButton from "../utils/NamedFilterDropdownButton";
import { useAuth } from "../../context/auth-context";

const ManagementDashboard = (props) => {
  const { from, to } = props;
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState({});
  const { user } = useAuth();

  const [selectedView, setSelectedView] = useState();

  const [selected, setSelected] = useState("");

  const { stringified, filters, toggleFilter, filterCount } =
    useFilter(jobFilters);

  useEffect(() => {
    setSelectedView("");
    if (from && to && selected && selected.value) {
      setLoading(true);
      fetchDashboard(
        selected.model,
        selected.value,
        from,
        to,
        setDashboard,
        false,
        stringified,
      ).then(() => setLoading(false));
    }
  }, [from, to, selected, stringified]);

  if (!user.active_organisation.beta) return <NotFound />;

  return (
    <>
      <HeaderPage
        titlePage="Management Dashboard"
        beta
        {...props}
        crumbs={[]}
      />
      {Object.keys(dashboard).length !== 0 && (
        <div className="row">
          <div className="col-12">
            <p className="tx-inverse">
              Click on the tiles to view more information.
            </p>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-lg-8">
          <ProjectOverviewDateRange className="d-flex d-lg-none" show />
          <SelectDashboard
            selected={selected}
            className="d-lg-none"
            setSelected={setSelected}
          />
          {selected && (
            <NamedFilterDropdownButton
              name="Available Filters"
              className="d-lg-none mb-3"
              filters={filters}
              toggleFilter={toggleFilter}
              filterCount={filterCount}
            />
          )}
          {loading ? (
            <CustomScaleLoader>
              <p className="tx-inverse">Fetching Overview...</p>
            </CustomScaleLoader>
          ) : Object.keys(dashboard).length === 0 ? (
            <SVGContainer SVG={PiggyBank}>
              <p className="mt-3">Please select a dashboard!</p>
            </SVGContainer>
          ) : (
            <DashboardCards
              dashboard={dashboard}
              setSelectedView={setSelectedView}
              selectedView={selectedView}
            />
          )}
        </div>
        <div className="col-lg-4">
          <div className="mt-3 mt-lg-0">
            <ProjectOverviewDateRange className="d-none d-lg-flex" show />
          </div>
          <SelectDashboard
            selected={selected}
            className="d-none d-lg-block"
            setSelected={setSelected}
          />
          {selected && (
            <NamedFilterDropdownButton
              name="Available Filters"
              className="d-none d-lg-block"
              filters={filters}
              toggleFilter={toggleFilter}
              filterCount={filterCount}
            />
          )}
          {selected?.model === "App\\Models\\OrganisationBranch" && (
            <Link
              to={`/branches/${selected.uuid}/profit-loss`}
              className="btn btn-outline-secondary mt-3 w-100"
            >
              Profit Loss
            </Link>
          )}
        </div>
      </div>

      <DashboardViews
        from={from}
        to={to}
        selected={selected}
        view={selectedView}
        stringifiedFilters={stringified}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  from: state.projectOverviewDateRange.from,
  to: state.projectOverviewDateRange.to,
});

export default connect(mapStateToProps, {})(ManagementDashboard);

export const jobFilters = [
  {
    name: "status",
    label: "Job Status",
    multiple: true,
    options: [
      {
        label: "Pending",
        value: JobStatus.Pending,
        selected: true,
      },
      {
        label: "In Progress",
        value: JobStatus.InProgress,
        selected: true,
      },
      {
        label: "On Hold",
        value: JobStatus.OnHold,
        selected: true,
      },
      {
        label: "Completed",
        value: JobStatus.Completed,
        selected: true,
      },
      {
        label: "Invoice Requested",
        value: JobStatus.InvoiceRequested,
        selected: true,
      },
    ],
  },
];
