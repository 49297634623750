import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setStandardJobTypes } from "../../actions/standardJobTypeActions";
import { useAuth } from "../../context/auth-context";
import HEADER_LINKS from "../../data/header_links";
import { AdministrationLevels } from "../user/userTypes";
import BottomLogo from "../utils/BottomLogo";
import BasicUserNavigation from "./BasicUserNavigation";
import Header from "./Header";
import HeaderLogo from "./HeaderLogo";
import MobileLogo from "./MobileLogo";
import MobileNav from "./MobileNav";
import MobileSearch from "./MobileSearch";
import UserMenu from "./UserMenu";

const MobileHeader = (props) => {
  return (
    <>
      <div className="slim-header ht-60 ht-lg-75">
        <div className="container">
          <HeaderLogo />
          <MobileNav {...props} />
          <MobileLogo />
          <UserMenu {...props} />
        </div>
      </div>
      <div
        className={`slim-header header-brick tn-1000 ${
          props.bricks === true ? "show" : ""
        }`}
      >
        <BottomLogo />
      </div>
    </>
  );
};

const Navigation = (props) => {
  const [formattedHeaderLinks, setFormattedHeaderLinks] = useState([]);
  const { user } = useAuth();

  const isBasic =
    user?.position_administration_level === AdministrationLevels.Basic &&
    !user.is_admin;

  useEffect(() => {
    const links = HEADER_LINKS.map((link) =>
      link.id !== "registers"
        ? link
        : {
            ...link,
            links: user?.navigation,
          },
    );

    setFormattedHeaderLinks(links);
  }, [user]);

  const actualLinks = formattedHeaderLinks.map((link) => {
    return {
      ...link,
      links: link.links
        ? link.links.filter(
            (l) => !l?.beta || (l?.beta && user.active_organisation?.beta),
          )
        : null,
    };
  });

  return (
    <>
      {isBasic ? (
        <BasicUserNavigation />
      ) : (
        <>
          <MobileSearch />
          <MobileHeader headerLinks={actualLinks} {...props} />
        </>
      )}

      {isBasic ? null : <Header headerLinks={actualLinks} {...props} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  boss_token: state.localStorage.boss_token,
  bricks: state.localStorage.bricks,
});

export default connect(mapStateToProps, { setStandardJobTypes })(Navigation);
