const validate = ({ start_time, finish_time, night_shift }) => {
  const errors = {};

  if (finish_time < start_time && !night_shift)
    errors.finish_time = "Finish time must be greater than start time.";
  if (finish_time > start_time && night_shift)
    errors.finish_time =
      "When adding a night shift, Finish Time must be less than start time.";

  return errors;
};

export default validate;
