import { Field } from "redux-form";
import MentionField from "../form/MentionField";
import required from "../utils/required";
import { draftField } from "../form/formatters";
import FileList from "../utils/FileList";

const Fields = (props: any) => {
    return (
        <div className="row">
            <div className="col-12 form-group">
            <Field
                component={MentionField}
                name="comment"
                textarea
                label="Comment"
                validate={required}
                required
                spellCheck={true}
                {...draftField}
            />
            </div>
            <div className="col-12 form-group">
                <FileList {...props} files={props.files} setFiles={props.setFiles} />
            </div>
        </div>
    )
}

export default Fields;