import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import Deliverables from "./Deliverables";
import Invoice from "./Invoice";
import SentInvoices from "./SentInvoices";

const ShowPage = (props) => {
  const { match } = props;
  const { page, uuid } = match.params;

  const { user } = useAuth();

  switch (page) {
    case "documents":
      return (
        <ProfileDocuments
          {...props}
          model="App\Models\Invoice"
          documentableId={uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/invoice/${uuid}`}
          homeUrl={`invoices/${uuid}/documents`}
          noFolders
        />
      );
    case "deliverables":
      return <Deliverables {...props} />;
    case "overview":
      return <Invoice {...props} />;
    case "sent":
      return <SentInvoices {...props} />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
