import React from "react";
import useModal from "../hooks/useModal";
import AddCompetencyModal from "./AddCompetencyModal";

const AddCompetency = (props) => {
  const { className, children } = props;
  const { toggle, modal } = useModal();

  return (
    <>
      <span className={className} onClick={toggle}>
        {children}
      </span>
      <AddCompetencyModal {...props} toggle={toggle} modal={modal} />
    </>
  );
};

export default AddCompetency;
