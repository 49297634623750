import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  initialize,
  reduxForm,
  reset,
} from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import AddDocketToNameButton from "./AddDocketToNameButton";
import DeliverablePricing from "./DeliverablePricing";
import { Button } from "reactstrap";
import useModal from "../hooks/useModal";
import ContactModal from "../clients/ContactModal";
import renderToggleInput from "../utils/renderToggleInput";

const DetailsForm = (props) => {
  const { initialValues, handleSubmit, dispatch, setDeliverable, recipients } =
    props;

  const { user } = useAuth();
  const { toggle, modal } = useModal();

  const [projectContacts, setProjectContacts] = useState([]);

  const {
    data: contacts,
    setData: setContacts,
    takeAction,
    refreshData,
  } = useApi(`projects/${initialValues.project.uuid}/contacts`, [], true);

  const mapToOptionItems = (contacts) => {
    return contacts.map((contact) => ({
      label: contact.label ?? contact.name,
      value: contact.value ?? contact.contact_id,
    }));
  };

  const submit = (values) => {
    return takeAction("update", `/deliverables/${initialValues.uuid}`, values)
      .then(({ data }) => {
        dispatch(initialize("deliverableDetails", { ...data.data }));
        setDeliverable(data.data);
        return toast.success(`${values.name} updated successfully`);
      })
      .catch((err) => {
        return errorSwal(err);
      });
  };

  const onNewClientSubmit = (values, dispatch) => {
    return takeAction(
      "store",
      `organisation-clients/${initialValues.client.uuid}/contacts`,
      values,
    )
      .then(({ data }) => {
        return takeAction("store", "project-contacts", {
          project_id: initialValues.project.id,
          contact_id: data.data.id,
        });
      })

      .then(({ data }) => {
        setContacts([...contacts, data.data]);
        props?.change?.(
          "recipients",
          mapToOptionItems([
            ...recipients,
            {
              label: data.data.name,
              value: data.data.contact_id,
            },
          ]),
        );
        toast.success("Contact Added");
        toggle();
        dispatch(reset("ProjectContacts"));
        refreshData();
      })
      .catch(errorSwal);
  };

  useEffect(() => {
    if (contacts?.length !== 0) {
      setProjectContacts(mapToOptionItems(contacts));
    }
  }, [contacts]);

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className="col-lg-9 form-group">
            <Field
              name="name"
              component={renderField}
              required
              label="Name"
              validate={required}
            />
          </div>
          <div className="col-lg-3 form-group">
            <Field
              name="is_progress_claim"
              component={renderToggleInput}
              label="Is Progress Claim"
              information="When checked, the job will not be required to be marked as 'complete' before invoicing the deliverable."
            />
          </div>
          <AddDocketToNameButton {...props} job={initialValues.job} />
          <div className="col-lg-12 form-group">
            <Field
              name="purchase_order"
              component={renderField}
              label="Line Item Prefix"
              placeholder="Leave blank if no prefix required."
            />
            <small>
              Add in a note that will be prepended to all line items.
            </small>
          </div>

          <div className="col-lg-6 form-group">
            <Field
              name="recipients"
              multiple
              options={projectContacts}
              component={SelectInputAsync}
              label="Recipients"
            />
            <div className="d-flex flex-column">
              <small className="form-text text-muted">
                Please select the people who you want to send this to.
              </small>
              <small>
                <span className="d-flex align-items-center gap-1">
                  <Button
                    type="button"
                    onClick={toggle}
                    color="link"
                    className="p-0 fs-6"
                  >
                    Click here
                  </Button>
                  <span>to add a new contact.</span>
                </span>
              </small>
            </div>
          </div>

          <div className="col-lg-6 form-group">
            <Field
              name="contacts"
              multiple
              url={`/users/staff`}
              component={SelectInputAsync}
              label="Contacts"
            />
            <small className="form-text text-muted">
              Please select the people from{" "}
              {user.active_organisation.display_name} who you want to act as
              contacts for this.
            </small>
          </div>

          <div className="col-12">
            <DeliverablePricing {...props} />
          </div>
          <div className="form-group col-lg-12 mg-b-10">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
      <ContactModal
        toggle={toggle}
        modal={modal}
        title="Add new Contact"
        form="AddClientContact"
        onSubmit={onNewClientSubmit}
      />
    </>
  );
};

const form = reduxForm({
  form: "deliverableDetails",
  enableReinitialize: true,
});

const selector = formValueSelector("deliverableDetails");

const mapStateToProps = (state) => {
  return {
    recipients: selector(state, "recipients"),
  };
};

export default connect(mapStateToProps, {})(form(DetailsForm));
