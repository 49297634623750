import React from "react";

const ApprovalAlert = ({ approvalAction, approval }) => {
  if (!approvalAction) return null;

  return (
    <div className="alert alert-warning" role="alert">
      {approvalAction.action_type === "Approve" ? (
        <>
          Work submitted by:{" "}
          <a href={`mailto:${approval.user.email}`}>{approval.user.name}</a>
          <br />
          Approval required by:{" "}
          <UserEmailLink approvalAction={approvalAction} />
        </>
      ) : (
        <>
          Work rejected by:{" "}
          <a href={`mailto:${approvalAction.previous_action.user.email}`}>
            {approvalAction.previous_action.user.name}
          </a>
          <br />
          Work needs to be redone by:{" "}
          <UserEmailLink approvalAction={approvalAction} />
        </>
      )}
    </div>
  );
};

const UserEmailLink = ({ approvalAction }) => (
  <a href={`mailto:${approvalAction.user.email}`}>{approvalAction.user.name}</a>
);

export default ApprovalAlert;
