import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import errorSwal from "../utils/errorSwal";
import { useAuth } from "../../context/auth-context";

const Index = () => {
  const { data, takeAction, loading } = useApi(
    "super-users",
    [],
    true,
    "/home",
  );

  const { setUser } = useAuth();

  const history = useHistory();

  const loginAs = (user) => {
    return takeAction("store", `super-users/${user.uuid}`)
      .then(({ data }) => {
        setUser(data.data);

        history.push("/home");
        return;
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => {
        return (
          <Button color="link" onClick={() => loginAs(info.row.original)}>
            {info.getValue()}
          </Button>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",
    },
  ];

  return <ReactTable data={data} columns={columns} loading={loading} />;
};

export default Index;
