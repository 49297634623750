import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { Field, getFormValues } from "redux-form";
import FieldType from "../../enums/FieldType";
import SelectInput from "../form/SelectInput";
import buttonGroup from "../utils/buttonGroup";
import FormHeader from "../utils/FormHeader";
import renderField from "../utils/renderField";
import required from "../utils/required";

const CustomFieldConditions = ({
  fields,
  formValues,
  index,
  field,
  setSelectedConditional,
}) => {
  const parentFields = formValues?.custom_fields
    .map((customField, index) => {
      return {
        label: customField?.field_attributes?.label ?? null,
        value: customField?.uuid,
        ...customField,
      };
    })
    .filter((_, i) => i !== index);

  const parentField = field;

  return (
    <>
      <FormHeader extra={<LogicalOperator field={field} />}>
        Conditions
      </FormHeader>
      {fields.map((field, index) => {
        const value = fields.get(index);

        const selectedField = parentFields?.find(
          (parentField) => parentField.value === value.field,
        );

        return (
          <React.Fragment key={index}>
            <div
              className="col-lg-4 form-group"
              onClick={(e) => e.stopPropagation()}
            >
              <Field
                component={SelectInput}
                name={`${field}.field`}
                label="Field"
                options={parentFields}
                validate={required}
                required
                onFocus={(e) => {
                  setSelectedConditional({
                    parent_field: parentField,
                    field: `${field}.field`,
                  });
                }}
                onBlur={() => setSelectedConditional(null)}
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={SelectInput}
                name={`${field}.condition`}
                label="Condition"
                options={[
                  {
                    label: "Is",
                    value: "==",
                  },
                  {
                    label: "Is Not",
                    value: "!=",
                  },
                ]}
                validate={required}
                required
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={
                  selectedField?.type === FieldType.Toggle
                    ? SelectInput
                    : renderField
                }
                name={`${field}.value`}
                label="Value"
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                validate={required}
                required
              />
            </div>
            <div className="col-lg-2 form-group">
              <Button
                color="danger"
                className="mt-label"
                block
                outline
                onClick={() => fields.remove(index)}
              >
                Delete
              </Button>
            </div>
          </React.Fragment>
        );
      })}

      <div className="form-group col-12">
        <Button
          color="secondary"
          outline
          onClick={() => fields.push({ condition: "==" })}
        >
          Add Condition
        </Button>
      </div>
    </>
  );
};

const LogicalOperator = ({ field }) => {
  return (
    <div>
      <label className="form-control-label tx-inverse tx-semibold d-block">
        Operator
      </label>
      <Field
        component={buttonGroup}
        options={[
          { label: "OR", value: "some" },
          { label: "AND", value: "every" },
        ]}
        name={`${field}.field_attributes.logical_operator`}
        validate={required}
        required
      />
    </div>
  );
};

const mapStateToProps = (state, { form }) => ({
  formValues: getFormValues(form)(state),
});

export default connect(mapStateToProps)(CustomFieldConditions);
