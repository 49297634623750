import { toArray } from "./toArray";

const FieldType = {
  Text: 0,
  Select: 1,
  Toggle: 2,
  File: 3,
  Constant: 4,
  Date: 6,
  Formula: 7,
  RadioButton: 8,
  StarRating: 9,
  FieldArray: 10,
  Signature: 11,
  //Missing 12 as it's a moisture.
  CustomValue: 13,
  Chart: 14,
  Time: 15,
  Separator: 17
};

export const fieldTypes = toArray(FieldType);

export default FieldType;
