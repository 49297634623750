import React from "react";
import NotFound from "../404";
import Edit from "./Edit";
import CompetencySkillsList from "../competencySkills/List";
import { useParams } from "react-router-dom";

const ShowPage = (props) => {
  const { page } = useParams();
  const { category } = props;

  switch (page) {
    case "details":
      return <Edit initialValues={category} {...props} />;
    case "skills":
      return <CompetencySkillsList {...props} />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
