import React from "react";
import { Button } from "reactstrap";
import JobStatus from "../../../enums/JobStatus";
import useModal from "../../hooks/useModal";
import AddUnderstandingModal from "./AddUnderstandingModal";
import { CustomJobJob } from "./customJobTypes";

interface AddUnderstandingButtonProps {
  job: CustomJobJob;
  setJob: (job: CustomJobJob) => void;
}

const AddUnderstandingButton = (props: AddUnderstandingButtonProps) => {
  const { job } = props;

  const { toggle, modal } = useModal();

  if (!job.must_be_understood || job.status != JobStatus.Completed) {
    return null;
  }

  return (
    <>
      <Button onClick={toggle} color="link" className="p-0 mt-3">
        Add Sign In
      </Button>
      <AddUnderstandingModal toggle={toggle} modal={modal} {...props} />
    </>
  );
};

export default AddUnderstandingButton;
