import React from "react";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";

const ProfileTabs = ({ match, docket }) => {
  const { uuid, page } = match.params;
  return (
    <TabList>
      <Tab
        link={`/dockets/${uuid}/details`}
        active={page === "details"}
        icon="fa fa-file-text-o"
      >
        Details
      </Tab>
      {docket.signature && (
        <Tab
          link={`/dockets/${uuid}/signature`}
          active={page === "signature"}
          icon="fa fa-folder-open-o"
        >
          Signature
        </Tab>
      )}
    </TabList>
  );
};

export default ProfileTabs;
