import { useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Field,
  FormSubmitHandler,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import AddressFields from "../addresses/AddressFields";
import useApi from "../api/useApi";
import AddMultiple from "../form/AddMultiple";
import { OrganisationSetting } from "../organisation/types";
import AbnField from "../utils/AbnField";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import ClientInformation from "./ClientInformation";

interface ClientSignUpFormProps {
  setting: OrganisationSetting;
  abnHolder: boolean;
}

const ClientSignUpForm = (
  props: InjectedFormProps<{}, ClientSignUpFormProps> & ClientSignUpFormProps,
) => {
  const { takeAction } = useApi();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { handleSubmit, setting, abnHolder } = props;

  const onSubmit: FormSubmitHandler<any, ClientSignUpFormProps> = (values) => {
    return takeAction("store", "organisation-clients", values)
      .then(({ data }: { data: { data: { uuid: string } } }) => {
        toast.success("Client Added");
        history.push(`/clients/${data.data.uuid}/details`);
        return queryClient.invalidateQueries(["clients"]);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <ClientInformation
          {...props}
          add
          disableName={abnHolder && setting?.meta_value === "0"}
        />
        {!setting?.meta_value ? (
          <>
            {abnHolder ? (
              <>
                <div className="col-lg-3 form-group">
                  <AbnField {...props} />
                </div>
                <div className="col-lg-3 form-group">
                  <Field component={RenderField} name="acn" label="ACN" />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    name="trading_name"
                    label="Trading Name"
                  />
                </div>

                <div className="col-lg-6 form-group">
                  <Field
                    component={AddMultiple}
                    name="accounts_email"
                    label="Accounts Email/s"
                    validate={required}
                    required
                  />
                </div>
                <div className="col-12 form-group">
                  <Field
                    component={RenderField}
                    textarea
                    name="description"
                    label="Description"
                  />
                </div>
              </>
            ) : null}

            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name="contact_telephone"
                label="Contact Phone"
                validate={required}
                required
              />
            </div>

            <AddressFields {...props} postal />
          </>
        ) : null}
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<{}, ClientSignUpFormProps>({});

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    abnHolder: selector(state, "abn_holder"),
  };
};

export default connect(mapStateToProps, {})(form(ClientSignUpForm));
