import React from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../../api/api";

/**
 *
 * @param {import("../../../types/typedefs").LikeQuery} config
 */
export default function useCreateLike(config) {
  const queryData = useQueryClient();

  return useMutation((values) => api.post("likes", config), {
    onSuccess: (data, variables, context) => {
      queryData.setQueryData(["likes", { ...config }], [data]);
    },
  });
}
