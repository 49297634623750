import HeaderPage from "../header/HeaderPage";
import ModalButton from "./ModalButton";
import Notices from "./Notices";

const Noticeboard = () => {
  return (
    <>
      <HeaderPage
        titlePage="Noticeboard"
        pageDescription="See Updates From Your Colleagues."
        crumbs={[]}
      />
      <div className="mb-3 d-flex align-items-center">
        <div className="ms-auto">
          <ModalButton className="" />
        </div>
      </div>
      <Notices />
    </>
  );
};

export default Noticeboard;
