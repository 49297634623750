import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import { Timesheet } from "./timesheetTypes";

interface FormValues {
  pay_run_id: number;
}

const AddToPayRun = ({
  timesheet,
  onSuccess,
}: {
  timesheet: Timesheet;
  onSuccess: () => void;
}) => {
  const { takeAction } = useApi();
  const { modal, toggle } = useModal();
  const { user } = useAuth();

  const onSubmit = (values: FormValues) => {
    const value = {
      ...values,
      user_id: timesheet.user_id,
      timesheet_id: timesheet.id,
    };

    return takeAction("store", "pays", value)
      .then(() => {
        toggle();
        toast.success(`Successfully added to Pay Run.`);
        onSuccess();
      })
      .catch(errorSwal);
  };

  if (
    timesheet?.pay_id ||
    timesheet.approval_status.status !== "Approved" ||
    !user?.is_admin
  ) {
    return null;
  }

  return (
    <>
      <button className="btn btn-outline-primary w-100 mt-3" onClick={toggle}>
        Add to Pay Run
      </button>
      <FormModal
        onSubmit={onSubmit}
        title="Select a Pay Run"
        modal={modal}
        toggle={toggle}
        form="AddToPayRun"
      >
        <div className="form-group col-12">
          <Field
            component={SelectInput}
            name="pay_run_id"
            label="Pay Run"
            required
            validate={required}
            url="incomplete-pay-runs"
            formatData={(data: any) =>
              data.map((d: any) => ({
                value: d.id,
                label: `Pay Run - ${dayjs(d.start_date)
                  .format("DD/MM/YYYY")
                  .concat(" to ", dayjs(d.finish_date).format("DD/MM/YYYY"))} ${
                  d.branches.length > 0
                    ? `(${d.branches.map((b: any) => b.name).join(", ")})`
                    : ""
                }`,
              }))
            }
          />
        </div>
      </FormModal>
    </>
  );
};

export default AddToPayRun;
