import React from "react";
import { Button } from "reactstrap";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import fileDownload from "js-file-download";
import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import isSubmitting from "../../utils/submitting";

dayjs.extend(localizedFormat);

const TestStatusDump = () => {
  const { takeAction, loading } = useApi();

  const generateDump = () => {
    return takeAction("store", "test-status-dump")
      .then(({ data }) => {
        fileDownload(data, `Test Dump ${dayjs().format("DD-MM-YYYY, LT")}.csv`);
      })
      .catch(errorSwal);
  };

  return (
    <Button
      disabled={loading}
      color="primary"
      onClick={generateDump}
      outline
      className="ms-3 mt-1"
      size="sm"
      primary
    >
      {isSubmitting(
        loading,
        "Download Test Statuses",
        "Generating Test Statuses...",
      )}
    </Button>
  );
};

export default TestStatusDump;
