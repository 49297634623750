import React, { useState, useEffect } from "react";
import axios from "../api/api";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import NOTIFICATIONS from "../../data/notifications";
import NotificationSetting from "./NotificationSetting";

const NotificationSettings = (props) => {
  const [loading, setLoading] = useState(true);

  const [userNotifications, setUserNotifications] = useState([]);

  useEffect(() => {
    axios
      .get(`/unsubscribed-notifications`)
      .then(({ data }) =>
        setUserNotifications(
          NOTIFICATIONS.map((notification) => {
            return {
              ...notification,
              is_unsubscribed: data.data.find(
                ({ notification: unsubscribed }) =>
                  unsubscribed === notification.name,
              ),
            };
          }),
        ),
      )
      .catch(errorSwal)
      .then(() => setLoading(false));
  }, []);

  if (loading) return <Spinner loading />;

  return (
    <div className="row">
      {userNotifications.map((notification) => {
        return (
          <NotificationSetting
            key={notification.name}
            notification={notification}
            userNotifications={userNotifications}
            setUserNotifications={setUserNotifications}
          />
        );
      })}
    </div>
  );
};

export default NotificationSettings;
