import swal from "sweetalert";

const deleteSwal = (name, text) =>
  new Promise((resolve) => {
    swal({
      title: "Delete?",
      text: text || `Are you sure you want to delete${name ? ` ${name}` : ""}?`,
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          closeModal: true,
          visible: true,
        },
        delete: {
          text: "Delete",
          value: true,
          closeModal: true,
          className: "btn btn-danger align-baseline",
        },
      },
    }).then((remove) => {
      if (remove) {
        resolve();
      }
    });
  });
export default deleteSwal;
