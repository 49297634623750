import React from "react";
import FormModal from "../utils/FormModal";
import { Field } from "redux-form";
import RenderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";

const Modal = (props) => {
  return (
    <FormModal {...props}>
      <>
        <div className="col-12 form-group">
          <Field
            component={SelectInputAsync}
            url="/enums?enum=Sentiment"
            name="sentiment"
            label="Sentiment"
            formatData={formatData}
            order={false}
            required
            validate={required}
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="comment"
            label="Feedback"
            textarea
            required
            validate={required}
          />
        </div>
      </>
    </FormModal>
  );
};

const formatData = (data) => data.data.map((d) => d);

export default Modal;
