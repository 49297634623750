import dayjs from "dayjs";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import deleteSwal from "../utils/deleteSwal";

export interface IToken {
  name: string;
  id: string;
  created_at: string;
  last_used_at?: string;
  token?: string;
}

export interface UserTokensProps {
  tokens: IToken[];
  setTokens: (tokens: IToken[]) => void;
}

const UserTokens = () => {
  const { takeAction }: IUseApi = useApi();

  const deleteToken = (token: IToken) => {
    return deleteSwal(token.name)
      .then(() => takeAction("destroy", `users/tokens/${token.id}`))
      .then(() => {
        toast.success("Token deleted");
        setTokens(tokens?.filter((t) => t.id !== token.id) ?? []);
      })
      .catch(errorSwal);
  };

  const {
    data: tokens,
    setData: setTokens,
    loading,
  }: IUseApi<IToken[]> = useApi("users/tokens", [], true);

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "created_at",
      header: "Name",
      cell: (info: any) => {
        const token = info.row.original;
        return (
          <div>
            <p className="mb-1 ">
              Created{" "}
              <span className="fw-bolder tx-inverse">
                {dayjs(info.getValue()).format("DD/MM/YYYY hh:mm a")}
              </span>
            </p>
            <p className="mb-0 tx-10">
              {token.last_used_at
                ? `Last used at ${dayjs(token.last_used_at).format(
                    "DD/MM/YYYY hh:mm a",
                  )}`
                : "Token Unused"}
            </p>
          </div>
        );
      },
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: (info: any) => {
        return (
          <DeleteButton deleteFunction={() => deleteToken(info.row.original)} />
        );
      },
    },
  ];

  return (
    <>
      <ReactTable columns={columns} data={tokens ?? []} loading={loading} />
    </>
  );
};

export default UserTokens;
