import { Field, FieldArray, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { money } from "../form/formatters";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import calibrations from "./Calibrations";

const FormEquipmentType = (props) => {
  const { initialValues, onDelete, handleSubmit } = props;
  const { user } = useAuth();

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            name="type"
            type="text"
            validate={required}
            required
            component={renderField}
            label="Type"
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            name="group"
            validate={required}
            required
            url="/equipment-type-groups"
            component={SelectInputAsync}
            label="Group"
            formatData={(data) =>
              data.data.map((d) => ({
                label: d.name,
                value: d.id,
              }))
            }
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            name="replacement_value"
            type="text"
            component={renderField}
            label="Replacement Value"
            {...money}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="rate"
            type="text"
            component={renderField}
            label="Rate"
            {...money}
          />
          <small>
            Rate is how much the equipment type will cost per day to rent. Use
            this in conjunction with project equipment to get an overview of the
            cost of a project.
          </small>
        </div>

        <div className="col-lg-12 form-group">
          <Field
            name="remarks"
            textarea
            component={renderField}
            label="Remarks"
          />
        </div>
        <div className="form-group col-lg-12">
          <FieldArray
            initialValues={initialValues}
            component={calibrations}
            name="calibration_types"
            {...props}
          />
        </div>

        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>

        {!initialValues !== undefined && user.is_admin && (
          <div className="form-group col-lg-3">
            <button
              className="btn btn-outline-danger form-control"
              onClick={() => {
                onDelete(initialValues.uuid);
              }}
              type="button"
            >
              <i className="fa fa-trash mg-r-5" /> Delete
            </button>
          </div>
        )}
      </div>
    </form>
  );
};
const form = reduxForm({ form: "EquipmentType" });

export default form(FormEquipmentType);
