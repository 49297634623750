import { InjectedFormProps } from "redux-form";
import FormModal from "../utils/FormModal";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { useHistory } from "react-router-dom";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import { Branch } from "./branchTypes";
import AddTenderFields from "../tenders/AddTenderFields";

const AddBranchTenderModal = ({
  branch,
  toggle,
  modal,
}: {
  branch: Branch;
  toggle: () => void;
  modal: boolean;
}) => {
  const { takeAction }: IUseApi = useApi();
  const history = useHistory();

  const onSubmit = (values: any) => {
    return takeAction("store", "tenders", values)
      .then(({ data }) => {
        history.push(`${data.data.link}`);
        toast.success("Tender created successfully");
      })
      .catch(formError);
  };

  return (
    <FormModal
      form="AddBranchTender"
      onSubmit={onSubmit}
      toggle={toggle}
      modal={modal}
      title={`Add Tender to ${branch.name}`}
      initialValues={{
        job: {
          branch_id: branch.id,
        },
      }}
    >
      {(props: InjectedFormProps) => (
        <AddTenderFields showProject={true} showSave={false} {...props} />
      )}
    </FormModal>
  );
};

export default AddBranchTenderModal;
