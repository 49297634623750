import _ from "lodash";
import { useParams, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import getFileIcon from "../utils/getFileIcon";
import { useCreateZip } from "../../context/createZip-context";
import isSubmitting from "../utils/submitting";

const isImage = (mimeType) => mimeType.substr(0, 5) === "image";

const ExternalSharedDocumentListItem = (props) => {
  const {
    doc,
    toggle,
    selectedFolder,
    setSelectedFolder,
    setSelectedDocument,
  } = props;

  const { uuid } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { creatingZip, createZipEvent } = useCreateZip();
  const urlParams = new URLSearchParams(window.location.search);

  return (
    <div
      onDoubleClick={(e) => {
        if (!doc.is_folder) {
          e.preventDefault();
          setSelectedDocument(doc);
          toggle();
          return;
        }
        history.push(`/shared-files/${uuid}/${doc.uuid}${location?.search}`);
      }}
      color="link"
      className="ms-auto p-0"
    >
      <div id={doc.uuid} className="file-item">
        <div className="row no-gutters wd-100p" style={{ flexFlow: "row" }}>
          <div className="col-9 col-sm-9 d-flex align-items-center">
            <File doc={doc} />
          </div>
          <div className="">
            {doc.is_folder ? (
              <button
                onClick={() => {
                  setSelectedFolder(doc);
                  return createZipEvent(
                    `external-shared-files/download/${uuid}`,
                    {
                      parent_uuid: doc.uuid,
                      email: urlParams.get("email"),
                    },
                  );
                }}
                disabled={creatingZip}
                color="link"
                className="ms-auto btn p-0"
              >
                {isSubmitting(
                  doc.uuid === selectedFolder?.uuid && creatingZip,
                  "Zip and Download",
                  "Zipping...",
                )}
              </button>
            ) : (
              <button
                onClick={() => window.location.replace(doc.link)}
                color="link"
                className="ms-auto btn p-0"
              >
                Download
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const File = ({ doc }) => {
  return (
    <>
      <Icon doc={doc} />
      <div>
        <p className="tx-inverse text-start" style={{ marginBottom: "-6px" }}>
          {doc.name}
        </p>
      </div>
    </>
  );
};

const Icon = ({ doc }) => {
  if (isImage(doc.mime_type))
    return (
      <img
        src={doc.link}
        height="25px"
        width="50px"
        style={{ marginRight: "10px" }}
      />
    );

  return <i className={getFileIcon(doc.name, doc.is_folder)} />;
};

export default ExternalSharedDocumentListItem;
