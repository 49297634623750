import { FormSubmitHandler, InjectedFormProps, reduxForm } from "redux-form";
import ProjectForm from "../projects/ProjectForm";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { TodoTask } from "./todoTypes";
import { Link, useParams } from "react-router-dom";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";
import { ConvertToWorkFormProps } from "./ConvertToJobModal";
import { toast } from "react-toastify";

const ConvertToProjectForm = (
  props: InjectedFormProps<any, ConvertToWorkFormProps> &
    ConvertToWorkFormProps,
) => {
  const { handleSubmit, setData, toggle } = props;

  const { taskUuid } = useParams<{ taskUuid: string }>();

  const { takeAction }: IUseApi<{}, { data: TodoTask }> = useApi();

  const onSubmit: FormSubmitHandler<any, ConvertToWorkFormProps> = (values) => {
    return takeAction(
      "store",
      `to-do-tasks/${taskUuid}/convert/project`,
      values,
    )
      .then(({ data }) => {
        setData(data.data);
        toggle();
        toast.success(
          <div className="d-flex">
            <p className="mb-0">Todo Converted</p>
            <Link className="ms-auto" to={`/${data.data.work?.link}` ?? ""}>
              View Project
            </Link>
          </div>,
        );
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormErrorAlert error={props.error} />
      <ProjectForm {...props} adding={true} />
    </form>
  );
};

const form = reduxForm<any, ConvertToWorkFormProps>({
  form: "CONVERT_TO_PROJECT",
});

export default form(ConvertToProjectForm);
