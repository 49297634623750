import { Field } from "redux-form";
import useClientContacts from "../clients/hooks/useClientContacts";
import CreatableSelectInput from "../form/CreatableSelectInput";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import renderToggleInput from "../utils/renderToggleInput";
import useApi from "../api/useApi";

const SendModal = (props) => {
  const { data } = useClientContacts(props.invoice.client.uuid);

  const { data: users } = useApi("users?filter[employed]=true", []);

  const formattedUsers = users.map((user) => ({
    label: user.secondary_email ?? user.email,
    value: user.secondary_email ?? user.email,
  }));

  return (
    <FormModal {...props}>
      <div className="col-lg-6 form-group">
        <Field
          validate={required}
          required
          component={CreatableSelectInput}
          name="to"
          label="Send Invoice To"
          isMulti={true}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={CreatableSelectInput}
          name="cc"
          label="cc"
          isMulti={true}
          options={[
            ...(data?.map((contact) => ({
              label: `${contact.name} ${
                !contact.email ? "(No email found)" : ""
              }`,
              value: contact.email,
              disabled: !contact.email,
            })) ?? []),
            ...formattedUsers,
          ]}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={renderToggleInput}
          name="send_attachments"
          label="Send Uploaded Documents as Attachments"
        />
      </div>
    </FormModal>
  );
};

export default SendModal;
