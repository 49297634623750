import React from "react";
import { Field, reduxForm } from "redux-form";

import renderField from "../utils/renderField";
import required from "../utils/required";
import AddressFields from "../addresses/AddressFields";
import SubmitButton from "../utils/SubmitButton";
import NumberField from "../utils/NumberField";
import SelectInputAsync from "../utils/SelectInputAsync";

const FormTrackingSite = (props) => {
  const { handleSubmit, deleteButton } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="form-group col-lg-6">
          <Field
            validate={required}
            required
            name="name"
            type="text"
            component={renderField}
            label="Name"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            label="Manager"
            name="manager_id"
            component={SelectInputAsync}
            url="/users/staff"
            validate={required}
            required
          />
        </div>
        <div className="form-group col-lg-12">
          <NumberField {...props} />
        </div>

        <div className="form-group col-lg-12">
          <Field
            validate={required}
            required
            name="phone"
            type="text"
            component={renderField}
            label="Phone"
          />
        </div>

        <AddressFields postal {...props} />

        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
        {deleteButton}
      </div>
    </form>
  );
};

export default reduxForm()(FormTrackingSite);
