import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import EditTopic, { EditTopicProps } from "./EditTopic";
import TopicPositions from "./TopicPositions";
import { TopicProfilePages } from "./TopicProfile";
import TopicSubjects from "./TopicSubjects";

const ShowTopicPage = (props: EditTopicProps) => {
  const { page } = useParams<{ page: TopicProfilePages }>();
  const { user } = useAuth();

  switch (page) {
    case "subjects":
      return <TopicSubjects {...props} />;
    case "details":
      return user?.hasAccessTo("App\\Models\\Topic", "update") ? (
        <EditTopic {...props} />
      ) : (
        <NotFound />
      );
    case "positions":
      return user?.hasAccessTo("App\\Models\\Topic", "update_positions") ? (
        <TopicPositions {...props} />
      ) : (
        <NotFound />
      );
    default:
      return <NotFound />;
  }
};

export default ShowTopicPage;
