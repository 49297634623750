import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { reduxForm, Field, submit, Form } from "redux-form";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

const RenameModal = (props) => {
  const { toggle, modal, handleSubmit, document, dispatch, form, documents } =
    props;
  const { takeAction } = useApi();

  const queryClient = useQueryClient();

  const rename = (values) => {
    return takeAction("update", `documents/standard/${document.uuid}`, values)
      .then(({ data }) => {
        queryClient.invalidateQueries("standard-documents");

        toast.success("Document renamed.");
      })
      .then(toggle)
      .catch(errorSwal);
  };

  return (
    <Modal
      className="wd-md-800"
      isOpen={modal}
      toggle={toggle}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <ModalHeader toggle={toggle}>Rename {document.name}</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(rename)}>
          <Field component={RenderField} name="name" label="name" />
        </Form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton {...props} onClick={() => dispatch(submit(form))} />
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm();
export default form(RenameModal);
