// @ts-nocheck
//Need the no check in order for blur to work correctly...
import dayjs from "dayjs";
import { FocusEventHandler } from "react";
import { WrappedFieldProps } from "redux-form";
import FormHeader from "../utils/FormHeader";

const MoistureField = (
  props: WrappedFieldProps & {
    label: string;
    required: boolean;
    change: (form: string, value: any) => void;
    onBlur?: FocusEventHandler;
  },
) => {
  const { input, label, required, change } = props;

  return (
    <div className="row">
      <FormHeader>
        {label} {input.value?.completed_at ? "(Completed)" : ""}
      </FormHeader>
      <div className="col-lg-12 form-group">
        <Label required={required} field="Tin" />
        <input
          name={`${input.name}.container_id`}
          className="form-control"
          value={input.value?.container_id}
          onChange={(e) => change(`${input.name}.container_id`, e.target.value)}
          onBlur={() => input.onBlur()}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Label required={required} field="Mass of Tin" />
        <input
          name={`${input.name}.mass_of_tin`}
          className="form-control"
          type="number"
          step="0.01"
          value={input.value?.mass_of_tin}
          onChange={(e) => change(`${input.name}.mass_of_tin`, e.target.value)}
          onBlur={() => input.onBlur()}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Label required={required} field="Mass of Tin and Wet Soil" />
        <input
          name={`${input.name}.mass_of_tin_and_wet_soil`}
          className="form-control"
          type="number"
          step="0.01"
          value={input.value?.mass_of_tin_and_wet_soil}
          onChange={(e) =>
            change(`${input.name}.mass_of_tin_and_wet_soil`, e.target.value)
          }
          onBlur={() => input.onBlur()}
        />
      </div>
      {input.value?.dry_moisture_masses?.map((d: any, i: number) => {
        return (
          <>
            <div className="col-lg-12 form-group">
              <Label field={`Dry Moisture Mass ${i + 1}`} />
              <p className="mb-0">{d.mass}</p>
            </div>
            <div className="col-lg-12 form-group">
              <Label field={`Dry Moisture ${i + 1} Taken at`} />
              <p className="mb-0">
                {dayjs(d.created_at).format("DD/MM/YYYY HH:mm")}
              </p>
            </div>
          </>
        );
      })}
    </div>
  );
};

const Label = (props: { required?: boolean; field: string }) => {
  const { required, field } = props;

  return (
    <label className="form-control-label tx-inverse tx-semibold">
      {field}
      {required ? <span className="tx-danger"> *</span> : ""}
    </label>
  );
};

export default MoistureField;
