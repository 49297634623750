import { useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Avatar from "../utils/Avatar";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import { SupplierIndex } from "./supplierTypes";

const SupplierTable = ({ suppliers }: { suppliers: SupplierIndex[] }) => {
  const columnHelper = createColumnHelper<SupplierIndex>();

  const columns = useMemo<ColumnDef<SupplierIndex, any>[]>(
    () => [
      columnHelper.accessor("display_name", {
        header: "Name",
        cell: (props) => {
          const supplier = props.row.original;

          return (
            <div className="d-flex align-items-center w-100">
              <div>
                <Avatar name={supplier.display_name} colour="info" />
              </div>
              <div className="ms-1">
                <p className="mb-0 fw-bolder tx-inverse no-wrap">
                  <Link to={supplier.link}>{supplier.display_name}</Link>
                </p>
              </div>
            </div>
          );
        },
      }),

      columnHelper.accessor("type", {
        header: "Type",
        cell: (info) => info.getValue() ?? "-",
      }),
      columnHelper.accessor("abn_holder", {
        header: "ABN Holder",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
      }),

      columnHelper.accessor("contact_email", {
        header: "Contact Email",
        cell: (info) => info.getValue() ?? "-",
      }),

      columnHelper.accessor("accounts_email", {
        header: "Accounts Email",
        cell: (info) => info.getValue() ?? "-",
      }),

      columnHelper.accessor("contact_telephone", {
        header: "Contact Telephone",
        cell: (info) => info.getValue() ?? "-",
      }),
      columnHelper.accessor("is_hold", {
        header: "Status",
        cell: (info) => {
          const supplier = info.row.original;

          const color = supplier.is_hold ? "bg-danger" : "bg-info";

          const text = supplier.is_hold ? "Hold" : "Open";

          return (
            <div
              className={`${color} shadow rounded-pill text-white tx-10 text-center px-2`}
            >
              {text}
            </div>
          );
        },
      }),
    ],
    [suppliers],
  );
  return (
    <ReactTable<SupplierIndex>
      disableSearch
      columns={columns}
      data={suppliers}
    />
  );
};

export default SupplierTable;
