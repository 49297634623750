import { Field, FieldArray, InjectedFormProps, reduxForm } from "redux-form";
import { Loan, Purchase } from "./purchaseTypes";
import RenderField from "../utils/renderField";
import { money, percentage } from "../form/formatters";
import required from "../utils/required";
import FormHeader from "../utils/FormHeader";
import LoanFees from "./LoanFees";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";
import AsyncSelectInput from "../form/AsyncSelectInput";
import api from "../api/api";
import { XeroContact } from "../xero/xeroTypes";

export interface LoanFormProps {
  purchase: Purchase;
}

const LoanForm = (
  props: InjectedFormProps<Loan, LoanFormProps> & LoanFormProps,
) => {
  const { handleSubmit, initialValues } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <FormHeader>Loan Details</FormHeader>
        <div className="col-lg-12 form-group">
          <Field
            component={AsyncSelectInput}
            name="contact_id"
            label="Contact"
            validate={required}
            required
            defaultOptions={
              initialValues?.contact_id
                ? [
                    {
                      label: initialValues.contact?.Name,
                      value: initialValues.contact?.ContactID,
                    },
                  ]
                : undefined
            }
            asyncFunction={(inputValue: string) =>
              api.get(`/xero/contacts?term=${inputValue}`).then(({ data }) =>
                data.data.map((d: XeroContact) => ({
                  label: d.Name,
                  value: d.ContactID,
                })),
              )
            }
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="amount"
            label="Amount"
            {...money}
            validate={required}
            required
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="loan_start_date"
            label="Loan Start Date"
            type="date"
            validate={required}
            required
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="loan_from_date"
            label="Begin Loan From"
            type="date"
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="loan_interest_rate"
            {...percentage}
            label="Loan Interest Rate"
            validate={required}
            required
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="loan_period_years"
            label="Loan Period (Years)"
            validate={required}
            required
            type="number"
            extraProps={{ step: "1", min: "1" }}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="loan_balloon_amount"
            label="Balloon Amount"
            {...money}
          />
        </div>
        <FieldArray component={LoanFees} name="fees" />
      </div>
      <div className="col-12 form-group">
        <SubmitButton {...props} />
      </div>
    </form>
  );
};

const form = reduxForm<Loan, LoanFormProps>({});

export default form(LoanForm);
