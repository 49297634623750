import useModal from "../hooks/useModal";
import AuditLogModal from "./AuditLogModal";
import AuditLogDetails from "./AuditLogDetails";
import PaginatedList from "../pagination/PaginatedList";
import AuditLogTable from "./AuditLogTable";
import useAuditLogs from "./hooks/useAuditLogs";
import { useState } from "react";
import { IActivityLog } from "./IActivityLog";

interface AuditLogProps {
  subjectType: string;
  uuid: number;
}

const AuditLog = (props: AuditLogProps) => {
  const { subjectType, uuid } = props;
  const { toggle, modal } = useModal();
  const [ log, setLog ] = useState<IActivityLog | null>(null);

  const handleRowClick = (row: IActivityLog) => {
    setLog(row);
    toggle();
  };

  return (
    <>
        <PaginatedList
          listName="auditLogList"
          originalFilters={[]}
          indexHook={useAuditLogs}
          indexHookArguments={[subjectType, uuid]}
          list={({ data }) => {
            const activityLogs = data as IActivityLog[];
  
            return (
              <div className="col-12">
                <AuditLogTable
                    auditLogs={activityLogs}
                    handleRowClick={handleRowClick}
                  />
            </div>
            );
          }}
        />
      <AuditLogModal
        content={(toggle : () => void) => (
          log && (
            <AuditLogDetails
              toggle={toggle}
              modal={modal}
              log={log}
            />
          )
        )}
        toggle={toggle} />
    </>
  );
};

export default AuditLog;