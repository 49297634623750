import React from "react";
import isSubmitting from "../utils/submitting";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const ResendInvitation = () => {
  const { uuid } = useParams();
  const { loading, takeAction } = useApi();

  const resendInvitation = () =>
    takeAction("store", `resend-supplier-detail-forms/${uuid}`)
      .then(() => toast.success("Email Sent"))
      .catch(errorSwal);

  return (
    <div className="mt-3">
      <button
        disabled={loading}
        className="btn btn-outline-secondary w-100"
        onClick={resendInvitation}
      >
        {isSubmitting(loading, "Resend supplier form", "Resending...")}
      </button>
    </div>
  );
};

export default ResendInvitation;
