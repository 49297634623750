import PricingFields from "./PricingFields";
import useApi from "../api/useApi";

const PricingFieldArray = (props) => {
  const { fields, isInvoiced } = props;

  const { data: pricingItems } = useApi(
    props.pricingItems ? "" : `pricing-items`,
    props.pricingItems ?? [],
  );

  const { data: units } = useApi(
    props.units ? "" : `billable-item-units`,
    props.units ?? [],
  );

  const { data: pricingTypes } = useApi(`pricing-types`, []);

  return (
    <>
      {fields.map((pricing, index) => (
        <PricingFields
          pricing={pricing}
          fields={fields}
          pricingItems={pricingItems}
          pricingTypes={pricingTypes}
          key={index}
          index={index}
          {...props}
          units={units.map((unit) => ({
            label: unit.name,
            value: unit.id,
          }))}
        />
      ))}

      {!isInvoiced && (
        <div className="row">
          <div className="col-lg-12 d-flex form-group">
            <button
              type="button"
              className="btn ms-auto btn-link"
              onClick={() => fields.push()}
            >
              Add Pricing
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PricingFieldArray;
