import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import SpreadPrice from "../utils/SpreadPrice";
import InvoiceBadge from "./InvoiceBadge";
import useUserGroup from "../hooks/useUserGroup";
import { Invoice } from "./invoiceTypes";

const ProjectTable = ({ data } : { data : Invoice[]}) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]); 
  const isAccounting = useUserGroup("Accounting");

  useEffect(() => {
    setInvoices(data);
  }, [data]);

  const { takeAction: deleteInvoice } = useApi();
  const deleteInv = (invoice : any) => {
    deleteSwal(invoice.name)
      .then(() => deleteInvoice("destroy", `invoices/${invoice.uuid}`))
      .then(() => {
        setInvoices((prevInvoices) => prevInvoices.filter((inv) => inv.uuid !== invoice.uuid));
        toast.success(`${invoice.name} deleted.`);
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Invoice Number",
      cell: (info : any) => {
        const invoice = info.row.original;
        return <Link to={invoice.link}>{invoice.name}</Link>;
      },
    },
    {
      accessorKey: "details",
      header: "Details",
      cell: (info : any) => {
        const invoice = info.row.original;
        return invoice?.paid_at ? (
          <InvoiceBadge invoice={{ ...invoice, paid_at: null }} />
        ) : (
          <InvoiceBadge invoice={invoice} />
        );
      },
    },
    {
      accessorKey: "paid_at",
      header: "Paid Date",
      cell: (info : any) => {
        const invoice = info.row.original;
        return invoice?.paid_at ? <InvoiceBadge invoice={invoice} /> : "-";
      },
    },
    {
      accessorKey: "total_due",
      header: "Invoice Value",
      cell: (info : any) => <SpreadPrice price={info.row.original?.total_due} />,
    },
  ];

  if (isAccounting) {
    columns.push({
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => {
        const invoice = info.row.original;
        if (invoice.first_completed_at) return "-";
        return <DeleteButton deleteFunction={() => deleteInv(invoice)} />;
      },
    });
  }

  return (
    <>
      <div className="d-flex mb-2">
        <p className="tx-inverse mb-0 me-auto">All prices exclude GST.</p>
      </div>
      <ReactTable disableSearch data={invoices} columns={columns} wrapperClasses="table-responsive card pd-sm-x-0-force"/>
    </>
  );
};

export default ProjectTable;
