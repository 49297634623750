import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import FileInput from "../upload/FileInput";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import Spinner from "../utils/Spinner";
import SubmitButton from "../utils/SubmitButton";
import SelectInput from "../form/SelectInput";
import { IUseApiWithData } from "../api/apiTypes";
import filterOption from "../../utils/filterOption";
import _ from "lodash";
import { connect } from "react-redux";
import { useState } from "react";
import { CustomField } from "../customFields/customFieldTypes";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import { useAuth } from "../../context/auth-context";
import { CustomJob } from "../customJobs/customJobTypes";
import CustomForm from "../customFields/CustomForm";

const BugModal = (props: any) => {
  const {
    handleSubmit,
    toggle,
    modal,
    submitting,
    form,
    reset,
    valid,
    groupId,
  } = props;

  const { takeAction } = useApi();
  const { user } = useAuth();

  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();
  const { upload: uploadCustomFields } = useUploadCustomFields(
    `${user?.active_organisation.uuid}/custom-jobs`,
  );

  const {
    data,
  }: IUseApiWithData<
    {
      name: string;
      id: number;
      custom_job: CustomJob;
      custom_form: CustomField[];
      group: {
        name: string;
        id: number;
      };
    }[]
  > = useApi("ticket-types/to-options", []);

  const {
    takeAction: generatePrompt,
    loading: generatingPrompt,
    setLoading,
  } = useApi();

  const [customJob, setCustomJob] = useState<CustomJob | undefined>(undefined);
  const [selectedGroupName, setSelectedGroupName] = useState<string>("");

  const onPrompt = (
    fieldUuid: any,
    prompt: any,
    name: any,
    change: any,
    attachments: any,
    setAnnotations: any,
  ) => {
    return generatePrompt("store", `custom-fields/${fieldUuid}/prompts`, {
      prompts: [
        {
          role: "user",
          content: prompt,
          attachments: attachments.map((a: any) => ({
            file_id: a.id,
            tools: [
              {
                type: "file_search",
              },
            ],
          })),
        },
      ],
      // modelType: "App\\Models\\ProjectJob",
      // modelId: job?.id,
    })
      .then(({ data }: { data: any }) => {
        if (data.data.cached_thread_id) {
          setLoading(true);
          const checkThread = () => {
            return takeAction(
              "show",
              `open-ai/run/${data.data.run_id}/threads/${data.data.cached_thread_id}`,
            )
              .then(({ data }: { data: any }) => {
                if (data.data) {
                  toast.success("Prompt added");
                  change(name, data.data);

                  setLoading(false);

                  return;
                }

                return setTimeout(checkThread, 3000);
              })
              .catch((err: any) => {
                setLoading(false);
                errorSwal(err);
              });
          };

          return checkThread();
        }

        toast.success("Prompt added");

        change(name, data.data.content.text.value);

        if (setAnnotations && data.data.content.text.annotations.length > 0) {
          setAnnotations(data.data.content.text.annotations);
        }
        //Replace the last .value of the 'name' to .thread_id
        const nameArray = name.split(".");

        nameArray[nameArray.length - 1] = "thread_id";

        change(nameArray.join("."), data.data.thread_id);

        // if (setAnnotations && data.data.content.text.annotations.length > 0) {
        //   setAnnotations(data.data.content.text.annotations);
        // }
        //Replace the last .value of the 'name' to .thread_id
      })
      .catch(errorSwal);
  };

  const onSubmit = (values: any) => {
    if (values.file) {
      return upload(values.file, "bug-reports", (document: any) => {
        return store({ ...values, document });
      });
    }
    return store(values);
  };

  const store = (values: any) => {
    const defaultTicketType: string[] = [
      "feature_request",
      "bug_report",
      "support_request",
    ];

    const typeMapping: { [key: string]: string } = {
      feature_request: "Feature Request",
      bug_report: "Bug Report",
      support_request: "Support Request",
    };

    if (defaultTicketType.includes(values.type)) {
      values.sentry_id = `${Sentry.captureMessage(values.description)}`;
    }

    return uploadCustomFields(values)
      .then((values: any): Promise<any> => {
        return takeAction(
          "store",
          defaultTicketType.includes(values.type)
            ? "bug-reports"
            : "support-tickets",
          {
            ...values,
            path: window.location.href,
          },
        ).then((data: any) => {
          toast.success(
            `${data.data.name ?? typeMapping[values.type]} submitted`,
          );
          setUploadPercent(0);
          toggle();
          reset(form);
        });
      })
      .catch(errorSwal);
  };

  const groups = Object.values(_.groupBy(data, "group.name")).map(
    (g) => g[0].group,
  );

  const formattedTicketTypes = data.map((d) => {
    return {
      label: (
        <div className="d-flex align-items-center">
          <div className="">
            <p className="mb-0 text-dark">{d.name}</p>
            <small className="mb-0 tx-lg-10">{d.group.name} Support</small>
          </div>
        </div>
      ),
      value: d.id,
      text: d.name,
      group_id: d.group.id,
      group_name: d.group.name,
      custom_job: d.custom_job,
      custom_form: d.custom_form,
    };
  });

  const options = formattedTicketTypes.filter(
    (t) => t.group_name === selectedGroupName,
  );

  return (
    <Modal
      backdrop="static"
      isOpen={modal}
      toggle={() => {
        setCustomJob(undefined);
        toggle();
      }}
      className="wd-md-1000 mx-wd-800 w-95"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggle}>Support Request</ModalHeader>
        <ModalBody>
          <div className="row">
            {submitting && <Spinner loading={submitting} />}

            {data.length > 0 && (
              <div className="col-12 form-group">
                <Field
                  component={SelectInput}
                  options={Object.values(groups).map((g) => ({
                    label: g.name,
                    value: g.id,
                  }))}
                  name="group_id"
                  label="Department"
                  changeValue={(t: { label: string; value: number }) => {
                    setSelectedGroupName(t.label);
                    setCustomJob(undefined);
                  }}
                />
              </div>
            )}

            <div className="col-lg-12 form-group">
              <Field
                name="type"
                validate={required}
                component={SelectInput}
                required
                label="Type"
                options={options}
                filterOption={filterOption}
                changeValue={(value: {
                  value: number;
                  custom_form: CustomField[];
                  custom_job: CustomJob;
                }) => {
                  setCustomJob(value.custom_job);
                }}
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                name="description"
                type="textarea"
                textarea
                rows={6}
                cols={64}
                label="Description"
                component={RenderField}
                validate={required}
                required
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                name="file"
                component={FileInput}
                label="File"
                percent={uploadPercent}
              />
              <small>
                If possible, please upload a screenshot of your issue. This will
                help us to get your issue fixed as soon as possible.
              </small>
            </div>
            <CustomForm
              shouldError={true}
              customForm={customJob?.custom_fields
                .filter((field: any) => !field.field_attributes.hide_externally)
                .map((field: any) => {
                  return {
                    ...field,
                    field_attributes: {
                      ...field.field_attributes,
                      disabled: field.field_attributes.disabled_externally,
                    },
                  };
                })}
              {...props}
              onPrompt={onPrompt}
              generatingPrompt={generatingPrompt}
            />
            {customJob?.requires_approval && valid && (
              <div className="col-12 form-group">
                <Field
                  component={SelectInput}
                  name="approver"
                  required
                  options={customJob.approvers ?? []}
                  label="Approver"
                />
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <SubmitButton {...props} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

const form = reduxForm({
  form: "BugReport",
});

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("BugReport");

  return {
    groupId: selector(state, "group_id"),
  };
};

export default connect(mapStateToProps, {})(form(BugModal));
