import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getMfas = ({ pageParam = 1 }, search?: string) => {
  return api
    .get(
      `users/two-factor-authentications?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }: { data: any }) => data);
};

export default function useUsersMfa(search?: string) {
  return useInfiniteQuery(
    ["multi-factor-authentications", { search }],
    (pageParam) => getMfas(pageParam, search),
    {
      getNextPageParam: ({ meta }: { meta: any }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
