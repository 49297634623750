import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

interface IcsEvent {
  dtStart: Date;
  dtEnd: Date;
  summary: string;
  description?: string;
  location?: string;
  url?: string;
}

const formatDateForIcs = (date: Date): string => {
  return dayjs(date).utc().format("YYYYMMDDTHHmmss[Z]");
};

export const generateWebcalUrl = (event: IcsEvent): string => {
  let ics = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\n`;
  ics += `DTSTART:${formatDateForIcs(event.dtStart)}\n`;
  ics += `DTEND:${formatDateForIcs(event.dtEnd)}\n`;
  ics += `SUMMARY:${event.summary.replace(/\n/g, "\\n")}\n`;
  ics += event.description
    ? `DESCRIPTION:${event.description.replace(/\n/g, "\\n")}\n`
    : "";
  ics += event.location
    ? `LOCATION:${event.location.replace(/\n/g, "\\n")}\n`
    : "";
  ics += event.url ? `URL:${event.url}\n` : "";
  ics += `END:VEVENT\nEND:VCALENDAR\n`;

  return `data:text/calendar;charset=utf8,${encodeURIComponent(ics)}`;
};

const generateIcs = (filename: string, event: IcsEvent) => {
  let ics = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\n`;
  ics += `DTSTART:${formatDateForIcs(event.dtStart)}\n`;
  ics += `DTEND:${formatDateForIcs(event.dtEnd)}\n`;
  ics += `SUMMARY:${event.summary.replace(/\n/g, "\\n")}\n`;
  ics += event.description
    ? `DESCRIPTION:${event.description.replace(/\n/g, "\\n")}\n`
    : "";
  ics += event.location
    ? `LOCATION:${event.location.replace(/\n/g, "\\n")}\n`
    : "";
  ics += event.url ? `URL:${event.url}\n` : "";
  ics += `END:VEVENT\nEND:VCALENDAR\n`;

  const blob = new Blob([ics], { type: "text/calendar;charset=utf-8;" });

  const link = document.createElement("a");

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      filename.replace(/[<>:"\/\\|?*\x00-\x1F]/g, "_"),
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default generateIcs;
