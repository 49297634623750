import { useEffect } from "react";
import FormErrorAlert from "../form/FormErrorAlert";
import CustomField from "./CustomField";

const CustomForm = (props) => {
  const { customForm, change, name = "custom_fields" } = props;

  useEffect(() => {
    if (customForm) {
      customForm.forEach((field) => {
        change(`${name}.${field.uuid}.field_id`, field.id);
        change(`${name}.${field.uuid}.label`, field.field_attributes.label);
        change(`${name}.${field.uuid}.order`, field.order);
        change(`${name}.${field.uuid}.type`, field.type);
        change(`${name}.${field.uuid}.uuid`, field.uuid);
      });
    }
  }, [customForm]);

  if (!customForm) return null;

  return (
    <>
      <FormErrorAlert error={props.error} />
      {customForm.map((field) => {
        return (
          <CustomField
            shouldError={false}
            {...field}
            key={field.uuid}
            {...props}
            name={name}
          />
        );
      })}
    </>
  );
};

export default CustomForm;
