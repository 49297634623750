import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import Prices from "../prices/Prices";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import Edit from "./Edit";
import TenderQuotePage from "./TenderQuotePage";
import { Tender } from "./tenderTypes";
import ProjectEmails from "../projects/ProjectEmails";

export interface ShowTenderPageProps {
  tender: Tender;
  setTender: (tender: Tender) => void;
}

export type TenderPages =
  | "details"
  | "quote"
  | "documents"
  | "pricing"
  | "emails";

const ShowTenderPage = (props: ShowTenderPageProps) => {
  const { uuid, page } = useParams<{
    uuid: string;
    page: TenderPages;
  }>();

  const { user } = useAuth();

  const { tender } = props;

  switch (page) {
    case "details":
      return <Edit {...props} initialValues={tender} />;
    case "quote":
      return <TenderQuotePage {...props} />;

    case "documents":
      return (
        <ProfileDocuments
          {...props}
          model="App\Models\ProjectJob"
          documentableId={tender.job.uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/jobs/${tender.job.uuid}`}
          homeUrl={`tenders/${uuid}/documents`}
        />
      );
    case "pricing":
      return (
        <Prices
          {...props}
          chargeableType="App\Models\Tender"
          chargeableId={tender.id}
          duplicate
          showAmount
        />
      );
    case "emails":
      return (
        <ProjectEmails
          messagableId={tender.id}
          messagableType="App\\Models\\Tender"
        />
      );

    default:
      return <NotFound />;
  }
};

export default ShowTenderPage;
