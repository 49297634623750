import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ReactComponent as Empty } from "../../svgs/empty.svg";

import {
  fetchMoistureContainers,
  deleteMoistureContainer,
} from "../../actions/moistureContainerActions";
import CustomScaleLoader from "../utils/scaleLoader";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import SVGContainer from "../utils/SVGContainer";
import ClipLoader from "react-spinners/ClipLoader";
import { primary } from "../utils/Colours";

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      deleting: [],
    };
  }

  delete = (e, container) => {
    e.stopPropagation();
    const { deleteMoistureContainer, selectContainer, selectedContainer } =
      this.props;
    return deleteSwal()
      .then(() => {
        this.setState({ deleting: [...this.state.deleting, container.uuid] });
        return deleteMoistureContainer(container.uuid);
      })
      .then(() => {
        toast.success(`${container.name} Deleted Successfully`);
        if (selectedContainer.uuid === container.uuid) selectContainer({});
      })
      .catch(() => {
        errorSwal();
      })
      .then(() =>
        this.setState({
          deleting: this.state.deleting.filter((del) => del !== container.uuid),
        }),
      );
  };

  componentDidMount = () =>
    this.props
      .fetchMoistureContainers()
      .then(() => this.setState({ loading: false }));

  render() {
    const { moistureContainers, selectContainer, selectedContainer } =
      this.props;
    if (this.state.loading) {
      return (
        <CustomScaleLoader>Fetching Moisture Containers...</CustomScaleLoader>
      );
    }
    if (moistureContainers.length === 0) {
      return (
        <SVGContainer SVG={Empty} className="mt-3" width="33%">
          <p className="tx-inverse">
            No containers added yet, start by adding one above!{" "}
          </p>
        </SVGContainer>
      );
    }
    return (
      <div className=" table-responsive">
        <table className="table table-striped">
          <thead className="thead-inverse">
            <tr>
              <th>Name</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {moistureContainers.map((container) => (
              <tr
                key={container.uuid}
                onClick={() => selectContainer(container)}
                className={`pointer-hover link-hover ${
                  selectedContainer.uuid === container.uuid
                    ? "table-secondary"
                    : ""
                }`}
              >
                {this.state.deleting.includes(container.uuid) ? (
                  <td colSpan="2">
                    <ClipLoader color={primary} size={20} /> Deleting...
                  </td>
                ) : (
                  <Fragment>
                    <td scope="row">{container.name}</td>
                    <td>
                      {" "}
                      <i
                        onClick={(e) => this.delete(e, container)}
                        className="tx-22 pointer-hover tx-danger icon ion-trash-a"
                      />
                    </td>
                  </Fragment>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  moistureContainers: state.moistureContainers.items,
});

export default connect(mapStatetoProps, {
  fetchMoistureContainers,
  deleteMoistureContainer,
})(Table);
