import { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import DuplicatePrices from "./DuplicatePrices";

const DuplicatePriceButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <ButtonDropdown
      className="w-100 mb-3"
      isOpen={open}
      toggle={() => setOpen(!open)}
    >
      <DropdownToggle className="w-100" color="light" caret>
        <i className="fa fa-plus pe-2" />
        Duplicate Prices
      </DropdownToggle>
      <DropdownMenu>
        <div className="pd-30 pd-sm-40">
          <DuplicatePrices setOpen={setOpen} {...props} />
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default DuplicatePriceButton;
