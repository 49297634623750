import dayjs from "dayjs";
import queryString from "query-string";
import errorSwal from "../utils/errorSwal";
import axios from "../api/api";

const fetchDashboard = (
  model,
  id,
  from,
  to,
  setDashboard,
  includeProjects = true,
  stringified = "",
) => {
  const query = {
    startDate: from ? `${dayjs(from).format("YYYY-MM-DD")}` : null,
    endDate: to ? `${dayjs(to).format("YYYY-MM-DD")}` : null,
    model: model,
    modelId: id,
    includeProjects,
  };

  return axios
    .get(`/dashboards?${queryString.stringify(query)}${stringified}`)
    .then((res) => setDashboard(res.data))
    .catch(errorSwal);
};

export default fetchDashboard;
