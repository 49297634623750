import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import useS3Uploader from "./useS3Uploader";

const useUploadStandardDocument = () => {
  const { upload, uploadPercent, loading } = useS3Uploader();
  const { takeAction } = useApi();

  const uploadDocument = (file, bucket, data) => {
    return upload(file, bucket, (res) => {
      data.documents = [res];
      return takeAction("store", "documents/standard", data)
        .then((res) => {
          toast.success("Document uploaded successfully");
          return res;
        })
        .catch((err) => {
          return errorSwal(err);
        });
    });
  };

  return {
    uploadDocument,
    uploadPercent,
    loading,
  };
};

export default useUploadStandardDocument;
