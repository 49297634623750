import {
  FETCH_DOCKETS,
  NEW_DOCKET,
  UPDATE_DOCKET,
  DELETE_DOCKET,
  FETCH_DOCKET_PREVIEW,
  UPDATE_SIGNATURE,
  GET_JOBS_FOR_DOCKET,
  GET_PROJECTS_FOR_DOCKET,
  GET_DOCKET_ITEMS
} from "../actions/types";

const initialState = {
  items: [],
  jobs: [],
  projects: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCKETS:
      return {
        ...state,
        items: action.payload
      };
    case NEW_DOCKET:
      return {
        ...state,
        items: action.payload
      };
    case UPDATE_DOCKET:
      return {
        ...state,
        items: { ...action.payload.data }
      };
    case DELETE_DOCKET:
      return {
        ...state
      };
    case FETCH_DOCKET_PREVIEW:
      return {
        ...state,
        preview: action.payload
      };
    case UPDATE_SIGNATURE:
      return {
        ...state,
        signature: action.payload
      };
    case GET_JOBS_FOR_DOCKET:
      return {
        ...state,
        jobs: action.payload
      };
    case GET_PROJECTS_FOR_DOCKET:
      return {
        ...state,
        projects: action.payload
      };
    case GET_DOCKET_ITEMS:
      return {
        ...state,
        docketItems: action.payload
      };
    default:
      return state;
  }
}
