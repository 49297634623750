import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Progress, UncontrolledTooltip } from "reactstrap";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { baseCpirFilters } from "../managementDashboard/ManagementCPIRs";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { BootstrapColour } from "../utils/utilTypes";
import { CPIRIndex } from "./cpirTypes";
import useCpirs from "./hooks/useCpirs";
import DocsModal from "../utils/DocsModal";
import riskDetails from "./risk";

const CPIRs = () => {
  const { data: cpirCategories }: { data: { name: string }[] } = useApi(
    "cpir-categories",
    [],
  );

  const { data: cpirTypes }: { data: { name: string }[] } = useApi(
    "cpir-types",
    [],
  );

  const [cpirFilters, setCpirFilters] = useState(baseCpirFilters);

  useEffect(() => {
    setCpirFilters([
      ...baseCpirFilters,
      {
        label: "Category",
        name: "cpir_categories.name",
        options: cpirCategories.map((category) => {
          return {
            label: category.name,
            value: category.name,
          };
        }),
      },
      {
        label: "Type",
        name: "cpir_types.name",
        options: cpirTypes.map((type) => {
          return {
            label: type.name,
            value: type.name,
          };
        }),
      },
    ]);
  }, [cpirCategories, cpirTypes]);

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/corrective-preventative-and-improvement-recommendations-cpirs-9310/" />
      <HeaderPage
        titlePage="Corrective, Preventative and Improvement Recommendations (CPIRs)"
        crumbs={[
          {
            link: "cpirs",
            name: "CPIRs",
            active: true,
          },
        ]}
      />
      <div className="mb-3">
        <Link to="/cpirs/add" className="btn btn-outline-primary">
          Add new CPIR
        </Link>
      </div>
      <PaginatedList
        listName="cpirList"
        indexHook={useCpirs}
        originalFilters={cpirFilters}
        itemCard={({ item }: { item: CPIRIndex }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<CPIRHeader cpir={item} />}
            bottomContent={<CPIRFooter cpir={item} />}
          />
        )}
      />
    </>
  );
};

const info = (cpir: CPIRIndex): PaginatedInformation[] => {
  return [
    {
      name: "Type",
      value: cpir.type || cpir.original_type,
    },
    {
      name: "Raised By",
      value: cpir.raised_by,
    },
    {
      name: "Raised To",
      value: cpir.raised_to,
    },
    {
      name: "Status",
      value: cpir.status,
    },
    {
      name: "Target Date for Closure",
      value: dayjs(cpir.target_date_for_closure).format("DD/MM/YYYY"),
    },
    {
      name: "Completed On",
      value: cpir.completed_on
        ? dayjs(cpir.completed_on).format("DD/MM/YYYY")
        : undefined,
    },
  ];
};

const CPIRFooter = ({ cpir }: { cpir: CPIRIndex }) => {
  const risk = riskDetails(cpir?.risk_score ?? 0);

  return (
    <>
      <div className="d-flex align-items-center mb-1 mt-2">
        <p className={`text-${risk.colour} fw-bolder tx-12 mb-0`}>
          {cpir.risk_level ?? "No"} Risk
        </p>
        <div className="ms-auto">
          <div
            className={`bg-${statusBadgeColor(
              cpir,
            )} shadow rounded-pill text-white tx-10 text-center px-2`}
          >
            {cpir.status}
          </div>
        </div>
      </div>
      {cpir?.risk_score ? (
        <Progress
          id={`progress_${cpir.uuid}`}
          value={(cpir?.risk_score / 25) * 100}
          color={risk.colour}
          className="progress-bar-xs"
          striped
          animated
        />
      ) : null}
    </>
  );
};

const CPIRHeader = ({ cpir }: { cpir: CPIRIndex }) => {
  const risk = riskDetails(cpir?.risk_score ?? 0);
  const tooltipId = useMemo(() => `cpir_title_${cpir.uuid}`, [cpir.uuid]);

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={cpir.raised_by} colour={risk.colour} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={cpir.link}>{cpir.title}</Link>
          </p>
          {cpir.uuid && (
            <UncontrolledTooltip placement="top-start" target={tooltipId}>
              {cpir.title}
            </UncontrolledTooltip>
          )}
          <p className="mb-0">{cpir.name}</p>
        </div>
      </div>
    </div>
  );
};

export const statusBadgeColor = (cpir: CPIRIndex): BootstrapColour => {
  if (cpir.status === "Waiting for Approval") {
    return "info";
  }

  if (cpir.status === "Closed") {
    return "secondary";
  }

  return "warning";
};

export default CPIRs;
