import {
  HiOutlineSortAscending,
  HiOutlineSortDescending,
} from "react-icons/hi";
import { TiArrowUnsorted } from "react-icons/ti";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ISorter } from "../hooks/useSort";

interface SortDropdownProps<StringLiteral> {
  sorters: ISorter<StringLiteral>[];
  toggleSorter: (sorterName: StringLiteral) => void;
}

const SortDropdown = <StringLiteral,>({
  sorters,
  toggleSorter,
}: SortDropdownProps<StringLiteral>) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" caret className="p-1">
        <TiArrowUnsorted className="tx-16" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Sort</DropdownItem>
        {sorters.map((sorter) => {
          return (
            <DropdownItem
              onClick={() => toggleSorter(sorter.name)}
              key={`${sorter.name}`}
              className="d-flex align-items-center"
            >
              {sorter.label} <SortIcon sortBy={sorter.sortBy} />
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const SortIcon = ({ sortBy }: { sortBy?: "asc" | "desc" }) => {
  if (!sortBy) {
    return null;
  }

  return sortBy === "asc" ? (
    <HiOutlineSortAscending className="text-secondary ms-2" />
  ) : (
    <HiOutlineSortDescending className="text-secondary ms-2" />
  );
};

export default SortDropdown;
