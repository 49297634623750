import { useEffect } from "react";
import Editor, { useMonaco } from "@monaco-editor/react";
import FieldType from "../../enums/FieldType";

const CustomFieldCodeEditor = (props) => {
  const { vals, fields, isFirstJsEditor, codeSuggestions, setCodeSuggestions } =
    props;
  const monaco = useMonaco();

  useEffect(() => {
    let disposable = null;
    if (monaco) {
      const existingLabels = fields
        .filter(
          (field) =>
            field?.field_attributes?.label &&
            codeSuggestions.includes(field?.field_attributes?.label),
        )
        .map((field) => field?.field_attributes?.label);
      const newLabels = fields
        .filter(
          (field) =>
            field?.field_attributes?.label &&
            [FieldType.Text, FieldType.Formula].includes(
              parseInt(field?.type),
            ) &&
            !existingLabels.includes(field?.field_attributes?.label),
        )
        .map((field) => field?.field_attributes?.label);

      const updatedLabels = existingLabels.concat(newLabels);
      setCodeSuggestions(updatedLabels);
      const addLabels = isFirstJsEditor ? updatedLabels : newLabels;
      disposable = monaco.languages.registerCompletionItemProvider(
        "javascript",
        {
          provideCompletionItems: (model, position, token) => {
            const result = addLabels
              .filter((label) => label !== vals?.field_attributes?.label)
              .map((label) => {
                const variable = `$`.concat(
                  `{${label
                    ?.trim()
                    .replace(/{|}/g, "")
                    .replace(/\s+/g, "-")
                    .toLowerCase()}}`,
                );

                return {
                  insertText: `${variable}`,
                  kind: 4,
                  label: `label: ${label}`,
                  detail: `variable: ${variable}`,
                };
              });
            return {
              suggestions: result,
            };
          },
        },
      );
    }
    return () => disposable?.dispose();
  }, [monaco, props.fields]);

  return <Editor {...props.input} height="50vh" defaultLanguage="javascript" />;
};

export default CustomFieldCodeEditor;
