import { toast } from "react-toastify";

import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import FormSkillCategory from "./Form";
import { useHistory } from "react-router-dom";

const AddSkillCategory = () => {
  const history = useHistory();

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("store", "skill-categories", values)
      .then(({ data }) => {
        toast.success(`${values.name} added successfully`);
        history.push(`/skill-categories/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Skill Category"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "skill-categories", name: "Skill Categories" },
          { link: "", name: "New Skill Category", active: true },
        ]}
      />
      <FormSkillCategory onSubmit={onSubmit} />
    </>
  );
};

export default AddSkillCategory;
