import React from "react";

const Empty = props => {
  return (
    <div className="d-flex flex-wrap w-100 justify-content-center bg-white p-5 border">
      <button type="button" className="btn btn-link p-0" onClick={props.toggle}>
        <img className="w-100" src="/img/up_to_date.svg" height="250" />
      </button>
      <p className="fw-bold w-100 tx-inverse text-center mt-5 mb-2">
        No Required Competencies Found.
      </p>
      <p className="w-100  text-center mb-2">
        Preset competencies that are needed for certain positions or roles.
      </p>
      <p className="w-100  text-center mb-2">
        New required competencies will automatically be added to each staff
        member that is currently associated to the position or role.
      </p>
      <p className="w-100  text-center mb-0">
        <button
          type="button"
          onClick={props.toggle}
          className="btn btn-link p-0"
        >
          Click here
        </button>{" "}
        to get started.
      </p>
    </div>
  );
};

export default Empty;
