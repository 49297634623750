import dayjs from "dayjs";
import { Link } from "react-router-dom";
import employment_basis from "../../data/employment_basis";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { EmploymentDetailIndex } from "./employmentDetailTypes";
import useEmploymentDetails from "./hooks/useEmploymentDetails";
import { OrganisationRole, Position, User } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const EmploymentDetails = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/hr-staff-training/employment-details/" />
      <HeaderPage
        titlePage="Employment Details"
        pageDescription={
          <>
            Employment Details contain important information about an employee's
            position, pay, and location that is used to manage and track their
            employment within an organization.
          </>
        }
        relatedLinks={[
          {
            name: "Roles",
            link: "/organisation-roles",
            model: OrganisationRole,
          },
          {
            name: "Positions",
            link: "/positions",
            model: Position,
          },
          {
            name: "Staff",
            link: "/users",
            model: User,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          {
            link: "employment-details",
            name: "Employment Details",
            active: true,
          },
        ]}
      />
      <PaginatedList
        listName="employmentDetailsList"
        indexHook={useEmploymentDetails}
        originalFilters={employmentDetailsFilters}
        addLink="/employment-details/add"
        itemCard={({ item }: { item: EmploymentDetailIndex }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<EmploymentDetailHeader detail={item} />}
            bottomContent={<EmploymentDetailFooter detail={item} />}
          />
        )}
      />
    </>
  );
};

const EmploymentDetailHeader = ({
  detail,
}: {
  detail: EmploymentDetailIndex;
}) => {
  return (
    <div className="d-flex align-items-center w-100">
      <div style={{ maxWidth: "15%" }}>
        <a href={`mailto:${detail.user.email}`}>
          <Avatar name={detail.user.name} colour="info" />
        </a>
      </div>
      <div className="ms-1" style={{ width: "85%" }}>
        <p className="mb-0 fw-bolder tx-inverse no-wrap">
          <Link to={detail.link}>{detail.user.name}</Link>
        </p>
        <p className="mb-0">{detail.position.name}</p>
      </div>
    </div>
  );
};

const EmploymentDetailFooter = ({
  detail,
}: {
  detail: EmploymentDetailIndex;
}) => {
  return (
    <div className="d-flex align-items-center mt-2">
      {detail.is_active ? (
        <div className="ms-auto bg-success shadow rounded-pill text-white tx-10 text-center px-2">
          Active
        </div>
      ) : detail.finish_date ? (
        <div className="ms-auto bg-secondary shadow rounded-pill text-white tx-10 text-center px-2">
          Finished on {dayjs(detail.finish_date).format("DD/MM/YYYY")}
        </div>
      ) : (
        <div className="ms-auto bg-info shadow rounded-pill text-white tx-10 text-center px-2">
          Begins on {dayjs(detail.start_date).format("DD/MM/YYYY")}
        </div>
      )}
    </div>
  );
};

const info = (detail: EmploymentDetailIndex): PaginatedInformation[] => {
  return [
    {
      name: "Branch",
      value: detail.branch,
    },
    {
      name: "Position",
      value: detail.position.name,
    },
    {
      name: "Employment Basis",
      value: detail.employment_basis,
    },
    {
      name: "Award",
      value: detail.award_abbreviation,
    },
  ];
};

const employmentDetailsFilters: IFilter<
  "finished" | "employment_basis" | "current_staff" | "is_primary",
  [string, boolean] | string | boolean
>[] = [
  {
    label: "Finished",
    name: "finished",
    options: [
      {
        label: "Not Finished",
        value: [dayjs().format("YYYY-MM-DD"), false],
        selected: true,
      },
      {
        label: "Finished",
        value: [dayjs().format("YYYY-MM-DD"), true],
      },
    ],
  },
  {
    label: "Basis",
    name: "employment_basis",
    options: employment_basis,
  },
  {
    label: "Currently Employed",
    name: "current_staff",
    options: [
      {
        label: "Current Employees",
        value: true,
        selected: true,
      },
      {
        label: "Previous Employees",
        value: false,
      },
    ],
  },
  {
    label: "Is Primary",
    name: "is_primary",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
];

export default EmploymentDetails;
