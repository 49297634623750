import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class Tab extends Component {
  render() {
    const { link, active, tabIndex } = this.props;
    return (
      <Link
        to={link}
        tabIndex={tabIndex}
        className={`nav-link ${active ? "active" : ""}`}
      >
        <span>{this.props.children}</span>
        <span />
      </Link>
    );
  }
}

Tab.propTypes = {
  link: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default Tab;
