import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import ReportModal from "./ReportModal";
import { useParams } from "react-router-dom";

const GenerateReport = (props) => {
  const { takeAction } = useApi();
  const { modal, toggle } = useModal();
  const { user } = useAuth();

  const { test } = useParams();

  const [compactions, setCompactions] = useState([]);

  const [signatory, setSignatory] = useState(false);

  useEffect(() => {
    takeAction("show", `completed-compactions/${test}`)
      .then(({ data }) => setCompactions(data.data))
      .then(() =>
        takeAction(
          "show",
          `user-competency-levels/${user.uuid}?skill=${encodeURIComponent(
            "App\\Models\\TestMethod",
          )}&skillId=46&skillLevel=3`,
        ),
      )
      .then(({ data }) => setSignatory(data.data.competent));
  }, []);

  const compsComplete = compactions.every((comp) => comp.is_complete);

  const submit = (values) => {
    return takeAction(
      "store",
      `test-requests/${test}/compaction-reports`,
      values,
    )
      .then(({ data }) => {
        props.history.push(data.data.link);
      })
      .catch(errorSwal);
  };

  if (!signatory || !compsComplete) {
    return null;
  }

  return (
    <>
      <button type="button" onClick={toggle} className="btn btn-primary">
        Generate Report
      </button>
      <ReportModal modal={modal} toggle={toggle} onSubmit={submit} />
    </>
  );
};

export default GenerateReport;
