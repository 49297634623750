import classNames from "classnames";
import React from "react";

const SearchInput = (props) => {
  const { setValue, loading, wrapperclass = null, className } = props;

  return (
    <div className={classNames("search-box shadow-sm d-flex", wrapperclass)}>
      <input
        autoComplete="off"
        placeholder="Search..."
        className={`form-control w-100 ${className ?? ""}`}
        type="search"
        style={{ zIndex: 1 }}
        {...props}
        onChange={(e) => setValue(e.target.value)}
      />
      <button className="btn btn-primary ms-auto" type="button">
        <i className={`fa fa-${loading ? "spinner fa-spin" : "search"}`} />
      </button>
    </div>
  );
};

export default SearchInput;
