import errorSwal from "../utils/errorSwal";
import Select from "react-select";
import { JobOverviewDashboardProject as IJobOverviewDashboardProject } from "./dashboardTypes";
import { CustomFieldEquipment } from "../customFields/customFieldTypes";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { useQueryClient } from "react-query";
import { useState } from "react";

interface AddEquipmentToProjectSelectProps {
  refreshData: Function;
  organisationEquipment: CustomFieldEquipment[];
  project: IJobOverviewDashboardProject;
}

const AddEquipmentToProjectSelect = ({
  refreshData,
  organisationEquipment,
  project,
}: AddEquipmentToProjectSelectProps) => {
  const { takeAction }: IUseApi = useApi();
  const [value, setValue] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const queryClient = useQueryClient();

  return (
    <>
      <label
        className="form-control-label tx-inverse tx-semibold"
        htmlFor={`equipment_${project.uuid}`}
      >
        Add equipment to project
      </label>
      <Select
        id={`equipment_${project.uuid}`}
        options={organisationEquipment.map((e) => ({
          label: e.name,
          value: e.uuid,
        }))}
        value={value}
        onChange={(selected) => {
          if (!selected) {
            return;
          }

          setValue(selected);
          return takeAction("store", `equipment/${selected.value}/projects`, {
            project_id: project.id,
          })
            .then(() => {
              queryClient.invalidateQueries(["job-overview-dashboard"]);
              refreshData();
              toast.success("Equipment added to project");

              setValue(null);
            })
            .catch(errorSwal);
        }}
      />
    </>
  );
};

export default AddEquipmentToProjectSelect;
