import { useState, useEffect } from "react";
import axios from "../api/api";
import { Field, reduxForm, FieldArray, submit } from "redux-form";
import COMPETENCY_SKILL_TYPES from "../../data/competency_skill_types";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import SubmitButton from "../utils/SubmitButton";
import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import errorSwal from "../utils/errorSwal";
import SkillLevels from "./SkillLevels";
import required from "../utils/required";
import renderToggleInput from "../utils/renderToggleInput";
import SelectInput from "../form/SelectInput";

const AddModal = (props) => {
  const { toggle, modal, handleSubmit, dispatch, title, initialValues, form } =
    props;

  const [skillIds, setSkillIds] = useState([]);

  useEffect(() => {
    if (initialValues) {
      change(null, initialValues.skill_type);
    }
  }, []);

  const change = (e, val) => {
    if (!val) {
      setSkillIds([]);
      return;
    }

    const selectedSkill = COMPETENCY_SKILL_TYPES.find(
      (type) => type.value === val,
    );

    return axios
      .get(
        `/react-select${selectedSkill.selectQuery}&model=${encodeURIComponent(
          selectedSkill.value,
        )}`,
      )
      .then(({ data }) => setSkillIds(data))
      .catch(() => errorSwal("Unable to fetch job types."));
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="wd-md-850"
      style={{ maxWidth: "850px" }}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      {/* <ModalBody> */}
      <form onSubmit={handleSubmit}>
        <ModalBody className="border-bottom">
          <div className="row">
            <div className="col-6 form-group">
              <Field
                validate={required}
                required
                component={SelectInputAsync}
                name="skill_type"
                url="skill-types"
                label="Skill Type"
                formatData={({ data }) => {
                  return data.map((d) => {
                    return d;
                  });
                }}
                onChange={change}
              />
            </div>
            <div className="col-6 form-group">
              <Field
                validate={required}
                required
                component={SelectInput}
                options={skillIds}
                name="skill_id"
                label="Skill"
              />
              {skillIds.length === 0 && (
                <small className="d-block">No skills found.</small>
              )}
            </div>
            <div className="col-12 form-group">
              <Field
                validate={required}
                required
                component={renderField}
                type="number"
                name="minimum_level_of_competence"
                label="Minimum Level of Competence"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="confidential"
                label="Confidential"
              />
              <small className="d-block">
                Only organisation administrators are able to see confidential
                skill categories.
              </small>
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="send_to_branch_manager"
                label="Send Job to Branch Manager"
              />
              <small className="d-block">
                When checked, instead of the user being responsible for
                completing the job, their default branch manager will be. This
                is useful for things like performance reviews.
              </small>
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="add_initially"
                label="Add Initially"
              />
              <small className="d-block">
                When unchecked, the initial competency created when added to
                'required competencies' will be in the future, whenever the
                skill level is set for. Uncheck it for things like performance
                reviews.
              </small>
            </div>
          </div>
        </ModalBody>
        <div style={{ backgroundColor: "#f0f2f7" }} className="p-3">
          <FieldArray {...props} component={SkillLevels} name="skill_levels" />
        </div>
      </form>
      {/* </ModalBody> */}
      <ModalFooter>
        <SubmitButton onClick={() => dispatch(submit(form))} {...props} />
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm({});

export default form(AddModal);
