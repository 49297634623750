import { useParams } from "react-router-dom";
import NotFound from "../404";
import ProfileForm from "../customFields/ProfileForm";
import Edit from "./Edit";
import { fieldTypes } from "../../enums/FieldType";

const ShowPage = (props) => {
  const { page } = useParams();
  const { information, setInformation } = props;

  switch (page) {
    case "details":
      return <Edit initialValues={information} {...props} />;
    case "fields":
      return (
        <ProfileForm
          initialValues={information}
          {...props}
          modelId={information.id}
          modelType="App\Models\RequiredProjectInformation"
          onSuccess={(data) =>
            setInformation({
              ...information,
              custom_fields: data.data,
            })
          }
          fieldTypes={[
            ...fieldTypes,
            {
              label: "Prompt",
              value: 16,
            },
          ]}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
