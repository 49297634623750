import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import Form from "./Form";

const ShowPage = (props) => {
  const { page, uuid } = useParams();
  const { user } = useAuth();

  switch (page) {
    case "details":
      return (
        <Form
          initialValues={props.expenseClaim}
          form="UpdateExpenseClaim"
          {...props}
        />
      );
    case "documents":
      return (
        <NewProfileDocuments
          {...props}
          model="App\Models\StaffPayment"
          documentableId={uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/expense-claims/${uuid}`}
          homeUrl={`expense-claims/${uuid}/documents`}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
