import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import JobTypeModal from "../jobs/JobTypeModal";
import { AdministrationLevels } from "../user/userTypes";

const FloatingActionButton = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { user } = useAuth();
  const isBasic =
    user.position_administration_level == AdministrationLevels.Basic &&
    !user.is_admin;

  if (isBasic) {
    return null;
  }

  return (
    <>
      <nav id="fab" className="floating-button d-none d-lg-block z-20">
        {/* 
        Commenting out as it will be done in the outlook app now, we may add back in later.
        {(isAccounting || user?.is_admin) && (
          <Link
            to="/upload-purchase-order"
            className="buttons align-items-center justify-content-center"
            tooltip="Upload Purchase Order"
          >
            <i className="fa fa-file fa-lg" />
          </Link>
        )} */}
        <Link
          to="/direct-reports"
          className="buttons align-items-center justify-content-center"
          tooltip="Direct Reports"
        >
          <i className="fa fa-users fa-lg" />
        </Link>
        <Link
          to="/dockets/add"
          className="buttons align-items-center justify-content-center"
          tooltip="Add Docket"
        >
          <i className="fa fa-file-text-o fa-lg" />
        </Link>
        <Button
          onClick={toggle}
          className="buttons align-items-center justify-content-center"
          tooltip="Add Job"
          color="link"
        >
          <i className="fa fa-pencil-square-o fa-lg" />
        </Button>
        <Button
          onClick={props.toggleBug}
          className="buttons align-items-center justify-content-center"
          tooltip="Support Request"
          color="link"
        >
          <i className="fa fa-bug fa-lg" />
        </Button>
        <Link
          className="buttons align-items-center justify-content-center"
          tooltip="Add Corrective, Preventative or Improvement Recommendation (CPIR)"
          to="/cpirs/add"
        >
          <i className="fa fa-binoculars" />
        </Link>
        <Link
          to="/projects/add"
          className="buttons align-items-center justify-content-center"
          tooltip="Add Project"
        >
          <i className="fa fa-bar-chart fa-lg" />
        </Link>
        <Link to="/home" className="buttons" tooltip="Menu">
          <i className="fa fa-bars fa-2x" />
        </Link>
      </nav>

      <JobTypeModal toggle={toggle} modal={modal} selectProject {...props} />
    </>
  );
};

export default FloatingActionButton;
