import React from "react";

const InitialSVG = props => {
  const { name, size, fontSize, fill, className } = props;
  const initials = name.match(/\b\w/g) || [];
  const halfSize = size / 2;
  return (
    <svg className={className} height={size} width={size} {...props}>
      <circle cx={halfSize} cy={halfSize} r={halfSize} fill="#c5c5c5" />
      <text
        fill={fill}
        fontSize={fontSize}
        textAnchor="middle"
        x={halfSize}
        y={halfSize + 5}
      >
        {initials}
      </text>
    </svg>
  );
};

InitialSVG.defaultProps = {
  size: "40",
  fontSize: "16",
  fill: "#ffffff"
};

export default InitialSVG;
