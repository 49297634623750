import React from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../../api/api";

import { toast } from "react-toastify";

export default function useCreateNotice() {
  const queryData = useQueryClient();

  return useMutation((values) => api.post("notices", values), {
    onSuccess: (data, variables) => {
      queryData.invalidateQueries("notices");
      toast.success("Notice added.");
    },
  });
}
