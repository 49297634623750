import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import swal from "sweetalert";
import useApi from "../api/useApi";
import buttonGroup from "../utils/buttonGroup";
import dateTime, { formatting } from "../utils/dateTime";
import formatDate from "../utils/formatDate";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import { Link } from "react-router-dom";

const ProjectReminder = (props) => {
  const { currentReminder, moveForward, completed } = props;
  const { loading, takeAction } = useApi();

  const completeProject = (e, val) => {
    if (!val) return;

    return swal({
      title: `Complete ${currentReminder.name}`,
      text: `Are you sure you want to complete ${currentReminder.name}?`,
      icon: "warning",
    }).then((shouldComplete) => {
      if (shouldComplete) {
        return takeAction("update", `user-projects/${currentReminder.uuid}`, {
          status: 1,
        })
          .then(() => {
            moveForward();
            toast.success("Project marked complete");
          })
          .catch(errorSwal);
      }
    });
  };

  return (
    <>
      <Spinner loading={loading} />
      <div className="col-12 mb-3">
        <Link
          to={`/${currentReminder.link}`}
          className="tx-inverse mb-0 fw-bold"
        >
          {currentReminder.name}
        </Link>
        {currentReminder.client && (
          <p className="mb-0">Client: {currentReminder.client.display_name}</p>
        )}
        <p className="mb-0">
          Scheduled Finish Date:{" "}
          {formatDate(currentReminder.scheduled_finish_date)}
        </p>
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={buttonGroup}
          name="completed"
          label="Completed"
          groupClass="w-100"
          buttonClass="w-100"
          onChange={completeProject}
          options={[
            {
              value: true,
              label: "Yes",
            },
            {
              value: false,
              label: "No",
            },
          ]}
        />
      </div>
      {!completed && completed !== undefined && (
        <>
          <div className="col-lg-12 form-group">
            <Field
              component={dateTime}
              name="scheduled_finish_date"
              label="New Scheduled Finish Date"
              {...formatting}
            />
          </div>
        </>
      )}
    </>
  );
};
const selector = formValueSelector("Reminder");

const mapStateToProps = (state) => {
  return {
    completed: selector(state, "completed"),
  };
};
export default connect(mapStateToProps, {})(ProjectReminder);
