import { FormSubmitHandler } from "redux-form";
import useApi from "../api/useApi";
import Spinner from "../utils/Spinner";
import AccountsPayableSetupForm from "./AccountsPayableSetupForm";
import {
  AccountsPayableAccount,
  TMicrosoftIntegration,
} from "./microsoftTypes";
import { IUseApi } from "../api/apiTypes";
import formError from "../utils/formError";

const AccountsPayableSetup = ({
  microsoft,
}: {
  microsoft: TMicrosoftIntegration;
}) => {
  const { takeAction }: IUseApi = useApi();

  const { data, loading, setData } = useApi(
    `integrations/${microsoft.uuid}/accounts-payable-account`,
    {},
    true,
  );

  const onUpdate: FormSubmitHandler<AccountsPayableAccount> = (values: any) => {
    return takeAction(
      "update",
      `inbound-accounts-payable-account/${values.uuid}`,
      values,
    )
      .then(({ data }) => {
        setData(data.data);
      })
      .catch(formError);
  };

  const onSubmit: FormSubmitHandler<AccountsPayableAccount> = (values: any) => {
    return takeAction("store", `inbound-accounts-payable-account`, {
      ...values,
      integration_id: microsoft.id,
    })
      .then(({ data }) => {
        setData(data.data);
      })
      .catch(formError);
  };

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <p>
        Set up an email to listen for accounts payable and automatically link
        invoices to purchase orders.
      </p>

      <AccountsPayableSetupForm
        initialValues={data ?? undefined}
        form="accounts_payable_setup"
        onSubmit={data ? onUpdate : onSubmit}
      />
    </>
  );
};

export default AccountsPayableSetup;
