import axios from "../api/api";
import { FETCH_NOTIFICATIONS } from "./types";

export const fetchNotifications = (callback) => (dispatch) => {
  return axios
    .get(`/notifications/`)
    .then((response) => response.data)
    .then((notifications) =>
      dispatch({
        type: FETCH_NOTIFICATIONS,
        payload: notifications,
      }),
    )
    .then((res) => {
      if (callback) {
        callback(res);
      }
    })
    .catch((error) => {
      alert(error);
    });
};

export const updateNotifications = (ids) => (dispatch) => {
  const data = {
    ids,
  };

  return axios
    .post(`/notifications`, data)
    .then((response) => response.data.data)
    .then((notifications) =>
      dispatch({
        type: FETCH_NOTIFICATIONS,
        payload: notifications,
      }),
    )
    .catch((error) => {
      alert(error);
    });
};

export const updateNotification = (id) => (dispatch) => {
  return axios
    .put(`/notifications/${id}`)
    .then((response) => response.data.data)
    .then((notifications) =>
      dispatch({
        type: FETCH_NOTIFICATIONS,
        payload: notifications,
      }),
    );
};
