import Select from "react-select";
import PayTable from "./PayTable";
import ProfileBar from "./ProfileBar";
import _ from "lodash";
import { CompletedAtForm } from "./Profile";
import { useAuth } from "../../context/auth-context";

const PayRunDetails = (props) => {
  const { user } = useAuth();

  const filterOptions = _.uniqBy(props.payRun.pays, "branch_name").map(
    ({ branch_name }) => ({
      value: branch_name,
      label: branch_name,
    }),
  );

  const sortOptions = [
    {
      label: "First Name",
      value: "first_name",
    },
    {
      label: "Last Name",
      value: "last_name",
    },
  ];

  const styles = {
    menu: (provided) => {
      return {
        ...provided,
        zIndex: 10,
      };
    },
  };

  return (
    <>
      <ProfileBar {...props} className="mg-y-20 d-block d-lg-none" />
      {user.is_admin && (
        <CompletedAtForm
          initialValues={props.payRun}
          setPayRun={props.setPayRun}
        />
      )}
      <div className="row mg-y-20">
        <div className="col-lg-12 form-group">
          <p className="mg-b-0">
            To toggle pays as completed, please click tick or cross.
          </p>
        </div>
        <div className="col-lg-12">
          <label className="form-control-label tx-inverse tx-semibold">
            Sort By
          </label>
          <Select
            options={sortOptions}
            value={sortOptions.filter((option) => option.value == props.sortBy)}
            onChange={(value) => {
              localStorage.setItem("filter_pay_runs_by", value.value);
              props.setSortBy(value.value);
            }}
            styles={styles}
          />
        </div>
      </div>
      <PayTable pays={props.filteredPays} {...props} />
    </>
  );
};

export default PayRunDetails;
