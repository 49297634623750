import axios from "../api/api";
import {
  DELETE_CALIBRATION,
  FETCH_CALIBRATIONS,
  FETCH_UPCOMING_CALIBRATIONS,
  NEW_CALIBRATION,
  UPDATE_CALIBRATION,
} from "./types";

export const fetchCalibrations =
  (id = "", callback) =>
  (dispatch) => {
    return axios
      .get(`/equipment-calibrations/${id}`)
      .then((response) => response.data)
      .then((calibrations) =>
        dispatch({
          type: FETCH_CALIBRATIONS,
          payload: calibrations,
        }),
      )
      .catch((error) => {
        alert(error);
      });
  };

export const fetchUpcomingCalibrations = (period, callback) => (dispatch) => {
  return axios
    .get(`/equipment-calibrations/upcoming?period=${period}`)
    .then((response) => response.data)
    .then((calibrations) =>
      dispatch({
        type: FETCH_UPCOMING_CALIBRATIONS,
        payload: calibrations,
      }),
    )
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      alert(error);
    });
};

export const createCalibration = (postData, callback) => (dispatch) => {
  return axios
    .post(`/equipment-calibrations/`, postData)
    .then((calibration) =>
      dispatch({
        type: NEW_CALIBRATION,
        payload: calibration,
      }),
    );
};

export const updateCalibration = (postData, callback) => (dispatch) => {
  return axios
    .put(`/equipment-calibrations/${postData.uuid}`, postData)
    .then((calibration) =>
      dispatch({
        type: UPDATE_CALIBRATION,
        payload: calibration,
      }),
    )
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      alert(error);
    });
};

export const deleteCalibration = (uuid) => (dispatch) => {
  return axios
    .delete(`/equipment-calibrations/${uuid}`)
    .then((calibration) =>
      dispatch({
        type: DELETE_CALIBRATION,
        payload: calibration,
        deletedCalibration: uuid,
      }),
    );
};
