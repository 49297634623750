import _ from "lodash";
import React from "react";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";

const SkillList = (props) => {
  const { search, activeStaffSkillFilters, setActiveStaffSkillFilters } = props;
  const { loading, data: skillLevels } = useApi(
    "staff-matrix/skill-level-list",
    [],
    true,
  );

  function groupSkill(formattedList, current) {
    const hasName =
      formattedList.filter((skill) => skill?.name === current.skill_name)
        .length > 0;

    return hasName
      ? formattedList.map((skill) => {
          if (skill.name === current.skill_name) {
            return {
              ...skill,
              levels: skill.levels.concat([
                { id: current.id, uuid: current.uuid, name: current.name },
              ]),
            };
          }
          return skill;
        })
      : formattedList.concat([
          {
            skill_id: current.skill_id,
            skill_uuid: current.skill_uuid,
            name: current.skill_name,
            skill_type: current.skill_type,
            levels: [
              { id: current.id, uuid: current.uuid, name: current.name },
            ],
          },
        ]);
  }

  const groupedSkill = skillLevels.reduce(groupSkill, []);

  const filteredgroupedSkill = search
    ? groupedSkill
        .filter(
          (skill) =>
            skill.name.toLowerCase().includes(props.search.toLowerCase()) ||
            _.includes(
              JSON.stringify(skill.levels).toLowerCase(),
              search.toLowerCase(),
            ),
        )
        .map((skill) => ({
          ...skill,
          levels: skill.levels.filter((level) =>
            level.name.toLowerCase().includes(search.toLowerCase()),
          ),
        }))
    : groupedSkill;

  return loading ? (
    <CustomScaleLoader>
      <p>Fetching skills...</p>
    </CustomScaleLoader>
  ) : (
    filteredgroupedSkill.map((skill) => (
      <div key={skill.skill_uuid} className="row">
        <SkillRow
          item={skill}
          nameStyle="fw-bold"
          isActive={activeStaffSkillFilters.skillType.includes(
            skill.skill_type,
          )}
          updateStaffSkillFilters={(item) => {
            setActiveStaffSkillFilters({
              ...activeStaffSkillFilters,
              skillType: _.xor(activeStaffSkillFilters.skillType, [
                item.skill_type,
              ]),
            });
          }}
        />
        {skill.levels.map((level) => (
          <React.Fragment key={level.uuid}>
            <SkillRow
              item={level}
              nameStyle="ms-3"
              isActive={activeStaffSkillFilters.skillLevel.includes(level.id)}
              updateStaffSkillFilters={(item) =>
                setActiveStaffSkillFilters({
                  ...activeStaffSkillFilters,
                  skillLevel: _.xor(activeStaffSkillFilters.skillLevel, [
                    item.id,
                  ]),
                })
              }
            />
          </React.Fragment>
        ))}
      </div>
    ))
  );
};

export default SkillList;

export const SkillRow = ({
  item,
  isActive,
  bodyStyle = "",
  nameStyle = "",
  updateStaffSkillFilters,
}) => (
  <div className="ro.w" onClick={() => updateStaffSkillFilters(item)}>
    <li
      className={`d-flex align-items-center py-3 ms-3 pointer-hover link-hover ${
        isActive ? "bg-gray-200" : ""
      }`}
    >
      <div className={`media-body ${bodyStyle}`}>
        <p className={`tx-inverse text-wrap mg-b-0 ${nameStyle}`}>
          {item.name}
        </p>
      </div>
    </li>
  </div>
);
