import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { reduxForm, Field, submit } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";

const ReportModal = (props) => {
  const { toggle, modal, handleSubmit, dispatch } = props;
  const { takeAction } = useApi();

  const [annexLabs, setAnnexLabs] = useState([]);
  const onChange = (val) => {
    takeAction(
      "index",
      `laboratories?filter[annex]=1&filter[accreditation_number]=${val.accreditation_number}`,
    ).then(({ data }) => setAnnexLabs(formatLabs(data)));
  };

  return (
    <Modal className="wd-md-600" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Generate Report</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-lg-6">
              <Field
                component={SelectInputAsync}
                url="/laboratories?filter[annex]=0"
                formatData={formatLabs}
                onChangeValue={onChange}
                name="base_laboratory_id"
                label="Base Lab"
              />
            </div>

            <div className="form-group col-lg-6">
              <Field
                component={SelectInputAsync}
                options={annexLabs}
                formatData={formatLabs}
                name="annex_laboratory_id"
                label="Annex Lab"
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          onClick={() => dispatch(submit("GenerateReport"))}
          {...props}
        />
      </ModalFooter>
    </Modal>
  );
};

const formatLabs = (data) => {
  return data.data.map((lab) => {
    return {
      label: lab.branch.name,
      value: lab.id,
      accreditation_number: lab.accreditation_number,
    };
  });
};

const form = reduxForm({ form: "GenerateReport" });

export default form(ReportModal);
