import { useParams } from "react-router-dom";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";

import { PiEyedropperSampleDuotone } from "react-icons/pi";
import { TestRequestPages } from "./ShowPage";

const DashboardTabs = () => {
  const { uuid, page } = useParams<{
    uuid: string;
    page: TestRequestPages;
  }>();

  return (
    <TabList>
      <Tab
        link={`/testrequests/${uuid}/details`}
        active={page === "details"}
        icon="fa fa-file-text-o"
      >
        Details
      </Tab>
      <Tab
        link={`/testrequests/${uuid}/samples`}
        active={page === "samples"}
        icon={<PiEyedropperSampleDuotone className="tx-16" />}
      >
        Samples
      </Tab>
      <Tab
        link={`/testrequests/${uuid}/documents`}
        active={page === "documents"}
        icon="fa fa-folder-open-o"
      >
        Documents
      </Tab>
      <Tab
        link={`/testrequests/${uuid}/tests`}
        active={page === "tests"}
        icon="fa fa-pencil-square-o"
      >
        Tests
      </Tab>
      <Tab
        link={`/testrequests/${uuid}/purchase-order`}
        active={page === "purchase-order"}
        icon="fa fa-pencil-square-o"
      >
        Purchase Orders
      </Tab>
      <Tab
        link={`/testrequests/${uuid}/external-request`}
        active={page === "external-request"}
        icon="fa fa-pencil-square-o"
      >
        External Request
      </Tab>
    </TabList>
  );
};

export default DashboardTabs;
