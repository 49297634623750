import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { IFilter } from "../hooks/useFilter";

interface FilterDropdownProps<StringLiteral, ValueType> {
  filters: IFilter<StringLiteral, ValueType>[];
  toggleFilter: (filterName: StringLiteral, optionValue: ValueType) => void;
  filterCount: number;
}

const FilterDropdown = <StringLiteral, ValueType>({
  filters,
  toggleFilter,
  filterCount,
}: FilterDropdownProps<StringLiteral, ValueType>) => {
  return (
    <UncontrolledDropdown className="ms-3">
      <DropdownToggle color="link" caret className="p-1" aria-label="Filters">
        <FaFilter className="tx-16" />
        <Badge color="" className="p-1 text-black">
          {filterCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu>
        {filters
          .filter((f) => f.options.length > 0)
          .map((filter) => {
            return (
              <React.Fragment key={`${filter.name}`}>
                <DropdownItem header>{filter.label}</DropdownItem>
                {filter.options?.map((option, index) => {
                  return (
                    <DropdownItem
                      onClick={() => toggleFilter(filter.name, option.value)}
                      key={index}
                    >
                      {option.label}{" "}
                      {option.selected ? (
                        <AiOutlineCheck className="ms-1 tx-success" />
                      ) : null}
                    </DropdownItem>
                  );
                })}
              </React.Fragment>
            );
          })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default FilterDropdown;
