import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import SelectInput from "../form/SelectInput";
import {
  AveragingMethods,
  DepreciationCalculationMethods,
  DepreciationMethods,
} from "../equipmentTypeGroups/equipmentTypeGroupTypes";
import RenderField from "../utils/renderField";
import { money, percentage } from "../form/formatters";
import required from "../utils/required";
import { connect } from "react-redux";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import SubmitButton from "../utils/SubmitButton";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";
import BottomLogo from "../utils/BottomLogo";
import FormHeader from "../utils/FormHeader";

interface EquipmentPurchaseProps {
  depreciationRate: number;
  effectiveLifeYears: number;
  taxDepreciationRate: number;
  taxEffectiveLifeYears: number;
  depreciationMethod?: DepreciationMethods;
  taxDepreciationMethod?: DepreciationMethods;
}

interface EquipmentPurchaseFormValues {
  purchase_price: number;
  depreciation_start_date: string;
  depreciation_method: DepreciationMethods;
  averaging_method: AveragingMethods;
  depreciation_rate: number;
  effective_life_years: number;
  depreciation_calculation_method: DepreciationCalculationMethods;

  cost_limit: number;
  purchase: {
    name: string;
    link: string;
    supplier: {
      name: string;
      link: string;
    };
    project: {
      name: string;
      link: string;
    };
  };
}

const EquipmentPurchase = (
  props: InjectedFormProps<
    EquipmentPurchaseFormValues,
    EquipmentPurchaseProps
  > &
    EquipmentPurchaseProps,
) => {
  const {
    handleSubmit,
    depreciationRate,
    effectiveLifeYears,
    initialValues,
    change,
    taxDepreciationRate,
    taxEffectiveLifeYears,
    depreciationMethod,
    taxDepreciationMethod,
  } = props;

  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: taxSettings,
  }: IUseApiWithData<{ tax_depreciation_enabled: boolean }> = useApi(
    "accounting/tax-settings",
    {
      tax_depreciation_enabled: false,
    },
    true,
  );

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<
    EquipmentPurchaseFormValues,
    EquipmentPurchaseProps
  > = (values) => {
    return takeAction("update", `equipment/${uuid}/purchases`, values)
      .then(() => {
        toast.success("Purchase updated successfully.");
      })
      .catch(formError);
  };

  const taxDepreciationDoesntRequireInfo = [
    DepreciationMethods.FullDepreciation,
    DepreciationMethods.NoDepreciation,
  ].includes(taxDepreciationMethod as DepreciationMethods);

  const depreciationDoesntRequireInfo = [
    DepreciationMethods.FullDepreciation,
    DepreciationMethods.NoDepreciation,
  ].includes(depreciationMethod as DepreciationMethods);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 mb-4 ">
          <div className="money bg-white p-3 rounded-lg shadow-sm border position-relative">
            <h5 className="mb-3 ">
              <Link
                className="text-dark"
                to={initialValues.purchase?.link ?? ""}
              >
                {initialValues.purchase?.name}
              </Link>
            </h5>
            <p className="mb-0">
              <Link
                className="text-muted"
                to={initialValues.purchase?.supplier.link ?? ""}
              >
                {initialValues.purchase?.supplier.name}
              </Link>
            </p>
            <p className="mb-0">
              <Link
                className="text-muted"
                to={`/${initialValues.purchase?.project.link}` ?? ""}
              >
                {initialValues.purchase?.project.name}
              </Link>
            </p>
            <BottomLogo size={30} bottom={25} left={230} />
          </div>
        </div>
        <FormErrorAlert error={props.error} />
        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            name={`purchase_price`}
            label="Purchase Price"
            {...money}
            required
            validate={required}
          />
        </div>
        <FormHeader>Book Depreciation</FormHeader>
        <div className="col-lg-12 form-group">
          <Field
            component={SelectInput}
            name={`depreciation_method`}
            label="Depreciation Method"
            required
            validate={required}
            options={[
              {
                label: "No Depreciation",
                value: DepreciationMethods.NoDepreciation,
              },
              {
                label: "Straight Line",
                value: DepreciationMethods.StraightLine,
              },
              {
                label: "Diminishing Value",
                value: DepreciationMethods.DiminishingValue100,
              },
              {
                label: "Diminishing Value (150%)",
                value: DepreciationMethods.DiminishingValue150,
              },
              {
                label: "Diminishing Value (200%)",
                value: DepreciationMethods.DiminishingValue200,
              },
              {
                label: "Full Depreciation",
                value: DepreciationMethods.FullDepreciation,
              },
            ]}
            changeValue={(value: { value: DepreciationMethods }) => {
              console.log(value.value);
              if (
                [
                  DepreciationMethods.FullDepreciation,
                  DepreciationMethods.NoDepreciation,
                ].includes(value.value)
              ) {
                change(`effective_life_years`, null);
                change(`depreciation_rate`, 0);
                change(`depreciation_calculation_method`, null);
              }
            }}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name={`depreciation_start_date`}
            label="Depreciation Start Date"
            type="date"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name={`depreciation_rate`}
            label="Depreciation Rate"
            {...percentage}
            extraProps={{
              disabled: effectiveLifeYears || depreciationDoesntRequireInfo,
            }}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name={`effective_life_years`}
            label="Effective Life (Years)"
            extraProps={{
              disabled: depreciationRate || depreciationDoesntRequireInfo,
            }}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            name="depreciation_calculation_method"
            label="Depreciation Calculation Method"
            options={[
              {
                label: "None",
                value: DepreciationCalculationMethods.None,
              },
              {
                label: "Rate",
                value: DepreciationCalculationMethods.Rate,
              },
              {
                label: "Life",
                value: DepreciationCalculationMethods.Life,
              },
            ]}
            isDisabled={depreciationDoesntRequireInfo}
            required={!depreciationDoesntRequireInfo}
            validate={!depreciationDoesntRequireInfo ? required : null}
          />
        </div>

        {taxSettings.tax_depreciation_enabled && (
          <>
            <FormHeader>Tax Depreciation</FormHeader>
            <div className="col-lg-12 form-group">
              <Field
                component={SelectInput}
                name={`tax_depreciation_method`}
                options={[
                  {
                    label: "No Depreciation",
                    value: DepreciationMethods.NoDepreciation,
                  },
                  {
                    label: "Straight Line",
                    value: DepreciationMethods.StraightLine,
                  },
                  {
                    label: "Diminishing Value",
                    value: DepreciationMethods.DiminishingValue100,
                  },
                  {
                    label: "Diminishing Value (150%)",
                    value: DepreciationMethods.DiminishingValue150,
                  },
                  {
                    label: "Diminishing Value (200%)",
                    value: DepreciationMethods.DiminishingValue200,
                  },
                  {
                    label: "Full Depreciation at Purchase",
                    value: DepreciationMethods.FullDepreciation,
                  },
                ]}
                label="Depreciation Method"
                required
                changeValue={(value: { value: DepreciationMethods }) => {
                  console.log(value.value);
                  if (
                    [
                      DepreciationMethods.FullDepreciation,
                      DepreciationMethods.NoDepreciation,
                    ].includes(value.value)
                  ) {
                    change(`tax_effective_life_years`, null);
                    change(`tax_depreciation_rate`, 0);
                    change(`tax_depreciation_calculation_method`, null);
                  }
                }}
                validate={required}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`tax_depreciation_start_date`}
                label="Tax Depreciation Start Date"
                type="date"
                required
                validate={required}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`tax_depreciation_rate`}
                label="Rate"
                {...percentage}
                extraProps={{
                  disabled:
                    taxEffectiveLifeYears || taxDepreciationDoesntRequireInfo,
                }}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`tax_effective_life_years`}
                label="Effective Life (Years)"
                extraProps={{
                  disabled:
                    taxDepreciationRate || taxDepreciationDoesntRequireInfo,
                }}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={SelectInput}
                name={`tax_depreciation_calculation_method`}
                options={[
                  {
                    label: "None",
                    value: DepreciationCalculationMethods.None,
                  },
                  {
                    label: "Rate",
                    value: DepreciationCalculationMethods.Rate,
                  },
                  {
                    label: "Life",
                    value: DepreciationCalculationMethods.Life,
                  },
                ]}
                label="Depreciation Calculation Method"
                required={!taxDepreciationDoesntRequireInfo}
                isDisabled={taxDepreciationDoesntRequireInfo}
                validate={!taxDepreciationDoesntRequireInfo ? required : null}
              />
            </div>
          </>
        )}

        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            {...money}
            name={`cost_limit`}
            label="Cost Limit"
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("EquipmentPurchase");

  return {
    depreciationMethod: selector(state, "depreciation_method"),
    depreciationRate: selector(state, "depreciation_rate"),
    effectiveLifeYears: selector(state, "effective_life_years"),
    taxDepreciationRate: selector(state, "tax_depreciation_rate"),
    taxEffectiveLifeYears: selector(state, "tax_effective_life_years"),
    taxDepreciationMethod: selector(state, "tax_depreciation_method"),
  };
};

const form = reduxForm<EquipmentPurchaseFormValues, EquipmentPurchaseProps>({
  form: "EquipmentPurchase",
});

export default connect(mapStateToProps, {})(form(EquipmentPurchase));
