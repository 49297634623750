import React, { Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import SelectInputAsync from "../../utils/SelectInputAsync";
import renderField from "../../utils/renderField";
import isSubmitting from "../../utils/submitting";
import required from "../../utils/required";

const Submit = ({ handleSubmit, previousPage, submitting, initialValues }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="form-group col-12">
        <h5 className="text-dark">Submit</h5>
      </div>
      <div className="form-group col-12">
        <label>
          <Field
            component="input"
            type="radio"
            name="general.approved"
            value="0"
          />{" "}
          Been approved
        </label>
        {" | "}
        <label>
          <Field
            component="input"
            type="radio"
            name="general.approved"
            value="1"
          />{" "}
          Been Approved Subject To
        </label>
        {" | "}
        <label>
          <Field
            component="input"
            type="radio"
            name="general.approved"
            value="2"
          />{" "}
          Not Been Approved
        </label>
      </div>
      {!initialValues.general.completed_at && (
        <Fragment>
          <div className="form-group col-6">
            <Field
              label="Send Site Inspection To"
              multiple
              url={`/clients/${initialValues.job.project.client.uuid}/contacts`}
              component={SelectInputAsync}
              name="general.sent_to"
              formatData={formatData}
            />
          </div>
          <div className="form-group col-6">
            <Field
              label="Concreter Name"
              component={renderField}
              name="general.concreter_name"
            />
          </div>
          <div className="form-group col-6">
            <Field
              label="P.O."
              component={renderField}
              name="deliverable.purchase_order"
            />
          </div>
          <div className="form-group col-6">
            <Field
              label="Concreter Email"
              component={renderField}
              type="email"
              name="general.concreter_email"
            />
          </div>
        </Fragment>
      )}
      <div className="col-12 form-group">
        <Field
          label="Comments"
          component={renderField}
          textarea
          name="general.comments"
        />
      </div>
      {!initialValues.general.completed_at && (
        <div className="form-group col-12">
          <label>
            <Field
              component={renderField}
              type="checkbox"
              name="general.engineer_approved"
              value="1"
              validate={required}
            />{" "}
            I state that all this information is factually accurate and agree to
            have my signature put on this site inspection
          </label>
        </div>
      )}
    </div>
    <div className="row mg-y-20">
      <div className="col-6">
        <button
          className="btn w-100 bg-gray-400 tx-gray-800"
          type="button"
          onClick={previousPage}
        >
          <i className="icon ion-reply" /> Reinforcement
        </button>
      </div>
      <div className="col-6">
        <button
          className="btn w-100 btn-primary"
          disabled={submitting}
          type="submit"
        >
          {isSubmitting(submitting, "Submit", "Generating Site Inspection...")}
        </button>
      </div>
    </div>
  </form>
);

const formatData = ({ data }) =>
  data.map((d) => {
    return {
      label: d.name,
      value: d.id,
    };
  });

export default reduxForm({
  form: "addResidentialSiteInspection",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Submit);
