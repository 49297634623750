import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import Prices from "./Prices";
import { BillableItem, BillableItemUnit } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const StandardPricing = () => {
  const { user } = useAuth();
  return (
    <div>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/price-lists/" />
      <HeaderPage
        titlePage="Standard Price List"
        pageDescription={
          <>
            The Billable Items and Billable Item Units feature enables tracking
            and billing for project items and services, with units of
            measurement specified for each item, and can be linked to
            deliverables and prices for accurate client billing, and optionally
            marked as requiring insurance.
          </>
        }
        relatedLinks={[
          {
            name: "Billable Items",
            link: "/billable-items",
            model: BillableItem,
          },
          {
            name: "Billable Item Units",
            link: "/billable-item-units",
            model: BillableItemUnit,
          },
        ]}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "", name: "Standard Price List", active: true },
        ]}
      />
      <Prices
        chargeableType="App\Models\Organisation"
        chargeableId={user.active_organisation.id}
      />
    </div>
  );
};

export default StandardPricing;
