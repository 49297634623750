import React from "react";
import { toast } from "react-toastify";
import { Field, reduxForm, formValueSelector } from "redux-form";
import errorSwal from "../utils/errorSwal";

import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import { connect } from "react-redux";

const CompactionForm = (props) => {
  const { updateCompaction, handleSubmit, wetMassRetained19, massOfSample } =
    props;

  const onSubmit = (values) => {
    return updateCompaction(values)
      .then(() => {
        toast.success(`Compaction updated successfully`);
      })
      .catch((err) => errorSwal(err));
  };

  const use37Mm = (100 * wetMassRetained19) / massOfSample > 20;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12">
          <h6 className="tx-inverse">Tester Details</h6>
          <hr />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            required
            validate={required}
            name="tester_id"
            label="Tester"
            component={SelectInputAsync}
            url="/users/staff"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            required
            validate={required}
            component={renderField}
            label="Tested On"
            name="tested_at"
            type="date"
          />
        </div>
        <div className="col-12">
          <h6 className="tx-inverse">Equipment</h6>
          <hr />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            required
            validate={required}
            name="balance_id"
            label="Balance"
            component={SelectInputAsync}
            url="/equipment-type-equipment?filter[type]=Balance"
            formatData={formatEquipmentType}
          />
        </div>

        <div className="col-lg-3 form-group">
          <Field
            required
            validate={required}
            name="oven_id"
            label="Oven"
            component={SelectInputAsync}
            url="/equipment-type-equipment?filter[type]=Oven"
            formatData={formatEquipmentType}
          />
        </div>

        <div className="col-lg-3 form-group">
          <Field
            required
            validate={required}
            name="mould_id"
            label="Mould"
            component={SelectInputAsync}
            url="/equipment-type-equipment?filter[type]=Mould A"
            formatData={formatEquipmentType}
          />
        </div>

        <div className="col-lg-3 form-group">
          <Field
            required
            validate={required}
            name="hammer_id"
            label="Hammer"
            component={SelectInputAsync}
            url="/equipment-type-equipment?filter[type]=Compaction Hammer"
            formatData={formatEquipmentType}
          />
        </div>

        <div className="col-lg-3">
          <h6 className="tx-inverse">Moistures</h6>
          <hr />
        </div>
        <div className="col-lg-6">
          <h6 className="tx-inverse">Prep</h6>
          <hr />
        </div>
        <div className="col-lg-3">
          <h6 className="tx-inverse">Curing Time</h6>
          <hr />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            component={renderField}
            label="Container"
            name="moisture.container_id"
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            required
            validate={required}
            name="mass_of_sample"
            label="Mass of Sample"
            component={renderField}
          />
        </div>
        <div className="col-lg-3" />
        <div className="col-lg-3 form-group">
          <Field
            required
            validate={required}
            type="date"
            name="curing_start_time"
            label="Curing Start Time"
            component={renderField}
          />
        </div>

        <div className="col-lg-3 form-group">
          <Field
            component={renderField}
            type="number"
            extraProps={{ step: "0.01" }}
            label="Tin Weight (g)"
            name="moisture.mass_of_tin"
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            required
            validate={required}
            name="wet_mass_retained_19"
            label="Wet Mass of Oversize (19mm)"
            component={renderField}
          />
        </div>
        <div className="col-lg-3 form-group">
          {use37Mm && (
            <Field
              required
              validate={required}
              name="wet_mass_retained_37"
              label="Wet Mass of Oversize (37.5mm)"
              component={renderField}
            />
          )}
        </div>
        <div className="col-lg-3 form-group">
          {/* enum */}
          <Field
            required
            validate={required}
            name="estimated_variation_from_omc"
            label="Estimated Variation From OMC"
            component={SelectInputAsync}
            options={[]}
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            component={renderField}
            type="number"
            extraProps={{ step: "0.01" }}
            label="Wet Mass (g)"
            name="moisture.mass_of_tin_and_wet_soil"
          />
        </div>

        <div className="col-lg-3 form-group">
          <Field
            name="wet_oversize_percentage"
            label={`Wet OverSize Percentage (${use37Mm ? "37.5" : "19"}mm)`}
            component={renderField}
            readOnly
          />
        </div>
        <div className="col-lg-3" />
        <div className="col-lg-3 form-group">
          {/* enum */}
          <Field
            required
            validate={required}
            name="plasticity"
            label="Plasticity"
            component={SelectInputAsync}
            options={[]}
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            component={renderField}
            type="number"
            extraProps={{ step: "0.01" }}
            label="Dry Mass (g)"
            name="moisture.dry_mass"
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="dry_mass_retained"
            label="Dry Mass Retained"
            component={renderField}
          />
        </div>
        <div className="col-lg-3" />

        <div className="col-lg-3 form-group">
          <Field
            name="minimum_curing_time"
            label="Minimum Curing Time"
            component={renderField}
            readOnly
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="moisture.moisture_content"
            label="Moisture Content"
            component={renderField}
            readOnly
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="under_balance_weight"
            label="Under Balance Weight"
            component={renderField}
          />
        </div>
        <div className="col-lg-3" />
        <div className="col-lg-3 form-group">
          <Field
            name="curing_finish_time"
            label="Curing Finish Time"
            component={renderField}
            readOnly
          />
        </div>
        <div className="col-lg-3" />
        <div className="col-lg-3 form-group">
          <Field
            name="volume_of_oversize"
            label="Volume of Oversize"
            component={renderField}
            readOnly
          />
        </div>
        <div className="col-lg-6" />
        <div className="col-lg-3" />
        <div className="col-lg-3 form-group">
          <Field
            name="density_of_oversize"
            label="Density of Oversize"
            component={renderField}
            readOnly
          />
        </div>
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const formatEquipmentType = ({ data }) => {
  return data.map((d) => ({
    label: d.name,
    value: d.id,
  }));
};

const selector = formValueSelector("Compaction");

const mapStateToProps = (state) => ({
  wetMassRetained19: selector(state, "wet_mass_retained_19"),
  massOfSample: selector(state, "mass_of_sample"),
});

const form = reduxForm({ form: "Compaction" });

export default connect(mapStateToProps, {})(form(CompactionForm));
