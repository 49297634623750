import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Form from "./Form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const Add = () => {
  const { takeAction } = useApi();
  const history = useHistory();

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      custom_fields: values.custom_fields.map((field: any, index: any) => ({
        ...field,
        order: index,
      })),
    };

    return takeAction("store", "safety-initiatives", data)
      .then(({ data }: { data: any }) => {
        history.push(`/safety-initiatives/${data.data.uuid}/details`);
        toast.success(`${values.name} added.`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Add New Safety Initiative"
        crumbs={[
          { name: "Project Forms", link: "/project-forms" },
          { name: "Add Project Form", link: "", active: true },
        ]}
      />
      <Form
        onSubmit={onSubmit}
        form="AddProjectForm"
        initialValues={{
          custom_fields: [{}],
          show_field: true,
          price_bands: [{}],
        }}
      />
    </>
  );
};

export default Add;
