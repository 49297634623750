import { AxiosResponse } from "axios";
import InformationAlert from "../utils/InformationAlert";
import ProfileSingleFile from "../utils/ProfileSingleFile";
import { ShowTenderPageProps } from "./ShowTenderPage";
import { Link, useParams } from "react-router-dom";

const TenderQuotePage = (props: ShowTenderPageProps) => {
  const { tender, setTender } = props;

  const { uuid } = useParams<{ uuid: string }>();

  const onUploadFile = ({
    data,
  }: AxiosResponse<{
    data: { link: string }[] | { link: string };
  }>) => {
    if (!tender.file) {
      return;
    }

    const link = Array.isArray(data.data) ? data.data[0].link : data.data.link;

    setTender({
      ...tender,
      file: {
        ...tender.file,
        link,
      },
    });
  };

  if (!tender.total_price && !tender.file?.link) {
    return (
      <InformationAlert
        title="No Price Added"
        body={
          <div>
            <p>
              Please <Link to={`/tenders/${uuid}/details`}>click here</Link> add
              a total price to the tender before uploading a quote.
            </p>
            <p>
              Adding tender prices allows tracking and analysis of submitted
              prices, providing insights on market rates, better negotiations,
              and informed contract decisions.
            </p>
            <p>It also increases transparency and streamlines procurement.</p>
          </div>
        }
        type="warning"
      />
    );
  }

  return (
    <ProfileSingleFile
      whenSubmitted={onUploadFile}
      {...props}
      link={tender.file?.inline_link}
      downloadLink={tender.file?.link}
      mime_type={tender.file?.mime_type}
      documentable_id={tender.uuid}
      documentable_type="App\Models\Tender"
      bucket={`tenders/${tender.uuid}`}
    />
  );
};

export default TenderQuotePage;
