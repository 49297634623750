import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import formError from "../utils/formError";
import EditModal from "./AddEditModal";
import TemplatesListModal from "./TemplatesListModal";

const List = (props) => {
  const { chartOfAccounts, setChartOfAccounts, types, taxCodes } = props;
  const { takeAction, loading } = useApi();
  const { modal, toggle, setModal } = useModal();
  const { user } = useAuth();
  const [selectedItem, setSelectedItem] = useState();
  const [templates, setTemplates] = useState([]);
  const { modal: templatesModal, toggle: templatesToggle } = useModal();

  const userCanUpdate = user.hasAccessTo(
    "App\\Models\\ChartOfAccount",
    "update",
  );

  const userCanDelete = user.hasAccessTo(
    "App\\Models\\ChartOfAccount",
    "delete",
  );

  const onSubmit = (chart) => {
    return takeAction("update", `chart-of-accounts/${chart.uuid}`, chart)
      .then(({ data }) => {
        setChartOfAccounts(
          chartOfAccounts.map((account) => {
            return account.uuid !== data.data.uuid
              ? {
                  ...account,
                  sales_account: data.data.sales_account
                    ? false
                    : account.sales_account,
                }
              : data.data;
          }),
        );
        setModal(false);
        toast.success(` ${chart.code} - ${chart.name} updated.`);
      })
      .catch(formError);
  };

  const deleteRow = (chart) => {
    deleteSwal(`${chart.code} - ${chart.name}`)
      .then(() => takeAction("destroy", `/chart-of-accounts/${chart.uuid}`))
      .then(({ data }) => {
        if (!data?.has_template) {
          const removedChart = chartOfAccounts.filter(
            (charts) => charts.uuid !== chart.uuid,
          );
          setChartOfAccounts(removedChart);
          return toast.success(`${chart.code} - ${chart.name} deleted`);
        }
        setTemplates(data.data);
        setSelectedItem(chart);
        templatesToggle();
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      id: "code",
      header: "Code",
      cell: ({ row, getValue }) => {
        const account = row.original;

        return userCanUpdate ? (
          <>
            <Button
              color="link"
              className="p-0"
              onClick={() => {
                setSelectedItem(account);
                toggle();
              }}
            >
              {getValue()}
            </Button>{" "}
          </>
        ) : (
          getValue()
        );
      },
      accessorFn: (account) => account.code,
    },
    {
      id: "name",
      header: "Name",
      accessorFn: (account) => account.name,
    },
    {
      id: "type_name",
      header: "Type",
      accessorFn: (account) => account.type_name,
    },
    {
      id: "tax_code_description",
      header: "Tax Code",
      accessorFn: (account) => account.tax_code_description,
    },
    {
      id: "description",
      header: "Description",
      accessorFn: (account) => account.description,
    },
    {
      id: "credit_card",
      header: "Payment",
      accessorFn: (account) => (account.credit_card ? "Yes" : "No"),
    },
    {
      id: "purchase_order",
      header: "Purchase Order",
      accessorFn: (account) => (account.purchase_order ? "Yes" : "No"),
    },
    {
      accessorKey: "expense_claim",
      header: "Expense Claim",
      accessorFn: (account) => (account.expense_claim ? "Yes" : "No"),
    },
    {
      accessorKey: "budget_control",
      header: "Budget Control",
      accessorFn: (account) => (account.budget_control ? "Yes" : "No"),
    },
    {
      accessorKey: "sales_account",
      header: "Sales Account",
      accessorFn: (account) => (account.sales_account ? "Yes" : "No"),
    },
  ];

  if (userCanDelete) {
    columns.push({
      accessorKey: "delete",
      header: "Delete",
      cell: ({ row }) => (
        <DeleteButton deleteFunction={() => deleteRow(row.original)} />
      ),
    });
  }

  return (
    <>
      <ReactTable columns={columns} data={chartOfAccounts} loading={loading} />
      {!templatesModal && selectedItem && (
        <EditModal
          taxCodes={taxCodes}
          modal={modal}
          toggle={toggle}
          title={`Edit ${selectedItem.code} - ${selectedItem.name}`}
          types={types}
          form="EditChartOfAccounts"
          initialValues={selectedItem}
          onSubmit={onSubmit}
          organisationGroupMembers={props.organisationGroupMembers}
        />
      )}
      {templatesModal && selectedItem && (
        <TemplatesListModal
          chartOfAccount={`${selectedItem.code} - ${selectedItem.name}`}
          data={templates}
          modal={templatesModal}
          toggle={templatesToggle}
        />
      )}
    </>
  );
};

export default List;
