import React from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import DashboardEditForm from "./DashboardEditForm";

const DashboardEditDropdown = props => {
  const { toggle, dropDownOpen, position, job, users } = props;

  window.addEventListener("keydown", e => {
    if (e.key === "Escape" && dropDownOpen) {
      toggle();
    }
  });

  return (
    <Dropdown
      inNavbar={true}
      toggle={toggle}
      tag="span"
      isOpen={dropDownOpen}
      style={{
        position: "fixed",
        left: position.x,
        top: position.y,
        zIndex: "200"
      }}
    >
      <DropdownMenu className="pd-30 pd-sm-40" style={{ width: "600px" }}>
        <h6 className="tx-gray-800 tx-uppercase mb-3 tx-bold">
          Edit {job.name}
        </h6>
        <DashboardEditForm {...props} initialValues={job} users={users} />
      </DropdownMenu>
    </Dropdown>
  );
};

export default DashboardEditDropdown;
