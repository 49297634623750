import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  getFormValues,
  reduxForm,
} from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import RenderField from "../utils/renderField";
import FormHeader from "../utils/FormHeader";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import api from "../api/api";
import AsyncSelectInput from "../form/AsyncSelectInput";
import { connect } from "react-redux";
import useApi from "../api/useApi";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { EmploymentHeroUser } from "./userTypes";
import { IUseApi } from "../api/apiTypes";
import FormErrorAlert from "../form/FormErrorAlert";
import { toast } from "react-toastify";
import formError from "../utils/formError";

interface UserPayrollProps {
  formValues?: EmploymentHeroUser;
}

const UserPayroll = (
  props: UserPayrollProps &
    InjectedFormProps<EmploymentHeroUser, UserPayrollProps>,
) => {
  const { handleSubmit, formValues, change } = props;

  const { userId } = useParams<{ userId: string }>();

  const { takeAction }: IUseApi<{}, {}> = useApi();

  const { data } = useApi(`payroll-integration/users/${userId}`, null, true);

  const onSubmit: FormSubmitHandler<EmploymentHeroUser> = (values) => {
    return takeAction("update", `payroll-integration/users/${userId}`, values)
      .then(({ data }) => {
        toast.success("User updated");
      })
      .catch(formError);
  };

  useEffect(() => {
    if (data) {
      props.initialize(data);
    }
  }, [data]);

  if (!data) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <FormHeader>Super Details</FormHeader>
        <div
          className={`${
            formValues?.superFund1_EmployerNominatedFund
              ? "col-lg-12"
              : "col-lg-4"
          } form-group`}
        >
          <Field
            component={renderToggleInput}
            name="superFund1_EmployerNominatedFund"
            label="Employer Nominated Fund"
          />
        </div>
        {!formValues?.superFund1_EmployerNominatedFund && (
          <>
            <div className="col-lg-4 form-group">
              <Field
                name="superFund1_ProductCode"
                component={AsyncSelectInput}
                label="Super Fund"
                changeValue={(value: any) => {
                  change("superFund1_FundName", value.fundName);
                }}
                defaultOptions={[
                  {
                    label: (
                      <div>
                        <p className="mb-0 text-dark">
                          {data?.superFund1_FundName}
                        </p>
                        <small>{data?.superFund1_ProductCode}</small>
                      </div>
                    ),
                    value: data?.superFund1_ProductCode,
                    fundName: data?.superFund1_FundName,
                  },
                ]}
                required
                validate={required}
                asyncFunction={(inputValue: string) =>
                  api
                    .get(`/payroll-integration/super-funds?term=${inputValue}`)
                    .then(({ data }) =>
                      data.data.map((d: any) => ({
                        label: (
                          <div>
                            <p className="mb-0 text-dark">{d.productName}</p>
                            <small>{d.productCode}</small>
                          </div>
                        ),
                        value: d.productCode,
                        fundName: d.productName,
                      })),
                    )
                }
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={RenderField}
                name="superFund1_MemberNumber"
                label="Member Number"
                required
                validate={required}
              />
            </div>
          </>
        )}

        <FormHeader>Tax Details</FormHeader>
        <div className="col-lg-4 form-group">
          <Field
            component={renderToggleInput}
            name="stslDebt"
            label="STSL Debt"
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={renderToggleInput}
            name="claimTaxFreeThreshold"
            label="Claim Tax Free Threshold"
          />
        </div>

        <FormHeader>Bank Account</FormHeader>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="bankAccount1_AccountName"
            label="Bank Account Name"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="bankAccount1_AccountNumber"
            label="Bank Account Number"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="bankAccount1_BSB"
            label="Bank Account BSB"
            required
            validate={required}
          />
        </div>

        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  return {
    formValues: getFormValues(form)(state) as EmploymentHeroUser,
  };
};

const form = reduxForm<EmploymentHeroUser, UserPayrollProps>({});

export default connect(mapStateToProps, {})(form(UserPayroll));
