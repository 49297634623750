import React from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import ClientFeedbackTemplateForm from "./ClientFeedbackTemplateForm";
import { ClientFeedbackTemplateIndex } from "./clientFeedbackTypes";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import errorSwal from "../utils/errorSwal";

const AddClientFeedbackTemplate = () => {
  const { takeAction }: IUseApi<{}, { data: ClientFeedbackTemplateIndex }> =
    useApi();
  const history = useHistory();

  const onSubmit = (values: any) => {
    return takeAction("store", "client-feedback-templates", {
      ...values,
      custom_fields: values.custom_fields.map((field: any, index: number) => ({
        ...field,
        order: index,
      })),
    })
      .then(({ data }) => {
        toast.success(`${data.data.name} added successfully`);
        history.push(`/client-feedback-types/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <div>
      <HeaderPage
        crumbs={[
          { name: "Client Feedback Types", link: "/client-feedback-types" },
          { name: "Add Client Feedback Type", link: "", active: true },
        ]}
        titlePage="Add Client Feedback Type"
      />
      <ClientFeedbackTemplateForm
        includeCustomFields
        form="AddClientFeedbackTemplate"
        onSubmit={onSubmit}
        initialValues={{
          custom_fields: [{}],
        }}
      />
    </div>
  );
};

export default AddClientFeedbackTemplate;
