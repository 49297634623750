import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "../api/api";

const SelectDashboard = ({ setSelected, selected, className }) => {
  const [open, setOpen] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const toggle = () => setOpen(!open);

  useEffect(() => {
    axios(`/user-dashboards`).then(({ data }) => {
      setDashboards(data.data);
    });
  }, []);

  return (
    <ButtonDropdown
      isOpen={open}
      toggle={toggle}
      className={`w-100 ${className}`}
    >
      <DropdownToggle
        outline={selected ? true : false}
        color="primary"
        block
        caret
        className="mt-3"
      >
        {selected ? selected.label : "Select Dashboard"}
      </DropdownToggle>
      <DropdownMenu>
        {_.sortBy(dashboards, "label").map((dashboard, index) => {
          return (
            <DropdownItem key={index} onClick={() => setSelected(dashboard)}>
              {dashboard.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default SelectDashboard;
