import { AiOutlineClose } from "react-icons/ai";
import { FiCheck } from "react-icons/fi";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { BossUser, EmploymentHeroUser, SyncUsers } from "./SyncUsers";
import Select from "react-select";
import { useState } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

interface EmploymentHeroUserCardProps {
  user: BossUser;
  users: SyncUsers;
  setUsers: (users: SyncUsers) => void;
  refreshData: Function;
}

const EmploymentHeroUserCard = ({
  user,
  users,
  setUsers,
  refreshData,
}: EmploymentHeroUserCardProps) => {
  const { takeAction, loading }: IUseApi = useApi();

  const syncedTo =
    user.external_integration_id &&
    users.employment_hero_users.find(
      (u) => u.id == user.external_integration_id,
    );

  const estimatedUser =
    !user.external_integration_id &&
    users.employment_hero_users.find(
      (u) =>
        u.email == user.email ||
        u.email == user.secondary_email ||
        u.name == user.name,
    );

  const [selected, setSelected] = useState(
    estimatedUser
      ? {
          value: estimatedUser.id,
          label: `${estimatedUser.name} - ${estimatedUser.email}`,
          data: estimatedUser,
        }
      : null,
  );

  const createUser = () => {
    return takeAction(
      "store",
      `integrations/employment-hero/users/sync/${user.uuid}`,
    )
      .then(() => {
        toast.success(`${user.name} synced to Employment Hero`);
        refreshData();
      })
      .catch(errorSwal);
  };

  const syncUser = () => {
    if (!selected) return toast.error("Please select a user");

    return takeAction(
      "update",
      `integrations/employment-hero/users/sync/${user.uuid}`,
      {
        employment_hero_id: selected.value,
      },
    )
      .then(() => {
        toast.success(`${user.name} synced to Employment Hero`);
        setUsers({
          ...users,
          boss_users: users.boss_users.map((u) =>
            u.uuid !== user.uuid
              ? u
              : {
                  ...u,
                  external_integration_id: selected.value,
                },
          ),
        });
      })
      .catch(errorSwal);
  };

  return (
    <div className="col-lg-6 mb-3">
      <div className="bg-white border rounded-lg">
        <div className="p-3">
          <div className="d-flex mb-1">
            <p className="mb-0 tx-inverse fw-bolder">{user.name}</p>
            <p className="mb-0 ms-auto">
              {user?.external_integration_id ? (
                <FiCheck className="tx-success" />
              ) : (
                <AiOutlineClose className="tx-danger" />
              )}
            </p>
          </div>
          <p className="mb-1">{user.email}</p>
          {syncedTo && (
            <p className="mb-0">
              Synced to <span className="text-dark">{syncedTo.name}</span>
            </p>
          )}
          {/* {user?.employment_hero_user ? (
            <div className="mt-3 tx-12 mb-0">
              Employment Hero email:{" "}
              <span className="tx-inverse">
                {user.employment_hero_user.email}
              </span>
            </div>
          ) : null} */}
        </div>
        {user.external_integration_id ? null : (
          <div
            className={`p-3 border-top border-0 tx-inverse bg-light rounded-bottom w-100`}
          >
            <Select
              value={selected}
              // @ts-ignore
              onChange={setSelected}
              options={users.employment_hero_users.map((u) => ({
                value: u.id,
                label: `${u.name} - ${u.email}`,
                data: u,
              }))}
              // @ts-ignore
              formatOptionLabel={({ data }: { data: EmploymentHeroUser }) => {
                return (
                  <>
                    <p className="mb-0">{data.name}</p>
                    <p className="text-secondary fs-6 mb-0">{data.email}</p>
                  </>
                );
              }}
            />
            <div className="d-flex space-x-3">
              <Button
                size="sm"
                color="primary"
                onClick={syncUser}
                disabled={loading}
                outline
                className="mt-3"
              >
                {isSubmitting(loading, "Sync", "Syncing...")}
              </Button>
              <Button
                size="sm"
                color="primary"
                disabled={loading}
                outline
                className="mt-3"
              >
                Create in Employment Hero
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmploymentHeroUserCard;
