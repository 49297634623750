import React, { Fragment } from "react";
import { ScaleLoader } from "react-spinners";
import { primary } from "./Colours";

const CustomScaleLoader = ({ children, color = "" }) => {
  return (
    <Fragment>
      {children && (
        <div className="d-flex justify-content-center">{children}</div>
      )}
      <ScaleLoader
        sizeUnit={"px"}
        height={40}
        className="d-flex justify-content-center"
        color={color || primary}
        width={4}
        radius={0}
        loading
      />
    </Fragment>
  );
};

export default CustomScaleLoader;
