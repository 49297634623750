import React from "react";
import FormErrorAlert from "../form/FormErrorAlert";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import isSubmitting from "../utils/submitting";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import renderToggleInput from "../utils/renderToggleInput";
import Spinner from "../utils/Spinner";

interface FormValues {
  password: string;
  password_confirmation: string;
}

const SetPassword = (props: InjectedFormProps<FormValues>) => {
  const { handleSubmit, submitting } = props;

  const { setUser } = useAuth();

  const history = useHistory();

  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading }: IUseApi<{ has_password?: boolean }> = useApi(
    `users/${uuid}/set-password`,
    null,
    true,
  );

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<FormValues> = (values) => {
    return takeAction("store", `users/${uuid}/set-password`, values)
      .then(({ data }) => {
        setUser(data.data);
        toast.success("Password set successfully");
        history.push("/home");
      })
      .catch(formError);
  };

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <div className="signin-wrapper">
      <div className="signin-box signup">
        {data?.has_password ? (
          <>
            <h2 className="slim-logo d-flex justify-content-center">
              <a href="https://thebossapp.com.au">
                <img
                  style={{ height: "75px" }}
                  src="/img/boss-app.png"
                  alt="The BOSS App"
                />
              </a>
            </h2>
            <p className="text-center text-dark mb-0 tx-18">
              Password already set.
            </p>
            <Link to="/login" className="btn btn-primary btn-block mt-3 w-100">
              Login
            </Link>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className="slim-logo d-flex justify-content-center">
              <a href="https://thebossapp.com.au">
                <img
                  style={{ height: "75px" }}
                  src="/img/boss-app.png"
                  alt="The BOSS App"
                />
              </a>
            </h2>

            <FormErrorAlert error={props.error} />

            <div className="row">
              <div className="col-12 form-group">
                <Field
                  component={RenderField}
                  name="password"
                  label="Password"
                  type="password"
                  validate={required}
                  required
                />
              </div>
              <div className="col-12 form-group">
                <Field
                  component={RenderField}
                  name="password_confirmation"
                  label="Confirm Password"
                  type="password"
                  validate={required}
                  required
                />
              </div>
              <div className="col-12 form-group">
                <Field
                  validate={required}
                  required
                  component={renderToggleInput}
                  label="Terms of Service"
                  name="accepted"
                />
                <small className="d-block">
                  You have read and agree to The BOSS App's{" "}
                  <a
                    href="https://landing.thebossapp.com.au/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </a>
                  .
                </small>
              </div>
            </div>
            <div>
              <button
                className="btn btn-primary w-100 btn-signin"
                disabled={submitting}
              >
                {isSubmitting(
                  submitting,
                  "Set my password",
                  "Setting Password...",
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

const form = reduxForm<FormValues>({ form: "SetPassword" });

export default form(SetPassword);
