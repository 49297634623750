import React, { useEffect } from "react";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { Field } from "redux-form";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import { primary } from "../utils/Colours";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInput from "../form/SelectInput";

const PricingFields = (props) => {
  const {
    pricing,
    pricingItems,
    units,
    index,
    fields,
    projectUuid,
    change,
    initialValues,
    isInvoiced,
    deliverable,
    setDeliverable,
    initialize,
    dispatch,
    job,
    pricingTypes,
  } = props;

  const { user } = useAuth();

  const value = fields.get(index);

  const [selectedUnits, setSelectedUnits] = React.useState("");
  const { takeAction, loading: deleting } = useApi();

  useEffect(() => {
    if (pricingTypes.length === 1) {
      change(`${pricing}.line_item_type`, pricingTypes[0].value);
    }
  }, [pricingTypes]);

  const changeUnits = (e, val) => setSelectedUnits(val);

  const isPositionAdmin = user.is_position_admin;

  const changeId = (e, val) => {
    if (!isPositionAdmin) {
      return;
    }

    const queryString = `project-pricing/${projectUuid}?item_type=${encodeURIComponent(
      value?.line_item_type,
    )}&item_id=${val}&units_id=${selectedUnits}`;

    axios.get(queryString).then(({ data }) => {
      if (data.price) {
        change(`${pricing}.price`, data.price);
      }
    });
  };

  const deletePrice = () => {
    if (value.uuid) {
      deleteSwal()
        .then(() => {
          return takeAction(
            "destroy",
            `deliverables/pricing/${initialValues.pricing[index].uuid}`,
          );
        })
        .then(() => {
          toast.success("Pricing deleted");
          const del = {
            ...deliverable,
            pricing: deliverable.pricing.filter(
              (d) => initialValues.pricing[index].uuid !== d.uuid,
            ),
          };
          dispatch(initialize("deliverableDetails", { ...del }));
          setDeliverable(del);
        })
        .catch(errorSwal);
    } else {
      fields.remove(index);
    }
  };

  const selectedItems = pricingItems.filter(
    (item) => item.type === value?.line_item_type,
  );

  if (deleting) {
    return (
      <div className="mb-3 d-flex align-items-center">
        <ClipLoader color={primary} /> Deleting...
      </div>
    );
  }

  return (
    <div className="row border-bottom mb-3">
      {value?.description && (
        <div className="col-lg-12 form-group">
          <p className="mb-0 text-dark">{value?.description}</p>
        </div>
      )}
      {pricingTypes.length > 1 ? (
        <div className="col-lg-4 form-group">
          <Field
            validate={required}
            required
            component={SelectInput}
            name={`${pricing}.line_item_type`}
            options={pricingTypes}
            label="Item Type"
          />
        </div>
      ) : null}
      <div className="col-lg-4 form-group">
        <Field
          validate={required}
          required
          label="Units"
          name={`${pricing}.billable_item_units_id`}
          options={units}
          component={SelectInput}
          onChange={changeUnits}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          validate={required}
          required
          label="Type"
          name={`${pricing}.line_item_id`}
          options={selectedItems}
          component={SelectInput}
          onChange={changeId}
          disabled={!selectedUnits || !value?.line_item_type}
        />
      </div>
      {isPositionAdmin && (
        <div className="col-lg-4 form-group">
          <Field
            validate={required}
            required
            label="Price"
            name={`${pricing}.price`}
            component={renderField}
            {...money}
          />
        </div>
      )}
      <div className="col-lg-4 form-group">
        <Field
          validate={required}
          required
          label="Quantity"
          name={`${pricing}.quantity`}
          component={renderField}
          type="number"
          extraProps={{ step: "0.001" }}
        />
      </div>
      {job?.estimated_value && isPositionAdmin ? (
        <div className="col-lg-4 form-group">
          <Field
            label="Percentage"
            name={`${pricing}.percentage`}
            component={renderField}
            type="number"
            onChangeValue={(value) => {
              if (!value) {
                return;
              }

              const totalPrice = (value / 100) * job?.estimated_value;

              change(`${pricing}.price`, totalPrice.toFixed(2));
              change(`${pricing}.quantity`, 1);
              change("is_progress_claim", true);
            }}
            information="Type here to have the price be a percentage of the estimated job value"
          />
        </div>
      ) : null}
      {!isInvoiced && (
        <div className="col-lg-4 form-group d-flex ms-auto align-items-end">
          <button
            onClick={deletePrice}
            className="btn btn-outline-danger w-100"
            type="button"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default PricingFields;
