import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Field, FormSubmitHandler, formValueSelector } from "redux-form";
import JOB_STATUS from "../../data/job_status";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormModal from "../utils/FormModal";
import SelectInputAsync from "../utils/SelectInputAsync";
import formError from "../utils/formError";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { JobOverviewDashboardProjectJob } from "./dashboardTypes";
import { connect } from "react-redux";
import { JobStatus } from "../jobs/jobTypes";

const EditJobModal = ({
  toggle,
  modal,
  job,
  status,
}: {
  toggle: () => void;
  modal: boolean;
  job: JobOverviewDashboardProjectJob;
  status: JobStatus;
}) => {
  const { takeAction }: IUseApi = useApi();
  const queryClient = useQueryClient();

  const onSubmit: FormSubmitHandler<{
    name: string;
    project_manager: number;
  }> = (values) => {
    return takeAction("update", `project-jobs/${job.uuid}`, values)
      .then(() => {
        queryClient.invalidateQueries(["job-overview-dashboard"]);
        queryClient.invalidateQueries(["job-overview-dashboard-users"]);
        toast.success("Job updated successfully");
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title={`Edit ${job.name}`}
      form={`JOB_${job.uuid}`}
      onSubmit={onSubmit}
      initialValues={job}
    >
      {(props: any) => {
        return (
          <>
            <div className="col-lg-6 form-group">
              <Field
                validate={required}
                required
                component={RenderField}
                name="name"
                label="Name"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                validate={required}
                required
                component={SelectInputAsync}
                url="/projectjobs/jobmanager"
                name="project_manager"
                label="Job Manager"
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                component={RenderField}
                textarea
                name="description"
                label="Notes"
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={RenderField}
                name="scheduled_start_date"
                label="Scheduled Start Date"
                type="date"
                validate={required}
                required
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                name="scheduled_start_time"
                type="time"
                component={RenderField}
                label="Scheduled Start Time"
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={RenderField}
                name="scheduled_finish_date"
                label="Scheduled Finish Date"
                type="date"
                validate={required}
                required
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                name="scheduled_finish_time"
                type="time"
                component={RenderField}
                label="Scheduled Finish Time"
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                name="status"
                label="Job Status"
                component={SelectInputAsync}
                options={JOB_STATUS}
                required
                validate={required}
                placeholder="Job Status"
                order={false}
              />
            </div>
            {status == JobStatus.Cancelled && (
              <div className="form-group col-lg-12">
                <Field
                  name="cancelled_reason"
                  label="Reason for Cancellation"
                  textarea
                  component={RenderField}
                  required
                  validate={required}
                />
              </div>
            )}
          </>
        );
      }}
    </FormModal>
  );
};

const mapStateToProps = (
  state: any,
  { job }: { job: JobOverviewDashboardProjectJob },
) => {
  const selector = formValueSelector(`JOB_${job.uuid}`);

  return {
    status: selector(state, "status"),
  };
};

export default connect(mapStateToProps)(EditJobModal);
