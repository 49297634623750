import { Link } from "react-router-dom";
import SpreadPrice from "../utils/SpreadPrice";
import DetailsTable from "./DetailsTable";
import getStatus from "../jobs/status";
import { JobStatus } from "../jobs/jobTypes";
import { useEffect, useState } from "react";
import { Table } from "@tanstack/react-table";
import dayjs from "dayjs";
import { money } from "../form/formatters";

const WIPTable = (props: any) => {
  const [tableInstance, setTableInstance] = useState<Table<any>>();

  const columnsToToggle = [
    "scheduled_finish_date",
    "job_type",
    "estimated_hours",
    "total_hours_added",
    "realised_value",
    "estimated_value",
  ];

  useEffect(() => {
    if (tableInstance) {
      columnsToToggle.forEach((column) => {
        tableInstance.getColumn(column)?.toggleVisibility(false);
      });
    }
  }, [tableInstance]);

  const columns = [
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info: any) => (
        <Link to={`/${info.row.original.project.link}`}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "project.client.name",
      header: "Client",
      cell: (info: any) => (
        <Link to={`/${info.row.original.project.client.link}`}>
          {info.getValue()}
        </Link>
      ),
    },
    {
      accessorKey: "project_manager.name",
      header: "Project Manager",
      cell: (info: any) => {
        const job = info.row.original;
        return (
          <a href={`mailto:${job.project_manager.email}`}>
            {job.project_manager.name}
          </a>
        );
      },
    },
    {
      accessorKey: "job_manager.name",
      header: "Job Manager",
      cell: (info: any) => {
        const job = info.row.original;
        return (
          <a href={`mailto:${job.job_manager.email}`}>{job.job_manager.name}</a>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info: any) => getStatus(info.getValue()),
      csvFormatter: (row: any) => JobStatus[row.status],
    },

    {
      accessorKey: "name",
      header: "Job Name",
      cell: (info: any) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "wip",
      header: "Amount",
      cell: (info: any) => <SpreadPrice price={info.getValue()} />,
    },
    {
      accessorKey: "scheduled_finish_date",
      header: "Scheduled Finish Date",
      csvFormatter: (row: any) =>
        dayjs(row.scheduled_finish_date).format("DD/MM/YYYY"),
      cell: (info: any) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "job_type",
      header: "Job Type",
    },
    {
      accessorKey: "estimated_hours",
      header: "Estimated Hours",
    },
    {
      accessorKey: "total_hours_added",
      header: "Total Hours Added",
    },
    {
      accessorKey: "realised_value",
      header: "Money Left in Project",
      cell: (info: any) => {
        const project = info.row.original.project;
        return (
          <SpreadPrice
            price={project.estimated_value - project.realised_value}
          />
        );
      },
      csvFormatter: (row: any) => {
        const project = row.project;
        return money.format(project.estimated_value - project.realised_value);
      },
    },
    {
      accessorKey: "estimated_value",
      header: "% Complete",
      cell: (info: any) => {
        const project = info.row.original.project;
        const percent =
          (project.realised_value / project.estimated_value) * 100;

        return percent.toFixed(1) + "%";
      },
      csvFormatter: (row: any) => {
        const project = row.project;
        const percent =
          (project.realised_value / project.estimated_value) * 100;

        return percent.toFixed(1) + "%";
      },
    },
  ];

  return (
    <DetailsTable
      url="wip-overview"
      {...props}
      title="WIP"
      setTableInstance={setTableInstance}
      columns={columns}
      enableColumnToggle={true}
    />
  );
};

export default WIPTable;
