import classnames from "classnames";

const ProfileButtons = ({ buttons }) => {
  return (
    <ul className="nav nav-activity-profile mg-y-20">
      {buttons.map((button, i) => {
        return (
          <li className="nav-item" key={i}>
            <button
              type="button"
              {...button}
              className={classnames("w-100 nav-link tn-500", button.className)}
            >
              {button.text}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default ProfileButtons;
