import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Route, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";

import { tracking } from "./components/utils/googleanalytics/config";
import history from "./components/utils/History";
import { AuthProvider } from "./context/auth-context";
import store from "./store";
import { RewriteFrames } from "@sentry/integrations";

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: "https://f6764ab42cb64497814e6f5c19e17916@o155481.ingest.sentry.io/1211676",
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          console.log("frame before");
          console.log(frame);
          if (frame?.filename.includes("/js/")) {
            return {
              ...frame,
              filename:
                frame.filename.substring(0, frame.filename.indexOf("/js/")) +
                frame.filename.substring(frame.filename.indexOf("/assets/")),
              abs_path:
                frame.filename.substring(0, frame.filename.indexOf("/js/")) +
                frame.filename.substring(frame.filename.indexOf("/assets/")),
            };
          }
          return frame;
        },
      }),
    ],
    ignoreErrors: [
      "Network Error",
      "Request failed with status code 401",
      "NotAllowedError: Read permission denied.",
      "Request failed with status code 422",
      "Request failed with status code 404",
      "Request failed with status code 429",
      "Request failed with status code 500",
      "Non-Error promise rejection captured with keys: invoice_number",
    ],
  });
  tracking(); // Google Analytics
}

const Routes = () => {
  useEffect(() => {
    var script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBJdHEjOZfcfgYYF190-XvLY94f087wydQ&libraries=places&callback=initMap";
    script.async = true;

    // Attach your callback function to the `window` object
    window.initMap = function () {
      // JS API is loaded and available
    };

    // Append the 'script' element to 'head'
    document.head.appendChild(script);
  }, []);

  return (
    <Provider store={store}>
      <AuthProvider>
        <Router history={history}>
          <Route path="/" component={App} />
          <ToastContainer />
        </Router>
      </AuthProvider>
    </Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Routes />);
