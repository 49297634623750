import { FETCH_SAMPLES } from "../actions/types";

const initialState = {
  items: [],
  selected: {},
  allSelected: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SAMPLES:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
}
