import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import CustomScaleLoader from "../utils/scaleLoader";
import HazardReportForm from "./HazardReportForm";
import { HazardReport } from "./types";
import { toast } from "react-toastify";

const HazardReportOverview = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: hazardReport,
    loading,
    setData: setHazardReport,
  }: IUseApi<HazardReport> = useApi(`hazard-reports/${uuid}`, null, true);

  const { takeAction }: IUseApi<{}, { data: HazardReport }> = useApi();

  const onSubmit: FormSubmitHandler<HazardReport> = (values) => {
    return takeAction("update", `/hazard-reports/${uuid}`, values)
      .then(({ data }) => {
        setHazardReport(data.data);
        toast.success("Report updated");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Projects",
            link: "/projects",
          },
          {
            name: hazardReport?.hazardable?.name ?? "-",
            link: `/${hazardReport?.hazardable?.link}`,
          },
          {
            name: hazardReport?.safety_initiative?.name ?? "-",
            link: `/`,
            active: true,
          },
        ]}
        titlePage={hazardReport?.safety_initiative?.name ?? "Take 5"}
      />
      {loading ? (
        <CustomScaleLoader>Fetching Report...</CustomScaleLoader>
      ) : (
        <>
          <p
            style={{
              marginTop: "-16px",
            }}
          >
            Added By{" "}
            <a
              href={`mailto:${hazardReport?.user?.email}`}
              className="tx-inverse"
            >
              {hazardReport?.user?.name}
            </a>{" "}
            to{" "}
            <Link
              className="tx-inverse"
              to={`/${hazardReport?.hazardable.link}`}
            >
              {hazardReport?.hazardable.name}
            </Link>{" "}
            at{" "}
            <span className="tx-inverse">
              {dayjs(hazardReport?.created_at).format("HH:mm a")}
            </span>{" "}
            on the{" "}
            <span className="tx-inverse">
              {dayjs(hazardReport?.created_at).format("DD/MM/YYYY")}
            </span>
          </p>
          <HazardReportForm
            initialValues={hazardReport}
            form="HazardReportOverview"
            onSubmit={onSubmit}
          />
        </>
      )}
    </>
  );
};

export default HazardReportOverview;
