import { Link, useParams } from "react-router-dom";
import InitialAvatar from "../utils/InitialAvatar";

const StaffList = (props: any) => {
  const { filteredAndOrderedStaff } = props;

  const { uuid } = useParams<{ uuid: string }>();

  return (
    <div>
      {filteredAndOrderedStaff.length > 0 ? (
        filteredAndOrderedStaff.map((member: any) => (
          <Link to={`/staff-matrix/users/${member.uuid}`} key={member.id}>
            <li
              className={`d-flex align-items-center py-3 ps-3 pointer-hover link-hover ${
                uuid === member.uuid ? "bg-gray-200" : ""
              }`}
            >
              <InitialAvatar userName={member.name} />
              <div className="media-body">
                <h6 className="tx-inverse text-wrap mg-b-0">{member.name}</h6>
                {member.primary_position && (
                  <p className="mb-0 tx-12 text-secondary">
                    {member.primary_position.name}
                  </p>
                )}
              </div>
            </li>
          </Link>
        ))
      ) : (
        <div className="slim-card-title text-center mb-3">No Staff Found.</div>
      )}
    </div>
  );
};

export default StaffList;
