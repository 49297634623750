import React from "react";

const ManagerLeftTabs = ({ label, items, wrapperClass = "" }) => {
  return (
    <div className={`manager-left d-lg-block w-100 ${wrapperClass}`}>
      <label className="section-label-sm">{label}</label>
      <nav className="nav">
        {items.map((item) => {
          return (
            <a
              key={item.label}
              {...item}
              className={`nav-link ${item.className}`}
            >
              <span>{item.label}</span>
              {item.count && item.countDisplay(item)}
            </a>
          );
        })}
      </nav>
    </div>
  );
};

export default ManagerLeftTabs;
