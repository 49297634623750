import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import ReactTable from "../table/ReactTable";
import axios from "../api/api";
import { PenaltyRate, PublicHoliday, Position } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";
import { Badge } from "reactstrap";

const PayRuns = () => {
  const [loading, setLoading] = useState(true);
  const [payRuns, setPayRuns] = useState([]);

  useEffect(() => {
    axios
      .get(`/pay-runs`)
      .then(({ data }) => {
        setPayRuns(data.data);
      })
      .catch(errorSwal)
      .then(() => setLoading(false));
  }, []);

  const columns = [
    {
      accessorKey: "start_date",
      header: "Start Date",
      cell: (info) => {
        const payRun = info.row.original;
        return (
          <Link to={`pay-runs/${payRun.uuid}/pays`}>
            {dayjs(payRun.start_date).format("DD/MM/YYYY")}
          </Link>
        );
      },
    },
    {
      accessorKey: "finish_date",
      header: "Finish Date",
      cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "paid_on",
      header: "Paid On",
      cell: (info) =>
        info.getValue() ? (
          <Badge color="success" className="shadow rounded-pill">
            {dayjs(info.getValue()).format("DD/MM/YYYY")}
          </Badge>
        ) : (
          <Badge color="warning" className="shadow rounded-pill">
            Not Paid
          </Badge>
        ),
    },
    {
      accessorKey: "branches",
      header: "Branches",
      cell: (info) => {
        const branches = info.getValue();
        return branches.map((branch) => branch.name).join(", ");
      },
    },
    {
      accessorKey: "completed_at",
      header: "Completed",
      cell: (info) => (
        <i
          className={`icon ${
            info.getValue() ? "ion-checkmark tx-success" : "ion-close tx-danger"
          }`}
        />
      ),
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/timesheet-tracking/creating-a-pay-run/" />
      <HeaderPage
        titlePage="Pay Runs"
        pageDescription={
          <>
            Manage employee pay and compensation by creating and managing pay
            runs that include all relevant timesheets, deductions, and expenses.
          </>
        }
        relatedLinks={[
          {
            name: "Penalty Rates",
            link: "/penalty-rates",
            model: PenaltyRate,
          },
          {
            name: "Public Holidays",
            link: "/public-holidays",
            model: PublicHoliday,
          },
          {
            name: "Positions",
            link: "/positions",
            model: Position,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "pay-runs", name: "Pay Runs", active: true },
        ]}
      />
      <Link to="pay-runs/add" className="btn btn-outline-primary mb-2">
        Add New Pay Run
      </Link>
      <ReactTable columns={columns} data={payRuns} loading={loading} />
    </>
  );
};

export default PayRuns;
