import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import ApprovalModal from "./ApprovalModal";
import { ApprovalAction } from "./approvalTypes";

interface ApproveMultipleButtonProps {
  approvalActions: ApprovalAction[];
  onSuccess?: () => void;
  buttonText?: string;
}

const ApproveMultipleButton = (props: ApproveMultipleButtonProps) => {
  const { toggle, modal } = useModal();
  const { onSuccess, approvalActions, buttonText = "Approve All" } = props;
  const { user } = useAuth();
  const history = useHistory();

  const { takeAction }: IUseApi = useApi();
  const actionsToUpdate = approvalActions.filter((action) => {
    return (
      action.action_type === "Approve" &&
      (user?.is_admin || action.user_id === user?.id)
    );
  });

  const onSubmit = (values: any) => {
    return takeAction("update", "approval-actions/multiple", {
      actions: actionsToUpdate.map(({ uuid }) => uuid),
      ...values,
    })
      .then(() => (onSuccess ? onSuccess() : history.push("/home")))
      .catch(errorSwal);
  };

  if (actionsToUpdate.length === 0) {
    return null;
  }

  return (
    <>
      <Button color="primary" outline size="sm" onClick={toggle}>
        {buttonText}
      </Button>
      <ApprovalModal
        form="ApproveMultiple"
        toggle={toggle}
        modal={modal}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ApproveMultipleButton;
