import {
  FETCH_ORGANISATION_SETTINGS,
  UPDATE_ORGANISATION_SETTINGS,
  NEW_ORGANISATION_SETTINGS,
  UPDATE_ORGANISATION_APPROVALS,
} from '../actions/types';

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORGANISATION_SETTINGS:
      return {
        ...state,
        items: action.payload,
      };
    case UPDATE_ORGANISATION_SETTINGS:
      return {
        ...state,
        items: action.payload.data,
      };
      case UPDATE_ORGANISATION_APPROVALS:
      return {
        ...state,
        items: action.payload,
      };
    case NEW_ORGANISATION_SETTINGS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
}
