import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import HeaderPage, { Crumb } from "../../header/HeaderPage";
import Profile from "../../profile";
import { DashboardH3, DashboardHeader } from "../../utils/DashboardHeader";
import formError from "../../utils/formError";
import CustomScaleLoader from "../../utils/scaleLoader";
import { TestSuiteStatus, TestSuiteStatusPages } from "../testSuiteTypes";
import ShowTestSuiteStatusPage from "./ShowTestSuiteStatusPage";
import { toast } from "react-toastify";

const TestSuiteStatusProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const {
    data: status,
    setData: setStatus,
    loading,
  }: IUseApi<TestSuiteStatus> = useApi(
    `test-suite-statuses/${uuid}`,
    null,
    true,
  );

  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const { takeAction }: IUseApi<{}, { data: TestSuiteStatus }> = useApi();

  const onSubmit: FormSubmitHandler<TestSuiteStatus> = (values) => {
    return takeAction("update", `test-suite-statuses/${uuid}`, values)
      .then(({ data }) => {
        setStatus(data.data);
        toast.success(`${data.data.name} updated.`);
      })
      .catch(formError);
  };

  useEffect(() => {
    if (status) {
      setCrumbs([
        {
          name: "Test Suites",
          link: "/test-suites",
        },
        {
          name: status?.test_suite?.name,
          link: `/test-suites/${status.test_suite.uuid}/statuses`,
        },
        {
          name: status?.name,
          link: `/test-suite-statuses/${status.uuid}/details`,
          active: true,
        },
      ]);
    }
  }, [status]);

  return (
    <>
      <HeaderPage
        titlePage={status?.name ?? "Test Suite Status"}
        crumbs={crumbs}
      />
      {status && !loading ? (
        <Profile<TestSuiteStatusPages>
          content={
            <ShowTestSuiteStatusPage
              status={status}
              setStatus={setStatus}
              onSubmit={onSubmit}
            />
          }
          tabs={[
            {
              label: "Details",
              link: `/test-suite-statuses/${status.uuid}/details`,
              page: "details",
            },
            {
              label: "Fields",
              link: `/test-suite-statuses/${status.uuid}/fields`,
              page: "fields",
            },
          ]}
          header={
            <DashboardHeader>
              <DashboardH3>{status?.name}</DashboardH3>
              <p className="mb-0">
                Test suite:{" "}
                <Link to={`/test-suites/${status.test_suite.uuid}/statuses`}>
                  {status?.test_suite?.name}
                </Link>
              </p>
            </DashboardHeader>
          }
        />
      ) : (
        <CustomScaleLoader>Fetching Status...</CustomScaleLoader>
      )}
    </>
  );
};

export default TestSuiteStatusProfile;
