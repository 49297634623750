import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import SelectInputAsync from "../../utils/SelectInputAsync";
import SubmitButton from "../../utils/SubmitButton";
import { toast } from "react-toastify";

const UserForm = (props) => {
  const { data, handleSubmit } = props;

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("store", "the-testing-app-users", values)
      .then(() => toast.success("Users Synced"))
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {data.the_testing_app_users.map((user, index) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="mb-0">theTESTINGapp User</p>
              <p className="mb-0 tx-inverse">{user.name}</p>
              <Field
                type="hidden"
                name={`users[${index}].testing_app_id`}
                component="string"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <i className="icon ion-arrow-right-a tx-inverse fw-bolder tx-20" />
            </div>
            <div className=" w-25">
              <p className="mb-0">Boss User</p>
              <Field
                component={SelectInputAsync}
                options={data.boss_users.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                name={`users[${index}].boss_id`}
              />
            </div>
          </div>
        );
      })}
      <SubmitButton {...props} className="mt-3" />
    </form>
  );
};

const form = reduxForm({
  form: "SyncSiteClassifierAppUsers",
  enableReinitialize: true,
});

const mapStateToProps = (state, { data }) => {
  const initialValues = data.the_testing_app_users.map((user) => {
    const values = {
      testing_app_id: user.id,
    };

    const matchedUser = data.boss_users.find((bossUser) => {
      return bossUser.name.toLowerCase() === user.name.toLowerCase();
    });

    if (matchedUser) {
      values.boss_id = matchedUser.id;
    }

    return values;
  });

  return {
    initialValues: {
      users: initialValues,
    },
  };
};

export default connect(mapStateToProps, {})(form(UserForm));
