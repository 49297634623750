import React from "react";
import { Field } from "redux-form";
import renderField from "../utils/renderField";
import { email } from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const RecipientSelection = (props) => {
  const { loading, availableRecipients, change } = props;

  const updateHandler = (event, newValue, previousValue, name) => {
    if (name === "recipient") {
      change("custom_recipient", "");
      return;
    }

    change("recipient", null);
  };

  return (
    <>
      <div className="col-lg-12 form-group">
        <Field
          name="recipient"
          label="Select contact"
          options={availableRecipients}
          isLoading={loading}
          onChange={updateHandler}
          component={SelectInputAsync}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          type="email"
          name="custom_recipient"
          onChange={updateHandler}
          component={renderField}
          label="... or enter email"
          validate={email}
        />
      </div>
    </>
  );
};

export default RecipientSelection;
