import React from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { DataResponse, IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { findAndReplace } from "../utils/arrayUtils";
import confirm from "../utils/confirm";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { SubjectItemEditorProps } from "./SubjectItemEditor";
import { ISubjectItem } from "./subjectTypes";

const ReadButton = (props: SubjectItemEditorProps) => {
  const { item, items, setItems } = props;

  const { takeAction, loading }: IUseApi = useApi();

  const markAsRead = () => {
    return confirm({
      title: `Mark ${item?.name} read?`,
      text: `By marking ${item?.name} as read, you declare that you have read and understood all content related to this subject.`,
      onConfirm: () =>
        takeAction("store", `subject-items/${item?.uuid}/read`)
          .then(({ data }: DataResponse<ISubjectItem>) => {
            setItems(findAndReplace("uuid", items, data.data));
            toast.success("Subject marked as read.");
          })
          .catch(errorSwal),
    });
  };

  if (!item?.requires_reading) {
    return null;
  }

  return (
    <Button disabled={loading} onClick={markAsRead} color="primary" outline>
      {isSubmitting(loading, "Mark as Read", "Marking as Read...")}
    </Button>
  );
};

export default ReadButton;
