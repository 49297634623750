import React from "react";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import required from "../utils/required";

const LinkTenderButton = ({
  className,
  job,
  onSuccess,
}: {
  className: string;
  job: any;
  onSuccess: Function;
}) => {
  const { toggle, modal } = useModal();

  return (
    !job.is_standard &&
    !job.job_type.link &&
    !job.tender && (
      <>
        <div className={`my-3 ${className}`}>
          <button
            className="btn btn-outline-primary w-100 no-wrap"
            onClick={toggle}
          >
            Link Tender
          </button>
        </div>
        <LinkJobModal
          toggle={toggle}
          modal={modal}
          job={job}
          onSuccess={onSuccess}
        />
      </>
    )
  );
};

const LinkJobModal = ({
  job,
  toggle,
  modal,
  onSuccess,
}: {
  job: any;
  toggle: Function;
  modal: boolean;
  onSuccess: Function;
}) => {
  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction("update", `tenders/${values.uuid}/link`, {
      work_id: values.work_id,
    })
      .then(() => {
        toggle();
        toast.success("Job Linked Successfully.");
        onSuccess();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      initialValues={{ work_id: job.id }}
      form="LinkJob"
      title={`Link ${job.name} to a Tender`}
      onSubmit={onSubmit}
      toggle={toggle}
      modal={modal}
    >
      <div className="form-group col-12">
        <Field
          url={`tenders/link`}
          component={SelectInput}
          required
          validate={required}
          name="uuid"
          label="Tender"
          formatData={(data: { name: string; uuid: string }[]) => {
            return data.map((d) => {
              return {
                label: d.name,
                value: d.uuid,
              };
            });
          }}
        />
      </div>
    </FormModal>
  );
};

export default LinkTenderButton;
