import "chart.js/auto";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { OverviewCPIR } from "../managementDashboard/ManagementCPIRCharts";
import * as chartjs from "chart.js";
import * as d3 from "d3";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { FiMoreHorizontal } from "react-icons/fi";
import { AiOutlineCheck } from "react-icons/ai";
import dayjs from "dayjs";
import _ from "lodash";
import colors from "../../data/colors";
import { convertLevelToColour } from "../managementDashboard/ManagementCPIRCharts";

const CPIROverviewLine = ({ data }: { data: OverviewCPIR[] }) => {
  const [group, setGroup] = useState<"risk_level" | "category">("risk_level");

  const [dateType, setDateType] = useState<"created_at" | "closed_at">(
    "created_at",
  );

  return (
    <>
      <div className="d-flex mg-b-20">
        <p className="slim-card-title mb-0 me-auto"> Overview</p>
        <UncontrolledDropdown>
          <DropdownToggle color="link" className="py-0 ms-auto">
            <FiMoreHorizontal className="tx-18 text-secondary" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Group</DropdownItem>
            <DropdownItem onClick={() => setGroup("risk_level")}>
              Risk Level{" "}
              {group === "risk_level" && (
                <AiOutlineCheck className="ms-1 tx-success" />
              )}
            </DropdownItem>
            <DropdownItem onClick={() => setGroup("category")}>
              Category{" "}
              {group === "category" && (
                <AiOutlineCheck className="ms-1 tx-success" />
              )}
            </DropdownItem>
            <DropdownItem header>Created/Closed</DropdownItem>
            <DropdownItem onClick={() => setDateType("created_at")}>
              Date Created
              {dateType === "created_at" && (
                <AiOutlineCheck className="ms-1 tx-success" />
              )}
            </DropdownItem>
            <DropdownItem onClick={() => setDateType("closed_at")}>
              Date Closed
              {dateType === "closed_at" && (
                <AiOutlineCheck className="ms-1 tx-success" />
              )}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <Line data={lineData(data, group, dateType)} />
    </>
  );
};

const lineData = (
  data: OverviewCPIR[],
  group: "risk_level" | "category",
  dateType: "created_at" | "closed_at",
): chartjs.ChartData<"line"> => {
  const filtered = data.filter((cpir) =>
    dateType === "closed_at" ? cpir.closed_at : true,
  );

  const orderedCpirs = _.orderBy(filtered, (d) => dayjs(d[dateType]));

  const dateGroup = Object.values(
    _.groupBy(orderedCpirs, (cpir) =>
      dayjs(cpir[dateType]).startOf("month").format("MMM YYYY"),
    ),
  );

  const range = d3.timeMonth.range(
    dayjs(orderedCpirs[0][dateType])
      .subtract(dateGroup.length === 1 ? 2 : 1, "month")
      .toDate(),
    dayjs(orderedCpirs[orderedCpirs.length - 1][dateType])
      .add(dateGroup.length === 1 ? 1 : 0, "months")
      .toDate(),
  );

  const dataSets = _.groupBy(orderedCpirs, (cpir) =>
    cpir[group] ? cpir[group] : group === "risk_level" ? "No Risk" : "-",
  );

  return {
    labels: range.map((date) => dayjs(date).format("MMM YYYY")),
    datasets: Object.entries(dataSets).map(([label, cpirs]) => {
      const dateGroup = _.groupBy(cpirs, (cpir) =>
        dayjs(cpir[dateType]).startOf("month").format("MMM YYYY"),
      );

      const colours = colors[convertLevelToColour(label)];

      return {
        label,
        data: range.map((date) => {
          return dateGroup[dayjs(date).format("MMM YYYY")]?.length ?? 0;
        }),
        backgroundColor: colours.light,
        borderColor: colours.border,
        borderWidth: 1,
      };
    }),
  };
};

export default CPIROverviewLine;
