import { useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { BiCloudUpload } from "react-icons/bi";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Button, Progress } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import errorSwal from "../utils/errorSwal";
import InformationAlert from "../utils/InformationAlert";
import HeaderPage from "../header/HeaderPage";
import dayjs from "dayjs";
import FormHeader from "../utils/FormHeader";
import { money } from "../form/formatters";
import ReactTable from "../table/ReactTable";
import isSubmitting from "../utils/submitting";

interface ReceiptToUpload {
  date: string;
  supplier_name: string;
  card: {
    user: string;
    card_last_four: string;
    card_brand: string;
    organisation_id: number;
    organisation_name: string;
  };
  imported_cost: number;
  uuid: string;
}

const UploadCreditCardReceipts = () => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();

  const [toUpload, setToUpload] = useState<ReceiptToUpload[]>([]);

  const { takeAction, loading: saving }: IUseApi<{}, { data: any }> = useApi();

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setRejectedFiles(fileRejections);

    setLoading(true);
    return upload(
      acceptedFiles[0],
      `tmp/organisations/purchase-upload`,
      (file: any) => {
        return takeAction("store", "/purchases/import", file)
          .then(({ data }) => {
            toast.success("Purchases Uploaded");
            setUploadPercent(0);
            setLoading(false);
            setToUpload(data.data);
          })
          .catch((err) => {
            setLoading(false);
            errorSwal(err);
          });
      },
    );
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      multiple: false,
      onDrop,
      disabled: loading,
    });

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "supplier_name",
        header: "Supplier",
      },
      {
        accessorKey: "date",
        header: "Date",
        cell: (info: any) => {
          return dayjs(info.getValue()).format("DD/MM/YYYY");
        },
      },
      {
        accessorKey: "imported_cost",
        header: "Total",
        cell: (info: any) => {
          return money.format(info.getValue());
        },
      },
      {
        accessorKey: "card.card_last_four",
        header: "Card Number",
      },
      {
        accessorKey: "card.user",
        header: "Card User",
      },
      {
        accessorKey: "card.organisation_name",
        header: "Card Organisation Name",
        cell: (info: any) => {
          return info.getValue() || "-";
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        cell: (info: any) => {
          return (
            <div className="d-flex align-items-center">
              <Button
                color="danger"
                size="sm"
                outline
                onClick={() => {
                  setToUpload([
                    ...toUpload.filter(
                      (d) => d.uuid !== info.row.original.uuid,
                    ),
                  ]);
                }}
              >
                Remove
              </Button>
            </div>
          );
        },
      },
    ];
  }, [toUpload]);

  return (
    <>
      <HeaderPage titlePage="Upload Purchases" crumbs={[]} />
      <div className="border-0 bg-white shadow-sm rounded-lg  position-relative">
        <div className="p-3 border-bottom">
          <p className="fw-bolder text-dark mb-0">Upload Excel</p>
        </div>
        <div className="p-3">
          <div
            {...getRootProps()}
            className={`tn-300 rounded-lg ${
              isDragActive ? "bg-gray-100" : ""
            } d-flex align-items-center w-100 h-100 justify-content-center`}
            style={{ minHeight: "7rem", border: "1px dashed #dee2e6" }}
          >
            <input {...getInputProps()} />
            {loading ? (
              <div>
                <i className="fa fa-spinner fa-spin" />
              </div>
            ) : (
              <div>
                <div className="text-center">
                  <BiCloudUpload className="tx-24 text-dark" />
                </div>
                <p className="mb-0">Upload Payment List</p>
              </div>
            )}
          </div>
          {uploadPercent > 0 && uploadPercent < 100 && (
            <div className="mt-3">
              <Progress
                className="progress-bar-sm"
                striped
                animated
                value={uploadPercent}
                color="primary"
              />
            </div>
          )}
          {rejectedFiles.length > 0 && (
            <div className="mt-3">
              <InformationAlert
                type="warning"
                title="Files Rejected"
                body={`The following files were rejected: \n\n${rejectedFiles
                  .map((file) => file.file.name)
                  .join("\n\n")}.\n\nPlease upload a CSV or excel file.`}
              />
            </div>
          )}
        </div>
      </div>
      {toUpload.length > 0 && (
        <>
          <FormHeader>{toUpload.length} Purchases to be uploaded</FormHeader>
          <ReactTable columns={columns} data={toUpload} />
          <Button
            className="mt-3"
            color="primary"
            onClick={() => {
              takeAction("update", "purchases/import", toUpload)
                .then(({ data }) => {
                  toast.success("Purchases Uploaded");

                  history.push("/purchases");
                  setToUpload([]);
                })
                .catch(errorSwal);
            }}
          >
            {isSubmitting(saving, "Save", "Saving...")}
          </Button>
        </>
      )}
    </>
  );
};

export default UploadCreditCardReceipts;
