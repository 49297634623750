import { useEffect } from "react";
import { reduxForm, FieldArray } from "redux-form";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";
import SettingsFields from "./SettingsFields";
import sortValues from "./sortValues";
import useApi from "../api/useApi";
import DocsModal from "../utils/DocsModal";

const GeneralSettings = (props) => {
  const { initialValues, handleSubmit, initialize, setOrganisation } = props;

  const { takeAction } = useApi();

  const sortedInitialValues = sortValues(initialValues);

  const onSubmit = (values) => {
    return takeAction(
      "update",
      "organisation/settings",
      generateValues(values, sortedInitialValues),
    )
      .then(({ data }) => {
        setOrganisation(data.data);
        return toast.success("Settings updated!");
      })
      .catch(errorSwal);
  };

  const initializeForm = () => {
    const settings = sortedInitialValues.settings.map((setting) => {
      const toReturn = {};
      toReturn[setting.meta_key] = setting.meta_value;

      toReturn.number_starts_at = setting.number_starts_at;

      return toReturn;
    });

    initialize({ settings });
  };

  useEffect(() => {
    initializeForm();
  }, []);

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/general-settings/" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <FieldArray component={SettingsFields} name="settings" {...props} />
          <div className="form-group col-lg-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const generalSettings = reduxForm({
  form: "generalSettings",
});

const generateValues = (values, sortedInitialValues) => {
  return values.settings.map((setting, index) => {
    const value = sortedInitialValues.settings[index];
    return {
      ...value,
      meta_value: setting[value.meta_key],
      number_starts_at: setting.number_starts_at,
    };
  });
};

export default generalSettings(GeneralSettings);
