import { useEffect } from "react";
import _ from "lodash";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useApi from "../api/useApi";
import Tabs from "../tabs/Tabs";
import CustomScaleLoader from "../utils/scaleLoader";
import PositionList from "./PositionList";
import TimesheetTable from "./TimesheetTable";
import dayjs from "dayjs";

const PayModal = (props) => {
  const { modal, toggle, className, pay, pays, updatePay, setSelectedPay } =
    props;
  const { loading, data: payTimesheet, setUrl, refreshData } = useApi();

  useEffect(() => {
    if (pay) {
      setUrl(`pay-timesheets/${pay.uuid}`);
    }
  }, [pay]);

  const incrementPay = () => {
    const orderedPays = _.orderBy(pays, (p) => p.user.name.toLowerCase());

    const index = _.findIndex(orderedPays, (p) => p.uuid === pay.uuid);

    setSelectedPay(orderedPays?.[index + 1]);
  };

  const markCompleted = () => {
    if (!pay.completed_at) {
      return updatePay(pay, {
        completed_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      }).then(incrementPay);
    }

    incrementPay();
  };

  const tabs = [
    {
      title: "Timesheets",
      component: (
        <TimesheetTable pay={payTimesheet} refreshData={refreshData} />
      ),
    },
    {
      title: "Positions",
      component: <PositionList pay={payTimesheet} />,
    },
  ];

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className={`modal-dialog ${className}`}
      style={{ maxWidth: "90%", width: "90%" }}
    >
      <ModalHeader toggle={toggle}>{pay?.user?.name}</ModalHeader>
      <ModalBody>
        {loading ? (
          <CustomScaleLoader>
            <p>Fetching Timesheets...</p>
          </CustomScaleLoader>
        ) : (
          <Tabs tabs={tabs} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button outline color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button outline color="primary" onClick={markCompleted}>
          {pay?.completed_at ? "" : "Mark complete and "}Move On
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PayModal;
