import React from "react";
import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import { CustomField, DataSeriesValue } from "./customFieldTypes";

const DataSeriesValues = ({
  fields,
  parentIndex,
  customFields,
}: WrappedFieldArrayProps<DataSeriesValue> & {
  customFields: CustomField[];
  parentIndex: number;
}) => {
  const fieldValues = customFields
    .map((field, i) => {
      return {
        label: field?.field_attributes?.label,
        value: i,
      };
    })
    .filter((f, i) => i !== parentIndex);

  return (
    <>
      {fields.map((field, i) => {
        return (
          <React.Fragment key={i}>
            <div className="col-lg-5 form-group">
              <Field
                component={SelectInput}
                name={`${field}.x_axis_value`}
                options={fieldValues}
                validate={required}
                required
                label="x-Axis Value"
              />
            </div>
            <div className="col-lg-5 form-group">
              <Field
                component={SelectInput}
                name={`${field}.y_axis_value`}
                label="y-Axis Value"
                options={fieldValues}
                validate={required}
                required
              />
            </div>
            <div className="col-lg-2 form-group d-flex align-items-end">
              <Button color="danger" outline onClick={() => fields.remove(i)}>
                Delete
              </Button>
            </div>
          </React.Fragment>
        );
      })}
      <div className="col-12 form-group">
        <Button onClick={() => fields.push({})}>Add Value</Button>
      </div>
    </>
  );
};

export default DataSeriesValues;
