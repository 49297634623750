import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ProfilePage from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import { ProjectForm } from "./projectForm";
import ShowPage from "./ShowPage";
import DeleteButton from "./DeleteButton";

export type ProjectFormPages = "details" | "fields" | "price-bands";

const Profile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { user } = useAuth();

  const {
    data,
    setData: setProjectForm,
    loading,
    response,
  }: IUseApi<ProjectForm> = useApi(
    `project-forms/${uuid}`,
    null,
    true,
    "/project-forms",
  );
  const projectForm = data as ProjectForm;

  const tabs: ProfileTab<ProjectFormPages>[] = [
    {
      link: `/project-forms/${uuid}/details`,
      icon: "",
      page: "details",
      label: "Details",
    },
    {
      link: `/project-forms/${uuid}/fields`,
      icon: "",
      page: "fields",
      label: "Fields",
    },
    {
      link: `/project-forms/${uuid}/price-bands`,
      icon: "",
      page: "price-bands",
      label: "Price Bands",
    }
  ];

  if (!response || loading) return <Spinner loading />;

  return (
    <>
      <HeaderPageAdminFilter
        titlePage={projectForm?.name}
        crumbs={[
          { name: "Project Forms", link: "/project-forms" },
          { name: projectForm?.name, link: "", active: true },
        ]}
      />

      <ProfilePage
        header={
          <>
            <DashboardHeader>
              <DashboardH3>{projectForm?.name}</DashboardH3>
            </DashboardHeader>
          </>
        }
        content={
          <ShowPage
            projectForm={projectForm}
            setProjectForm={setProjectForm}
          />
        }
        tabs={tabs}
        sideBar={<DeleteButton />}
      />
    </>
  );
};

export default Profile;
