import React from "react";
import { Link } from "react-router-dom";
import useApi from "../api/useApi";

import HeaderPage from "../header/HeaderPage";
import DatabaseListView from "../listView/DatabaseListView";
import Spinner from "../utils/Spinner";
import { OrganisationBranch, OrganisationDivision } from "../../enums/Model";

const List = () => {
  const { loading, data } = useApi("trackingsites", [], true);

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage="Tracking Sites"
        pageDescription={<>Tracking sites are your office locations.</>}
        relatedLinks={[
          { name: "Branches", link: "/branches", model: OrganisationBranch },
          {
            name: "Divisions",
            link: "/divisions",
            model: OrganisationDivision,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "trackingsites", name: "Tracking Sites", active: true },
        ]}
      />

      <DatabaseListView
        list={data}
        name="trackingsites"
        add={{ link: "trackingsites/add", name: "Tracking Site" }}
      />
    </>
  );
};

export default List;
