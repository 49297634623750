import React from "react";
import {
  DashboardHeader,
  DashboardH3,
  DashboardSubTitle
} from "../utils/DashboardHeader";

const ProfileHeader = ({ category }) => {
  return (
    <DashboardHeader>
      <DashboardH3>{category.name}</DashboardH3>
    </DashboardHeader>
  );
};

export default ProfileHeader;
