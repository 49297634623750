import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { Purchase } from "./purchaseTypes";
import dayjs from "dayjs";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import { connect } from "react-redux";
import ReactTable from "../table/ReactTable";
import { useEffect } from "react";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";
import FormHeader from "../utils/FormHeader";

interface PrePaymentPageProps {
  prePaymentCount?: number;
  purchase: Purchase;
  onSuccess: (purchase: Purchase) => void;
}

interface PrePaymentForm {
  pre_payment_count: number;
}

const PrePaymentPage = ({
  purchase,
  onSuccess,
  prePaymentCount,
}: PrePaymentPageProps) => {
  //

  return (
    <>
      <PrePaymentPageForm
        initialValues={purchase}
        purchase={purchase}
        onSuccess={onSuccess}
        form="PrePayment"
        prePaymentCount={prePaymentCount}
      />
      <JournalEntries purchase={purchase} prePaymentCount={prePaymentCount} />
    </>
  );
};

const JournalEntries = ({
  purchase,
  prePaymentCount,
}: Omit<PrePaymentPageProps, "onSuccess">) => {
  const {
    data: journalEntries,
    refreshData,
    setUrl,
    url,
    loading,
  } = useApi("", []);

  useEffect(() => {
    if (url) {
      refreshData();
      return;
    }
    setUrl(
      `manual-journals?filters=${encodeURIComponent(
        `Narration.Contains("${purchase.name}") && Status=="POSTED"`,
      )}`,
    );
  }, [purchase]);

  const columns = [
    {
      accessorKey: "date.date",
      header: "Date",

      cell: (info: any) => (
        <a
          href={info.row.original.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {dayjs(info.getValue()).format("DD/MM/YYYY")}
        </a>
      ),
    },
    {
      accessorKey: "narration",
      header: "Narration",
      cell: (info: any) => info.getValue(),
    },
  ];

  return (
    <>
      <FormHeader>Journal Entries</FormHeader>
      <ReactTable loading={loading} columns={columns} data={journalEntries} />
    </>
  );
};

const PrePaymentForm = (
  props: InjectedFormProps<PrePaymentForm, PrePaymentPageProps> &
    PrePaymentPageProps,
) => {
  const { handleSubmit, prePaymentCount, purchase, onSuccess } = props;

  const { takeAction }: IUseApi<{}, { data: Purchase }> = useApi();

  const onSubmit: FormSubmitHandler<PrePaymentForm, PrePaymentPageProps> = (
    values,
  ) => {
    return takeAction(
      "update",
      `purchases/${purchase.uuid}/pre-payments`,
      values,
    )
      .then(({ data }) => {
        onSuccess(data.data);
      })
      .catch(formError);
  };

  const finishDate = prePaymentCount
    ? dayjs(purchase.date)
        .add(prePaymentCount - 1, "months")
        .endOf("month")
        .format("DD/MM/YYYY")
    : null;

  console.log(props.error);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        {/* @ts-ignore */}
        <FormErrorAlert error={props.error ?? props.error?.message} />
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="pre_payment_count"
            label="Pre Payment Count"
            validate={required}
            required
          />
          {finishDate ? (
            <small>
              Finish Date: <span className="text-dark">{finishDate}</span>
            </small>
          ) : null}
        </div>
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<PrePaymentForm, PrePaymentPageProps>({});

const PrePaymentPageForm = form(PrePaymentForm);

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("PrePayment");

  return {
    prePaymentCount: selector(state, "pre_payment_count"),
  };
};

export default connect(mapStateToProps, {})(PrePaymentPage);
