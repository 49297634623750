import FormModal from "../utils/FormModal";
import Fields from "./Fields";

const PurchaseModal = (props) => {
  return (
    <FormModal {...props}>
      {(formProps) => {
        return (
          <Fields
            {...props}
            file
            purchaseOrder={props.url === "purchase-orders"}
            creditCard={props.url === "credit-card-receipts"}
            {...formProps}
          />
        );
      }}
    </FormModal>
  );
};

export default PurchaseModal;
