import _ from "lodash";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";

const Header = ({ headerLinks, history, bricks }) => {
  const { user } = useAuth();

  if (headerLinks.length === 0) {
    return null;
  }

  if (history.location.pathname === "/organisations/select") {
    return null;
  }

  if (user.timesheet_required) {
    return null;
  }

  return (
    <div
      className={`slim-navbar d-none d-lg-block tn-1000 ${
        bricks ? "brick-navbar-shadow" : ""
      }`}
    >
      <div className="container">
        <ul className="nav shadow-sm">
          {headerLinks.map((header) => {
            const hasSub = header.links;
            return hasSub ? (
              <NavWithSub key={header.id} header={header} />
            ) : (
              <NavWithoutSub key={header.id} header={header} />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const NavWithSub = ({ header }) => {
  const isMega = header.nav_type === "mega";

  return (
    <li className={`nav-item with-sub ${isMega ? "mega-dropdown" : ""}`}>
      <a className="nav-link text-primary" href="javascript:void(0)">
        <i className={`${header.icon} fa-lg pd-r-10`} />
        <span>{header.heading}</span>
      </a>
      <div className="sub-item w-100">
        {isMega ? (
          <div className="row">
            {header.links.map((link, index) => (
              <div key={index} className="col">
                <label className="section-label">{link.name}</label>
                <ul>
                  {_.sortBy(link.links, "value").map(
                    ({ link, value }, index) => (
                      <li key={index}>
                        <Link to={link}>{value}</Link>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <ul>
            {_.sortBy(header.links, "value").map(({ link, value }, index) => (
              <li key={index}>
                <Link to={link}>{value}</Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};

export const NavWithoutSub = ({ header }) => {
  const { user } = useAuth();

  if (header.admin && !user.is_admin) {
    return null;
  }

  return (
    <li className="nav-item">
      <Link to={header.link} className="nav-link text-primary">
        <i className={`${header.icon} fa-lg pd-r-10`} />
        <span>{header.heading}</span>
      </Link>
    </li>
  );
};

export default Header;
