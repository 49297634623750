import useApi from "../api/useApi";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import Answers from "./Answers";

const CheckInAnswers = ({
  performanceReview,
  setPerformanceReview,
}: {
  performanceReview: any;
  setPerformanceReview: Function;
}) => {
  const { takeAction } = useApi();

  const onSubmit = (values: any) => {
    return takeAction(
      "update",
      `/performance-reviews/${performanceReview.uuid}/check-in-answers`,
      values,
    )
      .then(({ data }: { data: any }) => {
        setPerformanceReview({
          ...performanceReview,
          check_in_answers: data.data,
        });
        toast.success("Check In Updated");
      })
      .catch(formError);
  };

  return (
    /** @ts-ignore */
    <Answers
      customFields={performanceReview?.check_in_answers?.custom_form}
      initialValues={performanceReview?.check_in_answers}
      onSubmit={onSubmit}
      form="CheckInAnswers"
    />
  );
};

export default CheckInAnswers;
