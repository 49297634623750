import axios from "../api/api";
import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, formValueSelector, reduxForm } from "redux-form";
import WETTING_EQUIPMENT from "../../data/oversize_wetting_equipment";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import isSubmitting from "../utils/submitting";
import PercentAlert from "./PercentAlert";

const alertClass = (percent) => {
  if (percent < 20) return "primary";
  if (percent < 100) return "warning";
  return "danger";
};

class OversizeForm extends Component {
  submit = (values) => {
    return axios
      .put(`/oversizes/${values.uuid}`, values)
      .then(() => toast.success("Oversize Updated"))
      .catch(errorSwal);
  };

  render() {
    const {
      handleSubmit,
      massOfTin19,
      massOfTinAndSoil19,
      totalSampleMass,
      submitting,
    } = this.props;

    const massOfSoil19 = massOfTinAndSoil19 - massOfTin19;
    let oversizePercentage = 0;
    if (massOfSoil19 > 0) {
      oversizePercentage = (massOfSoil19 / totalSampleMass) * 100;
    }
    const oversizeAlertClass = alertClass(oversizePercentage);
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className="row">
          <div className="col-12">
            <h6 className="tx-inverse">Oversize Information</h6>
            <hr />
          </div>
          <div className="col-lg-12 form-group">
            <Field
              component={renderField}
              name="total_sample_mass"
              label="Total Mass of Sample (g)"
              type="number"
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              label="Balance"
              component={SelectInputAsync}
              name="balance_id"
              url="/organisation-standard-equipment-types?type=Balance"
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              label="Wetting Equipment"
              component={SelectInputAsync}
              name="wetting_equipment"
              options={WETTING_EQUIPMENT}
            />
          </div>
          <div className="col-12 mt-4">
            <h6 className="tx-inverse">
              Material Retained Over 19<sup>mm</sup> Sieve
            </h6>
            <hr />
          </div>

          <div className="col-lg-6 form-group">
            <Field
              component={renderField}
              name="moisture_19.mass_of_tin"
              label="Mass of Tin (g)"
              type="number"
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={renderField}
              name="moisture_19.mass_of_tin_and_wet_soil"
              label="Mass of Tin and Wet Soil (g)"
              type="number"
            />
          </div>
          <PercentAlert
            className={oversizeAlertClass}
            oversizePercentage={oversizePercentage}
          />
          <div className="col-lg-6 form-group">
            <Field
              label="Oven"
              component={SelectInputAsync}
              name="moisture_19.oven_id"
              url="/organisation-standard-equipment-types?type=Oven"
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              label="Container"
              component={SelectInputAsync}
              name="moisture_19.container_id"
              url={`/react-select?model=${encodeURIComponent(
                "App\\Models\\MoistureContainer",
              )}&select=id,name&value=id&label=name`}
            />
          </div>
          {oversizePercentage > 20 && (
            <Fragment>
              <div className="col-12 mt-4">
                <h6 className="tx-inverse">
                  Material Retained Over 37.5<sup>mm</sup> Sieve
                </h6>
                <hr />
              </div>

              <div className="col-lg-6 form-group">
                <Field
                  component={renderField}
                  name="moisture_37.mass_of_tin"
                  label="Mass of Tin (g)"
                  type="number"
                />
              </div>
              <div className="col-lg-6 form-group">
                <Field
                  component={renderField}
                  name="moisture_37.mass_of_tin_and_wet_soil"
                  label="Mass of Tin and Wet Soil (g)"
                  type="number"
                />
              </div>
              <div className="col-lg-6 form-group">
                <Field
                  label="Oven"
                  component={SelectInputAsync}
                  name="moisture_37.oven_id"
                  url="/organisation-standard-equipment-types?type=Oven"
                />
              </div>
              <div className="col-lg-6 form-group">
                <Field
                  label="Container"
                  component={SelectInputAsync}
                  name="moisture_37.container_id"
                  url={`/react-select?model=${encodeURIComponent(
                    "App\\Models\\MoistureContainer",
                  )}&select=id,name&value=id&label=name`}
                />
              </div>
            </Fragment>
          )}
          <div className="col-12">
            <button
              type="submit"
              disabled={submitting}
              className="btn btn-primary"
            >
              {isSubmitting(submitting, "Save Oversize")}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const form = reduxForm({ form: "Oversize" });

const selector = formValueSelector("Oversize");

const mapStateToProps = (state) => ({
  massOfTin19: selector(state, "moisture_19.mass_of_tin"),
  massOfTinAndSoil19: selector(state, "moisture_19.mass_of_tin_and_wet_soil"),
  totalSampleMass: selector(state, "total_sample_mass"),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(form(OversizeForm));
