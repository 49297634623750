import { CustomField } from "./customFieldTypes";
import EquipmentCustomValueFields from "./EquipmentCustomValueFields";

export const valueFilters: FilterType = {
  "/custom-fields/equipment": {
    passes: (value) => {
      if (!value) {
        return false;
      }

      const hasTypeFilter = !!value?.field_attributes?.filters?.find(
        ({ key }) => key === "type_id",
      );

      return hasTypeFilter;
    },
    fields: (props) => {
      return <EquipmentCustomValueFields {...props} />;
    },
  },
};

interface FilterType {
  [key: string]: {
    passes: (value?: CustomField) => boolean;
    fields: (props: {
      filters: { key: string; value: any }[];
      field: string;
      value?: CustomField;
    }) => JSX.Element;
  };
}
