const PREPERATION_TYPES = [
  { name: "depth_of_excavations", label: "Depth of Excavations" },
  { name: "adequacy_of_foundation_base", label: "Adequacy of Foundation Base" },
  { name: "bar_chairs", label: "Bar Chairs" },
  { name: "placement_of_reinforcement", label: "Placement of Reinforcement" },
  {
    name: "backhoe_piers",
    label: "Backhoe and/or Bored Piers",
    comments_description: "Diameter..."
  },
  { name: "additional_mesh_over_piers", label: "Additional Mesh Over Piers" },
  { name: "starter_bars_onsite", label: "Start Bars Onsite" },
  { name: "depth_location", label: "Depth/Location" },
  { name: "depth_of_beams", label: "Depth of Beams/Pods" },
  { name: "width_of_beams", label: "Width of Beams" },
  { name: "step_down_details", label: "Step-down detail" },
  {
    name: "starter_bars_tied_to_reinforcement",
    label: "Starter Bars Tied to Reinforcement"
  }
];

export default PREPERATION_TYPES;
