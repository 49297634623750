import axios from "../api/api";
import errorSwal from "./errorSwal";

/*
 * The returned value of this will have 3 main parts.
 * If response.Message exists, it means that they were unable to find the abn,
 * you are able to show response.Message as the error message.
 * The organisation name will be response.EntityName.
 * The trading name will be response.BusinessName[0].
 */

const checkAbn = (abn) => {
  return axios
    .post(`/util/checkabn`, { abn })
    .then((res) => res.data.data)
    .catch(() =>
      errorSwal("Unable to reach the ABN service, please try again later"),
    );
};

export default checkAbn;
