import React from "react";
import { Link } from "react-router-dom";

const Forbidden = () => {
  return (
    <div className="page-error-wrapper">
      <div>
        <h1 className="error-title">403</h1>
        <h5 className="tx-sm-24 tx-normal">
          You are forbidden to view this page.
        </h5>
        <p className="mg-b-50">
          You do not have appropriate permissions to view this page.
        </p>
        <p className="mg-b-50">
          <Link to="/">Back to Home</Link>
        </p>
      </div>
    </div>
  );
};

export default Forbidden;
