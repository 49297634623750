import swal from "sweetalert";

/**
 *
 * @param {import("../../types/typedefs").ConfirmConfig} config
 * @returns Promise
 */
const confirm = (config) => {
  return swal({
    title: config.title,
    text: config.text || `Continue?`,
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        closeModal: true,
        visible: true,
      },
      delete: {
        text: "Continue",
        value: true,
        closeModal: true,
        className: "btn btn-danger align-baseline",
      },
    },
  }).then((remove) => {
    if (remove) {
      return config.onConfirm();
    }
  });
};

export default confirm;
