const SpreadPrice = ({ price, wrapperClass = "" }) => {
  price =
    price && price !== 0
      ? Math.round((price + Number.EPSILON) * 100) / 100
      : null;

  const formatted = price
    ? `${price.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "-";

  return (
    <div
      className={`d-flex ${wrapperClass} ${price >= 100_000 ? "tx-20" : ""}`}
    >
      <span>$</span>
      <span className={`ms-auto`}>{formatted}</span>
    </div>
  );
};

export default SpreadPrice;
