import { SET_LOCAL_STORAGE } from "../actions/types";

const initialState = {
  boss_token: localStorage.getItem("boss_token"),
  bricks: localStorage.getItem("bricks") === "true" ? true : false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOCAL_STORAGE:
      localStorage.setItem(action.key, action.value);
      const newState = {
        ...state,
      };
      newState[action.key] = action.value;
      return newState;
    default:
      return state;
  }
}
