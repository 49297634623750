import React from "react";

import { toast } from "react-toastify";
import FormSkillCategory from "./Form";
import errorSwal from "../utils/errorSwal";
import axios from "../api/api";

const EditSkillCategory = (props) => {
  const onSubmit = (values) => {
    return axios
      .put(`/skill-categories/${props.category.uuid}`, values)
      .then(({ data }) => {
        props.setCategory(data.data);
        return toast.success(`${values.name} updated successfully`);
      })
      .catch(errorSwal);
  };

  return (
    <FormSkillCategory
      initialValues={props.initialValues}
      onSubmit={onSubmit}
      {...props}
    />
  );
};

export default EditSkillCategory;
