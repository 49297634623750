import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getPurchases = (
  { pageParam = 1 },
  search: string | undefined,
  uuid: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `suppliers/${uuid}/purchases?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useSupplierPurchases(
  search: string | undefined,
  uuid: string,
) {
  return useInfiniteQuery(
    ["supplier-purchases", { search, uuid }],
    (pageParam) => getPurchases(pageParam, search, uuid),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
