import { useAuth } from "../../context/auth-context";
import { Button } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { AuthUser } from "./userTypes";
import { toast } from "react-toastify";
import isSubmitting from "../utils/submitting";
import PasswordModal from "./PasswordModal";
import useModal from "../hooks/useModal";

const DeleteTwoFactorAuthentication = () => {
  const { user, setUser } = useAuth();
  const { modal, toggle } = useModal();

  const { takeAction, loading }: IUseApi<{}, { data: AuthUser }> = useApi();

  const remove = () => {
    return deleteSwal("Two factor authentication")
      .then(() => takeAction("destroy", "user/two-factor-authentication"))
      .then(({ data }) => {
        toast.success("Two factor authentication removed.");

        setUser(data.data);
      });
  };

  if (!user?.has_mfa || user?.two_factor_required_at?.two_factor_enabled_at) {
    return null;
  }

  return (
    <div className="mt-3 border border-danger p-3 bg-white">
      <h5 className="text-danger slim-card-title">
        Remove Two Factor Authentication
      </h5>
      <Button
        disabled={loading}
        color="danger"
        outline
        onClick={toggle}
        className="mt-3"
        size="sm"
      >
        {isSubmitting(loading, "Remove", "Removing...")}
      </Button>
      <PasswordModal
        modal={modal}
        toggle={toggle}
        form="DELETE_2FA_PASSWORD"
        onSuccess={remove}
      />
    </div>
  );
};

export default DeleteTwoFactorAuthentication;
