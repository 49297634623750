import React from "react";
import { Field, reduxForm } from "redux-form";

import renderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import required from "../utils/required";
import SelectInput from "../form/SelectInput";

const FormSkillCategory = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            name="name"
            required
            validate={required}
            type="text"
            label="Name"
            component={renderField}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            type="text"
            label="Skill Approver"
            name="approver_id"
            component={SelectInput}
            url="/staff"
            formatData={(users) =>
              users.map((user) => ({ label: user.name, value: user.id }))
            }
          />
          <small>
            The skill approver will be the person tasked with approving all
            competencies within this skill category.
          </small>
        </div>

        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "SkillCategory" });

export default form(FormSkillCategory);
