import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { reduxForm } from "redux-form";
import { setLocalStorage } from "../../actions/localStorageActions";
import { useAuth } from "../../context/auth-context";
import AddressFields from "../addresses/AddressFields";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import OrganisationFields from "../login/OrganisationFields";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";

dayjs.extend(utc);
dayjs.extend(timezone);

const AddToExistingUser = (props) => {
  const { takeAction, loading } = useApi();
  const { setUser } = useAuth();

  const history = useHistory();

  //TODO org in the top right corner doesn't get changed
  //whe this is successful
  const submit = (values) => {
    return takeAction("store", "existing-user-organisations", values)
      .then(({ data }) => {
        setUser(data.data);
        history.push("/home");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage titlePage="Add new Organisation" crumbs={[]} />
      <form onSubmit={props.handleSubmit(submit)}>
        <div className="row">
          <OrganisationFields loading={loading} {...props} />
          <AddressFields {...props} postal />
          <div className="col-12 form-group">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};
const form = reduxForm({
  form: "RegisterOrganisation",
  initialValues: { timezone: dayjs.tz.guess() },
});

export default connect(null, { setLocalStorage })(form(AddToExistingUser));
