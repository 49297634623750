import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import { useAuth } from "../../context/auth-context";

interface CopyButtonProps {
  model: string;
}

const CopyButton = (props: CopyButtonProps) => {
  const { uuid } = useParams<{ uuid: string }>();

  const { modal, toggle } = useModal();

  const { user } = useAuth();

  const { takeAction, loading } = useApi();

  const { data: organisations } = useApi(
    `/organisations/associated/${uuid}?model=${encodeURIComponent(
      props.model,
    )}`,
    [],
    true,
  );

  const copyClient = (values: any) => {
    const model = props.model;

    return takeAction(
      "store",
      model === "App\\Models\\Supplier"
        ? `suppliers/${uuid}/copy`
        : `organisation-clients/${uuid}/copy`,
      {
        ...values,
      },
    )
      .then(({ data }: any) => {
        toggle();
        toast.success(`${data.data.name} successfully copied.`);
      })
      .catch(errorSwal);
  };

  if (organisations.length === 0) {
    return null;
  }

  if (!user?.is_position_admin) {
    return null;
  }

  return (
    <div className="mt-3">
      <button
        className="btn btn-outline-secondary w-100"
        onClick={toggle}
        disabled={loading}
      >
        Copy to Other Organisation
      </button>
      <CopyModal
        title="Copy to Other Organisation"
        toggle={toggle}
        modal={modal}
        uuid={uuid}
        onSubmit={copyClient}
        form="CopyToOtherOrganisation"
        model={props.model}
        organisations={organisations}
      />
    </div>
  );
};

const CopyModal = (props: any) => {
  return (
    <FormModal {...props}>
      <div className="col-12">
        <Field
          label="Organisations"
          name="organisation_id"
          component={SelectInputAsync}
          validate={required}
          required
          options={props.organisations}
        />
      </div>
    </FormModal>
  );
};

export default CopyButton;
