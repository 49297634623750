import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

interface Contact {
  uuid: string;
  name: string;
  email: string;
  phone?: string;
  deliverable_recipient: boolean;
  invoice_recipient: boolean;
}

interface ContactDropdownProps {
  contact: Contact;
  onDelete?: (contact: Contact) => any;
  onUpdate?: (
    contact: Contact,
    data: {
      deliverable_recipient?: boolean;
      invoice_recipient?: boolean;
    },
  ) => any;
}

const ContactDropdown = ({
  contact,
  onDelete,
  onUpdate,
}: ContactDropdownProps) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" className="p-0">
        <FiMoreHorizontal className="tx-18 text-secondary" />
      </DropdownToggle>
      <DropdownMenu>
        {onDelete && (
          <DropdownItem onClick={() => onDelete(contact)}>Delete</DropdownItem>
        )}
        {onUpdate && (
          <>
            <DropdownItem
              onClick={() =>
                onUpdate(contact, {
                  deliverable_recipient: !contact.deliverable_recipient,
                })
              }
            >
              Deliverable Recipient{" "}
              {contact.deliverable_recipient ? (
                <AiOutlineCheck className="ms-1 tx-success" />
              ) : null}{" "}
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                onUpdate(contact, {
                  invoice_recipient: !contact.invoice_recipient,
                })
              }
            >
              Invoice Recipient{" "}
              {contact.invoice_recipient ? (
                <AiOutlineCheck className="ms-1 tx-success" />
              ) : null}{" "}
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
export default ContactDropdown;
