import { useInfiniteQuery } from "react-query";
import api from "../../api/api";
import { SelectedSkill } from "../SkillStaffMatrix";

const getUserCompetencies = (
  { pageParam = 1 },
  search?: string,
  competencySkills?: SelectedSkill[],
) => {
  if (!pageParam) {
    return;
  }

  const competencySkillQuery =
    competencySkills
      ?.map(({ uuid }) => `competency_skills[]=${uuid}`)
      .join("&") || "";

  return api
    .get(
      `user-competency-skills?page=${pageParam}&filter[search]=${search}&${competencySkillQuery}`,
    )
    .then(({ data }) => data);
};

export default function useUserCompetencies(
  search?: string,
  competencySkills?: SelectedSkill[],
) {
  return useInfiniteQuery(
    ["user-competencies", { search, competencySkills }],
    (pageParam) => getUserCompetencies(pageParam, search, competencySkills),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
