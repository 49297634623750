import React from "react";
import { Field, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { useParams } from "react-router-dom";

const Edit = (props) => {
  const { takeAction } = useApi();

  const { uuid } = useParams();

  const onSubmit = (values) => {
    return takeAction("update", `tender-types/${uuid}`, values)
      .then(({ data }) => {
        props.setType(data.data);
        toast.success(`${values.name} updated`);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="name"
            label="Name"
            required
            validate={required}
          />
        </div>
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "UpdateTenderType" });

export default form(Edit);
