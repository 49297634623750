import { useState } from "react";
import { Tab } from "./tabTypes";

interface TabsProps {
  tabs: Tab[];
  rightNav?: (currentTab: number) => JSX.Element | null;
  onSelect?: (selectedIndex: number) => any;
  wrapperClass?: string;
  startingTab?: number;
}

const Tabs = ({
  tabs,
  rightNav,
  onSelect,
  wrapperClass = "",
  startingTab = 0,
}: TabsProps) => {
  const [currentTab, setCurrentTab] = useState<number>(startingTab);

  return (
    <>
      <div className={`nav-statistics-wrapper ${wrapperClass ?? ""}`}>
        <nav className="nav flex-nowrap" style={{ overflowX: "auto" }}>
          {tabs.map((tab, index) => {
            return (
              <button
                key={index}
                type="button"
                onClick={() => {
                  if (onSelect) {
                    onSelect(index);
                  }
                  setCurrentTab(index);
                }}
                className={`btn btn-link nav-link mb-3 ${
                  currentTab == index ? "active" : ""
                }`}
              >
                {tab.title}
              </button>
            );
          })}
        </nav>
        {rightNav && rightNav(currentTab)}
      </div>
      {tabs[currentTab].component}
    </>
  );
};

export default Tabs;
