import * as d3 from "d3";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { useD3 } from "../hooks/useD3";
import * as regression from "d3-regression";
import FormHeader from "../utils/FormHeader";
import { Regression } from "./customFieldTypes";

const RenderChartField = (props) => {
  const dataSeries = props?.data_series?.map((series) => {
    if (series.type === "formula") {
      return {
        ...series,
        graph_data: evaluateFormula(
          series.formula ?? "",
          Object.values(props?.formValues?.custom_fields ?? {}),
        ),
      };
    }

    return {
      ...series,
      graph_data: getValues(
        series.values ?? [],
        Object.values(props?.formValues?.custom_fields ?? {}),
      ),
    };
  });

  const ref = useD3(
    (svg) => {
      const joinedData = dataSeries
        ? dataSeries?.flatMap((s) => {
            return s.graph_data;
          })
        : [];

      const xValues = joinedData.map((d) => parseFloat(d[0]));
      const yValues = joinedData.map((d) => parseFloat(d[1]));

      const xV = [d3.min(xValues), d3.max(xValues)];

      const yV = [
        d3.min(yValues) - d3.min(yValues) * 0.01,
        d3.max(yValues) + d3.max(yValues) * 0.01,
      ];

      const x = d3.scaleLinear().domain(xV).range([0, 600]);
      const y = d3.scaleLinear().domain(yV).range([400, 60]);

      const xAxis = (g) =>
        g
          .attr("transform", `translate(40,400)`)
          .call(d3.axisBottom(x).tickSizeOuter(0));

      const yAxis = (g) =>
        g
          .attr("transform", `translate(40,0)`)
          .style("color", "grey")
          .call(d3.axisLeft(y).ticks(null, "s"));

      svg.select(".x-axis").call(xAxis);
      svg.select(".y-axis").call(yAxis);

      dataSeries?.forEach((series, i) => {
        svg
          .select(`.series_${i}`)
          .selectAll(".circle")
          .data(series.graph_data)
          .join("circle")
          .attr("class", "circle")
          .attr("r", "3")
          .attr("fill", series.color)
          .attr("cx", (d) => x(d[0]))
          .attr("cy", (d) => y(d[1]))
          .transition()
          .each(d3.easeLinear)
          .duration(500)
          .delay((d, i) => i * 50)
          .attr("transform", "translate(40, 0)");

        if (series.regression_line) {
          const regression = getRegression(series);

          if (
            series.graph_data.length < 4 &&
            series?.regression_line === Regression.Polynomial
          ) {
            regression.order(series.graph_data.length - 1);
          }

          const regressionData = regression(series.graph_data);

          const yD = regressionData.map((d) => d[1]);

          const maxY = regressionData[d3.maxIndex(yD)];

          if (maxY) {
            d3.select(`#chart-tooltip-${i}`)
              .attr("x", x(maxY[0]) - 50)
              .attr("y", y(maxY[1]) - 20)
              .text(`Max y: ${maxY[1]}, max x: ${maxY[0]}`);
          } else {
            d3.select(`#chart-tooltip-${i}`).text(``);
          }

          svg
            .select(`.series_${i}`)
            .select(".regression-line")
            .datum(regressionData)
            .attr("stroke", series.color)
            .attr("stroke-width", 2)
            .attr("opacity", 0.5)
            .attr("stroke-dasharray", "5,5")
            .attr("fill", "none")
            .attr(
              "d",
              d3
                .line()
                .x(function (d) {
                  return x(d[0]);
                })
                .y(function (d) {
                  return y(d[1]);
                }),
            )
            .attr("transform", "translate(40, 0)");
          // .on("mouseover", (d) => {
          //   tooltip.style("opacity", 1);
          // })
          // .on("mousemove", (e) => {
          //   tooltip
          //     .html("The exact value of<br>this cell is: ")
          //     .style("left", e.pageX + 10 + "px")
          //     .style("top", e.pageY + "px");
          // })
          // .on("mouseleave", (d) => {
          //   tooltip.style("opacity", 0);
          // });
        }
      });
    },
    [JSON.stringify(props?.formValues?.custom_fields)],
  );

  return (
    <div className="bg-white border rounded-lg shadow p-3">
      <FormHeader>{props.label}</FormHeader>
      <svg
        ref={ref}
        style={{
          height: 500,
          width: "100%",
          marginRight: "0px",
          marginLeft: "0px",
        }}
      >
        {props?.data_series?.map((s, i) => {
          return (
            <g key={i} className={`series_${i}`}>
              <text id={`chart-tooltip-${i}`} />
              <path className="regression-line" />
              <path className="path" />
            </g>
          );
        })}

        <text
          fontWeight="lighter"
          stroke="currentColor"
          className="text-secondary"
          style={{ textAnchor: "middle" }}
          y="450"
          x="50%"
        >
          {props.x_axis_label}
        </text>
        <text
          y="10"
          x="-33%"
          stroke="currentColor"
          className="text-secondary"
          style={{
            transform: "rotate(270deg)",
          }}
          fontWeight="lighter"
        >
          {props.y_axis_label}
        </text>
        <g className="x-axis" />
        <g className="y-axis" />
      </svg>
    </div>
  );
};

const evaluateFormula = (formula, values) => {
  const formatedValues = values.map((field) => {
    const variable = `$`.concat(
      `{${field?.label
        ?.trim()
        .replace(/{|}/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()}}`,
    );
    return { key: variable, value: field.value };
  });
  formatedValues.forEach((field, index) => {
    formula = formula.replaceAll(`${field.key}`, field?.value ?? "-");
  });

  try {
    return new Function(formula)();
  } catch (err) {
    return [];
  }
};

const getValues = (data, values) => {
  return data
    .map((d) => {
      const x = values.find(({ order }) => order === d.x_axis_value)?.value;
      const y = values.find(({ order }) => order === d.y_axis_value)?.value;

      const vals = [x, y];

      return vals;
    })
    .filter((d) => d[0] !== undefined && d[1] !== undefined);
};

const getRegression = (series) => {
  switch (series.regression_line) {
    case Regression.Linear:
      return regression.regressionLinear();
    case Regression.Quadratic:
      return regression.regressionQuad();
    case Regression.Polynomial:
      return regression.regressionPoly();
    case Regression.Exponential:
      return regression.regressionExp();
    case Regression.Logarithmic:
      return regression.regressionLog();
    case Regression.PowerLaw:
      return regression.regressionPow();
    default:
      return null;
  }
};

const mapStateToProps = (state, { meta }) => {
  return { formValues: getFormValues(meta.form)(state) };
};

export default connect(mapStateToProps, {})(RenderChartField);
