import React from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import RightModal from "../utils/RightModal";
import SkillHistory from "./SkillHistory";

const SkillModal = (props) => {
  const { toggle, modal, competency } = props;

  if (!competency) {
    return null;
  }

  return (
    <RightModal modal={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{competency?.skill_level?.name}</ModalHeader>
      <ModalBody>
        <SkillHistory competencyUuid={modal} {...props} />
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-outline-secondary bd-0"
          onClick={toggle}
        >
          Close
        </button>
      </ModalFooter>
    </RightModal>
  );
};

export default SkillModal;
