import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import AddressFields from "../addresses/AddressFields";
import Fields from "../bankAccountDetails/Fields";
import AddMultiple from "../form/AddMultiple";
import AbnField from "../utils/AbnField";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import InsuranceFields from "./InsuranceFields";
import CustomForm from "../customFields/CustomForm";

const ExternalForm = (props) => {
  const { handleSubmit, supplier } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormHeader>Details</FormHeader>
        {supplier?.abn_holder ? (
          <>
            <div className="col-lg-12 form-group">
              <AbnField {...props} />
            </div>
            <div className="col-lg-6 form-group">
              <Field component={RenderField} name="acn" label="ACN" />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name="name"
                label="Name"
                disabled
                readOnly
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name="trading_name"
                label="Trading Name"
              />
            </div>

            <div className="col-lg-6 form-group">
              <Field
                component={AddMultiple}
                name="accounts_email"
                label="Accounts Email/s"
                validate={required}
                required
              />
            </div>
            <div className="col-12 form-group">
              <Field
                component={RenderField}
                textarea
                name="description"
                label="Description"
              />
            </div>
          </>
        ) : (
          <div className="col-lg-6 form-group">
            <Field component={RenderField} name="name" label="Name" />
          </div>
        )}
        <div className="col-lg-6 form-group">
          <Field
            component={AddMultiple}
            name="contact_email"
            label="Contact Email/s"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="contact_telephone"
            label="Contact Phone"
            validate={required}
            required
          />
        </div>

        <FormHeader>Bank Details</FormHeader>
        <Fields prefix="bank_details" />
        <AddressFields {...props} postal />

        {supplier?.insurances_required.length > 0 && (
          <>
            <FormHeader>Required Insurances</FormHeader>
            <FieldArray
              component={InsuranceFields}
              name="insurances_required"
            />
          </>
        )}
        {supplier?.custom_form?.length > 0 && (
          <>
            <FormHeader>Extra Information</FormHeader>
            <CustomForm
              customForm={supplier?.custom_form}
              modelType="App\Models\Supplier"
              modelId={supplier?.id}
              {...props}
              fieldUrlAppends={`&supplier_uuid=${supplier.uuid}`}
            />
          </>
        )}
        <div className="col-lg-12 form-group">
          <Field
            name="declaration"
            component={renderToggleInput}
            label="Declaration"
            validate={required}
            required
          />
          <small className="d-block">
            I declare that all information provided is correct to the best of my
            knowledge.
          </small>
        </div>
      </div>
      <SubmitButton {...props} className="w-100" />
    </form>
  );
};

const form = reduxForm({
  form: "ExternalSupplierForm",
  enableReinitialize: true,
});

const selector = formValueSelector("ExternalSupplierForm");

const mapStateToProps = (state) => {
  return {
    contact_email: selector(state, "contact_email"),
  };
};

export default connect(mapStateToProps, {})(form(ExternalForm));
