import "chart.js/auto";
import { Component } from "react";
import { BreakType } from "./timesheetTypes";
import { Bar } from "react-chartjs-2";

const options = {
  scales: {
    yAxes: {
      ticks: {
        beginAtZero: true,
      },
    },
    xAxes: {
      ticks: { display: false },
    },
  },
};

const formatProductivity = (productivity, label = "job_name") => {
  return {
    labels: productivity.map((prod, key) => {
      const isBreak = [BreakType.Paid, BreakType.Unpaid].includes(
        prod?.break_type,
      );

      return isBreak
        ? prod?.break_type === BreakType.Paid
          ? "Paid Break"
          : "Unpaid Break"
        : prod[label];
    }),
    datasets: [
      {
        label: "Productivity Hours",
        data: productivity.map((prod) => prod.time_in_hours),
        backgroundColor: "rgba(0, 170, 79, 0.2)",
        borderColor: "rgba(0, 170, 79, 1)",
        borderWidth: 1,
      },
    ],
  };
};
class ProductivityBarChart extends Component {
  clickProductivity = (prod) => {
    const { history, productivity } = this.props;
    const selectedProductivity = prod[0] ? productivity[prod[0]._index] : "";
    if (selectedProductivity)
      return history.push(`/jobs/${selectedProductivity.job_uuid}/details`);
  };

  render() {
    const { productivity, label } = this.props;
    return (
      <Bar
        options={options}
        getElementAtEvent={this.clickProductivity}
        data={formatProductivity(productivity, label)}
      />
    );
  }
}

export default ProductivityBarChart;
