import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getProjectRates = (
  { pageParam = 1 },
  search?: string,
  number?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `projects/${number}/equipment-rates?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useProjectEquipmentRates(
  search?: string,
  number?: string,
) {
  return useInfiniteQuery(
    [`project-equipment-rates/${number}`, { search }],
    (pageParam) => getProjectRates(pageParam, search, number),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
