import { useMemo } from "react";
import PaginatedList from "../pagination/PaginatedList";
import useInboundAccountsPayable from "./hooks/useInboundAccountsPayable";
import { InboundAccountsPayable } from "./types";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import DeleteButton from "../utils/DeleteButton";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Message } from "@microsoft/microsoft-graph-types";

type InboundAccountsPayableFilters = IFilter<"is_active", boolean>;

const InboundAccountsPayableList = () => {
  return (
    <>
      <HeaderPage
        titlePage="Inbound Accounts Payable"
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "/", name: "Inbound Accounts Payable", active: true },
        ]}
      />
      <PaginatedList
        listName="inboudAccountsPayableList"
        indexHook={useInboundAccountsPayable}
        originalFilters={[]}
        list={({ data }: { data?: InboundAccountsPayable[] }) => {
          if (!data) return null;
          return (
            <div className="col-12">
              <InboundAccountsPayableTable data={data} />
            </div>
          );
        }}
      />
    </>
  );
};

const InboundAccountsPayableTable = ({
  data,
}: {
  data: InboundAccountsPayable[];
}) => {
  const { loading, takeAction }: IUseApi = useApi();

  const queryClient = useQueryClient();

  const reject = (inbound: InboundAccountsPayable) => {
    return deleteSwal(inbound.subject).then(() =>
      takeAction("store", `inbound-accounts-payable/${inbound.uuid}/reject`)
        .then(() => {
          toast.success("Email Rejected");

          queryClient.invalidateQueries("inbound-accounts-payable");
        })
        .catch(errorSwal),
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "subject",
        header: "Subject",
        cell: (info: any) => {
          const inbound = info.row.original as Message;

          return (
            <div>
              {!inbound.hasAttachments ? (
                <p className="mb-0">{inbound.subject} (No Attachments)</p>
              ) : (
                <Link
                  to={`inbound-accounts-payable/${
                    inbound.id
                  }/details?from=${encodeURIComponent(
                    inbound.from?.emailAddress?.address ?? "",
                  )}&subject=${encodeURIComponent(inbound.subject ?? "")}`}
                >
                  {inbound.subject}
                </Link>
              )}
              <p className="mb-0">
                {inbound.from?.emailAddress?.address} (
                {inbound.from?.emailAddress?.name})
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "createdDateTime",
        header: "Received",
        cell: (info: any) => {
          const inbound = info.row.original as Message;

          return (
            <p>{new Date(inbound?.createdDateTime ?? "").toLocaleString()}</p>
          );
        },
      },
      {
        accessorKey: "_",
        header: "Reject",
        cell: (info: any) => {
          const inbound = info.row.original as InboundAccountsPayable;

          if (inbound.status) {
            return null;
          }

          return (
            <DeleteButton
              deleteFunction={() => {
                return reject(inbound);
              }}
              deleting={loading}
            />
          );
        },
      },
    ],
    [data, loading, reject],
  );

  return <ReactTable disableSearch columns={columns} data={data} />;
};

export const inboundAccountsPayableFilters: InboundAccountsPayableFilters[] = [
  {
    label: "Status",
    name: "is_active",
    options: [
      {
        label: "Not Complete",
        value: true,
        selected: true,
      },
      {
        label: "Complete",
        value: false,
      },
    ],
  },
];

export default InboundAccountsPayableList;
