import Linkify from "react-linkify";
import getBgColor from "../utils/getBgColor";
import getBgColorInvert from "../utils/getBgColorInvert";
import HeaderFooter from "./HeaderFooter";

const colorType = (props) => {
  if (props.invertColor) return getBgColorInvert(props.progress);

  return getBgColor(props.progress);
};

export const DashboardHeader = (props) => {
  return (
    <div>
      <div className="card card-profile mg-b-20 shadow-sm rounded-lg">
        <div className="card-body wrap-all">
          <div className="flex-grow-1">
            <div className="media-body">
              <Linkify>{props.children}</Linkify>
            </div>
          </div>
        </div>
        <HeaderFooter {...props} />
        {props.progressBar && (
          <div className="card-footer">
            <div
              style={{ width: `${props.progress}%` }}
              className={`progress-bar progress-bar-xs bg-${colorType(props)}`}
              role="progressbar"
              aria-valuenow={props.progress}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const DashboardH3 = ({ children, className = "" }) => (
  <h3 className={`card-profile-name ${className}`}>{children}</h3>
);
export const DashboardSubTitle = ({ children, className = "" }) => {
  if (!children) return null;
  return <p className={`tx-inverse mb-0 ${className}`}>{children}</p>;
};
