import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Fields from "./Fields";
import { reduxForm, submit } from "redux-form";
import SubmitButton from "../utils/SubmitButton";

const FormModal = (props) => {
  const { toggle, modal, handleSubmit, dispatch } = props;
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      style={{ maxWidth: "1000px", width: "500px" }}
    >
      <ModalHeader toggle={toggle}>Add new Required Competency</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <Fields />
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          {...props}
          onClick={() => dispatch(submit("RequiredCompetency"))}
        />
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm({ form: "RequiredCompetency" });

export default form(FormModal);
