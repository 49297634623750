import { TodoTask, WorkTypeRecord } from "./todoTypes";
import { ReactComponent as Project } from "../../svgs/construction.svg";
import { ReactComponent as Tender } from "../../svgs/spreadsheet.svg";
import { ReactComponent as Job } from "../../svgs/engineer.svg";
import { ReactComponent as Cpir } from "../../svgs/authentication.svg";
import { Link } from "react-router-dom";

const ShowWorkLink = ({ todo }: { todo: TodoTask }) => {
  const worktypeObject: WorkTypeRecord = {
    "App\\Models\\CPIR": {
      label: "CPIR",
      icon: Cpir,
    },
    "App\\Models\\ProjectJob": {
      label: "Job",
      icon: Job,
    },
    "App\\Models\\Tender": {
      label: "Tender",
      icon: Tender,
    },
    "App\\Models\\Project": {
      label: "Project",
      icon: Project,
    },
  };

  if (!todo.work) return null;

  const worktype = worktypeObject[todo.work?.type ?? ""];

  return (
    <>
      <p className="fw-bolder tx-12 mb-2 text-dark mt-3 mb-1">
        {worktype.label}
      </p>
      <Link
        to={`${todo.work.type === "App\\Models\\Project" ? "/" : ""}${
          todo.work.link
        }`}
      >
        <div className="bg-white position-relative w-100 p-3 border-warning rounded-lg shadow-sm d-flex align-items-center justify-content-center">
          <div className="text-center">
            <worktype.icon className="active" height="150px" width="150px" />
            <p className="mb-0 mt-3 text-dark">{todo.work.name}</p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ShowWorkLink;
