import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getProfileViews = (
  { pageParam = 1 },
  search: string,
  viewable_id: string,
  viewable_type: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `profiles/views?page=${pageParam}&viewable_type=${viewable_type}&viewable_id=${viewable_id}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useProfileViews(
  search: string,
  viewable_id: string,
  viewable_type: string,
) {
  return useInfiniteQuery(
    ["profile-views", { search, viewable_id, viewable_type }],
    (pageParam) =>
      getProfileViews(pageParam, search, viewable_id, viewable_type),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
