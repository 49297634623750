import React from "react";
import { useHistory, useParams } from "react-router";
import { InjectedFormProps, reduxForm } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import CustomForm from "../customFields/CustomForm";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import SubmitButton from "../utils/SubmitButton";
import { ClientFeedback } from "./clientFeedbackTypes";

const ExternalClientFeedbackForm = (props: InjectedFormProps) => {
  const { handleSubmit } = props;
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();

  const { takeAction }: IUseApi<ClientFeedback, { data: ClientFeedback }> =
    useApi();

  const onSubmit = (values: any) => {
    return takeAction("update", `client-feedback/${uuid}`, values)
      .then(({ data }) => {
        history.push("/success");
      })
      .catch(errorSwal);
  };

  const { data, loading }: IUseApi<any> = useApi(
    `client-feedback/${uuid}`,
    {},
    true,
  );

  return (
    <div className="signin-wrapper">
      <div className="signin-box signup" style={{ width: "800px" }}>
        <h2 className="slim-logo mb-5 text-center">
          {data?.organisation?.logo_url ? (
            <img src={data?.organisation?.logo_url} alt="Logo" height="100px" />
          ) : (
            <a
              href="https://landing.thebossapp.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              theBOSSapp
            </a>
          )}
        </h2>
        <Spinner loading={loading} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <CustomForm customForm={data?.custom_form ?? []} {...props} />
            <div className="col-12">
              <SubmitButton {...props} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const form = reduxForm({ form: "ExternalClientFeedback" });

export default form(ExternalClientFeedbackForm);
