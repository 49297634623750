import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import BugModal from "../reportBug/BugModal";

const NewMobileNav = ({ headerLinks }) => {
  const [isActive, setIsActive] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(false);

  const { user } = useAuth();
  const isPositionAdmin = user.is_position_admin;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setIsActive(false);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      const sideMenu = document.getElementById("side-menu");
      const hamburger = document.getElementById("hamburger");

      const target = e.target;

      if (target === hamburger) {
        return;
      }

      if (target.dataset.mobileMenu) {
        return;
      }

      if (target === sideMenu) {
        return;
      }

      closeSidebar();
    });
  }, []);

  const closeSidebar = () => {
    setIsActive(false);
    setActiveSubMenu(false);
  };

  const filteredHeaderLinks = isPositionAdmin
    ? headerLinks
    : headerLinks.filter(
        (headerLink) =>
          headerLink.id !== "organisation" ||
          (headerLink.id === "organisation" && isPositionAdmin),
      );

  return (
    <>
      <div
        className="slim-header-left menu-toggle d-lg-none d-xs-block"
        onClick={() => setIsActive(true)}
      >
        <i id="hamburger" className="fa fa-bars fa-lg" aria-hidden="true" />
      </div>

      <div
        className={`mobile mobile-three d-lg-none ${
          isActive ? "menu-active" : ""
        }`}
      >
        <div className="mobile-nav">
          <ul id="side-menu">
            <li className="link" onClick={closeSidebar}>
              <a href="javascript:void(0)">
                <i className="fa fa-times text-secondary" aria-hidden="true" />
                <br />
                Close
              </a>
            </li>

            {filteredHeaderLinks.map((header, index) => {
              return header.links ? (
                <NavWithSub
                  header={header}
                  setIsActive={setIsActive}
                  activeSubMenu={activeSubMenu}
                  setActiveSubMenu={setActiveSubMenu}
                  index={index}
                  closeSidebar={closeSidebar}
                  key={index}
                />
              ) : (
                <NavWithoutSub
                  header={header}
                  setIsActive={setIsActive}
                  closeSidebar={closeSidebar}
                  key={index}
                />
              );
            })}

            {user.active_organisation.beta && (
              <li className="link" onClick={toggle}>
                <a href="javascript:void(0)">
                  <i className="fa fa-bug fa-lg" aria-hidden="true" />
                  <br />
                  Support Request
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      <BugModal modal={modal} toggle={toggle} />
    </>
  );
};

const NavWithSub = ({
  header,
  activeSubMenu,
  setActiveSubMenu,
  index,
  closeSidebar,
}) => {
  const isMega = header.nav_type === "mega";

  return (
    <>
      <li
        className="link"
        data-mobile-menu="true"
        onClick={() => setActiveSubMenu(index)}
      >
        <a href="javascript:void(0)" data-mobile-menu="true">
          <i
            className={`${header.icon} fa-lg`}
            data-mobile-menu="true"
            aria-hidden="true"
          />
          <br data-mobile-menu="true" />
          {header.heading}
        </a>
      </li>
      <div
        className={`sub-menu ${
          activeSubMenu === index ? "sub-menu-active" : ""
        }`}
      >
        <ul
          className={`sub-ul ${activeSubMenu === index ? "sub-ul-active" : ""}`}
        >
          {header.links.map((headerLink, index) => {
            if (isMega) {
              return headerLink.links.map((link, i) => {
                return (
                  <Link
                    className="w-100"
                    to={link.link}
                    onClick={closeSidebar}
                    key={i}
                  >
                    <li>{link.value}</li>
                  </Link>
                );
              });
            }

            return (
              <Link
                className="w-100"
                to={headerLink.link}
                onClick={closeSidebar}
                key={index}
              >
                <li>{headerLink.value}</li>
              </Link>
            );
          })}
        </ul>
      </div>
    </>
  );
};

const NavWithoutSub = ({ header, closeSidebar = () => {} }) => {
  return (
    <li className="link">
      <Link to={header.link} onClick={closeSidebar}>
        <i className={`${header.icon} fa-lg`} aria-hidden="true" />
        <br />
        {header.heading}
      </Link>
    </li>
  );
};

export default NewMobileNav;
