import React from "react";
import { toast } from "react-toastify";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import formError from "../../utils/formError";
import FormModal from "../../utils/FormModal";
import RenderField from "../../utils/renderField";
import { InvoiceReminder, InvoiceReminderItem } from "./invoiceReminderTypes";

interface InvoiceReminderModalProps {
  toggle: Function;
  modal: boolean;
  invoiceReminder: InvoiceReminder;
  setInvoiceReminder: (reminder: InvoiceReminder) => void;
}

const InvoiceReminderModal = (props: InvoiceReminderModalProps) => {
  const { toggle, modal, invoiceReminder, setInvoiceReminder } = props;

  const { takeAction }: IUseApi<{}, { data: InvoiceReminderItem }> = useApi();

  const onSubmit: FormSubmitHandler<InvoiceReminderItem> = (values) => {
    return takeAction("store", `invoice-reminder-items`, values)
      .then(({ data }) => {
        toggle();
        toast.success("Reminder Added");
        setInvoiceReminder({
          ...invoiceReminder,
          items: [...invoiceReminder.items, data.data],
        });
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      form="AddReminder"
      onSubmit={onSubmit}
      title="Add Reminder"
    >
      <div className="col-lg-12">
        <Field
          component={RenderField}
          type="number"
          label="Amount of time after"
          name="length"
        />
      </div>
    </FormModal>
  );
};

export default InvoiceReminderModal;
