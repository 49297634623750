import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import useApi from "../api/useApi";
import PermissionGroupSubjects from "./PermissionGroupSubjects";
import {
  PermissionGroupProps,
  PermissionGroupSubject,
} from "./permissionGroupTypes";
import Avatar from "../utils/Avatar";

const UserPermissionGroups = (props: PermissionGroupProps) => {
  const { data } = useApi("/users?filter[employed]=true", [], true);

  const options = data as {
    id: number;
    full_name: string;
    email: string;
    secondary_email: string;
  }[];

  return (
    <>
      <p className="slim-card-title">Users</p>
      <PermissionGroupSubjects
        subjectTitle="User"
        subjectType="App\Models\User"
        {...props}
        options={options.map((option) => ({
          value: option.id,
          text: option.full_name,
          label: (
            <div className="d-flex align-items-center">
              <Avatar rounded="-circle" colour="info" name={option.full_name} />
              <div className="">
                <p className="mb-0 text-dark">{option.full_name}</p>
                <small className="mb-0 text-muted">
                  {option.secondary_email ?? option.email}
                </small>
              </div>
            </div>
          ),
        }))}
      />
    </>
  );
};

export default UserPermissionGroups;
