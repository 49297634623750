import { Field, reduxForm } from "redux-form";

import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const FormTimesheetApprovalUser = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            name="user_id"
            component={SelectInputAsync}
            url="employed-users"
            label="User"
            required
            validate={required}
            formatData={(data) => {
              return data.data.map((d) => {
                return {
                  label: d.name,
                  value: d.id,
                };
              });
            }}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="branch_id"
            component={SelectInputAsync}
            url={`/react-select?model=${encodeURIComponent(
              "App\\Models\\OrganisationBranch",
            )}&select=id,organisation_id,division_id,tracking_site_id&label=name&value=id`}
            label="Branch"
            required
            validate={required}
          />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({});

export default form(FormTimesheetApprovalUser);
