import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import Profile from "../profile";
import { DashboardHeader, DashboardH3 } from "../utils/DashboardHeader";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import ShowPage from "./ShowPage";
import { RouteParams } from "./projectTypeTypes";
import DeleteButton from "./DeleteButton";

const ProjectTypeProfile = () => {
  const { uuid } = useParams<RouteParams>();

  const {
    takeAction,
    data: projectType,
    loading,
    response,
    setData,
  } = useApi(`project-types/${uuid}`);

  const onSubmit = (values : any) => {
    return takeAction("update", `project-types/${uuid}`, values)
      .then(({ data } : { data : any}) => {
        setData(data.data);
        toast.success(`${values.name} updated.`);
      })
      .catch(errorSwal);
  };

  if (loading && !response) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={projectType?.name}
        crumbs={[
          { link: "/project-types", name: "Project Types" },
          { link: "", name: projectType?.name, active: true },
        ]}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{projectType?.name}</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowPage
            onSubmit={onSubmit}
            initialValues={projectType}
            projectType={projectType}
            setData={setData}
          />
        }
        tabs={[
          {
            link: `/project-types/${uuid}/details`,
            page: "details",
            label: "Details",
            icon: "",
          }
        ]}
        sideBar={<DeleteButton />}
      />
    </>
  );
};

export default ProjectTypeProfile;
