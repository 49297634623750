import React from "react";
import { FormSubmitHandler } from "redux-form";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import EmploymentAwardForm from "./EmploymentAwardForm";
import useAddEmploymentAward from "./hooks/useAddEmploymentAward";
import { EmploymentAward } from "./types";

const AddEmploymentAward = () => {
  const addAward = useAddEmploymentAward();

  const onSubmit: FormSubmitHandler<EmploymentAward> = (values) => {
    return addAward(values).catch(formError);
  };

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Add Employment Award" />

      <EmploymentAwardForm form="AddEmploymentAward" onSubmit={onSubmit} />
    </>
  );
};

export default AddEmploymentAward;
