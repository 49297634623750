import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";

const Add = () => {
  const { takeAction } = useApi();
  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", "budget-templates", values)
      .then(({ data }) => {
        toast.success("Budget Added");
        history.push(`/budget-templates/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Add New Budget Template"
        crumbs={[
          {
            name: "Budget Templates",
            link: "/budget-templates",
          },
          {
            name: "Add New Budget",
            link: "/",
            active: true,
          },
        ]}
      />

      <Form onSubmit={onSubmit} form="AddBudgetTemplate" />
    </>
  );
};

export default Add;
