import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector } from "redux-form";
import TemplateType from "../../enums/TemplateType";
import axios from "../api/api";
import useApi from "../api/useApi";
import CustomForm from "../customFields/CustomForm";
import SelectInput from "../form/SelectInput";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import toggle from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import signaturePad from "../utils/signaturePad";
import SubmitButton from "../utils/SubmitButton";
import EmailTo from "./emailTo";
import DocketTable from "./Table";

const DocketForm = (props) => {
  const {
    initialValues,
    jobs,
    items,
    notes,
    getItems,
    change,
    setJobs,
    project_id,
    docket_type_id,
  } = props;

  const [clientContacts, setClientContacts] = useState([]);

  const { data: template } = useApi(
    `template-types?type=${TemplateType.Docket}`,
  );

  const { data: docketTypes } = useApi(
    "/docket-types?paginate=false&filter[has_template]=false",
    [],
  );

  const {
    data: docketType,
    setUrl,
    setData: setDocketType,
  } = useApi(
    initialValues?.docket_type
      ? `docket-types/${initialValues?.docket_type?.uuid}`
      : null,
  );

  useEffect(() => {
    if (project_id) {
      axios
        .get(`project-client-contacts?project_id=${project_id}`)
        .then(({ data }) => setClientContacts(data));
    }
  }, []);

  const changeJob = (e, val) => getItems(val);

  const changeProject = (e, val) => {
    change("jobs", "");

    axios
      .get(`docket-jobs?project_id=${val}`)
      .then(({ data }) => setJobs(data.data))
      .catch(() => {
        errorSwal("Unable to fetch jobs for that project");
      })
      .then(() => axios.get(`project-client-contacts?project_id=${val}`))
      .then(({ data }) => setClientContacts(data))
      .catch(() => errorSwal("Unable to fetch contacts for that project"));
  };

  return (
    <div className="row">
      <div className="col-12 form-group">
        {docketTypes.length > 0 ? (
          <Field
            component={SelectInput}
            name="docket_type_id"
            label="Docket Type"
            options={docketTypes.map((type) => ({
              value: type.id,
              uuid: type.uuid,
              label: type.name,
            }))}
            changeValue={(value) => {
              change("custom_fields", "");

              if (!value) {
                setDocketType(null);

                return;
              }

              setUrl(`docket-types/${value.uuid}`);
            }}
          />
        ) : null}
      </div>

      {!initialValues?.uuid && (
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInputAsync}
            name="project_id"
            label="Project"
            url="/projects/list"
            required
            validate={required}
            onChange={changeProject}
          />
        </div>
      )}
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInputAsync}
          options={jobs}
          label="Jobs"
          multiple
          name="jobs"
          onChange={changeJob}
          required
          validate={required}
        />
      </div>
      <div className="col-md-6 form-group">
        <Field
          required
          component={SelectInputAsync}
          name="user_id"
          label="Technician"
          url="/users/staff"
          validate={required}
        />
      </div>
      <div className="col-md-6 form-group">
        <Field
          required
          validate={required}
          component={renderField}
          name="date"
          type="date"
          label="Date"
        />
      </div>
      <div className="col-md-6 form-group">
        <Field name="overtime" label="Overtime" component={toggle} />
      </div>
      <div className="col-12 form-group">
        <Field name="notes" component={renderField} textarea label="Notes" />
      </div>
      {docketType && (
        <CustomForm customForm={docketType.custom_fields} {...props} />
      )}
      <div className="col-12 form-group">
        <DocketTable {...props} items={items} notes={notes} />
      </div>
      {!initialValues?.uuid && (
        <>
          <div className="col-12 form-group">
            <Field
              component={signaturePad}
              label="Client Signature"
              name="signature"
              validate={required}
            />
          </div>
          <div className="col-12 form-group">
            <Field
              component={renderField}
              label="Signatory Name"
              name="signatory"
              required
              validate={required}
            />
          </div>
          {template?.template?.documents?.length > 1 && !docket_type_id && (
            <div className="col-12 form-group">
              <Field
                component={SelectInput}
                label="Select Template"
                name="template_id"
                required
                validate={required}
                options={
                  template?.template?.documents?.map((document) => ({
                    label: document.name,
                    value: document.id,
                  })) ?? []
                }
              />
            </div>
          )}
          <div className="col-12 form-group">
            <Field
              component={SelectInputAsync}
              name="recipients"
              multiple
              ariaLabel="send to"
              label="Send Docket To"
              options={clientContacts}
            />
          </div>
          <FieldArray
            name="extra_recipients"
            component={EmailTo}
            label="Email To"
          />
        </>
      )}

      <div className="col-12 mt-3">
        <SubmitButton {...props} />
      </div>
    </div>
  );
};

const selector = formValueSelector("Docket");

const mapStateToProps = (state) => ({
  notes: selector(state, "notes"),
  project_id: selector(state, "project_id"),
  docket_type_id: selector(state, "docket_type_id"),
});

export default connect(mapStateToProps, {})(DocketForm);
