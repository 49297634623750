import React from "react";
import { fromEvent } from "file-selector";
import { useDropzone } from "react-dropzone";
import { Field } from "redux-form";
import swal from "sweetalert";
import getFileIcon from "./getFileIcon";

const FileList = (props) => {
  const { files, setFiles, change } = props;

  const removeFile = (fileToRemove, i) => {
    const filtered = files.filter((f) => filterFile(f, fileToRemove));
    setFiles(filtered);
    change("documents", filtered);
  };

  const onDrop = (dropped, rejected) => {
    if (rejected.length > 0) {
      return swal(
        "Too many Files",
        "You are only allowed up to 20 files at a time",
        "warning",
      );
    }

    const prevFiles = files;
    const allFiles = [...files, ...dropped];
    setFiles(allFiles);

    if ([...files, ...dropped].length > 20) {
      setFiles(prevFiles);
      return swal(
        "Too many Files",
        "You are only allowed up to 20 files at a time",
        "warning",
      );
    }

    allFiles.forEach((file, i) => change(`documents[${i}]`, file));
  };

  async function getCustomFile(event) {
    if (event.type === "drop") {
      const items = event.dataTransfer?.items;
      for (var i = 0; i < items.length; i++) {
        let item = items[i].webkitGetAsEntry();
        if (item?.isDirectory) {
          swal(
            "File Type Error",
            `Only files are allowed. Current upload contains folder.`,
            "warning",
          );
          return [];
        }
      }
    }
    return await fromEvent(event);
  }

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    maxFiles: 20,
    getFilesFromEvent: (event) => getCustomFile(event),
  });

  return (
    <>
      <div className="file-group">
        {files.map((file, i) => (
          <div
            className={`file-item ${file.link ? "pointer-hover" : ""}`}
            key={file.preview}
            onClick={() =>
              file.link ? (window.location.href = file.link) : null
            }
          >
            <div className="row no-gutters wd-100p">
              <div className="col-9 col-sm-9 d-flex align-items-center">
                <i className={getFileIcon(file.name)} />
                <div>
                  <p className="tx-inverse" style={{ marginBottom: "-6px" }}>
                    {file.name}
                  </p>
                  {file.created_by?.name && (
                    <small>
                      Uploaded by{" "}
                      <a href={`mailto:${file.created_by?.email}`}>
                        {file.created_by?.name}
                      </a>
                    </small>
                  )}
                </div>
              </div>
              <div className="col-3 d-flex justify-content-end">
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    return removeFile(file, i);
                  }}
                  className="fa fa-close pointer-hover"
                />
              </div>
            </div>
            <Field component={FileComponent} name={`documents[${i}]`} />
          </div>
        ))}
      </div>
      <div className={`fileuploader ${isDragAccept ? "dragging" : ""}`}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div id="upload-label">
            <i className="fa fa-cloud-upload" />
          </div>
        </div>
      </div>
    </>
  );
};

const filterFile = (currentFile, fileToRemove) => {
  if (currentFile.uuid) {
    return currentFile.uuid !== fileToRemove.uuid;
  }

  return currentFile.lastModified !== fileToRemove.lastModified;
};

const FileComponent = () => <input type="file" style={{ display: "none" }} />;

export default FileList;
