import React from "react";
import { ReactComponent as Logo } from "../../svgs/logo-grey.svg";

const BottomLogo = ({ size = 50, left = 180, bottom = 45 }) => {
  return (
    <div
      className="position-absolute firefox-support"
      style={{
        bottom: "0px",
        right: "0px",
        overflow: "hidden",
      }}
    >
      <div style={{ margin: `0px -${left}px -${bottom}px 0px` }}>
        <Logo
          height={`${size}%`}
          width={`${size}%`}
          className="logo-svg tn-300"
        />
      </div>
    </div>
  );
};

export default BottomLogo;
