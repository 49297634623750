import React from "react";
import { amountOfHours } from "./functions/timesheetFunctions";

const ProductivityCard = ({ addTimesheet, events, className }) => {
  if (!addTimesheet) return null;
  const totalHours = amountOfHours(events);
  return (
    <div className={`card card-sales mg-t-20 ${className}`}>
      <h6 className="slim-card-title tx-success">Productivity</h6>
      <div className="row">
        <div className="col">
          <label className="tx-12">Hours</label>
          <p className={`${totalHours > 10 ? "text-danger" : ""}`}>
            {totalHours.toFixed(2)}
          </p>
          {totalHours > 12 && (
            <small className="text-danger">
              It looks like you have done too many hours for one day. Please
              check your productivity is correct.
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductivityCard;
