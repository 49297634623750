import React from "react";
import { Button } from "reactstrap";
import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import isSubmitting from "../../utils/submitting";
import { toast } from "react-toastify";

const SyncBranches = () => {
  const { takeAction, loading } = useApi();

  const syncBranches = () => {
    return takeAction("store", "the-testing-app-branches")
      .then(() => toast.success("Branches Synced"))
      .catch(errorSwal);
  };

  return (
    <Button onClick={syncBranches}>
      {isSubmitting(loading, "Sync all Branches", "Syncing...")}
    </Button>
  );
};

export default SyncBranches;
