import React, { useState } from "react";
import { Field } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import useApi from "../api/useApi";

const Fields = () => {
  const [skillCategorySkills, setSkillCategorySkills] = useState([]);
  const [skillLevels, setSkillLevels] = useState([]);
  const { takeAction } = useApi();

  const handleSkillCategoryChange = (_, value) => {
    setSkillCategorySkills([]);

    takeAction("index", `skill-categories/${value}/competency-skills`).then(
      ({ data }) =>
        setSkillCategorySkills(
          data.data.map((d) => ({
            label: d.skill,
            value: d.id,
            skillLevels: d.skill_levels,
          })),
        ),
    );
  };

  const handleSkillChange = (v, value) => {
    const skillCategory = skillCategorySkills.find((s) => s.value === value);

    setSkillLevels(
      skillCategory.skillLevels.map((level) => ({
        label: level.name,
        value: level.id,
      })),
    );
  };

  return (
    <div className="rows">
      <div className="col-12 form-group">
        <Field
          component={SelectInputAsync}
          url="/skill-categories"
          name="skill_category"
          label="Category"
          formatData={formatSkillCategories}
          onChange={handleSkillCategoryChange}
        />
      </div>
      <div className="col-12 form-group">
        <Field
          component={SelectInputAsync}
          options={skillCategorySkills}
          name="skill"
          label="Competency Skill"
          onChange={handleSkillChange}
        />
      </div>
      <div className="col-12 form-group">
        <Field
          component={SelectInputAsync}
          options={skillLevels}
          name="skill_level_id"
          label="Skill Level"
        />
      </div>
    </div>
  );
};

const formatSkillCategories = ({ data }) => {
  return data.map(( skillCategory ) => {
    return {
      label: skillCategory.name,
      value: skillCategory.uuid,
    };
  });
};

export default Fields;
