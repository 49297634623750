import React, { useEffect } from "react";
import { EquipmentCalibrationType } from "./equipmentCalibrationTypeTypes";
import {
  Field,
  FieldArray,
  FormSubmitHandler,
  InjectedFormProps,
  WrappedFieldArrayProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import FormHeader from "../utils/FormHeader";
import SelectInput from "../form/SelectInput";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";

interface EquipmentCalibrationWorksheetProps {
  calibrationType: EquipmentCalibrationType;
}

const EquipmentCalibrationWorksheet = (
  props: InjectedFormProps<{}, EquipmentCalibrationWorksheetProps> &
    EquipmentCalibrationWorksheetProps,
) => {
  const { calibrationType, handleSubmit } = props;

  const { takeAction }: IUseApi = useApi();

  const {
    data: equipmentTypes,
  }: IUseApiWithData<{ name: string; id: number }[]> = useApi(
    "custom-fields/equipment-types",
    [],
  );

  const { data: worksheets }: IUseApiWithData<string[]> = useApi(
    `workbook-sheets?id=${calibrationType.document_id}`,
    [],
  );

  const onSubmit: FormSubmitHandler<{}, EquipmentCalibrationWorksheetProps> = (
    data,
  ) => {
    return takeAction(
      "update",
      `calibration-types/${calibrationType.uuid}/worksheet`,
      data,
    ).then(() => {
      toast.success("Worksheet updated successfully");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FieldArray
          name="worksheet_equipment_types"
          equipmentTypes={equipmentTypes}
          component={EquipmentTypeSheets}
          worksheets={worksheets}
        />
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const EquipmentTypeSheets = ({
  fields,
  worksheets,
  equipmentTypes,
}: WrappedFieldArrayProps & {
  worksheets: string[];
  equipmentTypes: { name: string; id: number }[];
}) => {
  return (
    <>
      <FormHeader>Equipment Sheets</FormHeader>
      {fields.map((name, index) => {
        return (
          <React.Fragment key={name}>
            <div className="col-12 form-group">
              <Field
                component={SelectInput}
                name={`${name}.sheet_name`}
                label="Sheet Name"
                options={worksheets.map((sheet) => ({
                  label: sheet,
                  value: sheet,
                }))}
              />
            </div>
            <FieldArray
              component={EquipmentTypeFields}
              name={`${name}.equipment_types`}
              equipmentTypes={equipmentTypes}
            />
          </React.Fragment>
        );
      })}
      <div className="col-12">
        <Button color="primary" onClick={() => fields.push({})}>
          Add Equipment Sheet
        </Button>
      </div>
    </>
  );
};

const EquipmentTypeFields = ({
  fields,
  equipmentTypes,
}: WrappedFieldArrayProps & {
  equipmentTypes: { name: string; id: number }[];
}) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <WrappedEquipmentType
            key={field}
            field={field}
            index={index}
            fields={fields}
            equipmentTypes={equipmentTypes}
          />
        );
      })}
      <div className="col-12 form-group">
        <Button onClick={() => fields.push({})}>Add Equipment Type</Button>
      </div>
    </>
  );
};

const EquipmentType = ({
  field,
  index,
  fields,
  equipmentTypes,
  equipmentTypeId,
}: any) => {
  const { data, setUrl } = useApi("", []);

  useEffect(() => {
    if (equipmentTypeId) {
      setUrl(
        `/custom-fields?model=${encodeURIComponent(
          "App\\Models\\EquipmentType",
        )}&modelId=${equipmentTypeId}`,
      );
    }
  }, [equipmentTypeId]);

  return (
    <>
      <div className="col-lg-5 form-group">
        <Field
          component={SelectInput}
          name={`${field}.equipment_type_id`}
          label="Equipment Type"
          options={equipmentTypes.map((t: any) => ({
            label: t.name,
            value: t.id,
          }))}
        />
      </div>
      <div className="col-lg-5 form-group">
        <Field
          component={SelectInput}
          isMulti
          options={data.map((d: any) => ({
            label: d.field_attributes.label,
            value: d.id,
          }))}
          name={`${field}.custom_field_ids`}
          label="Custom Fields"
        />
      </div>
      <div className="col-lg-2 form-group d-flex align-self-end">
        <Button
          outline
          block
          className=""
          color="danger"
          onClick={() => fields.remove(index)}
        >
          Remove
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: any, props: any) => {
  const selector = formValueSelector("equipmentCalibrationWorksheet");

  return {
    equipmentTypeId: selector(state, `${props.field}.equipment_type_id`),
  };
};

const WrappedEquipmentType = connect(mapStateToProps)(EquipmentType);

const form = reduxForm<{}, EquipmentCalibrationWorksheetProps>({
  form: "equipmentCalibrationWorksheet",
});

export default form(EquipmentCalibrationWorksheet);
