import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchCompactions,
  updateCompaction,
} from "../../actions/compactionActions";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";

import Spinner from "../utils/Spinner";
import CompactionForm from "./CompactionForm";
import CompactionPoints from "./CompactionPoints";
import Curve from "./Curve";
import Details from "./Details";
import TestStatus from "./TestStatus";

const Compaction = (props) => {
  const [loading, setLoading] = useState(true);
  const { compaction, match, fetchCompactions } = props;

  useEffect(() => {
    fetchCompactions(match.params.test)
      .catch((err) => errorSwal(err))
      .then(() => setLoading(false));
  }, []);

  if (loading) return <Spinner loading />;

  const ovenBalanceCompaction = {
    ...compaction,
    oven_id: compaction.moisture ? compaction.moisture.oven_id : null,
    balance_id: compaction.moisture ? compaction.moisture.balance_id : null,
  };

  return (
    <>
      <HeaderPage
        titlePage={`${compaction.sample} - ${compaction.test_method}`}
        crumbs={[
          { link: "testrequests", name: "Test Requests" },
          {
            link: compaction.test_request.link,
            name: compaction.test_request.name,
          },
          {
            link: "",
            name: `${compaction.sample} - ${compaction.test_method}`,
            active: true,
          },
        ]}
      />

      <CompactionForm initialValues={ovenBalanceCompaction} {...props} />
      <CompactionPoints initialValues={compaction} {...props} />
      {compaction.OMC && <Details {...props} />}
      <Curve points={compaction.points} compaction={compaction} />
      <TestStatus compaction={compaction} />
    </>
  );
};

const mapStateToProps = (state) => ({
  compaction: state.compactions.items,
});

export default connect(mapStateToProps, { fetchCompactions, updateCompaction })(
  Compaction,
);
