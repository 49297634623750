import _ from "lodash";
import { BiCheck } from "react-icons/bi";
import { SkillCategory } from "../skillCategories/SkillCategoriesList";
import Avatar from "../utils/Avatar";
import { SelectedSkill } from "./SkillStaffMatrix";

const StaffMatrixSkillCategory = ({
  skillCategory,
  selectedSkills,
  setSelectedSkills,
}: {
  skillCategory: SkillCategory;
  selectedSkills: SelectedSkill[];
  setSelectedSkills: (selectedSkills: SelectedSkill[]) => void;
}) => {
  return (
    <div key={skillCategory.id} className="bg-white border rounded">
      <div className="d-flex align-items-center w-100 p-3">
        <div style={{ maxWidth: "15%" }}>
          <Avatar rounded="-circle" name={skillCategory.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p className="mb-0 fw-bolder tx-inverse no-wrap">
            {skillCategory.name}
          </p>
          <p className="mb-0">
            {skillCategory.skills_count} skill
            {skillCategory.skills_count === 1 ? "" : "s"}
          </p>
        </div>
      </div>
      <div>
        {skillCategory?.competency_skills?.map((skill, i) => {
          const isSelected = _.find(selectedSkills, { uuid: skill.uuid });
          return (
            <button
              type="button"
              className={`text-start w-100 p-3 tn-300 border-0 border border-bottom ${
                isSelected ? "bg-gray-100" : "bg-white"
              } ${i === 0 ? "border-top" : ""}`}
              onClick={() => setSelectedSkills(_.xor(selectedSkills, [skill]))}
              key={skill.uuid}
            >
              <div className="w-100 d-flex">
                <div>{skill.skill}</div>

                {isSelected && (
                  <div className="ms-auto">
                    <BiCheck className="text-success tx-18" />
                  </div>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default StaffMatrixSkillCategory;
