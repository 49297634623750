import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { fromEvent } from "file-selector";
import useApi from "../api/useApi";
import UploadOverlay from "../documents/UploadOverlay";
import UploadProgress from "../documents/UploadProgress";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import { checkMobileAndTablet } from "../utils/deviceDetection";
import Empty from "./Empty";
import StandardDocumentList from "./StandardDocumentList";
import parseFolders from "./parseFolders";
import { useQueryClient } from "react-query";

const Upload = (props) => {
  const { folderUuid } = useParams();

  const {
    documents,
    homeUrl,
    documentPath,
    model,
    documentableId,
    highlightedDocuments,
    setHighlightedDocuments,
  } = props;
  const { takeAction } = useApi();
  const { files, upload, setFiles } = useUploadMultipleToS3(documentPath);

  const queryClient = useQueryClient();

  const onDrop = async (allFilesAndRootFolders, rejectedFiles) => {
    const uploadFiles = allFilesAndRootFolders.filter(
      (item) => item instanceof File && item?.name !== ".DS_Store",
    );

    const rootFolders = allFilesAndRootFolders.filter((item) => item?.folder);

    function getEmptyFolders(children, item, list) {
      let emptyList = list ?? [];
      if (children?.length === 0) {
        emptyList.push({ ...item, isEmpty: true });
        return;
      }
      for (var i = 0; i < children?.length; i++) {
        getEmptyFolders(children[i]?.children, children[i], emptyList);
      }
      return emptyList;
    }

    const emptyFolders =
      rootFolders?.length > 0 ? getEmptyFolders(rootFolders) : [];

    if (rejectedFiles.length > 0) {
      rejectedFiles.map(({ file, errors }) => {
        errors.map((e) => {
          if (e.code == "too-many-files") {
            swal(
              "Over File Limit ",
              "Only allowed to upload a maximum of 25 files at a time",
              "warning",
            );
          } else if (e.code == "File Name Too Long") {
            swal(
              e.code,
              e.message.concat(` Check file: ${file.name}.`),
              "warning",
            );
          } else {
            swal(e.code, e.message, "warning");
          }
        });
      });
      return;
    }

    return upload(uploadFiles, true)
      .then((documents) => {
        const uploadedDocuments = documents.filter((doc) => doc?.name);
        const uploadedDocumentNameList = uploadedDocuments.map(
          (doc) => doc.name,
        );
        const notUploadedFiles = uploadFiles.filter((uploadFile) => {
          return !uploadedDocumentNameList.includes(uploadFile?.name);
        });
        if (notUploadedFiles?.length) {
          swal(
            `Unable to upload`,
            `The following file${
              notUploadedFiles?.length > 1 ? "s are " : " is "
            }not uploaded.\n
            ${notUploadedFiles.map((f) => f.name).toString()}\n
            Please upload the missing file${
              notUploadedFiles?.length > 1 ? "s" : ""
            } again.
            `,
            "warning",
          );
        }
        return takeAction("store", "documents/standard", {
          documents: parseFolders(
            emptyFolders.concat(uploadedDocuments),
            "filePath",
          ),
          documentable_type: model,
          documentable_id: documentableId,
          document_id: folderUuid,
        });
      })
      .then(({ data }) => {
        toast.success("Documents Uploaded successfully!");
        setFiles([]);

        queryClient.invalidateQueries("standard-documents");
      });
  };

  async function getFile(fileEntry) {
    try {
      return await new Promise((resolve, reject) =>
        fileEntry.file(resolve, reject),
      );
    } catch (err) {
      swal(
        "File Error",
        `Unable to read the file - ${fileEntry.name}, check file permission and/or its existance. This file might be missing from upload.`,
        "warning",
      );
    }
  }

  async function getEntriesTree(item, path, filesAndFolders) {
    if (item instanceof File && item?.name !== ".DS_Store") {
      filesAndFolders.push({
        name: item.name,
        path: item.path,
        filePath: item.path,
        file_size: item.size,
        mime_type: item.type,
      });
    } else if (item.isFile && item?.name !== ".DS_Store") {
      const file = await getFile(item);
      if (file) {
        Object.defineProperty(file, "path", {
          value: path + item?.name,
        });
        filesAndFolders.push({
          name: file.name,
          path: file.path,
          filePath: file.path,
          file_size: file.size,
          mime_type: file.type,
        });
      }
    } else if (item.isDirectory) {
      const folder = {
        folder: item?.name,
        name: item.name,
        path: path + item.name,
        filePath: path + item.name,
        file_size: 0,
        children: [],
      };
      filesAndFolders.push(folder);
      var dirReader = item.createReader();
      dirReader.readEntries(function (entries) {
        for (var i = 0; i < entries.length; i++) {
          getEntriesTree(entries[i], path + item.name + "/", folder.children);
        }
      });
    }
  }

  async function getCustomFile(event) {
    let filesAndFoldersWithChildren = [];
    if (event.type === "drop") {
      const items = event.dataTransfer?.items;
      for (var i = 0; i < items.length; i++) {
        let item = items[i].webkitGetAsEntry();
        item?.isDirectory &&
          (await getEntriesTree(item, "/", filesAndFoldersWithChildren));
      }
    }
    const files = await fromEvent(event);
    return filesAndFoldersWithChildren.concat(files);
  }

  function nameLengthValidator(file) {
    if (file.name.length > 255) {
      return {
        code: "File Name Too Long",
        message: `File Name is larger than 255 characters.`,
      };
    }
    return null;
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      maxFiles: 25,
      validator: nameLengthValidator,
      noClick: documents.length > 0 && !checkMobileAndTablet(),
      getFilesFromEvent: (event) => getCustomFile(event),
    });

  const { getRootProps: getClickRootProps, getInputProps: getClickInputProps } =
    useDropzone({
      onDrop,
      maxFiles: 25,
      validator: nameLengthValidator,
      getFilesFromEvent: (event) => getCustomFile(event),
    });

  if (checkMobileAndTablet()) {
    return (
      <>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="fileuploader">
            <div id="upload-label">
              <i className="fa fa-cloud-upload" />
            </div>
          </div>
        </div>
        <UploadProgress files={files} />
        <StandardDocumentList
          documents={documents}
          url={homeUrl}
          highlightedDocuments={highlightedDocuments}
          setHighlightedDocuments={setHighlightedDocuments}
        />
      </>
    );
  }

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <UploadProgress files={files} />
        {documents.length !== 0 ? (
          <>
            <StandardDocumentList
              documents={documents}
              url={homeUrl}
              highlightedDocuments={highlightedDocuments}
              setHighlightedDocuments={setHighlightedDocuments}
            />
            <UploadOverlay isDragActive={isDragActive} />
          </>
        ) : (
          <Empty
            folder={{ name: "" }}
            isDragActive={isDragActive}
            isDragAccept={isDragAccept}
          />
        )}
      </div>
      {documents.length !== 0 && (
        <div {...getClickRootProps()}>
          <input {...getClickInputProps()} />
          <div className="fileuploader">
            <div id="upload-label">
              <i className="fa fa-cloud-upload" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Upload;
