import dayjs from "dayjs";
import _ from "lodash";
import { BsChevronDown, BsChevronLeft } from "react-icons/bs";
import useModal from "../hooks/useModal";
import Avatar from "../utils/Avatar";
import TextButton from "../utils/TextButton";
import { TimesheetUser } from "./PayRunForm";

const PayRunOverviewUser = ({ user }: { user: TimesheetUser }) => {
  const { modal: showTimesheets, toggle } = useModal();

  return (
    <div className="bg-white shadow-sm rounded-lg ">
      <TextButton
        onClick={toggle}
        className="w-100 text-start d-flex align-items-center p-3"
      >
        <Avatar name={user.user.name} colour="info" />
        <div>
          <p className="mb-0 text-dark">{user.user.name}</p>
          <p className="mb-0 text-secondary">
            {user.timesheets.length} Timesheets,{" "}
            {_.sumBy(user.timesheets, "total_hours").toFixed(2)} hours
          </p>
        </div>
        <div className="ms-auto">
          {showTimesheets ? <BsChevronDown /> : <BsChevronLeft />}
        </div>
      </TextButton>
      {showTimesheets && (
        <div>
          <div className="p-3">
            <div className="space-y-3">
              {user.timesheets.map((timesheet) => {
                return (
                  <div className="d-flex">
                    <div>
                      <a className="d-block" href={timesheet.link}>
                        {dayjs(timesheet.date).format("DD/MM/YYYY")}
                      </a>
                      <small>{timesheet.type_description}</small>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="p-3 border-top bg-light rounded d-flex space-x-3">
            {user.user.email ? (
              <a
                className="btn btn-info btn-sm text-white"
                href={`mailto:${user.user.email}`}
              >
                Email
              </a>
            ) : null}
            {user.user.phone ? (
              <a className="btn btn-sm border" href={`tel:${user.user.phone}`}>
                Call
              </a>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default PayRunOverviewUser;
