import React from "react";
import { Button } from "reactstrap";
import useApi from "../../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../../utils/errorSwal";
import isSubmitting from "../../utils/submitting";

const SyncClients = () => {
  const { takeAction, loading } = useApi();
  const syncClients = () => {
    return takeAction("store", "the-site-classifier-app-clients")
      .then(() => toast.success("Clients Synced"))
      .catch(errorSwal);
  };

  return (
    <div>
      <Button onClick={syncClients} disabled={loading}>
        {isSubmitting(loading, "Sync all Clients", "Syncing...")}
      </Button>
    </div>
  );
};

export default SyncClients;
