import ReactLinkify from "react-linkify";
import CommentItem from "./CommentItem";

const CommentList = ({ comments }) => {
  return (
    <ReactLinkify properties={{ target: "_blank" }}>
      <div>
        {comments.map((comment) => {
          return <CommentItem comment={comment} key={comment.uuid} />;
        })}
      </div>
    </ReactLinkify>
  );
};

export default CommentList;
