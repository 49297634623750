import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import FormBillableItemUnit from "./BillableItemUnitForm";

const AddBillableItemUnit = () => {
  const { takeAction } = useApi();

  const history = useHistory();

  const queryClient = useQueryClient();

  const onSubmit = (values) => {
    return takeAction("store", "billable-item-units", values)
      .then(({ data }) => {
        toast.success(`${data.data.name} added successfully`);

        return queryClient.invalidateQueries("billable-item-units");
      })
      .then(() => history.push(`/billable-item-units`))
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Billable Item Unit"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "billable-item-units", name: "Billable Item Unit" },
          {
            link: "billable-item-units/add",
            name: "New Billable Item Unit",
            active: true,
          },
        ]}
      />

      <FormBillableItemUnit form="AddBillableItemUnit" onSubmit={onSubmit} />
    </>
  );
};

export default AddBillableItemUnit;
