import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { UserCompetent } from "./Fields";
import SelectInput from "../form/SelectInput";
import useApi from "../api/useApi";
import RenderField from "../utils/renderField";
import formError from "../utils/formError";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";

interface SelectSupervisorProps {
  userCompetent?: UserCompetent;
  supervisors: {
    name: string;
    id: number;
    title?: string;
    uuid: string;
  }[];
  testMethodId: number;
  refreshData: Function;
}

interface FormValues {
  supervisor_id: string;
  password: string;
}

const SelectSupervisor = (
  props: InjectedFormProps<FormValues, SelectSupervisorProps> &
    SelectSupervisorProps,
) => {
  const {
    handleSubmit,
    userCompetent,
    supervisors,
    testMethodId,
    refreshData,
  } = props;

  const { takeAction } = useApi();

  const onSubmit: FormSubmitHandler<FormValues, SelectSupervisorProps> = (
    values,
  ) => {
    return takeAction("store", `test-supervisors/${values.supervisor_id}`, {
      test_methods: [testMethodId],
      password: values.password,
    })
      .then(() => {
        refreshData();
        toast.success("Supervisor Added");
      })
      .catch(formError);
  };

  const canUpdateStatus =
    userCompetent &&
    (userCompetent.competencies.length > 0 ||
      userCompetent.supervisors.length > 0);

  if (canUpdateStatus) {
    return null;
  }

  return (
    <div className="bg-white p-3 rounded-lg border mt-3">
      <label className="slim-card-title mb-0">Supervisor Required</label>
      <p>
        As you are not competent to perform this test, you must select a
        supervisor to approve before marking the test as complete.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-12 form-group">
            <Field
              component={SelectInput}
              label="Supervisor"
              name="supervisor_id"
              options={supervisors.map((supervisor) => ({
                value: supervisor.uuid,
                label: supervisor.name,
              }))}
            />
          </div>
          <div className="col-lg-12 form-group">
            <Field
              component={RenderField}
              name="password"
              type="password"
              label="Password"
            />
          </div>
          <div className="col-lg-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </div>
  );
};

const form = reduxForm<FormValues, SelectSupervisorProps>({});

export default form(SelectSupervisor);
