import dayjs from "dayjs";
import { Button } from "reactstrap";
import useModal from "../hooks/useModal";
import EditInvoiceDate from "./EditInvoiceDate";
import { InvoiceState } from "./invoiceTypes";
import useUserGroup from "../hooks/useUserGroup";

const InvoiceDate = ({ invoice, setInvoice }: InvoiceState) => {
  const { toggle, modal } = useModal();

  const isAccounting = useUserGroup("Accounting");

  return (
    <div className="d-flex align-items-center mb-1">
      <p className="mb-0 tx-inverse">
        {invoice.date ? (
          <>
            Date:{" "}
            <span className="fw-bolder">
              {dayjs(invoice.date).format("DD/MM/YYYY")}
            </span>
          </>
        ) : (
          "No Date Added"
        )}
      </p>
      {isAccounting && (
        <Button
          className="ms-auto"
          outline
          color="info"
          size="sm"
          onClick={toggle}
        >
          Edit
        </Button>
      )}
      <EditInvoiceDate
        setInvoice={setInvoice}
        invoice={invoice}
        toggle={toggle}
        modal={modal}
      />
    </div>
  );
};

export default InvoiceDate;
