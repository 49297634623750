import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const DeleteSupplierButton = ({ supplier }) => {
  const { takeAction, loading } = useApi();

  const history = useHistory();

  const deleteSupplier = () => {
    return deleteSwal(supplier.name)
      .then(() => takeAction("destroy", `suppliers/${supplier.uuid}`))
      .then(() => {
        toast.success(`${supplier.name} deleted`);
        history.push("/suppliers");
      })
      .catch(errorSwal);
  };

  if (supplier.form_filled_at) return null;

  return (
    <Button
      color="danger"
      outline
      block
      disabled={loading}
      onClick={deleteSupplier}
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </Button>
  );
};

export default DeleteSupplierButton;
