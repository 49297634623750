import { FiMoreVertical } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { ResourceGroup } from "../resourceGroups/resourceGroupTypes";
import errorSwal from "../utils/errorSwal";

interface DeleteJobFolderProps {
  uuid: string;
  data?: ResourceGroup;
  setData: Function;
}

const DeleteJobFolder = ({ uuid, data, setData }: DeleteJobFolderProps) => {
  const { takeAction }: IUseApi = useApi();

  const onDelete = () => {
    return takeAction("destroy", `resource-groups/${uuid}`)
      .then(() => {
        toast.success("Folder Deleted");

        const castedData = data as ResourceGroup;

        setData({
          ...castedData,
          members: castedData.members.filter((m) => m.member_uuid !== uuid),
        });
      })
      .catch(errorSwal);
  };

  return (
    <UncontrolledDropdown
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <DropdownToggle color="link" className="text-secondary p-0">
        <FiMoreVertical className="tx-18" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onDelete}>Delete</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default DeleteJobFolder;
