import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import CustomScaleLoader from "../utils/scaleLoader";
import {
  EmploymentHeroIntegrationPages,
  TEmploymentHeroIntegration,
} from "./employmentHeroTypes";
import ShowEmploymentHeroPage from "./ShowEmploymentHeroPage";

const EmploymentHeroIntegrationProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, setData, loading }: IUseApi<TEmploymentHeroIntegration> =
    useApi(`/integrations/${uuid}`, null, true);

  const employmentHero = data as TEmploymentHeroIntegration;

  return (
    <>
      <HeaderPageAdminFilter crumbs={[]} titlePage="Employment Hero" />
      {loading ? (
        <CustomScaleLoader>
          Fetching Integration Information...
        </CustomScaleLoader>
      ) : (
        <Profile<EmploymentHeroIntegrationPages>
          header={
            <DashboardHeader>
              <DashboardH3>Employment Hero</DashboardH3>
            </DashboardHeader>
          }
          content={
            <ShowEmploymentHeroPage
              employmentHero={employmentHero}
              setEmploymentHero={setData}
            />
          }
          tabs={[
            {
              label: "Details",
              link: `/integrations/employment-hero/${uuid}/details`,
              page: "details",
            },
            {
              label: "Sync Leave Reasons",
              link: `/integrations/employment-hero/${uuid}/sync-leave-reasons`,
              page: "sync-leave-reasons",
            },
            {
              label: "Sync Users",
              link: `/integrations/employment-hero/${uuid}/sync-users`,
              page: "sync-users",
            },
          ]}
        />
      )}
    </>
  );
};

export default EmploymentHeroIntegrationProfile;
