import React from "react";
import Tabs from "../tabs/Tabs";
import { Client } from "./clientTypes";
import EntityFeedback from "../entityFeedback/EntityFeedback";
import ClientFeedback from "./ClientFeedback";
import DocsModal from "../utils/DocsModal";

const ClientFeedbackCollection = ({ client }: { client: Client }) => {
  const tabs = [
    {
      title: `Feedback for ${client.name}`,
      component: (
        <>
          <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/clients/feedback/" />
          <EntityFeedback
            entity={{
              entity_type: "App\\Models\\OrganisationClient",
              entity_id: client.id,
            }}
          />
        </>
      ),
    },
    {
      title: `Feedback from ${client.name}`,
      component: <ClientFeedback uuid={client.uuid} />,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default ClientFeedbackCollection;
