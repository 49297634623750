import { Field } from "redux-form";
import FormModal from "../utils/FormModal";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SelectInput from "../form/SelectInput";
import { useAuth } from "../../context/auth-context";
import { IntegrationType } from "../integrations/integrationTypes";
import Tabs from "../tabs/Tabs";
import useApi from "../api/useApi";
import filterOption from "../../utils/filterOption";

const AddEditModal = (props) => {
  const { taxCodes, types, organisationGroupMembers } = props;

  const hasGroupMembers = organisationGroupMembers.length > 0;

  const { user } = useAuth();

  const toRender =
    user.active_organisation.integration_types.includes(
      IntegrationType.Accounting,
    ) && !props.initialValues?.uuid ? (
      <Tabs
        tabs={[
          {
            title: "Details",
            component: <Fields {...props} hasGroupMembers={hasGroupMembers} />,
          },
          {
            title: "Sync From Accounting",
            component: (
              <SyncFromAccounting chartOfAccounts={props.chartOfAccounts} />
            ),
          },
        ]}
      />
    ) : (
      <Fields {...props} hasGroupMembers={hasGroupMembers} />
    );

  return <FormModal {...props}>{toRender}</FormModal>;
};

const Fields = ({
  hasGroupMembers,
  taxCodes,
  types,
  organisationGroupMembers,
}) => {
  return (
    <>
      <div className="form-group col-lg-4">
        <Field
          required
          validate={required}
          component={renderField}
          name="name"
          label="Name"
        />
      </div>
      <div className="form-group col-lg-2">
        <Field
          required
          validate={required}
          component={renderField}
          name="code"
          label="Code"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          required
          validate={required}
          component={SelectInputAsync}
          options={types}
          name="type"
          label="Type"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          required
          validate={required}
          component={SelectInputAsync}
          name="tax_code"
          label="Tax Code"
          options={taxCodes}
        />
      </div>
      <div className="form-group col-lg-12">
        <Field
          required
          validate={required}
          component={renderField}
          textarea
          name="description"
          label="Description"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Payment"
          name="credit_card"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Purchase Order"
          name="purchase_order"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Expense Claim"
          name="expense_claim"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Sales Account"
          name="sales_account"
        />
      </div>

      <div className="form-group col-lg-12">
        <Field
          component={renderToggleInput}
          label="Budget Control"
          name="budget_control"
        />
        <small className="d-block">
          Check this if you want purchases attributed to this account be blocked
          if they are over budget.
        </small>
      </div>
      {hasGroupMembers ? (
        <>
          <div className="form-group col-lg-6">
            {/* These are the wrong way around, I know.*/}
            <Field
              component={SelectInput}
              options={organisationGroupMembers.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              name="loan_organisation_id"
              label="Inter Entity Loan From"
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              component={SelectInput}
              options={organisationGroupMembers.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              name="loan_from_organisation_id"
              label="Inter Entity Loan To"
            />
          </div>
          <div className="form-group col-lg-12">
            <Field
              component={renderToggleInput}
              name="is_external_credit_card_account"
              label="Is External Credit Card Account"
            />
          </div>
        </>
      ) : null}
    </>
  );
};

const SyncFromAccounting = ({ chartOfAccounts }) => {
  const { data: integrationAccounts } = useApi("accounting/accounts", []);

  const currentlyIntegratedAccounts = chartOfAccounts
    .filter((account) => account.accounting_integration_model)
    .map(
      (account) => account.accounting_integration_model.external_integration_id,
    );

  const options = integrationAccounts
    .filter(
      (account) => !currentlyIntegratedAccounts.includes(account.AccountID),
    )
    .map((account) => ({
      label: (
        <>
          <p className="text-dark mb-0">{account.Code}</p>
          <small className="mb-0">{account.Name}</small>
        </>
      ),
      value: account.AccountID,
      text: `${account.Name} ${account.Code}`,
    }));

  return (
    <div className="form-group col-lg-12">
      <Field
        component={SelectInput}
        name="account_id"
        validate={required}
        required
        label="Sync From Accounting"
        filterOption={filterOption}
        options={options}
      />
      <small className="d-block">
        Check this if you want to sync this account from your accounting
        software.
      </small>
    </div>
  );
};

export default AddEditModal;
