import { Link, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import PaginatedList from "../pagination/PaginatedList";
import useBranchProjects from "./hooks/useBranchProjects";
import { InvoiceStatus } from "../invoices/invoiceTypes";
import ReactTable from "../table/ReactTable";
import { money } from "../form/formatters";
import dayjs from "dayjs";

const BranchProjectsList = () => {
  const { uuid } = useParams();

  const filters = [
    {
      label: "Status",
      name: "projects.status",
      options: [
        {
          label: "Open",
          value: 0,
        },
        {
          label: "Closed",
          value: 1,
        },
      ],
    },
    {
      label: "Internal",
      name: "projects.internal",
      options: [
        {
          label: "Internal",
          value: 1,
        },
        {
          label: "External",
          value: 0,
        },
      ],
    },
    {
      label: "Invoice Status",
      name: "invoiceStatus",
      options: [
        {
          label: "Awaiting Invoice",
          value: InvoiceStatus.AwaitingInvoice,
        },
      ],
    },
  ];

  return (
    <PaginatedList
      listName="branchProjectsList"
      indexHook={useBranchProjects}
      indexHookArguments={[uuid]}
      originalFilters={filters}
      list={({ data }) => (
        <div className="col-12">
          {data && data.length > 0 && <BranchProjectTable data={data ?? []} />}
        </div>
      )}
    />
  );
};

export default BranchProjectsList;

const BranchProjectTable = ({ data }) => {
  const columns = [
    {
      id: "name",
      header: "Name",
      dataIndex: "name",
      cell: (info) => {
        const project = info.row.original;
        return (
          <div className="d-block">
            <Link
              className="text-dark mb-0"
              to={`/projects/${project.uuid}/details`}
            >
              {project.name}
            </Link>
            <small className="d-block">{project.number}</small>
          </div>
        );
      },
    },
    {
      id: "client",
      header: "Client",
      dataIndex: "client.name",
      cell: (info) => {
        const project = info.row.original;

        if (project.internal) {
          return "Internal Project";
        }

        return (
          <Link to={`/clients/${project.client.uuid}/details`}>
            {project.client.name}
          </Link>
        );
      },
    },
    {
      id: "location",
      header: "Location",
      dataIndex: "location",
      cell: (info) => {
        const project = info.row.original;
        return project.location;
      },
    },
    {
      id: "created_at",
      header: "Created",
      dataIndex: "created_at",
      cell: (info) => {
        const project = info.row.original;

        return dayjs(project.created_at).format("DD/MM/YYYY, hh:mm a");
      },
    },
    {
      id: "status",
      header: "Status",
      dataIndex: "status",
      cell: (info) => {
        const project = info.row.original;

        return (
          <Badge
            className="rounded-pill shadow"
            color={project.status ? "secondary" : "info"}
          >
            {project.status === 0 ? "Open" : "Closed"}
          </Badge>
        );
      },
    },
    {
      id: "estimated_value",
      header: "Estimated Value",
      dataIndex: "estimated_value",
      cell: (info) => {
        const project = info.row.original;
        return money.format(project.estimated_value);
      },
    },
  ];

  return <ReactTable disableSearch columns={columns} data={data} />;
};
