import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, reduxForm, submit } from "redux-form";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import required from "../utils/required";

const accreditationNumbers = [
  {
    label: "Protest Engineering (Gold Coast) - 19667",
    value: "Protest Engineering (Gold Coast) - 19667",
  },
  {
    label: "Protest Engineering (Sunshine Coast) Accreditation Number - 20499",
    value: "Protest Engineering (Sunshine Coast) Accreditation Number - 20499",
  },
  {
    label: "Protest Engineering (Emerald) Accreditation Number - 20501",
    value: "Protest Engineering (Emerald) Accreditation Number - 20501",
  },
  {
    label: "Protest Engineering (Melbourne) Accreditation Number - 20511",
    value: "Protest Engineering (Melbourne) Accreditation Number - 20511",
  },
];

const FormModal = (props) => {
  const { toggle, modal, initialValues, handleSubmit, dispatch } = props;
  return (
    <Modal className="md-wd-600" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {initialValues ? `Update ${initialValues.name}` : "Create Laboratory"}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-12">
              <Field
                component={SelectInputAsync}
                url="/organisationbranches?filter[division.name]=laboratory"
                name="branch_id"
                label="Branch"
                formatData={formatBranch}
                required
                validate={required}
              />
            </div>
            <div className="form-group col-12">
              <Field
                component={SelectInputAsync}
                options={accreditationNumbers}
                required
                validate={required}
                name="accreditation_number"
                label="Accreditation Number"
              />
            </div>
            <div className="form-group col-12">
              <Field
                component={renderField}
                name="site_number"
                label="Site Number"
                required
                validate={required}
              />
            </div>
            <div className="form-group col-12">
              <Field
                component={renderToggleInput}
                name="annex"
                label="Is Annex"
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          {...props}
          onClick={() => dispatch(submit("Laboratory"))}
        />
      </ModalFooter>
    </Modal>
  );
};

const formatBranch = (data) => {
  return data.data.map(({ branch }) => {
    return {
      label: branch.name,
      value: branch.id,
    };
  });
};

const form = reduxForm({ form: "Laboratory" });
export default form(FormModal);
