import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import { IDocument } from "../../documents/documentTypes";
import useModal from "../../hooks/useModal";
import errorSwal from "../../utils/errorSwal";
import isSubmitting from "../../utils/submitting";
import GenerateDeliverableModal from "../GenerateDeliverableModal";
import { CustomJobJob } from "./customJobTypes";

const GenerateDeliverable = ({
  documents,
  job,
}: {
  documents?: IDocument[];
  job: CustomJobJob;
}) => {
  const [selectedDocument, setSelectedDocument] = useState<IDocument>();
  const { toggle, modal } = useModal();
  const { takeAction, loading }: IUseApi<{}, { data: { uuid: string } }> =
    useApi();

  const history = useHistory();

  const generateDeliverable: FormSubmitHandler<IDocument> = (values) => {
    return takeAction(
      "store",
      `/custom-jobs/${job.uuid}/templates/${selectedDocument?.uuid}/generate-deliverable`,
      values,
    )
      .then(({ data }) => {
        toast.success("Deliverable Generated");
        history.push(`/deliverables/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  if (!job) {
    return null;
  }

  if (documents === undefined || documents.length === 0) {
    return null;
  }

  return (
    <li className="nav-item">
      <UncontrolledDropdown type="button">
        <DropdownToggle caret className="w-100 nav-link">
          {isSubmitting(loading, "Generate Deliverable", "Generating...")}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Choose Download</DropdownItem>
          {documents.map((document) => {
            return (
              <DropdownItem
                onClick={() => {
                  setSelectedDocument(document);
                  toggle();
                }}
              >
                {document.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
      <GenerateDeliverableModal
        modal={modal}
        toggle={toggle}
        onSubmit={generateDeliverable}
        job={job}
      />
    </li>
  );
};

export default GenerateDeliverable;
