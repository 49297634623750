import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import { EquipmentStatus } from "./equipmentTypes";

const ProfileTabs = ({ page, uuid, equipment }) => {
  const isHiring = [
    EquipmentStatus.Hiring,
    EquipmentStatus.OnHired,
    EquipmentStatus.OffHired,
  ].includes(equipment.withdrawn);

  return (
    <TabList>
      <Tab link={`/equipment/${uuid}/details`} active={page === "details"}>
        Details
      </Tab>
      <Tab link={`/equipment/${uuid}/documents`} active={page === "documents"}>
        Documents
      </Tab>
      <Tab
        link={`/equipment/${uuid}/calibrations`}
        active={page === "calibrations"}
      >
        Calibrations
      </Tab>
      <Tab
        link={`/equipment/${uuid}/project-history`}
        active={page === "project-history"}
      >
        Project History
      </Tab>
      {equipment.type.has_unit_frequency && (
        <Tab
          link={`/equipment/${uuid}/calibration-tracking`}
          active={page === "calibration-tracking"}
        >
          Calibration Tracking
        </Tab>
      )}
      {isHiring && (
        <Tab
          link={`/equipment/${uuid}/days-stood-down`}
          active={page === "days-stood-down"}
        >
          Days Stood Down
        </Tab>
      )}
      {equipment.recurring_payment_id && (
        <Tab
          link={`/equipment/${uuid}/contract-agreement`}
          active={page === "contract-agreement"}
        >
          Contract Agreement
        </Tab>
      )}
      {equipment.recurring_payment_id && (
        <Tab link={`/equipment/${uuid}/invoices`} active={page === "invoices"}>
          Invoices
        </Tab>
      )}
      {equipment.purchase_id && (
        <Tab link={`/equipment/${uuid}/purchase`} active={page === "purchase"}>
          Purchase
        </Tab>
      )}
    </TabList>
  );
};

export default ProfileTabs;
