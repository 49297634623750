import React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import CpirTypeForm, { CpirTypeFormData } from "./CpirTypeForm";
import { CPIRType } from "./cpirTypeTypes";

const AddCpirType = () => {
  const { takeAction }: IUseApi<any, { data: CPIRType }> = useApi();
  const history = useHistory();

  const onSubmit: FormSubmitHandler<CpirTypeFormData> = (values) => {
    return takeAction("store", "/cpir-types", {
      ...values,
      custom_fields: values.custom_fields.map((field: any, index: number) => ({
        ...field,
        order: index,
      })),
    })
      .then(({ data }) => {
        history.push(`/cpir-types/${data.data.uuid}/details`);
        toast.success("CPIR Type added successfully");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "CPIR Types",
            link: "/cpir-types",
          },
          {
            name: "Add CPIR Type",
            link: "/",
            active: true,
          },
        ]}
        titlePage="Add CPIR Type"
      />
      <CpirTypeForm
        onSubmit={onSubmit}
        showCustomFields
        initialValues={{ custom_fields: [{}] }}
      />
    </>
  );
};

export default AddCpirType;
