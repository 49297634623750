import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getEquipmentCalibrations = (
  { pageParam = 1 },
  equipmentUuid: string,
  search?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `equipment-equipment-calibrations/${equipmentUuid}?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useEquipmentCalibrations(
  search: string,
  equipmentUuid: string,
) {
  return useInfiniteQuery(
    ["equipment-calibrations", { search }],
    (pageParam) => getEquipmentCalibrations(pageParam, equipmentUuid, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
