import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import ReactTable from "../table/ReactTable";
import Modal from "./Modal";
import useLeaveReasonsApi from "./useLeaveReasonsApi";
import { User, OrganisationBranch } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const Index = () => {
  const {
    index,
    update,
    store,
    destroy,
    loading,
    reasons,
  } = useLeaveReasonsApi();

  const { uuid } = useParams();

  useEffect(() => {
    index();
  }, []);

  const selectedReason = reasons.find(reason => reason.uuid === uuid);

  const columns = [
    {
      accessorKey: "reason",
      header: "Reason",
      cell: info => (
        <Link to={`/leave-reasons/${info.row.original.uuid}`}>
          {info.getValue()}
        </Link>
      ),
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: info => {
        const reason = info.row.original;
        return (
          <button
            type="button"
            className="btn btn-outline-danger btn-sm"
            onClick={() => destroy(reason)}
          >
            Delete
          </button>
        );
      },
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/hr-staff-training/leave-reasons/" />
      <HeaderPage
        titlePage="Leave Reasons"
        pageDescription={
          <>
            The Leave Reasons feature categorizes different types of employee
            leaves, providing organizations with valuable insight into their
            leave requests.
          </>
        }
        relatedLinks={[
          {
            name: "Staff",
            link: "/users",
            model: User,
          },
          { name: "Branches", link: "/branches", model: OrganisationBranch },
        ]}
        crumbs={[
          {
            link: "/organisations/settings/links",
            name: "Organisation",
          },
          {
            link: "/",
            active: true,
            name: "Leave Reasons",
          },
        ]}
      />
      <div className="mb-3 d-flex">
        <Link
          className="btn btn-outline-primary ms-auto"
          to="/leave-reasons/add"
        >
          Add Reason
        </Link>
      </div>

      <ReactTable columns={columns} data={reasons} loading={loading} />

      <Modal modal={uuid === "add"} form="AddLeaveReason" onSubmit={store} />
      <Modal
        modal={uuid && uuid === selectedReason?.uuid}
        initialValues={selectedReason}
        form="EditLeaveReason"
        onSubmit={update}
      />
    </>
  );
};

export default Index;
