import React from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import AccountRequestFields from "./AccountRequestFields";
import { AccountRequest } from "./accountTypes";

interface CreateAccountRequestProps {
  toggle: Function;
  modal: boolean;
  requestable: {
    type: string;
    id: number;
  };
  onCreated: (accountRequest: AccountRequest) => void;
}

const CreateAccountRequest = (props: CreateAccountRequestProps) => {
  const { takeAction } = useApi();
  const { onCreated, toggle, requestable } = props;

  const onSubmit = (values: any) => {
    return takeAction("store", `account-requests`, {
      ...values,
      requestable_type: requestable.type,
      requestable_id: requestable.id,
    })
      .then(({ data }: { data: { data: AccountRequest } }) => {
        toast.success(`Account Request Updated`);
        onCreated(data.data);
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      title="Create Account Request"
      {...props}
      form="CreateAccountRequest"
      onSubmit={onSubmit}
    >
      <AccountRequestFields form="CreateAccountRequest" />
    </FormModal>
  );
};

export default CreateAccountRequest;
