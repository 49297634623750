import CopyToClipboard from "react-copy-to-clipboard";
import { AiFillCopy } from "react-icons/ai";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import { ApprovalAction } from "../approvals/approvalTypes";
import ApproveMultipleButton from "../approvals/ApproveAllButton";
import FormErrorAlert from "../form/FormErrorAlert";
import Tabs from "../tabs/Tabs";
import { Tab } from "../tabs/tabTypes";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";
import { UrlValues } from "./CustomFieldFilter";
import CustomFields from "./CustomFields";
import { CustomField } from "./customFieldTypes";
import CustomFormPreview from "./CustomFormPreview";
import { FilterType } from "./filters";

interface ProfileFormProps {
  modelType: string;
  modelId?: number;
  onSuccess: any;
  onApproval?: () => void;
  fieldTypes?: {
    label: string;
    value: number;
  }[];
  getExtraFilters?: (url: UrlValues) => Promise<FilterType[]>;
  customFields: CustomField[];
  canEditCustomJob?: boolean;
  canSendExternally?: boolean;
}

const ProfileForm = (
  props: InjectedFormProps<{ custom_fields: CustomField[] }, ProfileFormProps> &
    ProfileFormProps,
) => {
  const {
    handleSubmit,
    modelType,
    modelId,
    onSuccess,
    initialValues,
    onApproval,
    canEditCustomJob = true,
  } = props;

  const { takeAction } = useApi();

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      custom_fields: values.custom_fields.map((field: any, index: number) => {
        return {
          ...field,
          order: index,
        };
      }),
      model: {
        type: modelType,
        id: modelId,
      },
    };

    return takeAction("update", `custom-fields`, data)
      .then(({ data }: { data: { data: CustomField[] } }) => {
        onSuccess(data);
        toast.success("Fields Updated");
      })
      .catch(errorSwal);
  };

  const approvalActions = initialValues?.custom_fields
    ?.map((field) => field.approval_action)
    ?.filter((field): field is ApprovalAction => Boolean(field));

  const tabs: Tab[] = canEditCustomJob
    ? [
        {
          title: "Fields",
          component: (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormErrorAlert error={props.error} />
              <CustomFields {...props} />
              <div className="row">
                <div className="col-12">
                  <SubmitButton {...props} />
                </div>
              </div>
            </form>
          ),
        },
        {
          title: "Preview",
          component: <CustomFormPreview />,
        },
      ]
    : [
        {
          title: "Preview",
          component: <CustomFormPreview />,
        },
      ];

  return (
    <>
      <Tabs
        tabs={tabs}
        rightNav={() => (
          <div className="space-x-3">
            {approvalActions && approvalActions?.length > 0 ? (
              <ApproveMultipleButton
                approvalActions={approvalActions}
                onSuccess={onApproval}
              />
            ) : null}
            <CopyToClipboard
              text={JSON.stringify(props.customFields)}
              options={{ format: "text/plain" }}
              onCopy={() => toast.success(`fields copied.`)}
            >
              <span className="pointer-hover">
                Copy Fields <AiFillCopy />
              </span>
            </CopyToClipboard>
          </div>
        )}
      />
    </>
  );
};

const selector = formValueSelector("ProfileCustomFields");

const form = reduxForm<{ custom_fields: CustomField[] }, ProfileFormProps>({
  form: "ProfileCustomFields",
  enableReinitialize: true,
});

const mapStateToProps = (state: any) => {
  return {
    customFields: selector(state, "custom_fields"),
  };
};

export default connect(mapStateToProps)(form(ProfileForm));
