import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../api/api";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import DatabaseListView from "../listView/DatabaseListView";
import { SkillCategory } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const List = () => {
  const [loading, setLoading] = useState(true);
  const [qualifications, setQualifications] = useState({});

  useEffect(() => {
    axios
      .get(`/qualifications`)
      .then(({ data }) => setQualifications(data.data))
      .catch(errorSwal)
      .then(() => setLoading(false));
  }, []);

  if (loading) return <Spinner loading />;

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/hr-staff-training/employment-details/" />
      <HeaderPage
        titlePage="Licenses/Registrations/Qualifications"
        pageDescription={
          <>
            Manage staff credentials and generate reminders to ensure compliance
            with industry regulations.
          </>
        }
        relatedLinks={[
          {
            name: "Skill Categories",
            link: "/skill-categories",
            model: SkillCategory,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          {
            link: "/qualificationss",
            name: "Licenses/Registrations/Qualifications",
            active: true,
          },
        ]}
      />
      <DatabaseListView
        list={qualifications}
        name="qualifications"
        add={{
          link: "qualifications/add",
          name: "License/Registration/Qualification",
        }}
      />
    </>
  );
};

export default List;
