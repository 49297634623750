import { MouseEventHandler } from "react";
import isSubmitting from "./submitting";

const DeleteButton = ({
  deleteFunction,
  deleting = false,
}: {
  deleteFunction: MouseEventHandler;
  deleting?: boolean;
}) => {
  return (
    <button
      onClick={deleteFunction}
      disabled={deleting}
      type="button"
      className="btn btn-outline-danger py-0"
    >
      {isSubmitting(deleting, "Delete", "Deleting...")}
    </button>
  );
};

export default DeleteButton;
