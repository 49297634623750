import { DELETE_DELIVERABLE_PRICING } from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_DELIVERABLE_PRICING:
      return {
        ...state,
        items: [action.payload],
      };
    default:
      return state;
  }
}
