import React from "react";
import { Field } from "redux-form";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import PRICE_BANDS_STATUS from "../../data/price_bands_status";
import { IPriceBand } from "./IPriceBand";
import { money } from "../form/formatters";

interface IPriceBandsFormModalProps {
  toggle: Function;
  modal: boolean;
  title: string;
  form: string;
  initialValues?: IPriceBand | null;
  onSubmit: (values: IPriceBand) => void;
}

const PriceBandsFormModal: React.FC<IPriceBandsFormModalProps> = (props) => {
  return (
    <FormModal {...props}>
      <div className="row">
        <div className="col-6 form-group">
          <Field
            validate={required}
            required
            component={renderField}
            name="lower_limit"
            label="Lower Limit of Estimated Value"
            {...money}
          />
        </div>
        <div className="form-group col-6">
          <Field
            validate={required}
            required
            component={renderField}
            name="upper_limit"
            label="Upper Limit of Estimated Value"
            {...money}
          />
        </div>

        <div className="form-group col-6">
          <Field
            component={SelectInputAsync}
            label="Required"
            name="status"
            options={PRICE_BANDS_STATUS}
            validate={required}
            required
          />
        </div>
      </div>
    </FormModal>
  );
};

export default PriceBandsFormModal;