import { useEffect } from "react";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import Form from "./Form";
import GenerateReport from "./GenerateReport";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import SelectSupervisor from "./SelectSupervisor";

const NuclearDensometer = () => {
  const { test } = useParams();

  const { data, loading } = useApi(
    `/sample-nuclear-densometers/${test}`,
    null,
    true,
  );

  const { data: userCompetent, setUrl, refreshData } = useApi("");

  const { data: supervisors, setUrl: setSupervisorsUrl } = useApi("", []);

  useEffect(() => {
    if (data?.[0]) {
      setUrl(`test-supervisors?test_methods[]=${data[0].test_method_id}`);
    }
  }, [data]);

  useEffect(() => {
    if (
      userCompetent &&
      (userCompetent.competencies.length === 0 ||
        userCompetent.supervisors.length === 0)
    ) {
      setSupervisorsUrl(
        `staff-competencies/users-competent?filter[skill_type]=${encodeURIComponent(
          "App\\Models\\TestMethod",
        )}&filter[skill_ids][]=${data[0].test_method_id}`,
      );
    }
  }, [userCompetent]);

  if (loading) return <Spinner loading />;

  const firstNuc = data[0];

  const pageTitle = `Nuclear Densometers for ${firstNuc.test_request}`;

  return (
    <>
      <HeaderPage
        titlePage={pageTitle}
        crumbs={[
          {
            link: firstNuc.test_request_link,
            name: firstNuc.test_request,
          },
          { link: "", name: pageTitle, active: true },
        ]}
      />
      <Form
        initialValues={{
          nucs: data,
        }}
        userCompetent={userCompetent}
        supervisors={supervisors}
      />

      <GenerateReport />

      <SelectSupervisor
        userCompetent={userCompetent}
        supervisors={supervisors}
        form="SelectSupervisor"
        refreshData={refreshData}
        testMethodId={firstNuc.test_method_id}
      />
    </>
  );
};

export default NuclearDensometer;
