import { Fragment } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, WrappedFieldArrayProps } from "redux-form";
import buttonGroup from "../utils/buttonGroup";
import RenderField from "../utils/renderField";

const HazardReportAnswer = ({
  fields,
  answers,
}: WrappedFieldArrayProps & {
  answers: any[];
}) => {
  return (
    <>
      {fields.map((field, i) => {
        const value = fields.get(i);
        const answer = answers?.[i];

        return (
          <div className="col-12 row" key={i}>
            <div className="col-lg-2 form-group">
              <Field
                component={buttonGroup}
                name={`${field}.answer`}
                label={`${value.name} ${
                  value?.question_deleted ? "(Question Deleted)" : ""
                }`}
                options={[
                  {
                    label: "Yes",
                    value: true,
                  },
                  {
                    label: "No",
                    value: false,
                  },
                ]}
              />
            </div>
            {answer.answer === true ? (
              <div className="col-lg-2">
                <Field
                  component={buttonGroup}
                  options={[
                    {
                      label: "Yes",
                      value: true,
                    },
                    {
                      label: "No",
                      value: false,
                    },
                  ]}
                  name={`${field}.hazard_manageable`}
                  label="Hazard Manageable?"
                />
              </div>
            ) : null}
            {answer.hazard_manageable === false ? (
              <div className="col-lg-8 form-group">
                <Field
                  component={RenderField}
                  name={`${field}.controls_implemented`}
                  label="Controls Implemented"
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

const mapStateToProps = (state: any, props: { form: string }) => {
  const selector = formValueSelector(props.form);

  return {
    answers: selector(state, "answers"),
  };
};

export default connect(mapStateToProps, {})(HazardReportAnswer);
