import { EditorState } from "draft-js";
import React, { FunctionComponent, useState } from "react";
import { ResizableBox } from "react-resizable";
import { MediaProps } from "reactstrap";
import { useWindowEvent } from "../hooks/useWindowEvent";

const ResizableMedia: FunctionComponent<MediaProps> = (props) => {
  const { children, contentState, block, blockProps } = props;
  const entity = contentState.getEntity(block.getEntityAt(0));
  const { src, width, height } = entity.getData();
  const [lockAspectRatio, setLockAspectRatio] = useState(false);

  useWindowEvent("keydown", (e: KeyboardEvent) => {
    if (e.ctrlKey) {
      setLockAspectRatio(true);
    }
  });

  useWindowEvent("keyup", (e: KeyboardEvent) => {
    if (e.key === "Control") {
      setLockAspectRatio(false);
    }
  });

  return (
    <ResizableBox
      className="mw-100"
      onResizeStop={(e, data) => {
        const replacedEntity = contentState.replaceEntityData(
          block.getEntityAt(0),
          {
            src,
            ...data.size,
          },
        );

        blockProps.setEditorState(
          EditorState.set(blockProps.editorState, {
            currentContent: replacedEntity,
          }),
        );
      }}
      width={width}
      height={height}
      lockAspectRatio={lockAspectRatio}
    >
      {children}
    </ResizableBox>
  );
};

export default ResizableMedia;
