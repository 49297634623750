import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import { IFilter } from "../hooks/useFilter";

interface FilterDropdownProps<StringLiteral, ValueType> {
  name?: string;
  className?: string;
  filters: IFilter<StringLiteral, ValueType>[];
  toggleFilter: (filterName: StringLiteral, optionValue: ValueType) => void;
  filterCount: number;
}

const NamedFilterDropdownButton = <StringLiteral, ValueType>({
  name = "Filters",
  className = "",
  filters,
  toggleFilter,
  filterCount,
}: FilterDropdownProps<StringLiteral, ValueType>) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <ButtonDropdown
      isOpen={open}
      toggle={toggle}
      className={`w-100 ${className}`}
    >
      <DropdownToggle
        outline={true}
        color="primary"
        block
        caret
        className="mt-3"
        aria-label="Filters"
      >
        {name && `${name}`} <FaFilter className="tx-16" />
        <Badge color="" className="p-1 text-black">
          {filterCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu>
        {filters.map((filter) => {
          return (
            <React.Fragment key={`${filter.name}`}>
              <DropdownItem header>{filter.label}</DropdownItem>
              {filter.options?.map((option, index) => {
                return (
                  <DropdownItem
                    onClick={() => toggleFilter(filter.name, option.value)}
                    key={index}
                  >
                    {option.label}{" "}
                    {option.selected ? (
                      <AiOutlineCheck className="ms-1 tx-success" />
                    ) : null}
                  </DropdownItem>
                );
              })}
            </React.Fragment>
          );
        })}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default NamedFilterDropdownButton;
