import axios from "../api/api";
import { FETCH_PAY_RUN_TIMESHEETS } from "../actions/types";

export const fetchPayRunTimesheets =
  (id = "") =>
  (dispatch) => {
    return axios
      .get(`/pay-run-timesheets/${id}`)
      .then((response) => response.data.data)
      .then((payRunTimesheets) =>
        dispatch({
          type: FETCH_PAY_RUN_TIMESHEETS,
          payload: payRunTimesheets,
        }),
      );
  };
