import { BootstrapColour } from "../utils/utilTypes";

const riskDetails = (
  riskScore: number,
): {
  name: "Low" | "Medium" | "High" | "Extreme";
  colour: BootstrapColour;
} => {
  switch (true) {
    case riskScore < 4:
      return { name: "Low", colour: "success" };
    case riskScore < 7:
      return { name: "Medium", colour: "info" };
    case riskScore < 15:
      return { name: "High", colour: "warning" };
    default:
      return { name: "Extreme", colour: "danger" };
  }
};

export default riskDetails;
