import { Field, FormSubmitHandler } from "redux-form";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";
import { Test } from "./testRequestTypes";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { useEffect } from "react";
import SelectInput from "../form/SelectInput";

const SupervisorModal = ({
  modal,
  toggle,
  test,
  status,
  tests,
  setTests,
}: {
  modal: boolean;
  toggle: () => void;
  test?: Test;
  status?: string;
  tests: Test[];
  setTests: (tests: Test[]) => void;
}) => {
  const { takeAction }: IUseApi = useApi();

  const {
    data: supervisors,
    setUrl,
  }: IUseApiWithData<{ name: string; id: number }[]> = useApi("", []);

  useEffect(() => {
    if (test) {
      setUrl(
        `/supervisor-approvals?id=${test?.id}&type=${encodeURIComponent(
          "App\\Models\\Test",
        )}&category=Laboratory`,
      );
    }
  }, [test]);

  const onSubmit: FormSubmitHandler<{
    supervisor_id: number;
    password: string;
  }> = (values) => {
    if (!status) {
      return;
    }

    return takeAction("update", `/tests/${test?.uuid}`, {
      status,
      ...values,
    })
      .then(() => {
        toast.success(`Test status updated.`);
        toggle();
        setTests(
          tests.map((t) => {
            return t.id === test?.id ? { ...t, status } : t;
          }),
        );
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      onSubmit={onSubmit}
      form="SupervisorApproval"
      title="Need Supervisor Approval"
    >
      <div className="col-lg-12 form-group">
        <Field
          label="Supervisor"
          name="supervisor_id"
          component={SelectInput}
          options={supervisors.map((supervisor) => ({
            label: supervisor.name,
            value: supervisor.id,
          }))}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={RenderField}
          name="password"
          type="password"
          label="Password"
          autoComplete="new-password"
        />
      </div>
    </FormModal>
  );
};

export default SupervisorModal;
