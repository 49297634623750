import { Button } from "reactstrap";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { useAuth } from "../../context/auth-context";
import { GroupType } from "../user/userTypes";
import { IntegrationType } from "../integrations/integrationTypes";

const ForceRemoveFromSharepointButton = ({ project }: { project: any }) => {
  const { takeAction, loading } = useApi();

  const { user } = useAuth();

  const forceRemoveFromSharepoint = () => {
    return takeAction(
      "store",
      `projects/${project.uuid}/force-remove-from-sharepoint`,
    )
      .then(() => {
        toast.success(
          "Project Beginning Removal From Sharepoint, you will be emailed",
        );
      })
      .catch(errorSwal);
  };

  if (!user?.groups.includes(GroupType.IT) && !user?.is_admin) {
    return null;
  }

  if (
    !user.active_organisation.integration_types.includes(
      IntegrationType.OfficeSuite,
    )
  ) {
    return null;
  }

  if (project.status != 1) {
    return null;
  }

  return (
    <Button
      disabled={loading}
      block
      className="mt-3"
      outline
      color="info"
      onClick={forceRemoveFromSharepoint}
    >
      {isSubmitting(loading, "Force Remove From Sharepoint", "Removing...")}
    </Button>
  );
};

export default ForceRemoveFromSharepointButton;
