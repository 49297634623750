import dayjs from "dayjs";
import React from "react";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";

const ProfileHeader = ({ equipment }) => {
  return (
    <DashboardHeader>
      <DashboardH3>
        {equipment.name} - {equipment.type.type}
      </DashboardH3>
      <DashboardSubTitle>Branch: {equipment.branch.name}</DashboardSubTitle>
      {equipment.created_by && (
        <p className="mb-0 text-secondary">
          Created by{" "}
          <a
            className="text-dark fw-bolder"
            href={`mailto:${equipment.created_by.email}`}
          >
            {equipment.created_by.name}
          </a>{" "}
          on{" "}
          <span className="text-dark fw-bolder">
            {dayjs(equipment.created_at).format("DD/MM/YYYY")}
          </span>{" "}
          at{" "}
          <span className="text-dark fw-bolder">
            {dayjs(equipment.created_at).format("hh:mm a")}
          </span>
        </p>
      )}
    </DashboardHeader>
  );
};

export default ProfileHeader;
