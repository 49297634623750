import React from "react";

const PositionsWidget = ({ positions }) => (
  <div>
    {positions && positions.length > 0 && (
      <div className="manager-left w-100 d-lg-block card card mg-t-20">
        <div className="card-body pd-25">
          <div className="slim-card-title">Your Positions</div>
          {positions.map((position) => (
            <div
              key={position.uuid}
              className="d-flex align-items-center mg-y-25"
            >
              <div className="media-body">
                <h6 className="tx-14 tx-inverse mg-b-2">
                  {position.position.name}
                </h6>
                <p className="mg-b-0">{position.employment_basis}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
);

export default PositionsWidget;
