import React from "react";
import { Field } from "redux-form";
import TimeUnitType, { timeUnitTypes } from "../../enums/TimeUnitType";
import { date } from "../form/formatters";
import SelectInput from "../form/SelectInput";
import dateField from "../utils/dateTime";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";

const FrequencyFields = () => {
  return (
    <>
      <FormHeader>Frequency</FormHeader>
      <div className="col-lg-4 form-group">
        <Field
          component={dateField}
          {...date}
          name="start_date"
          label="Start Date"
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field component={RenderField} name="frequency" label="Frequency" />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          component={SelectInput}
          name="frequency_units"
          options={timeUnitTypes.filter(
            (type) => type.value !== TimeUnitType.Initial,
          )}
          label="Units"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={dateField}
          {...date}
          name="end_date"
          label="End Date"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="number_of_occurrences"
          label="Number of Occurrences"
        />
      </div>
    </>
  );
};

export default FrequencyFields;
