import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { AccountsPayableAccount } from "./microsoftTypes";
import useApi from "../api/useApi";
import { IUseApiWithData } from "../api/apiTypes";
import { MailFolder } from "@microsoft/microsoft-graph-types";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInput from "../form/SelectInput";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";

const AccountsPayableSetupForm = (
  props: InjectedFormProps<AccountsPayableAccount>,
) => {
  const { handleSubmit, initialValues } = props;
  //

  const {
    data: folders,
    setUrl,
    loading,
  }: IUseApiWithData<MailFolder[]> = useApi(
    initialValues?.email
      ? `microsoft/${props.initialValues.email}/folders`
      : "",
    [],
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="email"
            label="Accounts Payable Email"
            validate={required}
            required
            onBlur={(e: any, value: string) => {
              if (!value) return;

              setUrl(`microsoft/${value}/folders`);
            }}
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={SelectInput}
            name="folder_id"
            label="Folder"
            validate={required}
            required
            options={folders.map((folder) => ({
              label: folder.displayName,
              value: folder.id,
            }))}
            loading={loading}
          />
          <small>
            Choose the folder you'd like to 'listen' too. It's generally
            recommended to have 'Inbox' selected.
          </small>
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<AccountsPayableAccount>({});

export default form(AccountsPayableSetupForm);
