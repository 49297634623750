import General from "./general";
import PreperationInspected from "./preperationInspected";
import Reinforcement from "./reinforcement";
import Submit from "./submit";
import WeatherConditions from "./weatherConditions";

const ShowResidentialSiteInspection = ({
  pageNumber,
  nextPage,
  previousPage,
  siteInspection,
  submit,
  setSiteInspection,
}) => {
  switch (pageNumber) {
    case 1:
      return (
        <General
          onSubmit={nextPage}
          setSiteInspection={setSiteInspection}
          initialValues={siteInspection}
          showEditModal={true}
        />
      );
    case 2:
      return (
        <WeatherConditions
          previousPage={previousPage}
          onSubmit={nextPage}
          initialValues={siteInspection}
        />
      );
    case 3:
      return (
        <PreperationInspected
          previousPage={previousPage}
          onSubmit={nextPage}
          initialValues={siteInspection}
        />
      );
    case 4:
      return (
        <Reinforcement
          onSubmit={nextPage}
          previousPage={previousPage}
          initialValues={siteInspection}
        />
      );
    case 5:
      return (
        <Submit
          onSubmit={submit}
          previousPage={previousPage}
          initialValues={siteInspection}
        />
      );
    default:
      return null;
  }
};

export default ShowResidentialSiteInspection;
