import { useParams } from "react-router-dom";
import { fieldTypes } from "../../enums/FieldType";
import NotFound from "../404";
import useApi from "../api/useApi";
import { UrlValues } from "../customFields/CustomFieldFilter";
import { CustomField } from "../customFields/customFieldTypes";
import { FilterType } from "../customFields/filters";
import ProfileForm from "../customFields/ProfileForm";
import CustomJobAggregates from "./CustomJobAggregates";
import CustomJobTemplates from "./CustomJobTemplates";
import { CustomJob, CustomJobState } from "./customJobTypes";
import Edit from "./Edit";
import getRegisterFields from "./getRegisterFields";
import { CustomJobPages } from "./Profile";

const getExtraFilters = (url?: UrlValues): Promise<FilterType[]> => {
  if (url === "/custom-fields/equipment") {
    return Promise.resolve([
      {
        label: "From Job Tracking Site",
        name: "branch.tracking_site_id",
      },
      {
        label: "From Job Branch",
        name: "branch_id",
      },
    ]);
  }

  if (url?.startsWith("custom-fields/registers")) {
    return getRegisterFields(url);
  }

  return Promise.resolve([]);
};

const ShowPage = (props: CustomJobState) => {
  const { page, uuid } = useParams<{ page: CustomJobPages; uuid: string }>();
  const { takeAction } = useApi();

  const { customJob, setCustomJob, canEditCustomJob } = props;

  switch (page) {
    case "details":
      return (
        <Edit
          initialValues={customJob}
          {...props}
          canEditCustomJob={canEditCustomJob}
        />
      );
    case "fields":
      return (
        <ProfileForm
          initialValues={customJob}
          {...props}
          modelId={customJob.id}
          modelType="App\Models\CustomJob"
          canSendExternally={true}
          onSuccess={(data: { data: CustomField[] }) =>
            setCustomJob({
              ...customJob,
              custom_fields: data.data,
            })
          }
          onApproval={() => {
            takeAction("show", `custom-jobs/${uuid}`).then(
              ({ data }: { data: { data: CustomJob } }) => {
                setCustomJob(data.data);
              },
            );
          }}
          canEditCustomJob={canEditCustomJob}
          getExtraFilters={getExtraFilters}
          fieldTypes={[
            ...fieldTypes,
            {
              label: "Prompt",
              value: 16,
            },
          ]}
        />
      );
    case "templates":
      return canEditCustomJob ? (
        <CustomJobTemplates {...props} />
      ) : (
        <NotFound />
      );
    case "aggregates":
      return <CustomJobAggregates />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
