import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import SelectInput from "../form/SelectInput";
import { Button } from "reactstrap";
import RenderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import isSubmitting from "../utils/submitting";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { TodoTask } from "./todoTypes";
import { Link, useParams } from "react-router-dom";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import FormErrorAlert from "../form/FormErrorAlert";
import AsyncSelectInput from "../form/AsyncSelectInput";
import api from "../../api/api";
import { ConvertToWorkFormProps } from "./ConvertToJobModal";
import { useEffect, useState } from "react";

const ConvertToJobForm = (
  props: InjectedFormProps<any, ConvertToWorkFormProps> &
    ConvertToWorkFormProps,
) => {
  const { toggle, setData } = props;

  const { taskUuid } = useParams<{ taskUuid: string }>();

  const [projectClosed, setProjectClosed] = useState(false);

  useEffect(() => {
    if (!projectClosed) {
      props.change("project_scheduled_finish_date", null);
    }
  }, [projectClosed]);

  const { takeAction }: IUseApi<{}, { data: TodoTask }> = useApi();

  const onSubmit: FormSubmitHandler<any, ConvertToWorkFormProps> = (values) => {
    return takeAction("store", `to-do-tasks/${taskUuid}/convert/job`, values)
      .then(({ data }) => {
        setData(data.data);
        toggle();
        toast.success(
          <div className="d-flex">
            <p className="mb-0">Todo Converted</p>
            <Link to={data.data.work?.link ?? ""}>View Job</Link>
          </div>,
        );
      })
      .catch(formError);
  };

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-12 form-group">
          <Field
            name="name"
            label="Name"
            component={RenderField}
            validate={required}
            required
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            label="Project"
            name="project_id"
            component={AsyncSelectInput}
            changeValue={(value: any) => {
              setProjectClosed(value.status === 1);
            }}
            asyncFunction={(inputValue: string) =>
              api
                .get(`/schedule-projects?filter[search]=${inputValue}`)
                .then(({ data }) => data.data)
            }
            validate={required}
            required
          />
          <small className="text-muter">Type to see options.</small>
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInputAsync}
            url="jobtypes/types?filter[]=JobTypes&filter[]=CustomJobs"
            name="type"
            validate={required}
            required
            onChangeValue={(value: {
              id: number;
              label: string;
              link: string;
              type: string;
              value: string;
            }) => {
              props.change("job_type_id", value.id);
              props.change("job_type_type", value.type);
            }}
            label="Job Type"
          />
        </div>
        {projectClosed && (
          <div className="col-12 form-group">
            <Field
              component={RenderField}
              name="project_scheduled_finish_date"
              type="date"
              label="Project Scheduled Finish Date"
              required
              validate={required}
            />
            <small>
              The project you've selected is closed. Creating a job for it will
              open the project again. As such, you need to select a new
              scheduled finish date for the project.
            </small>
          </div>
        )}
        <div className="col-lg-6 form-group">
          <Field
            name="branch_id"
            url="/custom-fields/branches"
            formatData={(data: any[]) =>
              data.map((d) => ({
                label: d.name,
                value: d.id,
              }))
            }
            component={SelectInput}
            label="Branch"
            validate={required}
            required
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            validate={required}
            required
            component={SelectInputAsync}
            url="/projectjobs/jobmanager"
            label="Job Manager"
            name="project_manager"
          />
        </div>
        <div className="col-lg-12 form-group">
          <Field
            name="description"
            label="Description"
            component={RenderField}
            textarea
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="scheduled_start_time"
            label="Scheduled Start Time"
            type="time"
            component={RenderField}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="scheduled_finish_time"
            label="Scheduled Finish Time"
            type="time"
            component={RenderField}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="scheduled_start_date"
            label="Scheduled Start Date"
            type="date"
            component={RenderField}
            validate={required}
            required
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="scheduled_finish_date"
            label="Scheduled Finish Date"
            type="date"
            component={RenderField}
            validate={required}
            required
          />
        </div>
        <div className="co-12 mt-3">
          <Button
            style={{
              backgroundColor: "#0c78c4",
            }}
            className="border-0"
            block
            type="submit"
            disabled={props.submitting}
          >
            {isSubmitting(props.submitting, "Save", "Saving...")}
          </Button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<any, ConvertToWorkFormProps>({
  form: "CONVERT_TO_JOB",
  enableReinitialize: true,
});

export default form(ConvertToJobForm);
