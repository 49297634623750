import React, { useState } from "react";
import Toggle from "react-toggle";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import ClipLoader from "react-spinners/ClipLoader";
import { primary } from "../utils/Colours";
import axios from "../api/api";

const NotificationSetting = ({
  notification,
  userNotifications,
  setUserNotifications,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const onToggle = (e) => {
    const checked = e.target.checked;
    setSubmitting(true);
    return updateOrDelete()
      .then(({ data }) => {
        toast.success(
          `${notification.label} notification successfully turned ${
            checked ? "on" : "off"
          }.`,
        );
        updateUserNotifications(checked, data.data);
      })
      .catch((err) => {
        return errorSwal(err);
      })
      .then(() => setSubmitting(false));
  };

  const updateUserNotifications = (checked, data) => {
    const newNotifications = userNotifications.map((currentNotification) => {
      if (currentNotification.name !== notification.name) {
        return currentNotification;
      }
      return {
        ...currentNotification,
        is_unsubscribed: checked ? undefined : data,
      };
    });
    return setUserNotifications(newNotifications);
  };

  const updateOrDelete = () => {
    const url = `/unsubscribed-notifications`;
    const { is_unsubscribed } = notification;

    return is_unsubscribed
      ? axios.delete(`${url}/${is_unsubscribed.uuid}`)
      : axios.post(url, { notification: notification.name });
  };

  return (
    <>
      <div className="col-10 tx-inverse">
        {submitting ? (
          <>
            <ClipLoader color={primary} size={15} /> Submitting
          </>
        ) : (
          <>
            <p className="tx-bold mb-0">{notification.label}</p>
            <p className="mb-1 d-none d-sm-block">{notification.description}</p>
          </>
        )}
      </div>
      <div className="d-flex justify-content-end align-items-center col-2">
        <Toggle
          onChange={onToggle}
          defaultChecked={!notification.is_unsubscribed}
        />
      </div>
      <div className="col-12">
        <hr />
      </div>
    </>
  );
};

export default NotificationSetting;
