import dayjs from 'dayjs';

const getDaysAgoTimestamps = (numberOfDays: number) => {
  const currentDate = new Date();
  const numberOfDaysAgo = new Date();
  numberOfDaysAgo.setDate(currentDate.getDate() - numberOfDays);

  const startTimestamp = Math.floor(numberOfDaysAgo.getTime() / 1000);
  const endTimestamp = Math.floor(currentDate.getTime() / 1000) - 1;

  return {
    displayValue: `${dayjs(numberOfDaysAgo).format('DD/MM/YYYY')} TO ${dayjs(currentDate).format('DD/MM/YYYY')}`,
    value: `${startTimestamp} TO ${endTimestamp}`,
  };
};

const formatDateRangeForFilter = (startDate : string, endDate : string) => {
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);

  const startTimestamp = Math.floor(startDateTime.getTime() / 1000);
  const endTimestamp = Math.floor(endDateTime.getTime() / 1000) - 1;

  return {
    displayValue: `${dayjs(startDateTime).format('DD/MM/YYYY')} TO ${dayjs(endDateTime).format('DD/MM/YYYY')}`,
    value: `${startTimestamp} TO ${endTimestamp}`,
  };
};

const timestampToDate = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000);
  const formattedDate = dayjs(date).format('DD/MM/YYYY hh:mm A');
  return formattedDate;
};

const dateToTimestamp = (input: string) => {
  if (typeof input === 'number') {
    return input;
  } 
    
  const date = new Date(input);
  const timestamp = Math.floor(date.getTime() / 1000);

  return timestamp;
};

export {
  getDaysAgoTimestamps,
  formatDateRangeForFilter,
  timestampToDate,
  dateToTimestamp,
};