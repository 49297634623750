import dayjs from "dayjs";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import PaginatedCard, {
  PaginatedInformation,
} from "../../pagination/PaginatedCard";
import Avatar from "../../utils/Avatar";
import { SampleIndex } from "./sampleTypes";

const SampleItem = ({ sample }: { sample: SampleIndex }) => {
  return (
    <PaginatedCard info={info(sample)} header={<Header sample={sample} />} />
  );
};

const Header = ({ sample }: { sample: SampleIndex }) => {
  const tooltipId = useMemo(() => `test_request_${sample.uuid}`, [sample.uuid]);
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar
            name={sample.test_request.name}
            colour="info"
            rounded="-circle"
          />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={sample.test_request.link}>{sample.number}</Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {sample.test_request.name}
          </UncontrolledTooltip>
          <p className={`mb-0 tx-12`}>{sample.test_request.name}</p>
        </div>
      </div>
    </div>
  );
};

const info = (sample: SampleIndex): PaginatedInformation[] => {
  return [
    {
      name: "Project",
      link: sample.project.link,
      value: sample.project.name,
    },
    {
      name: "Client",
      link: sample.client.link,
      value: sample.client.name,
    },
    {
      name: "Sampled On",
      value: dayjs.unix(sample.sampled_at_date).format("DD/MM/YYYY"),
    },
    {
      name: "Report",
      value: !sample.reports ? (
        "-"
      ) : (
        <>
          {sample.reports.length === 1 ? (
            <a href={sample.reports[0].link}>{sample.reports[0].name}</a>
          ) : (
            <>
              <a id={`report-popover-${sample.uuid}`}>
                {sample.reports.map((report) => report.name).join(", ")}
              </a>
              <UncontrolledPopover
                trigger="hover"
                placement="left"
                target={`report-popover-${sample.uuid}`}
              >
                <PopoverBody className="white-space">
                  {sample.reports.map((report, index) => (
                    <p className="mb-0" key={index}>
                      <a href={report.link}>{report.name}</a>
                    </p>
                  ))}
                </PopoverBody>
              </UncontrolledPopover>
            </>
          )}
        </>
      ),
    },
  ];
};

export default SampleItem;
