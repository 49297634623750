import _ from "lodash";
import { Button } from "reactstrap";
import { AggregateOutputType } from "../customJobs/customJobTypes";

interface CalculateProjectAggregateProps {
  values: any[];
  code?: string;
  outputType?: AggregateOutputType;
}

const CalculateProjectAggregate = (props: CalculateProjectAggregateProps) => {
  const groupedValues = _.groupBy(props.values, "field_id");

  const formattedValues = Object.values(groupedValues).map((values) => {
    const label = values[0].label;

    const variable = `$`.concat(
      `{${label
        ?.trim()
        .replace(/{|}/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()}}`,
    );

    return {
      key: variable,
      value: JSON.stringify(values),
    };
  });

  let toEval: string = props.code ?? "";

  formattedValues.forEach((field) => {
    toEval = toEval.replaceAll(field.key, field?.value ?? "-");
  });

  const emptyVariables = toEval.match(/\${[^}]*}/g);

  emptyVariables?.forEach((field) => {
    toEval = toEval.replaceAll(field, "null");
  });

  let evaluated: string | number = 0;

  try {
    evaluated = new Function(toEval)();
  } catch (err) {
    console.log(err);
    evaluated = "Unable To Evaluate";
  }

  if (!evaluated) {
    return null;
  }

  return (
    <>
      <div
        id="aggregate"
        className="bg-white p-3 border rounded-lg mt-3 white-space"
      >
        {evaluated}
      </div>
      {props.outputType !== null && (
        <Button
          onClick={() => {
            var encodedUri = encodeURI(
              `data:text/csv;charset=utf-8,${evaluated}`,
            );
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "aggregate.csv");
            document.body.appendChild(link); // Required for FF

            link.click();
          }}
          className="mt-3"
        >
          Download
        </Button>
      )}
    </>
  );
};

export default CalculateProjectAggregate;
