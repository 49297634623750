import { AtomicBlockUtils, EditorState, Modifier } from "draft-js";
import { useState } from "react";
import { BsCodeSlash } from "react-icons/bs";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { EditorStateProps } from "./SubjectItemEditor";

const EmbedCode = (props: EditorStateProps) => {
  const { editorState, setEditorState } = props;
  const [code, setCode] = useState("");

  const addCode = () => {
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
      "CODE",
      "MUTABLE",
      {
        code,
      },
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithImage = Modifier.applyEntity(
      contentStateWithEntity,
      editorState.getSelection(),
      entityKey,
    );

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      EditorState.set(editorState, {
        currentContent: contentStateWithImage,
      }),
      entityKey,
      " ",
    );

    setEditorState(newEditorState);
    setCode("");
  };

  return (
    <>
      <UncontrolledDropdown color="link" className="px-0 ms-2">
        <DropdownToggle color="link" className="px-0 me-2">
          <BsCodeSlash className="tx-18" />
        </DropdownToggle>
        <DropdownMenu className="p-0">
          <div className="d-flex">
            <input
              className="flex-grow-1 no-focus py-2 border-0 ps-1"
              placeholder="Paste embed here..."
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Button onClick={addCode} color="link" size="sm">
              Add
            </Button>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default EmbedCode;
