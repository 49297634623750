import React, { useState } from "react";
import { Field } from "redux-form";
import renderField from "../utils/renderField";
import CustomScaleLoader from "../utils/scaleLoader";
import errorSwal from "../utils/errorSwal";
import axios from "../api/api";

const MoistureFields = ({
  index,
  point,
  handleSubmit,
  balance_id,
  oven_id,
}) => {
  const [updating, setUpdating] = useState(false);

  const submitMoisture = (index) => (values) => {
    const data = values.points[index].moisture;

    setUpdating(true);

    return axios
      .put(`/moistures/${data.uuid}`, { ...data, balance_id, oven_id })
      .catch(errorSwal)
      .then(() => setUpdating(false));
  };

  return (
    <>
      <div className="col-12">
        <h6 className="tx-inverse text-center mt-3 mb-0">Moisture</h6>
        <hr />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          label="Container"
          name={`${point}.moisture.container_id`}
          onBlur={handleSubmit(submitMoisture(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          type="number"
          extraProps={{ step: "0.01" }}
          label="Tin Weight (g)"
          name={`${point}.moisture.mass_of_tin`}
          onBlur={handleSubmit(submitMoisture(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          type="number"
          extraProps={{ step: "0.01" }}
          label="Wet Mass (g)"
          name={`${point}.moisture.mass_of_tin_and_wet_soil`}
          onBlur={handleSubmit(submitMoisture(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          type="number"
          extraProps={{ step: "0.01" }}
          label="Dry Mass (g)"
          name={`${point}.moisture.dry_mass`}
          onBlur={handleSubmit(submitMoisture(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          label="Moisture Content (%)"
          name={`${point}.moisture.moisture_content`}
          readOnly
        />
      </div>
      {updating && (
        <div className="col-12">
          <CustomScaleLoader>
            Updating Moisture {index + 1}...
          </CustomScaleLoader>
        </div>
      )}
    </>
  );
};

export default MoistureFields;
