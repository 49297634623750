import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormCalibrationTracking from "./CalibrationTrackingForm";

const FORM_NAME = "CalibrationTracking";

const AddCalibrationTracking = (props) => {
  const {
    selectItem,
    refreshEquipment,
    initialValues,
    setCalibrationTracking,
    calibrationTracking,
  } = props;

  const { uuid } = useParams();

  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    return createOrUpdate(values)
      .then(refreshEquipment)
      .then(() => {
        dispatch(reset(FORM_NAME));
        toast.success(`added successfully`);
        selectItem("");
      })
      .catch(errorSwal);
  };

  const createOrUpdate = (values) => {
    if (initialValues?.uuid) {
      return takeAction(
        "update",
        `calibration-tracking/${initialValues.uuid}`,
        values,
      ).then(({ data }) =>
        setCalibrationTracking(
          calibrationTracking.map((item) =>
            item.uuid === data.data.uuid ? data.data : item,
          ),
        ),
      );
    }

    return takeAction(
      "store",
      `equipment/${uuid}/calibration-tracking`,
      values,
    ).then(({ data }) =>
      setCalibrationTracking([...calibrationTracking, data.data]),
    );
  };

  return (
    <FormCalibrationTracking onSubmit={onSubmit} form={FORM_NAME} {...props} />
  );
};

export default AddCalibrationTracking;
