import _ from "lodash";
import dayjs from "dayjs";
import React from "react";
import { Spinner } from "reactstrap";

const EquipmentChanges = ({
  changes,
  loading,
}: {
  changes: any[];
  loading: boolean;
}) => {
  if (changes.length === 0) {
    return null;
  }

  return (
    <div className="my-3 bg-white p-3 shadow-sm rounded-lg border position-relative">
      {loading && (
        <div className="z-index-10 t-0 l-0 h-100 w-100 d-flex align-items-center justify-content-center position-absolute bg-white-4">
          <Spinner color="primary" />
        </div>
      )}
      <p className="slim-card-title">Edits</p>
      <div className="space-y-5">
        {_.orderBy(changes, (d) => dayjs(d.created_at), ["desc"]).map(
          (change) => {
            return (
              <div>
                <p className="mb-1">
                  <span className="tx-inverse">{change.field}</span>, edited by{" "}
                  <a
                    href={`mailto:${change.user.email}`}
                    className="tx-inverse"
                  >
                    {change.user.name}
                  </a>{" "}
                </p>
                <p className="mb-1">
                  Edited at{" "}
                  <span className="tx-inverse">
                    {dayjs(change.created_at).format("hh:mm")}
                  </span>{" "}
                  on the{" "}
                  <span className="tx-inverse">
                    {dayjs(change.created_at).format("DD/MM/YYYY")}
                  </span>
                </p>
                <p>
                  Changed from{" "}
                  <span className="tx-inverse">{change.edit.old}</span> to{" "}
                  <span className="tx-inverse">{change.edit.new}</span> for the
                  following reason:
                </p>
                <p className="mb-0 tx-inverse">{change.reason}</p>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

export default EquipmentChanges;
