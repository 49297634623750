import ReactTable from "../table/ReactTable";
import { IPriceBand, PriceBandStatus, getTextByValue } from "./IPriceBand";
import DeleteButton from "../utils/DeleteButton";
import { Button } from "reactstrap";

interface PriceBandTableProps {
  priceBands: IPriceBand[];
  handleRowClick: (row: IPriceBand) => void;
  handleDeleteRow: (row: IPriceBand) => void;
}

const PriceBandTable = (props: PriceBandTableProps) => {
  const { handleRowClick, priceBands, handleDeleteRow } = props;

  const columns = [
    {
      accessorKey: "lower_limit",
      header: "Lower Limit of Estimated Value",
      cell: ({ row, getValue } : { row: any, getValue: any}) => {
        const priceBand = row.original;

        return (
          <>
            <Button
              color="link"
              className="p-0"
              onClick={() => handleRowClick(priceBand)} // Use a callback function
            >
              {getValue()}
            </Button>{" "}
          </>
        );
      },
    },
    {
      accessorKey: "upper_limit",
      header: "Upper Limit of Estimated Value",
      cell: ({ row, getValue } : { row: any, getValue: any}) => {
        const priceBand = row.original;

        return (
          <>
            <Button
              color="link"
              className="p-0"
              onClick={() => handleRowClick(priceBand)} // Use a callback function
            >
              {getValue()}
            </Button>{" "}
          </>
        );
      },
    },
    {
      accessorKey: "status",
      header: "required",
      cell: ({ row, getValue } : { row: any, getValue: any}) => {
        const priceBand = row.original;

        return (
          <>
            <Button
              color="link"
              className="p-0"
              onClick={() => handleRowClick(priceBand)} // Use a callback function
            >
              {getTextByValue(PriceBandStatus, getValue())}
            </Button>{" "}
          </>
        );
      },
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: ({ row }: { row: any }) => (
        <DeleteButton deleteFunction={() => handleDeleteRow(row.original)} />
      ),
    }
  ];

  return (
    <ReactTable
      disableSearch
      data={priceBands}
      columns={columns}
    />
  );
};

export default PriceBandTable;
