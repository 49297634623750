import React from "react";
import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import EmploymentAwardForm from "./EmploymentAwardForm";
import { ShowEmploymentAwardPageProps } from "./ShowEmploymentAwardPage";
import { EmploymentAward } from "./types";

const EditEmploymentAward = ({
  employmentAward,
  setEmploymentAward,
}: ShowEmploymentAwardPageProps) => {
  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi<EmploymentAward, { data: EmploymentAward }> =
    useApi();

  const onSubmit: FormSubmitHandler<EmploymentAward> = (values) => {
    return takeAction("update", `/employment-awards/${uuid}`, values).then(
      ({ data }) => {
        setEmploymentAward(data.data);
      },
    );
  };

  return (
    <EmploymentAwardForm
      form="EditEmploymentDetails"
      initialValues={employmentAward}
      onSubmit={onSubmit}
    />
  );
};

export default EditEmploymentAward;
