import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";

import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import isSubmitting from "../utils/submitting";

const BillableItemUnitForm = (props) => {
  const history = useHistory();
  const { initialValues } = props;

  const { uuid } = useParams();

  const { takeAction, loading: deleting } = useApi();

  const queryClient = useQueryClient();

  const deleteUnit = () => {
    return deleteSwal()
      .then(() => takeAction("destroy", `billable-item-units/${uuid}`))
      .then(() => {
        queryClient.invalidateQueries("billable-item-units");
        toast.success(`${initialValues.name} Deleted Successfully`);
        history.push(`/billable-item-units`);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-lg-12 form-group align-items-center">
          <Field
            name="name"
            label="Name"
            type="text"
            validate={required}
            required
            component={renderField}
          />
        </div>
        <div className="form-group col-lg-12 d-flex">
          {uuid && (
            <Button
              color="danger"
              className="ms-auto"
              outline
              onClick={deleteUnit}
              disabled={deleting}
            >
              {isSubmitting(deleting, "Delete", "Deleting...")}
            </Button>
          )}
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({});

export default form(BillableItemUnitForm);
