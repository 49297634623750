import WIPTable from "./WIPTable";
import InvoicedTable from "./InvoicedTable";
import MadePurchasesTable from "./MadePurchasesTable";
import WagesTable from "./WagesTable";
import BilledPurchasesTable from "./BilledPurchasesTable";
import EstimatedAmountBilled from "./EstimatedAmountBilledTable";
import ExpenseClaimsTable from "./ExpenseClaimsTable";
import CostsTable from "./CostsTable";
import useProjectEffort from "../projects/hooks/useProjectEffort";
import useProjectExpenses from "../projects/hooks/useProjectExpenses";

const DashboardViews = (props) => {
  return (
    <div className="row">
      <div className="col-12 mt-3">
        <Router {...props} />
      </div>
    </div>
  );
};

const Router = (props) => {
  const { view, ...dashboard } = props;

  switch (view) {
    case "wip":
      return <WIPTable {...dashboard} />;
    case "invoiced":
      return <InvoicedTable {...dashboard} />;
    case "purchases_made":
      return <MadePurchasesTable {...dashboard} />;
    case "wages":
      return <WagesTable {...dashboard} />;
    case "purchases_billed":
      return <BilledPurchasesTable {...dashboard} />;
    case "estimated_amount_billed":
      return <EstimatedAmountBilled {...dashboard} />;
    case "expense_claims":
      return <ExpenseClaimsTable {...dashboard} />;
    case "effort":
      return (
        <CostsTable hook={useProjectEffort} listName="effort" {...dashboard} />
      );
    case "expense_cost":
      return (
        <CostsTable
          hook={useProjectExpenses}
          listName="expense_cost"
          {...dashboard}
        />
      );
    default:
      return null;
  }
};

export default DashboardViews;
