import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import axios from "../api/api";

const AddDocketToNameButton = ({ job, change, name }) => {
  const [docket, setDocket] = useState(null);

  useEffect(() => {
    axios
      .get(`/job-dockets/${job.uuid}`)
      .then(({ data }) => setDocket(data.data));
  }, []);

  const addDocketNumber = () => {
    const newName = name
      ? `${name} - Docket ${docket.name}`
      : `Docket ${docket.name}`;

    change("name", newName);
  };

  if (!docket) return null;

  return (
    <div className="col-12 form-group">
      <button
        onClick={addDocketNumber}
        type="button"
        className="px-0 btn btn-link"
      >
        Append docket name to deliverable name.
      </button>
    </div>
  );
};

const selector = formValueSelector("deliverableDetails");

const mapStateToProps = (state) => ({
  name: selector(state, "name"),
});

export default connect(mapStateToProps, {})(AddDocketToNameButton);
