import useApi from "../api/useApi";
import PermissionGroupSubjects from "./PermissionGroupSubjects";
import { PermissionGroupProps } from "./permissionGroupTypes";

const PositionPermissionGroups = (props: PermissionGroupProps) => {
  const { data } = useApi("/custom-fields/positions", [], true);

  const options = data as {
    id: number;
    name: string;
    disabled: boolean;
  }[];

  return (
    <>
      <p className="slim-card-title mb-2">Positions</p>
      <p>
        Please note that any positions added will not automatically give people
        associated to those positions access. Only users added to these
        positions in the future will be added to this permission group.
      </p>
      <PermissionGroupSubjects
        subjectTitle="Position"
        subjectType="App\Models\OrganisationPosition"
        {...props}
        options={options.map((option) => ({
          value: option.id,
          label: option.name,
          disabled: option.disabled,
        }))}
      />
    </>
  );
};

export default PositionPermissionGroups;
