import React from "react";
import ReactTable from "../table/ReactTable";
import dayjs from "dayjs";

const ProfileViewLogTable = ({ data }) => {
  if (!data || data?.length === 0) return null;

  const columns = [
    {
      accessorKey: "user.full_name",
      header: "Staff Name",
      cell: (info) => {
        const viewLog = info.row.original;
        return (
          <a href={`mailto:${viewLog.user.email}`}>{viewLog.user.full_name}</a>
        );
      },
    },
    {
      accessorKey: "created_at",
      header: "Viewed Date",
      cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY hh:mm a"),
    },
  ];

  return <ReactTable disableSearch columns={columns} data={data} />;
};

export default ProfileViewLogTable;
