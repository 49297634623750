import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { ReactComponent as AddUserSVG } from "../../svgs/add_user.svg";
import { ReactComponent as Collaboration } from "../../svgs/collaboration.svg";
import useApi from "../api/useApi";
import ProductivityCards from "../home/ProductivityCards";
import useFilter from "../hooks/useFilter";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import ClientsWon from "../user/ClientsWon";
import FilterDropdown from "../utils/FilterDropdown";
import SVGContainer from "../utils/SVGContainer";
import AddCompetency from "./AddCompetency";
import { competencyBadgeText } from "./Skill";
import StaffSkills from "./StaffSkills";

const SkillCard = (props) => {
  const { selectedUser, staffCompetencies } = props;
  const [search, setSearch] = useState("");
  const { data, setUrl } = useApi();
  const types = _.groupBy(staffCompetencies, "skill_level.competency_type");
  const history = useHistory();
  const { competency, uuid } = useParams();
  const { user } = useAuth();

  const showOverview = useOrganisationSetting("show_user_overview");

  useEffect(() => {
    if (uuid && !staffCompetencies.find((c) => c.uuid === competency)) {
      history.push(`/staff-matrix/users/${uuid}`);
    }
  }, [staffCompetencies]);

  const COMPLETED = 1;
  const INCOMPLETE = 0;
  const NOT_REQUIRED = 2;

  const FILTER_HEADER_NAME_SKILL_TYPE = "skill-type";
  const FILTER_HEADER_NAME_STATUS = "status";

  const defaultFilters = useMemo(
    () => [
      {
        label: "Skill Type",
        name: FILTER_HEADER_NAME_SKILL_TYPE,
        options: Object.keys(types).map((skill) => {
          return {
            label: skill
              .split("\\")
              [skill.split("\\").length - 1].split(/(?=[A-Z])/)
              .join(" "),
            value: skill,
          };
        }),
      },
      {
        label: "Status",
        name: FILTER_HEADER_NAME_STATUS,
        options: [
          {
            label: "Completed",
            value: COMPLETED,
          },
          {
            label: "Incomplete",
            value: INCOMPLETE,
          },
          {
            label: "Not Required",
            value: NOT_REQUIRED,
          }
        ],
      }
    ],
    staffCompetencies,
  );

  const { filters, toggleFilter, filterCount, activeFilters } =
    useFilter(defaultFilters);

  useEffect(() => {
    if (selectedUser) {
      setUrl(`user-branch-manager/${selectedUser?.uuid}`);
    }
  }, [selectedUser]);

  if (!selectedUser) {
    return (
      <SVGContainer SVG={AddUserSVG}>
        <h3 className="tx-inverse mg-b-20">Select User to Begin</h3>
      </SVGContainer>
    );
  }

  if (staffCompetencies.length === 0) {
    return (
      <>
        <h6 className="slim-card-title">{selectedUser.name}</h6>
        <SVGContainer SVG={Collaboration}>
          <p>
            No Competencies added for {selectedUser.name},{" "}
            <AddCompetency {...props} user={selectedUser}>
              <a href="javascript:void(0)">click here</a>
            </AddCompetency>{" "}
            to get started!
          </p>
        </SVGContainer>
      </>
    );
  }

  let filteredResults =
    search === ""
      ? staffCompetencies
      : staffCompetencies.filter((competency) => {
          const badgeText = competencyBadgeText(competency);

          return (
            competency.skill_level.full_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            badgeText.toLowerCase().includes(search.toLowerCase())
          );
        });

  filteredResults =
    activeFilters.length === 0
      ? filteredResults
      : filteredResults.filter((competency) => {

          return activeFilters.every((filter) => {
            if (filter.header_name === FILTER_HEADER_NAME_STATUS) {
              if (filter.value === NOT_REQUIRED && competency.not_required) {
                return true;
              }

              if (filter.value === COMPLETED && (!competency.is_incomplete && !competency.not_required)) {
                return true;
              }
              if (filter.value === INCOMPLETE && (competency.is_incomplete && !competency.not_required)) {
                return true;
              }
            } else if (filter.header_name === FILTER_HEADER_NAME_SKILL_TYPE) {
              if (competency.skill_level.competency_type === filter.value) {
                return true;
              }
            }
            
            return false;
          });
        });

  return (
    <>
      <div className="d-flex align-items-center">
        <div>
          <h6 className="slim-card-title">{selectedUser.name}</h6>
          {(user.is_admin || user.uuid === uuid) && (
            <p className="mb-0">
              Personal Email:{" "}
              <a href={`mailto:${selectedUser.email}`}>{selectedUser.email}</a>
            </p>
          )}
          {selectedUser.secondary_email && (
            <p className="mb-0">
              Work Email:{" "}
              <a href={`mailto:${selectedUser.secondary_email}`}>
                {selectedUser.secondary_email}
              </a>
            </p>
          )}
          {(user.is_admin || user.uuid === uuid) && (
            <p className="mb-0">
              Personal Phone:{" "}
              <a href={`tel:${selectedUser.phone}`}>{selectedUser.phone}</a>
            </p>
          )}
          {selectedUser.work_phone && (
            <p className="mb-0">
              Work Phone:{" "}
              <a href={`tel:${selectedUser.phone}`}>
                {selectedUser.work_phone}
              </a>
            </p>
          )}
        </div>
        <AddCompetency {...props} user={selectedUser} className="ms-auto">
          <button className="btn btn-outline-primary" type="button">
            <i className="fa fa-plus mg-r-10" /> Add Competency
          </button>
        </AddCompetency>
      </div>
      {showOverview?.meta_value ? (
        <>
          {(user.is_admin || data?.is_branch_manager) && (
            <div className="mt-3">
              <ProductivityCards
                overviewWidth={6}
                projectWidth={12}
                projectClass="mt-3"
                jobLengthWidth={6}
                userId={selectedUser.uuid}
                user={selectedUser}
                showClientsWon={true}
              />
            </div>
          )}

          <ClientsWon userUuid={selectedUser.uuid} />
        </>
      ) : null}
      <div className="d-flex align-items-center my-3">
        <div className="search-box shadow-sm flex-grow-1 d-flex">
          <input
            autoComplete="off"
            placeholder="Search..."
            className="form-control w-100"
            type="search"
            style={{ zIndex: 1 }}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className="btn btn-primary ms-auto" type="button">
            <i className="fa fa-search" />
          </button>
        </div>
        <FilterDropdown
          filters={filters}
          toggleFilter={toggleFilter}
          filterCount={filterCount}
          isFetching={false}
        />
      </div>
      <StaffSkills {...props} staffCompetencies={filteredResults} activeFilters={activeFilters} />
    </>
  );
};

export default SkillCard;
