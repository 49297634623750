import React from "react";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import {
  TemplateDocument,
  TemplateDocumentProfilePages,
} from "./templateDocumentTypes";
import Spinner from "../utils/Spinner";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import { ProfileTab } from "../tabs/tabTypes";
import HeaderPage from "../header/HeaderPage";
import { Link } from "react-router-dom";
import ShowTemplateDocumentPage from "./ShowTemplateDocumentPage";

const TemplateDocumentProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, setData, loading }: IUseApi<TemplateDocument> = useApi(
    `template-documents/${uuid}`,
    null,
    true,
  );

  if (loading) {
    return <Spinner loading={loading} />;
  }

  const tabs: ProfileTab<TemplateDocumentProfilePages>[] = [
    {
      label: "Details",
      link: `/template-documents/${uuid}/details`,
      page: "details",
    },
  ];

  if (data?.spreadsheet_sheets) {
    tabs.push({
      label: "Fields",
      link: `/template-documents/${uuid}/fields`,
      page: "fields",
    });
  }

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Templates",
            link: "/templates",
          },
          {
            name: data?.template.name ?? "-",
            link: data?.template.link,
          },
          {
            name: data?.name ?? "-",
            active: true,
          },
        ]}
        titlePage={data?.name ?? "-"}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{data?.name}</DashboardH3>
            <p>
              From template{" "}
              <Link to={data?.template?.link ?? "/"}>
                {data?.template.name}
              </Link>{" "}
            </p>
          </DashboardHeader>
        }
        content={
          <ShowTemplateDocumentPage document={data} setDocument={setData} />
        }
        tabs={tabs}
      />
    </>
  );
};

export default TemplateDocumentProfile;
