import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { FormSubmitHandler, InjectedFormProps } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import MoistureFields from "./MoistureFields";
import { Moisture } from "./moistureTypes";

const AddMoistureModal = ({
  toggle,
  modal,
}: {
  toggle: Function;
  modal: boolean;
}) => {
  const { takeAction }: IUseApi<{}, { data: Moisture }> = useApi();
  const queryClient = useQueryClient();

  const onSubmit: FormSubmitHandler = (values, _, props) => {
    return takeAction("store", "moistures", values)
      .then(() => {
        props.change?.("container_id", null);
        toast.success("Moisture added");
        queryClient.invalidateQueries("moistures");
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Add Moisture"
      onSubmit={onSubmit}
      form="AddMoisture"
    >
      {(props: InjectedFormProps) => <MoistureFields {...props} />}
    </FormModal>
  );
};

export default AddMoistureModal;
