import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import getStatus from "../jobs/status";
const JobListJob = ({ job, contextMenu, index, jobCount }) => {
  return (
    <div
      key={job.uuid}
      className={`mt-3 ${index + 1 == jobCount ? "" : "border-bottom"}`}
      onContextMenu={(e) => contextMenu(e, job)}
    >
      <div className="d-flex align-items-center pb-0 w-100">
        {job.project && (
          <p className="mb-0">
            <Link className="tx-inverse" to={`/${job.project.link}`}>
              {job.project.name}
            </Link>
          </p>
        )}
        <Link target="_blank" rel="noopener noreferrer" to={`${job.link}`}>
          <>
            {job.name} ({job.manager})
            {job.remaining_hours !== undefined && (
              <> - Hours Remaining: {job.remaining_hours}</>
            )}
          </>
        </Link>
      </div>
      <div className="d-flex justify-content-between pb-1">
        {!job.project && <DateComponent job={job} />}
      </div>
      <p className="mb-3">
        {getStatus(parseInt(job.status), job.invoiced_at)}
        {job.critical_path === 1 && (
          <span className="badge bg-danger">Critical Path</span>
        )}
        {job.is_overdue && (
          <span className="badge bg-danger mg-l-5">Overdue</span>
        )}
      </p>
    </div>
  );
};

const DateComponent = ({ job }) => {
  if (["4", "2"].includes(job.status)) return <p>N/A</p>;
  if (job.actual_finish_date)
    return (
      <p className="mb-0">
        Job completed on: {dayjs(job.actual_finish_date).format("DD/MM/YYYY")}
      </p>
    );
  if (job.scheduled_finish_date) {
    return (
      <p className="mb-0">
        Scheduled for completion:{" "}
        {dayjs(job.scheduled_finish_date).format("DD/MM/YYYY")}
      </p>
    );
  }
  return <p className="mb-0">Unable to find scheduled finish date</p>;
};

export default JobListJob;
