import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import Invoices from "../Billing/Invoices";
import Provider from "../Billing/Provider";
import Subscription from "../Billing/Subscription";

const Billing = () => {
  const { takeAction, loading } = useApi();

  const { user } = useAuth();

  const [invoices, setInvoices] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");

  useEffect(() => {
    takeAction(
      "index",
      `organisations/${user.active_organisation.uuid}/subscription-invoices`,
    )
      .then(({ data }) => {
        setInvoices(data.data);
      })
      .then(() =>
        takeAction("show", `payment-methods/${user.active_organisation.uuid}`),
      )
      .then(({ data }) => setPaymentMethod(data.data));
  }, []);

  return (
    <>
      <Provider
        setPaymentMethod={setPaymentMethod}
        paymentMethod={paymentMethod}
      />
      <Subscription paymentMethod={paymentMethod} setInvoices={setInvoices} />
      <Invoices invoices={invoices} loading={loading} />
    </>
  );
};

export default Billing;
