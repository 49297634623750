import { BiPlus } from "react-icons/bi";
// @ts-ignore
import "react-datetime/css/react-datetime.css";
import { TaskListProps } from "./TaskList";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { TodoTask } from "./todoTypes";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import errorSwal from "../utils/errorSwal";

const AddTask = (props: TaskListProps) => {
  const { uuid } = useParams<{ uuid: string | "my-day" }>();
  const { taskList, setTaskList } = props;
  const { takeAction }: IUseApi<{}, { data: TodoTask }> = useApi();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const oldTaskList = taskList;
    setTaskList({
      ...taskList,
      tasks: [
        ...(taskList.tasks ?? []),
        {
          title: formData.get("title")?.toString() ?? "",
        },
      ],
    });
    form.reset();
    return takeAction(
      "store",
      uuid === "my-day"
        ? "to-do-tasks"
        : `to-do-task-lists/${uuid}/to-do-tasks`,
      {
        title: formData.get("title"),
        due_date: formData?.get("due_date"),
      },
    )
      .then(({ data }) => {
        setTaskList({
          ...taskList,
          tasks: [...(taskList.tasks ?? []), data.data],
        });
      })
      .catch((err) => {
        setTaskList(oldTaskList);
        errorSwal(err);
      });
  };

  return (
    <div className="border shadow-sm rounded-lg bg-white">
      <form className="" onSubmit={onSubmit}>
        <div className=" border-bottom w-100 d-flex px-2 align-items-center">
          <BiPlus className="tx-20" />
          <input
            name="title"
            className="no-focus border-0 w-100 px-1 py-3"
            placeholder="Add Todo"
            required
          />
        </div>
        <div className="bg-gray-100 p-2 rounded-bottom d-flex align-items-center">
          <input
            type="date"
            name="due_date"
            className="bg-gray-100 p-2 no-focus border-0 rounded-lg due-date"
            placeholder="Due Date"
          />
          <Button
            outline
            size="sm"
            className="ms-auto"
            color="primary"
            type="submit"
          >
            Add
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTask;
