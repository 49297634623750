import React from "react";
import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import usePenaltyRates from "./hooks/usePenaltyRates";
import { PenaltyRate, TimesheetType } from "./penaltyRateTypes";
import { PublicHoliday, Position } from "../../enums/Model";

const PenaltyRatesList = () => {
  return (
    <>
      <HeaderPage
        titlePage="Penalty Rates"
        pageDescription={
          <>
            Penalty rates in Australia provide higher pay for working outside
            normal business hours and are designed to compensate workers for
            working during less desirable or unsociable hours.
          </>
        }
        relatedLinks={[
          {
            name: "Public Holidays",
            link: "/public-holidays",
            model: PublicHoliday,
          },
          {
            name: "Positions",
            link: "/positions",
            model: Position,
          },
        ]}
        crumbs={[
          {
            link: "organisations/settings/links",
            name: "Organisation",
          },
          { link: "/penalty-rates", name: "Penalty Rates", active: true },
        ]}
      />
      <PaginatedList
        listName="penaltyRatesList"
        indexHook={usePenaltyRates}
        originalFilters={filters}
        itemCard={({ item }: { item: PenaltyRate }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<PenaltyRateHeader penaltyRate={item} />}
          />
        )}
        addLink="/penalty-rates/add"
      />
    </>
  );
};

const PenaltyRateHeader = ({ penaltyRate }: { penaltyRate: PenaltyRate }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={penaltyRate?.name ?? penaltyRate.title} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/penalty-rates/${penaltyRate.uuid}/details`}>
              {penaltyRate?.name ?? penaltyRate.title}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const info = (penaltyRate: PenaltyRate): PaginatedInformation[] => {
  return [
    {
      name: "Type",
      value: TimesheetType[penaltyRate.timesheet_type],
    },
    {
      name: "Employment Basis",
      value: penaltyRate.employment_basis,
    },
    {
      name: "Award",
      value: penaltyRate.award,
    },
  ];
};

const filters: IFilter<
  "employment_basis" | "timesheet_type",
  string | number
>[] = [
  {
    label: "Employment Basis",
    name: "employment_basis",
    options: [
      {
        label: "Fixed Term - Full Time",
        value: "Fixed Term - Full Time",
      },
      {
        label: "Fixed Term - Part Time",
        value: "Fixed Term - Part Time",
      },
      {
        label: "Full Time",
        value: "Full Time",
      },
      {
        label: "Part Time",
        value: "Part Time",
      },
      {
        label: "Casual",
        value: "Casual",
      },
      {
        label: "Contract",
        value: "Contract",
      },
      {
        label: "Student Placement",
        value: "Student Placement",
      },
    ],
  },
  {
    label: "Timesheet Type",
    name: "timesheet_type",
    options: [
      { label: "Public Holiday", value: 0 },
      { label: "Saturday", value: 1 },
      { label: "Sunday", value: 2 },
      { label: "Night Shift", value: 3 },
    ],
  },
];

export default PenaltyRatesList;
