import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import usePurchases from "../purchases/hooks/usePurchases";
import PurchaseTable from "../purchases/PurchaseTable";
import { Purchase } from "../purchases/purchaseTypes";
import { PurchaseFilters } from "../purchases/PurchaseFilters";

interface TestRequestPurchaseOrderProps {
  testRequestID: number;
}

const TestRequestPurchaseOrder = (props: TestRequestPurchaseOrderProps) => {
  const { uuid } = useParams<{ uuid: string }>();

  return (
    <>
    <PaginatedList
        listName="testRequestPurchaseOrderList"
        indexHook={usePurchases}
        indexHookArguments={[props.testRequestID]}
        originalFilters={PurchaseFilters}
        list={({ data }: { data?: Purchase[] }) => (
          <div className="col-12">
            <PurchaseTable data={data} />
          </div>
        )}
      />
    </>
  );
};



export default TestRequestPurchaseOrder;
