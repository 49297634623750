import React from "react";
import { Button } from "reactstrap";
import { Field } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";

const MoveJobButton = ({ job, setJob }: { job: any; setJob: Function }) => {
  const { toggle, modal } = useModal();

  return (
    <>
      <button className="tx-primary" onClick={toggle}>
        Move Project
      </button>
      <MoveJobModal setJob={setJob} toggle={toggle} modal={modal} job={job} />
    </>
  );
};

const MoveJobModal = ({
  job,
  toggle,
  modal,
  setJob,
}: {
  job: any;
  toggle: Function;
  modal: boolean;
  setJob: Function;
}) => {
  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction("store", `jobs/${job.uuid}/move`, values)
      .then(({ data }) => {
        setJob(data.data);
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      initialValues={{ project_id: job.project.id }}
      form="MoveJob"
      title={`Move ${job.name}`}
      onSubmit={onSubmit}
      toggle={toggle}
      modal={modal}
    >
      <div className="form-group col-12">
        <Field
          url="custom-fields/projects"
          component={SelectInput}
          name="project_id"
          label="Project"
          formatData={(data: { name: string; id: number }[]) => {
            return data.map((d) => {
              return {
                label: d.name,
                value: d.id,
              };
            });
          }}
        />
      </div>
    </FormModal>
  );
};

export default MoveJobButton;
