import { useParams } from "react-router-dom";
import NotFound from "../404";
import useApi from "../api/useApi";
import { SafetyInitiative, SafetyInitiativeState } from "./safetyInitiative";
import Edit from "./Edit";
import { SafetyInitiativePages } from "./Profile";
import { CustomField } from "../customFields/customFieldTypes";
import ProfileForm from "../customFields/ProfileForm";
import { fieldTypes } from "../../enums/FieldType";
// import PriceBandsList from "./PriceBandsList";
import HazardReportQuestions from "./HazardReportQuestions";

const ShowPage = (props: SafetyInitiativeState) => {
  const { page, uuid } = useParams<{ page: SafetyInitiativePages; uuid: string }>();
  const { takeAction } = useApi();

  const { safetyInitiative, setSafetyInitiative } = props;

  switch (page) {
    case "details":
      return (
        <Edit
          initialValues={safetyInitiative}
          {...props}
        />
      );
    case "questions":
      return (
        <HazardReportQuestions safetyInitiative={safetyInitiative} />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
