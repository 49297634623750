import createEditorStateWithText from "@draft-js-plugins/editor/lib/utils/createEditorStateWithText";
import { AtomicBlockUtils, EditorState, Modifier } from "draft-js";
import React from "react";
import { useState } from "react";
import { BsCameraVideo } from "react-icons/bs";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { EditorStateProps } from "./SubjectItemEditor";

const EmbedVideo = (props: EditorStateProps) => {
  const { editorState, setEditorState } = props;
  const [url, setUrl] = useState("");

  const addUrl = () => {
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
      "VIDEO",
      "MUTABLE",
      {
        src: url,
        width: 500,
        height: 300,
      },
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithImage = Modifier.applyEntity(
      contentStateWithEntity,
      editorState.getSelection(),
      entityKey,
    );

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      EditorState.set(editorState, {
        currentContent: contentStateWithImage,
      }),
      entityKey,
      " ",
    );

    setEditorState(newEditorState);
    setUrl("");
  };

  return (
    <>
      <UncontrolledDropdown color="link" className="px-0 ms-2">
        <DropdownToggle color="link" className="px-0 me-2">
          <BsCameraVideo className="tx-18" />
        </DropdownToggle>
        <DropdownMenu className="p-0">
          <div className="d-flex">
            <input
              className="flex-grow-1 no-focus py-2 border-0 ps-1"
              placeholder="Paste video link here..."
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <Button onClick={addUrl} color="link" size="sm">
              Add
            </Button>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default EmbedVideo;
