import { useMemo } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { BootstrapColour } from "./utilTypes";

const Avatar = ({
  name,
  colour,
  rounded = "",
  rawName,
  size = "40px",
  tooltip = true,
  textSize = "tx-14",
}: {
  rawName?: string;
  name: string;
  colour: BootstrapColour;
  rounded?:
    | "-top"
    | "-right"
    | "-bottom"
    | "-left"
    | "-circle"
    | "-pill"
    | "-0"
    | "";
  size?: string;
  tooltip?: boolean;
  textSize?: string;
}) => {
  const firstTwo = name.split(" ").slice(0, 2);
  const initials = firstTwo.map((word) => word[0]).join("");

  const id = useMemo(
    () =>
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(2, 10),
    [name],
  );

  if (!id) {
    return null;
  }

  return (
    <>
      <div
        className={`bg-grad-${colour} shadow rounded${rounded} tx-white d-flex align-items-center justify-content-center me-2 ${textSize}`}
        style={{ width: size, height: size }}
        id={id}
      >
        {rawName ?? initials}
      </div>
      {tooltip && (
        <UncontrolledTooltip placement="top" target={id}>
          {name}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default Avatar;
