import useApi from "../api/useApi";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import Answers from "./Answers";

const ManagerAnswers = ({
  performanceReview,
  setPerformanceReview,
}: {
  performanceReview: any;
  setPerformanceReview: Function;
}) => {
  const { takeAction } = useApi();

  const onSubmit = (values: any) => {
    return takeAction(
      "update",
      `/performance-reviews/${performanceReview.uuid}/manager-answers`,
      values,
    )
      .then(({ data }: { data: any }) => {
        setPerformanceReview({
          ...performanceReview,
          manager_answers: data.data,
        });
        toast.success("Manager Answers Updated");
      })
      .catch(formError);
  };

  return (
    /** @ts-ignore */
    <Answers
      customFields={performanceReview?.manager_answers.custom_form}
      initialValues={performanceReview?.manager_answers}
      onSubmit={onSubmit}
      form="ManagerAnswers"
    />
  );
};

export default ManagerAnswers;
