import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";

const ClientItemOverview = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading } = useApi(`clients/${uuid}/overview`, [], true);

  const columns = [
    { header: "Item", accessorKey: "item" },
    { header: "Price", accessorKey: "price" },
    { header: "Quantity", accessorKey: "quantity" },
    { header: "Deliverable Name", accessorKey: "deliverable_name" },
    { header: "Job Completed At", accessorKey: "job_completed_at" },
    { header: "Job Name", accessorKey: "job_name" },
    { header: "Project Name", accessorKey: "project_name" },
  ];

  return <ReactTable columns={columns} data={data} loading={loading} />;
};

export default ClientItemOverview;
