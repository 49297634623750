import { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

const Autocomplete = ({
  data,
  input,
  label,
  placeholder,
  className,
  required,
  meta: { touched, error, invalid },
  handleSelect,
}) => {
  const [position, setPosition] = useState();

  const getPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setPosition(pos);
      });
    }
  };

  return (
    <PlacesAutocomplete
      {...input}
      onSelect={handleSelect}
      searchOptions={
        position
          ? {
              location: new window.google.maps.LatLng(
                position.coords.latitude,
                position.coords.longitude,
              ),
              radius: 2000,
            }
          : {}
      }
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div className="position-relative">
          {label && (
            <label className="tx-semibold tx-inverse">
              {label}
              {required ? <span className="tx-danger"> *</span> : ""}
            </label>
          )}
          <input
            {...getInputProps({
              placeholder,
              className: `form-control ${className} ${
                touched && error && "parsley-error"
              }`,
            })}
            onFocus={getPosition}
          />
          {touched && error && (
            <span className={error ? "parsley-errors-list" : ""}>{error}</span>
          )}
          <div
            className={`autocomplete-dropdown-container ${
              suggestions.length > 1 ? "active" : ""
            }`}
          >
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";

              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa" }
                : { backgroundColor: "#ffffff" };

              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default Autocomplete;
