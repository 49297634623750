import dayjs from "dayjs";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { Calibration } from "../equipmentCalibrationTypes/calibrationTypeTypes";
import { CustomFieldValue, FieldAttributes } from "./customFieldTypes";

const EquipmentFieldInformation = (props: {
  formValues: { custom_fields: CustomFieldValue };
  field_attributes: FieldAttributes;
  uuid: string;
  currentValue: any;
}) => {
  const { currentValue } = props;
  const { data, setUrl, loading }: IUseApi<Calibration[]> = useApi(``, []);

  useEffect(() => {
    if (currentValue && !Array.isArray(currentValue)) {
      setUrl(
        `equipment-equipment-calibrations/${currentValue}?filter[status]=incomplete&filter[date_before]=${dayjs()
          .add(1, "day")
          .format("YYYY-MM-DD")}`,
      );
    }
  }, [currentValue]);

  if (data?.length === 0 || loading) {
    return null;
  }

  return (
    <div className="d-block space-y-3 mt-3">
      {data?.map((calibration) => {
        return (
          <Alert
            title="Incomplete Check"
            className="rounded-lg border border-warning shadow-sm"
            color="warning"
          >
            <Link className="text-dark" to={calibration.link}>
              {" "}
              {calibration.calibration_type.procedures}{" "}
            </Link>
          </Alert>
        );
      })}
    </div>
  );
};

export default EquipmentFieldInformation;
