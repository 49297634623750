import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
//BO$$
import buttonGroup from "../../utils/buttonGroup";
import renderField from "../../utils/renderField";
import removeComments from "./removeComments";

import SATISFACTION from "../../../data/site_inspection_satisfaction";
import REINFORCEMENT_TYPES from "../../../data/reinforcement_types";

const Reinforcement = (props) => (
  <form onSubmit={props.handleSubmit}>
    <div className="row">
      <div className="form-group col-12">
        <h5 className="text-dark">Reinforcement Types</h5>
      </div>
      {REINFORCEMENT_TYPES.map((type) => (
        <div className="form-group col-md-6">
          <Field
            name={`reinforcement.${type.name}`}
            label={type.label}
            component={buttonGroup}
            options={SATISFACTION}
            groupClass="w-100"
            buttonClass="w-100"
            onChange={() =>
              removeComments(type.name, props.dispatch, "reinforcement")
            }
          />
          {props[type.name] === "C" && (
            <div className="form-group mg-t-10">
              <Field
                placeholder="Comments..."
                name={`reinforcement.comments.${type.name}`}
                component={renderField}
              />
            </div>
          )}
        </div>
      ))}
    </div>
    <div className="row mg-y-20">
      <div className="col-6">
        <button
          className="btn w-100 bg-gray-400 tx-gray-800"
          type="button"
          onClick={props.previousPage}
        >
          <i className="icon ion-reply" /> Preparation Inspected
        </button>
      </div>
      <div className="col-6">
        <button className="btn w-100 btn-primary" type="submit">
          Finalise <i className="icon ion-forward" />
        </button>
      </div>
    </div>
  </form>
);

const form = reduxForm({
  destroyOnUnmount: false,
  form: "addResidentialSiteInspection",
});

const selector = formValueSelector("addResidentialSiteInspection");

const mapStateToProps = (state) => {
  const toReturn = {};
  REINFORCEMENT_TYPES.forEach((type) => {
    toReturn[type.name] = selector(state, `reinforcement.${type.name}`);
  });
  return toReturn;
};

export default connect(mapStateToProps, {})(form(Reinforcement));
