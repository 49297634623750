import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ExpiredModal = (props) => {
  const { modal } = props;

  return (
    <Modal isOpen={modal} backdrop="static">
      <ModalHeader>Link Expired</ModalHeader>
      <ModalBody>
        <p>Looks like the link that you've tried to access has expired.</p>
        <p>Please ensure you are using the most recent link sent.</p>
        <p>Alternatively, please ask to resend your invite.</p>
        <p>Thanks!</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary">
          <a className="text-white" href="https://landing.thebossapp.com.au">
            Learn More about theBOSSapp
          </a>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExpiredModal;
