import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NoMatch from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import CheckIns from "./CheckIns";
import EditTimesheet from "./EditTimesheet";
import { Timesheet } from "./timesheetTypes";
import TimesheetProductivity from "./TimesheetProductivity";

const ShowTimesheetPage = (props: { timesheet: Timesheet }) => {
  const { timesheet } = props;
  const { user } = useAuth();

  const { page, uuid } = useParams<{ page: string; uuid: string }>();

  switch (page) {
    case "details":
      return <EditTimesheet {...props} />;
    case "productivity":
      return <TimesheetProductivity {...props} />;
    case "documents":
      return (
        <NewProfileDocuments
          model="App\Models\Timesheet"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/leave/${user?.uuid}`}
          homeUrl={`timesheets/${uuid}/documents`}
        />
      );
    case "check-ins":
      return <CheckIns timesheet={timesheet} />;
    default:
      return <NoMatch />;
  }
};

export default ShowTimesheetPage;
