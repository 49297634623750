import { useState } from "react";
import { WrappedFieldProps } from "redux-form";
import { ReactComponent as StarLeft } from "../../svgs/star-left.svg";
import { ReactComponent as StarRight } from "../../svgs/star-right.svg";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";

const StarRating = (
  props: WrappedFieldProps & {
    label: string;
    required?: boolean;
    information?: string;
    disabled?: boolean;
  },
) => {
  const { input, label, information, required, disabled = false } = props;
  const [hoverValue, setHoverValue] = useState(0);

  return (
    <>
      {label && (
        <>
          <label className="form-control-label tx-inverse tx-semibold">
            {label}
            {required ? <span className="tx-danger"> *</span> : ""}
            <FieldInformationPopOver information={information} />
          </label>
        </>
      )}
      <div
        className="space-x-1 d-flex"
        onMouseLeave={(e) => {
          setHoverValue(0);
        }}
      >
        {[1, 2, 3, 4, 5].map((i) => {
          const halfSelected =
            hoverValue > 0 ? hoverValue >= i - 0.5 : input.value >= i - 0.5;
          const fullSelected =
            hoverValue > 0 ? hoverValue >= i : input.value >= i;
          return (
            <div
              className="d-flex"
              key={i}
              onMouseOut={(e) => e.stopPropagation()}
            >
              <StarLeft
                height="30px"
                width="15px"
                onMouseEnter={(e) => (disabled ? null : setHoverValue(i - 0.5))}
                className={`star ${disabled ? "" : "pointer-hover"} ${
                  halfSelected ? "star-filled" : ""
                }`}
                onClick={() => (disabled ? null : input.onChange(i - 0.5))}
              />
              <StarRight
                height="30px"
                width="15px"
                onMouseEnter={(e) => (disabled ? null : setHoverValue(i))}
                className={`star ${disabled ? "" : "pointer-hover"} ${
                  fullSelected ? "star-filled" : ""
                }`}
                onClick={() => (disabled ? null : input.onChange(i))}
              />
            </div>
          );
        })}
      </div>
      {input.value ? (
        <small className="d-block">{input.value} / 5</small>
      ) : null}
    </>
  );
};

export default StarRating;
