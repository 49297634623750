import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getPurchases = (
  { pageParam = 1 },
  search?: string,
  testRequestID?: number,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `purchases?page=${pageParam}&filter[search]=${search}${
        testRequestID ? `&filter[test_request_id]=${testRequestID}` : ""
      }`,
    )
    .then(({ data }) => data);
};

export default function usePurchases(search?: string, testRequestID?: number) {
  return useInfiniteQuery(
    ["purchases", { search, testRequestID }],
    (pageParam) => getPurchases(pageParam, search, testRequestID),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
