import React from "react";
import { toast } from "react-toastify";
import { initialize } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import errorSwal from "../utils/errorSwal";
import EquipmentForm from "./EquipmentForm";

const FORM_NAME = "EditEquipment";

const ProfileForm = (props) => {
  const { setEquipment, refreshData } = props;

  const { user } = useAuth();

  const { takeAction } = useApi();

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/equipment`,
  );

  const onSubmit = (values, dispatch) => {
    return upload(values)
      .then((values) =>
        takeAction("update", `equipment/${values.uuid}`, values),
      )
      .then(({ data }) => {
        setEquipment(data.data);
        dispatch(initialize(FORM_NAME, data.data));
        refreshData();
        return toast.success(`${values.name} updated successfully`);
      })
      .catch(errorSwal);
  };

  return <EquipmentForm form={FORM_NAME} onSubmit={onSubmit} {...props} />;
};

export default ProfileForm;
