const CPIR_SUBJECT_TYPES = [
  {
    value: "App\\Models\\User",
    label: "Person",
  },
  {
    value: "App\\Models\\OrganisationBranch",
    label: "Branch",
  },
  {
    value: "App\\Models\\Group",
    label: "Group",
  },
];

export default CPIR_SUBJECT_TYPES;
