import { toast } from "react-toastify";
import { reset } from "redux-form";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import ModalForm from "./ModalForm";

const FORM_NAME = "COMPETENCY_SKILLS";

const AddModalButton = (props) => {
  const { category, setSkills, skills } = props;
  const { modal, toggle } = useModal();

  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    return takeAction("store", `competency-skills`, {
      ...values,
      skill_category_id: category.id,
      skill_levels: values.skill_levels.map((level, i) => {
        return {
          ...level,
          level: i + 1,
        };
      }),
    })
      .then(({ data }) => {
        setSkills([...skills, data.data]);
        toast.success("Skill Added Successfully");
        dispatch(reset(FORM_NAME));
      })
      .catch(errorSwal);
  };

  return (
    <>
      <ul className="nav nav-activity-profile mg-y-20">
        <li className="nav-item">
          <button
            type="button"
            onClick={toggle}
            className="nav-link w-100 pointer-hover onFocus"
          >
            <i className="icon ion-compose tx-primary" />
            Add Skill
          </button>
        </li>
      </ul>

      {modal && (
        <ModalForm
          onSubmit={onSubmit}
          title="Add New Skill"
          form={FORM_NAME}
          {...props}
          modal={modal}
          toggle={toggle}
          initialValues={{
            add_initially: true,
            skill_levels: [{}],
          }}
        />
      )}
    </>
  );
};

export default AddModalButton;
