import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import useApi from "../api/useApi";
import { date } from "../form/formatters";
import { Invoice } from "../invoices/invoiceTypes";
import SendInvoiceButton from "../invoices/SendInvoiceButton";
import ReactTable from "../table/ReactTable";
import formatMoney from "../utils/formatMoney";
import FilterButtons from "./FilterButtons";
import DocsModal from "../utils/DocsModal";

const Invoices = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { state } = useLocation<{ filter?: string }>();
  const [filter, setFilter] = useState(state?.filter);

  const url = `clients/${uuid}/invoices?${queryString.stringify(
    filter
      ? {
          filter: filter,
        }
      : {},
  )}`;
  const { data, loading, setUrl } = useApi(url, [], true);

  const columnHelper = createColumnHelper<Invoice>();

  useEffect(() => {
    setUrl(url);
  }, [filter]);

  const invoices = data as Invoice[];

  //See https://github.com/TanStack/table/issues/4241 for reason of any
  const columns = React.useMemo<ColumnDef<Invoice, any>[]>(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (props) => {
          const invoice = props.row.original;
          return (
            <>
              <Link to={invoice.link}>{props.getValue()}</Link>
              <div>
                {invoice.is_overdue && (
                  <Badge className="me-1" color="danger">
                    Overdue
                  </Badge>
                )}
                {invoice.current_total_due && !invoice.paid_at ? (
                  <Badge color="info">
                    ${formatMoney(invoice.current_total_due)} Due
                  </Badge>
                ) : null}

                {invoice.paid_at ? <Badge color="success">Paid</Badge> : null}
              </div>
            </>
          );
        },
      }),

      columnHelper.accessor("expected_payment_date", {
        header: "Expected Payment Date",
        cell: (info) => date.format(info.getValue()),
      }),
      columnHelper.accessor("paid_at", {
        header: "Paid On",
        cell: (props) => {
          const paidAt = props.getValue();
          if (!paidAt) {
            return "Awaiting Payment";
          }

          return date.format(paidAt);
        },
      }),
      columnHelper.display({
        header: "Send Invoice",
        cell: (info) => {
          if (info.row.original.paid_at) {
            return null;
          }

          return (
            <SendInvoiceButton
              className="btn btn-outline-primary btn-sm"
              contacts={[]}
              invoice={info.row.original}
            />
          );
        },
      }),
    ],
    [],
  );

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/adding-an-invoice/" />
      <FilterButtons filter={filter} setFilter={setFilter} loading={loading} />
      <ReactTable<Invoice>
        data={invoices}
        columns={columns}
        loading={loading}
      />
    </>
  );
};

export default Invoices;
