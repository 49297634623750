import {
  FETCH_CALIBRATIONS,
  NEW_CALIBRATION,
  UPDATE_CALIBRATION,
  DELETE_CALIBRATION,
  FETCH_UPCOMING_CALIBRATIONS
} from "../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  let payloadData;
  const newItems = Object.assign({}, state.items);
  let currentData;
  if (state.items.data) {
    currentData = state.items.data;
  }
  if (action.payload) {
    const { data } = action.payload;
    payloadData = data;
  }
  switch (action.type) {
    case FETCH_CALIBRATIONS:
      return {
        ...state,
        items: action.payload
      };
    case FETCH_UPCOMING_CALIBRATIONS:
      return {
        ...state,
        upcomingCalibrations: action.payload
      };
    case NEW_CALIBRATION:
      currentData.push(payloadData.data);
      newItems.data = currentData;

      return {
        ...state,
        items: newItems
      };
    case UPDATE_CALIBRATION:
      return {
        ...state,
        items: action.payload
      };
    case DELETE_CALIBRATION:
      currentData = newItems.data.filter(
        item => action.deletedCalibration !== item.uuid
      );

      return {
        ...state,
        items: {
          ...newItems,
          data: currentData
        }
      };
    default:
      return state;
  }
}
