import React from "react";
import { ButtonDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { reset } from "redux-form";
import useModal from "../hooks/useModal";
import FolderFields from "./FolderFields";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";

const CreateFolder = (props) => {
  const { setFolder, folder } = props;
  const { modal, toggle } = useModal();
  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    const url = folder?.uuid ? `folders/${folder.uuid}/children` : "folders";
    if (!values.name) {
      return toast.warning("Please Enter Folder Name.");
    }
    return takeAction("store", url, values)
      .then(({ data }) => {
        setFolder(
          folder?.uuid
            ? {
                ...folder,
                children: [...folder.children, data.data],
              }
            : data.data,
        );
        dispatch(reset("Folder"));
        toggle();
        toast.success(`${values.name} created successfully`);
      })
      .catch(errorSwal);
  };

  return (
    <ButtonDropdown className="w-100" isOpen={modal} toggle={toggle}>
      <DropdownToggle
        outline
        className="w-100 text-start"
        color="primary"
        caret
      >
        <i className="fa fa-folder pe-2"></i>Add Folder
      </DropdownToggle>
      <DropdownMenu>
        <div className="pd-30 pd-sm-40 wd-sm-350">
          <FolderFields onSubmit={onSubmit} />
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default CreateFolder;
