import React, { Component } from "react";
import { Field } from "redux-form";

import renderField from "../utils/renderField";
import required from "../utils/required";
import isSubmitting from "../utils/submitting";

class FormCalibrationUnit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  render() {
    const { submitting, initialValues } = this.props;
    const { deleting } = this.state;

    return (
      <div className="row">
        <div className="col-lg-9 form-group align-items-center">
          <Field
            name="name"
            placeholder="Name"
            type="text"
            component={renderField}
            validate={[required]}
          />
        </div>

        <div className="form-group col-lg-3 mg-b-10">
          <button
            className="btn btn-primary bd-0 form-control"
            disabled={deleting || submitting}
          >
            {isSubmitting(submitting)}
          </button>
        </div>
      </div>
    );
  }
}

export default FormCalibrationUnit;
