import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import swal from "sweetalert";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import SpreadPrice from "../utils/SpreadPrice";
import isSubmitting from "../utils/submitting";
import ItemForm from "./ItemForm";

const Deliverables = (props) => {
  const { takeAction, loading } = useApi();
  const [selected, setSelected] = useState([]);
  const { invoice, setInvoice } = props;

  const { user } = useAuth();

  const deleteRows = () => {
    if (invoice?.integration_estimation?.length > 0 && !user.is_admin) {
      return swal({
        title: "Unable to edit",
        text: "It looks like this invoice is out of sync with your accounting integration, please ask an admin to sync up before continuing.",
        icon: "warning",
      });
    }

    return deleteSwal(
      "the following deliverables:\n" +
        selected.map((deliverable) => deliverable.name).join("\n"),
    )
      .then(() =>
        takeAction("store", `invoices/${invoice.uuid}/items/delete`, {
          deliverables: selected.map((deliverable) => deliverable.id),
        }),
      )
      .then(({ data }) => {
        setInvoice(data.data);
        setSelected([]);
        toast.success("Items Deleted");
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "job",
      header: "Job",
      cell: (info) => {
        const deliverable = info.row.original;
        return <Link to={deliverable.job.link}>{deliverable.job.name}</Link>;
      },
    },
    {
      accessorKey: "name",
      header: "Deliverable Name",
      cell: (info) => {
        const deliverable = info.row.original;
        return <Link to={deliverable.link}>{deliverable.name}</Link>;
      },
    },
    {
      accessorKey: "job.branch",
      header: "Branch",
    },
    {
      accessorKey: "total_cost",
      header: "Deliverable Value",
      cell: (info) => <SpreadPrice price={info.getValue()} />,
    },
  ];

  const rowClasses = (row) => {
    return row.missing_prices ? "table-danger" : "";
  };

  return (
    <>
      <ItemForm {...props} />
      <ReactTable
        enableMultiSelect
        searchWrapperClasses="align-items-center"
        columns={columns}
        data={invoice.deliverables}
        loading={loading}
        setSelectedRows={setSelected}
        rowClasses={rowClasses}
        extraButtons={
          selected.length > 0 ? (
            <div className="ms-3">
              <Button
                size="sm"
                outline
                color="danger"
                onClick={deleteRows}
                disabled={loading}
              >
                {isSubmitting(
                  loading,
                  <>
                    Delete {selected.length} Deliverable
                    {selected.length === 1 ? "" : "s"}
                  </>,
                  "Deleting...",
                )}
              </Button>
            </div>
          ) : null
        }
      />
    </>
  );
};

export default Deliverables;
