import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";

import Spinner from "../utils/Spinner";
import BillableItemUnitForm from "./BillableItemUnitForm";

const EditBillableItemUnit = () => {
  const { uuid } = useParams();

  const { takeAction } = useApi();

  const { data, setData, loading } = useApi(
    `/billable-item-units/${uuid}`,
    {},
    true,
  );

  const onSubmit = (values) => {
    return takeAction("update", `/billable-item-units/${uuid}`, values)
      .then(({ data }) => {
        toast.success(`${data.data.name} updated successfully`);
        setData(data.data);
      })
      .catch(formError);
  };

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <HeaderPage
        titlePage={data.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "billable-item-units", name: "Billable Item Units" },
          { link: "", name: data.name, active: true },
        ]}
      />

      <BillableItemUnitForm
        form="EditBillableItemUnit"
        onSubmit={onSubmit}
        initialValues={data}
      />
    </>
  );
};

export default EditBillableItemUnit;
