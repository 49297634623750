import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import swal from "sweetalert";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import ArchiveModal from "./ArchiveModal";

const ArchiveButton = ({ purchase }) => {
  const { takeAction, loading } = useApi();
  const history = useHistory();
  const { toggle, modal } = useModal();

  const archive = () => {
    if (!purchase.archived_at) {
      toggle();
      return;
    }

    return swal({
      title: purchase.archived_at ? "Restore?" : "Archive?",
      text: `Are you sure you want to ${
        purchase.archived_at ? "restore" : "archive"
      } ${purchase.name}?`,
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          closeModal: true,
          visible: true,
        },
        delete: {
          text: purchase.archived_at ? "Restore" : "Archive",
          value: true,
          closeModal: true,
          className: "btn btn-danger align-baseline",
        },
      },
    })
      .then((archive) => {
        if (archive) {
          return takeAction(
            purchase.archived_at ? "destroy" : "store",
            `purchases/${purchase.uuid}/archived`,
          );
        }
        return false;
      })
      .then((r) => {
        if (!r) {
          return;
        }
        history.push(`/projects/${purchase.project.uuid}/purchases`);
        toast.success(
          `${purchase.name} ${purchase.archived_at ? "restored" : "archived"}`,
        );
      })
      .catch(errorSwal);
  };

  if (purchase.approved) {
    return null;
  }

  const onSubmit = (values) => {
    return takeAction("store", `purchases/${purchase.uuid}/archived`, values)
      .then(() => {
        history.push(`/projects/${purchase.project.uuid}/purchases`);
        toast.success(
          `${purchase.name} ${purchase.archived_at ? "restored" : "archived"}`,
        );
      })
      .catch(errorSwal);
  };

  return (
    <>
      <Button
        className="mt-3"
        outline
        color="danger"
        block
        onClick={archive}
        disabled={loading}
      >
        {isSubmitting(
          loading,
          purchase.archived_at ? "Restore" : "Archive",
          purchase.archived_at ? "Restoring..." : "Archiving...",
        )}
      </Button>
      <ArchiveModal
        toggle={toggle}
        modal={modal}
        form="ArchivePurchase"
        title={`Archive ${purchase.name}`}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ArchiveButton;
