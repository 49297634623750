import React from "react";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import Empty from "../utils/Empty";
import CustomScaleLoader from "../utils/scaleLoader";
import TimeLine from "../utils/TimeLine";

interface ProjectHistory {
  id: number;
  created_at: string;
  from?: {
    name: string;
    link: string;
  };
  to?: {
    name: string;
    link: string;
  };
}

const EquipmentProjectHistory = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading } = useApi(`/equipment/${uuid}/project-history`, []);

  const history = data as ProjectHistory[];

  return (
    <>
      <TimeLine
        items={history.map((d) => {
          return {
            uuid: d.id,
            created_at: d.created_at,
            content: (
              <>
                {" "}
                Changed from{" "}
                {d.from ? (
                  <Link to={`/${d.from.link}`}>{d.from.name}</Link>
                ) : (
                  "No Project"
                )}{" "}
                to{" "}
                {d.to ? (
                  <Link to={`/${d.to.link}`}>{d.to.name}</Link>
                ) : (
                  "No Project"
                )}
              </>
            ),
          };
        })}
        title="Project History"
        empty={<NoHistory history={history} loading={loading} />}
      />
    </>
  );
};

const NoHistory = ({
  history,
  loading,
}: {
  history: ProjectHistory[];
  loading: boolean;
}) => {
  if (loading) {
    return <CustomScaleLoader>Fetching History...</CustomScaleLoader>;
  }

  if (history.length === 0) {
    return (
      <div className="mt-3">
        <Empty top={false}>
          <p className="mt-5">No Project History Found</p>
        </Empty>
      </div>
    );
  }

  return null;
};

export default EquipmentProjectHistory;
