import Tabs from "../tabs/Tabs";
import { Tab } from "../tabs/tabTypes";
import CustomJobDocumentTemplate from "./CustomJobDocumentTemplate";
import CustomJobEmailTemplate from "./CustomJobEmailTemplate";
import CustomJobJobTemplates from "./CustomJobJobTemplate";

const CustomJobTemplateTabs = (props: any) => {
  const tabs: Tab[] = [
    {
      title: "Documents",
      component: <CustomJobDocumentTemplate {...props} />,
    },
    {
      title: "Email",
      component: <CustomJobEmailTemplate {...props} />,
    },
    {
      title: "Job",
      component: <CustomJobJobTemplates {...props} />,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default CustomJobTemplateTabs;
