import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import errorSwal from "../utils/errorSwal";
import { fetchPayRunTimesheets } from "../../actions/payRunTimesheetActions";
import TimesheetTable from "../timesheets/TimesheetTable";
import CustomScaleLoader from "../utils/scaleLoader";
import dayjs from "dayjs";

class PayRunTimesheets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      approvalTime: "",
    };
  }

  componentDidMount = () => {
    const { fetchPayRunTimesheets, uuid } = this.props;

    return fetchPayRunTimesheets(uuid)
      .catch(errorSwal)
      .then(() => this.setState({ loading: false }));
  };

  onChange = (e) => {
    this.setState({ approvalTime: e.target.value });
  };

  render() {
    const { approvalTime } = this.state;
    const { timesheets } = this.props;
    if (this.state.loading)
      return (
        <CustomScaleLoader>
          <p>Fetching Timesheets...</p>
        </CustomScaleLoader>
      );
    const filteredTimesheets = timesheets.filter(({ timesheet }) => {
      return (
        !approvalTime ||
        dayjs(timesheet.approval_status.at) > dayjs(approvalTime)
      );
    });

    return (
      <>
        <div className="form-group">
          <label>Filter by Approval Status Time After</label>
          <input
            type="datetime-local"
            value={this.state.approvalTime}
            onChange={this.onChange}
            className="form-control"
          />
        </div>
        <TimesheetTable showUser timesheets={filteredTimesheets} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  timesheets: state.payRunTimesheets.items,
});

export default connect(mapStateToProps, { fetchPayRunTimesheets })(
  PayRunTimesheets,
);
