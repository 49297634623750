import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getMoistures = ({ pageParam = 1 }, search?: string, takenAt?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `moistures?page=${pageParam}&filter[search]=${search}${
        takenAt ? `&filter[day_taken]=${takenAt}` : ""
      }`,
    )
    .then(({ data }) => data);
};

export default function useMoistures(search?: string, taken_at?: string) {
  return useInfiniteQuery(
    ["moistures", { search, taken_at }],
    (pageParam) => getMoistures(pageParam, search, taken_at),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
      refetchInterval: 10_000,
    },
  );
}
