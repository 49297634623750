import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const SyncIntegrationEstimation = ({ estimated, invoice, setInvoice }) => {
  const { uuid } = useParams();
  const { takeAction, loading } = useApi();
  const { user } = useAuth();

  const syncIntegration = () => {
    return takeAction("store", `integrations/invoices/${uuid}`, {
      integration_id: estimated.id,
      provider: estimated.provider,
    })
      .then(({ data }) => {
        toast.success("Integration Synced");
        setInvoice(data.data);
      })
      .catch(errorSwal);
  };

  if (!user.is_admin) return null;

  return (
    <Alert color="primary">
      <p className="mb-0">
        {invoice.name} isn't yet synced with {estimated.provider_name}
      </p>
      <p className="mb-0">
        We found an invoice in {estimated.provider_name} with the same name{" "}
        <Button
          disabled={loading}
          onClick={syncIntegration}
          color="link"
          className="px-0"
          size="sm"
        >
          {isSubmitting(loading, "Click Here", "Syncing...")}
        </Button>{" "}
        to sync.
      </p>
    </Alert>
  );
};

export default SyncIntegrationEstimation;
