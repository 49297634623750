/**
 * If this get changed, please also change on ProjectJob.php Model.
 */
const JOB_STATUS = [
  { value: "0", label: "Pending" },
  { value: "1", label: "In Progress" },
  { value: "2", label: "On Hold" },
  { value: "3", label: "Completed" },
  { value: "4", label: "Cancelled" },
];

export default JOB_STATUS;
