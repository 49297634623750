import dayjs from "dayjs";
import React, { useState } from "react";
import { Badge } from "reactstrap";
import ClientFeedback from "../clientFeedback/ClientFeedback";
import { ClientFeedback as IClientFeedback } from "../clientFeedback/clientFeedbackTypes";

const ShowProjectFeedback = ({ feedback }: { feedback: any }) => {
  const [selectedFeedback, setSelectedFeedback] = useState<IClientFeedback>();

  if (selectedFeedback) {
    return (
      <ClientFeedback
        setFeedback={setSelectedFeedback}
        initialValues={selectedFeedback}
        feedback={{
          ...selectedFeedback,
          custom_form: selectedFeedback.custom_form.map((field) => ({
            ...field,
            field_attributes: {
              ...field.field_attributes,
              disabled: true,
            },
          })),
        }}
        form="ClientFeedback"
      />
    );
  }

  return (
    <button
      onClick={() => setSelectedFeedback(feedback)}
      disabled={!feedback.completed_at}
      className="text-justify bg-white p-3 border rounded-lg shadow d-flex align-items-center w-100"
    >
      <div className="cfeedback">
        <p className="mb-1 tx-inverse fw-bolder">{feedback.template.name}</p>
        <p className="mb-0 text-secondary">
          Requested{" "}
          {feedback.requested_by ? (
            <>
              by{" "}
              <a
                className="tx-inverse"
                href={`mailto:${feedback.requested_by.email}`}
              >
                {feedback.requested_by?.name}
              </a>{" "}
            </>
          ) : (
            ""
          )}{" "}
          on{" "}
          <span className="tx-inverse">
            {dayjs(feedback.created_at).format("DD/MM/YYYY")}
          </span>{" "}
          at{" "}
          <span className="tx-inverse">
            {dayjs(feedback.created_at).format("hh:mma")}
          </span>
        </p>
        <p className="mb-0 text-secondary">
          Sent to{" "}
          <a
            className="tx-inverse me-1"
            href={`mailto:${feedback.sent_to.email}`}
          >
            {feedback.sent_to.name}
          </a>
        </p>
      </div>
      <div className="ms-auto">
        {feedback.completed_at ? (
          <Badge color="success">Completed</Badge>
        ) : (
          <Badge color="warning">Incomplete</Badge>
        )}
      </div>
    </button>
  );
};

export default ShowProjectFeedback;
