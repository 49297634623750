import React from "react";
import DetailField from "./DetailField";

const OrganisationDetails = ({ organisation }) => {
  return (
    <>
      <DetailField
        label="Company Name"
        value={organisation.name.toLowerCase()}
      />
      {organisation.trading_name && (
        <DetailField label="Trading Name" value={organisation.trading_name} />
      )}
      <DetailField label="ABN" value={organisation.abn} />
      <DetailField label="ACN" value={organisation.acn} />
      <DetailField label="Accounts Email" value={organisation.accounts_email} />
      <DetailField label="Description" value={organisation.description} />
    </>
  );
};

export default OrganisationDetails;
