import { IUseApi } from "./../api/apiTypes";
import { useEffect, useState } from "react";
import useApi from "../api/useApi";

type Groups =
  | "Accounting"
  | "Safety"
  | "HR"
  | "Administration"
  | "Procedures"
  | "Business Development"
  | "I.T.";

const useUserGroup = (groupName: Groups): boolean => {
  const [userIsPartOfGroup, setUserIsPartOfGroup] = useState(false);

  const { takeAction }: IUseApi = useApi();

  useEffect(() => {
    takeAction(
      "show",
      `user-is-part-of-group?group=${encodeURIComponent(groupName)}`,
    ).then(({ data }) => {
      setUserIsPartOfGroup(data.data ? true : false);
    });
  }, [groupName]);

  return userIsPartOfGroup;
};

export default useUserGroup;
