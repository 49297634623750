import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DatabaseListView from "../listView/DatabaseListView";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import Modal from "./DivisionModal";
import * as divisionApi from "../../api/divisions";
import useApi from "../api/useApi";
import { TrackingSite, OrganisationBranch } from "../../enums/Model";

const Divisions = () => {
  const { uuid } = useParams();
  const history = useHistory();
  const [selected, setSelected] = useState(false);
  const [modal, setModal] = useState(false);

  const {
    data: divisions,
    setData: setDivisions,
    loading,
  } = useApi("organisationdivisions", [], true);

  const toggle = () => {
    if (uuid) {
      history.push("/divisions");
      setSelected(false);
    }
    setModal(!modal);
  };

  const setResource = (value) => {
    if (!uuid) {
      setDivisions([...divisions, value]);
      return;
    }

    setDivisions(
      divisions.map((division) =>
        division.division.uuid !== uuid ? division : value,
      ),
    );
  };

  const removeDivision = (value) => {
    setDivisions(
      divisions.filter(
        (division) => division.division.uuid !== value.data.division.uuid,
      ),
    );
    toggle();
  };

  useEffect(() => {
    const division = divisions.find(({ division }) => division.uuid === uuid);
    setSelected(division);

    if (uuid) {
      setModal(true);
    }
  }, [uuid, loading]);

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage="Divisions"
        pageDescription={
          <>
            Divisions represent the various categories of operations within your
            organization, such as 'Engineering', 'Administration', and so on.
          </>
        }
        relatedLinks={[
          {
            name: "Tracking Sites",
            link: "/trackingsites",
            model: TrackingSite,
          },
          { name: "Branches", link: "/branches", model: OrganisationBranch },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "divisions", name: "Divisions", active: true },
        ]}
      />
      <DatabaseListView
        buttons={[
          <button
            onClick={toggle}
            type="button"
            className="btn btn-outline-primary btn-sm"
          >
            Add New Division
          </button>,
        ]}
        list={divisions}
        name="divisions"
      />
      <Modal
        toggle={toggle}
        modal={modal}
        setResource={setResource}
        setModal={setModal}
        initialValues={selected ? selected.division : null}
        formSubmit={uuid ? divisionApi.update : divisionApi.store}
        uuid={uuid}
        removeDivision={removeDivision}
      />
    </>
  );
};

export default Divisions;
