import React from "react";
import { Equipment } from "./equipmentTypes";
import {
  Field,
  FieldArray,
  FormSubmitHandler,
  InjectedFormProps,
  WrappedFieldArrayProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import RenderField from "../utils/renderField";
import SelectInput from "../form/SelectInput";
import { IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import SubmitButton from "../utils/SubmitButton";
import FormHeader from "../utils/FormHeader";
import TimeUnitType, { timeUnitTypes } from "../../enums/TimeUnitType";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";
import { toast } from "react-toastify";
import { I } from "msw/lib/SetupApi-f4099ef3";
import state from "sweetalert/typings/modules/state";

interface Props {
  equipment: Equipment;
  setEquipment: (equipment: Equipment) => void;
}

type EquipmentRecurringPurchaseDetailsProps = InjectedFormProps<
  Equipment,
  Props
> &
  Props;

const EquipmentRecurringPurchaseDetails = (
  props: EquipmentRecurringPurchaseDetailsProps,
) => {
  const { handleSubmit, setEquipment } = props;

  const { takeAction }: IUseApiWithData<{}, { data: Equipment }> = useApi();

  const onSubmit: FormSubmitHandler<Equipment, Props> = (values) => {
    return takeAction(
      "store",
      `/equipment/${props.equipment.uuid}/recurring-payments`,
      values,
    )
      .then(({ data }) => {
        setEquipment(data.data);
        toast.success("Contract agreement details added successfully.");
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert {...props} />
        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            name="default_invoice_name"
            label="Default Invoice Name"
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="recurring_payment_start_date"
            label="Start Date"
            type="date"
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="recurring_payment_next_date"
            label="Next Invoice Due On"
            type="date"
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="recurring_payment_end_date"
            label="End Date"
            type="date"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="recurring_payment_frequency"
            label="Frequency"
            type="number"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            name="recurring_payment_frequency_units"
            label="Units"
            options={timeUnitTypes.filter(
              (type) => type.value !== TimeUnitType.Initial,
            )}
          />
        </div>
        <FieldArray component={Prices} change={props.change} name="prices" />
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const Prices = ({
  fields,
  change,
}: WrappedFieldArrayProps & { change: InjectedFormProps["change"] }) => {
  const {
    data: billableItemUnits,
  }: IUseApiWithData<{ name: string; id: number }[]> = useApi(
    "billable-item-units",
    [],
  );

  const {
    data: pricingTypes,
  }: IUseApiWithData<{ label: string; value: number }[]> = useApi(
    "pricing-types",
    [],
  );

  const {
    data: pricingItems,
  }: IUseApiWithData<{ name: string; id: number; type: string }[]> = useApi(
    "pricing-items",
    [],
  );

  return (
    <>
      <FormHeader>Prices</FormHeader>
      {fields.map((price, index) => {
        const priceData = fields.get(index);

        return (
          <React.Fragment key={index}>
            <div className="col-lg-12 form-group">
              <p className="mb-0 text-dark">{priceData?.description}</p>
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                name={`${price}.billable_item_units_id`}
                label="Units"
                options={billableItemUnits.map((b) => ({
                  label: b.name,
                  value: b.id,
                }))}
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                name={`${price}.line_item_type`}
                label="Item Type"
                options={pricingTypes}
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                name={`${price}.line_item_id`}
                label="Type"
                options={pricingItems.filter(
                  (type) => type.type === priceData?.line_item_type,
                )}
              />
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

const form = reduxForm<Equipment, Props>({
  form: "equipmentRecurringPurchaseDetails",
});

// const selector = formValueSelector("equipmentRecurringPurchaseDetails");
// const mapStateToProps = (state: any) => {

//     return {
//         : selector(state, "equipment"),
//     };
// }

export default form(EquipmentRecurringPurchaseDetails);
