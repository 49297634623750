import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Link } from "react-router-dom";
import NotFound from "../404";
import RegisterOrganisation from "./RegisterOrganisation";
import RegisterUser from "./RegisterUser";

dayjs.extend(utc);
dayjs.extend(timezone);

const Register = (props) => {
  const { match } = props;
  const { type } = match.params;

  return (
    <div className="signin-wrapper">
      <div className="signin-box signup" style={{ width: "800px" }}>
        <h2 className="slim-logo">
          <a href="/">theBOSSapp</a>
          <h3 className="signin-title-primary">Sign Up!</h3>
        </h2>
        <Router {...props} type={type} />
        <p className="mg-t-40 mg-b-0">
          Already have an account? <Link to="/login">Sign In</Link>
        </p>
      </div>
    </div>
  );
};

const Router = (props) => {
  switch (props.type) {
    case "organisation":
      return (
        <RegisterOrganisation initialValues={dayjs.tz.guess()} {...props} />
      );
    case "individual":
      return props.match.params.uuid ? (
        <RegisterUser {...props} />
      ) : (
        <NotFound />
      );
    default:
      return <NotFound />;
  }
};

export default Register;
