import "chart.js/auto";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { BsLink } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormHeader from "../utils/FormHeader";
import ComplianceMetricTable, { Metric } from "./ComplianceMetricTable";
import DateRangePicker from "./DateRangePicker";
import { dateRangeQuery, lastQuarterRange } from "./ProductivityCards";

interface ComplianceMetricsResponse {
  cpirs: number;
  approvals: number;
  calibrations: number;
  competencies: number;
  timesheets: number;
}

interface ScoreType {
  type: "cpirs" | "approvals" | "calibrations" | "competencies" | "timesheets";
  score: number;
}

const scores: ScoreType[] = [
  {
    type: "cpirs",
    score: 0.5,
  },
  {
    type: "approvals",
    score: 0.5,
  },
  {
    type: "calibrations",
    score: 0.5,
  },
  {
    type: "competencies",
    score: 0.5,
  },
  {
    type: "timesheets",
    score: 0.25,
  },
];

const ComplianceMetrics = ({
  userId,
  width = 4,
  showSelected = false,
}: {
  userId?: string;
  width: number;
  showSelected?: boolean;
}) => {
  const [selectedOverview, setSelectedOverview] = useState<ScoreType>();

  const [range, setRange] = useState(lastQuarterRange);

  const dateRange = dateRangeQuery(range);

  const { data: metric, setUrl: setMetricUrl }: IUseApi<Metric[]> = useApi();

  const { data, setUrl, loading }: IUseApi<ComplianceMetricsResponse> = useApi(
    `compliance-metrics${userId ? `/${userId}` : ""}?${dateRange}`,
  );

  useEffect(() => {
    if (selectedOverview) {
      setMetricUrl(
        `compliance-metric${userId ? `/${userId}` : ""}?${dateRange}&metric=${
          selectedOverview.type
        }`,
      );
    }
  }, [selectedOverview, range]);

  useEffect(() => {
    if (!dateRange) {
      return;
    }

    setUrl(`compliance-metrics${userId ? `/${userId}` : ""}?${dateRange}`);
  }, [range]);

  const individualScores: ScoreType[] = scores.map((score) => {
    return {
      type: score.type,
      score: (data?.[score.type] ?? 0) * score.score,
    };
  });

  return (
    <>
      <div className={`col-lg-${width} mb-3 mb-lg-0`}>
        <div className="card h-100 position-relative">
          {loading && (
            <div className="z-index-10 t-0 l-0 h-100 w-100 d-flex align-items-center justify-content-center position-absolute bg-white-4">
              <Spinner color="primary" />
            </div>
          )}
          <div className="p-3 d-flex h-100 flex-column justify-content-between">
            <div className="d-flex">
              <div>
                <p className="slim-card-title tx-primary mb-0 d-flex align-items center">
                  <Link
                    to="/compliance-metrics"
                    className="d-flex align-items-center"
                  >
                    Compliance Metrics
                    <BsLink className="tx-18 me-2" />{" "}
                  </Link>
                  <a
                    href="https://docs.thebossapp.com.au/docs/thebossapp-docs/miscellaneous/compliance-metrics-9777/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-info-circle" />
                  </a>
                </p>
                <small>How quickly you get certain tasks done.</small>
              </div>
              <DateRangePicker range={range} setRange={setRange} />
            </div>
            <div>
              <Pie
                options={{
                  onClick: (evt, element) => {
                    if (!element.length) {
                      setSelectedOverview(undefined);
                      return;
                    }
                    const { index } = element[0];
                    setSelectedOverview(individualScores[index]);
                  },
                }}
                data={{
                  labels: individualScores.map((score) => score.type),
                  datasets: [
                    {
                      data: individualScores.map((score) => score.score),
                      label: "Scores",
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
            <div className="">
              <p className="text-center tx-inverse tx-16 fw-bolder mb-0">
                {_.sumBy(individualScores, "score") / individualScores.length}
              </p>
              <p className="text-center mb-0">Total Score</p>
            </div>
          </div>
        </div>
      </div>
      {showSelected && metric && (
        <>
          <FormHeader
            className=""
            extra={
              <p className="mb-0 ms-auto">
                Score:{" "}
                {
                  individualScores.find(
                    (score) => score.type === selectedOverview?.type,
                  )?.score
                }
              </p>
            }
          >
            {selectedOverview?.type}
          </FormHeader>
          <div className="col-12 mt-3">
            <ComplianceMetricTable metric={metric ?? []} />
          </div>
        </>
      )}
    </>
  );
};

export default ComplianceMetrics;
