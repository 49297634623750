import React from "react";
import FormModal from "../utils/FormModal";
import { Field } from "redux-form";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const ExpectedPaymentDateModal = ({
  modal,
  toggle,
  setInvoice,
  invoice,
}: {
  modal: boolean;
  toggle: Function;
  setInvoice: Function;
  invoice: any;
}) => {
  const { takeAction } = useApi();

  const onSubmit = (values: { expected_payment_date: string }) => {
    return takeAction("update", `invoice-payments/${invoice.uuid}`, values)
      .then(({ data }: { data: any }) => {
        setInvoice(data.data);
        toggle();
        toast.success("Expected Payment Date Updated");
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      onSubmit={onSubmit}
      title="Edit Expected Payment Date"
      modal={modal}
      toggle={toggle}
      initialValues={{
        expected_payment_date: invoice.expected_payment_date,
      }}
      form="InvoiceExpectedPaymentDate"
    >
      <div className="col-lg-12">
        <Field
          component={RenderField}
          type="date"
          name="expected_payment_date"
          label="Expected Payment Date"
        />
      </div>
    </FormModal>
  );
};

export default ExpectedPaymentDateModal;
