import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import useApi from "../api/useApi";
import { primary } from "../utils/Colours";

const BranchDropdown = ({
  selectedBranch,
  setSelectedBranch,
  wrapperClass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const { takeAction, loading } = useApi();

  useEffect(() => {
    takeAction("index", "/organisationbranches/user").then(({ data }) =>
      setBranches(data.data),
    );
  }, []);

  const selectBranch = (branch) => {
    setIsOpen(false);
    setSelectedBranch(branch);
  };

  return (
    <div
      className={`dropdown dropdown-demo ${
        isOpen ? "show" : ""
      } ${wrapperClass}`}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`btn btn${selectedBranch ? "-outline" : ""}-primary w-100`}
        type="button"
      >
        <div>
          <span>{selectedBranch ? selectedBranch.name : "Select Branch"}</span>
          <i className={`fa fa-angle-${isOpen ? "up" : "down"} mg-l-10`} />
        </div>
      </button>
      <div className={`dropdown-menu pd-5 ${isOpen ? "show" : ""}`}>
        {loading ? (
          <div className="d-flex justify-content-center">
            <ClipLoader color={primary} />
          </div>
        ) : (
          <nav className="nav dropdown-nav">
            {branches.map((branch) => (
              <a
                href="javascript:void(0)"
                onClick={() => selectBranch(branch)}
                className="nav-link"
                key={branch.uuid}
              >
                <i className="fa fa-building-o tx-primary me-2" /> {branch.name}
              </a>
            ))}
          </nav>
        )}
      </div>
    </div>
  );
};

export default BranchDropdown;
