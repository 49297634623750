import React from "react";
import { FieldArray, reduxForm } from "redux-form";
import FormHeader from "../utils/FormHeader";
import SubmitButton from "../utils/SubmitButton";
import RequiredInsuranceFields from "./RequiredInsuranceFields";

const ExternalInsuranceForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormHeader>Required Insurances</FormHeader>
        <FieldArray component={RequiredInsuranceFields} name="insurances" />
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};
const form = reduxForm({
  form: "ExternalInsurances",
  enableReinitialize: true,
});

export default form(ExternalInsuranceForm);
