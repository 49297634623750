import MfaRecoveryCodes from "./MfaRecoveryCodes";
import SetUpMfa from "./SetUpMfa";
import RemoveTrustedDevice from "./RemoveTrustedDevice";
import DeleteTwoFactorAuthentication from "./DeleteTwoFactorAuthentication";

const MultiFactorAuthentication = () => {
  return (
    <>
      <SetUpMfa />
      <MfaRecoveryCodes />
      <RemoveTrustedDevice />
      <DeleteTwoFactorAuthentication />
    </>
  );
};

export default MultiFactorAuthentication;
