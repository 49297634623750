import React from "react";
import HeaderPage from "../header/HeaderPage";
import ComplianceMetrics from "./ComplianceMetrics";

const ComplianceMetricsPage = () => {
  return (
    <>
      <HeaderPage titlePage="Compliance Metrics" crumbs={[]} />
      <div className="row">
        <div className="col-3"></div>
        <ComplianceMetrics width={6} showSelected={true} />
        <div className="col-3"></div>
      </div>
    </>
  );
};

export default ComplianceMetricsPage;
