import { BeatLoader } from "react-spinners";
import { useSwipeable } from "react-swipeable";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useWindowEvent } from "../hooks/useWindowEvent";
import { primary } from "../utils/Colours";
import DisplayEmail from "./DisplayEmail";

const PreviewModal = (props) => {
  const { document, modal, toggle, changeDocument, onClosed } = props;

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => changeDocument("forward"),
    onSwipedRight: () => changeDocument("backward"),
  });

  useWindowEvent("keyup", (e) => {
    if (e.key === "ArrowRight") {
      changeDocument("forward");
      return;
    }

    if (e.key === "ArrowLeft") {
      changeDocument("backward");
      return;
    }

    return;
  });

  const googlePrintUrl = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(
    document.link,
  )}`;

  if (!document) {
    return null;
  }

  const image = isImage(document);

  return (
    <>
      <Modal
        modalClassName="fade effect-just-me document-preview"
        isOpen={modal}
        toggle={toggle}
        onClosed={onClosed}
        className="document-preview-modal"
      >
        <ModalHeader
          tag={() => (
            <>
              <div className="d-flex w-100 ">
                <h5 className="tx-white me-auto modal-title">
                  {document.name}{" "}
                </h5>
              </div>
              {
                // Cant use a link do to an error in react dropzone
              }
              <span
                aria-hidden="true"
                onClick={() => (window.location.href = document.link)}
              >
                <i
                  className={`fa fa-download tx-20 pointer-hover tx-white ${
                    image ? "me-1" : "me-3"
                  }`}
                />
              </span>
              {!image && (
                <span
                  aria-hidden="true"
                  onClick={() => (window.location.href = googlePrintUrl)}
                >
                  <i className="fa fa-print tx-20 tx-white me-1 pointer-hover" />
                </span>
              )}
            </>
          )}
          toggle={toggle}
        />
        <ModalBody>
          <div
            className="d-flex align-items-center justify-content-between document-preview-modal-body"
            {...swipeHandlers}
          >
            <button
              type="button"
              className="btn btn-link d-none d-md-block"
              onClick={() => changeDocument("backward")}
            >
              <i className="icon ion-reply tx-24 text-secondary"></i>
            </button>
            <ShowFile document={document} />
            <button
              type="button"
              className="btn btn-link d-none d-md-block"
              onClick={() => changeDocument("forward")}
            >
              <i className="icon ion-forward tx-24 text-secondary"></i>
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ShowFile = ({ document }) => {
  const microsoftViewUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
    document.inline_link,
  )}`;

  if (document.name.split(".").pop().toLowerCase() === "pdf") {
    return <iframe className="document-iframe" src={document.inline_link} />;
  }

  if (isImage(document)) {
    return (
      <img
        alt="Unable to show"
        src={document.inline_link}
        className="mw-100 mw-md-30"
      />
    );
  }

  if (isEmail(document)) {
    return <DisplayEmail document={document} />;
  }

  return (
    <div className="w-100">
      <BeatLoader
        color={primary}
        className="d-flex justify-content-center"
        loading
      />
      <iframe className="document-iframe" src={microsoftViewUrl} />
    </div>
  );
};

const isImage = (document) => document.mime_type.split("/")[0] === "image";

const isEmail = (document) => document.name.endsWith(".eml");

export default PreviewModal;
