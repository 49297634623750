const asyncOptions = [
  {
    label: "Projects",
    value: "/custom-fields/projects",
  },
  {
    label: "Jobs",
    value: "/custom-fields/jobs",
  },
  {
    label: "Clients",
    value: "/custom-fields/clients",
  },
  {
    label: "Suppliers",
    value: "/custom-fields/suppliers",
  },
  {
    label: "Equipment",
    value: "/custom-fields/equipment",
  },
  {
    label: "Users",
    value: "/custom-fields/users",
  },
  {
    label: "Branch",
    value: "/custom-fields/branches",
  },
  {
    label: "Positions",
    value: "/custom-fields/positions",
  },
  {
    label: "Tracking Sites",
    value: "/custom-fields/tracking-sites",
  },
  {
    label: "Divisions",
    value: "/custom-fields/divisions",
  },
  {
    label: "Cpirs",
    value: "/custom-fields/cpirs",
  },
];
export default asyncOptions;
