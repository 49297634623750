import React from "react";
import { useParams } from "react-router-dom";
import { Field } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import { ITemplate } from "../templates/templateTypes";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const GeneratePdfModal = ({
  template,
  modal,
  toggle,
}: {
  template: ITemplate;
  modal: boolean;
  toggle: Function;
}) => {
  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: { template_id: number }) => {
    return takeAction("store", `dockets/${uuid}/pdfs`, values)
      .then(({ data }) => {
        window.location.replace(data.data.link);
        toast.success("Docket PDF generated, downloading now!");
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      onSubmit={onSubmit}
      modal={modal}
      toggle={toggle}
      form="GenerateDocketPDF"
      title="Generate Docket PDF"
      save="Generate"
      saving="Generating..."
    >
      <div className="form-group col-12">
        <Field
          component={SelectInput}
          label="Select Template"
          name="template_id"
          required
          validate={required}
          options={
            template?.documents?.map((document) => ({
              label: document.name,
              value: document.id,
            })) ?? []
          }
        />
      </div>
    </FormModal>
  );
};

export default GeneratePdfModal;
