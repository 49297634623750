import axios from "../api/api";
import errorSwal from "../utils/errorSwal";

const generatePayRunCSV = (pay_run_uuid, invoice_number) => {
  return axios
    .post(`/export-pay-run-csv`, { pay_run_uuid, invoice_number })
    .then(({ data }) => (window.location.href = data.link))
    .catch(errorSwal);
};

export default generatePayRunCSV;
