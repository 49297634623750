import LinkifyIt from "linkify-it";
import React from "react";
import tlds from "tlds";
import useApi from "../api/useApi";
const OpenGraph = ({ text, wrapperClass = "" }) => {
  const linkify = new LinkifyIt();
  linkify.tlds(tlds);
  const links = linkify.match(text);

  const url = links?.find((link) => {
    return link.url && (link.schema === "https:" || link.schema === "http:");
  })?.url;

  const { data } = useApi(
    url ? `open-graph?url=${encodeURIComponent(url)}` : null,
  );

  if (!data || !data.url) {
    return null;
  }

  return (
    <a href={data.url} target="_blank" rel="noopener noreferrer">
      <div className={`${wrapperClass} border shadow-sm rounded-lg`}>
        {data.image && data["site_name"] !== "YouTube" && (
          <img
            className="w-100 open-graph-image"
            alt="graph"
            src={data.image}
          />
        )}
        {data["site_name"] === "YouTube" && (
          <iframe
            height={data["video:height"]}
            className="w-100"
            src={data["video:url"]}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
        <div className="p-3 border-top">
          <p className="tx-inverse tx-bold mb-0">{data.title}</p>
          <p className="mb-0 tx-gray-700">{data.description}</p>
          <p className="mb-0 tx-gray-500">{data.url}</p>
        </div>
      </div>
    </a>
  );
};

export default OpenGraph;
