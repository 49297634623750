import React from "react";
import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import formError from "../utils/formError";
import PenaltyRateForm from "./PenaltyRateForm";
import { PenaltyRate } from "./penaltyRateTypes";
import { ShowPenaltyRatePageProps } from "./ShowPenaltyRatePage";
import InformationAlert from "../utils/InformationAlert";

const EditPenaltyRate = (props: ShowPenaltyRatePageProps) => {
  const { penaltyRate, setPenaltyRate } = props;

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi<any, { data: PenaltyRate }> = useApi();

  const onSubmit: FormSubmitHandler<PenaltyRate> = (values) => {
    return takeAction("update", `/penalty-rates/${uuid}`, values)
      .then(({ data }) => {
        setPenaltyRate(data.data);
        toast.success("Penalty Rate Updated");
      })
      .catch((err) => {
        errorSwal(err);
        return formError(err);
      });
  };

  return (
    <div>
      <InformationAlert
            title={`Penalty Rate`}
            body={`Please be aware that any updates made to this penalty rate will update all unpaid timesheets affected by this penalty rate, including those currently in a pay run.`}
            type="warning"
          />
          <hr />
    <PenaltyRateForm
      initialValues={penaltyRate}
      onSubmit={onSubmit}
      form="UpdatePenaltyRate"
      setPenaltyRate={setPenaltyRate}
    />
    </div>
  );
};

export default EditPenaltyRate;
