import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getDownloadLogs = (
  { pageParam = 1 },
  search: string,
  document_id: string,
  document_type: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `documents/logs?page=${pageParam}&document_type=${document_type}&document_id=${document_id}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useDownloadLogs(
  search: string,
  document_id: string,
  document_type: string,
) {
  return useInfiniteQuery(
    ["download-logs", { search, document_id, document_type }],
    (pageParam) =>
      getDownloadLogs(pageParam, search, document_id, document_type),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
