import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import { ProjectContact } from "../projects/projectTypes";
import formError from "../utils/formError";
import { Invoice } from "./invoiceTypes";
import SendModal from "./SendModal";
import { useAuth } from "../../context/auth-context";

const SendInvoiceButton = ({
  invoice,
  contacts,
  className = "nav-link pointer-hover w-100",
}: {
  invoice: Invoice;
  contacts: ProjectContact[];
  className?: string;
}) => {
  const { takeAction }: IUseApi = useApi();
  const { toggle, modal } = useModal();
  const { user } = useAuth();

  const onSubmit = (values: {
    to: { label: string; value: string }[];
    cc: { label: string; value: string }[];
    send_attachments?: boolean;
  }) => {
    return takeAction("store", `invoices/${invoice.uuid}/mail`, {
      to: values.to.map(({ value }) => value),
      cc: values.cc.map(({ value }) => value),
      send_attachments: values.send_attachments,
    })
      .then(() => {
        toggle();
        toast.success("Invoice sent");
      })
      .catch(formError);
  };

  const toRecipients = (invoice?.client?.email || []).map((email) => ({
    label: `${email} [Client Contact]`,
    value: email,
  }));

  const invoiceRecipients = (contacts || [])
    .filter(({ email }) => email)
    .map(({ email, name }) => ({
      label: `${email} [Invoice Recipients]`,
      value: email,
    }));

  /** @ts-ignore */
  toRecipients.push(...invoiceRecipients);

  const contactsToCC = [
    {
      label: user?.secondary_email ?? user?.email,
      value: user?.secondary_email ?? user?.email,
    },
  ];

  const isDraft = !invoice.completed_at ? "Draft " : "";

  if (invoice.line_items?.length === 0) {
    return null;
  }

  return (
    <>
      <li className="nav-item">
        <button type="button" onClick={toggle} className={className}>
          <i className="icon ion-ios-redo tx-primary" /> Send{" "}
          {invoice.is_credit_note
            ? `${isDraft}Credit Note`
            : `${isDraft}Invoice`}
        </button>
      </li>
      <SendModal
        toggle={toggle}
        modal={modal}
        form="InvoiceSend"
        onSubmit={onSubmit}
        title={`Send ${invoice.name}`}
        initialValues={{
          to: toRecipients,
          cc: contactsToCC,
        }}
        invoice={invoice}
      />
    </>
  );
};

export default SendInvoiceButton;
