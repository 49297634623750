import React from "react";
import { Deduction } from "./deductionTypes";
import { money } from "../form/formatters";

const DeductionOverview = ({ deduction }: { deduction: Deduction }) => {
  return (
    <div className="bg-white p-3 border rounded-lg shadow-sm space-y-5">
      <div className="d-flex border-bottom pb-3">
        <p className="text-muted mb-0">Amount:</p>
        <p className="text-dark mb-0 ms-auto fw-boler tx-16">
          {money.format(deduction.amount)}
        </p>
      </div>
      <div className="d-flex border-bottom pb-3">
        <p className="text-muted mb-0">Description:</p>
        <p className="text-dark mb-0 ms-auto fw-boler tx-16">
          {deduction.description}
        </p>
      </div>
      <div className="d-flex ">
        <p className="text-muted mb-0">Type:</p>
        <p className="text-dark mb-0 ms-auto fw-boler tx-16">
          {deduction.is_recurring ? "Recurring" : "One Time"}
        </p>
      </div>
    </div>
  );
};

export default DeductionOverview;
