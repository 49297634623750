import _ from "lodash";
import React from "react";
import TimeLine from "../utils/TimeLine";

const FileHistory = ({ documents }) => {
  const formattedDocuments = _.orderBy(documents, "created_at").map(
    (document) => {
      return {
        title: document?.name,
        created_at: document?.created_at,
        content: <a href={document.link}>{document.name}</a>,
      };
    },
  );

  return <TimeLine items={formattedDocuments} title="File History" />;
};

export default FileHistory;
