import _ from "lodash";
import React from "react";
import { Badge } from "reactstrap";
import { getStatus } from "../jobs/InvoiceStatusBadge";
import ManagerLeftTabs from "../utils/ManagerLeftTabs";

interface JobFilters {
  statuses: number[];
  search: string;
  overdue: boolean;
  dueDateLimit: number;
  dueDateLimitDays: number;
  invoiceStatuses: (number | null)[];
}

const InvoiceStatusTabs = ({
  jobs,
  toggleInvoiceStatuses,
  filters,
}: {
  jobs: any[];
  toggleInvoiceStatuses: Function;
  filters: JobFilters;
}) => {
  const statuses = _.countBy(jobs, "invoice_status");
  const items = Object.entries(statuses)
    .map((val) => {
      const status = val[0] !== "null" ? parseInt(val[0]) : null;
      const statusInfo = getStatus(status);

      const isFiltered = filters.invoiceStatuses.includes(status);

      return {
        value: status,
        count: val[1],
        label: statusInfo.name,
        color: statusInfo.color,
        countDisplay: (item: any) => (
          <Badge className="rounded shadow" color={item.color}>
            {item.count}
          </Badge>
        ),
        onClick: () => toggleInvoiceStatuses(status),
        className: isFiltered ? "active" : "",
      };
    })
    .filter((item) => {
      return item.value !== null;
    });

  if (items.length === 0) {
    return null;
  }

  return (
    <ManagerLeftTabs label="Invoice Status" wrapperClass="my-3" items={items} />
  );
};

export default InvoiceStatusTabs;
