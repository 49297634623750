import { toast } from "react-toastify";
import { Field, FieldArray, initialize, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import PurchaseItems from "../purchaseItems/PurchaseItems";
import FileInput from "../upload/FileInput";
import uploadToS3 from "../upload/uploadToS3";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { GroupType } from "../user/userTypes";

const InvoiceForm = (props) => {
  const { handleSubmit, purchaseOrder, setPurchaseOrder, dispatch } = props;
  const { takeAction } = useApi();
  const { user } = useAuth();

  const { response, takeAction: checkNumber } = useApi();

  const submit = (values) => {
    return uploadToS3(
      values.document,
      `organisations/${user.active_organisation.uuid}/purchase-order-invoices`,
    )
      .then((documents) => {
        const data = {
          ...values,
        };

        if (documents.length) {
          data.documents = documents;
        }

        return takeAction(
          "store",
          `purchase-invoices/${purchaseOrder.purchase_invoice.uuid}`,
          data,
        );
      })
      .then(({ data }) => {
        toast.success(`${purchaseOrder.name} invoice updated`);
        dispatch(initialize("PurchaseInvoice", data.data.purchase_invoice));

        return setPurchaseOrder(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        {!purchaseOrder.billed_at && (
          <div className="col-12 form-group">
            <Field
              required={
                !purchaseOrder.purchase_invoice.file.link ? true : false
              }
              validate={
                !purchaseOrder.purchase_invoice.file.link ? required : null
              }
              component={FileInput}
              name="document"
              label="Invoice File"
            />
          </div>
        )}
        <div className="col-lg-6 form-group">
          <Field
            name="date"
            component={renderField}
            label="Date"
            required
            validate={required}
            type="date"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="number"
            component={renderField}
            label="Supplier Invoice Number"
            required
            validate={required}
            onBlur={(_, number) => {
              checkNumber(
                "store",
                `purchase-invoices/${purchaseOrder.purchase_invoice.uuid}/validate-number`,
                {
                  number,
                },
              );
            }}
          />
          {response?.response?.status === 422 && (
            <small className="tx-warning">
              {response?.response?.data.errors.number[0]}
            </small>
          )}
        </div>

        <FieldArray
          component={PurchaseItems}
          {...props}
          name="purchase_items"
          disabled={purchaseOrder.billed_at}
        />
        {(!purchaseOrder.billed_at ||
          user.groups.includes(GroupType.Accounting)) && (
          <div className="form-group col-12">
            <SubmitButton {...props} />
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({ form: "PurchaseInvoice" });

export default form(InvoiceForm);
