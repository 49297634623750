import React from "react";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";

const UserPerformanceReviews = () => {
  const { userId } = useParams<{ userId: string }>();

  const { data } = useApi(
    `/users/${userId}/performance-reviews/rounds`,
    [],
    true,
  );

  return (
    <div className="space-y-3">
      {data?.map((round: any) => {
        return (
          <div className="" key={round.id}>
            <Link
              to={`/performance-reviews/${round.primary_review.uuid}/employee-assessment`}
            >
              <div className="bg-white p-3 border rounded-lg shadow-sm">
                <p className="mb-0 text-dark">
                  {round.template} - {round.primary_review.initial_year}
                </p>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default UserPerformanceReviews;
