import {
  FETCH_MOISTURE_CONTAINERS,
  NEW_MOISTURE_CONTAINER,
  UPDATE_MOISTURE_CONTAINER,
  DELETE_MOISTURE_CONTAINER
} from "../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_MOISTURE_CONTAINERS:
      return {
        ...state,
        items: action.payload
      };
    case NEW_MOISTURE_CONTAINER:
      return {
        ...state,
        items: [action.payload, ...state.items]
      };
    case UPDATE_MOISTURE_CONTAINER:
      return {
        ...state,
        items: state.items.map(item =>
          item.uuid !== action.payload.uuid ? item : action.payload
        )
      };
    case DELETE_MOISTURE_CONTAINER:
      return {
        ...state,
        items: state.items.filter(item => item.uuid !== action.payload)
      };
    default:
      return state;
  }
}
