import queryString from "query-string";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomScaleLoader from "../utils/scaleLoader";
import DocumentBreadcrumbs from "./DocumentBreadcrumbs";
import DownloadFolderButton from "./DownloadFolderButton";
import StandardFolderDropdown from "./StandardFolderDropdown";
import Upload from "./Upload";
import DocsModal from "../utils/DocsModal";
import useStandardDocuments from "./hooks/useStandardDocuments";
import flattenInfinitePages from "../utils/flattenInfinitePages";
import useObserver from "../hooks/useObserver";
import useApi from "../api/useApi";

const NewProfileDocuments = (props) => {
  const { folderUuid } = useParams();

  const { homeUrl, documentPath, model, documentableId, noFolders } = props;

  // const [path, setPath] = useState([]);
  const { data: path, setUrl, setData: setPath } = useApi();
  const [highlightedDocuments, setHighlightedDocuments] = useState([]);

  const query = {
    documentableId,
    documentableType: model,
  };

  const string = queryString.stringify(query);

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useStandardDocuments(string, folderUuid);

  const documents = flattenInfinitePages(data);

  useEffect(() => {
    // showOrIndex();
    setHighlightedDocuments([]);
    if (folderUuid) {
      setUrl(`standard-documents/${folderUuid}/path`);
      return;
    }

    setUrl("");
    setPath([]);
  }, [folderUuid]);

  const intersection = useObserver(
    () => !isFetchingNextPage && hasNextPage && fetchNextPage(),
  );

  if (!data) {
    return <CustomScaleLoader>Fetching Documents...</CustomScaleLoader>;
  }

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/miscellaneous/controlled-documents/" />

      {!noFolders && (
        <>
          <StandardFolderDropdown
            margin="mg-b-20"
            {...props}
            path={documentPath}
          />
          <div className="d-flex mb-3">
            <DocumentBreadcrumbs
              path={path}
              {...props}
              url={homeUrl}
              highlightedDocuments={highlightedDocuments}
              setHighlightedDocuments={setHighlightedDocuments}
            />
            <div className="d-flex ms-auto">
              <DownloadFolderButton
                query={query}
                documents={documents}
                highlightedDocuments={highlightedDocuments}
              />
            </div>
            {props.extraInfo}
          </div>
        </>
      )}

      <Upload
        documents={documents}
        {...props}
        // setPath={setPath}
        highlightedDocuments={highlightedDocuments}
        setHighlightedDocuments={setHighlightedDocuments}
      />

      <div id="intersection" ref={intersection} />
    </>
  );
};

export default NewProfileDocuments;
