import React, { MouseEventHandler } from "react";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import DeleteButton from "../utils/DeleteButton";
import errorSwal from "../utils/errorSwal";
import useApi from "../api/useApi";
import { AiOutlineCheck } from "react-icons/ai";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../table/ReactTable";
import {
  StandardDocument,
  StandardDocumentShare,
} from "./standardDocumentTypes";

interface FileSharedListTableProps {
  doc: StandardDocument;
  sharedEmails: [];
  refreshData: () => void;
  addNewToggle: MouseEventHandler;
  toggleEditShare: MouseEventHandler;
  setSelectedFileShare: any;
}

const FileSharedListTable = (props: FileSharedListTableProps) => {
  const {
    doc,
    sharedEmails,
    refreshData,
    addNewToggle,
    toggleEditShare,
    setSelectedFileShare,
  } = props;
  const { user } = useAuth();
  const { takeAction, loading } = useApi();

  const columnHelper = createColumnHelper<StandardDocumentShare>();
  const StandardDocumentShares = sharedEmails as StandardDocumentShare[];

  const columns = React.useMemo<ColumnDef<StandardDocumentShare, any>[]>(
    () => [
      columnHelper.accessor("email", {
        header: "Email",
        cell: ({ getValue, row }) => {
          const share = row.original;
          const isSharedByParent = !(
            (!doc?.id &&
              share.shareable_type !== "App\\Models\\StandardDocument") ||
            (share.shareable_id === doc?.id &&
              share.shareable_type === "App\\Models\\StandardDocument")
          );

          return (
            <Button
              color="link"
              className="p-0"
              onClick={toggleEditShare}
              disabled={isSharedByParent}
            >{`${getValue()}`}</Button>
          );
        },
      }),
      columnHelper.accessor("expires_at", {
        header: "Expiry Date",
        cell: ({ getValue }) => {
          const expires_at = getValue();
          return expires_at ? (
            <>
              {dayjs(expires_at).format("DD/MM/YYYY")}
              {dayjs(expires_at).isBefore(dayjs()) && (
                <span className="text-danger"> (Expired)</span>
              )}
            </>
          ) : (
            "No Expiry"
          );
        },
      }),
    ],
    [],
  );
  if (user?.is_position_admin) {
    columns.push(
      columnHelper.accessor("shareable_id", {
        header: "Delete",
        cell: ({ row }) => {
          const share = row.original;
          const isSharedItself =
            !doc?.id ||
            (share.shareable_id === doc?.id &&
              share.shareable_type === "App\\Models\\StandardDocument");
          if (isSharedItself) {
            return <DeleteButton deleteFunction={() => deleteShare(share)} />;
          }
        },
      }),
      columnHelper.accessor("shareable_type", {
        header: "Shared By Parent",
        cell: ({ row }) => {
          const share = row.original;
          const isSharedByParent = !(
            (!doc?.id &&
              share.shareable_type !== "App\\Models\\StandardDocument") ||
            (share.shareable_id === doc?.id &&
              share.shareable_type === "App\\Models\\StandardDocument")
          );
          if (isSharedByParent) {
            return <AiOutlineCheck className="tx-22 text-success ms-2" />;
          }
        },
      }),
    );
  }

  const deleteShare = (share: StandardDocumentShare) => {
    return takeAction("destroy", `shared-files/${share.uuid}`)
      .then(() => {
        toast.success(`Sharing updated.`);
        refreshData();
      })
      .catch(errorSwal);
  };

  return (
    <ReactTable
      columns={columns}
      data={StandardDocumentShares}
      extraButtons={
        user?.is_position_admin ? (
          <div className="ms-2 align-self-center mb-3">
            <Button onClick={addNewToggle} color="primary" outline size="md">
              Add Share
            </Button>
          </div>
        ) : undefined
      }
      onRowClick={(share: StandardDocumentShare) => {
        setSelectedFileShare(share);
      }}
      loading={loading}
    />
  );
};

export default FileSharedListTable;
