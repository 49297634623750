import { useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import InformationAlert from "../utils/InformationAlert";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { BiCloudUpload } from "react-icons/bi";
import useS3Uploader from "../hooks/useS3Uploader";
import ProfileButtons from "../utils/ProfileButtons";
import { FiDownload } from "react-icons/fi";
import isSubmitting from "../utils/submitting";
const POLLING_INTERVAL = 2000; // 2 seconds
const MAX_ATTEMPTS = 30;

const ImportExternalTestRequest = () => {
  const [loading, setLoading] = useState(false);

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction: downloadFile, loading: downloading } = useApi();

  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();
  const { takeAction } = useApi();

  const checkForCompletedJob = async (attempt = 0): Promise<string> => {
    try {
      const { data } = await takeAction(
        "show",
        `test-requests/${uuid}/import-external`,
      );

      if (data.data.path === false) {
        throw new Error("Job not completed");
      }

      if (data.data.path) {
        return data.data.path;
      }

      // Optional: prevent infinite polling
      if (attempt >= MAX_ATTEMPTS) {
        throw new Error("Maximum polling attempts reached");
      }

      // Wait 2 seconds before next attempt
      await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
      return checkForCompletedJob(attempt + 1);
    } catch (error) {
      throw error;
    }
  };

  const onDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    setRejectedFiles(fileRejections);
    setLoading(true);

    try {
      await upload(
        acceptedFiles[0],
        `tmp/organisations/test-request-upload/${uuid}`,
        async (file: any) => {
          await takeAction(
            "store",
            `test-requests/${uuid}/import-external`,
            file,
          );
          await checkForCompletedJob();

          toast.success("Equipment Uploaded");
          setUploadPercent(0);
        },
      );
    } catch (err) {
      errorSwal(err);
    } finally {
      setLoading(false);
    }
  };
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      multiple: false,
      onDrop,
      disabled: loading,
    });

  return (
    <div>
      <ProfileButtons
        buttons={[
          {
            disabled: downloading,
            text: isSubmitting(
              downloading,
              <>
                <FiDownload className="tx-18 text-purple me-3" /> Download
                External Test Request
              </>,
              "Generating...",
            ),
            onClick: () => {
              downloadFile("store", `test-requests/${uuid}/generate-external`)
                .then(({ data }: any) => {
                  window.location.href = data.data.link;
                })
                .catch(errorSwal);
            },
          },
        ]}
      />

      <div
        {...getRootProps()}
        className={`tn-300 rounded-lg bg-white ${
          isDragActive ? "bg-gray-100" : ""
        } d-flex align-items-center w-100 h-100 justify-content-center`}
        style={{ minHeight: "7rem", border: "1px dashed #dee2e6" }}
      >
        <input {...getInputProps()} />
        {loading ? (
          <div>
            <i className="fa fa-spinner fa-spin" />
          </div>
        ) : (
          <div>
            <div className="text-center">
              <BiCloudUpload className="tx-24 text-dark" />
            </div>
            <p className="mb-0">Upload External Test Request</p>
          </div>
        )}
      </div>
      {rejectedFiles.length > 0 && (
        <div className="mt-3">
          <InformationAlert
            type="warning"
            title="Files Rejected"
            body={`The following files were rejected: \n\n${rejectedFiles
              .map((file) => file.file.name)
              .join("\n\n")}.\n\nPlease upload a CSV or excel file.`}
          />
        </div>
      )}
    </div>
  );
};

export default ImportExternalTestRequest;
