import React from "react";
import Deliverables from "./Deliverables";
import NotFound from "../404";

export const ShowPage = (props) => {
  switch (props.match.params.page) {
    case "deliverables":
      return <Deliverables {...props} />;
    default:
      return <NotFound />;
  }
};
