import AuthenticatedApp from "./AuthenticatedApp";

import { useAuth } from "./context/auth-context";
import "./scss/boss.scss";
import UnauthenticatedApp from "./UnauthenticatedApp";

const App = (props) => {
  const { user } = useAuth();

  return user ? (
    <AuthenticatedApp {...props} />
  ) : (
    <UnauthenticatedApp {...props} />
  );
};

export default App;
