import HeaderPage from "../../header/HeaderPage";
import { ProfileTab } from "../../tabs/tabTypes";
import { useParams } from "react-router-dom";

export type XeroPayrollProfilePages = "pay-items" | "fields" | "settings";

const XeroPayrollProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const tabs: ProfileTab<XeroPayrollProfilePages>[] = [
    {
      label: "Pay Items",
      link: `/integrations/xero-payroll/${uuid}/pay-items`,
      page: "pay-items",
    },
  ];

  return (
    <>
      <HeaderPage titlePage="Xero Payroll" crumbs={[]} />

      {/* <Profile tabs={} /> */}
    </>
  );
};

export default XeroPayrollProfile;
