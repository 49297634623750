import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getRegisterItems = (
  { pageParam = 1 },
  search?: string,
  uuid?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `registers/${uuid}/items?page=${pageParam}&filter[search]=${search}&paginate=9`,
    )
    .then(({ data }) => data);
};

export default function useRegisterItems(search?: string, uuid?: string) {
  return useInfiniteQuery(
    [`register-items`, uuid, { search }],
    (pageParam) => getRegisterItems(pageParam, search, uuid),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
