import { Field, InjectedFormProps, formValueSelector } from "redux-form";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import FormModal from "../utils/FormModal";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import { useAuth } from "../../context/auth-context";
import { IntegrationType } from "../integrations/integrationTypes";
import { IUseApiWithData } from "../api/apiTypes";
import { ChartOfAccount } from "../chartOfAccounts/chartOfAccountTypes";
import FormHeader from "../utils/FormHeader";
import {
  AveragingMethods,
  DepreciationCalculationMethods,
  DepreciationMethods,
  EquipmentTypeGroup,
} from "./equipmentTypeGroupTypes";
import { connect } from "react-redux";
import { percentage } from "../form/formatters";
import renderToggleInput from "../utils/renderToggleInput";

interface EquipmentTypeGroupModalProps {
  equipmentTypeGroups: EquipmentTypeGroup[];
  isDepreciable?: boolean;
  depreciationRate?: number;
  effectiveLifeYears?: number;
  taxDepreciationRate?: number;
  taxEffectiveLifeYears?: number;
  initialValues?: EquipmentTypeGroup;
  accountingEquipmentTypeGroups: {
    id: string;
    name: string;
  }[];
  taxSettings: {
    tax_depreciation_enabled: boolean;
  };
  depreciationMethod?: DepreciationMethods;
  taxDepreciationMethod?: DepreciationMethods;
}

const Modal = (props: EquipmentTypeGroupModalProps) => {
  const { user } = useAuth();

  const {
    depreciationRate,
    effectiveLifeYears,
    isDepreciable,
    equipmentTypeGroups,
    accountingEquipmentTypeGroups,
    taxSettings,
    taxDepreciationRate,
    taxEffectiveLifeYears,
    depreciationMethod,
    taxDepreciationMethod,
  } = props;

  const depreciationRequired =
    user?.active_organisation.integration_types.includes(
      IntegrationType.Accounting,
    );

  const checkForIntegration =
    !props.initialValues?.accounting_integration_model && depreciationRequired;

  const { data: accounts }: IUseApiWithData<ChartOfAccount[]> = useApi(
    "chart-of-accounts",
    [],
    true,
  );

  const formattedAccounts = accounts?.map((account) => ({
    label: `${account.code} - ${account.name}`,
    value: account.id,
  }));

  const filteredIntegrationGroups = accountingEquipmentTypeGroups
    .filter((group) => {
      return !equipmentTypeGroups
        .filter(
          (group) =>
            group.accounting_integration_model?.external_integration_id,
        )
        .map(
          (group) =>
            group.accounting_integration_model?.external_integration_id,
        )
        .includes(group.id);
    })
    .map((group) => ({
      label: group.name,
      value: group.id,
    }));

  const depreciationDoesntRequireInfo = [
    DepreciationMethods.FullDepreciation,
    DepreciationMethods.NoDepreciation,
  ].includes(depreciationMethod as DepreciationMethods);

  const taxDepreciationDoesntRequireInfo = [
    DepreciationMethods.FullDepreciation,
    DepreciationMethods.NoDepreciation,
  ].includes(taxDepreciationMethod as DepreciationMethods);

  return (
    <FormModal {...props}>
      {({ change }: InjectedFormProps) => {
        return (
          <>
            <div className="col-12 form-group">
              <Field
                component={RenderField}
                name="name"
                label="Name"
                required
                validate={required}
              />
            </div>
            {checkForIntegration && (
              <div className="col-12 form-group">
                <Field
                  component={SelectInput}
                  name="external_integration_id"
                  options={filteredIntegrationGroups}
                  label="Sync with Accounting Software"
                  changeValue={(value?: any) => {
                    if (value) {
                      change("is_depreciable", true);
                      change("name", value.label);
                    }
                  }}
                />
              </div>
            )}
            {user?.active_organisation.integration_types.includes(
              IntegrationType.Accounting,
            ) && (
              <div className="col-12 form-group">
                <Field
                  component={renderToggleInput}
                  name="is_depreciable"
                  label="Is Depreciable"
                />
              </div>
            )}
            {isDepreciable ? (
              <>
                <FormHeader>Fixed Asset Settings</FormHeader>
                <div className="col-lg-4 form-group">
                  <Field
                    component={SelectInput}
                    name="fixed_asset_account_id"
                    options={formattedAccounts}
                    label="Fixed Asset Account"
                    required={depreciationRequired}
                    validate={depreciationRequired ? required : null}
                  />
                </div>
                <div className="col-lg-4 form-group">
                  <Field
                    component={SelectInput}
                    name="depreciation_expense_account_id"
                    options={formattedAccounts}
                    label="Depreciation Expense Account"
                    required={depreciationRequired}
                    validate={depreciationRequired ? required : null}
                  />
                </div>
                <div className="col-lg-4 form-group">
                  <Field
                    component={SelectInput}
                    name="accumulated_depreciation_account_id"
                    options={formattedAccounts}
                    label="Accumulated Depreciation Account"
                    required={depreciationRequired}
                    validate={depreciationRequired ? required : null}
                  />
                </div>

                <FormHeader>Book Depreciation</FormHeader>
                <div className="col-lg-6 form-group">
                  <Field
                    component={SelectInput}
                    name="depreciation_method"
                    options={[
                      {
                        label: "No Depreciation",
                        value: DepreciationMethods.NoDepreciation,
                      },
                      {
                        label: "Straight Line",
                        value: DepreciationMethods.StraightLine,
                      },
                      {
                        label: "Diminishing Value",
                        value: DepreciationMethods.DiminishingValue100,
                      },
                      {
                        label: "Diminishing Value (150%)",
                        value: DepreciationMethods.DiminishingValue150,
                      },
                      {
                        label: "Diminishing Value (200%)",
                        value: DepreciationMethods.DiminishingValue200,
                      },
                      {
                        label: "Full Depreciation at Purchase",
                        value: DepreciationMethods.FullDepreciation,
                      },
                    ]}
                    label="Depreciation Method"
                    required={depreciationRequired}
                    changeValue={(value: { value: DepreciationMethods }) => {
                      if (
                        [
                          DepreciationMethods.FullDepreciation,
                          DepreciationMethods.NoDepreciation,
                        ].includes(value.value)
                      ) {
                        change("effective_life_years", null);
                        change("averaging_method", null);
                        change("depreciation_rate", null);
                        change("depreciation_calculation_method", null);
                      }
                    }}
                    validate={depreciationRequired ? required : null}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    component={SelectInput}
                    name="averaging_method"
                    options={[
                      {
                        label: "Actual Days",
                        value: AveragingMethods.ActualDays,
                      },
                      {
                        label: "Full Month",
                        value: AveragingMethods.FullMonth,
                      },
                    ]}
                    label="Averaging Method"
                    isDisabled={depreciationDoesntRequireInfo}
                    required={
                      depreciationRequired && !depreciationDoesntRequireInfo
                    }
                    validate={
                      depreciationRequired && !depreciationDoesntRequireInfo
                        ? required
                        : null
                    }
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    name="depreciation_rate"
                    label="Rate"
                    {...percentage}
                    extraProps={{
                      disabled:
                        effectiveLifeYears || depreciationDoesntRequireInfo,
                    }}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    name="effective_life_years"
                    label="Effective Life (Years)"
                    extraProps={{
                      disabled:
                        depreciationRate || depreciationDoesntRequireInfo,
                    }}
                  />
                </div>
                <div className="col-lg-12 form-group">
                  <Field
                    component={SelectInput}
                    name="depreciation_calculation_method"
                    options={[
                      {
                        label: "None",
                        value: DepreciationCalculationMethods.None,
                      },
                      {
                        label: "Rate",
                        value: DepreciationCalculationMethods.Rate,
                      },
                      {
                        label: "Life",
                        value: DepreciationCalculationMethods.Life,
                      },
                    ]}
                    label="Depreciation Calculation Method"
                    required={
                      depreciationRequired && !depreciationDoesntRequireInfo
                    }
                    isDisabled={depreciationDoesntRequireInfo}
                    validate={
                      depreciationRequired && !depreciationDoesntRequireInfo
                        ? required
                        : null
                    }
                  />
                </div>
                {taxSettings.tax_depreciation_enabled && (
                  <>
                    <FormHeader>Tax Depreciation</FormHeader>
                    <div className="col-lg-6 form-group">
                      <Field
                        component={SelectInput}
                        name="tax_depreciation_method"
                        options={[
                          {
                            label: "No Depreciation",
                            value: DepreciationMethods.NoDepreciation,
                          },
                          {
                            label: "Straight Line",
                            value: DepreciationMethods.StraightLine,
                          },
                          {
                            label: "Diminishing Value",
                            value: DepreciationMethods.DiminishingValue100,
                          },
                          {
                            label: "Diminishing Value (150%)",
                            value: DepreciationMethods.DiminishingValue150,
                          },
                          {
                            label: "Diminishing Value (200%)",
                            value: DepreciationMethods.DiminishingValue200,
                          },
                          {
                            label: "Full Depreciation at Purchase",
                            value: DepreciationMethods.FullDepreciation,
                          },
                        ]}
                        label="Depreciation Method"
                        required={depreciationRequired}
                        changeValue={(value: {
                          value: DepreciationMethods;
                        }) => {
                          if (
                            [
                              DepreciationMethods.FullDepreciation,
                              DepreciationMethods.NoDepreciation,
                            ].includes(value.value)
                          ) {
                            change("tax_effective_life_years", null);
                            change("tax_averaging_method", null);
                            change("tax_depreciation_rate", null);
                            change("tax_depreciation_calculation_method", null);
                          }
                        }}
                        validate={depreciationRequired ? required : null}
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <Field
                        component={SelectInput}
                        name="tax_averaging_method"
                        options={[
                          {
                            label: "Actual Days",
                            value: AveragingMethods.ActualDays,
                          },
                          {
                            label: "Full Month",
                            value: AveragingMethods.FullMonth,
                          },
                        ]}
                        label="Averaging Method"
                        isDisabled={taxDepreciationDoesntRequireInfo}
                        required={
                          depreciationRequired &&
                          !taxDepreciationDoesntRequireInfo
                        }
                        validate={
                          depreciationRequired &&
                          !taxDepreciationDoesntRequireInfo
                            ? required
                            : null
                        }
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <Field
                        component={RenderField}
                        name="tax_depreciation_rate"
                        label="Rate"
                        {...percentage}
                        extraProps={{
                          disabled:
                            taxEffectiveLifeYears ||
                            taxDepreciationDoesntRequireInfo,
                        }}
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <Field
                        component={RenderField}
                        name="tax_effective_life_years"
                        label="Effective Life (Years)"
                        extraProps={{
                          disabled:
                            taxDepreciationRate ||
                            taxDepreciationDoesntRequireInfo,
                        }}
                      />
                    </div>
                    <div className="col-lg-12 form-group">
                      <Field
                        component={SelectInput}
                        name="tax_depreciation_calculation_method"
                        options={[
                          {
                            label: "None",
                            value: DepreciationCalculationMethods.None,
                          },
                          {
                            label: "Rate",
                            value: DepreciationCalculationMethods.Rate,
                          },
                          {
                            label: "Life",
                            value: DepreciationCalculationMethods.Life,
                          },
                        ]}
                        label="Depreciation Calculation Method"
                        required={
                          depreciationRequired &&
                          !taxDepreciationDoesntRequireInfo
                        }
                        isDisabled={taxDepreciationDoesntRequireInfo}
                        validate={
                          depreciationRequired &&
                          !taxDepreciationDoesntRequireInfo
                            ? required
                            : null
                        }
                      />
                    </div>
                  </>
                )}
              </>
            ) : null}
          </>
        );
      }}
    </FormModal>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    depreciationRate: selector(state, "depreciation_rate"),
    effectiveLifeYears: selector(state, "effective_life_years"),
    taxDepreciationRate: selector(state, "tax_depreciation_rate"),
    taxEffectiveLifeYears: selector(state, "tax_effective_life_years"),
    isDepreciable: selector(state, "is_depreciable"),
    depreciationMethod: selector(state, "depreciation_method"),
    taxDepreciationMethod: selector(state, "tax_depreciation_method"),
  };
};

export default connect(mapStateToProps, {})(Modal);
