import * as _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteCalibrationUnit,
  fetchCalibrationUnits,
  selectCalibrationUnit,
} from "../../actions/calibrationUnitActions";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";

import Spinner from "../utils/Spinner";

class CalibrationUnits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    this.props
      .fetchCalibrationUnits()
      .then(() => this.setState({ loading: false }));
  };

  delete = (price) => {
    const { deleteCalibrationUnit } = this.props;

    return deleteSwal()
      .then(() => {
        this.setState({ deleting: true });
        return deleteCalibrationUnit(price.uuid);
      })
      .then(() => {
        toast.success(`${price.name} Deleted Successfully`);
      })
      .catch((err) => {
        errorSwal(err);
      })
      .then(() => this.setState({ deleting: false }));
  };

  render() {
    const { calibrationUnits, selectCalibrationUnit } = this.props;
    if (this.state.loading) return <Spinner loading />;
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead className="thead-inverse">
            <tr>
              <th>Name</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(calibrationUnits, "name").map((unit) => (
              <tr
                className="pointer-hover"
                onClick={() => selectCalibrationUnit(unit)}
                key={unit.uuid}
              >
                <td scope="row">{unit.name}</td>
                <td>
                  <i
                    onClick={() => this.delete(unit)}
                    className="tx-22 pointer-hover tx-danger icon ion-trash-a"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  calibrationUnits: state.calibrationUnits.items,
});

export default connect(mapStatetoProps, {
  fetchCalibrationUnits,
  deleteCalibrationUnit,
  selectCalibrationUnit,
})(CalibrationUnits);
