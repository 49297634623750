import { Field } from "redux-form";
import TimeZones from "../../data/timezone";
import AddressFields from "../addresses/AddressFields";
import renderField from "../utils/renderField";
import required, { maxLength } from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const length = maxLength(20);

const OrganisationForm = (props) => {
  return (
    <>
      <label className="section-title">Organisation Details</label>
      <hr />
      <div className="row mg-b-25">
        <div className="form-group col-lg-12">
          <Field
            name="trading_name"
            type="text"
            component={renderField}
            label="Trading Name"
          />
        </div>

        <div className="form-group col-lg-6">
          <Field
            validate={required}
            name="contact_email"
            type="email"
            component={renderField}
            label="Primary Contact Email"
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={required}
            name="accounts_email"
            type="email"
            component={renderField}
            label="Accounts Email"
          />
        </div>
        <div className="form-group col-lg-6">
          <Field name="acn" type="text" component={renderField} label="ACN" />
        </div>
        <div className="form-group col-lg-6">
          <Field
            validate={[required, length]}
            name="contact_telephone"
            type="text"
            component={renderField}
            label="Primary Contact Phone"
          />
        </div>
        <div className="form-group col-12">
          <Field
            component={SelectInputAsync}
            name="timezone"
            options={TimeZones}
            validate={required}
            required
            label="Timezone"
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={renderField}
            name="website"
            type="text"
            label="Website"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={renderField}
            name="primary_colour"
            type="color"
            label="Primary Branding Colour"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={renderField}
            name="secondary_colour"
            type="color"
            label="Secondary Branding Colour"
          />
        </div>
        <div className="form-group col-lg-12">
          <Field
            validate={required}
            name="description"
            textarea
            component={renderField}
            label="Description"
          />
        </div>
        <div className="form-group col-lg-12">
          <Field
            name="two_factor_enabled_at"
            type="date"
            component={renderField}
            label="Enable 2 Factor Authentication From"
          />
          <small className="d-block">
            If you set a date here, all users will be required to enable 2
            factor authentication from this date.
          </small>
          <small className="d-block">
            Leave blank to disable 2 factor authentication for this
            organisation.
          </small>
          <small className="d-block">
            We recommend giving your staff at least 2 weeks notice.
          </small>
        </div>
        <AddressFields {...props} postal />

        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </>
  );
};

export default OrganisationForm;
