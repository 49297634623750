import { useParams } from "react-router";
import { toast } from "react-toastify";
import swal from "sweetalert";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import errorSwal from "../utils/errorSwal";

const Invites = () => {
  const { userId } = useParams();
  const {
    data: invites,
    setData: setInvites,
    takeAction,
    loading,
  } = useApi(`user/${userId}/invites`, [], true);

  const actionInvite = (accepted, data) => {
    swal({
      title: `${accepted ? "Accept" : "Deny"} ${data.organisation.name}?`,
      text: "Are you sure you want to take this action.",
      buttons: true,
    })
      .then((confirm) => {
        if (confirm) {
          return takeAction("update", `invite-users/${data.uuid}`, {
            accepted,
          }).then(() => {
            setInvites(invites.filter((i) => i.uuid !== data.uuid));
            toast.success(`Invite ${accepted ? "Accepted" : "Denied"}`);
          });
        }
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "organisation.name",
      header: "Organisation",
    },
    {
      accessorKey: "action",
      header: "Actions",
      cell: (info) => {
        const data = info.row.original;
        return (
          <>
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={() => actionInvite(true, data)}
            >
              Accept
            </button>
            <span className="border-end mx-2" />
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={() => actionInvite(false, data)}
            >
              Deny
            </button>
          </>
        );
      },
    },
  ];

  return <ReactTable data={invites} columns={columns} loading={loading} />;
};

export default Invites;
