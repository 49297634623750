import { isEmpty } from "lodash";

export default function formatAddress(inputAddress) {
  const { line_1, line_2, suburb, state, postcode } = inputAddress;

  let address = "";

  if (isEmpty(inputAddress)) return "Not Provided";

  if (line_1) address += line_1;

  if (line_2) address = address ? `${line_2}/${line_1}` : line_2;

  if (suburb) address += address ? `, ${suburb}` : suburb;

  if (state) address += address ? `, ${state}` : state;

  if (postcode) address += address ? `, ${postcode}` : postcode;

  return address;
}
