import React from "react";
import { Link, useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import HeaderPage from "../header/HeaderPage";
import ReactTable from "../table/ReactTable";
import { Budget } from "./budgetTypes";

const BudgetProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading }: IUseApi<Budget> = useApi(
    `budgets/${uuid}`,
    [],
    true,
  );

  const columns = [
    {
      accessorKey: "name",
      header: "Purchase Item",
      cell: (info: any) => {
        const purchase = info.row.original;
        return <Link to={purchase.link}>{purchase.name}</Link>;
      },
    },
    {
      accessorKey: "cost",
      header: "Cost",
      cell: (info: any) => money.format(info.getValue()) ?? "-",
    },
  ];

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Budget Templates",
            link: "/budget-templates",
          },
          {
            name: data?.template?.description ?? "-",
            link: `/budget-templates/${data?.template?.uuid}/details`,
          },
          {
            name: data?.date_range ?? "-",
            link: "/",
            active: true,
          },
        ]}
        titlePage={`${data?.template?.description} - ${data?.date_range}`}
      />
      <ReactTable
        data={data?.line_items ?? []}
        columns={columns}
        loading={loading}
      />
    </>
  );
};

export default BudgetProfile;
