import { AxiosResponse } from "axios";
import api from "../../api/api";
import { FieldType } from "../customFields/customFieldTypes";
import { Register } from "../registers/registerTypes";
import { UrlValues } from "./../customFields/CustomFieldFilter";
import { FilterType } from "./../customFields/filters";

const branchMapper = {
  "/custom-fields/branches": "branch_id",
  "/custom-fields/tracking-sites": "tracking_site_id",
};

const getRegisterFields = (url: UrlValues): Promise<FilterType[]> => {
  const uuid = url.split("/")[2];

  return api
    .get(`registers/${uuid}`)
    .then(({ data }: AxiosResponse<{ data: Register }>) => {
      const { custom_fields } = data.data;

      return custom_fields
        .filter((field) => {
          return (
            field.type === FieldType.Select &&
            field.field_attributes.url &&
            Object.keys(branchMapper).includes(field.field_attributes.url)
          );
        })
        .map((field) => {
          return {
            label: field.field_attributes.label,
            name: `customFields:${field.id}.customValues,${
              // @ts-ignore
              branchMapper[field.field_attributes.url]
            }`,
          };
        });
    });
};

export default getRegisterFields;
