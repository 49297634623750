import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import CustomFields from "../customFields/CustomFields";
import SelectInput from "../form/SelectInput";
import { Group } from "../groups/groupTypes";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

interface NotificationGroupField {
  label: string;
  value: number;
  group_id: number;
}

export interface CpirTypeFormData {
  name: string;
  risk_matrix_required?: boolean;
  custom_fields: any[];
  notification_groups: NotificationGroupField[];
}
const CpirTypeForm = (
  props: InjectedFormProps<CpirTypeFormData, { showCustomFields?: boolean }> & {
    showCustomFields?: boolean;
  },
) => {
  const { handleSubmit, showCustomFields } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="name"
            label="Name"
            validate={required}
            required
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={renderToggleInput}
            name="risk_matrix_required"
            label="Risk Matrix Required"
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={SelectInput}
            name="notification_groups"
            label="Notify group of CPIR"
            url="/groups?paginate=50"
            formatData={(data: Group[]): NotificationGroupField[] => {
              return data.map((d) => ({
                label: d.name,
                value: d.id,
                group_id: d.id,
              }));
            }}
            isMulti
          />
        </div>
        {showCustomFields && (
          <>
            <FormHeader>CPIR Type Form</FormHeader>
            <div className="col-12 form-group">
              <CustomFields {...props} />
            </div>
          </>
        )}
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};
const form = reduxForm<CpirTypeFormData, { showCustomFields?: boolean }>({
  form: "AddCpirType",
});

export default form(CpirTypeForm);
