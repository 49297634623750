import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { reset } from "redux-form";
import useApi from "../api/useApi.js";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import AddButton from "./AddButton";
import Form from "./Form";

const CategoryTable = (props) => {
  const { loading, categories, setCategories } = props;
  const { takeAction } = useApi();
  const [selectedCategory, setSelectedCategory] = useState();
  const { modal, toggle } = useModal();

  const onDelete = (category) => {
    deleteSwal(
      "",
      `Delete CPIR category "${category.name}"?\n 
       The category will persist in existing CPIRs.`,
    ).then(() => {
      return takeAction("destroy", `cpir-categories/${category.uuid}`)
        .then(() => {
          setCategories(
            categories.filter(({ uuid }) => uuid !== category.uuid),
          );
          toast.success(`${category.name} deleted successfully`);
        })
        .catch(errorSwal);
    });
  };

  const editCategory = (values, dispatch, { form }) => {
    return takeAction("update", `cpir-categories/${values.uuid}`, values)
      .then(({ data }) => {
        toast.success("Category updated successfully");
        setCategories(
          categories.map((c) => (c.uuid === values.uuid ? data.data : c)),
        );
        dispatch(reset(form));
        toggle();
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Category",
      cell: (info) => {
        return (
          <Button
            color="link"
            className="p-0"
            onClick={() => {
              setSelectedCategory(info.row.original);
              toggle();
            }}
          >
            {info.getValue()}
          </Button>
        );
      },
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => (
        <DeleteButton
          deleteFunction={() => {
            onDelete(info.row.original);
          }}
        ></DeleteButton>
      ),
    },
  ];

  return (
    <>
      <AddButton categories={categories} setCategories={setCategories} />
      <ReactTable data={categories} columns={columns} loading={loading} />
      <Form
        initialValues={selectedCategory}
        onSubmit={editCategory}
        form="EditCPIRCategory"
        title={`Edit ${selectedCategory?.name}`}
        modal={modal}
        toggle={toggle}
      />
    </>
  );
};

export default CategoryTable;
