import React, { useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import SearchInput from "../search/SearchInput";
import ListViewButtons from "./ListViewButtons";
import SearchItem from "./SearchItem";
import textIncludes from "./textIncludes";

const DatabaseListView = (props) => {
  const [search, setSearch] = useState("");
  const { name, add, buttons, list } = props;

  const [view, setView] = useState(localStorage.getItem(`boss_${name}_view`));

  const filteredJobs = () => {
    return list.filter((item) => {
      const listItems = Object.values(item.search.list);
      const badges = item.search.badges;
      return (
        textIncludes(item.search.name, search) ||
        listItems.filter((item) => textIncludes(item, search)).length > 0 ||
        (badges &&
          badges.filter((badge) => textIncludes(badge.label, search)).length >
            0)
      );
    });
  };

  const setListView = (type) => {
    localStorage.setItem(`boss_${name}_view`, type);
    setView(type);
  };

  const viewClasses = {
    list: "col-lg-12 text-start",
    tile: "col-lg-4 text-center",
  };

  const orderedJobs = _.orderBy(filteredJobs(), [
    (d) => d.search.name.toLowerCase(),
  ]);

  return (
    <>
      <div className="row mb-3 d-flex align-items-center">
        {(add || buttons) && (
          <div className="col mb-2">
            {add && (
              <Link className="btn btn-outline-primary" to={add.link}>
                Add New {add.name}
              </Link>
            )}
            {buttons?.map((button, index) => button)}
          </div>
        )}
        <div className="col d-none d-lg-flex justify-content-end">
          <ListViewButtons setListView={setListView} {...props} />
        </div>
        <div className="col-12 col-12 text-start">
          <SearchInput
            value={search}
            setValue={(search) => setSearch(search)}
          />
        </div>
      </div>
      <div className="row row-sm">
        {orderedJobs.map((item) => (
          <SearchItem
            key={item.search.id}
            item={item}
            className={viewClasses[view]}
          />
        ))}
      </div>
    </>
  );
};

export default DatabaseListView;
