import React from "react";

const ListViewButtons = ({ setListView }) => {
  return (
    <>
      <a
        href="javascript:void(0)"
        onClick={() => {
          setListView("list");
        }}
        className="pe-3"
      >
        <i className="fa fa-list fa-lg" />
      </a>
      <a
        href="javascript:void(0)"
        onClick={() => {
          setListView("tile");
        }}
      >
        <i className="fa fa-th fa-lg" />
      </a>
    </>
  );
};

export default ListViewButtons;
