import { useParams } from "react-router-dom";
import NotFound from "../404";
import AccessControlList from "../accessControlList/AccessControlList";
import EditPermissionGroup from "./EditPermissionGroup";
import PermissionGroupSubjects from "./PermissionGroupSubjects";
import { PermissionGroupProps, ProfilePages } from "./permissionGroupTypes";
import PositionPermissionGroups from "./PositionPermissionGroups";
import UserPermissionGroups from "./UserPermissionGroups";

const ShowPermissionGroupPage = (props: PermissionGroupProps) => {
  const { permissionGroup, setPermissionGroup } = props;
  const { page } = useParams<{ page: ProfilePages }>();

  switch (page) {
    case "details":
      return <EditPermissionGroup {...props} />;
    case "permissions":
      return (
        <AccessControlList
          subjectType="App\Models\PermissionGroup"
          subject={permissionGroup}
          setSubject={setPermissionGroup}
        />
      );
    case "users":
      return <UserPermissionGroups {...props} />;
    case "positions":
      return <PositionPermissionGroups {...props} />;
    default:
      return <NotFound />;
  }
};

export default ShowPermissionGroupPage;
