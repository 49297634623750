import axios from "../api/api";
import {
  DELETE_DOCKET,
  FETCH_DOCKETS,
  FETCH_DOCKET_PREVIEW,
  GET_JOBS_FOR_DOCKET,
  GET_PROJECTS_FOR_DOCKET,
  NEW_DOCKET,
  UPDATE_DOCKET,
  UPDATE_SIGNATURE,
} from "./types";

export const fetchDockets =
  (id = "") =>
  (dispatch) => {
    return axios
      .get(`/dockets/${id}`)
      .then((response) => response.data.data)
      .then((dockets) =>
        dispatch({
          type: FETCH_DOCKETS,
          payload: dockets,
        }),
      );
  };

export const fetchDocketPreview = (jobs) => (dispatch) => {
  return axios
    .get(`/dockets/preview?jobs=${jobs}`)
    .then((response) => response.data)
    .then((docket) =>
      dispatch({
        type: FETCH_DOCKET_PREVIEW,
        payload: docket,
      }),
    );
};

export const createDocket = (postData) => (dispatch) => {
  return axios.post(`/dockets/`, postData).then((docket) =>
    dispatch({
      type: NEW_DOCKET,
      payload: docket,
    }),
  );
};

export const uploadDocket = (postData) => (dispatch) => {
  return axios.post(`/dockets/upload`, postData).then((docket) =>
    dispatch({
      type: NEW_DOCKET,
      payload: docket,
    }),
  );
};

export const updateDocket = (postData) => (dispatch) => {
  return axios
    .put(`/dockets/${postData.uuid}`, postData)
    .then((response) => response.data)
    .then((docket) =>
      dispatch({
        type: UPDATE_DOCKET,
        payload: docket,
      }),
    );
};

export const updateDocketInvoiced = (uuid, postData) => (dispatch) => {
  return axios
    .put(`/dockets/invoice/${uuid}`, postData)
    .then((response) => response.data)
    .then((docket) =>
      dispatch({
        type: UPDATE_DOCKET,
        payload: docket,
      }),
    );
};

export const deleteDocket = (uuid) => (dispatch) => {
  return axios.delete(`/dockets/${uuid}`).then((docket) =>
    dispatch({
      type: DELETE_DOCKET,
      payload: docket,
    }),
  );
};

export const updateSignature = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SIGNATURE,
    payload: data,
  });
};

export const getDocketJobs = (project) => (dispatch) => {
  return axios
    .get(`/projects/jobs/select/${project}?filter=docketItem`)
    .then(({ data }) =>
      dispatch({
        type: GET_JOBS_FOR_DOCKET,
        payload: data,
      }),
    );
};

export const getDocketProjects = () => (dispatch) => {
  return axios.get(`/projects/list`).then(({ data }) =>
    dispatch({
      type: GET_PROJECTS_FOR_DOCKET,
      payload: data,
    }),
  );
};

export const removeDocketProjects = () => (dispatch) => {
  return dispatch({
    type: GET_PROJECTS_FOR_DOCKET,
    payload: [],
  });
};

export const createNewDocket = (data) => (dispatch) => {
  return axios.post(`/dockets/newstore`, data).then(({ data }) =>
    dispatch({
      type: NEW_DOCKET,
      data: data,
    }),
  );
};

export const uploadNewDocket = (values) => (dispatch) => {
  let data = new FormData();
  data.append("file", values.image, values.image.name);
  data.append("user_id", values.user_id);
  data.append("date", values.date);
  data.append("name", values.name.trim());
  data.append("project_id", values.project_id);
  data.append("branch_id", values.branch_id);
  if (values.notes) data.append("notes", values.notes);
  data.append("overtime", values.overtime ? 1 : 0);
  if (values.jobs) data.append("jobs", JSON.stringify(values.jobs));
  if (values.billable_items)
    data.append("billable_items", JSON.stringify(values.billable_items));
  return axios.post(`/dockets/newstore`, data).then(({ data }) =>
    dispatch({
      type: NEW_DOCKET,
      data: data,
    }),
  );
};
