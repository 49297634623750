import React, { useState } from "react";
import renderField from "../utils/renderField";
import { Field } from "redux-form";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";

const PointFields = ({ index, handleSubmit, updateCompactionPoint, point }) => {
  const [updating, setUpdating] = useState(false);

  const submit = (index) => (values) => {
    const data = values.points[index];
    setUpdating(true);
    return updateCompactionPoint(data)
      .catch((err) => errorSwal(err))
      .then(() => setUpdating(false));
  };

  return (
    <>
      <div className="col-12">
        <h6 className="tx-inverse tx-bold text-center">Point {index + 1}</h6>
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          type="number"
          extraProps={{ step: "0.01" }}
          label="Mass of Sample (g)"
          name={`${point}.mass_of_subsample`}
          onBlur={handleSubmit(submit(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          type="number"
          extraProps={{ step: "0.01" }}
          label="Percent of Water Added (%)"
          name={`${point}.percent_of_water_added`}
          onBlur={handleSubmit(submit(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          label="Mass of Water Added (g)"
          name={`${point}.water_added_grams`}
          readOnly
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          type="number"
          extraProps={{ step: "0.01" }}
          label="Mass of Mould and Wet Soil (g)"
          name={`${point}.mass_of_mould_and_wet_soil`}
          onBlur={handleSubmit(submit(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          type="number"
          extraProps={{ step: "0.01" }}
          label="Mass of Mould (g)"
          name={`${point}.mass_of_mould`}
          onBlur={handleSubmit(submit(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          label="Mass of Soil"
          name={`${point}.mass_of_soil`}
          readOnly
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          type="number"
          extraProps={{ step: "0.01" }}
          label="Volume of Mould (cc)"
          name={`${point}.volume_of_mould`}
          onBlur={handleSubmit(submit(index))}
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          label="Wet Density"
          name={`${point}.wet_density`}
          readOnly
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={renderField}
          label="Converted Wet Density"
          name={`${point}.converted_wet_density`}
          readOnly
        />
      </div>
      {updating && (
        <div className="col-12">
          <CustomScaleLoader>Updating Point {index + 1}...</CustomScaleLoader>
        </div>
      )}
    </>
  );
};

export default PointFields;
