import { useParams } from "react-router";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import { ClientFeedbackTemplateIndex } from "./clientFeedbackTypes";
import Spinner from "../utils/Spinner";
import ShowClientFeedbackTemplatePage from "./ShowClientFeedbackTemplatePage";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import deleteSwal from "../utils/deleteSwal";

const ClientFeedbackTemplateProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data, setData, loading }: IUseApi<ClientFeedbackTemplateIndex> =
    useApi(`client-feedback-templates/${uuid}`, {}, true);

  const { takeAction }: IUseApi<{}, { data: ClientFeedbackTemplateIndex }> =
    useApi();

  const history = useHistory();

  const updateTemplate = (values: any): Promise<void> => {
    return takeAction("update", `client-feedback-templates/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Feedback Updated");
        setData(data.data);
      })
      .catch(errorSwal);
  };

  const deleteTemplate = () => {
    return deleteSwal(template.name)
      .then(() => takeAction("destroy", `client-feedback-templates/${uuid}`))
      .then(() => {
        history.push("/client-feedback-types");
        toast.success("Feedback Deleted");
      })
      .catch(errorSwal);
  };

  const template = data as ClientFeedbackTemplateIndex;

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <HeaderPage crumbs={[]} titlePage={data?.name ?? "-"} />
      <Profile<"details" | "fields">
        header={
          <DashboardHeader>
            <DashboardH3>{data?.name}</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowClientFeedbackTemplatePage
            updateTemplate={updateTemplate}
            template={template}
            setTemplate={setData}
          />
        }
        tabs={[
          {
            icon: "",
            label: "Details",
            link: `/client-feedback-types/${uuid}/details`,
            page: "details",
          },
          {
            icon: "",
            label: "Fields",
            link: `/client-feedback-types/${uuid}/fields`,
            page: "fields",
          },
        ]}
        sideBar={
          <Button
            onClick={deleteTemplate}
            color="danger"
            outline
            block
            className="mt-3"
          >
            Delete
          </Button>
        }
      />
    </>
  );
};

export default ClientFeedbackTemplateProfile;
