import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import Contacts from "../projectContacts/Contacts";

import { useAuth } from "../../context/auth-context";
import ProfileViewsList from "../profileViews/ProfileViewsList";
import Spinner from "../utils/Spinner";
import ProjectHeader from "./Header";
import InfoCard from "./InfoCard";
import ProjectDashboardTabs from "./ProjectDashboardTabs";
import ProjectOverviewDateRange from "./ProjectOverviewDateRange";
import ShowPage from "./ShowPage";
import DeleteProjectButton from "./DeleteProjectButton";
import ForceCloseButton from "./ForceCloseButton";
import { ProjectStatus } from "./projectTypes";
import SharedProjectProfile from "./SharedProjectProfile";
import { money } from "../form/formatters";
import ForceRemoveFromSharepointButton from "./ForceRemoveFromSharepointButton";

const Profile = (props) => {
  const { dateFrom, dateTo, enteredTo } = props;
  const { number, page } = useParams();

  const { user } = useAuth();

  const isAdmin = user.is_position_admin;

  const {
    loading,
    data: project,
    setData: setProject,
    setUrl,
  } = useApi(`projects/${number}`, "", true, "/projects");

  useEffect(() => {
    setUrl(`projects/${number}`);
  }, [number]);

  if (loading) {
    return <Spinner loading />;
  }

  if (user.active_organisation.id !== project.organisation_id) {
    return <SharedProjectProfile project={project} />;
  }

  return (
    <>
      <Spinner loading={loading} />

      <HeaderPage
        titlePage="Project Profile"
        crumbs={[
          { link: "projects", name: "Projects" },
          {
            link: `projects/${project.uuid}`,
            name: project.number,
            active: true,
          },
        ]}
        headTitle={project.full_name}
      />
      <div className="row row-sm justify-content-end">
        <div className="col-lg-4 m-1">
          <ProfileViewsList
            viewable={project}
            viewable_type="App\\Models\\Project"
          />
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-lg-8">
          <ProjectHeader project={project} />
          <ShowPage
            page={page}
            number={number}
            {...props}
            project={project}
            setProject={setProject}
          />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <ProjectDashboardTabs page={page} number={number} project={project} />
          <div className="mt-3">
            <ProjectOverviewDateRange
              show={page === "main"}
              from={dateFrom}
              to={dateTo}
              enteredTo={enteredTo}
              {...props}
            />
          </div>
          {isAdmin && !project.is_template && (
            <>
              <div className="card card-status mb-3">
                <label className="slim-card-title">Budget</label>
                <div className="flex-grow-1">
                  <div className="media-body space-y-3">
                    <div className="d-flex tx-inverse">
                      <p className="me-auto">Budget/Contract Value:</p>
                      <p>{money.format(project.estimated_value)}</p>
                    </div>
                    <div
                      className={`d-flex tx-inverse ${
                        project.estimated_value -
                          project.total_estimated_value <
                        0
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      <p className="me-auto">
                        Total Jobs Budget/Contract Value:
                      </p>
                      <p>{money.format(project.total_estimated_value)}</p>
                    </div>
                  </div>
                </div>
                <label className="slim-card-title mt-4 ">Effort</label>
                <div className="flex-grow-1">
                  <div className="media-body space-y-3">
                    <div className="d-flex tx-inverse">
                      <p className="me-auto">Effort Value:</p>
                      <p>{money.format(project.effort_value)}</p>
                    </div>
                    <div
                      className={`d-flex tx-inverse ${
                        project.estimated_value - project.effort_value < 0
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      <p className="me-auto">Budget Remaining:</p>
                      <p>
                        {" "}
                        {money.format(
                          project.estimated_value - project.effort_value,
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <label className="slim-card-title mt-4 ">Actual</label>
                <div className="flex-grow-1">
                  <div className="media-body space-y-3">
                    <div className="d-flex tx-inverse">
                      <p className="me-auto">Actual Value:</p>
                      <p>{money.format(project.completion_value)}</p>
                    </div>
                    <div
                      className={`d-flex tx-inverse ${
                        project.estimated_value - project.completion_value < 0
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      <p className="me-auto">Budget Remaining:</p>
                      <p>
                        {" "}
                        {money.format(
                          project.estimated_value - project.completion_value,
                        )}
                      </p>
                    </div>
                    {/* <div className="d-flex tx-inverse">
                      <p className="me-auto">Percentage Claimed:</p>
                      <p>% {percentageValue()}</p>
                    </div> */}
                  </div>
                </div>
              </div>
              <InfoCard project={project} />
            </>
          )}
          <DeleteProjectButton project={project} />
          {!project.internal && !project.is_template && (
            <Contacts setProject={setProject} project={project} />
          )}
          <div className="mt-3">
            <SideList
              commentableType="App\Models\Project"
              commentableId={project.id}
            />
          </div>
          {project.status !== ProjectStatus.CLOSED && !project.is_template && (
            <ForceCloseButton />
          )}
          <ForceRemoveFromSharepointButton project={project} />
        </div>
      </div>
    </>
  );
};

export default Profile;
