import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import NewVariableList from "../templates/NewVariableList";
import Spinner from "../utils/Spinner";
import Form from "./Form";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const Edit = () => {
  const { uuid } = useParams();

  const {
    data: template,
    setData: setTemplate,
    takeAction,
    loading,
  } = useApi(`email-templates/${uuid}`, null, true);

  const onSubmit = (values) => {
    return takeAction("update", `email-templates/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Template Updated");
        setTemplate(data.data);
      })
      .catch(errorSwal);
  };

  if (loading && !template) return <Spinner loading={loading} />;

  return (
    <>
      <HeaderPageAdminFilter
        titlePage={`Edit (${template.type_description}) ${template?.name} - ${template.template_label}`}
        crumbs={[
          {
            link: "/email-templates",
            name: "Email Templates",
          },
          {
            link: "/",
            name: template?.name,
            active: true,
          },
        ]}
      />

      <div className="row">
        <div className="col-lg-8">
          <Form
            onSubmit={onSubmit}
            form="EditEmailTemplate"
            selectedTemplate={template?.variable_generator}
            initialValues={template}
          />
        </div>
        <div className="col-lg-4">
          <NewVariableList
            variables={template?.variable_generator?.variables}
          />
        </div>
      </div>
    </>
  );
};

export default Edit;
