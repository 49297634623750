import React from "react";
import TextButton from "../utils/TextButton";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { useParams } from "react-router-dom";

const SyncEstimatedEntity = ({ client, refreshData }: any) => {
  const { uuid } = useParams<{ uuid: string }>();

  const { loading, takeAction } = useApi();

  if (!client.estimated_client_entity?.id) {
    return null;
  }

  return (
    <div className="p-3 bg-white border rounded-lg">
      <p className="text-dark mb-0 tx-16 mb-2">Found Matching Organisation.</p>
      <p className="mb-0">
        This suppliers ABN matches with an organisation in boss.
      </p>
      {loading ? (
        <p className="mb-0">
          Syncing supplier with {client.estimated_client_entity.name}...
        </p>
      ) : (
        <p className="mb-0">
          <TextButton
            disabled={loading}
            onClick={() => {
              return takeAction(
                "store",
                `clients/${uuid}/sync-estimated-entity`,
                {
                  entity_id: client?.estimated_client_entity?.id,
                },
              )
                .then(refreshData)
                .catch(errorSwal);
            }}
          >
            Click here
          </TextButton>{" "}
          if you would to sync supplier with{" "}
          {client?.estimated_client_entity.name}.
        </p>
      )}
    </div>
  );
};

export default SyncEstimatedEntity;
