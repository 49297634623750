import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiFillCopy, AiOutlineDownload } from "react-icons/ai";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import downloadCSV from "../../utils/downloadCsv";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { useEffect, useState } from "react";
import useModal from "../hooks/useModal";
import PasswordModal from "./PasswordModal";

const MfaRecoveryCodes = () => {
  const { user } = useAuth();

  const [showRecoveryCodes, setShowRecoveryCodes] = useState(false);

  const { modal, toggle } = useModal();

  if (!user?.has_mfa) {
    return null;
  }

  return (
    <>
      <div className="bg-white p-3 mb-3 rounded-lg border">
        <div className="">
          <p className="slim-card-title mb-2">Recovery Codes</p>
        </div>
        <RecoveryCodesList showRecoveryCodes={showRecoveryCodes} />
        {!showRecoveryCodes && (
          <>
            <p>
              Recovery codes allow you to access your account if you lose your
              phone.
            </p>
            <Button color="primary" onClick={toggle}>
              Show Recovery Codes
            </Button>
            <PasswordModal
              toggle={toggle}
              modal={modal}
              onSuccess={() => setShowRecoveryCodes(true)}
            />
          </>
        )}
      </div>
    </>
  );
};

const RecoveryCodesList = ({
  showRecoveryCodes,
}: {
  showRecoveryCodes: boolean;
}) => {
  const { user } = useAuth();

  const { response, refreshData, setUrl }: IUseApi = useApi("", []);

  useEffect(() => {
    if (showRecoveryCodes) {
      setUrl("user/two-factor-recovery-codes");
      refreshData();
    }
  }, [user?.has_mfa, showRecoveryCodes]);

  const { takeAction, loading }: IUseApi<{}, { data: string[] }> = useApi();

  const resetCodes = () => {
    takeAction("store", "user/two-factor-recovery-codes")
      .then(() => refreshData())
      .then(() => {
        toast.success("Recovery codes reset.");
      })
      .catch(errorSwal);
  };

  if (!showRecoveryCodes) {
    return null;
  }

  return (
    <>
      <p className="mb-4">
        Store these recovery codes in a safe place. <br /> You can use these one
        time codes to recover your account if you lose your phone.
      </p>
      <ul className="list-group">
        {response?.data?.map((code: string) => {
          return (
            <li key={code} className="list-group-item">
              {code}
            </li>
          );
        })}
      </ul>
      <div className="ms-auto d-flex space-x-3 mt-3">
        <CopyToClipboard
          text={response?.data?.join(", ")}
          options={{ format: "text/plain" }}
          onCopy={() => toast.success("Codes copied to clipboard")}
        >
          <Button outline color="primary" size="sm">
            <AiFillCopy /> Copy
          </Button>
        </CopyToClipboard>
        <Button
          size="sm"
          outline
          color="primary"
          onClick={() =>
            downloadCSV(
              response?.data?.map((d: string) => {
                return {
                  Codes: d,
                };
              }),
              "recovery-codes",
            )
          }
        >
          <AiOutlineDownload /> Download
        </Button>
        <Button
          color="primary"
          className="ms-auto"
          disabled={loading}
          onClick={resetCodes}
        >
          {isSubmitting(
            loading,
            "Reset Recovery Codes",
            "Resetting Recovery Codes...",
          )}
        </Button>
      </div>
    </>
  );
};

export default MfaRecoveryCodes;
