import { toArray } from "./toArray";

const ReminderType = {
  Purchase: 0,
  Project: 1,
  Job: 2,
  Approval: 3,
  PerformanceReview: 4,
  "Staff Performance Review": 5,
};

export const getDescription = (type) => {
  if (type === undefined) {
    return null;
  }
  return Object.keys(ReminderType).find((key) => ReminderType[key] === type);
};

export const reminderTypes = toArray(ReminderType);

export default ReminderType;
