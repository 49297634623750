import React from "react";

const MobileFooter = ({ children }) => (
  <div className="mobile-footer-menu d-lg-none pos-fixed l-0 b-0 w-100">
    <div className="slim-navbar">
      <div className="container">
        <ul
          className="nav flex-nowrap"
          style={{ minHeight: "55px", overflowX: "auto" }}
        >
          {children}
        </ul>
      </div>
    </div>
  </div>
);

export default MobileFooter;
