import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { updateCompactionPoint } from "../../actions/compactionActions";
import MoistureFields from "./MoistureFields";
import PointFields from "./PointFields";
import MoistureData from "./MoistureData";

const PointForm = (props) => {
  const { fields, initialValues } = props;

  return (
    <div className="row flex-nowrap" style={{ overflowX: "auto" }}>
      {fields.map((point, index) => {
        const pointData = initialValues.points[index];
        return (
          <div key={index} className="col-lg-3 col-md-6 col-12">
            <div className="row">
              <PointFields {...props} index={index} point={point} />
              <MoistureFields {...props} index={index} point={point} />
              <MoistureData pointData={pointData} />
              {pointData.dry_density > 0 && (
                <div className="col-12 form-group">
                  <label className="tx-bold tx-inverse">
                    Dry Density (t/m<sup>3</sup>)
                  </label>
                  <p className="tx-inverse">
                    {pointData.dry_density.toFixed(2)}
                  </p>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const selector = formValueSelector("Compaction");

const mapStateToProps = (state) => ({
  balance_id: selector(state, "balance_id"),
  oven_id: selector(state, "oven_id"),
});

export default connect(mapStateToProps, { updateCompactionPoint })(PointForm);
