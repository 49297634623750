import React from "react";
import { User } from "@microsoft/microsoft-graph-types";
import { useParams } from "react-router-dom";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import TextButton from "../../utils/TextButton";
import { MicrosoftUser } from "./UserMicrosoftDetails";
import errorSwal from "../../utils/errorSwal";

const CreateMicrosoftUser = ({
  user,
  setUser,
}: {
  user: MicrosoftUser;
  setUser: (user: MicrosoftUser) => void;
}) => {
  const { userId } = useParams<{ userId: string }>();

  const { takeAction, loading }: IUseApi<{}, { data: User }> = useApi();

  const createUser = () => {
    return takeAction("store", `microsoft/users/sync/${userId}`)
      .then(({ data }) => {
        setUser({
          ...user,
          microsoft_user: data.data,
        });
      })
      .catch(errorSwal);
  };

  if (user.integration || user.microsoft_user) {
    return null;
  }

  return (
    <div className="bg-white rounded shadow-sm border p-3">
      <p>No Microsoft Account found.</p>
      <TextButton disabled={loading} onClick={createUser}>
        Click Here
      </TextButton>{" "}
      to create an account with the email of {user.email}
    </div>
  );
};

export default CreateMicrosoftUser;
