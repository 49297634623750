import { reduxForm, Field } from "redux-form";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { money } from "../form/formatters";

const Edit = (props) => {
  const { handleSubmit, setInformation } = props;
  const { uuid } = useParams();

  const { takeAction } = useApi();

  const submit = (values) => {
    const data = {
      upper_limit: values.upper_limit !== "" ? values.upper_limit : null,
      lower_limit: values.lower_limit !== "" ? values.lower_limit : null,
    };

    return takeAction("update", `required-project-information/${uuid}`, data)
      .then(({ data }) => {
        toast.success("Information Updated");
        setInformation(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="lower_limit"
            label="Lower Limit"
            {...money}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="upper_limit"
            label="Upper Limit"
            {...money}
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "RequiredProjectInformation" });

export default form(Edit);
