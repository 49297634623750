import React from "react";

const PercentAlert = ({ oversizePercentage, className }) => {
  if (!oversizePercentage) return null;
  return (
    <div className="col-12 form-group">
      <div className={`bd-l bd-3 bd-${className} bg-gray-200 pd-x-20 pd-y-25`}>
        <h5 className={`tx-${className}`}>
          Wet Oversize Percentage: <b>{oversizePercentage.toFixed(2)}</b>%
        </h5>
      </div>
    </div>
  );
};

export default PercentAlert;
