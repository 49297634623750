import { useEffect, useState } from "react";
import HeaderPage from "../../header/HeaderPage";
import useApi from "../../api/useApi";
import { IUseApi } from "../../api/apiTypes";
import { SelectOption } from "../../utils/utilTypes";
import errorSwal from "../../utils/errorSwal";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import formError from "../../utils/formError";
import SelectInput from "../../form/SelectInput";
import { IXeroPayrollIntegration } from "./xeroPayrollTypes";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SubmitButton from "../../utils/SubmitButton";

interface FormValues {
  tenant_id: string;
}

const XeroPayrollSuccess = (props: InjectedFormProps<FormValues>) => {
  const { handleSubmit } = props;

  const url = `${window.location.pathname}${window.location.search}`;

  const [tenants, setTenants] = useState<SelectOption[]>([]);
  const [integration, setIntegration] = useState<IXeroPayrollIntegration>();

  const history = useHistory();

  const {
    takeAction,
  }: IUseApi<
    {},
    { data: { tenants: SelectOption[]; integration: IXeroPayrollIntegration } }
  > = useApi();

  const { takeAction: saveTenant }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<FormValues> = (values) => {
    return saveTenant("store", `xero/payroll/tenants`, values)
      .then(() => {
        toast.success("Successfully connected to Xero");
        history.push(`/integrations/xero-payroll/${integration?.uuid}/details`);
      })
      .catch(formError);
  };

  useEffect(() => {
    takeAction("store", url)
      .then(({ data }) => {
        setTenants(data.data.tenants);
        setIntegration(data.data.integration);
      })
      .catch((error) => {
        errorSwal(
          "There was an error connecting to Xero. Please contact support.",
        );
      });
  }, []);

  return (
    <>
      <HeaderPage titlePage="Select Payroll Organisation" crumbs={[]} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-12 form-group">
            <Field
              component={SelectInput}
              name="tenant_id"
              props={{ options: tenants, label: "Select Tenant" }}
            />
          </div>
          <div className="col-lg-12 form-group">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const form = reduxForm<FormValues>({ form: "XeroPayrollSuccess" });

export default form(XeroPayrollSuccess);
