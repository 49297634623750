import React from "react";
import { Progress, UncontrolledTooltip } from "reactstrap";
import { money } from "../form/formatters";
import { percent } from "../home/ProductivityOverview";
import { IProjectManagerProject } from "./projectManagerTypes";

const ProjectManagerProgress = ({
  project,
}: {
  project: IProjectManagerProject;
}) => {
  return (
    <>
      <div className="mb-3">
        <p className="mb-2 ms-auto tx-12">
          <span className="tx-inverse">
            <span
              className="fw-bolder"
              id={`estimated_hours_${project.uuid}`}
            >
              Estimated Hours
            </span>{" "}
            vs{" "}
            <span
              className="fw-bolder"
              id={`actual_hours_${project.uuid}`}
            >
              Actual Hours
            </span>
          </span>{" "}
          | {percent(project.estimated_hours, project.actual_hours)}%
        </p>
        <Progress
          value={percent(project.estimated_hours, project.actual_hours)}
          barClassName="progress-bar-xs"
          color="purple"
        />
      </div>
      <div className="mb-3">
        <p className="mb-2 ms-auto tx-12">
          <span className="tx-inverse">
            <span
              className="fw-bolder"
              id={`invoiced_${project.uuid}`}
            >
              Total Amount Invoiced
            </span>{" "}
            +{" "}
            <span id={`wip_${project.uuid}`} className="fw-bolder">
              Total WIP
            </span>{" "}
            vs{" "}
            <span
              id={`estimated_invoiced_${project.uuid}`}
              className="fw-bolder"
            >
              Estimated Amount Invoiced
            </span>
          </span>{" "}
          |{" "}
          {percent(project.invoiced + project.wip, project.estimated_invoices)}%
        </p>
        <Progress
          value={percent(
            project.invoiced + project.wip,
            project.estimated_invoices,
          )}
          barClassName="progress-bar-xs"
          color="info"
        />
      </div>
      <UncontrolledTooltip target={`estimated_hours_${project.uuid}`}>
        {project.estimated_hours} Estimated Hours
      </UncontrolledTooltip>
      <UncontrolledTooltip target={`actual_hours_${project.uuid}`}>
        {project.actual_hours} Actual Hours
      </UncontrolledTooltip>
      <UncontrolledTooltip target={`invoiced_${project.uuid}`}>
        {money.format(project.invoiced.toFixed())}
      </UncontrolledTooltip>
      <UncontrolledTooltip target={`wip_${project.uuid}`}>
        {money.format(project.wip.toFixed())}
      </UncontrolledTooltip>
      <UncontrolledTooltip target={`estimated_invoiced_${project.uuid}`}>
        {money.format(project.estimated_invoices.toFixed())}
      </UncontrolledTooltip>
    </>
  );
};

export default ProjectManagerProgress;
