import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ProfilePage from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import { SafetyInitiative } from "./safetyInitiative";
import ShowPage from "./ShowPage";

export type SafetyInitiativePages = "details" | "questions";

const Profile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { user } = useAuth();

  const {
    data,
    setData: setSafetyInitiative,
    loading,
    response,
  }: IUseApi<SafetyInitiative> = useApi(
    `safety-initiatives/${uuid}`,
    null,
    true,
    "/safety-initiatives",
  );
  const safetyInitiative = data as SafetyInitiative;

  const tabs: ProfileTab<SafetyInitiativePages>[] = [
    {
      link: `/safety-initiatives/${uuid}/details`,
      icon: "",
      page: "details",
      label: "Details",
    },
    {
      link: `/safety-initiatives/${uuid}/questions`,
      icon: "",
      page: "questions",
      label: "Questions",
    }
  ];

  if (!response || loading) return <Spinner loading />;

  return (
    <>
      <HeaderPageAdminFilter
        titlePage={safetyInitiative?.name}
        crumbs={[
          { name: "Safety Initiatives", link: "/safety-initiatives" },
          { name: safetyInitiative?.name, link: "", active: true },
        ]}
      />

      <ProfilePage
        header={
          <>
            <DashboardHeader>
              <DashboardH3>{safetyInitiative?.name}</DashboardH3>
            </DashboardHeader>
          </>
        }
        content={
          <ShowPage
            safetyInitiative={safetyInitiative}
            setSafetyInitiative={setSafetyInitiative}
          />
        }
        tabs={tabs}
        // sideBar={<DeleteButton />}
      />
    </>
  );
};

export default Profile;
