import React from "react";
import { Field, FormSubmitHandler } from "redux-form";
import SelectInput from "../form/SelectInput";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import { OrgChartPosition } from "./OrgChartPositionTypes";

interface AddChildModalProps {
  toggle: Function;
  modal: boolean;
  position: OrgChartPosition;
  addChild: Function;
}

const AddChildModal = (props: AddChildModalProps) => {
  const { position, modal, toggle, addChild } = props;

  const onSubmit: FormSubmitHandler<{ child: OrgChartPosition }> = (values) =>
    addChild({ ...values.child, parent_id: position.id });

  return (
    <FormModal
      title={`Add Child for ${position.name} - ${position.division.name}`}
      modal={modal}
      onSubmit={onSubmit}
      toggle={toggle}
      form={`AddParent${position.id}`}
      initialValues={position}
    >
      <div className="col-12 form-group">
        <Field
          component={SelectInput}
          name="child"
          label="Child"
          required
          validate={required}
          url="organisation-positions?filter[has_parent]=false"
          formatData={(data: any) =>
            data.map((d: any) => ({
              value: d,
              label: d.division.name.concat(" - ", d.name),
            }))
          }
        />
      </div>
    </FormModal>
  );
};

export default AddChildModal;
