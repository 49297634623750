import { AtomicBlockUtils, EditorState, Modifier } from "draft-js";
import React from "react";
import { BsImage } from "react-icons/bs";
import { useAuth } from "../../context/auth-context";
import { useParams } from "react-router-dom";
import { ISubjectItem } from "./subjectTypes";
import useApi from "../api/useApi";
import { DataResponse, IUseApi } from "../api/apiTypes";
import uploadToS3 from "../upload/uploadToS3";
import errorSwal from "../utils/errorSwal";
import TextButton from "../utils/TextButton";

interface EditorStateProps {
  item: ISubjectItem;
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
}

const EmbedImage = (props: EditorStateProps) => {
  const { item, editorState, setEditorState } = props;
  const { user } = useAuth();
  const { uuid } = useParams<{ uuid: string }>();
  const { takeAction }: IUseApi = useApi();

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    uploadToS3(
      file,
      `organisations/${user?.active_organisation.uuid}/subjects/${uuid}/media`,
    )
      .then((documents) =>
        takeAction("store", `subject-items/${item.uuid}/media`, {
          documents,
        }),
      )
      .then(({ data }: DataResponse<{ link: string }[]>) => {
        const contentState = editorState.getCurrentContent();
        let newEditorState = editorState;

        const document = data.data[0];
        const img = new Image();
        img.onload = () => {
          const contentStateWithEntity = contentState.createEntity(
            "IMAGE",
            "MUTABLE",
            {
              src: document.link,
              width: img.width,
              height: img.height,
            },
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

          const contentStateWithImage = Modifier.applyEntity(
            contentStateWithEntity,
            editorState.getSelection(),
            entityKey,
          );

          newEditorState = AtomicBlockUtils.insertAtomicBlock(
            EditorState.set(editorState, {
              currentContent: contentStateWithImage,
            }),
            entityKey,
            " ",
          );

          setEditorState(newEditorState);
        };
        img.src = document.link;
      })
      .catch(errorSwal);
  };

  return (
    <>
      <TextButton
        onClick={() => {
          document.getElementById(`imageUploadInput_${item.id}`)?.click();
        }}
      >
        <BsImage className="tx-14 text-primary" />
      </TextButton>
      <input
        type="file"
        accept="image/*"
        className="d-none"
        id={`imageUploadInput_${item.id}`}
        onChange={handleImageUpload}
      />
    </>
  );
};

export default EmbedImage;
