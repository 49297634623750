import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getRegisters = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(`registers?page=${pageParam}&filter[search]=${search}&paginate=9`)
    .then(({ data }) => data);
};

export default function useRegisters(search?: string) {
  return useInfiniteQuery(
    ["registers", { search }],
    (pageParam) => getRegisters(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
