import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import DatabaseListView from "../listView/DatabaseListView";
import Spinner from "../utils/Spinner";
import DocsModal from "../utils/DocsModal";

const OrganisationRoles = props => {
  const [organisationRoles, setOrganisationRoles] = useState([]);
  const { takeAction, loading } = useApi();

  useEffect(() => {
    takeAction("index", "organisation-roles").then(({ data }) =>
      setOrganisationRoles(data.data),
    );
  }, []);

  if (loading) return <Spinner loading />;

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/roles/" />
      <HeaderPage
        titlePage="Roles"
        pageDescription={
          <>
            Roles in theBOSSapp are designed to attach required competencies and
            ensure their timely completion and must be associated with an
            existing division and skill category.
          </>
        }
        relatedLinks={[
          { name: "Employment Details", link: "/employment-details" },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisations" },
          { link: "organisation-roles", name: "Roles", active: true },
        ]}
      />
      <DatabaseListView
        list={organisationRoles}
        name="organisationroles"
        add={{ link: "organisation-roles/add", name: "Role" }}
      />
    </>
  );
};

export default OrganisationRoles;
