type User = {
  name: string;
  phone: string;
  email: string;
  organisation: string;
};

function downloadContactCard(user: User) {
  let vCardData =
    "BEGIN:VCARD\n" +
    "VERSION:3.0\n" +
    "N:;" +
    user.name +
    ";;;\n" +
    "FN:" +
    user.name +
    "\n" +
    "ORG:" +
    user.organisation +
    "\n" +
    "TEL;TYPE=CELL:" +
    user.phone +
    "\n" +
    "EMAIL:" +
    user.email +
    "\n" +
    "END:VCARD";

  let blob = new Blob([vCardData], { type: "text/vcard;charset=utf-8;" });

  let link = document.createElement("a");
  document.body.appendChild(link);

  let url = window.URL || window.webkitURL;
  link.href = url.createObjectURL(blob);
  link.download = user.name + ".vcf";

  link.click();
}

export default downloadContactCard;
