import {
  Field,
  FieldArray,
  FieldArrayFieldsProps,
  formValueSelector,
} from "redux-form";
import SelectInput from "../form/SelectInput";
import { connect } from "react-redux";
import useApi from "../api/useApi";
import { useEffect } from "react";
import EquipmentTypeFieldWrapper from "./EquipmentTypeFieldWrapper";

interface EquipmentTypeFieldProps {
  field: string;
  equipmentTypes: any[];
  form: string;
  index: number;
  fields: FieldArrayFieldsProps<{ register_id: number }>;
  worksheets: string[];
}

const EquipmentTypeFields = (
  props: EquipmentTypeFieldProps & { equipmentTypeId?: number },
) => {
  const {
    field,
    equipmentTypes,
    equipmentTypeId,
    fields,
    index,
    worksheets,
    form,
  } = props;

  const { data, setUrl } = useApi("", []);

  useEffect(() => {
    if (equipmentTypeId) {
      setUrl(
        `/custom-fields?model=${encodeURIComponent(
          "App\\Models\\EquipmentType",
        )}&modelId=${equipmentTypeId}`,
      );
    }
  }, [equipmentTypeId]);

  return (
    <>
      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          name={`${field}.sheet_name`}
          options={worksheets.map((worksheet) => ({
            label: worksheet,
            value: worksheet,
          }))}
          label={`Sheet ${index + 1} Name`}
        />
      </div>

      <FieldArray
        name={`${field}.equipment_types`}
        component={EquipmentTypeFieldWrapper}
        equipmentTypes={equipmentTypes}
        form={form}
        index={index}
        parentFields={fields}
      />
    </>
  );
};

const mapStateToProps = (state: any, props: EquipmentTypeFieldProps) => {
  const selector = formValueSelector(props.form);

  return {
    equipmentTypeId: selector(state, `${props.field}.equipment_type_id`),
  };
};

export default connect(mapStateToProps, {})(EquipmentTypeFields);
