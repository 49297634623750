import { FieldArray, InjectedFormProps, reduxForm } from "redux-form";
import SampleFields from "./SampleFields";
import { Sample } from "./testRequestTypes";

interface SampleFormProps {
  samples: Sample[];
}

const SampleForm = (props: InjectedFormProps<SampleFormProps>) => {
  const { handleSubmit, change } = props;

  return (
    <form>
      <FieldArray
        handleSubmit={handleSubmit}
        change={change}
        component={SampleFields}
        name="samples"
      />
    </form>
  );
};

const form = reduxForm<SampleFormProps>({});

export default form(SampleForm);
