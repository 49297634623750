import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getBrandingThemes = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `branding-themes?page=${pageParam}&filter[search]=${search}&paginate=9`,
    )
    .then(({ data }) => data);
};

export default function useBrandingThemes(search?: string) {
  return useInfiniteQuery(
    ["branding-themes", { search }],
    (pageParam) => getBrandingThemes(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
