import { AuthUser } from "./../user/userTypes";
const userHasAccessTo = (user: AuthUser, model: string, method: string) => {
  return (
    user.is_admin ||
    !!user.acl_items.find((item) => {
      return item.model === model && item.method === method;
    })
  );
};

export default userHasAccessTo;
