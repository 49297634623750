import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, UncontrolledTooltip } from "reactstrap";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const GenerateJobButton = ({ calibration, setCalibration }) => {
  const { takeAction, loading } = useApi();

  const { uuid } = useParams();

  const generate = () => {
    takeAction("store", `equipment-calibrations/${uuid}/jobs`)
      .then(({ data }) => {
        toast.success("Job Created");
        setCalibration(data.data);
      })
      .catch(errorSwal);
  };

  if (calibration?.job?.uuid) return null;

  return (
    <>
      <Button
        className="mt-3"
        outline
        block
        id="generateJobExplainer"
        onClick={generate}
        disabled={loading}
      >
        {isSubmitting(loading, "Generate Job", "Generating...")}
      </Button>
      <UncontrolledTooltip placement="right" target="generateJobExplainer">
        Generate a job for the equipment to be able to do it before it is due.
      </UncontrolledTooltip>
    </>
  );
};

export default GenerateJobButton;
