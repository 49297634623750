import React from "react";
import { Field, reduxForm } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

const FolderFields = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          required
          validate={required}
          component={RenderField}
          name="name"
          label="Name"
        />
      </div>
      <SubmitButton {...props} />
    </form>
  );
};

const form = reduxForm({ form: "Folder" });

export default form(FolderFields);
