import * as chartjs from "chart.js";

const moneyOptions: chartjs.ChartOptions<"line"> = {
  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        label: (item: any): string => {
          if (!item.yLabel) {
            return "$ 0";
          }

          return `$ ${parseFloat(item.yLabel.toString()).toFixed(2)}`.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ",",
          );
        },
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (scale, value: number) =>
          `$ ${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      },
    },
  },
};

export const barMoneyOptions: chartjs.ChartOptions<"bar"> = {
  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        label: (item: any): string => {
          if (!item.yLabel) {
            return "$ 0";
          }

          return `$ ${parseFloat(item.yLabel.toString()).toFixed(2)}`.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ",",
          );
        },
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (scale, value: number) =>
          `$ ${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      },
    },
  },
};

export default moneyOptions;
