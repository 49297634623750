import React, { useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
const BetaButton = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <>
      <button
        id="BetaPopover"
        onClick={toggle}
        className="btn btn-link p-0"
        type="button"
      >
        <i className="fa fa-info-circle tx-inverse" />
      </button>
      <Popover
        placement="bottom"
        isOpen={open}
        target="BetaPopover"
        toggle={toggle}
      >
        <PopoverHeader>
          This Feature is Still in a Beta Stage of Development
        </PopoverHeader>
        <PopoverBody>
          <p>
            You are seeing this because your organisation has elected to see
            beta features.
          </p>
          <p>
            There may still be bugs present in this feature. If you find
            anything wrong, please email{" "}
            <a href="mailto:info@thebossapp.com.au">info@thebossapp.com.au</a>.
          </p>{" "}
          <p>
            The functionality of this feature may also change without notice.
          </p>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default BetaButton;
