import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { BiListUl, BiPlus } from "react-icons/bi";
import { TodoTaskList } from "./todoTypes";
import MobileFooter from "../footer/MobileFooter";
import FooterLink from "../footer/FooterLink";
import { BsSun } from "react-icons/bs";

export interface TaskListProps {
  taskLists: TodoTaskList[];
  setTaskLists: (taskLists: TodoTaskList[]) => void;
}

const TaskLists = ({ taskLists, setTaskLists }: TaskListProps) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { takeAction } = useApi();

  const width = taskLists?.length > 4 ? "28%" : null;

  return (
    <>
      <div className="manager-left space-y-3 w-100">
        <nav className="nav">
          <Link
            className={`nav-link ${uuid === "my-day" ? "active" : ""}`}
            to="/to-dos/my-day"
          >
            <div>
              <BsSun className=" me-1" /> My Day
            </div>
          </Link>
        </nav>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            const originalData = taskLists;
            setTaskLists([
              ...taskLists,
              {
                name: formData.get("name")?.toString() ?? "",
              },
            ]);
            const form = e.target as HTMLFormElement;
            form.reset();
            takeAction("store", "to-do-task-lists", {
              name: formData.get("name"),
            })
              .then(({ data }: { data: any }) => {
                setTaskLists([...originalData, data.data]);
              })
              .catch((err: any) => {
                setTaskLists(originalData);
                errorSwal(err);
              });
          }}
        >
          <div className="tn-300 d-flex align-items-center  py-3 px-1 rounded-lg ">
            <BiPlus className="tx-18" />
            <input
              type="text"
              className="w-100 border-0 bg-transparent no-focus"
              placeholder="New task list"
              name="name"
              required
            />
          </div>
        </form>
        <nav className="nav">
          {taskLists.map((taskList: any) => {
            return (
              <Link
                to={taskList.uuid ? `/to-dos/${taskList.uuid}` : ""}
                key={taskList.id}
                className={`nav-link d-flex ${
                  uuid && uuid === taskList.uuid ? "active" : ""
                }`}
              >
                <div>
                  <BiListUl className="tx-20 me-1" /> {taskList.name}{" "}
                </div>
                <span className="ms-auto">
                  {
                    taskList.tasks?.filter((task: any) => !task.completed_at)
                      .length
                  }
                </span>
              </Link>
            );
          })}
        </nav>
      </div>
      <MobileFooter>
        <FooterLink
          /** @ts-ignore */
          width={width}
          active={uuid === "my-day"}
          link="/to-dos/my-day"
          icon="fa-sun-o"
        >
          My Day
        </FooterLink>

        {taskLists.map((taskList) => {
          return (
            <FooterLink
              active={uuid && uuid === taskList.uuid}
              link={taskList.uuid ? `/to-dos/${taskList.uuid}` : ""}
              icon="fa-list-ul"
              /** @ts-ignore */
              width={width}
            >
              {taskList.name}
            </FooterLink>
          );
        })}
      </MobileFooter>
    </>
  );
};

export default TaskLists;
