import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import Form from "./Form";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const Edit = () => {
  const { uuid } = useParams();

  const {
    data: trackingSite,
    setData: setTrackingSite,
    loading,
  } = useApi(`trackingsites/${uuid}`, null, true);

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `trackingsites/${uuid}`, values)
      .then(({ data }) => {
        toast.success(`${data.data.trackingsite.name} Updated`);
        setTrackingSite({
          trackingsite: data.data.trackingsite,
        });
      })
      .catch(errorSwal);
  };

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={trackingSite?.trackingsite?.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "trackingsites", name: "Tracking Sites" },
          {
            link: `trackingsites/${trackingSite?.trackingsite?.uuid}`,
            name: trackingSite?.trackingsite?.name,
            active: true,
          },
        ]}
      />
      <Form
        form="EditTrackingSite"
        initialValues={trackingSite?.trackingsite}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default Edit;
