import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import HeaderPage from "../header/HeaderPage";
import ProfilePage from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import ShowPage from "./ShowPage";

const TicketTypeProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: ticketType,
    setData: setTicketType,
    loading,
    response,
  } = useApi(`ticket-types/${uuid}`);

  const tabs = [
    {
      link: `/ticket-types/${uuid}/details`,
      icon: "",
      page: "details",
      label: "Details",
    }
  ];

  if (loading || !response) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={`Ticket Type - ${ticketType.type}`}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "ticket-types", name: "Ticket Types" },
          {
            link: `ticket-types/${ticketType.uuid}`,
            name: ticketType.type,
            active: true,
          },
        ]}
      />
      <ProfilePage
        header={
          <DashboardHeader>
            <DashboardH3>{ticketType?.name}</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowPage
            ticketType={ticketType}
            setTicketType={setTicketType}
          />
        }
        tabs={tabs}
      />
    </>
  );
};

export default TicketTypeProfile;
