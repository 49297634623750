import { useParams } from "react-router";
import CalibrationTable from "./CalibrationTable";
import PaginatedList from "../pagination/PaginatedList";
import useEquipmentCalibrations from "./hooks/useEquipmentCalibrations";
import { CalibrationType, Equipment } from "../equipment/equipmentTypes";
import FormHeader from "../utils/FormHeader";
import { Button } from "reactstrap";
import { FaEllipsisV } from "react-icons/fa";
import FormModal from "../utils/FormModal";
import { Field, FormSubmitHandler } from "redux-form";
import SelectInput from "../form/SelectInput";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import formError from "../utils/formError";
import { toast } from "react-toastify";

const EquipmentCalibrations = ({
  equipment,
  setEquipment,
}: {
  equipment: Equipment;
  setEquipment: Function;
}) => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    response: users,
  }: IUseApiWithData<{
    data: { value: number; label: string }[];
  }> = useApi("users/staff", {
    data: [],
  });

  return (
    <>
      <FormHeader>Calibrations</FormHeader>
      <div
        className="d-flex flex-nowrap space-x-3 mb-3 custom-scrollbar"
        style={{ overflowX: "auto" }}
      >
        {equipment.type.calibration_types.map((calibrationType) => {
          return (
            <CalibrationManager
              key={calibrationType.uuid}
              equipment={equipment}
              calibrationType={calibrationType}
              setEquipment={setEquipment}
              users={users?.data ?? []}
            />
          );
        })}
      </div>
      <PaginatedList
        listName="equipmentCalibrationsList"
        /** @ts-ignore */
        indexHook={useEquipmentCalibrations}
        indexHookArguments={[uuid]}
        originalFilters={[]}
        list={({ data }: { data?: any[] }) => (
          <div className="col-12">
            {data && data.length > 0 && (
              <CalibrationTable
                calibrations={data}
                deliverables={data}
                useSelected={false}
              />
            )}
          </div>
        )}
      />
    </>
  );
};

const CalibrationManager = ({
  equipment,
  calibrationType,
  users,
  setEquipment,
}: {
  equipment: Equipment;
  calibrationType: CalibrationType;
  users: { value: number; label: string }[];
  setEquipment: Function;
}) => {
  const { toggle, modal } = useModal();

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<{ user_id: number }> = (values) => {
    return takeAction(
      "store",
      `equipment-calibration-managers/${equipment.uuid}`,
      {
        ...values,
        equipment_calibration_type_id: calibrationType.id,
      },
    )
      .then(({ data }) => {
        setEquipment(data.data);
        toggle();
        toast.success("Calibration manager updated");
      })
      .catch(formError);
  };

  const manager = equipment.calibration_managers.find(
    (manager) => manager.equipment_calibration_type_id === calibrationType.id,
  );

  return (
    <div key={calibrationType.uuid} className="col-lg-4 col-md-6 col-12 mb-2">
      <div className="bg-white p-2 rounded-lg shadow-sm border d-flex align-items-center">
        <div>
          <p className="mb-0 text-dark">{calibrationType.procedures}</p>
          <small>
            {manager ? `Manager: ${manager?.user?.name}` : "No Manager"}
          </small>
        </div>
        <Button color="link" className="p-0 ms-auto" onClick={toggle}>
          <FaEllipsisV className="text-secondary" />
        </Button>
      </div>
      <FormModal
        toggle={toggle}
        modal={modal}
        form={`update_calibration_manager_${calibrationType.uuid}`}
        title="Update Calibration Manager"
        onSubmit={onSubmit}
        initialValues={{ user_id: manager?.user_id }}
      >
        <div className="col-12">
          <Field
            component={SelectInput}
            options={users}
            name="user_id"
            label="Manager"
          />
        </div>
      </FormModal>
    </div>
  );
};

export default EquipmentCalibrations;
