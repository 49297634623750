import axios from "axios";
import api from "./api";
import { AuthUser } from "../components/user/userTypes";
export interface ILoginValues {
  password: string;
  email: string;
}

const Axios = axios.create({
  // @ts-ignore
  baseURL: import.meta.env.VITE_APP_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

Axios.defaults.withCredentials = true;

export const login = (
  values: ILoginValues,
): Promise<
  | AuthUser
  | { two_factor: boolean }
  | { two_factor_required: boolean; two_factor_organisation: string }
> => {
  return Axios.get(`sanctum/csrf-cookie`)
    .then(() => api.post(`/auth/login`, values))
    .then(({ data }) => {
      return data.data;
    });
};

export const register = (values: any) => {
  return null;
};

export const getUser = () => api.get("/me").then(({ data }) => data.data);

export const logout = () => api.post("/logout");
