import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const ProjectManagerProjectDropdown = ({
  viewJobs,
  setViewJobs,
}: {
  viewJobs: boolean;
  setViewJobs: Function;
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" className="p-0">
        <FiMoreHorizontal className="tx-18 text-secondary" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setViewJobs(!viewJobs)}>
          View Jobs{" "}
          {viewJobs ? <AiOutlineCheck className="ms-1 tx-success" /> : null}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ProjectManagerProjectDropdown;
