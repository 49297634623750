import { useHistory, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import {
  AccountsPayable,
  InboundAccountsPayable,
  InboundAccountsPayableDocument,
} from "./types";
import Spinner from "../utils/Spinner";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import ShowInboundAccountsPayablePage from "./ShowInboundAccountsPayablePage";
import InboundAccountsPayableForm from "./InboundAccountsPayableForm";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import isSubmitting from "../utils/submitting";
import useModal from "../hooks/useModal";
import FormModal from "../utils/FormModal";
import { Field, FormSubmitHandler } from "redux-form";
import RenderField from "../utils/renderField";

const InboundAccountsPayableProfile = () => {
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const { takeAction, loading: rejecting }: IUseApi = useApi();

  const { toggle, modal } = useModal();

  const history = useHistory();

  const [selectedPayable, setSelectedPayable] = useState<AccountsPayable>();

  //I need to get the from and subject from the url query
  const url = new URLSearchParams(window.location.search);
  const from = url.get("from");
  const subject = url.get("subject");

  const [selectedDocument, setSelectedDocument] =
    useState<InboundAccountsPayableDocument>();

  const { data, loading, refreshData }: IUseApi<InboundAccountsPayable> =
    useApi(
      `inbound-accounts-payable/${uuid}?from=${from}&subject=${subject}`,
      null,
      true,
    );

  useEffect(() => {
    const timer = setInterval(() => {
      if (!data?.documents_analysed) {
        refreshData();
      }
    }, 2000);

    return () => clearInterval(timer);
  }, [data]);

  useEffect(() => {
    if (data && data.documents) {
      setSelectedDocument(data.documents[0]);
    }
  }, [data]);

  if (!data) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <HeaderPage
        titlePage={data.subject}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          {
            link: "/inbound-accounts-payable",
            name: "Inbound Accounts Payable",
          },
          { link: "/", name: data.subject, active: true },
        ]}
      />

      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{data.subject}</DashboardH3>
            <p className="mb-0">Sent from {data.from}</p>
          </DashboardHeader>
        }
        content={
          <ShowInboundAccountsPayablePage
            payable={data}
            selectedPayable={selectedPayable}
            setSelectedPayable={setSelectedPayable}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
          />
        }
        tabs={[
          {
            label: "Details",
            link: `/inbound-accounts-payable/${uuid}/details`,
            page: "details",
          },
          {
            label: "Email",
            link: `/inbound-accounts-payable/${uuid}/email`,
            page: "email",
          },
        ]}
        sideBar={
          <div className="mt-3">
            <Button
              block
              color="danger"
              outline
              disabled={rejecting}
              onClick={() => {
                deleteSwal("", "Are you sure you want to reject this email?")
                  .then(() => {
                    return takeAction(
                      "store",
                      `inbound-accounts-payable/${uuid}/reject`,
                    );
                  })
                  .then(() => {
                    toast.success("Document Rejected");
                    history.push("/inbound-accounts-payable");
                  })
                  .catch(errorSwal);
              }}
            >
              {isSubmitting(rejecting, "Reject", "Rejecting...")}
            </Button>
            <Button
              block
              color="primary"
              className="mt-3"
              outline
              onClick={toggle}
            >
              Reply
            </Button>
            <ReplyModal
              toggle={toggle}
              modal={modal}
              inboundAccountsPayable={data}
            />
            <InboundAccountsPayableForm
              selectedDocument={selectedDocument}
              selectedPayable={selectedPayable}
            />
          </div>
        }
      />
    </>
  );
};

const ReplyModal = ({
  toggle,
  modal,
  inboundAccountsPayable,
}: {
  toggle: () => void;
  modal: boolean;
  inboundAccountsPayable: InboundAccountsPayable;
}) => {
  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<{ messsage: string }> = (values) => {
    return takeAction(
      "store",
      `inbound-accounts-payable/${inboundAccountsPayable.uuid}/reply`,
      values,
    )
      .then(() => {
        toast.success("Reply Sent");
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      form="ReplyToInboundAccountsPayable"
      onSubmit={onSubmit}
      title="Send Reply"
      initialValues={{
        to: inboundAccountsPayable.from,
        message:
          "We were unable to find a Purchase Order for this invoice.\n\nPlease provide us with the following information so we can process this invoice.",
      }}
    >
      <div className="col-12 form-group">
        <Field component={RenderField} name="to" label="To" />
        <Field
          component={RenderField}
          textarea
          name="message"
          label="Message"
        />
      </div>
    </FormModal>
  );
};

export default InboundAccountsPayableProfile;
