import React, { useEffect } from "react";
import { Field } from "redux-form";
import dayjs from "dayjs";
import DateTime, { formatting } from "../../utils/dateTime";
import SubmitButton from "../../utils/SubmitButton";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ClosedProjectModal = (props) => {
  const {
    jobScheduledFinishDate,
    modal,
    toggle,
    change,
    handleSubmit,
    onSubmit,
  } = props;

  useEffect(() => {
    if (modal) {
      change("project_scheduled_finish_date", jobScheduledFinishDate);
    }
  }, [modal]);

  return (
    <Modal
      isOpen={modal}
      className="modal-dialog modal-lg wd-md-600"
      toggle={toggle}
      data-keyboard="false"
    >
      <ModalHeader toggle={toggle}>Reopen closed project?</ModalHeader>

      <ModalBody>
        <p>
          You are about to create a job in a closed project. This will reopen
          the project and set the project scheduled finish date:{" "}
          {dayjs(jobScheduledFinishDate).format("DD/MM/YYYY")}
        </p>
        <Field
          label="Change Project Scheduled Finish Date"
          component={DateTime}
          type="date"
          name="project_scheduled_finish_date"
          {...formatting}
        />
      </ModalBody>

      <ModalFooter>
        <SubmitButton
          {...props}
          save="Create"
          saving="Creating"
          onClick={handleSubmit(onSubmit)}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ClosedProjectModal;
