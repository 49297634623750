import React from "react";
import { Modal } from "reactstrap";

const RightModal = (props) => {
  return (
    <Modal
      {...props}
      isOpen={props.modal}
      toggle={props.toggle}
      className="modal-dialog mg-y-0 w-50 modal-right"
      modalClassName="effect-slide-in-right"
      contentClassName="min-ht-100v"
    >
      {props.children}
    </Modal>
  );
};

export default RightModal;
