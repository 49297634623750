import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const MarkAsBilledButton = ({ receipt, setReceipt }) => {
  const { takeAction, loading } = useApi();

  const update = () => {
    return takeAction("store", "billed-credit-card-receipts", {
      id: receipt.id,
    })
      .then(({ data }) => {
        setReceipt(data.data);
        toast.success(
          `${data.data.name} marked as ${
            data.data.billed_at ? "billed" : "not billed"
          }.`,
        );
      })
      .catch(errorSwal);
  };

  if (!receipt.file?.link || !receipt.date || receipt.billed_at) return null;

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      <li className="nav-item">
        <button
          type="button"
          onClick={update}
          disabled={loading}
          className="pointer-hover w-100 nav-link"
        >
          {isSubmitting(
            loading,
            <>
              <i className="icon ion-checkmark tx-primary" /> Mark as billed
            </>,
            "Marking...",
          )}
        </button>
      </li>
    </ul>
  );
};
export default MarkAsBilledButton;
