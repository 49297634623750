import { Link } from "react-router-dom";

const isExternalLink = (url) => {
  return /^(http|https):\/\/|^www/.test(url);
};

const ApprovalReminder = ({ currentReminder }) => {
  if (isExternalLink(currentReminder.link)) {
    return (
      <a href={currentReminder.link} target="_blank" rel="noreferrer">
        {currentReminder.title}
      </a>
    );
  }

  return (
    <div className="col-12">
      <Link to={currentReminder.link}>{currentReminder.title}</Link>
    </div>
  );
};

export default ApprovalReminder;
