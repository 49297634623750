import useApi from "../api/useApi";
import { Tab } from "../tabs/tabTypes";
import ProductivityForm from "./ProductivityForm";
import { EDIT_TIMESHEET_FORM } from "./TimesheetProfile";
import Tabs from "../tabs/Tabs";
import TestProductivity from "./TestProductivity";

const TimesheetProductivity = (props: any) => {
  const { timesheet } = props;

  const { data } = useApi(
    `users/${timesheet.user_uuid}/status-updates?date=${timesheet.date}`,
    [],
    true,
  );

  const tabs: Tab[] = [
    {
      title: "Productivity",
      component: (
        <ProductivityForm
          {...props}
          initialValues={timesheet}
          userId={timesheet.user_uuid}
          form={EDIT_TIMESHEET_FORM}
          selectedDay={timesheet.date}
        />
      ),
    },
    {
      title: "Test Productivity",
      component: <TestProductivity timesheet={timesheet} productivity={data} />,
    },
  ];

  if (data.length === 0) {
    return (
      <ProductivityForm
        {...props}
        initialValues={timesheet}
        userId={timesheet.user_uuid}
        form={EDIT_TIMESHEET_FORM}
        selectedDay={timesheet.date}
      />
    );
  }

  return <Tabs tabs={tabs} />;
};

export default TimesheetProductivity;
