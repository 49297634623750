import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const UnauthenticatedFallback = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  useEffect(() => {
    localStorage.setItem("return_path", `${pathname}${search}`);
    history.push("/login");
  }, []);

  return <div></div>;
};

export default UnauthenticatedFallback;
