import React from "react";
import HeaderPage from "../header/HeaderPage";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import PaginatedList from "../pagination/PaginatedList";
import { Register } from "./registerTypes";
import useRegisters from "./hooks/useRegisters";
import Avatar from "../utils/Avatar";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import PaginatedCard from "../pagination/PaginatedCard";

const RegistersList = () => {
  return (
    <>
      <HeaderPageAdminFilter titlePage="Custom Registers" crumbs={[]} />

      <PaginatedList
        listName="registersList"
        indexHook={useRegisters}
        originalFilters={[]}
        addLink="/registers/add"
        itemCard={({ item }: { item: Register }) => {
          return (
            <PaginatedCard
              info={[]}
              header={<RegisterHeader register={item} />}
            />
          );
        }}
      />
    </>
  );
};

const RegisterHeader = ({ register }: { register: Register }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={register.name} colour={"info"} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={`register_title_${register.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={`/registers/${register.uuid}/details`}>
              {register.name}
            </Link>
          </p>
          <UncontrolledTooltip
            placement="top-start"
            target={`register_title_${register.uuid}`}
          >
            {register.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default RegistersList;
