import { Field, reset, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import axios from "../api/api";
import SelectInputAsync from "../utils/SelectInputAsync";
import renderField from "../utils/renderField";
import required from "../utils/required";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import { formatNumber } from "../dashboard/OverviewCard";

const PaymentFields = ({
  fields,
  index,
  member,
  itemOptions,
  canAdd,
  initialValues,
  dispatch,
  recurringPurchase,
  setPayment,
  payment,
  item,
  quantity,
}) => {
  const colWidth = `col-lg-${canAdd ? "6" : "8"}`;
  let total;
  let purchasePrice;
  if (item && quantity) {
    purchasePrice = recurringPurchase.prices.find((price) => price.id === item);
    total = purchasePrice.price * quantity;
  }
  const deleteRow = () => {
    const toBeDeleted =
      initialValues && initialValues.items && initialValues.items[index];
    if (toBeDeleted) {
      const item = initialValues.items[index];
      deleteSwal()
        .then(() => axios.delete(`/purchase-items/${item.uuid}`))
        .then(() => {
          toast.success("Delete successful");
          setPayment({
            ...payment,
            items: payment.items.filter((i) => i.uuid !== item.uuid),
          });
        })
        .then(() => {
          dispatch(reset("EditRecurringPaymentPurchase"));
          fields.remove(index);
        })
        .catch(errorSwal);
      return;
    }
    fields.remove(index);
  };

  return (
    <>
      <div className={`${colWidth} form-group`}>
        <Field
          label="Item"
          component={SelectInputAsync}
          options={itemOptions}
          name={`${member}.item`}
          required
          validate={required}
        />
      </div>
      <div className="col-lg-2 form-group">
        <Field
          label="Quantity"
          component={renderField}
          name={`${member}.quantity`}
          type="number"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-2 form-group">
        <label className="form-control-label tx-inverse tx-semibold">
          Total
        </label>
        <div className="form-control bg-transparent bd-0">
          <p className="tx-inverse mb-0">
            ${total ? formatNumber(total) : "0"}
          </p>
        </div>
      </div>
      {canAdd && (
        <div className="col-lg-2 form-group d-flex align-items-end">
          <button
            type="button"
            onClick={deleteRow}
            className="btn btn-outline-danger w-100"
          >
            Delete
          </button>
        </div>
      )}
    </>
  );
};

const selector = formValueSelector("RecurringPayment");

const mapStateToProps = (state, { member }) => ({
  item: selector(state, `${member}.item`),
  quantity: selector(state, `${member}.quantity`),
});

export default connect(mapStateToProps, {})(PaymentFields);
