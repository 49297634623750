import { Link } from "react-router-dom";
import { IProject } from "./projectTypes";
import ReactTable from "../table/ReactTable";

const InterEntityInvoices = ({ project }: { project: IProject }) => {
  const columns = [
    {
      accessorKey: "name",
      header: "Invoice Number",
      cell: (info: any) => {
        const invoice = info.row.original;
        return <Link to={invoice.link}>{invoice.name}</Link>;
      },
    },
  ];

  return <ReactTable data={project.inter_entity_invoices} columns={columns} />;
};

export default InterEntityInvoices;
