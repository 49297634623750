import React, { useState } from "react";
import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import Avatar from "../utils/Avatar";
import InitialAvatar from "../utils/InitialAvatar";
import { ProjectFilters } from "./ProjectManagerDashboard";
import ProjectManagerInvoicedGraph from "./ProjectManagerInvoicedGraph";
import ProjectManagerJobs from "./ProjectManagerJobs";
import ProjectManagerProgress from "./ProjectManagerProgress";
import ProjectManagerProjectDropdown from "./ProjectManagerProjectDropdown";
import { IProjectManagerProject } from "./projectManagerTypes";
import TimeLeftInProject from "./TimeLeftInProject";

interface ProjectManagerProjectProps {
  project: IProjectManagerProject;
  filters: ProjectFilters;
}

const ProjectManagerProject = ({
  project,
  filters,
}: ProjectManagerProjectProps) => {
  const [viewJobs, setViewJobs] = useState<boolean>(false);

  return (
    <div className="col-lg-12 mb-3">
      <div className="bg-white border">
        <div className="d-flex bg-gray-100 border-bottom p-3">
          <div className="d-flex">
            {project.client ? (
              <Link to={project?.client?.link}>
                <Avatar
                  colour={project.status === 1 ? "secondary" : "info"}
                  name={project?.client?.name ?? "Internal"}
                />
              </Link>
            ) : (
              <Avatar
                colour={project.status === 1 ? "secondary" : "info"}
                name="Internal"
              />
            )}
            <div>
              <Link
                to={project.link}
                className="mb-0 tx-inverse fw-bold"
              >
                {project.name}
              </Link>
              <p className="mb-0 tx-12">{project.number}</p>
            </div>
          </div>
          <div className="ms-auto">
            <ProjectManagerProjectDropdown
              viewJobs={viewJobs}
              setViewJobs={setViewJobs}
            />
          </div>
        </div>
        <div className="p-3">
          <p className="mb-0 my-3 white-space tx-inverse">
            {project.description}
          </p>
          <ProjectManagerInvoicedGraph project={project} />
          <div className="d-flex mb-3">
            <p className="mb-0 tx-secondary">
              <TiTick /> {project.incomplete_jobs_count} of {project.jobs_count}{" "}
              Job{project.jobs_count === 1 ? "" : "s"} Incomplete
            </p>
          </div>
          <ProjectManagerProgress project={project} />
          <div className="d-flex">
            {project.team_members.map((member) => {
              return (
                <React.Fragment key={member.uuid}>
                  <a href={`mailto:${member.email}`}>
                    <InitialAvatar
                      userName={member.name}
                      key={member.uuid}
                      size={24}
                      fontSize={12}
                      id={`team_member_${project.uuid}_${member.uuid}`}
                    />
                  </a>
                  <UncontrolledTooltip
                    target={`team_member_${project.uuid}_${member.uuid}`}
                  >
                    {member.name}
                  </UncontrolledTooltip>
                </React.Fragment>
              );
            })}
            <div className="ms-auto">
              <TimeLeftInProject project={project} />
            </div>
          </div>
          <ProjectManagerJobs
            viewJobs={viewJobs}
            project={project}
            projectFilters={filters}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectManagerProject;
