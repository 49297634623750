import { Badge } from "reactstrap";
import getInvoiceDetails from "./getInvoiceDetails";

const InvoiceBadge = ({ invoice }) => {
  const details = getInvoiceDetails(invoice);

  return (
    <Badge className="rounded-pill shadow" color={details.color}>
      {details.text}
    </Badge>
  );
};

export default InvoiceBadge;
