import NotFound from "../404";
import { useParams } from "react-router-dom";
import Edit from "./Edit";
import EquipmentCalibrationTypes from "../equipmentCalibrationTypes/EquipmentCalibrationTypes";
import { useAuth } from "../../context/auth-context";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import ProfileForm from "../customFields/ProfileForm";

const ShowPage = (props) => {
  const { page, uuid } = useParams();
  const { user } = useAuth();

  const { equipmentType, setEquipmentType } = props;

  switch (page) {
    case "details":
      return <Edit {...props} />;
    case "calibrations":
      return <EquipmentCalibrationTypes {...props} />;
    case "documents":
      return (
        <NewProfileDocuments
          {...props}
          model="App\Models\EquipmentType"
          documentableId={props.equipmentType.uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/equipment-types/${uuid}`}
          homeUrl={`equipment-types/${uuid}/documents`}
        />
      );
    case "fields":
      return (
        <ProfileForm
          initialValues={equipmentType}
          {...props}
          modelId={equipmentType.id}
          modelType="App\Models\EquipmentType"
          onSuccess={(data) =>
            setEquipmentType({
              ...equipmentType,
              custom_fields: data.data,
            })
          }
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
