import React from "react";
import { Button } from "reactstrap";
import useModal from "../../hooks/useModal";
import MarkAsUnderstoodModal from "./MarkAsUnderstoodModal";

const MarkAsUnderstoodButton = ({
  toMarkAsUnderstood,
  understandableType,
  understandableId,
  onComplete,
}: {
  toMarkAsUnderstood: boolean;
  understandableType: string;
  understandableId: number;
  onComplete: Function;
}) => {
  const { toggle, modal } = useModal();

  if (!toMarkAsUnderstood) {
    return null;
  }

  return (
    <>
      <Button onClick={toggle} color="link">
        Mark as Understood
      </Button>
      <MarkAsUnderstoodModal
        toggle={toggle}
        modal={modal}
        understandableType={understandableType}
        understandableId={understandableId}
        onComplete={onComplete}
      />
    </>
  );
};

export default MarkAsUnderstoodButton;
