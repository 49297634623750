import React from "react";
import _ from "lodash";
import { Badge } from "reactstrap";
import ManagerLeftTabs from "../utils/ManagerLeftTabs";

const StatusTabs = ({ jobs, filters, toggleStatuses }) => {
  if (jobs.length === 0) {
    return null;
  }

  const statuses = _.countBy(jobs, "status");
  const items = Object.entries(statuses).map((val) => {
    const status = parseInt(val[0]);

    const isFiltered = filters.statuses.includes(status);
    return {
      value: status,
      count: val[1],
      ...jobs.find((job) => job.status == status).status_badge,
      countDisplay: (item) => (
        <Badge className="shadow" color={item.color}>
          {item.count}
        </Badge>
      ),
      onClick: () => toggleStatuses(status),
      className: isFiltered ? "active" : "",
    };
  });

  return <ManagerLeftTabs label="Status" items={items} />;
};

export default StatusTabs;
