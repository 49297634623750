import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoPersonAddOutline } from "react-icons/io5";
import { Spinner } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import DateRangePicker from "../home/DateRangePicker";

interface ClientsWonResponse {
  client_count: number;
  total_invoiced: number;
}

const ClientsWon = ({ userUuid }: { userUuid?: string }) => {
  const [range, setRange] = useState<[Dayjs | undefined, Dayjs | undefined]>([
    dayjs().subtract(1, "Q").endOf("Q"),
    dayjs().subtract(1, "Q").startOf("Q"),
  ]);

  const dateRange =
    range[0] && range[1]
      ? `dateRange[]=${range[0].format(
          "YYYY-MM-DD",
        )}&dateRange[]=${range[1].format("YYYY-MM-DD")}`
      : "";

  useEffect(() => {
    if (dateRange) {
      setUrl(`clients-won${userUuid ? `/${userUuid}` : ""}?${dateRange}`);
    }
  }, [range]);

  const { data, loading, setUrl }: IUseApi<ClientsWonResponse> = useApi();

  return (
    <div className="mt-3 position-relative">
      <div className="card p-4 h-100 d-flex">
        {loading && (
          <div className="z-index-10 t-0 l-0 h-100 w-100 d-flex align-items-center justify-content-center position-absolute bg-white-4">
            <Spinner color="primary" />
          </div>
        )}
        <div className="mb-3 d-flex">
          <div>
            <h6 className="slim-card-title tx-teal mb-0">Clients Won</h6>
            <small>A count of clients won and total amount invoiced.</small>
          </div>
          <DateRangePicker range={range} setRange={setRange} />
        </div>
        <div className="d-flex flex-column mt-5 h-100 justify-content-between">
          <div className="d-flex align-items-center">
            <IoPersonAddOutline className="me-3 tx-24 tx-teal" />
            <div>
              <p className="mb-0 tx-12">Clients Won</p>
              <p className="mb-0 tx-18 fw-bolder tx-inverse">
                {data?.client_count}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <FaRegMoneyBillAlt className="me-3 tx-24 tx-teal" />
            <div>
              <p className="mb-0 tx-12">Total Amount Invoiced</p>
              <p className="mb-0 tx-18 fw-bolder tx-inverse">
                {money.format(data?.total_invoiced ?? 0)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsWon;
