import _ from "lodash";
import React from "react";
import PreviewModal from "../documents/PreviewModal";
import useModal from "../hooks/useModal";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import ExternalSharedDocumentListItem from "./ExternalSharedDocumentListItem";

const ExternalSharedStandardDocumentList = ({
  documents,
  selectedFolder,
  setSelectedFolder,
}) => {
  const { toggle, modal } = useModal();
  const naturallyOrdered = documents
    .slice()
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true, base: true }),
    );
  const orderedDocuments = _.orderBy(naturallyOrdered, ["is_folder"], ["desc"]);
  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(orderedDocuments);

  if (documents.length === 0) return null;

  return (
    <>
      <div className="mg-t-10" id="docList">
        <div className="file-group">
          {orderedDocuments.map((doc) => (
            <ExternalSharedDocumentListItem
              key={doc.uuid}
              doc={doc}
              toggle={toggle}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              setSelectedDocument={setSelectedDocument}
            />
          ))}
        </div>
      </div>
      <PreviewModal
        document={selectedDocument}
        modal={modal}
        toggle={toggle}
        changeDocument={changeDocument}
      />
    </>
  );
};

export default ExternalSharedStandardDocumentList;
