import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetForm } from "../../../actions/utilActions";

import HeaderPage from "../../header/HeaderPage";

import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import generateIcs from "../../utils/generateIcs";
import Spinner from "../../utils/Spinner";
import ShowResidentialSiteInspection from "./ShowResidentialSiteInspection";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { BsDownload } from "react-icons/bs";

const AddSiteInspection = (props) => {
  const [page, setPage] = useState(1);

  const { uuid } = useParams();
  const history = useHistory();

  const { takeAction } = useApi();

  const {
    data: siteInspection,
    loading,
    setData: setSiteInspection,
  } = useApi(`siteinspections/residential/${uuid}`, null, true);

  const submit = (values) => {
    values.uuid = values.general.uuid;
    return takeAction("update", `siteinspections/residential/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Site Inspection Updated!");
        history.push(
          `/deliverables/${data.siteInspection.deliverable.uuid}/deliverable`,
        );
      })
      .catch(errorSwal);
  };

  const nextPage = () => setPage(page + 1);

  const previousPage = () => setPage(page - 1);

  useEffect(() => {
    return () => props.resetForm("addResidentialSiteInspection");
  }, []);

  if (loading) {
    return <Spinner loading />;
  }

  const icsValues = {
    dtStart: new Date(siteInspection.general.scheduled_at),
    dtEnd: new Date(siteInspection.general.scheduled_finish),
    summary: siteInspection.name,
    description: `See https://app.thebossapp.com.au/siteinspections/residential/${siteInspection.general.uuid} for more information`,
  };

  return (
    <>
      <HeaderPage
        titlePage={siteInspection.name}
        crumbs={[
          { link: "/jobs", name: "Jobs" },
          { link: "", name: siteInspection.name, active: true },
        ]}
      />
      <div className="d-flex justify-content-end mg-b-10">
        {siteInspection.deliverable && (
          <Link
            to={`/deliverables/${siteInspection.deliverable.uuid}/deliverable`}
            className="btn btn-link"
          >
            View Deliverable
          </Link>
        )}
        <Button
          onClick={() => generateIcs(`${siteInspection.name}.ics`, icsValues)}
          color="primary"
          outline
          size="sm"
          className="d-flex align-items-center justify-content-center"
        >
          <BsDownload className="me-2" /> Download Calender Event
        </Button>
      </div>
      <div className="bg-white p-4 rounded-lg border">
        <ShowResidentialSiteInspection
          pageNumber={page}
          nextPage={nextPage}
          previousPage={previousPage}
          submit={submit}
          siteInspection={siteInspection}
          setSiteInspection={setSiteInspection}
        />
      </div>
    </>
  );
};

export default connect(null, {
  resetForm,
})(AddSiteInspection);
