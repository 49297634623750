import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import useModal from "../../hooks/useModal";
import { CustomJobTemplate } from "../customJobTypes";
import { CustomJobJob } from "./customJobTypes";
import TemplateModal from "./TemplateModal";

const CreateJobFromTemplate = ({ job }: { job?: CustomJobJob }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<CustomJobTemplate>();

  const { modal, toggle } = useModal();

  if (!job) {
    return null;
  }

  if (job.custom_job_templates === null || job.custom_job_templates.length === 0) {
    return null;
  }

  return (
    <li className="nav-item">
      <UncontrolledDropdown type="button">
        <DropdownToggle caret className="w-100 nav-link">
          Create Job
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Choose Template</DropdownItem>
          {job.custom_job_templates.map((template) => {
            return (
              <DropdownItem
                key={template.uuid}
                onClick={() => {
                  setSelectedTemplate(template);
                  toggle();
                }}
              >
                {template.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
      <TemplateModal
        toggle={toggle}
        modal={modal}
        job={job}
        customJobTemplate={selectedTemplate}
      />
    </li>
  );
};

export default CreateJobFromTemplate;
