import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import CalibrationTrackingForm from "./AddCalibrationTracking";
import CalibrationTrackingTable from "./CalibrationTrackingTable";

const CalibrationTracking = (props) => {
  const { uuid } = useParams();

  const {
    data: calibrationTracking,
    loading,
    setData: setCalibrationTracking,
  } = useApi(`equipment/${uuid}/calibration-tracking`, [], true);

  const [selectedItem, setSelectedItem] = useState();

  const selectItem = (item) => {
    let newItem = item;

    if (item.uuid === selectedItem?.uuid) newItem = "";

    setSelectedItem(newItem);
  };

  if (loading) {
    return (
      <CustomScaleLoader>
        Fetching calibration tracking information...
      </CustomScaleLoader>
    );
  }

  return (
    <>
      <CalibrationTrackingForm
        initialValues={selectedItem ?? {}}
        selectItem={selectItem}
        calibrationTracking={calibrationTracking}
        setCalibrationTracking={setCalibrationTracking}
        {...props}
      />
      <CalibrationTrackingTable
        selectItem={selectItem}
        {...props}
        selectedItem={selectedItem}
        setCalibrationTracking={setCalibrationTracking}
        calibrationTracking={calibrationTracking}
      />
    </>
  );
};

export default CalibrationTracking;
