import _ from "lodash";
import { useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { getHours } from "./functions/timesheetFunctions";
import ProductivityBarChart from "./ProductivityBarChart";
import { EDIT_TIMESHEET_FORM } from "./TimesheetProfile";

const HourCard = (props) => {
  const [showProjectProductivity, setShowProjectProductivity] = useState(false);
  const { startTime, finishTime, nightShift, unpaidBreak } = props;

  const hours = getHours(startTime, finishTime, nightShift, unpaidBreak);

  function formatPerProjectProductivity() {
    const productivityGroupedByProject = Object.values(
      _.groupBy(props.timesheet.productivity, "project_id"),
    );
    const formatedProjectProductivity = productivityGroupedByProject.map(
      (projectProductivity) => {
        const project_name = projectProductivity[0].project_name;
        return {
          time_in_hours: _.sumBy(projectProductivity, "time_in_hours"),
          project_name: project_name,
        };
      },
    );
    return formatedProjectProductivity;
  }

  const productivityBarChartData = showProjectProductivity
    ? formatPerProjectProductivity()
    : props.timesheet.productivity;

  return (
    <div className={`card card-sales ${props.className}`}>
      <h6 className="slim-card-title tx-success">Hours</h6>
      <div className="row">
        <div className="col">
          <label className="tx-12">Timesheet</label>
          <p>{hours}</p>
        </div>
        <div className="col">
          <label className="tx-12">Productivity</label>
          <p>{props.timesheet.total_productivity_hours}</p>
        </div>
      </div>
      {props.timesheet.productivity.length > 0 && (
        <>
          <ProductivityBarChart
            {...props}
            productivity={productivityBarChartData}
            label={showProjectProductivity ? "project_name" : "job_name"}
          />
          <button
            className="btn btn-outline-primary w-100 mt-3"
            onClick={() => setShowProjectProductivity(!showProjectProductivity)}
          >
            {showProjectProductivity
              ? "Show Job Productivity"
              : "Show Project Productivity"}
          </button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const selector = formValueSelector(EDIT_TIMESHEET_FORM);

  return {
    startTime: selector(state, "start_time"),
    finishTime: selector(state, "finish_time"),
    nightShift: selector(state, "night_shift"),
    unpaidBreak: selector(state, "unpaid_break"),
  };
};

export default connect(mapStateToProps, {})(HourCard);
