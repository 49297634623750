import React from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import FormTicketType from "./Form";
import DocsModal from "../utils/DocsModal";
import { TicketType } from "./ticketType";

const AddTicketType = (props : any) => {
  const { history } = props;
  const { takeAction } = useApi();

  const onSubmit = (values : TicketType) => {
    return takeAction("store", "ticket-types", values)
      .then(({ data } : { data: any }) => {
        toast.success("Ticket type created successfully!");
        history.push(`/ticket-types/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Ticket Type"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "ticket-types", name: "Ticket Types" },
          {
            link: "ticket-types/add",
            name: "New ticket Type",
            active: true,
          },
        ]}
      />
      <FormTicketType
        onSubmit={onSubmit}
        {...props}
      />
    </>
  );
};

export default AddTicketType;
