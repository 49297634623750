import React, { Fragment } from "react";
import FieldInformationPopOver from "./FieldInformationPopOver";

const buttonGroup = (props) => {
  const {
    input: { value, onChange },
    options,
    label,
    meta: { touched, error },
    groupClass,
    buttonClass,
    onClick,
    input,
    information,
  } = props;

  return (
    <Fragment>
      <label className="form-control-label tx-inverse tx-semibold d-block">
        {label}
        <FieldInformationPopOver input={input} information={information} />
      </label>
      <div className={`btn-group ${groupClass}`} role="group">
        {options.map((option) => (
          <button
            type="button"
            key={option.value}
            onClick={() => {
              value === option.value ? onChange(null) : onChange(option.value);
              if (onClick)
                value === option.value ? onClick(null) : onClick(option.value);
            }}
            className={`btn ${buttonClass} pd-x-25 ${
              value === option.value
                ? "btn-primary active"
                : "btn-outline-primary"
            }`}
          >
            {option.label}
          </button>
        ))}
      </div>
      {touched && error && (
        <span className={error ? "parsley-errors-list" : ""}>{error}</span>
      )}
    </Fragment>
  );
};

export default buttonGroup;
