import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { formValueSelector } from "redux-form";

const ToggleAll = ({
  selectedTrackingSites,
  formattedTrackingSites,
  change,
}) => {
  const allTrackingSites = formattedTrackingSites.map(({ value }) => value);

  const allSelected =
    selectedTrackingSites?.length > 0 &&
    _.xor(allTrackingSites, selectedTrackingSites).length === 0;

  return (
    <Button
      color="primary"
      outline
      className="ms-auto"
      onClick={() => {
        change("tracking_sites", allSelected ? [] : allTrackingSites);
      }}
    >
      {allSelected ? "Remove All" : "Select All"}
    </Button>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    selectedTrackingSites: selector(state, "tracking_sites"),
  };
};

export default connect(mapStateToProps, {})(ToggleAll);
