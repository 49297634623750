import { Link, useParams } from "react-router-dom";
import { ButtonGroup } from "reactstrap";

const RequestLinks = ({ user, className = "" }) => {
  const { page } = useParams();

  if (page !== "timesheets") {
    return null;
  }

  return (
    <ButtonGroup className={`w-100 ${className}`}>
      <Link
        to={`/users/${user.id}/leave/request`}
        className="btn btn-outline-dark w-50 mt-3 text-truncate"
      >
        Request Leave
      </Link>
    </ButtonGroup>
  );
};

export default RequestLinks;
