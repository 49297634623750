import React from "react";
import { Field } from "redux-form";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import { SelectOption } from "../utils/utilTypes";

const CustomJobTemplateFields = ({
  change,
}: {
  change: (field: string, value: any) => void;
}) => {
  return (
    <>
      <div className="col-12 form-group">
        <Field
          component={RenderField}
          name="name"
          label="Name"
          validate={required}
          required
        />
        <small className="d-block">
          You are able to use variables from this custom job to put in the name.
        </small>
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="estimated_hours"
          label="Estimated Hours"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="estimated_value"
          label="Estimated Value"
          required
          validate={required}
        />
      </div>
      <div className="form-group col-12">
        <Field
          label="Job Type"
          name="job_type"
          url="/jobtypes/types?filter[]=JobTypes&filter[]=CustomJobs"
          required
          validate={required}
          component={SelectInputAsync}
          onChangeValue={({ type, id }: { type: string; id: number }) => {
            change("job_type_type", type);
            change("job_type_id", id);
          }}
        />
      </div>
      <div className="col-12 form-group">
        <Field
          name="description"
          label="Notes"
          textarea
          rows={3}
          cols={64}
          required
          validate={required}
          component={RenderField}
        />
        <small className="d-block">
          You are able to use variables from this custom job to put in the
          description.
        </small>
      </div>
      <div className="col-lg-6 form-group">
        <Field
          name="length"
          component={RenderField}
          label="Length Of Job"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          name="length_units"
          component={SelectInput}
          label="Length of Job Units"
          url="/enums?enum=TimeUnitType"
          formatData={formatData}
          required
          validate={required}
        />
      </div>
    </>
  );
};

const formatData = (data: SelectOption[]): SelectOption[] => {
  return data
    .filter((d) => d.label !== "Initial")
    .map((d) => {
      return {
        ...d,
      };
    });
};

export default CustomJobTemplateFields;
