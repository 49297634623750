import { AiOutlineClockCircle } from "react-icons/ai";
import {
  Badge,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import dayjs from "dayjs";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { primary } from "../utils/Colours";

const DateFilter = (props: {
  selectedDay: Date | undefined;
  setSelectedDay: (day: Date | undefined) => void;
}) => {
  const { selectedDay, setSelectedDay } = props;

  return (
    <UncontrolledDropdown className={`ms-2`}>
      <DropdownToggle
        caret
        style={{ height: "20px" }}
        className="p-0 "
        size="sm"
        color="link"
      >
        <AiOutlineClockCircle className="tx-16" />
        <Badge color="" className="p-1 text-black">
          {selectedDay ? 1 : null}
        </Badge>
      </DropdownToggle>

      <DropdownMenu className="py-0">
        <div className="px-3 pt-3">
          <style>{modifiersStyles}</style>
          <DayPicker
            className="Range"
            mode="single"
            selected={selectedDay}
            onSelect={setSelectedDay}
          />
        </div>
        <button
          type="button"
          className="bg-light border-0 w-100 border-top text-secondary rounded-bottom p-3 mt-3"
          onClick={() => setSelectedDay(dayjs().subtract(1, "day").toDate())}
        >
          Yesterday
        </button>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const modifiersStyles = `.DayPicker-Day--selected {
    color: white !important;
    background-color: ${primary} !important;
    border-radius:0  !important;
  }`;

export default DateFilter;
