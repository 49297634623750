import { InjectedFormProps, reduxForm } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import MoistureFields from "./MoistureFields";

const MoistureForm = (props: InjectedFormProps) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <MoistureFields {...props} />
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({});

export default form(MoistureForm);
