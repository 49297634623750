import React from "react";
import ProfilePage from "../profile";
import useApi from "../api/useApi";
import { useParams, useHistory } from "react-router-dom";
import Spinner from "../utils/Spinner";
import HeaderPage from "../header/HeaderPage";
import { DashboardHeader, DashboardH3 } from "../utils/DashboardHeader";
import ShowPage from "./ShowPage";
import { Button } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { toast } from "react-toastify";

const Profile = () => {
  const { uuid } = useParams();
  const history = useHistory();

  const {
    data: information,
    setData: setInformation,
    response,
    loading,
    takeAction,
  } = useApi(`required-project-information/${uuid}`);

  const tabs = [
    {
      link: `/required-project-information/${uuid}/details`,
      icon: "",
      page: "details",
      label: "Details",
    },
    {
      link: `/required-project-information/${uuid}/fields`,
      icon: "",
      page: "fields",
      label: "Fields",
    },
  ];

  const deleteInformation = () => {
    return deleteSwal("Information Required")
      .then(() => takeAction("destroy", `required-project-information/${uuid}`))
      .then(() => {
        history.push("/required-project-information");
        toast.success("Information required deleted");
      })
      .catch(errorSwal);
  };

  if (!response || loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={information?.name}
        crumbs={[
          {
            link: "/organisations/settings/links",
            name: "Organisation Settings",
          },
          {
            link: "/required-project-information",
            name: "Required Project Information",
          },
          { link: "/", name: information?.name, active: true },
        ]}
      />
      <ProfilePage
        header={
          <DashboardHeader>
            <DashboardH3>{information?.name}</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowPage information={information} setInformation={setInformation} />
        }
        tabs={tabs}
        sideBar={
          <Button
            block
            disabled={loading}
            color="danger"
            onClick={deleteInformation}
            outline
            className="mt-3"
          >
            {isSubmitting(loading, "Delete", "Deleting...")}
          </Button>
        }
      />
    </>
  );
};

export default Profile;
