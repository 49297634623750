import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import isSubmitting from "../utils/submitting";

interface SkillCategoryProps {
  skillCategory: {
    id: number;
    name: string;
    uuid: string;
  };
}

const DeleteSkillCategory = ({ skillCategory }: SkillCategoryProps) => {
  const { takeAction, loading }: IUseApi = useApi();

  const history = useHistory();

  const onDelete = () =>
    deleteSwal(skillCategory.name).then(() => {
      return takeAction("destroy", `skill-categories/${skillCategory.uuid}`)
        .then(() => {
          history.push("/skill-categories");
          toast.success(`${skillCategory.name} deleted.`);
        })
        .catch(errorSwal);
    });

  return (
    <Button
      color="danger"
      block
      className="mt-3"
      outline
      onClick={onDelete}
      disabled={loading}
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </Button>
  );
};

export default DeleteSkillCategory;
