import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import FormModal from "./FormModal";
import { User } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const TimesheetApprovalUsers = () => {
  const { toggle, modal } = useModal();

  const { loading, data: users, setData: setUsers, takeAction } = useApi(
    "timesheet-approval-users",
    [],
  );

  const deleteApprover = approver => {
    deleteSwal(`${approver.user} from ${approver.branch}`)
      .then(() =>
        takeAction("destroy", `timesheet-approval-users/${approver.uuid}`),
      )
      .then(() => {
        setUsers(users.filter(user => user.uuid !== approver.uuid));
        toast.success(`${approver.user} deleted`);
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "user",
      header: "User",
    },
    {
      accessorKey: "branch",
      header: "Branch",
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: info => {
        return (
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => deleteApprover(info.row.original)}
          >
            Delete
          </button>
        );
      },
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/timesheet-tracking/timesheet-approvers/" />
      <HeaderPage
        titlePage="Timesheet Approvers"
        pageDescription={
          <>
            A timesheet approver verifies employee timesheets for accuracy and,
            once added, can be selected by staff from the relevant branch for
            approval.
          </>
        }
        relatedLinks={[
          {
            name: "Staff",
            link: "/users",
            model: User,
          },
        ]}
        crumbs={[
          {
            link: "/organisations/settings/links",
            name: "Organisation",
          },
          {
            link: "",
            name: "Timesheet Approvers",
            active: true,
          },
        ]}
      />

      <button className="btn btn-outline-primary mb-3" onClick={toggle}>
        Add New
      </button>

      <FormModal
        toggle={toggle}
        modal={modal}
        users={users}
        setUsers={setUsers}
      />
      <ReactTable data={users} columns={columns} loading={loading} />
    </>
  );
};

export default TimesheetApprovalUsers;
