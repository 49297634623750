import { useParams } from "react-router-dom";
import NotificationsDetails from "./NotificationsDetails";
import NotificationSettings from "./NotificationSettings";

const ShowNotificationPage = (props: {
  showRead: boolean;
  notifications: any[];
}) => {
  const { page } = useParams<{ page: "show" | "settings" }>();

  switch (page) {
    case "show":
      return (
        <div className="card">
          <div className="dropdown-list">
            {" "}
            <NotificationsDetails {...props} />
          </div>
        </div>
      );
    case "settings":
      return <NotificationSettings />;
    default:
      return null;
  }
};

export default ShowNotificationPage;
