import React from "react";

const UploadOverlay = ({ isDragActive }) => {
  return (
    <div className={`drag-overlay ${isDragActive && "drag-over"}`}>
      <div className="d-flex justify-content-center align-items-center h-100 text-primary">
        <div className="text-center">
          <div className="drag-overlay-icon">
            <i className="fa fa-cloud-upload" />
          </div>
          <div className="upload-text">Upload Files</div>
        </div>
      </div>
    </div>
  );
};

export default UploadOverlay;
