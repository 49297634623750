import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PaginatedList from "../pagination/PaginatedList";
import useDownloadLogs from "./hooks/useDownloadLogs";
import DownloadLogTable from "./DownloadLogTable";

const DownloadLogModal = (props) => {
  const { toggle, modal, document, document_type } = props;
  const document_id = document.id;
  return (
    <Modal
      isOpen={modal}
      className="wd-md-1000 mx-wd-800 w-95"
      toggle={toggle}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <ModalHeader toggle={toggle}>{document.name} Download Logs</ModalHeader>
      <ModalBody>
        <PaginatedList
          indexHook={useDownloadLogs}
          indexHookArguments={[document_id, document_type]}
          originalFilters={[]}
          list={({ data }) => (
            <div className="col-12">
              <DownloadLogTable data={data} />
            </div>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DownloadLogModal;
