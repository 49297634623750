import {
  FETCH_TEST_REQUESTS,
  UPDATE_TEST_REQUEST,
  NEW_SAMPLE,
  UPDATE_SAMPLE,
} from "../actions/types";

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TEST_REQUESTS:
      return {
        ...state,
        items: action.payload,
      };
    case UPDATE_TEST_REQUEST:
      return {
        ...state,
        items: action.payload,
      };
    case NEW_SAMPLE:
      return {
        ...state,
        items: {
          ...state.items,
          testrequest: {
            ...state.items.testrequest,
            samples: [...state.items.testrequest.samples, action.payload],
          },
        },
      };
    case UPDATE_SAMPLE:
      return {
        ...state,
        items: {
          ...state.items,
          testrequest: {
            ...state.items.testrequest,
            samples: state.items.testrequest.samples.map((sample) =>
              sample.uuid !== action.payload.uuid ? sample : action.payload,
            ),
          },
        },
      };
    default:
      return state;
  }
}
