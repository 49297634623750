import { TodoTaskList } from "./todoTypes";
// @ts-ignore
import "react-datetime/css/react-datetime.css";
import AddTask from "./AddTask";
import TodoTask from "./TodoTask";
import { useState } from "react";
import TextButton from "../utils/TextButton";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { useHistory, useParams } from "react-router-dom";
import errorSwal from "../utils/errorSwal";
import _ from "lodash";

export interface TaskListProps {
  taskList: TodoTaskList;
  setTaskList: (taskList: TodoTaskList) => void;
}

const TaskList = (
  props: TaskListProps & {
    taskLists: TodoTaskList[];
    setTaskLists: (taskLists: TodoTaskList[]) => void;
  },
) => {
  const { uuid } = useParams<{ uuid: string | "my-day" }>();
  const { taskList, setTaskList, taskLists, setTaskLists } = props;
  const [showCompleted, setShowCompleted] = useState(false);
  const { takeAction } = useApi();

  const history = useHistory();

  const deleteList = () => {
    return deleteSwal(taskList.name).then(() => {
      history.push("/to-dos/my-day");
      const oldTaskLists = taskLists;

      setTaskLists(taskLists.filter((t) => t.uuid !== taskList.uuid));

      takeAction("destroy", `to-do-task-lists/${taskList.uuid}`).catch(
        (err: any) => {
          setTaskLists(oldTaskLists);
          errorSwal(err);
        },
      );
    });
  };

  const inCompleteTasks =
    taskList?.tasks?.filter((task) => !task.completed_at) ?? [];

  const completeTasks =
    taskList?.tasks?.filter((task) => task.completed_at) ?? [];

  return (
    <>
      <div className="mb-2 d-flex">
        <h5 className="text-dark tx-16">{taskList?.name ?? "My Day"}</h5>
        {!taskList.is_default && uuid !== "my-day" && (
          <TextButton onClick={deleteList} className="ms-auto">
            <AiOutlineDelete className="text-danger tx-16" />
          </TextButton>
        )}
      </div>
      <AddTask {...props} />

      <div className="space-y-3 mt-4">
        {_.sortBy(inCompleteTasks, "due_date").map((task, index) => {
          return (
            <TodoTask
              key={task.uuid ?? index}
              task={task}
              taskList={taskList}
              setTaskList={setTaskList}
            />
          );
        })}
      </div>

      <div className="row mt-3">
        <div className="col-12  mt-3">
          <TextButton
            className="d-flex align-items-center"
            onClick={() => setShowCompleted(!showCompleted)}
          >
            {showCompleted ? (
              <BiChevronDown className="tx-24 text-muted me-2" />
            ) : (
              <BiChevronRight className="tx-24 text-muted me-2" />
            )}
            <p className={`section-title mt-0 mb-0`}>Completed Tasks</p>{" "}
            <p className="mb-0 ms-3 text-muted">{completeTasks.length}</p>
          </TextButton>{" "}
        </div>
      </div>
      {showCompleted ? (
        <div className="space-y-3 mt-3">
          {_.orderBy(completeTasks, "date_completed", "desc").map(
            (task, index) => {
              return (
                <TodoTask
                  key={task.uuid ?? index}
                  task={task}
                  taskList={taskList}
                  setTaskList={setTaskList}
                />
              );
            },
          )}
        </div>
      ) : null}
    </>
  );
};

export default TaskList;
