import { toast } from "react-toastify";
import { Field } from "redux-form";
import FormModal from "../../utils/FormModal";
//BOSS COMPONENTS
import SelectInput from "../../form/SelectInput";
import useApi from "../../api/useApi";
import { useParams } from "react-router-dom";
import formError from "../../utils/formError";

const ChangeReportModal = (props) => {
  const {
    toggle,
    modal,
    selectedTests,
    setSelectedTests,
    testRequestDeliverables,
    setTests,
  } = props;

  const { uuid } = useParams();

  const { takeAction } = useApi();

  const addReport = ({ report }) => {
    return takeAction("update", `test-requests/${uuid}/reports/${report}`, {
      tests: selectedTests.map(({ id }) => id),
    })
      .then(({ data }) => {
        setTests(data.data);
        setSelectedTests(
          data.data.filter((test) =>
            selectedTests.some((selectedTest) => selectedTest.id === test.id),
          ),
        );
        toast.success("Report added to test");
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      form="addReport"
      onSubmit={addReport}
      modal={modal}
      title="Add Report"
      toggle={toggle}
    >
      <div className="col-lg-12 form-group">
        <Field
          name="report"
          label="Select New Report"
          component={SelectInput}
          options={testRequestDeliverables.map((deliverable) => ({
            label: deliverable.name,
            value: deliverable.uuid,
          }))}
          placeholder="Report"
        />
      </div>
    </FormModal>
  );
};

export default ChangeReportModal;
