import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import Spinner from "../utils/Spinner";

const TimesheetReminder = () => {
  const { user } = useAuth();

  const { takeAction, loading, response, data, setData } = useApi(
    `timesheet-reminder`,
    null,
    true,
  );

  const didntWork = () => {
    return takeAction("destroy", "timesheet-reminder").then(() =>
      setData(false),
    );
  };

  if (loading) return <Spinner loading />;

  return (
    <Modal isOpen={data?.remind} backdrop="static">
      <ModalHeader>Time Sheet Reminder</ModalHeader>
      <ModalBody>
        <p className="mb-0">
          It looks like you haven't put in a time sheet for the last week day.
        </p>
        <p className="mb-0">Do you need to put in a time sheet?</p>
      </ModalBody>
      <ModalFooter>
        <Link to={`/users/${user.uuid}/timesheets?date=${data.date}`} className="btn btn-primary">
          Add Time Sheet
        </Link>
        <Button outline color="secondary" onClick={() => setData(false)}>
          Remind Me Later
        </Button>
        <Button color="secondary" onClick={didntWork}>
          I Did Not Work
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TimesheetReminder;
