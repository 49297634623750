import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import isSubmitting from "../utils/submitting";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import colors from "../../data/colors";
import { GroupType } from "./userTypes";

const AdminDeleteTwoFactorAuthentication = ({
  user,
  setUser,
}: {
  user: any;
  setUser: (user: any) => void;
}) => {
  const history = useHistory();

  const { user: authUser } = useAuth();
  const isAdmin = authUser?.is_admin;
  const isIT = authUser?.groups.includes(GroupType.IT);

  const { takeAction, loading } = useApi();

  const remove = () => {
    return deleteSwal(
      `Two factor authentication for ${user.first_name} ${user.last_name}`,
    )
      .then(() =>
        takeAction("destroy", `user/${user.uuid}/two-factor-authentication`),
      )
      .then(({ data }) => {
        toast.success("Two factor authentication removed.");
        const page = isIT ? "admin-mfa" : "profile";
        history.push(`/users/${user.uuid}/${page}`);
        setUser(data.data.user);
      });
  };

  if (!isAdmin && !isIT) {
    return null;
  }

  if (!user?.has_mfa) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: colors.danger.light,
        color: colors.danger.main,
        borderColor: colors.danger.border,
      }}
      className="mt-3 border border-danger p-3 rounded-lg"
    >
      <h5 className="text-danger slim-card-title">
        Remove Two Factor Authentication
      </h5>
      <p className="text-danger">
        You may want to remove someone's two factor authentication if they are
        having issues logging in.
      </p>
      <Button
        disabled={loading}
        color="danger"
        outline
        onClick={remove}
        className="mt-3"
        size="sm"
      >
        {isSubmitting(loading, "Remove", "Removing...")}
      </Button>
    </div>
  );
};

export default AdminDeleteTwoFactorAuthentication;
