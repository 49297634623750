import { Badge, Modal, ModalBody } from "reactstrap";
import { Sample, TestMethod } from "./testRequestTypes";
import { useState } from "react";
import _ from "lodash";
import { BiCheck } from "react-icons/bi";
import FormHeader from "../utils/FormHeader";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import SearchInput from "../search/SearchInput";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const ToggleTestModal = ({
  modal,
  toggle,
  samples,
  setSamples,
}: {
  modal: boolean;
  toggle: () => void;
  samples: Sample[];
  setSamples: (samples: Sample[]) => void;
}) => {
  const [selectedSamples, setSelectedSamples] = useState<Sample[]>([]);

  const { data: testMethods }: IUseApiWithData<TestMethod[]> = useApi(
    "test-methods",
    [],
  );

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-fullscreen modal-samples"
      data-keyboard="false"
    >
      <ModalBody className="w-100 position-relative">
        <SampleList
          selectedSamples={selectedSamples}
          setSelectedSamples={setSelectedSamples}
          samples={samples}
          toggle={toggle}
        />
        <TestMethods
          testMethods={testMethods}
          selectedSamples={selectedSamples}
          setSamples={setSamples}
          samples={samples}
          setSelectedSamples={setSelectedSamples}
        />
      </ModalBody>
    </Modal>
  );
};

const TestMethods = ({
  testMethods,
  selectedSamples,
  samples,
  setSamples,
  setSelectedSamples,
}: {
  testMethods: TestMethod[];
  selectedSamples: Sample[];
  setSamples: (samples: Sample[]) => void;
  setSelectedSamples: (samples: Sample[]) => void;
  samples: Sample[];
}) => {
  const [search, setSearch] = useState("");

  const selectedTestMethods = selectedSamples
    .flatMap((s) => s.tests)
    .map((t) => t.id);

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction, loading }: IUseApi<{}, { data: Sample[] }> = useApi();

  const toggleTest = (test: TestMethod) => {
    if (selectedSamples.length === 0) {
      toast.warning("Please select at least one sample");

      return;
    }

    const toDelete = selectedSamples.filter((sample) => {
      return sample.tests.map((t) => t.id).includes(test.id);
    });

    if (
      selectedSamples
        .flatMap((s) => s.tests)
        .map((t) => t.id)
        .includes(test.id)
    ) {
      return removeTest(test, toDelete);
    }

    return addTest(test);
  };

  const removeTest = (test: TestMethod, samplesToDelete: Sample[]) => {
    return takeAction("store", `samples/tests/delete`, {
      samples: samplesToDelete.map((sample) => sample.id),
      test_method_id: test.id,
    })
      .then(({ data }) => {
        setSamples(
          samples.map((s) => data.data.find((d) => d.id === s.id) || s),
        );

        setSelectedSamples(
          selectedSamples.map((s) => data.data.find((d) => d.id === s.id) || s),
        );
      })
      .catch(errorSwal);
  };

  const addTest = (test: TestMethod) => {
    return takeAction("store", `test-requests/${uuid}/tests`, {
      test_method_id: test.id,
      samples: selectedSamples.map((sample) => sample.id),
    })
      .then(({ data }) => {
        setSamples(
          samples.map((s) => data.data.find((d) => d.id === s.id) || s),
        );

        setSelectedSamples(
          selectedSamples.map((s) => data.data.find((d) => d.id === s.id) || s),
        );
      })
      .catch(errorSwal);
  };

  const filtered = testMethods.filter((testMethod) => {
    return (
      testMethod.method.toLowerCase().includes(search.toLowerCase()) ||
      testMethod.name.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <div className="row mt-4">
      <FormHeader>
        <h6 className="mb-0">Test Methods</h6>
      </FormHeader>
      <div className="col-12">
        <SearchInput value={search} setValue={setSearch} wrapperclass="mb-3" />
      </div>
      {filtered.map((testMethod) => {
        const isSelected = selectedTestMethods.includes(testMethod.id);

        return (
          <div
            key={testMethod.id}
            className="col-12 form-group col-md-6 col-lg-4"
          >
            <button
              type="button"
              className={`border rounded p-3 tn-300 position-relative text-center w-100 h-100 ${
                loading ? "pointer-wait" : ""
              } ${
                loading
                  ? "bg-gray-200 shadow-none"
                  : isSelected
                  ? "bg-gray-300 shadow-none"
                  : "bg-white"
              }`}
              disabled={loading}
              onClick={() => toggleTest(testMethod)}
            >
              {isSelected && (
                <BiCheck
                  className="position-absolute tx-22 text-success"
                  style={{
                    top: "0.5rem",
                    right: "0.5rem",
                  }}
                />
              )}
              <p className="text-dark mb-3 tx-16 fw-bolder">
                {testMethod.method}
              </p>
              <p className="mb-0 text-secondary">{testMethod.name}</p>
            </button>
          </div>
        );
      })}
    </div>
  );
};

const SampleList = ({
  selectedSamples,
  setSelectedSamples,
  samples,
  toggle,
}: {
  selectedSamples: Sample[];
  setSelectedSamples: (samples: Sample[]) => void;
  samples: Sample[];
  toggle: () => void;
}) => {
  const [viewSamples, setViewSamples] = useState(false);

  return (
    <>
      <div
        style={{
          backgroundColor: "#eef0f6",
          top: "-16px",
        }}
        className="row position-sticky z-20"
      >
        <div className="col-12">
          <div className="d-flex align-items-center">
            <div>
              <h6 className="mb-0 section-title mt-0">
                {samples.length} Samples
              </h6>
              <div className="d-flex space-x-2 align-items-center">
                <input
                  checked={selectedSamples.length === samples.length}
                  id="select_all_samples"
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedSamples(samples);
                      return;
                    }
                    setSelectedSamples([]);
                  }}
                />
                <label
                  style={{ userSelect: "none" }}
                  className="mb-0"
                  htmlFor="select_all_samples"
                >
                  Select All Samples ({selectedSamples.length}/{samples.length}{" "}
                  currently selected)
                </label>
              </div>
              {selectedSamples.length > 0 && (
                <div className="d-flex space-x-2 align-items-center">
                  <input
                    checked={viewSamples}
                    id="view_samples"
                    type="checkbox"
                    onChange={(e) => {
                      setViewSamples(e.target.checked);
                    }}
                  />
                  <label
                    style={{ userSelect: "none" }}
                    className="mb-0"
                    htmlFor="view_samples"
                  >
                    View Selected Samples
                  </label>
                </div>
              )}

              {viewSamples && (
                <p className="text-dark mt-2 mb-0">
                  {_.orderBy(selectedSamples, "sample_id")
                    .map((sample) => sample.sample_id)
                    .join(", ")}
                </p>
              )}
            </div>
            <button
              type="button"
              className="p-0 border-0 bg-transparent ms-auto tx-24 text-muted"
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggle}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <hr className="col-12" />
        </div>
      </div>
      <div className="space-x-3 d-flex flex-shrink-0 overflow-auto w-100 pb-4">
        {samples.map((sample) => {
          const isSelected = selectedSamples.includes(sample);

          return (
            <button
              type="button"
              style={{
                flexBasis: "250px",
              }}
              onClick={() =>
                setSelectedSamples(_.xor(selectedSamples, [sample]))
              }
              key={sample.uuid}
              className={`${
                isSelected ? "bg-gray-400" : "bg-white shadow"
              } p-3 border-0 tn-300 rounded-lg flex-shrink-0`}
            >
              <div className="d-flex align-items-center">
                <p className="mb-0 fw-bolder">{sample.sample_id}</p>
                {isSelected && <BiCheck className="ms-auto tx-22 tx-success" />}
              </div>
              <div className="d-flex mt-3 align-items-center">
                <p className="mb-0">
                  {sample.tests.map((test) => test.title).join(", ")}
                </p>
                <Badge className="ms-auto rounded-pill bg-grad-info">
                  {sample.tests.length}
                </Badge>
              </div>
            </button>
          );
        })}
      </div>
    </>
  );
};

export default ToggleTestModal;
