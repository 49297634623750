import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ReactTable from "../../table/ReactTable";

const SampleTable = ({ data = [] }: { data?: any[] }) => {
  const columns = [
    {
      accessorKey: "number",
      header: "Number",
      cell: (info: any) => {
        return (
          <Link to={info.row.original.test_request.link}>
            {info.getValue()}
          </Link>
        );
      },
    },
    {
      accessorKey: "sampled_at_date",
      header: "Sampled On",
      cell: (info: any) => {
        return dayjs.unix(info.getValue()).format("DD/MM/YYYY");
      },
    },
    {
      accessorKey: "client.name",
      header: "Client",
      cell: (info: any) => {
        return (
          <Link to={info.row.original.client.link}>{info.getValue()}</Link>
        );
      },
    },
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info: any) => {
        return (
          <Link to={info.row.original.project.link}>{info.getValue()}</Link>
        );
      },
    },
    {
      accessorKey: "test_request.name",
      header: "Test Request",
      cell: (info: any) => {
        return (
          <Link to={info.row.original.test_request.link}>
            {info.getValue()}
          </Link>
        );
      },
    },
    {
      accessorKey: "tests",
      header: "Tests",
      cell: (info: any) => {
        if (!info.getValue()) return "-";

        return info
          .getValue()
          .map(({ name }: { name: string }) => name)
          .join(", ");
      },
    },
  ];

  return <ReactTable disableSearch columns={columns} data={data} />;
};

export default SampleTable;
