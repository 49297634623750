import dayjs from "dayjs";
import React from "react";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import ReactTable from "../../table/ReactTable";
import errorSwal from "../../utils/errorSwal";
import ProfileButtons from "../../utils/ProfileButtons";
import { CustomJobJob, Understanding } from "./customJobTypes";
import UsersRequiredToSignIn from "./UsersRequiredToSignIn";

export interface UnderstandingsProps {
  job: CustomJobJob;
  setJob: (job: CustomJobJob) => void;
}

const Understandings = ({ job, setJob }: UnderstandingsProps) => {
  const {
    takeAction,
    loading,
  }: IUseApi<
    {},
    {
      data: {
        sign_in_required: boolean;
      };
    }
  > = useApi();

  const toggleSignInRequired = () => {
    return takeAction("update", `jobs/${job.uuid}/sign-ins-required`, {
      sign_in_required: !job.sign_in_required,
    })
      .then(({ data }) => {
        setJob({
          ...job,
          sign_in_required: data.data.sign_in_required,
        });
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => {
        const understanding = info.row.original;
        return (
          info.getValue() ?? (
            <a href={`mailto:${understanding.user?.email}`}>
              {understanding.user?.name}
            </a>
          )
        );
      },
    },
    {
      accessorKey: "type",
      header: "Type",
      cell: (info: any) => {
        return info.getValue() ?? "Employee";
      },
    },
    {
      accessorKey: "signature",
      header: "Signature",
      cell: (info: any) => {
        if (!info.getValue()) {
          return null;
        }

        return <img src={info.getValue()} className="img-fluid" alt="" />;
      },
    },
    {
      accessorKey: "created_at",
      header: "Time Taken",
      cell: (info: any) => dayjs(info.getValue()).format("DD/MM/YYYY hh:mm a"),
    },
  ];

  return (
    <>
      <ProfileButtons
        buttons={[
          {
            text: loading ? (
              <>
                <i className="fa fa-spinner fa-spin me-2" /> Updating...{" "}
              </>
            ) : job.sign_in_required ? (
              "Unmark as Requiring Sign In"
            ) : (
              "Mark as Requiring Sign In"
            ),
            onClick: toggleSignInRequired,
            disabled: loading,
          },
        ]}
      />
      <UsersRequiredToSignIn
        job={job}
        setJob={setJob}
        initialValues={{
          user_ids: job.users_to_sign_in,
        }}
      />
      <div className="mb-3">
        <small>
          When a job is marked as requiring sign in, a notification will show on
          jobs in this project that overlap with this jobs scheduled start and
          end date ({dayjs(job.scheduled_start_date).format("DD/MM/YYYY")} -{" "}
          {dayjs(job.scheduled_finish_date).format("DD/MM/YYYY")}).
        </small>
        {job.sign_in_required && (
          <small className="d-block">
            To have this notification show up on all jobs, leave the above field
            empty. If you'd like this to only show for certain people please
            specify above.
          </small>
        )}
      </div>
      <ReactTable data={job.understandings} columns={columns} />
    </>
  );
};

export default Understandings;
