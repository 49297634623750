import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formValueSelector, reduxForm } from "redux-form";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import useModal from "../../hooks/useModal";
import Spinner from "../../utils/Spinner";
import ClosedProjectModal from "./closedProjectModal";
import JobForm from "./JobForm";
import SubmitButton from "../../utils/SubmitButton";
import formError from "../../utils/formError";
import FormErrorAlert from "../../form/FormErrorAlert";
import { useAuth } from "../../../context/auth-context";

const AddJob = (props) => {
  const { handleSubmit } = props;
  const { takeAction } = useApi();
  const { toggle, modal } = useModal();
  const history = useHistory();

  const { projectUuid, jobTypeId, model } = useParams();

  const { data: project } = useApi(`projects/${projectUuid}`, 0, true);

  const { user } = useAuth();

  const onSubmit = (values) => {
    values.job_type_id = jobTypeId;
    values.job_type_type = model;

    return takeAction("store", `projects/${projectUuid}/jobs`, values)
      .then(({ data }) => {
        toast.success("Job created successfully!");
        if (project && project.status === 1) {
          toast.success(`${project.name} reopened!`);
        }
        history.push(data.data.link);
      })
      .catch(formError);
  };

  const checkProjectStatus = (values) => {
    if (project.status === 1) {
      toggle();
    } else {
      onSubmit(values);
    }
  };

  if (!project) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={"Create New Job"}
        pageDescription={
          <span className="text-secondary">
            To be added to{" "}
            <Link className="text-secondary" to={`/${project.link}`}>
              {project?.full_name}
            </Link>
          </span>
        }
        crumbs={[
          {
            link: "",
            name: "New Job",
            active: true,
          },
        ]}
      />

      {project.status === 1 ? (
        <form onSubmit={handleSubmit(checkProjectStatus)}>
          <div className="row">
            <FormErrorAlert error={props.error} />
            <JobForm organisationId={project?.organisation_id} {...props} />
            <div className="form-group col-12">
              <SubmitButton {...props} />
            </div>
          </div>

          <ClosedProjectModal
            {...props}
            modal={modal}
            toggle={toggle}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
          />
        </form>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <FormErrorAlert error={props.error} />
            <JobForm organisationId={project?.organisation_id} {...props} />
            <div className="form-group col-12">
              <SubmitButton {...props} />
            </div>
          </div>
        </form>
      )}
    </>
  );
};

const form = reduxForm({
  form: "addJobRegister",
});

const mapStateToProps = (state) => {
  const selector = formValueSelector("addJobRegister");

  return {
    jobScheduledFinishDate: selector(state, "scheduled_finish_date"),
  };
};

export default connect(mapStateToProps, {})(form(AddJob));
