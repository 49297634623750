import { useEffect, useState } from "react";
import useApi from "../api/useApi";
import CalibrationFields from "./CalibrationFields";

const Calibrations = (props) => {
  const [calibrationUnits, setCalibrationUnits] = useState([]);
  const { takeAction } = useApi();
  const { fields, initialValues } = props;

  useEffect(() => {
    takeAction(
      "index",
      `react-select?model=${encodeURIComponent(
        "App\\Models\\CalibrationUnit",
      )}&select=name,id&label=name&value=id`,
    ).then(({ data }) => setCalibrationUnits(data));
  }, []);

  return (
    <div className="tx-inverse">
      {fields.length > 0 && (
        <div className="mt-3">
          <h5>Calibrations</h5>
          <hr />
        </div>
      )}

      {fields.map((calibration, index) => (
        <CalibrationFields
          calibration={calibration}
          index={index}
          initialValues={initialValues}
          fields={fields}
          calibrationUnits={calibrationUnits}
          key={index}
          {...props}
        />
      ))}

      <div className="form-group d-flex">
        <button
          type="button"
          className="btn btn-link ms-auto px-0"
          onClick={() => fields.push({ unit_type: false, isTailoredCalibrationJobDeadlines: false })}
        >
          Add Calibration
        </button>
      </div>
    </div>
  );
};

export default Calibrations;
