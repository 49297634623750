import Tabs from "../tabs/Tabs";
import { Tab } from "../tabs/tabTypes";
import SampleVariables from "./SampleVariables";
import StandardVariables from "./StandardVariables";
import { TestSuiteProps } from "./testSuiteTypes";
import UploadTestSuiteTemplate from "./UploadTestSuiteTemplate";

const TestSuiteTemplate = (props: TestSuiteProps) => {
  const { testSuite } = props;

  const tabs: Tab[] = [
    {
      component: <UploadTestSuiteTemplate {...props} />,
      title: "File",
    },
  ];

  if (testSuite.template) {
    tabs.push(
      {
        component: <StandardVariables {...props} />,
        title: "Standard Variables",
      },
      {
        component: <SampleVariables {...props} />,
        title: "Sample Variables",
      },
    );
  }

  return <Tabs tabs={tabs} />;
};

export default TestSuiteTemplate;
