import { connect } from "react-redux";
import {
  Field,
  FormSubmitHandler,
  formValueSelector,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { IDocument } from "../documents/documentTypes";
import SelectInput from "../form/SelectInput";
import buttonGroup from "../utils/buttonGroup";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import { toast } from "react-toastify";

interface GenerateFromTemplateModalProps {
  toggle: Function;
  modal: boolean;
  templates: IDocument[];
  format?: "Word" | "PDF" | "xlsx";
  parentUuid?: string;
  fieldUuid: string;
  onChange: Function;
}

const GenerateFromTemplateModal = (props: GenerateFromTemplateModalProps) => {
  const { toggle, modal, format, templates, parentUuid, fieldUuid, onChange } =
    props;

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<{ format: string; template: string }> = (
    values,
  ) => {
    return takeAction("store", `/custom-field-templates/${parentUuid}`, {
      ...values,
      field_uuid: fieldUuid,
    })
      .then(({ data }) => {
        onChange(data.data.value);
        toast.success("File Generated");
        toggle();
      })
      .catch(formError);
  };

  const options = templates
    .filter((template) => {
      if (!format) {
        return false;
      }

      const split = template.name.split(".");
      const fileType = split[split.length - 1];

      if (format === "xlsx") {
        return fileType === "xlsx";
      }
      return fileType === "docx";
    })
    .map((template) => {
      return {
        label: template.name,
        value: template.id,
      };
    });

  return (
    <FormModal
      onSubmit={onSubmit}
      toggle={toggle}
      modal={modal}
      title="Generate File from Template"
      form="GENERATE_FROM_TEMPLATE"
    >
      <div className="col-lg-12 form-group">
        <Field
          component={buttonGroup}
          validate={required}
          required
          options={[
            {
              label: "Word",
              value: "Word",
            },
            {
              label: "PDF",
              value: "PDF",
            },
            {
              label: "Excel",
              value: "xlsx",
            },
          ]}
          name="format"
          label="Format"
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          name="template"
          label="Template"
          options={options}
        />
      </div>
    </FormModal>
  );
};

const selector = formValueSelector("GENERATE_FROM_TEMPLATE");

const mapStateToProps = (state: any) => {
  return {
    format: selector(state, "format"),
  };
};

export default connect(mapStateToProps)(GenerateFromTemplateModal);
