import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import Icon from "../listView/Icon";
import PaginatedCard from "../pagination/PaginatedCard";
import { IFilter } from "../hooks/useFilter";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { GroupType, UserIndex } from "./userTypes";
import useUsers from "./hooks/useUsers";
import { InviteUser, LeaveReason } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";
import { useAuth } from "../../context/auth-context";

const UsersList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/hr-staff-training/staff/" />
      <HeaderPage
        titlePage="Users"
        relatedLinks={[
          {
            name: "Invite Staff to Join Organisation",
            link: "/users/invite",
            model: InviteUser,
          },
          { name: "Employment Details", link: "/employment-details" },
          {
            name: "Leave Reasons",
            link: "/leave-reasons",
            model: LeaveReason,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          {
            link: "users",
            name: "Users",
            active: true,
          },
        ]}
      />
      <PaginatedList
        listName="usersList"
        indexHook={useUsers}
        indexHookArguments={[]}
        originalFilters={employmentFilters}
        itemCard={({ item }: { item: UserIndex }) => (
          <PaginatedCard
            key={item.uuid}
            header={<UserHeader user={item} />}
            info={info(item)}
            bottomContent={
              <div className="d-flex justify-content-end">
                <Icon
                  className="mb-0"
                  data={{
                    email: item.email,
                    phone: item.phone,
                  }}
                />
              </div>
            }
          />
        )}
      />
    </>
  );
};

const UserHeader = ({ user }: { user: UserIndex }) => {
  const { user: authUser } = useAuth();
  const isAdmin = authUser?.is_admin;
  const isIT = authUser?.groups.includes(GroupType.IT);
  const userLink =
    isIT && !isAdmin ? user.link.replace("profile", "admin-mfa") : user.link;

  return (
    <div className="d-flex align-items-center w-100">
      <div style={{ maxWidth: "15%" }}>
        <Link to={userLink}>
          <Avatar name={user.full_name} colour="info" />
        </Link>
      </div>
      <div className="ms-1" style={{ width: "85%" }}>
        <p className="mb-0 fw-bolder tx-inverse no-warp">
          <Link to={userLink}>{user.full_name}</Link>
        </p>
        {user.primary_position && (
          <p className="mb-0 text-secondary tx-12">
            {user.primary_position.name}
          </p>
        )}
      </div>
    </div>
  );
};

const info = (user: UserIndex) => {
  return [
    {
      name: "Administrator",
      value: user.is_admin ? (
        <div className="ms-auto bg-success shadow rounded-pill text-white tx-10 text-center px-2">
          Yes
        </div>
      ) : (
        <div className="ms-auto bg-secondary shadow rounded-pill text-white tx-10 text-center px-2">
          No
        </div>
      ),
    },
  ];
};

const employmentFilters: IFilter<"employed", boolean>[] = [
  {
    label: "Employment Status",
    name: "employed",
    options: [
      {
        label: "Current Employees",
        value: true,
        selected: true,
      },
      {
        label: "Previous Employees",
        value: false,
      },
    ],
  },
];

export default UsersList;
