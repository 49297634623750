import { Field, InjectedFormProps, reduxForm } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { TestSuite } from "./testSuiteTypes";

const TestSuiteForm = (props: InjectedFormProps<TestSuite>) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-9 form-group">
          <Field
            required
            validate={required}
            component={RenderField}
            name="name"
            label="Name"
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            required
            validate={required}
            component={RenderField}
            name="report_prefix"
            label="Report Prefix"
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<TestSuite>({});

export default form(TestSuiteForm);
