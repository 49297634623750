import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { useState } from "react";
import useApi from "../api/useApi";
import CheckoutForm from "./CheckoutForm";

const Provider = (props) => {
  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

  const [paymentIntent, setPaymentIntent] = useState();

  const { loading, takeAction } = useApi();

  useEffect(() => {
    takeAction("store", `/payment-method-intents`).then(({ data }) =>
      setPaymentIntent(data.data),
    );
  }, []);

  const options = {
    clientSecret: paymentIntent?.client_secret,
  };

  if (loading) {
    return false;
  }

  return (
    <div className="example">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm intent={paymentIntent} {...props} />
      </Elements>
    </div>
  );
};

export default Provider;
