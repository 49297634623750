import React from "react";
import { Button } from "reactstrap";

const FilterButtons = ({ filter, setFilter, loading }) => {
  const toggleFilter = (name) => {
    setFilter(name === filter ? null : name);
  };

  return (
    <div className="d-flex mb-3">
      <Button
        className="btn-oblong me-2 ms-auto"
        size="sm"
        outline={filter !== "overdue"}
        color="primary"
        onClick={() => toggleFilter("overdue")}
        disabled={loading}
      >
        Overdue
      </Button>
      <Button
        className="btn-oblong"
        size="sm"
        outline={filter !== "unpaid"}
        color="primary"
        onClick={() => toggleFilter("unpaid")}
        disabled={loading}
      >
        Unpaid
      </Button>
    </div>
  );
};

export default FilterButtons;
