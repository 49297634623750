import React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const InvoiceButton = ({ deliverable }) => {
  const { takeAction, loading } = useApi();

  const history = useHistory();

  const createInvoice = () => {
    return takeAction("store", `deliverable-invoices/${deliverable.uuid}`)
      .then(({ data }) => {
        toast.success(`${data.data.name} Created`);
        history.push(`/invoices/${data.data.uuid}/deliverables`);
      })
      .catch(errorSwal);
  };

  if (deliverable.invoice || !deliverable.has_been_sent) return null;

  return (
    <li className="nav-item">
      <button
        type="button"
        disabled={loading}
        onClick={createInvoice}
        className="nav-link w-100 pointer-hover link-hover"
      >
        {" "}
        {isSubmitting(
          loading,
          <>
            <i className="icon ion-plus tx-success" /> Create Invoice
          </>,
          "Creating...",
        )}
      </button>
    </li>
  );
};

export default InvoiceButton;
