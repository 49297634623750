import { useInfiniteQuery } from "react-query";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const getProjectExpenses = (
  { pageParam = 1 },
  search?: string,
  number?: string,
  dates?: [string, string] | null,
  ignoreDates?: boolean,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `projects/${number}/expenses?page=${pageParam}&filter[search]=${search}${
        !ignoreDates && dates
          ? `&filter[date_between]=${dayjs(dates[0]).format(
              "YYYY-MM-DD",
            )},${dayjs(dates[1]).format("YYYY-MM-DD")}`
          : ""
      }`,
    )
    .then(({ data }) => data);
};

export default function useProjectExpenses(
  search?: string,
  from?: string,
  to?: string,
  ignoreDates?: boolean,
) {
  const { number } = useParams<{ number: string }>();
  console.log(from, to);

  return useInfiniteQuery(
    ["project-expenses", { search, number }],
    (pageParam) =>
      getProjectExpenses(
        pageParam,
        search,
        number,
        from && to ? [from, to] : null,
        ignoreDates,
      ),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
