import { useParams } from "react-router-dom";
import { XeroIntegrationProfilePages } from "./XeroIntegrationProfile";
import XeroContacts from "./XeroContacts";
import NotFound from "../404";
import ContactGroups from "./ContactGroups";
import XeroReports from "./XeroReports";
import XeroExtraInformation from "./XeroExtraInformation";

const ShowXeroIntegrationPage = (props: any) => {
  const { page } = useParams<{ page: XeroIntegrationProfilePages }>();

  switch (page) {
    case "contacts":
      return <XeroContacts />;
    case "contact-groups":
      return <ContactGroups initialValues={props.xero} />;
    case "reports":
      return <XeroReports />;
    case "extra-info":
      /** @ts-ignore */
      return <XeroExtraInformation initialValues={props.xero} />;
    default:
      return <NotFound />;
  }
};

export default ShowXeroIntegrationPage;
