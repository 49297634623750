import {
  ParsedEmail,
  StandardDocument,
} from "../standardDocuments/standardDocumentTypes";
import useApi from "../api/useApi";
import { useEffect } from "react";
import { IUseApi } from "../api/apiTypes";
import { BeatLoader } from "react-spinners";
import { primary } from "../utils/Colours";
const DisplayEmail = ({ document }: { document: StandardDocument }) => {
  const { data, setUrl, loading }: IUseApi<ParsedEmail> = useApi();

  useEffect(() => {
    setUrl(`documents/${document.uuid}/parse-email`);
  }, [document]);

  if (!data || loading) {
    return (
      <BeatLoader
        color={primary}
        className="d-flex justify-content-center"
        loading
      />
    );
  }

  return (
    <div>
      <div className="bg-white p-3 mb-3 rounded">
        <h5 className="text-dark fw-bolder mb-0">{data.subject}</h5>
      </div>
      <div className="p-3 bg-white rounded">
        <div className="">
          <div className="">
            <p className="text-dark mb-2">
              {data.from?.name} &lt;
              <a href={`mailto:${data.from.email}`}>{data.from?.email}</a>&gt;
            </p>
            <p className="text-secondary mb-1">
              To:{" "}
              {data.to.map((to) => (
                <>
                  {to.name} &lt;<a href={`mailto:${to.email}`}>{to.email}</a>
                  &gt;{" "}
                </>
              ))}
            </p>
            {data.cc.length > 0 && (
              <p className="text-secondary mb-1">
                CC:{" "}
                {data.cc.map((cc) => (
                  <>
                    {cc.name} &lt;<a href={`mailto:${cc.email}`}>{cc.email}</a>
                    &gt;{" "}
                  </>
                ))}
              </p>
            )}
            {data.bcc.length > 0 && (
              <p className="text-secondary mb-1">
                BCC:{" "}
                {data.bcc.map((bcc) => (
                  <>
                    {bcc.name} &lt;
                    <a href={`mailto:${bcc.email}`}>{bcc.email}</a>
                    &gt;{" "}
                  </>
                ))}
              </p>
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: data.html,
            }}
            className="text-dark mt-3"
          />
        </div>
      </div>
    </div>
  );
};

export default DisplayEmail;
