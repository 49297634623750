import { setUser } from "@sentry/react";
import { toast } from "react-toastify";
import { Field, FormSubmitHandler } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import FileInput from "../upload/FileInput";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import { AuthUser } from "./userTypes";

interface UploadSignatureModalProps {
  modal: boolean;
  toggle: Function;
  setUser: (user: AuthUser) => void;
}

const UploadSignatureModal = (props: UploadSignatureModalProps) => {
  const { upload, uploadPercent } = useS3Uploader();

  const { user } = useAuth();

  const { takeAction }: IUseApi<{}, { data: { user: any } }> = useApi();

  const onSubmit: FormSubmitHandler<{ document: Document }> = (values) => {
    return upload(
      values.document,
      `users/${user?.uuid}/documents`,
      (document: {
        path: string;
        name: string;
        file_size: string;
        mime_type: string;
      }) => {
        return takeAction("store", "/user-signatures/uploaded", { document })
          .then(({ data }) => {
            props.setUser(data.data.user);
            toast.success("File Uploaded");
            props.toggle();
          })
          .catch(errorSwal);
      },
    );
  };

  return (
    <FormModal
      title="Upload Signature"
      form="UPLOAD_SIGNATURE"
      onSubmit={onSubmit}
      {...props}
    >
      <div className="col-lg-12 form-group">
        <Field
          percent={uploadPercent}
          component={FileInput}
          name="document"
          label="Signature"
        />
      </div>
    </FormModal>
  );
};

export default UploadSignatureModal;
