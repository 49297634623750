import { useEffect } from "react";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import InformationAlert from "../utils/InformationAlert";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import { useHistory } from "react-router-dom";
import { Integration } from "../integrations/integrationTypes";

const Redirect = () => {
  const params = new URLSearchParams(window.location.search);

  const history = useHistory();
  const { user } = useAuth();

  const {
    takeAction,
    setData,
    data,
  }: IUseApi<
    {
      error?: string;
    },
    {
      data: Integration;
    }
  > = useApi();

  useEffect(() => {
    if (params.get("error")) {
      return;
    }
    if (params.get("state") !== user?.active_organisation?.uuid) {
      setData({
        error:
          "It appears the request has been tampered with. Please try again.",
      });
      return;
    }
    takeAction("store", "microsoft-token", {
      code: params.get("code"),
      tenant: params.get("tenant"),
    })
      .then(({ data }) => {
        history.push(`/integrations/microsoft/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  }, []);

  if (params.get("error")) {
    return <div>{params.get("error_description")}</div>;
  }

  if (data?.error) {
    return (
      <InformationAlert
        type="danger"
        body="The request received does not match the request sent to Microsoft. This means that it has been tampered with. Please try again."
        title={data?.error}
      />
    );
  }

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Connecting with Microsoft" />
      <div
        style={{
          height: "60vh",
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="bg-white border rounded p-5 text-center">
          <img
            style={{
              width: "200px",
            }}
            src="/img/microsoft_logo.png"
            alt="Microsoft"
          />
          <p className="mt-3 text-dark mb-0">
            <i className={`fa fa-spinner fa-spin me-2`} />
            Connecting with Microsoft
          </p>
          <p className="mb-0 mt-2">You should be automatically redirected.</p>
          <p className="mb-0">If not, please contact support.</p>
          <a
            className="btn btn-primary mt-3"
            href="mailto:info@thebossapp.com.au"
          >
            Contact Support
          </a>
        </div>
      </div>
    </>
  );
};

export default Redirect;
