import { useMemo } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { BrandingTheme } from "./brandingThemeTypes";
import useBrandingThemes from "./hooks/useBrandingThemes";

const BrandingThemeList = () => {
  return (
    <>
      <HeaderPageAdminFilter
        crumbs={[
          {
            name: "Branding Themes",
            link: "/",
            active: true,
          },
        ]}
        titlePage="Branding Themes"
      />
      <PaginatedList
        listName="brandingThemeList"
        originalFilters={[]}
        indexHook={useBrandingThemes}
        addLink="/branding-themes/add"
        itemCard={({ item }: { item: BrandingTheme }) => {
          return (
            <PaginatedCard info={info(item)} header={<Header theme={item} />} />
          );
        }}
      />
    </>
  );
};

const Header = ({ theme }: { theme: BrandingTheme }) => {
  const tooltipId = useMemo(() => `type${theme.uuid}`, [theme.uuid]);

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={theme.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/branding-themes/${theme.uuid}/details`}>
              {theme.name}
            </Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {theme.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

const info = (brandingTheme: BrandingTheme): PaginatedInformation[] => {
  return [
    {
      name: "Organisation Name",
      value: brandingTheme.organisation_name,
    },
    {
      name: "Email",
      value: brandingTheme.email,
    },
  ];
};

export default BrandingThemeList;
