import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getProjectJobs = (
  { pageParam = 1 },
  search: string,
  number?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `projects/${number}/jobs?page=${pageParam}&filter[search]=${search}&paginate=15`,
    )
    .then(({ data }) => data);
};

export default function useProjectJobs(search: string, number?: string) {
  return useInfiniteQuery(
    ["project-jobs", { search, number }],
    (pageParam) => getProjectJobs(pageParam, search, number),
    {
      getNextPageParam: ({meta}) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;


        return nextPage;
      },
    },
  );
}
