import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, formValueSelector } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import ClientInformation from "../clients/ClientInformation";
import CustomForm from "../customFields/CustomForm";
import { date } from "../form/formatters";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import buttonGroup from "../utils/buttonGroup";
import dateField from "../utils/dateTime";
import errorSwal from "../utils/errorSwal";
import FormHeader from "../utils/FormHeader";
import FormModal from "../utils/FormModal";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const SuccessModal = (props) => {
  const { toggle, setSuccessful, setTender, create, tender } = props;

  const { takeAction } = useApi();
  const { user } = useAuth();

  const [requiredInformation, setRequiredInformation] = useState(null);

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/projects/required-information`,
  );

  const updateInformation = (estimatedValue) => {
    takeAction(
      "show",
      `estimated-value-project-information?estimated_value=${estimatedValue}`,
    ).then(({ data }) => {
      setRequiredInformation(data.data);
    });
  };

  useEffect(() => {
    if (tender?.total_price) {
      updateInformation(tender?.total_price);
    }
  }, [tender]);

  const workLookup = (props) => ({
    job: <JobFields {...props} />,
    project: (
      <ProjectFields requiredInformation={requiredInformation} {...props} />
    ),
  });

  const onSubmit = (values) => {
    if (
      values?.create &&
      (!values?.scheduled_start_date ||
        !values?.scheduled_finish_date ||
        (values?.create === "project" && !values?.purchase_order))
    ) {
      return toast.warning(`Please fill necessary fields.`);
    }

    const actionType = values.copy_project
      ? {
          url: `tenders/${tender.uuid}/project-template`,
          type: "store",
        }
      : {
          url: `/tender-status/${tender.uuid}`,
          type: "update",
        };

    return upload({
      ...values,
      successful: true,
      estimated_value: tender?.total_price,
      team_members: tender?.job?.users,
    })
      .then((data) => takeAction(actionType.type, actionType.url, data))
      .then(({ data }) => {
        setSuccessful(true);
        setTender(data.data);
        toggle();
      })
      .catch(errorSwal);
  };

  const options = [
    {
      label: "Project",
      value: "project",
    },
  ];

  if (tender.client) {
    options.push({
      label: "Job",
      value: "job",
    });
  }

  return (
    <FormModal
      title="Mark Tender Successful"
      onSubmit={onSubmit}
      form="MarkTenderSuccessful"
      {...props}
    >
      {(formProps) => (
        <>
          <div className="col-lg-12 form-group">
            <Field
              component={buttonGroup}
              name="create"
              groupClass="w-100"
              buttonClass="w-100"
              label="Create Work?"
              options={options}
            />
            <small>If you do not require work to be created, press save.</small>
          </div>
          {workLookup(formProps)[create]}
        </>
      )}
    </FormModal>
  );
};

const JobFields = (props) => {
  return (
    <>
      <StandardFields />
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInputAsync}
          name="project_id"
          required
          validate={required}
          label="Project"
          url="/custom-fields/projects"
          formatData={formatData}
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          name="branch_id"
          label="Branch"
          required
          validate={required}
          component={SelectInputAsync}
          url="/organisationbranches/branches"
          placeholder="Select Branch"
          hideDisabled={true}
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          label="Job Type"
          name="job_type"
          url="/jobtypes/types?filter[]=JobTypes&filter[]=CustomJobs"
          required
          validate={required}
          component={SelectInputAsync}
          onChangeValue={({ type, id }) => {
            props.change("job_type_type", type);
            props.change("job_type_id", id);
          }}
        />
      </div>
    </>
  );
};

const ProjectFields = (props) => {
  const { requiredInformation, copyProject, tender } = props;
  return (
    <>
      {!tender.client && (
        <>
          <FormHeader
            extra={
              <p className="mb-0">
                You must request a new client in order to create a project.
              </p>
            }
          >
            New Client Information
          </FormHeader>
          <ClientInformation
            fieldPrefix="client"
            form="MarkTenderSuccessful"
            change={props.change}
          />
          <FormHeader>Project Information</FormHeader>
        </>
      )}

      <div className="col-lg-12 form-group">
        <Field
          label="Project From Template"
          component={renderToggleInput}
          name="copy_project"
        />
      </div>
      {copyProject ? <CopyProjectFields /> : <StandardFields />}
      <div className="col-lg-6 form-group">
        <Field
          label="Purchase Order"
          component={renderField}
          name="purchase_order"
          required
          validation={required}
        />
      </div>
      {!copyProject && requiredInformation && (
        <CustomForm
          customForm={requiredInformation?.custom_fields}
          {...props}
        />
      )}
    </>
  );
};

const CopyProjectFields = () => {
  return (
    <>
      <div className="col-lg-12 form-group">
        <Field
          label="Project"
          component={SelectInputAsync}
          required
          validation={required}
          formatData={({ data }) => {
            return data.map((d) => ({
              label: d.name,
              value: d.id,
            }));
          }}
          name="project_id"
          url="/custom-fields/projects"
        />
      </div>
      <div className="col-lg-6">
        <Field
          component={SelectInputAsync}
          name="project_manager"
          label="Manager"
          url="/users/projectmanagers"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={dateField}
          {...date}
          name="scheduled_start_date"
          label="Scheduled Start Date"
          validate={required}
          required
        />
        <small className="d-block">
          N.B. the created project will have the same length as this project.
        </small>
      </div>

      <div className="col-lg-6 form-group">
        <Field
          component={renderToggleInput}
          name="copy_documents"
          label="Copy Files"
        />
        <small className="d-block">
          If checked, will copy all project and job files and file structure.
        </small>
      </div>

      <div className="col-lg-6 form-group">
        <Field
          component={renderToggleInput}
          name="copy_jobs"
          label="Copy Jobs"
        />
      </div>
    </>
  );
};

const StandardFields = () => (
  <>
    <div className="col-lg-6 form-group">
      <Field
        label="Scheduled Start Date"
        component={renderField}
        name="scheduled_start_date"
        type="date"
        required
        extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
        validation={required}
      />
    </div>
    <div className="col-lg-6 form-group">
      <Field
        label="Scheduled Finish Date"
        component={renderField}
        name="scheduled_finish_date"
        type="date"
        required
        validation={required}
        extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
      />
    </div>
    <div className="col-lg-6 form-group">
      <Field
        name="project_manager"
        component={SelectInputAsync}
        url="/users/projectmanagers"
        placeholder="Select Manager"
        required
        label="Manager"
        validation={required}
      />
    </div>
  </>
);

const formatData = ({ data }) =>
  data.map((option) => ({
    label: option.name,
    value: option.id,
    disabled: option.disabled ?? false,
  }));

const selector = formValueSelector("MarkTenderSuccessful");

const mapStateToProps = (state) => {
  return {
    create: selector(state, "create"),
    copyProject: selector(state, "copy_project"),
  };
};

export default connect(mapStateToProps, {})(SuccessModal);
