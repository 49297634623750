import React, { useMemo } from "react";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useDeductions from "./hooks/useDeductions";
import { Deduction } from "./deductionTypes";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import dayjs from "dayjs";
import ApprovalStatus from "../user/ApprovalStatus";

const DeductionsList = () => {
  return (
    <>
      <HeaderPage titlePage="Deductions" crumbs={[]} />
      <PaginatedList
        indexHook={useDeductions}
        originalFilters={[]}
        addLink="/deductions/add"
        list={({ data }) => {
          const activityLogs = data as Deduction[];

          return (
            <div className="col-12">
              <DeductionsTable deductions={activityLogs} />
            </div>
          );
        }}
      />
    </>
  );
};

const DeductionsTable = ({ deductions }: { deductions: Deduction[] }) => {
  const columns = useMemo(() => {
    return [
      {
        header: "Name",
        accessorKey: "name",
        cell: (info: any) => {
          const deduction = info.row.original as Deduction;

          return (
            <>
              <Link to={`deductions/${deduction.uuid}/details`}>
                {deduction.user.name} - {deduction.description}
              </Link>
              <div className="d-block">
                <ApprovalStatus
                  className="rounded-pill shadow-sm mt-2"
                  status={deduction.approval_status}
                />
              </div>
            </>
          );
        },
      },
      {
        header: "Date",
        accessorKey: "date",
        cell: (info: any) => {
          const deduction = info.row.original as Deduction;

          if (!deduction.date) return "Recurring";

          return dayjs(deduction.date).format("DD/MM/YYYY");
        },
      },
    ];
  }, [deductions]);

  return <ReactTable disableSearch columns={columns} data={deductions} />;
};

export default DeductionsList;
