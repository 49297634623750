import React from "react";
import { Link } from "react-router-dom";

const NoMatch = () => (
  <div className="page-error-wrapper">
    <div>
      <h1 className="error-title">404</h1>
      <h5 className="tx-sm-24 tx-normal">
        The page you were looking for doesn't exist.
      </h5>
      <p className="mg-b-50">
        You may have mistyped the address or the page may have moved.
      </p>
      <p className="mg-b-50">
        <Link to="/home">Back to Home</Link>
      </p>
    </div>
  </div>
);

export default NoMatch;
