import { Field, reduxForm } from "redux-form";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";

const SendDetailsFormModal = (props) => {
  return (
    <FormModal {...props}>
      <div className="col-12">
        <Field component={RenderField} name="email" label="Email" />
        <small>To send to multiple, separate with a comma (,).</small>
      </div>
    </FormModal>
  );
};

export default SendDetailsFormModal;