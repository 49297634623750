import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import { money } from "../form/formatters";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ProfilePage from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import CreateBudgetButton from "./CreateBudgetButton";
import ShowPage from "./ShowPage";

const Profile = () => {
  const { uuid, page } = useParams();

  const {
    data: budgetTemplate,
    setData: setBudgetTemplate,
    loading,
  } = useApi(`budget-templates/${uuid}`, null, true, "/budget-templates");

  const {
    data: budgets,
    setUrl,
    loading: fetchingBudgets,
    setData: setBudgets,
  } = useApi(null, []);

  useEffect(() => {
    if (page === "budgets") {
      setUrl(`budget-templates/${uuid}/budgets`);
    }
  }, [page, uuid]);

  const tabs = [
    {
      link: `/budget-templates/${uuid}/details`,
      icon: "",
      page: "details",

      label: "Details",
    },
    {
      link: `/budget-templates/${uuid}/documents`,
      icon: "",
      page: "documents",

      label: "Documents",
    },
    {
      link: `/budget-templates/${uuid}/budgets`,
      icon: "",
      page: "budgets",

      label: "Budgets",
    },
  ];

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPageAdminFilter
        titlePage={`Budget`}
        crumbs={[
          {
            name: "Budget Templates",
            link: "/budget-templates",
          },
          {
            name: budgetTemplate?.description,
            link: "/",
            active: true,
          },
        ]}
      />
      <ProfilePage
        tabs={tabs}
        header={
          <DashboardHeader>
            <DashboardH3>{budgetTemplate?.description}</DashboardH3>
            {budgetTemplate?.approved_amount !== budgetTemplate.amount && (
              <Badge color="warning">
                Currently Approved Amount:{" "}
                {money.format(budgetTemplate?.approved_amount)}
              </Badge>
            )}
          </DashboardHeader>
        }
        content={
          <ShowPage
            budgetTemplate={budgetTemplate}
            setBudgetTemplate={setBudgetTemplate}
            budgets={budgets}
            fetchingBudgets={fetchingBudgets}
          />
        }
        sideBar={
          <>
            <ApprovalButton approvalAction={budgetTemplate?.approval_action} />
            <CreateBudgetButton budgets={budgets} setBudgets={setBudgets} />
          </>
        }
      />
    </>
  );
};

export default Profile;
