import { Field, InjectedFormProps, reduxForm } from "redux-form";
import buttonGroup from "../utils/buttonGroup";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import { TestSuiteTemplateIncrement } from "./testSuiteTypes";

const TemplateDetailForm = (props: InjectedFormProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <FormHeader>Template Details</FormHeader>

        <div className="col-lg-3 form-group">
          <Field
            component={RenderField}
            type="number"
            label="Samples per page"
            name="samples_per_page"
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            component={RenderField}
            type="number"
            label="Increment Amount"
            name="increment_amount"
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            component={buttonGroup}
            options={[
              {
                label: "Columns",
                value: TestSuiteTemplateIncrement.Columns,
              },
              {
                label: "Rows",
                value: TestSuiteTemplateIncrement.Rows,
              },
            ]}
            label="Increments By"
            name="increments_by"
          />
        </div>
        <div className="col-lg-3 d-flex align-items-end form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({});

export default form(TemplateDetailForm);
