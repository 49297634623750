import React from "react";
import { Link } from "react-router-dom";

const EmptyBudgetTemplate = () => {
  return (
    <div className="d-flex flex-wrap w-100 justify-content-center p-3">
      <p className="fw-bold w-100 tx-inverse text-center mb-2">
        No Budget Templates Found.
      </p>
      <p className="w-100 text-center mb-0 text-secondary">
        Budget template allows you to setup recurring budgets that reoccur on
        monthly or yearly basis.
      </p>
      <p className="w-100 text-center mb-0">
        <Link to="/budget-templates/add" className="btn btn-link p-1 fw-bold">
          Click here
        </Link>
        <span className="text-secondary">to get started.</span>
      </p>
    </div>
  );
};

export default EmptyBudgetTemplate;
