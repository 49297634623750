import React from "react";
import { IEmploymentHeroIntegration } from "./employmentHeroTypes";
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/auth-context";
import useApi from "../../api/useApi";
import { IUseApi } from "../../api/apiTypes";
import errorSwal from "../../utils/errorSwal";

const EmploymentHeroIntegration = ({
  isIntegrated,
}: {
  isIntegrated?: IEmploymentHeroIntegration;
}) => {
  const { user } = useAuth();

  const {
    takeAction,
    setData,
    data,
  }: IUseApi<{
    error?: string;
  }> = useApi();

  const oauthHandler = () => {
    takeAction("index", "integrations/employment-hero/oauth-url")
      .then(({ data }) => {
        window.location.replace(data.link);
      })
      .catch(errorSwal);
  };

  return (
    <div className="col-lg-4">
      {isIntegrated ? (
        <>
          <Link
            to={`/integrations/employment-hero/${isIntegrated.uuid}/details`}
          >
            <div className="d-flex justify-content-center align-items-center bg-white p-4 border rounded-lg">
              <img
                style={{
                  width: "130px",
                }}
                src="/img/employment-hero.png"
                alt="Employment Hero"
              />
            </div>
          </Link>
          <a onClick={oauthHandler} href="#">
            Get new permissions
          </a>
        </>
      ) : (
        <button onClick={oauthHandler} className="border border-0 p-0 w-100">
          <div className="d-flex justify-content-center align-items-center bg-white p-4 border rounded-lg">
            <img
              style={{
                width: "130px",
              }}
              src="/img/employment-hero.png"
              alt="Employment Hero"
            />
          </div>
        </button>
      )}
    </div>
  );
};

export default EmploymentHeroIntegration;
