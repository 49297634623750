import dayjs from "dayjs";
import useFilter, { IFilter } from "../hooks/useFilter";
import useObserver from "../hooks/useObserver";
import usePagination from "../hooks/usePagination";
import { InvoiceStatus } from "../invoices/invoiceTypes";
import { JobStatus } from "../jobs/jobTypes";
import Empty from "../utils/Empty";
import CustomScaleLoader from "../utils/scaleLoader";
import useProjectManagerJobs from "./hooks/useProjectManagerJobs";
import JobManagerFilters from "./JobManagerFilters";
import { ProjectFilters } from "./ProjectManagerDashboard";
import ProjectManagerJob from "./ProjectManagerJob";
import {
  IProjectManagerJob,
  IProjectManagerProject,
} from "./projectManagerTypes";

export type JobFilterType =
  | "status"
  | "overdue"
  | "critical_path"
  | "organisation_branches.id"
  | "project_manager"
  | "user_is_part_of_job"
  | "invoiceStatus"
  | "date_between";

const ProjectManagerJobs = ({
  viewJobs,
  project,
  projectFilters,
}: {
  viewJobs: boolean;
  project: IProjectManagerProject;
  projectFilters: ProjectFilters;
}) => {
  const { searchQuery } = usePagination();

  const { stringified, filters, toggleFilter, filterCount } =
    useFilter(jobFilters);

  const periodFilter = getPeriodFilterQuery(projectFilters) ?? "";

  const { data, fetchNextPage, isFetchingNextPage, isFetching, status } =
    useProjectManagerJobs(
      project.uuid,
      viewJobs,
      searchQuery + stringified + periodFilter,
    );

  const intersection = useObserver(
    () => !isFetchingNextPage && fetchNextPage(),
  );

  const jobs: IProjectManagerJob[] | undefined = data?.pages
    .map((page) => page.data)
    .flat();

  if (!viewJobs) {
    return null;
  }

  return (
    <div className="mt-4 border-top">
      <div className="d-flex align-items-center">
        <p className="slim-card-title mt-4">
          Jobs for {project.number} - {project.name}
        </p>
        <div className="ms-auto">
          <JobManagerFilters
            filterCount={filterCount}
            toggleFilter={toggleFilter}
            filters={filters}
          />
        </div>
      </div>
      {status === "loading" ? (
        <CustomScaleLoader>Fetching Jobs...</CustomScaleLoader>
      ) : jobs?.length === 0 ? (
        <Empty height="50%" width="25%">
          <div className="text-center  mb-5">
            <p className="tx-inverse mb-1">No jobs found. </p>
            {getPeriodFilterQuery(projectFilters) && (
              <p className="mb-0">
                You currently have a period filter in place at the project
                level, this will also filter these jobs.
              </p>
            )}
          </div>
        </Empty>
      ) : (
        <>
          <div className="space-y-5">
            {jobs?.map((job) => (
              <ProjectManagerJob
                projectFilters={projectFilters}
                key={job.uuid}
                job={job}
              />
            ))}
          </div>
          <div ref={intersection} />
          {(isFetchingNextPage || isFetching) && (
            <div className="mt-3">
              <CustomScaleLoader>Fetching Extra Jobs...</CustomScaleLoader>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const jobFilters: IFilter<
  JobFilterType,
  JobStatus | boolean | InvoiceStatus | string
>[] = [
  {
    name: "status",
    label: "Status",
    multiple: true,
    options: [
      {
        label: "Pending",
        value: JobStatus.Pending,
      },
      {
        label: "In Progress",
        value: JobStatus.InProgress,
      },
      {
        label: "On Hold",
        value: JobStatus.OnHold,
      },
      {
        label: "Cancelled",
        value: JobStatus.Cancelled,
      },
      {
        label: "Completed",
        value: JobStatus.Completed,
      },
    ],
  },
  {
    label: "Overdue",
    name: "overdue",
    options: [
      {
        label: "Overdue",
        value: true,
      },
      {
        label: "Not Overdue",
        value: false,
      },
    ],
  },
  {
    label: "Critical Path",
    name: "critical_path",
    options: [
      {
        label: "Critical Path",
        value: true,
      },
      {
        label: "Not Critical",
        value: false,
      },
    ],
  },
  {
    label: "Invoice Status",
    name: "invoiceStatus",
    options: [
      {
        label: "Awaiting Invoice",
        value: InvoiceStatus.AwaitingInvoice,
      },
      {
        label: "Invoiced",
        value: InvoiceStatus.Invoiced,
      },
      {
        label: "Paid",
        value: InvoiceStatus.Paid,
      },
      {
        label: "Invoice Not Required",
        value: InvoiceStatus.InvoiceNotRequired,
      },
    ],
  },
  {
    label: "Finish Date",
    name: "date_between",
    options: [
      {
        label: "This Month",
        value: `${dayjs().startOf("month").format("YYYY-MM-DD")},${dayjs()
          .endOf("month")
          .format("YYYY-MM-DD")}`,
      },
      {
        label: "This Week",
        value: `${dayjs().startOf("week").format("YYYY-MM-DD")},${dayjs()
          .endOf("week")
          .format("YYYY-MM-DD")}`,
      },
      {
        label: "Next Week",
        value: `${dayjs()
          .startOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD")},${dayjs()
          .endOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD")}`,
      },
    ],
  },
];

const getPeriodFilter = (projectFilters: ProjectFilters) => {
  const filter = projectFilters.find((filter) => filter.name === "period");

  return filter?.options?.find((option) => option.selected);
};

export const getPeriodFilterQuery = (projectFilters: ProjectFilters) => {
  const filteredValue = getPeriodFilter(projectFilters);

  if (filteredValue) {
    return `&filter[period]=${filteredValue?.value}`;
  }

  return null;
};

export default ProjectManagerJobs;
