import _ from "lodash";

const parseFolders = (filesAndFolders, pathName = "path") => {
  const filesAndFoldersWithSplitPath = filesAndFolders.map((folder) => {
    folder.splitPath = folder[pathName].substring(1).split("/");
    return folder;
  });

  return getformattedChildren(filesAndFoldersWithSplitPath);
};

const getformattedChildren = (files, index = 0) => {
  const filteredFiles = _.groupBy(files, (file) => file.splitPath[index]);

  return Object.values(filteredFiles).map((files) => {
    return index === files[0].splitPath.length - 1
      ? files[0]?.emptyFolder
        ? { folder: files[0]?.name, children: [] }
        : files[0]
      : {
          folder: files[0].splitPath[index],
          children: getformattedChildren(files, index + 1),
        };
  });
};

export default parseFolders;
