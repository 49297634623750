import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import PaginationCardHeader from "../pagination/PaginationCardHeader";
import usePermissionGroups from "./hooks/usePermissionGroup";
import { PermissionGroup } from "./permissionGroupTypes";
import { User, Position } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const PermissionGroupList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/user-levels/" />
      <HeaderPage
        titlePage="Permission Groups"
        pageDescription={
          <>
            An permission group is a security mechanism used to restrict access
            to sensitive data based on a set of rules defined by an
            administrator, and a checkbox represents a permission that can be
            granted or denied to a user or position.
          </>
        }
        relatedLinks={[
          {
            name: "Staff",
            link: "/users",
            model: User,
          },
          {
            name: "Positions",
            link: "/positions",
            model: Position,
          },
        ]}
        crumbs={[]}
      />
      <PaginatedList
        listName="permissionGroupList"
        indexHook={usePermissionGroups}
        originalFilters={[]}
        addLink="/permission-groups/add"
        itemCard={({ item }: { item: PermissionGroup }) => (
          <PaginatedCard
            header={
              <PaginationCardHeader
                colour="info"
                link={`/permission-groups/${item.uuid}/details`}
                title={item.name}
                uuid={item.uuid}
                key={item.uuid}
              />
            }
            info={[
              {
                name: "Subject Amount",
                value: (
                  <Badge
                    color={
                      item?.subjects_count && item?.subjects_count > 0
                        ? "info"
                        : "warning"
                    }
                    className="rounded-pill "
                  >
                    {item.subjects_count ?? 0}
                  </Badge>
                ),
              },
            ]}
          />
        )}
      />
    </>
  );
};

export default PermissionGroupList;
