import { ConvertToWorkFormProps, ConvertType } from "./ConvertToJobModal";
import ConvertToJobForm from "./ConvertToJobForm";
import ConvertToProjectForm from "./ConvertToProjectForm";
import ConvertToTenderForm from "./ConvertToTenderForm";
import { TodoTask } from "./todoTypes";
import { useAuth } from "../../context/auth-context";

const ShowConvertPage = ({
  convertType,
  toggle,
  setData,
  todo,
}: {
  convertType?: ConvertType;
  todo?: TodoTask;
} & ConvertToWorkFormProps) => {
  const { user } = useAuth();

  switch (convertType) {
    case "job":
      return (
        <ConvertToJobForm
          initialValues={{
            name: todo?.title,
            description: todo?.description,
            scheduled_start_date: todo?.due_date,
            scheduled_finish_date: todo?.due_date,
            project_manager: user?.id,
          }}
          toggle={toggle}
          setData={setData}
        />
      );
    case "project":
      return (
        <ConvertToProjectForm
          initialValues={{
            name: todo?.title,
            description: todo?.description,
            scheduled_start_date: todo?.due_date,
            scheduled_finish_date: todo?.due_date,
            project_manager: user?.id,
            status: "0",
          }}
          toggle={toggle}
          setData={setData}
        />
      );
    case "tender":
      return (
        <ConvertToTenderForm
          initialValues={{
            job: {
              description: todo?.description,
              scheduled_start_date: todo?.due_date,
              scheduled_finish_date: todo?.due_date,
              project_manager: user?.id,
            },
            tender: {
              project_name: todo?.title,
            },
          }}
          toggle={toggle}
          setData={setData}
        />
      );
    // case "CPIR":
    //   return (
    //     <ConvertToCpirForm
    //       initialValues={{
    //         title: todo?.title,
    //       }}
    //       toggle={toggle}
    //       setData={setData}
    //     />
    //   );
    default:
      return null;
  }
};

export default ShowConvertPage;
