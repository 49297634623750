const PRICING_TYPES = [
  { value: "App\\Models\\TestMethod", label: "Test Method" },
  { value: "App\\Models\\BillableItem", label: "Billable Item" },
  { value: "App\\Models\\TestSuite", label: "Test Suite" },
  {
    value: "App\\Models\\User",
    label: "User",
  },
];

export default PRICING_TYPES;
