import React from "react";

const MoistureData = ({ pointData }) => {
  if (
    !pointData.moisture.dry_moisture ||
    !pointData.moisture.dry_moisture.moisture_content
  ) {
    return null;
  }

  return (
    <>
      <div className="form-group col-12">
        <label>Dry Mass (g)</label>
        <p className="tx-inverse">{pointData.moisture.dry_moisture.mass}</p>
      </div>
      <div className="form-group col-12">
        <label>Moisture Content (%)</label>
        <p className="tx-inverse">
          {pointData.moisture.moisture_content.toFixed(2)}
        </p>
      </div>
    </>
  );
};

export default MoistureData;
