import dayjs from "dayjs";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import ExternalInsuranceForm from "./ExternalInsuranceForm";
import { findInsurance } from "./ExternalPage";

const ExternalInsurances = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const { upload, setBucket } = useUploadMultipleToS3();

  const {
    data: insurances,
    takeAction,
    loading,
  } = useApi(`suppliers/${uuid}/required-insurances${location.search}`, []);

  const onSubmit = (values) => {
    const documents = values.insurances.map(({ document }) => document);

    return upload(documents)
      .then((uploaded) => {
        const data = {
          insurances: values.insurances.map((insurance) => {
            return {
              ...insurance,
              expiry_date: insurance.expiry_date.format("YYYY-MM-DD"),
              document: findInsurance(insurance, uploaded),
            };
          }),
        };

        return takeAction("update", `required-insurances/${uuid}`, data);
      })
      .then(() => toast.success("Insurances Updated"))
      .catch((err) => {
        console.log(err);
        errorSwal(err);
      });
  };

  useEffect(() => {
    if (insurances.length > 0) {
      const organisationUuid = insurances[0].organisation.uuid;
      setBucket(
        `organisations/${organisationUuid}/documents/suppliers/${uuid}`,
      );
    }
  }, [insurances]);

  if (loading) {
    return (
      <div className="signin-wrapper">
        <CustomScaleLoader>
          <p>Loading Form...</p>
        </CustomScaleLoader>
      </div>
    );
  }
  return (
    <>
      <div className="signin-wrapper">
        <div className="signin-box signup" style={{ width: "800px" }}>
          <h2 className="slim-logo mb-0">
            <a
              href="https://landing.thebossapp.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              theBOSSapp
            </a>
          </h2>
          {insurances.length > 0 ? (
            <ExternalInsuranceForm
              onSubmit={onSubmit}
              initialValues={{
                insurances: insurances.map((insurance) => ({
                  ...insurance,
                  expiry_date: insurance.expiry_date
                    ? dayjs(insurance.expiry_date).format("DD/MM/YYYY")
                    : null,
                })),
              }}
            />
          ) : (
            <h4 className="mt-3">Great, everything is up to date.</h4>
          )}
        </div>
      </div>
    </>
  );
};

export default ExternalInsurances;
