import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import { BreakType, Timesheet } from "../timesheets/timesheetTypes";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { Job } from "./jobTypes";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";
import dayjs from "dayjs";

const GenerateTimesheetFromJob = (
  props: InjectedFormProps<Timesheet, { job: Job }> & { job: Job },
) => {
  const { job, handleSubmit } = props;

  const history = useHistory();
  const { takeAction }: IUseApi<{}, { data: Timesheet }> = useApi();

  const { user } = useAuth();

  const { data: approvers, setUrl } = useApi(undefined, []);

  const { data: staffRoles } = useApi(`users/${user?.uuid}/staff-roles`, []);

  useEffect(() => {
    if (staffRoles?.length === 1) {
      setUrl(
        `timesheet-approval-users/users?branch_id=${staffRoles[0].branch_id}`,
      );
    }
  }, [staffRoles]);

  const onSubmit: FormSubmitHandler<Timesheet, { job: Job }> = (values) => {
    const productivity: {
      start_time: string;
      finish_time: string;
      job_id: number;
      break_type?: BreakType;
    }[] = [
      {
        start_time: `${values.date} ${job.scheduled_start_time}`,
        finish_time: `${values.date} ${job.scheduled_finish_time}`,
        job_id: job.id,
      },
    ];

    if (values.unpaid_break) {
      productivity.push({
        ...getBreakProductivity(
          `${values.date} ${job.scheduled_start_time}`,
          2,
          values.unpaid_break,
        ),
        job_id: job.id,
        break_type: BreakType.Unpaid,
      });
    }

    if (values.paid_break) {
      productivity.push({
        ...getBreakProductivity(
          `${values.date} ${job.scheduled_start_time}`,
          4,
          values.paid_break,
        ),
        job_id: job.id,
        break_type: BreakType.Paid,
      });
    }

    const data = {
      ...values,
      role_id: values.role_id ?? staffRoles[0].id,
      user_id: user?.id,
      productivity,
    };

    return takeAction("store", "timesheets", data)
      .then(({ data }) => {
        toast.success("Timesheet added");
        history.push(`/timesheets/${data.data.uuid}/details`);
      })
      .catch(formError);
  };

  if (!job.scheduled_start_time || !job.scheduled_finish_time) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormHeader
          extra={
            <p className="mb-0">
              This will automatically put the productivity of your timesheet to
              be this job only.
            </p>
          }
        >
          Create Timesheet from {job.name}
        </FormHeader>
        <FormErrorAlert error={props.error} />
        {staffRoles?.length === 1 ? null : (
          <div className="col-lg-12 form-group">
            <Field
              component={SelectInput}
              options={staffRoles?.map(
                (role: {
                  is_disabled: boolean;
                  dropdown_name: string;
                  id: number;
                  branch_id: number;
                }) => {
                  return {
                    label: role.dropdown_name,
                    value: role.id,
                    disabled: role.is_disabled,
                    branch_id: role.branch_id,
                  };
                },
              )}
              name="role_id"
              label="Role"
              required
              validate={required}
              changeValue={({ branch_id }: { branch_id: number }) => {
                setUrl(`timesheet-approval-users/users?branch_id=${branch_id}`);
              }}
            />
          </div>
        )}

        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            options={approvers}
            name="approver"
            label="Approver"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="date"
            type="date"
            label="Date"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="start_time"
            label="Start Time"
            type="time"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="finish_time"
            label="Finish Time"
            type="time"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="unpaid_break"
            label="Unpaid Break (hours)"
            type="number"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="paid_break"
            label="Paid Break (hours)"
            type="number"
          />
        </div>
        <div className="col-lg-12 form-group">
          <Field
            component={renderToggleInput}
            name="night_shift"
            label="Night Shift"
          />
        </div>
        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            name="remarks"
            label="Remarks"
            textarea
          />
        </div>
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const getBreakProductivity = (
  startTime: string,
  toAdd: number,
  breakAmount: string,
) => {
  return {
    start_time: dayjs(startTime).add(toAdd, "hours").format("YYYY-MM-DD hh:mm"),
    finish_time: dayjs(startTime)
      .add(toAdd + parseFloat(breakAmount), "hours")
      .format("YYYY-MM-DD hh:mm"),
  };
};

const form = reduxForm<Timesheet, { job: Job }>({
  form: "AddTimesheetFromJob",
});

export default form(GenerateTimesheetFromJob);
