export enum CPIRRiskLevels {
  Low = 4,
  Medium = 7,
  High = 15,
  Extreme = 25,
}

export type CPIRStatus = "Waiting for Approval" | "Closed" | "Open";

export interface CPIRIndex {
  id: string;
  uuid: string;
  type?: string;
  raised_by: string;
  raised_to: string;
  status: CPIRStatus;
  target_date_for_closure: string;
  completed_on?: string;
  name: string;
  risk_score?: number;
  link: string;
  closed_at: string;
  title: string;
  risk_level?: string;
  original_type?: string;
}
