import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import formError from "../utils/formError";
import Spinner from "../utils/Spinner";
import RegisterItemForm, { RegisterItemFormProps } from "./RegisterItemForm";
import { toast } from "react-toastify";

const AddRegisterItem = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { data, loading } = useApi(`registers/${uuid}`, {}, true);

  const history = useHistory();

  const { takeAction }: IUseApi = useApi();

  const { upload } = useUploadCustomFields(
    `${user?.active_organisation.uuid}/register-items/${uuid}`,
  );

  const onSubmit: FormSubmitHandler<{}, RegisterItemFormProps> = (values) => {
    return upload(values).then((values) => {
      return takeAction("store", `registers/${uuid}/items`, values)
        .then(({ data }) => {
          queryClient.invalidateQueries(`register-items`);
          history.push(`/register-items/${data.data.uuid}/details`);
          toast.success(`${data?.name} added`);
        })
        .catch(formError);
    });
  };

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage titlePage={`Add new ${data?.name}`} crumbs={[]} />
      <RegisterItemForm
        register={data}
        form="AddRegisterItem"
        onSubmit={onSubmit}
      />
    </>
  );
};

export default AddRegisterItem;
