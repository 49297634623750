import { useQuery } from "react-query";
import api from "../../api/api";
import {
  JobOverviewDashboardFilter,
  getFilterString,
} from "./useJobOverviewDashboard";
import { UserIndex } from "../../user/userTypes";

const getJobOverviewDashboardUsers = (
  search?: string,
  filters?: JobOverviewDashboardFilter[],
) => {
  return api
    .get<{ data: UserIndex[] }>(
      `job-overview-dashboard/users?filter[search]=${search}${getFilterString(
        filters,
      )}`,
    )
    .then(({ data }) => data.data);
};

export default function useJobOverviewDashboardUsers(
  search?: string,
  filters?: JobOverviewDashboardFilter[],
) {
  return useQuery(["job-overview-dashboard-users", { search, filters }], () =>
    getJobOverviewDashboardUsers(search, filters),
  );
}
