import { ApprovalAction } from "./../approvals/approvalTypes";
import { Approval } from "../approvals/approvalTypes";
import { CustomField } from "./../customFields/customFieldTypes";
import { TemplateDocument } from "./../templateDocuments/templateDocumentTypes";
import { TemplateVariable } from "./../templates/templateTypes";

export interface TestMethod {
  name: string;
  method: string;
  id: number;
}

export interface TestSuite {
  uuid: string;
  name: string;
  id: string;
  samples_per_page: number;
  increment_amount: number;
  increments_by: number;
  template: TemplateDocument;
  methods?: TestMethod[];
  statuses: TestSuiteStatus[];
}

export interface TestSuiteProps {
  testSuite: TestSuite;
  setTestSuite: (testSuite: TestSuite) => void;
}

export interface TestSuiteStatus {
  name: string;
  id: number;
  uuid: string;
  custom_fields?: CustomField[];
  test_suite: {
    name: string;
    uuid: string;
  };
  variables: TemplateVariable[];
  order: number;
}

export type TestSuitePages = "details" | "statuses" | "template";

export type TestSuiteStatusPages = "details" | "fields";

export type TestSuiteTestPages = "details" | "tests";

export interface TestSuiteTestProps {
  test: TestSuiteTest;
  setTest: (test: TestSuiteTest) => void;
}

export interface TestSuiteTestSample {
  number: string;
  id: number;
  uuid: string;
}

export interface ShowTestSuiteTestSample {
  statuses: {
    custom_fields: any[];
    custom_form: any[];
    name: string;
    uuid: string;
    id: number;
  }[];
}

export interface TestSuiteTest {
  id: number;
  uuid: string;
  test_suite_id: number;
  completed_at?: string;
  samples: TestSuiteTestSample[];
  test_suite: {
    name: string;
  };
  link: string;
  current_status?: {
    name: string;
  };
  approval?: Approval;
  approval_action?: ApprovalAction;
  test_request: {
    link: string;
    name: string;
    job: {
      name: string;
      link: string;
      branch_id: number;
    };
  };
  deliverable?: {
    name: string;
    link: string;
    has_been_sent: boolean;
  };
}

export enum TestSuiteTemplateIncrement {
  Columns,
  Rows,
}
