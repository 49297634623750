import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import EditRegisterItem from "./EditRegisterItem";
import { RegisterItemPages, RegisterItemProps } from "./registerItemTypes";

const ShowRegisterItemPage = (props: RegisterItemProps) => {
  const { user } = useAuth();

  const { page, uuid } = useParams<{ page: RegisterItemPages; uuid: string }>();

  switch (page) {
    case "details":
      return <EditRegisterItem {...props} />;
    case "documents":
      return (
        <NewProfileDocuments
          model="App\Models\RegisterItem"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/register-items/${uuid}`}
          homeUrl={`register-items/${uuid}/documents`}
        />
      );
  }
};

export default ShowRegisterItemPage;
