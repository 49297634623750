import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useS3Uploader from "../hooks/useS3Uploader";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";

const AddExpenseClaim = () => {
  const { takeAction } = useApi();
  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();
  const history = useHistory();
  const { user } = useAuth();

  const onSubmit = (values) => {
    return upload(
      values.file,
      `organisations/${user.active_organisation.uuid}/expense-claims`,
      (file) => {
        return takeAction("store", "expense-claims", {
          ...values,
          file,
        })
          .then(() => {
            toast.success("Expense Claims Add");
            history.push(`/users/${user.uuid}/expense-claims`);
          })
          .catch((err) => {
            errorSwal(err);
            setUploadPercent(0);
          });
      },
    );
  };

  return (
    <>
      <HeaderPage titlePage="Add New Expense Claim" beta crumbs={[]} />

      <Form
        form="AddExpenseClaim"
        onSubmit={onSubmit}
        uploadPercent={uploadPercent}
      />
    </>
  );
};

export default AddExpenseClaim;
