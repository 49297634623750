import { ApprovalAction } from "./../approvals/approvalTypes";
export interface CustomFieldListItem {
  name: string;
  id: number;
}

export enum FieldType {
  Text,
  Select,
  Toggle,
  File,
  Constant,
  Radio,
  Date,
  Formula,
  RadioButton,
  StarRating,
  FieldArray,
  Signature,
  Moisture,
  CustomValue,
}

export enum Regression {
  Linear = 1,
  Quadratic = 2,
  Polynomial = 3,
  Exponential = 4,
  Logarithmic = 5,
  PowerLaw = 6,
}

export interface CustomField<Types = FieldType> {
  field_attributes: FieldAttributes;
  id: number;
  order: number;
  type: Types;
  uuid: string;
  approval_action?: ApprovalAction;
}

export interface FieldAttributes {
  label: string;
  required?: boolean;
  multiple?: boolean;
  options?: {
    name: string;
    value: number;
  }[];
  url?: AvailableDatabaseFields;
  options_selection?: 1 | 0;
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  text?: string;
  information?: string;
  date_format?: string;
  formula?: string;
  formula_type?: string;
  conditional?: boolean;
  conditionals?: any[];
  logical_operator?: "every" | "some";
  filters?: {
    key: string;
    value: any;
  }[];
  notify?: boolean;
  custom_value_field?: number;
  custom_value_type?: number;
  custom_value?: string;
  type?: "number" | "text" | "password" | "tel" | "url";
  x_axis_label?: string;
  y_axis_label?: string;
  data_series?: IDataSeries[];
}

export type AvailableDatabaseFields =
  | "/custom-fields/projects"
  | "/custom-fields/jobs"
  | "/custom-fields/clients"
  | "/custom-fields/suppliers"
  | "/custom-fields/equipment"
  | "/custom-fields/users"
  | "/custom-fields/branches"
  | "/custom-fields/positions"
  | "/custom-fields/tracking-sites"
  | "/custom-fields/divisions";

export interface DataSeriesValue {
  x_axis_value?: number;
  y_axis_value?: number;
}

export type CustomFieldValue = {
  [key: string]: {
    field_id: number;
    label: string;
    order: number;
    type: FieldType;
    uuid: string;
    value?: any;
  };
};

export interface IDataSeries {
  type?: "values" | "formula";
  regression_line?: Regression;
  regression_line_order?: number;
  formula?: string;
  values?: DataSeriesValue[];
}

export interface CustomFieldNotification {
  notify_type: "App\\Models\\Group" | "App\\Models\\User";
  to_notify?: number;
  condition: "==" | "!=";
  value?: string;
  email: string;
}

export interface CustomDataBaseItem<T = {}> {
  name: string
  id: number,
  disabled?: boolean
} 

export interface CustomFieldEquipment extends CustomDataBaseItem {
  uuid: string
}