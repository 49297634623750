import React from "react";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import { ApprovalAction } from "./approvalTypes";
import { toast } from "react-toastify";

const TransferModal = ({
  toggle,
  modal,
  approvalAction,
  onSuccess,
  transferUsers,
}: {
  toggle: Function;
  modal: boolean;
  approvalAction: ApprovalAction;
  onSuccess?: (action: ApprovalAction) => void;
  transferUsers?: { label: string; value: number }[];
}) => {
  const { takeAction }: IUseApi<{}, { data: ApprovalAction }> = useApi();

  const onSubmit: FormSubmitHandler<{ user_id: number }> = (values) => {
    return takeAction(
      "store",
      `/approval-actions/${approvalAction.uuid}/transfer`,
      values,
    )
      .then(({ data }) => {
        toggle();
        toast.success("Approval Transferred");

        onSuccess?.(data.data);
      })
      .catch(errorSwal);
  };

  const selectInputProps = transferUsers
    ? {
        options: transferUsers,
      }
    : {
        url: "/staff",
        formatData: (data: { name: string; id: number }[]) => {
          return data.map((staff) => ({
            label: staff.name,
            value: staff.id,
          }));
        },
      };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Transfer Approval"
      form="TransferModal"
      onSubmit={onSubmit}
    >
      <div className="col-12 form-group">
        {/** @ts-ignore */}
        <Field
          component={SelectInput}
          {...selectInputProps}
          name="user_id"
          label="User"
        />
      </div>
    </FormModal>
  );
};

export default TransferModal;
