import axios from "../api/api";
import { FETCH_TEST_REQUESTS, UPDATE_TEST_REQUEST } from "./types";

export const fetchTestRequests =
  (id = "") =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(`testrequests/${id}`)
        .then((response) => response.data.data)
        .then((testRequests) =>
          dispatch({
            type: FETCH_TEST_REQUESTS,
            payload: testRequests,
          }),
        )
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });

export const updateTestRequest = (postData) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`testrequests/${postData.testrequest.uuid}`, postData)
      .then((response) => response.data.data)
      .then((testRequest) =>
        dispatch({
          type: UPDATE_TEST_REQUEST,
          payload: testRequest,
        }),
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
