import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";

const SetHead = ({ hTitle = "", children = null, notifications }) => {
  const unreadNotificationLength = notifications
    ? notifications.filter((notification) => !notification.read_at).length
    : 0;
  return (
    <Helmet>
      <title>
        {unreadNotificationLength > 0 ? `(${unreadNotificationLength}) ` : ""}
        {hTitle ? `${hTitle} | ` : ""}theBOSSapp
      </title>
      {import.meta.env.DEV && (
        <link
          rel="icon"
          type="image/png"
          href="/local-favicon.ico"
          sizes="16x16"
        />
      )}
      {children}
    </Helmet>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications.items,
});

export default connect(mapStateToProps, {})(SetHead);
