import React from "react";
import useApi from "../api/useApi.js";
import HeaderPage from "../header/HeaderPage";
import CategoryTable from "./CategoryTable";
import { CPIR } from "../../enums/Model";

const ListCPIRCategories = () => {
  const {
    loading,
    data: categories,
    setData: setCategories,
  } = useApi("cpir-categories", [], true);

  return (
    <>
      <HeaderPage
        titlePage="CPIR Categories"
        pageDescription={
          <>
            The CPIR feature allows organizations to submit, evaluate and
            implement recommendations for corrective, preventative or
            improvement actions, leading to better quality management processes.
          </>
        }
        relatedLinks={[{ name: "CPIRs", link: "/cpirs", model: CPIR }]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "", name: "CPIR Categories", active: true },
        ]}
      />
      <CategoryTable
        categories={categories}
        setCategories={setCategories}
        loading={loading}
      />
    </>
  );
};

export default ListCPIRCategories;
