import { Button } from "reactstrap";
import { Field, initialize, WrappedFieldArrayProps } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import Empty from "../utils/Empty";
import errorSwal from "../utils/errorSwal";
import FormHeader from "../utils/FormHeader";
import LoadingOverlay from "../utils/LoadingOverlay";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import TextButton from "../utils/TextButton";
import { SelectOption } from "../utils/utilTypes";
import RecurringJobDeliverablePricing from "./RecurringJobDeliverablePricing";
import { RecurringJobDeliverablesProps } from "./RecurringJobDeliverables";
import { IRecurringJobDeliverable, RecurringJob } from "./recurringJobTypes";

const RecurringJobDeliverableFields = (
  props: {
    initialValues: any;
  } & WrappedFieldArrayProps<IRecurringJobDeliverable> &
    RecurringJobDeliverablesProps,
) => {
  const { fields, initialValues, setRecurringJob, dispatch, change } = props;
  const { user } = useAuth();

  const { takeAction, loading }: IUseApi = useApi();

  const onDelete = (deliverable: IRecurringJobDeliverable, index: number) => {
    if (!deliverable.uuid) {
      fields.remove(index);
      return;
    }

    return deleteSwal(deliverable.name)
      .then(() =>
        takeAction("destroy", `recurring-job-deliverables/${deliverable.uuid}`),
      )
      .then(({ data }: { data: { data: RecurringJob } }) => {
        fields.remove(index);
        setRecurringJob(data.data);
        dispatch && dispatch(initialize("RecurringJobDeliverables", data.data));
      })
      .catch(errorSwal);
  };

  if (fields.length === 0) {
    return (
      <div className="col-12 mt-5">
        <TextButton className="w-100" onClick={() => fields.push({ name: "" })}>
          <Empty />
          <p className="fw-bolder mt-4 mb-1">No Deliverables Added</p>
          <p className="text-secondary">
            Click here to get started adding deliverables.
          </p>
        </TextButton>
      </div>
    );
  }

  return (
    <>
      {fields.map((field: string, index: number) => {
        return (
          <div className="row position-relative" key={index}>
            <LoadingOverlay loading={loading} />
            <FormHeader>Deliverable {index + 1}</FormHeader>

            <div className="col-lg-12 form-group">
              <Field
                component={RenderField}
                name={`${field}.name`}
                label="Name"
                validate={required}
                required
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                name={`${field}.line_item_prefix`}
                component={RenderField}
                label="Line Item Prefix"
                placeholder="Leave blank if no prefix required."
              />
              <small>
                Add in a note that will be prepended to all line items.
              </small>
            </div>
            <div className="col-lg-6 form-group">
              <Field
                name={`${field}.recipients`}
                multiple
                url={`/projects/${initialValues.project.uuid}/contacts`}
                component={SelectInputAsync}
                formatData={formatData}
                label="Recipients"
              />
              <small className="form-text text-muted">
                Please select the people who you want to send this to.
              </small>
            </div>

            <div className="col-lg-6 form-group">
              <Field
                name={`${field}.contacts`}
                multiple
                url="/users/staff"
                component={SelectInputAsync}
                label="Contacts"
              />
              <small className="form-text text-muted">
                Please select the people from{" "}
                {user?.active_organisation.display_name} who you want to act as
                contacts for this.
              </small>
            </div>
            <RecurringJobDeliverablePricing
              initialValues={initialValues}
              field={field}
              index={index}
              change={change}
              setRecurringJob={setRecurringJob}
              dispatch={dispatch}
            />
            <div className="col-lg-12 form-group d-flex">
              {fields.length === index + 1 && (
                <Button
                  color="secondary"
                  outline
                  onClick={() => fields.push({ name: "" })}
                >
                  Add Deliverable
                </Button>
              )}
              <Button
                onClick={() => onDelete(fields.get(index), index)}
                color="danger"
                outline
                className="ms-auto"
              >
                Delete
              </Button>
            </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col-12 form-group">
          <SubmitButton {...props.meta} />
        </div>
      </div>
    </>
  );
};

const formatData = (data: {
  data: { name: string; contact_id: number }[];
}): SelectOption[] => {
  return data.data.map((contact) => ({
    label: contact.name,
    value: contact.contact_id,
  }));
};

export default RecurringJobDeliverableFields;
