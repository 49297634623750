import _ from "lodash";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import OrganisationListInstance from "./OrganisationListInstance";

const OrganisationList = () => {
  const { user } = useAuth();

  const { data, loading } = useApi(`users/${user.uuid}`, []);

  const organisations = _.orderBy(
    data?.user?.organisation,
    (o) => o.display_name.toLowerCase(),
    "asc",
  );

  if (loading)
    return <CustomScaleLoader>Fetching Organisations...</CustomScaleLoader>;

  return (
    <div className="list-group space-y-5">
      {organisations?.map((org, index) => (
        <OrganisationListInstance index={index} key={org.uuid} org={org} />
      ))}
    </div>
  );
};
export default OrganisationList;
