import { useEffect, useState } from "react";
import { Field, FieldArray, reset } from "redux-form";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import FileInput from "../upload/FileInput";
import renderField from "../utils/renderField";
import required from "../utils/required";
import AddDocketToNameButton from "./AddDocketToNameButton";
import PricingFieldArray from "./PricingFieldArray";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import ContactModal from "../clients/ContactModal";
import errorSwal from "../utils/errorSwal";
import SelectInput from "../form/SelectInput";
import renderToggleInput from "../utils/renderToggleInput";

const FormDeliverable = (props) => {
  const { name, file, job } = props;

  const { user } = useAuth();
  const { toggle, modal } = useModal();

  const [projectContacts, setProjectContacts] = useState([]);
  const [recipients, setRecipients] = useState([]);

  const {
    data: contacts,
    setData: setContacts,
    takeAction,
    refreshData,
  } = useApi(`projects/${job.project.uuid}/contacts`, [], true);

  const onNewClientSubmit = (values, dispatch) => {
    return takeAction(
      "store",
      `organisation-clients/${job.client.uuid}/contacts`,
      values,
    )
      .then(({ data }) => {
        return takeAction("store", "project-contacts", {
          project_id: job.project.id,
          contact_id: data.data.id,
        });
      })

      .then(({ data }) => {
        setContacts([...contacts, data.data]);
        setRecipients([...recipients, data.data]);
        toast.success("Contact Added");
        toggle();
        dispatch(reset("ProjectContacts"));
        refreshData();
      })
      .catch(errorSwal);
  };

  const mapToOptionItems = (contacts) => {
    return contacts.map((contact) => ({
      label: contact.name,
      value: contact.contact_id,
    }));
  };

  useEffect(() => {
    if (contacts?.length !== 0) {
      setProjectContacts(mapToOptionItems(contacts));
    }

    if (recipients?.length !== 0) {
      props?.change?.("deliverable_recipients", mapToOptionItems(recipients));
    }
  }, [contacts, recipients]);

  return (
    <>
      {name && (
        <>
          <div className="col-lg-12">
            <div className="form-group">
              <Field
                name="name"
                component={renderField}
                required
                label="Name"
                validate={required}
              />
            </div>
          </div>
          <AddDocketToNameButton {...props} />
        </>
      )}

      <div className="col-lg-9 form-group">
        <Field
          name="purchase_order"
          component={renderField}
          label="Line Item Prefix"
          placeholder="Leave blank if no prefix required."
        />
        <small className="form-text text-muted">
          Add in a note that will be prepended to all line items.
        </small>
      </div>
      <div className="col-lg-3 form-group">
        <Field
          name="is_progress_claim"
          component={renderToggleInput}
          label="Is Progress Claim"
          information="When checked, the job will not be required to be marked as 'complete' before invoicing the deliverable."
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          name="deliverable_recipients"
          isMulti
          options={projectContacts}
          component={SelectInput}
          label="Recipients"
        />
        <div className="d-flex flex-column">
          <small className="form-text text-muted">
            Please select the people who you want to send this to.
          </small>
          {user?.active_organisation.id !=
          job.project.organisation_id ? null : (
            <small>
              <span className="d-flex align-items-center gap-1">
                <Button
                  type="button"
                  onClick={toggle}
                  color="link"
                  className="p-0 fs-6"
                >
                  Click here
                </Button>
                <span>to add a new contact.</span>
              </span>
            </small>
          )}
        </div>
      </div>

      <div className="col-lg-6 form-group">
        <Field
          name="deliverable_contacts"
          isMulti
          url="/users"
          formatData={(data) => {
            return data.map((d) => ({
              label: d.full_name,
              value: d.id,
            }));
          }}
          component={SelectInput}
          label="Contacts"
        />
        <small className="form-text text-muted">
          Please select the people from {user.active_organisation.display_name}{" "}
          who you want to act as contacts for this.
        </small>
      </div>

      {file && (
        <div className="col-lg-12">
          <hr />
          <div className="form-group">
            <Field name="file" component={FileInput} label="Deliverable File" />
          </div>
        </div>
      )}
      <div className="col-lg-12">
        <hr />
        <FieldArray component={PricingFieldArray} {...props} name="pricing" />
      </div>

      <ContactModal
        toggle={toggle}
        modal={modal}
        title="Add new Contact"
        form="AddClientContact"
        onSubmit={onNewClientSubmit}
      />
    </>
  );
};

export default FormDeliverable;
