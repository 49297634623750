import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const DeleteClientButton = ({ client }) => {
  const { uuid } = useParams();

  const history = useHistory();

  const { takeAction, loading } = useApi();

  const deleteClient = () => {
    return deleteSwal(client.display_name)
      .then(() => takeAction("destroy", `organisation-clients/${uuid}`))
      .then(() => {
        history.push("/clients");
        toast.success(`${client.display_name} deleted.`);
      })
      .catch(errorSwal);
  };

  if (client.form_filled_at) {
    return null;
  }

  return (
    <Button
      color="danger"
      className="mt-3"
      block
      outline
      onClick={deleteClient}
      disabled={loading}
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </Button>
  );
};

export default DeleteClientButton;
