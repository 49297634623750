import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import RenderField from "../utils/renderField";
import { money } from "../form/formatters";
import { Fragment } from "react";
import FormHeader from "../utils/FormHeader";
import SelectInput from "../form/SelectInput";
import useApi from "../api/useApi";
import required from "../utils/required";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";

const LoanFees = ({ fields }: WrappedFieldArrayProps) => {
  const { data: accounts } = useApi("chart-of-accounts", [], true);

  const { data: branches } = useApi("organisation-branches", [], true);

  const { takeAction } = useApi();

  const deleteFee = (fee: { uuid: string }, index: number) => {
    if (!fee.uuid) {
      fields.remove(index);
      return;
    }

    return deleteSwal()
      .then(() => takeAction("destroy", `loan-fees/${fee.uuid}`))
      .then(() => {
        fields.remove(index);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <FormHeader>Loan Fees</FormHeader>
      {fields.map((field, index) => {
        const fee = fields.get(index);

        return (
          <Fragment key={index}>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.name`}
                label="Name"
                validate={required}
                required
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.amount`}
                {...money}
                label="Amount"
                validate={required}
                required
              />
            </div>
            <div className="col-lg-5 form-group">
              <Field
                component={SelectInput}
                name={`${field}.account_id`}
                options={accounts.map((account: any) => {
                  return {
                    label: `${account.name} - ${account.code}`,
                    value: account.id,
                  };
                })}
                label="Account"
                validate={required}
                required
              />
            </div>
            <div className="col-lg-5 form-group">
              <Field
                component={SelectInput}
                name={`${field}.branch_id`}
                options={branches.map((branch: any) => {
                  return {
                    label: branch.name,
                    value: branch.id,
                  };
                })}
                label="Branch"
                validate={required}
                required
              />
            </div>
            <div className="col-lg-2 form-group d-flex align-items-end">
              <Button
                block
                color="danger"
                outline
                onClick={() => deleteFee(fee, index)}
              >
                Remove
              </Button>
            </div>
          </Fragment>
        );
      })}
      <div className="col-12">
        <Button outline onClick={() => fields.push({})}>
          Add Fee
        </Button>
      </div>
    </>
  );
};

export default LoanFees;
