import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import errorSwal from "../utils/errorSwal";
import EquipmentForm from "./EquipmentForm";
import DocsModal from "../utils/DocsModal";

const AddEquipment = props => {
  const history = useHistory();

  const { takeAction } = useApi();

  const { user } = useAuth();

  const { loading, data, setData } = useApi(
    `/custom-fields?model=${encodeURIComponent("App\\Models\\Equipment")}`,
    [],
    true,
  );

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/equipment`,
  );

  const onSubmit = values => {
    return upload(values)
      .then(values => takeAction("store", `equipment`, values))
      .then(({ data }) => {
        toast.success(`${data.data.name} added successfully`);
        history.push(`/equipment/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/equipment-asset-management/equipment/" />
      <HeaderPage
        titlePage="New Equipment"
        crumbs={[
          { link: "equipment", name: "Equipment" },
          { link: "equipment/add", name: "New Equipment", active: true },
        ]}
      />
      <EquipmentForm
        onSubmit={onSubmit}
        form="AddEquipment"
        {...props}
        customFields={data}
      />
    </>
  );
};

export default AddEquipment;
