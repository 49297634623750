import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { reduxForm, submit, reset } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import FormDivision from "./Form";
import errorSwal from "../utils/errorSwal";
import { destroy } from "../../api/divisions";
import isSubmitting from "../utils/submitting";

const DivisionModal = (props) => {
  const {
    toggle,
    modal,
    initialValues,
    handleSubmit,
    dispatch,
    uuid,
    removeDivision,
    submitting,
  } = props;

  const [deleting, setDeleting] = useState(false);

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {initialValues ? initialValues.name : "Add new Division"}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <FormDivision {...props} />
        </form>
      </ModalBody>
      <ModalFooter>
        {!deleting && (
          <SubmitButton
            {...props}
            onClick={() => dispatch(submit("Division"))}
          />
        )}
        {!submitting && initialValues && (
          <button
            type="button"
            className="btn btn-outline-danger"
            disabled={deleting}
            onClick={() => {
              setDeleting(true);
              destroy(uuid)
                .then(({ data }) => removeDivision(data))
                .then(() => setDeleting(false));
            }}
          >
            {isSubmitting(
              deleting,
              <>
                <i className="fa fa-trash mg-r-5" /> Delete
              </>,
              "Deleting...",
            )}
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

const submitForm = (
  values,
  _,
  { setResource, dispatch, reset, formSubmit, uuid, toggle },
) => {
  return formSubmit(values, uuid)
    .then(({ data }) => {
      setResource(data.data);
      toggle();
      dispatch(reset("Division"));
    })
    .catch(errorSwal);
};

const form = reduxForm({
  form: "Division",
  onSubmit: submitForm,
  enableReinitialize: true,
});

export default form(DivisionModal);
