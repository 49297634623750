import React from "react";
import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import VariableList from "../templates/VariableList";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import { DocketType } from "./docketTypeTypes";
import ShowDocketTypePage from "./ShowDocketTypePage";

export type DocketTypeProfilePages = "details" | "fields" | "template";

export interface DocketTypeProps {
  docketType?: DocketType;
  setDocketType: (docketType: DocketType) => void;
}

const DocketTypeProfile = () => {
  const { uuid, page } = useParams<{
    uuid: string;
    page: DocketTypeProfilePages;
  }>();

  const { takeAction }: IUseApi = useApi();

  const {
    data: docketType,
    setData: setDocketType,
    loading,
  }: IUseApi<DocketType> = useApi(
    `docket-types/${uuid}`,
    { name: "", custom_fields: [] },
    true,
  );

  const onSubmit: FormSubmitHandler<DocketType> = (values) => {
    return takeAction("update", `docket-types/${uuid}`, values)
      .then(({ data }) => {
        setDocketType(data.data);
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <HeaderPage
        crumbs={[
          { name: "Docket Types", link: "docket-types" },
          { name: docketType?.name ?? "-", link: "docket-types", active: true },
        ]}
        titlePage={docketType?.name ?? "-"}
      />
      <Profile<DocketTypeProfilePages>
        content={
          <ShowDocketTypePage
            onSubmit={onSubmit}
            docketType={docketType}
            setDocketType={setDocketType}
          />
        }
        header={
          <DashboardHeader>
            <DashboardH3>{docketType?.name}</DashboardH3>
          </DashboardHeader>
        }
        sideBar={
          page === "template" ? (
            <div className="mt-3">
              <VariableList variables={docketType?.template_variables ?? []} />
            </div>
          ) : null
        }
        tabs={[
          {
            label: "Details",
            page: "details",
            icon: "",
            link: `/docket-types/${uuid}/details`,
          },
          {
            label: "Fields",
            page: "fields",
            icon: "",
            link: `/docket-types/${uuid}/fields`,
          },
          {
            label: "Template",
            page: "template",
            icon: "",
            link: `/docket-types/${uuid}/template`,
          },
        ]}
      />
    </>
  );
};

export default DocketTypeProfile;
