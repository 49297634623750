import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Button } from "reactstrap";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import CustomScaleLoader from "../../utils/scaleLoader";
import Labels from "./Labels";

const PrintSampleLabels = () => {
  const history = useHistory();
  const ref = useRef();

  const [currentPage, setCurrentPage] = useState(1);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const { data: samples, response, setUrl, loading } = useApi();

  useEffect(() => {
    setUrl(`samples/labels${history.location.search}&page=${currentPage}`);
  }, [currentPage]);

  return (
    <>
      <HeaderPage
        titlePage="Print Sample Labels"
        crumbs={[
          {
            link: samples?.[0].project?.link,
            name: samples?.[0].project?.name,
          },
          {
            link: samples?.[0].test_request?.link,
            name: samples?.[0].test_request?.name,
          },
          {
            link: "",
            name: "Print Sample Labels",
            active: true,
          },
        ]}
      />
      <Button className="mb-3" outline onClick={handlePrint}>
        Print Labels
      </Button>

      <div className="pagination-wrapper justify-content-between border-top-0 px-0">
        <ul className="pagination">
          <li className="page-item">
            <Button onClick={() => setCurrentPage(1)} className="page-link">
              {"<<"}
            </Button>
          </li>
        </ul>
        <ul className="pagination">
          {response?.data?.meta &&
            [...Array(response?.data?.meta?.last_page).keys()].map((i) => {
              return (
                <li
                  className={`page-item ${
                    i + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <Button
                    className="page-link"
                    key={i}
                    onClick={() => setCurrentPage(i + 1)}
                  >
                    {i + 1}
                  </Button>
                </li>
              );
            })}
        </ul>
        <ul className="pagination">
          <li className="page-item">
            <Button
              onClick={() => setCurrentPage(response?.data?.meta?.last_page)}
              className="page-link"
            >
              {">>"}
            </Button>
          </li>
        </ul>
      </div>
      {loading ? (
        <CustomScaleLoader>Fetching Samples...</CustomScaleLoader>
      ) : (
        <Labels samples={samples} ref={ref} />
      )}
    </>
  );
};

export default PrintSampleLabels;
