import { Field, reduxForm } from "redux-form";
import { money } from "../form/formatters";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const BasicForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            name="type"
            type="text"
            validate={required}
            required
            component={RenderField}
            label="Type"
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            name="group"
            validate={required}
            required
            url="/equipment-type-groups"
            component={SelectInputAsync}
            label="Group"
            formatData={(data) =>
              data.data.map((d) => ({
                label: d.name,
                value: d.id,
              }))
            }
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            name="replacement_value"
            type="text"
            component={RenderField}
            label="Replacement Value"
            {...money}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="rate"
            type="text"
            component={RenderField}
            label="Rate"
            {...money}
          />
          <small>
            Rate is how much the equipment type will cost per day to rent. Use
            this in conjunction with project equipment to get an overview of the
            cost of a project.
          </small>
        </div>

        <div className="col-lg-12 form-group">
          <Field
            name="remarks"
            textarea
            component={RenderField}
            label="Remarks"
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({
  form: "BasicEquipmentType",
});

export default form(BasicForm);
