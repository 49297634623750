import axios from "../api/api";
import { FETCH_COMPACTIONS, UPDATE_COMPACTION } from "../actions/types";

export const fetchCompactions =
  (id = "") =>
  (dispatch) => {
    return axios
      .get(`/compactions/${id}`)
      .then((response) => response.data.data)
      .then((compactions) =>
        dispatch({
          type: FETCH_COMPACTIONS,
          payload: compactions,
        }),
      );
  };

export const updateCompaction = (postData) => (dispatch) => {
  return axios
    .put(`/compactions/${postData.uuid}`, postData)
    .then(({ data }) =>
      dispatch({
        type: UPDATE_COMPACTION,
        payload: data.data,
      }),
    );
};

export const updateCompactionPoint = (data) => (dispatch) => {
  return axios.put(`/compaction-points/${data.uuid}`, data);
};
