import { Field } from "redux-form";
import AddMultiple from "../form/AddMultiple";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SupplierNameField from "./SupplierNameField";
import { SimilarSupplier, SupplierType } from "./supplierTypes";

interface SupplierFillFieldsProps {
  similarSuppliers: SimilarSupplier[];
  setUrl: (url: string) => void;
  setData: (data: any[]) => void;
  data: SupplierType[];
  url: string;
  loading: boolean;
}

const SupplierFillFields = (props: SupplierFillFieldsProps) => {
  const { data, loading } = props;

  return (
    <>
      <div className="col-lg-6 form-group">
        <SupplierNameField
          setUrl={props.setUrl}
          setData={props.setData}
          url={props.url}
          similarSuppliers={props.similarSuppliers}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={AddMultiple}
          name="contact_email"
          label="Contact Email/s"
          validate={required}
          required
        />
        <small>
          When sending notifications to this supplier, the first email address
          in this list will be the address the email is sent to.
        </small>
      </div>
      <div className="col-lg-3 form-group">
        <Field
          component={renderToggleInput}
          name="abn_holder"
          label="ABN Holder"
        />
      </div>
      <div className="col-lg-3 form-group">
        <Field
          component={renderToggleInput}
          name="direct_debit"
          label="Direct Debit"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInputAsync}
          name="type_id"
          label="Supplier Type"
          validate={required}
          required
          loading={loading}
          options={data?.map((d) => ({
            label: d.name,
            value: d.id,
          }))}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={renderToggleInput}
          name="is_subcontractor"
          label="Subcontractor"
        />
      </div>
    </>
  );
};

export default SupplierFillFields;
