import React from "react";
import { formatNumber } from "../dashboard/OverviewCard";
import SpreadPrice from "./SpreadPrice";
const PriceTd = props => {
  return (
    <td {...props.tdProps}>
      <SpreadPrice {...props} />
    </td>
  );
};

export default PriceTd;
