import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import SelectInput from "../form/SelectInput";
import FileInput from "../upload/FileInput";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { BrandingTheme, IntegrationBrandingTheme } from "./brandingThemeTypes";

export interface BrandingThemeFormProps {
  showFile?: boolean;
  external_integration_id?: string;
  percent?: number;
}

const BrandingThemeForm = (
  props: InjectedFormProps<BrandingTheme, BrandingThemeFormProps> &
    BrandingThemeFormProps,
) => {
  const { data }: IUseApi<IntegrationBrandingTheme[]> = useApi(
    "integration-branding-themes",
    [],
  );

  const {
    showFile = false,
    handleSubmit,
    external_integration_id,
    percent = 0,
  } = props;

  const integrationBrandingThemes = data as IntegrationBrandingTheme[];

  const brandingThemeOptions = integrationBrandingThemes.map((theme) => ({
    label: theme.name,
    value: theme.id,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="name"
            label="Name"
            validate={required}
            required
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            type="email"
            label="Email"
            name="email"
            validate={required}
            required
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            label="Organisation Name"
            name="organisation_name"
            validate={required}
            required
          />
        </div>
        {integrationBrandingThemes?.length > 0 ? (
          <div className="col-12 form-group">
            <Field
              component={SelectInput}
              name="external_integration_id"
              label="External Branding Theme"
              options={brandingThemeOptions}
            />
            <small className="d-block">
              Please note that branding themes are not able to be synced to
              external providers.
            </small>
            <small className="d-block">
              This means that any updates you make to the branding theme on
              theBOSSapp, will not by synced to your accounting provider.
            </small>
          </div>
        ) : null}

        {!external_integration_id && showFile && (
          <div className="col-12 form-group">
            <Field
              component={FileInput}
              label="Logo"
              name="logo"
              percent={percent}
            />
          </div>
        )}
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    external_integration_id: selector(state, "external_integration_id"),
  };
};

const form = reduxForm<BrandingTheme, BrandingThemeFormProps>({});

export default connect(mapStateToProps, {})(form(BrandingThemeForm));
