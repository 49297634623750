import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import React from "react";
import { useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";

export interface ProjectEquipmentRate {
  date: string;
  id: string;
  uuid: string;
  rate: number;
  equipment: {
    name: string;
    link: string;
  };
}

interface ProjectEquipmentRatesListProps {
  rates: ProjectEquipmentRate[];
}

const ProjectEquipmentRatesList = ({
  rates,
}: ProjectEquipmentRatesListProps) => {
  const columnHelper = createColumnHelper<ProjectEquipmentRate>();

  const { number } = useParams<{ number: string }>();

  const queryClient = useQueryClient();

  const { takeAction }: IUseApi = useApi();

  const deleteRate = (deleted: ProjectEquipmentRate) => {
    return deleteSwal(deleted.equipment.name)
      .then(() =>
        takeAction("destroy", `equipment-project-rates/${deleted.uuid}`),
      )
      .then(() => {
        toast.success("Rate removed from project");
        queryClient.invalidateQueries(`project-equipment-rates/${number}`);
      })
      .catch(errorSwal);
  };

  const columns = React.useMemo<ColumnDef<ProjectEquipmentRate, any>[]>(
    () => [
      columnHelper.accessor("equipment.name", {
        header: "Equipment",
        cell: (info) => {
          return (
            <Link to={info.row.original.equipment.link}>{info.getValue()}</Link>
          );
        },
      }),
      columnHelper.accessor("date", {
        header: "Date",
        cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY"),
      }),
      columnHelper.accessor("rate", {
        header: "Rate",
        cell: (info) => money.format(info.getValue()),
        footer: `Total ${money.format(rates.reduce((a, b) => a + b.rate, 0))}`,
      }),
      columnHelper.display({
        header: "Delete",
        cell: (info) => {
          return (
            <DeleteButton
              deleteFunction={() => deleteRate(info.row.original)}
            />
          );
        },
      }),
    ],

    [rates],
  );

  return <ReactTable disableSearch columns={columns} data={rates} />;
};

export default ProjectEquipmentRatesList;
