import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import Form from "./Form";
import axios from "../api/api";

const Edit = (props) => {
  const [loading, setLoading] = useState(true);
  const [qualification, setQualification] = useState({});
  const { match } = props;
  const { uuid } = match.params;

  const onSubmit = (values) => {
    return axios
      .put(`/qualifications/${values.uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.name} updated successfully`);
        setQualification(data.data.qualification);
      })
      .catch(errorSwal);
  };

  useEffect(() => {
    axios
      .get(`/qualifications/${uuid}`)
      .then(({ data }) => setQualification(data.data.qualification))
      .catch(errorSwal)
      .then(() => setLoading(false));
  }, []);

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={qualification.name}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisations" },
          {
            link: "/qualifications",
            name: "Licenses/Registrations/Qualifications",
          },
          { link: "", name: qualification.name, active: true },
        ]}
      />
      <Form initialValues={qualification} onSubmit={onSubmit} />
    </>
  );
};

export default Edit;
