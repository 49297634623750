import { useState } from "react";
import { Field, InjectedFormProps } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import FileInput from "../upload/FileInput";
import required from "../utils/required";
import Toggle from "react-toggle";
interface AnalysedReceipt {
  supplier_name?: string;
  date?: string;
  total?: string;
  items?: {
    description?: string;
    price?: number;
    quantity?: number;
  }[];
}

const ReceiptField = (props: InjectedFormProps) => {
  const { change } = props;
  const { upload, uploadPercent } = useS3Uploader();
  const { user } = useAuth();

  const [analyseReceipt, setAnalyseReceipt] = useState(
    localStorage.getItem("analyse_receipt_file") === "false" ? false : true,
  );

  const { takeAction, loading }: IUseApi<{}, { data: AnalysedReceipt }> =
    useApi();

  return (
    <div className="col-lg-12 mb-3">
      <div className="d-flex align-items-center mb-2">
        <label className="tx-inverse tx-semibold mb-0">
          Attach Credit Card Receipt/Tax Invoice
        </label>
        <div className="d-flex ms-auto align-items-center">
          <Toggle
            checked={analyseReceipt}
            onChange={() => {
              localStorage.setItem(
                "analyse_receipt_file",
                !analyseReceipt ? "true" : "false",
              );
              setAnalyseReceipt(!analyseReceipt);
            }}
            className="toggle-sm"
            icons={false}
          />
          <span className="tx-sm ms-1">Analyse File</span>
        </div>
      </div>

      <Field
        component={FileInput}
        required
        validate={required}
        name="file"
        percent={uploadPercent}
        onChange={(value: any) => {
          if (!analyseReceipt) {
            return;
          }
          upload(
            value,
            `tmp/organisations/${user?.active_organisation.uuid}/credit-card-receipts`,
            (file: { path: string }) => {
              return takeAction("store", "analyse-receipts", {
                file_name: file.path,
              }).then(({ data }) => {
                change("supplier_name", data.data.supplier_name);

                change("purchase_items", null);
                change("total", data.data.total);

                data.data.items?.forEach((item, i) => {
                  change(
                    `purchase_items[${i}].description`,
                    item.description ?? "",
                  );
                  change(`purchase_items[${i}].price`, item.price ?? "");
                  change(`purchase_items[${i}].quantity`, item.quantity ?? "");
                });
              });
            },
          );
        }}
      />
      {loading && <small>Analysing Receipt...</small>}
    </div>
  );
};

export default ReceiptField;
