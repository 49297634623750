import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
const MobileLogo = () => {
  const originalLogoRatio = 1.414;
  const width = 40;
  const height = width * originalLogoRatio;
  return (
    <div className="slim-header-center d-lg-none d-xs-block">
      <h3 className="mg-b-0">
        <Link to="/home">
          <Logo
            height={height}
            width={width}
            title="The BOSS App"
            className="header-logo"
          />
        </Link>
      </h3>
    </div>
  );
};

export default MobileLogo;
