import React from "react";
import { Progress } from "reactstrap";
import { Field } from "redux-form";
import FileInput from "../upload/FileInput";
import FormModal from "../utils/FormModal";
import useUploadLogo from "./useUploadLogo";

const LogoModal = (props) => {
  const { addLogo, uploadPercent } = useUploadLogo(
    props.organisation,
    props.setOrganisation,
  );

  const onSubmit = (values) => {
    return addLogo(values.logo).then(props.toggle);
  };

  return (
    <FormModal {...props} form="Logo" onSubmit={onSubmit}>
      <div className="col-12 form-group">
        <Field component={FileInput} label="Logo" name="logo" />
        {uploadPercent > 0 && <Progress color="info" value={uploadPercent} />}
      </div>
    </FormModal>
  );
};

export default LogoModal;
