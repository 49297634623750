import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import ApprovalStatus from "../user/ApprovalStatus";
import ApprovalAlert from "../approvals/ApprovalAlert";
import ApprovalButton from "../approvals/ApprovalButton";
import ApprovalComments from "../approvals/ApprovalComments";
import Spinner from "../utils/Spinner";
import ProfileHeader from "./ProfileHeader";
import ShowPage from "./ShowPage";

const CPIRProfile = () => {
  const { uuid } = useParams();

  const {
    data: cpir,
    setData: setCpir,
    loading,
  } = useApi(`cpirs/${uuid}`, null, true, "/cpirs");

  const tabs = [
    {
      link: `/cpirs/${uuid}/details`,
      page: "details",
      label: "Details",
    },
    {
      link: `/cpirs/${uuid}/documents`,
      page: "documents",
      label: (
        <>
          Documents {cpir?.document_count ? `(${cpir?.document_count})` : null}
        </>
      ),
    },
  ];

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={`CPIR/${cpir?.number}`}
        crumbs={[
          { link: "cpirs", name: "Incidents/Near Misses/Observations" },
          { link: "", name: cpir?.name, active: true },
        ]}
      />
      <Profile
        tabs={tabs}
        header={<ProfileHeader cpir={cpir} />}
        content={
          <>
            {" "}
            <ApprovalAlert
              approvalAction={cpir?.approval_action}
              approval={cpir?.approval}
            />
            <ShowPage cpir={cpir} setCpir={setCpir} />
          </>
        }
        sideBar={
          <>
            {" "}
            <div className="d-block mt-3">
              <ApprovalStatus
                status={cpir?.approval_status}
                className="w-100 py-2"
              />
            </div>
            <ApprovalButton
              approvalAction={cpir?.approval_action}
              approvalOptions={[
                { value: 1, label: "Approve" },
                { value: 2, label: "Redo" },
              ]}
            />
            <div className="mt-3">
              <ApprovalComments
                modelType="App\Models\CPIR"
                modelId={cpir?.id}
              />
            </div>
            <div className="mt-3">
              <SideList
                commentableType="App\Models\ProjectJob"
                commentableId={cpir?.job.id}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default CPIRProfile;
