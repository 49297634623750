import DetailsTable from "./DetailsTable";
import { Link } from "react-router-dom";
import SpreadPrice from "../utils/SpreadPrice";

const BilledPurchasesTable = (props) => {
  const columns = [
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info) => (
        <Link to={`/${info.row.original.project.link}`}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "project.client.name",
      header: "Client",
      cell: (info) => {
        if (!info.row.original.project.client) {
          return "-";
        }

        return (
          <Link to={info.row.original.project.client.link}>
            {info.row.original.project.client.name}
          </Link>
        );
      },
    },
    {
      accessorKey: "chart_of_accounts",
      header: "Accounts",
      cell: (info) => {
        return (
          info
            .getValue()
            ?.map(({ name }) => name)
            .join(", ") ?? "-"
        );
      },
      csvFormatter: (row) =>
        row?.chart_of_accounts?.map(({ name }) => name).join(", ") ?? "-",
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "cost",
      header: "Amount",
      cell: (info) => <SpreadPrice price={info.getValue()} />,
    },
  ];

  return (
    <DetailsTable
      url="billed-purchases-overview"
      {...props}
      title="Purchases"
      columns={columns}
    />
  );
};

export default BilledPurchasesTable;
