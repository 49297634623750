import { Fragment, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Form, reduxForm, submit } from "redux-form";
import { BreakType } from "../timesheets/timesheetTypes";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";
import isSubmitting from "../utils/submitting";
import ScheduleFields from "./ScheduleFields";
import FormErrorAlert from "../form/FormErrorAlert";

const ScheduleModal = (props) => {
  const [deleting, setDeleting] = useState(false);
  const {
    initialValues,
    handleSubmit,
    whenSubmitted,
    dispatch,
    modal,
    toggle,
    selectedEvent,
    onDelete,
  } = props;

  const handleDelete = () => {
    return deleteSwal()
      .then(() => {
        setDeleting(true);
        return onDelete(initialValues.uuid);
      })
      .then(() => {
        toast.success("Delete Successful");
        return toggle();
      })
      .catch(errorSwal)
      .then(() => setDeleting(false));
  };

  const start = `${initialValues.start_time_date} ${initialValues.start_time_time}`;
  const finish = `${initialValues.finish_time_date} ${initialValues.finish_time_time}`;

  const isBreak = [BreakType.Paid, BreakType.Unpaid].includes(
    initialValues?.break_type,
  );

  return (
    <Modal
      isOpen={modal}
      className="wd-md-1000 mx-wd-800"
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
        {initialValues?.id ? "Update" : "Add"} Task
      </ModalHeader>
      <ModalBody>
        {selectedEvent && selectedEvent?.job && (
          <p>
            <Link to={selectedEvent.job.link}>{selectedEvent.job.name}</Link>
          </p>
        )}
        {initialValues?.job_link && (
          <p>
            <Link to={initialValues.job_link}>{initialValues.job_name}</Link>
          </p>
        )}
        {isBreak && (
          <p>
            {initialValues?.break_type === BreakType.Paid ? "Paid" : "Unpaid"}{" "}
            Break.
          </p>
        )}

        <p>
          {isBreak ? "Break" : "Task"} from{" "}
          <span className="tx-14 tx-bold text-dark">
            {dayjs(start).format("DD/MM/YYYY hh:mm a")}
          </span>{" "}
          to{" "}
          <span className="tx-14 tx-bold text-dark">
            {dayjs(finish).format("DD/MM/YYYY hh:mm a")}
          </span>
        </p>

        <Form
          onSubmit={handleSubmit((values, dispatch) =>
            whenSubmitted(values, dispatch),
          )}
        >
          <div className="row">
            <FormErrorAlert error={props.error} />
          </div>
          <ScheduleFields
            initialValues={initialValues}
            change={props.change}
            form={props.form}
            isBreak={isBreak}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn d-flex me-auto btn-link bd-0"
          onClick={toggle}
        >
          Close
        </button>
        <Fragment>
          <SubmitButton
            onClick={() => dispatch(submit("ScheduleModal"))}
            {...props}
          />
          {initialValues?.id && !isBreak && (
            <button
              type="button"
              className="btn btn-outline-danger bd-0"
              onClick={handleDelete}
              disable={deleting.toString()}
            >
              {isSubmitting(deleting, "Delete", "Deleting...")}
            </button>
          )}
        </Fragment>
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm({
  form: "ScheduleModal",
  enableReinitialize: true,
});

export default form(ScheduleModal);
