import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NoMatch from "../404";
import useApi from "../api/useApi";
import EquipmentCalibrations from "../calibrations/EquipmentCalibrations";
import CalibrationTracking from "../calibrationTrackings/CalibrationTracking";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import Spinner from "../utils/Spinner";
import AccumulatedHours from "./AccumulatedHours";
import EquipmentChanges from "./EquipmentChanges";
import EquipmentProjectHistory from "./EquipmentProjectHistory";
import EquipmentStandDowns from "./EquipmentStandDowns";
import ProfileForm from "./ProfileForm";
import ProfileHeader from "./ProfileHeader";
import ProfileTabs from "./ProfileTabs";
import UpcomingCalibrationsWidget from "./UpcomingCalibrationsWidget";
import EquipmentInvoices from "./EquipmentInvoices";
import EquipmentRecurringPurchaseDetails from "./EquipmentRecurringPurchaseDetails";
import EquipmentPurchase from "./EquipmentPurchase";

const Profile = (props) => {
  const { uuid, page } = useParams();

  const {
    data: equipment,
    setData: setEquipment,
    refreshData: refreshEquipment,
  } = useApi(`equipment/${uuid}`, null, true, "/equipment");

  const {
    loading: fetchingChanges,
    data: changes,
    refreshData,
  } = useApi(
    `field-edit-reasons?editableType=${encodeURIComponent(
      "App\\Models\\Equipment",
    )}&editableUuid=${uuid}`,
    [],
    true,
  );

  if (!equipment) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={equipment.name}
        crumbs={[
          { link: "equipment", name: "Equipment" },
          { link: "/", name: equipment.name, active: true },
        ]}
      />
      <div className="row row-sm">
        <div className="col-lg-8">
          <ProfileHeader equipment={equipment} />
          <ShowPage
            page={page}
            uuid={uuid}
            {...props}
            equipment={equipment}
            setEquipment={setEquipment}
            refreshData={refreshData}
            refreshEquipment={refreshEquipment}
          />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <ProfileTabs page={page} uuid={uuid} equipment={equipment} />
          <UpcomingCalibrationsWidget equipment={equipment} />
          <div className="mt-3">
            <SideList
              commentableType="App\Models\Equipment"
              commentableId={equipment.id}
            />
          </div>
          <EquipmentChanges changes={changes} loading={fetchingChanges} />
          <AccumulatedHours />
        </div>
      </div>
    </>
  );
};

const ShowPage = (props) => {
  const { user } = useAuth();

  switch (props.page) {
    case "details":
      return (
        <ProfileForm
          initialValues={props.equipment}
          refreshData={props.refreshData}
          setEquipment={props.setEquipment}
        />
      );
    case "calibrations":
      return <EquipmentCalibrations {...props} />;
    case "documents":
      return (
        <ProfileDocuments
          {...props}
          model="App\Models\Equipment"
          documentableId={props.uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/equipment/${props.uuid}`}
          homeUrl={`equipment/${props.uuid}/documents`}
        />
      );
    case "calibration-tracking":
      return <CalibrationTracking {...props} />;
    case "project-history":
      return <EquipmentProjectHistory />;
    case "days-stood-down":
      return <EquipmentStandDowns />;
    case "contract-agreement":
      return (
        <EquipmentRecurringPurchaseDetails
          initialValues={props.equipment}
          {...props}
        />
      );
    case "invoices":
      return <EquipmentInvoices {...props} />;
    case "purchase":
      return props.equipment.purchase_id ? (
        <EquipmentPurchase initialValues={props.equipment} />
      ) : (
        <NoMatch />
      );
    default:
      return <NoMatch />;
  }
};

export default Profile;
