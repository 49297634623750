import React from "react";
import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import FormHeader from "../utils/FormHeader";
import LoadingOverlay from "../utils/LoadingOverlay";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { PenaltyRate } from "./penaltyRateTypes";
import { useAuth } from "../../context/auth-context";
import { IntegrationType } from "../integrations/integrationTypes";
import SelectInput from "../form/SelectInput";

const PenaltyRateHours = ({
  fields,
  initialValues,
  setPenaltyRate,
  reset,
  payCategories,
}: WrappedFieldArrayProps<any> & {
  initialValues?: PenaltyRate;
  setPenaltyRate?: (rate: PenaltyRate) => void;
  reset(): void;
  payCategories: { name: string; id: number }[];
}) => {
  const { takeAction, loading }: IUseApi<any, { data: PenaltyRate }> = useApi();
  const { user } = useAuth();

  const hasPayroll = user?.active_organisation.integration_types.includes(
    IntegrationType.Payroll,
  );

  return (
    <>
      <LoadingOverlay loading={loading} />
      <FormHeader>Hours</FormHeader>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={field}>
            <div className="form-group col-lg-3">
              <Field
                component={RenderField}
                name={`${field}.from`}
                label="From"
              />
            </div>
            <div className="form-group col-lg-3">
              <Field component={RenderField} name={`${field}.to`} label="To" />
            </div>
            <div className="form-group col-lg-3">
              <Field
                component={RenderField}
                name={`${field}.rate`}
                label="Rate"
                required
                validate={required}
              />
            </div>
            {hasPayroll && (
              <div className="col-md-3 form-group">
                <Field
                  component={SelectInput}
                  name={`${field}.payroll_category`}
                  label="Payroll Category"
                  required
                  validate={required}
                  options={payCategories.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))}
                />
              </div>
            )}
            <div
              className={`${
                hasPayroll ? "col-lg-12" : "col-lg-3 "
              } form-group d-flex`}
            >
              <Button
                onClick={() => {
                  const hour = initialValues?.hours?.[index];

                  if (hour && setPenaltyRate) {
                    return deleteSwal()
                      .then(() =>
                        takeAction(
                          "destroy",
                          `/penalty-rate-hours/${hour?.uuid}`,
                        ),
                      )
                      .then(({ data }) => {
                        setPenaltyRate(data.data);
                        reset();
                      })
                      .catch(errorSwal);
                  }

                  fields.remove(index);
                }}
                color="danger"
                className="align-self-end justify-self-end"
              >
                Delete
              </Button>
            </div>
          </React.Fragment>
        );
      })}
      <div className="col-12 form-group">
        <Button color="primary" outline onClick={() => fields.push({})}>
          Add Hours
        </Button>
      </div>
    </>
  );
};

export default PenaltyRateHours;
