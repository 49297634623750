import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ReactTable from "../table/ReactTable";
import { Template } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const EmailTemplates = () => {
  const { data, loading } = useApi("email-templates", [], true);

  const columns = [
    {
      accessorKey: "template_label",
      header: "Name",
      cell: info => {
        const template = info.row.original;
        return (
          <Button className="p-0" color="link">
            <Link to={`email-templates/${template.uuid}`}>
              ({template.type_description}) {template.name} - {info.getValue()}{" "}
            </Link>
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/email-templates/" />
      <HeaderPageAdminFilter
        titlePage="Email Templates"
        pageDescription={
          <>
            The "Email Templates" feature in theBOSSapp allows you to create
            custom email templates for your custom jobs, saving time and
            ensuring consistency in your communications with clients.
          </>
        }
        relatedLinks={[
          { name: "Templates", link: "/templates", model: Template },
        ]}
        crumbs={[
          {
            link: "/email-templates",
            name: "Email Templates",
            active: true,
          },
        ]}
      />
      <ReactTable
        columns={columns}
        data={data}
        loading={loading}
        extraButtons={
          <Link
            to="/email-templates/add"
            className="btn ms-2 btn-outline-primary"
          >
            New Email Template
          </Link>
        }
      />
    </>
  );
};

export default EmailTemplates;
