import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import SentBadge from "./SentBadge";
import { Badge } from "reactstrap";

const ProfileHeader = ({ deliverable }) => {
  const { user } = useAuth();

  const isPositionAdmin = user.is_position_admin;

  return (
    <DashboardHeader>
      <div className="d-flex align-items-center">
        <DashboardH3>
          {`${deliverable.name} / ${deliverable.revision_number}`}
        </DashboardH3>
      </div>

      <DashboardSubTitle>
        Client:{" "}
        <Link to={`${deliverable.client.link}`}>{deliverable.client.name}</Link>
      </DashboardSubTitle>

      <DashboardSubTitle>
        Project:
        <Link to={`/${deliverable.project.link}`}>
          {` ${deliverable.project.number} - ${deliverable.project.name}`}
        </Link>
      </DashboardSubTitle>

      <DashboardSubTitle>
        Job:
        <Link to={deliverable.job.link}>{` ${deliverable.job.name}`}</Link>
      </DashboardSubTitle>
      {deliverable.invoice && (
        <DashboardSubTitle className="mg-t-10">
          Invoice Number:{" "}
          <Link to={deliverable.invoice.link}>{deliverable.invoice.name}</Link>
        </DashboardSubTitle>
      )}

      {isPositionAdmin && (
        <>
          {deliverable.invoice_requested_by && (
            <DashboardSubTitle>
              <span className="badge bg-info">
                Invoice Requested under old system
              </span>
            </DashboardSubTitle>
          )}
          {deliverable.invoiced_at && (
            <DashboardSubTitle>
              <span className="badge bg-info">
                Invoiced under old system at {deliverable.invoiced_at}
              </span>
            </DashboardSubTitle>
          )}
        </>
      )}
      <div className="d-flex space-x-2 mg-t-10 ">
        {deliverable.is_progress_claim && (
          <Badge color="info" className="rounded-pill shadow">
            Progress Claim
          </Badge>
        )}
        <SentBadge className="rounded-pill shadow" sent={deliverable.sent} />
      </div>
    </DashboardHeader>
  );
};

export default ProfileHeader;
