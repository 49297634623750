import React from "react";
import { FieldArray } from "redux-form";
import FieldType from "../../enums/FieldType";
import CustomFieldNotifications from "./CustomFieldNotifications";
import { CustomField } from "./customFieldTypes";

const NotifyField = ({
  value,
  field,
}: {
  value?: CustomField;
  field: string;
}) => {
  if (!value?.field_attributes?.notify) {
    return null;
  }

  return (
    <FieldArray
      name={`${field}.field_attributes.notifications`}
      component={CustomFieldNotifications}
    />
  );
};

export default NotifyField;
