import { GrAdd } from "react-icons/gr";
import { Button } from "reactstrap";
import useModal from "../hooks/useModal";
import AddTokenModal from "./AddTokenModal";
import { OrganisationTokenProps } from "./OrganisationTokens";

const AddTokenButton = (props: OrganisationTokenProps) => {
  const { modal, toggle } = useModal();

  return (
    <>
      <Button onClick={toggle} color="link">
        <GrAdd />
      </Button>
      <AddTokenModal {...props} toggle={toggle} modal={modal} />
    </>
  );
};

export default AddTokenButton;
