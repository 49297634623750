import useModal from "../../hooks/useModal";
import {
  ApprovalAction,
  ApprovalStatuses,
} from "../../approvals/approvalTypes";
import { useAuth } from "../../../context/auth-context";
import { Button } from "reactstrap";
import FormModal from "../../utils/FormModal";
import {
  Field,
  FieldArray,
  FormSubmitHandler,
  formValueSelector,
  InjectedFormProps,
  WrappedFieldArrayProps,
} from "redux-form";
import RenderRemarks from "../../approvals/RenderRemarks";
import { RenderRadioButton } from "../../approvals/ApprovalModal";
import { approvalOptions } from "../../../data/approval_options";
import { connect } from "react-redux";
import useApi from "../../api/useApi";
import SelectInput from "../../form/SelectInput";
import required from "../../utils/required";
import { Fragment } from "react";
import { ChartOfAccount } from "../../chartOfAccounts/chartOfAccountTypes";
import { BranchIndex } from "../../branches/branchTypes";
import filterOption from "../../../utils/filterOption";
import { ApiCalls, IUseApi, IUseApiWithData } from "../../api/apiTypes";
import formError from "../../utils/formError";
import { toast } from "react-toastify";
import FormHeader from "../../utils/FormHeader";
import { money } from "../../form/formatters";
import TransferModal from "../../approvals/TransferModal";

const InterEntityApproval = ({
  approvalAction,
  invoice,
  setInvoice,
  refreshData,
}: {
  approvalAction?: ApprovalAction;
  invoice: any;
  setInvoice: Function;
  refreshData: Function;
}) => {
  const { user } = useAuth();
  const { toggle, modal } = useModal();

  const { toggle: toggleTransfer, modal: transferModal } = useModal();

  if (!approvalAction || approvalAction?.action_type !== "Approve") {
    return null;
  }

  if (approvalAction.user_id !== user?.id && !user?.is_admin) {
    return (
      <div className="mg-t-20 form-group w-100">
        <p className="mb-0 text-secondary">
          Awaiting Approval by{" "}
          <a
            className="text-secondary"
            href={`mailto:${approvalAction.user.email}`}
          >
            {approvalAction.user.name}
          </a>
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="btn-group mg-t-20 w-100">
        <Button type="button" outline color="info" onClick={toggle} block>
          Take Approval Action
        </Button>
        <Button color="info" onClick={toggleTransfer} outline block>
          Transfer Approval
        </Button>
      </div>

      <ConnectedApprovalModal
        toggle={toggle}
        modal={modal}
        invoice={invoice}
        approvalAction={approvalAction}
        setInvoice={setInvoice}
      />
      <TransferModal
        modal={transferModal}
        toggle={toggleTransfer}
        approvalAction={approvalAction}
        onSuccess={() => refreshData()}
      />
    </>
  );
};

const ApprovalModal = ({
  toggle,
  modal,
  invoice,
  approvalAction,
  setInvoice,
  status,
}: {
  toggle: Function;
  modal: boolean;
  invoice: any;
  approvalAction: ApprovalAction;
  status: number;
  setInvoice: Function;
}) => {
  const { takeAction }: IUseApi = useApi();

  const { data: branches }: IUseApiWithData<BranchIndex[]> = useApi(
    "user-branches",
    [],
  );
  const { data: accounts }: IUseApiWithData<ChartOfAccount[]> = useApi(
    "chart-of-accounts?purchaseOrders=1",
    [],
  );

  const onSubmit: FormSubmitHandler<{
    remarks?: string;
    approval_status: number;
  }> = (values) => {
    let data: [type: ApiCalls, url: string, data?: any] = [
      "update",
      `/approval-actions/${approvalAction.uuid}`,
      values,
    ];

    if (values.approval_status == ApprovalStatuses.APPROVED) {
      data = [
        "store",
        `/approved-inter-entity-invoices/${invoice.uuid}`,
        values,
      ];
    }

    return takeAction(...data)
      .then(({ data }) => {
        toggle();
        toast.success("Approval action taken");
        setInvoice(data.data);
      })
      .catch(formError);
  };

  const requiresPricing = status == ApprovalStatuses.APPROVED;

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Take Approval Action"
      form="InterEntityApprovalModal"
      onSubmit={onSubmit}
      initialValues={{
        prices: invoice.standard_line_items,
      }}
    >
      {({ change }: InjectedFormProps) => {
        return (
          <>
            <div className="form-group col-12">
              <Field
                label="Remarks"
                component={RenderRemarks}
                name="remarks"
                textarea
                approvalAction={approvalAction}
                change={change}
              />
            </div>
            <div
              className={`form-group col-12 ${requiresPricing ? "mb-4" : ""}`}
            >
              <Field
                component={RenderRadioButton}
                name="approval_status"
                options={approvalOptions}
              />
            </div>
            {requiresPricing ? (
              <FieldArray
                name="prices"
                component={InvoiceLineItems}
                branches={branches}
                accounts={accounts}
              />
            ) : null}
          </>
        );
      }}
    </FormModal>
  );
};

const InvoiceLineItems = ({
  fields,
  branches,
  accounts,
}: WrappedFieldArrayProps & {
  branches: BranchIndex[];
  accounts: ChartOfAccount[];
}) => {
  return (
    <>
      <FormHeader>Match Line Items to Accounts and Branches</FormHeader>

      {fields.map((name, index) => {
        const field = fields.get(index);

        return (
          <Fragment key={index}>
            <div className="col-lg-12 form-group">
              <p className="text-dark mb-0 fw-bolder">{field.name}</p>
            </div>
            <div className="col-lg-3">
              <p className="mb-0 text-dark">{field.quantity}</p>
              <small>Quantity</small>
            </div>
            <div className="col-lg-3">
              <p className="mb-0 text-dark">{money.format(field.price)}</p>
              <small>Price</small>
            </div>
            <div className="col-lg-3">
              <p className="mb-0 text-dark">{money.format(field.cost)}</p>
              <small>Total</small>
            </div>
            <div className="col-lg-3 form-group">
              <Field
                name={`${name}.branch_id`}
                component={SelectInput}
                type="number"
                label="Branch"
                required
                options={branches.map((branch) => ({
                  label: (
                    <div>
                      <p className="text-dark mb-0">{branch.name}</p>
                      <p className="mb-0 tx-12">{branch.number}</p>
                    </div>
                  ),
                  value: branch.id,
                  text: branch.display_name,
                }))}
                filterOption={filterOption}
                validate={required}
              />
            </div>
            <div className="col-12">
              <br className="w-full" />
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("InterEntityApprovalModal");

  return {
    status: selector(state, "approval_status"),
  };
};

const ConnectedApprovalModal = connect(mapStateToProps)(ApprovalModal);

export default InterEntityApproval;
