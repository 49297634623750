import { IFilter } from "../hooks/useFilter";
import {
    PurchaseArchiveStatus,
    PurchaseGSTType,
    PurchaseStatus,
    PurchaseType,
  } from "./purchaseTypes";

type PurchaseFilterTypes = IFilter<
"status" | "includes_gst" | "type" | "archive_status",
PurchaseStatus | PurchaseGSTType | PurchaseType | PurchaseArchiveStatus
>;

export const PurchaseFilters: PurchaseFilterTypes[] = [
    {
        label: "Type",
        name: "type",
        options: [
        {
            label: "Payment",
            value: PurchaseType.CreditCard,
        },
        {
            label: "Purchase Order",
            value: PurchaseType.PurchaseOrder,
        },
        {
            label: "Contract Agreement",
            value: PurchaseType.RecurringPurchase,
        },
        ],
    },
    {
        label: "Status",
        name: "status",
        options: [
        {
            label: "Billed",
            value: PurchaseStatus.Billed,
        },
        {
            label: "Ready To Be Billed",
            value: PurchaseStatus.ReadyToBeBilled,
        },
        {
            label: "Not Ready To Be Billed",
            value: PurchaseStatus.NotReadyToBeBilled,
        },
        ],
    },
    {
        label: "Archive Status",
        name: "archive_status",
        options: [
        {
            label: "Archived",
            value: PurchaseArchiveStatus.Archived,
        },
        {
            label: "Not Archived",
            value: PurchaseArchiveStatus.NotArchived,
        },
        ],
    },
    {
        label: "Tax Type",
        name: "includes_gst",
        options: [
        {
            label: "GST Included",
            value: PurchaseGSTType.GSTIncluded,
        },
        {
            label: "GST Excluded",
            value: PurchaseGSTType.GSTExcluded,
        },
        {
            label: "N/A",
            value: PurchaseGSTType.NA,
        },
        ],
    },
];