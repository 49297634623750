import { Link, useParams } from "react-router-dom";

import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import ApprovalComments from "../approvals/ApprovalComments";
import SideList from "../comments/SideList";
import { formatNumber } from "../dashboard/OverviewCard";
import HeaderPage from "../header/HeaderPage";
import SyncIntegrationEstimation from "../purchases/SyncIntegrationEstimation";
import ApprovalStatus from "../user/ApprovalStatus";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import InfoCard from "../utils/InfoCard";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import EditDate from "./EditDate";
import MarkAsBilledButton from "./MarkAsBilledButton";
import ShowPage from "./ShowPage";
import useUserGroup from "../hooks/useUserGroup";

const Profile = (props) => {
  const { uuid, page } = useParams();

  const {
    data: payment,
    setData: setPayment,
    loading,
  } = useApi(
    `recurring-payment-purchases/${uuid}`,
    null,
    true,
    "/recurring-purchases",
  );

  const isAccounting = useUserGroup("Accounting");

  if (loading) return <Spinner loading />;

  const initialValues = {
    ...payment,
    items: payment.items.map((item) => ({
      ...item,
      item: item.recurring_price_id,
    })),
  };

  return (
    <>
      <HeaderPage
        titlePage={payment.name}
        crumbs={[{ link: "/payments", name: "Payments" }]}
      />

      <div className="row">
        <div className="col-lg-8">
          <DashboardHeader>
            <DashboardH3>{payment.name}</DashboardH3>
            <DashboardSubTitle>
              Agreement:{" "}
              <Link to={payment.recurring_payment.link}>
                {payment.recurring_payment.name}
              </Link>
            </DashboardSubTitle>
            <DashboardSubTitle>
              Supplier:{" "}
              <Link to={`/suppliers/${payment.supplier.uuid}/details`}>
                {payment.supplier.name}
              </Link>
            </DashboardSubTitle>
            <DashboardSubTitle>
              Project:{" "}
              {payment.project?.name ? (
                <Link to={`/projects/${payment.project.uuid}/purchases`}>
                  {payment.project.name}
                </Link>
              ) : (
                "Unable to Find"
              )}
            </DashboardSubTitle>
            <DashboardSubTitle>
              Purchaser Name:{" "}
              <a href={`mailto:${payment.user.email}`}>{payment.user.name}</a>
            </DashboardSubTitle>
            <EditDate purchase={payment} setPurchase={setPayment} />
            <DashboardSubTitle>
              <p className="mt-2 mb-2 tx-inverse">
                Agreement Description: {payment.recurring_payment.description}
              </p>
            </DashboardSubTitle>
            <DashboardSubTitle>
              <p className="mt-2 mb-2 tx-inverse">
                Supplier Invoice: {payment.invoice.number}
              </p>
            </DashboardSubTitle>
            <DashboardSubTitle>
              {payment.recurring_payment.includes_gst_description}
            </DashboardSubTitle>
          </DashboardHeader>

          <div className="d-block d-lg-none my-3">
            <ApprovalStatus
              status={payment.approval_status}
              className="w-100 py-2"
            />
          </div>

          <MarkAsBilledButton
            payment={payment}
            setPayment={setPayment}
            isAccounting={isAccounting}
          />

          <ShowPage
            initialValues={initialValues}
            payment={payment}
            setPayment={setPayment}
            {...props}
            isAccounting={isAccounting}
          />
          <SyncIntegrationEstimation
            purchase={payment}
            setPurchase={setPayment}
          />
        </div>

        <div className="col-lg-4">
          <TabList>
            <Tab
              link={`/recurring-payment-purchases/${uuid}/details`}
              active={page === "details"}
              icon="icon ion-clipboard text-capitalize"
            >
              Details
            </Tab>
            <Tab
              link={`/recurring-payment-purchases/${uuid}/invoice`}
              active={page === "invoice"}
              icon="icon ion-clipboard text-capitalize"
            >
              Invoice
            </Tab>
          </TabList>
          <div className="d-none d-lg-block mt-3">
            <ApprovalStatus
              status={payment.approval_status}
              className="w-100 py-2"
            />
          </div>
          <ApprovalButton approvalAction={payment.approval_action} />
          <InfoCard
            info={[
              {
                title: "Total Cost",
                label: "Value",
                value: `$${formatNumber(payment.realised_total_cost)} (${
                  payment.recurring_payment.includes_gst_description
                })`,
              },
            ]}
          />

          <div className="mt-3">
            <ApprovalComments
              modelType="App\Models\Purchase"
              modelId={payment.id}
            />
          </div>
          <div className="mt-3">
            <SideList
              commentableType="App\Models\Purchase"
              commentableId={payment.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
