import React from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import FormEquipmentType from "./Form";
import DocsModal from "../utils/DocsModal";

const AddEquipmentType = props => {
  const { history } = props;
  const { takeAction } = useApi();

  const onSubmit = values => {
    if (Array.isArray(values.calibration_types)) {
      values.calibration_types.forEach(calibrationType => {
        // Unset isTailoredCalibrationJobDeadlines and is_tailored_calibration_job_deadlines
        delete calibrationType.isTailoredCalibrationJobDeadlines;
        delete calibrationType.is_tailored_calibration_job_deadlines;
      });
    }
    
    return takeAction("store", "equipment-types", values)
      .then(({ data }) => {
        toast.success("Equipment type created successfully!");
        history.push(`/equipment-types/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/equipment-asset-management/equipment-types-9197/" />
      <HeaderPage
        titlePage="New Equipment Type"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "equipment-types", name: "Equipment Types" },
          {
            link: "equipment-types/add",
            name: "New Equipment Type",
            active: true,
          },
        ]}
      />
      <FormEquipmentType
        onSubmit={onSubmit}
        {...props}
        initialValues={{
          calibration_types: [{}],
        }}
      />
    </>
  );
};

export default AddEquipmentType;
