import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import EmploymentHeroLeaveCategoryCard from "./EmploymentHeroLeaveCategoryCard";
import ImportLeaveReasonButton from "./ImportLeaveReasonButton";

export interface LeaveReasonToSync {
  boss_leave_reasons: {
    name: string;
    uuid: string;
  }[];

  employment_hero_leave_reasons: {
    name: string;
    id: number;
  }[];
}

const SyncLeaveReasons = () => {
  const {
    data,
    loading,
    setData: setLeaveReasons,
    refreshData,
  }: IUseApi<LeaveReasonToSync> = useApi(
    "integrations/employment-hero/leavecategories",
    {},
    true,
  );

  const { takeAction }: IUseApi = useApi();

  if (loading) {
    return (
      <CustomScaleLoader>Fetching leave reasons to sync...</CustomScaleLoader>
    );
  }

  return (
    <div className="row">
      <ImportLeaveReasonButton refreshData={refreshData} />
      {data?.boss_leave_reasons.map((leaveReason) => {
        return (
          <EmploymentHeroLeaveCategoryCard
            refreshData={refreshData}
            leaveReason={leaveReason}
            key={leaveReason.uuid}
            setLeaveReasons={setLeaveReasons}
            leaveReasons={data}
          />
        );
      })}
    </div>
  );
};

export default SyncLeaveReasons;
