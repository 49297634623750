interface FormattedAddress {
  subpremise?: google.maps.GeocoderAddressComponent;
  street_number?: google.maps.GeocoderAddressComponent;
  street_name?: google.maps.GeocoderAddressComponent;
  suburb?: google.maps.GeocoderAddressComponent;
  region?: google.maps.GeocoderAddressComponent;
  state?: google.maps.GeocoderAddressComponent;
  post_code?: google.maps.GeocoderAddressComponent;
  latitude?: number;
  longitude?: number;
}

const formatGoogleAddress = (
  address: google.maps.GeocoderResult[],
): FormattedAddress => {
  const output: FormattedAddress = {};

  const components = address[0].address_components;

  output.subpremise = components.find((component) =>
    component.types.includes("subpremise"),
  );

  output.street_number = components.find((component) =>
    component.types.includes("street_number"),
  );
  output.street_name = components.find((component) =>
    component.types.includes("route"),
  );
  output.suburb = components.find((component) =>
    component.types.includes("locality"),
  );
  output.region = components.find((component) =>
    component.types.includes("administrative_area_level_2"),
  );
  output.state = components.find((component) =>
    component.types.includes("administrative_area_level_1"),
  );
  output.post_code = components.find((component) =>
    component.types.includes("postal_code"),
  );

  output.latitude = address[0].geometry.location.lat();
  output.longitude = address[0].geometry.location.lng();

  return output;
};

export default formatGoogleAddress;

type Type =
  | "subpremise"
  | "street_number"
  | "street_name"
  | "suburb"
  | "region"
  | "state"
  | "post_code";

export const checkAddress = (
  address: FormattedAddress,
  type: Type,
  nameType: "short_name" | "long_name",
): string => address?.[type]?.[nameType || "long_name"] ?? "";
