import { useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Avatar from "../utils/Avatar";
import { Link } from "react-router-dom";
import { ProjectIndex } from "./projectTypes";
import ReactTable from "../table/ReactTable";

const ProjectTable = ({ projects }: { projects: ProjectIndex[] }) => {
  const columnHelper = createColumnHelper<ProjectIndex>();

  const columns = useMemo<ColumnDef<ProjectIndex, any>[]>(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (props) => {
          const project = props.row.original;

          const color = project.is_template
            ? "primary"
            : project.status
            ? "secondary"
            : "info";

          return (
            <div className="d-flex align-items-center w-100">
              <div>
                <Avatar name={project.name} colour={color} />
              </div>
              <div className="ms-1">
                <p className="mb-0 fw-bolder tx-inverse no-wrap">
                  <Link to={`/projects/${project.uuid}/details`}>
                    {project.name}
                  </Link>
                </p>
                <p className="mb-0">{project.number}</p>
              </div>
            </div>
          );
        },
      }),

      columnHelper.accessor("client.name", {
        header: "Client",
        cell: (info) => {
          const client = info.row.original.client;

          if (!info.row.original.internal && !info.row.original.is_template) {
            return "Internal Project";
          }

          if (!client?.name) {
            return "-";
          }

          return (
            <Link className="text-secondary" to={client?.link}>
              {client?.name}
            </Link>
          );
        },
      }),
      columnHelper.accessor("manager.name", {
        header: "Manager",
        cell: (info) => info.getValue() ?? "-",
      }),
      columnHelper.accessor("industry", {
        header: "Industry",
        cell: (info) => info.getValue() ?? "-",
      }),
      columnHelper.accessor("location", {
        header: "Location",
        cell: (info) => info.getValue() ?? "-",
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => {
          const project = info.row.original;

          const color = project.is_template
            ? "bg-primary"
            : project.status === 0
            ? "bg-info"
            : "bg-secondary";

          const text = project.is_template
            ? "Template"
            : project.status === 0
            ? "Open"
            : "Closed";

          return (
            <div
              className={`${color} shadow rounded-pill text-white tx-10 text-center px-2`}
            >
              {text}
            </div>
          );
        },
      }),
      columnHelper.accessor("tags", {
        header: "Tags",
        cell: (info) => {
          const tags = info.getValue() as { label: string; value: string }[];

          if (!tags?.length) {
            return "-";
          }

          return (
            <div className="d-flex space-x-3">
              {tags.map((tag) => (
                <div
                  className="rounded-pill shadow bg-indigo text-white text-center px-2 tx-10"
                  key={tag.value}
                >
                  {tag.value}
                </div>
              ))}
            </div>
          );
        },
      }),
    ],
    [],
  );
  return (
    <ReactTable<ProjectIndex> disableSearch columns={columns} data={projects} />
  );
};

export default ProjectTable;
