import dayjs from "dayjs";
import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { Invoice } from "./invoiceTypes";

const SentInvoices = ({ invoice }: { invoice: Invoice }) => {
  if (!invoice.sent_invoices) {
    return null;
  }

  return (
    <div className="space-y-5">
      {invoice.sent_invoices.map((sent_invoice) => {
        return (
          <div className="bg-white border rounded-lg shadow-sm">
            <div
              className="d-flex p-3 rounded-top"
              style={{ backgroundColor: "#002b4947" }}
            >
              <div>
                <p className="tx-primary fw-bolder tx-16 mb-2">Sent Invoice</p>
                <p className="tx-primary">
                  Sent by{" "}
                  <a href={`mailto:${sent_invoice.sent_by.email}`}>
                    {sent_invoice.sent_by.name}
                  </a>
                </p>
              </div>
              <img
                src="https://boss-prod.s3.ap-southeast-2.amazonaws.com/message-sent.svg"
                className="ms-auto"
                style={{ height: "120px" }}
                alt="Sent"
              />
            </div>
            <div className="p-3 d-flex align-items-center">
              <div>
                <p className="mb-2">
                  Sent on the{" "}
                  <span className="tx-inverse fw-bolder">
                    {dayjs(sent_invoice.created_at).format("DD/MM/YYYY")}
                  </span>{" "}
                  at{" "}
                  <span className="tx-inverse fw-bolder">
                    {dayjs(sent_invoice.created_at).format("hh:mma")}
                  </span>
                </p>
                <p className="mb-0">
                  Sent to{" "}
                  {sent_invoice.sent_to.map((email, index) => {
                    return (
                      <>
                        <a className="tx-inverse" href={`mailto:${email}`}>
                          {email}
                        </a>
                        {index + 1 !== sent_invoice.sent_to.length ? ", " : ""}
                      </>
                    );
                  })}
                </p>
              </div>
              <a
                className="btn btn-sm btn-primary ms-auto"
                href={sent_invoice.url}
              >
                <AiOutlineDownload /> Download Invoice
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SentInvoices;
