import swal from "sweetalert";

const markJobCompleteSwal = () => {
  return new Promise((resolve) => {
    swal({
      title: "Mark Complete?",
      text: "Would you like to mark this job as complete?",
      icon: "info",
      buttons: {
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((complete) => {
      if (complete) {
        resolve();
      }
    });
  });
};

export default markJobCompleteSwal;
