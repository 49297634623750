import React from "react";

const DateFormatTable = () => {
  return (
    <div className="mt-5 bg-white border shadow-sm">
      <table className="doctable table">
        <thead>
          <tr>
            <th>
              <code className="parameter">format</code> character
            </th>
            <th>Description</th>
            <th>Example returned values</th>
          </tr>
        </thead>
        <tbody className="tbody">
          <tr>
            <td>
              <em className="emphasis">Day</em>
            </td>
            <td>---</td>
            <td>---</td>
          </tr>
          <tr>
            <td>
              <code className="literal">d</code>
            </td>
            <td>Day of the month, 2 digits with leading zeros</td>
            <td>
              <code className="literal">01</code> to{" "}
              <code className="literal">31</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">D</code>
            </td>
            <td>A textual representation of a day, three letters</td>
            <td>
              <code className="literal">Mon</code> through{" "}
              <code className="literal">Sun</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">j</code>
            </td>
            <td>Day of the month without leading zeros</td>
            <td>
              <code className="literal">1</code> to{" "}
              <code className="literal">31</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">l</code> (lowercase 'L')
            </td>
            <td>A full textual representation of the day of the week</td>
            <td>
              <code className="literal">Sunday</code> through{" "}
              <code className="literal">Saturday</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">N</code>
            </td>
            <td>ISO-8601 numeric representation of the day of the week</td>
            <td>
              <code className="literal">1</code> (for Monday) through{" "}
              <code className="literal">7</code> (for Sunday)
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">S</code>
            </td>
            <td>
              English ordinal suffix for the day of the month, 2 characters
            </td>
            <td>
              <code className="literal">st</code>,{" "}
              <code className="literal">nd</code>,{" "}
              <code className="literal">rd</code> or
              <code className="literal">th</code>. Works well with{" "}
              <code className="literal">j</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">w</code>
            </td>
            <td>Numeric representation of the day of the week</td>
            <td>
              <code className="literal">0</code> (for Sunday) through{" "}
              <code className="literal">6</code> (for Saturday)
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">z</code>
            </td>
            <td>The day of the year (starting from 0)</td>
            <td>
              <code className="literal">0</code> through{" "}
              <code className="literal">365</code>
            </td>
          </tr>
          <tr>
            <td>
              <em className="emphasis">Week</em>
            </td>
            <td>---</td>
            <td>---</td>
          </tr>
          <tr>
            <td>
              <code className="literal">W</code>
            </td>
            <td>ISO-8601 week number of year, weeks starting on Monday</td>
            <td>
              Example: <code className="literal">42</code> (the 42nd week in the
              year)
            </td>
          </tr>
          <tr>
            <td>
              <em className="emphasis">Month</em>
            </td>
            <td>---</td>
            <td>---</td>
          </tr>
          <tr>
            <td>
              <code className="literal">F</code>
            </td>
            <td>
              A full textual representation of a month, such as January or March
            </td>
            <td>
              <code className="literal">January</code> through{" "}
              <code className="literal">December</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">m</code>
            </td>
            <td>Numeric representation of a month, with leading zeros</td>
            <td>
              <code className="literal">01</code> through{" "}
              <code className="literal">12</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">M</code>
            </td>
            <td>A short textual representation of a month, three letters</td>
            <td>
              <code className="literal">Jan</code> through{" "}
              <code className="literal">Dec</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">n</code>
            </td>
            <td>Numeric representation of a month, without leading zeros</td>
            <td>
              <code className="literal">1</code> through{" "}
              <code className="literal">12</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">t</code>
            </td>
            <td>Number of days in the given month</td>
            <td>
              <code className="literal">28</code> through{" "}
              <code className="literal">31</code>
            </td>
          </tr>
          <tr>
            <td>
              <em className="emphasis">Year</em>
            </td>
            <td>---</td>
            <td>---</td>
          </tr>
          <tr>
            <td>
              <code className="literal">L</code>
            </td>
            <td>Whether it's a leap year</td>
            <td>
              <code className="literal">1</code> if it is a leap year,{" "}
              <code className="literal">0</code> otherwise.
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">o</code>
            </td>
            <td>
              ISO-8601 week-numbering year. This has the same value as
              <code className="literal">Y</code>, except that if the ISO week
              number (<code className="literal">W</code>) belongs to the
              previous or next year, that year is used instead.
            </td>
            <td>
              Examples: <code className="literal">1999</code> or{" "}
              <code className="literal">2003</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">Y</code>
            </td>
            <td>A full numeric representation of a year, 4 digits</td>
            <td>
              Examples: <code className="literal">1999</code> or{" "}
              <code className="literal">2003</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="literal">y</code>
            </td>
            <td>A two digit representation of a year</td>
            <td>
              Examples: <code className="literal">99</code> or{" "}
              <code className="literal">03</code>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DateFormatTable;
