import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { Calendar } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import {
  change,
  formValueSelector,
  getFormError,
  isSubmitting,
  isValid,
  submit,
} from "redux-form";
import { useUserTimesheet } from "../../context/timesheet-context";
import colors from "../../data/colors";
import FormErrorAlert from "../form/FormErrorAlert";
import ScheduleModal from "../user/ScheduleModal";
import dayjsLocalizer from "../utils/dayjsLocalizer";
import { default as submitting } from "../utils/submitting";
import { BreakType } from "./timesheetTypes";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import {
  StatusBubble,
  statusDetails,
} from "../projectManagers/ProjectManagerJob";
import BottomLogo from "../utils/BottomLogo";
import FormHeader from "../utils/FormHeader";
import _ from "lodash";
import { toast } from "react-toastify";
import SearchInput from "../search/SearchInput";
import useModal from "../hooks/useModal";
import AllowanceModal from "./AllowanceModal";
import formError from "../utils/formError";

dayjs.locale("en");
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const ProductivityForm = (props) => {
  const [modal, setModel] = useState(false);
  const [values, setValues] = useState();
  const { productivity, setProductivity } = useUserTimesheet();
  const { selectedDay, initialValues, timesheetInfo } = props;
  const [search, setSearch] = useState("");

  const formattedDate = dayjs(props?.timesheet?.date ?? selectedDay).format(
    "YYYY-MM-DD",
  );

  const { userId } = useParams();

  const { data: currentUserJobs, setUrl } = useApi(
    `users/${userId || props.userId}/current-jobs?date=${formattedDate}`,
    [],
  );

  useEffect(() => {
    setUrl(
      `users/${userId || props.userId}/current-jobs?date=${formattedDate}`,
    );
  }, [selectedDay]);

  const { takeAction } = useApi();

  useEffect(() => {
    timesheetInfo?.startTime && createBreakProductivities();
    return () => {};
  }, [timesheetInfo]);

  const createBreakProductivities = () => {
    const timesheetDay = props.addTimesheet
      ? selectedDay
      : props?.timesheet?.date;
    const shiftStart = dayjs(
      `${dayjs(timesheetDay).format("YYYY-MM-DD")}T${timesheetInfo?.startTime}`,
    ).toDate();

    manageBreakProductivity(
      shiftStart,
      timesheetInfo?.paidBreak > 0,
      timesheetInfo?.unpaidBreak > 0,
    );
  };

  const manageBreakProductivity = (shiftStart, paidBreak, unpaidBreak) => {
    if (!paidBreak && !unpaidBreak) return;

    const addPaidBreak =
      paidBreak &&
      productivity.filter((event) => event?.break_type === BreakType.Paid)
        .length < 1;
    const addUnpaidBreak =
      unpaidBreak &&
      productivity.filter((event) => event?.break_type === BreakType.Unpaid)
        .length < 1;

    if (!addPaidBreak && !addUnpaidBreak) return;

    if (addPaidBreak && addUnpaidBreak) {
      const PaidBreak = createBreakProductivity(shiftStart, BreakType.Paid);
      const UnpaidBreak = createBreakProductivity(shiftStart, BreakType.Unpaid);
      setProductivity([...productivity, PaidBreak, UnpaidBreak]);
      return;
    }
    const Break = createBreakProductivity(
      shiftStart,
      addPaidBreak ? BreakType.Paid : BreakType.Unpaid,
    );
    setProductivity([...productivity, Break]);
  };

  const createBreakProductivity = (start, breakType) => {
    const breakStart = dayjs(start)
      .add(breakType === BreakType.Paid ? 2 : 4, "h")
      .toDate();

    const breakEnd = dayjs(breakStart)
      .add(
        breakType === BreakType.Paid
          ? timesheetInfo?.paidBreak * 60
          : timesheetInfo?.unpaidBreak * 60,
        "m",
      )
      .toDate();

    const randomId = Math.random().toString(36).substring(7);
    return {
      id: randomId,
      uuid: randomId,
      break_type: breakType,
      internal: true,
      title: breakType === BreakType.Paid ? "Paid Break" : "Unpaid Break",
      start: breakStart,
      start_time_date: dayjs(breakStart).format("YYYY-MM-DD"),
      start_time_time: dayjs(breakStart).format("HH:mm:ss"),
      comments: "",
      end: breakEnd,
      job_id: null,
      finish_time_date: dayjs(breakEnd).format("YYYY-MM-DD"),
      finish_time_time: dayjs(breakEnd).format("HH:mm:ss"),
      pulled_from_schedule: false,
    };
  };

  const handleSelect = ({ start, end }) => {
    setValues({
      start_time_date: dayjs(start).format("YYYY-MM-DD"),
      finish_time_date: dayjs(end).format("YYYY-MM-DD"),
      start_time_time: dayjs(start).format("HH:mm:ss"),
      finish_time_time: dayjs(end).format("HH:mm:ss"),
    });
    setModel(true);
  };

  const selectEvent = (event) => {
    if ([BreakType.Paid, BreakType.Unpaid].includes(event?.break_type)) return;

    setValues({
      ...event,
      start_time_date: dayjs(event.start).format("YYYY-MM-DD"),
      finish_time_date: dayjs(event.end).format("YYYY-MM-DD"),
      start_time_time: dayjs(event.start).format("HH:mm:ss"),
      finish_time_time: dayjs(event.end).format("HH:mm:ss"),
      uuid: event.id,
    });
    setModel(true);
  };

  const toggle = () => {
    setModel(false);
    setValues();
  };

  const submitProductivity = (values) => {
    if (values?.id) {
      takeAction("update", `staff-schedules/${values?.uuid}`, {
        start_time: `${values.start_time_date} ${values.start_time_time}`,
        finish_time: `${values.finish_time_date} ${values.finish_time_time}`,
        job_id: values?.job_id,
        comments: values?.comments,
        allowances: values?.allowances,
      });

      setProductivity(
        productivity.map((event) =>
          event?.id === values?.id
            ? {
                ...event,
                ...values,
                uuid: values.id,
                title:
                  values.job_id && values?.project_name
                    ? `${values.project_name} ${values.job_name}`
                    : event?.title,
                start: dayjs(
                  `${values.start_time_date}T${values.start_time_time}`,
                ).toDate(),
                end: dayjs(
                  `${values.finish_time_date}T${values.finish_time_time}`,
                ).toDate(),
                allowances: values?.allowances,
              }
            : event,
        ),
      );

      toggle();
      return;
    }

    return takeAction("store", "staff-schedules", {
      start_time: `${values.start_time_date} ${values.start_time_time}`,
      finish_time: `${values.finish_time_date} ${values.finish_time_time}`,
      job_id: values.job_id,
      user_uuid: props.userId,
      comments: values?.comments,
      allowances: values?.allowances,
    }).then(({ data }) => {
      setProductivity([
        ...productivity,
        {
          ...values,
          id: data.data.uuid,
          uuid: data.data.uuid,
          title: data.data.title,
          start: dayjs(
            `${values.start_time_date}T${values.start_time_time}`,
          ).toDate(),
          end: dayjs(
            `${values.finish_time_date}T${values.finish_time_time}`,
          ).toDate(),
          break_type: null,
          pulled_from_schedule: false,
          project: data.data.project,
          allowances: values?.allowances,
        },
      ]);

      toggle();

      return data.data;
    });
  };

  const updateProductivity = (event, start, end) => {
    if (![BreakType.Paid, BreakType.Unpaid].includes(event?.break_type)) {
      takeAction("update", `staff-schedules/${event?.uuid}`, {
        start_time: dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
        finish_time: dayjs(end).format("YYYY-MM-DD HH:mm:ss"),
        job_id: event?.job_id,
        comments: event?.comments,
      });
    }

    setProductivity(
      productivity.map((productivityEvent) =>
        productivityEvent?.id === event?.id
          ? {
              ...productivityEvent,
              ...event,
              uuid: event.id,
              start: start,
              end: end,
              start_time_date: dayjs(start).format("YYYY-MM-DD"),
              start_time_time: dayjs(start).format("HH:mm:ss"),
              finish_time_date: dayjs(end).format("YYYY-MM-DD"),
              finish_time_time: dayjs(end).format("HH:mm:ss"),
            }
          : productivityEvent,
      ),
    );
    toggle();
  };

  const calendarDefaultDate = initialValues?.start_time
    ? new Date(initialValues.date)
    : selectedDay;

  const DnDcal = () => {
    return (
      <div className="ht-800">
        <DragAndDropCalendar
          localizer={dayjsLocalizer(dayjs)}
          events={productivity}
          startAccessor="start"
          endAccessor="end"
          defaultDate={calendarDefaultDate}
          selectable
          scrollToTime={new Date(1970, 1, 1, 7)}
          defaultView="day"
          views={{ day: true }}
          onSelectSlot={handleSelect}
          onSelectEvent={selectEvent}
          toolbar={null}
          eventPropGetter={eventPropGetter}
          step={15}
          onEventResize={({ event, start, end }) => {
            if (
              [BreakType.Paid, BreakType.Unpaid].includes(event?.break_type)
            ) {
              props.change(
                props.form,
                event?.break_type === BreakType.Paid
                  ? "paid_break"
                  : "unpaid_break",
                dayjs(end).diff(dayjs(start), "minutes") / 60,
              );

              setProductivity(
                productivity.map((productivityEvent) =>
                  productivityEvent?.id === event?.id
                    ? {
                        ...productivityEvent,
                        ...event,
                        uuid: event.id,
                        start: start,
                        end: end,
                        start_time_date: dayjs(start).format("YYYY-MM-DD"),
                        start_time_time: dayjs(start).format("HH:mm:ss"),
                        finish_time_date: dayjs(end).format("YYYY-MM-DD"),
                        finish_time_time: dayjs(end).format("HH:mm:ss"),
                      }
                    : productivityEvent,
                ),
              );

              return;
            }
            updateProductivity(event, start, end);
          }}
          onEventDrop={({ event, start, end }) =>
            updateProductivity(event, start, end)
          }
        />
      </div>
    );
  };

  const filteredJobs = !search
    ? currentUserJobs
    : currentUserJobs.filter((job) => {
        return (
          job?.name?.toLowerCase().includes(search.toLowerCase()) ||
          job?.number?.toLowerCase().includes(search.toLowerCase()) ||
          job.project.full_name?.toLowerCase().includes(search.toLowerCase())
        );
      });

  return (
    <>
      <FormErrorAlert error={props.error} />
      {currentUserJobs.length > 0 ? (
        <div onDrop={(e) => e.stopPropagation()} className="row">
          <FormHeader
            extra={
              <>
                <p className="mb-1">
                  Jobs scheduled for{" "}
                  <span className="fw-bolder text-dark">
                    {" "}
                    {dayjs(selectedDay).format("DD/MM/YYYY")}
                  </span>
                  .
                </p>
                <p className="mb-0">
                  Select jobs from the list below to add to productivity
                </p>
              </>
            }
          >
            Your Jobs
          </FormHeader>
          <div className="col-12 mb-3">
            <SearchInput setValue={setSearch} />
          </div>
        </div>
      ) : (
        <p className="mb-1">
          No jobs scheduled for{" "}
          <span className="fw-bolder text-dark">
            {" "}
            {dayjs(selectedDay).format("DD/MM/YYYY")}
          </span>
          .
        </p>
      )}

      <div className="d-flex bg-body w-100 flex-nowrap overflow-auto pb-1 mb-3  z-10">
        {filteredJobs.map((job) => {
          return (
            <UserJob
              formattedDate={formattedDate}
              job={job}
              key={job.id}
              timesheetInfo={timesheetInfo}
              submitProductivity={submitProductivity}
            />
          );
        })}
      </div>
      <DnDcal />
      {modal && values && (
        <ScheduleModal
          initialValues={values}
          modal={modal}
          toggle={toggle}
          whenSubmitted={submitProductivity}
          onDelete={(id) => {
            takeAction("destroy", `staff-schedules/${id}`);
            setProductivity(productivity.filter((event) => event.id !== id));
          }}
        />
      )}
      {props.showSave && (
        <Button
          outline={!props.valid}
          disabled={!props.valid || props.isSubmitting}
          block
          className="mt-3"
          color="primary"
          onClick={() => {
            props.submit(props.form);
          }}
        >
          {submitting(props.isSubmitting, "Save", "Saving...")}
        </Button>
      )}
    </>
  );
};

const UserJob = ({ job, submitProductivity, formattedDate, timesheetInfo }) => {
  const statusInfo = statusDetails(job);

  const { productivity, setProductivity } = useUserTimesheet();

  const { toggle, modal } = useModal();

  const { data: allowances, setUrl } = useApi("", []);

  const [jobTimes, setJobTimes] = useState({});

  const { takeAction } = useApi();

  const lastProductivity = _.orderBy(productivity, "finish_time_time", [
    "desc",
  ]).filter((p) => p.break_type === null);

  const addJob = (values) => {
    return submitProductivity({
      title: job.name,
      job_id: job.id,
      start_time_date: formattedDate,
      finish_time_date: formattedDate,
      ...values,
    })
      .then((newProductivity) => {
        toast.success(
          <div className="">
            <p className="mb-0">{job.name} added to productivity</p>
            <Button
              type="button"
              className="px-0"
              onClick={() => {
                takeAction(
                  "destroy",
                  `staff-schedules/${newProductivity.uuid}`,
                ).catch(() => {
                  toast.error("Unable to delete productivity");

                  setProductivity([
                    ...productivity,
                    {
                      ...newProductivity,
                      id: newProductivity.uuid,
                      uuid: newProductivity.uuid,
                      title: newProductivity.title,
                      start: dayjs(newProductivity.start_time).toDate(),
                      end: dayjs(newProductivity.finish_time).toDate(),
                      break_type: null,
                      pulled_from_schedule: false,
                    },
                  ]);
                });

                setProductivity(
                  productivity.filter(
                    (event) => event.id !== newProductivity.uuid,
                  ),
                );
              }}
              color="link"
              size="sm"
            >
              Undo
            </Button>
          </div>,
        );
      })
      .catch(formError);
  };

  return (
    <>
      <div className="border text-start me-3 overflow-col-lg-6 overflow-col-12 p-3 overflow-col-md-6 rounded-lg position-relative tn-300 shadow-sm bg-white">
        <div className="d-flex">
          <div className="width-25">
            <div
              id={`job_status_${job.uuid}`}
              className={`me-2 d-flex justify-content-center align-items-center rounded-circle shadow bg-grad-${statusInfo.color}`}
              style={{
                height: "40px",
                width: "40px",
              }}
            >
              <StatusBubble job={job} />
              <UncontrolledTooltip target={`job_status_${job.uuid}`}>
                {statusInfo.text}
              </UncontrolledTooltip>
            </div>
          </div>
          <div className="text-justify no-wrap">
            <a
              id={`job_${job.uuid}`}
              href={job.link}
              target="_blank"
              rel="noreferrer noopener"
              className="mb-2 tx-inverse fw-bold"
              onClick={(e) => e.stopPropagation()}
            >
              {job.name}
              <p className="mb-0 text-secondary fw-normal tx-12">
                {job.number}
              </p>
            </a>
          </div>
          <div className="ms-auto space-x-2 d-flex">
            <Button
              id={`job_description_${job.uuid}`}
              type="button"
              color="link"
              className="p-0 ms-1"
              style={{ height: "14px" }}
            >
              <i className="fa fa-info-circle tx-inverse" />
            </Button>
            <UncontrolledPopover
              placement="auto"
              target={`job_description_${job.uuid}`}
            >
              <PopoverHeader>{job.name} Description</PopoverHeader>
              <PopoverBody className="white-space">
                {job.description}
              </PopoverBody>
            </UncontrolledPopover>
            <UncontrolledTooltip
              placement="top-start"
              target={`job_${job.uuid}`}
            >
              {job.name}
            </UncontrolledTooltip>
          </div>
        </div>
        <div className="d-flex align-items-center mt-4 space-x-2">
          <Button
            onClick={() => {
              if (job.project.allowance_count > 0) {
                toggle();
                setUrl(`projects/${job.project.uuid}/allowances`);

                setJobTimes({
                  start_time_time:
                    job.scheduled_start_time ??
                    lastProductivity[0]?.finish_time_time ??
                    "09:00",
                  finish_time_time:
                    job.scheduled_finish_time ??
                    getFinishTime(lastProductivity[0]),
                });

                return;
              }

              addJob({
                start_time_time:
                  job.scheduled_start_time ??
                  lastProductivity[0]?.finish_time_time ??
                  "09:00",
                finish_time_time:
                  job.scheduled_finish_time ??
                  getFinishTime(lastProductivity[0]),
              });
            }}
            className="px-0 text-dark"
            color="link"
          >
            Add to Productivity
          </Button>
          <p className="mb-0 text-dark">|</p>
          <Button
            onClick={() => {
              if (job.project.allowance_count > 0) {
                toggle();
                setUrl(`projects/${job.project.uuid}/allowances`);

                setJobTimes({
                  start_time_time: timesheetInfo.startTime ?? "09:00",
                  finish_time_time: timesheetInfo.finishTime ?? "17:00",
                });

                return;
              }

              addJob({
                start_time_time: timesheetInfo.startTime ?? "09:00",
                finish_time_time: timesheetInfo.finishTime ?? "17:00",
              });
            }}
            className="px-0 text-dark"
            color="link"
          >
            Add as Full Day
          </Button>
        </div>
        <div className="mt-2 space-y-3">
          <p className="mb-0 text-secondary">{job.project.full_name}</p>
          <p className="mb-0 text-secondary tx-12">
            Scheduled to be finished on{" "}
            <span className="tx-inverse">
              {dayjs(job.scheduled_finish_date).format("DD/MM/YYYY")}
            </span>
          </p>
        </div>
        <BottomLogo size={30} bottom={25} left={235} />
      </div>
      <AllowanceModal
        job={job}
        allowances={allowances}
        modal={modal}
        toggle={toggle}
        onSubmit={(values) => {
          return addJob({
            ...jobTimes,
            allowances: values.allowances.map((allowance) => ({
              project_allowance_id: allowance.id,
              quantity: allowance.quantity,
              ...allowance,
            })),
          });
        }}
      />
    </>
  );
};

const eventPropGetter = (event) => {
  if (event.job_id) {
    return {
      className: "bg-info",
      style: { borderColor: "#6bb0ff" },
    };
  }
  if ([BreakType.Paid, BreakType.Unpaid].includes(event?.break_type)) {
    return {
      className: "bg-warning border-warning",
      style: { borderColor: colors.info.border },
    };
  }
  return {
    className: "bg-primary",
    style: { borderColor: "#00538e" },
  };
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form);

  return {
    selectedDay: props?.timesheet?.date ?? state.timesheets.selectedDay,
    timesheetInfo: {
      startTime: selector(state, "start_time"),
      finishTime: selector(state, "finish_time"),
      nightShift: selector(state, "night_shift"),
      paidBreak: selector(state, "paid_break"),
      unpaidBreak: selector(state, "unpaid_break"),
    },
    error: getFormError(props.form)(state),
    isSubmitting: isSubmitting(props.form)(state),
    valid: isValid(props.form)(state),
  };
};

const getFinishTime = (productivity) => {
  if (!productivity) {
    return "10:00";
  }

  return dayjs(
    `${productivity.start_time_date} ${productivity.finish_time_time}`,
  )
    .add(1, "hour")
    .format("HH:mm");
};

export default connect(mapStateToProps, {
  change,
  submit,
})(ProductivityForm);
