import axios from "../api/api";
import { FETCH_OVERSIZE_FIELD_MOISTURES } from "../actions/types";

export const fetchOversizeFieldMoistures = (uuid) => (dispatch) => {
  return axios
    .get(`/oversize-field-moistures/${uuid}`)
    .then((response) => response.data.data)
    .then((oversizeFieldMoistures) =>
      dispatch({
        type: FETCH_OVERSIZE_FIELD_MOISTURES,
        payload: oversizeFieldMoistures,
      }),
    );
};
