import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ApprovalStatus from "../user/ApprovalStatus";

const TimesheetTable = ({ timesheets, showUser }) => {
  return (
    <div className="table-responsive rounded-lg shadow-sm border">
      <table className="table w-100 table-striped bg-white">
        <thead className="thead-inverse">
          <tr>
            <th>Date</th>
            <th>Type</th>
            {showUser && <th>User</th>}
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          {timesheets.map((timesheet) => (
            <tr key={timesheet.uuid}>
              <td>
                <Link to={`/timesheets/${timesheet.uuid}/details`}>
                  {timesheet.display_date}
                </Link>
                <p className="mg-b-0">Total Hours: {timesheet.total_hours}</p>
                <p className="mg-b-0">
                  {timesheet.pay?.pay_run?.link ? (
                    <Link to={timesheet.pay?.pay_run?.link}>
                      {timesheet.pay?.pay_run?.date_range}
                    </Link>
                  ) : (
                    timesheet.pay?.pay_run?.date_range
                  )}
                </p>
                <PayBadge timesheet={timesheet} />
                <ApprovalStatus status={timesheet.approval_status} />
              </td>
              <td>{timesheet.type_description}</td>
              {showUser && <td>{timesheet.user}</td>}
              <td>{timesheet.start_time}</td>
              <td>{timesheet.finish_time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PayBadge = ({ timesheet }) => {
  if (!timesheet.pay) {
    return null;
  }

  return (
    <div
      className={`badge ${
        timesheet.pay.completed_at ? "bg-success" : "bg-warning"
      } rounded-pill`}
    >
      {timesheet.pay.completed_at ? "P" : "Unp"}aid Pay Run{" "}
      <PayRunDisplay payRun={timesheet.pay.pay_run} />
    </div>
  );
};

const PayRunDisplay = ({ payRun }) => {
  if (payRun.link) {
    return (
      <Link className="text-white" to={payRun?.link}>
        {payRun?.date_range}
      </Link>
    );
  }

  return payRun?.date_range;
};

TimesheetTable.propTypes = {
  timesheets: PropTypes.array.isRequired,
};

export default TimesheetTable;
