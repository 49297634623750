import { useState } from "react";
import { UserIndex } from "../user/userTypes";
import Avatar from "../utils/Avatar";
import useJobOverviewDashboardUsers from "./hooks/useJobOverviewDashboardUsers";
import SearchInput from "../search/SearchInput";

const JobOverviewDashboardUsers = ({
  setDraggingUser,
  draggingUser,
  filters,
}: {
  setDraggingUser: (user?: UserIndex) => void;
  draggingUser?: UserIndex;
  filters: { name: string; value: string[] | number[] }[];
}) => {
  const [search, setSearch] = useState("");

  const { data: users } = useJobOverviewDashboardUsers(search, filters);

  return (
    <div
      className="col-lg-4 d-none d-md-block"
      style={{ height: "65vh", overflow: "auto" }}
    >
      <SearchInput setValue={setSearch} wrapperclass="mb-3" />

      <div className="list-group">
        {users?.map((user) => {
          return (
            <div
              draggable
              onDragStart={() => setDraggingUser(user)}
              onDragEnd={() => setDraggingUser(undefined)}
              key={user.uuid}
              className="list-group-item pd-y-20 d-flex align-items-center pointer-hover tn-300"
            >
              <Avatar rounded="-circle" name={user.full_name} colour="info" />
              <div>
                <p className="mb-0">{user.full_name}</p>
                <p className="mb-0 text-secondary">
                  {user.jobs_count} job{user.jobs_count === 1 ? "" : "s"}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JobOverviewDashboardUsers;
