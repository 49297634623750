import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { fieldTypes } from "../../../enums/FieldType";
import { CustomField } from "../../customFields/customFieldTypes";
import ProfileForm from "../../customFields/ProfileForm";
import { TestSuiteStatus, TestSuiteStatusPages } from "../testSuiteTypes";
import TestSuiteStatusForm from "./TestSuiteStatusForm";

interface ShowTestSuiteStatusPageProps {
  onSubmit: FormSubmitHandler<TestSuiteStatus>;
  status: TestSuiteStatus;
  setStatus: (status: TestSuiteStatus) => void;
}

const ShowTestSuiteStatusPage = ({
  onSubmit,
  status,
  setStatus,
}: ShowTestSuiteStatusPageProps) => {
  const { page } = useParams<{ page: TestSuiteStatusPages }>();

  switch (page) {
    case "details":
      return (
        <TestSuiteStatusForm
          onSubmit={onSubmit}
          initialValues={status}
          form="UpdateTestSuiteStatus"
        />
      );
    case "fields":
      return (
        <ProfileForm
          initialValues={status}
          modelId={status.id}
          modelType="App\Models\TestSuiteStatus"
          onSuccess={(data: { data: CustomField[] }) =>
            setStatus({
              ...status,
              custom_fields: data.data,
            })
          }
          fieldTypes={[
            ...fieldTypes,
            {
              label: "Moisture",
              value: 12,
            },
          ]}
        />
      );
    default:
      return null;
  }
};

export default ShowTestSuiteStatusPage;
