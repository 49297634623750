import React from "react";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import FormModal from "../../utils/FormModal";
import { CustomJobJob, Understanding } from "./customJobTypes";

import { toast } from "react-toastify";
import formError from "../../utils/formError";
import RenderField from "../../utils/renderField";
import required from "../../utils/required";
import SelectInput from "../../form/SelectInput";
import SignaturePad from "../../utils/signaturePad";

interface AddUnderstandingModalProps {
  job: CustomJobJob;
  setJob: (job: CustomJobJob) => void;
  toggle: Function;
  modal: boolean;
}

const AddUnderstandingModal = (props: AddUnderstandingModalProps) => {
  const { modal, toggle, job, setJob } = props;

  const { takeAction }: IUseApi<{}, { data: Understanding }> = useApi();

  const onSubmit: FormSubmitHandler<Partial<Understanding>> = (values) => {
    return takeAction("store", "understandings", {
      ...values,
      understandable_type: "App\\Models\\ProjectJob",
      understandable_id: job.id,
    })
      .then(({ data }) => {
        setJob({
          ...job,
          understandings: [...job.understandings, data.data],
        });

        toggle();
        toast.success("Sign in Added");
      })
      .catch(formError);
  };

  return (
    <FormModal
      title="Add Sign In"
      form="ADD_UNDERSTANDING"
      toggle={toggle}
      modal={modal}
      onSubmit={onSubmit}
    >
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="name"
          label="Name"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInput}
          name="type"
          label="Type"
          required
          validate={required}
          options={[
            {
              label: "Staff",
              value: "Staff",
            },
            {
              label: "Subcontractor",
              value: "Subcontractor",
            },
            {
              label: "Visitor",
              value: "Visitor",
            },
          ]}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field component={SignaturePad} label="Signature" name="signature" />
      </div>
    </FormModal>
  );
};

export default AddUnderstandingModal;
