import { useMemo } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import { AuthUser } from "../user/userTypes";
import Avatar from "../utils/Avatar";
import useProjects from "./hooks/useProjects";
import { ProjectIndex } from "./projectTypes";
import { Equipment, ProjectJob, Purchase, Industry } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";
import { Tab } from "../tabs/tabTypes";
import Tabs from "../tabs/Tabs";
import ProjectTable from "./ProjectTable";
import ProjectsMap from "./ProjectsMap";

export type ProjectFilters = IFilter<
  | "status"
  | "internal"
  | "project_manager"
  | "user_is_part_of_project"
  | "is_template",
  boolean | number
>;

const List = () => {
  const filters = useFilters();
  const tabs: Tab[] = [
    {
      title: "Cards",
      component: (
        <PaginatedList
          listName="projectsList"
          indexHook={useProjects}
          originalFilters={filters}
          itemCard={({ item }: { item: ProjectIndex }) => (
            <PaginatedCard
              key={item.uuid}
              info={info(item)}
              header={<ProjectHeader project={item} />}
              bottomContent={<ProjectFooter project={item} />}
            />
          )}
        />
      ),
    },
    {
      title: "Table",
      component: (
        <PaginatedList
          listName="projectsList"
          indexHook={useProjects}
          originalFilters={filters}
          list={({ data }: { data?: ProjectIndex[] }) => {
            return (
              <div>
                <ProjectTable projects={data ?? []} />
              </div>
            );
          }}
        />
      ),
    },
    {
      title: "Map",
      component: <ProjectsMap />,
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/" />
      <HeaderPage
        titlePage="Projects"
        pageDescription={
          <>
            Create projects, assign tasks and responsibilities, add purchases
            and contract agreements, and set allowances for team members.
          </>
        }
        relatedLinks={[
          {
            name: "Equipment",
            link: "/equipment",
            model: Equipment,
          },
          { name: "Jobs", link: "/jobs", model: ProjectJob },
          {
            name: "Purchases",
            link: "/purchases",
            model: Purchase,
          },
          { name: "Industries", link: "/industries", model: Industry },
        ]}
        crumbs={[{ link: "projects", name: "Projects" }]}
      />
      <div className="mb-3">
        <Link to="/projects/add" className="btn btn-outline-primary">
          Add new project
        </Link>
      </div>
      <Tabs
        tabs={tabs}
        startingTab={parseInt(localStorage.getItem("project_list_tab") ?? "0")}
        onSelect={(i) => {
          localStorage.setItem("project_list_tab", `${i}`);
        }}
      />
    </>
  );
};

const ProjectHeader = ({ project }: { project: ProjectIndex }) => {
  const tooltipId = useMemo(
    () => `project_title_${project.uuid}`,
    [project.uuid],
  );

  const color = project.is_template
    ? "primary"
    : project.status
    ? "secondary"
    : "info";

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={project.name} colour={color} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/projects/${project.uuid}/details`}>{project.name}</Link>
          </p>
          {project.uuid && (
            <UncontrolledTooltip placement="top-start" target={tooltipId}>
              {project.name}
            </UncontrolledTooltip>
          )}
          <p className="mb-0">{project.number}</p>
        </div>
      </div>
    </div>
  );
};

const ProjectFooter = ({ project }: { project: ProjectIndex }) => {
  const color = project.is_template
    ? "bg-primary"
    : project.status
    ? "bg-secondary"
    : "bg-info";

  const text = project.is_template
    ? "Template"
    : project.status
    ? "Closed"
    : "Open";

  return (
    <div className="d-flex align-items-center mb-1 mt-2">
      <div className=" ms-auto">
        <div
          className={`${color} shadow rounded-pill text-white tx-10 text-center px-2`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

const info = (project: ProjectIndex): PaginatedInformation[] => {
  return [
    {
      name: "Client",
      value: project.internal ? "Internal Project" : project?.client?.name,
      link: project?.client?.link,
    },
    {
      name: "Industry",
      value: project.industry,
    },
    {
      name: "Project Manager",
      value: project.manager.name,
    },
    {
      name: "Location",
      value: project.location,
    },
    {
      name: "Tags",
      value: (
        <div className="d-flex space-x-3  overflow-x-auto hide-scrollbar">
          {project.tags.map((tag) => (
            <div className="bg-indigo text-white rounded-pill text-center px-2 tx-10">
              {tag.value}
            </div>
          ))}
        </div>
      ),
    },
  ];
};

const useFilters = (): ProjectFilters[] => {
  const { user } = useAuth();
  const authUser = user as AuthUser;

  return [
    {
      label: "Your Projects",
      name: "user_is_part_of_project",
      options: [
        {
          label: "Your Projects",
          value: authUser.id,
        },
      ],
    },
    {
      label: "Status",
      name: "status",
      options: [
        {
          label: "Open",
          value: 0,
          selected: true,
        },
        {
          label: "Closed",
          value: 1,
        },
      ],
    },
    {
      label: "Internal",
      name: "internal",
      options: [
        {
          label: "Internal",
          value: true,
        },
        {
          label: "External",
          value: false,
        },
      ],
    },
    {
      label: "Template Projects",
      name: "is_template",
      options: [
        {
          label: "Template Projects",
          value: true,
        },
        {
          label: "Non Template Projects",
          value: false,
          selected: true,
        },
      ],
    },
  ];
};

export default List;
