import { useEffect, useState } from "react";
import Select, { components }  from "react-select";
import useApi from "../api/useApi";
import { primary } from "../utils/Colours";
import { da } from "date-fns/locale";

const SelectInput = (props) => {
  const {
    url,
    formatData,
    input,
    isMulti,
    empty,
    getOptionValues,
    required,
    isDisabled = false,
    meta: { touched, error, warning },
    optionStyles = {},
    menuStyles = {},
    indicatorsContainerStyles = {},
    valueContainerStyles = {},
    selectClass = "shadow-sm",
    isClearable = true,
    defaultValue,
  } = props;

  const { data, setUrl, loading } = useApi();

  const [options, setOptions] = useState(props.options ?? []);
  
  useEffect(() => {
    setUrl(url);

    if (!url && !props.options) {
      setOptions([]);
    }
  }, [url]);

  useEffect(() => {
    if (options != props.options) {
      setOptions(props.options ?? []);
    }
  }, [props.options]);

  useEffect(() => {
    if (getOptionValues) {
      getOptionValues(options, props.input.onChange);
    }
  }, [options]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (formatData) {
      setOptions(formatData(data) ?? []);
      return;
    }

    setOptions(data ?? []);
  }, [data, formatData]);

  const value = isMulti
    ? input.value
    : options.filter((option) => input.value === option.value);

  const isLoading = props.loading ?? loading;

  const styles = {
    menu: (provided) => {
      return {
        ...provided,
        zIndex: 15,
        ...menuStyles,
      };
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#e6e6e6" : styles.backgroundColor,
        color: isFocused ? "gray" : styles.color,
        ...optionStyles,
      };
    },
    control: (styles, { isFocused, isDisabled }) => {
      let newStyles = {
        ...styles,
      };

      if (props.controlStyles) {
        newStyles = {
          ...styles,
          ...props.controlStyles,
        };
      }

      if (isDisabled) {
        newStyles = {
          ...newStyles,
          backgroundColor: "#e9ecef",
          border: "1px solid #ced4da",
        };
      }

      return newStyles;
    },

    dropdownIndicator: (provided) => {
      return {
        ...provided,
        ...indicatorsContainerStyles,
      };
    },
    clearIndicator: (provided) => {
      return {
        ...provided,
        ...indicatorsContainerStyles,
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        ...indicatorsContainerStyles,
      };
    },
    valueContainer: (provided) => {
      // @ts-ignore
      return {
        ...provided,
        ...valueContainerStyles,
      };
    },
  };

  const CustomMultiValue = (props) => {
    const { value, label } = props.data;
    const newTheme = { ...props.theme };

    return (
      <components.MultiValue {...props} theme={newTheme}>
        <span style={{
          paddingRight: "5px",
        }}>{label}</span>
      </components.MultiValue>
    );
  };

  const CustomMultiValueRemove = (props) => {
    const { value, label } = props.data;

    if (value == defaultValue) {
      return null;
    }
    
    return (
      <components.MultiValueRemove {...props}></components.MultiValueRemove>
    );
  };
  
  return (
    <div>
      {props.label && (
        <div className="d-flex space-x-1">
          <label className="form-control-label tx-inverse tx-semibold">
            {props.label}
          </label>
          {required ? <span className="tx-danger"> *</span> : ""}
        </div>
      )}
      <Select
        isClearable={isClearable}
        {...props}
        {...props.input}
        isOptionDisabled={(option) => option.disabled}
        onChange={(value, { action }) => {
          if (props.changeValue) {
            props.changeValue(value);
          }

          if (action === "clear") {
            props.input.onChange(null);
          } else {
            props.input.onChange(isMulti ? value : value.value);
          }
        }}
        onBlur={() => props.input.onBlur()}
        isLoading={isLoading}
        options={options}
        value={value}
        theme={theme}
        className={selectClass}
        styles={styles}
        isDisabled={isDisabled}
        components={{
          MultiValue: CustomMultiValue,
          MultiValueRemove: CustomMultiValueRemove,
        }}
      />
      {url && !isLoading && options.length === 0 && (
        <small className="d-block">{empty}</small>
      )}
      {touched && warning && <span className="tx-warning">{warning}</span>}
      {touched && error && <span className="parsley-errors-list">{error}</span>}
    </div>
  );
};

const theme = (theme) => {
  return {
    ...theme,
    borderRadius: "0.3rem",
    spacing: {
      ...theme.spacing,
      controlHeight: "calc(1.5em + 1.188rem + 2px)",
    },

    colors: {
      ...theme.colors,
      primary: primary,
    },
  };
};

export default SelectInput;
