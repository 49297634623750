import React from "react";
import { useParams } from "react-router-dom";
import RegisterDetails from "./RegisterDetails";
import { RegisterPages, RegisterProps } from "./registerTypes";
import NotFound from "../404";
import ProfileForm from "../customFields/ProfileForm";
import { CustomField } from "../customFields/customFieldTypes";

const ShowRegisterPage = (props: RegisterProps) => {
  const { page } = useParams<{ page: RegisterPages }>();

  const { register, setRegister } = props;

  switch (page) {
    case "details":
      return <RegisterDetails {...props} />;
    case "fields":
      return (
        <ProfileForm
          initialValues={register}
          {...props}
          modelId={register.id}
          modelType="App\Models\Register"
          onSuccess={(data: { data: CustomField[] }) =>
            setRegister({
              ...register,
              custom_fields: data.data,
            })
          }
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowRegisterPage;
