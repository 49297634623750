import React from "react";
import { PerformanceReviewTemplate } from "./perfomanceReviewTypes";
import { useParams } from "react-router-dom";
import PerformanceReviewTemplateForm from "./PerformanceReviewTemplateForm";
import { FormSubmitHandler } from "redux-form";
import ProfileForm from "../customFields/ProfileForm";
import FormHeader from "../utils/FormHeader";

export interface PerformanceReviewTemplatePageProps {
  performanceReviewTemplate: PerformanceReviewTemplate;
  setPerformanceReviewTemplate: (
    performanceReviewTemplate: PerformanceReviewTemplate,
  ) => void;
}

const ShowPerformanceReviewTemplatePage = ({
  performanceReviewTemplate,
  setPerformanceReviewTemplate,
  onSubmit,
}: PerformanceReviewTemplatePageProps & {
  onSubmit: FormSubmitHandler<PerformanceReviewTemplate>;
}) => {
  const { page } = useParams<{ page: string }>();

  switch (page) {
    case "details":
      return (
        <PerformanceReviewTemplateForm
          form="PerformanceReviewTemplate"
          onSubmit={onSubmit}
          initialValues={performanceReviewTemplate}
        />
      );
    case "employee-questions":
      return (
        <>
          <FormHeader>Employee Questions</FormHeader>
          <ProfileForm
            modelType="App\Models\PerformanceReviewEmployeeQuestion"
            modelId={performanceReviewTemplate.employee_questions.id}
            onSuccess={console.log}
            initialValues={performanceReviewTemplate.employee_questions}
          />
        </>
      );
    case "manager-questions":
      return (
        <>
          <FormHeader>Manager Questions</FormHeader>
          <ProfileForm
            modelType="App\Models\PerformanceReviewManagerQuestion"
            modelId={performanceReviewTemplate.manager_questions.id}
            onSuccess={console.log}
            initialValues={performanceReviewTemplate.manager_questions}
          />
        </>
      );
    case "check-in-questions":
      return (
        <>
          <FormHeader>Check In Questions</FormHeader>
          <ProfileForm
            modelType="App\Models\PerformanceReviewCheckInQuestion"
            modelId={performanceReviewTemplate.check_in_questions.id}
            onSuccess={console.log}
            initialValues={performanceReviewTemplate.check_in_questions}
          />
        </>
      );
    default:
      return <></>;
  }
};

export default ShowPerformanceReviewTemplatePage;
