import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { Progress } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import ApprovalStatus from "../user/ApprovalStatus";
import getBgColor from "../utils/getBgColor";
import RequiresRenewalToggle from "./RequiresRenewalToggle";

const Skill = (props) => {
  const { competency, index, staffCompetencies } = props;
  const { user } = useAuth();

  const { uuid } = useParams();

  const requiresRenewal =
    !user.is_admin || (!competency.renew_on && competency.requires_renewal);

  return (
    <Link to={`/staff-matrix/users/${uuid}/${competency.uuid}`}>
      <div
        className="pointer-hover link-hover pd-20 border-top border-end border-start bg-white"
        style={{
          borderRadius:
            index === 0 ? "0.3rem 0.3rem 0px 0px" : "0px 0px 0px 0px",
        }}
      >
        <p className="mb-0 tx-inverse tx-semibold">
          {competency.skill_level.name}
        </p>
        <p className="mb-0 text-secondary">{competency.skill_level.level} </p>

        <div
          className="d-flex align-items-end"
          style={{ marginTop: requiresRenewal ? "27.5px" : "0" }}
        >
          <CompetencyBadge {...props} />
          <RequiresRenewalToggle {...props} />
        </div>
      </div>
      <Progress
        color={getProgressColour(competency)}
        value={
          !competency.requires_renewal || !competency.renew_on
            ? 100
            : competency.percentage
        }
        animated
        className="progress-bar-xs"
        style={{
          borderRadius:
            staffCompetencies.length === index + 1
              ? "0px 0px 0.3rem 0.3rem"
              : "0px 0px 0px 0px",
        }}
      />
    </Link>
  );
};

const getProgressColour = (competency) => {
  if (!competency.requires_renewal || competency.deleted_at) {
    return "secondary";
  }

  if (competency.approval_status?.status === "To be Approved") {
    return "info";
  }

  if (
    competency.approval_status &&
    competency.approval_status?.status !== "Approved"
  ) {
    return "warning";
  }

  if (!competency.renew_on && !competency.is_complete) {
    return "danger";
  }

  if (competency.is_complete) {
    return "success";
  }

  return getBgColor(competency.percentage);
};

//Using this for the search
export const competencyBadgeText = (competency) => {
  if (!competency.requires_renewal || competency.deleted_at) {
    return "Not Required";
  }

  if (competency.percentage === 100) {
    return "Due";
  }

  if (competency.is_complete) {
    return "Complete";
  }

  if (!competency.renew_on && !competency.is_complete) {
    return "Due";
  }

  return !competency.renew_on
    ? "Initial Competency Completed"
    : `Renew on ${dayjs(competency.renew_on).format("DD/MM/YYYY")}`;
};

const CompetencyBadge = ({ competency }) => {
  if (
    competency.approval_status &&
    competency.approval_status?.status !== "Approved"
  ) {
    return (
      <ApprovalStatus
        className="rounded-pill shadow"
        status={competency.approval_status}
      />
    );
  }

  if (competency.deleted_at) {
    return (
      <span className="shadow rounded-pill badge bg-secondary">Deleted</span>
    );
  }

  if (!competency.requires_renewal) {
    return (
      <span className="shadow rounded-pill badge bg-secondary">
        Not Required
      </span>
    );
  }

  if (competency.is_complete) {
    return (
      <span className="shadow rounded-pill badge bg-success">Complete</span>
    );
  }

  if (competency.percentage === 100 || !competency.renew_on) {
    return <span className="shadow rounded-pill badge bg-danger">Due</span>;
  }

  const badgeText = !competency.renew_on
    ? "Initial Competency Completed"
    : `Renew on ${dayjs(competency.renew_on).format("DD/MM/YYYY")}`;

  return (
    <span
      className={`shadow rounded-pill badge bg-${getBgColor(
        competency.percentage,
      )}`}
    >
      {badgeText}
    </span>
  );
};

export default Skill;
