import useModal from "../hooks/useModal";

interface PriceBandModalProps {
    content: (toggle: () => void) => JSX.Element | null;
    toggle: () => void;
}

const PriceBandModal = (props : PriceBandModalProps) => {
    const { content, toggle  } = props;
    const { modal } = useModal();

    return (
        <>
            {modal}
            {content && content(toggle)}
        </>
    );
};

export default PriceBandModal;