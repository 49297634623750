import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { IncompleteUserTask } from "./userTypes";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import FormHeader from "../utils/FormHeader";

const IncompleteTasksModal = ({
  toggle,
  modal,
  tasks,
}: {
  toggle: () => void;
  modal: boolean;
  tasks?: IncompleteUserTask[];
}) => {
  return (
    <Modal className="wd-md-1000 mx-wd-800 w-95" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Incomplete Tasks</ModalHeader>
      <ModalBody>
        <div className="space-y-7">
          {tasks?.map((task: IncompleteUserTask) => (
            <IncompleteTaskItem tasks={task} key={task.title} />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const IncompleteTaskItem = ({ tasks }: { tasks: IncompleteUserTask }) => {
  const columns = [
    {
        accessorKey: "name",
        header: tasks.title == "Group Leader" ? "Group":"Task",
        cell: (info: any) => <Link to={info.row.original.link}>{info.getValue()}</Link>,
    },
];

  return (
    <div>
      <div>
        <FormHeader
          extra={<p className="mb-0 text-secondary">{tasks.sub_title}</p>}
          className="text-dark"
        >
          {tasks.title} ({tasks.count})
        </FormHeader>
      </div>
      <ReactTable disableSearch columns={columns} data={tasks.items} />
    </div>
  );
};

export default IncompleteTasksModal;
