const Empty = ({ project, toggle }) => {
  return (
    <div className="d-flex flex-wrap w-100 justify-content-center bg-white p-5 border">
      <button type="button" className="btn btn-link p-0" onClick={toggle}>
        <img className="w-100" src="/img/empty_recurring.svg" height="300" />
      </button>
      <p className="fw-bold w-100 tx-inverse text-center mt-5 mb-2">
        No Recurring Jobs Found for {project.full_name}.
      </p>
      <p className="w-100  text-center mb-0">
        Recurring jobs allow you to set jobs to be created at particular time
        periods.
      </p>
      <p className="w-100  text-center mb-0">
        This is handy for repetitive tasks (e.g. send invoices at the end of
        every month).
      </p>
      <p className="w-100  text-center mb-0">
        <button type="button" onClick={toggle} className="btn btn-link p-0">
          Click here
        </button>{" "}
        to get started.
      </p>
    </div>
  );
};

export default Empty;
