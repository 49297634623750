import { Position, Qualification, SkillCategory } from "../../enums/Model";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import DocsModal from "../utils/DocsModal";
import MatrixNav from "./MatrixNav";
import SkillStaffMatrix from "./SkillStaffMatrix";
import UserStaffMatrix from "./UserStaffMatrix";

const Matrix = ({ page }: { page: "users" | "skills" }) => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/hr-staff-training/the-staff-matrix-9103/" />
      <HeaderPageAdminFilter
        titlePage="Staff Matrix"
        pageDescription={
          <>
            Track the skills and knowledge of your employees and ensure they are
            up-to-date with the skills required for their job.
          </>
        }
        relatedLinks={[
          {
            name: "Positions",
            link: "/positions",
            model: Position,
          },
          {
            name: "Skill Categories",
            link: "/skill-categories",
            model: SkillCategory,
          },
          {
            name: "Licences/Registrations/Qualifications",
            link: "/qualifications",
            model: Qualification,
          },
        ]}
        crumbs={[{ link: "", name: "Staff Matrix", active: true }]}
      />
      <div className="row">
        <div className="col-12">
          <MatrixNav page={page} />
        </div>
        {page === "users" ? <UserStaffMatrix /> : <SkillStaffMatrix />}
      </div>
    </>
  );
};

export default Matrix;
