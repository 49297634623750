import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import PayRunForm from "./PayRunForm";

const AddPayRun = (props) => {
  const { takeAction } = useApi();

  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", "pay-runs", values)
      .then(() => {
        toast.success("Pay run added successfully");
        history.push("/pay-runs");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Pay Run"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "pay-runs", name: "Pay Runs" },
          { link: "", name: "New Pay Run", active: true },
        ]}
      />

      <form onSubmit={props.handleSubmit(onSubmit)}>
        <PayRunForm {...props} />
      </form>
    </>
  );
};

const form = reduxForm({
  form: "addPayRun",
  initialValues: {
    include_recurring_deductions: true,
    include_allowances: true,
  },
});
export default form(AddPayRun);
