import { Field, FieldArray } from "redux-form";
import SelectInput from "../form/SelectInput";
import buttonGroup from "../utils/buttonGroup";
import RenderField from "../utils/renderField";
import CustomFieldCodeEditor from "./CustomFieldCodeEditor";
import { CustomField, IDataSeries, Regression } from "./customFieldTypes";
import DataSeriesValues from "./DataSeriesValues";

const DataSeriesType = ({
  vals,
  field,
  index,
  currentValue,
  fields,
  parentIndex,
  isFirstJsEditor,
  codeSuggestions,
  setCodeSuggestions,
}: {
  vals: any;
  field: string;
  index: number;
  currentValue: IDataSeries;
  fields: CustomField[];
  parentIndex: number;
  isFirstJsEditor: boolean;
  codeSuggestions: any;
  setCodeSuggestions: any;
}) => {
  return (
    <>
      <div className="">
        <p className="mb-0 tx-inverse fw-bolder">Data Series {index + 1}</p>
      </div>
      <div className="col-lg-6 form-group">
        <Field
          className="ms-auto"
          component={buttonGroup}
          name={`${field}.type`}
          options={[
            {
              label: "Values",
              value: "values",
            },
            {
              label: "Formula",
              value: "formula",
            },
          ]}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name={`${field}.color`}
          label="Colour"
          type="color"
        />
      </div>

      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          label="Regression Line"
          name={`${field}.regression_line`}
          options={regressionOptions}
        />
      </div>
      {currentValue?.type === "formula" && (
        <>
          <p className="tx-inverse">Formula</p>
          <Field
            component={CustomFieldCodeEditor}
            name={`${field}.formula`}
            vals={vals}
            fields={fields}
            codeSuggestions={codeSuggestions}
            setCodeSuggestions={setCodeSuggestions}
            isFirstJsEditor={isFirstJsEditor}
          />
        </>
      )}
      {currentValue?.type === "values" && (
        <>
          <p className="tx-inverse">Values</p>
          <FieldArray
            component={DataSeriesValues}
            name={`${field}.values`}
            customFields={fields}
            parentIndex={parentIndex}
          />
        </>
      )}
    </>
  );
};

const regressionOptions = [
  {
    label: "Linear",
    value: Regression.Linear,
  },
  {
    label: "Quadratic",
    value: Regression.Quadratic,
  },
  {
    label: "3rd order Polynomial",
    value: Regression.Polynomial,
  },
  {
    label: "Exponential",
    value: Regression.Exponential,
  },
  {
    label: "Logarithmic",
    value: Regression.Logarithmic,
  },
  {
    label: "Power Law",
    value: Regression.PowerLaw,
  },
];

export default DataSeriesType;
