import { Button } from "reactstrap";
import { WrappedFieldProps } from "redux-form";
import colors from "../../data/colors";
import LIKELIHOOD from "../../data/likelihood";
import { riskMatrix } from "../../data/risk_matrix";
import SEVERITY from "../../data/severity";
import { BootstrapColour } from "../utils/utilTypes";

interface RiskMatrixProps {
  change: (field: string, value: any) => void;
  likelihood: number;
  severity: number;
}

const RiskMatrix = ({
  input,
  meta,
  change,
  likelihood,
  severity,
}: WrappedFieldProps & RiskMatrixProps) => {
  const onClick = (severity: number, likelihood: number) => {
    change("severity", severity);
    change("likelihood", likelihood);
  };

  const inError = meta.touched && meta.error;

  return (
    <div className="col-12 mb-3 form-group mb-4">
      <label className="form-control-label tx-inverse tx-semibold">
        Risk Level
      </label>
      <div className="row mb-3">
        <p className="col mb-0 width-20 text-center tx-inverse"></p>
        {SEVERITY.map((sev) => {
          return (
            <p className="col fw-bolder mb-0 width-20 text-center tx-inverse ">
              {sev.label}
            </p>
          );
        })}
      </div>
      {LIKELIHOOD.map((likel) => {
        return (
          <div className="row">
            <div className="col width-20 d-flex align-items-center">
              <p className="mb-0 tx-inverse fw-bolder">{likel.label}</p>
            </div>
            {/* <div className="col width-20">
              <p className="mb-0">{likel.frequency}</p>
            </div> */}

            {SEVERITY.map((sev) => {
              //
              const score =
                riskMatrix[parseInt(likel.value)][parseInt(sev.value)];

              const riskValue = getValue(score);
              const color = colors[riskValue.color];

              const isActive =
                parseInt(likel.value) === likelihood &&
                parseInt(sev.value) === severity;
              return (
                <Button
                  size="sm"
                  type="button"
                  color={isActive ? riskValue.color : undefined}
                  onClick={() =>
                    onClick(parseInt(sev.value), parseInt(likel.value))
                  }
                  className="col width-20 rounded-0 shadow-none py-3 "
                  style={
                    !isActive
                      ? {
                          backgroundColor: color.light,
                          border: "none",
                          color: color.main,
                        }
                      : { border: "none" }
                  }
                >
                  {riskValue.name}
                </Button>
              );
            })}
          </div>
        );
      })}
      {inError && <span className="parsley-errors-list">{meta.error}</span>}
    </div>
  );
};

const getValue = (
  score: number,
): {
  name: string;
  color: BootstrapColour;
} => {
  if (score < 4) {
    return {
      name: "Low",
      color: "success",
    };
  }

  if (score < 7) {
    return {
      name: "Medium",
      color: "info",
    };
  }

  if (score < 15) {
    return {
      name: "High",
      color: "warning",
    };
  }

  return {
    name: "Extreme",
    color: "danger",
  };
};

export default RiskMatrix;
