import axios from "./api";

export const store = (url, values, config) => {
  checkUrl(url);
  return axios.post(url, values, config);
};

export const update = (url, values, config) => {
  checkUrl(url);
  return axios.put(url, values, config);
};

export const index = (url, config) => {
  checkUrl(url);
  return axios.get(url, config);
};

export const show = (url, config) => {
  checkUrl(url);
  return axios.get(url, config);
};

export const destroy = (url, config) => {
  checkUrl(url);
  return axios.delete(url, config);
};

const checkUrl = (url) => {
  if (!url) {
    throw new Error("Unable to find URL");
  }
};
