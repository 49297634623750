import React from "react";
import { Button } from "reactstrap";
import useModal from "../../hooks/useModal";
import { TestSuiteTestProps } from "../testSuiteTypes";
import ApproverModal from "./ApproverModal";

const AddApproveTestSuiteTestButton = (props: TestSuiteTestProps) => {
  const { test } = props;

  const { toggle, modal } = useModal();

  if (!test.completed_at) {
    return null;
  }

  if (test.approval) {
    return null;
  }

  return (
    <>
      <Button onClick={toggle} color="primary" outline>
        Add Approval
      </Button>
      <ApproverModal {...props} toggle={toggle} modal={modal} />
    </>
  );
};

export default AddApproveTestSuiteTestButton;
