import React from "react";
import { useDropzone } from "react-dropzone";
import { Progress } from "reactstrap";
import swal from "sweetalert";
import { ReactComponent as Logo } from "../../svgs/undraw_logo.svg";
import useModal from "../hooks/useModal";
import Spinner from "../utils/Spinner";
import LogoModal from "./LogoModal.jsx";
import useUploadLogo from "./useUploadLogo";

const LogoPage = ({ organisation, setOrganisation }) => {
  const { toggle, modal } = useModal();
  const { addLogo, loading, uploadPercent } = useUploadLogo(
    organisation,
    setOrganisation,
  );

  const onDrop = (files, rejected) => {
    let text = "";
    if (rejected.length === 1) {
      text = "Please select an image.";
    }

    if (rejected.length > 1) {
      text = "You are only allowed one logo at a time.";
    }

    if (text) {
      return swal("Error", text, "warning");
    }

    return addLogo(files[0]);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      accept: "image/*",
      maxFiles: 1,
      multiple: false,
    });

  if (!organisation.logo) {
    return (
      <>
        <div className="bg-white border p-5">
          <div className="d-flex justify-content-center mb-3">
            <Logo
              width="70%"
              height="100%"
              onClick={toggle}
              className="pointer-hover"
            />
          </div>
          {/* <SVGContainer SVG={Logo} /> */}
          <div className="text-center">
            <p className="tx-inverse tx-16 fw-bolder mb-1">No Logo Found</p>
            <p className="mb-0">
              Upload a logo to add into emails that you send
            </p>
            <p className="mb-0">
              Get started by{" "}
              <button
                type="button"
                className="p-0 btn btn-link"
                onClick={toggle}
              >
                Clicking Here
              </button>
            </p>
          </div>
        </div>
        <LogoModal
          toggle={toggle}
          modal={modal}
          title="Update Company Logo"
          organisation={organisation}
          setOrganisation={setOrganisation}
        />
      </>
    );
  }

  return (
    <div
      className={`border duration-300 ${
        isDragActive ? "bg-gray-200" : "bg-white"
      } ${isDragAccept ? "" : "bg-danger"}`}
      style={{
        transitionDuration: "0.3s",
      }}
    >
      <Spinner loading={loading} />
      <div className="p-5">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="d-flex justify-content-center">
            <img
              src={organisation.logo.document.link}
              alt="organisation logo"
              className="w-50"
            />
          </div>
        </div>
        <div className="text-center mt-4">
          <p className="mb-0 tx-inverse">
            Click the logo or drag new logo to edit
          </p>
        </div>
      </div>
      {uploadPercent > 0 && <Progress color="info" value={uploadPercent} />}
    </div>
  );
};

export default LogoPage;
