import _ from "lodash";
import { Timesheet } from "./timesheetTypes";
import { money } from "../form/formatters";

const AllowancesCard = ({ timesheet }: { timesheet: Timesheet }) => {
  if (!timesheet?.allowances || timesheet?.allowances?.length === 0) {
    return null;
  }

  return (
    <div className="my-3 border rounded-lg shadow-sm bg-white p-3">
      <p className="tx-12 mb-2">
        Allowance{timesheet.allowances.length > 1 ? "s" : ""}:
      </p>
      <div className="space-y-2">
        {timesheet.allowances.map((allowance, index) => {
          return (
            <div>
              <p className="mb-0" key={index}>
                <span className="tx-inverse tx-bold">
                  {allowance.amount ?? 0}
                </span>{" "}
                <span className="fst-italic">{allowance.name} </span>
                {allowance.unit}
                {allowance.amount == 1 ? "" : "s"} at{" "}
                <span className="tx-bold tx-inverse">
                  {money.format(allowance.rate.toFixed(2))}
                </span>
                .{" "}
              </p>
              <p className="mb-0">{money.format(allowance.total.toFixed(2))}</p>
            </div>
          );
        })}
      </div>

      <p className="mb-0 mt-3">
        <span className="tx-inverse tx-16 fw-bolder">
          {money.format(_.sumBy(timesheet.allowances, "total").toFixed(2))}
        </span>
      </p>
      <p className="mb-0 tx-10">Total</p>
    </div>
  );
};

export default AllowancesCard;
