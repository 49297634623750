const getFileIcon = (fileName, isFolder) => {
  if (isFolder) return "fa fa-folder-o";

  if (!fileName) {
    return "fa fa-file-o";
  }

  const icons = {
    "fa-file-pdf-o tx-danger": ["pdf"],
    "fa-file-excel-o tx-success": [
      "xlsx",
      "xls",
      "csv",
      "xlsb",
      "xlsm",
      "xlxt",
      "xltx",
      "xltm",
      "xlam",
      "xlw",
      "xla",
    ],
    "fa-file-image-o text-primary": ["jpg", "png", "jpeg"],
    "fa-file-word-o text-info": ["docx", "doc", "docm", "dotx", "dotm", "docb"],
  };

  const indexOfLastDot = fileName.lastIndexOf(".");

  if (indexOfLastDot === -1) {
    return "fa fa-folder-o";
  }

  let displayIcon = "";

  const documentType = fileName.substr(indexOfLastDot + 1);

  for (const icon in icons) {
    if (icons[icon].indexOf(documentType) !== -1) {
      displayIcon = icon;
      break;
    }
  }
  return `fa ${displayIcon || "fa-file-o"}`;
};

export default getFileIcon;
