import dayjs from "dayjs";
import React from "react";
import { ReactComponent as Empty } from "../../svgs/empty.svg";
import PreviewModal from "../documents/PreviewModal";
import useModal from "../hooks/useModal";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import ApprovalButton from "../approvals/ApprovalButton";
import ApprovalStatus from "../user/ApprovalStatus";
import SVGContainer from "../utils/SVGContainer";

const Insurances = ({ supplier }) => {
  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(supplier.insurances.map(({ document }) => document));
  const { toggle, modal } = useModal();

  if (supplier.insurances.length === 0) {
    return (
      <div>
        <SVGContainer SVG={Empty} className="mg-t-20" height="300px" />
        <div className="mt-3">
          <h5 className="tx-inverse text-center">
            No insurances found for {supplier.display_name}
          </h5>
          <div className="mt-2">
            <p className="mb-0 text-center">
              An email has been sent requesting insurances to be uploaded.
            </p>
            <p className="mb-0 text-center">
              {supplier.user.name} will be notified when {supplier.display_name}{" "}
              uploads insurances.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      {supplier.insurances.map((insurance) => {
        return (
          <div key={insurance.uuid} className="col-12 mb-2">
            <div className="bg-white border">
              <div className="p-2 ms-2">
                <div className="d-flex align-items-center mb-3 tx-primary">
                  <button
                    type="button"
                    className="btn btn-link p-0 fw-bolder tx-16 mb-0"
                    onClick={() => {
                      setSelectedDocument(insurance.document);
                      toggle();
                    }}
                  >
                    {insurance.type.name}
                  </button>
                </div>
                <p className="mb-0">
                  <span className="tx-secondary">Expires</span>{" "}
                  <span className="tx-inverse">
                    {dayjs(insurance.expiry_date).format("DD/MM/YYYY")}
                  </span>
                </p>
                {insurance?.approval_status && (
                  <ApprovalStatus
                    status={insurance?.approval_status}
                    className="w-auto py-2"
                  />
                )}
                <div className="mg-t-10 form-group">
                  <ApprovalButton
                    approvalAction={insurance.approval_action}
                    form={`ApprovalModal-${insurance.uuid}`}
                    onSubmitted={() => window.location.reload()}
                    wrapperClass="mg-auto"
                    buttonClass="btn btn-outline-info"
                    approvalOptions={[
                      {
                        label: "Approve",
                        value: 1,
                      },
                      {
                        label: "Redo",
                        value: 2,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <PreviewModal
        document={selectedDocument}
        modal={modal}
        toggle={toggle}
        changeDocument={changeDocument}
      />
    </div>
  );
};

export default Insurances;
