import dayjs from "dayjs";
import React from "react";
import { DownloadLog } from "./downloadLogTypes";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../table/ReactTable";

const DownloadLogTable = ({ data }: { data: any }) => {
  if (!data || data?.length === 0) return null;

  const columnHelper = createColumnHelper<DownloadLog>();
  const DownloadLogs = data as DownloadLog[];

  const columns = React.useMemo<ColumnDef<DownloadLog, any>[]>(
    () => [
      columnHelper.accessor("user.full_name", {
        header: "Staff Name",
        cell: ({ row }) => {
          const downloadLog = row.original;
          return (
            <a href={`mailto:${downloadLog.user.email}`}>
              {downloadLog.user.full_name}
            </a>
          );
        },
      }),
      columnHelper.accessor("download_date", {
        header: "Downloaded Date",
        cell: ({ getValue }) => dayjs(getValue()).format("DD/MM/YYYY"),
      }),
    ],
    [],
  );

  return <ReactTable disableSearch columns={columns} data={DownloadLogs} />;
};

export default DownloadLogTable;
