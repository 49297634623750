import _ from "lodash";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import {
  AccessControlListItem,
  AccessControlListOption,
} from "./accessControlListItemTypes";

interface Subject {
  id: number;
  acl_items: AccessControlListItem[];
  uuid: string;
}

interface AccessControlListProps<T extends Subject = Subject> {
  subject: T;
  setSubject: (subject: T) => void;
  subjectType: string;
}

const AccessControlList = <T extends Subject = Subject>({
  subject,
  setSubject,
  subjectType,
}: AccessControlListProps<T>) => {
  const { takeAction, loading }: IUseApi = useApi();

  const { data, loading: fetchingOptions } = useApi("acl-options", [], true);

  const aclOptions = data as AccessControlListOption[];

  const updateAcl = (
    model: string,
    method: string,
    checked: boolean,
    uuid?: string,
  ) => {
    return takeAction(
      checked ? "destroy" : "store",
      checked
        ? `access-control-list-items/${uuid}`
        : "access-control-list-items",
      {
        subject_type: subjectType,
        subject_id: subject.id,
        model,
        method,
      },
    ).then(({ data }) => {
      setSubject({
        ...subject,
        acl_items: checked
          ? subject.acl_items.filter((item) => item.uuid !== uuid)
          : [...subject.acl_items, data.data],
      });
    });
  };

  if (fetchingOptions) {
    return <CustomScaleLoader>Fetching Options...</CustomScaleLoader>;
  }

  return (
    <div className="space-y-5">
      {_.sortBy(aclOptions, (i) => i.label.toLowerCase()).map((item) => {
        return (
          <div>
            <p className="text-dark fw-bolder mb-3">{item.label}</p>
            <div className="d-flex space-x-5">
              {item.methods.map((method) => {
                const currentItem = subject.acl_items.find(
                  (subjectItem) =>
                    subjectItem.model === item.model &&
                    subjectItem.method === method,
                );

                return (
                  <div className="d-flex flex-column">
                    <label className="ckbox" htmlFor={`${item.model}${method}`}>
                      <input
                        type="checkbox"
                        id={`${item.model}${method}`}
                        name={item.label + method}
                        checked={!!currentItem}
                        disabled={loading}
                        onChange={() =>
                          updateAcl(
                            item.model,
                            method,
                            !!currentItem,
                            currentItem?.uuid,
                          )
                        }
                      />
                      <span>{method}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AccessControlList;
