import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import SVGContainer from "../utils/SVGContainer";
import { ReactComponent as NoData } from "../../svgs/no_data.svg";

const BranchEquipment = ({ branch }: { branch: any }) => {
  const { uuid } = useParams<{ uuid: string }>();

  const [previous, setPrevious] = useState(false);

  const { data, setUrl, loading } = useApi(
    `/organisation-branches/${uuid}/equipment`,
    [],
    true,
  );

  useEffect(() => {
    if (previous) {
      setUrl(`/organisation-branches/${uuid}/equipment?previous=true`);

      return;
    }

    setUrl(`/organisation-branches/${uuid}/equipment`);
  }, [previous]);

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex">
          <label className="section-title mt-3">
            {" "}
            {previous ? "Previous" : "Current"} Equipment
          </label>
          <Button
            color="link"
            className="ms-auto p-0"
            onClick={() => setPrevious(!previous)}
          >
            Toggle current/previous
          </Button>
        </div>
        <div className="col-12">
          <hr />
        </div>
      </div>
      {loading ? (
        <CustomScaleLoader>
          Fetching {previous ? "Previous" : "Current"} Equipment...
        </CustomScaleLoader>
      ) : (
        <div className="row">
          {data?.length > 0 ? (
            data?.map((equipment: any, index: number) => {
              return (
                <div key={index} className="col-lg-6">
                  <div className="p-3 bg-white border rounded mb-2">
                    <Link to={equipment.link}>{equipment.name}</Link>
                    <Activities
                      activities={equipment.activities}
                      branch={branch}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="ps-2 pe-2">
              <div className=" bg-white">
                <SVGContainer SVG={NoData} width="25%" top={false}>
                  <p className="mt-5 mb-0 tx-inverse tx-medium">
                    No data found
                  </p>
                </SVGContainer>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Activities = ({
  activities,
  branch,
}: {
  activities: any[];
  branch: { id: number };
}) => {
  let partOfBranch = false;

  return (
    <>
      {activities.map((activity) => {
        if (
          !partOfBranch &&
          activity?.properties?.attributes?.branch_id == branch.id
        ) {
          partOfBranch = true;
          return (
            <p key={activity.id} className="mb-0 mt-3">
              Added to branch at {dayjs(activity.created_at).format("HH:mm")} on{" "}
              {dayjs(activity.created_at).format("DD/MM/YYYY")}
            </p>
          );
        }
        if (partOfBranch && activity.properties?.old?.branch_id == branch.id) {
          partOfBranch = false;
          return (
            <p key={activity.id} className="mb-0">
              Removed from branch at{" "}
              {dayjs(activity.created_at).format("HH:mm")} on{" "}
              {dayjs(activity.created_at).format("DD/MM/YYYY")}
            </p>
          );
        }
      })}
    </>
  );
};

export default BranchEquipment;
