import React from "react";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import ShowEmploymentAwardPage from "./ShowEmploymentAwardPage";
import { EmploymentAward } from "./types";

const EmploymentAwardProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, setData, loading }: IUseApi<EmploymentAward, EmploymentAward> =
    useApi(`employment-awards/${uuid}`, null, true);

  const employmentAward = data as EmploymentAward;

  const tabs: ProfileTab<"details" | "documents">[] = [
    {
      label: "Details",
      link: `/employment-awards/${uuid}/details`,
      page: "details",
    },
    {
      label: "Documents",
      link: `/employment-awards/${uuid}/documents`,
      page: "documents",
    },
  ];

  if (loading && !data) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Organisation",
            link: "/organisations/settings/links",
          },
          {
            name: "Employment Awards",
            link: "/employment-awards",
          },
          {
            name: data?.abbreviation ?? "",
            link: ``,
            active: true,
          },
        ]}
        titlePage={data?.abbreviation ?? ""}
      />
      <Profile
        content={
          <ShowEmploymentAwardPage
            employmentAward={employmentAward}
            setEmploymentAward={setData}
          />
        }
        tabs={tabs}
        header={
          <DashboardHeader>
            <DashboardH3>{data?.abbreviation}</DashboardH3>
          </DashboardHeader>
        }
      />
    </>
  );
};

export default EmploymentAwardProfile;
