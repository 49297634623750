import { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import useModal from "../hooks/useModal";
import StandardDocumentModal from "../standardDocuments/standardDocumentModal";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import ProfileButtons from "../utils/ProfileButtons";
import Spinner from "../utils/Spinner";
import DocumentTable from "./DocumentTable";
import VariableList from "./VariableList";

const Profile = () => {
  const { uuid } = useParams();
  const [template, setTemplate] = useState({});
  const { toggle, modal } = useModal();
  const { user } = useAuth();

  const { data, loading } = useApi(`templates/${uuid}`, {}, true);

  useEffect(() => {
    setTemplate(data.template);
  }, [data]);

  if (loading) return <Spinner loading />;

  const deleteTemplateDocument = (document) => {
    setTemplate({
      ...template,
      documents: template.documents.filter((d) => d.uuid !== document.uuid),
    });
  };

  if (!template) {
    return;
  }

  return (
    <>
      <HeaderPageAdminFilter
        titlePage={`${template.name} Template`}
        crumbs={[
          { link: "/templates", name: "Templates" },
          { link: "/", name: template.name, active: true },
        ]}
      />
      <div className="row">
        <div className="col-lg-8">
          <DashboardHeader>
            <DashboardH3>{template.name}</DashboardH3>
          </DashboardHeader>
          <ProfileButtons
            buttons={[
              {
                text: (
                  <>
                    <FiUpload className="tx-indigo tx-18 me-2" />
                    Upload New File
                  </>
                ),
                onClick: toggle,
              },
            ]}
          />
          <DocumentTable
            user={user}
            template={template}
            deleteTemplateDocument={deleteTemplateDocument}
          />
        </div>
        <div className="col-lg-4">
          <VariableList variables={template.variables} />
        </div>
      </div>
      <StandardDocumentModal
        modal={modal}
        toggle={toggle}
        documentable_type="App\Models\Template"
        bucket={`organisations/${user.active_organisation.uuid}/templates/${template.uuid}`}
        documentable_id={template.uuid}
        whenSubmitted={({ data }) => {
          toggle();
          setTemplate({
            ...template,
            documents: [...template.documents, data.data],
          });
        }}
      />
    </>
  );
};

export default Profile;
