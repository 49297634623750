import React from "react";
import TextButton from "../utils/TextButton";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import errorSwal from "../utils/errorSwal";

const SyncEstimatedEntity = ({
  supplier,
  refreshData,
}: {
  supplier: {
    estimated_supplier_entity?: {
      name: string;
      id: number;
    };
  };
  refreshData: () => void;
}) => {
  const { takeAction, loading } = useApi();
  const { uuid } = useParams<{ uuid: string }>();

  if (!supplier.estimated_supplier_entity?.id) {
    return null;
  }

  return (
    <div className="p-3 bg-white border rounded-lg">
      <p className="text-dark mb-0 tx-16 mb-2">Found Matching Organisation.</p>
      <p className="mb-0">
        This suppliers ABN matches with an organisation in boss.
      </p>
      {loading ? (
        <p className="mb-0">
          Syncing supplier with {supplier.estimated_supplier_entity.name}...
        </p>
      ) : (
        <p className="mb-0">
          <TextButton
            disabled={loading}
            onClick={() => {
              return takeAction(
                "store",
                `suppliers/${uuid}/sync-estimated-entity`,
                {
                  entity_id: supplier?.estimated_supplier_entity?.id,
                },
              )
                .then(refreshData)
                .catch(errorSwal);
            }}
          >
            Click here
          </TextButton>{" "}
          if you would to sync supplier with{" "}
          {supplier.estimated_supplier_entity.name}.
        </p>
      )}
    </div>
  );
};

export default SyncEstimatedEntity;
