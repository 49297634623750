import { IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import EmploymentHeroUserCard from "./EmploymentHeroUserCard";

export interface BossUser {
  name: string;
  uuid: string;
  email: string;
  external_integration_id?: number;
  secondary_email?: string;
}

export interface EmploymentHeroUser {
  name: string;
  email: string;
  id: number;
}

export interface SyncUsers {
  boss_users: BossUser[];
  employment_hero_users: EmploymentHeroUser[];
}

const SyncUsers = () => {
  const {
    data,
    loading,
    setData: setUsers,
    refreshData,
  }: IUseApiWithData<SyncUsers> = useApi(
    "integrations/employment-hero/users/sync",
    [],
    true,
  );

  if (loading) {
    return <CustomScaleLoader>Fetching users to sync...</CustomScaleLoader>;
  }

  return (
    <div className="row">
      {data.boss_users.map((user) => {
        return (
          <EmploymentHeroUserCard
            users={data}
            refreshData={refreshData}
            setUsers={setUsers}
            user={user}
            key={user.uuid}
          />
        );
      })}
    </div>
  );
};

export default SyncUsers;
