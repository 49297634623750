import useApi from "../api/useApi";
import { Link } from "react-router-dom";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ReactTable from "../table/ReactTable";
import { useAuth } from "../../context/auth-context";
import { ProjectJob, Template } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";
import { Badge } from "reactstrap";

const CustomJobs = () => {
  const { data, loading } = useApi("custom-jobs", []);

  const { user } = useAuth();

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => {
        const customJob = info.row.original;
        return (
          <>
            <Link
              to={`custom-jobs/${customJob.uuid}/details`}
              className="d-flex align-items-center"
            >
              {info.getValue()}{" "}
              {customJob.archived_at ? (
                <Badge className="rounded-pill shadow block ms-3" color="info">
                  Archived
                </Badge>
              ) : (
                ""
              )}
            </Link>

            <small>{customJob.organisation.name}</small>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/custom-jobs/" />
      <HeaderPageAdminFilter
        titlePage="Custom Jobs"
        pageDescription={
          <>
            The Custom Job Types feature allows organizations to create job
            types with specific fields to capture information and manage them
            effectively.
          </>
        }
        relatedLinks={[
          { name: "Jobs", link: "/jobs", model: ProjectJob },
          { name: "Templates", link: "/templates", model: Template },
        ]}
        crumbs={[{ name: "Custom Jobs", link: "/custom-jobs", active: true }]}
      />
      {user.hasAccessTo("App\\Models\\CustomJob", "create") && (
        <Link to="/custom-jobs/add" className="mb-3 btn btn-outline-primary">
          Add New
        </Link>
      )}
      <ReactTable columns={columns} data={data} loading={loading} />
    </>
  );
};

export default CustomJobs;
