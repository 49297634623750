import Avatar from "../utils/Avatar";
import { ContactStateProps, ISupplierContact } from "./SupplierContacts";
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import ContactModal from "./ContactModal";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
// import ClientContactProjectModal from "./ClientContactProjectModal";
import deleteSwal from "../utils/deleteSwal";
import formError from "../utils/formError";

const SupplierContactCard = (props: ContactStateProps) => {
  const { contact } = props;

  return (
    <div className="col-lg-6 mb-3">
      <div className="bg-white rounded-lg shadow-sm border ">
        <div className="p-3">
          <div className="d-flex align-items-center">
            <Avatar rounded="-circle" colour="info" name={contact.name} />
            <div>
              <p className="tx-inverse mb-0">{contact.name}</p>
              <p className="mb-0">{contact.description}</p>
            </div>
            <div className="ms-auto align-self-start">
              <ContactCardDropdown {...props} />
            </div>
          </div>
          <div className="mt-4">
            {contact.email ? (
              <CopyToClipboard
                text={contact.email}
                options={{ format: "text/plain" }}
                onCopy={() => toast.success("Email copied to clipboard")}
              >
                <p className="mb-0 pointer-hover">
                  <HiOutlineMail className="me-1" /> {contact.email}
                </p>
              </CopyToClipboard>
            ) : null}
            {contact.phone ? (
              <CopyToClipboard
                text={contact.phone}
                options={{ format: "text/plain" }}
                onCopy={() => toast.success("Number copied to clipboard")}
              >
                <p className="mb-0 pointer-hover">
                  <HiOutlinePhone className="me-1" /> {contact.phone}
                </p>
              </CopyToClipboard>
            ) : null}
          </div>
        </div>
        <div className="p-3 mt-2 border-top d-flex justify-content-end space-x-3">
          {contact.email ? (
            <a
              className="btn text-white btn-info btn-sm"
              href={`mailto:${contact.email}`}
            >
              Email
            </a>
          ) : null}
          {contact.phone ? (
            <a className="btn btn-sm border" href={`tel:${contact.phone}`}>
              Call
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const ContactCardDropdown = (props: ContactStateProps) => {
  const { contact, contacts, setContacts } = props;

  const { takeAction }: IUseApi<any, { data: ISupplierContact }> = useApi();
  const { toggle: toggleEdit, modal: editModal } = useModal();

  const deleteContact = () => {
    deleteSwal(contact.name)
      .then(() => takeAction("destroy", `suppliers-contacts/${contact.uuid}`))
      .then(() => {
        toast.success("Contact Deleted");
        setContacts(contacts.filter((c) => c.uuid !== contact.uuid));
      })
      .catch(errorSwal);
  };

  const editContact: FormSubmitHandler<ISupplierContact> = (values) => {
    return takeAction("update", `suppliers-contacts/${values.uuid}`, values)
      .then(({ data }) => {
        setContacts(
          contacts.map((c) => (c.uuid !== data.data.uuid ? c : data.data)),
        );
        toggleEdit();
        toast.success("Contact Updated");
      })
      .catch(formError);
  };

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle color="link" className="p-1">
          <FiMoreHorizontal className="text-secondary" />
        </DropdownToggle>
        <DropdownMenu className="z-20">
          <DropdownItem
            onClick={toggleEdit}
            className="d-flex align-items-center px-2 text-secondary"
          >
            <AiOutlineEdit className="me-3 tx-16" /> Edit
          </DropdownItem>
          <DropdownItem
            onClick={deleteContact}
            className="d-flex align-items-center px-2 text-secondary"
          >
            <AiOutlineDelete className="me-3 tx-16" /> Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <ContactModal
        onSubmit={editContact}
        title={`Edit ${contact?.name}`}
        form="EditClientContact"
        toggle={toggleEdit}
        modal={editModal}
        initialValues={contact}
      />
    </>
  );
};

export default SupplierContactCard;
