import { FETCH_SAMPLE_TESTS, NEW_SAMPLE_TEST, UPDATE_SAMPLE_TEST, DELETE_SAMPLE_TEST, FETCH_SAMPLE_TESTS_METHODS } from '../actions/types'

const initialState = {
    items: [],
    methods: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_SAMPLE_TESTS:
            return {
                ...state,
                items: action.payload
            }
        case NEW_SAMPLE_TEST:
            return {
                ...state,
                items: action.payload
            }
        case UPDATE_SAMPLE_TEST:
            return {
                ...state,
                items: action.payload
            }
        case DELETE_SAMPLE_TEST:
            return {
                ...state,
                items: action.payload
            }
        case FETCH_SAMPLE_TESTS_METHODS:
            return {
                ...state,
                methods: action.payload
            }
        default:
        return state;
    }
}