import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ReactTable from "../table/ReactTable";
import { Badge } from "reactstrap";

const CpirTable = ({ loading, cpirs }) => {
  if (!cpirs || cpirs?.length === 0) return null;

  const columns = [
    {
      accessorKey: "title",
      header: "Title",
      cell: (info) => (
        <Link className="text-secondary" to={info.row.original.link}>
          {info.getValue()}
        </Link>
      ),
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => {
        return (
          <Link className="text-secondary" to={info.row.original.link}>
            {info.getValue()}
          </Link>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Type",
    },
    {
      accessorKey: "target_date_for_closure",
      header: "Target Date For Closure",
      cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "raised_by",
      header: "Raised By",
    },
    {
      accessorKey: "raised_to",
      header: "Raised To",
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => (
        <Badge
          className="rounded-pill shadow"
          color={statusBadgeColor(info.row.original)}
        >
          {info.getValue()}
        </Badge>
      ),
    },
  ];

  return (
    <ReactTable
      disableSearch
      columns={columns}
      data={cpirs}
      loading={loading}
    />
  );
};

const statusBadgeColor = (cpir) => {
  if (cpir.status === "Waiting for Approval") {
    return "info";
  }

  if (cpir.status === "Closed") {
    return "secondary";
  }

  return "warning";
};

export default CpirTable;
