const LIKELIHOOD_OF_SUCCESS = [
  {
    value: "1",
    label: "Low <35%",
  },
  {
    value: "2",
    label: "Medium 35-75%",
  },
  {
    value: "3",
    label: "High >75%",
  },
];

export default LIKELIHOOD_OF_SUCCESS;
