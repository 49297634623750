import { Link } from "react-router-dom";

const GenerateDocket = ({ initialValues }) => {
  const job = [
    {
      label: initialValues?.full_name,
      value: initialValues?.id,
    },
  ];

  return (
    <Link
      className="btn btn-outline-primary w-100 no-wrap"
      to={`/dockets/add?job=${encodeURIComponent(
        JSON.stringify(job),
      )}&project=${initialValues?.project?.id}&jobUuid=${
        initialValues.uuid
      }&projectUuid=${initialValues.project?.uuid}`}
    >
      Generate Docket from Job
    </Link>
  );
};
export default GenerateDocket;
