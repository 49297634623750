import React, { useEffect, useState } from "react";
import useApi from "../api/useApi";

import useModal from "../hooks/useModal";
import FormModal from "./FormModal";
import HeaderPage from "../header/HeaderPage";
import List from "./List";
import errorSwal from "../utils/errorSwal";

const Index = () => {
  const { takeAction, loading } = useApi();
  const [laboratories, setLaboratories] = useState([]);

  const { modal, toggle } = useModal();

  useEffect(() => {
    takeAction("index", "laboratories").then(({ data }) =>
      setLaboratories(data.data)
    );
  }, []);

  const onSubmit = values => {
    return takeAction("store", "laboratories", values)
      .then(({ data }) => {
        setLaboratories([...laboratories, data.data]);
        toggle();
      })
      .catch(err => errorSwal(err));
  };

  return (
    <>
      <HeaderPage titlePage="Laboratories" crumbs={[]} />
      <button
        type="button"
        className="btn btn-outline-primary mb-3"
        onClick={toggle}
      >
        Add new Lab
      </button>
      <FormModal onSubmit={onSubmit} modal={modal} toggle={toggle} />
      <List loading={loading} laboratories={laboratories} />
    </>
  );
};

export default Index;
