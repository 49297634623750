import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import Spinner from "../utils/Spinner";
import SampleForm from "./SampleForm";

const Samples = () => {
  const { uuid } = useParams();

  const { data: samples, loading } = useApi(
    `test-requests/${uuid}/samples`,
    [],
    true,
  );

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <SampleForm
      initialValues={{
        samples,
      }}
      form="SAMPLE_FORM"
    />
  );
};

export default Samples;
