import React from "react";
import { useParams } from "react-router-dom";
import { Field, FormSubmitHandler, InjectedFormProps } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import InformationAlert from "../utils/InformationAlert";
import RenderField from "../utils/renderField";
import { TestSuiteStatus } from "./testSuiteTypes";
import { toast } from "react-toastify";
import FormErrorAlert from "../form/FormErrorAlert";

interface AddTestSuiteStatusModalProps {
  statuses: TestSuiteStatus[];
  setStatuses: (statuses: TestSuiteStatus[]) => void;
  toggle: Function;
  modal: boolean;
}

const AddTestSuiteStatusModal = (props: AddTestSuiteStatusModalProps) => {
  const { statuses, setStatuses, toggle, modal } = props;
  const { takeAction }: IUseApi<{}, { data: TestSuiteStatus }> = useApi();

  const { uuid } = useParams<{ uuid: string }>();

  const onSubmit: FormSubmitHandler<TestSuiteStatus> = (values, _, props) => {
    return takeAction("store", `test-suites/${uuid}/statuses`, values)
      .then(({ data }) => {
        setStatuses([...statuses, data.data]);
        toast.success("Status added successfully");
        toggle();
        props?.reset?.();
      })
      .catch(formError);
  };

  return (
    <FormModal
      modal={modal}
      toggle={toggle}
      onSubmit={onSubmit}
      form="AddTestSuiteStatus"
      title="Add New Test Suite Status"
    >
      <div className="col-12 form-group">
        <Field component={RenderField} name="name" label="Name" />
      </div>
    </FormModal>
  );
};

export default AddTestSuiteStatusModal;
