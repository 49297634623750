import { InjectedFormProps, reduxForm } from "redux-form";
import CustomForm from "../../customFields/CustomForm";
import FormHeader from "../../utils/FormHeader";
import { TestSuiteTest } from "../testSuiteTypes";

interface StatusFormProps {
  custom_fields: any[];
  custom_form: any[];
  name: string;
  test: TestSuiteTest;
}

const StatusForm = (
  props: StatusFormProps & InjectedFormProps<{}, StatusFormProps>,
) => {
  return (
    <form>
      <div className="row">
        <FormHeader>{props.name}</FormHeader>
        <CustomForm
          saveOnBlur={true}
          customForm={props.custom_form}
          branchId={props.test.test_request.job.branch_id}
          {...props}
        />
      </div>
    </form>
  );
};

const form = reduxForm<{}, StatusFormProps>({});

export default form(StatusForm);
