import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import HeaderPage from "../../header/HeaderPage";
import PaginatedCard from "../../pagination/PaginatedCard";
import PaginatedList from "../../pagination/PaginatedList";
import Avatar from "../../utils/Avatar";
import useJobTypes from "./hooks/useJobTypes";
import { JobType } from "./jobTypeTypes";
import { ProjectJob, CustomJob } from "../../../enums/Model";

const JobTypes = () => {
  return (
    <>
      <HeaderPage
        titlePage="Job Types"
        pageDescription={
          <>
            Select from standard, organization, or custom job types when
            creating a new job, each with varying workflows and information
            requirements.
          </>
        }
        relatedLinks={[
          { name: "Jobs", link: "/jobs", model: ProjectJob },
          { name: "Custom Jobs", link: "/custom-jobs", model: CustomJob },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "jobtypes", name: "Job Types", active: true },
        ]}
      />
      <PaginatedList
        indexHook={useJobTypes}
        originalFilters={[]}
        addLink="/jobtypes/add"
        itemCard={({ item }: { item: JobType }) => {
          return (
            <PaginatedCard
              header={<JobTypeHeader jobType={item} />}
              info={[]}
            />
          );
        }}
      />
    </>
  );
};

const JobTypeHeader = ({ jobType }: { jobType: JobType }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={jobType.name} colour={"info"} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={`job_type${jobType.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={`jobtypes/${jobType.uuid}`}>{jobType.name}</Link>
          </p>
          <UncontrolledTooltip
            placement="top-start"
            target={`job_type${jobType.uuid}`}
          >
            {jobType.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default JobTypes;
