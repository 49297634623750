import { toast } from "react-toastify";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import formError from "../../utils/formError";
import SelectInputAsync from "../../utils/SelectInputAsync";
import SubmitButton from "../../utils/SubmitButton";
import { CustomJobJob } from "./customJobTypes";
import { UnderstandingsProps } from "./Understandings";

interface IUsersRequiredToSignIn {
  user_ids: { value: number }[];
}

const UsersRequiredToSignIn = (
  props: UnderstandingsProps &
    InjectedFormProps<IUsersRequiredToSignIn, UnderstandingsProps>,
) => {
  const { job, setJob, handleSubmit } = props;

  const { takeAction }: IUseApi<{}, { data: CustomJobJob }> = useApi();

  const onSubmit: FormSubmitHandler<
    IUsersRequiredToSignIn,
    UnderstandingsProps
  > = (values) => {
    const userIds = values.user_ids.map((user) => user.value);

    return takeAction("update", `jobs/${job.uuid}/users-required-to-sign-in`, {
      user_ids: userIds,
    })
      .then(({ data }) => {
        setJob(data.data);
        toast.success("Users required to sign in updated");
      })
      .catch(formError);
  };

  if (!job.sign_in_required) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-9 form-group">
          <Field
            component={SelectInputAsync}
            url="/users/staff"
            name="user_ids"
            multiple
            label="Users Required to Sign In"
          />
        </div>
        <div className="col-3 form-group d-flex align-items-end">
          <SubmitButton className="w-100" {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<IUsersRequiredToSignIn, UnderstandingsProps>({
  form: "UsersRequiredToSignIn",
});

export default form(UsersRequiredToSignIn);
