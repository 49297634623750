import {
  FETCH_CALIBRATION_UNITS,
  NEW_CALIBRATION_UNIT,
  UPDATE_CALIBRATION_UNIT,
  DELETE_CALIBRATION_UNIT,
  SELECT_CALIBRATION_UNIT
} from "../actions/types";

const initialState = {
  items: [],
  selected: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CALIBRATION_UNITS:
      return {
        ...state,
        items: action.payload
      };
    case NEW_CALIBRATION_UNIT:
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    case UPDATE_CALIBRATION_UNIT:
      return {
        ...state,
        items: state.items.map(item =>
          item.uuid !== action.payload.uuid ? item : action.payload
        ),
        selected: {}
      };
    case DELETE_CALIBRATION_UNIT:
      return {
        ...state,
        items: state.items.filter(item => item.uuid !== action.payload),
        selected: {}
      };
    case SELECT_CALIBRATION_UNIT:
      const selectedItem =
        state.selected.uuid === action.payload.uuid ? {} : action.payload;
      return {
        ...state,
        selected: { ...selectedItem }
      };

    default:
      return state;
  }
}
