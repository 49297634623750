import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { StoodDownEquipment } from "./equipmentTypes";

interface StoodDownFormProps {
  dates: StoodDownEquipment[];
  setDates: (dates: StoodDownEquipment[]) => void;
}

const StoodDownForm = (
  props: InjectedFormProps<StoodDownEquipment, StoodDownFormProps> &
    StoodDownFormProps,
) => {
  const { dates, setDates, handleSubmit } = props;

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi<{}, { data: StoodDownEquipment }> = useApi();

  const onSubmit: FormSubmitHandler<StoodDownEquipment, StoodDownFormProps> = (
    values,
    _,
    props,
  ) => {
    return takeAction("store", `equipment/${uuid}/stand-downs`, values)
      .then(({ data }) => {
        setDates([...dates, data.data]);
        toast.success("Stood Down Day Added");
        props?.reset?.();
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <FormHeader>Add Date Stood Down</FormHeader>
        <div className="col-lg-3">
          <Field
            component={RenderField}
            type="date"
            name="date"
            validate={required}
            required
            label="Date Stood Down"
          />
        </div>
        <div className="col-lg-7">
          <Field component={RenderField} name="reason" label="Reason" />
        </div>
        <div className="col-lg-2 d-flex align-items-end">
          <SubmitButton {...props} className="w-100" />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<StoodDownEquipment, StoodDownFormProps>({});

export default form(StoodDownForm);
