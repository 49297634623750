import dayjs from "dayjs";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { connect } from "react-redux";
import { stopSubmit, touch } from "redux-form";
import useApi from "../api/useApi";

const MarkAsSubmittedButton = ({ tender, setTender, stopSubmit, touch }) => {
  const { takeAction, loading: submitting } = useApi();

  const updateSubmitted = () => {
    return takeAction("update", `/submitted-tenders/${tender.uuid}`, {
      submitted_on: tender.submitted_on ? null : dayjs().format("YYYY-MM-DD"),
    })
      .then(({ data }) => {
        toast.success(
          `Tender ${data.data.submitted_on ? "" : "un"}marked as submitted`,
        );

        setTender(data.data);
      })
      .catch((err) => {
        if (tender.submitted_on) {
          return errorSwal(err);
        }

        stopSubmit("EditTender", {
          _error: err.response.data.message,
          ...err.response.data?.errors,
        });

        touch("EditTender", ...Object.keys(err.response.data.errors));
      });
  };

  if (tender.successful !== null) return null;

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      <li className="nav-item">
        <button
          type="button"
          onClick={updateSubmitted}
          className="pointer-hover w-100 nav-link"
        >
          {isSubmitting(
            submitting,
            <>
              {!tender.submitted_on && (
                <i className="icon ion-checkmark tx-success" />
              )}
              {tender.submitted_on ? "Unm" : "M"}ark Tender as Submitted
            </>,
          )}
        </button>
      </li>
    </ul>
  );
};

export default connect(null, {
  stopSubmit,
  touch,
})(MarkAsSubmittedButton);
