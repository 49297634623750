import React, { Fragment } from "react";
import { Field } from "redux-form";
import renderField from "../utils/renderField";
import required from "../utils/required";

const EmailTo = ({ fields, meta: { error, submitFailed } }) => {
  return (
    <>
      {error && submitFailed && (
        <div className="col-12">
          <span className="tx-danger">{error}</span>
        </div>
      )}
      <div className="col-12">
        <label className="tx-inverse mt-3 mb-0">
          Add Extra People to Email Docket to.
        </label>
        <hr />
      </div>
      {fields.map((clientUsers, index) => (
        <Fragment key={index}>
          <div className="form-group col-lg-4">
            <Field
              name={`${clientUsers}.label`}
              component={renderField}
              label="Name"
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name={`${clientUsers}.value`}
              component={renderField}
              label="Email"
              type="email"
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4 d-flex align-items-end">
            <button
              type="button"
              onClick={() => fields.remove(index)}
              className="btn btn-outline-danger w-100"
            >
              Remove
            </button>
          </div>
        </Fragment>
      ))}
      <div className="col-lg-4 ms-auto">
        <button
          type="button"
          className="btn btn-outline-secondary w-100"
          onClick={() => fields.push()}
        >
          Add Email
        </button>
      </div>
    </>
  );
};
export default EmailTo;
