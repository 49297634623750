import axios from "../api/api";
import {
  DELETE_INVITE,
  FETCH_INVITE,
  FETCH_INVITES,
  FETCH_USERS,
  NEW_INVITE,
  RESET_PASSWORD,
  UPDATE_USER,
  VERIFY_TOKEN,
} from "./types";

export const fetchUsers =
  (id = "") =>
  (dispatch) => {
    return axios
      .get(`/users/${id}`)
      .then((response) => response.data.data)
      .then((users) =>
        dispatch({
          type: FETCH_USERS,
          payload: users,
        }),
      );
  };

export const updateUser = (postData) => (dispatch) => {
  return axios.put(`/users/${postData.uuid}`, postData).then((user) =>
    dispatch({
      type: UPDATE_USER,
      payload: user,
    }),
  );
};

export const fetchInvitations = () => (dispatch) => {
  return axios
    .get(`/invite/user`)
    .then((response) => response.data.data)
    .then((invitations) =>
      dispatch({
        type: FETCH_INVITES,
        payload: invitations,
      }),
    );
};
export const fetchInvitation = (uuid) => (dispatch) => {
  return axios
    .get(`/util/getInvite?uuid=${uuid}`)
    .then(({ data }) => data.data)
    .then((invite) =>
      dispatch({
        type: FETCH_INVITE,
        payload: invite,
      }),
    );
};

export const createInvitation = (values) => (dispatch) => {
  return axios
    .post(`/invite/user`, values)
    .then(({ data }) => data.data)
    .then((user) =>
      dispatch({
        type: NEW_INVITE,
        payload: user,
      }),
    );
};

export const deleteInvitation = (uuid) => (dispatch) => {
  return axios.delete(`/invite/user/${uuid}`).then(() =>
    dispatch({
      type: DELETE_INVITE,
      payload: uuid,
    }),
  );
};

export const verifyToken = (postData, callback) => (dispatch) => {
  axios
    .post(`/auth/verify`, { token: postData.token })
    .then((reset) =>
      dispatch({
        type: VERIFY_TOKEN,
        payload: reset,
      }),
    )
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      alert(error);
    });
};

export const resetPassword = (postData, callback) => (dispatch) => {
  axios
    .post(`/auth/reset`, postData)
    .then((reset) =>
      dispatch({
        type: RESET_PASSWORD,
        payload: reset,
      }),
    )
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      alert(error);
    });
};
