import React from "react";
import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { money } from "../form/formatters";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { SelectOption } from "../utils/utilTypes";

interface EquipmentRatesProps {
  equipment: SelectOption[];
}

const EquipmentRates = (
  props: WrappedFieldArrayProps & EquipmentRatesProps,
) => {
  const { fields, equipment } = props;

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field}>
          <FormHeader
            labelClass="mb-0"
            className="col-12 d-flex flex-wrap align-items-center"
            extra={
              <Button
                size="sm"
                color="danger"
                outline
                onClick={() => fields.remove(index)}
                className="ms-auto p-1 align-self-end"
              >
                Delete
              </Button>
            }
          >
            Rate {index + 1}
          </FormHeader>
          <div className="col-lg-4">
            <Field
              component={RenderField}
              type="date"
              name={`${field}.date`}
              required
              validate={required}
              label="Date"
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={SelectInput}
              name={`${field}.equipment_id`}
              options={equipment}
              required
              validate={required}
              label="Equipment"
            />
          </div>
          <div className="col-lg-4">
            <Field
              component={RenderField}
              name={`${field}.rate`}
              required
              validate={required}
              {...money}
              label="Rate"
            />
          </div>
        </React.Fragment>
      ))}
      <div className="col-lg-12">
        <Button onClick={() => fields.push({})}>Add Rate</Button>
      </div>
    </>
  );
};

export default EquipmentRates;
