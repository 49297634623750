import React from "react";
import { Link } from "react-router-dom";
import useSortJobs from "../hooks/useSortJobs";
import Job from "./Job";
import SortDropdown from "./SortDropdown";

const ProjectCard = ({ project }) => {
  const { sortJobs, toggleSort, sort } = useSortJobs();

  const ordered = sortJobs(project.jobs);

  if (project.jobs.length === 0) return null;
  return (
    <div className="card mb-3">
      <div className="card-header bd-0 d-flex align-items-center justify-content-between">
        <Link to={project.link}>
          <span className="text-secondary">{project.name}</span>
        </Link>
        <div className="card-option tx-24">
          <SortDropdown toggleSort={toggleSort} sort={sort} />
        </div>
      </div>
      <div className="card-body">
        {ordered.map((job, index) => {
          return (
            <Job key={job.id} job={job} jobs={project.jobs} index={index} />
          );
        })}
      </div>
    </div>
  );
};

export default ProjectCard;
