import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ReactTable from "../table/ReactTable";
import InformationAlert from "../utils/InformationAlert";

const TemplatesListModal = (props) => {
  const { data, modal, toggle, chartOfAccount } = props;

  const columns = [
    {
      accessorKey: "branch",
      header: "Budget Template",
      cell: ({ row, getValue }) => {
        return (
          <Link to={`/budget-templates/${row.original.uuid}/details`}>
            {`${chartOfAccount} - ${getValue()}`}
          </Link>
        );
      },
    },
  ];

  return (
    <Modal
      className="modal-dialog modal-lg wd-md-600"
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader
        toggle={toggle}
      >{`${chartOfAccount} Associated Budget Templates`}</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <InformationAlert
            title={`${chartOfAccount} has associated budget templates.`}
            body={`Please change the account of the following budget templates before deleting this account.`}
            type="warning"
          />
        </div>
        <ReactTable columns={columns} data={data} />
      </ModalBody>
      <ModalFooter>
        <button onClick={toggle}>Close</button>
      </ModalFooter>
    </Modal>
  );
};

export default TemplatesListModal;
