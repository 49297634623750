import React from "react";
import { useHistory } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import TrackingCategoryForm from "./TrackingCategoryForm";
import { toast } from "react-toastify";
import Spinner from "../utils/Spinner";

const TrackingCategories = () => {
  const { data, takeAction, loading } = useApi("xero/tracking-categories", []);

  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", "xero/tracking-categories", values)
      .then(() => {
        history.push("/organisations/settings/links");
        toast.success("Categories Synced Successfully");
      })
      .catch(errorSwal);
  };

  return (
    <>
      {loading && <Spinner loading />}

      <HeaderPage titlePage="Step 2. Sync Tracking Categories" crumbs={[]} />
      {data?.organisation?.map((category, index) => {
        return (
          <div className="bg-white p-4 mb-3">
            <TrackingCategoryForm
              form={`TrackingCategory${index}`}
              index={index}
              category={category}
              xero={data?.xero}
              onSubmit={onSubmit}
            />
          </div>
        );
      })}
    </>
  );
};

export default TrackingCategories;
