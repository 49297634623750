import React from "react";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import CustomScaleLoader from "../utils/scaleLoader";
import { BrandingTheme, BrandingThemeProfilePages } from "./brandingThemeTypes";
import ShowBrandingThemeProfilePage from "./ShowBrandingThemeProfilePage";

const BrandingThemeProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data,
    setData: setBrandingTheme,
    loading,
  }: IUseApi<BrandingTheme> = useApi(`branding-themes/${uuid}`, null, true);

  const brandingTheme = data as BrandingTheme;

  const tabs: ProfileTab<BrandingThemeProfilePages>[] = [
    {
      label: "Details",
      link: `/branding-themes/${uuid}/details`,
      page: "details",
    },
  ];

  if (!brandingTheme?.external_integration_id) {
    tabs.push({
      label: "Logo",
      link: `/branding-themes/${uuid}/logo`,
      page: "logo",
    });
  }

  return (
    <>
      <HeaderPageAdminFilter
        crumbs={[
          {
            name: "Branding Themes",
            link: "/branding-themes",
          },
          {
            name: brandingTheme ? brandingTheme.name : "-",
            link: "/branding-themes",
            active: true,
          },
        ]}
        titlePage={brandingTheme?.name ?? "-"}
      />
      {loading ? (
        <CustomScaleLoader>Fetching Branding Theme...</CustomScaleLoader>
      ) : (
        <Profile<BrandingThemeProfilePages>
          content={
            <ShowBrandingThemeProfilePage
              brandingTheme={brandingTheme}
              setBrandingTheme={setBrandingTheme}
            />
          }
          header={
            <DashboardHeader>
              <DashboardH3>{brandingTheme?.name ?? "-"}</DashboardH3>
            </DashboardHeader>
          }
          tabs={tabs}
        />
      )}
    </>
  );
};

export default BrandingThemeProfile;
