import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import useApi from "../api/useApi";
import isSubmitting from "../utils/submitting";
import { primary } from "../utils/Colours";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import ReactTable from "../table/ReactTable";

const InvoiceReminder = (props) => {
  const { loading, data, setData } = useApi(
    `user-invoice-reminder`,
    null,
    true,
  );
  const { takeAction } = useApi();
  const { takeAction: takeActionComplete, loading: completing } = useApi();
  const [loadingInvoices, setLoadingInvoices] = useState([]);
  const invoiceFromUuid = (uuid) => data.filter((inv) => inv.uuid === uuid)[0];
  const startLoading = (uuid) => setLoadingInvoices([...loadingInvoices, uuid]);
  const stopLoading = (uuid) =>
    setLoadingInvoices(loadingInvoices.filter((x) => x !== uuid));

  useEffect(() => {
    // Duplicate uuid field to avoid keyerror when used across two table columns
    if (!data || !data.length) return;
    if ("uuid2" in data[0]) return;
    setData(
      data.map((d) => {
        return { ...d, uuid2: d.uuid };
      }),
    );
  });

  const complete = () => {
    takeActionComplete("destroy", "user-invoice-reminder")
      .then(() => setData([]))
      .catch(errorSwal);
  };

  const requestInvoice = (uuid) => {
    startLoading(uuid);
    const post = { requested_at: dayjs().format("YYYY-MM-DD HH:mm:ss") };
    const invoice = invoiceFromUuid(uuid);
    if (!invoice.hasDeliverables) return;
    takeAction("update", `invoices/${uuid}`, post)
      .then(() => {
        const remaining = data.length;
        stopLoading(uuid);
        setData(data.filter((inv) => inv.uuid !== uuid));
        toast.success(`Invoice ${invoice.name} requested`);
        if (remaining === 1) complete();
      })
      .catch((err) => {
        stopLoading(uuid);
        errorSwal(err);
      });
  };

  const cancelInvoice = (uuid) => {
    startLoading(uuid);
    const invoice = invoiceFromUuid(uuid);
    takeAction("store", `user-invoice-reminder/${uuid}`, {})
      .then(() => {
        const remaining = data.length;
        stopLoading(uuid);
        setData(data.filter((inv) => inv.uuid !== uuid));
        toast.success(`Email Sent to Accounts to cancel ${invoice.name}`);
        if (remaining === 1) complete();
      })
      .catch((err) => {
        stopLoading(uuid);
        errorSwal(err);
      });
  };

  // Need to get endpoints working for request/cancel

  const columns = [
    {
      accessorKey: "name",
      header: "Number",
    },
    {
      accessorKey: "project",
      header: "Project",
    },
    {
      accessorKey: "uuid",
      header: "Request",
      cell: (info) => {
        const uuid = info.getValue();
        const disabled = !invoiceFromUuid(uuid).hasDeliverables;

        return (
          <div className="text-center" id={`request-${uuid}`}>
            {loadingInvoices.includes(uuid) ? (
              <div style={{ position: "relative", left: "60%" }}>
                <ClipLoader size={15} color={primary} />
              </div>
            ) : (
              <Button
                color="white"
                className="pt-0"
                disabled={disabled}
                onClick={() => requestInvoice(uuid)}
              >
                <i className="fa fa-send tx-inverse pe-2"></i>
                {disabled && (
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`request-${uuid}`}
                  >
                    Can't request Invoice with no Deliverables
                  </UncontrolledTooltip>
                )}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "uuid2",
      header: "Cancel",
      cell: (info) => {
        const uuid = info.getValue();
        return loadingInvoices.includes(uuid) ? (
          ""
        ) : (
          <div className="text-center">
            <Button
              color="white"
              style={{ marginTop: "-14px" }}
              onClick={() => cancelInvoice(uuid)}
            >
              <i className="tx-22 pb-3 tx-danger icon ion-trash-a" />
            </Button>
          </div>
        );
      },
    },
  ];

  if (loading) return <Spinner loading />;

  if (!data) return null;

  return (
    <Modal
      isOpen={Boolean(data?.length)}
      backdrop="static"
      className="modal-lg wd-md-600"
    >
      <ModalHeader>Invoice Reminder</ModalHeader>
      <ModalBody>
        <h5 className="text-center tx-primary mb-5 mt-2">
          {data.length === 1
            ? "You have an outstanding invoice"
            : `You have ${data.length} outstanding invoices`}
        </h5>

        <ReactTable disableSearch columns={columns} data={data} />
      </ModalBody>
      <ModalFooter>
        <Button outline color="secondary" onClick={complete}>
          {isSubmitting(completing, "Complete", "Completing...")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InvoiceReminder;
