import { InjectedFormProps } from "redux-form";
import ProjectFormFields from "./Fields";
import SubmitButton from "../../utils/SubmitButton";
import { reduxForm } from "redux-form";

const ProjectProjectFormsForm = (props: InjectedFormProps) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
        <ProjectFormFields {...props} />
        <div className="row">
            <div className="form-group col-lg-12">
                <SubmitButton {...props} />
            </div>
        </div>
    </form>
  );
};

export default reduxForm({
  form: "ProjectFormsForm",
})(ProjectProjectFormsForm);