import React from "react";
import { ClientFeedback as IClientFeedback } from "../clientFeedback/clientFeedbackTypes";
import ShowProjectFeedback from "../projects/ShowProjectFeedback";

const ClientProjectFeedback = ({ project }: any) => {
  const feedback = project.feedback as IClientFeedback[];

  return (
    <div className="space-y-3 m-3">
      <p className="slim-card-title ps-2 pt-3 mb-0">{project.display_name}</p>
      {feedback.length > 0 ? (
        feedback.map((feedback) => {
          return (
            <ShowProjectFeedback key={feedback.uuid} feedback={feedback} />
          );
        })
      ) : (
        <p className="text-center">No Feedback</p>
      )}
    </div>
  );
};

export default ClientProjectFeedback;
