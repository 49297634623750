export enum DepreciationMethods {
  NoDepreciation = 1,
  StraightLine = 2,
  DiminishingValue100 = 3,
  DiminishingValue150 = 4,
  DiminishingValue200 = 5,
  FullDepreciation = 6,
}

export enum AveragingMethods {
  ActualDays = 1,
  FullMonth = 2,
}

export enum DepreciationCalculationMethods {
  None = 1,
  Rate = 2,
  Life = 3,
}

export interface EquipmentTypeGroup {
  name: string;
  uuid: string;
  id: number;
  equipment_type_count: number;
  fixed_asset_account_id: string;
  depreciation_expense_account_id: string;
  accumulated_depreciation_account_id: string;
  depreciation_method: DepreciationMethods;
  averaging_method: AveragingMethods;
  depreciation_rate: number;
  effective_life_years: number;
  depreciation_calculation_method: DepreciationCalculationMethods;
  is_depreciable: boolean;
  accounting_integration_model?: {
    uuid: string;
    external_integration_id: string;
  };
}
