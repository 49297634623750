import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import Spinner from "../utils/Spinner";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import { Integration } from "../integrations/integrationTypes";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import RenderField from "../utils/renderField";
import ShowOpenAIPage from "./ShowOpenAIPage";
import SubmitButton from "../utils/SubmitButton";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";
import { toast } from "react-toastify";
import { IUseApiWithData } from "../api/apiTypes";
import { Model } from "./openAiTypes";
import SelectInput from "../form/SelectInput";

const OpenAIProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading } = useApi(`integrations/${uuid}`, null, true);

  if (loading && !data) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="OpenAI" />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>OpenAI</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowOpenAIPage
            /** @ts-ignore */
            integration={data as Integration<{ api_key: string }>}
          />
        }
        tabs={[
          {
            label: "Details",
            link: `/integrations/open-ai/${uuid}/details`,
            page: "details",
          },
          {
            label: "Assistants",
            link: `/integrations/open-ai/${uuid}/assistants`,
            page: "assistants",
          },
        ]}
      />
    </>
  );
};

interface OpenAIDetailsProps {
  integration: Integration;
}

interface OpenAIDetailsFormValues {
  access_token: {
    api_key: string;
  };
  extra_info: {
    default_model: string;
  };
}

const OpenAIDetails = (
  props: InjectedFormProps<OpenAIDetailsFormValues, OpenAIDetailsProps> &
    OpenAIDetailsProps,
) => {
  const { handleSubmit, integration } = props;

  console.log(props.initialValues);

  const { data: models }: IUseApiWithData<Model[]> = useApi(
    "open-ai/models",
    [],
    true,
  );

  const { takeAction } = useApi();

  const onSubmit: FormSubmitHandler<
    OpenAIDetailsFormValues,
    OpenAIDetailsProps
  > = (values) => {
    return takeAction(
      "update",
      `integrations/${integration.uuid}/open-ai`,
      values,
    )
      .then(() => {
        toast.success("OpenAI details updated successfully");
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="access_token.api_key"
            label="API Key"
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={SelectInput}
            name="extra_info.default_model"
            label="Default Model"
            options={models.map((m: Model) => ({
              label: m.id,
              value: m.id,
            }))}
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<OpenAIDetailsFormValues, OpenAIDetailsProps>({});

export const WrappedOpenAIDetails = form(OpenAIDetails);

export default OpenAIProfile;
