import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../context/auth-context";
import { ReactComponent as Security } from "../../svgs/security.svg";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import { AuthUser } from "../user/userTypes";
import formError from "../utils/formError";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import isSubmitting from "../utils/submitting";
import { useRedirectOnLogin } from "./hooks/useRedirectOnLogin";

const TwoFactorChallenge = (props: InjectedFormProps<{ code: string }>) => {
  const { handleSubmit } = props;
  const { setUser } = useAuth();
  const redirect = useRedirectOnLogin();

  const [recoveryCode, setRecoveryCode] = useState(false);

  const {
    takeAction,
    loading,
  }: IUseApi<
    {},
    {
      data: AuthUser;
    }
  > = useApi();

  const onSubmit: FormSubmitHandler<{
    code: string;
    trust_device?: boolean;
    device_id?: string;
  }> = (values) => {
    if (values.trust_device) {
      const uuid = uuidv4();

      localStorage.setItem("device_id", uuid);
      values.device_id = uuid;
    }

    return takeAction("store", "/auth/two-factor-challenge", values)
      .then(({ data }) => {
        setUser(data.data);
        if (recoveryCode) {
          toast.success(
            "Recovery code used. You will not be able to use that code again. Please set up a new recovery code in your profile.",
          );
        }
        redirect(data.data);
      })
      .catch(formError);
  };

  return (
    <div className="signin-wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white rounded-lg shadow p-5 border signin-card">
          <h2 className="w-100 d-flex justify-content-center mb-5">
            <a href="/">
              <Security height={100} />
            </a>
          </h2>

          <div className="text-center mb-5">
            <h5 className="text-dark">Two Factor Authentication</h5>
            <p className="mb-0">
              Enter 6 Digit Code from your Authenticator App
            </p>
          </div>
          <FormErrorAlert error={props.error} />
          <div>
            <div className="form-group">
              {recoveryCode ? (
                <Field
                  className="form-control"
                  name="recovery_code"
                  label="Recovery Code"
                  required
                  validate={required}
                  placeholder="Recovery Code"
                  component={RenderField}
                />
              ) : (
                <Field
                  className="form-control"
                  name="code"
                  label="Code"
                  required
                  validate={required}
                  placeholder="Code"
                  component={RenderField}
                />
              )}
            </div>
            <div className="form-group">
              <Field
                component={renderToggleInput}
                name="trust_device"
                label="Trust Device"
                information="This will allow you to bypass 2FA for 30 days."
              />
            </div>

            <button
              className="btn btn-primary w-100 btn-signin"
              disabled={loading}
            >
              {isSubmitting(loading, "Submit", "Submitting...")}
            </button>
            <Button
              color="link"
              className="px-0"
              onClick={() => setRecoveryCode(!recoveryCode)}
            >
              Use {recoveryCode ? "2Fa" : "Recovery"} Code
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const form = reduxForm<{ code: string }>({
  form: "TwoFactorChallenge",
});

export default form(TwoFactorChallenge);
