import React from "react";
import { Field } from "redux-form";
import useApi from "../api/useApi";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const Modal = (props) => {
  const { setTypes, types, toggle } = props;

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("store", "tender-types", values)
      .then(({ data }) => {
        setTypes([...types, data.data]);
        toast.success(`${values.name} added successfully`);
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal {...props} title="Add New Tender Type" onSubmit={onSubmit}>
      <div className="col-12 form-group">
        <Field
          name="name"
          label="Name"
          required
          validate={required}
          component={RenderField}
        />
      </div>
    </FormModal>
  );
};

export default Modal;
