import HeaderPage from "../header/HeaderPage";
import useApi from "../api/useApi";
import GroupForm from "./GroupForm";
import { IUseApi } from "../api/apiTypes";
import { Group, GroupFormData } from "./groupTypes";
import { FormSubmitHandler } from "redux-form";
import formError from "../utils/formError";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const AddGroup = () => {
  const { takeAction }: IUseApi<{}, { data: Group }> = useApi();

  const history = useHistory();

  const onSubmit: FormSubmitHandler<GroupFormData> = (values) => {
    return takeAction("store", "/groups", values)
      .then(({ data }) => {
        history.push(`/groups/${data.data.uuid}`);
        toast.success("Group Added");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add Group"
        crumbs={[
          {
            name: "Groups",
            link: "/groups",
          },
          {
            name: "Add Group",
            link: "/groups/add",
            active: true,
          },
        ]}
      />
      <GroupForm onSubmit={onSubmit} form="AddGroup" />
    </>
  );
};

export default AddGroup;
