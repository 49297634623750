import React from "react";
import { useAuth } from "../../context/auth-context";
import useModal from "../hooks/useModal";
import StandardDocumentModal from "../standardDocuments/standardDocumentModal";

const AddFileButton = (props) => {
  const { setRole, role } = props;
  const { user } = useAuth();
  const { toggle, modal } = useModal();

  return (
    <>
      <ul className="nav nav-activity-profile mg-y-20">
        <li className="nav-item">
          <button
            type="button"
            onClick={toggle}
            className="pointer-hover w-100 nav-link"
          >
            <i className="icon ion-plus tx-primary" /> Upload File
          </button>
        </li>
      </ul>
      <StandardDocumentModal
        {...props}
        toggle={toggle}
        modal={modal}
        whenSubmitted={({ data }) =>
          setRole({
            ...role,
            file: data.data,
          })
        }
        documentable_id={role.uuid}
        documentable_type="App\Models\OrganisationRole"
        bucket={`organisations/${user.active_organisation.uuid}/organisation-roles/${role.uuid}`}
      />
    </>
  );
};

export default AddFileButton;
