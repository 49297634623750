import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import isSubmitting from "../utils/submitting";
import { IProject } from "./projectTypes";

const DeleteProjectButton = ({ project }: { project: IProject }) => {
  const { takeAction, loading } = useApi();
  const { user } = useAuth();
  const history = useHistory();

  const deleteProject = () => {
    deleteSwal(project.name)
      .then(() => takeAction("destroy", `/projects/${project.uuid}`))
      .then(() => {
        toast.success("Project Deleted");
        history.push("/projects");
      });
  };

  if (!user?.hasAccessTo("App\\Models\\Project", "delete")) {
    return null;
  }

  if (project.jobs_count > 0 && !project.is_template) {
    return null;
  }

  return (
    <Button
      color="danger"
      outline
      block
      className="mt-3"
      disabled={loading}
      onClick={deleteProject}
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </Button>
  );
};

export default DeleteProjectButton;
