import React from "react";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { PermissionGroup, ProfilePages } from "./permissionGroupTypes";
import ShowPermissionGroupPage from "./ShowPermissionGroupPage";
import { ProfileTab } from "../tabs/tabTypes";
import DocsModal from "../utils/DocsModal";

const PermissionGroupProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const tabs: ProfileTab<ProfilePages>[] = [
    {
      label: "Details",
      link: `/permission-groups/${uuid}/details`,
      page: "details",
    },
    {
      label: "Permissions",
      link: `/permission-groups/${uuid}/permissions`,
      page: "permissions",
    },
    {
      label: "Users",
      link: `/permission-groups/${uuid}/users`,
      page: "users",
    },
    {
      label: "Positions",
      link: `/permission-groups/${uuid}/positions`,
      page: "positions",
    },
  ];

  const { data, loading, setData }: IUseApi<PermissionGroup> = useApi(
    `permission-groups/${uuid}`,
    null,
    true,
  );

  if (loading) {
    return <Spinner loading />;
  }

  if (!data) return null;

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/user-levels/" />
      <HeaderPage
        titlePage="Permission Group Profile"
        crumbs={[{ name: "Permission Groups", link: "/permission-groups" }]}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{data.name}</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowPermissionGroupPage
            permissionGroup={data}
            setPermissionGroup={setData}
          />
        }
        tabs={tabs}
      />
    </>
  );
};

export default PermissionGroupProfile;
