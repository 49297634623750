import { Children, cloneElement } from "react";
import FooterLink from "../../footer/FooterLink";
import MobileFooter from "../../footer/MobileFooter";

const TabList = (props) => {
  const { children } = props;
  const width = children?.length > 4 ? "28%" : null;

  return (
    <div>
      <div className="manager-left d-lg-block w-100">
        <nav className="nav">
          {Children.map(
            children,
            (child, index) =>
              child &&
              cloneElement(child, {
                tabIndex: index + 1,
              }),
          )}
        </nav>
      </div>
      <MobileFooter>
        {Children.map(
          children,
          (child) => child && <FooterLink {...child.props} width={width} />,
        )}
      </MobileFooter>
    </div>
  );
};

export default TabList;
