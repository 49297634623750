import React from "react";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import Tabs from "../../tabs/Tabs";
import Login from "./Login";
import SyncClients from "./SyncClients";
import SyncUsers from "./SyncUsers";

const TheSiteClassifierApp = () => {
  const { data } = useApi("the-site-classifier-app", null);

  const tabs = [
    {
      title: "Sync Clients",
      component: <SyncClients />,
    },
    {
      title: "Sync Users",
      component: <SyncUsers />,
    },
  ];

  return (
    <>
      <HeaderPage titlePage="the SiteClassification App" crumbs={[]} />
      {data?.integration ? (
        <Tabs
          tabs={tabs}
          rightNav={() => data?.integration?.access_token?.organisation}
        />
      ) : (
        <Login />
      )}
    </>
  );
};

export default TheSiteClassifierApp;
