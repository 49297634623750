import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { Comment } from "../comments/commentTypes";
import { draftField } from "../form/formatters";
import MentionField from "../form/MentionField";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import FileList from "../utils/FileList";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { SupportRequest } from "./supportRequestTypes";

interface CommentValues {
  comment: string;
  documents: (Blob | File)[];
}

const SupportComment = (
  props: InjectedFormProps<
    CommentValues,
    { setData: Function; supportRequest: SupportRequest }
  > & {
    setData: Function;
    supportRequest: SupportRequest;
  },
) => {
  const { handleSubmit, setData, supportRequest } = props;

  const { uuid } = useParams<{ uuid: string }>();

  const { user } = useAuth();

  const { upload } = useUploadMultipleToS3(
    `organisations/${user?.active_organisation.uuid}/comments`,
  );

  const { takeAction }: IUseApi<{}, { data: Comment }> = useApi();

  const submitComment: FormSubmitHandler<
    CommentValues,
    { setData: Function; supportRequest: SupportRequest }
  > = (values, dispatch, props) => {
    return upload(values.documents ?? [])
      .then((documents: any[]) => {
        values = {
          ...values,
          documents,
        };
        return takeAction("store", `support-requests/${uuid}/comments`, values);
      })
      .then(({ data }) => {
        toast.success("Comment added");
        setData({
          ...supportRequest,
          comments: [...supportRequest.comments, data.data],
        });
        props?.reset?.();

        setFiles([]);
      });
  };

  const [files, setFiles] = useState([]);

  return (
    <form onSubmit={handleSubmit(submitComment)}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={MentionField}
            {...draftField}
            validate={required}
            name="comment"
            placeholder="Write Comment Here..."
          />
        </div>
        <div className="col-12 form-group">
          <FileList {...props} files={files} setFiles={setFiles} />
        </div>
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<
  CommentValues,
  { setData: Function; supportRequest: SupportRequest }
>({});

export default form(SupportComment);
