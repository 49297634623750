import React, { useEffect, useState } from "react";
import AddModalButton from "./AddModalButton";
import SkillTable from "./SkillTable";
import errorSwal from "../utils/errorSwal";
import { useParams } from "react-router-dom";
import axios from "../api/api";

const List = (props) => {
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const { uuid } = useParams();

  useEffect(() => {
    axios
      .get(`/skill-categories/${uuid}/competency-skills`)
      .then(({ data }) => {
        setSkills(data.data);
        setLoading(false);
      })
      .catch(errorSwal);
  }, []);

  return (
    <>
      <AddModalButton skills={skills} setSkills={setSkills} {...props} />
      <SkillTable
        skills={skills}
        setSkills={setSkills}
        loading={loading}
        {...props}
      />
    </>
  );
};

export default List;
