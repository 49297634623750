import { Fragment } from "react";

const Card = (props) => {
  const {
    description,
    details,
    activeDashboard,
    setActiveDashboard,
    setFetchingDashboardData,
    fetchingDashboardData,
    disableClick,
  } = props;

  const noneDue = details.every(({ value }) => value === 0);

  return (
    <div
      className={`col-lg-3 col-sm-6 mb-sm-3 mb-3 ${
        noneDue ? "d-none d-md-block" : ""
      }`}
    >
      <div className="card card-total">
        <div>
          <p>{description}</p>
          <div className="d-flex align-items-center justify-content-around mg-b-15 overflow-auto">
            {details.map((d, index) => {
              const final = index + 1 === details.length;

              const isActive = activeDashboard?.key
                ? d.key === activeDashboard.key
                : activeDashboard?.link === d.link;

              return (
                <Fragment key={index}>
                  <div className="text-center tn-200">
                    <button
                      onClick={() => {
                        if (disableClick) {
                          return;
                        }
                        setFetchingDashboardData(true);
                        setActiveDashboard({
                          link: d.link,
                          description: description,
                          key: d.key,
                        });
                      }}
                      type="button"
                      className={`mb-0 btn btn-link p-0 ${
                        isActive ? "text-warning" : "text-body"
                      }`}
                      disabled={
                        fetchingDashboardData || disableClick || d.value == 0
                      }
                    >
                      <h1 className={`mb-0 ${isActive ? "text-warning" : ""}`}>
                        {d.value}
                      </h1>
                      <p className="mb-0">{d.title}</p>
                    </button>
                  </div>
                  {!final && (
                    <div
                      className="border-end mb-g-15"
                      style={{ height: "30px" }}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
