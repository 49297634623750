export default [
  { value: "Fixed Term - Full Time", label: "Fixed Term - Full Time" },
  { value: "Fixed Term - Part Time", label: "Fixed Term - Part Time" },
  { value: "Maximum Term - Full Time", label: "Maximum Term - Full Time" },
  { value: "Maximum Term - Part Time", label: "Maximum Term - Part Time" },
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Casual", label: "Casual" },
  { value: "Contract", label: "Contract" },
  { value: "Student Placement", label: "Student Placement" },
];
