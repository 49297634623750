import { WrappedFieldProps } from "redux-form";
import { ApprovalAction } from "./approvalTypes";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import getFileIcon from "../utils/getFileIcon";
import errorSwal from "../utils/errorSwal";
import { useAuth } from "../../context/auth-context";
import { AiOutlineClose } from "react-icons/ai";
import formatBytes from "../../utils/formatBytes";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { StandardDocument } from "../standardDocuments/standardDocumentTypes";
import { adjustTextareaHeight } from "../utils/renderField";

const RenderRemarks = (
  props: WrappedFieldProps & {
    approvalAction?: ApprovalAction;
    change: Function;
  },
) => {
  const {
    input,
    meta: { touched, error },
    approvalAction,
  } = props;

  const { user } = useAuth();

  const [uploadedFiles, setUploadedFiles] = useState<StandardDocument[]>(
    approvalAction?.documents ?? [],
  );

  const { files, upload, setFiles } = useUploadMultipleToS3(
    `organisations/${user?.active_organisation.uuid}/approval-actions/${approvalAction?.uuid}`,
  );

  const { takeAction }: IUseApi = useApi();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onDrop = (acceptedFiles: File[]) => {
    if (!approvalAction) return;

    if (!acceptedFiles.length) return;
    //
    return upload(acceptedFiles).then((documents) => {
      return takeAction("store", "documents/standard", {
        documentable_type: "App\\Models\\ApprovalAction",
        documentable_id: approvalAction.uuid,
        documents: documents,
      })
        .then(({ data }) => {
          toast.success("Documents uploaded successfully");
          setUploadedFiles(data.data);
          setFiles([]);
        })
        .catch(errorSwal);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    preventDropOnDocument: false,
    noClick: true,
    disabled: !approvalAction,
  });

  useEffect(() => adjustTextareaHeight(textareaRef), [input.value]);

  return (
    <>
      <div className="form-group" {...getRootProps()}>
        <input {...getInputProps()} />
        <label className="form-control-label tx-inverse tx-semibold">
          Remarks
        </label>
        <div
          className={`upload-input-wrapper rounded-lg tn-300  ${
            isDragActive ? "border-dashed border-dark" : ""
          } ${touched && error ? "border-danger" : ""}`}
        >
          <textarea
            {...input}
            rows={4}
            className={`w-100 border-0 p-0 textarea-upload-input`}
            placeholder={`Enter details ${
              approvalAction ? "and drag and drop files " : ""
            }here`}
            ref={textareaRef}
          />
          {approvalAction ? (
            <div
              className="d-flex align-items-center border rounded-lg p-1 tn-300"
              style={{ width: "30%" }}
            >
              <label
                htmlFor="approval_files"
                className="pointer-hover ms-1  mb-0 "
              >
                {" "}
                <FiUpload />
              </label>
              <input
                name="approval_files"
                className="p-0 upload-input"
                onChange={(e) => {
                  const droppedFiles = e.target.files;
                  let files = [];
                  if (!droppedFiles) return;

                  for (const file of droppedFiles) {
                    files.push(file);
                  }

                  onDrop(files);
                }}
                multiple
                type="file"
                title="Files"
                id="approval_files"
              />
            </div>
          ) : null}
        </div>
        {touched && error && (
          <span className="parsley-errors-list">{error}</span>
        )}
      </div>
      <div className="my-3 space-y-3">
        {[...files, ...uploadedFiles].map((f) => {
          const file = f as
            | (File & { path?: string; progress?: number; uuid?: string })
            | StandardDocument;

          return (
            <div className="border rounded-lg ">
              <div className="align-items-center d-flex p-3">
                <i className={`${getFileIcon(file.name, false)} me-2`} />{" "}
                <div>
                  <p className="mb-0 text-dark">{file.name} </p>
                  <small className="mt-0">
                    {formatBytes(
                      "size" in file
                        ? file.size
                        : "file_size" in file
                        ? file.file_size
                        : 0,
                    )}{" "}
                    bytes
                  </small>
                </div>
                {file.uuid && (
                  <button
                    role="button"
                    title="Delete"
                    onClick={() => {
                      const oldFiles = uploadedFiles;
                      const newFiles = uploadedFiles.filter((f) => f !== file);
                      setUploadedFiles(newFiles);
                      takeAction(
                        "destroy",
                        `documents/standard/${file.uuid}`,
                      ).catch((err) => {
                        errorSwal(err);
                        setUploadedFiles(oldFiles);
                      });
                    }}
                    type="button"
                    className="ms-auto btn btn-sm btn-link d-flex align-items-center"
                    name="remove"
                  >
                    <AiOutlineClose className="tx-16" />
                  </button>
                )}
              </div>

              {"progress" in file ? (
                <div className="progress">
                  <div
                    className={`progress-bar ${
                      file.progress === 100 ? "bg-success" : "bg-warning"
                    } progress-bar-xs rounded-bottom`}
                    style={{
                      width: `${file.progress}%`,
                    }}
                    role="progressbar"
                    title={`Progress: ${file.progress}%`}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RenderRemarks;
