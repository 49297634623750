import dayjs from "dayjs";
import React from "react";
import { Button } from "reactstrap";
import useModal from "../hooks/useModal";
import ExpectedPaymentDateModal from "./ExpectedPaymentDateModal";

const ExpectedPaymentDate = ({
  invoice,
  setInvoice,
}: {
  invoice: any;
  setInvoice: Function;
}) => {
  const { toggle, modal } = useModal();

  return (
    <div className="d-flex align-items-center">
      <p className="mb-0 tx-inverse">
        Expected Payment Date:{" "}
        <span className="fw-bolder">
          {invoice.expected_payment_date
            ? dayjs(invoice.expected_payment_date).format("DD/MM/YYYY")
            : "Not Added"}
        </span>
      </p>
      <Button
        className="ms-auto"
        outline
        color="info"
        size="sm"
        onClick={toggle}
      >
        Edit
      </Button>
      <ExpectedPaymentDateModal
        toggle={toggle}
        invoice={invoice}
        setInvoice={setInvoice}
        modal={modal}
      />
    </div>
  );
};

export default ExpectedPaymentDate;
