import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";

const MatrixNav = ({ page }: { page: "users" | "skills" }) => {
  const { user } = useAuth();

  return (
    <div className="nav-statistics-wrapper">
      <nav className="nav flex-nowrap">
        <Link
          to="/staff-matrix/users"
          className={`btn btn-link nav-link mb-3 ${
            page === "users" ? "active" : ""
          }`}
        >
          Users
        </Link>
        <Link
          to="/staff-matrix/skills"
          className={`btn btn-link nav-link mb-3 ${
            page === "skills" ? "active" : ""
          }`}
        >
          Skills (BETA)
        </Link>
      </nav>
    </div>
  );
};

export default MatrixNav;
