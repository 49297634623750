const getBgColorInvert = percent => {
  switch (true) {
    case percent < "25":
      return "danger";
    case percent < "50":
      return "warning";
    case percent < "75":
      return "info";
    default:
      return "success";
  }
};

export default getBgColorInvert;
