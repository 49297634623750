import { TestDashboardOverview } from "./../testRequestTypes";
import { useQuery } from "react-query";
import api from "../../../api/api";

const getDashboardOverview = (
  query?: string,
): Promise<TestDashboardOverview> => {
  return api.get(`test-dashboard?${query ?? ""}`).then(({ data }) => data.data);
};

export const useTestDashboardOverview = (query?: string) => {
  return useQuery(["test-dashboard", query], () => getDashboardOverview(query));
};
