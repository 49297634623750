import "chart.js/auto";
import * as chartjs from "chart.js";
import _ from "lodash";
import querystring from "query-string";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { AiOutlineCheck } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import colors from "../../data/colors";
import useApi from "../api/useApi";
import CPIROverviewLine from "../CPIRs/CPIROverviewLine";
import { Range } from "../hooks/useProductivityRange";
import LoadingOverlay from "../utils/LoadingOverlay";
import { rangeQuery } from "./ManagementCPIRs";
import { Group } from "./managementDashboardTypes";

type RiskLevels = "Low" | "Medium" | "High" | "Extreme";

export interface OverviewCPIR {
  id: number;
  risk_level: RiskLevels;
  created_at: string;
  closed_at?: string;
  category: string;
  risk_score: number;
  is_overdue: boolean;
}

const ManagementCPIRCharts = ({
  group,
  range,
}: {
  group?: Group;
  range: Range;
}) => {
  const {
    data,
    setUrl,
    loading,
  }: { data: OverviewCPIR[]; setUrl: (url: string) => any; loading: boolean } =
    useApi("", [], true);

  useEffect(() => {
    const period = rangeQuery(range);

    if (group && period) {
      const query: string = querystring.stringify({
        model: group.type,
        id: group.id,
      });

      setUrl(`cpir-management-overview?${query}${period}`);
    }
  }, [group, range]);

  if (!group || data.length === 0) {
    return null;
  }

  return (
    <div className="row mb-3">
      <div className="col-lg-4">
        <div className="position-relative">
          <LoadingOverlay loading={loading} />
          <div className="bg-white p-3 border">
            <p className="slim-card-title">Status Overview</p>
            <Pie data={statusData(data)} />
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="position-relative">
          <LoadingOverlay loading={loading} />
          <div className="bg-white p-3 border">
            <OverviewPie
              data={data}
              pieData={riskLevelData}
              title="Risk Level"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="position-relative">
          <LoadingOverlay loading={loading} />
          <div className="bg-white p-3 border">
            <OverviewPie data={data} pieData={categoryData} title="Category" />
          </div>
        </div>
      </div>

      <div className="col-lg-12 mt-3">
        <div className="position-relative">
          <LoadingOverlay loading={loading} />
          <div className="bg-white p-3 border">
            <CPIROverviewLine data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

const riskLevelData = (data: OverviewCPIR[]): chartjs.ChartData<"pie"> => {
  const riskLevels = Object.entries(_.groupBy(data, "risk_level"));

  return {
    labels: _.uniq(
      data.map((cpir) => (cpir.risk_level ? cpir.risk_level : "No Risk")),
    ),
    datasets: [
      {
        label: "Risk Level",
        data: riskLevels.map(([level, cpirs]) => cpirs.length),
        backgroundColor: riskLevels.map(([level]) => {
          return colors[convertLevelToColour(level)].light;
        }),
        borderColor: riskLevels.map(([level]) => {
          return colors[convertLevelToColour(level)].border;
        }),
        borderWidth: 1,
      },
    ],
  };
};

const statusData = (data: OverviewCPIR[]): chartjs.ChartData<"pie"> => {
  const open = data.filter((cpir) => !cpir.closed_at && !cpir.is_overdue);

  const closed = data.filter((cpir) => cpir.closed_at);

  const overdue = data.filter((cpir) => cpir.is_overdue);
  return {
    labels: ["Open", "Closed", "Overdue"],
    datasets: [
      {
        label: "Status",
        data: [open.length, closed.length, overdue.length],
        backgroundColor: ["#fce6c6", "#d4e2f3", colors.danger.light],
        borderColor: ["#F49917", "#5B93D3", colors.danger.border],
        borderWidth: 1,
      },
    ],
  };
};

const categoryData = (data: OverviewCPIR[]): chartjs.ChartData<"pie"> => {
  const categories = Object.entries(_.groupBy(data, "category"));

  return {
    labels: _.uniq(data.map((cpir) => cpir.category)),
    datasets: [
      {
        label: "Category",
        data: categories.map(([level, cpirs]) => cpirs.length),
      },
    ],
  };
};

export const convertLevelToColour = (
  level: string,
): "danger" | "warning" | "info" | "success" | "light" => {
  if (level === "Extreme") {
    return "danger";
  }

  if (level === "High") {
    return "warning";
  }

  if (level === "Medium") {
    return "info";
  }
  if (level === "Low") {
    return "success";
  }

  return "light";
};

const OverviewPie = ({
  data,
  pieData,
  title,
}: {
  data: OverviewCPIR[];
  pieData: (data: OverviewCPIR[]) => chartjs.ChartData<"pie">;
  title: string;
}) => {
  const [status, setStatus] = useState<"open" | "closed">();

  const filtered = data.filter((cpir) => {
    if (!status) {
      return true;
    }

    if (status === "closed") {
      return cpir.closed_at;
    }

    return !cpir.closed_at;
  });

  return (
    <>
      <div className="d-flex mg-b-20">
        <p className="slim-card-title mb-0 me-auto">{title} Overview</p>
        <UncontrolledDropdown>
          <DropdownToggle color="link" className="py-0 ms-auto">
            <FiMoreHorizontal className="tx-18 text-secondary" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Status</DropdownItem>
            <DropdownItem
              onClick={() => setStatus(status === "open" ? undefined : "open")}
            >
              Open{" "}
              {status === "open" && (
                <AiOutlineCheck className="ms-1 tx-success" />
              )}
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                setStatus(status === "closed" ? undefined : "closed")
              }
            >
              Closed{" "}
              {status === "closed" && (
                <AiOutlineCheck className="ms-1 tx-success" />
              )}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <Pie data={pieData(filtered)} />
    </>
  );
};

export default ManagementCPIRCharts;
