import ReactTable from "../table/ReactTable";
import { IActivityLog } from "./IActivityLog";
import dayjs from "dayjs";

interface AuditLogTableProps {
  auditLogs: IActivityLog[];
  handleRowClick: (row: IActivityLog) => void;
}

const AuditLogTable = (props: AuditLogTableProps) => {
  const { handleRowClick, auditLogs } = props;

  const columns = [
    {
      accessorKey: "event",
      header: "Event Type",
    },
    {
      accessorKey: "causer.name",
      header: "User",
      // cell: (info: any) => info.getValue()?.full_name,
    },
    {
      accessorKey: "created_at",
      header: "Created At",
      cell: (info: any) => dayjs(info.getValue()).format("DD/MM/YYYY hh:mm a"),
    },
  ];

  return (
    <ReactTable
      onRowClick={handleRowClick}
      disableSearch
      data={auditLogs}
      columns={columns}
    />
  );
};

export default AuditLogTable;
