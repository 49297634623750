import {
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import SearchDocumentation from "./SearchDocumentation";

const HelpDropdown = () => {
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle className="header-notification me-1" color="link">
          <i className="icon ion-help tx-20"></i>
        </DropdownToggle>
        <SearchDocumentation />
      </UncontrolledDropdown>
    </>
  );
};

export default HelpDropdown;
