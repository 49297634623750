import { Field, InjectedFormProps, reduxForm } from "redux-form";
import FormErrorAlert from "../../form/FormErrorAlert";
import RenderField from "../../utils/renderField";
import required from "../../utils/required";
import SubmitButton from "../../utils/SubmitButton";
import { TestSuiteStatus } from "../testSuiteTypes";

const TestSuiteStatusForm = (props: InjectedFormProps<TestSuiteStatus>) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            required
            validate={required}
            label="Name"
            name="name"
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<TestSuiteStatus>({});

export default form(TestSuiteStatusForm);
