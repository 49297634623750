import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getBranchDeliverables = (
  { pageParam = 1 },
  search?: string,
  uuid?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `organisation-branches/${uuid}/deliverables?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useBranchDeliverables(search?: string, uuid?: string) {
  return useInfiniteQuery(
    ["branch-deliverables", { search, uuid }],
    (pageParam) => getBranchDeliverables(pageParam, search, uuid),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
