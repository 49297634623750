import DetailsTable from "./DetailsTable";
import SpreadPrice from "../utils/SpreadPrice";
import { formatNumber } from "./OverviewCard";
import dayjs from "dayjs";
import ReactTable from "../table/ReactTable";
import { Link } from "react-router-dom";

const WagesTable = (props) => {
  const columns = [
    {
      header: "Timesheets",
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <button
            className="btn btn-sm btn-secondary"
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: "pointer" },
            }}
          >
            {row.getIsExpanded() ? "Hide" : "Show"}
          </button>
        ) : null;
      },
    },
    {
      accessorKey: "name",
      header: "User",
      cell: ({ row, getValue }) => (
        <div>
          <a href={`mailto:${row.original.email}`}>{getValue()}</a>
        </div>
      ),
    },
    {
      accessorKey: "total_wages",
      header: "Amount",
      cell: (info) => <SpreadPrice price={info.getValue()} />,
    },
  ];

  const renderSubTable = (row) => {
    const columns = [
      {
        accessorKey: "date",
        header: "Date",
        cell: (info) => {
          const timesheet = info.row.original;
          return (
            <Link to={`/timesheets/${timesheet.uuid}/details`}>
              {dayjs(timesheet.date).format("DD/MM/YYYY")}
            </Link>
          );
        },
      },
      {
        accessorKey: "actual_hours",
        header: "Hours",
      },
      {
        accessorKey: "hourly_rate",
        header: "Hourly Rate",
        cell: (info) => `$${formatNumber(info.getValue())}`,
      },
      {
        accessorKey: "pay",
        header: "Total Cost",
        cell: (info) => `$${formatNumber(info.getValue())}`,
      },
      {
        accessorKey: "type_description",
        header: "Timesheet Type",
      },
    ];

    return (
      <ReactTable columns={columns} data={row.original?.timesheets ?? []} />
    );
  };

  return (
    <>
      {/* <p className="tx-inverse">
        N.B. Time sheets without any dollar values are have not yet been
        approved.
      </p> */}
      <DetailsTable
        {...props}
        title="Wages"
        url="wages-overview"
        columns={columns}
        getRowCanExpand={() => true}
        renderSubComponent={renderSubTable}
      />
    </>
  );
};

export default WagesTable;
