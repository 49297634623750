import React from "react";
import { Field, reduxForm } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const AccountForm = (props) => {
  const { handleSubmit, account, xeroAccounts } = props;

  const options = xeroAccounts?.map((xeroAccount) => ({
    value: xeroAccount.AccountID,
    label: `${xeroAccount.Code} - ${xeroAccount.Name}`,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-5 form-group d-flex align-items-center">
          <p className="tx-inverse fw-bolder mb-0">
            {account?.code} - {account?.name}
          </p>
        </div>
        <div className="col-2 form-group d-flex justify-content-center align-items-center">
          <i className="icon ion-arrow-right-a tx-inverse fw-bolder tx-20" />
        </div>
        <div className="col-5 form-group">
          <Field
            component={SelectInputAsync}
            options={options}
            name="xero_id"
            label="Xero Account"
          />
        </div>
        <div className="col-12 form-group">
          <SubmitButton {...props} save="Sync" saving="Syncing..." />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ enableReinitialize: true });

export default form(AccountForm);
