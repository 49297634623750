import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";

const useBranchFilters = () => {
  const { data } = useApi("organisation-branches", []);

  const branches = data as {
    name: string;
    id: number;
  }[];

  return branches;
};
export default useBranchFilters;
