import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Form from "./Form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const Add = () => {
  const { takeAction } = useApi();
  const history = useHistory();

  const onSubmit = (values) => {
    const data = {
      ...values,
      custom_fields: values.custom_fields.map((field, index) => ({
        ...field,
        order: index,
      })),
    };

    return takeAction("store", "custom-jobs", data)
      .then(({ data }) => {
        history.push(`/custom-jobs/${data.data.uuid}/details`);
        toast.success(`${values.name} added.`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Add New Custom Job"
        crumbs={[
          { name: "Custom Jobs", link: "/custom-jobs" },
          { name: "Add Custom Job", link: "", active: true },
        ]}
      />
      <Form
        onSubmit={onSubmit}
        form="AddCustomJob"
        canSendExternally
        initialValues={{
          custom_fields: [{}],
        }}
      />
    </>
  );
};

export default Add;
