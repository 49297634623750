import React, { useEffect, useState } from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import FileInput from "../upload/FileInput";
import DateTime, { formatting } from "../utils/dateTime";
import RenderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import renderToggleInput from "../utils/renderToggleInput";
import { money } from "../form/formatters";
import required from "../utils/required";
import { connect } from "react-redux";
import api from "../api/api";
import AsyncSelectInput from "../form/AsyncSelectInput";

const Form = (props) => {
  const { takeAction } = useApi();
  const { initialValues, uploadPercent, isRecurring, payTo } = props;
  const { user } = useAuth();

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (initialValues?.user_id) {
      updateRoles(initialValues.user_id);
    }
  }, []);

  useEffect(() => {
    if (isRecurring && props.initialized) {
      props.change("date", null);
      return;
    }

    if (props.initialized) {
      props.change("start_date", null);
    }
  }, [isRecurring, props.initialized]);

  const updateRoles = (id) => {
    takeAction("index", getStaffRoleUrl(id)).then(({ data }) => {
      setRoles(data.data);
    });
  };

  const showCategory =
    user.active_organisation.integration_types.includes("Payroll");

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <div className="col-lg-5 form-group">
          <Field
            component={SelectInputAsync}
            url="/users/staff"
            name="user_id"
            label="User"
            onChangeValue={({ value }) => updateRoles(value)}
            required
            validate={required}
          />
        </div>
        <div className="col-lg-5 form-group">
          <Field
            component={RenderField}
            name={isRecurring ? "start_date" : "date"}
            label={isRecurring ? "Start Date" : "Date"}
            type="date"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-2 form-group">
          <Field
            component={renderToggleInput}
            name="is_recurring"
            label="Is Recurring?"
          />
        </div>
        <div className={`${showCategory ? "col-lg-4" : "col-lg-6"} form-group`}>
          <Field
            component={SelectInput}
            options={roles}
            name="role_id"
            label="Role"
            required
            validate={required}
          />
        </div>
        <div className={`${showCategory ? "col-lg-4" : "col-lg-6"} form-group`}>
          <Field
            component={RenderField}
            name="amount"
            label="Amount"
            required
            validate={required}
            {...money}
          />
        </div>
        {showCategory && (
          <>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                url="/payroll-integrations/deduction-categories"
                required
                validate={required}
                name="external_category_id"
                label="Deduction Category"
                formatData={(data) =>
                  data.map((d) => ({ label: d.name, value: d.id }))
                }
              />
            </div>
          </>
        )}
        {user.is_admin && (
          <div className="col-lg-4 form-group">
            <Field
              component={SelectInput}
              name="pay_to"
              options={[
                { label: "Withheld by Employer", value: 1 },
                {
                  label: "Bank Account",
                  value: 2,
                },
                {
                  label: "Super Fund",
                  value: 3,
                },
              ]}
              label="Pay To"
            />
          </div>
        )}
        {showCategory && (
          <>
            {payTo === 2 && (
              <>
                <div className="col-lg-4 form-group">
                  <Field
                    component={RenderField}
                    name="bank_account_bsb"
                    label="BSB"
                    required
                    validate={required}
                  />
                </div>
                <div className="col-lg-4 form-group">
                  <Field
                    component={RenderField}
                    name="bank_account_number"
                    label="Account Number"
                    required
                    validate={required}
                  />
                </div>
              </>
            )}
            {payTo === 3 && (
              <>
                <div className="col-lg-4 form-group">
                  <Field
                    component={RenderField}
                    name="super_fund_member_number"
                    label="Super Fund Member Number"
                    required
                    validate={required}
                  />
                </div>
                <div className="col-lg-4 form-group">
                  <Field
                    component={AsyncSelectInput}
                    name="super_fund_name"
                    label="Super Fund"
                    required
                    validate={required}
                    asyncFunction={(inputValue) =>
                      api
                        .get(
                          `/payroll-integration/super-funds?term=${inputValue}`,
                        )
                        .then(({ data }) =>
                          data.data.map((d) => ({
                            label: (
                              <div>
                                <p className="mb-0 text-dark">
                                  {d.productName}
                                </p>
                                <small>{d.productCode}</small>
                              </div>
                            ),
                            value: d.productCode,
                          })),
                        )
                    }
                  />
                </div>
              </>
            )}
          </>
        )}
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="description"
            label="Description"
            required
            validate={required}
          />
        </div>
        {!initialValues?.uuid && (
          <div className="col-12 form-group">
            <Field
              component={FileInput}
              name="file"
              label="File"
              percent={uploadPercent}
              required
              validate={required}
            />
          </div>
        )}
        {user.is_admin && (
          <div className="col-12 form-group">
            <SubmitButton {...props} />
          </div>
        )}
      </div>
    </form>
  );
};

export const getStaffRoleUrl = (userId) => {
  return `react-select-data?model=${encodeURIComponent(
    "App\\Models\\StaffRole",
  )}&select=id,branch_id,position_id,user_id,employment_basis,remarks,start_date,finish_date,organisation_id&label=roleBranch&value=id&where[user_id]=${userId}&additional[branch_id]=branch_id&additional[employment_basis]=employment_basis&additional[disabled]=is_disabled&has=branch`;
};

const form = reduxForm();

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    isRecurring: selector(state, "is_recurring"),
    payTo: selector(state, "pay_to"),
  };
};

export default connect(mapStateToProps)(form(Form));
