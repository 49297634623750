import React from "react";
import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import DeliverableTable from "../deliverables/DeliverableTable";
import useBranchDeliverables from "./hooks/useBranchDeliverables";

const BranchDeliverables = () => {
  const { uuid } = useParams();

  return (
    <PaginatedList
      listName="branchDeliverablesList"
      indexHook={useBranchDeliverables}
      indexHookArguments={[uuid]}
      originalFilters={[]}
      list={({ data }) => (
        <div className="col-12">
          {data?.length > 0 && (
            <DeliverableTable
              showJob={true}
              deliverables={data}
              useSelected={false}
            />
          )}
        </div>
      )}
    />
  );
};

export default BranchDeliverables;
