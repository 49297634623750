import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import EditBankDetails from "../bankAccountDetails/Edit";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import Billing from "./Billing";
import GeneralSettings from "./GeneralSettings";
import Integrations from "../integrations/Integrations";
import LogoPage from "./LogoPage";
import OrganisationTokens from "./OrganisationTokens";
import QuickLinks from "./QuickLinks";
import UpdateDetails from "./UpdateDetails";

const ShowPage = (props) => {
  const { user } = useAuth();
  const { page } = useParams();

  const uuid = user.active_organisation.uuid;

  switch (page) {
    case "details":
      return <UpdateDetails {...props} />;
    case "links":
      return <QuickLinks />;
    case "logo":
      return <LogoPage {...props} />;
    case "documents":
      return (
        <>
          <p>These documents are only visible to administrators.</p>
          <ProfileDocuments
            {...props}
            model="App\Models\Organisation"
            documentableId={uuid}
            documentPath={`organisations/${uuid}/documents/organisation`}
            homeUrl={`organisations/settings/documents`}
          />
        </>
      );
    case "general":
      return <GeneralSettings {...props} />;
    case "bank-details":
      return <EditBankDetails {...props} />;
    case "billing":
      return <Billing {...props} />;
    case "integrations":
      return <Integrations {...props} />;
    case "tokens":
      return <OrganisationTokens />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
