import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import ShowPage from "./ShowPage";

const SkillLevelProfile = () => {
  const { uuid } = useParams();

  const {
    loading,
    data: level,
    setData: setLevel,
  } = useApi(`skill-levels/${uuid}`, null, true);

  if (loading) return <Spinner loading />;

  const title = `${level.skill_name} - ${level.name}`;

  const tabs = [
    {
      link: `/skill-levels/${uuid}/details`,
      page: "details",
      label: "Details",
    },
    {
      link: `/skill-levels/${uuid}/fields`,
      page: "fields",
      label: "Fields",
    },
  ];

  return (
    <>
      <HeaderPage titlePage={title} crumbs={[]} />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{title}</DashboardH3>
          </DashboardHeader>
        }
        tabs={tabs}
        content={<ShowPage level={level} setLevel={setLevel} />}
      />
    </>
  );
};

export default SkillLevelProfile;
