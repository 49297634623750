import React from "react";
import { Goal } from "./userTypes";
import CircularProgress from "../utils/CircularProgress";
import { GoalModal } from "./UserGoals";
import useModal from "../hooks/useModal";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import LoadingOverlay from "../utils/LoadingOverlay";
import TextButton from "../utils/TextButton";
import { Button } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";

const UserGoalItem = ({
  goal,
  goals,
  setGoals,
}: {
  goal: Goal;
  goals: Goal[];
  setGoals: (goals: Goal[]) => void;
}) => {
  const { toggle, modal } = useModal();

  const { takeAction, loading }: IUseApi<{}, { data: Goal }> = useApi();

  const deleteGoal = () => {
    return deleteSwal(goal.name)
      .then(() => takeAction("destroy", `/goals/${goal.uuid}`))
      .then(() => {
        toast.success("Goal deleted");
        setGoals(goals.filter((g) => g.uuid !== goal.uuid));
      })
      .catch(errorSwal);
  };

  const onSubmit: FormSubmitHandler<Goal> = (values) => {
    return takeAction("update", `/goals/${goal.uuid}`, values)
      .then(({ data }) => {
        toggle();
        toast.success("Goal Updated");
        setGoals(goals.map((g) => (g.uuid === goal.uuid ? data.data : g)));
      })
      .catch(formError);
  };

  return (
    <>
      <div className="p-3 border bg-white rounded-lg d-flex align-items-center w-100 position-relative">
        <LoadingOverlay loading={loading} />
        <TextButton onClick={toggle} className=" d-flex align-items-center">
          <div>
            <CircularProgress
              stroke={goal.percentage_complete === 100 ? "#00aa4f" : "#3b7eca"}
              size={75}
              value={goal.percentage_complete ?? 0}
              max={100}
            />
          </div>
          <div className="text-start ms-3">
            <p className="mb-0 text-dark tx-18">{goal.name}</p>
            <p className="mb-0 text-muted">{goal.description}</p>
          </div>
        </TextButton>
        <Button
          outline
          onClick={deleteGoal}
          color="danger"
          size="sm"
          className="ms-auto"
        >
          Delete
        </Button>
      </div>
      <GoalModal
        toggle={toggle}
        modal={modal}
        onSubmit={onSubmit}
        title={`Update ${goal.name}`}
        goal={goal}
      />
    </>
  );
};

export default UserGoalItem;
