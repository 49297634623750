import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const TrackingCategoryForm = (props) => {
  const { category, index, xero, handleSubmit, change } = props;

  const [xeroOptions, setXeroOptions] = useState([]);

  const formattedCategories = xero.map((x) => ({
    label: x.Name,
    value: x.TrackingCategoryID,
  }));

  const formattedXeroOptions = xeroOptions.map((x) => ({
    label: x.Name,
    value: x.TrackingOptionID,
  }));

  change("id", category.id);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormHeader>Category {index + 1}</FormHeader>
        <div className="col-5 form-group align-self-center">
          <p className="tx-inverse fw-bolder mb-0">{category.name}</p>
        </div>
        <div className="col-1 form-group align-self-center">
          <div className="col-1  d-flex justify-content-center align-items-center">
            <i className="icon ion-arrow-right-a tx-inverse fw-bolder tx-20" />
          </div>
        </div>
        <div className="col-6 form-group">
          <Field
            component={SelectInputAsync}
            required
            validate={required}
            options={formattedCategories}
            name="xero_id"
            label="Xero Category"
            onChangeValue={({ value }) => {
              const newOptions = xero.find(
                (x) => x.TrackingCategoryID === value,
              )?.Options;

              setXeroOptions(newOptions ?? []);
            }}
          />
          <Field component={RenderField} type="hidden" name="id" />
        </div>

        <FormHeader>Options</FormHeader>
        {category?.options?.map((option, i) => {
          change(`options.${i}.id`, option.id);

          return (
            <>
              <div className="col-5 form-group align-self-center">
                <p className="tx-inverse mb-0">{option.trackable.name}</p>
              </div>
              <div className="col-1 form-group align-self-center">
                <div className="col-1  d-flex justify-content-center align-items-center">
                  <i className="icon ion-arrow-right-a tx-inverse fw-bolder tx-20" />
                </div>
              </div>
              <div className="col-6 form-group">
                <Field
                  component={SelectInputAsync}
                  name={`options.${i}.xero_id`}
                  options={formattedXeroOptions}
                  label={`Xero ${option.trackable.name}`}
                />
                <Field
                  component={RenderField}
                  type="hidden"
                  name={`options.${i}.id`}
                />
              </div>
            </>
          );
        })}
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm();

export default form(TrackingCategoryForm);
