import { useQuery } from "react-query";
import api from "../../api/api";

const getTimesheets = (id, unpaid,viewLeave) => {
  return api
    .get(`timesheets?userId=${id}&filter[unpaid]=${unpaid}&filter[viewLeave]=${viewLeave}`)
    .then(({ data }) => data.data);
};

export default function useTimesheets(id, unpaid = true, viewLeave = true) {
  return useQuery(
    [
      "timesheets",
      {
        id,
        unpaid,
        viewLeave,
      },
    ],
    () => getTimesheets(id, unpaid,viewLeave),
  );
}
