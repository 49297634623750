import React from "react";
import { useParams } from "react-router-dom";
import {
  ChangeAction,
  FieldArray,
  initialize,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import RecurringJobDeliverableFields from "./RecurringJobDeliverableFields";
import { RecurringJob } from "./recurringJobTypes";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { Dispatch } from "redux";

export interface RecurringJobDeliverablesProps {
  setRecurringJob: (job: RecurringJob) => void;
  dispatch?: Dispatch;
  change?: ChangeAction;
}

const RecurringJobDeliverables = (
  props: RecurringJobDeliverablesProps &
    InjectedFormProps<{}, RecurringJobDeliverablesProps>,
) => {
  const { handleSubmit, setRecurringJob, initialValues, dispatch, change } =
    props;
  const { uuid } = useParams<{ uuid: string }>();
  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction("update", `recurring-jobs/${uuid}/deliverables`, values)
      .then(({ data }: { data: { data: RecurringJob } }) => {
        setRecurringJob(data.data);
        toast.success("Deliverables Updated");
        dispatch && dispatch(initialize("RecurringJobDeliverables", data.data));
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldArray
        component={RecurringJobDeliverableFields}
        name="deliverables"
        initialValues={initialValues}
        setRecurringJob={setRecurringJob}
        dispatch={dispatch}
        change={change}
      />
    </form>
  );
};

const form = reduxForm<{}, RecurringJobDeliverablesProps>({
  form: "RecurringJobDeliverables",
});

export default form(RecurringJobDeliverables);
