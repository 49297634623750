import React from "react";
import { Field } from "redux-form";

import renderField from "../utils/renderField";
import NumberField from "../utils/NumberField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const FormDivision = (props) => {
  return (
    <div className="row">
      <div className="col-lg-6 form-group">
        <Field
          name="name"
          type="text"
          component={renderField}
          required
          validate={required}
          label="Name"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          label="Manager"
          name="manager_id"
          component={SelectInputAsync}
          url="/users/staff"
          validate={required}
          required
        />
      </div>
      <div className="col-lg-12">
        <NumberField {...props} />
      </div>
    </div>
  );
};

export default FormDivision;
