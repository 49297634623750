const filterOption = (
  option: {
    data: {
      text: string;
    };
  },
  input: string,
) => {
  return !input || option.data.text.toLowerCase().includes(input.toLowerCase());
};

export default filterOption;
