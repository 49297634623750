import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { AiOutlineCaretDown, AiOutlineClockCircle } from "react-icons/ai";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { primary } from "../utils/Colours";

dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrBefore);

interface DateRangePickerProps {
  range: [Dayjs | undefined, Dayjs | undefined];
  setRange: (range: [Dayjs | undefined, Dayjs | undefined]) => void;
  className?: string;
}

const DateRangePicker = (props: DateRangePickerProps) => {
  const { range, setRange, className = "" } = props;

  const [startDate, setStartDate] = useState<Dayjs | undefined>(undefined);
  const [endDate, setEndDate] = useState<Dayjs | undefined>(undefined);
  const [enteredTo, setEnteredTo] = useState(range[1]);

  const buttons = [
    {
      text: "Fortnight",
      onClick: () => {
        setRange([
          dayjs().subtract(2, "weeks").startOf("weeks"),
          dayjs().subtract(1, "weeks").endOf("weeks"),
        ]);
        setEnteredTo(dayjs().subtract(1, "weeks").endOf("weeks"));
      },
    },
    {
      text: "Month",
      onClick: () => {
        setRange([
          dayjs().subtract(1, "month").startOf("month"),
          dayjs().subtract(1, "month").endOf("month"),
        ]);
        setEnteredTo(dayjs().subtract(1, "month").endOf("month"));
      },
    },
    {
      text: "Quarter",
      onClick: () => {
        setRange([
          dayjs().subtract(1, "quarter").startOf("quarter"),
          dayjs().subtract(1, "quarter").endOf("quarter"),
        ]);
        setEnteredTo(dayjs().subtract(1, "quarter").endOf("quarter"));
      },
    },
  ];

  return (
    <UncontrolledDropdown className={`ms-auto ${className}`}>
      <DropdownToggle
        style={{ height: "20px" }}
        className="p-0 "
        size="sm"
        color="link"
      >
        <div>
          <AiOutlineClockCircle className="tx-16" />
          <AiOutlineCaretDown />
        </div>
      </DropdownToggle>

      <DropdownMenu className="py-0">
        <style>{modifiersStyles}</style>
        <DayPicker
          className="Range"
          selected={[
            (startDate ? startDate.toDate() : range[0]?.toDate()) ?? new Date(),
            {
              from: startDate ? startDate.toDate() : range[0]?.toDate(),
              to: enteredTo?.toDate(),
            },
          ]}
          defaultMonth={range[0]?.toDate()}
          onDayMouseEnter={(day) => {
            if (startDate && !endDate) {
              setEnteredTo(dayjs(day));
            }
          }}
          onDayClick={(day) => {
            if ((!startDate && !endDate) || (startDate && endDate)) {
              setStartDate(dayjs(day));
              setEndDate(undefined);
              return;
            }
            if (
              startDate &&
              !endDate &&
              dayjs(startDate).isSameOrBefore(dayjs(day))
            ) {
              setEndDate(dayjs(day));
            } else {
              return;
            }
            setRange([startDate, dayjs(day)]);
          }}
        />
        <div className="mb-2 align-items-center px-2 d-flex">
          <small>Select Previous</small>
          <small className="mb-0 ms-auto">
            ({range[0] && range[0].format("DD/MM/YYYY")} -{" "}
            {enteredTo ? enteredTo.format("DD/MM/YYYY") : "N/A"})
          </small>
        </div>
        <div className="d-flex border-top bg-gray-100 rounded-bottom">
          {buttons.map((info, index) => {
            return (
              <Button
                key={index}
                color="link"
                className={`flex-fill ${
                  index !== buttons.length - 1 ? "border-end" : ""
                }`}
                size="sm"
                onClick={info.onClick}
              >
                {info.text}
              </Button>
            );
          })}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const modifiersStyles = `.DayPicker-Day--selected {
    color: white !important;
    background-color: ${primary} !important;
    border-radius:0  !important;
  }`;

//className={range ? "" : "text-secondary"}

export default DateRangePicker;
