import { Field, FieldArrayFieldsProps } from "redux-form";
import SelectInput from "../form/SelectInput";
import { Button } from "reactstrap";

interface RegisterFieldProps {
  field: string;
  registers: any[];
  form: string;
  fields: FieldArrayFieldsProps<{ register_id: number }>;
  index: number;
}

const RegisterFields = (props: RegisterFieldProps) => {
  const { field, registers, fields, index } = props;

  return (
    <>
      <div className="col-lg-10 form-group">
        <Field
          component={SelectInput}
          name={`${field}.register_id`}
          options={registers}
          label="Register"
        />
      </div>
      <div className="col-lg-2 d-flex align-self-end form-group">
        <Button
          color="danger"
          outline
          block
          onClick={() => fields.remove(index)}
        >
          Remove
        </Button>
      </div>
    </>
  );
};

export default RegisterFields;
