import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import { IHazardReportQuestion } from "./types";
import { toast } from "react-toastify";
import { SafetyInitiative } from "./safetyInitiative";

interface AddQuestionModalProps {
  safetyInitiative: SafetyInitiative;
  toggle: Function;
  modal: boolean;
  initialValues?: IHazardReportQuestion;
  questions?: IHazardReportQuestion[];
  setQuestions: (questions: IHazardReportQuestion[]) => void;
}

const AddQuestionModal = ({
  safetyInitiative,
  toggle,
  modal,
  initialValues,
  questions,
  setQuestions,
}: AddQuestionModalProps) => {
  const { takeAction }: IUseApi<{}, { data: IHazardReportQuestion }> = useApi();

  const onSubmit: FormSubmitHandler<IHazardReportQuestion> = (
    values,
    _,
    props,
  ) => {
    const updatedValues = {
      ...values,
      safety_initiative_id: safetyInitiative.id,
    };

    return takeAction("store", "/hazard-report-questions", updatedValues)
      .then(({ data }) => {
        setQuestions([...(questions ?? []), data.data]);
        toast.success("Question added.");
        toggle();
        props.reset?.();
      })
      .catch(formError);
  };

  return (
    <FormModal
      initialValues={initialValues}
      toggle={toggle}
      onSubmit={onSubmit}
      form="AddHazardReportQuestion"
      modal={modal}
      title="Add Take 5 Question"
    >
      <div className="col-12 form-group">
        <Field component={RenderField} name="name" label="Question" />
      </div>
    </FormModal>
  );
};

export default AddQuestionModal;
