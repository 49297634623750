import { useParams } from "react-router";
import { Link } from "react-router-dom";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DocsModal from "../utils/DocsModal";
import { IUseApiWithData } from "../api/apiTypes";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import { Badge, PopoverBody, UncontrolledPopover } from "reactstrap";
import TextButton from "../utils/TextButton";
import { IntegrationType } from "../integrations/integrationTypes";

const RequestedLeave = ({ user }: { user: { id: number; uuid: string } }) => {
  const { userId } = useParams<{ userId: string }>();

  const { data, loading } = useApi(
    `users/${userId}/requested-leave?sort=-created_at`,
    [],
    true,
  );
  const standardWorkHours = useOrganisationSetting("standard_work_hours");

  const {
    data: leaveBalances,
  }: IUseApiWithData<
    {
      leaveCategoryName: string;
      unitType: string;
      accruedAmount: number;
      leaveCategoryId: number;
    }[]
  > = useApi(`estimated-leave-balances/${user.uuid}`, []);

  const {
    data: leaveReasons,
  }: IUseApiWithData<
    {
      private: boolean;
      integration_models: {
        integration_type: IntegrationType;
        external_integration_id: string;
      }[];
    }[]
  > = useApi(
    "leave-reasons?include=integrationModels&filter[private]=false",
    [],
  );

  const columns = [
    {
      accessorKey: "period_string",
      header: "Date/s",
      cell: (info: any) => {
        const leave = info.row.original;

        return <Link to={leave.link}>{info.getValue()}</Link>;
      },
      sort: true,
    },
    {
      accessorKey: "reason.reason",
      header: "Leave Reason",
    },
    {
      accessorKey: "total_hours",
      header: "Total Hours Requested",
    },
    {
      accessorKey: "accepted_at",
      header: "Status",
      cell: (info: any) => {
        if (info.getValue()) {
          return (
            <Badge color="success" className="rounded-pill shadow-sm">
              Accepted
            </Badge>
          );
        }

        return (
          <Badge color="info" className="rounded-pill shadow-sm">
            Pending Approval
          </Badge>
        );
      },
    },
  ];

  const nonPrivateExternalIds = leaveReasons
    .filter((reason) => !reason.private)
    .flatMap((reason) =>
      reason.integration_models
        .filter((model) => model.integration_type === IntegrationType.Payroll)
        .map((model) => parseInt(model.external_integration_id)),
    );

  const leaveWithBalances = leaveBalances
    .filter((leave) => nonPrivateExternalIds.includes(leave.leaveCategoryId))
    .filter((leave) => leave.accruedAmount !== 0);

  return (
    <>
      <div className="row">
        {leaveWithBalances.map((leave, i) => {
          return (
            <div className="col-6">
              <div className="bg-white p-3 mb-3 rounded-lg shadow-sm border d-flex align-items-center">
                <div>
                  <p className="fs-2 mb-0 text-dark">
                    {leave.accruedAmount.toFixed(2)}
                  </p>
                  <TextButton
                    onClick={() => {}}
                    id={`hours_info_${i}`}
                    className="mb-0 text-secondary"
                  >
                    {leave.leaveCategoryName} ({leave.unitType})
                  </TextButton>
                  <UncontrolledPopover target={`hours_info_${i}`}>
                    <PopoverBody>
                      <p className="mb-0">
                        {(
                          leave.accruedAmount /
                          (Number(standardWorkHours?.meta_value) ?? 7.6)
                        ).toFixed(2)}{" "}
                        Days
                      </p>
                      <p className="mb-0">
                        {(
                          leave.accruedAmount /
                          ((Number(standardWorkHours?.meta_value) ?? 7.6) * 5)
                        ).toFixed(2)}{" "}
                        Weeks
                      </p>
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <ReactTable
        extraButtons={
          <Link
            className="btn btn-outline-primary btn-sm ms-1"
            to={`/users/${user.id}/leave/request`}
          >
            Request Leave
          </Link>
        }
        columns={columns}
        data={data ?? []}
        loading={loading}
      />
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/timesheet-tracking/leave/" />
    </>
  );
};

export default RequestedLeave;
