import _ from "lodash";

const StyledCheck = (props) => {
  const {
    options,
    button,
    input: { value, onChange },
    label,
  } = props;

  return (
    <>
      {label}

      {options.map((option) => {
        return button({
          ...option,
          onClick: () => onChange(_.xor(value ?? [], [option.value])),
          active: value?.includes(option.value),
        });
      })}
    </>
  );
};

export default StyledCheck;
