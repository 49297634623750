import { Fragment, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import { filters, FilterType } from "./filters";
export type UrlValues =
  | "/custom-fields/projects"
  | "/custom-fields/jobs"
  | "/custom-fields/clients"
  | "/custom-fields/suppliers"
  | "/custom-fields/equipment"
  | "/custom-fields/users"
  | "/custom-fields/branches"
  | "/custom-fields/positions"
  | "/custom-fields/tracking-sites"
  | "/custom-fields/divisions"
  | string;

const CustomFieldFilter = ({
  url,
  fields,
  getExtraFilters,
}: WrappedFieldArrayProps & {
  url: UrlValues;
  getExtraFilters?: (url: UrlValues) => Promise<FilterType[]>;
}) => {
  const [extraFilters, setExtraFilters] = useState<FilterType[]>([]);

  useEffect(() => {
    if (getExtraFilters) {
      getExtraFilters(url).then((filters) => {
        setExtraFilters(filters);
      });
    }
  }, [url]);

  let filterValues = filters(url);

  if (extraFilters) {
    filterValues = [...filterValues, ...extraFilters];
  }

  if (filterValues.length === 0) {
    return null;
  }

  return (
    <>
      <FormHeader>Filters</FormHeader>
      {fields.map((field, index) => {
        const value = fields.get(index);

        const selectedFilter = filterValues.find(
          (val) => val.name === value.key,
        );

        const hasSign = selectedFilter?.signOptions;

        const hasOptions =
          (selectedFilter?.options?.length &&
            selectedFilter?.options?.length > 0) ||
          selectedFilter?.url;

        return (
          <Fragment key={index}>
            <div
              className={`${
                !hasOptions ? "col-lg-10" : hasSign ? "col-lg-3" : "col-lg-5"
              } form-group`}
            >
              <Field
                label="Key"
                component={SelectInput}
                options={filterValues.map((value) => ({
                  label: value.label,
                  value: value.name,
                }))}
                name={`${field}.key`}
              />
            </div>
            {hasSign && (
              <div className="col-lg-3 form-group">
                <Field
                  label="Is not"
                  component={SelectInput}
                  options={[
                    {
                      label: "Is Not",
                      value: "!",
                    },
                  ]}
                  name={`${field}.sign`}
                />
              </div>
            )}
            {hasOptions && (
              <div
                className={`${hasSign ? "col-lg-3" : "col-lg-5"} form-group`}
              >
                <Field
                  label="Value"
                  component={SelectInput}
                  options={selectedFilter?.options ?? []}
                  url={selectedFilter?.url ?? null}
                  name={`${field}.value`}
                  formatData={selectedFilter?.formatData ?? null}
                />
              </div>
            )}
            <div className="col-2 form-group d-flex align-items-end">
              <Button
                onClick={() => fields.remove(index)}
                color="danger"
                block
                outline
              >
                Delete
              </Button>
            </div>
          </Fragment>
        );
      })}
      <div className="col-12">
        <Button outline onClick={() => fields.push({})}>
          Add Filter
        </Button>
      </div>
    </>
  );
};

export default CustomFieldFilter;
