import { useEffect, useState } from "react";
import dayjs from "dayjs";
import ReactTable from "../table/ReactTable";
import axios from "../api/api";

const DetailsTable = (props) => {
  const {
    columns,
    from,
    to,
    selected,
    title,
    url,
    stringifiedFilters = "",
    getRowCanExpand,
    renderSubComponent,
    ignoreDates = false,
    setTableInstance,
    enableColumnToggle = false,
  } = props;

  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let query = {
      model: selected.model,
      modelId: selected.value,
    };

    if (!ignoreDates) {
      query = {
        ...query,
        startDate: dayjs(from).format("YYYY-MM-DD"),
        endDate: dayjs(to).format("YYYY-MM-DD"),
      };
    }

    const stringifiedFilters = new URLSearchParams(query).toString();

    axios.get(`/${url}?${stringifiedFilters}`).then(({ data }) => {
      setLoading(false);
      return setDetails(data);
    });
  }, [stringifiedFilters]);

  return (
    <ReactTable
      enableCsvExport
      enableMultiSelect
      getRowCanExpand={getRowCanExpand}
      renderSubComponent={renderSubComponent}
      CsvFileName={title}
      columns={columns}
      data={details}
      loading={loading}
      enableColumnToggle={enableColumnToggle}
      getTableInstance={setTableInstance ?? (() => {})}
    />
  );
};

export default DetailsTable;
