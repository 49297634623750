import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import FormHeader from "../utils/FormHeader";
import required from "../utils/required";
import CustomFields from "../customFields/CustomFields";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import { DocketType } from "./docketTypeTypes";

interface DocketTypeFormProps {
  showFields?: boolean;
  initialValues?: any;
}

const DocketTypeForm = (
  props: InjectedFormProps<DocketType, DocketTypeFormProps> &
    DocketTypeFormProps,
) => {
  const { handleSubmit, showFields = false } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="name"
            type="text"
            label="Name"
            validate={required}
            required
          />
        </div>
        {showFields && (
          <>
            <FormHeader>Docket Type Form</FormHeader>
            <div className="col-12">
              <CustomFields {...props} />
            </div>
          </>
        )}
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<DocketType, DocketTypeFormProps>({});

export default form(DocketTypeForm);
