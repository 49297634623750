import React from "react";
import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import BrandingThemeForm, { BrandingThemeFormProps } from "./BrandingThemeForm";
import { BrandingTheme, BrandingThemeProfileProps } from "./brandingThemeTypes";
import { toast } from "react-toastify";

const EditBrandingTheme = (props: BrandingThemeProfileProps) => {
  const { setBrandingTheme } = props;

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi<{}, { data: BrandingTheme }> = useApi();

  const onSubmit: FormSubmitHandler<BrandingTheme, BrandingThemeFormProps> = (
    values,
  ) => {
    return takeAction("update", `branding-themes/${uuid}`, values)
      .then(({ data }) => {
        setBrandingTheme(data.data);
        toast.success(`${data.data.name} updated successfully`);
      })
      .catch(formError);
  };

  return (
    <BrandingThemeForm
      form="EditBrandingTheme"
      initialValues={props.brandingTheme}
      onSubmit={onSubmit}
    />
  );
};

export default EditBrandingTheme;
