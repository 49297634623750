import Editor from "@monaco-editor/react";
import { WrappedFieldProps } from "redux-form";

const RenderMonaco = (
  props: WrappedFieldProps & { label: string; required?: boolean },
) => {
  const { label, required } = props;

  return (
    <>
      <label className="form-control-label tx-inverse tx-semibold">
        {label}
        {required ? <span className="tx-danger"> *</span> : ""}
      </label>
      <Editor
        {...props.input}
        height="40vh"
        language="javascript"
        defaultValue={props.input.value}
      />
    </>
  );
};

export default RenderMonaco;
