import { useEffect, useState } from "react";
import Editor, { useMonaco } from "@monaco-editor/react";
import FieldType from "../../enums/FieldType";

const FormulaField = (props) => {
  const {
    required,
    label,
    fieldClass,
    fields,
    vals,
    codeSuggestions,
    setCodeSuggestions,
  } = props;
  const [randomId, setRandomId] = useState();
  const [isCode, setIsCode] = useState();
  const monaco = useMonaco();

  useEffect(() => {
    const formulaType = vals.field_attributes?.formula_type;

    if (!formulaType) {
      return;
    }

    if (formulaType === "math") {
      !randomId && setRandomId(Math.random().toString(36).substring(7));
      setIsCode(false);
      return;
    }

    setIsCode(true);
  }, [vals.field_attributes?.formula_type, isCode]);

  useEffect(() => {
    let disposable = null;
    let isFirstJsEditor = false;

    if (fields.length && monaco && (isCode || randomId)) {
      randomId &&
        !monaco.languages.getEncodedLanguageId(randomId) &&
        monaco.languages.register({ id: randomId });

      const existingLabels = fields
        .filter(
          (field) =>
            field?.field_attributes?.label &&
            codeSuggestions.includes(field?.field_attributes?.label),
        )
        .map((field) => field?.field_attributes?.label);
      const newLabels = fields
        .filter(
          (field) =>
            field?.field_attributes?.label &&
            [FieldType.Text, FieldType.Formula].includes(
              parseInt(field?.type),
            ) &&
            !existingLabels.includes(field?.field_attributes?.label),
        )
        .map((field) => field?.field_attributes?.label);

      const updatedLabels = existingLabels.concat(newLabels);

      if (isCode) {
        setCodeSuggestions(updatedLabels);

        loopForFirstEditor: for (let field of fields) {
          if (field?.field_attributes?.formula_type === "code") {
            if (
              field?.field_attributes?.label === vals?.field_attributes?.label
            ) {
              isFirstJsEditor = true;
            }

            break;
          } else if (parseInt(field?.type) === FieldType.Chart) {
            const dataseries = field?.field_attributes?.data_series;

            for (let data of dataseries) {
              if (data?.type === "formula") {
                break loopForFirstEditor;
              }
            }
          }
        }
      }

      const addLabels = isCode
        ? isFirstJsEditor
          ? updatedLabels
          : newLabels
        : updatedLabels;

      disposable = monaco.languages.registerCompletionItemProvider(
        isCode ? "javascript" : randomId,
        {
          provideCompletionItems: (model, position, token) => {
            const prepLabels = isCode
              ? addLabels
              : addLabels.filter(
                  (label) => label !== vals?.field_attributes?.label,
                );
            const result = prepLabels.map((label) => {
              const variable = `$`.concat(
                `{${label
                  ?.trim()
                  .replace(/{|}/g, "")
                  .replace(/\s+/g, "-")
                  .toLowerCase()}}`,
              );
              return {
                insertText: `${variable}`,
                kind: 4,
                label: `label: ${label}`,
                detail: `variable: ${variable}`,
              };
            });
            return {
              suggestions: result,
            };
          },
        },
      );
    }

    return () => disposable?.dispose();
  }, [monaco, fields]);

  return (
    <>
      <label className="form-control-label tx-inverse tx-semibold">
        {label}
        {required ? <span className="tx-danger"> *</span> : ""}
      </label>
      {(isCode || randomId) && (
        <div
          className={
            fieldClass ??
            "bg-white p-2 form-control-border shadow-sm rounded-lg"
          }
        >
          <Editor
            {...props.input}
            height="40vh"
            language={isCode ? "javascript" : randomId}
          />
        </div>
      )}
    </>
  );
};

export default FormulaField;
