import dayjs from "dayjs";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FieldArray, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import EquipmentRates from "./EquipmentRates";
import { IProjectEquipment } from "./ProjectEquipment";

interface CustomFieldEquipment {
  name: string;
  id: number;
}

interface AddProjectEquipmentRatesModalProps {
  toggle: () => void;
  modal: boolean;
  equipment: IProjectEquipment[];
}

const AddProjectEquipmentRatesModal = (
  props: AddProjectEquipmentRatesModalProps,
) => {
  const { toggle, modal, equipment } = props;
  const queryClient = useQueryClient();

  const { data: organisationEquipment } = useApi(
    `/custom-fields/equipment`,
    [],
    true,
  );

  const { number } = useParams<{ number: string }>();

  const initialValues = equipment.map((e) => ({
    equipment_id: e.id,
    rate: e.rate,
    date: dayjs().format("YYYY-MM-DD"),
  }));

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler = (values) => {
    return takeAction("store", `projects/${number}/equipment-rates`, values)
      .then(() => {
        toast.success("Equipment rates added");
        queryClient.invalidateQueries(`project-equipment-rates/${number}`);
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      form="AddEquipmentRates"
      title="Add Equipment Rates"
      toggle={toggle}
      modal={modal}
      initialValues={{ rates: initialValues }}
      onSubmit={onSubmit}
    >
      <FieldArray
        name="rates"
        component={EquipmentRates}
        equipment={organisationEquipment.map((e: CustomFieldEquipment) => ({
          label: e.name,
          value: e.id,
        }))}
      />
    </FormModal>
  );
};

export default AddProjectEquipmentRatesModal;
