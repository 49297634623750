import {
  NEW_STAFF_SCHEDULE,
  UPDATE_STAFF_SCHEDULE,
  DELETE_STAFF_SCHEDULE,
} from "../actions/types";

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  const items = Object.assign([], state.items);
  switch (action.type) {
    case NEW_STAFF_SCHEDULE:
      items.push(action.payload);
      return {
        ...state,
        items,
      };
    case UPDATE_STAFF_SCHEDULE:
      return {
        ...state,
        items: items.map((item) =>
          item.uuid === action.payload.uuid ? action.payload : item,
        ),
      };
    case DELETE_STAFF_SCHEDULE:
      return {
        ...state,
        items: items.reduce((newItems, item) => {
          if (item.uuid !== action.payload.uuid) newItems.push(item);
          return newItems;
        }, []),
      };
    default:
      return state;
  }
}
