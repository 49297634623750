import deleteSwal from "../utils/deleteSwal";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { useHistory } from "react-router-dom";

const DeleteLeaveRequestButton = ({ requestedLeave }) => {
  const { takeAction, loading } = useApi();
  const history = useHistory();
  const { user } = useAuth();

  const deleteLeaveRequest = () => {
    return deleteSwal(
      "Leave Request",
      requestedLeave.timesheets.length > 0
        ? `This leave request has ${requestedLeave.timesheets.length} timesheets associated with it. Are you sure you want to delete it?`
        : null,
    ).then(() => {
      takeAction("destroy", `requested-leave/${requestedLeave.uuid}`)
        .then(() => {
          history.push(`/users/${user.uuid}/requested-leave`);
          toast.success("Requested leave deleted.");
        })
        .catch(errorSwal);
    });
  };

  return (
    <button
      onClick={deleteLeaveRequest}
      type="button"
      className="btn w-100 btn-outline-danger mt-3"
      disabled={loading}
    >
      {isSubmitting(loading, "Delete Leave Request", "Deleting...")}
    </button>
  );
};

export default DeleteLeaveRequestButton;
