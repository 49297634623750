import useModal from "../hooks/useModal";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { IPriceBand } from "./IPriceBand";
import PriceBandTable from "./PriceBandTable";
import ProfileButtons from "../utils/ProfileButtons";
import PriceBandsFormModal from "./PriceBandsFormModal";
import PriceBandModal from "./PriceBandModal";
import { useState } from "react";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import deleteSwal from "../utils/deleteSwal";

const PriceBandsList = (props: any) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { toggle, modal } = useModal();
  const [ priceBand, setPriceBand ] = useState<IPriceBand | null>(null);

  const {
    takeAction,
    data: priceBands,
    setData: setPriceBands,
    loading,
  } = useApi(`project-forms/${uuid}/price-bands`, []);

  const buttons = [
    {
      onClick: () => {
        setPriceBand(null);
        toggle();
      },
      text: (
        <>
          <i className="icon ion-compose tx-primary" /> Create a Price Band
        </>
      ),
    },
  ];

  const handleRowClick = (row: IPriceBand) => {
    setPriceBand(row);
    toggle();
  };

  const handleDeleteRow = (row: IPriceBand) => {
    deleteSwal(`${row.lower_limit} - ${row.upper_limit}`)
      .then(() => takeAction("destroy", `/price-bands/${row.uuid}`))
      .then(({ data }) => {
        const updatedPriceBands = priceBands.filter((item : IPriceBand) => item.uuid !== row.uuid);
        setPriceBands(updatedPriceBands);
      })
      .catch(errorSwal);
  };

  const onSubmit = async (values: IPriceBand) => {
    try {
      const action = values.uuid ? "update" : "store";
      const url = values.uuid ? `price-bands/${values.uuid}` : `project-forms/${uuid}/price-bands`;
  
      const { data } = await takeAction(action, url, values);
  
      const indexOfItemToUpdate = priceBands.findIndex((item: IPriceBand) => item.uuid === data.data.uuid);
      const updatedPriceBands = [...priceBands];
  
      if (indexOfItemToUpdate !== -1) {
        updatedPriceBands[indexOfItemToUpdate] = data.data;
      } else {
        updatedPriceBands.unshift(data.data);
      }
  
      setPriceBands(updatedPriceBands);
      toast.success("Fields Updated");
      toggle();
    } catch (error) {
      errorSwal(error);
    }
  };

  return (
    <>
      <ProfileButtons buttons={buttons} />
      <PriceBandModal
        content={(toggle: () => void) => (
          <PriceBandsFormModal
            toggle={toggle}
            modal={modal}
            title="Edit"
            form="EditPriceBands"
            initialValues={priceBand}
            onSubmit={onSubmit}
          />
        )}
        toggle={toggle}
      />
      <PriceBandTable
        handleDeleteRow={handleDeleteRow}
        handleRowClick={handleRowClick}
        priceBands={priceBands}
      />
    </>
  );
};

export default PriceBandsList;
