import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";

export interface Metric {
  link: string;
  name: string;
  id: number;
  days_to_complete?: number;
  days_worked?: number;
  completed_at?: string;
  expected_completed_date: string;
}

const ComplianceMetricTable = ({ metric }: { metric: Metric[] }) => {
  const columns = [
    {
      accessorKey: "link",
      header: "Name",
      cell: (info: any) => {
        const metric = info.row.original;
        return <Link to={metric.link}>{metric.name}</Link>;
      },
    },
    {
      accessorKey: "expected_completed_date",
      header: "Expected to be completed",
      cell: (info: any) => {
        return dayjs(info.getValue()).format("DD/MM/YYYY");
      },
    },
    {
      accessorKey: "completed_at",
      header: "Completed At",
      cell: (info: any) => {
        const CompletedAt = info.getValue();
        if (!CompletedAt) {
          return "Incomplete";
        }

        return dayjs(CompletedAt).format("DD/MM/YYYY");
      },
    },
    {
      accessorKey: "days_to_complete",
      header: "Days To Complete",
      cell: (info: any) => {
        const metric = info.row.original;
        if (!metric.completed_at) {
          return "Incomplete";
        }
        return (
          <div>
            <p className="tx-inverse fw-bolder mb-0">{info.getValue()}</p>
            <p className="mb-0 tx-12 tx-secondary">
              Worked {metric.days_worked} days
            </p>
          </div>
        );
      },
    },
  ];

  return <ReactTable data={metric} columns={columns} />;
};

export default ComplianceMetricTable;
