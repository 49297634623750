import { useEffect, useState } from "react";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import Empty from "./Empty";
import List from "./List";
import RecurringJobFormModal from "./RecurringJobFormModal";
import { useParams } from "react-router-dom";
import formError from "../utils/formError";

const RecurringJobs = ({ project }) => {
  const { takeAction, loading } = useApi();
  const [selectedRecurringJob, setSelectedRecurringJob] = useState({});
  const [recurringJobs, setRecurringJobs] = useState([]);
  const { toggle, modal } = useModal();

  const { number } = useParams();

  useEffect(() => {
    takeAction("index", `projects/${number}/recurring-jobs`)
      .then(({ data }) => {
        setRecurringJobs(data.data);
      })
      .catch(errorSwal);
  }, []);

  const store = (values) => {
    const data = {
      ...values,
      project_id: project.id,
    };

    return takeAction("store", "recurring-jobs", data)
      .then(({ data }) => {
        setRecurringJobs([...recurringJobs, data.data]);
        setSelectedRecurringJob({});
        toggle();
      })
      .catch(formError);
  };

  return (
    <>
      {!loading && recurringJobs.length === 0 ? (
        <Empty toggle={toggle} project={project} />
      ) : (
        <>
          {!loading && (
            <div className="flex">
              <button
                type="button"
                onClick={() => {
                  setSelectedRecurringJob({});
                  toggle();
                }}
                className="btn btn-outline-primary mb-3"
              >
                Add Recurring Job
              </button>
            </div>
          )}
          <List
            recurringJobs={recurringJobs}
            setSelectedRecurringJob={setSelectedRecurringJob}
            toggle={toggle}
            modal={modal}
            setRecurringJobs={setRecurringJobs}
            loading={loading}
          />
        </>
      )}
      <RecurringJobFormModal
        modal={modal}
        toggle={toggle}
        onSubmit={store}
        initialValues={selectedRecurringJob}
        project={project}
      />
    </>
  );
};

export default RecurringJobs;
