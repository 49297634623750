import _ from "lodash";
import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Group } from "./managementDashboardTypes";

const ManagementDashboardGroups = ({
  selectedGroup,
  setSelectedGroup,
  data,
}: {
  selectedGroup: Group | undefined;
  setSelectedGroup: Function;
  data?: Group[];
}) => {
  const groups = Object.values(_.groupBy(data, "type"));

  return (
    <UncontrolledDropdown className="ms-3">
      <DropdownToggle
        color="primary"
        outline={!selectedGroup}
        caret
        className="p-1"
      >
        {selectedGroup ? selectedGroup.name : "Select a Group"}
      </DropdownToggle>
      <DropdownMenu>
        {groups.map((groupedGroup: Group[]) => {
          return (
            <React.Fragment key={groupedGroup[0].type_header}>
              <DropdownItem header>{groupedGroup[0].type_header}</DropdownItem>
              {_.sortBy(groupedGroup, "name").map((group) => {
                return (
                  <DropdownItem
                    onClick={() => setSelectedGroup(group)}
                    key={group.uuid}
                  >
                    {group.name}{" "}
                    {group.uuid === selectedGroup?.uuid ? (
                      <AiOutlineCheck className="ms-1 tx-success" />
                    ) : null}
                  </DropdownItem>
                );
              })}
            </React.Fragment>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ManagementDashboardGroups;
