import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";

const ContactForm = (props) => {
  const { client, xeroContacts, handleSubmit, setData, moveForward, contacts } =
    props;
  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("store", `xero/clients/contacts`, {
      ...values,
      organisation_contact: client.uuid,
    }).then(({ data }) => {
      setData({
        xero: xeroContacts,
        organisation: contacts.map((c) =>
          c.uuid === data.data.uuid ? data.data : c,
        ),
      });
      toast.success(`${client.name} synced.`);
      moveForward();
    });
  };

  const options = xeroContacts?.map((contact) => ({
    label: contact.Name,
    value: contact.ContactID,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-5  d-flex align-items-center">
          <div>
            <p className="mb-0 fw-bolder tx-inverse ">{client?.name}</p>
            <p className="mb-0">
              {client?.is_supplier ? "Supplier" : "Client"}
            </p>
          </div>
        </div>
        <div className="col-1  d-flex justify-content-center align-items-center">
          <i className="icon ion-arrow-right-a tx-inverse fw-bolder tx-20" />
        </div>
        <div className="col-5 form-group">
          <Field
            component={SelectInputAsync}
            name="xero_contact"
            label="Xero Contact"
            options={options}
            required
            validate={required}
          />
        </div>
        <div className="col-1  d-flex justify-content-center align-items-center">
          <SubmitButton {...props} save="Sync" saving="Syncing..." />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({
  enableReinitialize: true,
});

const mapStateToProps = (state, { xeroContacts, client }) => {
  const suggestedContact = xeroContacts?.find((contact) => {
    return contact.Name?.toLowerCase() === client.name?.toLowerCase();
  });

  return {
    initialValues: {
      xero_contact: suggestedContact?.ContactID,
    },
  };
};

export default connect(mapStateToProps, {})(form(ContactForm));
