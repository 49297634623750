import React from "react";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import formError from "../../utils/formError";
import FormModal from "../../utils/FormModal";
import { toast } from "react-toastify";

interface MarkAsUnderstoodModalProps {
  toggle: Function;
  modal: boolean;
  understandableId: number;
  understandableType: string;
  onComplete: Function;
}

const MarkAsUnderstoodModal = (props: MarkAsUnderstoodModalProps) => {
  const { toggle, onComplete, understandableId, understandableType, modal } =
    props;

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler = (values) => {
    return takeAction("store", "understandings", {
      understandable_type: understandableType,
      understandable_id: understandableId,
    })
      .then(({ data }) => {
        toast.success("Marked as 'understood'");
        toggle();
        onComplete(data.data);
      })
      .catch(formError);
  };

  return (
    <FormModal
      onSubmit={onSubmit}
      title="Mark as Understood?"
      save="I Understand"
      form="UNDERSTOOD_MODAL"
      toggle={toggle}
      modal={modal}
    >
      <div className="col-12">
        <p className="mb-0">
          By clicking 'I Understand', you confirm that you have read and
          understood all information displayed on this webpage.
        </p>
      </div>
    </FormModal>
  );
};

export default MarkAsUnderstoodModal;
