import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import ProfileButtons from "../utils/ProfileButtons";
import useModal from "../hooks/useModal";
import AddModal from "./AddModal";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import DeleteButton from "../utils/DeleteButton";
import { toast } from "react-toastify";

const EquipmentCalibrationTypes = (props) => {
  const { equipmentType, setEquipmentType } = props;
  const { toggle, modal } = useModal();
  const { takeAction, loading } = useApi();

  const promptDelete = (calibration) => {
    deleteSwal(calibration.procedures).then(() => {
      takeAction("destroy", `equipment-calibration-types/${calibration.uuid}`)
        .then(() => {
          toast.success("Calibration removed");
          setEquipmentType({
            ...equipmentType,
            calibration_types: equipmentType.calibration_types.filter(
              (c) => c.uuid !== calibration.uuid,
            ),
          });
        })
        .catch(errorSwal);
    });
  };

  const columns = [
    {
      accessorKey: "procedures",
      header: "Calibration",
      cell: (info) => {
        return (
          <Link
            to={`/equipment-calibration-types/${info.row.original.uuid}/details`}
          >
            {info.getValue()}
          </Link>
        );
      },
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => {
        return (
          <DeleteButton
            deleteFunction={() => promptDelete(info.row.original)}
          />
        );
      },
    },
  ];

  const buttons = [
    {
      text: "Add new Calibration Type",
      onClick: toggle,
    },
  ];

  return (
    <>
      <ProfileButtons buttons={buttons} />
      <ReactTable
        columns={columns}
        data={equipmentType.calibration_types}
        loading={loading}
      />

      <AddModal toggle={toggle} modal={modal} {...props} />
    </>
  );
};

export default EquipmentCalibrationTypes;
