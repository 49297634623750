import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import { deletePricing } from "../../actions/deliverableActions";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";

class PricingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: {},
    };
  }

  deletePricing = (index) => {
    const { initialValues, deletePricing, fields } = this.props;
    const { loading } = this.state;
    let pricing;
    pricing = initialValues.pricing[index];
    if (pricing) {
      deleteSwal().then(() => {
        const isLoading = loading;
        isLoading[index] = true;
        this.setState({
          loading: isLoading,
        });
        return deletePricing(pricing.uuid)
          .then(() => {
            toast.success("Deleted");
            fields.remove(index);
          })
          .catch((err) => {
            errorSwal(`Sorry, we were unable to delete this price \n ${err}`);
          })
          .then(() => {
            isLoading[index] = false;
            this.setState({
              loading: isLoading,
            });
          });
      });
    } else {
      fields.remove(index);
    }
  };

  render() {
    const { fields, initialValues } = this.props;
    const { loading } = this.state;
    return (
      <div className="row">
        {fields.map((pricing, index) => (
          <Fragment key={index}>
            <div className="form-group col-lg-3">
              {initialValues && (
                <Field
                  name={`${pricing}.uuid`}
                  component={renderField}
                  type="hidden"
                />
              )}
              <Field
                name={`${pricing}.item`}
                component={renderField}
                placeholder="Item"
              />
            </div>
            <div className="form-group col-lg-3">
              <Field
                name={`${pricing}.price`}
                component={renderField}
                placeholder="Price"
                type="number"
              />
            </div>
            <div className="form-group col-lg-3">
              <Field
                name={`${pricing}.quantity`}
                component={renderField}
                placeholder="Quantity"
                type="number"
                extraProps={{ step: "0.001" }}
              />
            </div>
            <div className="form-group col-lg-3">
              <Field
                name={`${pricing}.units`}
                component={renderField}
                type="text"
                placeholder="Units"
              />
            </div>
            <div className="form-group col-lg-12">
              <button
                type="button"
                onClick={() => this.deletePricing(index)}
                className="btn btn-outline-danger"
                disabled={loading[index]}
              >
                {loading[index] ? "Deleting..." : "Remove"}
              </button>
            </div>
          </Fragment>
        ))}
        <div className="col-lg-12 d-flex ms-auto align-items-start">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => fields.push()}
          >
            Add Pricing
          </button>
        </div>
      </div>
    );
  }
}

export default connect(null, { deletePricing })(PricingForm);
