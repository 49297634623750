import { useParams } from "react-router-dom";
import JobTable from "../../jobs/JobTable";
import PaginatedList from "../../pagination/PaginatedList";
import useProjectForms from "../hooks/useProjectForms";

const ProjectProjectFormsTable = () => {
  const { number } = useParams();

  return (
    <>
      <PaginatedList
        indexHook={useProjectForms}
        indexHookArguments={[number]}
        list={({ data }) => (
          <div className="col-12">
            {data?.length > 0 && (
              <JobTable
                jobs={data}
                showEstimate={false}
              />
            )}
          </div>
        )}
      />
    </>
  );
};

export default ProjectProjectFormsTable;
