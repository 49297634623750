import React from "react";
import { Badge } from "reactstrap";
import dayjs from "dayjs";

const Badges = ({ badges }) => {
  if (!badges || badges.length === 0) return null;
  return (
    <>
      {badges.map((badge, index) => {
        if (badge.date) {
          return dayjs(badge.date) < dayjs() ? (
            <Badge
              key={index}
              color="danger"
              className={index !== 0 ? "ms-1" : ""}
            >
              {badge.label}
            </Badge>
          ) : null;
        }
        return (
          <Badge
            key={index}
            color={badge.color}
            className={badges.length !== index + 1 ? "me-1" : ""}
          >
            {badge.label}
          </Badge>
        );
      })}
    </>
  );
};

export default Badges;
