const HOW_CLIENTS_FOUND = [
  { label: "Online", value: "Online" },
  { label: "Word of Mouth", value: "Word of Mouth" },
  { label: "Person", value: "Person" },
  { label: "Other", value: "Other" },
  { label: "Referral", value: "Referral" },
  { label: "Unsure", value: "Unsure" },
];

export default HOW_CLIENTS_FOUND;
