import React from "react";
import { Button } from "reactstrap";
import { WrappedFieldArrayProps } from "redux-form";
import FormHeader from "../utils/FormHeader";
import { CustomField, IDataSeries } from "./customFieldTypes";
import DataSeriesType from "./DataSeriesType";

const DataSeries = ({
  vals,
  fields,
  meta,
  customFields,
  jsEditor,
  codeSuggestions,
  setCodeSuggestions,
  parentIndex,
}: WrappedFieldArrayProps<IDataSeries> & {
  customFields: CustomField[];
  jsEditor: any;
  parentIndex: number;
  codeSuggestions: any;
  setCodeSuggestions: any;
  vals: any;
}) => {
  return (
    <>
      <FormHeader>Data Series</FormHeader>
      {fields.map((field, i) => {
        const currentValue = fields.get(i);

        return (
          <React.Fragment key={i}>
            <DataSeriesType
              fields={customFields}
              field={field}
              index={i}
              currentValue={currentValue}
              parentIndex={parentIndex}
              vals={vals}
              isFirstJsEditor={
                jsEditor.isFirstJsEditor &&
                jsEditor.isFirstJsEditorFieldIndex === i
              }
              codeSuggestions={codeSuggestions}
              setCodeSuggestions={setCodeSuggestions}
            />
            <div className="col-lg-4 form-group">
              <Button color="danger" outline onClick={() => fields.remove(i)}>
                Delete Data Series
              </Button>
            </div>
          </React.Fragment>
        );
      })}
      <div className="col-lg-4">
        <Button color="primary" outline onClick={() => fields.push({})}>
          Add Data Series
        </Button>
      </div>
    </>
  );
};

export default DataSeries;
