import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Form from "./Form";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import HeaderPage from "../header/HeaderPage";
import ReactTable from "../table/ReactTable";
import { Supplier } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const List = () => {
  const { data: supplierTypes, setData, takeAction, loading } = useApi(
    "supplier-types",
    [],
  );

  const { toggle, modal } = useModal();

  const onSubmit = (values, dispatch) => {
    return takeAction("store", "supplier-types", values)
      .then(({ data }) => {
        setData([...supplierTypes, data.data]);
        toggle();
        dispatch(reset("AddSupplierType"));
        toast.success(`${values.name} added.`);
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: info => (
        <Link to={`supplier-types/${info.row.original.uuid}/details`}>
          {info.getValue()}
        </Link>
      ),
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/supplier-types/" />
      <HeaderPage
        titlePage="Supplier Types"
        pageDescription={
          <>
            The correct classification of suppliers can have an impact on the
            operational efficiency and the quality of products and services
            provided by businesses.
          </>
        }
        relatedLinks={[
          { name: "Suppliers", link: "/suppliers", model: Supplier },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "", name: "Supplier Types", active: true },
        ]}
      />
      <div className="d-flex mb-3">
        <button
          type="button"
          onClick={toggle}
          className="btn btn-outline-primary"
        >
          Add New Supplier Type
        </button>
      </div>
      <ReactTable
        data={_.sortBy(supplierTypes, "name")}
        columns={columns}
        loading={loading}
      />
      <Form
        onSubmit={onSubmit}
        title="Add new Supplier Type"
        form="AddSupplierType"
        toggle={toggle}
        modal={modal}
      />
    </>
  );
};

export default List;
