import { useMemo } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UncontrolledTooltip } from "reactstrap";
import fileDownload from "js-file-download";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import confirm from "../utils/confirm";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { ClientIndex, InvoiceTerms } from "./clientTypes";
import useClients from "./hooks/useClients";
import { Project } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

dayjs.extend(localizedFormat);

const Clients = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/clients/" />
      <HeaderPage
        titlePage="Clients"
        pageDescription={
          <>
            Create and manage client profiles and stay up-to-date on client
            interactions.
          </>
        }
        relatedLinks={[{ name: "Projects", link: "/projects", model: Project }]}
        crumbs={[
          {
            link: "organisations/settings/links",
            name: "Organisation",
          },
          { active: true, name: "Clients" },
        ]}
      />
      <div className="d-flex align-items-center">
        <Link to="/clients/add" className="d-flex align-items-center">
          <AiOutlinePlus className="tx-primary tx-18 fw-bold me-1" /> Add New
          Client
        </Link>
        <DownloadData />
      </div>
      <PaginatedList
        listName="clientsList"
        indexHook={useClients}
        originalFilters={clientFilters}
        itemCard={({ item }: { item: ClientIndex }) => (
          <PaginatedCard
            key={item.uuid}
            info={clientInfo(item)}
            header={<ClientHeader client={item} />}
            bottomContent={<ClientFooter client={item} />}
          />
        )}
      />
    </>
  );
};

const clientFilters: IFilter<
  "invoice_terms" | "abn_holder" | "is_active" | "is_key_client",
  InvoiceTerms | boolean
>[] = [
  {
    label: "Key Client",
    name: "is_key_client",
    options: [
      {
        label: "Key Client",
        value: true,
      },
    ],
  },
  {
    label: "Invoice Terms",
    name: "invoice_terms",
    options: [
      {
        label: "C.O.D.",
        value: InvoiceTerms.COD,
      },
      {
        label: "Days From End Of Month",
        value: InvoiceTerms.DaysFromEndOfMonth,
      },
      {
        label: "Days From Invoiced",
        value: InvoiceTerms.DaysFromInvoiced,
      },
    ],
  },
  {
    label: "ABN Holder",
    name: "abn_holder",
    options: [
      {
        label: "ABN",
        value: true,
      },
      {
        label: "No ABN",
        value: false,
      },
    ],
  },
  {
    label: "Status",
    name: "is_active",
    options: [
      {
        label: "Active",
        value: true,
      },
      {
        label: "Inactive",
        value: false,
      },
    ],
  },
];

const ClientHeader = ({ client }: { client: ClientIndex }) => {
  const tooltipId = useMemo(() => `client_title_${client.uuid}`, [client.uuid]);

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={client.invoice_terms} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={client.link}>{client.display_name}</Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {client.display_name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

const ClientFooter = ({ client }: { client: ClientIndex }) => {
  const { takeAction, loading } = useApi();

  return (
    <div className="d-flex align-items-center mt-3">
      <p className="contact-social mb-0 space-x-1">
        <ContactIcon label="email" contact={client.contact_email} />
        <ContactIcon label="location" contact={client.formatted_address} />
        <ContactIcon label="telephone" contact={client.contact_telephone} />
      </p>
      {!client.form_filled_at && (
        <div className="ms-auto">
          <button
            type="button"
            className="bg-warning shadow rounded-pill text-white tx-10 text-center px-2"
            disabled={loading}
            onClick={() => {
              confirm({
                title: "Resend Link to Fill Form",
                text: `Are you sure you want to resend the link to fill out the form to ${client.display_name}?`,
                onConfirm: () =>
                  takeAction("store", `client-details-form/${client.uuid}`)
                    .then(() =>
                      toast.success(
                        `${client.display_name} notified to fill out details`,
                      ),
                    )
                    .catch(errorSwal),
              });
            }}
            style={{ border: "none" }}
          >
            {isSubmitting(
              loading,
              "Details not Filled.",
              `Notifying ${client.display_name}`,
            )}
          </button>
        </div>
      )}
      {client.is_key_client && (
        <div
          color="success"
          className="shadow bg-success ms-auto rounded-pill text-white tx-10 text-center px-2"
        >
          Key Client
        </div>
      )}
    </div>
  );
};

const ContactIcon = ({
  label,
  contact,
}: {
  label: "telephone" | "email" | "location";
  contact?: string;
}) => {
  if (!contact) {
    return null;
  }

  let url = "";

  if (label === "email") {
    url = `mailto:${contact}`;
  }
  if (label === "location") {
    url = `https://maps.google.com/?q${contact}`;
  }

  if (label === "telephone") {
    url = `tel:${contact}`;
  }

  return (
    <a href={url}>
      <i className={`icon ion-ios-${label}-outline tx-30 lh-0 mg-r-5`} />
    </a>
  );
};

const clientInfo = (client: ClientIndex): PaginatedInformation[] => {
  return [
    {
      name: "Company Name",
      value: (
        <span className="text-capitalize">{client.name?.toLowerCase()}</span>
      ),
    },
    {
      name: "ABN Holder",
      value: client.abn_holder ? "Yes" : "No",
    },
    {
      name: "Contact Email",
      value: (
        <a href={`mailto:${client.contact_email}`}>{client.contact_email}</a>
      ),
    },
    {
      name: "Accounts Email",
      value: client.accounts_email,
    },
    {
      name: "Contact Telephone",
      value: client.contact_telephone,
    },
  ];
};

const DownloadData = () => {
  const { loading, takeAction } = useApi();

  const downloadData = () => {
    return takeAction("store", "client-exports")
      .then((res: any) =>
        fileDownload(
          res.data,
          `Clients ${dayjs().format("DD-MM-YYYY, LT")}.csv`,
        ),
      )
      .catch(errorSwal);
  };

  return (
    <button
      type="button"
      disabled={loading}
      className="btn btn-link ms-auto"
      onClick={downloadData}
    >
      {isSubmitting(
        loading,
        <>
          Download CSV <i className="fa fa-download tx-primary tx-16" />
        </>,
        "Generating...",
      )}
    </button>
  );
};

export default Clients;
