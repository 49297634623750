import ReactTable from "../table/ReactTable";
import axios from "../api/api";

const Invoices = ({ loading, invoices }) => {
  const download = (invoice) => {
    axios
      .post(`/subscription-invoice-downloads`, {
        invoiceId: invoice.id,
      })
      .then(({ data }) => (window.location.href = data.data));
  };

  const columns = [
    {
      accessorKey: "date",
      header: "Date",
    },
    {
      accessorKey: "total",
      header: "Total",
    },
    {
      accessorKey: "download",
      header: "Download",
      cell: (info) => {
        return (
          <button
            onClick={() => download(info.row.original)}
            className="p-0 btn btn-link"
          >
            Download
          </button>
        );
      },
    },
  ];

  if (!invoices) {
    return null;
  }

  return (
    <div className="card mg-t-20">
      <div className="card-body">
        <h6 className="slim-card-title">Invoices</h6>
        {!loading && invoices.length === 0 ? (
          <p className="text-center">No Invoice.</p>
        ) : (
          <>
            {invoices?.length > 0 && <p>Download invoices here.</p>}
            <ReactTable columns={columns} data={invoices} loading={loading} />
          </>
        )}
      </div>
    </div>
  );
};

export default Invoices;
