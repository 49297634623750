import React from "react";
import { useParams } from "react-router";
import NotFound from "../404";
import ProfileForm from "../customFields/ProfileForm";
import ClientFeedbackTemplateForm from "./ClientFeedbackTemplateForm";
import { ClientFeedbackTemplateIndex } from "./clientFeedbackTypes";

interface ShowClientFeedbackTemplatePageProps {
  template: ClientFeedbackTemplateIndex;
  setTemplate: (data: ClientFeedbackTemplateIndex) => void;
  updateTemplate: (values: any) => Promise<void>;
}

const ShowClientFeedbackTemplatePage = (
  props: ShowClientFeedbackTemplatePageProps,
) => {
  const { template, setTemplate, updateTemplate } = props;

  const { page } = useParams<{ page: "details" | "fields"; uuid: string }>();

  switch (page) {
    case "details":
      return (
        <ClientFeedbackTemplateForm
          form="UpdateClientFeedbackTemplate"
          onSubmit={updateTemplate}
          initialValues={template}
        />
      );
    case "fields":
      return (
        <ProfileForm
          initialValues={template}
          {...props}
          modelId={template.id}
          modelType="App\Models\ClientFeedbackTemplate"
          onSuccess={(data: { data: any[] }) => {
            setTemplate({
              ...template,
              custom_fields: data.data,
            });
          }}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowClientFeedbackTemplatePage;
