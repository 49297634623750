import { useHistory, useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import HazardReportForm from "./HazardReportForm";
import { HazardReport, IHazardReportQuestion } from "./types";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import errorSwal from "../utils/errorSwal";

const AddHazardReport = () => {
  const { type, id } = useParams<{ type: "project"; id: string }>();
  const history = useHistory();

  const { data: questions, loading }: IUseApi<IHazardReportQuestion[]> = useApi(
    `hazard-report-questions`,
    [],
    true,
  );

  const typeConverter = {
    project: "App\\Models\\Project",
  };

  const { takeAction }: IUseApi<{}, { data: HazardReport }> = useApi();

  const onSubmit: FormSubmitHandler<HazardReport> = (values) => {
    values.hazardable_type = typeConverter[type];
    values.hazardable_id = parseInt(id);

    return takeAction("store", `safety-initiatives/${values.safety_initiative_uuid}/hazard-reports`, values)
      .then(({ data }) => {
        history.push(`/${data.data.hazardable.link}`);
        toast.success("Take 5 Added Successfully.");
      })
      .catch((err) => {
        errorSwal(err);
        return formError(err);
      });
  };

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Add new Safety Initiatives" />

      <HazardReportForm
        onSubmit={onSubmit}
        form="AddHazardReport"
        // initialValues={{
        //   answers: questions?.map((question) => ({
        //     question_id: parseInt(question.id),
        //     name: question.name,
        //   })),
        // }}
      />
    </>
  );
};

export default AddHazardReport;
