import { Link } from "react-router-dom";
import formatDate from "../utils/formatDate";
import UserPopover from "../user/UserPopover";
import DeleteButton from "../utils/DeleteButton";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import { money } from "../form/formatters";

const TableRow = ({ data, refreshData }) => {
  const { takeAction, loading } = useApi();

  const deleteItem = () => {
    if (data.type !== "timesheet") {
      return;
    }

    deleteSwal()
      .then(() => takeAction("destroy", `timesheets/${data.uuid}/pay`))
      .then(() => {
        refreshData();
        toast.success("Item deleted.");
      })
      .catch(errorSwal);
  };

  if (data.type === "timesheet") {
    return (
      <tr>
        <td scope="row">
          <Link to={`/timesheets/${data.uuid}/details`}>
            {data.display_date}
          </Link>
        </td>
        <td>
          {data.type_description}

          {data.leave_reason && (
            <small className="mb-0 d-block">{data.leave_reason.reason}</small>
          )}
        </td>
        <td>
          <UserPopover
            user={data.approved_by}
            target={`id_${data.uuid.replace(
              /-/g,
              "_",
            )}_${data.approved_by.uuid.replace(/-/g, "_")}`}
          />
        </td>
        <td>{data.day_of_week}</td>
        <td>{data.start_time}</td>
        <td>{data.finish_time}*</td>
        <td>{data.paid_break}</td>
        <td>{data.unpaid_break}</td>
        <td>{data.remarks}</td>
        <td>{data.role}</td>
        <td>
          {data.productivity
            ? data?.productivity
                ?.map((p) => `${p.project_name} (${p.hours} hours)`)
                ?.join(", ")
            : null}
        </td>
        <td>{data.total_hours.toFixed(2)}</td>
        <td>{`$${data.hourly_rate.toFixed(2)}`}</td>
        <td>
          <DeleteButton deleting={loading} deleteFunction={deleteItem} />
        </td>
      </tr>
    );
  }

  if (data.type === "allowance") {
    return (
      <tr>
        <td scope="row">
          <Link to={data.link}>{formatDate(data.date)}</Link>
        </td>
        <td>{data.name} (Allowance)</td>
        <td></td>
        <td></td>
        <td>{data.start_time}</td>
        <td>{data.finish_time}</td>
        <td></td>
        <td></td>
        <td>
          Allowance for <Link to={data.project.link}>{data.project.name}</Link>
        </td>
        <td>{data.role}</td>
        <td></td>
        <td>{data.amount.toFixed(2)}</td>
        <td>{`$${data.rate.toFixed(2)}`}</td>
        <td> - </td>
      </tr>
    );
  }

  if (data.type === "staff_role_allowance") {
    return (
      <tr>
        <td scope="row">Employment Detail Allowance</td>
        <td>
          <p className="mb-0">{data.allowance_type}</p>
          <small>{money.format(data.amount)}</small>
        </td>
        <td> - </td>
        <td> - </td>
        <td> - </td>
        <td> - </td>
        <td> - </td>
        <td> - </td>
        <td>{data.notes ?? " - "}</td>
        <td>{data.role}</td>
        <td> - </td>
        <td> - </td>
        <td> - </td>
        <td> - </td>
      </tr>
    );
  }

  const description = data.account
    ? `(${data.account.code} - ${data.account.name}) - ${data.description}`
    : data.description;

  return (
    <tr>
      <td scope="row">
        <Link to={data.link}>
          {data.date ? formatDate(data.date) : "Is Recurring"}
        </Link>
      </td>
      <td>
        {data.type_description}
        <small className="mb-0 d-block">{money.format(data.amount)}</small>
      </td>
      <td>
        {" "}
        <UserPopover
          user={data.approved_by}
          target={`id_${data.uuid.replace(
            /-/g,
            "_",
          )}_${data.approved_by.uuid.replace(/-/g, "_")}`}
        />
      </td>
      <td>{data.day_of_week}</td>
      <td> - </td>
      <td> - </td>
      <td> - </td>
      <td> - </td>
      <td>{description}</td>
      <td>{data.role}</td>
      <td> - </td>
      <td> - </td>
      <td> - </td>
      <td> - </td>
    </tr>
  );
};

export default TableRow;
