import React, { Fragment } from "react";
import { Field } from "redux-form";
import { TEMPERATURE, RAIN, WIND } from "../../../data/weather_conditions";
import buttonGroup from "../../utils/buttonGroup";
import required from "../../utils/required";

const WeatherButtons = ({ name, label }) => {
  return (
    <Fragment>
      <div className="col-12">
        <label className="mg-b-0 mg-t-10">{label}</label>
      </div>
      <div className="col-lg-4">
        <Field
          component={buttonGroup}
          name={`weather.${name}_temperature`}
          options={TEMPERATURE}
          groupClass="w-100"
          buttonClass="w-100"
          validate={[required]}
        />
      </div>
      <div className="col-lg-4">
        <Field
          component={buttonGroup}
          name={`weather.${name}_rain`}
          options={RAIN}
          groupClass="w-100"
          buttonClass="w-100"
          validate={[required]}
        />
      </div>
      <div className="col-lg-4">
        <Field
          component={buttonGroup}
          name={`weather.${name}_wind`}
          options={WIND}
          groupClass="w-100"
          buttonClass="w-100"
          validate={[required]}
        />
      </div>
    </Fragment>
  );
};

export default WeatherButtons;
