import { SubscribedSku, User } from "@microsoft/microsoft-graph-types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import FormHeader from "../../utils/FormHeader";
import CustomScaleLoader from "../../utils/scaleLoader";
import CreateMicrosoftUser from "./CreateMicrosoftUser";
import SyncMicrosoftUser from "./SyncMicrosoftUser";
import { useAuth } from "../../../context/auth-context";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import RenderField from "../../utils/renderField";
import required from "../../utils/required";
import SelectInput from "../../form/SelectInput";
import SubmitButton from "../../utils/SubmitButton";
import { toast } from "react-toastify";
import formError from "../../utils/formError";
import FormErrorAlert from "../../form/FormErrorAlert";
import { GroupType } from "../../user/userTypes";
import UserLicenseForm from "./UserLicenseForm";

export interface MicrosoftUser {
  integration?: {
    uuid: string;
  };
  microsoft_user?: User;
  email: string;
}

export interface AssignedOfficeLicense {
  sku_id: string;
  sku_part_number: string;
}

export interface LicenseData {
  user_licences: SubscribedSku[];
  organisation_licences: (SubscribedSku & { displayName: string })[];
}

const UserMicrosoftDetails = (props: {
  user: any;
  setUser: (user: any) => void;
}) => {
  const { userId } = useParams<{ userId: string }>();

  const { user: authUser } = useAuth();

  const {
    data: licenceData,
    refreshData,
    setUrl,
  }: IUseApi<LicenseData> = useApi();

  const { data, loading, setData } = useApi(
    `/microsoft/users/${userId}`,
    {},
    true,
  );

  const isAdmin = authUser?.is_admin;
  const isPartOfIt = authUser?.groups.includes(GroupType.IT);

  useEffect(() => {
    if (!data?.microsoft_user || !data.integration) {
      return;
    }

    if (!isAdmin && !isPartOfIt) {
      return;
    }

    setUrl(`/microsoft/licences/users/${userId}`);
  }, [data]);

  const splitEmail = data?.microsoft_user?.userPrincipalName?.split("@");

  if (loading) {
    return <CustomScaleLoader>Fetching data...</CustomScaleLoader>;
  }

  return (
    <>
      <CreateMicrosoftUser setUser={setData} user={data} />
      <SyncMicrosoftUser setUser={setData} user={data} />
      {data.microsoft_user && data.integration && (
        <>
          <MicrosoftUserDetailsForm
            initialValues={{
              user_email_template: splitEmail?.[0],
              user_email_template_domain: splitEmail?.[1],
            }}
            msUser={data}
            {...props}
          />
          {isAdmin || isPartOfIt ? (
            <>
              {!licenceData ? (
                <CustomScaleLoader>Fetching License Data...</CustomScaleLoader>
              ) : (
                <UserLicenseForm
                  licenceData={licenceData as LicenseData}
                  refreshData={refreshData}
                  initialValues={{
                    licences: licenceData?.user_licences.map(
                      (l) => l.skuId as string,
                    ),
                  }}
                />
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

interface DetailForm {
  user_email_template: string;
  user_email_template_domain: string;
}

interface MicrosoftUserDetailsProps {
  msUser: any;
  user: any;
  setUser: (user: any) => void;
}

const MicrosoftUserDetails = (
  props: InjectedFormProps<DetailForm, MicrosoftUserDetailsProps> &
    MicrosoftUserDetailsProps,
) => {
  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<DetailForm, MicrosoftUserDetailsProps> = (
    values,
  ) => {
    return takeAction(
      "update",
      `microsoft/users/${props.user.uuid}/email`,
      values,
    )
      .then(() => {
        toast.success("Email updated successfully");
      })
      .catch(formError);
  };

  const { msUser, handleSubmit } = props;
  const { user: authUser } = useAuth();

  const isAdmin = authUser?.is_admin;
  const isPartOfIt = authUser?.groups.includes(GroupType.IT);

  return (
    <>
      <FormHeader>Details</FormHeader>
      {isAdmin || isPartOfIt ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormErrorAlert error={props.error} />
          <div className="col-lg-12 form-group">
            <div className="d-flex space-x-1 align-items-start">
              <label
                htmlFor="user_email_template"
                className="form-control-label tx-inverse tx-semibold"
              >
                Email
              </label>
              <span className="tx-danger"> *</span>
            </div>
            <div className="d-flex border bg-white">
              <Field
                component={RenderField}
                name="user_email_template"
                placeholder=""
                validate={required}
                required
                className="border-0 w-50"
              />
              <div className="w-50">
                <Field
                  component={SelectInput}
                  validate={required}
                  required
                  name="user_email_template_domain"
                  className="border-0 w-50 bg-gray-100 rounded-0"
                  controlStyles={{
                    border: "none",
                    borderRadius: "0 0.3rem 0.3rem 0",
                    backgroundColor: "#f2f2f2",
                  }}
                  url="/microsoft/domains"
                  formatData={(data: { id: string }[]) =>
                    data.map((d) => ({
                      label: `@${d.id}`,
                      value: d.id,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-12 form-group">
            <SubmitButton
              {...props}
              save="Update Email"
              saving="Updating Email..."
            />
          </div>
        </form>
      ) : (
        <div className="col-12">
          Email: {msUser.microsoft_user.userPrincipalName}
        </div>
      )}
    </>
  );
};

const MicrosoftUserDetailsForm = reduxForm<
  DetailForm,
  MicrosoftUserDetailsProps
>({
  form: "microsoftUserDetails",
})(MicrosoftUserDetails);

export default UserMicrosoftDetails;
