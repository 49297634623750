import React from "react";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import RegisterForm, { RegisterFormProps } from "./RegisterForm";
import { Register, RegisterProps } from "./registerTypes";
import { toast } from "react-toastify";

const RegisterDetails = (props: RegisterProps) => {
  const { register, setRegister } = props;

  const { takeAction }: IUseApi<{}, { data: Register }> = useApi();

  const onSubmit: FormSubmitHandler<Register, RegisterFormProps> = (values) => {
    return takeAction("update", `registers/${register.uuid}`, values)
      .then(({ data }) => {
        toast.success("Register Updated");
        setRegister(data.data);
      })
      .catch(formError);
  };

  return (
    <>
      <RegisterForm
        register={register}
        initialValues={register}
        form="EditRegister"
        onSubmit={onSubmit}
      />
    </>
  );
};

export default RegisterDetails;
