import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import OrganisationFields from "./OrganisationFields";
import UserFields from "./UserFields";

const RegisterOrganisation = (props) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [invite, setInvite] = useState(false);
  const { history, handleSubmit, match } = props;
  const { setUser } = useAuth();

  useEffect(() => {
    if (match.params.uuid) {
      axios
        .get(`invited-organisations/${match.params.uuid}`)
        .then(({ data }) => {
          setInvite(data.data);
        })
        .catch(() => {
          toast.error("Unable to find invitiation");
          history.push("/register/organisation");
        });
    }
  }, []);

  const submit = (values) => {
    setSubmitting(true);

    submitForm(values)
      .then(({ data }) => {
        setUser(data.data);
        history.push("/home");
        history.go();
        toast.success("Sign up Successful!");
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        return errorSwal(err);
      });
  };

  const submitForm = (values) => {
    if (match.params.uuid) {
      return axios.put(`invited-organisations/${match.params.uuid}`, values);
    }
    return axios.post(`organisations`, values);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-12">
          <label className="section-title mt-3">Organisation Details</label>
          <hr />
        </div>
        <OrganisationFields
          loading={loading}
          setLoading={setLoading}
          invite={invite}
          {...props}
        />

        <UserFields
          streetTitle="Organisation Address"
          postalTitle="Organisation Postal Address"
          isOrganisation
          {...props}
        />
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-primary w-100 btn-signin"
            disabled={submitting}
          >
            {isSubmitting(submitting, "Sign Up", "Signing Up...")}
          </button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "RegisterOrganisation" });

export default form(RegisterOrganisation);
