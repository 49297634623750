import { useEffect, useState } from "react";
import { Field } from "redux-form";
import SelectInputAsync from "../../utils/SelectInputAsync";
import useApi from "../../api/useApi";
import CustomScaleLoader from "../../utils/scaleLoader";
import ToggleFields from "./ToggleFields";
import { ProjectForm } from "../../projectForms/projectForm";
import required from "../../utils/required";

interface Division {
  label: string;
  value: string;
}

const ProjectFormFields = (props: any) => {
  const { change, initialValues, estimatedProjectValue } = props;

  const [projectFormToggleFields, setProjectFormToggleFields] = useState<
    ProjectForm[]
  >([]);
  const [projectType, setProjectType] = useState(null);
  const [organisationDivision, setOrganisationDivision] = useState(null);

  const { takeAction, loading } = useApi();

  const projectTypeOnchange = (value: any) => {
    setProjectType(value);
    fetchProjectForms(value, organisationDivision);
  };

  const divisionOnchange = (value: any) => {
    setOrganisationDivision(value);
    fetchProjectForms(projectType, value);
  };

  const fetchProjectForms = (type: any, divisions: any) => {
    if (type == null && divisions == null) {
      setProjectFormToggleFields([]);
      return;
    }

    let url = "project-forms";

    if (type) {
      url += `?project_type=${type}`;
    }

    if (divisions) {
      divisions.forEach((division: any, index: any) => {
        url += type
          ? `&organisation_division[]=${division.value}`
          : `?organisation_division[]=${division.value}`;
      });
    }

    projectFormToggleFields.map((projectForm: ProjectForm) => {
      change(`project_forms[${projectForm.uuid}]`, null);
    });

    takeAction("index", url).then(({ data }: { data: any }) => {
      setProjectFormToggleFields(data.data);
    });
  };

  useEffect(() => {
    if (initialValues?.project_form_toggle_fields) {
      setProjectFormToggleFields(initialValues.project_form_toggle_fields);
    }
    if (initialValues?.project_type_id) {
      setProjectType(initialValues.project_type_id);
    }
    if (initialValues?.organisation_divisions) {
      setOrganisationDivision(initialValues.organisation_divisions);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="form-group col-lg-4">
          <Field
            name="project_type_id"
            url="/project-types"
            label="Select Project Type"
            component={SelectInputAsync}
            formatData={({ data }: { data: ProjectForm[] }) =>
              data.map((d: ProjectForm) => ({ label: d.name, value: d.id }))
            }
            onChange={projectTypeOnchange}
            required
            validate={required}
          />
        </div>

        <div className="form-group col-lg-4">
          <Field
            name="organisation_divisions"
            url="/organisationdivisions/divisions"
            label="Division"
            component={SelectInputAsync}
            onChange={divisionOnchange}
            multiple={true}
            formatData={(data: Division[]) => {
              return data.map((d) => ({
                label: d.label,
                value: d.value,
              }));
            }}
          />
        </div>
      </div>
      {loading && (
        <div className="col-12">
          <CustomScaleLoader>
            Fetching Extra Information Required...
          </CustomScaleLoader>
        </div>
      )}

      {projectFormToggleFields && (
        <>
          <ToggleFields
            projectFormToggleFields={projectFormToggleFields}
            estimatedProjectValue={estimatedProjectValue}
            {...props}
          />
        </>
      )}
    </>
  );
};

export default ProjectFormFields;
