import React from "react";
import { Field } from "redux-form";
import renderField from "../../utils/renderField";

const AddCommentsField = ({ namePrefix, fieldInformation, fieldsToAdd }) => {
  if (fieldsToAdd.includes(fieldInformation.name)) {
    return (
      <div className="form-group mg-t-10">
        <Field
          component={renderField}
          name={`${namePrefix ? `${namePrefix}.` : ""}${
            fieldInformation.name
          }_comments`}
          placeholder={
            fieldInformation.comments_description ||
            `${fieldInformation.label} Comments`
          }
        />
      </div>
    );
  }
  return null;
};

export default AddCommentsField;
