import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);

const getStatus = (status, invoicedAt) => {
  switch (parseInt(status, 36)) {
    case 0:
      return (
        <span className="rounded-pill shadow badge bg-warning">Pending</span>
      );
    case 1:
      return (
        <span className="rounded-pill shadow badge bg-info">In Progress</span>
      );
    case 2:
      return (
        <span className="rounded-pill shadow badge bg-warning-light">
          On Hold
        </span>
      );
    case 3:
      return (
        <span className="rounded-pill shadow badge bg-success">Completed</span>
      );
    case 4:
      return (
        <span className="rounded-pill shadow badge bg-secondary">
          Cancelled
        </span>
      );
    case 5:
      return (
        <span className="rounded-pill shadow badge bg-success">
          Invoiced
          {invoicedAt
            ? ` on ${dayjs(invoicedAt).format("DD/MM/YYYY, LT")}`
            : ""}
        </span>
      );
    case 6:
      return (
        <span className="rounded-pill shadow badge bg-success">
          Invoice Requested
        </span>
      );
    default:
  }
};

export default getStatus;
