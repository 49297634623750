import { useEffect } from "react";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import ProjectContactForm from "../projectContacts/Form";
import ProjectContactList from "../projectContacts/List";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";

const Contacts = (props) => {
  const { tender } = props;
  const { user } = useAuth();

  const {
    data: contacts,
    setData: setContacts,
    loading,
    takeAction,
    response,
    setUrl,
  } = useApi(null, [], true);

  useEffect(() => {
    if (tender.client) {
      setUrl(`tender-contacts?tender=${tender.id}`);
      return;
    }

    setUrl("");
  }, [tender]);

  const deleteTenderContact = (contact) => {
    return deleteSwal(contact.name)
      .then(() => takeAction("destroy", `tender-contacts/${contact.uuid}`))
      .then(() => {
        setContacts(contacts.filter((c) => c.uuid !== contact.uuid));
        toast.success(`${contact.name} Deleted.`);
      })
      .catch(errorSwal);
  };

  const createTenderContact = (values, dispatch) => {
    return takeAction("store", "tender-contacts", {
      ...values,
      tender_id: tender.id,
    })
      .then(({ data }) => {
        setContacts([...contacts, data.data]);
        dispatch(reset("ProjectContacts"));
      })
      .catch(errorSwal);
  };

  if (!tender.client) {
    return null;
  }

  if (loading && !response) {
    return <CustomScaleLoader>Fetching Contacts...</CustomScaleLoader>;
  }

  return (
    <div className="bg-white border position-relative">
      {loading && (
        <div
          style={{ zIndex: 10 }}
          className="position-absolute w-100 h-100  z-10 d-flex align-items-center justify-content-center bg-black-3"
        >
          <div>
            <CustomScaleLoader>Loading...</CustomScaleLoader>
          </div>
        </div>
      )}
      <div className="p-3 bg-gray-100 border-bottom">
        <h5 className="tx-inverse mb-0">Tender Contacts</h5>
      </div>
      <ProjectContactForm
        clientUuid={tender.client.uuid}
        onSubmit={createTenderContact}
        {...props}
        contacts={contacts}
      />
      <ProjectContactList
        onDelete={deleteTenderContact}
        userId={user.id}
        contacts={contacts}
        {...props}
      />
    </div>
  );
};

export default Contacts;
