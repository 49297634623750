import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FaHourglassHalf, FaHourglassStart } from "react-icons/fa";
import { FiMoreHorizontal } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import useModal from "../hooks/useModal";
import { IndexJob, JobStatus } from "../jobs/jobTypes";
import { BootstrapColour } from "../utils/utilTypes";
import { ProjectFilters } from "./ProjectManagerDashboard";
import ProjectManagerJobProgress from "./ProjectManagerJobProgress";
import ProjectManagerProductivity from "./ProjectManagerProductivity";
import { IProjectManagerJob } from "./projectManagerTypes";

const ProjectManagerJob = ({
  job,
  projectFilters,
}: {
  job: IProjectManagerJob;
  projectFilters: ProjectFilters;
}) => {
  const [viewProductivity, setViewProductivity] = useState<boolean>(false);

  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const statusInfo = statusDetails(job);

  return (
    <div className="border shadow-sm">
      <Button
        color="link"
        onClick={() => setViewDetails(!viewDetails)}
        className={`d-flex w-100 no-focus align-items-center bg-gray-100 p-3 ${
          viewDetails ? "border-bottom" : ""
        }`}
      >
        <div
          id={`job_status_${job.uuid}`}
          className={`me-2 d-flex justify-content-center align-items-center rounded-circle shadow bg-grad-${statusInfo.color}`}
          style={{ height: "40px", width: "40px" }}
        >
          <StatusBubble job={job} />
          <UncontrolledTooltip target={`job_status_${job.uuid}`}>
            {statusInfo.text}
          </UncontrolledTooltip>
        </div>
        <div className="text-justify">
          <Link to={job.link} className="mb-2 tx-inverse fw-bold">
            {job.name}
          </Link>
          <p className="mb-0 tx-12">{job.number}</p>
        </div>
        {!viewDetails && (
          <>
            <div className="ms-3">
              <ProjectManagerJobProgress job={job} />
            </div>
            <div className="space-x-3 mt-0 ms-auto">
              <Badge color={statusInfo.color}>{statusInfo.text}</Badge>
              {job.critical_path && <Badge color="danger">Critical Path</Badge>}
              {job.is_overdue && <Badge color="warning">Overdue</Badge>}
            </div>
          </>
        )}
        {viewDetails && (
          <div className="ms-auto">
            <ProjectManagerJobDropdown
              viewProductivity={viewProductivity}
              setViewProductivity={setViewProductivity}
            />
          </div>
        )}
      </Button>
      {viewDetails && (
        <>
          <div className="p-3 space-y-5">
            <div>
              <p className="mb-3 white-space tx-inverse">{job.description}</p>
            </div>
            <ProjectManagerJobProgress job={job} />
            <div className="d-flex">
              <div className="space-x-3 mt-0 ms-auto">
                <Badge color={statusInfo.color}>{statusInfo.text}</Badge>
                {job.critical_path && (
                  <Badge color="danger">Critical Path</Badge>
                )}
                {job.is_overdue && <Badge color="warning">Overdue</Badge>}
              </div>
            </div>
          </div>
          <div className={`${viewProductivity ? "px-3 pb-3" : ""}`}>
            <ProjectManagerProductivity
              viewProductivity={viewProductivity}
              job={job}
              projectFilters={projectFilters}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const StatusBubble = ({
  job,
}: {
  job: IndexJob | IProjectManagerJob;
}) => {
  if (
    [
      JobStatus.Completed,
      JobStatus.InvoiceRequested,
      JobStatus.Invoiced,
    ].includes(job.status)
  ) {
    return <AiOutlineCheck className="text-white fw-bold tx-18" />;
  }

  if (job.is_overdue) {
    return <p className="text-white fw-bold tx-18 mb-0">!</p>;
  }

  if (job.status == JobStatus.Cancelled) {
    return <p className="text-white fw-bold tx-18 mb-0">-</p>;
  }

  if (job.status == JobStatus.OnHold) {
    return <p className="text-white fw-bold tx-18 mb-0">-</p>;
  }

  if (job.status == JobStatus.Pending) {
    return <FaHourglassStart className="text-white tx-14" />;
  }

  return <FaHourglassHalf className="text-white tx-14" />;
};

export const statusDetails = (job: {
  status: JobStatus;
  is_overdue?: boolean;
}): { text: string; color: BootstrapColour } => {
  if (job.status == JobStatus.Invoiced) {
    return {
      color: "success",
      text: "Invoiced",
    };
  }

  if (job.is_overdue) {
    return {
      color: "danger",
      text: "Overdue",
    };
  }

  if (job.status == JobStatus.Completed) {
    return {
      color: "success",
      text: "Completed",
    };
  }

  if (job.status == JobStatus.InProgress) {
    return {
      color: "info",
      text: "In Progress",
    };
  }
  if (job.status == JobStatus.Pending) {
    return {
      color: "warning",
      text: "Pending",
    };
  }
  if (job.status == JobStatus.InvoiceRequested) {
    return {
      color: "success",
      text: "Invoice Requested",
    };
  }
  if (job.status == JobStatus.OnHold) {
    return {
      color: "warning",
      text: "On Hold",
    };
  }

  return {
    color: "secondary",
    text: "Cancelled",
  };
};

const ProjectManagerJobDropdown = ({
  viewProductivity,
  setViewProductivity,
}: {
  viewProductivity: boolean;
  setViewProductivity: Function;
}) => {
  const { toggle, modal } = useModal();

  return (
    <Dropdown
      toggle={toggle}
      isOpen={modal}
      onClick={(e) => e.stopPropagation()}
    >
      <DropdownToggle color="link" className="p-0">
        <FiMoreHorizontal className="tx-18 text-secondary" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setViewProductivity(!viewProductivity)}>
          View Productivity{" "}
          {viewProductivity ? (
            <AiOutlineCheck className="ms-1 tx-success" />
          ) : null}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProjectManagerJob;
