import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import CALIBRATIONS_TYPES from "../../data/calibration_types";
import CALIBRATIONS_TYPE_UNITS from "../../data/calibration_type_units";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import RenderField from "../utils/renderField";

const CalibrationFields = ({
  calibration,
  index,
  fields,
  calibrationUnits,
  frequencyUnits,
  unitType,
  isTailoredCalibrationJobDeadlines
}) => {
  return (
    <div className="row">
      <div className="form-group col-lg-12">
        <label>
          Calibration {index + 1} (
          {unitType ? "Frequency in Units" : "Frequency in Time"})
        </label>
      </div>
      <div className="form-group col-lg-9">
        <Field
          name={`${calibration}.procedures`}
          component={renderField}
          label="Name"
          validate={required}
          required
        />
      </div>
      <div className="col-lg-3 mb-3">
        <Field
          component={renderToggleInput}
          name={`${calibration}.unit_type`}
          label="Frequency Units"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          name={`${calibration}.type`}
          component={SelectInputAsync}
          options={CALIBRATIONS_TYPES}
          label="Type"
          validate={required}
          required
        />
      </div>
      <div className="form-group col-lg-3">
        {!unitType ? (
          <Field
            name={`${calibration}.frequency_units`}
            component={SelectInputAsync}
            options={CALIBRATIONS_TYPE_UNITS}
            label="Units"
            validate={required}
            required
          />
        ) : (
          <Field
            name={`${calibration}.unit_frequency_units`}
            component={SelectInputAsync}
            options={calibrationUnits}
            label="Units"
            validate={required}
            required
          />
        )}
      </div>
      {frequencyUnits != 3 && (
        <div className="form-group col-lg-3">
          <Field
            name={`${calibration}.frequency`}
            component={renderField}
            label="Frequency"
            type="number"
            validate={required}
            required
          />
        </div>
      )}
      <div className="form-group col-lg-3">
        <Field
          name={`${calibration}.source`}
          component={renderField}
          type="text"
          label="Source"
          validate={required}
          required
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          name={`${calibration}.critically_overdue_units`}
          component={SelectInputAsync}
          options={CALIBRATIONS_TYPE_UNITS}
          label="Critically Overdue Units"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          name={`${calibration}.critically_overdue_amount`}
          component={renderField}
          label="Critically Overdue Amount"
          type="number"
        />
      </div>

      {!unitType ? (
          <>
            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name={`${calibration}.is_tailored_calibration_job_deadlines`}
                label="Customized Job Creation Deadline"
                information="When enabled, this field allows you to personalize the deadlines for calibration job creation, catering to your unique requirements. You can customize the scheduling process for job creation, ensuring precise generation of calibration tasks. For instance, you can set a specific time frame, like one month before the deadline, for the job to appear on the manager's screen."
              />
            </div>
        </>
      ) : null}

      {isTailoredCalibrationJobDeadlines == 1 && !unitType ? (
          <>
            <div className="form-group col-lg-3">
                <Field
                    name={`${calibration}.job_creation_units`}
                    component={SelectInputAsync}
                    options={CALIBRATIONS_TYPE_UNITS.filter((item) => {
                      return item.value !== '3';
                    })}
                    label="Units"
                  />
            </div>

            <div className="form-group col-lg-3">
              <Field
                  name={`${calibration}.job_creation_length`}
                  component={RenderField}
                  label="Length"
                  type="number"
                />
            </div>
          </>
        ) : null}

      <div className="form-group col-lg-12 d-flex">
        <button
          type="button"
          onClick={() => fields.remove(index)}
          className="btn btn-outline-danger ms-auto"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

const selector = formValueSelector("EquipmentType");

const mapStateToProps = (state, { calibration }) => ({
  frequencyUnits: selector(state, `${calibration}.frequency_units`),
  unitType: selector(state, `${calibration}.unit_type`),
  isTailoredCalibrationJobDeadlines: selector(state, `${calibration}.is_tailored_calibration_job_deadlines`)
});

export default connect(mapStateToProps, {})(CalibrationFields);
