import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";

const CustomJobEmailTemplate = (props) => {
  const columns = [
    {
      accessorKey: "template_label",
      header: "Name",
      cell: (info) => {
        const template = info.row.original;
        return (
          <Button className="p-0" color="link">
            <Link to={`/email-templates/${template.uuid}`}>
              ({template.type_description}) {template.name} - {info.getValue()}{" "}
            </Link>
          </Button>
        );
      },
    },
  ];

  return (
    <ReactTable columns={columns} data={props.customJob?.emailTemplates} />
  );
};

export default CustomJobEmailTemplate;
