import React, { useMemo, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { IoArrowRedo } from "react-icons/io5";
import {
  MdEdit,
  MdMoreHoriz,
  MdOutlineEmail,
  MdPersonAddAlt1,
  MdPhone,
} from "react-icons/md";
import { useQueryClient } from "react-query";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Field, FormSubmitHandler } from "redux-form";
import { useAuth } from "../../context/auth-context";
import downloadContactCard from "../../utils/downloadContactCard";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import useModal from "../hooks/useModal";
import FormModal from "../utils/FormModal";
import errorSwal from "../utils/errorSwal";
import {
  JobOverviewDashboardProject as IJobOverviewDashboardProject,
  JobOverviewDashboardEquipment,
  JobOverviewDashboardProjectJob,
} from "./dashboardTypes";
import EditJobModal from "./EditJobModal";
import getStatus from "../jobs/status";
import { Link } from "react-router-dom";
import { CustomFieldEquipment } from "../customFields/customFieldTypes";
import AddEquipmentToProjectSelect from "./AddEquipmentToProjectSelect";
import { toast } from "react-toastify";
import ReactTable from "../table/ReactTable";
import { UserIndex } from "../user/userTypes";
import AddJobModal from "./AddJobModal";

const JobOverviewDashboardProject = ({
  project,
  organisationEquipment,
  refreshData,
  draggingUser,
}: {
  project: IJobOverviewDashboardProject;
  organisationEquipment: CustomFieldEquipment[];
  refreshData: Function;
  draggingUser?: UserIndex;
}) => {
  const { toggle, modal } = useModal();

  const [draggedUser, setDraggedUser] = useState<UserIndex>();

  const [droppedUser, setDroppedUser] = useState<UserIndex>();

  return (
    <div
      className="position-relative"
      onDragOver={(e) => {
        e.preventDefault();
        setDraggedUser(draggingUser);
      }}
      onDragLeave={() => setDraggedUser(undefined)}
      onDrop={() => {
        setDroppedUser(draggedUser);
        setDraggedUser(undefined);
        toggle();
      }}
    >
      <div
        style={{ border: "2px dashed #d6d6d6" }}
        className={`position-absolute tn-300 w-100 h-100 d-flex align-items-center justify-content-center bg-black-3 rounded-lg top-0 left-0 ${
          draggedUser ? "opacity-1 z-20" : "opacity-0"
        }`}
      >
        <div className="">
          <p className="text-white mb-0 tx-20 fw-bolder">
            Add Job to {project.name} for {draggedUser?.full_name}
          </p>
        </div>
      </div>
      <div className="bg-white tn-300 z-10 position-relative border">
        <Header project={project} />
        <Body
          project={project}
          organisationEquipment={organisationEquipment}
          refreshData={refreshData}
        />
        <AddJobModal
          droppedUser={droppedUser}
          project={project}
          toggle={toggle}
          modal={modal}
        />
      </div>
    </div>
  );
};

const Body = ({
  project,
  organisationEquipment,
  refreshData,
}: {
  project: IJobOverviewDashboardProject;
  organisationEquipment: CustomFieldEquipment[];
  refreshData: Function;
}) => {
  const { toggle, modal } = useModal();

  const [selectedJobs, setSelectedJobs] = useState<
    JobOverviewDashboardProjectJob[]
  >([]);

  return (
    <>
      <MoveProjectModal
        selectedJobs={selectedJobs}
        toggle={toggle}
        setSelectedJobs={setSelectedJobs}
        modal={modal}
      />
      <div className="p-3 d-flex align-items-center">
        <h6 className="slim-card-title mb-0">Jobs</h6>
        {selectedJobs.length > 0 ? (
          <Button
            onClick={toggle}
            size="sm"
            outline
            color="primary"
            className="ms-auto"
          >
            Move selected jobs to new project <IoArrowRedo />
          </Button>
        ) : null}
      </div>
      <Jobs jobs={project.jobs} setSelectedJobs={setSelectedJobs} />
      <div className="p-3 d-flex align-items-center">
        <h6 className="slim-card-title mb-0">Equipment</h6>
        <div className="ms-auto w-25">
          <AddEquipmentToProjectSelect
            project={project}
            refreshData={refreshData}
            organisationEquipment={organisationEquipment}
          />
        </div>
      </div>
      <div className="list-group">
        <Equipment equipment={project.equipment} refreshData={refreshData} />
      </div>
    </>
  );
};

const Equipment = ({
  equipment,
  refreshData,
}: {
  equipment: JobOverviewDashboardEquipment[];
  refreshData: Function;
}) => {
  const { takeAction, loading }: IUseApi = useApi();

  const queryClient = useQueryClient();
  if (equipment.length === 0) {
    return (
      <div className="list-group-item pd-y-20  border-end-0 border-start-0">
        No equipment found
      </div>
    );
  }

  return (
    <div>
      {equipment.map((e) => (
        <div
          key={e.uuid}
          className="list-group-item pd-y-20  border-end-0 border-start-0 d-flex align-items-center"
        >
          <div>{e.name}</div>
          <div className="ms-auto">
            <Button
              outline
              color="danger"
              size="sm"
              disabled={loading}
              onClick={() => {
                takeAction("destroy", `equipment/${e.uuid}/projects`)
                  .then(() => {
                    toast.success("Equipment Removed From Project");
                    refreshData();
                    queryClient.invalidateQueries(["job-overview-dashboard"]);
                  })
                  .catch(errorSwal);
              }}
            >
              Remove From Project
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

const Jobs = ({
  jobs,
  setSelectedJobs,
}: {
  jobs: JobOverviewDashboardProjectJob[];
  setSelectedJobs: React.Dispatch<
    React.SetStateAction<JobOverviewDashboardProjectJob[]>
  >;
}) => {
  const columns = useMemo(
    () => [
      {
        header: "Manager",
        accessorKey: "manager.name",
      },
      {
        header: "Name",
        accessorKey: "name",
        cell(info: any) {
          const job = info.row.original as JobOverviewDashboardProjectJob;

          return (
            <Link className="text-secondary" to={job.link}>
              {job.name}
            </Link>
          );
        },
      },

      {
        header: "Status",
        name: "status",
        cell: (info: any) => {
          return getStatus(info.row.original.status);
        },
      },
      {
        header: "Actions",
        accessor: "actions",
        cell: (info: any) => {
          const job = info.row.original as JobOverviewDashboardProjectJob;

          return <JobActions job={job} />;
        },
      },
    ],
    [jobs],
  );

  return (
    <ReactTable
      wrapperClasses="white-table table-responsive mb-3"
      hideFooter
      disableSearch
      columns={columns}
      data={jobs}
      enableMultiSelect
      setSelectedRows={setSelectedJobs}
    />
  );
};

const JobActions = ({ job }: { job: JobOverviewDashboardProjectJob }) => {
  const { toggle, modal } = useModal();
  const { user } = useAuth();

  return (
    <>
      <EditJobModal toggle={toggle} modal={modal} job={job} />
      <UncontrolledDropdown>
        <DropdownToggle color="link">
          <MdMoreHoriz />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem className="text-secondary" onClick={toggle}>
            <MdEdit className="tx-16" /> Edit Job
          </DropdownItem>
          <DropdownItem>
            <a
              id={`email_${job.uuid}`}
              href={`mailto:${job.manager.email}`}
              className="text-secondary"
            >
              <MdOutlineEmail className="tx-16" /> Email
            </a>
          </DropdownItem>
          {job.manager.phone && (
            <>
              <DropdownItem>
                <a href={`tel:${job.manager.phone}`} className="text-secondary">
                  <MdPhone className="tx-16" /> Call
                </a>
              </DropdownItem>
              <DropdownItem>
                <Button
                  onClick={() =>
                    downloadContactCard({
                      ...job.manager,
                      phone: job.manager.phone ?? "",
                      organisation:
                        user?.active_organisation.display_name ?? "",
                    })
                  }
                  color="link"
                  className="p-0 text-secondary"
                  size="sm"
                >
                  <MdPersonAddAlt1 className="tx-16" /> Add Contact
                </Button>
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

const Header = ({ project }: { project: IJobOverviewDashboardProject }) => {
  return (
    <div className="border-bottom p-3 d-flex align-items-end position-sticky top-0 z-10 bg-info">
      <div>
        <Link to={`/${project.link}`} className="mb-0 d-block text-white">
          {project.number} - {project.name}
        </Link>
        {project.address !== "Not Provided" && (
          <a
            target="_blank"
            href={`http://maps.google.com/maps?q=${encodeURIComponent(
              project.address,
            )}`}
            className="text-white"
            rel="noreferrer noopener"
          >
            {project.address} <BiLinkExternal />
          </a>
        )}
      </div>
    </div>
  );
};

const MoveProjectModal = ({
  selectedJobs,
  toggle,
  modal,
  setSelectedJobs,
}: {
  selectedJobs: JobOverviewDashboardProjectJob[];
  setSelectedJobs: (jobs: JobOverviewDashboardProjectJob[]) => void;
  toggle: () => void;
  modal: boolean;
}) => {
  const { takeAction }: IUseApi = useApi();

  const queryClient = useQueryClient();

  const onSubmit: FormSubmitHandler<{ project_id: number }> = (values) => {
    return takeAction("store", `moved-jobs`, {
      jobs: selectedJobs.map((j) => j.id),
      project_id: values.project_id,
    })
      .then(() => {
        queryClient.invalidateQueries(["job-overview-dashboard"]);
        toggle();
        setSelectedJobs([]);
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Move Jobs to New Project"
      onSubmit={onSubmit}
      form="move_project_form"
    >
      <Field
        component={SelectInput}
        name="project_id"
        label="Project"
        url="/custom-fields/projects"
        formatData={(data: { id: number; name: string }[]) => {
          return data.map((d) => {
            return {
              label: d.name,
              value: d.id,
            };
          });
        }}
      />
    </FormModal>
  );
};

export default JobOverviewDashboardProject;
