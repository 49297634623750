import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import SelectInput from "../form/SelectInput";
import HeaderPage from "../header/HeaderPage";
import useObserver from "../hooks/useObserver";
import RenderField from "../utils/renderField";
import CustomScaleLoader from "../utils/scaleLoader";
import useJobOverviewDashboard from "./hooks/useJobOverviewDashboard";
import JobOverviewDashboardProject from "./JobOverviewDashboardProjects";
import useApi from "../api/useApi";
import { IUseApiWithData } from "../api/apiTypes";
import { CustomFieldEquipment } from "../customFields/customFieldTypes";
import { UserIndex } from "../user/userTypes";
import JobOverviewDashboardUsers from "./JobOverviewDashboardUsers";
import SearchInput from "../search/SearchInput";
import JobStatus from "../../enums/JobStatus";

interface UserBranch {
  label: string;
  model: "App\\Models\\Organisation" | "App\\Models\\OrganisationBranch";
  value: number;
  uuid: string;
}

const JobOverviewDashboard = ({
  branches,
  to,
  from,
  status,
}: {
  branches: UserBranch[];
  to: string;
  from: string;
  status: { label: string; value: string }[];
}) => {
  const {
    data: equipment,
    refreshData,
  }: IUseApiWithData<CustomFieldEquipment[]> = useApi(
    "custom-fields/equipment?filter[has_no_project]=true",
    [],
  );

  const [draggingUser, setDraggingUser] = useState<UserIndex>();

  const [filters, setFilters] = useState<
    { name: string; value: string[] | number[] }[]
  >([
    {
      name: "range",
      value: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
    },
    {
      name: "status",
      value: [JobStatus.InProgress, JobStatus.Pending],
    },
  ]);

  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    setFilters([
      {
        name: "range",
        value: [from, to],
      },
      {
        name: "branches",
        value: branches?.map((b) => b.value) ?? [],
      },
      {
        name: "status",
        value: status?.map((b) => b.value) ?? [],
      },
    ]);
  }, [branches, to, from, status]);

  const { data, isFetchingNextPage, fetchNextPage, isFetching } =
    useJobOverviewDashboard(searchQuery, filters);

  const intersection = useObserver(fetchNextPage);

  const projects = data?.pages?.flatMap((page) => page?.data) ?? [];

  return (
    <>
      <HeaderPage titlePage="Job Overview" crumbs={[]} />
      <FiltersForm
        initialValues={{
          from: dayjs().format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
          branches: [],
          status: [
            {
              label: "Pending",
              value: JobStatus.Pending,
            },
            {
              label: "In Progress",
              value: JobStatus.InProgress,
            },
          ],
        }}
        form="JOB_OVERVIEW_DASHBOARD_FILTERS"
      />
      <div className="row">
        <div className="col-lg-8" style={{ height: "65vh", overflow: "auto" }}>
          <SearchInput setValue={setSearchQuery} wrapperclass="mb-3" />
          <div className="space-y-3">
            {projects.map((project) => {
              if (!project) {
                return null;
              }

              return (
                <JobOverviewDashboardProject
                  key={project.uuid}
                  organisationEquipment={equipment}
                  project={project}
                  refreshData={refreshData}
                  draggingUser={draggingUser}
                />
              );
            })}
          </div>
          <div
            style={{ height: "10vh" }}
            id="intersection"
            ref={intersection}
          />
          {isFetchingNextPage && (
            <CustomScaleLoader>Fetching More Data...</CustomScaleLoader>
          )}
        </div>
        <JobOverviewDashboardUsers
          filters={filters}
          setDraggingUser={setDraggingUser}
          draggingUser={draggingUser}
        />
      </div>
    </>
  );
};

const Filters = () => {
  return (
    <div className="row">
      <div className="col-lg-3 form-group">
        <Field component={RenderField} label="From" name="from" type="date" />
      </div>
      <div className="col-lg-3 form-group">
        <Field component={RenderField} label="To" name="to" type="date" />
      </div>
      <div className="col-lg-3 form-group">
        <Field
          component={SelectInput}
          isMulti
          label="Branches"
          name="branches"
          url="/user-dashboards"
          formatData={(data: UserBranch[]) => {
            return data.filter(
              (b) => b.model === "App\\Models\\OrganisationBranch",
            );
          }}
        />
      </div>
      <div className="col-lg-3 form-group">
        <Field
          component={SelectInput}
          label="Status"
          name="status"
          isMulti
          options={[
            {
              label: "Pending",
              value: JobStatus.Pending,
            },
            {
              label: "In Progress",
              value: JobStatus.InProgress,
            },
            {
              label: "On Hold",
              value: JobStatus.OnHold,
            },
            {
              label: "Completed",
              value: JobStatus.Completed,
            },
            {
              label: "Cancelled",
              value: JobStatus.Cancelled,
            },
          ]}
        />
      </div>
    </div>
  );
};

const form = reduxForm({});

export const FiltersForm = form(Filters);

const selector = formValueSelector("JOB_OVERVIEW_DASHBOARD_FILTERS");

const mapStateToProps = (state: any) => {
  return {
    branches: selector(state, "branches"),
    to: selector(state, "to"),
    from: selector(state, "from"),
    status: selector(state, "status"),
  };
};

export default connect(mapStateToProps)(JobOverviewDashboard);
