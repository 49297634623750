import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useApi from "../api/useApi";
import FileSharedListTable from "./FileSharedListTable";
import CustomScaleLoader from "../utils/scaleLoader";
import useModal from "../hooks/useModal";
import AddShareFilesModel from "./AddShareFilesModel";
import EditShareFilesModel from "./EditShareFilesModel";
import queryString from "query-string";
import { useQueryClient } from "react-query";

const ManageFileSharingModal = (props) => {
  const {
    doc,
    toggle,
    modal,
    query,
    highlightedDocuments = [],
    title = "Manage File Shares",
  } = props;
  const {
    data: sharedEmails,
    refreshData,
    loading,
  } = useApi(`shared-files?${queryString.stringify(query) ?? ""}`, [], true);

  const queryClient = useQueryClient();

  const { modal: addModal, toggle: addToggle } = useModal();
  const { modal: editShareModal, toggle: toggleEditShare } = useModal();
  const [selectedFileShare, setSelectedFileShare] = useState();

  const validRootShares = sharedEmails.filter((share) =>
    share.expires_at
      ? !dayjs(share.expires_at).isBefore(dayjs())
      : !share.expires_at,
  );

  useEffect(() => {
    if (!loading) {
      queryClient.invalidateQueries("standard-documents");
    }
  }, [validRootShares]);

  return (
    <>
      <AddShareFilesModel
        modal={addModal}
        toggle={addToggle}
        refreshData={refreshData}
        query={query}
        form="RequestFileSharing"
        className={"wd-md-1000 mx-wd-800"}
        save={"Share"}
        saving={"Sharing..."}
        title={`Share ${
          highlightedDocuments.length > 0 ? "Selected" : "All"
        } Files`}
        documentIds={highlightedDocuments.map(({ id }) => id)}
      />

      <EditShareFilesModel
        modal={editShareModal}
        toggle={toggleEditShare}
        refreshData={refreshData}
        form="EditFileSharing"
        title={`Edit File Sharing to ${selectedFileShare?.email}`}
        selectedFileShare={selectedFileShare}
        setSelectedFileShare={setSelectedFileShare}
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="wd-md-1000 mx-wd-800"
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {loading ? (
            <CustomScaleLoader>Fetching File Shares...</CustomScaleLoader>
          ) : (
            <FileSharedListTable
              doc={doc}
              sharedEmails={sharedEmails}
              refreshData={refreshData}
              addNewToggle={addToggle}
              toggleEditShare={toggleEditShare}
              setSelectedFileShare={setSelectedFileShare}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ManageFileSharingModal;
