import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import AddMultiple from "../form/AddMultiple";
import SelectInput from "../form/SelectInput";
import AbnField from "../utils/AbnField";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SupplierNameField from "./SupplierNameField";
import { SimilarSupplier, SupplierType } from "./supplierTypes";

interface SupplierFillFieldsProps {
  similarSuppliers: SimilarSupplier[];
  setUrl: (url: string) => void;
  setData: (data: any[]) => void;
  data: SupplierType[];
  url: string;
  loading: boolean;
  abnHolder?: boolean;
  change(field: string, value: any): void;
}

const OrgFillFields = (props: SupplierFillFieldsProps) => {
  const { data, loading, abnHolder } = props;

  return (
    <>
      <div className="col-lg-12 form-group">
        <Field
          component={renderToggleInput}
          name="abn_holder"
          label="ABN Holder"
        />
      </div>
      {abnHolder && (
        <div className="col-lg-12 form-group">
          <AbnField form="AddSupplier" change={props.change} />
        </div>
      )}
      <div className="col-lg-6 form-group">
        <SupplierNameField
          setUrl={props.setUrl}
          setData={props.setData}
          url={props.url}
          similarSuppliers={props.similarSuppliers}
        />
      </div>
      {abnHolder && (
        <>
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              label="Trading Name"
              name="trading_name"
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={AddMultiple}
              name="accounts_email"
              label="Accounts Email/s"
              validate={required}
              required
            />
          </div>
        </>
      )}
      <div className="col-lg-6 form-group">
        <Field
          component={AddMultiple}
          name="contact_email"
          label="Contact Email/s"
          validate={required}
          required
        />
        <small>
          When sending notifications to this supplier, the first email address
          in this list will be the address the email is sent to.
        </small>
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="contact_telephone"
          label="Contact Phone"
        />
      </div>

      <div className="col-lg-3 form-group">
        <Field
          component={renderToggleInput}
          name="direct_debit"
          label="Direct Debit"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInput}
          name="type_id"
          label="Supplier Type"
          validate={required}
          required
          loading={loading}
          options={data?.map((d) => ({
            label: d.name,
            value: d.id,
          }))}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={renderToggleInput}
          name="is_subcontractor"
          label="Subcontractor"
        />
      </div>
      {/* <FormHeader>Bank Details</FormHeader>
      <Fields validate={false} prefix="bank_details" />
      <AddressFields validate={false} form="AddSupplier" {...props} postal /> */}
    </>
  );
};

const selector = formValueSelector("AddSupplier");

const mapStateToProps = (state: any) => {
  return {
    abnHolder: selector(state, "abn_holder"),
  };
};

export default connect(mapStateToProps)(OrgFillFields);
