import {
  InjectedFormProps,
  reduxForm
} from "redux-form";
import CustomForm from "../customFields/CustomForm";
import SubmitButton from "../utils/SubmitButton";
import { CustomJobJob } from "../customJobs/jobs/customJobTypes";
import { getFilterValueMapper } from "../customJobs/jobs/getFilterValueMapper";
import JobStatus from "../../enums/JobStatus";

interface CustomJobJobFormProps {
  job: CustomJobJob;
  onSubmit: (values: any) => Promise<void>;
}

const Form = (
  props: InjectedFormProps<CustomJobJob, CustomJobJobFormProps> &
    CustomJobJobFormProps,
) => {
  const { handleSubmit, job } = props;

  return (
    <form onSubmit={handleSubmit} noValidate={true}>
      <div className="row">
        <CustomForm
          customForm={job.custom_form
          .filter((field: any) => !field.field_attributes.hide_externally)
          .map((field: any) => {
            return {
              ...field,
              field_attributes: {
                ...field.field_attributes,
                disabled: (job.status == JobStatus.Cancelled || job.status == JobStatus.Completed) || field.field_attributes.disabled_externally,
              },
            };
          })}
          {...props}
          shouldError={true}
          templates={job?.template?.documents ?? []}
          parentUuid={job.uuid}
          valueMapper={getFilterValueMapper(job)}
          modelType="App\Models\ProjectJob"
          modelId={job.id}
        />

        <div className="col-12 form-group">
          <SubmitButton {...props} disabled={job.status == JobStatus.Cancelled || job.status == JobStatus.Completed}/>
        </div>
      </div>
    </form>
  );
};

const FormWithRedux = reduxForm<CustomJobJob, CustomJobJobFormProps>({
  form: 'customJobForm'
})(Form);

export default FormWithRedux;