import dayjs from "dayjs";
import _ from "lodash";
import { BsFileEarmarkLock } from "react-icons/bs";
import { RiShareForwardFill } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import { primary } from "../utils/Colours";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import getFileIcon from "../utils/getFileIcon";
import DropDown from "./DropDown";
import { useQueryClient } from "react-query";

export const isImage = (mimeType) => mimeType.substr(0, 5) === "image";

const DocumentListItem = (props) => {
  const {
    doc,
    setSelectedDocument,
    toggle,
    url,
    documents,
    highlightedDocuments,
    setHighlightedDocuments,
    orderedDocuments,
  } = props;

  const { takeAction, loading: deleting } = useApi();

  const { takeAction: move, loading: moving } = useApi();

  const queryClient = useQueryClient();

  const history = useHistory();

  const deleteDocument = () => {
    const deleteMessage = doc.has_files
      ? "This folder has files in it, are you sure you want to delete?"
      : `Are you sure you want to delete ${doc.name}`;

    return deleteSwal(null, deleteMessage)
      .then(() => takeAction("destroy", `documents/standard/${doc.uuid}`))
      .then(() => {
        queryClient.invalidateQueries("standard-documents");
        return toast.success(`${doc.name} deleted successfully.`);
      })
      .catch(errorSwal);
  };

  const dragImage = new Image();
  dragImage.src =
    "data:image/svg+xml;base64," +
    btoa(`<svg height="40" width="40" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#c5c5c5" />
    <text
      fill="#ffffff"
      fontSize="10"
      textAnchor="middle"
      x="7"
      y="15"
    >
      ${highlightedDocuments.length}
    </text>
  </svg>`);

  return (
    <Link
      onDoubleClick={(e) => {
        if (!doc.is_folder) {
          e.preventDefault();
          setSelectedDocument(doc);
          toggle();
          return;
        }
        history.push(`/${url}/${doc.uuid}`);
      }}
      onClick={(e) => {
        if (e.ctrlKey) {
          e.preventDefault();
          setHighlightedDocuments(_.xor(highlightedDocuments, [doc]));
          return;
        }
        if (e.shiftKey) {
          e.preventDefault();
          const lastClicked =
            highlightedDocuments[highlightedDocuments.length - 1];
          if (!lastClicked) {
            setHighlightedDocuments(
              _.isEqual(highlightedDocuments, [doc]) ? [] : [doc],
            );
            return;
          }
          const clickPos = orderedDocuments.findIndex(
            (d) => d.uuid === doc.uuid,
          );
          const lastPos = orderedDocuments.findIndex(
            (d) => d.uuid === lastClicked.uuid,
          );
          let positive = true;
          if (lastPos > clickPos) {
            positive = false;
          }
          let toSet = [];
          for (
            let i = lastPos;
            positive ? i < clickPos + 1 : i > clickPos - 1;
            positive ? i++ : i--
          ) {
            toSet.push(orderedDocuments[i]);
          }
          setHighlightedDocuments(toSet);
          return;
        }
        setHighlightedDocuments(
          _.isEqual(highlightedDocuments, [doc]) ? [] : [doc],
        );
      }}
      to={(location) => location.pathname}
    >
      <div
        draggable="true"
        id={doc.uuid}
        onDragOver={() => {
          if (doc.is_folder) {
            document.getElementById(doc.uuid).classList.add("bg-gray-300");
          }
        }}
        onDragLeave={() => {
          document.getElementById(doc.uuid).classList.remove("bg-gray-300");
        }}
        onDrop={() => {
          document.getElementById(doc.uuid).classList.remove("bg-gray-300");

          if (doc.default_folder_type !== null) {
            toast.warning("Unable to move default folder");
            return;
          }

          if (doc.folder_default_folder_type !== null) {
            toast.warning("Unable to move children of default Folders");
            return;
          }

          if (doc.is_folder && highlightedDocuments.includes(doc)) {
            toast.warning("Unable to move folder into itself");
            return;
          }

          if (doc.is_folder) {
            move("store", `dropped-documents/${doc.uuid}`, {
              documents: highlightedDocuments.map(({ id }) => id),
            })
              .then(() => {
                queryClient.invalidateQueries("standard-documents");
                toast.success("Documents Moved");
                setHighlightedDocuments([]);
              })
              .catch(errorSwal);
          }
        }}
        onDragEnd={() =>
          document.getElementById(doc.uuid).classList.remove("bg-gray-300")
        }
        onDragStart={(e) => {
          if (!highlightedDocuments.includes(doc)) {
            e.preventDefault();
          }

          e.dataTransfer.setDragImage(dragImage, 20, 20);
        }}
        className="file-item"
        style={{
          backgroundColor: highlightedDocuments.includes(doc) ? "#e9ecef" : "",
        }}
      >
        <div className="row no-gutters wd-100p">
          <div className="col-9 col-sm-9 d-flex align-items-center">
            <File deleting={deleting} moving={moving} doc={doc} />
          </div>
          <div className="col-6 col-sm-2 mg-t-5 mg-sm-t-0">
            {dayjs(doc.created_at).format("DD/MM/YYYY")}
          </div>

          {!deleting && (
            <DropDown
              doc={doc}
              deleteDocument={deleteDocument}
              documents={documents}
            />
          )}
        </div>
      </div>
    </Link>
  );
};

const File = ({ deleting, moving, doc }) => {
  if (deleting) {
    return (
      <>
        <ClipLoader loading color={primary} size={30} /> Deleting...
      </>
    );
  }

  if (moving) {
    return (
      <>
        <ClipLoader loading color={primary} size={30} /> Moving...
      </>
    );
  }

  return (
    <>
      <div className="position-relative">
        <Icon doc={doc} />

        {(doc.is_self_shared ||
          doc.is_shared_by_parent ||
          doc.is_shared_by_root) && (
          <RiShareForwardFill
            style={{
              top: "-3px",
              right: "12px",
            }}
            className="text-secondary tx-12 ms-3  position-absolute"
          />
        )}
      </div>

      <div>
        <p className="tx-inverse" style={{ marginBottom: "-6px" }}>
          {doc.name}
        </p>
        {doc.created_by?.name && (
          <small className="text-secondary">
            Uploaded by{" "}
            <a className="text-dark" href={`mailto:${doc.created_by?.email}`}>
              {doc.created_by?.name}
            </a>
          </small>
        )}
      </div>

      {doc.permissions !== null || doc.user_permissions?.length > 0 ? (
        <BsFileEarmarkLock className="ms-2 text-secondary tx-18" />
      ) : null}
    </>
  );
};

export default DocumentListItem;

const Icon = ({ doc }) => {
  if (isImage(doc.mime_type))
    return (
      <img
        src={doc.link}
        height="25px"
        width="50px"
        style={{ marginRight: "10px" }}
      />
    );

  return <i className={`${getFileIcon(doc.name, doc.is_folder)}`} />;
};
