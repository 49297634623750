import React from "react";
import TabList from "../utils/tabs/TabList";
import Tab from "../utils/tabs/Tab";
import { useParams } from "react-router-dom";

const Tabs = () => {
  const { page, uuid } = useParams();

  return (
    <TabList>
      <Tab
        link={`/supplier-projects/${uuid}/deliverables`}
        active={page === "deliverables"}
        icon="icon ion-clipboard text-capitalize"
      >
        Deliverables
      </Tab>
    </TabList>
  );
};
export default Tabs;
