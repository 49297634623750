import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import PaginatedList from "../pagination/PaginatedList";
import useProjectEquipmentRates from "./hooks/useProjectEquipmentRates";
import { IProjectEquipment } from "./ProjectEquipment";
import AddProjectEquipmentRatesModal from "./AddProjectEquipmentRatesModal";
import ProjectEquipmentRatesList, {
  ProjectEquipmentRate,
} from "./ProjectEquipmentRatesList";

const ProjectEquipmentRates = ({
  equipment,
}: {
  equipment: IProjectEquipment[];
}) => {
  const { number } = useParams<{ number: string }>();
  const { toggle, modal } = useModal();

  //

  return (
    <>
      <PaginatedList
        listName="projectEquipmentRatesList"
        indexHook={useProjectEquipmentRates}
        indexHookArguments={[number]}
        originalFilters={[]}
        list={({ data }) => {
          const rates = data as ProjectEquipmentRate[];

          return (
            <div className="col-12">
              {data?.length === 0 ? null : (
                <ProjectEquipmentRatesList rates={rates ?? []} />
              )}
            </div>
          );
        }}
        extraButtons={() => {
          return (
            <Button onClick={toggle} outline size="sm">
              Add Rate
            </Button>
          );
        }}
      />
      <AddProjectEquipmentRatesModal
        equipment={equipment}
        toggle={toggle}
        modal={modal}
      />
    </>
  );
};

export default ProjectEquipmentRates;
