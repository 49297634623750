import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import SVGContainer from "../utils/SVGContainer";
import { ReactComponent as Feedback } from "../../svgs/feedback.svg";
import { Button } from "reactstrap";
import Modal from "./Modal";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import TimeLine from "../utils/TimeLine";
import SentimentBadge from "./SentimentBadge";
import dayjs from "dayjs";

const EntityFeedback = ({ entity }) => {
  const { toggle, modal } = useModal();

  const {
    data: feedback,
    setData: setFeedback,
    loading,
    takeAction,
    response,
  } = useApi(
    `entity-feedback?entity_type=${encodeURIComponent(
      entity.entity_type,
    )}&entity_id=${entity.entity_id}`,
    [],
  );

  const submit = (values, dispatch) =>
    takeAction("store", `entity-feedback`, {
      ...entity,
      ...values,
    })
      .then(({ data }) => {
        setFeedback([...feedback, data.data]);
        toggle();
        toast.success("Feedback added");
        dispatch(reset("EntityFeedback"));
      })
      .catch(errorSwal);

  const formattedFeedback = feedback.map((item) => ({
    ...item,
    content: (
      <>
        {item.comment} <br /> <SentimentBadge feedback={item} />
      </>
    ),
  }));

  if (loading && !response) {
    return <CustomScaleLoader>Loading Feedback...</CustomScaleLoader>;
  }

  if (feedback.length === 0) {
    return (
      <>
        <SVGContainer SVG={Feedback} className="mg-t-40" />
        <div className="text-center mt-4">
          <h5 className="tx-inverse">No Feedback Found.</h5>
          <p className="mb-2">Click below to get started adding feedback.</p>
          <Button color="primary" outline size="small" onClick={toggle}>
            Add Feedback
          </Button>
        </div>
        <Modal
          form="EntityFeedback"
          onSubmit={submit}
          modal={modal}
          toggle={toggle}
          title="Add new Feedback"
        />
      </>
    );
  }

  return (
    <>
      <TimeLine
        items={_.orderBy(formattedFeedback, (f) => dayjs(f.created_at), [
          "desc",
        ])}
        title={
          <div className="d-flex mb-3">
            <p className="slim-card-title">Feedback</p>
            <Button
              color="primary"
              className="ms-auto"
              outline
              onClick={toggle}
            >
              Add Feedback
            </Button>
          </div>
        }
      />
      <Modal
        form="EntityFeedback"
        onSubmit={submit}
        modal={modal}
        toggle={toggle}
        title="Add new Feedback"
      />
    </>
  );
};

EntityFeedback.propTypes = {
  entity: PropTypes.shape({
    entity_id: PropTypes.number.isRequired,
    entity_type: PropTypes.string.isRequired,
  }).isRequired,
};

export default EntityFeedback;
