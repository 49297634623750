import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { Organisation } from "../organisation/types";
import { useAuth } from "../../context/auth-context";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import SubmitButton from "../utils/SubmitButton";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import FormErrorAlert from "../form/FormErrorAlert";
import CustomScaleLoader from "../utils/scaleLoader";
import filterOption from "../../utils/filterOption";
import FormHeader from "../utils/FormHeader";
import { XeroAccount } from "./xeroTypes";
import { ChartOfAccount } from "../chartOfAccounts/chartOfAccountTypes";

const XeroExtraInformation = (
  props: InjectedFormProps<any, { extra_info: any }>,
) => {
  const { user } = useAuth();

  const { data: organisation, takeAction }: IUseApi<Organisation> = useApi(
    `/organisations/${user?.active_organisation.uuid}`,
    null,
    true,
  );

  const {
    data: accounts,
  }: IUseApiWithData<{ xero: XeroAccount[]; organisation: ChartOfAccount[] }> =
    useApi(`xero/accounts`, {
      xero: [],
      organisation: [],
    });

  const formattedAccounts = formatAccounts(accounts.xero);

  // const { data: accounts } = useApi("chart-of-accounts", [], true);

  const onSubmit: FormSubmitHandler<{ extra_info: any }, any> = (values) => {
    return takeAction("update", "xero/extra-info", {
      ...props.initialValues.extra_info,
      ...values,
    })
      .then(() => {
        toast.success("Details saved.");
      })
      .catch(formError);
  };

  if (!organisation) {
    return <CustomScaleLoader>Fetching Group Organisations</CustomScaleLoader>;
  }

  return (
    <>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <div className="row">
          <FormErrorAlert error={props.error} />
          <div className="col-12 form-group">
            <Field
              name="extra_info.loan_organisation_id"
              label="Credit Card Loan Company"
              component={SelectInput}
              options={
                organisation?.group_members?.map((member) => ({
                  label: member.name,
                  value: member.id,
                })) ?? []
              }
            />
          </div>
          <div className="col-12 form-group">
            <Field
              name="extra_info.default_pre_payment_account_id"
              label="Default Pre Payment Account"
              component={SelectInput}
              filterOption={filterOption}
              options={accounts.organisation.map((d) => ({
                label: (
                  <div>
                    <p className="mb-0 text-dark">{d.code}</p>
                    <small className="mb-0 text-muted">{d.name}</small>
                  </div>
                ),
                value: d.id,
                text: `${d.name} ${d.code}`,
              }))}
            />
          </div>
          <FormHeader>Loan Accounts</FormHeader>

          <div className="col-12 form-group">
            <Field
              name="extra_info.asset_account"
              label="Asset Clearing Account"
              component={SelectInput}
              filterOption={filterOption}
              options={formattedAccounts}
            />
          </div>
          <div className="col-12 form-group">
            <Field
              name="extra_info.loan_account"
              label="Loan Account"
              component={SelectInput}
              filterOption={filterOption}
              options={formattedAccounts}
            />
          </div>
          <div className="col-12 form-group">
            <Field
              name="extra_info.unexpired_interest_account"
              label="Unexpired Interest Account"
              component={SelectInput}
              filterOption={filterOption}
              options={formattedAccounts}
            />
          </div>
          <div className="col-12 form-group">
            <Field
              name="extra_info.interest_expense_account"
              label="Interest Expense Account"
              component={SelectInput}
              filterOption={filterOption}
              options={formattedAccounts}
            />
          </div>

          <div className="col-12 form-group">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const formatAccounts = (data: XeroAccount[]) =>
  data.map((d) => ({
    label: (
      <div>
        <p className="mb-0 text-dark">{d.Code}</p>
        <small className="mb-0 text-muted">{d.Name}</small>
      </div>
    ),
    value: d.AccountID,
    text: `${d.Name} ${d.Code}`,
    type: d.Type,
  }));

const form = reduxForm<any, { extra_info: any }>({ form: "XeroExtraInfo" });

export default form(XeroExtraInformation);
