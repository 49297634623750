import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import useTestSuites from "./hooks/useTestSuites";
import { TestSuite } from "./testSuiteTypes";

const TestSuiteList = () => {
  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Test Suites" />
      <PaginatedList
        listName="testSuiteList"
        indexHook={useTestSuites}
        originalFilters={[]}
        addLink="/test-suites/add"
        itemCard={({ item }: { item: TestSuite }) => (
          <PaginatedCard
            key={item.uuid}
            info={[]}
            header={<TestSuiteHeader testSuite={item} />}
          />
        )}
      />
    </>
  );
};

const TestSuiteHeader = ({ testSuite }: { testSuite: TestSuite }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={testSuite.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={`branch_title${testSuite.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={`/test-suites/${testSuite.uuid}/details`}>
              {testSuite.name}
            </Link>
          </p>
          <UncontrolledTooltip
            placement="top-start"
            target={`branch_title${testSuite.uuid}`}
          >
            {testSuite.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default TestSuiteList;
