import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { DataResponse, IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { findAndReplace } from "../utils/arrayUtils";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { SubjectItemEditorProps } from "./SubjectItemEditor";
import { ISubjectItem } from "./subjectTypes";

const AddApprovalButton = (props: SubjectItemEditorProps) => {
  const { item, items, setItems } = props;
  const { takeAction, loading }: IUseApi = useApi();

  const { user } = useAuth();

  const addApproval = () => {
    return takeAction("store", `subject-items/${item?.uuid}/approvals`)
      .then(({ data }: DataResponse<ISubjectItem>) => {
        setItems(findAndReplace("uuid", items, data.data));
        toast.success("Approval Added");
      })
      .catch(errorSwal);
  };

  if (
    item?.current_approval ||
    !item?.name ||
    !item.content ||
    !user?.hasAccessTo("App\\Models\\SubjectItem", "update") ||
    !item.to_be_sent_for_approval ||
    item.is_read_only
  ) {
    return null;
  }

  return (
    <Button color="success" outline disabled={loading} onClick={addApproval}>
      {isSubmitting(loading, "Send for Approval", "Sending...")}
    </Button>
  );
};

export default AddApprovalButton;
