import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  Field,
  Form,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
} from "redux-form";
import { approvalOptions as defaultOptions } from "../../data/approval_options";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { ApprovalAction, ApprovalOptions } from "./approvalTypes";
import RenderRemarks from "./RenderRemarks";
import FormErrorAlert from "../form/FormErrorAlert";

interface ApprovalModalProps {
  toggle: () => void;
  modal: boolean;
  validateComments?: boolean;
  approvalOptions?: {
    value: ApprovalOptions;
    label: string | "Approve" | "Redo" | "Deny";
  }[];
  dispatch?: Function;
  form?: string;
  canTransfer?: true;
  approvalAction?: ApprovalAction;
}

interface StyledCheckButtonProps {
  value: ApprovalOptions;
  label: string | "Approve" | "Redo" | "Deny";
  onClick: () => void;
  active: boolean;
  disabled: boolean;
}

const ApprovalModal: React.FC<
  ApprovalModalProps & InjectedFormProps<{}, ApprovalModalProps>
> = (props) => {
  const {
    toggle,
    modal,
    handleSubmit,
    dispatch,
    validateComments = false,
    approvalOptions = defaultOptions,
    form = "ApprovalModal",
  } = props;

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog modal-lg wd-md-600"
      data-keyboard="false"
      form={form}
    >
      <ModalHeader toggle={toggle}>Update Approval</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <FormErrorAlert error={props.error} />
            <div className="form-group col-12">
              <Field
                label="Remarks"
                component={RenderRemarks}
                name="remarks"
                textarea
                validate={validateComments ? required : null}
                approvalAction={props.approvalAction}
                change={props.change}
              />
            </div>
            <div className="form-group col-12">
              <Field
                component={RenderRadioButton}
                name="approval_status"
                options={approvalOptions}
              />
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter className="d-flex">
        <SubmitButton
          onClick={() => dispatch?.(submit(form))}
          {...props}
          save="Submit Approval"
          saving="Submitting..."
          className="ms-3 btn-sm"
        />
      </ModalFooter>
    </Modal>
  );
};

export const RenderRadioButton = (
  props: WrappedFieldProps & { options: any[] },
) => {
  const { input, options } = props;

  return (
    <div className="space-y-3">
      {options.map((option) => {
        return (
          <div className="d-flex">
            <input
              type="radio"
              className="mb-0"
              style={{
                transform: "scale(1.2)",
              }}
              id={`${input.name}_${option.value}`}
              {...input}
              value={option.value}
              checked={input.value == option.value}
            />
            <div className="ms-2">
              <label
                htmlFor={`${input.name}_${option.value}`}
                className="mb-0 d-block tx-14"
              >
                <div className="text-dark fw-bolder">{option.label}</div>
                {/* <small>{option.description}</small> */}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const form = reduxForm<{}, ApprovalModalProps>({});

export default form(ApprovalModal);
