import React from "react";
import colors from "../../data/colors";
import useApi from "../api/useApi";

const RemoveTrustedDevice = () => {
  const { takeAction, loading } = useApi();

  const removeDevice = () => {
    takeAction("store", "user/remove-trusted-device", {
      device_id: localStorage.getItem("device_id"),
    }).then(() => localStorage.removeItem("device_id"));
  };

  if (!localStorage.getItem("device_id")) {
    return null;
  }

  return (
    <div
      className="subtle-info-grad shadow rounded-lg d-flex align-items-end position-relative"
      style={{ border: `1px solid ${colors.info.border}` }}
    >
      <div
        className="p-3"
        style={{
          color: colors.info.main,
        }}
      >
        <p className="mb-0">You have marked this device as a trusted device.</p>
        <p>
          Please click below to remove this device from your trusted devices.
        </p>
        <button
          type="button"
          className="bg-transparent border-0 p-0 fw-bolder"
          style={{ color: colors.info.main }}
          disabled={loading}
          onClick={removeDevice}
        >
          Remove this device from trusted devices
        </button>
      </div>
    </div>
  );
};

export default RemoveTrustedDevice;
