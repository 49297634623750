import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getEquipment = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(`equipment?page=${pageParam}&filter[search]=${search}`)
    .then(({ data }) => data);
};

export default function useEquipment(search?: string) {
  return useInfiniteQuery(
    ["equipment", { search }],
    (pageParam) => getEquipment(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
