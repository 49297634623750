import { useEffect, useRef, useState } from "react";
import { InboundAccountsPayable, SelectedPayableProps } from "./types";
import {
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
  getDocument,
} from "pdfjs-dist";
import TextButton from "../utils/TextButton";
import { FiCheck, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useWindowEvent } from "../hooks/useWindowEvent";
import colors from "../../data/colors";
import FormHeader from "../utils/FormHeader";
import { FaRegFilePdf } from "react-icons/fa";

const InboundAccountsPayableDetails = ({
  payable,
  selectedPayable,
  setSelectedPayable,
  selectedDocument,
  setSelectedDocument,
}: {
  payable: InboundAccountsPayable;
} & SelectedPayableProps) => {
  useEffect(() => {
    setSelectedPayable(
      payable.documents[0].possible_accounts_payable[0] ?? undefined,
    );
  }, []);

  const [hovering, setHovering] = useState({
    invoice_number: false,
    payable_number: false,
  });

  const [loadingTask, setLoadingTask] = useState<PDFDocumentLoadingTask>();

  useEffect(() => {
    if (selectedDocument) {
      setLoadingTask(getDocument(selectedDocument?.document?.link as string));
    }
  }, [selectedDocument]);

  const [scale, setScale] = useState(1);
  const [pdf, setPdf] = useState<PDFDocumentProxy>();
  const [page, setPage] = useState(1);
  const divRef = useRef<HTMLDivElement | null>(null);

  const renderPdf = () => {
    // Initialize PDF.js

    if (!loadingTask) {
      return;
    }

    loadingTask.promise.then((pdf) => {
      setPdf(pdf);
      pdf.getPage(page).then((renderedPage) => {
        const pdfCanvas = document.getElementById(
          "pdfCanvas",
        ) as HTMLCanvasElement;

        const context = pdfCanvas.getContext("2d") as CanvasRenderingContext2D;

        const viewport = renderedPage.getViewport({ scale: scale });
        pdfCanvas.height = viewport.height;
        pdfCanvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        renderedPage.render(renderContext).promise.then(() => {
          // PDF page rendered, now draw rectangles
          const drawingCanvas = document.getElementById(
            "drawingCanvas",
          ) as HTMLCanvasElement;

          let ctx = drawingCanvas.getContext("2d") as CanvasRenderingContext2D;

          drawingCanvas.width = viewport.width;
          drawingCanvas.height = viewport.height;

          selectedDocument?.possible_blocks?.map((entry) => {
            if (entry.Page !== page) return;

            const valueBox = entry.Geometry?.BoundingBox;

            if (!valueBox) return;

            const rectanglePoints = [
              {
                x: (valueBox.Left ?? 0) * (viewport?.width ?? 0),
                y: (valueBox.Top ?? 0) * (viewport?.height ?? 0),
                width: (valueBox.Width ?? 0) * (viewport?.width ?? 0),
                height: (valueBox.Height ?? 0) * (viewport?.height ?? 0),
              },
            ];

            rectanglePoints.forEach((point) => {
              const padding = 3;

              ctx.beginPath();
              ctx.rect(
                point.x - padding,
                point.y - padding,
                point.width + 2 * padding,
                point.height + 2 * padding,
              );

              ctx.strokeStyle = colors.info.border; // setting color to red

              ctx.lineWidth =
                hovering.payable_number &&
                selectedPayable?.id == entry.accounts_payable_id
                  ? 4
                  : 2; // setting line width
              ctx.stroke();
            });
          });

          if (selectedDocument?.invoice_field?.PageNumber === page) {
            const labelBox =
              selectedDocument.invoice_field.LabelDetection?.Geometry
                ?.BoundingBox;
            const valueBox =
              selectedDocument.invoice_field.ValueDetection?.Geometry
                ?.BoundingBox;

            const rectanglePoints = [];

            if (labelBox) {
              const labelRectangle = {
                x: (labelBox.Left ?? 0) * (viewport?.width ?? 0),
                y: (labelBox.Top ?? 0) * (viewport?.height ?? 0),
                width: (labelBox.Width ?? 0) * (viewport?.width ?? 0),
                height: (labelBox.Height ?? 0) * (viewport?.height ?? 0),
              };

              rectanglePoints.push(labelRectangle);
            }

            if (valueBox) {
              const valueRectangle = {
                x: (valueBox.Left ?? 0) * (viewport?.width ?? 0),
                y: (valueBox.Top ?? 0) * (viewport?.height ?? 0),
                width: (valueBox.Width ?? 0) * (viewport?.width ?? 0),
                height: (valueBox.Height ?? 0) * (viewport?.height ?? 0),
              };

              rectanglePoints.push(valueRectangle);
            }

            rectanglePoints.forEach((point) => {
              const padding = 3;

              ctx.beginPath();
              ctx.rect(
                point.x - padding,
                point.y - padding,
                point.width + 2 * padding,
                point.height + 2 * padding,
              );

              ctx.strokeStyle = colors.danger.border; // setting color to red

              ctx.lineWidth = hovering.invoice_number ? 4 : 2; // setting line width
              ctx.stroke();
            });
          }
        });
      });
    });
  };

  useEffect(() => {
    document
      .getElementById("invoice_number")
      ?.addEventListener("mouseenter", () =>
        setHovering({
          invoice_number: true,
          payable_number: false,
        }),
      );
    document
      .getElementById("invoice_number")
      ?.addEventListener("mouseleave", () =>
        setHovering({
          invoice_number: false,
          payable_number: false,
        }),
      );

    document.getElementById("payable")?.addEventListener("mouseenter", () =>
      setHovering({
        invoice_number: false,
        payable_number: true,
      }),
    );
    document.getElementById("payable")?.addEventListener("mouseleave", () =>
      setHovering({
        invoice_number: false,
        payable_number: false,
      }),
    );
  }, [payable, scale, page, loadingTask]);

  useWindowEvent(
    "keydown",
    (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        if (pdf && page > 1) {
          setPage(page - 1);
        }
      }
      if (e.key === "ArrowRight") {
        if (pdf && page < pdf.numPages) {
          setPage(page + 1);
        }
      }
    },
    [page],
  );

  useEffect(() => {
    if (selectedDocument) {
      renderPdf();
    }
  }, [payable, scale, page, loadingTask, hovering, selectedPayable]);

  if (!selectedDocument) {
    return null;
  }

  return (
    <>
      <div className="row mb-3">
        <FormHeader>Attached Documents</FormHeader>
        {payable.documents.map((document) => {
          const isSelected = document.uuid === selectedDocument?.uuid;

          return (
            <div className="col-lg-6">
              <button
                type="button"
                onClick={() => setSelectedDocument(document)}
                className={`${
                  isSelected ? "bg-gray-200" : "bg-white"
                } w-100 text-start p-3 shadow-sm rounded-lg border d-flex align-items-center`}
              >
                <FaRegFilePdf className="text-danger me-2 tx-26" />
                <div>
                  <p className="mb-0 text-dark">
                    {document.document?.name}{" "}
                    {isSelected ? (
                      <FiCheck className="text-success tx-16" />
                    ) : null}
                  </p>
                  <div className="mb-1 text-muted">
                    {document.possible_blocks.map((block) => {
                      return (
                        <div
                          onClick={() => {
                            if (block.Page) {
                              setPage(block.Page);
                            }

                            const selected =
                              document.possible_accounts_payable.find(
                                (p) => p.id === block.accounts_payable_id,
                              );

                            if (selected) {
                              setSelectedPayable(selected);
                            }
                          }}
                        >
                          {block.accounts_payable_name
                            ? `${block.accounts_payable_name} (${block.Text})`
                            : block.Text}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </button>
            </div>
          );
        })}
      </div>
      <div className="bg-white rounded-lg border shadow-sm">
        <div className="bg-gray-200 p-3 border-bottom">
          <p className="fw-bolder text-dark mb-0">
            {selectedDocument.document?.name}
          </p>
        </div>
        <div className="d-flex p-3 border-bottom align-items-center">
          <input
            type="range"
            min="0"
            max="2"
            step="0.05"
            value={scale}
            onChange={(e) => setScale(parseFloat(e.target.value))}
          />
          <div className="ms-1">{(scale * 100).toFixed(0)}%</div>
          <div className="d-flex ms-auto space-x-2">
            <TextButton
              className="tx-16 "
              disabled={pdf && page === 1}
              onClick={() => {
                if (pdf && page > 1) {
                  setPage(page - 1);
                }
              }}
            >
              <FiChevronLeft />
            </TextButton>
            <TextButton
              className="tx-16"
              disabled={pdf && page === pdf.numPages}
              onClick={() => {
                if (pdf && page < pdf.numPages) {
                  setPage(page + 1);
                }
              }}
            >
              <FiChevronRight />
            </TextButton>
          </div>
          <p className="mb-0 ms-2">
            {page} / {pdf?.numPages}
          </p>
        </div>
        <div
          ref={divRef}
          style={{ position: "relative", overflow: "auto", maxHeight: "100vh" }}
        >
          <canvas id="pdfCanvas"></canvas>
          <canvas
            id="drawingCanvas"
            style={{ position: "absolute", top: 0, left: 0 }}
          ></canvas>
        </div>
      </div>
    </>
  );
};

export default InboundAccountsPayableDetails;
