import { useState } from "react";
import { OnChangeValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { WrappedFieldProps } from "redux-form";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";

const components = {
  DropdownIndicator: null,
};

interface Option {
  value: string;
  label: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const AddMultiple = (
  props: WrappedFieldProps & {
    label: string;
    required?: boolean;
    information?: string;
    onAdd?: Function;
    hideRequired: boolean;
  },
) => {
  const { label, required, information, onAdd } = props;
  const [inputValue, setInputValue] = useState("");

  const handleChange = (value: OnChangeValue<Option, true>) => {
    props.input.onChange(value.map(({ value }) => value));
  };

  const handleBlur = () => {
    if (!inputValue) return;

    props.input.onChange([...props.input.value, inputValue]);
    if (onAdd) onAdd(props.input.value, inputValue);
  };

  const handleKeyDown = (event: any) => {
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case ",":
        props.input.onChange([...props.input.value, inputValue]);

        if (onAdd) onAdd(props.input.value, inputValue);

        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <>
      {label ? (
        <label className="form-control-label tx-inverse tx-semibold">
          {label}
          {required ? <span className="tx-danger"> *</span> : ""}
          <FieldInformationPopOver information={information} />
        </label>
      ) : null}
      <CreatableSelect
        isClearable
        inputValue={inputValue}
        isMulti
        components={components}
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        value={
          props.input.value
            ? props.input.value.map((value: string) => ({
                label: value,
                value,
              }))
            : null
        }
        placeholder="Separate values with a comma or by pressing enter..."
      />
    </>
  );
};

export default AddMultiple;
