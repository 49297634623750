import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import ApprovalStatus from "../user/ApprovalStatus";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import InsuranceModal from "./InsuranceModal";

const Insurances = (props) => {
  const { uuid } = useParams();

  const {
    data: insurances,
    takeAction,
    refreshData,
    loading,
  } = useApi(`supplier-types/${uuid}/insurances`, []);
  const { user } = useAuth();

  const { toggle, modal } = useModal();

  const { toggle: toggleEdit, modal: editModal } = useModal();

  const [activeInsurance, setActiveInsurance] = useState();

  const updateInsurance = (values) => {
    return takeAction(
      "update",
      `supplier-type-insurances/${values.uuid}`,
      values,
    )
      .then(({ data }) => {
        toast.success(`${values.name} updated`);
        refreshData();
        toggleEdit();
      })
      .catch(errorSwal);
  };

  const addInsurance = (values, dispatch) => {
    return takeAction("store", `supplier-types/${uuid}/insurances`, values)
      .then(({ data }) => {
        toast.success(`${values.name} added.`);
        refreshData();
        toggle();
        dispatch(reset("AddInsurance"));
      })
      .catch(errorSwal);
  };

  const deleteInsurance = (insurance) => {
    deleteSwal(insurance.name).then(() => {
      return takeAction("destroy", `supplier-type-insurances/${insurance.uuid}`)
        .then(({ data }) => {
          toast.success(`${insurance.name} deletion request sent.`);
          refreshData();
        })
        .catch(errorSwal);
    });
  };

  const columns = useMemo(() => {
    const columns = [
      {
        accessorKey: "name",
        header: "Name",
        cell: (info) => {
          const insurance = info.row.original;
          return (
            <>
              <button
                className="btn btn-link p-0"
                onClick={() => {
                  setActiveInsurance(insurance);
                  toggleEdit();
                }}
              >
                {info.getValue()}
              </button>
              <br />
              {insurance.approval_action && insurance.approval_status && (
                <ApprovalStatus
                  status={insurance.approval_status}
                  className="w-auto py-2"
                />
              )}
            </>
          );
        },
      },
      {
        accessorKey: "uuid",
        header: "Delete",
        cell: (info) => {
          const insurance = info.row.original;
          if (
            !insurance.deleted_at ||
            (insurance.deleted_at &&
              insurance.approval_action?.action_type !== "Approve")
          ) {
            return (
              <button
                onClick={() => deleteInsurance(insurance)}
                className="btn btn-outline-danger btn-sm"
                type="button"
              >
                Delete
              </button>
            );
          }
        },
      },
    ];

    const hasApprovalAction = insurances.some((insurance) => {
      return (
        insurance?.approval_action?.user_id === user.id &&
        insurance?.approval_action?.action_type !== "Redo"
      );
    });

    if (hasApprovalAction) {
      columns.push({
        accessorKey: "approval_action",
        header: "Take Approval Action",
        cell: (info) => {
          const insurance = info.row.original;
          const denyApproval =
            insurance.deleted_at &&
            insurance.approval_status?.status === "To be Approved to Delete";

          const options = [{ value: 1, label: "Approve" }];

          options.push(
            denyApproval
              ? { value: 3, label: "Deny" }
              : { value: 2, label: "Redo" },
          );

          return (
            <ApprovalButton
              approvalAction={insurance.approval_action}
              form={`ApprovalModal-${insurance.uuid}`}
              onSubmitted={() => window.location.reload()}
              wrapperClass="mg-auto"
              buttonClass="btn btn-outline-info btn-sm"
              approvalOptions={options}
            />
          );
        },
      });
    }

    return columns;
  }, [insurances, user]);

  const data = insurances.filter(
    (insurance) =>
      !insurance.deleted_at ||
      (insurance.deleted_at &&
        insurance.approval_action &&
        insurance.approval_status),
  );

  return (
    <>
      <button
        type="button"
        onClick={toggle}
        className="btn btn-outline-primary mb-3"
      >
        Add Insurance
      </button>
      <ReactTable data={data ?? []} columns={columns} loading={loading} />
      <InsuranceModal
        initialValues={activeInsurance}
        title={`Edit ${activeInsurance?.name}`}
        onSubmit={updateInsurance}
        form="EditInsurance"
        modal={editModal}
        toggle={toggleEdit}
      />
      <InsuranceModal
        title="Add Insurance"
        onSubmit={addInsurance}
        form="AddInsurance"
        modal={modal}
        toggle={toggle}
      />
    </>
  );
};

export default Insurances;
