import { Field } from "redux-form";
import FormModal from "../utils/FormModal";
import { SiMastercard, SiVisa } from "react-icons/si";
import SelectInput from "../form/SelectInput";
import filterOption from "../../utils/filterOption";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

const UserCreditCardModal = ({
  toggle,
  modal,
  initialValues = {},
  form = "UserCreditCard",
  onSubmit,
  title = "Add new User Credit Card",
}: {
  toggle: () => void;
  modal: boolean;
  initialValues?: any;
  form?: string;
  onSubmit?: Function;
  title?: string;
}) => {
  const { takeAction, loading } = useApi();

  const queryClient = useQueryClient();

  const deleteCard = () => {
    return deleteSwal()
      .then(() =>
        takeAction("destroy", `user-credit-cards/${initialValues.uuid}`),
      )
      .then(() => {
        toggle();
        toast.success("User credit card deleted successfully");
        queryClient.invalidateQueries("user-credit-cards");
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title={title}
      form={form}
      onSubmit={onSubmit}
      initialValues={initialValues}
      extraFooterButtons={
        initialValues?.uuid
          ? [
              <Button color="danger" outline onClick={deleteCard}>
                Delete
              </Button>,
            ]
          : []
      }
    >
      <div className="col-lg-3" />
      <div className="col-lg-6 form-group">
        <div
          style={{
            borderRadius: "1.3rem",
          }}
          className="p-3 border shadow-lg temple-gradient text-white position-relative"
        >
          <div className="d-flex mb-3 align-items-center">
            <img
              alt=""
              src="/img/card-chip.png"
              className="shadow-sm"
              style={{ height: "30px" }}
            />
            <div className="ms-auto">
              <Field
                component={SelectInput}
                selectClass="-"
                indicatorsContainerStyles={{
                  color: "white",
                }}
                isClearable={false}
                controlStyles={{
                  border: "none",
                  background: "none",
                  color: "white",
                }}
                menuStyles={{
                  background: "#882de2",
                }}
                options={[
                  {
                    label: <SiVisa className="tx-40 text-white" />,
                    value: "Visa",
                  },
                  {
                    label: <SiMastercard className="tx-40 text-white" />,
                    value: "Mastercard",
                  },
                ]}
                name="card_brand"
              />
            </div>
          </div>

          <div className="d-flex form-group tx-26">
            **** **** ****{" "}
            <Field
              component="input"
              type="number"
              name="card_last_four"
              placeholder="Last 4 digits..."
              className="ms-2 bg-none text-white flex-grow-1 credit-card-input"
              style={{ background: "none", border: "none", width: "30%" }}
            />
          </div>
          <div className="d-flex mb-3 align-items-center">
            <label className="ms-auto mg-lg-r-10 mg-lg-t-8">Expiry Date</label>
            <div className="">
              <Field
                name="expiry_date"
                type="month"
                component="input"
                className="ms-2 bg-none text-white flex-grow-1 credit-card-input"
                style={{ background: "none", border: "none" }}
                // component={RenderField}
              />
            </div>
          </div>
          <label className="mb-0">Card Holder Name</label>
          <div style={{ zIndex: 10 }}>
            <Field
              component={SelectInput}
              name="user_id"
              url="/users?filter[employed]=true"
              filterOption={filterOption}
              formatData={(data: any[]) => {
                return data.map((user: any) => ({
                  label: (
                    <span className="text-white tx-18 text-uppercase">
                      {user.full_name}
                    </span>
                  ),
                  value: user.id,
                  text: user.full_name,
                }));
              }}
              indicatorsContainerStyles={{
                color: "white",
              }}
              selectClass="-"
              controlStyles={{
                border: "none",
                background: "none",
              }}
              menuStyles={{
                background: "#882de2",
              }}
              valueContainerStyles={{
                padding: "none",
              }}
            />
          </div>
        </div>
      </div>
    </FormModal>
  );
};

export default UserCreditCardModal;
