import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import CustomFields from "../customFields/CustomFields";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

interface ClientFeedbackTemplateFormProps
  extends InjectedFormProps<{}, { includeCustomFields?: boolean }> {
  includeCustomFields?: boolean;
}

const ClientFeedbackTemplateForm = (props: ClientFeedbackTemplateFormProps) => {
  const { handleSubmit, includeCustomFields = false } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="name"
            type="text"
            label="Name"
            validate={required}
            required
          />
        </div>
        {includeCustomFields && (
          <>
            <FormHeader>Feedback Form</FormHeader>
            <div className="col-12">
              <CustomFields {...props} />
            </div>
          </>
        )}
      </div>
      <div className="col-12">
        <SubmitButton {...props} />
      </div>
    </form>
  );
};

const form = reduxForm<{}, { includeCustomFields?: boolean }>({
  enableReinitialize: true,
});

export default form(ClientFeedbackTemplateForm);
