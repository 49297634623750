import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import errorSwal from "../utils/errorSwal";
import FormBranch from "./Form";

const AddBranch = (props) => {
  const { takeAction } = useApi();

  const { loading, data, setData } = useApi(
    `/custom-fields?model=${encodeURIComponent(
      "App\\Models\\OrganisationBranch",
    )}`,
    [],
    true,
  );

  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", "organisation-branches", values)
      .then(({ data }) => {
        toast.success("Branch created successfully!");
        history.push(`/branches/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="New Branch"
        crumbs={[
          { link: "branches", name: "Branches" },
          { link: "branches/add", name: "New Branch", active: true },
        ]}
      />
      <FormBranch
        customFields={data}
        onSubmit={onSubmit}
        form="AddBranch"
        {...props}
      />
    </>
  );
};

export default AddBranch;
