import { Link } from "react-router-dom";
import { IRequestedLeave } from "./leaveTypes";

const LeaveTimesheets = ({
  requestedLeave,
}: {
  requestedLeave: IRequestedLeave;
}) => {
  return (
    <div className="mt-3">
      <p className="text-dark mb-2 fw-bolder ">Timesheets</p>
      <div className="space-y-3">
        {requestedLeave.timesheets.map((timesheet) => {
          return (
            <div className="bg-white border shadow-sm p-3">
              <Link to={timesheet.link}>{timesheet.display_date}</Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeaveTimesheets;
