import React, { useEffect, useState } from "react";
import Spinner from "../utils/Spinner";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import ProfileHeader from "./ProfileHeader";
import ProfileTabs from "./ProfileTabs";
import ShowPage from "./ShowPage";

import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import DeleteSkillCategory from "./DeleteSkillCategory";

const CategoryList = () => {
  const { uuid } = useParams();

  const {
    data: category,
    setData: setCategory,
    loading,
    response,
  } = useApi(`skill-categories/${uuid}`, null);

  if (!response || loading) return <Spinner loading />;

  if (!category) {
    return <div>Error, Please Refresh</div>;
  }

  return (
    <>
      <HeaderPage
        titlePage={category.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "skill-categories", name: "Skill Categories" },
          { link: "", name: category.name, active: true },
        ]}
      />
      <div className="row">
        <div className="col-lg-8">
          <ProfileHeader category={category} />
          <ShowPage category={category} setCategory={setCategory} />
        </div>
        <div className="col-lg-4">
          <ProfileTabs /> <DeleteSkillCategory skillCategory={category} />
        </div>
      </div>
    </>
  );
};

export default CategoryList;
