import {
  FormAction,
  InjectedFormProps,
  getFormValues,
  reduxForm,
} from "redux-form";
import CustomForm from "../customFields/CustomForm";
import { CustomField } from "../customFields/customFieldTypes";
import dayjs from "dayjs";
import { connect } from "react-redux";
import isSubmitting from "../utils/submitting";

interface AnswersProps {
  customFields: CustomField[];
  dispatch: (action: FormAction) => void;
  onSubmit: (values: any) => Promise<void>;
  formValues: any;
}

const Answers = (props: InjectedFormProps<{}, AnswersProps> & AnswersProps) => {
  const { handleSubmit, customFields, onSubmit, dispatch, formValues, form } =
    props;

  return (
    <form onSubmit={handleSubmit} noValidate={true}>
      <div className="row">
        <CustomForm customForm={customFields} {...props} shouldError={false} />
        <div className="col-12 mt-3 d-flex">
          <button
            disabled={props.submitting}
            type="submit"
            className="btn btn-outline-primary btn-shadow"
          >
            {isSubmitting(props.submitting, "Save", "Saving...")}
          </button>
          <button
            type="button"
            disabled={props.submitting}
            onClick={handleSubmit((values) => {
              return onSubmit({
                ...values,
                completed_at: dayjs().utc().format("YYYY-MM-DD HH:mm:ss"),
              });
            })}
            className="btn btn-outline-success btn-shadow ms-auto"
          >
            {isSubmitting(props.submitting, "Save and Submit", "Saving...")}
          </button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<{}, AnswersProps>({});

const mapStateToProps = (state: any, { form }: { form: string }) => {
  return {
    formValues: getFormValues(form)(state),
  };
};

export default connect(mapStateToProps, {})(form(Answers));
