import React, { useState } from "react";

const CreateError = () => {
  const [create, setCreate] = useState("");

  const handleFormInput = (e: any) => {
    e.persist();
    setCreate(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (create === "error") {
      // @ts-ignore
      console.log(create.error.now);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="text-center">
        <br />
        <br />
        <input
          name="create"
          type="text"
          onChange={handleFormInput}
          required
        ></input>
        <br />
        <br />
        <input type="submit" className="btn btn-info btn-pad" value="Create" />
        <br></br>
      </div>
    </form>
  );
};

export default CreateError;
