import React from "react";
import { useParams } from "react-router-dom";
import ProfileForm from "../customFields/ProfileForm";
import Edit from "./Edit";
import NotFound from "../404";

const ShowPage = (props) => {
  const { type, setType } = props;

  const { page, uuid } = useParams();

  switch (page) {
    case "details":
      return <Edit {...props} initialValues={type} />;
    case "fields":
      return (
        <ProfileForm
          initialValues={type}
          {...props}
          modelId={type.id}
          modelType="App\Models\TenderType"
          onSuccess={(data) =>
            setType({
              ...type,
              custom_fields: data.data,
            })
          }
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
