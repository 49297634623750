import React from "react";
import { Link } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import ReactTable from "../table/ReactTable";
import { Project } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const Index = () => {
  const { data, loading } = useApi("required-project-information", []);

  const columns = [
    {
      accessorKey: "uuid",
      header: "Limit",
      cell: info => {
        return (
          <Link to={`required-project-information/${info.getValue()}/details`}>
            {info.row.original.name}
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/required-project-information/" />
      <HeaderPage
        titlePage="Required Project Information"
        pageDescription={
          <>
            Define additional fields that must be completed for projects with an
            estimated value exceeding a certain amount.
          </>
        }
        relatedLinks={[{ name: "Projects", link: "/projects", model: Project }]}
        crumbs={[]}
      />
      <Link
        to="/required-project-information/add"
        className="mb-3 btn btn-outline-primary"
      >
        Add New
      </Link>
      <ReactTable columns={columns} data={data} loading={loading} />
    </>
  );
};

export default Index;
