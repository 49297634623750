import { ChangeEventHandler, useEffect, useState } from "react";

interface UsePaginationConfig {
  page?: number;
  pageSize?: number;
  search?: string;
}

const usePagination = (
  config: UsePaginationConfig = {},
): {
  search: string;
  searchQuery: string;
  onSearch: ChangeEventHandler<HTMLInputElement>;
  onChange: Function;
} => {
  const [page, setPage] = useState<number>(config.page ?? 1);
  const [pageSize, setPageSize] = useState<number>(config.pageSize ?? 12);
  const [search, setSearch] = useState<string>(config.search ?? "");
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchQuery(search);
    }, 500);

    return () => clearTimeout(timeout);
  }, [search]);

  const onSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const onChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  return {
    search,
    searchQuery,
    onSearch,
    onChange,
  };
};

export default usePagination;
