import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import ApprovalButton from "../approvals/ApprovalButton";
import InfoCard from "../utils/InfoCard";
import Spinner from "../utils/Spinner";
import DeleteButton from "./DeleteButton";
import ProfileButtons from "./ProfileButtons";
import ProfileHeader from "./ProfileHeader";
import SentDeliverableList from "./SentDeliverableList";
import ShowPage from "./ShowPage";
import ProfileViewsList from "../profileViews/ProfileViewsList";
import { useAuth } from "../../context/auth-context";

const DeliverableProfile = (props) => {
  const { uuid } = useParams();
  const { user } = useAuth();

  const isPositionAdmin = user.is_position_admin;

  const {
    loading,
    data: deliverable,
    setData: setDeliverable,
  } = useApi(`deliverables/${uuid}`, "");

  const tabs = [
    {
      link: `/deliverables/${uuid}/deliverable`,
      icon: "fa fa-gift",
      page: "deliverable",
      label: "Deliverable",
    },
    {
      link: `/deliverables/${uuid}/details`,
      icon: "fa fa-file-text-o",
      page: "details",
      label: "Details",
    },
    {
      link: `/deliverables/${uuid}/documents`,
      icon: "fa fa-history",
      page: "documents",
      label: "Documents",
    },
  ];

  if (loading || !deliverable) return <Spinner loading />;

  const isInvoiced = deliverable?.invoice?.requested_at;

  return (
    <>
      <HeaderPage
        titlePage={deliverable.name}
        crumbs={[
          { link: "projects", name: "Projects" },
          {
            link: deliverable.project.link,
            name: deliverable.project.number,
          },
          { link: deliverable.job.link, name: deliverable.job.number },
          { link: "/", name: deliverable.name, active: true },
        ]}
      />
      <div className="row row-sm justify-content-end">
        <div className="col-lg-4 m-1">
          <ProfileViewsList
            viewable={deliverable}
            viewable_type={"App\\Models\\Deliverable"}
          />
        </div>
      </div>
      <Profile
        header={<ProfileHeader deliverable={deliverable} />}
        content={
          <>
            <ProfileButtons
              {...props}
              deliverable={deliverable}
              setDeliverable={setDeliverable}
            />
            <ShowPage
              {...props}
              deliverable={deliverable}
              setDeliverable={setDeliverable}
              isInvoiced={isInvoiced}
            />
          </>
        }
        tabs={tabs}
        sideBar={
          <>
            <ApprovalButton
              {...props}
              approvalAction={deliverable.current_revision.approval_action}
            />

            <DeleteButton deliverable={deliverable} />

            {isPositionAdmin && (
              <InfoCard
                info={[
                  {
                    title: "Value",
                    label: "Total Value",
                    value: `$${deliverable.total_cost.toFixed(2)}`,
                  },
                ]}
              />
            )}
            <SentDeliverableList deliverable={deliverable} />

            <div className="mt-3">
              <SideList
                commentableType="App\Models\Deliverable"
                commentableId={deliverable.id}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default DeliverableProfile;
