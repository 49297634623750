import React from "react";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";

const Edit = (props) => {
  const { takeAction } = useApi();
  const { uuid } = useParams();
  const { setCustomJob, customJob, canEditCustomJob } = props;

  const onSubmit = (values) => {
    if (!canEditCustomJob) {
      return toast.warning("Shared Custom Jobs is not allowed to edit.");
    }

    return takeAction("update", `custom-jobs/${uuid}`, values)
      .then(({ data }) => {
        setCustomJob(data.data);
        toast.success(`${values.name} updated`);
      })
      .catch(errorSwal);
  };

  return (
    <Form
      onSubmit={onSubmit}
      form="EditCustomJob"
      initialValues={customJob}
      showFields={false}
      disableFormEdit={!canEditCustomJob}
    />
  );
};

export default Edit;
