import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import React from "react";
import { SubscribedSku } from "@microsoft/microsoft-graph-types";
import {
  OfficeSuitePosition,
  OfficeSuitePositionLicence,
} from "./microsoftTypes";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import isSubmitting from "../utils/submitting";

export interface ModalProps {
  toggle: React.MouseEventHandler<any>;
  modal: boolean;
  skus: {
    skuId: string;
    skuPartNumber: string;
    displayName: string;
  }[];
  syncedPosition?: OfficeSuitePosition;
  positions: OfficeSuitePosition[];
  setPositions: (positions: OfficeSuitePosition[]) => void;
  position: { id: number; name: string };
}

const LicenceModal = ({
  toggle,
  modal,
  skus,
  syncedPosition,
  setPositions,
  positions,
  position,
}: ModalProps) => {
  const {
    takeAction,
  }: IUseApi<{}, { data: { licence_id: string; uuid: string } }> = useApi();

  const { takeAction: sync, loading: syncing } = useApi();

  const syncLicences = () => {
    return sync(
      "store",
      `office-suite-positions/${syncedPosition?.uuid}/licences/sync`,
    )
      .then(() => {
        toast.success("Licence sync started. May take a few minutes.");
      })
      .catch(errorSwal);
  };

  const addLicence = (licence: SubscribedSku) => {
    return takeAction(
      "store",
      `office-suite-positions/${syncedPosition?.uuid}/licences`,
      {
        licence_id: licence.skuId,
      },
    )
      .then(({ data }) => {
        setPositions(
          positions?.map((p) =>
            p.id !== syncedPosition?.id
              ? p
              : {
                  ...syncedPosition,
                  licences: [...syncedPosition?.licences, data.data],
                },
          ),
        );
        toast.success("Licence added successfully");
      })
      .catch(errorSwal);
  };

  const removeLicence = (licence: OfficeSuitePositionLicence) => {
    return takeAction(
      "destroy",
      `office-suite-position-licences/${licence.uuid}`,
    ).then(() => {
      setPositions(
        positions?.map((p) =>
          p.id !== syncedPosition?.id
            ? p
            : {
                ...syncedPosition,
                licences: syncedPosition?.licences.filter(
                  (l) => l.uuid !== licence.uuid,
                ),
              },
        ),
      );
      toast.success("Licence removed successfully");
    });
  };

  return (
    <Modal
      className="wd-md-1000 mx-wd-800"
      backdrop="static"
      toggle={toggle}
      isOpen={modal}
    >
      <ModalHeader toggle={toggle}>
        Edit Licences for {position.name}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          {skus.map((sku) => {
            const selectedLicence = syncedPosition?.licences.find((licence) => {
              return licence.licence_id === sku?.skuId;
            });

            const id = `id_${syncedPosition?.uuid}_${sku.skuId}`;

            return (
              <div className="col-lg-6 form-group">
                <button
                  onClick={() => {
                    selectedLicence
                      ? removeLicence(selectedLicence)
                      : addLicence(sku);
                  }}
                  type="button"
                  className="p-3 border rounded text-start bg-transparent w-100"
                >
                  <div className="d-flex align-items-center">
                    <p
                      className="text-dark mb-0 no-wrap"
                      style={{ maxWidth: "80%" }}
                      id={id}
                    >
                      {sku.displayName}
                    </p>{" "}
                    {selectedLicence ? (
                      <FaCheck className="text-success ms-4 tx-16 ms-auto" />
                    ) : null}
                    <UncontrolledTooltip target={id}>
                      {sku.displayName}
                    </UncontrolledTooltip>
                  </div>
                  <div>
                    <small className="text-secondary d-block">
                      {sku.skuPartNumber}
                    </small>
                    {selectedLicence ? (
                      <small className="text-secondary">Assigned</small>
                    ) : (
                      ""
                    )}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          size="sm"
          onClick={syncLicences}
          disabled={syncing}
        >
          {isSubmitting(syncing, "Sync Licences to Users", "Syncing...")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LicenceModal;
