import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import SearchInput from "../search/SearchInput";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";

type TestMethod = {
  id: number;
  name: string;
  method: string;
  total_test_time: number;
  status_count: number;
};

const ExpectedTestTimeList = () => {
  const [search, setSearch] = useState("");

  const { data: testMethods, setUrl }: IUseApiWithData<TestMethod[]> = useApi(
    `test-methods`,
    [],
  );

  useEffect(() => {
    if (search) {
      setUrl(`test-methods?filter[search]=${search}`);
      return;
    }

    setUrl(`test-methods`);
  }, [search]);

  const columnHelper = createColumnHelper<TestMethod>();

  const columns = useMemo<ColumnDef<TestMethod, any>[]>(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (props) => {
          const testMethod = props.row.original;

          return (
            <Link to={`/expected-test-times/${testMethod.method}`}>
              <p className="mb-0 text-dark">{testMethod.method}</p>
              <p className="mb-0 text-muted tx-12">{testMethod.name}</p>
            </Link>
          );
        },
      }),
      columnHelper.accessor("total_test_time", {
        header: "Expected Test Time",
        cell: (info) => {
          return `${info.getValue()} minutes`;
        },
      }),
      columnHelper.accessor("status_count", {
        header: "Number of Statuses",
      }),
    ],
    [],
  );

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Expected Test Times"
        crumbs={[
          {
            link: "/expected-test-times",
            name: "Expected Test Times",
            active: true,
          },
        ]}
      />
      <SearchInput setValue={setSearch} wrapperclass="mb-3" />
      <ReactTable<TestMethod>
        disableSearch
        columns={columns}
        data={testMethods}
      />
    </>
  );
};

export default ExpectedTestTimeList;
