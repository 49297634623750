import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import { IndexJob } from "../jobs/jobTypes";
import {
  StatusBubble,
  statusDetails,
} from "../projectManagers/ProjectManagerJob";
import BottomLogo from "../utils/BottomLogo";

const BasicUserProjectJobCard = ({ job }: { job: IndexJob }) => {
  const statusInfo = statusDetails(job);

  return (
    <div
      className="border p-3 rounded-lg position-relative tn-300 shadow-sm bg-white"
      style={{ zIndex: 100 }}
    >
      <div className="d-flex">
        <div className="width-25">
          <div
            id={`job_status_${job.uuid}`}
            className={`me-2 d-flex justify-content-center align-items-center rounded-circle shadow bg-grad-${statusInfo.color}`}
            style={{
              height: "40px",
              width: "40px",
            }}
          >
            <StatusBubble job={job} />
            <UncontrolledTooltip target={`job_status_${job.uuid}`}>
              {statusInfo.text}
            </UncontrolledTooltip>
          </div>
        </div>
        <div className="text-justify no-wrap">
          <Link
            id={`job_${job.uuid}`}
            to={job.link}
            className="mb-2 tx-inverse fw-bold"
          >
            {job.name}
            <p className="mb-0 text-secondary fw-normal tx-12">{job.number}</p>
          </Link>
        </div>
      </div>
      <p className="fw-bolder text-dark mb-0 mt-4">Description</p>
      <p className="mb-0">{job.description}</p>
      <div className="mt-5 space-y-3">
        <p className="mb-0 text-secondary tx-12">
          Scheduled to be finished on{" "}
          <span className="tx-inverse">
            {dayjs(job.scheduled_finish_date).format("DD/MM/YYYY")}
          </span>
        </p>
      </div>
      <BottomLogo size={30} bottom={25} left={235} />
    </div>
  );
};

export default BasicUserProjectJobCard;
