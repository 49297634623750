import React from "react";
import JobStatus from "../../enums/JobStatus";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import { InvoiceStatus } from "../jobs/jobTypes";
import { ProjectStatus } from "../projects/projectTypes";
import PaginatedList from "./JobManagerPaginatedList";
import ProjectList, { IJob } from "./ProjectList";
import useJobs from "./useJobManagerJobs";
import DocsModal from "../utils/DocsModal";

const JobManagerJobList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/jobs/" />
      <HeaderPage titlePage="Jobs" crumbs={[]} />
      <PaginatedList
        originalFilters={filters}
        indexHook={useJobs}
        list={({ data }: { data?: IJob[] }) => {
          return <ProjectList jobs={data ?? []} />;
        }}
      />
    </>
  );
};

const filters: IFilter<
  | "status"
  | "overdue"
  | "userIsStaff"
  | "invoiceStatus"
  | "dueToday"
  | "projectStatus",
  number | boolean
>[] = [
  {
    label: "Status",
    name: "status",
    multiple: true,
    options: [
      {
        label: "Pending",
        value: JobStatus.Pending,
      },
      {
        label: "In Progress",
        value: JobStatus.InProgress,
      },
      {
        label: "On Hold",
        value: JobStatus.OnHold,
      },
      {
        label: "Completed",
        value: JobStatus.Completed,
      },
      {
        label: "Cancelled",
        value: JobStatus.Cancelled,
      },
    ],
  },
  {
    label: "Project Status",
    name: "projectStatus",
    options: [
      {
        label: "Open",
        value: ProjectStatus.OPEN,
        selected: true,
      },
      {
        label: "Closed",
        value: ProjectStatus.CLOSED,
      },
    ],
  },
  {
    label: "Due Today",
    name: "dueToday",
    options: [
      {
        label: "Due Today",
        value: true,
      },
    ],
  },
  {
    label: "Overdue",
    name: "overdue",
    options: [
      {
        label: "Is Overdue",
        value: true,
      },
    ],
  },
  {
    label: "Jobs You are Staff For",
    name: "userIsStaff",
    options: [
      {
        label: "Jobs you are staff for",
        value: true,
      },
    ],
  },
  {
    label: "Invoice Status",
    name: "invoiceStatus",
    options: [
      {
        label: "Awaiting Invoice",
        value: InvoiceStatus.AwaitingInvoice,
      },
      {
        label: "Invoiced",
        value: InvoiceStatus.Invoiced,
      },
      {
        label: "Paid",
        value: InvoiceStatus.Paid,
      },
      {
        label: "Invoice Not Required",
        value: InvoiceStatus.InvoiceNotRequired,
      },
    ],
  },
];

export default JobManagerJobList;
