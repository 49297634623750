import { toast } from "react-toastify";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
  reset,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";

import confirm from "../utils/confirm";
import errorSwal from "../utils/errorSwal";
import formError from "../utils/formError";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import { InviteUser } from "./inviteUserTypes";
import SelectInput from "../form/SelectInput";

export interface AddUserInviteProps {
  setInvites: (invites: InviteUser[]) => void;
  invites: InviteUser[];
}

const AddUserInvite = (
  props: InjectedFormProps<InviteUser, AddUserInviteProps> & AddUserInviteProps,
) => {
  const { handleSubmit, setInvites, invites } = props;
  const { takeAction }: IUseApi<{}, { data: InviteUser }> = useApi();

  const { data: hasMicrosoft } = useApi(
    "integrations/microsoft",
    null,
    false,
    "",
    false,
  );

  const onSubmit: FormSubmitHandler<InviteUser, AddUserInviteProps> = (
    values,
    dispatch,
  ) => {
    return takeAction("store", "invite/user/check", values)
      .catch((data) => {
        if (data?.response?.status == 409) {
          confirm({
            title: "Employee Exists",
            text: "We found a previous employee with this email, would you like to reinstate them?",
            onConfirm: () =>
              takeAction(
                "store",
                `organisation-users/${data.response.data.message}/reinstate`,
              )
                .then(() => {
                  toast.success(`${values.first_name} Reinstated Successfully`);
                  dispatch(reset("Invite"));
                })
                .catch(errorSwal),
          });
        }
        throw new Error("409");
      })
      .then(() => takeAction("store", "invite/user", values))
      .then(({ data }) => {
        setInvites([...invites, data.data]);
        toast.success(`${data.data.first_name} Invited Successfully`);
        dispatch(reset("InviteUser"));
      })
      .catch((err) => {
        if (err?.message == 409) {
          return;
        }

        return formError(err);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="mb-3">
          <FormErrorAlert error={props.error} />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="first_name"
            type="text"
            component={renderField}
            label="First name"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="last_name"
            type="text"
            component={renderField}
            label="Last name"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="email"
            type="email"
            label="Email address"
            required
            validate={required}
            component={renderField}
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="default_branch_id"
            component={SelectInputAsync}
            url={`/react-select?model=${encodeURIComponent(
              "App\\Models\\OrganisationBranch",
            )}&select=id,organisation_id,division_id,tracking_site_id&label=name&value=id&with[]=trackingSite&with[]=division`}
            label="Default Branch"
          />
        </div>
        {hasMicrosoft?.uuid && (
          <div className="col-12 form-group">
            <Field
              component={SelectInput}
              name="settings.microsoft_domain"
              label="Microsoft Domain"
              url="/microsoft/domains"
              formatData={(data: { id: string }[]) =>
                data.map((d) => ({
                  label: `@${d.id}`,
                  value: d.id,
                }))
              }
            />
            <small>
              Leave blank for @
              {hasMicrosoft?.extra_info?.user_email_template_domain}
            </small>
          </div>
        )}
        <div className="form-layout-footer form-group col-lg-12">
          <SubmitButton save="Send" saving="Sending Invite..." {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<InviteUser, AddUserInviteProps>({});

export default form(AddUserInvite);
