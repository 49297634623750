import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import PermissionGroupForm from "./PermissionGroupForm";
import { PermissionGroup } from "./permissionGroupTypes";

const AddPermissionGroup = () => {
  const { takeAction }: IUseApi<{}, { data: PermissionGroup }> = useApi();
  const history = useHistory();

  const onSubmit: FormSubmitHandler<PermissionGroup> = (values) => {
    return takeAction("store", "/permission-groups", values)
      .then(({ data }) => {
        toast.success("Permission Group Added");
        history.push(`/permission-groups/${data.data.uuid}/details`);
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add Permission Group"
        crumbs={[
          { name: "Permission Groups", link: "/permission-groups" },
          { name: "Add Permission Group" },
        ]}
      />
      <PermissionGroupForm onSubmit={onSubmit} form="AddPermissionGroup" />
    </>
  );
};

export default AddPermissionGroup;
