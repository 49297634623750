import _ from "lodash";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import { Button } from "reactstrap";
import { getFormValues, WrappedFieldProps } from "redux-form";
import useModal from "../hooks/useModal";
import isSubmitting from "../utils/submitting";
import ChatModal from "./ChatModal";
import { useDropzone } from "react-dropzone";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import Markdown from "react-markdown";
import { IoClose } from "react-icons/io5";
import getFileIcon from "../utils/getFileIcon";
import LinkRenderer from "./LinkRenderer";

interface Annotation {
  type: string;
  text: string;
  start_index: number;
  end_index: number;
  file_path: { file_id: string };
}

const CustomPromptInput = (
  props: WrappedFieldProps & {
    label: string;
    formValues: any;
    prompt: any;
    onPrompt: (
      fieldUuid: string,
      prompt: string,
      name: string,
      change: Function,
      files?: any[],
      setAnnotations?: Function,
    ) => Promise<any>;
    generatingPrompt: boolean;
    fieldUuid: string;
    change: Function;
    modelType: string;
    modelId: number;
  },
) => {
  const { input, onPrompt, fieldUuid } = props;
  const { toggle, modal } = useModal();

  const [files, setFiles] = useState<{ filename: string; id: string }[]>([]);

  const [annotations, setAnnotations] = useState<any[]>([]);

  const [values, setValues] = useState<any[]>([]);

  const {
    upload,
    files: uploadingFiles,
    setFiles: setUploadingFiles,
  } = useUploadMultipleToS3("tmp/custom-fields");

  const { takeAction: downloadFile, loading: downloadingFile } = useApi();

  const { takeAction } = useApi();

  useEffect(() => {
    const newValues = Object.entries(
      _.orderBy(props.formValues?.custom_fields, "order") ?? [],
    )
      .filter(
        ([key, field]: any) =>
          `custom_fields.${key}.value` !== props.input.name,
      )
      .map(([key, field]: any) => {
        const variable = `$`.concat(
          `{${field?.label
            ?.trim()
            .replace(/{|}/g, "")
            .replace(/\s+/g, "-")
            .toLowerCase()}}`,
        );
        return { key: variable, value: field?.value };
      });

    setValues(newValues);
  }, [props.formValues?.custom_fields]);

  let toEval: string = props.prompt;

  values.forEach((field: any) => {
    toEval = toEval.replaceAll(`${field.key}`, field?.value ?? "-");
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      upload(acceptedFiles, false, true).then((data) => {
        return takeAction("store", "open-ai/files", { files: data })
          .then(({ data }: { data: any }) => {
            setFiles(data.data);
            setUploadingFiles([]);
          })
          .catch((err: any) => {
            setUploadingFiles([]);
            errorSwal(err);
          });
      });
    },
    [upload, takeAction, setUploadingFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
  });

  const markdownComponents = useMemo(() => {
    return {
      a: ({ href, children }: any) => (
        <LinkRenderer
          href={href}
          children={children}
          takeAction={downloadFile}
          loading={downloadingFile}
          annotations={input.value?.content?.text?.annotations}
          value={input.value}
        />
      ),
    };
  }, [input.value]);

  if (!onPrompt) {
    return (
      <div>
        <p>Prompts are currently unavailable for these fields</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="d-flex">
        <label
          htmlFor={input.name}
          className="form-control-label tx-inverse tx-semibold"
        >
          {props.label}
        </label>
        <div className="d-flex ms-auto align-items-center">
          <Toggle
            className="ms-auto toggle-sm"
            checked={modal}
            onChange={toggle}
            icons={false}
          />
          <span className="tx-sm ms-1">Toggle Prompt</span>
        </div>
      </div>

      <div
        className={`form-control d-block upload-input-wrapper tn-300 ${
          isDragActive ? "border-dashed border-dark" : ""
        }`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {uploadingFiles.length > 0 && (
          <div className="d-flex">
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            <span>Uploading...</span>
          </div>
        )}
        {files.length > 0 && (
          <div className="d-flex space-x-3 mb-3">
            {files.map((file, index) => (
              <div
                key={index}
                className="d-flex align-items-center border rounded-lg p-2 bg-white"
              >
                <i className={`${getFileIcon(file.filename)} me-2 tx-16`} />
                <span>{file.filename}</span>
                <button
                  className="btn btn-link p-0 ms-1"
                  onClick={() => {
                    setFiles(files.filter((f) => f.id !== file.id));
                  }}
                >
                  <IoClose />
                </button>
              </div>
            ))}
          </div>
        )}
        <Markdown
          components={markdownComponents}
          urlTransform={(url: string) => {
            return url;
          }}
        >
          {input.value?.content?.text?.value}
        </Markdown>
        {modal && <small className="d-block text-secondary">{toEval}</small>}
      </div>
      <div className="d-flex space-x-3 mt-3">
        <Button
          onClick={() =>
            onPrompt(
              fieldUuid,
              toEval,
              input.name,
              props.change,
              files,
              setAnnotations,
            )
          }
          size="sm"
          color="primary"
          outline
          disabled={props.generatingPrompt}
        >
          {isSubmitting(
            props.generatingPrompt,
            "Generate Response",
            "Generating...",
          )}
        </Button>
        {input.value ? (
          <ChatModal
            modelType={props.modelType}
            modelId={props.modelId}
            initialChat={input.value}
            fieldUuid={fieldUuid}
            change={props.change}
            name={input.name}
            threadId={input.value?.thread_id}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, { meta }: { meta: { form: string } }) => {
  return { formValues: getFormValues(meta.form)(state) };
};
export default connect(mapStateToProps)(CustomPromptInput);
