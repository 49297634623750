import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getProjects = ({ pageParam = 1 }, search: string, uuid: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `organisation-branches/${uuid}/projects?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useBranchProjects(search: string, uuid: string) {
  return useInfiniteQuery(
    ["branch-projects", { search, uuid }],
    (pageParam) => getProjects(pageParam, search, uuid),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
