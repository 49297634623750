import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import renderToggleInput from "../../utils/renderToggleInput";
import { PriceBandStatus } from "../../projectForms/IPriceBand";

interface FieldState {
  [uuid: string]: boolean;
}

const ToggleFields = (props: any) => {
  const { projectFormToggleFields, change, estimatedProjectValue } = props;
  const [fieldStates, setFieldStates] = useState<FieldState>({});
  const [fieldNotApplicable, setFieldNotApplicable] = useState<FieldState>({});

  useEffect(() => {
    if (projectFormToggleFields) {
      const updatedFieldStates: FieldState = { ...fieldStates };
      const updatedFieldNotApplicable: FieldState = { ...fieldStates };

      projectFormToggleFields.forEach((projectForm: any) => {
        const { price_bands, uuid } = projectForm;
        const hasJob = projectForm.hasJob !== undefined ? projectForm.hasJob : false;

        const matchingRange = price_bands.find((range: any) =>
          estimatedProjectValue >= range.lower_limit && estimatedProjectValue <= range.upper_limit
        );
        
        if (matchingRange) {
          const { status } = matchingRange;
          const isDisabled = [PriceBandStatus.NotApplicable, PriceBandStatus.Required].includes(status);

          let checkValue = false;
          if (status === PriceBandStatus.Required) {
            checkValue = true;
          }

          if (status == PriceBandStatus.Optional) {
            checkValue = hasJob;
          }

          change(`project_forms[${uuid}]`, checkValue);

          updatedFieldStates[uuid] = isDisabled;
          updatedFieldNotApplicable[uuid] = status === PriceBandStatus.NotApplicable;
        } else {
          change(`project_forms[${projectForm.uuid}]`, hasJob);
          updatedFieldStates[uuid] = false;
          updatedFieldNotApplicable[uuid] = false;
        }
      });

      setFieldStates(updatedFieldStates);
      setFieldNotApplicable(updatedFieldNotApplicable);
    }
  }, [projectFormToggleFields, estimatedProjectValue]);

  if (!projectFormToggleFields) return null;

  return (
    <div className="row">
      {projectFormToggleFields.map((field: any) => (
        !fieldNotApplicable[field.uuid] && (
          <div key={field.uuid} className="form-group col-lg-2">
              <Field
                name={`project_forms[${field.uuid}]`}
                component={renderToggleInput}
                label={field.name}
                disabled={fieldStates[field.uuid]}
              />
          </div>
        )
      ))}
    </div>
  );
};

export default ToggleFields;