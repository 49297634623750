import dayjs from "dayjs";
import { Button } from "reactstrap";
import { FormSubmitHandler, InjectedFormProps } from "redux-form";
import { Calibration } from "../equipmentCalibrationTypes/calibrationTypeTypes";
import isSubmitting from "../utils/submitting";

const BottomCompleteButton = ({
  calibration,
  baseSubmit,
  handleSubmit,
  submitting,
}: {
  calibration: Calibration;
  setCalibration: (calibration: Calibration) => void;
  baseSubmit: FormSubmitHandler<Calibration>;
} & InjectedFormProps<Calibration>) => {
  const submitAndMarkComplete: FormSubmitHandler<Calibration> = (
    values,
    dispatch,
    props,
  ) => {
    values = {
      ...values,
      completed_at: calibration.completed_at
        ? undefined
        : dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    return baseSubmit(values, dispatch, props);
  };

  if (calibration.worksheet_document) {
    return null;
  }

  return (
    <Button
      className="ms-auto"
      type="button"
      disabled={submitting}
      outline
      onClick={handleSubmit(submitAndMarkComplete)}
    >
      {isSubmitting(
        submitting,
        <>Save and {calibration.completed_at ? "Unm" : "M"}ark Complete</>,
        "Marking...",
      )}
    </Button>
  );
};

export default BottomCompleteButton;
