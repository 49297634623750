import { useQuery } from "react-query";
import api from "../../api/api";

export default function useBranchLeave(
  uuid: string,
  leaveRange: { start: string; end: string },
) {
  return useQuery("branch-leaves", () =>
    api
      .get(
        `organisation-branches/${uuid}/leave?start=${leaveRange.start}&end=${leaveRange.end}`,
      )
      .then(({ data }) => data),
  );
}
