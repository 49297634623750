import { Field, InjectedFormProps, reduxForm } from "redux-form";
import CustomFields from "../customFields/CustomFields";
import FormErrorAlert from "../form/FormErrorAlert";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import { Register } from "./registerTypes";

export interface RegisterFormProps {
  creating?: boolean;
  register?: Register;
}

const RegisterForm = (
  props: InjectedFormProps<Register, RegisterFormProps> & RegisterFormProps,
) => {
  const { creating = false, register } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-lg-12 form-group">
          <Field component={RenderField} name="name" label="Name" />
        </div>

        {!creating && register && (
          <>
            <div className="col-lg-12 form-group">
              <Field
                component={SelectInput}
                name="settings.title"
                label="Card Title"
                options={register.custom_fields.map((field) => {
                  return {
                    label: field.field_attributes?.label,
                    value: field.id,
                  };
                })}
              />
            </div>
          </>
        )}
        {creating && (
          <>
            <FormHeader>Fields</FormHeader>
            <CustomFields {...props} />
          </>
        )}
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<Register, RegisterFormProps>({});

export default form(RegisterForm);
