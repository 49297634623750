import React, { MouseEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import useApi from "../api/useApi";
import Avatar from "../utils/Avatar";
import CustomScaleLoader from "../utils/scaleLoader";
import { ClientContact } from "./ClientContacts";

interface ContactProject {
  name: string;
  number: string;
  link: string;
  uuid: string;
  status: 1 | 0;
}

const ClientContactProjectModal = ({
  toggle,
  modal,
  contact,
}: {
  toggle: MouseEventHandler<any>;
  modal: boolean;
  contact: ClientContact;
}) => {
  const { data, setUrl, loading } = useApi(undefined, []);

  const [statusFilter, setStatusFilter] = useState<1 | 0 | null>(0);

  let projects = data as ContactProject[];

  const filteredProjects = projects.filter((p) =>
    statusFilter === null ? true : p.status === statusFilter,
  );

  return (
    <Modal
      toggle={toggle}
      className="wd-md-1000 mx-wd-800"
      isOpen={modal}
      onOpened={() =>
        setUrl(`organisation-client-contacts/${contact?.uuid}/projects`)
      }
    >
      <ModalHeader toggle={toggle}>{contact.name}'s projects</ModalHeader>
      <ModalBody>
        {loading ? (
          <CustomScaleLoader>
            Fetching {contact.name}'s projects...
          </CustomScaleLoader>
        ) : (
          <>
            <div>
              <div className="d-flex align-items-center">
                <label className="section-title mt-3">Projects</label>
                <div className="ms-auto">
                  <ButtonGroup size="sm">
                    <Button
                      onClick={() =>
                        setStatusFilter(statusFilter === 0 ? null : 0)
                      }
                      outline={statusFilter !== 0}
                    >
                      Open
                    </Button>
                    <Button
                      onClick={() =>
                        setStatusFilter(statusFilter === 1 ? null : 1)
                      }
                      outline={statusFilter !== 1}
                    >
                      Closed
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
              <hr />
            </div>
            <div className="space-y-3">
              {filteredProjects.map((project) => {
                return (
                  <div className="d-flex">
                    <Avatar
                      colour={project.status === 1 ? "secondary" : "info"}
                      name={project.name}
                    />
                    <div>
                      <p className="mb-0 tx-inverse">
                        <Link to={`/${project.link}`}>{project.name}</Link>
                      </p>
                      <p className="mb-0">{project.number}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ClientContactProjectModal;
