const COMPETENCY_SKILL_TYPES = [
  {
    value: "App\\Models\\TestMethod",
    label: "Test Method",
    selectQuery: "?select=id,method,name&value=id&label=fullName",
  },
  {
    value: "App\\Models\\Qualification",
    label: "Licenses/Registrations/Qualifications",
    selectQuery: "?select=id,name&value=id&label=name",
  },
  {
    value: "App\\Models\\JobType",
    label: "Job Type",
    selectQuery: "?select=id,name&value=id&label=name",
  },
  {
    value: "App\\Models\\TestSuite",
    label: "Test Suite",
    selectQuery: "?select=id,name&value=id&label=name",
  },
];
export default COMPETENCY_SKILL_TYPES;
