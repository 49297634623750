import { useHistory, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import ShowPage from "./ShowPage";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";

const Profile = (props) => {
  const { uuid, page } = useParams();

  const { takeAction } = useApi();

  const history = useHistory();

  const {
    loading,
    data: position,
    setData: setPosition,
  } = useApi(`organisation-positions/${uuid}`);

  const deletePosition = () => {
    deleteSwal(position.name).then(() => {
      takeAction("destroy", `organisation-positions/${uuid}`)
        .then(() => {
          history.push("/positions");
          toast.success(`${position.name} deleted`);
        })
        .catch(errorSwal);
    });
  };

  if (loading || !position) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={position.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "positions", name: "Positions" },
          { link: "", name: position.name, active: true },
        ]}
      />

      <div className="row">
        <div className="col-lg-8">
          <DashboardHeader>
            <DashboardH3>{position.name}</DashboardH3>
            <DashboardSubTitle>{position.division.name}</DashboardSubTitle>
          </DashboardHeader>
          <ShowPage position={position} setPosition={setPosition} {...props} />
        </div>
        <div className="col-lg-4">
          <TabList>
            <Tab
              link={`/positions/${uuid}/details`}
              active={page === "details"}
              icon="icon ion-clipboard text-capitalize"
            >
              Details
            </Tab>
            <Tab
              link={`/positions/${uuid}/position-description`}
              active={page === "position-description"}
              icon="icon ion-document text-capitalize"
            >
              Position Description
            </Tab>
            <Tab
              link={`/positions/${uuid}/required-competencies`}
              active={page === "required-competencies"}
              icon="icon ion-person-stalker text-capitalize"
            >
              Required Competencies
            </Tab>
          </TabList>
          <div className="mt-3">
            <button
              className="btn btn-outline-danger w-100"
              type="button"
              onClick={deletePosition}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
