import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import MentionDisplay from "../form/MentionDisplay";
import getFileIcon from "../utils/getFileIcon";
import { isImage } from "../standardDocuments/DocumentListItem";
import { Button, UncontrolledTooltip } from "reactstrap";
import { BiDownload } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import useModal from "../hooks/useModal";
import PreviewModal from "../documents/PreviewModal";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import Avatar from "../utils/Avatar";

dayjs.extend(localizedFormat);

const CommentItem = ({ comment }) => {
  return (
    <div className="d-flex p-3 border-bottom" key={comment.uuid}>
      <div>
        <Avatar name={comment.author.name} rounded="-circle" colour="info" />
      </div>
      <div>
        <a className="text-dark" href={`mailto:${comment.author.email}`}>
          {comment.author.name}
        </a>
        <span className="post-date">
          {dayjs(comment.created_at).format("DD MMM YYYY, LT")}
        </span>
        <MentionDisplay content={comment.comment} />

        <DocumentRow documents={comment.documents} />
      </div>
    </div>
  );
};

export const DocumentRow = ({ documents }) => {
  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(documents);

  const { toggle, modal } = useModal();

  if (documents.length === 0) return null;

  return (
    <>
      <div className="row">
        <h6 className="text-dark fw-bolder mt-3">Attachments</h6>
        {documents.map((document, index) => {
          return (
            <>
              <UncontrolledTooltip target={`comment_${document.uuid}`}>
                {document.name}
              </UncontrolledTooltip>
              <div
                id={`comment_${document.uuid}`}
                className="col-4 mt-2 position-relative"
                key={index}
              >
                <div
                  style={{ height: "90px", width: "90px" }}
                  className="p-1 position-relative border d-flex align-items-center justify-content-center rounded"
                >
                  {isImage(document.mime_type) ? (
                    <img
                      className="w-100 h-100 rounded"
                      src={document.link}
                      alt={document.name}
                    />
                  ) : (
                    <i
                      className={`${getFileIcon(document.name, false)} tx-34`}
                    ></i>
                  )}
                  <div
                    style={{
                      width: "95%",
                      height: "95%",
                    }}
                    className="rounded opacity-0 hover-opacity-100 tn-300 bg-black-5 position-absolute z-1000 d-flex align-items-center justify-content-center"
                  >
                    <a
                      href={document.link}
                      className="p-1 text-white"
                      size="sm"
                    >
                      <BiDownload className="tx-20" />
                    </a>
                    <Button
                      onClick={() => {
                        setSelectedDocument(document);
                        toggle();
                      }}
                      color="link"
                      className="p-1 text-white"
                      size="sm"
                    >
                      <AiOutlineEye className="tx-20" />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <PreviewModal
        document={selectedDocument}
        modal={modal}
        toggle={toggle}
        changeDocument={changeDocument}
      />
    </>
  );
};

export default CommentItem;
