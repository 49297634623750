import React from "react";
import { useParams } from "react-router-dom";
import NotFound from "../404";
import Accounts from "./Accounts";
import ClientContacts from "./ClientContacts";
import SelectTenant from "./SelectTenant";
import TrackingCategories from "./TrackingCategories";

const ShowPage = () => {
  const { page } = useParams();

  switch (page) {
    case "successful":
      return <SelectTenant />;
    case "accounts":
      return <Accounts />;
    case "client-contacts":
      return <ClientContacts />;
    case "tracking-categories":
      return <TrackingCategories />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
