import React from "react";
import dayjs from "dayjs";
import { approvalMessage, validateApprovals } from "./validateApprovals";

const dateField = ({
  data,
  input,
  label,
  type,
  required,
  meta: { touched, error },
}) => {
  const hasApproval = validateApprovals(data, input);

  return (
    <React.Fragment>
      <label className="form-control-label tx-semibold tx-inverse">
        {label}
        {required ? <span className="tx-danger"> *</span> : ""}
      </label>

      {!hasApproval && (
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text h-100">
              <i className="fa fa-calendar tx-16 lh-0 op-6" />
            </div>
          </div>
          <input
            className={
              touched && error ? "form-control parsley-error" : "form-control"
            }
            {...input}
            placeholder="MM/DD/YYYY"
            type={type}
          />
        </div>
      )}

      {touched && error && (
        <span className={error ? "parsley-errors-list" : ""}>{error}</span>
      )}

      {hasApproval && (
        <div>
          <div className="readOnly has-approval">
            {dayjs(input.value).format("DD/MM/YYYY")}
          </div>
          <span className="parsley-errors-list">{approvalMessage()}</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default dateField;
