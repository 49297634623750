import React from "react";
import { Field } from "redux-form";
import RenderField from "../utils/renderField";
import { Button } from "reactstrap";
import DeleteButton from "../utils/DeleteButton";

const Options = (props) => {
  const { fields } = props;

  return (
    <>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={index}>
            <div className="col-lg-5 form-group">
              <Field
                component={RenderField}
                name={`${field}.label`}
                label="Label"
              />
            </div>
            <div className="col-lg-5 form-group">
              <Field
                component={RenderField}
                name={`${field}.value`}
                label="Value"
              />
            </div>
            <div className="col-lg-2 d-flex align-items-end form-group">
              <Button
                color="danger"
                block
                outline
                onClick={() => fields.remove(index)}
              >
                Delete
              </Button>
            </div>
          </React.Fragment>
        );
      })}
      <div className="col-12 form-group">
        <Button color="primary" onClick={() => fields.push()}>
          Add New Option
        </Button>
      </div>
    </>
  );
};

export default Options;
