import _ from "lodash";
import React from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import { OrgChartComponent } from "./OrgChartComponent";
import { OrgChartPosition } from "./OrgChartPositionTypes";

const OrganisationPositionChart = () => {
  const {
    data: positions,
    loading,
    refreshData,
    takeAction,
  } = useApi(
    "organisation-positions?filter[has_parent]=true&show_users=true",
    [],
    true,
  );

  const correctedData = positions.map((d: OrgChartPosition) => {
    return {
      ...d,
      parentId: d.is_head ? "" : d.parent_id ?? null,
    };
  });

  const sortedData = _.sortBy(correctedData, ["parent_id", "chart_order"]);

  const groupedData = _.groupBy(sortedData, "parent_id");

  const indexedGroupedData = _.mapValues(groupedData, function (group) {
    return group.map((position, index) => ({
      ...position,
      index,
      has_left: _.first(group) !== position,
      has_right: _.last(group) !== position,
    }));
  });

  const formattedData = Object.values(indexedGroupedData).flat();

  function updateChild(position: OrgChartPosition) {
    takeAction("update", `organisation-positions/${position.uuid}`, {
      ...position,
    })
      .then(() => {
        toast.success(`Position Updated  Successfully.`);
        refreshData();
      })
      .catch(errorSwal);
  }

  function changeOrder({
    position,
    index,
  }: {
    position: OrgChartPosition;
    index: number;
  }) {
    const group = formattedData.filter(
      (p) => p.parent_id === position.parent_id,
    );

    group.splice(index, 0, group.splice(position.index, 1)[0]);
    const positions = group.map((p, index) => ({
      ...p,
      chart_order: index,
    }));
    takeAction("update", `organisation-chart-order`, { positions })
      .then(() => {
        toast.success(`Order Changed  Successfully.`);
        refreshData();
      })
      .catch(errorSwal);
  }

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage="Organisation Chart"
        crumbs={[
          {
            link: "",
            name: "Position Chart",
            active: true,
          },
        ]}
      />
      <>
        {formattedData ? (
          <div
            style={{
              width: "99vw",
              left: "0",
              position: "absolute",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <OrgChartComponent
              data={formattedData}
              addChild={updateChild}
              removeChild={updateChild}
              changeOrder={changeOrder}
            />
          </div>
        ) : (
          <div className="signin-wrapper">No Data available</div>
        )}
      </>
    </>
  );
};

export default OrganisationPositionChart;
