import { Link } from "react-router-dom";
import { useAuth } from "../../../context/auth-context";
import { TMicrosoftIntegration } from "../../microsoft/microsoftTypes";

const msScopes = [
  "Calendars.Read",
  "Calendars.ReadWrite",
  "Directory.Read.All",
  "Directory.ReadWrite.All",
  "Team.Create",
  "Sites.FullControl.All",
  "Sites.Manage.All",
  "Sites.Read.All",
  "Sites.ReadWrite.All",
  "Team.Create",
  "Team.ReadBasic.All",
  "TeamSettings.ReadWrite.All",
  "TeamMember.ReadWrite.All",
  "TeamMember.ReadWriteNonOwnerRole.All",
  "User.Export.All",
  "User.ManageIdentities.All",
  "User.Read",
  "User.Read.All",
  "User.ReadWrite.All",
  "Files.Read.All",
  "Files.ReadWrite.All",
  "Tasks.ReadWrite.All",
  "Mail.ReadWrite",
  "Mail.Send",
];

const MicrosoftIntegration = ({
  isIntegrated,
}: {
  isIntegrated?: TMicrosoftIntegration;
}) => {
  const { user } = useAuth();

  const msGraphQuery = [
    {
      key: "client_id",
      value: "134a5cd2-f594-4941-8ce2-381aade73dc7",
    },
    {
      key: "response_type",
      value: "code",
    },
    {
      key: "redirect_uri",
      value: `${window.location.origin}/redirect/ms-graph`,
    },
    {
      key: "state",
      value: user?.active_organisation.uuid ?? "-",
    },
  ];

  if (isIntegrated) {
    msGraphQuery.push({
      key: "prompt",
      value: "consent",
    });
  }
  const params = new URLSearchParams();

  msGraphQuery.forEach((item) => {
    params.append(item.key, item.value);
  });

  const msGraphUri = `https://login.microsoftonline.com/common/adminconsent?${params.toString()}`;

  return (
    <div className="col-lg-4">
      {isIntegrated ? (
        <>
          <Link to={`/integrations/microsoft/${isIntegrated.uuid}/details`}>
            <div className="d-flex justify-content-center align-items-center bg-white p-4 border rounded-lg">
              <img
                style={{
                  width: "100px",
                }}
                src="/img/microsoft_logo.png"
                alt="Microsoft"
              />
            </div>
          </Link>
          <a
            href={`https://login.microsoftonline.com/common/adminconsent?${params.toString()}`}
          >
            Get new permissions
          </a>
        </>
      ) : (
        <a target="_blank" rel="noopener noreferrer" href={msGraphUri}>
          <div className="d-flex justify-content-center align-items-center bg-white p-4 border rounded-lg">
            <img
              style={{
                width: "100px",
              }}
              src="/img/microsoft_logo.png"
              alt="Microsoft"
            />
          </div>
        </a>
      )}
    </div>
  );
};

export default MicrosoftIntegration;
