import "chart.js/auto";
import React from "react";
import * as chartjs from "chart.js";
import * as d3 from "d3";
import _ from "lodash";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import colors from "../../data/colors";
import moneyOptions from "../charts/moneyOptions";
import { IProjectManagerProject } from "./projectManagerTypes";

const ProjectManagerInvoicedGraph = ({
  project,
}: {
  project: IProjectManagerProject;
}) => {
  return <Line data={data(project)} options={moneyOptions} />;
};

const data = (project: IProjectManagerProject): chartjs.ChartData<"line"> => {
  const range = d3.timeMonth.range(
    dayjs(project.scheduled_start_date).subtract(1, "month").toDate(),
    dayjs(project.scheduled_finish_date).toDate(),
  );

  return {
    labels: range.map((date) => dayjs(date).format("MMM YYYY")),
    datasets: [
      {
        label: "Amount Invoiced",
        data: range.map((date) => {
          return (
            _.sumBy(
              project.monthly_invoiced[dayjs(date).format("YYYY-MM")],
              "total_cost",
            ) ?? 0
          );
        }),
        backgroundColor: colors.success.light,
        borderColor: colors.success.border,
        borderWidth: 1,
      },
      {
        label: "Estimated Amount Invoiced",
        data: range.map(() => {
          return project.estimated_value_per_month;
        }),
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: colors.info.border,
        borderWidth: 1,
      },
    ],
  };
};

export default ProjectManagerInvoicedGraph;
