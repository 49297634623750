import dayjs from "dayjs";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { reduxForm } from "redux-form";
import { resetForm } from "../../../actions/utilActions";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import errorSwal from "../../utils/errorSwal";
import SubmitButton from "../../utils/SubmitButton";
import GeneralFields from "./generalFields";
import validate from "./scheduleValidation";
import { toast } from "react-toastify";

const ScheduleSiteInspection = (props) => {
  const { resetForm, form, handleSubmit } = props;

  const { projectUuid } = useParams();

  const history = useHistory();

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    values.general.scheduled_at = dayjs(values.general.scheduled_at).format(
      "YYYY-MM-DD HH:mm:ss",
    );

    values.general.scheduled_finish = dayjs(
      values.general.scheduled_finish,
    ).format("YYYY-MM-DD HH:mm:ss");

    return takeAction("store", "siteinspections/residential", {
      ...values,
      projectUuid,
    })
      .then(({ data }) => {
        history.push(`/siteinspections/residential/${data.data.uuid}`);
        toast.success("Site Inspection Scheduled");
      })
      .catch(errorSwal);
  };

  useEffect(() => {
    return () => resetForm(form);
  }, []);

  return (
    <>
      <HeaderPage
        titlePage="Schedule New Residential Site Inspection"
        crumbs={[
          { link: "siteinspections", name: "Site Inspections" },
          {
            link: "siteinspections/scheduled",
            name: "Scheduled Site Inspections",
          },
          { link: "", name: "Schedule Site Inspection", active: true },
        ]}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <GeneralFields />
          <div className="col-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const form = reduxForm({
  validate,
  form: "scheduleResidentialSiteInspection",
  initialValues: { general: { reference_number: "" } },
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default connect(null, { resetForm })(form(ScheduleSiteInspection));
