import { useMemo } from "react";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";

const FieldInformationPopOver = ({
  information = "",
  id = null,
  link = null,
}) => {
  const backupId = useMemo(
    () => `information_${Math.floor(Math.random() * 100000 + 10000)}`,
    [],
  );

  id = id ?? backupId;

  if (!information && !link) {
    return null;
  }

  return (
    <>
      {link || (
        <>
          <Button
            id={id}
            type="button"
            color="link"
            className="p-0 ms-1 text-dark"
          >
            <i className="fa fa-info-circle tx-secondary" />
          </Button>
          <UncontrolledPopover placement="bottom" target={id}>
            <PopoverBody>{information}</PopoverBody>
          </UncontrolledPopover>
        </>
      )}
    </>
  );
};

export default FieldInformationPopOver;
