import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import buttonGroup from "../utils/buttonGroup";
import errorSwal from "../utils/errorSwal";
import required from "../utils/required";
import isSubmitting from "../utils/submitting";

const CHARGEABLE = [
  { value: "App\\Models\\Organisation", label: "Organisation" },
  { value: "App\\Models\\OrganisationClient", label: "Client" },
  { value: "App\\Models\\Project", label: "Project" },
  { value: "App\\Models\\Tender", label: "Tender" },
];

const DuplicatePrices = (props) => {
  const {
    handleSubmit,
    submitting,
    chargeableId,
    chargeableType,
    setOpen,
    reset,
    setPrices,
    prices,
  } = props;

  const {
    takeAction: getPricing,
    setUrl,
    loading: loadingPricing,
    data: options,
  } = useApi();

  const { user } = useAuth();

  const { takeAction } = useApi();

  const [selectedCharge, setSelectedCharge] = useState("");

  const submit = (values) => {
    const data = {
      ...values,
      duplicate_id: chargeableId,
      duplicate_type: chargeableType,
    };

    if (!values.chargeable_id) {
      data.chargeable_id = user.active_organisation.id;
    }

    return takeAction("store", "duplicate-pricing", data)
      .then(({ data }) => {
        toast.success("Prices successfully duplicated");

        reset("DuplicatePrices");

        setOpen(false);
        setPrices([...prices, ...data.data]);
      })
      .catch(errorSwal);
  };

  const changeType = (e, val) => {
    if (val !== CHARGEABLE[0].value) {
      setUrl(`duplicate-pricing?chargeableType=${encodeURIComponent(val)}`);
    }

    setSelectedCharge(val);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            name="chargeable_type"
            label="Type"
            component={buttonGroup}
            options={CHARGEABLE}
            groupClass="w-100"
            buttonClass="w-100"
            onChange={changeType}
            validate={required}
          />
        </div>
        {selectedCharge && selectedCharge !== CHARGEABLE[0].value && (
          <div className="col-12 form-group">
            <Field
              name="chargeable_id"
              component={SelectInput}
              options={options ?? []}
              validate={required}
            />
            {loadingPricing && (
              <small>
                <i className="fa fa-spinner fa-spin" /> Loading...
              </small>
            )}
            {!loadingPricing && options?.length === 0 ? (
              <small>
                No{" "}
                {selectedCharge === CHARGEABLE[1].value
                  ? "clients"
                  : "projects"}{" "}
                found
              </small>
            ) : null}
          </div>
        )}
        <div className="col-12">
          <Button disabled={submitting} block color="primary">
            {isSubmitting(submitting, "Duplicate", "Duplicating...")}
          </Button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "DuplicatePrices" });

export default form(DuplicatePrices);
