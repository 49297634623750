import RightModal from "../utils/RightModal";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CustomScaleLoader from "../utils/scaleLoader";
import TestHistoryList from "./TestHistoryList";
import useApi from "../api/useApi";
import { useEffect } from "react";

const TestHistoryModal = ({ modal, toggle, test }) => {
  const { data: history, loading, setUrl } = useApi(``, [], true);

  useEffect(() => {
    if (test) {
      setUrl(`tests/${test.uuid}/history`);
    }
  }, [test]);

  return (
    <RightModal modal={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {test?.sample_number_id} - {test?.method} History
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <CustomScaleLoader>
            <p>Fetching History...</p>
          </CustomScaleLoader>
        ) : (
          <TestHistoryList history={history} />
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-outline-secondary bd-0"
          onClick={toggle}
        >
          Close
        </button>
      </ModalFooter>
    </RightModal>
  );
};

export default TestHistoryModal;
