import ReactTable from "../table/ReactTable";
import dayjs from "dayjs";

const SentDeliverableList = ({ deliverable }) => {
  if (deliverable.sent.length === 0) return null;

  const columns = [
    {
      accessorKey: "recipients",
      header: "Recipients",
      cell: (info) => {
        const value = info.getValue();
        if (value.length === 0) {
          return "-";
        }
        return value.map(({ label }) => label).join(", ");
      },
    },
    {
      accessorKey: "created_at",
      header: "Date",
      cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY HH:mm"),
    },
  ];

  return (
    <div className="mt-3">
      <p className="tx-inverse">Sent Times</p>
      <ReactTable data={deliverable.sent} columns={columns} />
    </div>
  );
};

export default SentDeliverableList;
