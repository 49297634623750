import { toast } from "react-toastify";
import { SubmissionError, reset } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import uploadToS3 from "../upload/uploadToS3";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import DeliverableTable from "./DeliverableTable";
import DeliverablesModal from "./Modal";
import { BiLinkExternal } from "react-icons/bi";

const ProfileDeliverables = (props) => {
  const { toggle, modal } = useModal();
  const { jobId, model, id, job } = props;
  const { user } = useAuth();

  const {
    loading,
    data: deliverables,
    setData: setDeliverables,
  } = useApi(`/jobs/${id}/deliverables`, []);

  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    const bucket = `organisations/${user.active_organisation.uuid}/deliverables`;
    return uploadToS3(values.file, bucket)
      .then((documents) => {
        return takeAction("store", "deliverables", {
          ...values,
          documents,
          deliverable_type: model,
          job_id: jobId,
          contacts: values.deliverable_contacts,
          recipients: values.deliverable_recipients,
        });
      })
      .then(({ data }) => {
        toast.success(`${data.data.name} added successfully`);
        setDeliverables([...deliverables, data.data]);
        toggle();
        dispatch(reset("deliverableDetails"));
      })
      .catch((err) => {
        if (err.response?.status !== 422) {
          return errorSwal(err);
        }

        if (!err.response.data?.data?.project) {
          return errorSwal(err);
        }

        throw new SubmissionError({
          _error: (
            <>
              <p className="mb-0">{err.response.data.data.message}</p>

              <p className="mb-0">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={err.response.data.data.project.link}
                >
                  {err.response.data.data.project.name} <BiLinkExternal />
                </a>
              </p>
            </>
          ),
        });
      });
  };

  if (loading) {
    return <CustomScaleLoader>Fetching Deliverables...</CustomScaleLoader>;
  }

  return (
    <>
      {user.active_organisation.id != job.organisation.id ? null : (
        <>
          <ul className="nav nav-activity-profile mg-t-20">
            <li className="nav-item">
              <button
                className="nav-link w-100 pointer-hover onFocus"
                type="button"
                onClick={toggle}
              >
                <i className="icon ion-compose tx-primary" />
                Add New Deliverable
              </button>
            </li>
          </ul>
          <DeliverablesModal
            jobId={jobId}
            {...props}
            toggle={toggle}
            modal={modal}
            onSubmit={onSubmit}
            initialValues={{
              pricing: [{}],
              deliverable_recipients: [],
            }}
          />
        </>
      )}
      <div className="mt-3">
        <DeliverableTable deliverables={deliverables} />
      </div>
    </>
  );
};

export default ProfileDeliverables;
