import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import { ITakeAction } from "../api/apiTypes";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import renderToggleInput from "../utils/renderToggleInput";
import { AccountRequest } from "./accountTypes";

interface FormValues {
  update_expected_payment_dates: boolean;
}

const AcceptRequestModal = ({
  toggle,
  modal,
  unpaidInvoices,
  takeAction,
  accountRequest,
  onCompleted,
}: {
  toggle: Function;
  modal: boolean;
  unpaidInvoices?: { name: string; link: string; uuid: string }[];
  initialValues: FormValues;
  takeAction: ITakeAction<any>;
  accountRequest: AccountRequest;
  onCompleted: () => void;
}) => {
  const onSubmit = (values: FormValues) => {
    return takeAction(
      "store",
      `complete-account-requests/${accountRequest.uuid}`,
      {
        ...values,
        accepted: true,
      },
    )
      .then(() => {
        onCompleted();
        toast.success(`Account request updated successful.`);
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      onSubmit={onSubmit}
      title="Accept Client Request"
      modal={modal}
      toggle={toggle}
      form="AcceptClientRequest"
    >
      <div className="col-12">
        <Field
          component={renderToggleInput}
          name="update_expected_payment_dates"
          label="Update Expected Payment Dates"
        />
      </div>
      <div className="col-12">
        {unpaidInvoices && unpaidInvoices.length > 0 && (
          <div className="mt-3">
            <p className="mb-1">
              The following invoices for this client have not been paid, would
              you like to update their expected payment dates to match the new
              account conditions?
            </p>
            {unpaidInvoices?.map((invoice) => {
              return (
                <Link key={invoice.uuid} to={invoice.link}>
                  {invoice.name}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </FormModal>
  );
};

export default AcceptRequestModal;
