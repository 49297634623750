import { Field, InjectedFormProps, reduxForm } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import { GroupFormData } from "./groupTypes";
import FormErrorAlert from "../form/FormErrorAlert";
import RenderField from "../utils/renderField";
import { IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";

const GroupForm = (props: InjectedFormProps<GroupFormData>) => {
  const {
    data,
  }: IUseApiWithData<
    {
      id: number;
      name: string;
      email: string;
    }[]
  > = useApi("staff", []);

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-lg-12 form-group">
          <Field
            name="name"
            type="text"
            component={RenderField}
            required
            label="Name"
          />
        </div>
        {/* <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            name="group_leader_id"
            label="Group Leader"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            isMulti
            name="group_members"
            label="Group Members"
          />
        </div> */}
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<GroupFormData>({});

export default form(GroupForm);
