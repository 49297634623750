import {
  FETCH_CALIBRATION_TRACKINGS,
  NEW_CALIBRATION_TRACKING,
  UPDATE_CALIBRATION_TRACKING,
  DELETE_CALIBRATION_TRACKING
} from "../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CALIBRATION_TRACKINGS:
      return {
        ...state,
        items: action.payload
      };
    case NEW_CALIBRATION_TRACKING:
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    case UPDATE_CALIBRATION_TRACKING:
      return {
        ...state,
        items: state.items.map(item =>
          item.uuid !== action.payload.uuid ? item : action.payload
        )
      };
    case DELETE_CALIBRATION_TRACKING:
      return {
        ...state,
        items: state.items.filter(item => item.uuid !== action.payload)
      };
    default:
      return state;
  }
}
