import { lastQuarterRange } from "./../home/ProductivityCards";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { ProductivityRange } from "./../timesheets/timesheetTypes";

export type Range = {
  start: Dayjs | undefined;
  end: Dayjs | undefined;
} | null;

const useProductivityRange = (
  defaultPeriod: ProductivityRange = lastQuarterRange,
): [ProductivityRange, (range: ProductivityRange) => any, Range] => {
  const [range, setRange] = useState<ProductivityRange>(defaultPeriod);

  return [
    range,
    setRange,
    range
      ? {
          start: range[0],
          end: range[1],
        }
      : null,
  ];
};

export default useProductivityRange;
