import dayjs from "dayjs";
import swal from "sweetalert";
const changeCompetencyDateSwal = (newDate: string, change: Function) => {
  return swal({
    icon: "info",
    title: "Change Competency Date?",
    text: `Changing to this skill level, the new expected date is ${dayjs(
      newDate,
    ).format("DD/MM/YYYY")}, would you like to update the date to this?`,
    buttons: {
      cancel: {
        text: "No",
        value: null,
        closeModal: true,
        visible: true,
      },
      delete: {
        text: "Yes",
        value: true,
        closeModal: true,
        className: "btn btn-danger align-baseline",
      },
    },
  }).then((value) => {
    if (value) {
      change("added_on", newDate);
    }
  });
};

export default changeCompetencyDateSwal;
