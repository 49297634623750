import { useParams } from "react-router-dom";
import Details from "./Details";
import {
  EmploymentHeroIntegrationPages,
  EmploymentHeroProps,
} from "./employmentHeroTypes";
import SyncUsers from "./SyncUsers";
import SyncLeaveReasons from "./SyncLeaveReasons";

const ShowEmploymentHeroPage = (props: EmploymentHeroProps) => {
  const { page } = useParams<{ page: EmploymentHeroIntegrationPages }>();

  switch (page) {
    case "details":
      return <Details initialValues={props.employmentHero} />;
    case "sync-leave-reasons":
      return <SyncLeaveReasons />;
    case "sync-users":
      return <SyncUsers />;
    default:
      return null;
  }
};

export default ShowEmploymentHeroPage;
