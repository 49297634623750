import React from "react";
import useApi from "../api/useApi";
import { FormSubmitHandler } from "redux-form";
import { PerformanceReviewTemplate } from "./perfomanceReviewTypes";
import { useHistory } from "react-router-dom";
import PerformanceReviewTemplateForm from "./PerformanceReviewTemplateForm";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import { toast } from "react-toastify";

const AddPerformanceReviewTemplate = () => {
  const { takeAction } = useApi();
  const history = useHistory();

  const onSubmit: FormSubmitHandler<PerformanceReviewTemplate> = (values) => {
    takeAction("store", "performance-reviews/templates", values)
      .then(
        ({
          data,
        }: {
          data: {
            data: PerformanceReviewTemplate;
          };
        }) => {
          history.push(
            `/performance-review-templates/${data.data.uuid}/details`,
          );

          toast.success("Performance Review Template Added");
        },
      )
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add Performance Review Template"
        crumbs={[
          { link: "performance-review-templates", name: "Performance Reviews" },
          {
            link: "performance-review-templates/add",
            name: "Add Performance Review Template",
            active: true,
          },
        ]}
      />
      <PerformanceReviewTemplateForm
        onSubmit={onSubmit}
        form="AddPerformanceReviewTemplate"
      />
    </>
  );
};

export default AddPerformanceReviewTemplate;
