import React from "react";
import { Field } from "redux-form";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import required from "../utils/required";

const ContactModal = (props) => {
  return (
    <FormModal {...props}>
      <div className="col-lg-12 form-group">
        <Field
          component={RenderField}
          validate={required}
          required
          name="name"
          label="Name"
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field component={RenderField} name="email" label="Email" />
      </div>
      <div className="col-lg-12 form-group">
        <Field component={RenderField} name="phone" label="Phone" />
      </div>
      <div className="col-lg-12 form-group">
        <Field component={RenderField} name="description" label="Description" />
        <small>Add a description e.g. position at company.</small>
      </div>
    </FormModal>
  );
};

export default ContactModal;
