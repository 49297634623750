import React, { useMemo } from "react";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useAllowanceTypes from "./hooks/useAllowanceTypes";
import { AllowanceType } from "./allowanceTypeTypes";
import ReactTable from "../table/ReactTable";
import useModal from "../hooks/useModal";
import { Button } from "reactstrap";
import { RiAddFill } from "react-icons/ri";
import FormModal from "../utils/FormModal";
import { Field, FormSubmitHandler } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import { useQueryClient } from "react-query";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import SelectInput from "../form/SelectInput";

const AllowanceTypesList = () => {
  const { modal, toggle } = useModal();

  const { takeAction }: IUseApi = useApi();

  const {
    data: payCategories,
  }: IUseApiWithData<{ name: string; id: number }[]> = useApi(
    "payroll-integrations/pay-categories",
    [],
  );

  const queryClient = useQueryClient();

  const onSubmit: FormSubmitHandler<AllowanceType> = (values) => {
    return takeAction("store", "allowance-types", values)
      .then(() => {
        queryClient.invalidateQueries("allowance-types");

        toggle();

        toast.success("Allowance Type created");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Allowance Types" />
      <PaginatedList
        indexHook={useAllowanceTypes}
        listName="allowanceTypes"
        extraButtons={() => (
          <Button onClick={toggle} className="p-0 ms-1" color="link">
            <RiAddFill aria-label="Add New Resource" className="tx-24 ms-1" />
          </Button>
        )}
        originalFilters={[]}
        list={({ data }) => {
          const allowanceTypes = data as AllowanceType[];

          return (
            <div className="col-12">
              <AllowanceTypeTable
                payCategories={payCategories}
                allowanceTypes={allowanceTypes}
              />
            </div>
          );
        }}
      />
      <AllowanceModal
        form="AllowanceType"
        onSubmit={onSubmit}
        toggle={toggle}
        modal={modal}
        payCategories={payCategories}
      />
    </>
  );
};

const AllowanceModal = ({
  toggle,
  modal,
  onSubmit,
  initialValues,
  form,
  payCategories,
}: {
  toggle: Function;
  modal: boolean;
  onSubmit?: Function;
  initialValues?: AllowanceType;
  form?: string;
  payCategories: { name: string; id: number }[];
}) => {
  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      form={form}
      title="Update Allowance Type"
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <div className="form-group col-12">
        <Field
          name="name"
          component={RenderField}
          required
          validate={required}
          label="Name"
        />
      </div>
      {payCategories.length > 0 && (
        <div className="form-group col-12">
          <Field
            name="external_integration_id"
            component={SelectInput}
            label="Pay Category"
            required
            validate={required}
            options={payCategories.map((payCategory) => ({
              label: payCategory.name,
              value: payCategory.id,
            }))}
          />
        </div>
      )}
    </FormModal>
  );
};

const AllowanceTypeTable = ({
  allowanceTypes,
  payCategories,
}: {
  allowanceTypes: AllowanceType[];
  payCategories: { name: string; id: number }[];
}) => {
  const columns = useMemo(
    () => [
      { accessorKey: "name", header: "Name" },
      {
        accessorKey: "Actions",
        header: "actions",
        cell: (info: any) => {
          const { modal, toggle } = useModal();
          const queryClient = useQueryClient();
          const { takeAction, loading }: IUseApi = useApi();

          const onSubmit: FormSubmitHandler<AllowanceType> = (values) => {
            return takeAction(
              "update",
              `allowance-types/${values.uuid}`,
              values,
            )
              .then(() => {
                toggle();
                toast.success("Allowance Type updated");
                queryClient.invalidateQueries("allowance-types");
              })
              .catch(formError);
          };

          const parsed = parseInt(info.row.original.external_integration_id);

          return (
            <>
              <div className="d-flex align-items-center space-x-2">
                <Button
                  onClick={toggle}
                  size="sm"
                  color="primary"
                  outline
                  disabled={loading}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    deleteSwal(info.row.original.name)
                      .then(() =>
                        takeAction(
                          "destroy",
                          `allowance-types/${info.row.original.uuid}`,
                        ),
                      )
                      .then(() => {
                        toast.success("Allowance Type Deleted");
                        queryClient.invalidateQueries("allowance-types");
                      })
                      .catch(errorSwal);
                  }}
                  size="sm"
                  color="danger"
                  outline
                  disabled={loading}
                >
                  Delete
                </Button>
              </div>
              <AllowanceModal
                onSubmit={onSubmit}
                initialValues={{
                  ...info.row.original,
                  external_integration_id: !isNaN(parsed)
                    ? parsed
                    : info.row.original.external_integration_id,
                }}
                toggle={toggle}
                form={`AllowanceType${info.row.original.uuid}`}
                modal={modal}
                payCategories={payCategories}
              />
            </>
          );
        },
      },
    ],
    [allowanceTypes, payCategories],
  );

  return <ReactTable columns={columns} data={allowanceTypes} disableSearch />;
};

export default AllowanceTypesList;
