import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import SKILL_LEVEL_UNITS from "../../data/skill_level_units";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import useApi from "../api/useApi";
import renderToggleInput from "../utils/renderToggleInput";

const Form = (props) => {
  const { handleSubmit, setLevel } = props;

  const isInitial = props.units == "0";
  const { takeAction } = useApi();
  const { uuid } = useParams();

  const onSubmit = (values) => {
    return takeAction("update", `skill-levels/${uuid}`, values)
      .then(({ data }) => {
        setLevel(data.data);
        toast.success(`${values.name} updated.`);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <Field
            validate={required}
            required
            component={RenderField}
            name="name"
            label="Name"
          />
        </div>
        <div className={`form-group col-${isInitial ? "12" : "6"}`}>
          <Field
            validate={required}
            required
            label="Units"
            component={SelectInputAsync}
            name="units"
            options={SKILL_LEVEL_UNITS}
            order={false}
          />
          <small>How often does this level need to be renewed?</small>
        </div>
        {!isInitial && (
          <div className="form-group col-6">
            <Field
              validate={required}
              required
              label="Amount"
              component={RenderField}
              name="amount"
              type="number"
            />
          </div>
        )}
        <div className="form-group col-lg-12">
          <Field
            name="notify_hr"
            label="Notify HR on Completion?"
            component={renderToggleInput}
          />
        </div>
        <div className="form-group col-12 mg-t-20">
          <Field
            validate={required}
            required
            label="Remarks"
            component={RenderField}
            name="remarks"
            textarea
          />
        </div>
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    units: selector(state, "units"),
  };
};

export default connect(mapStateToProps, {})(reduxForm()(Form));
