import React from "react";
import { Button } from "reactstrap";
import isSubmitting from "./submitting";

const LoadableButton = (props) => {
  const { disabled, text = "Submit", submittingText = "Submitting..." } = props;

  return (
    <Button {...props}>{isSubmitting(disabled, text, submittingText)}</Button>
  );
};

export default LoadableButton;
