import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge, Button } from "reactstrap";
import { useAuth } from "../../../context/auth-context";
import JobStatus from "../../../enums/JobStatus";
import useApi from "../../api/useApi";
import ApprovalButton from "../../approvals/ApprovalButton";
import ApprovalComments from "../../approvals/ApprovalComments";
import SideList from "../../comments/SideList";
import HeaderPage from "../../header/HeaderPage";
import useUploadCustomFields from "../../hooks/useUploadCustomFields";
import getStatus from "../../jobs/status";
import ProfilePage from "../../profile";
import ProfileViewsList from "../../profileViews/ProfileViewsList";
import ApprovalStatus from "../../user/ApprovalStatus";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../../utils/DashboardHeader";
import errorSwal from "../../utils/errorSwal";
import formError from "../../utils/formError";
import Spinner from "../../utils/Spinner";
import AddUnderstandingButton from "./AddUnderstandingButton";
import DownloadDropdown from "./DownloadDropdown";
import MarkAsUnderstoodButton from "./MarkAsUnderstoodButton";
import ShowPage from "./ShowPage";
import UserMustSign from "./UserMustSign";
import generateIcs from "../../utils/generateIcs";

const Profile = () => {
  const { uuid } = useParams();

  const { user } = useAuth();

  const {
    takeAction: generatePrompt,
    loading: generatingPrompt,
    setLoading,
  } = useApi();

  const {
    data: job,
    setData: setJob,
    loading,
    response,
    refreshData,
  } = useApi(`custom-jobs/jobs/${uuid}`, {}, true, "/jobs");

  const showTimesheet =
    job?.scheduled_start_time &&
    job?.scheduled_finish_time &&
    job.project_manager === user?.id &&
    job.status == JobStatus.Completed;

  const tabs = [
    {
      link: `/custom-jobs/jobs/${uuid}/details`,
      icon: "fa-pencil",
      page: "details",
      label: "Details",
    },
    {
      link: `/custom-jobs/jobs/${uuid}/documents`,
      icon: "fa-folder-open-o",
      page: "documents",
      label: "Documents",
    },
    {
      link: `/custom-jobs/jobs/${uuid}/external-requests`,
      icon: "fa-paper-plane-o",
      page: "external-requests",
      label: "Send Job Externally",
    },
  ];

  if (showTimesheet) {
    tabs.push({
      link: `/custom-jobs/jobs/${uuid}/timesheet`,
      icon: "fa-clock-o",
      page: "timesheet",
      label: "Add Timesheet",
    });
  }

  if (!job?.project?.internal) {
    tabs.push({
      link: `/custom-jobs/jobs/${uuid}/deliverables`,
      icon: "fa-briefcase",
      page: "deliverables",
      label: "Deliverables",
    });
  }

  if (job.must_be_understood) {
    tabs.push({
      link: `/custom-jobs/jobs/${uuid}/understandings`,
      icon: "fa-pencil-square-o",
      page: "understandings",
      label: "Sign Ins",
    });
  }

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/custom-jobs/${uuid}`,
  );

  const { takeAction } = useApi();

  const onPrompt = (
    fieldUuid,
    prompt,
    name,
    change,
    attachments = [],
    setAnnotations,
  ) => {
    return generatePrompt("store", `custom-fields/${fieldUuid}/prompts`, {
      prompts: [
        {
          role: "user",
          content: prompt,
          attachments: attachments.map((a) => ({
            file_id: a.id,
            tools: [
              {
                type: "file_search",
              },
            ],
          })),
        },
      ],
      modelType: "App\\Models\\ProjectJob",
      modelId: job?.id,
    })
      .then(({ data }) => {
        if (data.data.cached_thread_id) {
          setLoading(true);
          const checkThread = () => {
            return takeAction(
              "show",
              `open-ai/run/${data.data.run_id}/threads/${data.data.cached_thread_id}`,
            )
              .then(({ data }) => {
                if (data.data) {
                  toast.success("Prompt added");
                  change(name, data.data);

                  setLoading(false);

                  return;
                }

                return setTimeout(checkThread, 3000);
              })
              .catch((err) => {
                setLoading(false);
                errorSwal(err);
              });
          };

          return checkThread();
        }

        toast.success("Prompt added");

        change(name, data.data.content.text.value);

        if (setAnnotations && data.data.content.text.annotations.length > 0) {
          setAnnotations(data.data.content.text.annotations);
        }
        //Replace the last .value of the 'name' to .thread_id
        const nameArray = name.split(".");

        nameArray[nameArray.length - 1] = "thread_id";

        change(nameArray.join("."), data.data.thread_id);
      })
      .catch(errorSwal);
  };

  const onSubmit = (values) => {
    return upload(values)
      .then((values) => {
        return takeAction("update", `custom-jobs/jobs/${uuid}`, values);
      })
      .then(({ data }) => {
        setJob(data.data);
        toast.success("Job updated");
      })
      .catch(formError);
  };

  if (loading || !response) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={job?.name}
        crumbs={[
          { name: "Projects", link: "/projects" },
          { name: job?.project?.name, link: `/${job?.project?.link}` },
          { name: job?.name, link: job?.link },
          { name: job?.type?.name, link: "", active: true },
        ]}
      />
      <UserMustSign toSign={job.user_must_sign} />
      <div className="row row-sm justify-content-end">
        <div className="col-lg-4 m-1">
          <ProfileViewsList
            viewable={job}
            viewable_type={"App\\Models\\ProjectJob"}
          />
        </div>
      </div>
      <ProfilePage
        header={
          <DashboardHeader>
            <DashboardH3>
              <Link to={job?.link}>{job?.name}</Link>
            </DashboardH3>

            <DashboardSubTitle>
              Client:{" "}
              <Link to={`${job?.project?.client?.link}`}>
                {job?.project?.client?.name}
              </Link>
            </DashboardSubTitle>
            <DashboardSubTitle>
              Project:{" "}
              <Link to={`/${job?.project?.link}`}>{job?.project?.name}</Link>
            </DashboardSubTitle>
            <div className="d-flex">
              <p className="mg-b-0 mg-t-10 space-x-1">
                {getStatus(job?.status)}
                {(job?.critical_path === 1 || job?.critical_path === true) && (
                  <Badge className="rounded-pill shadow" color="danger">
                    Critical Path
                  </Badge>
                )}
                {job?.is_overdue && (
                  <Badge className="rounded-pill shadow" color="danger">
                    Overdue
                  </Badge>
                )}
              </p>
              {job.sign_in_required && (
                <div className="align-self-center">
                  {" "}
                  <Badge className="rounded-pill shadow" color="info">
                    Sign in Required
                  </Badge>
                </div>
              )}
              <div className="ms-auto">
                <MarkAsUnderstoodButton
                  understandableType="App\Models\ProjectJob"
                  understandableId={job.id}
                  toMarkAsUnderstood={job.to_mark_as_understood}
                  onComplete={() => {
                    setJob({
                      ...job,
                      to_mark_as_understood: false,
                      user_must_sign: [],
                    });
                  }}
                />
              </div>
            </div>
            <AddUnderstandingButton job={job} setJob={setJob} />
            <Button
              color="link"
              className="ps-3 mt-3"
              onClick={() =>
                generateIcs(`${job.name}.ics`, {
                  description: job.description,
                  dtStart: new Date(
                    `${job.scheduled_start_date} ${
                      job.scheduled_start_time ?? ""
                    }`,
                  ),
                  dtEnd: new Date(
                    `${job.scheduled_finish_date} ${
                      job.scheduled_finish_time ?? ""
                    }`,
                  ),
                  summary: job.name,
                  location: job.project?.formatted_address,
                  url: `${window.location.origin}${location.pathname}`,
                })
              }
            >
              Download Calendar Event
            </Button>
          </DashboardHeader>
        }
        content={
          <>
            <DownloadDropdown
              model={{ model: "App\\Models\\ProjectJob", id: job?.id }}
              documents={job?.template?.documents}
              emails={job?.emails}
              {...job}
              job={job}
            />

            <ShowPage
              onSubmit={onSubmit}
              form="EditCustomJobJob"
              job={job}
              initialValues={job}
              setJob={setJob}
              onPrompt={onPrompt}
              generatingPrompt={generatingPrompt}
            />
          </>
        }
        tabs={tabs}
        sideBar={
          <>
            <ApprovalButton
              onSubmitted={refreshData}
              approvalAction={job.approval_action}
              approvalOptions={[
                { value: 1, label: "Approve" },
                { value: 2, label: "Redo" },
                { value: 3, label: "Deny" },
              ]}
            />

            <ApprovalStatus
              className="w-100 my-3"
              status={job.approval_status}
            />
            <div className="mt-3">
              <ApprovalComments
                modelType="App\Models\ProjectJob"
                modelId={job?.id}
              />
            </div>
            <div className="mt-3">
              <SideList
                commentableType="App\Models\ProjectJob"
                commentableId={job?.id}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default Profile;
