const test_depths = [
  {
    value: 25,
    label: "25mm",
  },
  {
    value: 50,
    label: "50mm",
  },
  {
    value: 75,
    label: "75mm",
  },
  {
    value: 100,
    label: "100mm",
  },
  {
    value: 125,
    label: "125mm",
  },
  {
    value: 150,
    label: "150mm",
  },
  {
    value: 175,
    label: "175mm",
  },
  {
    value: 200,
    label: "200mm",
  },
  {
    value: 225,
    label: "225mm",
  },
  {
    value: 250,
    label: "250mm",
  },
  {
    value: 275,
    label: "275mm",
  },
  {
    value: 300,
    label: "300mm",
  },
];

export default test_depths;
