import React from "react";
import dayjs from "dayjs";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import getFileIcon from "../utils/getFileIcon";
import documentProps from "./documentProps";

const FileListItem = (props) => {
  const { document, more, id } = props;

  return (
    <div className="file-item" id={id}>
      <div className="row no-gutters wd-100p">
        <div className="col-9 col-sm-7 d-block text-truncate align-items-center d-flex">
          <i
            className={`${getFileIcon(document.name, document.is_folder)} me-3`}
          />
          <div className="">
            {document.name}
            {document.organisation ? (
              <small className="d-block">{document.organisation.name}</small>
            ) : null}
          </div>
        </div>

        <div className="col-6 col-sm-3 mg-t-5 mg-sm-t-0">
          {dayjs(document.created_at).format("DD MMMM YYYY")}
        </div>

        {more && (
          <div className="col-6 col-sm-2 tx-right mg-t-5 mg-sm-t-0">
            <UncontrolledDropdown
              onClick={(e) => {
                e.preventDefault();
                return e.stopPropagation();
              }}
            >
              <DropdownToggle color="link" className="text-secondary">
                <i className="icon ion-more" />
                <i className="icon wb-more-vertical" aria-hidden="true" />
              </DropdownToggle>
              <DropdownMenu>{more}</DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </div>
    </div>
  );
};

FileListItem.propTypes = {
  ...documentProps,
};

export default FileListItem;
