import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import isSubmitting from "../utils/submitting";

const SignUpUser = (props) => {
  const { handleSubmit, submitting, history } = props;
  const { uuid } = useParams();
  const { setUser } = useAuth();

  const submit = (values) => {
    return axios
      .put(`signed-up-users/${uuid}`, values)
      .then(({ data }) => {
        setUser(data.data);
        props.history.push("/home");
        history.go();
        toast.success("Sign up successful");
      })
      .catch(errorSwal);
  };

  useEffect(() => {
    axios.get(`signed-up-users/${uuid}`).catch((err) => {
      toast.error("Could not find user");
      return history.push("/login");
    });
  }, []);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="d-flex justify-content-center align-items-center vh-100 signin-wrapper "
    >
      <div className="signin-box signup">
        <div className="card-body">
          <h2 className="slim-logo">
            <a href="/">The BOSS App</a>
            <h3 className="signin-title-primary">Sign Up!</h3>
          </h2>
          <div className="row">
            <div className="col-12 form-group">
              <Field
                component={renderField}
                name="password"
                label="Password"
                type="password"
                validate={required}
                required
              />
            </div>
            <div className="col-12 form-group">
              <Field
                component={renderField}
                name="password_confirmation"
                label="Confirm Password"
                type="password"
                validate={required}
                required
              />
            </div>
            <div className="col-12 form-group">
              <Field
                validate={required}
                required
                component={renderToggleInput}
                label="Terms of Service"
                name="accepted"
              />
              <small className="d-block">
                You have read and agree to The BOSS App's{" "}
                <a
                  href="https://landing.thebossapp.com.au/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms of service
                </a>
                .
              </small>
            </div>
            <div className="col-12 form-group">
              <button
                type="submit"
                className="btn btn-primary w-100 btn-signin"
                disabled={submitting}
              >
                {isSubmitting(submitting, "Sign Up", "Signing Up...")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "SignUp" });

export default form(SignUpUser);
