import HeaderPage from "../header/HeaderPage";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import { IXeroIntegration } from "./xeroTypes";
import useApi from "../api/useApi";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import ShowXeroIntegrationPage from "./ShowXeroIntegrationPage";

export type XeroIntegrationProfilePages =
  | "accounts"
  | "contacts"
  | "tracking-categories"
  | "contact-groups"
  | "reports"
  | "extra-info"
  | "loan-accounts";

const XeroIntegrationProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading }: IUseApi<IXeroIntegration> = useApi(
    `/integrations/${uuid}`,
    null,
    true,
  );

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Xero" />
      <Profile<XeroIntegrationProfilePages>
        header={
          <DashboardHeader>
            <DashboardH3>Xero</DashboardH3>
          </DashboardHeader>
        }
        tabs={[
          {
            label: "Contacts",
            link: `/integrations/xero/${uuid}/contacts`,
            page: "contacts",
          },
          {
            label: "Setup",
            link: `/integrations/xero/${uuid}/extra-info`,
            page: "extra-info",
          },
          // {
          //   label: "Accounts",
          //   link: `/integrations/xero/${uuid}/accounts`,
          //   page: "accounts",
          // },
          // {
          //   label: "Tracking Categories",
          //   link: `/integrations/xero/${uuid}/tracking-categories`,
          //   page: "tracking-categories",
          // },
          {
            label: "Contact Groups",
            link: `/integrations/xero/${uuid}/contact-groups`,
            page: "contact-groups",
          },
          {
            label: "Reports",
            link: `/integrations/xero/${uuid}/reports`,
            page: "reports",
          },
        ]}
        content={<ShowXeroIntegrationPage xero={data} />}
      />
    </>
  );
};

export default XeroIntegrationProfile;
