import React, { FunctionComponent } from "react";
import { ReactComponent as BoxEmpty } from "../../svgs/empty.svg";
import SVGContainer from "./SVGContainer";

interface EmptyProps {
  width?: string;
  height?: string;
  top?: boolean;
  children?: React.ReactNode;
}

const Empty: FunctionComponent<EmptyProps> = ({
  children,
  height,
  width,
  top = true,
}) => {
  return (
    <SVGContainer top={top} height={height} width={width} SVG={BoxEmpty}>
      {children}
    </SVGContainer>
  );
};

export default Empty;
