import React, { useState } from "react";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { Goal } from "./userTypes";
import FormHeader from "../utils/FormHeader";
import CustomScaleLoader from "../utils/scaleLoader";
import { Button } from "reactstrap";
import FormModal from "../utils/FormModal";
import { Field, FormSubmitHandler } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import useModal from "../hooks/useModal";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import UserGoalItem from "./UserGoalItem";
import { percentage } from "../form/formatters";
import SVGContainer from "../utils/SVGContainer";
import { ReactComponent as Empty } from "../../svgs/empty.svg";
import TextButton from "../utils/TextButton";

const UserGoals = ({
  uuid,
  queryString = "",
  initialValues,
}: {
  uuid: string;
  queryString?: string;
  initialValues?: Partial<Goal>;
}) => {
  const {
    data: goals,
    setData: setGoals,
    loading,
  }: IUseApiWithData<Goal[]> = useApi(
    `/users/${uuid}/goals?${queryString}`,
    [],
    true,
  );

  const { takeAction }: IUseApi<{}, { data: Goal }> = useApi();

  const { toggle, modal } = useModal();

  const onSubmit: FormSubmitHandler<Goal> = (values) => {
    return takeAction("store", `/users/${uuid}/goals`, values)
      .then(({ data }) => {
        setGoals([data.data, ...goals]);
        toggle();
        toast.success("Goal Added");
      })
      .catch(formError);
  };

  return (
    <>
      <div className="d-flex align-content-center">
        <label className="section-title mt-0">Goals</label>
        <Button
          onClick={toggle}
          color="primary"
          outline
          size="sm"
          className="ms-auto"
        >
          Add Goal
        </Button>
      </div>
      <br className="w-100" />
      {loading ? (
        <CustomScaleLoader>Fetching Goals...</CustomScaleLoader>
      ) : (
        <div className="space-y-3">
          {goals.length === 0 ? (
            <SVGContainer top={false} SVG={Empty}>
              <p className="mt-5">
                No goals found{" "}
                <TextButton onClick={toggle}>click here</TextButton> to start
                adding.
              </p>
            </SVGContainer>
          ) : null}
          {goals.map((goal) => {
            return (
              <UserGoalItem
                goals={goals}
                setGoals={setGoals}
                key={goal.id}
                goal={goal}
              />
            );
          })}
        </div>
      )}
      <GoalModal
        goal={initialValues}
        toggle={toggle}
        modal={modal}
        onSubmit={onSubmit}
      />
    </>
  );
};

export const GoalModal = ({
  goal,
  toggle,
  modal,
  onSubmit,
  title = "Add Goal",
  form = "GoalModal",
}: {
  form?: string;
  goal?: Partial<Goal>;
  toggle: () => void;
  modal: boolean;
  onSubmit: FormSubmitHandler<Goal>;
  title?: string;
}) => {
  const [percentComplete, setPercentComplete] = useState(
    goal?.percentage_complete ?? 0,
  );

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title={title}
      form={form}
      initialValues={goal ?? {}}
      onSubmit={onSubmit}
    >
      <div className="form-group col-12">
        <Field
          component={RenderField}
          required
          validate={required}
          name="name"
          label="Name"
        />
      </div>

      <div className="form-group col-lg-6">
        <Field
          component={RenderField}
          type="date"
          name="start_date"
          label="Start Date"
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          component={RenderField}
          type="date"
          name="to_be_completed_by"
          label="Date to be Completed By"
        />
      </div>
      <div className="form-group col-12">
        <Field
          component={RenderField}
          textarea
          name="description"
          label="Description"
        />
      </div>
      {goal ? (
        <div className="form-group col-12">
          <Field
            component={RenderField}
            name="percentage_complete"
            label="Percentage Complete"
            type="range"
            onChange={(e: any) => setPercentComplete(e.target.value)}
            extraProps={{
              min: 0,
              max: 100,
              step: 1,
            }}
          />
          <small>{percentage.format(percentComplete)}</small>
        </div>
      ) : null}
    </FormModal>
  );
};

export default UserGoals;
