import React from "react";
import HeaderPage from "../header/HeaderPage";
import Form from "./Form";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const Add = () => {
  const { takeAction } = useApi();
  const history = useHistory();

  const onSubmit = (values) => {
    const data = {
      ...values,
      custom_fields: values.custom_fields.map((field, index) => {
        return {
          ...field,
          order: index,
        };
      }),
    };

    return takeAction("store", "required-project-information", data)
      .then(({ data }) => {
        toast.success("Information Added");
        history.push(`/required-project-information/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add New Required Project Information"
        crumbs={[]}
      />
      <Form
        onSubmit={onSubmit}
        form="AddRequiredInformation"
        initialValues={{
          custom_fields: [{}],
        }}
      />
    </>
  );
};

export default Add;
