import { OrganisationSetting } from "./../organisation/types";
import { useQuery } from "react-query";
import api from "../api/api";
import { OrganisationSettingKeys } from "../organisation/types";

const getOrganisationSettings = () =>
  api.get("organisation-settings").then(({ data }) => data.data?.settings);

const useOrganisationSetting = (
  setting: OrganisationSettingKeys,
): OrganisationSetting | undefined => {
  const { data } = useQuery("organisation_settings", getOrganisationSettings);

  return data?.find(
    ({ meta_key }: { meta_key: OrganisationSettingKeys }) =>
      meta_key === setting,
  );
};

export default useOrganisationSetting;
