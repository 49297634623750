import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import Avatar from "../utils/Avatar";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import usePositionList from "./hooks/usePositionList";
import { Position } from "./Position";
import DocsModal from "../utils/DocsModal";

const PositionsList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/positions/" />
      <HeaderPage
        titlePage="Positions"
        pageDescription={
          <>
            The positions feature in theBOSSapp assigns positions to staff,
            adding checks, credentials, and permissions to ensure that staff
            have the appropriate qualifications and access to resources.
          </>
        }
        relatedLinks={[
          {
            name: "Staff Matrix",
            link: "/staff-matrix",
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "positions", name: "Positions", active: true },
        ]}
      />

      <Link className="btn btn-sm btn-outline-primary mb-2" to="positions/add">
        Add New Position
      </Link>
      <PaginatedList
        indexHook={usePositionList}
        indexHookArguments={[]}
        originalFilters={[]}
        itemCard={({ item }: { item: Position }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<PositionHeader position={item} />}
          />
        )}
      />
    </>
  );
};

export default PositionsList;

const PositionHeader = ({ position }: { position: Position }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={position.name} colour={"info"} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={`position_title${position.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={`/positions/${position.uuid}/details`}>
              {position.division.name} - {position.name}
            </Link>
          </p>
          <UncontrolledTooltip
            placement="top-start"
            target={`position_title${position.uuid}`}
          >
            {position.division.name} - {position.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

const info = (position: Position): PaginatedInformation[] => {
  return [
    {
      name: "Name",
      value: position.name,
    },
    {
      name: "Division",
      value: position.division.name,
    },
  ];
};
