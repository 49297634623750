import React from "react";
import _ from "lodash";
import { GiMailbox } from "react-icons/gi";
import ContactDropdown from "./ContactDropdown";

const ProjectContactList = ({ contacts, onDelete, onUpdate }) => {
  if (contacts.length === 0) {
    return null;
  }

  return (
    <div className="space-y-3 p-3 pb-0">
      {_.sortBy(contacts, ["name"]).map((contact) => {
        return (
          <div className="d-flex " key={contact.uuid}>
            <div>
              <p className="mb-0 tx-inverse fw-bolder">{contact.name}</p>
              <p className="mb-0">
                <a href={`mailto:${contact.email}`} className="text-secondary">
                  {contact.email}
                </a>
              </p>
              {contact.phone && (
                <p className="mb-0">
                  <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                </p>
              )}
              {contact.description && (
                <small className="mb-0">{contact.description}</small>
              )}
            </div>
            {(contact.deliverable_recipient || contact.invoice_recipient) && (
              <GiMailbox className="ms-3 tx-success tx-24 align-self-center" />
            )}
            <div className="ms-auto space-x-3">
              <ContactDropdown
                contact={contact}
                onDelete={onDelete}
                onUpdate={onUpdate}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectContactList;
