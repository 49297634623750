import React from "react";
import * as d3 from "d3";

export function useD3<T>(
  renderChartFn: (
    svg: d3.Selection<SVGSVGElement | null, T, null, undefined>,
  ) => void,
  dependencies: any[],
) {
  const ref = React.useRef<SVGSVGElement | null>(null);

  React.useEffect(() => {
    renderChartFn(d3.select(ref?.current));
    return () => {};
  }, dependencies);

  return ref;
}
