import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge } from "reactstrap";
import { Field, formValueSelector } from "redux-form";
import swal from "sweetalert";
import JOB_STATUS from "../../data/job_status";
import JobStatus from "../../enums/JobStatus";
import useApi from "../api/useApi";
import getStatus from "../jobs/status";
import buttonGroup from "../utils/buttonGroup";
import dateTime, { formatting } from "../utils/dateTime";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import Spinner from "../utils/Spinner";

const JobReminder = (props) => {
  const { currentReminder, moveForward } = props;

  const { takeAction, loading } = useApi();

  const completeJob = (e, val) => {
    if (!val) {
      return;
    }

    return swal({
      title: `Complete ${currentReminder.name}`,
      text: `Are you sure you want to complete ${currentReminder.name}?`,
      icon: "warning",
    }).then((shouldComplete) => {
      if (shouldComplete) {
        return takeAction("update", `user-jobs/${currentReminder.uuid}`, {
          ...currentReminder,
          status: 3,
        })
          .then(() => {
            moveForward();
            toast.success("Job marked complete");
          })
          .catch(errorSwal);
      }
    });
  };

  return (
    <>
      <Spinner loading={loading} />
      <div className="col-12 mb-3">
        <Link
          to={currentReminder.link}
          className="tx-inverse mb-0 fw-bold d-block"
        >
          {currentReminder.full_name}
        </Link>
        {getStatus(currentReminder.status)}
        {(currentReminder.critical_path === 1 ||
          currentReminder.critical_path === true) && (
          <Badge color="danger">Critical Path</Badge>
        )}
        {currentReminder.client && (
          <p className="mb-0">Client: {currentReminder?.client?.name}</p>
        )}
        <p className="mb-0">Project: {currentReminder?.project?.full_name}</p>
        <p className="mb-0">Branch: {currentReminder?.branch?.name}</p>
        <p className="mb-0">Description: {currentReminder?.description}</p>
      </div>
      {["App\\Models\\StandardJobType", "App\\Models\\CPIR"].includes(
        currentReminder.job_type_type,
      ) ? (
        <StandardJobTypeForm />
      ) : (
        <JobForm {...props} completeJob={completeJob} />
      )}
    </>
  );
};

const JobForm = ({ completed, completeJob, status }) => {
  return (
    <>
      <div className="col-lg-12 form-group">
        <Field
          component={buttonGroup}
          name="completed"
          label="Completed"
          groupClass="w-100"
          buttonClass="w-100"
          onChange={completeJob}
          options={[
            {
              value: true,
              label: "Yes",
            },
            {
              value: false,
              label: "No",
            },
          ]}
        />
      </div>
      {!completed && completed !== undefined && (
        <>
          <div className="col-lg-6 form-group">
            <Field
              component={dateTime}
              name="scheduled_finish_date"
              label="New Scheduled Finish Date"
              {...formatting}
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={SelectInputAsync}
              options={JOB_STATUS}
              name="status"
              label="Status"
              order={false}
            />
          </div>
          {status == JobStatus.Cancelled && (
            <div className="col-lg-12 form-group">
              <Field
                component={RenderField}
                name="cancelled_reason"
                label="Reason for Cancellation"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

const StandardJobTypeForm = () => {
  return (
    <div className="col-12 form-group">
      <Field
        component={dateTime}
        name="scheduled_finish_date"
        label="New Scheduled Finish Date"
        {...formatting}
      />
    </div>
  );
};

const selector = formValueSelector("Reminder");

const mapStateToProps = (state) => {
  return {
    completed: selector(state, "completed"),
    status: selector(state, "status"),
  };
};

export default connect(mapStateToProps, {})(JobReminder);
