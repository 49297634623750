import { toast } from "react-toastify";
import HeaderPage from "../header/HeaderPage";
import axios from "../api/api";
import formError from "../utils/formError";
import AddTenderForm from "./AddTenderForm";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const Add = (props) => {
  const { history, match } = props;
  const { projectUuid } = useParams();

  const submit = (values) => {
    const data = {
      ...values,
      project_uuid: projectUuid,
    };

    return axios
      .post(`/tenders`, data)
      .then(({ data }) => {
        toast.success("Tender created successfully");
        return history.push(`/tenders/${data.data.uuid}/details`);
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add New Tender"
        crumbs={[
          { link: "/jobs", name: "Jobs" },
          { link: "/jobs/create", name: "Create New Job" },
          { link: "", name: "Add New Tender", active: true },
        ]}
      />
      <AddTenderForm
        initialValues={{
          job: {
            scheduled_start_date: dayjs().format("YYYY-MM-DD"),
          },
        }}
        onSubmit={submit}
        form="AddTender"
        showProject={!projectUuid}
      />
    </>
  );
};

export default Add;
