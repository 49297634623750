import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";

const RecurringPurchaseTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Purchase Number",
        cell: (info) => {
          const purchase = info.row.original;

          return (
            <>
              <Link to={purchase.link}>{purchase.name}</Link>
              {purchase.archived_at ? (
                <>
                  <br />
                  <Badge color="info">Archived</Badge>
                </>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "realised_total_cost",
        header: "Purchase Price",
        cell: (info) => <SpreadPrice price={info.getValue()} />,
      },
      {
        accessorKey: "project.name",
        header: "Project",
        cell: (info) => {
          const purchase = info.row.original;
          return (
            <Link to={`/projects/${purchase.project.uuid}/purchases`}>
              {purchase.project.name}
            </Link>
          );
        },
      },
      {
        accessorKey: "supplier.name",
        header: "Supplier",
        cell: (info) => {
          const purchase = info.row.original;

          if (purchase?.supplier?.uuid) {
            return (
              <Link to={`/suppliers/${purchase?.supplier?.uuid}/details`}>
                {info.getValue()}
              </Link>
            );
          }
          return info.getValue() || "-";
        },
      },
    ],
    [data],
  );

  return <ReactTable disableSearch columns={columns} data={data} />;
};

export default RecurringPurchaseTable;
