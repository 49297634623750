import { Field, FormSubmitHandler } from "redux-form";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";
import formError from "../utils/formError";

const WithdrawReportModal = ({
  toggle,
  modal,
  testRequestWorksheet,
  fetchTests,
}: {
  toggle: () => void;
  modal: boolean;
  testRequestWorksheet: any;
  fetchTests: Function;
}) => {
  const { takeAction } = useApi();

  const withdrawReport: FormSubmitHandler<{
    reason: string;
  }> = (values) => {
    return takeAction(
      "store",
      `test-request-worksheets/${testRequestWorksheet.uuid}/withdraw-signature`,
      values,
    )
      .then(() => {
        fetchTests();
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      onSubmit={withdrawReport}
      toggle={toggle}
      form="WITHDRAW_REPORT"
      modal={modal}
      title="Withdraw Report"
    >
      <div className="col-12">
        <Field
          name="reason"
          label="Withdraw Reason"
          component={RenderField}
          textarea
          required
          validate={required}
        />
      </div>
    </FormModal>
  );
};

export default WithdrawReportModal;
