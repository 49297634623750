import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { PerformanceReviewTemplate } from "./perfomanceReviewTypes";
import Spinner from "../utils/Spinner";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import dayjs from "dayjs";
import { FormSubmitHandler } from "redux-form";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import ShowPerformanceReviewTemplatePage from "./ShowPerformanceReviewTemplatePage";

const PerformanceReviewTemplateProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data,
    setData: setPerformanceReviewTemplate,
    loading,
  }: IUseApi<PerformanceReviewTemplate> = useApi(
    `performance-reviews/templates/${uuid}`,
    null,
    true,
  );

  const template = data as PerformanceReviewTemplate;

  const { takeAction } = useApi();

  const onSubmit: FormSubmitHandler<PerformanceReviewTemplate> = (values) => {
    return takeAction("update", `performance-reviews/templates/${uuid}`, values)
      .then(({ data }: { data: { data: PerformanceReviewTemplate } }) => {
        setPerformanceReviewTemplate(data.data);
        toast.success("Performance Review Template Updated");
      })
      .catch(formError);
  };

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage titlePage={template.name} crumbs={[]} />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{template.name}</DashboardH3>
            <p className="mb-0">
              Begins on {dayjs(template.start_date).format("DD/MM/YYYY")}
            </p>
          </DashboardHeader>
        }
        content={
          <ShowPerformanceReviewTemplatePage
            performanceReviewTemplate={template}
            setPerformanceReviewTemplate={setPerformanceReviewTemplate}
            onSubmit={onSubmit}
          />
        }
        tabs={[
          {
            link: `/performance-review-templates/${uuid}/details`,
            icon: "fa fa-file-text-o",
            page: "details",
            label: "Details",
          },
          {
            link: `/performance-review-templates/${uuid}/employee-questions`,
            icon: "fa fa-file-text-o",
            page: "employee-questions",
            label: "Employee Questions",
          },
          {
            link: `/performance-review-templates/${uuid}/manager-questions`,
            icon: "fa fa-file-text-o",
            page: "manager-questions",
            label: "Manager Questions",
          },
          {
            link: `/performance-review-templates/${uuid}/check-in-questions`,
            icon: "fa fa-file-text-o",
            page: "check-in-questions",
            label: "Check In Questions",
          },
        ]}
      />
    </>
  );
};

export default PerformanceReviewTemplateProfile;
