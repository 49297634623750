import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import DatabaseListView from "../listView/DatabaseListView";
import Spinner from "../utils/Spinner";
import { BudgetTemplate, CustomJob } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const List = () => {
  const { data: templates, loading } = useApi("templates", [], true);

  const { user } = useAuth();

  if (loading) return <Spinner loading />;

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/templates/" />
      <HeaderPageAdminFilter
        titlePage="Templates"
        pageDescription={
          <>
            Templates allow you to upload a word doc, and then have certain
            parts of it filled with variables coming from different parts of the
            application.
          </>
        }
        relatedLinks={[
          {
            name: "Upload Purchase Orders",
            link: "/upload-purchase-order",
          },
          {
            name: "Budget Templates",
            link: "/budget-templates",
            model: BudgetTemplate,
          },
          { name: "Custom Jobs", link: "/custom-jobs", model: CustomJob },
        ]}
        crumbs={[{ link: "/templates", name: "Templates", active: true }]}
      />

      <DatabaseListView
        add={
          user.hasAccessTo("App\\Models\\Template", "create")
            ? { link: "templates/add", name: "Template" }
            : null
        }
        list={templates}
        name="templates"
      />
    </>
  );
};

export default List;
