import React from "react";
import { Field, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const ProjectContactForm = (props) => {
  const { handleSubmit, contacts } = props;

  if (contacts.length === 0) {
    return null;
  }

  return (
    <div className="bg-gray-100 border-bottom p-3 mb-0">
      <form onSubmit={handleSubmit}>
        <div className="row no-gutters">
          <div className="col-9 ">
            <Field
              name="contact_id"
              options={contacts}
              component={SelectInputAsync}
              validate={required}
            />
          </div>
          <div className="col-3 ">
            <SubmitButton
              {...props}
              save="Add"
              flex={false}
              className="w-100 ms-1"
              saving="Adding..."
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const form = reduxForm({ form: "ProjectContacts" });

export default form(ProjectContactForm);
