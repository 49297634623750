import React from "react";
import { connect } from "react-redux";

import { reduxForm } from "redux-form";
import EditGeneralModal from "./EditGeneralModal";

import GeneralFields from "./generalFields";

const General = ({
  handleSubmit,
  initialValues,
  showEditModal = false,
  setSiteInspection,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="form-group col-12 d-flex align-items-center">
          <h5 className="text-dark">General Details</h5>
          {showEditModal && (
            <div className="ms-auto">
              <EditGeneralModal
                setSiteInspection={setSiteInspection}
                siteInspection={initialValues}
              />
            </div>
          )}
        </div>
        <GeneralFields disabled={!!initialValues} />
      </div>
      <div className="row mg-y-20">
        <div className="ms-auto col-6">
          <button className="btn w-100 btn-primary" type="submit">
            Weather Conditions <i className="icon ion-forward" />
          </button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({
  form: "addResidentialSiteInspection",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default connect(null, {})(form(General));
