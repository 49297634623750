import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import formError from "../../utils/formError";
import CustomScaleLoader from "../../utils/scaleLoader";
import {
  ShowTestSuiteTestSample,
  TestSuiteTestProps,
  TestSuiteTestSample,
} from "../testSuiteTypes";
import StatusForm from "./StatusForm";

const TestSuiteTest = (
  props: TestSuiteTestProps & { sample: TestSuiteTestSample },
) => {
  const { sample, setTest, test } = props;

  const { takeAction }: IUseApi = useApi();

  const { data, loading }: IUseApi<ShowTestSuiteTestSample> = useApi(
    `/test-suite-tests/${test.uuid}/samples/${sample.uuid}`,
    null,
    true,
  );

  const testSuiteSample = data as ShowTestSuiteTestSample;

  if (loading) {
    return <CustomScaleLoader>Fetching data...</CustomScaleLoader>;
  }

  const onSubmit = (values: any, _: any, props: any) => {
    return takeAction(
      "update",
      `test-suite-test-samples/${values.uuid}`,
      values,
    )
      .then(({ data }) => {
        setTest(data.data);
      })
      .catch(formError);
  };

  return (
    <>
      {testSuiteSample.statuses.map((status) => {
        return (
          <StatusForm
            key={status.uuid}
            custom_form={status.custom_form}
            custom_fields={status.custom_fields}
            name={status.name}
            form={`STATUS_FORM_${status.id}`}
            onSubmit={onSubmit}
            initialValues={status}
            test={test}
          />
        );
      })}
    </>
  );
};

export default TestSuiteTest;
