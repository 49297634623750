import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import BudgetList from "./BudgetList";
import Edit from "./Edit";

const ShowPage = (props) => {
  const { page, uuid } = useParams();

  const { user } = useAuth();

  switch (page) {
    case "details":
      return <Edit initialValues={props.budgetTemplate} {...props} />;
    case "documents":
      return (
        <NewProfileDocuments
          {...props}
          model="App\Models\BudgetTemplate"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/budget-templates/${uuid}`}
          homeUrl={`budget-templates/${uuid}/documents`}
        />
      );
    case "budgets":
      return <BudgetList {...props} />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
