import { useEffect, useState } from "react";
import { IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import { SkillCategory } from "../skillCategories/SkillCategoriesList";
import SkillCategories from "./SkillCategories";
import SkillStaff from "./SkillStaff";

export interface SkillUser {
  id: number;
  name: string;
  email: string;
  competencies: {
    uuid: string;
    skill_level: {
      name: string;
      level: string;
      id: number;
      competency_id: string;
      competency_type: string;
      full_name: string;
    };
  }[];
}

export interface SelectedSkill {
  skill: string;
  uuid: string;
}

const SkillStaffMatrix = () => {
  const { data: skillCategories }: IUseApiWithData<SkillCategory[]> = useApi(
    "skill-categories?includes=competencySkills.skill",
    [],
  );

  const {
    data: staff,
    setUrl,
    setData,
    loading,
  }: IUseApiWithData<SkillUser[]> = useApi("", []);

  const [selectedSkills, setSelectedSkills] = useState<SelectedSkill[]>([]);


  return (
    <>
      <div className="col-lg-8">
        <SkillStaff
          loading={loading}
          selectedSkills={selectedSkills}
          
        />
      </div>
      <div className="col-lg-4">
        <SkillCategories
          setSelectedSkills={setSelectedSkills}
          selectedSkills={selectedSkills}
          skillCategories={skillCategories}
        />
      </div>
    </>
  );
};

export default SkillStaffMatrix;
