import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import RenderField from "../utils/renderField";
import required from "../utils/required";

const Form = (props: any) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            required
            validate={required}
            name="name"
            label="Name"
          />
        </div>

        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({});

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    showFields: selector(state, "show_field"),
  };
};

export default connect(mapStateToProps, {})(form(Form));
