import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import HeaderPage from "../header/HeaderPage";
import ProfilePage from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import DeleteEquipmentType from "./DeleteEquipmentType";
import ShowPage from "./ShowPage";

const Profile = () => {
  const { uuid } = useParams();

  const {
    data: equipmentType,
    setData: setEquipmentType,
    loading,
    response,
  } = useApi(`equipment-types/${uuid}`);

  const tabs = [
    {
      link: `/equipment-types/${uuid}/details`,
      icon: "",
      page: "details",
      label: "Details",
    },
    {
      link: `/equipment-types/${uuid}/documents`,
      page: "documents",
      label: "Documents",
    },
    {
      link: `/equipment-types/${uuid}/calibrations`,
      icon: "",
      page: "calibrations",
      label: "Calibrations",
    },
    {
      link: `/equipment-types/${uuid}/fields`,
      icon: "",
      page: "fields",
      label: "Fields",
    },
  ];

  if (loading || !response) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={`Equipment Type - ${equipmentType.type}`}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "equipment-types", name: "Equipment Types" },
          {
            link: `equipment-types/${equipmentType.uuid}`,
            name: equipmentType.type,
            active: true,
          },
        ]}
      />
      <ProfilePage
        header={
          <DashboardHeader>
            <DashboardH3>{equipmentType.type}</DashboardH3>
            <p className="mb-0">
              Total replacement value{" "}
              {money.format(equipmentType.total_replacement_value)}
            </p>
          </DashboardHeader>
        }
        content={
          <ShowPage
            equipmentType={equipmentType}
            setEquipmentType={setEquipmentType}
          />
        }
        tabs={tabs}
        sideBar={<DeleteEquipmentType equipmentType={equipmentType} />}
      />
    </>
  );
};

export default Profile;
