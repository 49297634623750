import React from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  Field,
  Form,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
  submit,
} from "redux-form";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

interface OnHoldModalProps {
  toggle: Function;
  modal: boolean;
  hold?: {
    uuid: string;
  };
  holdable: {
    holdable_type: string;
    holdable_id: number;
  };
  onSuccess: (hold: any) => void;
}

const OnHoldModal: React.FC<
  OnHoldModalProps & InjectedFormProps<{}, OnHoldModalProps>
> = (props: any) => {
  const { toggle, modal, handleSubmit, dispatch, holdable, onSuccess, hold } =
    props;

  const { takeAction } = useApi();

  const onSubmit: FormSubmitHandler<{ reason: string }> = (
    values,
    _,
    props,
  ) => {
    return takeAction("store", `holds`, {
      ...holdable,
      reason: values.reason,
    })
      .then(({ data }: { data: any }) => {
        toast.success("Hold Created");
        onSuccess(data.data);
        props?.reset?.();
        toggle();
      })
      .catch(errorSwal);
  };

  const removeHold: FormSubmitHandler<{ reason: string }> = (
    values,
    _,
    props,
  ) => {
    return takeAction("store", `holds/${hold?.uuid}/removed`, {
      removed_reason: values.reason,
    })
      .then(() => {
        toast.success("Hold Removed");
        onSuccess();
        props?.reset?.();
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <Modal className="wd-md-800" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {hold ? "Remove Hold" : "Create Hold"}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(hold ? removeHold : onSubmit)}>
          <div className="row">
            <div className="col-12 form-group">
              <Field
                validate={required}
                required
                component={RenderField}
                textarea
                name="reason"
                label="Reason"
              />
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          onClick={() => dispatch(submit("OnHoldModal"))}
          {...props}
        />
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm<{}, OnHoldModalProps>({ form: "OnHoldModal" });

export default form(OnHoldModal);
