import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NoMatch from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import EditRequestedLeave from "./EditRequestedLeave";
import { RequestedLeavePages, RequestedLeaveProps } from "./leaveTypes";

const ShowRequestedLeavePage = (props: RequestedLeaveProps) => {
  const { page, uuid } = useParams<{
    page: RequestedLeavePages;
    uuid: string;
  }>();

  const { user } = useAuth();

  switch (page) {
    case "details":
      return <EditRequestedLeave {...props} />;
    case "documents":
      return (
        <NewProfileDocuments
          {...props}
          model="App\Models\RequestedLeave"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/requested-leave/${uuid}`}
          homeUrl={`projects/${uuid}/documents`}
        />
      );
    default:
      return <NoMatch />;
  }
};

export default ShowRequestedLeavePage;
