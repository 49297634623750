import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import Avatar from "../utils/Avatar";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import useEquipmentTypeList from "./hooks/useEquipmentTypeList";
import { EquipmentType } from "./EquipmentTypes";
import { money } from "../form/formatters";
import {
  Equipment,
  EquipmentTypeGroup,
  CalibrationUnit,
} from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const EquipmentTypesList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/equipment-asset-management/equipment-types-9197/" />
      <HeaderPage
        titlePage="Equipment Types"
        pageDescription={
          <>
            Equipment types simplify equipment management and allows
            organizations to track calibrations, services, and replacement
            values, reducing the risk of loss or damage due to inadequate
            maintenance.
          </>
        }
        relatedLinks={[
          {
            name: "Equipment",
            link: "/equipment-types",
            model: Equipment,
          },
          {
            name: "Equipment Types Groups",
            link: "/equipment-type-groups",
            model: EquipmentTypeGroup,
          },
          {
            name: "Calibrations",
            link: "/calibration-units",
            model: CalibrationUnit,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "", name: "Equipment Types", active: true },
        ]}
      />
      <Link
        className="btn btn-sm btn-outline-primary mb-2"
        to="equipment-types/add"
      >
        Add New Equipment Type
      </Link>
      <PaginatedList
        indexHook={useEquipmentTypeList}
        indexHookArguments={[]}
        originalFilters={[]}
        itemCard={({ item }: { item: EquipmentType }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<EquipmentHeader equipmentType={item} />}
          />
        )}
      />
    </>
  );
};

export default EquipmentTypesList;

const EquipmentHeader = ({
  equipmentType,
}: {
  equipmentType: EquipmentType;
}) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={equipmentType.type} colour={"info"} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={`equipment_type_title${equipmentType.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={equipmentType.link}>{equipmentType.type}</Link>
          </p>
          <UncontrolledTooltip
            placement="top-start"
            target={`equipment_type_title${equipmentType.uuid}`}
          >
            {equipmentType.type}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

const info = (equipmentType: EquipmentType): PaginatedInformation[] => {
  return [
    {
      name: "Group",
      value: equipmentType.group_name,
    },
    {
      name: "Replacement Value",
      value: money.format(equipmentType.replacement_value),
    },
    {
      name: "Total Replacement Value",
      value: money.format(equipmentType.total_replacement_value),
    },
  ];
};
