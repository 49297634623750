import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import ItemFields from "./ItemFields";
import { money } from "../form/formatters";

export const formatBranches = (branches) => {
  return branches.map((branch) => ({
    label: branch.name,
    value: branch.id,
  }));
};

export const formatAccountCodes = (codes) => {
  return codes.map((code) => ({
    label: `${code.code} - ${code.name}`,
    value: code.id,
    type: code.type,
  }));
};

const PurchaseItems = (props) => {
  const {
    fields,
    creditCard,
    purchaseOrder,
    disabled,
    initialValues,
    purchaseItems,
    isTestPurchaseOrder,
  } = props;

  const { user } = useAuth();
  const [branches, setBranches] = useState([]);
  const [accountCodes, setAccountCodes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ((!initialValues || !initialValues.uuid) && fields.length === 0) {
      fields.push({});
    }
    axios
      .get(`user-branches`)
      .then(({ data }) => {
        setBranches(formatBranches(data.data));
      })
      .then(() =>
        axios.get(
          `chart-of-accounts?${creditCard ? "&creditCards=1" : ""}${
            purchaseOrder ? "&purchaseOrders=1" : ""
          }`,
        ),
      )
      .then(({ data }) => setAccountCodes(formatAccountCodes(data.data)))
      .then(() => setLoading(false));
  }, []);

  const totalPrice = purchaseItems
    ? purchaseItems.reduce((carry, item) => {
        if (item.price && item.quantity) {
          carry += item.price * item.quantity;
        }
        return carry;
      }, 0)
    : 0;

  return (
    <>
      {!disabled && (
        <div className="col-12 d-flex align-items-center">
          <h5 className="tx-inverse">Add Line Items Below</h5>
          <span className="ms-auto">
            <TotalPrice price={totalPrice} />
          </span>
        </div>
      )}
      {fields.map((member, index) => {
        const newProps = {
          member,
          branches,
          fields,
          index,
          accountCodes,
          loading,
          key: index,
        };

        return <ItemFields {...props} {...newProps} />;
      })}

      {!isTestPurchaseOrder && (
        <div className="col-12 form-group d-flex">
          <button
            className="btn btn-link ms-auto"
            type="button"
            onClick={() =>
              fields.push({
                branch_id: user.default_branch_id,
              })
            }
          >
            Add Line Item
          </button>
        </div>
      )}
    </>
  );
};

const TotalPrice = ({ price }) => (
  <>
    <small className="text-secondary">Total Price</small>
    <p className="text-dark mb-0 tx-16">
      <strong>{money.format(price)}</strong>
    </p>
  </>
);

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);
  return {
    purchaseItems: selector(state, "purchase_items"),
  };
};

export default connect(mapStateToProps, {})(PurchaseItems);
