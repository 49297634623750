import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import ShowPage from "./ShowPage";

const OrganisationProfile = (props) => {
  const { user } = useAuth();

  const {
    data: organisation,
    setData: setOrganisation,
    loading,
  } = useApi(`organisations/${user.active_organisation.uuid}`, null, true);

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={`${organisation.display_name} - Settings`}
        crumbs={[
          {
            link: "/organisation",
            name: organisation.display_name,
            active: true,
          },
        ]}
      />

      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{organisation.display_name}</DashboardH3>
            <DashboardSubTitle>ABN: {organisation.abn}</DashboardSubTitle>
          </DashboardHeader>
        }
        content={
          <ShowPage
            {...props}
            organisation={organisation}
            setOrganisation={setOrganisation}
            initialValues={organisation}
          />
        }
        tabs={[
          {
            label: "Links",
            link: "/organisations/settings/links",
            page: "links",
            icon: "fa fa-link",
          },
          {
            label: "Documents",
            link: "/organisations/settings/documents",
            page: "documents",
            icon: "fa fa-folder-open-o",
          },
          {
            label: "Logo",
            link: "/organisations/settings/logo",
            page: "logo",
          },
          {
            label: "Details",
            link: "/organisations/settings/details",
            page: "details",
            icon: "fa fa-file-text-o",
          },
          {
            label: "General",
            link: "/organisations/settings/general",
            page: "general",
            icon: "fa fa-gear",
          },
          {
            label: "Billing",
            link: "/organisations/settings/billing",
            page: "billing",
            icon: "fa fa-money",
          },
          {
            label: " Integrations",
            link: "/organisations/settings/integrations",
            page: "integrations",
            icon: "fa fa-gear",
          },
          {
            label: "Tokens",
            link: "/organisations/settings/tokens",
            page: "tokens",
            icon: "fa fa-gear",
          },
        ]}
      />
    </>
  );
};

export default OrganisationProfile;
