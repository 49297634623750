import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";

import Form from "./Form";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { Button } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";

const Edit = () => {
  const { uuid } = useParams();

  const history = useHistory();

  const {
    data: holiday,
    setData: setHoliday,
    loading,
  } = useApi(`public-holidays/${uuid}`, null, true, "/public-holidays");

  const { takeAction, loading: submitting } = useApi();

  const deleteHoliday = () => {
    return deleteSwal(holiday.name)
      .then(() => takeAction("destroy", `public-holidays/${uuid}`))
      .then(() => {
        history.push("/public-holidays");
        toast.success(`${holiday.name} deleted`);
      })
      .catch(errorSwal);
  };

  const onSubmit = (values) => {
    if (holiday?.timesheets_created_at) {
      return toast.warning("Not allowed to updated after creating timesheets.");
    }

    return takeAction("update", `public-holidays/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Public Holiday Updated");
        setHoliday(data.data);
      })
      .catch(errorSwal);
  };

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={`Edit ${holiday?.name}`}
        crumbs={[
          {
            name: "Organisation",
            link: "/organisations/settings/links",
          },
          {
            name: "Public Holidays",
            link: "/public-holidays",
          },
          {
            name: `Edit ${holiday?.name}`,
            link: "/",
            active: true,
          },
        ]}
      />
      <Form
        form="EditPublicHoliday"
        onSubmit={onSubmit}
        initialValues={holiday}
        extraEndForm={
          !holiday?.timesheets_created_at ? (
            <Button
              disabled={submitting}
              onClick={deleteHoliday}
              color="danger"
              outline
            >
              Delete
            </Button>
          ) : null
        }
      />
    </>
  );
};

export default Edit;
