import React from "react";
import { Field } from "redux-form";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";

const SendEquipmentCalibrationsModal = (props) => {
  const { selectedCalibrations, toggle, project } = props;
  const { takeAction } = useApi();

  const sendMail = (values) => {
    return takeAction("store", `send-equipment-calibrations`, {
      model_ids: selectedCalibrations.map(({ id }) => id),
      file_name: `${project.full_name} Calibrations`,
      ...values,
    })
      .then(() => {
        toast.success("Calibrations sent Successfully");
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal {...props} onSubmit={sendMail}>
      <div className=" form-group col-12">
        <Field
          className="form-control"
          required
          validate={required}
          component={RenderField}
          name="email"
          label="Email"
        />
      </div>
      <div className="form-group col-12">
        <Field
          className="form-control"
          textarea
          component={RenderField}
          name="message"
          label="Message"
        />
      </div>
    </FormModal>
  );
};

export default SendEquipmentCalibrationsModal;
