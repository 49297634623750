import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import { IFilter } from "../hooks/useFilter";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { BranchIndex } from "./branchTypes";
import useBranches from "./hooks/useBranches";
import { LeaveReason, CPIR, ProjectJob } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const BranchesList = () => {
  const { user } = useAuth();

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/branches-divisions-and-tracking-sites/" />
      <HeaderPageAdminFilter
        titlePage="Branches"
        pageDescription={
          <>
            A branch is a combination of a{" "}
            <Link to="/trackingsites">Tracking Sites</Link> and{" "}
            <Link to="/divisions">Divisions</Link>
          </>
        }
        relatedLinks={[
          {
            name: "Leave Reasons",
            link: "/leave-reasons",
            model: LeaveReason,
          },
          { name: "CPIRs", link: "/cpirs", model: CPIR },
          { name: "Jobs", link: "/jobs", model: ProjectJob },
        ]}
        crumbs={[{ link: "branches", name: "Branches", active: true }]}
      />
      {user?.is_admin && (
        <div className="d-flex space-x-3 mb-3">
          <Link to="/branches/add" className="btn btn-outline-primary p-1 px-2">
            Add new Branch
          </Link>
          <Link
            to="/branches/custom-fields"
            className="btn btn-outline-primary p-1 px-2"
          >
            Branch Custom Fields
          </Link>
        </div>
      )}
      <PaginatedList
        listName="branchesList"
        indexHook={useBranches}
        originalFilters={filters}
        itemCard={({ item }: { item: BranchIndex }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<BranchHeader branch={item} />}
            bottomContent={<BranchFooter branch={item} />}
          />
        )}
      />
    </>
  );
};

const BranchHeader = ({ branch }: { branch: BranchIndex }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar
            rawName={branch.number}
            name={branch.display_name}
            colour={branch.deleted_at ? "secondary" : "info"}
          />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={`branch_title${branch.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={branch.link}>{branch.name}</Link>
          </p>
          {branch.uuid && (
            <UncontrolledTooltip
              placement="top-start"
              target={`branch_title${branch.uuid}`}
            >
              {branch.name}
            </UncontrolledTooltip>
          )}
          <p className="mb-0">{branch.number}</p>
        </div>
      </div>
    </div>
  );
};

const BranchFooter = ({ branch }: { branch: BranchIndex }) => {
  return (
    <div className="w-100 d-flex">
      <div className=" ms-auto">
        <div
          className={`bg-${
            branch.deleted_at ? "secondary" : "info"
          } shadow rounded-pill text-white tx-10 text-center px-2`}
        >
          {branch.deleted_at ? "Archived" : "Active"}
        </div>
      </div>
    </div>
  );
};

const info = (branch: BranchIndex): PaginatedInformation[] => {
  return [
    {
      name: "Division",
      value: branch.division.name,
    },
    {
      name: "Tracking Site",
      value: branch.tracking_site.name,
    },
    {
      name: "Branch Manager",
      value: branch.branch_manager ? (
        <a href={`mailto:${branch.branch_manager?.email}`}>
          {branch.branch_manager?.name}
        </a>
      ) : (
        "-"
      ),
    },
  ];
};

const filters: IFilter<"trashed", "only" | "with">[] = [
  {
    label: "Archived",
    name: "trashed",
    options: [
      {
        label: "View only archived",
        value: "only",
      },
      {
        label: "Include archived",
        value: "with",
      },
    ],
  },
];

export default BranchesList;
