import FormModal from "../utils/FormModal";
import { Field } from "redux-form";
import DateTime, { formatting } from "../utils/dateTime";
import required from "../utils/required";
import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import { useState } from "react";
import DATE_OPTIONS from "../../data/date_options";
import DateType from "../../enums/DateType";

interface EmailSearchFilterModalProps {
  handleSubmit: (values: any) => void;
  toggle: () => void;
  modal: boolean;
  initialValues: {
    fromEmailAddress?: string | null;
    toEmailAddress?: string | null;
    date?: number | null;
    dateFrom?: string | null;
    dateTo?: string | null;
  };
}

const EmailSearchFilterModal = (props: EmailSearchFilterModalProps) => {
  const { handleSubmit, toggle, modal, initialValues } = props;
  const [customDates, setCustomDates] = useState(false);

  const handleDateChange = ({ value }: { value: number }) => {
    setCustomDates(value === DateType.customDates);
  };

  return (
      <FormModal
        onSubmit={handleSubmit}
        title="Search Filter"
        modal={modal}
        toggle={toggle}
        form="EmailSearchFilterModal"
        save="Search"
        initialValues={initialValues}
      >
        <div className="row">
          <div className="form-group col-lg-12">
            <Field
              component={renderField}
              name="fromEmailAddress"
              label="From"
            />
          </div>
          <div className="form-group col-lg-12">
            <Field
              component={renderField}
              name="toEmailAddress"
              label="To"
            />
          </div>
          <div className="form-group col-lg-12">
            <Field
              component={SelectInputAsync}
              name="date"
              label="Date"
              onChangeValue={handleDateChange}
              options={DATE_OPTIONS}
            />
          </div>
          {customDates ? (
          <>
            <div className="form-group col-lg-6">
              <Field
                component={DateTime}
                name="dateFrom"
                label="Date From"
                {...formatting}
                validate={required}
                required
              />
            </div>
            <div className="form-group col-lg-6">
              <Field
                component={DateTime}
                name="dateTo"
                label="Date To"
                {...formatting}
                validate={required}
                required
              />
            </div>
          </>  ) : null}
        </div>
      </FormModal>
  );
};

export default EmailSearchFilterModal;