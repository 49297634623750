import { useState } from "react";
import { Button, Popover, PopoverBody } from "reactstrap";
import useModal from "../../hooks/useModal";

import isSubmitting from "../../utils/submitting";

const AddSamplesButton = ({ addSamples, loading, size = "md" }) => {
  const { modal, toggle } = useModal();
  const [amount, setAmount] = useState(1);

  return (
    <>
      <Button type="button" color="primary" size={size} id="add-samples">
        <i className="fa fa-plus mg-r-5" />
        Add Samples
      </Button>
      <Popover target="add-samples" isOpen={modal} toggle={toggle}>
        <PopoverBody className="p-3">
          <label htmlFor="amount" className="fw-bolder tx-inverse">
            Number of Samples.
          </label>
          <input
            id="amount"
            className="form-control"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <div className="d-flex">
            <Button
              disable={loading}
              className="mt-3 ms-auto"
              size="sm"
              onClick={() => addSamples(amount).then(toggle)}
            >
              {isSubmitting(loading, "Add", "Adding...")}
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default AddSamplesButton;
