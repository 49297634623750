import { EmploymentAward } from "../types";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

const useAddEmploymentAward = () => {
  const { takeAction }: IUseApi<EmploymentAward, EmploymentAward> = useApi();
  const history = useHistory();

  const queryClient = useQueryClient();

  return (values: EmploymentAward) =>
    takeAction("store", "/employment-awards", values).then(() => {
      toast.success("Employment award added");

      history.push("/employment-awards");

      queryClient.invalidateQueries(["employment-awards"]);
    });
};

export default useAddEmploymentAward;
