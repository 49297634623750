import React from "react";
import { Field, reduxForm } from "redux-form";

import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import isSubmitting from "../utils/submitting";

const FormCalibrationTracking = (props) => {
  const { submitting, initialValues, handleSubmit, valid } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div class="form-layout">
        <div className="row">
          <div className="col-lg-3 form-group ">
            <Field
              label="Amount"
              placeholder="Amount"
              name="amount"
              type="number"
              component={renderField}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-3 form-group ">
            <Field
              label="Units"
              placeholder="Units"
              name="units_id"
              url={`/react-select?model=${encodeURIComponent(
                "App\\Models\\CalibrationUnit",
              )}&select=name,id&label=name&value=id`}
              component={SelectInputAsync}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-3 form-group ">
            <Field
              label="Date"
              placeholder="Date"
              name="date"
              type="date"
              component={renderField}
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-3 d-flex align-items-end">
            <button
              className={`btn btn${
                !valid ? "-outline" : ""
              }-primary bd-0 form-control`}
              disabled={submitting}
            >
              {isSubmitting(
                submitting,
                initialValues?.uuid ? "Update" : "Save",
                initialValues?.uuid ? "Updating..." : "Saving...",
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({
  enableReinitialize: true,
});

export default form(FormCalibrationTracking);
