import React from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { reset, submit } from "redux-form";
import useApi from "../api/useApi";
import uploadToS3 from "../upload/uploadToS3";
import errorSwal from "../utils/errorSwal";
import RightModal from "../utils/RightModal";
import SubmitButton from "../utils/SubmitButton";
import AddCompetencyForm from "./AddCompetencyForm";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useAuth } from "../../context/auth-context";

const AddCompetencyModal = (props) => {
  const { user, toggle, dispatch, modal, setStaffCompetencies } = props;
  const { user: authUser } = useAuth();

  const { takeAction, loading } = useApi();

  const onSubmit = (values, dispatch) => {
    values.user_id = user.id;

    return uploadToS3(
      values.documents,
      `organisations/${authUser.active_organisation.uuid}/staff-competencies`,
    )
      .then((documents) => {
        const data = {
          ...values,
          documents,
        };

        return takeAction("store", "staff-competencies", data);
      })
      .then(() => takeAction("index", `/user/${user.uuid}/competencies`))
      .then(({ data }) => {
        setStaffCompetencies(data.data);
        toast.success("Competency Added!");
        dispatch(reset("StaffCompetency"));
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <RightModal modal={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Competency for {user.name}</ModalHeader>
      <ModalBody>
        <AddCompetencyForm {...props} initialValues={{}} onSubmit={onSubmit} />
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          onClick={() => dispatch(submit("StaffCompetency"))}
          valid={true}
          submitting={loading}
        />
        <button
          type="button"
          className="btn btn-outline-secondary bd-0"
          onClick={toggle}
        >
          Close
        </button>
      </ModalFooter>
    </RightModal>
  );
};

export default connect()(AddCompetencyModal);
