import { toArray } from "./toArray";

const TimeUnitType = {
  Initial: 0,
  Days: 1,
  Weeks: 2,
  Months: 3,
  Years: 4,
};
export const timeUnitTypes = toArray(TimeUnitType);

export default TimeUnitType;
