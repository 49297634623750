import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { IProject } from "../projects/projectTypes";
import { IndexJob } from "../jobs/jobTypes";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import BasicUserProjectJobCard from "./BasicUserProjectJobCard";

const BasicUserProjectProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading } = useApi(`projects/${uuid}`, null, true);

  const { data: userJobs } = useApi(`projects/${uuid}/user-jobs`, [], true);

  const jobs = userJobs as IndexJob[];

  const project = data as IProject;

  if (loading) {
    return <Spinner loading={true} />;
  }

  return (
    <>
      <HeaderPage
        titlePage={project.full_name}
        crumbs={[
          { link: "projects", name: "Projects" },
          {
            link: `projects/${project.uuid}`,
            name: project.number,
            active: true,
          },
        ]}
      />
      <DashboardHeader>
        <DashboardH3>{project.full_name}</DashboardH3>
        {project.internal ? (
          <DashboardSubTitle>Internal Project</DashboardSubTitle>
        ) : (
          <>
            <DashboardSubTitle>
              Client: {project.client.display_name}
            </DashboardSubTitle>
            <DashboardSubTitle>
              Address:{" "}
              {project.formatted_address != "Not Provided" ? (
                <a
                  target="__blank"
                  href={`http://maps.google.com/maps?q=${project.formatted_address}`}
                >
                  {project.formatted_address}
                </a>
              ) : (
                project.formatted_address
              )}
            </DashboardSubTitle>
          </>
        )}
      </DashboardHeader>
      <h6 className="slim-card-title mb-3">Jobs</h6>
      <div className="space-y-3">
        {jobs.map((job) => {
          return <BasicUserProjectJobCard job={job} />;
        })}
      </div>
    </>
  );
};

export default BasicUserProjectProfile;
