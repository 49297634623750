import React from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { reduxForm, reset, submit } from "redux-form";
import useApi from "../api/useApi";
import SubmitButton from "../utils/SubmitButton";
import RecipientSelection from "./RecipientSelection";

const SendModal = (props) => {
  const { selectedCount, handleSubmit, modal, error, dispatch } = props;
  const { number } = useParams();

  const { loading, data: recipients } = useApi(
    `projects/${number}/contacts`,
    [],
  );

  const toggle = () => {
    if (modal) {
      dispatch(reset("bulkSendDeliverables"));
    }
    props.toggle();
  };

  const formattedRecipients = recipients.map((recipient) => ({
    label: recipient.name,
    value: recipient.id,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        isOpen={modal}
        className="modal-dialog modal-lg wd-md-600"
        toggle={toggle}
        data-keyboard="false"
      >
        <ModalHeader toggle={toggle}>
          {selectedCount > 1
            ? `Send ${selectedCount} deliverables`
            : `Send ${selectedCount} deliverable`}
        </ModalHeader>
        <ModalBody>
          <RecipientSelection
            loading={loading}
            availableRecipients={formattedRecipients}
            {...props}
          />
          <div className="col-lg-12">
            {error && (
              <strong className="text-center text-danger">{error}</strong>
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <SubmitButton
            {...props}
            save="Send"
            saving="Sending..."
            onClick={() => dispatch(submit("bulkSendDeliverables"))}
          />
        </ModalFooter>
      </Modal>
    </form>
  );
};

const form = reduxForm({ form: "bulkSendDeliverables" });
export default form(SendModal);
