import React from "react";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { Invoice } from "./invoiceTypes";

const DownloadInvoiceButton = ({ invoice }: { invoice: Invoice }) => {
  const { loading, takeAction }: IUseApi<{}, { data: string }> = useApi();

  const { uuid } = useParams<{ uuid: string }>();

  const downloadInvoice = () => {
    return takeAction("store", `invoices/${uuid}/download`)
      .then(({ data }) => {
        window.location.href = data.data;
      })
      .catch(errorSwal);
  };

  if (invoice.line_items?.length === 0) {
    return null;
  }

  return (
    <li className="nav-item">
      <button
        type="button"
        onClick={downloadInvoice}
        className="pointer-hover w-100 nav-link"
        disabled={loading}
      >
        <i className="icon ion-ios-cloud-download-outline tx-primary" />{" "}
        {isSubmitting(loading, `Download Invoice`, "Generating...")}
      </button>
    </li>
  );
};

export default DownloadInvoiceButton;
