import React from "react";
import { Field, reduxForm } from "redux-form";
import dateTime, { formatting } from "../utils/dateTime";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const ProfitAndLossForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            component={dateTime}
            name="from"
            label="From"
            {...formatting}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field component={dateTime} name="to" label="To" {...formatting} />
        </div>

        <div className="col-lg-10 form-group">
          <Field
            component={SelectInputAsync}
            name="payments_only"
            label="Accounting Basis"
            options={[
              {
                label: "Cash",
                value: true,
              },
              {
                label: "Accrual",
                value: false,
              },
            ]}
          />
        </div>
        <div className="form-group col-lg-2 d-flex align-items-end">
          <SubmitButton
            {...props}
            className="w-100"
            save="Update"
            saving="Updating..."
          />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "ProfitLoss" });

export default form(ProfitAndLossForm);
