import { Field, FieldArray } from "redux-form";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import FieldType from "../../enums/FieldType";
import DataSeries from "./DataSeries";

interface ChartFieldProps {
  field: string;
  fields: any;
  parentIndex: number;
  codeSuggestions: any;
  setCodeSuggestions: any;
  vals: any;
}

const ChartField = (props: ChartFieldProps) => {
  const {
    vals,
    field,
    fields,
    codeSuggestions,
    setCodeSuggestions,
    parentIndex,
  } = props;

  let jsEditor = {
    isFirstJsEditor: false,
    isFirstJsEditorFieldIndex: undefined,
  };
  loopForFirstEditor: for (let field of fields) {
    if (
      parseInt(field?.type) === FieldType.Formula &&
      field?.field_attributes?.formula_type === "code"
    ) {
      break;
    } else if (parseInt(field?.type) === FieldType.Chart) {
      const dataseries = field?.field_attributes?.data_series;
      for (var i = 0; i < dataseries?.length; i++) {
        let data: any = dataseries[i];
        if (data?.type === "formula") {
          if (
            field?.field_attributes?.label === vals?.field_attributes?.label
          ) {
            jsEditor = {
              isFirstJsEditor: true,
              // @ts-ignore
              isFirstJsEditorFieldIndex: i,
            };
          }
          break loopForFirstEditor;
        }
      }
    }
  }

  return (
    <>
      <FormHeader>Chart Options</FormHeader>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name={`${field}.field_attributes.x_axis_label`}
          label="x-axis Label"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name={`${field}.field_attributes.y_axis_label`}
          label="y-axis Label"
          required
          validate={required}
        />
      </div>
      <FieldArray
        component={DataSeries}
        customFields={fields}
        name={`${field}.field_attributes.data_series`}
        parentIndex={parentIndex}
        vals={vals}
        jsEditor={jsEditor}
        codeSuggestions={codeSuggestions}
        setCodeSuggestions={setCodeSuggestions}
      />
    </>
  );
};

export default ChartField;
