import dayjs from "dayjs";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { remove } from "../utils/arrayUtils";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import { IHazardReportQuestion } from "./types";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";

interface HazardReportQuestionProps {
  question: IHazardReportQuestion;
  questions: IHazardReportQuestion[];
  setQuestions: (questions: IHazardReportQuestion[]) => void;
}

const HazardReportQuestion = ({
  question,
  questions,
  setQuestions,
}: HazardReportQuestionProps) => {
  const { takeAction, loading }: IUseApi = useApi();

  const { user } = useAuth();

  const canDelete = user?.hasAccessTo(
    "App\\Models\\HazardReportQuestion",
    "delete",
  );

  const onDelete = () => {
    deleteSwal(question.name)
      .then(() =>
        takeAction("destroy", `hazard-report-questions/${question.uuid}`),
      )
      .then(() => {
        setQuestions(remove("uuid", questions, question));
        toast.success(`${question.name} deleted`);
      })
      .catch(errorSwal);
  };

  return (
    <div className="p-3 border-bottom d-flex align-items-center">
      <div>
        <p className="mb-1 text-dark">{question.name}</p>
        <small className="mb-0">
          Created by{" "}
          <a href={`mailto:${question.created_by.email}`}>
            {question.created_by.name}
          </a>{" "}
          at{" "}
          <span className="text-dark">
            {dayjs(question.created_at).format("hh:mm a")}
          </span>{" "}
          on{" "}
          <span className="text-dark">
            {dayjs(question.created_at).format("DD/MM/YYYY")}
          </span>
        </small>
      </div>
      {canDelete && (
        <div className="ms-auto">
          <DeleteButton deleting={loading} deleteFunction={onDelete} />
        </div>
      )}
    </div>
  );
};

export default HazardReportQuestion;
