import { useInfiniteQuery } from "react-query";
import api from "../../api/api";
import { JobOverviewDashboardProject } from "../dashboardTypes";
import { LaravelPaginatedResponse } from "../../utils/utilTypes";

export interface JobOverviewDashboardFilter {
  name: string;
  value: (string | number)[];
}

export const getFilterString = (filters?: JobOverviewDashboardFilter[]) => {
  if (!filters || filters.length === 0) {
    return "";
  }

  const filtersString = filters
    .filter(({ value }) => {
      return value.length > 0;
    })
    .map(({ name, value }) => {
      return value
        .filter((f) => f !== undefined)
        .map((v) => `filter[${name}][]=${v}`)
        .join("&");
    })
    .join("&");

  return `&${filtersString}`;
};

const getJobOverviewDashboard = (
  { pageParam = 1 },
  search?: string,
  filters?: JobOverviewDashboardFilter[],
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get<LaravelPaginatedResponse<JobOverviewDashboardProject[]>>(
      `job-overview-dashboard?page=${pageParam}&filter[search]=${search}${getFilterString(
        filters,
      )}`,
    )
    .then(({ data }) => data);
};

export default function useJobOverviewDashboard(
  search?: string,
  filters?: JobOverviewDashboardFilter[],
) {
  return useInfiniteQuery(
    ["job-overview-dashboard", { search, filters }],
    (pageParam) => getJobOverviewDashboard(pageParam, search, filters),
    {
      getNextPageParam: (response) => {
        if (!response) {
          return;
        }

        if (response.meta.current_page === response.meta.last_page) {
          return;
        }

        return response.meta.current_page + 1;
      },
    },
  );
}
