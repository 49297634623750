import { FieldArray, formValueSelector, reduxForm } from "redux-form";
import Fields from "./Fields";
import useApi from "../api/useApi";
import { connect } from "react-redux";

const Form = (props) => {
  const { data } = useApi("equipment-type-equipment?filter[type]=nuclear", []);

  return (
    <>
      <form>
        <FieldArray
          {...props}
          component={Fields}
          densityGauges={data}
          name="nucs"
          userCompetent={props.userCompetent}
          supervisors={props.supervisors}
        />
      </form>
    </>
  );
};

const form = reduxForm({ form: "nuclearDensometer" });

const selector = formValueSelector("nuclearDensometer");

const mapStateToProps = (state) => {
  return { nucForm: selector(state, "nucs") };
};

export default connect(mapStateToProps)(form(Form));
