import { useParams } from "react-router-dom";
import { SubscribedSku } from "@microsoft/microsoft-graph-types";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import CustomScaleLoader from "../utils/scaleLoader";
import {
  MicrosoftIntegrationPages,
  TMicrosoftIntegration,
} from "./microsoftTypes";
import ShowMicrosoftPage from "./ShowMicrosoftPage";

const MicrosoftIntegrationProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, setData, loading }: IUseApi<TMicrosoftIntegration> = useApi(
    `/integrations/${uuid}`,
    null,
    true,
  );

  const { data: skuData } = useApi("microsoft/skus", []);

  const microsoft = data as TMicrosoftIntegration;

  const skus = skuData as SubscribedSku[];

  return (
    <>
      <HeaderPageAdminFilter crumbs={[]} titlePage="Microsoft" />
      {loading ? (
        <CustomScaleLoader>
          Fetching Integration Information...
        </CustomScaleLoader>
      ) : (
        <Profile<MicrosoftIntegrationPages>
          header={
            <DashboardHeader>
              <DashboardH3>Microsoft</DashboardH3>
            </DashboardHeader>
          }
          content={
            <ShowMicrosoftPage
              microsoft={microsoft}
              setMicrosoft={setData}
              skus={skus}
            />
          }
          tabs={[
            {
              label: "Details",
              link: `/integrations/microsoft/${uuid}/details`,
              page: "details",
            },
            {
              label: "Roles to Sync",
              link: `/integrations/microsoft/${uuid}/positions-to-sync`,
              page: "positions-to-sync",
            },
            {
              label: "Sync Users",
              link: `/integrations/microsoft/${uuid}/sync-users`,
              page: "sync-users",
            },
            {
              label: "Accounts Payable Setup",
              link: `/integrations/microsoft/${uuid}/accounts-payable`,
              page: "accounts-payable",
            },
            {
              label: "Webhooks",
              link: `/integrations/microsoft/${uuid}/webhooks`,
              page: "webhooks",
            },
          ]}
        />
      )}
    </>
  );
};

export default MicrosoftIntegrationProfile;
