import { FileRejection, useDropzone } from "react-dropzone";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { StandardDocument } from "../standardDocuments/standardDocumentTypes";
import Spinner from "../utils/Spinner";
import { BaseModel } from "../utils/utilTypes";
import { FaRegFileExcel } from "react-icons/fa";
import { useState } from "react";
import useS3Uploader from "../hooks/useS3Uploader";
import { useAuth } from "../../context/auth-context";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { BiCloudUpload } from "react-icons/bi";
import ProgressBar from "../utils/ProgressBar";

type ExternalTestRequestTemplate = {
  document: StandardDocument;
} & BaseModel;

const ExternalTestRequestTemplateList = () => {
  const { data, loading, setData }: IUseApi<ExternalTestRequestTemplate> =
    useApi("external-test-request-templates", null, true);

  const [uploading, setUploading] = useState(false);

  const { user } = useAuth();

  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();

  const { takeAction } = useApi();

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setRejectedFiles(fileRejections);

    setUploading(true);

    return upload(
      acceptedFiles[0],
      `tmp/organisations/${user?.active_organisation.uuid}/test-request-uploads`,
      (file: any) => {
        return takeAction("store", `external-test-request-templates`, file)
          .then(({ data }: { data: { data: ExternalTestRequestTemplate } }) => {
            toast.success("File UPloaded successfully");
            setUploadPercent(0);
            setUploading(false);
            setData(data.data);
          })
          .catch((err: any) => {
            setUploading(false);
            setUploadPercent(0);
            errorSwal(err);
          });
      },
    );
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      multiple: false,
      onDrop,
      disabled: loading,
    });

  const officeViewUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
    data?.document?.link ?? "",
  )}`;

  if (loading) return <Spinner loading={loading} />;

  return (
    <>
      <HeaderPage titlePage="External Test Request Templates" crumbs={[]} />

      <div className="mb-5">
        <div
          {...getRootProps()}
          className={`tn-300 rounded-lg ${
            isDragActive ? "bg-gray-100" : "bg-white"
          } d-flex align-items-center w-100 h-100 justify-content-center`}
          style={{ minHeight: "7rem", border: "1px dashed #dee2e6" }}
        >
          <input {...getInputProps()} />
          {loading ? (
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          ) : (
            <div>
              {!data ? (
                <div className="d-flex justify-content-center mt-5">
                  <div>
                    <p className="text-center">
                      <FaRegFileExcel className="text-center tx-32 text-success" />
                    </p>
                    <p className="text-center text-dark tx-16 mb-1">
                      No Test Request Templates
                    </p>
                    <p className="text-center">
                      Get started by clicking here or dragging your document
                      your document.
                    </p>
                    <div className="d-flex justify-content-center">
                      {/* <AddSamplesButton
               addSamples={addSamples}
               size="sm"
               loading={loading}
             /> */}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-center">
                    <BiCloudUpload className="tx-32 text-dark" />
                  </div>
                  <p className="mb-0">Upload External Test Request Template</p>
                </>
              )}
            </div>
          )}
        </div>
        {uploadPercent > 0 && (
          <ProgressBar
            percent={uploadPercent}
            color={uploadPercent < 100 ? "info" : "success"}
          />
        )}
      </div>

      {data && (
        <iframe
          src={officeViewUrl}
          width="100%"
          height="800px"
          className="border shadow-sm rounded-lg"
          title="Office View"
        />
      )}
    </>
  );
};

export default ExternalTestRequestTemplateList;
