import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import SelectInput from "../form/SelectInput";
import { useEffect } from "react";
import useApi from "../api/useApi";
import { Button } from "reactstrap";

const EquipmentTypeChildrenFields = (props: {
  field: string;
  equipmentTypeId?: string;
  equipmentTypes: any[];
  fields: any;
  index: number;
}) => {
  const { equipmentTypeId, field, fields, index } = props;

  const { data, setUrl } = useApi("", []);

  useEffect(() => {
    if (equipmentTypeId) {
      setUrl(
        `/custom-fields?model=${encodeURIComponent(
          "App\\Models\\EquipmentType",
        )}&modelId=${equipmentTypeId}`,
      );
    }
  }, [equipmentTypeId]);

  return (
    <>
      <div className="col-lg-5 form-group">
        <Field
          component={SelectInput}
          options={props.equipmentTypes}
          name={`${field}.equipment_type_id`}
          label="Type"
        />
      </div>
      <div className="col-lg-5 form-group">
        <Field
          component={SelectInput}
          isMulti
          options={data.map((d: any) => ({
            label: d.field_attributes.label,
            value: d.id,
          }))}
          name={`${field}.custom_field_ids`}
          label="Custom Fields"
        />
      </div>
      <div className="col-lg-2 form-group d-flex align-self-end">
        <Button
          outline
          block
          className=""
          color="danger"
          onClick={() => fields.remove(index)}
        >
          Remove
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: any, props: any) => {
  const selector = formValueSelector(props.form);

  return {
    equipmentTypeId: selector(state, `${props.field}.equipment_type_id`),
  };
};

export default connect(mapStateToProps)(EquipmentTypeChildrenFields);
