import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useObserver from "../hooks/useObserver";
import InitialAvatar from "../utils/InitialAvatar";
import CustomScaleLoader from "../utils/scaleLoader";
import useLikes from "./hooks/useLikes";
const LikeModal = ({ likeQuery, title, modal, toggle }) => {
  const { data, isFetchingNextPage, fetchNextPage } = useLikes(
    likeQuery,
    modal,
  );

  const intersection = useObserver(
    () => !isFetchingNextPage && fetchNextPage(),
  );

  const likes = data?.pages?.map((page) => page.data).flat();

  return (
    <Modal className="wd-md-1000 mx-wd-800" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div className="space-y-5">
          {likes?.map((like) => {
            return (
              <div className="d-flex align-items-center">
                <InitialAvatar
                  userName={like.user.name}
                  size="30"
                  fontSize="12"
                />{" "}
                <p className="mb-0">{like.user.name}</p>
              </div>
            );
          })}
        </div>

        {isFetchingNextPage && (
          <CustomScaleLoader>Fetching Likes...</CustomScaleLoader>
        )}
        <div ref={intersection} />
      </ModalBody>
    </Modal>
  );
};

export default LikeModal;
