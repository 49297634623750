import { Button } from "reactstrap";
import { ReactComponent as Project } from "../../svgs/construction.svg";
import { ReactComponent as Tender } from "../../svgs/spreadsheet.svg";
import { ReactComponent as Job } from "../../svgs/engineer.svg";
import { BiCheck } from "react-icons/bi";
import { ConvertType } from "./ConvertToJobModal";

const ConvertTypeButtons = ({
  convertType,
  setConvertType,
  setShowForm,
}: {
  convertType?: ConvertType;
  setConvertType: (convertType?: ConvertType) => void;
  setShowForm: (showForm: boolean) => void;
}) => {
  const buttons: {
    toToggle: ConvertType;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    text: string;
  }[] = [
    {
      toToggle: "project",
      icon: Project,
      text: "Project",
    },
    {
      toToggle: "job",
      icon: Job,
      text: "Job",
    },
    {
      toToggle: "tender",
      icon: Tender,
      text: "Tender",
    },
  ];

  return (
    <div className="row">
      {buttons.map((button) => {
        const isActive = convertType === button.toToggle;

        return (
          <div className="col-lg-4 form-group">
            <button
              type="button"
              onClick={() =>
                setConvertType(
                  convertType === button.toToggle ? undefined : button.toToggle,
                )
              }
              className={`btn bg-white position-relative w-100 p-3 ${
                isActive ? "border-warning" : "border"
              } rounded-lg shadow-sm d-flex align-items-center justify-content-center`}
            >
              <div
                style={{ right: 5, top: 5 }}
                className={`position-absolute tn-300 ${
                  isActive ? "opacity-1" : "opacity-0"
                }`}
              >
                <BiCheck className="tx-warning tx-26" />
              </div>
              <div className="text-center">
                <button.icon
                  className={isActive ? "active" : ""}
                  height="150px"
                  width="150px"
                />
                <p className="mb-0 mt-3">{button.text}</p>
              </div>
            </button>
          </div>
        );
      })}

      <div className="col-12">
        <Button
          style={{
            backgroundColor: "#0c78c4",
          }}
          className="border-0 shadow"
          block
          disabled={!convertType}
          onClick={() => setShowForm(true)}
        >
          {convertType ? `Convert to ${convertType}` : "Select a type"}
        </Button>
      </div>
    </div>
  );
};

export default ConvertTypeButtons;
