import dayjs from "dayjs";
import queryString from "query-string";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";

import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import DocketForm from "./DocketForm";
import getJobQueryString from "./getJobQueryString";

const Add = (props) => {
  const { handleSubmit, location, history } = props;

  const {
    data: items,
    setData: setItems,
    loading: loadingItems,
    setUrl: setItemUrl,
  } = useApi(null, []);

  const { data: jobs, setData: setJobs, setUrl: setJobUrl } = useApi(null, []);

  const { takeAction } = useApi();

  const { job, project, jobUuid, projectUuid } = queryString.parse(
    location.search,
  );

  const submit = (values) => {
    const extra_recipients = values.extra_recipients || [];
    const recipients = values.recipients || [];

    const data = {
      ...values,
      recipients: [...recipients, ...extra_recipients],
    };

    return takeAction("store", "dockets", data)
      .then(() => {
        toast.success("Docket added successfully");

        if (jobUuid) {
          history.push(`/jobs/${jobUuid}/details`);
          return;
        }

        history.push("/dockets");
      })
      .catch(errorSwal);
  };

  const getItems = (jobs) => {
    if (jobs.length === 0) return setItems([]);

    const queryString = getJobQueryString(jobs);

    setItemUrl(`docket-items${queryString}`);
  };

  useEffect(() => {
    if (job) {
      setJobUrl(`docket-jobs?project_id=${project}`);
      const parsedJob = JSON.parse(job);
      getItems(parsedJob);
    }
  }, []);

  return (
    <>
      <HeaderPage
        titlePage="New Docket"
        crumbs={[
          { link: "/dockets", name: "Dockets" },
          { link: "dockets/add", name: "New Docket", active: true },
        ]}
      />
      <form onSubmit={handleSubmit(submit)}>
        <DocketForm
          jobs={jobs}
          setJobs={setJobs}
          getItems={getItems}
          setItems={setItems}
          loadingItems={loadingItems}
          items={items}
          {...props}
        />
      </form>
    </>
  );
};

const form = reduxForm({ form: "Docket" });

const mapStateToProps = (state, props) => {
  const { job, project } = queryString.parse(props.location.search);

  const values = {
    date: dayjs().format("YYYY-MM-DD"),
  };

  if (job) {
    values.jobs = JSON.parse(job);
  }

  if (project) {
    values.project_id = project;
  }

  return {
    initialValues: {
      user_id: props.userId,
      ...values,
    },
  };
};

export default connect(mapStateToProps, {})(form(Add));
