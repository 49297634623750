import React from "react";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import ApprovalButton from "../approvals/ApprovalButton";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import PurchaseItemDetails from "./PurchaseItemDetails";

const PurchaseItemOverBudget = () => {
  const { uuid } = useParams();

  const { data: purchaseItem, loading } = useApi(`purchase-items/${uuid}`);

  const title = `${purchaseItem?.purchase?.name} - ${purchaseItem?.description}`;

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage titlePage={title} crumbs={[]} />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{title}</DashboardH3>
            <DashboardSubTitle>
              <Link to={purchaseItem?.purchase?.link}>
                {purchaseItem?.purchase?.name}
              </Link>
            </DashboardSubTitle>
          </DashboardHeader>
        }
        content={<PurchaseItemDetails purchaseItem={purchaseItem} />}
        sideBar={
          <ApprovalButton
            approvalAction={purchaseItem?.over_budget_approval_action}
            wrapperClass="mt-0"
            canTransfer
            approvalOptions={[
              { value: 1, label: "Approve" },
              { value: 3, label: "Deny" },
            ]}
          />
        }
      />
    </>
  );
};

export default PurchaseItemOverBudget;
