import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import formatDate from "../utils/formatDate";
import { Badge } from "reactstrap";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import isSubmitting from "../utils/submitting";
import errorSwal from "../utils/errorSwal";
import { useAuth } from "../../context/auth-context";

const ProfileHeader = ({ payRun, payRunDates }) => {
  const { uuid } = useParams();

  const { user } = useAuth();

  const { takeAction, loading } = useApi();

  const completedPays = payRun.pays.filter((pay) => !!pay.completed_at).length;
  const percentComplete = (completedPays / payRun.pays.length) * 100;

  return (
    <DashboardHeader
      extraButtons={
        user.is_admin && (
          <button
            type="button"
            disabled={loading}
            onClick={() => {
              takeAction("store", `pay-runs/${uuid}/export`)
                .then(({ data }) => {
                  window.open(data.data.url);
                })
                .catch(errorSwal);
            }}
          >
            {isSubmitting(loading, "Export", "Exporting...")}
          </button>
        )
      }
      progressBar
      invertColor
      progress={percentComplete}
    >
      <DashboardH3>Pay Run: {payRunDates}</DashboardH3>
      <p className="mb-0">
        {completedPays} / {payRun.pays.length} Completed
      </p>
      <div className="mt-2">
        <Badge color={payRun.completed_at ? "success" : "warning"}>
          {payRun.completed_at
            ? `Completed at ${formatDate(
                payRun.completed_at,
                "DD/MM/YYYY hh:mm",
              )}`
            : "Incomplete"}
        </Badge>
      </div>
      <Badge color={payRun.paid_on ? "success" : "warning"}>
        {payRun.paid_on
          ? `Paid on ${dayjs(payRun.paid_on).format("DD/MM/YYYY")}`
          : "Unpaid"}
      </Badge>
    </DashboardHeader>
  );
};

export default ProfileHeader;
