import React, { useState } from "react";
import useApi from "../api/useApi";
import Forbidden from "../Forbidden";
import DateRangePicker from "../home/DateRangePicker";
import useProductivityRange from "../hooks/useProductivityRange";
import Tabs from "../tabs/Tabs";
import { Tab } from "../tabs/tabTypes";
import ManagementCPIRs from "./ManagementCPIRs";
import ManagementDashboardGroups from "./ManagementDashboardGroups";
import { Group } from "./managementDashboardTypes";
import ProjectOverview from "./ProjectOverview";

const ManagementDashboard = () => {
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [range, setRange, formattedRange] = useProductivityRange();

  const { data: groups, response }: { data: Group[]; response?: any } = useApi(
    "management-dashboard-groups",
    [],
    true,
  );

  const tabs: Tab[] = [
    {
      title: "CPIRs",
      component: (
        <ManagementCPIRs selectedGroup={selectedGroup} range={formattedRange} />
      ),
    },
    {
      title: "Projects",
      component: (
        <ProjectOverview selectedGroup={selectedGroup} range={formattedRange} />
      ),
    },
  ];

  if (response && groups.length === 0) {
    return <Forbidden />;
  }

  return (
    <div className="">
      <div className="d-flex my-4 align-items-end">
        <div>
          <h4 className="tx-inverse">Dashboard.</h4>
          <p className="mb-0">
            {!selectedGroup ? "No Group Selected." : selectedGroup.name}
          </p>
        </div>
        <div className="ms-auto d-flex">
          <ManagementDashboardGroups
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            data={groups}
          />
          <DateRangePicker className="ms-3" range={range} setRange={setRange} />
        </div>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default ManagementDashboard;
