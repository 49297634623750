import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import colors from "../../data/colors";

const MentionDisplay = ({ content }: { content: RawDraftContentState }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromRaw(content)),
  );

  const { plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(mentionProps) {
        const { mention } = mentionProps;
        const style = {
          backgroundColor: colors.info.light,
          borderColor: colors.info.border,
          color: colors.info.main,
        };
        
        return (
          mention.email ? (
            <a style={style} href={`mailto:${mention.email}`}>
              {mention.name}
            </a>
          ) : (
            <Link
              style={style}
              className="link-hover p-1 rounded-lg"
              to={mention.link ?? ""}
            >
              {mention.name}
            </Link>
          )
        );
      },
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  return (
    <div style={{ wordBreak: "break-word" }}>
      <Editor
        editorState={editorState}
        onChange={setEditorState}
        plugins={plugins}
        editorKey="editor"
        readOnly
      />
    </div>
  );
};

export default MentionDisplay;
