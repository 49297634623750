import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { Field, FormSubmitHandler } from "redux-form";
import { useAuth } from "../../../context/auth-context";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import FormModal from "../../utils/FormModal";
import RenderField from "../../utils/renderField";
import renderToggleInput from "../../utils/renderToggleInput";
import required from "../../utils/required";
import SelectInputAsync from "../../utils/SelectInputAsync";
import { CustomJobTemplate } from "../customJobTypes";
import { CustomJobJob } from "./customJobTypes";

import { useEffect, useState } from "react";
import { Job } from "../../jobs/jobTypes";

const TemplateModal = ({
  toggle,
  modal,
  customJobTemplate,
  job,
}: {
  toggle: () => void;
  modal: boolean;
  customJobTemplate?: CustomJobTemplate;
  job?: CustomJobJob;
}) => {
  const { takeAction }: IUseApi<{}, { data: { link: string } }> = useApi();
  const history = useHistory();
  const { user } = useAuth();
  const { takeAction: getFilledTemplate }: IUseApi<{}, { data: Job }> =
    useApi();

  const [filledTemplate, setFilledTemplate] = useState<Job>();

  useEffect(() => {
    if (modal) {
      getFilledTemplate(
        "store",
        `jobs/${job?.uuid}/custom-job-templates/${customJobTemplate?.uuid}/fill-template`,
        job,
      ).then(({ data }) => {
        setFilledTemplate(data.data);
      });
    }
  }, [modal]);

  const onSubmit: FormSubmitHandler<CustomJobTemplate> = (values) => {
    if (!job) {
      return;
    }

    return takeAction(
      "store",
      `jobs/${job.uuid}/custom-job-templates/${customJobTemplate?.uuid}`,
      values,
    )
      .then(({ data }) => {
        history.push(data.data.link);
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      initialValues={{
        ...filledTemplate,
        project_manager: user?.id,
        scheduled_start_date: dayjs().format("YYYY-MM-DD"),
      }}
      form="CREATE_JOB_FROM_TEMPLATE"
      onSubmit={onSubmit}
      title="Create Job From Template"
      toggle={toggle}
      modal={modal}
    >
      <div className="col-lg-12 form-group">
        <Field
          component={RenderField}
          name="name"
          label="Job Name"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInputAsync}
          name="project_manager"
          label="Job Manager"
          url="/projectjobs/jobmanager"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="scheduled_start_date"
          label="Scheduled Start Date"
          required
          validate={required}
          type="date"
          extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={RenderField}
          name="description"
          label="Description"
          required
          validate={required}
          textarea
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={renderToggleInput}
          name="job_is_dependant"
          label="Make Generated Job Dependant of this Job"
        />
      </div>
    </FormModal>
  );
};

export default TemplateModal;
