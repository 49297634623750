const LIKELIHOOD = [
  {
    value: "0",
    label: "Rare",
  },
  {
    value: "1",
    label: "Unlikely",
  },
  {
    value: "2",
    label: "Possible",
  },
  {
    value: "3",
    label: "Likely",
  },
  {
    value: "4",
    label: "Almost Certain",
  },
];

export default LIKELIHOOD;
