import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { InvoiceStatus, Job, JobStatus } from "./jobTypes";

interface InvoiceLessJobProps {
  job: Job;
  setJob: (job: Job) => void;
}

const MangeInvoiceLessJobs = ({ job, setJob }: InvoiceLessJobProps) => {
  const { takeAction, loading }: IUseApi<Job, { data: Job }> = useApi();

  const requestInvoiceLessJob = () => {
    return takeAction("store", `jobs/${job.uuid}/invoiceless-request`)
      .then(({ data }) => {
        setJob(data.data);
        toast.success("No Invoices Required Requested");
      })
      .catch(errorSwal);
  };

  const requestInvoiceJob = () => {
    return takeAction(
      "destroy",
      `invoiceless-job-requests/${job.invoiceless_job_request?.uuid}`,
    )
      .then(({ data }) => {
        setJob(data.data);
        toast.success("Updated To Invoice Requested");
      })
      .catch(errorSwal);
  };

  if (job.invoice_status === InvoiceStatus.Invoiced) {
    return;
  }

  return (
    <>
      {!job.invoiceless_job_request ? (
        <button
          disabled={loading}
          onClick={requestInvoiceLessJob}
          type="button"
        >
          {isSubmitting(loading, "Invoice Not Required", "Requesting...")}
        </button>
      ) : (
        <button disabled={loading} onClick={requestInvoiceJob} type="button">
          {isSubmitting(loading, "Invoice Required", "Requesting...")}
        </button>
      )}
    </>
  );
};

export default MangeInvoiceLessJobs;
