import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import TopicForm from "./TopicForm";
import { Topic } from "./topicType";

const AddTopic = () => {
  const { takeAction }: IUseApi = useApi();
  const history = useHistory();

  const onSubmit: FormSubmitHandler = (values) => {
    return takeAction("store", "topics", values)
      .then(({ data }: { data: { data: Topic } }) => {
        history.push(`/topics/${data.data.uuid}/details`);
        toast.success("Topic Added.");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Add Topic" />
      <TopicForm form="AddTopic" onSubmit={onSubmit} />
    </>
  );
};

export default AddTopic;
