import React from "react";
import { Progress, Spinner } from "reactstrap";

const UploadProgress = ({ files }) => (
  <div className="mt-3">
    {files.map((file, index) => (
      <div key={index} className="d-flex items-center w-100 mb-2">
        <Spinner type="grow" color="primary" className="me-3" />
        <div className="w-100">
          <p className="mb-0">{file.name}</p>
          <Progress
            className="progress-bar-sm"
            striped
            animated
            value={file.progress || 0}
            color="primary"
          />
        </div>
      </div>
    ))}
  </div>
);

export default UploadProgress;
