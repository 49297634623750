import React from "react";
import { MediaProps } from "reactstrap";
import ResizableMedia from "./ResizableMedia";
import { getSrc } from "./subjectUtils";

const EmbeddedVideo = (props: MediaProps) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src } = entity.getData();
  const embeddedSrc = getSrc(src);

  if (embeddedSrc) {
    return (
      <ResizableMedia {...props}>
        <div className="h-100 w-100">
          <iframe
            height="100%"
            width="100%"
            src={embeddedSrc}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </ResizableMedia>
    );
  }

  return (
    <ResizableMedia {...props}>
      <video src={src} controls />
    </ResizableMedia>
  );
};

export default EmbeddedVideo;
