import _ from "lodash";
import React, { useState } from "react";
import PreviewModal from "../documents/PreviewModal";
import useModal from "../hooks/useModal";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import DocumentListItem from "./DocumentListItem";

const StandardDocumentList = (props) => {
  const { documents, url, highlightedDocuments, setHighlightedDocuments } =
    props;

  const [draggingDocuments, setDraggingDocuments] = useState([]);

  const { toggle, modal } = useModal();

  const naturallyOrdered = documents
    .slice()
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true, base: true }),
    );

  const orderedDocuments = _.orderBy(naturallyOrdered, ["is_folder"], ["desc"]);

  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(orderedDocuments);

  if (documents.length === 0) return null;

  return (
    <>
      <div className="mg-t-20" id="docList">
        <div className="file-group">
          {orderedDocuments.map((doc) => (
            <DocumentListItem
              {...props}
              key={doc.uuid}
              doc={doc}
              url={url}
              setSelectedDocument={setSelectedDocument}
              toggle={toggle}
              highlightedDocuments={highlightedDocuments}
              setHighlightedDocuments={setHighlightedDocuments}
              setDraggingDocuments={setDraggingDocuments}
              draggingDocuments={draggingDocuments}
              orderedDocuments={orderedDocuments}
            />
          ))}
        </div>
      </div>
      <PreviewModal
        document={selectedDocument}
        modal={modal}
        toggle={toggle}
        changeDocument={changeDocument}
      />
    </>
  );
};

export default StandardDocumentList;
