import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import isSubmitting from "../utils/submitting";
import swal from "sweetalert";
import { useAuth } from "../../context/auth-context";

export interface RouteParams {
  number: string;
}

const DeleteButton = () => {
  const { number } = useParams<RouteParams>();
  const history = useHistory();
  const { takeAction, loading } = useApi();

  const { user } = useAuth();

  const forceClose = () => {
    swal({
      title: "Close This Project?",
      text: "This action will set all open jobs to 'invoice not required' and 'cancelled', subsequently marking the project as 'closed'.",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          closeModal: true,
          visible: true,
        },
        delete: {
          text: "Delete",
          value: true,
          closeModal: true,
          className: "btn btn-danger align-baseline",
        },
      },
    }).then((val) => {
      if (val) {
        return takeAction("destroy", `projects/close/${number}`)
          .then(() => {
            history.push("/projects");
            toast.success("Project Closed");
          })
          .catch(errorSwal);
      }
    });
  };

  if (!user?.is_admin) {
    return null;
  }

  return (
    <Button
      onClick={forceClose}
      outline
      color="danger"
      block
      className="mt-3"
      disabled={loading}
    >
      {isSubmitting(loading, "Force Close", "Closing...")}
    </Button>
  );
};

export default DeleteButton;
