import { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";

const FileInput = (props) => {
  const {
    input,
    required,
    label,
    meta,
    percent,
    customFileClassName = "",
    showFile = true,
  } = props;

  const [file, setFile] = useState({});

  const { name, size, link } = file;

  useEffect(() => {
    const val = props?.input?.value;
    if (val?.name) {
      setFile({
        name: val.name,
        size: val.file_size,
        link: val.link,
      });
    }
  }, []);

  const onChange = ({ target }) => {
    const file = target.files[0];
    if (file) {
      props?.onChange?.(file);
      input.onChange(file);
      setFile(file);
    }
  };

  return (
    <>
      {label && (
        <label className="tx-inverse tx-semibold">
          {label}
          {required ? <span className="tx-danger"> *</span> : ""}
          <FieldInformationPopOver {...props} />
        </label>
      )}
      <div className={`custom-file ${customFileClassName}`}>
        <input
          type="file"
          onChange={onChange}
          className={`custom-file-input ${
            meta.error && meta.touched ? "parsley-error" : ""
          }`}
          {...props}
        />
        {percent > 0 && (
          <Progress
            className="mt-2 progress-bar-sm"
            value={percent}
            animated
            striped
            color={percent == 100 ? "success" : "primary"}
          />
        )}
        <p className="custom-file-label custom-file-label-primary rounded-lg shadow-sm">
          {name || ""}
        </p>
        {name && size && <div>{`${Math.floor(size / 1000)} KB`}</div>}

        {link && showFile && <a href={link}>{name}</a>}

        {meta.touched && meta.error && (
          <span className={meta.error ? "parsley-errors-list" : ""}>
            {meta.error}
          </span>
        )}
      </div>
    </>
  );
};
export default FileInput;
