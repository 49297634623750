import { useMutation, useQueryClient } from "react-query";
import api from "../../api/api";
import { toast } from "react-toastify";

export default function useCreateTimesheet() {
  const queryData = useQueryClient();

  return useMutation((values) => api.post("timesheets", values), {
    onSuccess: (data, variables) => {
      queryData.invalidateQueries("timesheets");

      queryData.invalidateQueries("timesheet-dates");
      toast.success("Timesheet added.");
    },
  });
}
