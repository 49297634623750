import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import Edit from "./Edit";
import Samples from "./Samples";
import TestRequestTestSuites from "./TestRequestTestSuites";
import TestRequestTests from "./TestRequestTests";
import TestRequestPurchaseOrder from "./TestRequestPurchaseOrder";
import ImportExternalTestRequest from "./ImportExternalTestRequest";

export type TestRequestPages =
  | "details"
  | "samples"
  | "documents"
  | "tests"
  | "test-suites"
  | "purchase-order"
  | "external-request";

const ShowPage = (props: any) => {
  const { user } = useAuth();

  const { page, uuid } = useParams<{
    page: TestRequestPages;
    uuid: string;
  }>();

  switch (page) {
    case "details":
      return <Edit {...props} initialValues={props.testRequest} />;
    case "samples":
      return <Samples {...props} />;
    case "documents":
      return (
        <NewProfileDocuments
          model="App\Models\TestRequest"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/testRequests/${uuid}`}
          homeUrl={`testRequests/${uuid}/documents`}
        />
      );
    case "tests":
      return (
        <TestRequestTests
          {...props}
          projectID={props?.testRequest?.project?.id}
          testRequestID={props.testRequest.testrequest.id}
          job={props.testRequest.job}
        />
      );
    case "test-suites":
      return <TestRequestTestSuites />;
    case "purchase-order":
      return (
        <TestRequestPurchaseOrder
          {...props}
          testRequestID={props.testRequest.testrequest.id}
        />
      );
    case "external-request":
      return <ImportExternalTestRequest {...props} testRequestID={uuid} />;
    default:
      return null;
  }
};

export default ShowPage;
