import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchTestRequests } from "../../actions/testRequestActions";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import formatAddress from "../utils/formatAddress";
import Spinner from "../utils/Spinner";
import DashboardTabs from "./DashboardTabs";
import ShowPage from "./ShowPage";
import useApi from "../api/useApi";

const TestRequestDashboard = () => {
  const { uuid, page } = useParams();

  const {
    data,
    loading,
    setData: setTestRequest,
  } = useApi(
    `testrequests/${uuid}`,
    {
      job: {},
      project: {},
      testrequest: {},
    },
    true,
    "/samples",
  );

  const { job, project, testrequest } = data;

  if (loading) {
    return <Spinner loading={loading} />;
  }

  const percentComplete = !testrequest.test_count
    ? 0
    : (testrequest.completed_tests_count / testrequest.test_count) * 100;

  return (
    <React.Fragment>
      <HeaderPage
        titlePage={testrequest.title}
        crumbs={[
          { link: job.project_link, name: project.name },
          { link: job.link, name: job.name },
          {
            link: ``,
            name: "Test Request",
            active: true,
          },
        ]}
      />
      <div className="row row-sm">
        <div className="col-lg-8">
          <DashboardHeader progressBar invertColor progress={percentComplete}>
            <DashboardH3>{testrequest.title}</DashboardH3>
            <p className="tx-inverse mb-0">
              Client:{" "}
              <Link to={project?.client?.link}>{project?.client?.name}</Link>
            </p>
            <p className="tx-inverse mb-0">
              Project:{" "}
              <Link to={`/${job.project_link}`}>{job.project_name}</Link>
            </p>
            <p className="tx-inverse mb-0">
              Job: <Link to={job.link}>{job.name}</Link>
            </p>
            {formatAddress(project) !== "Not Provided" && (
              <p className="tx-inverse mb-0">
                Address:{" "}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                    formatAddress(project),
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatAddress(project)}
                </a>
              </p>
            )}
            <DashboardSubTitle>{testrequest.remarks}</DashboardSubTitle>
            {job.docket && (
              <DashboardSubTitle>
                Docket:{" "}
                <Link to={`/dockets/${job.docket.uuid}/details`}>
                  {job.docket.name}
                </Link>
              </DashboardSubTitle>
            )}
          </DashboardHeader>
          <ShowPage testRequest={data} setTestRequest={setTestRequest} />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <DashboardTabs />
          {testrequest.samples.length > 0 &&
            !job.docket &&
            page !== "samples" && (
              <div className="mg-t-20">
                <Link
                  className="btn btn-outline-primary w-100 no-wrap"
                  to={`/dockets/add?job=${encodeURIComponent(
                    JSON.stringify([
                      {
                        label: data.job.name,
                        value: data.job.id,
                      },
                    ]),
                  )}&project=${data?.job?.project_id}&jobUuid=${
                    data.job.uuid
                  }&projectUuid=${data.project?.uuid}`}
                >
                  Generate Docket from Job
                </Link>
              </div>
            )}
          {page !== "samples" && (
            <div className="mt-3">
              <SideList
                commentableType="App\Models\ProjectJob"
                commentableId={job?.id}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  initialValues: state.testRequests.items,
});

export default connect(mapStateToProps, {
  fetchTestRequests,
})(TestRequestDashboard);
