import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { reset, SubmissionError } from "redux-form";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import ProfileButtons from "../utils/ProfileButtons";
import SendModal from "./SendModal";
import isSubmitting from "../utils/submitting";

const DeliverableProfileButtons = ({ selectedDeliverables }) => {
  const { takeAction, loading } = useApi();
  const { toggle, modal } = useModal();
  const { number: projectUuid } = useParams();

  const selectedCount = selectedDeliverables.length;
  const profileButtons = [
    {
      text: selectedCount ? (
        <>
          <i className="fa fa-paper-plane tx-18 me-2 tx-primary" />
          Send selected deliverables
        </>
      ) : (
        "Select deliverables to send"
      ),
      onClick: toggle,
      disabled: !selectedCount,
    },
    {
      text: isSubmitting(
        loading,
        <>
          {selectedCount ? (
            <>
              <i className="fa fa-download tx-18 me-2 tx-primary" />
              Download selected deliverables
            </>
          ) : (
            "Select deliverables to Download"
          )}
        </>,
        "Zipping...",
      ),
      onClick: () => downloadDeliverable(),
      disabled: !selectedCount,
    },
  ];

  const onSubmit = (values, dispatch) => {
    const data = {
      deliverableUuids: selectedDeliverables.map(({ uuid }) => uuid),
      contact: validate(values),
    };

    return takeAction(
      "store",
      `projects/${projectUuid}/deliverables/bulksend`,
      data,
    )
      .then(() => {
        toast.success(`Deliverables sent successfully`);
        dispatch(reset("bulkSendDeliverables"));
        toggle();
      })
      .catch(errorSwal);
  };

  const validate = (values) => {
    const email = values.recipient
      ? values.recipient.value
      : values.custom_recipient;

    if (!email) {
      throw new SubmissionError({
        _error: "Please select or provide a recipient",
      });
    }

    return email;
  };

  const downloadDeliverable = () => {
    const data = {
      deliverableUuids: selectedDeliverables.map(({ uuid }) => uuid),
    };
    return takeAction(
      "store",
      `projects/${projectUuid}/deliverables/bulkdownload`,
      data,
    )
      .then(({ data }) => {
        window.location.href = data.data.path;
      })
      .catch(errorSwal);
  };

  return (
    <>
      <ProfileButtons buttons={profileButtons} />
      <SendModal
        modal={modal}
        toggle={toggle}
        onSubmit={onSubmit}
        selectedCount={selectedCount}
      />
    </>
  );
};

export default DeliverableProfileButtons;
