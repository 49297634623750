import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getUser } from "../../api/auth";
import { useAuth } from "../../context/auth-context";
import useModal from "../hooks/useModal";

const LogoutModal = () => {
  const { modal, setModal } = useModal();
  const [timeLeftUntilLogout, setTimeLeftUntilLogout] = useState();
  const { logout, sessionExpiry, refresh } = useAuth();

  useEffect(() => {
    const tokenInterval = setInterval(() => {
      const tokenLife = sessionExpiry - Date.now() / 1000;
      setTimeLeftUntilLogout(Math.round(tokenLife));

      if (tokenLife > 120 && modal) {
        setModal(false);
      }

      if (tokenLife < 120) {
        setModal(true);
      }

      if (tokenLife <= 0) {
        setModal(false);
      }
    }, 1000);

    return () => clearInterval(tokenInterval);
  }, [modal, sessionExpiry]);

  return (
    <Modal className="wd-md-1000 mx-wd-800" isOpen={modal} backdrop="static">
      <ModalHeader>Session Expiring</ModalHeader>
      <ModalBody>
        Your session will expire in {timeLeftUntilLogout} second/s.
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline onClick={logout}>
          Logout
        </Button>
        <Button color="primary" onClick={() => getUser().then(refresh)}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LogoutModal;
