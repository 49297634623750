import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import history from "../utils/History";
import formError from "../utils/formError";
import FormStaffRole from "./Form";

const EditEmploymentDetails = ({ employmentDetails, setEmploymentDetails }) => {
  const { takeAction } = useApi();
  const { uuid } = useParams();

  const onSubmit = (values, _, props) => {
    return takeAction("update", `staffroles/${uuid}`, values)
      .then(({ data }) => {
        toast.success(`Profile updated successfully`);
        setEmploymentDetails(data.data);
        props.initialize(data.data);
      })
      .catch(formError);
  };

  const deleteDetails = () => {
    deleteSwal(employmentDetails.name).then(() => {
      return takeAction("destroy", `staffroles/${uuid}`)
        .then(() => {
          history.push(`/employment-details`);
          toast.success(`${employmentDetails.name} deleted successfully`);
        })
        .catch(errorSwal);
    });
  };

  return (
    <FormStaffRole
      onDelete={deleteDetails}
      onSubmit={onSubmit}
      initialValues={employmentDetails}
    />
  );
};

export default EditEmploymentDetails;
