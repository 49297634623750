import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { AiFillCopy } from "react-icons/ai";
import { organisationVariables } from "./organisationVariables";
import Tabs from "../tabs/Tabs";

const NewVariableList = ({ variables }) => {
  const tabs = [
    {
      title: "Standard",
      component: <Variables variables={organisationVariables} />,
    },
  ];

  if (variables?.single) {
    tabs.unshift({
      title: "Variables",
      component: <Variables variables={variables.single} />,
    });
  }

  return (
    <div className="list-group">
      <label className="section-title mt-0">Available Variables</label>

      <Tabs tabs={tabs} wrapperClass="mb-3" />
    </div>
  );
};

const Variables = ({ variables }) => {
  return variables.map((variable, index) => {
    return (
      <div key={index} className="list-group-item d-block pd-y-20">
        <h6 className="lh-3 mg-b-10 tx-inverse">
          {variable.name}
          {(variable.table || variable.image) && " *"}
        </h6>
        <p className="tx-13 mg-b-0">{variable.description}</p>
        <p className="tx-13 mg-b-0 pointer-hover">
          <CopyToClipboard
            options={{ format: "text/plain" }}
            text={variable.template_variable}
            onCopy={() =>
              toast.success(`${variable.template_variable} copied.`)
            }
          >
            <span className="tx-primary">
              Use variable {variable.template_variable} <AiFillCopy />
            </span>
          </CopyToClipboard>
        </p>

        {variable.image && (
          <p className="tx-13 mg-b-0">
            <b>Image</b>
          </p>
        )}
        {variable.table && (
          <p className="tx-13 mg-b-0">
            <b>Table</b>
          </p>
        )}
      </div>
    );
  });
};

export default NewVariableList;
