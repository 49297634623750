import "chart.js/auto";
import React from "react";
import { Line } from "react-chartjs-2";

const options = {
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem) => `Dry Density: ${tooltipItem[0].yLabel}`,
      label: (tooltipItem) => `Moisture Percent: ${tooltipItem.xLabel}%`,
    },
  },
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Density (t/m3)",
        },
      },
    ],
    xAxes: [
      {
        id: "x-axis-0",
        type: "linear",
        scaleLabel: {
          display: true,
          labelString: "Moisture (%)",
        },
      },
    ],
  },
};

const dataPoints = (points) => {
  return points.map((point) => ({
    x: point.moisture.moisture_content.toFixed(2),
    y: point.dry_density.toFixed(2),
  }));
};

const formatData = (points) => {
  const labels = points.map(
    (point) => `${point.moisture.moisture_content.toFixed(2)}%`,
  );
  const data = dataPoints(points);

  return {
    labels,
    datasets: [
      {
        data,
        label: "Dry Density",
        backgroundColor: "rgba(0,170,79,0)",
        borderColor: "rgba(0,170,79,1)",
        lineTension: 0.5,
      },
    ],
  };
};

const Curve = ({ points }) => {
  const isComplete = points.every((point) => point.moisture.moisture_content);
  if (!isComplete) return null;
  return (
    <div className="card my-3">
      <div className="card-body">
        <label className="slim-card-title mb-5">Compaction Curve</label>
        <Line data={formatData(points)} options={options} />
      </div>
    </div>
  );
};
export default Curve;
