import axios from "../api/api";
import {
  DELETE_STAFF_SCHEDULE,
  NEW_STAFF_SCHEDULE,
  UPDATE_STAFF_SCHEDULE,
} from "../actions/types";

export const createStaffSchedule = (postData) => (dispatch) => {
  return axios.post(`/staff-schedules`, postData).then(({ data }) =>
    dispatch({
      type: NEW_STAFF_SCHEDULE,
      payload: data.data,
    }),
  );
};

export const updateStaffSchedule = (postData) => (dispatch) => {
  return axios
    .put(`/staff-schedules/${postData.uuid}`, postData)
    .then(({ data }) => data.data)
    .then((staffSchedule) =>
      dispatch({
        type: UPDATE_STAFF_SCHEDULE,
        payload: staffSchedule,
      }),
    );
};

export const deleteStaffSchedule = (uuid) => (dispatch) => {
  return axios
    .delete(`/staff-schedules/${uuid}`)
    .then(({ data }) => data.data)
    .then((staffSchedule) =>
      dispatch({
        type: DELETE_STAFF_SCHEDULE,
        payload: staffSchedule,
      }),
    );
};
