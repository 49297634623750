import React from "react";
import { toast } from "react-toastify";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import TopicForm from "./TopicForm";
import { Topic } from "./topicType";

export interface EditTopicProps {
  topic?: Topic;
  setTopic: (topic: Topic) => void;
}

const EditTopic = ({ topic, setTopic }: EditTopicProps) => {
  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler = (values) => {
    return takeAction("update", `topics/${topic?.uuid}`, values)
      .then(({ data }: { data: { data: Topic } }) => {
        setTopic(data.data);
        toast.success("Topic Updated");
      })
      .catch(errorSwal);
  };

  return (
    <TopicForm initialValues={topic} form="EditTopic" onSubmit={onSubmit} />
  );
};

export default EditTopic;
