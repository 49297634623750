import { useParams } from "react-router-dom";
import {
  TemplateDocument,
  TemplateDocumentProfilePages,
} from "./templateDocumentTypes";
import FileCard from "../utils/FileCard";
import TemplateDocumentFieldForm from "./TemplateDocumentFieldForm";
import NotFound from "../404";

const ShowTemplateDocumentPage = ({
  document,
  setDocument,
}: {
  document?: TemplateDocument;
  setDocument: (document: TemplateDocument) => void;
}) => {
  const { page } = useParams<{ page: TemplateDocumentProfilePages }>();

  if (!document) {
    return null;
  }

  switch (page) {
    case "details":
      return <FileCard link={document.link} mime_type={document.mime_type} />;
    case "fields":
      return document.spreadsheet_sheets ? (
        <TemplateDocumentFieldForm
          variables={document.template.variables}
          sheets={document.spreadsheet_sheets}
          setDocument={setDocument}
          initialValues={{ fields: document.fields }}
        />
      ) : (
        <NotFound />
      );
    default:
      return <FileCard link={document.link} mime_type={document.mime_type} />;
  }
};

export default ShowTemplateDocumentPage;
