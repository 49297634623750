const NOTIFICATIONS = [
  {
    label: "New Approval",
    description:
      "Receive an email notification when you receive a new approval.",
    name: "new-approval-action"
  },
  {
    label: "Approval Updated",
    description:
      "Receive an email notification when an approval you are waiting on has been updated.",
    name: "approval-action-updated"
  },
  {
    label: "New CPIR",
    description:
      "Receive an email notification when a new CPIR is raised for you to be done.",
    name: "new-cpir"
  },
  {
    label: "Invoice Status Changed",
    description:
      "Receive an email notification when an invoice has has its status changed.",
    name: "invoice-status-changed",
    group: "Accounting"
  },
  {
    label: "New Job",
    description:
      "Receive an email notification when a new job is added that you are the job manager for.",
    name: "new-job"
  },
  {
    label: "Job Status Changed",
    description:
      "Receive an email notification when a job that you are associated to has its status changed.",
    name: "job-status-changed"
  },
  {
    label: "Job Scheduled Finish Date Changed",
    description:
      "Receive an email notification when a job you are associated to has its finish date changed.",
    name: "job-scheduled-finish-date-changed"
  },
  {
    label: "Notice Added to Noticeboard",
    description:
      "Receive an email notification when somebody in your organisation posts a notice.",
    name: "new-notice"
  },
  {
    label: "New Comment Added",
    description:
      "Receive an email notification when somebody posts a comment on a resource.",
    name: "comment-added"
  }
];

export default NOTIFICATIONS;
