import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import { ExternalJobRequest } from "../../jobs/jobTypes";
import formError from "../../utils/formError";
import FormModal from "../../utils/FormModal";
import InformationAlert from "../../utils/InformationAlert";
import RenderField from "../../utils/renderField";
import required from "../../utils/required";

interface ExternalRequestModalProps {
  toggle: Function;
  modal: boolean;
  setRequests: (requests: ExternalJobRequest[]) => void;
  requests: ExternalJobRequest[];
}

const ExternalRequestModal = ({
  toggle,
  modal,
  setRequests,
  requests,
}: ExternalRequestModalProps) => {
  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi<{}, { data: ExternalJobRequest }> = useApi();

  const onSubmit: FormSubmitHandler<{ name?: string; email: string }> = (
    values,
  ) => {
    return takeAction("store", `jobs/${uuid}/external-requests`, values)
      .then(({ data }) => {
        setRequests([...requests, data.data]);
        toggle();

        toast.success("Job Sent");
      })
      .catch(formError);
  };

  return (
    <FormModal
      onSubmit={onSubmit}
      toggle={toggle}
      modal={modal}
      form="SendExternalRequest"
      title="Send Job Externally"
    >
      <div className="col-12 mb-4">
        <InformationAlert
          type="info"
          closable
          body={`If you have any select fields in this job that are pulled from your database, whomever you send this job to will be able to see this information. \n\nPlease use caution when using this feature.`}
          title="Sensitive data may be shown"
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={RenderField}
          name="email"
          required
          validate={required}
          label="Email"
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field component={RenderField} name="name" label="Name" />
      </div>
    </FormModal>
  );
};

export default ExternalRequestModal;
