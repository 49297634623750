import React from "react";
import { ReactComponent as Logo } from "../../svgs/logo-grey.svg";
import "./spinner.css";

const Spinner = ({ loading }: { loading: boolean }) => {
  if (!loading) return null;

  return (
    <div id="center" className="spinner">
      <div className="sweet-loading">
        <Logo height="100" width="75" className="spinner-loading" />
      </div>
    </div>
  );
};

export default Spinner;
