import PropTypes from "prop-types";
import { useAuth } from "../../context/auth-context";
import useModal from "../hooks/useModal";
import StandardDocumentModal from "../standardDocuments/standardDocumentModal";

const AddFileButton = (props) => {
  const { link, disableUpload, hideButton = false, downloadLink } = props;
  const { toggle, modal } = useModal();
  const { user } = useAuth();

  if (hideButton) {
    return null;
  }

  return (
    <>
      <ul className="nav nav-activity-profile mg-y-20">
        {!disableUpload && (
          <li className="nav-item">
            <button
              type="button"
              onClick={toggle}
              className="pointer-hover w-100 nav-link"
            >
              <i className="icon ion-plus tx-primary" /> Upload{" "}
              {props.title || "File"}
            </button>
          </li>
        )}
        {link && (
          <li className="nav-item">
            <button
              type="button"
              onClick={() => (window.location.href = downloadLink ?? link)}
              className="pointer-hover w-100 nav-link"
            >
              <i className="icon icon ion-archive tx-secondary" /> Download
            </button>
          </li>
        )}
      </ul>
      <StandardDocumentModal
        {...props}
        modal={modal}
        toggle={toggle}
        bucket={`organisations/${user.active_organisation.uuid}/${props.bucket}`}
      />
    </>
  );
};

AddFileButton.propTypes = {
  documentable_id: PropTypes.string.isRequired,
  documentable_type: PropTypes.string.isRequired,
  bucket: PropTypes.string.isRequired,
  whenSubmitted: PropTypes.func,
  title: PropTypes.string,
};

export default AddFileButton;
