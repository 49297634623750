import React, { useState, useEffect } from "react";
import FileList from "../utils/FileList";
import FormHeader from "../utils/FormHeader";

const Documents = (props) => {
  const { competency } = props;

  const [files, setFiles] = useState(competency.documents ?? []);

  useEffect(() => {
    setFiles(competency.documents);
  }, [competency.documents]);

  return (
    <>
      <FormHeader>Documents</FormHeader>
      <div className="col-12">
        <FileList {...props} files={files} setFiles={setFiles} />
      </div>
    </>
  );
};

export default Documents;
