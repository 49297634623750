import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { initialize } from "redux-form";
import useApi from "../../api/useApi";
import useModal from "../../hooks/useModal";
import formError from "../../utils/formError";
import FormModal from "../../utils/FormModal";
import GeneralFields from "./generalFields";

const FORM = "EditResidentialGeneral";

const EditGeneralModal = ({ siteInspection, setSiteInspection }) => {
  const { takeAction } = useApi();

  const { uuid } = useParams();

  const { toggle, modal } = useModal();

  const onSubmit = ({ general }, dispatch) => {
    general.scheduled_at = dayjs(general.scheduled_at).format(
      "YYYY-MM-DD HH:mm:ss",
    );

    general.scheduled_finish = dayjs(general.scheduled_finish).format(
      "YYYY-MM-DD HH:mm:ss",
    );

    return takeAction(
      "update",
      `siteinspections/residential/general/${uuid}`,
      general,
    )
      .then(({ data }) => {
        setSiteInspection(data.data);
        dispatch(initialize("addResidentialSiteInspection", data.data));
        toggle();
        toast.success("Site Inspection Updated");
      })
      .catch(formError);
  };

  return (
    <>
      <Button size="sm" outline color="primary" onClick={toggle}>
        Edit Details
      </Button>
      <FormModal
        form={FORM}
        initialValues={siteInspection}
        modal={modal}
        toggle={toggle}
        onSubmit={onSubmit}
        title={`Edit ${siteInspection.name}`}
      >
        <GeneralFields />
      </FormModal>
    </>
  );
};

export default EditGeneralModal;
