import React, { useEffect } from "react";

import PaymentFields from "./PaymentFields";

const PaymentFieldArray = props => {
  const { fields, recurringPurchase, initialValues, canAdd } = props;
  const itemOptions = recurringPurchase.prices.map(price => ({
    label: `${price.description} (${price.branch})`,
    value: price.id
  }));
  useEffect(() => {
    if (initialValues && initialValues.items && initialValues.items.length > 0)
      return;
    fields.push();
  }, []);

  return (
    <>
      {fields.map((member, index) => {
        return (
          <PaymentFields
            itemOptions={itemOptions}
            key={index}
            {...props}
            member={member}
            index={index}
          />
        );
      })}
      {canAdd && (
        <div className="col-12 form-group d-flex">
          <button
            className="btn btn-link ms-auto"
            type="button"
            onClick={() => fields.push({})}
          >
            Add Item
          </button>
        </div>
      )}
    </>
  );
};

export default PaymentFieldArray;
