import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ReactComponent as Empty } from "../../svgs/empty.svg";
import useApi from "../api/useApi";
import BranchDropdown from "../branches/BranchDropdown";
import HeaderPage from "../header/HeaderPage";
import SearchInput from "../search/SearchInput";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import SVGContainer from "../utils/SVGContainer";
import filterProjects from "./filterProjects";
import InvoiceStatusTabs from "./InvoiceStatusTabs";
import OverdueTab from "./OverdueTabs";
import ProjectCard from "./ProjectCard";
import StatusTabs from "./StatusTabs";
import useJobFilters from "./useJobFilters";

const BranchJobs = () => {
  const [projects, setProjects] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");

  const [sortDirection, setSortDirection] = useState("asc");

  const [machineState, setMachineState] = useState("no-branch-selected");

  const {
    filters,
    setFilters,
    toggleOverdue,
    toggleStatuses,
    setSearch,
    toggleInvoiceStatuses,
  } = useJobFilters();

  const { takeAction } = useApi();

  useEffect(() => {
    if (selectedBranch) {
      setMachineState("loading");
      takeAction(
        "index",
        `resource-project-jobs?model=OrganisationBranch&id=${selectedBranch.id}&filter[project.status]=0`,
      )
        .then(({ data }) => setProjects(data.data))
        .catch(errorSwal)
        .then(() => setMachineState("branch-selected"));
    }
  }, [selectedBranch]);

  const flattenedJobs = _.flatten(projects.map((p) => p.jobs));

  const filtered = filterProjects(projects, filters);

  const flattenedFiltered = _.flatten(filtered.map((p) => p.jobs));

  const sorted = _.orderBy(filtered, ["name"], [sortDirection]);

  return (
    <>
      <HeaderPage titlePage="Branch Jobs" crumbs={[]} />

      <div className="row">
        <div className="col-lg-8">
          {/* Am trying the state machine model in this component. */}
          {machineState === "loading" ? (
            <CustomScaleLoader>Fetching Projects...</CustomScaleLoader>
          ) : machineState === "no-branch-selected" ? (
            <SVGContainer SVG={Empty}>
              <p className="mg-y-20">
                No Branch Selected, Please Select a Branch from the Right.
              </p>
            </SVGContainer>
          ) : (
            <>
              {flattenedJobs.length > 0 && (
                <>
                  <SearchInput
                    value={filters.search}
                    setValue={setSearch}
                    wrapperclass="mb-3"
                  />
                  <div className="d-flex mb-3 align-items-center">
                    <p className="tx-inverse mb-0">
                      Showing {flattenedFiltered.length} of{" "}
                      {flattenedJobs.length} Jobs.
                    </p>
                    <button
                      type="button"
                      className="btn btn-link ms-auto"
                      onClick={() =>
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc",
                        )
                      }
                    >
                      <i className="fa fa-sort text-secondary" />
                    </button>
                  </div>
                </>
              )}
              {sorted.map((project) => {
                return <ProjectCard key={project.id} project={project} />;
              })}
            </>
          )}
        </div>
        <div className="col-lg-4">
          <BranchDropdown
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
            wrapperClass="mb-3"
          />
          <StatusTabs
            jobs={flattenedJobs}
            filters={filters}
            setFilters={setFilters}
            toggleStatuses={toggleStatuses}
          />

          <InvoiceStatusTabs
            jobs={flattenedJobs}
            toggleInvoiceStatuses={toggleInvoiceStatuses}
            filters={filters}
          />

          <OverdueTab
            jobs={flattenedJobs}
            filters={filters}
            toggleOverdue={toggleOverdue}
          />
        </div>
      </div>
    </>
  );
};

export default BranchJobs;
