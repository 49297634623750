import { Button } from "reactstrap";
import HeaderPage from "../header/HeaderPage";
import useFilter, { IFilter } from "../hooks/useFilter";
import useModal from "../hooks/useModal";
import usePagination from "../hooks/usePagination";
import useSort, { ISorter } from "../hooks/useSort";
import flattenInfinitePages from "../utils/flattenInfinitePages";
import useTestDashboardFilters from "./hooks/useTestDashboardFilters";
import TestDashboardFilterModal from "./TestDashboardFilterModal";
import TestDashboardOverview from "./TestDashboardOverview";
import { TestRequestIndex } from "./testRequestTypes";
import { useState } from "react";
import useTestRequestWorksheets from "./hooks/useTestRequests";
import TestWorksheetTable from "./TestWorksheetTable";

export type TestDashboardFilterKeys =
  | "branch_id"
  | "status"
  | "job_manager"
  | "project_id"
  | "client_id"
  | "overdue"
  | "test_method_id";

export type TestDashboardSortKeys =
  | "project_jobs.scheduled_finish_date"
  | "project_jobs.created_at";

export type TestDashboardFilter = IFilter<
  TestDashboardFilterKeys,
  number | boolean
>;

const TestDashboard = () => {
  const { searchQuery, onSearch } = usePagination();
  const { toggle, modal } = useModal();

  const originalFilters = useTestDashboardFilters();

  const {
    sorters,
    toggleSorter,
    stringified: sortString,
  } = useSort(testSorters);

  const {
    stringified,
    filters,
    toggleFilter,
    filterCount,
    activeFilters,
    toggleMulti,
  } = useFilter<TestDashboardFilterKeys, number | boolean>(originalFilters);

  const [testStatusFilter, setTestStatusFilter] = useState<
    "to_be_reported" | "incomplete"
  >();

  const statusFilters = testStatusFilter
    ? `&filter[test_status]=${testStatusFilter}`
    : "";

  const { data, isFetchingNextPage, fetchNextPage, isFetching } =
    useTestRequestWorksheets(
      searchQuery + stringified + statusFilters + sortString,
    );

  const flattenedData = flattenInfinitePages<TestRequestIndex>(data);

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Samples",
            link: "/samples",
          },
          {
            name: "Test Dashboard",
            link: "/",
            active: true,
          },
        ]}
        titlePage="Test Dashboard"
      />
      <div className="d-flex mb-3">
        <Button onClick={toggle} outline size="sm" color="primary">
          Filters {filterCount > 0 ? ` (${filterCount})` : ""}
        </Button>
        {/* <TestRequestsExportButton
          query={`&filter[search]=`.concat(
            searchQuery + stringified + sortString,
          )}
        /> */}
        <TestDashboardFilterModal
          toggleFilter={toggleFilter}
          filters={filters}
          modal={modal}
          toggleMulti={toggleMulti}
          toggle={toggle}
        />
      </div>
      <TestDashboardOverview
        queryString={stringified}
        testStatusFilter={testStatusFilter}
        setTestStatusFilter={setTestStatusFilter}
      />
      <TestWorksheetTable
        testRequestWorksheets={flattenedData ?? []}
        activeFilters={activeFilters}
        fetchNextPage={fetchNextPage}
        flattenedData={flattenedData}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={onSearch}
        sorters={sorters}
        toggleSorter={toggleSorter}
      />
      {/* <TestDashboardList
        activeFilters={activeFilters}
        fetchNextPage={fetchNextPage}
        flattenedData={flattenedData}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={onSearch}
        sorters={sorters}
        toggleSorter={toggleSorter}
      /> */}
    </>
  );
};

const testSorters: ISorter<TestDashboardSortKeys>[] = [
  {
    label: "Scheduled Finish Date",
    name: "project_jobs.scheduled_finish_date",
  },
  {
    label: "Created At",
    name: "project_jobs.created_at",
    sortBy: "asc",
  },
];

export default TestDashboard;
