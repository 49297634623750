import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import PermissionGroupForm from "./PermissionGroupForm";
import { PermissionGroup, PermissionGroupProps } from "./permissionGroupTypes";

const EditPermissionGroup = (props: PermissionGroupProps) => {
  const { permissionGroup, setPermissionGroup } = props;

  const { uuid } = useParams<{ uuid: string }>();
  const { takeAction }: IUseApi<{}, { data: PermissionGroup }> = useApi();

  const onSubmit: FormSubmitHandler<PermissionGroup> = (values) => {
    return takeAction("update", `/permission-groups/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Permission Group Updated");
        setPermissionGroup(data.data);
      })
      .catch(formError);
  };

  return (
    <PermissionGroupForm
      onSubmit={onSubmit}
      form="EditPermissionGroup"
      initialValues={permissionGroup}
    />
  );
};

export default EditPermissionGroup;
