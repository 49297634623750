import { MediaProps } from "reactstrap";
import createDOMPurify from "dompurify";

const DOMPurify = createDOMPurify(window);

const EmbeddedCode = (props: MediaProps) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { code } = entity.getData();

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(code, {
          ADD_TAGS: ["iframe"],
          ADD_ATTR: [
            "src",
            "width",
            "height",
            "allowfullscreen",
            "frameborder",
          ],
        }),
      }}
    />
  );
};

export default EmbeddedCode;
