import React, { useState, useRef } from "react";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { Link } from "react-router-dom";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import axios, { AxiosRequestConfig } from "axios";

interface SearchResult {
  title: string | null;
  link: string | null;
}

const SearchDocumentation: React.FC = () => {
  const { user } = useAuth();
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<SearchResult[]>([]);

  const { takeAction, loading } = useApi();

  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setQuery(searchTerm);

    clearDebounceTimeout();

    debounceTimeout.current = setTimeout(() => {
      if (!searchTerm) {
        setResults([]);
        return;
      }

      fetchSearchResults(searchTerm);
    }, 500);
  };

  const clearDebounceTimeout = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  };

  const fetchSearchResults = (searchTerm: string) => {
    const params = new URLSearchParams();
    params.append("action", "docy_search_data_fetch");
    params.append("keyword", searchTerm);

    const config: AxiosRequestConfig = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };

    axios
      .post(
        `https://docs.thebossapp.com.au/wp-admin/admin-ajax.php`,
        params,
        config,
      )
      .then(({ data }: { data: string }) => parseSearchResults(data))
      .catch(errorSwal);
  };

  const parseSearchResults = (data: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, "text/html");

    const searchResults = Array.from(
      doc.querySelectorAll(".search-result-item"),
    ).map((item) => {
      const linkElement = item.querySelector(
        "a.title",
      ) as HTMLAnchorElement | null;
      const link = linkElement?.href || null;

      const titleElement = item.querySelector(
        ".doc-section",
      ) as HTMLElement | null;
      const title = titleElement?.innerText.trim() || null;

      return { title, link };
    });

    setResults(searchResults);
  };

  return (
    <DropdownMenu className="mn-wd-lg-300">
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder="Search..."
        className="form-control mg-b-10"
      />

      {loading ? (
        <DropdownItem>Fetching results...</DropdownItem>
      ) : (
        <>
          {query ? (
            results.length > 0 ? (
              results.map((result) => (
                <DropdownItem
                  key={result.link || result.title}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={result.link || "#"}
                >
                  {result.title}
                </DropdownItem>
              ))
            ) : (
              <DropdownItem>No results found</DropdownItem>
            )
          ) : (
            <>
              <DropdownItem
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.thebossapp.com.au"
              >
                Documentation
              </DropdownItem>
              <DropdownItem href="mailto:info@thebossapp.com.au">
                Contact Support
              </DropdownItem>
              {user?.active_organisation.beta && (
                <DropdownItem>
                  <Link to="/support-requests">Support Requests</Link>
                </DropdownItem>
              )}
            </>
          )}
        </>
      )}
    </DropdownMenu>
  );
};

export default SearchDocumentation;
