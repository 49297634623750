import React from "react";
import useModal from "../hooks/useModal";
import UploadSignatureModal from "./UploadSignatureModal";

const UploadSignatureButton = ({
  setUser,
}: {
  setUser: (user: any) => void;
}) => {
  const { toggle, modal } = useModal();

  return (
    <>
      <li className="nav-item">
        <button onClick={toggle} type="button" className="nav-link w-100">
          <i className="icon ion-ios-cloud-upload tx-teal" /> Upload Signature
        </button>
      </li>
      <UploadSignatureModal setUser={setUser} modal={modal} toggle={toggle} />
    </>
  );
};

export default UploadSignatureButton;
