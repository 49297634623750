import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import LoadableButton from "../utils/LoadableButton";

const DeleteButton = ({ deliverable }) => {
  const { uuid } = useParams();
  const history = useHistory();

  const { takeAction, loading } = useApi();

  const deleteDeliverable = () => {
    return deleteSwal(deliverable.name).then(() => {
      return takeAction("destroy", `deliverables/${uuid}`)
        .then(() => {
          toast.success("Deliverable deleted.");
          history.push(deliverable.job.link);
        })
        .catch(errorSwal);
    });
  };

  return (
    <LoadableButton
      className="mt-3"
      outline
      block
      color="danger"
      onClick={deleteDeliverable}
      disabled={loading}
      text="Delete"
      submittingText="Deleting..."
    />
  );
};

export default DeleteButton;
