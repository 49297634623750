import React from "react";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";
import { RouteParams } from "./safetyInitiative";

const Edit = (props : any) => {
  const { takeAction } = useApi();
  const { uuid } = useParams<RouteParams>();
  const { setSafetyInitiative, safetyInitiative } = props;

  const onSubmit = (values : any) => {
    return takeAction("update", `safety-initiatives/${uuid}`, values)
      .then(({ data } : { data:any }) => {
        setSafetyInitiative(data.data);
        toast.success(`${values.name} updated`);
      })
      .catch(errorSwal);
  };

  return (
    <Form
      onSubmit={onSubmit}
      form="EditSafetyInitiative"
      initialValues={safetyInitiative}
    />
  );
};

export default Edit;
