import _ from "lodash";
import dayjs from "dayjs";
import React, { FunctionComponent, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  PopoverBody,
  PopoverHeader,
  Progress,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import { money } from "../form/formatters";
import { percent } from "../home/ProductivityOverview";
import { JobProductivity } from "./projectManagerTypes";

const ProjectManagerProductivityList = ({
  userGroup,
  totalHours,
  totalChargeOut,
}: {
  userGroup: JobProductivity[];
  totalHours: number;
  totalChargeOut: number;
}) => {
  const [hours, setHours] = useState<boolean>(true);

  const user = userGroup[0].user;
  const userHours = _.sumBy(userGroup, "time_in_hours");
  const userChargeOut = _.sumBy(userGroup, "charge_out_amount");

  return (
    <div className="border shadow-sm">
      <div className="bg-gray-100 p-3 d-flex">
        <div>
          <p className="tx-inverse fw-bolder mb-0">{user.name}</p>
          <p className="mb-0">
            {hours
              ? `${userHours} Total Hours`
              : `${money.format(userChargeOut)} Total Charge Out`}{" "}
          </p>
        </div>
        <div className="ms-auto">
          <HoursDropdown hours={hours} setHours={setHours} />
        </div>
      </div>
      <div className="p-3">
        <div className="space-y-3 mb-3">
          {userGroup.map((productivity, i) => {
            return (
              <div
                className={`${
                  i === userGroup.length - 1 ? "" : "border-bottom pb-2"
                }`}
              >
                <p className="tx-inverse mb-0">
                  <span className="tx-inverse fw-bolder">
                    {dayjs(productivity.start_time).format("DD/MM/YYYY")}
                  </span>{" "}
                  |{" "}
                  {hours
                    ? `${productivity.time_in_hours} hours.`
                    : `${money.format(
                        productivity.charge_out_amount,
                      )} at ${money.format(
                        productivity.charge_out_rate,
                      )} per hour.`}
                </p>
                <p className="mb-0">
                  From{" "}
                  <InverseSpan>
                    {dayjs(productivity.start_time).format("hh:mm, a")}
                  </InverseSpan>{" "}
                  to{" "}
                  <InverseSpan>
                    {dayjs(productivity.finish_time).format("hh:mm, a")}
                  </InverseSpan>
                  <CommentPopOver productivity={productivity} />
                </p>
              </div>
            );
          })}
        </div>
        <ProductivityProgress
          hours={hours}
          userHours={userHours}
          totalHours={totalHours}
          user={user}
          userChargeOut={userChargeOut}
          totalChargeOut={totalChargeOut}
        />
      </div>
    </div>
  );
};

const CommentPopOver = ({
  productivity,
}: {
  productivity: JobProductivity;
}) => {
  if (!productivity.comments) {
    return null;
  }

  return (
    <>
      <Button
        id={`productivity_comments_${productivity.uuid}`}
        type="button"
        color="link"
        className="p-0 ms-1"
      >
        <i className="fa fa-info-circle tx-inverse" />
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target={`productivity_comments_${productivity.uuid}`}
      >
        <PopoverHeader>Productivity Comments</PopoverHeader>
        <PopoverBody>{productivity.comments}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

const ProductivityProgress = ({
  hours,
  userHours,
  totalHours,
  user,
  userChargeOut,
  totalChargeOut,
}: {
  hours: boolean;
  userHours: number;
  totalHours: number;
  user: JobProductivity["user"];
  userChargeOut: number;
  totalChargeOut: number;
}) => {
  if (hours) {
    return (
      <>
        <p className="mb-1">
          <span
            className="tx-inverse fw-bolder"
            id={`user_productivity_hours_${user.uuid}`}
          >
            {percent(userHours, totalHours)}%
          </span>{" "}
          of the productivity for this job.
        </p>
        <UncontrolledTooltip target={`user_productivity_hours_${user.uuid}`}>
          {totalHours} Hours.
        </UncontrolledTooltip>
        <Progress
          value={percent(userHours, totalHours)}
          barClassName="progress-bar-xs"
          color="teal"
        />
      </>
    );
  }

  return (
    <>
      <p className="mb-1">
        <span
          className="tx-inverse fw-bolder"
          id={`user_productivity_hours_${user.uuid}`}
        >
          {percent(userChargeOut, totalChargeOut)}%
        </span>{" "}
        of the charge out for this job.
      </p>
      <UncontrolledTooltip target={`user_productivity_hours_${user.uuid}`}>
        {money.format(userChargeOut)}
      </UncontrolledTooltip>
      <Progress
        value={percent(userChargeOut, totalChargeOut)}
        barClassName="progress-bar-xs"
        color="teal"
      />
    </>
  );
};

const HoursDropdown = ({
  hours,
  setHours,
}: {
  hours: boolean;
  setHours: Function;
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" className="p-0">
        <FiMoreHorizontal className="tx-18 text-secondary" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>View Productivity by</DropdownItem>
        <DropdownItem onClick={() => setHours(true)}>
          Hours {hours ? <AiOutlineCheck className="ms-1 tx-success" /> : null}
        </DropdownItem>
        <DropdownItem onClick={() => setHours(false)}>
          Charge Out{" "}
          {!hours ? <AiOutlineCheck className="ms-1 tx-success" /> : null}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export const InverseSpan = ({ children }: { children: React.ReactNode }) => {
  return <span className="tx-inverse">{children}</span>;
};

export default ProjectManagerProductivityList;
