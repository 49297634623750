import useApi from "../api/useApi";
import { EmploymentDetailIndex } from "./employmentDetailTypes";
import queryString from "query-string";
import useModal from "../hooks/useModal";
import { Button } from "reactstrap";
import OnHoldModal from "../clients/OnHoldModal";
import CustomScaleLoader from "../utils/scaleLoader";
import { Hold } from "../holds/holdTypes";
import dayjs from "dayjs";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import { useMemo, useState } from "react";

const EmploymentDetailHolds = ({
  employmentDetails,
}: {
  employmentDetails: EmploymentDetailIndex;
}) => {
  const { toggle, modal } = useModal();

  const holdable = {
    holdable_type: "App\\Models\\StaffRole",
    holdable_id: employmentDetails.id,
  };

  const query = queryString.stringify(holdable);

  const [selectedHold, setSelectedHold] = useState();

  const {
    data: holds,
    loading,
    refreshData,
  } = useApi(`holds?${query}`, [], true);

  const columns = useMemo(
    () => [
      {
        accessorKey: "reason",
        header: "Hold",
        cell: (info: any) => {
          const hold = info.row.original;
          return (
            <>
              <p className="mb-2 tx-inverse">{info.getValue()}</p>
              <p className="mb-0 tx-12 text-secondary">
                Added on{" "}
                <span className="tx-inverse">
                  {dayjs(hold.created_at).format("DD/MM/YYYY")}
                </span>{" "}
                at{" "}
                <span className="tx-inverse">
                  {dayjs(hold.created_at).format("hh:mm a")}
                </span>
              </p>
              {hold.deleted_at && (
                <p className="mb-0 tx-12 text-secondary">
                  Lifted on{" "}
                  <span className="tx-inverse">
                    {dayjs(hold.deleted_at).format("DD/MM/YYYY")}
                  </span>{" "}
                  at{" "}
                  <span className="tx-inverse">
                    {dayjs(hold.deleted_at).format("hh:mm a")}
                  </span>
                </p>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "deleted_at",
        header: "Delete",
        cell: (info: any) => {
          if (info.getValue()) {
            return "-";
          }
          const hold = info.row.original;
          return (
            <DeleteButton
              deleteFunction={() => {
                setSelectedHold(hold);
                toggle();
              }}
            />
          );
        },
      },
    ],
    [holds, selectedHold],
  );

  const currentHold = holds.find((hold: Hold) => !hold.deleted_at);

  if (loading) {
    return <CustomScaleLoader>Fetching Holds...</CustomScaleLoader>;
  }

  return (
    <>
      <ReactTable
        columns={columns}
        data={holds}
        extraButtons={
          !currentHold ? (
            <div className="ms-2 d-flex align-content-center">
              <Button onClick={toggle} color="primary" size="sm">
                Add Hold
              </Button>
            </div>
          ) : undefined
        }
      />
      <OnHoldModal
        onSuccess={() => {
          refreshData();
          setSelectedHold(undefined);
        }}
        hold={selectedHold}
        toggle={toggle}
        modal={modal}
        holdable={holdable}
      />
    </>
  );
};

export default EmploymentDetailHolds;
