import React from "react";

const DetailField = ({ label, value, col = "6", link }) => {
  return (
    <div className={`col-md-${col}`}>
      <div className="form-group text-capitalize">
        <label className="form-control-label tx-inverse tx-semibold">
          {label}
        </label>
        <div className="form-control bg-transparent border-0">
          {link && value && link}
          {!value && "-"}
          {!link && value && value}
        </div>
      </div>
    </div>
  );
};

export default DetailField;
