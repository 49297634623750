import React from "react";
import {
  FieldArray,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import TemplateDocumentFields from "../templateDocuments/TemplateDocumentFields";
import { TemplateVariable } from "../templates/templateTypes";
import SubmitButton from "../utils/SubmitButton";
import { TestSuiteProps } from "./testSuiteTypes";

const SampleVariableForm = (
  props: InjectedFormProps<
    { fields: any[] },
    TestSuiteProps & { variables: TemplateVariable[] }
  > &
    TestSuiteProps & { variables: TemplateVariable[] },
) => {
  const { testSuite, handleSubmit, variables } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FieldArray
          component={TemplateDocumentFields}
          name="fields"
          sheets={testSuite.template.spreadsheet_sheets ?? []}
          variables={variables}
          valid={props.valid}
          submitting={props.submitting ?? false}
          submitButton={<SubmitButton {...props} />}
        />
      </div>
    </form>
  );
};

const form = reduxForm<
  { fields: any[] },
  TestSuiteProps & { variables: TemplateVariable[] }
>({});

export default form(SampleVariableForm);
