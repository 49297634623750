import { useInfiniteQuery, useQuery } from "react-query";
import api from "../../api/api";

/**
 *
 * @param {import("../../../types/typedefs").LikeQuery} config
 */
const getLikes = (config, { pageParam = 1 }) => {

  return api
    .get(
      `likes?likeable_type=${encodeURIComponent(
        config.likeable_type,
      )}&likeable_id=${config.likeable_id}&page=${pageParam}`,
    )
    .then(({ data }) => data);
};

/**
 *
 * @param {import("../../../types/typedefs").LikeQuery} config
 */
export default function useLikes(config, enabled = true) {
  return useInfiniteQuery(
    ["likes", { ...config }],
    (pageParam) => getLikes(config, pageParam),
    {
      enabled: enabled,
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
