import { useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import ReactTable from "../table/ReactTable";
import { IActivityLog } from "./IActivityLog";
import dayjs from "dayjs";

export interface IFullHistory {
  property_name: string;
  original_value: string;
  new_value: string;
}

const TAB_OVERVIEW = "overview";
const TAB_PROPERTY_CHANGES = "property_changes";

interface AuditLogDetailsProps {
  log: IActivityLog;
  toggle: () => void;
  modal: boolean;
}

const AuditLogDetails = (props: AuditLogDetailsProps) => {
  const { log, toggle, modal } = props;
  const [activeTab, setActiveTab] = useState(TAB_OVERVIEW);

  const columns = [
    { accessorKey: "property_name", header: "Property Name" },
    { accessorKey: "original_value", header: "Original Value" },
    { accessorKey: "new_value", header: "New Value" },
  ];

  const historyData: IFullHistory[] = useMemo(() => {
    if (!log) return [];

    const changes = log.changes;
    const eventData = changes?.attributes || {};
    const event = log.event;

    const formatValue = (value: any) => {
      if (
        Array.isArray(value) &&
        value.every((item) => item.label !== undefined)
      ) {
        return value.map((item) => item.label).join(", ");
      } else if (typeof value === "boolean") {
        return value.toString();
      }

      return value == null ? "N/A" : value;
    };

    return Object.keys(eventData).map((propertyName) => {
      const originalValue = formatValue(changes.old[propertyName]);
      const newValue = formatValue(eventData[propertyName]);

      return {
        property_name: propertyName,
        original_value: originalValue,
        new_value: newValue,
      };
    });
  }, [log]);

  const toggleTab = (tab: string) => setActiveTab(tab);

  const renderTabItems = () => (
    <Nav tabs>
      <NavItem>
        <NavLink
          className={activeTab === TAB_OVERVIEW ? "active" : ""}
          onClick={() => toggleTab(TAB_OVERVIEW)}
        >
          Overview
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === TAB_PROPERTY_CHANGES ? "active" : ""}
          onClick={() => toggleTab(TAB_PROPERTY_CHANGES)}
        >
          Property Changes
        </NavLink>
      </NavItem>
    </Nav>
  );

  return (
    <Modal
      className="wd-md-1000 mx-wd-800"
      backdrop="static"
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Activity Log</ModalHeader>
      <ModalBody>
        {renderTabItems()}
        <div className="tab-content">
          <div
            className={`tab-pane mt-3 fade ${
              activeTab === TAB_OVERVIEW ? "show active" : ""
            }`}
            id={`${TAB_OVERVIEW}`}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="card card-status mb-4">
                  <div>
                    <label className="slim-card-title">User:</label>
                    <div className="media-body">{log?.causer.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-status mb-4">
                  <div>
                    <label className="slim-card-title">Event Type:</label>
                    <div className="media-body">{log?.event}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card card-status mb-4">
                  <div>
                    <label className="slim-card-title">Subject Type:</label>
                    <div className="media-body">{log?.subject_type}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card card-status mb-4">
                  <div>
                    <label className="slim-card-title">Date:</label>
                    <div className="media-body">
                      {dayjs(log?.created_at).format("DD/MM/YYYY hh:mm a")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane mt-3 fade ${
              activeTab === TAB_PROPERTY_CHANGES ? "show active" : ""
            }`}
            id={`${TAB_PROPERTY_CHANGES}`}
          >
            <ReactTable data={historyData} columns={columns} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AuditLogDetails;
