export interface IPriceBand {
    project_form_id: number;
    upper_limit: number;
    lower_limit: number;
    status: number;
    uuid: string;
}

export enum PriceBandStatus {
    NotApplicable,
    Required,
    Optional,
}

export const getTextByValue = (enumObj: Record<string, any>, value: any): string => {
    const keys = Object.keys(enumObj).filter(key => enumObj[key] === value);
    return keys.length > 0 ? keys[0] : 'Value not found';
};