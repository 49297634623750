import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import useFeatureUsage, { Features } from "../hooks/useFeatureUsage";

interface FeatureNotificationProps {
  children: React.ReactNode;
  featureName: Features;
}

const FeatureNotification = (props: FeatureNotificationProps) => {
  const { children, featureName } = props;

  const { showNotification, updateFeatureUsage } = useFeatureUsage(featureName);
  if (!showNotification) return null;

  return (
    <div
      className={`bd-l my-3 bd-3 bd-info bg-gray-200 pd-x-20 pd-y-25 d-flex shadow-sm rounded`}
      role="alert"
    >
      <div>
        {children}
        <ButtonGroup size="sm">
          <Button
            color="info"
            outline
            onClick={() => updateFeatureUsage(false)}
          >
            Remind me Later
          </Button>
          <Button color="info" outline onClick={() => updateFeatureUsage(true)}>
            Don't Show Again
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default FeatureNotification;
