import React, { useState } from "react";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import InformationAlert from "../utils/InformationAlert";
import RenderField from "../utils/renderField";
import { IToken, OrganisationTokenProps } from "./OrganisationTokens";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiFillCopy } from "react-icons/ai";
import { toast } from "react-toastify";

const AddTokenModal = (
  props: OrganisationTokenProps & { toggle: Function; modal: boolean },
) => {
  const { toggle, modal, tokens, setTokens } = props;
  const [token, setToken] = useState("");

  const toggleModal = () => {
    toggle();

    setToken("");
  };

  const { takeAction }: IUseApi<{}, { data: IToken }> = useApi();

  const onSubmit: FormSubmitHandler<IToken> = (values) => {
    return takeAction("store", "organisation/tokens", values)
      .then(({ data }) => {
        setToken(data.data.token ?? "");
        toast.success("Token Created");
      })
      .catch(formError);
  };

  return (
    <FormModal
      onSubmit={onSubmit}
      form="AddOrganisationToken"
      toggle={toggleModal}
      modal={modal}
      title="Add new token"
    >
      {token && (
        <div className="mb-3">
          <InformationAlert
            type="information"
            closable
            body={
              <>
                Your token is{" "}
                <CopyToClipboard
                  text={token}
                  options={{ format: "text/plain" }}
                  onCopy={() => toast.success(`Token copied.`)}
                >
                  <span className="tx-inverse">
                    {token} <AiFillCopy />.
                  </span>
                </CopyToClipboard>
                Please note you will only be able to see this one time. You
                won't ever be able to see it again, so please copy.
              </>
            }
            title="Token"
          />
        </div>
      )}
      <div className="col-12 form-group">
        <Field component={RenderField} name="token_name" label="Name" />
      </div>
    </FormModal>
  );
};

export default AddTokenModal;
