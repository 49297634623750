import React from "react";
import XeroProfitAndLoss from "./XeroProfitAndLoss";

const XeroReports = () => {
  return (
    <div>
      <XeroProfitAndLoss form="XeroProfitAndLoss" />
    </div>
  );
};

export default XeroReports;
