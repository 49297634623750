import { useEffect } from "react";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import { IntegrationType } from "../integrations/integrationTypes";
import { useParams } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

const CheckFilesSynced = () => {
  const { user } = useAuth();
  const { number } = useParams<{ number: string }>();

  const { data, setUrl, refreshData } = useApi();

  useEffect(() => {
    if (
      !user?.active_organisation.integration_types.includes(
        IntegrationType.OfficeSuite,
      )
    ) {
      return;
    }

    //Poll every 10 seconds to see if the files are synced
    const interval = setInterval(refreshData, 10000);

    setUrl(`projects/${number}/files-synced`);

    return () => clearInterval(interval);
  }, []);

  if (
    !user?.active_organisation.integration_types.includes(
      IntegrationType.OfficeSuite,
    )
  ) {
    return null;
  }

  if (!data) {
    return null;
  }

  //Add a green status dot if not syncing and a yellow one if it is syncing
  return (
    <div className="align-self-center ms-2">
      <div
        className={`rounded-circle shadow-lg ${
          data.is_syncing ? "bg-warning" : "bg-success"
        }`}
        style={{ height: 10, width: 10 }}
        id="sync-status"
      />
      <UncontrolledTooltip target="sync-status">
        {data.is_syncing
          ? "Files are currently being synced with sharepoint"
          : "Files are synced with sharepoint"}
      </UncontrolledTooltip>
    </div>
  );
};

export default CheckFilesSynced;
