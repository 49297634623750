import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import { StoodDownEquipment } from "./equipmentTypes";
import StoodDownForm from "./StoodDownForm";

const EquipmentStandDowns = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction, loading: deleting }: IUseApi = useApi();

  const { data, loading, setData } = useApi(
    `/equipment/${uuid}/stand-downs`,
    [],
  );

  const dates = data as StoodDownEquipment[];
  const deleteStandDown = (standDown: StoodDownEquipment) => {
    return deleteSwal(
      `Stand Down from the ${dayjs(standDown.date).format("DD/MM/YYYY")}`,
    )
      .then(() =>
        takeAction("destroy", `stood-down-equipment/${standDown.uuid}`),
      )
      .then(() => {
        setData(dates.filter((d) => d.uuid !== standDown.uuid));
        toast.success("Stand down deleted");
      })
      .catch(errorSwal);
  };

  const columnHelper = createColumnHelper<StoodDownEquipment>();

  const columns: ColumnDef<StoodDownEquipment>[] = [
    {
      accessorKey: "date",
      header: "Date",
      cell: (info: any) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "reason",
      header: "Reason",
    },
    columnHelper.display({
      header: "Delete",
      cell: (info) => {
        return (
          <DeleteButton
            deleteFunction={() => deleteStandDown(info.row.original)}
          />
        );
      },
    }),
  ];

  return (
    <>
      <StoodDownForm form="STOOD_DOWN_FORM" dates={dates} setDates={setData} />
      <p>{!loading && `${dates.length} total days stood down`}</p>
      <ReactTable
        loading={loading || deleting}
        data={dates}
        columns={columns}
      />
    </>
  );
};

export default EquipmentStandDowns;
