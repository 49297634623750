import { useParams } from "react-router-dom";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import { IProject } from "./projectTypes";
import Profile from "../profile";
import HeaderPage from "../header/HeaderPage";
import { useState } from "react";
import ProjectProfileButtons from "../jobs/ProjectProfileButtons";
import useModal from "../hooks/useModal";
import JobTypeModal from "../jobs/JobTypeModal";
import ProjectJobs from "./ProjectJobs";
import { Badge } from "reactstrap";
import InterEntityInvoices from "../invoices/interEntity/InterEntityInvoices";

type SharedProjectProfilePages = "details" | "jobs" | "inter-entity-invoices";

const SharedProjectProfile = ({ project }: { project: IProject }) => {
  const tabs: ProfileTab<SharedProjectProfilePages>[] = [
    {
      label: "Details",
      page: "details",
      link: `/projects/${project.uuid}/details`,
    },
    {
      label: "Jobs",
      page: "jobs",
      link: `/projects/${project.uuid}/jobs`,
    },
    {
      label: "Inter Entity Invoices",
      page: "inter-entity-invoices",
      link: `/projects/${project.uuid}/inter-entity-invoices`,
    },
  ];

  return (
    <>
      <HeaderPage titlePage={project.full_name} crumbs={[]} />
      <Profile
        tabs={tabs}
        header={
          <DashboardHeader>
            <DashboardH3>{project.full_name}</DashboardH3>
            <p className="mb-0">{project.organisation.name}</p>
          </DashboardHeader>
        }
        content={<ShowSharedProjectProfilePage project={project} />}
      />
    </>
  );
};

const ShowSharedProjectProfilePage = ({ project }: { project: IProject }) => {
  const { page } = useParams<{
    projectUuid: string;
    page: SharedProjectProfilePages;
  }>();

  switch (page) {
    case "details":
      return <ProjectDetails project={project} />;
    case "jobs":
      return <SharedProjectJobs project={project} />;
    case "inter-entity-invoices":
      return <InterEntityInvoices project={project} />;
    default:
      return null;
  }
};

const SharedProjectJobs = ({ project }: { project: IProject }) => {
  const [selectedJob, setSelectedJob] = useState();

  const { number } = useParams<{ number: string }>();

  const { toggle, modal } = useModal();

  return (
    <>
      <div className="mg-b-0">
        <ProjectProfileButtons toggle={toggle} selectedJob={selectedJob} />

        <JobTypeModal
          /** @ts-ignore */
          project={project}
          projectUuid={number}
          modal={modal}
          toggle={toggle}
        />
      </div>

      <ProjectJobs selectedJob={selectedJob} setSelectedJob={setSelectedJob} />
    </>
  );
};

const ProjectDetails = ({ project }: { project: IProject }) => {
  const getStatusBadge = (status: number) => (
    <Badge
      className={`rounded-pill shadow ${
        status === 0 ? "bg-info" : "bg-secondary"
      }`}
    >
      {status === 0 ? "Open" : "Closed"}
    </Badge>
  );

  const DetailItem = ({
    label,
    value,
  }: {
    label: string;
    value: string | JSX.Element;
  }) => (
    <div className="mb-3">
      <p className="text-dark mb-1 tx-12">{label}</p>
      <p className="mb-0">{value}</p>
    </div>
  );

  return (
    <div className="my-3">
      <div className="row">
        <div className="col-md-6">
          <DetailItem label="Number" value={project.number} />
          <DetailItem label="Description" value={project.description} />
          <DetailItem label="Status" value={getStatusBadge(project.status)} />
        </div>
        <div className="col-md-6">
          <DetailItem label="Client" value={project.client?.display_name} />
          <DetailItem
            label="Project Manager"
            value={project.manager?.name ?? ""}
          />
        </div>
      </div>
      <DetailItem label="Address" value={project.formatted_address} />
      <DetailItem label="Country" value={project.country} />
      <DetailItem label="State" value={project.state} />
      <div className="mt-4">
        <h6 className="text-dark mb-3">Team Members</h6>
        {project.team.length > 0 ? (
          <div className="space-y-3">
            {project.team.map((member) => (
              <div key={member.id}>
                <p className="mb-0 text-dark">{member.name}</p>
                <a
                  className="tx-12 text-secondary"
                  href={`tel:${member.email}`}
                >
                  {member.email}
                </a>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-muted">No team members assigned.</p>
        )}
      </div>
    </div>
  );
};

export default SharedProjectProfile;
