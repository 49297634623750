export function validateApprovals(initialValues, input) {
    
    let approvals = [];
    let hasApproval = false;

    if(initialValues && initialValues.hasApprovals.length > 0) {
    
        Object.values(initialValues.hasApprovals).map((approval) => {
            Object.keys(JSON.parse(approval.fields)).map((field) => approvals.push(field))
        })
        
        return (approvals.includes(input.name) ? hasApproval = true : hasApproval = false)
    }

    return hasApproval;
}

export function approvalMessage() {
    return "* Pending approval.";
}