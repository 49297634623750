import { Field } from "redux-form";
import SelectInputAsync from "../../utils/SelectInputAsync";
import required from "../../utils/required";

export const Dependencies = (props) => {
  return (
    <div className={props.className}>
      <div className="form-group">
        <Field
          name={`${props.name || "job_dependency"}`}
          label="Job Dependencies"
          multiple
          component={SelectInputAsync}
          url={`/job/dependencies?project=${encodeURIComponent(
            props.projectUuid,
          )}`}
        />
      </div>
    </div>
  );
};

export const Branch = (props) => {
  return (
    <div className={props.className}>
      <div className="form-group">
        <Field
          name={`${props.name || "branch_id"}`}
          validate={required}
          component={SelectInputAsync}
          url="/organisationbranches/branches"
          required
          label="Branch"
          hideDisabled={true}
        />
      </div>
    </div>
  );
};

export const JobManager = (props) => {
  return (
    <div className={props.className}>
      <div className="form-group">
        <Field
          name={`${props.name || "project_manager"}`}
          validate={required}
          component={SelectInputAsync}
          url="/projectjobs/jobmanager"
          required
          label="Job Manager"
        />
      </div>
    </div>
  );
};

export const Project = (props) => {
  return (
    <div className={props.className}>
      <div className="form-group">
        <Field
          name={`${props.name || "project_id"}`}
          validate={required}
          component={SelectInputAsync}
          url="/projects/list"
          required
          label="Project"
        />
      </div>
    </div>
  );
};
