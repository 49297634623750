import { Group } from "../groups/groupTypes";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import errorSwal from "../utils/errorSwal";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import useApi from "../api/useApi";
import RenderField from "../utils/renderField";
import FormErrorAlert from "../form/FormErrorAlert";
import SubmitButton from "../utils/SubmitButton";
import { Button } from "reactstrap";
import isSubmitting from "../utils/submitting";
import formError from "../utils/formError";
import { IUseApi } from "../api/apiTypes";
import required from "../utils/required";

interface GroupFormProps {
  group?: Group;
  setGroup: (group: Group) => void;
}

const GroupForm = (
  props: GroupFormProps & InjectedFormProps<Group, GroupFormProps>,
) => {
  const { group, handleSubmit, setGroup, error } = props;

  const { takeAction: deleteAction, loading: deleting } = useApi();

  const { takeAction }: IUseApi<{}, { data: Group }> = useApi();

  const onSubmit: FormSubmitHandler<Group, GroupFormProps> = (values) => {
    return takeAction("update", `groups/${group?.uuid}`, values)
      .then(({ data }) => {
        setGroup(data.data);
        toast.success("Group updated");
      })
      .catch(formError);
  };

  const history = useHistory();

  const deleteGroup = () => {
    deleteSwal(group?.name)
      .then(() => deleteAction("destroy", `groups/${group?.uuid}`))
      .then(() => {
        history.push("/groups");
        toast.success("Group deleted");
      })
      .catch(errorSwal);
  };

  if (group?.is_default) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="col-12 form-group">
          <Field
            required
            validate={required}
            component={RenderField}
            name="name"
            label="Name"
          />
        </div>
        <div className="col-12 form-group d-flex space-x-3">
          <Button
            outline
            className="ms-auto"
            color="danger"
            onClick={deleteGroup}
            disabled={deleting}
          >
            {isSubmitting(deleting, "Delete", "Deleting...")}
          </Button>
          <SubmitButton {...props} className="-" />
        </div>
        <div className="col-12 d-flex"></div>
      </div>
      <div className="ms-auto"></div>
    </form>
  );
};

const form = reduxForm<Group, GroupFormProps>({});

export default form(GroupForm);
