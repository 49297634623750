import { useParams } from "react-router-dom";
import MachineDayRate from "../../machineDayRates/AddMachineDayRate";
import ScheduleSiteInspection from "../../siteInspections/residential/ScheduleSiteInspection";
import AddTender from "../../tenders/Add";
import AddTestRequest from "../../testRequests/Add";

const Index = (props) => {
  const { jobType } = useParams();

  switch (jobType) {
    case "testrequest":
      return <AddTestRequest {...props} />;
    case "residentialsiteinspection":
      return <ScheduleSiteInspection {...props} />;
    case "machine-day-rate":
      return <MachineDayRate {...props} />;
    case "tender":
      return <AddTender {...props} />;
    default:
      return null;
  }
};

export default Index;
