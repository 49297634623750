import { useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import axios from "../api/api";
import { useAuth } from "../../context/auth-context";

const PricingCards = ({
  organisationSubscriptionStatus,
  setOrganisationSubscriptionStatus,
  paymentMethod,
  setInvoices,
}) => {
  const { user } = useAuth();
  const { subscribed, user_count } = organisationSubscriptionStatus;
  const [value, setValue] = useState(subscribed);
  const [submitting, setSubmitting] = useState(false);
  const selectedIsDirty = value !== subscribed;

  const updatePlan = () => {
    const alertMessage = value
      ? `You will be charged $50 per active user, per month, with $${
          user_count * 50
        } being charged to your credit card right now.`
      : "You will no longer be able to view any data that you have input into theBOSSapp";

    swal({
      title: "Change Plan?",
      text: alertMessage,
      icon: "warning",
      buttons: true,
    }).then((val) => {
      if (val) {
        setSubmitting(true);
        axios
          .put(`subscriptions/${user.active_organisation.uuid}`, {
            subscribe: value,
          })
          .then(({ data }) => {
            setOrganisationSubscriptionStatus({
              ...organisationSubscriptionStatus,
              subscribed: data.data.subscribed,
            });
            setInvoices(data.data.invoices);
            toast.success("Plan successfully updated!");
          })
          .catch(errorSwal)
          .then(() => setSubmitting(false));
      }
    });
  };

  return (
    <>
      <div className="col-6">
        <div
          className={`card h-100 card-pricing-three link-hover ${
            !value ? "card-pricing-primary" : ""
          } pointer-hover`}
          role="button"
          tabIndex="0"
          onClick={() => setValue(false)}
          onKeyDown={(e) => (e.keyCode === 13 ? setValue(false) : null)}
        >
          <div className={`card-header ${!value ? "bg-primary" : ""}`}>
            Free {!subscribed ? " (Current)" : ""}
          </div>
          <div className="card-pricing">
            <h1>$0</h1>
            <p>per user, per month</p>
            <p>$0 per month for your organisation</p>
          </div>
          <div className="card-body">
            <ul className="pricing-list mb-0">
              <li>
                <i className="fa fa-check tx-success me-1"></i>Timesheets
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Staff Matrix
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Invoicing
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Purchases
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Dockets
              </li>
              <li>
                <i className="fa fa-close tx-danger me-1"></i>10 Projects
              </li>
              <li>
                <i className="fa fa-close tx-danger me-1"></i>100 Jobs
              </li>
              <li>
                <i className="fa fa-close tx-danger me-1"></i>10 Pieces of
                Equipment
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div
          className={`card h-100 card-pricing-three link-hover ${
            value ? "card-pricing-primary" : ""
          } pointer-hover`}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => (e.keyCode === 13 ? setValue(true) : null)}
          onClick={() => setValue(true)}
        >
          <div className={`card-header ${value ? "bg-primary" : ""}`}>
            Premium{subscribed ? " (Current)" : ""}
          </div>
          <div className="card-pricing">
            <h1>$50</h1>
            <p>per user, per month</p>
            <p>${user_count * 50} per month for your organisation</p>
          </div>
          <div className="card-body">
            <ul className="pricing-list mb-0">
              <li>
                <i className="fa fa-check tx-success me-1"></i>Timesheets
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Staff Matrix
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Invoicing
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Purchases
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Dockets
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Unlimited
                Projects
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Unlimited Jobs
              </li>
              <li>
                <i className="fa fa-check tx-success me-1"></i>Unlimited Pieces
                of Equipment
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3 d-flex">
        <button
          className={`btn btn${
            !selectedIsDirty || !paymentMethod ? "-outline" : ""
          }-success ms-auto`}
          disabled={!selectedIsDirty || submitting || !paymentMethod}
          onClick={updatePlan}
        >
          {!paymentMethod
            ? "Please Add Payment Method"
            : isSubmitting(submitting, "Update Plan", "Updating...")}
        </button>
      </div>
    </>
  );
};

export default PricingCards;
