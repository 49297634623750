import React from "react";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Form from "./Form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import errorSwal from "../utils/errorSwal";

const Add = () => {
  const { takeAction } = useApi();
  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", "public-holidays", values)
      .then(() => {
        toast.success("Public Holiday Added");
        history.push("/public-holidays");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add new Public Holiday"
        crumbs={[
          {
            name: "Organisation",
            link: "/organisations/settings/links",
          },
          {
            name: "Public Holidays",
            link: "/public-holidays",
          },
          {
            name: "Add New Public Holiday",
            link: "/",
            active: true,
          },
        ]}
      />
      <Form
        form="AddPublicHoliday"
        onSubmit={onSubmit}
        initialValues={{ start_time: "07:00", finish_time: "14:36" }}
      />
    </>
  );
};

export default Add;
