import { EditorCommand } from "@draft-js-plugins/editor";
import dayjs from "dayjs";
import {
  ContentBlock,
  ContentState,
  convertFromRaw,
  convertToRaw,
  DraftHandleValue,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from "draft-js";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineOrderedList, AiOutlineUnorderedList } from "react-icons/ai";
import { Button, Spinner } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { DataResponse, IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import { findAndReplace } from "../utils/arrayUtils";
import errorSwal from "../utils/errorSwal";
import AddApprovalButton from "./AddApprovalButton";
import DeleteSubjectItemButton from "./DeleteSubjectItemButton";
import EmbeddedImage from "./EmbeddedImage";
import EmbeddedVideo from "./EmbeddedVideo";
import EmbedVideo from "./EmbedVideo";
import EmbedImage from "./EmbedImage";
import useHandleDroppedFiles from "./hooks/useHandleDroppedFiles";
import ReadButton from "./ReadButton";
import { ISubjectItem } from "./subjectTypes";
import EmbedCode from "./EmbedCode";
import EmbeddedCode from "./EmbeddedCode";

interface FormValues {
  content?: string;
  name?: string;
}

export interface SubjectItemEditorProps {
  item: ISubjectItem;
  items: ISubjectItem[];
  setItems: (items: ISubjectItem[]) => void;
}

const SubjectItemEditor = (props: SubjectItemEditorProps) => {
  const { item, items, setItems } = props;
  const [initialRender, setInitialRender] = useState(true);
  const { takeAction, loading }: IUseApi = useApi();
  const { user } = useAuth();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      convertFromRaw(
        JSON.parse(
          item?.content ?? JSON.stringify({ blocks: [], entityMap: {} }),
        ),
      ),
    ),
  );

  const handleDroppedFiles = useHandleDroppedFiles(
    props.item,
    editorState,
    setEditorState,
  );

  const updateItem = (values: FormValues) => {
    return takeAction("update", `subject-items/${item.uuid}`, values).then(
      ({ data }: DataResponse<ISubjectItem>) => {
        setItems(findAndReplace("uuid", items, data.data));
      },
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!initialRender) {
        updateItem({
          content: JSON.stringify(
            convertToRaw(editorState.getCurrentContent() ?? {}),
          ),
        });
      }
      setInitialRender(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [editorState]);

  const ref = useRef<Editor>(null);

  const mapKeyToEditorCommand = (
    e: React.KeyboardEvent<{}>,
  ): EditorCommand | null => {
    if (e.shiftKey && e.ctrlKey && e.key === "L") {
      return "unordered-list-item";
    }

    if (e.shiftKey && e.ctrlKey && e.key === "U") {
      return "ordered-list-item";
    }

    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (
    command: EditorCommand,
    editorState: EditorState,
  ): DraftHandleValue => {
    if (command === "unordered-list-item" || command === "ordered-list-item") {
      setEditorState(RichUtils.toggleBlockType(editorState, command));
      return "handled";
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  const center = (type: Styles) => {
    setEditorState(RichUtils.toggleBlockType(editorState, type));
  };

  return (
    <>
      <div id={item?.uuid} className="bg-white w-full border text-dark">
        <div className="position-sticky z-10 t-0 rounded-lg shadow-sm bg-gray-100">
          <div className={!user?.is_admin || item.is_read_only ? "p-3 border-bottom" : ""}>
            {user?.hasAccessTo("App\\Models\\SubjectItem", "update") && !item.is_read_only ? (
              <NameInput updateItem={updateItem} item={item} />
            ) : (
              <p className="slim-card-title mb-0">{item?.name ?? "-"} </p>
            )}
          </div>
          {user?.hasAccessTo("App\\Models\\SubjectItem", "update") && !item.is_read_only && (
            <div className="d-flex align-items-center border-bottom px-3 ">
              <div className="d-flex">
                {BLOCK_TYPES.map((type) => {
                  return (
                    <Button
                      color="link"
                      className="px-0 me-2"
                      onClick={() => center(type.style)}
                    >
                      {type.label}
                    </Button>
                  );
                })}
              </div>
              <div className="border-end mx-1" style={{ height: "15px" }} />
              <EmbedImage
                item={props.item}
                editorState={editorState}
                setEditorState={setEditorState}
              />
              <EmbedVideo
                editorState={editorState}
                setEditorState={setEditorState}
              />
              <EmbedCode
                editorState={editorState}
                setEditorState={setEditorState}
              />
              <small className="mb-0 text-secondary ms-auto">
                {loading ? (
                  <>
                    Saving{" "}
                    <Spinner style={{ width: "0.8rem", height: "0.8rem" }} />
                  </>
                ) : (
                  `Last saved at ${dayjs(item?.updated_at).format(
                    "DD/MM/YYYY, hh:mm a",
                  )}`
                )}
              </small>
            </div>
          )}
        </div>
        <div className="p-5">
          <Editor
            editorState={editorState}
            blockRendererFn={(contentBlock) =>
              myBlockRenderer(contentBlock, editorState, setEditorState)
            }
            handleKeyCommand={handleKeyCommand}
            onChange={setEditorState}
            ref={ref}
            keyBindingFn={mapKeyToEditorCommand}
            readOnly={!user?.hasAccessTo("App\\Models\\SubjectItem", "update") || item.is_read_only}
            handlePastedFiles={(files: Array<Blob>) =>
              handleDroppedFiles(editorState.getSelection(), files)
            }
            handleDroppedFiles={handleDroppedFiles}
            onTab={(e) => {
              const newEditorState = RichUtils.onTab(e, editorState, 4);

              setEditorState(newEditorState);
            }}
            spellCheck
          />
        </div>
        <Footer {...props} />
      </div>
    </>
  );
};

const Footer = (props: SubjectItemEditorProps) => {
  const { items, item, setItems } = props;

  const { takeAction }: IUseApi = useApi();

  return (
    <div className="p-3 bg-gray-100 border-top rounded-bottom d-flex space-x-2">
      <ReadButton {...props} />
      <AddApprovalButton {...props} />
      <ApprovalButton
        wrapperClass="-"
        approvalAction={props.item.approval_action}
        onSubmitted={() => {
          return takeAction("show", `subject-items/${item.uuid}`)
            .then(({ data }) => {
              setItems(findAndReplace("uuid", items, data.data));
            })
            .catch(errorSwal);
        }}
      />
      {!item.is_read_only && <DeleteSubjectItemButton {...props} />}
    </div>
  );
};

type Styles = "unordered-list-item" | "ordered-list-item";

const BLOCK_TYPES: { label: JSX.Element; style: Styles }[] = [
  {
    label: <AiOutlineUnorderedList className="tx-18" />,
    style: "unordered-list-item",
  },
  {
    label: <AiOutlineOrderedList className="tx-18" />,
    style: "ordered-list-item",
  },
];

const NameInput = ({
  updateItem,
  item,
}: {
  updateItem: (values: FormValues) => Promise<void>;
  item: ISubjectItem;
}) => {
  const [name, setName] = useState(item.name ?? "");

  return (
    <input
      value={name}
      className="slim-card-title bg-transparent p-3 border-bottom no-focus"
      style={{
        border: "none",
        boxShadow: "none",
        width: "100%",
      }}
      placeholder="Edit Name Here..."
      onBlur={() => updateItem({ name })}
      onChange={(e) => setName(e.target.value)}
    />
  );
};

export interface EditorStateProps {
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
}

export const myBlockRenderer = (
  contentBlock: ContentBlock,
  editorState: EditorState,
  setEditorState?: (state: EditorState) => void,
) => {
  const type = contentBlock.getType();

  if (type === "atomic") {
    return {
      component: Media,
      editable: false,
      props: { setEditorState, editorState },
    };
  }
};

interface MediaProps {
  block: ContentBlock;
  contentState: ContentState;
  blockProps: EditorStateProps;
}

const Media = (props: MediaProps) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const type = entity.getType();

  if (type === "IMAGE") {
    return <EmbeddedImage {...props} />;
  }

  if (type === "VIDEO") {
    return <EmbeddedVideo {...props} />;
  }

  if (type === "CODE") {
    return <EmbeddedCode {...props} />;
  }

  return null;
};

export default SubjectItemEditor;
