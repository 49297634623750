import { useCallback, useEffect, useRef, useState } from "react";
import MapWrapper from "../maps/MapWrapper";
import useApi from "../api/useApi";
import MarkerClusterer from "@google/markerclustererplus";
import { IUseApiWithData } from "../api/apiTypes";
import { ProjectIndex } from "./projectTypes";

let markers: any[] = [];

const ProjectsMap = () => {
  const maps = window?.google?.maps;
  const {
    data: projects,
    setUrl,
    loading,
  }: IUseApiWithData<ProjectIndex[]> = useApi("", [], true);

  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const markerClustererRef = useRef<MarkerClusterer | null>(null);

  const setupMap = (
    node: any,
    center: { lat: number; lng: number },
    zoom = 11,
  ) => {
    const map = new maps.Map(node, {
      center,
      zoom,
    });
    setGoogleMap(map);
  };

  const ref = useCallback(
    (node: any) => {
      if (!node) {
        return;
      }

      if (!maps) {
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          setupMap(node, {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          setupMap(
            node,
            {
              lat: -25.2744,
              lng: 133.7751,
            },
            5,
          );
        },
      );
    },
    [maps],
  );

  useEffect(() => {
    if (!googleMap) {
      return;
    }

    let timeout: NodeJS.Timeout;

    const updateProjects = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        setUrl(
          `projects?filter[bounds][lat][]=${googleMap
            .getBounds()
            ?.getSouthWest()
            .lat()}&filter[bounds][lat][]=${googleMap
            .getBounds()
            ?.getNorthEast()
            .lat()}&filter[bounds][lng][]=${googleMap
            .getBounds()
            ?.getSouthWest()
            .lng()}&filter[bounds][lng][]=${googleMap
            .getBounds()
            ?.getNorthEast()
            .lng()}`,
        );
      }, 500);
    };

    maps.event.addListener(googleMap, "bounds_changed", updateProjects);
  }, [googleMap, setUrl, maps]);

  useEffect(() => {
    if (!googleMap || !maps || !projects) {
      return;
    }

    // Remove all previous markers
    markers.forEach((marker) => marker.setMap(null));
    markers = [];

    projects.forEach((project) => {
      const marker = new maps.Marker({
        position: { lat: project.latitude, lng: project.longitude },
        map: googleMap,
        title: project.name,
      });

      const infoWindow = new maps.InfoWindow({
        content: `<div>
                    <a href="/projects/${project.uuid}/details" class="text-dark fw-bolder tx-18">${project.name}</a>
                    <p class="text-dark">${project.number}</p>
                    <div class="d-flex justify-content-between">
                        <p class="mb-0">Client</p>
                        <a href="${project.client?.link}">${project.client?.name}</a>
                    </div>
                  </div>`,
      });

      marker.addListener("click", () => {
        infoWindow.open(googleMap, marker);
      });

      markers.push(marker);
    });

    // Initialize MarkerClusterer
    if (markerClustererRef.current) {
      markerClustererRef.current.clearMarkers();
    }

    markerClustererRef.current = new MarkerClusterer(googleMap, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });
  }, [googleMap, projects]);

  return (
    <MapWrapper>
      <div ref={ref} id="map" className="ht-600 position-relative" />
    </MapWrapper>
  );
};

export default ProjectsMap;
