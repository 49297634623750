import { useState } from "react";
import Confetti from "react-dom-confetti";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormCPIR from "./Form";
import validate from "./validate";

const EditCPIR = (props) => {
  const { setCpir } = props;
  const [closedOut, setClosedOut] = useState(false);
  const { takeAction } = useApi();

  const onSubmit = (values) => {
    const closedOut = values.closed_out && !props.initialValues.closed_out;

    return takeAction("update", `cpirs/${values.uuid}`, values)
      .then(({ data }) => {
        setCpir(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <ReduxCPIRForm
      onSubmit={onSubmit}
      initialValues={props.initialValues}
      closedOut={closedOut}
    />
  );
};

const CPIRForm = (props) => {
  const config = {
    angle: 90,
    spread: 45,
    startVelocity: 45,
    elementCount: "199",
    dragFriction: 0.1,
    duration: "5500",
    stagger: "1",
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <FormCPIR
        {...props}
        confetti={<Confetti active={props.closedOut} config={config} />}
      />
    </form>
  );
};

const form = reduxForm({ validate, form: "CPIR" });

const ReduxCPIRForm = form(CPIRForm);

export default EditCPIR;