import { ProjectAllowanceEnum } from "./../projects/projectTypes";
export enum TimesheetType {
  PublicHoliday,
  Saturday,
  Sunday,
  NightShift,
}

type EmploymentBasises =
  | "Fixed Term - Full Time"
  | "Fixed Term - Part Time"
  | "Full Time"
  | "Part Time"
  | "Casual"
  | "Contract"
  | "Student Placement";

interface PenaltyRateHour {
  to?: number;
  from?: number;
  rate: number;
  uuid?: string;
}

export interface PenaltyRateAllowance {
  id?: number;
  uuid?: string;
  name: string;
  unit: ProjectAllowanceEnum;
  amount: number;
  from?: number;
  to?: number;
}

export interface PenaltyRate {
  name: string;
  timesheet_type: TimesheetType;
  employment_basis: EmploymentBasises;
  award_id: number;
  award: string;
  title: string;
  uuid: string;
  hours: PenaltyRateHour[];
  allowances: PenaltyRateAllowance[];
}
