import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import history from "../utils/History";
import isSubmitting from "../utils/submitting";

const DeleteTimesheet = ({ timesheet, name }) => {
  const { user } = useAuth();
  const { takeAction, loading: deleting } = useApi();

  const deleteTimesheet = () => {
    deleteSwal(name)
      .then(() => takeAction("destroy", `timesheets/${timesheet.uuid}`))
      .then(() => {
        toast.success("Timesheet deleted.");
        history.push(`/users/${timesheet.user.uuid}/timesheets`);
      })
      .catch(errorSwal);
  };

  if (!user.is_admin && user.id !== timesheet.user_id) return null;

  if (timesheet.requested_leave?.uuid) {
    return null;
  }

  return (
    <button
      className="btn btn-outline-danger w-100 mt-3"
      onClick={deleteTimesheet}
      disabled={deleting}
    >
      {isSubmitting(deleting, "Delete", "Deleting...")}
    </button>
  );
};

export default DeleteTimesheet;
