import { CustomJobJob } from "./customJobTypes";

export const getFilterValueMapper = (job: CustomJobJob) => {
  const filters: {
    [key: string]: number;
  } = {
    branch_id: job.branch?.id,
    "branch.tracking_site_id": job.branch?.tracking_site_id,
  };

  job.custom_form.forEach((field) => {
    if (!field.field_attributes.url?.startsWith("custom-fields/registers")) {
      return;
    }

    field.field_attributes.filters?.forEach((filter) => {
      const filterType = filter.key.split(",")[1];
      filters[filter.key] =
        filterType === "branch_id"
          ? job.branch.id
          : job.branch.tracking_site_id;
    });
  });

  return filters;
};
