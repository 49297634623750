import React from "react";
import { ReactComponent as Done } from "../svgs/success.svg";
import SVGContainer from "./utils/SVGContainer";

const Success = () => {
  return (
    <div className="signin-wrapper">
      <div className="signin-box signup" style={{ width: "800px" }}>
        <h2 className="slim-logo mb-5 text-center">
          <a
            href="https://landing.thebossapp.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            theBOSSapp
          </a>
        </h2>
        <SVGContainer SVG={Done} top={false}>
          <div className="text-center">
            <p className="mt-5 mb-0">Form submitted successfully</p>
            <p className="">Thanks!</p>
          </div>
        </SVGContainer>
      </div>
    </div>
  );
};

export default Success;
