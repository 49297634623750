import React from "react";
import { Field, WrappedFieldArrayProps, formValueSelector } from "redux-form";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import { Button } from "reactstrap";
import { IoAdd } from "react-icons/io5";
import FormHeader from "../utils/FormHeader";
import useApi from "../api/useApi";
import { connect } from "react-redux";
import required from "../utils/required";
import errorSwal from "../utils/errorSwal";

interface Day {
  day:
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday";
  hours: string;
  uuid?: string;
}

const dayOptions = [
  {
    label: "Monday",
    value: "Monday",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    label: "Thursday",
    value: "Thursday",
  },
  {
    label: "Friday",
    value: "Friday",
  },
  {
    label: "Saturday",
    value: "Saturday",
  },
  {
    label: "Sunday",
    value: "Sunday",
  },
];

const DaysWorkedFields = (props: WrappedFieldArrayProps & { days: Day[] }) => {
  const { takeAction } = useApi();

  const { fields, days } = props;

  return (
    <>
      <div className="col-12">
        <FormHeader>Work Days</FormHeader>
      </div>
      <div className="row col-12 align-items-center mb-5">
        {fields.map((field, index) => {
          const day = fields.get(index);

          return (
            <div className="col-3">
              <div className="form-group">
                <Field
                  component={SelectInput}
                  options={dayOptions.filter((d) => {
                    return (
                      day.day === d.value ||
                      !days.some((day) => day.day === d.value)
                    );
                  })}
                  name={`${field}.day`}
                  label={`Day ${index + 1}`}
                  validate={required}
                  required
                />
              </div>
              <div className="form-group">
                <Field
                  component={RenderField}
                  name={`${field}.hours`}
                  label="Hours"
                  validate={required}
                  required
                />
              </div>
              <div className="form-group">
                <Button
                  color="danger"
                  outline
                  className="w-100"
                  onClick={() => {
                    if (day.uuid) {
                      return takeAction(
                        "destroy",
                        `staff-role-days/${day.uuid}`,
                      )
                        .then(() => {
                          fields.remove(index);
                        })
                        .catch(errorSwal);
                    }

                    fields.remove(index);
                  }}
                >
                  Remove Day {index + 1}
                </Button>
              </div>
            </div>
          );
        })}
        {fields.length < 7 && (
          <div className="col-3 d-flex align-items-center form-group">
            <button
              type="button"
              style={{
                height: "65%",
                minHeight: "146px",
                borderColor: "#e0e0e0",
              }}
              className="w-100 bg-white rounded-lg text-muted bd-dashed "
              onClick={() => fields.push({})}
            >
              <IoAdd className="tx-24" />
              <p className="mb-0">Add Day</p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const selector = formValueSelector("StaffRole");
const mapStateToProps = (state: any) => {
  return {
    days: selector(state, "days"),
  };
};

export default connect(mapStateToProps)(DaysWorkedFields);
