import { useParams } from "react-router";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { CpirTypeFormData } from "./CpirTypeForm";
import { CPIRType } from "./cpirTypeTypes";
import ShowCpirTypePage from "./ShowCpirTypePage";

import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { Button } from "reactstrap";
import { BiTrash } from "react-icons/bi";
import deleteSwal from "../utils/deleteSwal";

import { useHistory } from "react-router-dom";

export type CpirTypeProfilePages = "details" | "fields";

const CpirTypeProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { loading, data, setData } = useApi(`cpir-types/${uuid}`, {}, true);

  const history = useHistory();

  const { takeAction }: IUseApi<any, { data: CPIRType }> = useApi();

  const restoreType = () => {
    return takeAction("update", `cpir-types/${uuid}/restore`)
      .then(() => {
        toast.success("Type restored successfully");
        history.push(`cpir-types/${uuid}/details`);
      })
      .catch(errorSwal);
  };

  const deleteType = () => {
    return deleteSwal(
      type.name,
      "You will still be able to view old CPIRs that are associated to this type.",
    )
      .then(() => takeAction("destroy", `cpir-types/${uuid}`))
      .then(() => {
        history.push("/cpir-types");
        toast.success(
          <div className="d-flex align-items-center">
            <p className="mb-0">Type deleted</p>
            <Button className="ms-auto" onClick={restoreType} color="link">
              Undo
            </Button>
          </div>,
        );
      })
      .catch(errorSwal);
  };

  const onSubmit: FormSubmitHandler<CpirTypeFormData> = (values) => {
    return takeAction("update", `cpir-types/${uuid}`, values)
      .then(({ data }) => {
        setData(data.data);
        toast.success("CPIR Type Updated Successfully");
      })
      .catch(errorSwal);
  };

  let type = data as CPIRType;

  const tabs: ProfileTab<CpirTypeProfilePages>[] = [
    {
      label: "Details",
      link: `/cpir-types/${uuid}/details`,
      page: "details",
    },
    {
      label: "Fields",
      link: `/cpir-types/${uuid}/fields`,
      page: "fields",
    },
  ];

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        crumbs={[
          { link: "/cpir-types", name: "Cpir Types" },
          { link: "", active: true, name: type.name },
        ]}
        titlePage={type.name}
      />
      <Profile<CpirTypeProfilePages>
        content={
          <ShowCpirTypePage
            onSubmitTypeForm={onSubmit}
            type={type}
            setType={setData}
          />
        }
        header={
          <DashboardHeader>
            <DashboardH3>{type.name}</DashboardH3>
          </DashboardHeader>
        }
        tabs={tabs}
        sideBar={
          <Button
            onClick={deleteType}
            color="danger"
            className="mt-3"
            outline
            block
          >
            <BiTrash /> Delete
          </Button>
        }
      />
    </>
  );
};

export default CpirTypeProfile;
