import { InjectedFormProps, reduxForm } from "redux-form";
import CustomForm from "../customFields/CustomForm";
import { Register } from "../registers/registerTypes";
import SubmitButton from "../utils/SubmitButton";
import { RegisterItem } from "./registerItemTypes";

export interface RegisterItemFormProps {
  register?: Register;
  registerItem?: RegisterItem;
}

const RegisterItemForm = (
  props: InjectedFormProps<{}, RegisterItemFormProps> & RegisterItemFormProps,
) => {
  const { register, handleSubmit, registerItem } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <CustomForm
          customForm={register?.custom_fields ?? registerItem?.custom_form}
          {...props}
          parentUuid={register?.uuid ?? registerItem?.register?.uuid}
        />
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<{}, RegisterItemFormProps>({});

export default form(RegisterItemForm);
