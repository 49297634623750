import React from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import { Field } from "redux-form";
import useApi from "../api/useApi";
import FileInput from "../upload/FileInput";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import uploadToS3 from "../upload/uploadToS3";

const AddTemplateDocumentModal = (props: any) => {
  const { modal, toggle, customJobValues, extraComponent, whenSubmitted } = props;
  const { user } = useAuth();
  const { takeAction } = useApi();

  const onSubmit = (values: any) => {
    return uploadToS3(
      values.file,
      `organisations/${user?.active_organisation.uuid}/templates`,
    )
      .then((documents) =>
        takeAction("store", "templates", {
          documents,
          ...customJobValues,
        }),
      )
      .then(({ data }: { data: any }) => {
        toast.success("Template Added Successfully");
        whenSubmitted(data);
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Upload Template Document"
      onSubmit={onSubmit}
      form="CustomjobTemplateUpload"
      extraComponent={extraComponent}
    >
      <div className="col-lg-12 form-group">
        <Field
          component={FileInput}
          name="file"
          label="Template File"
          required
          validate={required}
        />
      </div>
    </FormModal>
  );
};

export default AddTemplateDocumentModal;
