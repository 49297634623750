import axios from "../api/api";
import { FETCH_TO_DOS } from "../actions/types";

export const fetchToDos = (start, end) => (dispatch) => {
  return axios
    .get(`/calendar-tasks${start || end ? `?start=${start}&end=${end}` : ""}`)
    .then((response) => response.data.data)
    .then((toDos) =>
      dispatch({
        type: FETCH_TO_DOS,
        payload: toDos,
      }),
    );
};

export const fetchBranchToDos = (branch, start, end) => (dispatch) => {
  return axios
    .get(
      `/branch-to-dos?branchUuid=${branch.uuid}${
        start || end ? `?start=${start}&end=${end}` : ""
      }`,
    )
    .then((response) => response.data.data)
    .then((toDos) =>
      dispatch({
        type: FETCH_TO_DOS,
        payload: toDos,
        branch,
      }),
    );
};
