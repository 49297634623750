import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import StyledCheck from "../form/StyledCheck";
import DateTime, { formatting } from "../utils/dateTime";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import Spinner from "../utils/Spinner";
import SubmitButton from "../utils/SubmitButton";
import ToggleAll from "./ToggleAll";
import TrackingSiteButton from "./TrackingSiteButton";
import dayjs from "dayjs";
import EmployeeList from "./EmployeeList";

const Form = (props) => {
  const {
    handleSubmit,
    startTime,
    finishTime,
    date,
    extraEndForm,
    initialValues,
  } = props;

  const { data: trackingSites, loading } = useApi("trackingsites", [], true);

  const formattedTrackingSites = trackingSites.map(({ trackingsite }) => {
    return {
      label: trackingsite.name,
      value: trackingsite.id,
      disabled: initialValues?.timesheets_created_at,
      ...trackingsite,
    };
  });

  const totalHours = () => {
    if (!startTime || !finishTime || !date) {
      return 0;
    }

    const formatDate = dayjs(date).format("YYYY-MM-DD");

    const start = dayjs(`${formatDate} ${startTime}`);
    const finish = dayjs(`${formatDate} ${finishTime}`);
    return dayjs.duration(finish.diff(start)).asHours()?.toFixed(2);
  };

  if (loading) return <Spinner loading />;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name="name"
              label="Name"
              validate={required}
              required
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={DateTime}
              name="date"
              label="Date"
              validate={required}
              required
              {...formatting}
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name="start_time"
              validate={required}
              required
              type="time"
              label="Start Time"
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name="finish_time"
              validate={required}
              required
              type="time"
              label="Finish Time"
            />
          </div>
          <Field
            component={StyledCheck}
            name="tracking_sites"
            options={formattedTrackingSites}
            label={
              <div className="col-12 d-flex align-items-center mb-3">
                <div>
                  <small className="d-block">Total Hours: {totalHours()}</small>
                  <label className="form-control-label tx-inverse tx-semibold mb-0">
                    Tracking Sites
                  </label>
                </div>
                <ToggleAll
                  {...props}
                  formattedTrackingSites={formattedTrackingSites}
                />
              </div>
            }
            button={(props) => <TrackingSiteButton {...props} />}
          />
          <div className="col-12 form-group d-flex">
            {extraEndForm}
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
      {initialValues.uuid && initialValues?.tracking_sites?.length > 0 && (
        <EmployeeList {...props} />
      )}
    </>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);
  return {
    date: selector(state, "date"),
    startTime: selector(state, "start_time"),
    finishTime: selector(state, "finish_time"),
    selectedTrackingSites: selector(state, "tracking_sites"),
  };
};

export default connect(mapStateToProps, {})(reduxForm()(Form));
