import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import Modal from "./Modal";
import { Tender, Template } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const Index = () => {
  const { data: types, setData: setTypes, loading } = useApi(
    "tender-types",
    [],
  );
  const { toggle, modal } = useModal();

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: info => (
        <Link
          to={`/tender-types/${info.row.original.uuid}/details`}
          type="link"
        >
          {info.getValue()}
        </Link>
      ),
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/tender-types/" />
      <HeaderPage
        titlePage="Tender Types"
        pageDescription={
          <>
            Tender types enable customization of tender forms with
            organization-specific fields, allowing efficient capture of relevant
            data for various projects, such as construction or software
            development.
          </>
        }
        relatedLinks={[
          { name: "Tenders", link: "/tenders", model: Tender },
          { name: "Templates", link: "/templates", model: Template },
        ]}
        crumbs={[
          {
            link: "/organisations/settings/links",
            name: "Organisation",
          },
          {
            link: "",
            name: "Tender Types",
            active: true,
          },
        ]}
      />
      <Button outline color="primary" className="mb-2" onClick={toggle}>
        Add New Tender Type
      </Button>
      <ReactTable data={types} columns={columns} loading={loading} />
      <Modal
        form="TenderType"
        types={types}
        setTypes={setTypes}
        modal={modal}
        toggle={toggle}
      />
    </>
  );
};

export default Index;
