import useApi from "../api/useApi";
import { Link } from "react-router-dom";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ReactTable from "../table/ReactTable";
import { useAuth } from "../../context/auth-context";

const ProjectForms = () => {
  const { data, loading } = useApi("project-forms", []);

  const { user } = useAuth();

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => {
        const projectForm = info.row.original;
        return (
          <>
            <Link to={`project-forms/${projectForm.uuid}/details`}>
              {info.getValue()}
            </Link>
            <br />
          </>
        );
      },
    },
  ];

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Project Forms"
        pageDescription={
          <>
            The Project Forms feature allows organisations to ensure the correct
            infomation is captured in projects as part of the Quality Assurance
            system. These forms are categorised by the Project Types feature.
          </>
        }
        crumbs={[
          { name: "Project Forms", link: "/project-forms", active: true },
        ]}
      />
      <Link to="/project-forms/add" className="mb-3 btn btn-outline-primary">
        Add New
      </Link>

      <ReactTable columns={columns} data={data} loading={loading} />
    </>
  );
};

export default ProjectForms;
