import { Fragment } from "react";
import { Field, WrappedFieldArrayProps, formValueSelector } from "redux-form";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";
import { Button } from "reactstrap";
import dayjs from "dayjs";
import { connect } from "react-redux";

const JobsFromTemplate = ({
  fields,
  change,
  jobs,
  startDate,
}: WrappedFieldArrayProps & {
  change: Function;
  jobs: any[];
  startDate: string;
}) => {
  const { response } = useApi("/projectjobs/jobmanager");

  if (fields.length === 0) {
    return null;
  }

  return (
    <>
      <div className="col-12">
        <div className="d-flex align-items-end mt-3">
          <label className="section-title my-0">Jobs</label>
          <Button
            color="primary"
            outline
            size="sm"
            type="button"
            className="ms-auto"
            onClick={() => {
              const mapped = jobs.map((job) => {
                const daysBetween = dayjs(job.scheduled_finish_date).diff(
                  dayjs(job.scheduled_start_date),
                  "day",
                );

                const newScheduledFinishDate = dayjs(startDate)
                  .add(daysBetween, "day")
                  .format("YYYY-MM-DD");

                return {
                  ...job,
                  scheduled_start_date: startDate,
                  scheduled_finish_date: newScheduledFinishDate,
                };
              });

              change("template_jobs", mapped);
            }}
          >
            Recalculate Job Dates
          </Button>
        </div>
        <div className="mt-1 d-flex align-items-center">
          <input
            type="checkbox"
            id="select_all_jobs"
            checked={jobs.every((job) => job.selected_job)}
            onChange={(e) =>
              change(
                "template_jobs",
                jobs.map((job) => ({
                  ...job,
                  selected_job: e.target.checked,
                })),
              )
            }
          />
          <label htmlFor="select_all_jobs" className="ms-2 my-0">
            Select all Jobs
          </label>
        </div>
        <hr className="w-100" />
      </div>
      {fields.map((field, index) => {
        const job = fields.get(index);

        return (
          <Fragment key={index}>
            <div
              className={`col-12 mb-3 d-flex space-x-3 ${
                index !== 0 ? "mt-3" : ""
              }`}
            >
              <Field
                component={RenderField}
                type="checkbox"
                name={`${field}.selected_job`}
                id={`job_${job.uuid}`}
                checked={job.selected_job}
              />
              <label
                htmlFor={`job_${job.uuid}`}
                className="mb-0 text-dark fw-bolder"
              >
                {job.full_name}
              </label>
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={RenderField}
                name={`${field}.name`}
                label="Name"
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={SelectInput}
                /** @ts-ignore */
                options={response?.data ?? []}
                name={`${field}.project_manager`}
                label="Manager"
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={RenderField}
                type="date"
                options={[]}
                name={`${field}.scheduled_start_date`}
                label="Start Date"
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={RenderField}
                type="date"
                options={[]}
                name={`${field}.scheduled_finish_date`}
                label="Finish Date"
              />
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

const mapStateToProps = (state: any, props: any) => {
  const selector = formValueSelector(props.form);

  return {
    jobs: selector(state, "template_jobs"),
  };
};

export default connect(mapStateToProps)(JobsFromTemplate);
