import dayjs from "dayjs";
import { FocusEvent } from "react";
import { useQueryClient } from "react-query";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { DryMoistureMass, Moisture } from "./moistureTypes";
import { useWindowEvent } from "../hooks/useWindowEvent";

const MoisturesTable = ({ moistures }: { moistures: Moisture[] }) => {
  const { takeAction }: IUseApi<{}, { data: DryMoistureMass }> = useApi();
  const queryClient = useQueryClient();

  useWindowEvent("keydown", (e: KeyboardEvent) => {
    if (e.key !== "Enter") {
      return;
    }

    const target = e.target as HTMLInputElement;

    if (target.name.includes("dry_moisture")) {
      //get the array key it's in (target.name is in the form moisturre[1].dry_moisture[0])
      const arrayKey = target.name.split(".");

      //Get the id from the strign moisture[{id}] using regex
      const id = arrayKey[0].match(/\d+/)?.[0];

      const moistureNumber = arrayKey[1].match(/\d+/)?.[0];

      if (!id) {
        return;
      }

      const previousMoistureId = moistures.find(
        (moisture) => moisture.id < parseInt(id),
      );

      if (previousMoistureId) {
        const nextRow = document.getElementById(
          `moisture[${previousMoistureId.id}].dry_moisture[${moistureNumber}]`,
        );

        if (nextRow) {
          nextRow.focus();
        }
      }
    }
  });

  const updateDryMoistureMass = (
    e: FocusEvent<HTMLInputElement>,
    field: string,
    moisture: Moisture,
    moistureMass?: DryMoistureMass,
  ) => {
    if (!e.target.value) {
      return;
    }

    return createOrUpdate(e, field, moisture, moistureMass)
      .then(({ data }) => {
        queryClient.invalidateQueries(["moistures"]);
      })
      .catch((err) => {
        //
      });
  };

  const createOrUpdate = (
    e: FocusEvent<HTMLInputElement>,
    field: string,
    moisture: Moisture,
    dryMass?: DryMoistureMass,
  ) => {
    if (dryMass) {
      return takeAction("update", `dry-moisture-masses/${dryMass.uuid}`, {
        [field]: e.target.value,
      });
    }

    return takeAction("store", `/moistures/${moisture.uuid}/dry-masses`, {
      [field]: e.target.value,
    });
  };

  if (moistures.length === 0) {
    return null;
  }

  return (
    <>
      <table className="table">
        <thead className="bg-gray-400 p-3">
          <tr className="text-dark">
            <th>Sample</th>
            <th>Tin</th>
            <th>Branch</th>
            <th>Taken At</th>
            <th>Tin Weight</th>
            <th>Wet Weight</th>
            <th>Dry Moisture 1</th>
            <th>Dry Moisture 2</th>
            <th>Dry Moisture 3</th>
            <th>Dry Moisture 4</th>
          </tr>
        </thead>
        <tbody className="p-3">
          {moistures.map((moisture) => {
            return (
              <tr key={moisture.uuid}>
                <td>{moisture.sample ?? "-"}</td>
                <td>{moisture.container_id}</td>
                <td>{moisture.branch?.name}</td>
                <td>{dayjs(moisture.taken_at).format("DD/MM/YYYY HH:mm")}</td>
                <td>{moisture.mass_of_tin}</td>
                <td>{moisture.mass_of_tin_and_wet_soil}</td>
                {[0, 1, 2, 3].map((value) => {
                  return (
                    <td>
                      <div className="row">
                        <>
                          <div className="col-4">
                            <label className="mg-sm-b-0 tx-sm-10">
                              Comment
                            </label>
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              name={`moisture[${moisture.id}].dry_moisture[${value}].comment`}
                              defaultValue={
                                moisture.dry_moisture_masses[value]?.comment ??
                                ""
                              }
                              id={`moisture[${moisture.id}].dry_moisture[${value}].comment`}
                              onBlur={(e) => {
                                updateDryMoistureMass(
                                  e,
                                  "comment",
                                  moisture,
                                  moisture.dry_moisture_masses[value],
                                );
                              }}
                            />
                          </div>
                        </>
                        <div className="ps-3 col-8">
                          <label className="mg-sm-b-0 tx-sm-10">Mass</label>
                          <input
                            style={{ width: "100%" }}
                            type="number"
                            step="0.01"
                            name={`moisture[${moisture.id}].dry_moisture[${value}].mass`}
                            defaultValue={
                              moisture.dry_moisture_masses[value]?.mass ?? ""
                            }
                            id={`moisture[${moisture.id}].dry_moisture[${value}].mass`}
                            onBlur={(e) => {
                              updateDryMoistureMass(
                                e,
                                "mass",
                                moisture,
                                moisture.dry_moisture_masses[value],
                              );
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default MoisturesTable;
