import { useParams } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import TaskLists from "./TaskLists";
import useApi from "../api/useApi";
import { IUseApiWithData } from "../api/apiTypes";
import { useEffect } from "react";
import { TodoTaskList } from "./todoTypes";
import TaskList from "./TaskList";
import dayjs from "dayjs";
import TodoModal from "./TodoModal";
import { Button } from "reactstrap";
import { AiOutlineSync } from "react-icons/ai";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";

const Todos = () => {
  const { uuid, taskUuid } = useParams<{ uuid: string; taskUuid: string }>();

  const { data: hasMicrosoft } = useApi(
    "integrations/microsoft",
    null,
    false,
    "",
    false,
  );

  const { takeAction, loading: startingSync } = useApi();

  const startSync = () => {
    return takeAction("store", "microsoft/user/to-dos/sync")
      .then(() => {
        toast.success(
          "Sync started, as it may take a while, we'll notify you when it's done.",
        );
      })
      .catch(errorSwal);
  };

  const { data: taskLists, setData: setTaskLists } = useApi(
    "to-do-task-lists",
    [],
  );

  const { data, setUrl, setData }: IUseApiWithData<TodoTaskList> = useApi("", {
    name: "My Day",
    tasks: [],
  });

  useEffect(() => {
    if (uuid !== "my-day") {
      setUrl(`to-do-task-lists/${uuid}`);
      return;
    }

    setUrl(`to-do-tasks?filter[due_date]=${dayjs().format("YYYY-MM-DD")}`);
  }, [uuid]);

  return (
    <>
      <HeaderPage titlePage="To Dos" crumbs={[]} />

      {hasMicrosoft && (
        <div className="d-flex space-x-3">
          <Button
            size="sm"
            className="text-secondary ms-auto px-0"
            color="link"
            onClick={startSync}
            disabled={startingSync}
          >
            <AiOutlineSync className={`tx-18 ${startingSync ? "spin" : ""}`} />{" "}
            Sync with Microsoft
          </Button>
          <FieldInformationPopOver
            //@ts-ignore
            information={
              <div>
                <p>
                  Due to limitations with microsoft's services, we aren't able
                  to keep certain things in sync.
                </p>
                <p>
                  If you're missing things from Microsoft Todo, click the 'Sync
                  with Microsoft' button to grab all data from Todo.
                </p>
              </div>
            }
          />
        </div>
      )}
      <div className="row">
        <div className="col-md-3">
          <TaskLists taskLists={taskLists} setTaskLists={setTaskLists} />
        </div>

        <div className="col-md-9">
          <TaskList
            taskLists={taskLists}
            setTaskLists={setTaskLists}
            taskList={data}
            setTaskList={setData}
          />
        </div>
      </div>

      <TodoModal setTaskList={setData} taskList={data} />
    </>
  );
};

export default Todos;
