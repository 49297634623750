export const findAndReplace = (needle, haystack, toReplace) => {
  return haystack.map((item) =>
    item[needle] === toReplace[needle] ? toReplace : item,
  );
};

/**
 *
 * @param {string} needle identifier
 * @param {any[]} haystack the array you want to remove the item from
 * @param {Object} toRemove The object to remove from the array.
 * @returns any[]
 */
export const remove = (needle, haystack, toRemove) => {
  return haystack.filter((item) => item[needle] !== toRemove[needle]);
};

export const add = (array, toAdd) => {
  return [...array, toAdd];
};

//See https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another/5306832#5306832
export const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};
