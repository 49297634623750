import { useState } from "react";
import { Field, reset } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import { draftField } from "../form/formatters";
import MentionField from "../form/MentionField";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import FileList from "../utils/FileList";
import FormModal from "../utils/FormModal";
import required from "../utils/required";
import CommentList from "./CommentList";
import Fields from "./Fields";
import SVGContainer from "../utils/SVGContainer";
import { ReactComponent as Empty } from "../../svgs/empty.svg";

const ListAddModal = (props : any) => {
  const { commentableType, commentableId, toggle, setComments, comments } =
    props;
  const [files, setFiles] = useState([]);

  const { user } = useAuth();

  const form = "ListAddModal";
  const { takeAction } = useApi();

  const { upload } = useUploadMultipleToS3(
    `organisations/${user?.active_organisation.uuid}/comments`,
  );

  const onSubmit = (values : any, dispatch : any) => {
    return upload(values.documents ?? [])
      .then((documents) => {
        const data = {
          commentable_type: commentableType,
          commentable_id: commentableId,
          ...values,
          documents,
        };

        return takeAction("store", "comments", data);
      })
      .then(({ data }) => {
        setComments([data.data, ...comments]);
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      form={form}
      title="Add new Comment"
      onSubmit={onSubmit}
      {...props}
    >
      {(formProps : any) => (
        <div className="row">
          <div className="col-6">
            {comments.length === 0 && (
              <SVGContainer SVG={Empty}>
                <p className="mg-y-20">
                  No comments added
                </p>
              </SVGContainer>
            )}
            <CommentList comments={comments} />
          </div>
          <div className="col-6">
            <Fields {...formProps} files={files} setFiles={setFiles} />
          </div>
        </div>
      )}
    </FormModal>
  );
};

export default ListAddModal;
