import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../table/ReactTable";
import { EquipmentHistory } from "../equipmentTypes/EquipmentTypes";

const ProjectEquipmentHistory = () => {
  const { number } = useParams<{ number: string }>();

  const { data, loading } = useApi(
    `projects/${number}/equipment-history`,
    [],
    true,
  );

  const columnHelper = createColumnHelper<EquipmentHistory>();

  const groupedData = _.groupBy(data, "equipment_uuid");

  const sortAndCombined = _.mapValues(groupedData, function (group) {
    return _.sortBy(group, ["id"])
      .map((activity: EquipmentHistory, index: number, group) => {
        activity.date_added = activity.properties.attributes?.project_id
          ? dayjs(activity.properties.attributes.updated_at).format(
              "DD/MM/YYYY HH:mm:ss a",
            )
          : undefined;
        activity.date_removed = group.hasOwnProperty(index + 1)
          ? dayjs(group[index + 1].properties.attributes.updated_at).format(
              "DD/MM/YYYY HH:mm:ss a",
            )
          : "-";
        return activity;
      })
      .filter((activity: EquipmentHistory) => activity?.date_added);
  });

  const sortAndCombinedData = Object.values(sortAndCombined).flat();

  const equipmentHistory = sortAndCombinedData as EquipmentHistory[];

  const columns = useMemo<ColumnDef<EquipmentHistory, any>[]>(
    () => [
      columnHelper.accessor("equipment_name", {
        header: "Equipment",
        cell: ({ row, getValue }) => {
          return (
            <Link to={`/equipment/${row.original.equipment_uuid}/details`}>
              {getValue()}
            </Link>
          );
        },
      }),
      columnHelper.accessor("date_added", {
        header: "Date Added",
        cell: ({ getValue }) => getValue(),
      }),
      columnHelper.accessor("date_removed", {
        header: "Date Removed",
        cell: ({ getValue }) => getValue(),
      }),
    ],
    [],
  );

  return (
    <ReactTable
      data={equipmentHistory}
      columns={columns}
      loading={loading}
      disableSearch
    />
  );
};

export default ProjectEquipmentHistory;
