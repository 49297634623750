import React from "react";
import { Link } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ReactTable from "../table/ReactTable";
import EmptyBudgetTemplate from "./EmptyBudgetTemplate";
import { Template } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const List = () => {
  const { data, loading } = useApi("budget-templates", []);

  const columns = [
    {
      accessorKey: "account.code",
      header: "Account",
      cell: info => {
        const template = info.row.original;
        return (
          <Link to={`/budget-templates/${template.uuid}/details`}>
            {info.getValue()} - {template.account.name}
          </Link>
        );
      },
    },
    {
      accessorKey: "branch.name",
      header: "Branch",
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/budget-templates/" />
      <HeaderPageAdminFilter
        titlePage="Budget Templates"
        pageDescription={
          <>
            Budgets, linked to accounts and created from templates, help monitor
            branch expenses, sending alerts and requiring approvals for
            exceeding limits, ensuring controlled spending on items like
            software.
          </>
        }
        relatedLinks={[
          { name: "Templates", link: "/templates", model: Template },
        ]}
        crumbs={[
          {
            name: "Budget Templates",
            link: "/",
            active: true,
          },
        ]}
      />
      <ReactTable
        data={data}
        columns={columns}
        loading={loading}
        extraButtons={
          <Link
            to="/budget-templates/add"
            className="btn btn-outline-primary mb-3"
          >
            Add New
          </Link>
        }
        customEmpty={<EmptyBudgetTemplate />}
      />
    </>
  );
};

export default List;
