import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { formValueSelector } from "redux-form";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import NewVariableList from "../templates/NewVariableList";
import Form from "./Form";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";

const Add = ({ template }) => {
  const { takeAction, data: templateTypes } = useApi(
    "email-template-variable-generators",
    [],
  );

  const history = useHistory();

  const selectedTemplate = templateTypes.find(
    ({ value }) => value === template,
  );

  const onSubmit = (values) => {
    return takeAction("store", "email-templates", values)
      .then(({ data }) => {
        history.push(`/email-templates/${data.data.uuid}`);
        toast.success("Successfully added template");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Add Email Template"
        crumbs={[
          {
            link: "/email-templates",
            name: "Email Templates",
          },
          {
            link: "/",
            name: "Add New Template",
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-8">
          <Form
            onSubmit={onSubmit}
            form="AddEmailTemplate"
            templateTypes={templateTypes}
            selectedTemplate={selectedTemplate}
          />
        </div>
        <div className="col-lg-4">
          <NewVariableList variables={selectedTemplate?.variables} />
        </div>
      </div>
    </>
  );
};

const selector = formValueSelector("AddEmailTemplate");

const mapStateToProps = (state) => {
  return {
    template: selector(state, "template"),
  };
};

export default connect(mapStateToProps, {})(Add);
