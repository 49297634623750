import _ from "lodash";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { TemplateVariable } from "../templates/templateTypes";
import formError from "../utils/formError";
import SampleVariableForm from "./SampleVariableForm";
import TemplateDetailForm from "./TemplateDetailForm";
import { TestSuiteProps } from "./testSuiteTypes";

const SampleVariables = (props: TestSuiteProps) => {
  const { takeAction }: IUseApi = useApi();
  const { uuid } = useParams<{ uuid: string }>();
  const { testSuite } = props;

  const sampleFields = testSuite.template.fields.filter(
    (field) => field.incrementable_id,
  );

  const submitTemplateDetails = (values: any) => {
    return takeAction("update", `test-suites/${uuid}`, values)
      .then(({ data }) => {
        props.setTestSuite(data.data);
        toast.success("Updated");
      })
      .catch(formError);
  };

  const submitSampleVariables: FormSubmitHandler<
    { fields: any[] },
    TestSuiteProps & { variables: TemplateVariable[] }
  > = (values) => {
    const data = {
      ...values,
      sample_variables: true,
      fields: values.fields.map((field) => ({
        ...field,
        incrementable_type: "App\\Models\\TestSuite",
        incrementable_id: testSuite.id,
      })),
    };

    return takeAction(
      "update",
      `/test-suites/${testSuite.template.uuid}/fields`,
      data,
    )
      .then(() => {
        toast.success("Updated");
      })
      .catch(formError);
  };

  const variables = _(testSuite.statuses)
    .flatMap((status) => {
      return status.variables;
    })
    .uniqBy("template_variable")
    .value();

  return (
    <>
      <TemplateDetailForm
        initialValues={testSuite}
        onSubmit={submitTemplateDetails}
        form="TemplateDetails"
      />

      <SampleVariableForm
        onSubmit={submitSampleVariables}
        form="SampleVariables"
        initialValues={{
          fields: sampleFields,
        }}
        variables={variables}
        {...props}
      />
    </>
  );
};

export default SampleVariables;
