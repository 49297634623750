import React from "react";

const Details = ({ compaction }) => {
  return (
    <div className="card card-dash-one mg-t-20">
      <div className="row no-gutters">
        <div className="col-lg-6">
          <i className="icon ion-ios-pie-outline" />
          <div className="dash-content">
            <label className="tx-success">
              OMC{" "}
              <span style={{ textTransform: "none" }}>
                (t/m<sup>3</sup>)
              </span>
            </label>
            <h2>{compaction.OMC.toFixed(2)}</h2>
          </div>
        </div>
        <div className="col-lg-6">
          <i className="icon ion-ios-analytics-outline" />
          <div className="dash-content">
            <label className="tx-purple">MDD (%)</label>
            <h2>{compaction.MDD.toFixed(2)}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
