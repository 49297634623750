import React from "react";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DocsModal from "../utils/DocsModal";

const ClientProjects = () => {
  const { uuid } = useParams();

  const { data: projects, loading } = useApi(`clients/${uuid}/projects`, []);

  const columns = [
    {
      accessorKey: "display_name",
      header: "Project Name",
      cell: info => {
        const project = info.row.original;
        return <Link to={`/${project.link}`}>{project.display_name}</Link>;
      },
    },
    {
      accessorKey: "address",
      header: "Address",
      cell: info => <AddressLink address={info.row.original.address} />,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: info => <GetStatus project={info.row.original} />,
    },
  ];

  return (
    <>
      <ReactTable columns={columns} data={projects} loading={loading} />{" "}
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/adding-new-projects/" />
    </>
  );
};

const GetStatus = ({ project }) => {
  switch (parseInt(project.status)) {
    case 0:
      return <span className="badge mg-t-10 bg-info">Open</span>;
    case 1:
      return <span className="badge mg-t-10 bg-secondary">Closed</span>;
  }
};

const AddressLink = ({ address }) => {
  if (address === "Not Provided") return <>{address}</>;
  return (
    <a
      target="_blank"
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
        address,
      )}`}
      rel="noopener noreferrer"
    >
      {address}
    </a>
  );
};

export default ClientProjects;
