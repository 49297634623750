import React from "react";
import { Link } from "react-router-dom";
import SpreadPrice from "../utils/SpreadPrice";
import DetailsTable from "./DetailsTable";

const MadePurchasesTable = (props) => {
  const columns = [
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info) => (
        <Link to={`/${info.row.original.project.link}`}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "chart_of_accounts",
      header: "Accounts",
      cell: (info) => {
        return info
          .getValue()
          .map(({ name }) => name)
          .join(", ");
      },
      csvFormatter: (row) =>
        row?.chart_of_accounts?.map(({ name }) => name).join(", ") ?? "-",
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "upcoming_cost",
      header: "Amount",
      cell: (info) => <SpreadPrice price={info.getValue()} />,
    },
  ];

  return (
    <DetailsTable
      {...props}
      title="Purchases"
      columns={columns}
      url="made-purchases-overview"
    />
  );
};

export default MadePurchasesTable;
