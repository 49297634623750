import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import useApi from "../api/useApi";
import { useCallback, useEffect, useMemo } from "react";
import {
  BiCheck,
  BiDownload,
  BiDuplicate,
  BiListUl,
  BiTrash,
  BiUpload,
} from "react-icons/bi";
import dayjs from "dayjs";
import { FaEllipsisH } from "react-icons/fa";
import AutoExpandingTextArea from "../form/AutoExpandingTextArea";
import { TaskListProps } from "./TaskList";
import { TodoTask } from "./todoTypes";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import { useAuth } from "../../context/auth-context";
import CircularProgress from "../utils/CircularProgress";
import { isImage } from "../standardDocuments/DocumentListItem";
import getFileIcon from "../utils/getFileIcon";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import useModal from "../hooks/useModal";
import PreviewModal from "../documents/PreviewModal";
import { ReactComponent as Convert } from "../../svgs/convert.svg";
import ConvertToJobModal from "./ConvertToJobModal";
import ShowWorkLink from "./ShowWorkLink";

const TodoModal = ({ taskList, setTaskList }: TaskListProps) => {
  const { taskUuid, uuid } = useParams<{ taskUuid: string; uuid: string }>();

  const { user } = useAuth();

  const { toggle: toggleConvert, modal } = useModal();

  const todo = useMemo(() => {
    return taskList?.tasks?.find((task) => task.uuid === taskUuid);
  }, [taskUuid, taskList]);

  const { data: files, setData: setFiles, setUrl } = useApi("", []);

  useEffect(() => {
    if (todo && taskUuid) {
      const query = {
        documentableId: todo?.uuid ?? "",
        documentableType: "App\\Models\\TodoTask",
      };

      setUrl(`documents/standard?${new URLSearchParams(query).toString()}`);
    }
  }, [taskUuid, todo]);

  const { takeAction } = useApi();

  const duplicateTask = () => {
    return takeAction("store", `to-do-tasks`, {
      ...todo,
      title: `${todo?.title} (copy)`,
      uuid: null,
      completed_at: null,
    })
      .then(({ data }: { data: { data: TodoTask } }) => {
        setTaskList({
          ...taskList,
          tasks: [...(taskList.tasks ?? []), data.data],
        });

        toast.success(
          <div className="d-flex">
            <p className="mb-0">
              <strong>{data.data.title}</strong> duplicated
            </p>
            <Link to={`/to-dos/${uuid}/tasks/${data.data.uuid}`}>
              Click here to view.
            </Link>
          </div>,
        );
      })
      .catch(errorSwal);
  };

  const deleteTask = () => {
    return deleteSwal(todo?.title).then(() => {
      history.push(`/to-dos/${uuid}`);
      const oldTaskList = taskList;

      setTaskList({
        ...taskList,
        tasks: taskList?.tasks?.filter((task) => task.uuid !== taskUuid) ?? [],
      });

      takeAction("destroy", `to-do-tasks/${taskUuid}`).catch((err: any) => {
        setTaskList(oldTaskList);
        errorSwal(err);
      });
    });
  };

  const setData = (data: TodoTask) => {
    setTaskList({
      ...taskList,
      tasks: taskList.tasks?.map((task) => {
        if (task.uuid === data.uuid) {
          return data;
        }

        return task;
      }),
    });
  };

  const history = useHistory();

  const toggle = () => {
    history.push(`/to-dos/${uuid}`);
  };

  return (
    <Modal
      style={{ maxWidth: "1300px" }}
      className="wd-md-1000 w-95"
      isOpen={!!taskUuid}
      toggle={toggle}
    >
      <div className="p-4 border-bottom rounded-top d-flex align-items-center">
        <h5 className="mb-0">{todo?.title}</h5>
        <div className="ms-auto d-flex space-x-3">
          <UncontrolledDropdown onClick={() => console.log("dl")}>
            <DropdownToggle className="btn-link bg-transparent border-0 p-0">
              <FaEllipsisH className="text-muted" />
            </DropdownToggle>
            <DropdownMenu style={{ width: "200px" }}>
              <DropdownItem header>
                Added {dayjs(todo?.created_at).format("MMM D YYYY")},
                {dayjs(todo?.created_at).format("HH:mma")}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={duplicateTask} className="text-muted ">
                <BiDuplicate className="me-2" />
                Duplicate
              </DropdownItem>

              <DropdownItem divider />
              <DropdownItem onClick={deleteTask} className="text-danger">
                <BiTrash className="me-2" />
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <button type="button" className="btn-close" onClick={toggle} />
        </div>
      </div>
      <ModalBody className="py-0">
        <div className="row">
          <div className="col-lg-9 p-3 px-4 d-flex">
            <input
              type="checkbox"
              className="form-check-input me-3 tx-18 rounded-circle"
              checked={!!todo?.completed_at}
              onChange={(e) => {
                const newTodo = {
                  ...todo,
                  completed_at: e.target.checked
                    ? dayjs().format("YYYY-MM-DD")
                    : null,
                };

                setData(newTodo);

                takeAction("update", `to-do-tasks/${taskUuid}`, newTodo).catch(
                  (err: any) => {
                    if (todo) {
                      setData(todo);
                    }
                    errorSwal(err);
                  },
                );
              }}
            />
            <div className="flex-grow-1">
              <form id="todo_form" className="w-100 rounded-lg">
                <input
                  type="text"
                  name="title"
                  value={todo?.title}
                  onChange={(e) => {
                    setData({
                      ...todo,
                      title: e.target.value,
                    });
                  }}
                  onBlur={() => {
                    takeAction("update", `to-do-tasks/${taskUuid}`, todo);
                  }}
                  className={`border-0 no-focus fw-bolder tx-20 w-100 ${
                    todo?.completed_at ? "text-decoration-line-through" : ""
                  }`}
                />
                <div className="d-flex mt-3">
                  <BiListUl className="text-muted tx-18" />{" "}
                  <AutoExpandingTextArea
                    placeholder="Add a description"
                    value={todo?.description}
                    onChange={(e) => {
                      setData({
                        ...todo,
                        description: e.target.value ?? "",
                      });
                    }}
                    onBlur={() => {
                      takeAction("update", `to-do-tasks/${taskUuid}`, todo);
                    }}
                    name="description"
                    className="d-block border-0 flex-grow-1 no-focus text-muted no-resize"
                  />
                </div>
              </form>
              <FileList files={files} setFiles={setFiles} />
            </div>
          </div>
          <div className="col-lg-3 p-3 bg-gray-100">
            <div className="border-bottom pb-2">
              <p className="text-muted tx-12 mb-0">Task List</p>
              <p className="text-dark mb-0 fw-bolder">
                {todo?.list?.name ?? ""}
              </p>
            </div>
            <div className="mt-3 border-bottom">
              <label
                htmlFor="todo_date"
                className="text-muted tx-12 d-block mb-1"
              >
                Due Date
              </label>
              <input
                type="date"
                value={todo?.due_date}
                id="todo_date"
                className="no-focus bg-transparent border-0 mb-2 w-100"
                onChange={(e) => {
                  setData({
                    ...todo,
                    due_date: e.target.value,
                  });
                }}
                onBlur={() =>
                  takeAction("update", `to-do-tasks/${taskUuid}`, todo)
                }
              />
            </div>
            <div className="mt-3 border-bottom">
              <UploadFiles
                setParentFiles={setFiles}
                parentFiles={files}
                todo={todo}
              />
            </div>
            {todo?.work ? (
              <ShowWorkLink todo={todo} />
            ) : (
              <div
                style={{ marginTop: "60px" }}
                className="bg-white  p-3 shadow-sm rounded-lg"
              >
                <div
                  style={{ marginTop: "-60px" }}
                  className="d-flex justify-content-center"
                >
                  <Convert className="drop-shadow" height={100} width={200} />
                </div>
                <p className="mb-0 mt-5 text-center">
                  Convert your 'to do' to a project, job or tender.
                </p>

                <Button
                  style={{
                    backgroundColor: "#0c78c4",
                  }}
                  block
                  className="border-0 mt-4 shadow"
                  onClick={toggleConvert}
                >
                  Convert
                </Button>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={deleteTask} color="danger">
          Delete
        </Button>
      </ModalFooter>
      <ConvertToJobModal
        todo={todo}
        toggle={toggleConvert}
        modal={modal}
        setData={setData}
      />
    </Modal>
  );
};

const FileList = ({
  files,
  setFiles,
}: {
  files: any[];
  setFiles: Function;
}) => {
  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(files);

  const { takeAction } = useApi();

  const { toggle, modal } = useModal();

  if (files.length === 0) {
    return null;
  }

  return (
    <>
      <h6 className="text-dark fw-bolder mt-5">Attachments</h6>
      <div className="d-flex flex-wrap mt-2">
        {files.map((document: any, index) => {
          return (
            <>
              <UncontrolledTooltip target={`document_${document.uuid}`}>
                {document.name}
              </UncontrolledTooltip>
              <div
                id={`document_${document.uuid}`}
                className="col-2 mt-2 position-relative"
                key={index}
              >
                <div
                  style={{ height: "130px", width: "130px" }}
                  className="p-1 position-relative border d-flex align-items-center justify-content-center rounded"
                >
                  {isImage(document.mime_type) ? (
                    <img
                      className="w-100 h-100 rounded"
                      src={document.link}
                      alt={document.name}
                    />
                  ) : (
                    <i
                      className={`${getFileIcon(document.name, false)} tx-34`}
                    ></i>
                  )}
                  <div
                    style={{
                      width: "95%",
                      height: "95%",
                    }}
                    className="rounded opacity-0 hover-opacity-100 tn-300 bg-black-5 position-absolute z-1000 d-flex align-items-center justify-content-center"
                  >
                    <a href={document.link} className="p-1 text-white">
                      <BiDownload className="tx-20" />
                    </a>
                    <Button
                      onClick={() => {
                        setSelectedDocument(document);
                        toggle();
                      }}
                      color="link"
                      className="p-1 text-white"
                      size="sm"
                    >
                      <AiOutlineEye className="tx-20" />
                    </Button>
                    <Button
                      onClick={() => {
                        const oldFiles = files;

                        deleteSwal(document.name)
                          .then(() => {
                            setFiles((files: any[]) => {
                              return files.filter(
                                (file) => file.uuid !== document.uuid,
                              );
                            });

                            return takeAction(
                              "destroy",
                              `documents/standard/${document.uuid}`,
                            );
                          })
                          .catch((err: any) => {
                            setFiles(oldFiles);
                            errorSwal(err);
                          });
                      }}
                      color="link"
                      className="p-1 text-white"
                      size="sm"
                    >
                      <AiOutlineDelete className="tx-20" />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <PreviewModal
        document={selectedDocument}
        modal={modal}
        toggle={toggle}
        changeDocument={changeDocument}
      />
    </>
  );
};

const UploadFiles = ({
  todo,
  setParentFiles,
  parentFiles,
}: {
  todo?: TodoTask;
  setParentFiles: Function;
  parentFiles: any[];
}) => {
  const { takeAction, loading } = useApi();
  const { user } = useAuth();

  const { upload, files, setFiles } = useUploadMultipleToS3(
    `/users/${user?.uuid}/to-do-tasks/attachments`,
  );

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      upload(acceptedFiles).then((documents: any[]) => {
        const data = {
          documentable_type: "App\\Models\\TodoTask",
          documentable_id: todo?.uuid,
          documents,
        };

        return takeAction("store", "documents/standard", data)
          .then(({ data }: { data: any }) => {
            setFiles([]);
            setParentFiles(data.data);
          })
          .catch(errorSwal);
      });
    },
    [todo, parentFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 20,
    disabled: loading,
  });

  return (
    <>
      <p className="fw-bolder tx-12 mb-2 text-dark">Upload Files</p>
      <div
        style={{
          border: "1px dashed #ccc",
        }}
        className={`d-flex align-items-center justify-content-center p-3 tn-300 rounded-lg ${
          isDragActive ? "bg-gray-200" : "bg-white"
        }`}
        {...getRootProps()}
      >
        <div className="text-center">
          <BiUpload className="tx-22" />
          <p className="text-muted mb-0 tx-12">Drag and drop files here</p>
        </div>
        <input {...getInputProps()} />
      </div>
      <div className="space-y-3 mt-3">
        {files?.map((file: any) => {
          return (
            <div className="p-3 bg-grad-info rounded-lg align-items-center d-flex">
              <div
                style={{
                  maxWidth: "50%",
                }}
                className="text-white"
              >
                <p className="mb-0 ">Uploading File...</p>
                <p className="mb-0 no-wrap">{file.name}</p>
              </div>
              <div className="ms-auto">
                {file.progress === 100 ? (
                  <BiCheck className="text-white tx-22" />
                ) : (
                  <CircularProgress
                    size={50}
                    value={file.progress ?? 0}
                    max={100}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TodoModal;
