import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";

const CopyExternalFormLinkButton = () => {
  const { data }: IUseApi<any[]> = useApi("user-branch-manager", []);
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();

  if (data?.length === 0 && !user?.is_admin) {
    return null;
  }

  return (
    <CopyToClipboard
      options={{ format: "text/plain" }}
      text={`${window.location.origin}/client-details/${uuid}`}
      onCopy={() => toast.success("Form link copied to clipboard.")}
    >
      <Button block outline color="secondary" className="mt-3">
        Copy Form Link
      </Button>
    </CopyToClipboard>
  );
};

export default CopyExternalFormLinkButton;
