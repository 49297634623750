import React from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { CustomField } from "../customFields/customFieldTypes";
import ProfileForm from "../customFields/ProfileForm";
import HeaderPage from "../header/HeaderPage";
import CustomScaleLoader from "../utils/scaleLoader";

const EquipmentCustomFields = () => {
  const { loading, data, setData }: IUseApi = useApi(
    `/custom-fields?model=${encodeURIComponent("App\\Models\\Equipment")}`,
    [],
    true,
  );

  const fields = data as CustomField[];

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Equipment",
            link: "/equipment",
          },
          {
            name: "Equipment Custom Fields",
            link: "/",
            active: true,
          },
        ]}
        titlePage="Equipment Custom Fields"
      />
      {loading ? (
        <CustomScaleLoader>Fetching Fields...</CustomScaleLoader>
      ) : (
        <ProfileForm
          initialValues={{
            custom_fields: fields,
          }}
          modelType="App\Models\Equipment"
          onSuccess={(data: { data: CustomField[] }) => {
            setData(data.data);
          }}
        />
      )}
    </>
  );
};

export default EquipmentCustomFields;
