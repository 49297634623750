import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import useApi from "../api/useApi";
import buttonGroup from "../utils/buttonGroup";
import dateTime, { formatting } from "../utils/dateTime";
import formatDate from "../utils/formatDate";
import RenderField from "../utils/renderField";
import Spinner from "../utils/Spinner";
import PurchaseTable from "./PurchaseTable";

const PurchaseReminder = (props) => {
  const { purchase_required, currentReminder } = props;

  const { loading } = useApi();

  if (!currentReminder) {
    return null;
  }

  return (
    <>
      <Spinner loading={loading} />
      <div className="col-12">
        <p className="mb-0 tx-inverse fw-bold">{currentReminder.name}</p>
        <div className="mb-3">
          <p className="mb-0">{currentReminder?.supplier?.name ?? "-"}</p>
          <p className="mb-0">
            Current expected delivery date:{" "}
            {formatDate(currentReminder.delivery_date)}
          </p>
        </div>
      </div>
      <div className="col-12 mb-3">
        <PurchaseTable data={currentReminder?.items ?? []} />
      </div>
      <div
        className={`col-lg-${
          purchase_required !== undefined ? "6" : "12"
        } form-group`}
      >
        <Field
          component={buttonGroup}
          groupClass="w-100"
          buttonClass="w-100"
          name="purchase_required"
          options={[
            {
              value: true,
              label: "Yes",
            },
            {
              value: false,
              label: "No",
            },
          ]}
          label="Is this purchase order still required?"
        />
      </div>

      {purchase_required !== undefined && !purchase_required && (
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="archive_reason"
            label="Reason for Archiving"
          />
        </div>
      )}

      {purchase_required && (
        <div className="col-lg-6 form-group">
          <Field
            component={dateTime}
            name="delivery_date"
            label="New Expected Delivery Date"
            {...formatting}
          />
        </div>
      )}
    </>
  );
};

const selector = formValueSelector("Reminder");

const mapStateToProps = (state) => {
  return {
    purchase_required: selector(state, "purchase_required"),
  };
};

export default connect(mapStateToProps, {})(PurchaseReminder);
