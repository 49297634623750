import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import swal from "sweetalert";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import SelectInput from "../form/SelectInput";
import FileInput from "../upload/FileInput";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { useAuth } from "../../context/auth-context";
import { IntegrationType } from "../integrations/integrationTypes";
import InformationAlert from "../utils/InformationAlert";
import TextButton from "../utils/TextButton";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import { sumBy } from "lodash";
import { useParams } from "react-router-dom";

const Form = (props) => {
  const {
    handleSubmit,
    change,
    multiDay,
    dateFrom,
    dateTo,
    fullDay,
    startTime,
    finishTime,
    date,
    initialValues,
    user,
    leaveReason,
  } = props;

  const [approvers, setApprovers] = useState([]);

  const { id } = useParams();
  const { user: authUser } = useAuth();

  const [selectedLeaveReason, setSelectedLeaveReason] = useState();

  const { data: leaveReasons } = useApi(
    "leave-reasons?include=integrationModels&filter[private]=false",
    [],
  );

  const workHours = useOrganisationSetting("standard_work_hours");

  const {
    loading: fetchingPublicHolidays,
    data: publicHolidays,
    setData,
    setUrl,
  } = useApi("", []);

  const { takeAction, loading, response } = useApi();

  useEffect(() => {
    if (initialValues?.uuid) {
      setSelectedLeaveReason(
        leaveReasons.find(
          (reason) => reason.id == initialValues.leave_reason_id,
        ),
      );
    }
  }, [leaveReasons]);

  const { data: requestedLeave, setUrl: setRequestedLeaveUrl } = useApi("", []);

  const {
    takeAction: fetchHours,
    data: leaveRequestEstimates,
    setUrl: setEstimatedHoursUrl,
  } = useApi("", []);

  const fetchApprovers = (branchId) => {
    return takeAction(
      "index",
      `timesheet-approval-users/users?branch_id=${branchId}`,
    ).then(({ data }) => {
      setApprovers(data.data);
    });
  };

  useEffect(() => {
    if (typeof multiDay == "undefined") {
      return;
    }

    if (multiDay) {
      change("start_time", null);
      change("finish_time", null);
      return;
    }

    change("date_from", null);
    change("date_to", null);
  }, [multiDay]);

  useEffect(() => {
    if (!multiDay && date) {
      setUrl(
        `public-holidays?filter[between_dates][]=${dayjs(date).format(
          "YYYY-MM-DD",
        )}&filter[between_dates][]=${dayjs(date).format("YYYY-MM-DD")}`,
      );
      return;
    }

    if (dateFrom && dateTo) {
      setUrl(
        `public-holidays?filter[between_dates][]=${dayjs(dateFrom).format(
          "YYYY-MM-DD",
        )}&filter[between_dates][]=${dayjs(dateTo).format("YYYY-MM-DD")}`,
      );
    }
  }, [multiDay, fullDay, dateFrom, dateTo, startTime, finishTime, date]);

  const calculateTotalHours = () => {
    if (!multiDay && fullDay) {
      return workHours?.meta_value ?? 7.6;
    }

    if (!multiDay && !fullDay && date && finishTime && startTime) {
      return dayjs(`${date} ${finishTime}`).diff(
        `${date} ${startTime}`,
        "hours",
        true,
      );
    }

    if (!dateFrom || !dateTo) {
      return 0;
    }

    const totalDays = dayjs(dateTo).diff(dateFrom, "d");

    let weekdayAmount = 0;

    let day;

    const publicHolidayDays = publicHolidays.map(({ date }) =>
      dayjs(date).format("YYYY-MM-DD"),
    );

    for (let i = 0; i <= totalDays; i++) {
      day = dayjs(dateFrom).add(i, "days");

      if (publicHolidayDays.includes(day.format("YYYY-MM-DD"))) {
        continue;
      }

      if ([0, 6].includes(day.day())) {
        continue;
      }

      weekdayAmount++;
    }

    return weekdayAmount * (workHours?.meta_value ?? 7.6);
  };

  const onChangeRole = (values) => {
    change("approver", "");
    setApprovers([]);
    if (values?.branch_id) {
      fetchApprovers(values.branch_id);
    }
  };

  const calculatedHours = useMemo(() => {
    const hours = calculateTotalHours();

    change("hours", hours);

    return hours;
  }, [
    multiDay,
    fullDay,
    dateFrom,
    dateTo,
    startTime,
    finishTime,
    date,
    publicHolidays,
    workHours,
  ]);

  const fetchLeaveRequestEstimate = async () => {
    if (
      !authUser.active_organisation.integration_types.includes(
        IntegrationType.Payroll,
      )
    ) {
      return;
    }

    if (
      (dateFrom && dateTo) ||
      (date && startTime && finishTime) ||
      (date && !startTime && !finishTime)
    ) {
      let urlQueryString = null;
      let requestedLeaveUrl = null;

      if (multiDay) {
        urlQueryString = dateTo ? `?date=${dateTo}` : null;
      } else {
        urlQueryString = date ? `?date=${date}` : null;
      }

      if (initialValues.id) {
        requestedLeaveUrl = `filter[where_not]=${initialValues.id}`;
      }

      if (!urlQueryString) {
        return;
      }

      setEstimatedHoursUrl(
        `estimated-leave-balances/${user.uuid}${urlQueryString}`,
      );

      if (leaveReason) {
        const userId = initialValues?.user_id ?? id;

        setRequestedLeaveUrl(
          `/users/${userId}/requested-leave?${requestedLeaveUrl}&filter[leave_reason_id]=${leaveReason}&filter[not_fully_paid]=true`,
        );
      }
    }
  };

  useEffect(() => {
    fetchLeaveRequestEstimate();
  }, [
    multiDay,
    fullDay,
    dateFrom,
    dateTo,
    startTime,
    finishTime,
    date,
    leaveReason,
  ]);

  const externalLeaveReason = selectedLeaveReason?.integration_models?.find(
    (m) => (m.integration_type = IntegrationType.Payroll),
  );

  const estimatedLeave = leaveRequestEstimates.find(
    (estimate) =>
      estimate.leaveCategoryId == externalLeaveReason?.external_integration_id,
  );

  const leaveAlreadyRequested = requestedLeave.reduce((sum, requestedLeave) => {
    if (requestedLeave.timesheets.length > 0) {
      return (sum += sumBy(
        requestedLeave.timesheets.filter((t) => !t.completed_at),
        "total_hours",
      ));
    }

    return (sum += requestedLeave.total_hours);
  }, 0);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormHeader>Details</FormHeader>
        <FormErrorAlert error={props.error} />
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="leave_reason_id"
            validate={required}
            required
            label="Reason"
            changeValue={setSelectedLeaveReason}
            options={leaveReasons.map((reason) => {
              return {
                label: reason.reason,
                value: reason.id,
                ...reason,
              };
            })}
            aria-label="Leave Reason"
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="role_id"
            validate={required}
            required
            label="Role"
            formatData={formatRoles}
            url={`/users/${user.uuid}/staff-roles`}
            changeValue={onChangeRole}
            getOptionValues={(options, onChange) => {
              if (options.length === 1) {
                onChange(options[0].value);
                onChangeRole(options[0]);
              }
            }}
          />
        </div>
        {initialValues?.approval_action?.action_type === "Redo" ||
        !initialValues?.uuid ? (
          <div className="col-lg-4 form-group">
            <Field
              component={SelectInput}
              label="Approver"
              options={approvers}
              name="approver"
              required
              validate={required}
              isLoading={loading}
            />
            {loading && <small>Fetching approvers...</small>}
            {approvers.length === 0 && response && !loading && (
              <small>No approvers found for selected branch.</small>
            )}
          </div>
        ) : null}

        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            name="remarks"
            textarea
            label="Remarks"
          />
        </div>
        {initialValues?.uuid ? null : (
          <div className="col-lg-12 form-group">
            <Field component={FileInput} name="file" label="Documentation" />
          </div>
        )}
        <div className="col-lg-12 form-group">
          <Field
            component={renderToggleInput}
            name="multi_day"
            label="Request for more than one day"
            ariaLabel="multi_day"
          />
        </div>

        {multiDay ? (
          <>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                type="date"
                name="date_from"
                label="From"
                required
                validate={required}
                onChangeValue={(value) => {
                  if (!dateTo || dayjs(dateTo).isBefore(dayjs(value))) {
                    change(
                      "date_to",
                      dayjs(value).add(1, "day").format("YYYY-MM-DD"),
                    );
                  }
                }}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                type="date"
                name="date_to"
                label="To"
                required
                validate={required}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name="date"
                label="Date"
                type="date"
                required
                validate={required}
              />
            </div>
            {initialValues?.uuid ? (
              <div className="col-lg-6 form-group"></div>
            ) : (
              <div className="col-lg-6 form-group">
                <Field
                  component={renderToggleInput}
                  name="full_day"
                  label="Full Day"
                />
              </div>
            )}
            {!fullDay && (
              <>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    type="time"
                    name="start_time"
                    label="Start Time"
                  />
                  <small>Leave blank for full day</small>
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    type="time"
                    name="finish_time"
                    label="Finish Time"
                  />
                </div>
              </>
            )}
          </>
        )}
        <CalculatedHours
          calculatedHours={calculatedHours}
          fetchingPublicHolidays={fetchingPublicHolidays}
        />
        {estimatedLeave && (
          <div className="col-lg-12">
            <p className="mb-0 text-dark">
              <span className="fw-bolder">
                {estimatedLeave.accruedAmount.toFixed(2)}{" "}
              </span>
              Current Leave Balance
            </p>
            {estimatedLeave.accruedAmount - calculatedHours < 0 ? (
              <div className="my-3">
                <InformationAlert
                  body="Not Enough Hours"
                  type="danger"
                  className="mt-3"
                />
              </div>
            ) : (
              <>
                {leaveAlreadyRequested > 0 ? (
                  <>
                    <p className="mb-0 text-dark">
                      {sumBy(requestedLeave, "total_hours").toFixed(2)}{" "}
                      <TextButton id="RequestedInfo">
                        hours already requested
                      </TextButton>
                    </p>
                    <UncontrolledPopover target="RequestedInfo">
                      <PopoverBody>
                        {requestedLeave.map((r) => {
                          return (
                            <div>
                              <a
                                href={r.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {r.reason.reason} - {r.total_hours.toFixed(2)}{" "}
                                hours
                              </a>
                            </div>
                          );
                        })}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
                <p className="text-dark mb-0">
                  {(
                    estimatedLeave.accruedAmount -
                    calculatedHours -
                    leaveAlreadyRequested
                  ).toFixed(2)}{" "}
                  <TextButton id="HoursInfo">Hours</TextButton> Remaining
                </p>
                <UncontrolledPopover target="HoursInfo">
                  <PopoverBody>
                    <p className="mb-0">
                      {(
                        (estimatedLeave.accruedAmount -
                          calculatedHours -
                          leaveAlreadyRequested) /
                          workHours?.meta_value ?? 7.6
                      ).toFixed(2)}{" "}
                      Days
                    </p>
                    <p className="mb-0">
                      {(
                        (estimatedLeave.accruedAmount -
                          calculatedHours -
                          leaveAlreadyRequested) /
                        ((workHours?.meta_value ?? 7.6) * 5)
                      ).toFixed(2)}{" "}
                      Weeks
                    </p>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const formatRoles = (data) => {
  return data.map((staffRole) => {
    return {
      ...staffRole,

      label: staffRole.role_branch,
      value: staffRole.id,
      disabled: staffRole.is_disabled,
    };
  });
};

const formatLeaveReasons = (leaveReasons) => {
  if (leaveReasons.length === 0) {
    swal({
      title: "No leave Reasons Found",
      text: `Your organisation has not added any leave reasons in yet.

      You are unable to add in leave without a "reason".
      
      Please talk to one of your organisation's administrators to rectify this.`,
      icon: "warning",
    });
    return [];
  }

  return leaveReasons.map((reason) => {
    return {
      label: reason.reason,
      value: reason.id,
      ...reason,
    };
  });
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    dates: selector(state, "dates"),
    multiDay: selector(state, "multi_day"),
    dateFrom: selector(state, "date_from"),
    dateTo: selector(state, "date_to"),
    startTime: selector(state, "start_time"),
    finishTime: selector(state, "finish_time"),
    fullDay: selector(state, "full_day"),
    date: selector(state, "date"),
    leaveReason: selector(state, "leave_reason_id"),
  };
};

const form = reduxForm({});

export default connect(mapStateToProps, {})(form(Form));

const CalculatedHours = ({ calculatedHours, fetchingPublicHolidays }) => {
  if (!calculatedHours || calculatedHours === 0) {
    return null;
  }

  return (
    <div className="col-12 form-group">
      <p className="mb-0 text-dark">
        {fetchingPublicHolidays ? (
          "Loading..."
        ) : (
          <>
            <span id="calculated-hours" className="fw-bolder">
              {" "}
              {calculatedHours.toFixed(2)}
            </span>{" "}
            total hours
          </>
        )}
      </p>
    </div>
  );
};
