import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import deleteSwal from "../utils/deleteSwal";
import Empty from "./Empty";
import FormModal from "./FormModal";
import errorSwal from "../utils/errorSwal";

const Index = ({ model, id }) => {
  const { takeAction, loading } = useApi();
  const [competencies, setCompetencies] = useState([]);
  const { modal, toggle } = useModal();

  useEffect(() => {
    takeAction("index", `required-competencies?model=${model}&id=${id}`)
      .then(({ data }) => setCompetencies(data.data))
      .catch(errorSwal);
  }, []);

  const submit = (values) => {
    values.requirable_type = model;
    values.requirable_id = id;

    return takeAction("store", "required-competencies", values)
      .then(({ data }) => {
        toggle();
        toast.success(`${data.data.skill_level.name} added.`);
        setCompetencies([...competencies, data.data]);
      })
      .catch(errorSwal);
  };

  const deleteCompetency = (competency) => {
    deleteSwal(competency.skill_level.name).then(() => {
      takeAction("destroy", `required-competencies/${competency.uuid}`)
        .then(() => {
          setCompetencies(
            competencies.filter((c) => c.uuid !== competency.uuid),
          );
          Alert.success(`${competency.skill_level.name} deleted.`);
        })
        .catch(errorSwal);
    });
  };

  const columns = [
    {
      accessorKey: "skill_level.skill_name",
      header: "Skill",
    },
    {
      accessorKey: "skill_level.name",
      header: "Name",
    },
    {
      accessorKey: "uuid",
      header: "Delete",
      cell: (info) => {
        return (
          <button
            type="button"
            onClick={() => deleteCompetency(info.row.original)}
            className="btn btn-outline-danger btn-sm"
          >
            Delete
          </button>
        );
      },
    },
  ];

  return (
    <>
      {!loading && competencies.length === 0 ? (
        <Empty toggle={toggle} />
      ) : (
        <>
          <button type="button" className="btn btn-link mb-3" onClick={toggle}>
            Add New Required Competency
          </button>
          <ReactTable data={competencies} columns={columns} loading={loading} />
        </>
      )}

      <FormModal modal={modal} toggle={toggle} onSubmit={submit} />
    </>
  );
};

export default Index;
