import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { draftField } from "../form/formatters";
import MentionField from "../form/MentionField";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import FileList from "../utils/FileList";
import FormModal from "../utils/FormModal";
import required from "../utils/required";

interface UnsuccessfulModalProps {
  toggle: Function;
  modal: boolean;
  setTender: (tender: any) => void;
}

const UnsuccessfulModal = ({
  toggle,
  modal,
  setTender,
}: UnsuccessfulModalProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const { takeAction }: IUseApi = useApi();
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();

  const { upload } = useUploadMultipleToS3(
    `organisations/${user?.active_organisation.uuid}/comments`,
  );

  const onSubmit = (values: any) => {
    if (
      values.unsuccessful_reason.blocks.every(
        (block: any) => block.text.trim() === "",
      )
    ) {
      toast.warning("Please write the reason for tender failure.");
      return;
    }

    return upload(values.documents).then((documents) => {
      const data = {
        ...values,
        documents,
        successful: false,
      };
      return takeAction("update", `tender-status/${uuid}`, data)
        .then(({ data }: { data: { data: any } }) => {
          toast.success("Tender marked as unsuccessful.");
          toggle();
          setTender(data.data);
        })
        .catch(errorSwal);
    });
  };

  return (
    <FormModal
      modal={modal}
      toggle={toggle}
      onSubmit={onSubmit}
      form="UnsuccessfulTender"
      title="Please provide a reason for tender submission failure."
    >
      {(formProps: any) => {
        return (
          <>
            <div className="form-group col-12">
              <Field
                component={MentionField}
                name="unsuccessful_reason"
                textarea
                label="Reason"
                validate={required}
                required
                {...draftField}
              />
            </div>
            <div className="col-12 form-group">
              <FileList {...formProps} files={files} setFiles={setFiles} />
            </div>
          </>
        );
      }}
    </FormModal>
  );
};

export default UnsuccessfulModal;
