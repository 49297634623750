import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import QUICK_LINKS from "../../data/quick_links";
import BetaButton from "../utils/BetaButton";
import SetHead from "../utils/SetHead";

export interface Crumb {
  link?: string;
  name: string;
  active?: boolean;
}

export interface RelatedLink {
  name: string;
  link: string;
  model?: string;
  method?: string;
}

export interface HeaderPageProps {
  crumbs: Crumb[];
  titlePage: string;
  titleExtra?: string | JSX.Element;
  pageDescription?: string | JSX.Element;
  relatedLinks?: RelatedLink[];
  beta?: boolean;
  headTitle?: string;
}

const HeaderPage = ({
  crumbs,
  titlePage,
  beta = false,
  titleExtra,
  pageDescription,
  relatedLinks,
  headTitle,
}: HeaderPageProps) => {
  const { user } = useAuth();

  const homeLink: string = `/users/${user?.uuid}/profile`;

  const quickLinks = QUICK_LINKS.flatMap((quickLink) => {
    return quickLink.links.map((link) => link.link);
  });

  const positionAdminRoutes = [
    "/clients",
    "/employment-details",
    "/groups",
    "/employment-awards",
  ];

  const filteredRelatedLinks = relatedLinks?.filter((relatedLink) => {
    return (
      user?.hasAccessTo(
        relatedLink?.model ?? "",
        relatedLink?.method ?? "view",
      ) ||
      (user?.is_admin && quickLinks.includes(relatedLink.link)) ||
      (!user?.is_admin && !quickLinks.includes(relatedLink.link)) ||
      (user?.is_position_admin &&
        positionAdminRoutes.includes(relatedLink.link))
    );
  });

  return (
    <>
      <SetHead hTitle={headTitle || titlePage} />
      <div
        className="manager-header"
        style={
          (pageDescription
            ? { paddingBottom: "10px" }
            : {}) as React.CSSProperties
        }
      >
        <div
          className="slim-pageheader"
          style={
            (pageDescription
              ? { paddingBottom: "5px" }
              : {}) as React.CSSProperties
          }
        >
          <ol className="breadcrumb slim-breadcrumb">
            <li className="breadcrumb-item">
              <Link to={homeLink}>Home</Link>
            </li>
            {crumbs.map((crumb, index) => {
              let link = "";
              if (crumb.link) {
                link = crumb.link.startsWith("/")
                  ? crumb.link
                  : `/${crumb.link}`;
              }

              return (
                <li key={index} className="breadcrumb-item">
                  {crumb.active ? (
                    crumb.name
                  ) : (
                    <Link to={link}>{crumb.name}</Link>
                  )}
                </li>
              );
            })}
          </ol>
          <h6 className="slim-pagetitle">
            {titlePage}
            {titleExtra ? <> {titleExtra}</> : ""}
            {beta ? (
              <>
                {" "}
                (BETA <BetaButton />)
              </>
            ) : (
              ""
            )}
          </h6>
        </div>
        {pageDescription && <p className="mb-1 mt-2">{pageDescription}</p>}
        {filteredRelatedLinks && filteredRelatedLinks?.length > 0 && (
          <div className="mb-2">
            RELATED LINKS:{" "}
            {filteredRelatedLinks?.map((relatedLink: RelatedLink, index) => {
              if (index === filteredRelatedLinks.length - 1) {
                return <Link to={relatedLink.link}>{relatedLink.name}</Link>;
              }
              return (
                <>
                  <Link to={relatedLink.link}>{relatedLink.name}</Link> |{" "}
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderPage;
