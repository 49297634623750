import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import { useCreateZip } from "../../context/createZip-context";
import isSubmitting from "../utils/submitting";
import ZippingDocument from "./ZippingDocument";
import SVGContainer from "../utils/SVGContainer";
import { ReactComponent as ShareLink } from "../../svgs/share-link.svg";
import ExternalSharedStandardDocumentList from "./ExternalSharedStandardDocumentList";

const SharedFiles = () => {
  const originalLogoRatio = 1.414;
  const width = 50;
  const height = width * originalLogoRatio;
  const { uuid, folderUuid } = useParams();
  const [path, setPath] = useState([]);
  const [sharingExpired, setSharingExpired] = useState(false);
  const { takeAction, loading } = useApi();
  const [sharedDocuments, setSharedDocuments] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState();
  const { creatingZip, createZipEvent, zipUrl, setZipUrl } = useCreateZip();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  useEffect(() => {
    function checkExpired(err) {
      if (
        err?.response?.status === 422 &&
        err?.response?.data?.message === "Document sharing is expired."
      ) {
        return setSharingExpired(true);
      }
      errorSwal(err);
    }

    if (folderUuid) {
      takeAction(
        "index",
        `external-shared-files/${uuid}/${folderUuid}?email=${email}`,
      )
        .then(({ data }) => {
          setSharedDocuments(data.data.children);
          setPath(data.data.file_path);
        })
        .catch(checkExpired);
    } else {
      takeAction("index", `external-shared-files/${uuid}?email=${email}`)
        .then(({ data }) => {
          setSharedDocuments(data.data);
        })
        .catch(checkExpired);
    }
  }, [folderUuid]);

  return (
    <div className="signin-wrapper">
      <div
        className="signin-box signup"
        style={{ width: "800px", marginTop: "5rem", marginBottom: "auto" }}
      >
        <h2 className="slim-logo mb-0">
          <a
            href="https://landing.thebossapp.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo
              height={height}
              width={width}
              title="theBOSSapp"
              className="header-logo pe-2"
            />
            theBOSSapp
          </a>
        </h2>
        {loading ? (
          <CustomScaleLoader>Fetching Shared Documents...</CustomScaleLoader>
        ) : sharingExpired ? (
          <div className="p-5 text-center">
            <SVGContainer SVG={ShareLink} className="mg-t-20" height={"300px"}>
              <p className="tx-inverse mg-b-20">
                <span className="text-danger">Shared link expired.</span>
                <br />
                Please contact the sender to renew the sharing!
              </p>
            </SVGContainer>
          </div>
        ) : (
          <div className="p-5">
            <div className="d-flex mb-1">
              {sharedDocuments.length > 0 && (
                <span className="ms-2 slim-card-title">
                  Shared by {sharedDocuments[0].shared_by.organisation}
                </span>
              )}
              {sharedDocuments.length > 0 && (
                <Button
                  onClick={() => {
                    setSelectedFolder();
                    return createZipEvent(
                      `external-shared-files/download/${uuid}`,
                      {
                        email: email,
                      },
                    );
                  }}
                  disabled={creatingZip}
                  color="link"
                  className="ms-auto p-0 me-2"
                >
                  {isSubmitting(
                    !selectedFolder && creatingZip,
                    <>
                      <AiOutlineDownload className="tx-16" />
                      Download All Files
                    </>,
                    "Zipping...",
                  )}
                </Button>
              )}
            </div>
            {folderUuid && (
              <div className="d-flex mb-1">
                <div>
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item ms-2">
                      <Link to={`/shared-files/${uuid}?email=${email}`}>
                        Home
                      </Link>
                    </li>
                    {path?.length > 0 &&
                      path.map((crumb, index) => (
                        <li key={crumb.uuid} className="breadcrumb-item">
                          <Crumb
                            index={index}
                            path={path}
                            crumb={crumb}
                            email={email}
                            url={`/shared-files/${uuid}`}
                          />
                        </li>
                      ))}
                  </ol>
                </div>
              </div>
            )}
            {sharedDocuments?.length > 0 ? (
              <ExternalSharedStandardDocumentList
                documents={sharedDocuments}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
              />
            ) : (
              <div className="border p-5">
                <img
                  className="w-100"
                  src="/img/empty_documents.svg"
                  height="200"
                  alt="Empty Folder"
                />
                <p className="fw-bold w-100 tx-inverse text-center mt-5 mb-2">
                  Empty Folder.
                </p>
              </div>
            )}
          </div>
        )}
        <ZippingDocument
          zipUrl={zipUrl}
          creatingZip={creatingZip}
          resetZipEvent={() => setZipUrl(undefined)}
        />
      </div>
    </div>
  );
};

const Crumb = ({ index, path, crumb, url, email }) => {
  const to = `${url}/${crumb.uuid}?email=${email}`;

  if (index + 1 === path.length) {
    return crumb.name;
  }

  return (
    <Link id={to} to={to}>
      {crumb.name}
    </Link>
  );
};

export default SharedFiles;
