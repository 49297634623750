import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { WrappedFieldProps } from "redux-form";
import { LaravelPaginatedResponse, SelectOption } from "../utils/utilTypes";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";

interface AsyncSelectInputProps {
  label: React.ReactNode;
  required?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  empty?: React.ReactNode;
  changeValue?: (value: SelectOption) => void;
  defaultOptions?: SelectOption[];
  url: string;
  formatData?: (data: any[]) => SelectOption[];
  initialOptions?: SelectOption[];
}

const PaginatedSelectInput = (
  props: WrappedFieldProps & AsyncSelectInputProps,
) => {
  const {
    input,
    label,
    required,
    isMulti,
    isDisabled,
    isLoading,
    empty,
    meta: { touched, error, warning },
    changeValue,
    defaultOptions = [],
    url,
    formatData,
    initialOptions,
  } = props;

  const { takeAction }: IUseApi<{}, LaravelPaginatedResponse<any>> = useApi();

  const [options, setOptions] = useState<any[]>(initialOptions ?? []);

  useEffect(() => {
    if (defaultOptions.length > 0) {
      setOptions(defaultOptions);
    }
  }, []);

  const value = isMulti
    ? input.value
    : options.filter((option) => input.value === option.value);

  return (
    <div>
      <div className="d-flex space-x-1">
        <label className="form-control-label tx-inverse tx-semibold">
          {label}
        </label>
        {required ? <span className="tx-danger"> *</span> : ""}
      </div>
      <AsyncPaginate
        isClearable
        {...props}
        {...props.input}
        loadOptions={(search, loadedOptions, additionalInfo) => {
          return takeAction(
            "index",
            `${url}&page=${additionalInfo?.page}&filter[search]=${search}`,
          ).then(({ data }) => {
            setOptions([
              ...loadedOptions,
              ...(formatData ? formatData(data.data) : data.data),
            ]);

            return {
              options: formatData ? formatData(data.data) : data.data,
              hasMore: data.meta.last_page != data.meta.current_page,
              additional: {
                page: data.meta.current_page + 1,
              },
            };
          });
        }}
        isOptionDisabled={(option) => option.disabled}
        onChange={(value: SelectOption, { action }) => {
          changeValue?.(value);

          if (action === "clear") {
            input.onChange(null);
          } else {
            input.onChange(isMulti ? value : value.value);
          }
        }}
        /** @ts-ignore */
        onBlur={() => input.onBlur()}
        isLoading={isLoading}
        value={value}
        className="shadow-sm"
        isDisabled={isDisabled}
        additional={{
          page: 1,
        }}
      />
      {/* {!isLoading && options.length === 0 && (
        <small className="d-block">{empty}</small>
      )} */}
      {touched && warning && <span className="tx-warning">{warning}</span>}
      {touched && error && <span className="parsley-errors-list">{error}</span>}
    </div>
  );
};

export default PaginatedSelectInput;
