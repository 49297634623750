import React from "react";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
//BOSS COMPONENTS
import FileInput from "../upload/FileInput";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import required from "../utils/required";

const StandardDocumentModal = (props) => {
  const {
    modal,
    toggle,
    whenSubmitted,
    documentable_type,
    documentable_id,
    bucket,
    extraComponent = null,
  } = props;

  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();
  const { takeAction } = useApi();

  const onSubmit = ({ standard_document_file }) => {
    const data = {
      documentable_type,
      documentable_id,
    };

    return upload(standard_document_file, bucket, (documents) => {
      return takeAction("store", "documents/standard/single", {
        ...data,
        documents: [documents],
      })
        .then((res) => {
          toast.success("Document uploaded successfully");
          toggle();
          return res;
        })
        .then((res) => {
          setUploadPercent(0);
          if (whenSubmitted) whenSubmitted(res);
        })
        .catch(errorSwal);
    });
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Upload Document"
      onSubmit={onSubmit}
      form="StandardDocumentModal"
      extraComponent={extraComponent}
    >
      <div className="form-group col-12">
        <Field
          name="standard_document_file"
          component={FileInput}
          required
          label="File"
          validate={required}
          percent={uploadPercent}
        />
      </div>
    </FormModal>
  );
};

export default StandardDocumentModal;
