type Colors = {
  [key: string]: {
    light: string;
    main: string;
    border: string;
  };
};

//N.B. Primary and secondary are incorrect
const colors: Colors = {
  danger: {
    light: "#f7d1d5",
    main: "#b51f2e",
    border: "#dc3545",
  },
  info: {
    light: "#d4e2f3",
    main: "#3b7eca",
    border: "#5B93D3",
  },
  warning: {
    light: "#fce6c6",
    main: "#c47709",
    border: "#F49917",
  },
  success: {
    light: "#bcfcb1",
    main: "#1c9806",
    border: "#23BF08",
  },
  primary: {
    light: "#bcfcb1",
    main: "#1c9806",
    border: "#23BF08",
  },
  secondary: {
    light: "#bcfcb1",
    main: "#1c9806",
    border: "#23BF08",
  },
  light: {
    light: "#dee2e6",
    main: "#212529",
    border: "#adb5bd",
  },
};

export default colors;
