import { reset } from "redux-form";
import useModal from "../hooks/useModal";
import formError from "../utils/formError";
import useCreateNotice from "./hooks/useCreateNotice";
import Modal from "./Modal";
import { useAuth } from "../../context/auth-context";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";

const ModalButton = ({ className }) => {
  const { toggle, modal } = useModal();

  const { user } = useAuth();
  const { mutateAsync } = useCreateNotice();

  const { files, setFiles, loading, upload } = useUploadMultipleToS3(
    `organisations/${user.active_organisation.uuid}/notices`,
  );

  const onSubmit = (values, dispatch) => {
    return upload(values.documents)
      .then((documents) => mutateAsync({ ...values, documents }))
      .then(() => {
        dispatch(reset("AddNotice"));
        setFiles([]);
        toggle();
      })
      .catch(formError);
  };

  return (
    <>
      <button
        className={`btn btn-outline-primary ${className}`}
        onClick={toggle}
        type="button"
      >
        Add Notice
      </button>
      {modal && (
        <Modal
          files={files}
          loading={loading}
          setFiles={setFiles}
          toggle={toggle}
          modal={modal}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default ModalButton;
