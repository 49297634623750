import { useEffect, useState } from "react";
import api from "../../../api/api";
import { useAuth } from "../../../context/auth-context";
import JobStatus from "../../../enums/JobStatus";
import { TestDashboardFilter } from "../TestDashboard";

interface TestRequestFilterCall {
  data: {
    name: string;
    id: number;
  }[];
}

const dataMapper = (data: { name: string; id: number }[]) => {
  return data.map((d) => {
    return {
      label: d.name,
      value: d.id,
    };
  });
};

const useTestDashboardFilters = (): TestDashboardFilter[] => {
  const { user } = useAuth();

  const [filters, setFilters] = useState<TestDashboardFilter[]>([
    {
      name: "status",
      label: "Job Status",
      multiple: true,
      options: [
        {
          label: "Pending",
          value: JobStatus.Pending,
        },
        {
          label: "In Progress",
          value: JobStatus.InProgress,
        },
        {
          label: "On Hold",
          value: JobStatus.OnHold,
        },
        {
          label: "Cancelled",
          value: JobStatus.Cancelled,
        },
        {
          label: "Completed",
          value: JobStatus.Completed,
        },
      ],
    },
    {
      name: "job_manager",
      label: "Your Test Requests",
      options: [
        {
          label: "Your Test Requests",
          value: user?.id ?? 1,
        },
      ],
    },
    {
      name: "overdue",
      label: "Overdue",
      options: [
        {
          label: "Overdue",
          value: true,
        },
      ],
    },
  ]);

  useEffect(() => {
    const filterValues: Promise<TestDashboardFilter>[] = [
      api
        .get<TestRequestFilterCall>("test-requests/branches")
        .then(({ data }) => {
          return {
            name: "branch_id",
            label: "Branch",
            multiple: true,
            options: dataMapper(data.data),
          };
        }),
      api
        .get<TestRequestFilterCall>("test-requests/projects")
        .then(({ data }) => {
          return {
            name: "project_id",
            label: "Project",
            multiple: true,
            options: dataMapper(data.data),
          };
        }),
      api
        .get<TestRequestFilterCall>("test-requests/clients")
        .then(({ data }) => {
          return {
            name: "client_id",
            label: "Client",
            multiple: true,
            options: dataMapper(data.data),
          };
        }),
      api
        .get<{ title: string; id: number }[]>("testregisters/methods")
        .then(({ data }) => {
          return {
            name: "test_method_id",
            label: "Test Method",
            multiple: true,
            options: data.map((d) => {
              return {
                label: d.title,
                value: d.id,
              };
            }),
          };
        }),
    ];

    Promise.all(filterValues).then((values) => {
      const newFilters = [...filters, ...values];

      setFilters(newFilters);
    });
  }, []);

  return filters;
};

export default useTestDashboardFilters;
