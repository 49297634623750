import { Link } from "react-router-dom";

const FooterLink = ({ active, link, icon = "", children, width = null }) => {
  return (
    <div
      className={!width ? "col" : ""}
      style={{ width, flex: width ? "none" : null }}
    >
      <li className={`nav-item ${active ? "active" : ""}`}>
        <Link
          style={{
            backgroundImage: "none",
            fontWeight: "200",
            fontSize: "11px",
          }}
          to={link}
          className="nav-link d-block"
        >
          <div className="d-block">
            {typeof icon === "string" ? (
              <i
                className={`fa ${icon?.length ? icon : "fa-file-text-o"} fa-lg`}
              />
            ) : (
              icon
            )}
          </div>
          <div className="d-block m-1">{children}</div>
        </Link>
      </li>
    </div>
  );
};

export default FooterLink;
