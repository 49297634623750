import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import errorSwal from "../utils/errorSwal";
import OrganisationForm from "./OrganisationForm";

const UpdateDetails = (props) => {
  const { user } = useAuth();

  const onSubmit = (values) => {
    return axios
      .put(`organisations/${user.active_organisation.uuid}`, values)
      .then(({ data }) => {
        props.setOrganisation(data.data);
        return toast.success("Details updated!");
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <OrganisationForm {...props} />
    </form>
  );
};

const form = reduxForm({ form: "organisationSettings" });

export default form(UpdateDetails);
