import React from "react";

const CompressPath = () => {
  const { host, pathname } = window.location;
  const path = `${host}${pathname}`;
  const locationArray = path.split("/");
  const compressedPath = `${locationArray[0]}/.../${
    locationArray[locationArray.length - 1]
  }`;

  return <div>{compressedPath}</div>;
};

export default CompressPath;
