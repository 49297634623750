import React, { Fragment } from "react";

const InfoCard = ({ info, wrapperClass }) => {
  return (
    <div className={`card card-status mt-3 ${wrapperClass}`}>
      {info.map((i, index) => (
        <Fragment key={index}>
          <label className={`slim-card-title ${index !== 0 ? "mt-4" : ""}`}>
            {i.title}
          </label>
          <div className="flex-grow-1">
            <div className="media-body">
              <div className="d-flex tx-inverse">
                <p className="me-auto">{i.label}:</p>
                <p>{i.value}</p>
              </div>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default InfoCard;
