import { Link, useParams } from "react-router-dom";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import Profile from "../../profile";
import { DashboardH3, DashboardHeader } from "../../utils/DashboardHeader";
import { useEffect } from "react";
import Spinner from "../../utils/Spinner";
import ItemForm from "../ItemForm";
import { IUseApi } from "../../api/apiTypes";
import { toast } from "react-toastify";
import ReactTable from "../../table/ReactTable";
import SpreadPrice from "../../utils/SpreadPrice";
import { money } from "../../form/formatters";
import _ from "lodash";
import { useAuth } from "../../../context/auth-context";
import errorSwal from "../../utils/errorSwal";
import ProfileButtons from "../../utils/ProfileButtons";
import isSubmitting from "../../utils/submitting";
import { BsSend } from "react-icons/bs";
import InterEntityApproval from "./InterEntityApproval";
import Invoice from "../Invoice";
import ApprovalStatus from "../../user/ApprovalStatus";

const InterEntityInvoiceProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { user } = useAuth();

  const {
    data: invoice,
    loading,
    setData: setInvoice,
    refreshData,
  } = useApi(`inter-entity-invoices/${uuid}`, null, true);

  const {
    data: uninvoicedDeliverables,
    setUrl: setDeliverableUrl,
    setData,
  } = useApi("", [], true);

  useEffect(() => {
    if (invoice) {
      setDeliverableUrl(
        `projects/${invoice.project.uuid}/uninvoiced-deliverables`,
      );
    }
  }, [invoice]);

  if (loading && !invoice) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={invoice?.name}
        crumbs={[
          {
            link: `/projects/${invoice?.project.uuid}/inter-entity-invoices`,
            name: invoice?.project.name,
          },
          {
            link: "",
            name: invoice?.name,
            active: true,
          },
        ]}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{invoice?.name}</DashboardH3>
            <p className="mb-0 text-dark">{invoice?.organisation.name}</p>
            <p className="mb-0 tx-10">Organisation</p>
          </DashboardHeader>
        }
        tabs={[
          {
            label: "Deliverables",
            page: "deliverables",
            link: `/inter-entity-invoices/${uuid}/deliverables`,
          },
          {
            label: "Overview",
            page: "overview",
            link: `/inter-entity-invoices/${uuid}/overview`,
          },
        ]}
        sideBar={
          <>
            {user?.active_organisation.id != invoice?.organisation.id ? (
              <InterEntityApproval
                invoice={invoice}
                approvalAction={invoice.approval_action}
                setInvoice={setInvoice}
                refreshData={refreshData}
              />
            ) : null}
            <ApprovalStatus
              status={invoice?.approval_status}
              className="w-100 py-2 mt-3"
            />
          </>
        }
        content={
          <ShowPage
            invoice={invoice}
            setInvoice={setInvoice}
            uninvoicedDeliverables={uninvoicedDeliverables}
            refreshData={refreshData}
          />
        }
      />
    </>
  );
};

const ShowPage = ({
  invoice,
  setInvoice,
  uninvoicedDeliverables,
  refreshData,
}: any) => {
  const { page } = useParams<{ page: string }>();
  const { user } = useAuth();

  switch (page) {
    case "deliverables":
      return (
        <>
          {user?.active_organisation.id == invoice?.organisation.id ? (
            <ItemForm
              /** @ts-ignore */
              invoice={invoice}
              setInvoice={setInvoice}
              uninvoicedDeliverables={uninvoicedDeliverables}
            />
          ) : null}
          <SendForApproval refreshData={refreshData} invoice={invoice} />
          <DeliverableTable data={invoice?.deliverables} />
        </>
      );
    case "overview":
      return <Invoice invoice={invoice} />;
    default:
      return null;
  }
};

const DeliverableTable = ({ data }: { data: any }) => {
  const columns = [
    {
      accessorKey: "job",
      header: "Job",
      cell: (info: any) => {
        const deliverable = info.row.original;
        return <Link to={deliverable.job.link}>{deliverable.job.name}</Link>;
      },
    },
    {
      accessorKey: "name",
      header: "Deliverable Name",
      cell: (info: any) => {
        const deliverable = info.row.original;
        return <Link to={deliverable.link}>{deliverable.name}</Link>;
      },
    },
    {
      accessorKey: "job.branch",
      header: "Branch",
    },
    {
      accessorKey: "total_cost",
      header: "Deliverable Value",
      cell: (info: any) => <SpreadPrice price={info.getValue()} />,
      footer: `Total: ${money.format(_.sumBy(data, "total_cost").toFixed(2))}`,
    },
  ];

  return <ReactTable data={data} columns={columns} />;
};

const SendForApproval = ({
  invoice,
  refreshData,
}: {
  invoice: any;
  refreshData: Function;
}) => {
  const { takeAction, loading }: IUseApi = useApi();

  if (invoice.approval_action?.action_type === "Approve") return null;

  if (invoice.line_items.length === 0) return null;

  if (invoice.approval?.current_action?.action_type == 0) return null;

  const buttons = [
    {
      onClick: () => {
        takeAction("store", `inter-entity-invoices/${invoice.uuid}/approvals`)
          .then(() => {
            toast.success("Invoice sent for approval.");
            refreshData();
          })
          .catch(errorSwal);
      },
      text: isSubmitting(
        loading,
        <>
          <BsSend className="tx-18 tx-purple me-2" /> Send for Approval
        </>,
        "Sending for Approval...",
      ),
      disabled: loading,
    },
  ];

  return <ProfileButtons buttons={buttons} />;
};

export default InterEntityInvoiceProfile;
