import { UseInfiniteQueryResult } from "react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import PaginatedList from "../pagination/PaginatedList";
import ReactTable from "../table/ReactTable";
import dayjs from "dayjs";
import { money } from "../form/formatters";
import { Link } from "react-router-dom";

interface Cost {
  uuid: string;
  created_at: string;
  type: string;
  total: number;
  date: string;
  name: string;
  link?: string;
}

const CostsTable = ({
  hook,
  listName,
  ignoreDates,
  from,
  to,
}: {
  hook: (search?: string, ...args: any[]) => UseInfiniteQueryResult;
  listName: string;
  ignoreDates?: boolean;
  from?: string;
  to?: string;
}) => {
  const columnHelper = createColumnHelper<Cost>();

  const columns = useMemo<ColumnDef<Cost, any>[]>(
    () => [
      columnHelper.accessor("type", {
        header: "Type",
      }),
      columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => {
          const effort = info.row.original;

          return (
            <div className="">
              {effort.link ? (
                <Link className="mb-0 text-dark fw-bolder" to={effort.link}>
                  {effort.name}
                </Link>
              ) : (
                <p className="mb-0 text-dark fw-bolder">{effort.name}</p>
              )}
              <p className="mb-0 ms-auto">
                {dayjs(effort.date).format("DD/MM/YYYY")}
              </p>
            </div>
          );
        },
      }),

      columnHelper.accessor("total", {
        header: "Total",
        cell: (info) => money.format(info.getValue()),
      }),
    ],
    [],
  );

  return (
    <PaginatedList
      listName={listName}
      indexHook={hook}
      indexHookArguments={[from, to, ignoreDates]}
      originalFilters={[]}
      list={({ data }: { data?: Cost[] }) => {
        return (
          <div className="col-12">
            <ReactTable disableSearch data={data ?? []} columns={columns} />
          </div>
        );
      }}
    />
  );
};

export default CostsTable;
