const validate = values => {
  const errors = {
    general: {}
  };
  if (values.general && !values.general.scheduled_at) {
    errors.general.scheduled_at = "Required";
  }
  if (values.general && !values.general.price) {
    errors.general.price = "Required";
  }
  if (values.general && !values.general.reference_number) {
    errors.general.reference_number = "Required";
  }

  return errors;
};

export default validate;
