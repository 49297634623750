import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FieldArray, InjectedFormProps, reduxForm } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { TemplateVariable } from "../templates/templateTypes";
import errorSwal from "../utils/errorSwal";
import TemplateDocumentFields from "./TemplateDocumentFields";
import { TemplateDocument } from "./templateDocumentTypes";

export interface TemplateDocumentFieldFormProps {
  sheets: string[];
  variables: TemplateVariable[];
  setDocument: (document: TemplateDocument) => void;
}

const TemplateDocumentFieldForm = (
  props: TemplateDocumentFieldFormProps &
    InjectedFormProps<{}, TemplateDocumentFieldFormProps>,
) => {
  const { takeAction }: IUseApi = useApi();

  const { uuid } = useParams<{ uuid: string }>();

  const { sheets, variables, setDocument } = props;

  const onSubmit = (values: any) => {
    return takeAction("update", `template-documents/${uuid}/fields`, values)
      .then(({ data }: { data: { data: TemplateDocument } }) => {
        toast.success("Updated Successfully");
        setDocument(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className="row">
        <FieldArray
          component={TemplateDocumentFields}
          name="fields"
          sheets={sheets}
          variables={variables}
          valid={props.valid}
          submitting={props.submitting ?? false}
          setDocument={props.setDocument}
        />
      </div>
    </form>
  );
};

const form = reduxForm<{}, TemplateDocumentFieldFormProps>({
  form: "TemplateDocumentFieldForm",
});

export default form(TemplateDocumentFieldForm);
