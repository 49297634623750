import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import colors from "../../../data/colors";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import isSubmitting from "../../utils/submitting";
import TextButton from "../../utils/TextButton";
import { MicrosoftUser } from "./UserMicrosoftDetails";

const SyncMicrosoftUser = ({
  user,
  setUser,
}: {
  user: MicrosoftUser;
  setUser: (user: MicrosoftUser) => void;
}) => {
  const { userId } = useParams<{ userId: string }>();
  const { takeAction, loading }: IUseApi<{}, { data: { uuid: string } }> =
    useApi();

  const syncUser = () => {
    return takeAction("update", `microsoft/users/sync/${userId}`, {
      microsoft_id: user.microsoft_user?.id,
    })
      .then(({ data }) => {
        setUser({
          ...user,
          integration: data.data,
        });
        toast.success("User synced");
      })
      .catch(errorSwal);
  };

  if (user.integration) {
    return null;
  }

  if (!user.microsoft_user) {
    return null;
  }

  return (
    <div
      style={{ border: `1px solid ${colors.info.border}` }}
      className="subtle-info-grad shadow rounded-lg d-flex position-relative p-3"
    >
      <div>
        <p style={{ color: colors.info.main }}>
          Microsoft user with email{" "}
          <span className="fw-bolder">{user.microsoft_user.mail}</span>, found
          but no record found in boss.
        </p>
        <p className="mb-0" style={{ color: colors.info.main }}>
          <TextButton disabled={loading} onClick={syncUser}>
            {isSubmitting(loading, "Click Here", "Syncing...")}
          </TextButton>{" "}
          to sync boss with microsoft.
        </p>
      </div>
    </div>
  );
};

export default SyncMicrosoftUser;
