import PropTypes from "prop-types";
import { money } from "../form/formatters";

const CostWidget = ({ price, name, description }) => (
  <div className="card card-status mt-3">
    <div className="flex-grow-1">
      <div className="media-body">
        <h1>{money.format(price)}</h1>
        <p>Total Cost for {name}</p>
        {description && <small>{description}</small>}
      </div>
    </div>
  </div>
);

CostWidget.propTypes = {
  price: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default CostWidget;
