import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import CustomFields from "../customFields/CustomFields";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import SelectInputAsync from "../utils/SelectInputAsync";
import PriceBands from "./PriceBands";

interface JobType {
    id: number;
    type: string,
    label: string;
    value: string;
    link?: string;
}

const Form = (props : any) => {
    const { handleSubmit, showFields } = props;
  
    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6 form-group">
                    <Field
                        component={RenderField}
                        required
                        validate={required}
                        name="name"
                        label="Name"
                    />
                </div>
                <div className="col-lg-6 form-group">
                    <Field
                        name="divisions"
                        url="/organisationdivisions/divisions"
                        label="Division"
                        component={SelectInputAsync}
                        multiple={true}
                    />
                </div>
                <div className="col-lg-6 form-group">
                    <Field
                        label="Select Project Types"
                        component={SelectInputAsync}
                        name="project_types"
                        multiple={true}
                        url={`project-types`}
                        formatData={({ data }: { data: { name: string; id: string }[] }) =>
                            data.map((d) => ({ label: d.name, value: d.id }))
                        }
                        />
                </div>

                <div className="form-group col-lg-6">
                    <Field
                        name="job_types"
                        label="Job Type"
                        component={SelectInputAsync}
                        url="/jobtypes/types/"
                        multiple={true}
                        formatData={(data : JobType[]) => {
                            return data.map((d) => ({
                                label: d.label,
                                type: d.type,
                                value: d.id
                            }));
                        }}
                        placeholder="Select Job Type"
                    />
                </div>
            </div>

            {showFields && <FieldArray
              {...props}
              component={PriceBands}
              name="price_bands"
            />}

            {showFields && <CustomFields {...props} />}
            <div className="row">
                <div className="col-12">
                <SubmitButton {...props} />
                </div>
            </div>
        </form>
    );
};

const form = reduxForm({});

const mapStateToProps = (state: any, { form }: { form: string }) => {
    const selector = formValueSelector(form);

    return {
        showFields: selector(state, "show_field"),
    };
};
export default connect(mapStateToProps, {})(form(Form));
