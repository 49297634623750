const TemplateType = {
  Docket: 0,
  PurchaseOrder: 1,
  PurchaseOrderNoTax: 2,
  Invoice: 3,
  CustomJob: 4,
  TenderType: 5,
};

export default TemplateType;
