import React from "react";
import { Button } from "reactstrap";
import { AiOutlineDownload } from "react-icons/ai";
import "../utils/zipping.css";

const ZippingDocument = ({
  zipUrl,
  creatingZip,
  resetZipEvent,
}: {
  zipUrl: string;
  creatingZip: boolean;
  resetZipEvent: () => {};
}) => {
  if (!creatingZip && !zipUrl) return null;

  return (
    <div className="zipping bg-primary">
      <div>
        {creatingZip ? `Zipping Documents` : zipUrl ? `Zip Ready` : ""}{" "}
        {creatingZip ? (
          <i className="fa fa-spinner fa-spin m-1" />
        ) : zipUrl ? (
          <>
            <Button
              className="btn btn-sm btn-info ms-2"
              onClick={() => {
                window.location.href = zipUrl;
                resetZipEvent();
              }}
            >
              <AiOutlineDownload className="tx-16" />
              Download
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ZippingDocument;
