import { Link, useHistory, useParams } from "react-router-dom";
import {
  AccountsPayable,
  InboundAccountsPayableDocument,
  InboundAccountsPayablePages,
} from "./types";
import InboundPurchaseOrderForm, {
  InboundPurchaseOrderFormProps,
} from "./InboundPurchaseOrderForm";
import InboundRecurringPurchaseForm from "./InboundRecurringPurchaseForm";
import colors from "../../data/colors";
import { FormSubmitHandler, formValueSelector } from "redux-form";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import { money } from "../form/formatters";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IUseApi } from "../api/apiTypes";

const InboundAccountsPayableForm = ({
  selectedPayable,
  selectedDocument,
  number,
}: {
  selectedPayable?: AccountsPayable;
  selectedDocument?: InboundAccountsPayableDocument;
  number?: string;
}) => {
  const { page } = useParams<{ page: InboundAccountsPayablePages }>();

  const history = useHistory();

  const { takeAction } = useApi();

  const { takeAction: checkNumber }: IUseApi = useApi();
  const [numberTaken, setNumberTaken] = useState<{
    name: string;
    link: string;
  }>();

  useEffect(() => {
    if (selectedPayable?.model === "App\\Models\\RecurringPayment" && number) {
      const timeout = setTimeout(() => {
        checkNumber("store", `recurring-payment-purchase-validation`, {
          recurring_payment_id: selectedPayable.id,
          invoice_number: number,
        })
          .then(() => {
            setNumberTaken(undefined);
          })
          .catch((err) => {
            if (
              err?.response?.status === 422 &&
              err?.response?.data?.errors.invoice_number?.[0]
            ) {
              setNumberTaken(err?.response?.data?.purchase);
            }
          });
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [number]);

  const submitRecurring: FormSubmitHandler<any> = (values) => {
    return takeAction(
      "store",
      `inbound-accounts-payable-documents/${selectedDocument?.uuid}/recurring-payments/${selectedPayable?.uuid}`,
      values,
    )
      .then(() => {
        toast.success("Contract Agreement Created");
        history.push("/inbound-accounts-payable");
      })
      .catch(formError);
  };

  const submitPurchaseOrder: FormSubmitHandler<
    any,
    InboundPurchaseOrderFormProps
  > = (values) => {
    return takeAction(
      "store",
      `inbound-accounts-payable-documents/${selectedDocument?.uuid}/purchase-orders/${selectedPayable?.purchase_invoice?.uuid}`,
      values,
    )
      .then(() => {
        toast.success("Purchase Order Created");
        history.push("/inbound-accounts-payable");
      })
      .catch(formError);
  };

  if (page !== "details") {
    return null;
  }

  if (!selectedPayable) {
    return null;
  }

  if (selectedPayable?.model === "App\\Models\\RecurringPayment") {
    return (
      <div
        className="mt-3 position-sticky"
        style={{ top: "1rem", borderColor: colors.info.border }}
      >
        <div
          className={`mb-3 p-3 bg-white rounded-lg shadow-sm ${
            numberTaken ? "border border-danger" : ""
          }`}
          id="payable"
        >
          <p className="slim-card-title">Selected Recurring Payment</p>
          <Link
            className="text-dark fw-bolder d-block"
            to={selectedPayable.link}
          >
            {selectedPayable.name}
          </Link>

          <Link
            to={`/suppliers/${selectedPayable.supplier.uuid}/details`}
            className="text-muted"
          >
            {selectedPayable.supplier.name}
          </Link>
          {numberTaken && (
            <div className="mt-1 text-danger">
              <p className="mb-0">
                Invoice Number Already Taken by{" "}
                <a
                  className="text-danger fw-bolder"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={numberTaken.link}
                >
                  {numberTaken.name}
                </a>
              </p>
            </div>
          )}
        </div>
        <InboundRecurringPurchaseForm
          form="inbound_recurring_purchase"
          /** @ts-ignore */
          onSubmit={submitRecurring}
          recurringPurchase={selectedPayable}
          initialValues={{
            number:
              selectedDocument?.invoice_field?.ValueDetection?.Text ?? null,
            payment: [{}],
            recurring_payment_id: selectedPayable.id,
          }}
        />
      </div>
    );
  }

  return (
    <div className="mt-3 position-sticky" style={{ top: "1rem" }}>
      <div
        className="mb-3 p-3 bg-white rounded-lg shadow-sm border border-info"
        id="payable"
      >
        <p className="slim-card-title">Selected Recurring Payment</p>
        <Link className="text-dark fw-bolder d-block" to={selectedPayable.link}>
          {selectedPayable.name}
        </Link>

        <Link
          to={`/suppliers/${selectedPayable.supplier.uuid}/details`}
          className="text-muted"
        >
          {selectedPayable.supplier.name}
        </Link>
        <p className="mb-0 text-muted">
          {money.format(selectedPayable.overall_cost)}
        </p>
        {selectedPayable.purchase_invoice?.file.link && (
          <small className="text-warning">
            Purchase Order already has am invoice attached.
          </small>
        )}
      </div>
      <InboundPurchaseOrderForm
        form="inbound_purchase_order"
        onSubmit={submitPurchaseOrder}
        purchaseOrder={selectedPayable}
        initialValues={{
          date: selectedPayable.date,
          purchase_items: selectedPayable.purchase_invoice?.purchase_items?.map(
            (item) => ({
              description: item.description,
              price: item.price,
              quantity: item.quantity,
              chart_of_accounts_id: item.chart_of_accounts_id,
              branch_id: item.branch_id,
              uuid: item.uuid,
            }),
          ),
          number: selectedDocument?.invoice_field?.ValueDetection?.Text ?? null,
        }}
      />
    </div>
  );
};

// const SearchPurchases = () => {
//   const { takeAction }: IUseApi = useApi();

//   return (
//     <AsyncSelect
//       loadOptions={(
//         inputValue: string,
//         callback: (options: SelectOption[]) => void,
//       ) => {
//         takeAction(
//           "index",
//           `inbound-accounts-payable/search?filter[search]=${inputValue}`,
//         ).then(({ data }) => {
//           callback(data.data);
//         });
//       }}
//     />
//   );
// };

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("inbound_recurring_purchase");

  return {
    number: selector(state, "number"),
  };
};

export default connect(mapStateToProps)(InboundAccountsPayableForm);
