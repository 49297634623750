import React, { Component } from "react";
import { Field } from "redux-form";
import Toggle from "react-toggle";

import renderField from "./renderField";
import required from "./required";

class NumberField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      generateNumber: true,
      showToggle: true,
    };
  }

  componentDidMount = () => {
    const { initialValues } = this.props;
    this.setState({
      generateNumber: !initialValues,
      showToggle: !initialValues,
    });
  };

  changeValue = () => {
    this.props.change("number", "");
    this.setState({ generateNumber: !this.state.generateNumber });
  };

  render() {
    const { showToggle, generateNumber } = this.state;
    const { fieldClassName, toggleClassName, change } = this.props;

    return (
      <>
        {!generateNumber && (
          <div className={`${fieldClassName} form-group`}>
            <Field
              {...this.props}
              validate={required}
              required
              component={renderField}
              name="number"
              type="number"
              label="Number"
            />
          </div>
        )}
        {showToggle && (
          <div className={`${toggleClassName} form-group`}>
            <label className="d-block tx-inverse tx-semibold">
              Auto Generate Number
            </label>
            <Toggle
              onChange={this.changeValue}
              checked={this.state.generateNumber}
            />
          </div>
        )}
      </>
    );
  }
}
export default NumberField;
