import * as _ from "lodash";
import React, { useEffect } from "react";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import Empty from "../utils/Empty";
import CustomScaleLoader from "../utils/scaleLoader";
import { ProjectFilters } from "./ProjectManagerDashboard";
import { getPeriodFilterQuery } from "./ProjectManagerJobs";
import ProjectManagerProductivityList from "./ProjectManagerProductivityList";
import { IProjectManagerJob, JobProductivity } from "./projectManagerTypes";

const ProjectManagerProductivity = ({
  job,
  viewProductivity,
  projectFilters,
}: {
  job: IProjectManagerJob;
  viewProductivity: boolean;
  projectFilters: ProjectFilters;
}) => {
  const {
    data,
    setUrl,
    loading,
  }: {
    data: JobProductivity[] | undefined;
    setUrl: Function;
    loading: boolean;
  } = useApi();

  useEffect(() => {
    if (viewProductivity) {
      setUrl(
        `jobs/${job.uuid}/productivity?${
          getPeriodFilterQuery(projectFilters) ?? ""
        }`,
      );
    }
  }, [viewProductivity]);

  if (!viewProductivity) {
    return null;
  }

  const totalHours = _.sumBy(data, "time_in_hours");
  const totalChargeOut = _.sumBy(data, "charge_out_amount");

  const groupedData = Object.values(_.groupBy(data, "user.uuid")) as
    | JobProductivity[][]
    | undefined;

  return (
    <div className="space-y-5 pt-4 border-top">
      <div>
        <p className="slim-card-title mb-0">
          Productivity for {job.number} - {job.name}
        </p>
        <p className="mb-0">
          {" "}
          {totalHours} total hours added | {money.format(totalChargeOut)} total
          charge out{" "}
        </p>
      </div>
      {loading ? (
        <CustomScaleLoader>Fetching Productivity...</CustomScaleLoader>
      ) : groupedData?.length === 0 ? (
        <Empty height="50%" width="25%">
          <div className="text-center mb-5">
            <p className="tx-inverse mb-1">No productivity found. </p>
            {getPeriodFilterQuery(projectFilters) && (
              <p className="mb-0">
                You currently have a period filter in place at the project
                level, this will also filter this productivity.
              </p>
            )}
          </div>
        </Empty>
      ) : (
        <>
          {groupedData?.map((userGroup) => {
            const user = userGroup[0].user;
            return (
              <ProjectManagerProductivityList
                totalChargeOut={totalChargeOut}
                userGroup={userGroup}
                key={user.uuid}
                totalHours={totalHours}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default ProjectManagerProductivity;
