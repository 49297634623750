import React, { useState } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import { remove } from "../utils/arrayUtils";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const InviteList = ({ setInvites, invites }) => {
  const { takeAction } = useApi();
  const { takeAction: resendAction } = useApi();

  const [sending, setSending] = useState([]);

  const deleteUser = (user) => {
    return deleteSwal("", `Cancel ${user.first_name}'s invitation?`)
      .then(() => takeAction("destroy", `invite/user/${user.uuid}`))
      .then(() => {
        setInvites(remove("uuid", invites, user));
        toast.success(`${user.first_name}'s invitation cancelled.`);
      })
      .catch(errorSwal);
  };

  const resend = ({ uuid }) => {
    setSending([...sending, uuid]);

    return resendAction("show", `invite/resend/${uuid}`)
      .then(() => {
        toast.success("Invitation Resent!");
        setSending(sending.filter((u) => u !== uuid));
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "first_name",
      header: "User",
      cell: (info) => {
        const user = info.row.original;
        return (
          <>
            <p className="mb-0 text-dark">
              {user.first_name} {user.last_name}
            </p>
            <small>{user.email}</small>
          </>
        );
      },
    },
    {
      accessorKey: "registered",
      header: "Status",
      cell: (info) => {
        if (info.getValue()) {
          return (
            <>
              <span className="square-8 bg-success mg-r-5 rounded-circle" />{" "}
              Registered
            </>
          );
        }

        return (
          <>
            <span className="square-8 bg-pink mg-r-5 rounded-circle" /> Waiting
          </>
        );
      },
    },
    {
      accessorKey: "created_at",
      header: "Date Invited",
      cell: (info) => dayjs(info.getValue()).format("DD/MMMM/YYYY"),
    },
    {
      accessorKey: "resend",
      header: "Resend",
      cell: (info) => {
        const user = info.row.original;
        if (user.is_registered) {
          return "-";
        }

        return (
          <button
            type="button"
            onClick={() => resend(user)}
            disabled={sending.includes(user.uuid)}
            className="btn btn-link p-0"
          >
            {isSubmitting(
              sending.includes(user.uuid),
              "Resend",
              "Resending...",
            )}
          </button>
        );
      },
    },

    {
      accessorKey: "cancel",
      header: "Cancel",
      cell: (info) => {
        const user = info.row.original;
        if (user.is_registered) {
          return "-";
        }
        return (
          <button
            type="button"
            onClick={() => deleteUser(user)}
            className="btn btn-outline-danger btn-sm"
          >
            Cancel
          </button>
        );
      },
    },
  ];

  return <ReactTable data={invites} columns={columns} />;
};

export default InviteList;
