import axios from "../api/api";
import { Component, Fragment } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { ButtonDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { fetchBranchToDos, fetchToDos } from "../../actions/toDoActions";
import errorSwal from "../utils/errorSwal";

class ToggleBranchSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      userBranches: [],
      loading: false,
    };
  }

  componentDidMount = () => {
    return axios
      .get(`/organisationbranches/user`)
      .catch((err) => errorSwal())
      .then(({ data }) => this.setState({ userBranches: data.data }));
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  fetchBranch = (branch) => {
    this.setState({ loading: true, dropdownOpen: false });
    return this.props
      .fetchBranchToDos(branch)
      .then(() => this.setState({ loading: false }));
  };

  fetchAll = () => {
    const { fetchToDos, calendarTimes } = this.props;
    this.setState({ loading: true });
    return fetchToDos(calendarTimes.start, calendarTimes.end).then(() =>
      this.setState({ loading: false }),
    );
  };

  render() {
    const { dropdownOpen, loading } = this.state;
    const { selectedBranch } = this.props;
    return (
      <Fragment>
        <ButtonDropdown
          className="btn-link"
          isOpen={dropdownOpen}
          toggle={this.toggle}
        >
          <DropdownToggle color="primary" caret={!loading} disabled={loading}>
            <ButtonText loading={loading} selectedBranch={selectedBranch} />
          </DropdownToggle>
          <DropdownMenu>
            <nav className="nav dropdown-nav">
              {this.state.userBranches.map((branch) => (
                <a
                  href="javascript:void(0)"
                  onClick={() => this.fetchBranch(branch)}
                  className="nav-link"
                  key={branch.uuid}
                >
                  <i className="fa fa-building-o tx-primary me-1 me-2 tx-18" />{" "}
                  {branch.name}
                </a>
              ))}
            </nav>
          </DropdownMenu>
        </ButtonDropdown>
        {selectedBranch.name && (
          <button
            type="button"
            onClick={this.fetchAll}
            className="px-3 ms-2 close"
            aria-label="Close"
            disabled={loading}
          >
            <span aria-hidden="true">×</span>
          </button>
        )}
      </Fragment>
    );
  }
}

const ButtonText = ({ loading, selectedBranch }) => {
  if (loading) {
    return (
      <Fragment>
        <ClipLoader loading color="#fff" size={14} /> Fetching Data...
      </Fragment>
    );
  }
  if (selectedBranch.name) {
    return `Viewing jobs for ${selectedBranch.name}`;
  }
  return "View Schedule for Branch";
};

const mapStateToProps = (state) => ({
  selectedBranch: state.toDos.selectedBranch,
});

export default connect(mapStateToProps, { fetchBranchToDos, fetchToDos })(
  ToggleBranchSchedule,
);
