import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Link } from "react-router-dom";
import PriceTd from "../utils/PriceTd";
import SpreadPrice from "../utils/SpreadPrice";

dayjs.extend(localizedFormat);

const Invoice = ({ invoice }) => {
  return (
    <>
      <div className="card card-invoice">
        <div className="card-body" id="invoice-body">
          <div className="row mg-t-20">
            {invoice.client && (
              <div className="col-md">
                <label className="section-label-sm tx-gray-500">
                  Billed To
                </label>
                <div className="billed-to">
                  <h6 className="tx-gray-800">
                    <Link to={invoice.client.link}>
                      {invoice.client.invoice_name}
                    </Link>
                  </h6>
                  <p>
                    {invoice.client.phone && (
                      <>
                        Tel No:{" "}
                        <a href={`tel:${invoice.client.phone}`}>
                          {invoice.client.phone}
                        </a>
                        <br />
                      </>
                    )}
                    {invoice.client.email && (
                      <>
                        Email:{" "}
                        <a href={`mailto:${invoice.client.email}`}>
                          {invoice.client.email}
                        </a>
                        <br />
                      </>
                    )}
                    {invoice.client.address !== "Not Provided" && (
                      <a
                        href={`http://maps.google.com/maps?q=${invoice.client.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {invoice.client.address}
                      </a>
                    )}
                  </p>
                </div>
              </div>
            )}
            <div className="col-md">
              <label className="section-label-sm tx-gray-500">
                Invoice Information
              </label>
              <p className="invoice-info-row">
                <span>P.O.</span>
                <span>{invoice.project.purchase_order || "Non Given"}</span>
              </p>
              <p className="invoice-info-row">
                <span>Invoice Number</span>
                <span>{invoice.name}</span>
              </p>
              {invoice.requested_by && (
                <p className="invoice-info-row">
                  <span>Requested By:</span>
                  <span>
                    {" "}
                    <a href={`mailto:${invoice.requested_by.email}`}>
                      {invoice.requested_by.name}
                    </a>{" "}
                  </span>
                </p>
              )}
              {invoice.requested_at && invoice.requested_by && (
                <p className="invoice-info-row">
                  <span>Requested At:</span>
                  <span>
                    {dayjs(invoice.requested_at).format("DD/MM/YYYY, LT")}
                  </span>
                </p>
              )}
              {invoice.completed_at && invoice.completed_by && (
                <>
                  <p className="invoice-info-row">
                    <span>Invoiced By:</span>
                    <span>
                      <a href={`mailto:${invoice.completed_by.email}`}>
                        {invoice.completed_by.name}
                      </a>
                    </span>
                  </p>
                  <p className="invoice-info-row">
                    <span>Invoiced At:</span>
                    <span>
                      {dayjs(invoice.completed_at).format("DD/MM/YYYY, LT")}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="table-responsive mg-t-40">
            <table className="table table-invoice">
              <thead>
                <tr>
                  <th className="wd-20p">Type</th>
                  <th className="tx-center">QNTY</th>
                  <th className="tx-right">Unit Price</th>
                  <th className="tx-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoice.line_items.map((item, i) => (
                  <tr key={i}>
                    <td>{item.description}</td>
                    <td className="tx-center">{item.quantity}</td>

                    <PriceTd price={item.price} />
                    <PriceTd price={item.price * item.quantity} />
                  </tr>
                ))}
                <tr>
                  <td colSpan="2" rowSpan="4" className="valign-middle" />
                  <td className="tx-right">Sub-Total</td>
                  <PriceTd
                    propsTd={{ colSpan: "2" }}
                    price={invoice.total_due}
                  />
                </tr>
                <tr>
                  <td className="tx-right">GST (10%)</td>
                  <PriceTd
                    propsTd={{ colSpan: "2" }}
                    price={invoice.total_due * 0.1}
                  />
                </tr>
                <tr>
                  <td className="tx-right tx-uppercase tx-bold tx-inverse">
                    Total Due
                  </td>
                  <td colSpan="2" className="tx-right">
                    <h4 className="tx-bold tx-lato">
                      <SpreadPrice price={invoice.total_due * 1.1} />
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
