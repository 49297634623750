export const TEMPERATURE = [
  { value: "Hot", label: "Hot" },
  { value: "Mild", label: "Mild" },
  { value: "Cold", label: "Cold" }
];

export const RAIN = [
  { value: "Fine", label: "Fine" },
  { value: "Wet", label: "Wet" }
];

export const WIND = [
  { value: "Windy", label: "Windy" },
  { value: "Breezy", label: "Breezy" },
  { value: "Calm", label: "Calm" }
];

export const SITE_DRAINAGE = [
  { value: "0", label: "Acceptable" },
  { value: "1", label: "Improve Before Handover" },
  { value: "2", label: "Improve ASAP" }
];

export const NEARBY_VEGETATION = [
  { value: "0", label: "Well clear" },
  { value: "1", label: "Too close" },
  { value: "2", label: "Close on adjoining property" }
];
