import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import MicrosoftPositionCard from "./MicrosoftPositionCard";
import { MicrosoftProps, OfficeSuitePosition } from "./microsoftTypes";

const PositionsToSync = (props: MicrosoftProps) => {
  const { data: positions, loading }: IUseApi<{ id: number; name: string }[]> =
    useApi(`custom-fields/positions`, [], true);

  const { takeAction }: IUseApi<{}, { data: OfficeSuitePosition }> = useApi();

  const { data, setData }: IUseApi<OfficeSuitePosition[]> = useApi(
    `office-suites/positions`,
    [],
  );

  const syncedPositions = data as OfficeSuitePosition[];

  const addPosition = (position_id: number) => {
    return takeAction("store", "office-suites/positions", {
      position_id,
      integration_id: props.microsoft.id,
    })
      .then(({ data }) => {
        setData([...syncedPositions, data.data]);
      })
      .catch(errorSwal);
  };

  const deletePosition = (positionId: number) => {
    const toDelete = syncedPositions.find(
      ({ position_id }) => position_id === positionId,
    );

    if (!toDelete) {
      toast.warning(
        "Unable to find position to delete. Please try refreshing the page and try again.",
      );

      return;
    }

    return takeAction("destroy", `office-suites/positions/${toDelete.uuid}`)
      .then(({ data }) => {
        setData(
          syncedPositions.filter(
            ({ position_id }) => position_id !== positionId,
          ),
        );
      })
      .catch(errorSwal);
  };

  return (
    <>
      <p className="section-title mt-1 mb-3">
        Select Positions to Sync to Microsoft
      </p>
      <p className="mb-1">
        Here you can configure which licences users will get.
      </p>
      <p className="mb-0">
        When a user is added to one of the selected positions, the licences
        assigned to that position will get added to their account.
      </p>
      <div className="row mt-4">
        {loading ? (
          <CustomScaleLoader>Fetching Positions...</CustomScaleLoader>
        ) : (
          <>
            {positions?.map((position) => {
              return (
                <MicrosoftPositionCard
                  skus={props.skus}
                  addPosition={addPosition}
                  deletePosition={deletePosition}
                  position={position}
                  syncedPositions={syncedPositions}
                  setPositions={setData}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default PositionsToSync;
