import PayRunDetails from "./PayRunDetails";
import PayRunTimesheets from "./PayRunTimesheets";
import NoMatch from "../404";
import PayRunBranches from "./PayRunBranches";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";

const ShowPage = (props) => {
  const {
    filteredPays,
    filterOptions,
    selectedOptions,
    change,
    payRun,
    setPayRun,
  } = props;

  const { user } = useAuth();

  const { page, uuid } = useParams();

  switch (page) {
    case "pays":
      return (
        <PayRunDetails
          {...props}
          filteredPays={filteredPays}
          filterOptions={filterOptions}
          selectedOptions={selectedOptions}
          change={change}
        />
      );
    case "documents":
      return (
        <NewProfileDocuments
          {...props}
          model="App\Models\PayRun"
          documentableId={uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/payruns/${uuid}`}
          homeUrl={`pay-runs/${uuid}/documents`}
        />
      );
    case "branches":
      return user.is_admin ? (
        <PayRunBranches
          payRun={payRun}
          setPayRun={setPayRun}
          initialValues={{
            branches: payRun.branches.map((branch) => {
              return {
                label: branch.name,
                value: branch.id,
              };
            }),
          }}
        />
      ) : (
        <NoMatch />
      );
    case "timesheets":
      return user.is_admin ? <PayRunTimesheets uuid={uuid} /> : <NoMatch />;
    default:
      return <NoMatch />;
  }
};
export default ShowPage;
