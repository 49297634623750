import _ from "lodash";
import { useState } from "react";

const useJobFilters = () => {
  const localDueDateLimit = window.localStorage.getItem("toggleDueDateFilter");
  const localDueDateLimitDays = parseInt(
    window.localStorage.getItem("dueDateFilterDays"),
  );
  const dueDateLimit =
    localDueDateLimit === null ? false : JSON.parse(localDueDateLimit);

  const [filters, setFilters] = useState({
    statuses: [],
    search: "",
    overdue: false,
    dueDateLimit: dueDateLimit,
    dueDateLimitDays: localDueDateLimitDays || 14,
    invoiceStatuses: [],
  });

  const setSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
    });
  };

  const toggleStatuses = (status) => {
    const toggleStatusArray = filters.statuses.includes(status)
      ? filters.statuses.filter((s) => s !== status)
      : [...filters.statuses, status];

    setFilters({
      ...filters,
      statuses: toggleStatusArray,
    });
  };

  const toggleInvoiceStatuses = (status) => {
    setFilters({
      ...filters,
      invoiceStatuses: _.xor(filters.invoiceStatuses, [status]),
    });
  };

  const toggleOverdue = () => {
    setFilters({
      ...filters,
      overdue: !filters.overdue,
    });
  };

  const toggleDueDateLimit = () => {
    const newToggleState = !filters.dueDateLimit;
    setFilters({
      ...filters,
      dueDateLimit: newToggleState,
    });
    window.localStorage.setItem("toggleDueDateFilter", newToggleState);
  };

  const setDueDateLimit = (days) => {
    setFilters({
      ...filters,
      dueDateLimitDays: days,
      dueDateLimit: true,
    });
    window.localStorage.setItem("toggleDueDateFilter", true);
    window.localStorage.setItem("dueDateFilterDays", days);
  };

  return {
    filters,
    setFilters,
    setSearch,
    toggleStatuses,
    toggleOverdue,
    toggleDueDateLimit,
    setDueDateLimit,
    toggleInvoiceStatuses,
  };
};

export default useJobFilters;
