import React from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import BasicForm from "./BasicForm";
import { useParams } from "react-router-dom";

const EditEquipmentType = (props) => {
  const { equipmentType, setEquipmentType } = props;
  const { uuid } = useParams();
  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `equipment-types/${uuid}`, values)
      .then(({ data }) => {
        setEquipmentType(data.data);
        toast.success(`${data.data.type} updated successfully`);
      })
      .catch(errorSwal);
  };

  return (
    <BasicForm {...props} onSubmit={onSubmit} initialValues={equipmentType} />
  );
};

export default EditEquipmentType;
