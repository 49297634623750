import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import ReactTable from "../table/ReactTable";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import { SelectOption } from "../utils/utilTypes";
import AddPermissionGroupSubjectModal from "./AddPermissionGroupSubjectModal";
import {
  PermissionGroupProps,
  PermissionGroupSubject,
  SubjectTypes,
} from "./permissionGroupTypes";

interface PermissionGroupSubjectsProps extends PermissionGroupProps {
  subjectType: SubjectTypes;
  subjectTitle: "User" | "Position";
  options: SelectOption[];
}

const PermissionGroupSubjects = (props: PermissionGroupSubjectsProps) => {
  const { permissionGroup, subjectType, subjectTitle, options } = props;
  const { toggle, modal } = useModal();

  const { takeAction: deleteAction, loading: deleting } = useApi();

  const { data, loading, setData }: IUseApi<PermissionGroupSubject[]> = useApi(
    `/permission-groups/${
      permissionGroup.uuid
    }/subjects?filter[subject_type]=${encodeURIComponent(subjectType)}`,
    [],
    true,
  );

  const deleteSubject = (subject: PermissionGroupSubject) => {
    return deleteSwal(subject.subject.name)
      .then(() =>
        deleteAction("destroy", `/permission-group-subjects/${subject.uuid}`),
      )
      .then(() => {
        if (!data) {
          return;
        }

        setData(data.filter((s) => s.uuid !== subject.uuid));
        toast.success("Subject deleted successfully");
      })
      .catch(errorSwal);
  };

  const columnHelper = createColumnHelper<PermissionGroupSubject>();

  const columns = useMemo<ColumnDef<PermissionGroupSubject, any>[]>(
    () => [
      columnHelper.accessor("subject.name", {
        header: "Name",
      }),
      columnHelper.display({
        header: "Delete",
        cell: (info) => {
          return (
            <Button
              onClick={() => deleteSubject(info.row.original)}
              className="p-0 px-1"
              color="danger"
              outline
            >
              Delete
            </Button>
          );
        },
      }),
    ],
    [data],
  );

  if (loading) {
    return <CustomScaleLoader>Fetching {subjectTitle}s...</CustomScaleLoader>;
  }

  if (!data) return null;

  return (
    <>
      <ReactTable<PermissionGroupSubject>
        data={data}
        columns={columns}
        loading={loading || deleting}
        extraButtons={
          <div className="d-flex align-items-center">
            <Button
              onClick={toggle}
              size="sm"
              outline
              color="primary"
              className="ms-2"
            >
              Add {subjectTitle}s
            </Button>
          </div>
        }
      />
      <AddPermissionGroupSubjectModal
        permissionGroup={permissionGroup}
        subjects={data}
        setSubjects={setData}
        toggle={toggle}
        modal={modal}
        options={options}
        subjectTitle={subjectTitle}
        subjectType={subjectType}
      />
    </>
  );
};

export default PermissionGroupSubjects;
