import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, Form, formValueSelector, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import CustomForm from "../customFields/CustomForm";
import FileList from "../utils/FileList";
import FormHeader from "../utils/FormHeader";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const AddCompetencyFOrm = (props) => {
  const { handleSubmit, skillLevelId, competencySkillId } = props;

  const [customForm, setCustomForm] = useState();
  const [skillLevels, setSkillLevels] = useState([]);

  const { takeAction } = useApi();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (skillLevelId) {
      takeAction("show", `staff-competency-custom-form/${skillLevelId}`).then(
        ({ data }) => setCustomForm(data.data),
      );
    }
  }, [skillLevelId]);

  useEffect(() => {
    if (competencySkillId) {
      setSkillLevels([]);
      takeAction(
        "show",
        `/react-select?select=*&model=${encodeURIComponent(
          "App\\Models\\SkillLevel",
        )}&value=id&label=name&where[competency_skill_id]=${competencySkillId}`,
      ).then(({ data }) => setSkillLevels(data));
    }
  }, [competencySkillId]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="form-group col-4">
          <Field
            component={SelectInputAsync}
            label="Competency"
            name="competency_skill_id"
            url={`/react-select?select=*&model=${encodeURIComponent(
              "App\\Models\\CompetencySkill",
            )}&value=id&label=name`}
            validate={required}
          />
        </div>
        <div className="form-group col-4">
          <Field
            component={SelectInputAsync}
            label="Skill Level"
            name="skill_level_id"
            options={skillLevels}
            validate={required}
          />
        </div>
        <div className="form-group col-4">
          <Field
            component={renderField}
            label="Date User Made Competent"
            name="added_on"
            type="date"
            validate={required}
          />
        </div>
        <div className="form-group col-12">
          <Field component={renderField} label="Comments" name="comments" />
        </div>

        {customForm?.length > 0 ? (
          <>
            <FormHeader>Competency Details</FormHeader>
            <CustomForm customForm={customForm} {...props} />
          </>
        ) : (
          <div className="col-12">
            <FileList {...props} files={files} setFiles={setFiles} />
          </div>
        )}
      </div>
    </Form>
  );
};

const form = reduxForm({
  form: "StaffCompetency",
});

const selector = formValueSelector("StaffCompetency");

const mapStateToProps = (state) => {
  return {
    skillLevelId: selector(state, "skill_level_id"),
    competencySkillId: selector(state, "competency_skill_id"),
  };
};
export default connect(mapStateToProps, {})(form(AddCompetencyFOrm));
