import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import EditEmploymentAward from "./EditEmploymentAward";
import { EmploymentAward } from "./types";

export interface ShowEmploymentAwardPageProps {
  employmentAward: EmploymentAward;
  setEmploymentAward: (award: EmploymentAward) => void;
}

const ShowEmploymentAwardPage = (props: ShowEmploymentAwardPageProps) => {
  const { page, uuid } = useParams<{ page: string; uuid: string }>();
  const { user } = useAuth();

  switch (page) {
    case "details":
      return <EditEmploymentAward {...props} />;
    case "documents":
      return (
        <NewProfileDocuments
          {...props}
          model="App\Models\EmploymentAward"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/employment-awards/${uuid}`}
          homeUrl={`employment-awards/${uuid}/documents`}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowEmploymentAwardPage;
