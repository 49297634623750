import React from "react";

const CustomFormOverview = ({ customForm }: { customForm: any[] }) => {
  if (!customForm) return null;

  return (
    <div className="space-y-3">
      {Object.values(customForm).map((field) => {
        return (
          <div key={field.uuid} className="bg-white p-3 border  rounded-lg">
            <label className="text-dark fw-bolder">{field.label}</label>
            <p className="mb-0">{field.value}</p>
          </div>
        );
      })}
    </div>
  );
};

export default CustomFormOverview;
