import PropTypes from "prop-types";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { ButtonDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import useModal from "../hooks/useModal";
import StandardFolder from "./StandardFolder";
import useApi from "../api/useApi";
import { reset } from "redux-form";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

const StandardFolderDropdown = (props) => {
  const { model, documentableId, margin, path } = props;

  const queryClient = useQueryClient();

  const { modal: dropdownOpen, toggle } = useModal();
  const { takeAction } = useApi();
  const { folderUuid } = useParams();

  const onSubmit = (values, dispatch) => {
    if (!values.name) {
      return toast.warning("Please Enter Folder Name.");
    }
    const data = {
      ...values,
      documentable_type: model,
      documentable_id: documentableId,
      document_id: folderUuid,
      path,
    };

    return takeAction("store", "standard-documents/folders", data)
      .then(({ data }) => {
        queryClient.invalidateQueries("standard-documents");
        dispatch(reset("addFolder"));
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <ButtonDropdown
      className={`w-100 ${margin}`}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle className="w-100" color="light" caret>
        <i className="fa fa-plus pe-2"></i>Add Folder
      </DropdownToggle>
      <DropdownMenu>
        <div className="pd-30 pd-sm-40 wd-sm-350">
          <StandardFolder onSubmit={onSubmit} />
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

StandardFolderDropdown.propTypes = {
  documentable_type: PropTypes.string.isRequired,
  documentable_id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  document_id: PropTypes.string,
  margin: PropTypes.string,
};

export default StandardFolderDropdown;
