import Edit from "./Edit";
import Insurances from "./Insurances";
import NotFound from "../utils/NotFound";
import { useParams } from "react-router-dom";
import SupplierTypeFields from "./SupplierTypeFields";

const ShowPage = (props) => {
  const { page } = useParams();

  switch (page) {
    case "details":
      return <Edit {...props} />;
    case "insurances":
      return <Insurances {...props} />;
    case "form":
      return <SupplierTypeFields {...props} />;
    default:
      return <NotFound />;
  }
};

export default ShowPage;
