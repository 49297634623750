import { useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";

const useSortJobs = (defaultKey) => {
  defaultKey = defaultKey
    ? defaultKey
    : localStorage.getItem("job_sort_key")
    ? localStorage.getItem("job_sort_key")
    : "name";

  const order = !localStorage.getItem("job_sort_order")
    ? true
    : localStorage.getItem("job_sort_order") === "true"
    ? true
    : false;

  const sortFuncs = {
    scheduled_finish_date: (job) => dayjs(job.scheduled_finish_date),
    scheduled_start_date: (job) => dayjs(job.scheduled_start_date),
    manager: (job) => job.manager.name,
    name: (job) => job.name,
    status: (job) => job.status,
    job_number: (job) => job.job_number,
  };

  const [sort, setSort] = useState({
    key: defaultKey,
    order,
  });

  const toggleSort = (key) => {
    const order = sort.key === key ? !sort.order : true;
    localStorage.setItem("job_sort_key", key);
    localStorage.setItem("job_sort_order", order);
    setSort({
      key,
      order,
    });
  };

  const sortJobs = (jobs) => {
    return _.orderBy(
      jobs,
      (job) => sortFuncs[sort.key](job),
      sort.order ? "asc" : "desc",
    );
  };

  return {
    sortJobs,
    toggleSort,
    sort,
    setSort,
  };
};

export default useSortJobs;
