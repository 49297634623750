import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import AddModalButton from "./AddModalButton";
import DuplicatePriceButton from "./DuplicatePriceButton";
import PriceList from "./PriceList";
import { Price, PricesProps } from "./priceTypes";

const Prices = (props: PricesProps) => {
  const { chargeableType, chargeableId, duplicate, onUpdate } = props;

  const { data, loading, setData } = useApi(
    `pricing?chargeable_type=${chargeableType}&chargeable_id=${chargeableId}`,
    [],
    true,
  );

  const prices = data as Price[];

  return (
    <>
      <div className="row">
        <div className={`col-lg-${props.duplicate ? "6" : "12"}`}>
          <AddModalButton prices={prices} setPrices={setData} {...props} />
        </div>
        {duplicate && (
          <div className="col-lg-6">
            <DuplicatePriceButton
              {...props}
              prices={prices}
              setPrices={setData}
            />
          </div>
        )}
      </div>
      {loading && <CustomScaleLoader>Fetching Prices...</CustomScaleLoader>}
      <div className="mg-t-20">
        <PriceList
          onUpdate={onUpdate}
          prices={prices}
          setPrices={setData}
          showAmount={props.showAmount}
        />
      </div>
    </>
  );
};

export default Prices;
