import React from "react";
import {
  Field,
  FieldArray,
  FormSubmitHandler,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { BranchIndex } from "../branches/branchTypes";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";
import FormHeader from "../utils/FormHeader";
import renderToggleInput from "../utils/renderToggleInput";
import { connect } from "react-redux";
import EmailBranches from "./EmailBranches";

interface XeroProfitAndLossProps {
  email?: boolean;
}

interface FormData {
  from: string;
  to: string;
  periods: string;
  timeframe: string;
  email_branches?: {
    uuid: string;
    recipients: { label: React.ReactNode; value: string }[];
    branches: { label: React.ReactNode; value: string }[];
  }[];
  branches: { label: string; value: string }[];
}

const XeroProfitAndLoss = (
  props: InjectedFormProps<FormData, XeroProfitAndLossProps> &
    XeroProfitAndLossProps,
) => {
  const { handleSubmit, email } = props;

  const { data: branches }: IUseApiWithData<BranchIndex[]> = useApi(
    `/organisation-branches?filter[include_child_branches]=true`,
    [],
  );

  const { takeAction }: IUseApi<{}, { data: string }> = useApi();

  const onSubmit: FormSubmitHandler<FormData> = (values) => {
    if (values.email_branches) {
      return takeAction("store", "email-profit-loss", {
        ...values,
        email_branches: values.email_branches.map((d) => ({
          ...d,
          recipients: d.recipients.map((r) => r.value),
          branches: d.branches.map((b) => b.value),
        })),
      })
        .then(() => {
          toast.success("Profit and Loss reports generated");
        })
        .catch(errorSwal);
    }

    return Promise.all(
      values.branches.map((branch: { label: string; value: string }) => {
        return takeAction(
          "store",
          `organisation-branches/${branch.value}/profit-loss`,
          { ...values, convert_to_excel: true },
        )
          .then(({ data }) => {
            window.open(data.data, "_blank");
          })
          .catch(errorSwal);
      }),
    ).then(() => {
      toast.success("Profit and Loss reports generated");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormHeader>Profit and Loss</FormHeader>
        <div className="col-md-6 form-group">
          <Field component={RenderField} type="date" name="from" label="From" />
        </div>
        <div className="col-md-6 form-group">
          <Field component={RenderField} type="date" name="to" label="To" />
        </div>
        <div className="col-md-6 form-group">
          <Field
            component={RenderField}
            type="number"
            name="periods"
            label="Periods"
            extraProps={{
              min: 1,
              max: 11,
            }}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            name="timeframe"
            label="Time Frame"
            options={[
              {
                label: "Month",
                value: "MONTH",
              },
              {
                label: "Quarter",
                value: "QUARTER",
              },
              {
                label: "Year",
                value: "YEAR",
              },
            ]}
          />
        </div>

        <div className="col-lg-12 form-group">
          <Field component={renderToggleInput} name="email" label="Email" />
        </div>
        {!email ? (
          <div className="col-lg-12 form-group">
            <Field
              component={SelectInput}
              name="branches"
              label="Branches"
              isMulti
              options={branches.map((d) => ({
                label: `${d.display_name} ${
                  d.organisation ? `(${d.organisation})` : ""
                }`,
                value: d.uuid,
              }))}
            />
          </div>
        ) : (
          <FieldArray
            component={EmailBranches}
            branches={branches}
            name="email_branches"
          />
        )}
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<FormData, XeroProfitAndLossProps>({});

const selector = formValueSelector("XeroProfitAndLoss");
const mapStateToProps = (state: any) => {
  return {
    email: selector(state, "email"),
  };
};

export default connect(mapStateToProps)(form(XeroProfitAndLoss));
