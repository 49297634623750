import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchNotifications,
  updateNotifications,
} from "../../actions/notificationsActions";
import NotificationsDetails from "./NotificationsDetails";

class Notifications extends Component {
  componentDidMount() {
    this.props.fetchNotifications();
  }

  render() {
    const { notifications, updateNotifications } = this.props;

    return (
      <div className="dropdown dropdown-b">
        <a
          href="javascript:void(0)"
          onClick={notifications ? this.update : null}
          className="header-notification"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="icon ion-ios-bell-outline" />
          {notifications && notifications.length
            ? notifications.map((notification) => (
                <div key={notification.id}>
                  {!notification.read_at && (
                    <span className="indicator bg-danger" />
                  )}
                </div>
              ))
            : ""}
        </a>
        <div
          className="dropdown-menu header-dropdown"
          x-placement="bottom-start"
        >
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
            <div>
              <a
                href="javascript:void(0)"
                onClick={() =>
                  updateNotifications(
                    notifications.map((notification) => notification.id),
                  )
                }
              >
                Mark All as Read
              </a>
            </div>
          </div>
          <div className="dropdown-list">
            <NotificationsDetails amount={5} {...this.props} />
            <Link to="/notifications/show">
              <div className="dropdown-list-footer">
                <i className="fa fa-angle-down" /> Show All Notifications (
                {notifications
                  ? notifications.filter(
                      (notification) => !notification.read_at,
                    ).length
                  : 0}
                )
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.items,
});

export default connect(mapStateToProps, {
  fetchNotifications,
  updateNotifications,
})(Notifications);
