const validate = (values) => {
  const errors = {};
  if (values.closed_out) {
    if (!values.root_cause) errors.root_cause = "Required if Closing CPIR Out";
    if (!values.corrective_action)
      errors.corrective_action = "Required if Closing CPIR Out";
    if (!values.preventative_action)
      errors.preventative_action = "Required if Closing CPIR Out";
    if (!values.how_actions_verified)
      errors.how_actions_verified = "Required if Closing CPIR Out";
    if (!values.investigation)
      errors.investigation = "Required if Closing CPIR Out";
  }

  if (values.severity === undefined || values.likelihood === undefined) {
    errors.matrix = "Please add a risk level.";
  }

  return errors;
};

export default validate;
