import { Field, reduxForm } from "redux-form";

import renderField from "../../utils/renderField";
import required from "../../utils/required";
import SubmitButton from "../../utils/SubmitButton";

const FormjobType = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <Field
            name="name"
            type="text"
            component={renderField}
            label="Name"
            required
            validate={required}
          />
        </div>

        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "JobType" });

export default form(FormjobType);
