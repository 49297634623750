import React from "react";
import useApi from "../../api/useApi";
import { toast } from "react-toastify";
import errorSwal from "../../utils/errorSwal";
import { Field, reduxForm } from "redux-form";
import RenderField from "../../utils/renderField";
import required from "../../utils/required";
import SubmitButton from "../../utils/SubmitButton";

const Login = (props) => {
  const { handleSubmit } = props;

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("store", "the-testing-app/login", values)
      .then(({ data }) =>
        toast.success(
          `Successfully integrated theTESTINGapp with ${data.data.organisation}`,
        ),
      )
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="email"
            validate={required}
            required
            label="theTESTINGApp email"
            type="email"
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="password"
            validate={required}
            required
            label="theTESTINGApp password"
            type="password"
          />
        </div>
        <small className="col-12">
          This will log you in as the organisation that you last logged into in
          theTESTINGApp.
        </small>
        <small className="col-12">
          Please ensure that this is the organisation that you want to be logged
          into.
        </small>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "theTESTINGappLogin" });

export default form(Login);
