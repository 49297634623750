import React from "react";
import { useAuth } from "../../context/auth-context";
import formatAddress from "../utils/formatAddress";
import FormHeader from "../utils/FormHeader";
import DetailField from "./DetailField";
import DetailForm from "./DetailForm";
import OrganisationDetails from "./organisationDetails";
import DocsModal from "../utils/DocsModal";

const ClientDetails = props => {
  const { user } = useAuth();

  const { client } = props;
  const streetAdress = {
    line_1: client.line_1,
    line_2: client.line_2,
    suburb: client.suburb,
    state: client.state,
    postcode: client.postcode,
  };

  const postalAddress = {
    line_1: client.postal_line_1,
    line_2: client.postal_line_2,
    suburb: client.postal_suburb,
    state: client.postal_state,
    postcode: client.postal_postcode,
  };

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/clients/client-profile/" />
      <div className="row">
        {!user.is_admin && (
          <>
            <DetailField
              label="ABN Holder"
              value={client.abn_holder ? "Yes" : "No"}
            />

            {client.abn_holder && <OrganisationDetails organisation={client} />}

            <DetailField
              label="Contact Email"
              value={client.contact_email}
              link={
                <a href={`mailto:${client.contact_email}`}>
                  {client.contact_email}
                </a>
              }
            />
            <DetailField
              label="Contact Phone"
              value={client.contact_telephone}
              link={
                <a href={`tel:${client.contact_telephone}`}>
                  {client.contact_telephone}
                </a>
              }
            />
            <DetailField
              label="Accounts Phone"
              value={client.accounts_telephone}
              link={
                <a href={`tel:${client.accounts_telephone}`}>
                  {client.accounts_telephone}
                </a>
              }
            />
            <DetailField
              label="Street Address"
              value={formatAddress(streetAdress)}
              link={
                <a
                  href={`http://maps.google.com/maps?q=${formatAddress(
                    streetAdress,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatAddress(streetAdress)}
                </a>
              }
            />
            <DetailField
              label="Postal Address"
              value={formatAddress(postalAddress)}
              link={
                <a
                  href={`http://maps.google.com/maps?q=${formatAddress(
                    postalAddress,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatAddress(postalAddress)}
                </a>
              }
            />
          </>
        )}
        <FormHeader>Client Details</FormHeader>
      </div>
      <DetailForm {...props} />
    </>
  );
};

export default ClientDetails;
