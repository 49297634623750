import "chart.js/auto";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import _ from "lodash";

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const formatHours = (hours) => {
  const groupedHours = _.groupBy(hours, "units_id");
  const formattedHours = Object.values(groupedHours).map((hour) => {
    return {
      units: hour[0].units,
      amount: _.sumBy(hour, (h) => parseFloat(h.amount)),
    };
  });
  return {
    labels: formattedHours.map((hour) => hour.units),
    datasets: [
      {
        data: formattedHours.map((hour) => hour.amount),
        backgroundColor: "rgba(0, 170, 79, 0.2)",
        borderColor: "rgba(0, 170, 79, 1)",
        borderWidth: 1,
      },
    ],
  };
};

class AccumulatedHours extends Component {
  render() {
    const { accumulatedHours } = this.props;
    if (accumulatedHours.length === 0) return null;
    return (
      <div className="card card-sales mt-3">
        <h6 className="slim-card-title tx-success">Accumulated Tracking</h6>

        <Bar data={formatHours(accumulatedHours)} options={options} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accumulatedHours: state.calibrationTracking.items,
});

export default connect(mapStateToProps, {})(AccumulatedHours);
