import React from "react";
import { toast } from "react-toastify";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import NoticeDeleteModal from "./NoticeDeleteModal";

interface noticeProps {
  noticeId: string;
  noticeName: string;
  queryData: any;
}

const NoticeCardDropdown = ({
  noticeId,
  noticeName,
  queryData,
}: noticeProps) => {
  const { takeAction }: IUseApi = useApi();
  const { toggle: toggleDelete, modal: deleteModal } = useModal();

  const onSubmit = (values: any) => {
    return takeAction("destroy", `notices/${noticeId}`)
      .then(({ data }: { data: { data: any } }) => {
        toast.success("Notice Deleted Successfully.");
        toggleDelete();
        queryData();
      })
      .catch(errorSwal);
  };

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle color="link" className="p-1">
          <FiMoreHorizontal className="text-secondary" />
        </DropdownToggle>
        <DropdownMenu className="z-20">
          <DropdownItem
            onClick={toggleDelete}
            className="d-flex align-items-center px-2 text-secondary"
          >
            <AiOutlineDelete className="me-2 tx-15" /> Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <NoticeDeleteModal
        onSubmit={onSubmit}
        form="DeleteNotice"
        toggle={toggleDelete}
        modal={deleteModal}
        noticeName={noticeName}
      />
    </>
  );
};

export default NoticeCardDropdown;
