import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const Buttons = (props) => {
  const { purchaseOrder } = props;
  const { loading, takeAction } = useApi();

  const generatePdf = () => {
    return takeAction("store", "purchase-order-pdfs", {
      purchase_order_id: purchaseOrder.id,
    })
      .then(({ data }) => {
        window.location.href = data.data;
      })
      .catch(errorSwal);
  };

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      <li className="nav-item">
        <button
          type="button"
          disabled={loading}
          onClick={generatePdf}
          className="pointer-hover w-100 nav-link"
        >
          {isSubmitting(
            loading,
            <>
              <i className="fa fa-download tx-primary tx-16" /> Download
              Purchase Order PDF
            </>,
            "Generating PDF...",
          )}
        </button>
      </li>
      <StatusButton {...props} />
    </ul>
  );
};

const getValues = ({ sent_at, received_at, billed_at }, userId) => {
  const now = dayjs().format("YYYY-MM-DD HH:mm:ss");

  if (!sent_at) {
    return {
      text: "Sent",
      values: {
        sent_at: now,
        sent_by: userId,
      },
    };
  }
  if (!received_at) {
    return {
      text: "Received",
      values: {
        received_at: now,
        received_by: userId,
      },
    };
  }
  if (!billed_at) {
    return {
      text: "Billed",
      values: {
        billed_at: now,
        billed_by: userId,
      },
    };
  }
  return null;
};

const StatusButton = ({ purchaseOrder, setPurchaseOrder }) => {
  const [submitting, setSubmitting] = useState(false);
  const { user } = useAuth();

  const purchaseOrderStatus = getValues(purchaseOrder, user.id);
  if (!purchaseOrderStatus) return null;

  const updatePurchaseOrder = () => {
    setSubmitting(true);

    return axios
      .put(
        `purchase-order-statuses/${purchaseOrder.uuid}`,
        purchaseOrderStatus.values,
      )
      .then(({ data }) => {
        toast.success(`${purchaseOrder.name} updated!`);
        setPurchaseOrder(data.data);
      })
      .catch(errorSwal)
      .then(() => setSubmitting(false));
  };

  if (
    purchaseOrderStatus.text === "Received" &&
    user.id !== purchaseOrder.user_id &&
    !user.is_admin
  ) {
    return null;
  }

  if (
    purchaseOrderStatus.text === "Billed" &&
    purchaseOrder.purchase_invoice.approval_action &&
    !user.is_admin
  ) {
    return null;
  }

  if (
    purchaseOrderStatus.text === "Billed" &&
    purchaseOrder.integration_model
  ) {
    return null;
  }

  return (
    <li className="nav-item">
      <button
        type="button"
        disabled={submitting}
        onClick={updatePurchaseOrder}
        className="pointer-hover w-100 nav-link"
      >
        {isSubmitting(
          submitting,
          `Mark ${purchaseOrderStatus.text}`,
          `Marking ${purchaseOrderStatus.text}...`,
        )}
      </button>
    </li>
  );
};

export default Buttons;
